import React, {CSSProperties, useEffect, useState} from "react";
import {ProjectNewEnum as e, ProjectNewModel, ProjectType, SetModel} from "@/models/Project/Projects";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import {TPKeyValue} from "@/helpers/TPKeyValue";
import {GroupsService} from "@/services/GroupsService";
import {Chip} from "@mui/material";
import {showToast, TPToastTypes} from "@/components/bootstrap/components/toasts/TPToast";

const defaulGroup = {key: '', value: '---'} as TPKeyValue;

interface ProjectNewStep2FrontProps {
    m: { [attribute: string]: any };
    project: ProjectNewModel;
    onChange: Function;
}

export default function ProjectNewStep2Front({m, project, onChange}: ProjectNewStep2FrontProps) {
    const [groups, setGroups] = useState<Array<TPKeyValue>>([]);
    const [queue, setQueue] = useState<string>('');
    const [queues, setQueues] = useState<Array<SetModel>>(project.conditionsOrQueues!);

    useEffect(() => {
        load()
    }, []);

    useEffect(() => {
        onChange(queues)
    }, [queues]);

    function load() {
        const {findByType} = new GroupsService()
        findByType(ProjectType.FRONTOFFICE)
            .then(response => {
                const g = response.map(({id: key, name: value}) => ({key, value}))
                setGroups([defaulGroup, ...g])
            })
            .catch(error => console.error(error));
    }

    function onChangeHandler(event: any) {
        const id = event.target.value
        if (queues.find(({groupId}) => `${groupId}` === id)) {
            showToast(m?.[e.MessageQueueAlreadyExists], TPToastTypes.warning)
            return;
        }

        const group = groups.find(({key}) => `${key}` === id);
        if (group) {
            setQueues([...queues, {groupId: group.key!} as any])
        }
        setQueue('')
    }

    function onDeleteChipHandler(index: number) {
        const q = [...queues]
        q.splice(index, 1)
        setQueues(q)
    }

    return (
        <>
            <h6 style={styles.title}>
                {m?.[e.Step2FrontSubtitleLabel]}
            </h6>

            <span>{m?.[e.Step2FrontDescriptionLabel]}</span>

            <form style={styles.form}>
                <div style={styles.flex50}>
                    <TPSelect
                        id="queue"
                        selectStyle={styles.select}
                        isMandatory={true}
                        labelText={m?.[e.Step2InputQueueLabel]}
                        dataSource={groups}
                        value={queue}
                        onChange={onChangeHandler}
                    />
                </div>

                <div style={styles.container}>
                    {groups && queues.map(({groupId}, index) => (
                        <Chip id={`chip-${groupId}-${index}`}
                              key={`chip-${groupId}-${index}`}
                              style={styles.chip}
                              label={groups.find(({key}) => key === groupId as any)?.value}
                              onDelete={() => onDeleteChipHandler(index)}
                        />
                    ))}
                </div>
            </form>
        </>
    );
}

const styles = {
    title: {
        marginBottom: "20px",
    } as CSSProperties,
    form: {
        margin: "20px 0",
    } as CSSProperties,
    flex50: { width: "50%", } as CSSProperties,
    select: {
        marginBottom: "20px",
    } as CSSProperties,
    container: {
        width: "50%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        flexFlow: "wrap",
        gap: "10px",
    } as CSSProperties,
    chip: {
        backgroundColor: "#ebf2ff",
    } as CSSProperties,
};
