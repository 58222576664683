import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useStatusDistributionListAdmin } from "./useStatusMyReminders";



type AppProviderAppProviderMyReminders = {
  children: ReactNode;
};

class model {
  status: Array<any> = new Array<any>;
  resourceSet: string = "";
  loading: boolean = false;
  statusModel: Array<any> = new Array<any>;
  setStatusModel: (data: Array<any>) => void = (data: Array<any>) => void {};
  calendarReminders: Array<any> = new Array<any>;
  setLoading: (data: boolean) => void = (data: boolean) => void {};
  setCalendarReminders: (data: Array<any>) => void = (data: Array<any>) => void {};
}

type modelContext = {
  status: Array<any>;
  resourceSet: string;
  loading: boolean;
  calendarReminders: Array<any>;
  statusModel: Array<any>;
  setStatusModel: (data: Array<any>) => void;
  setLoading: (data: boolean) => void;
  setCalendarReminders: (data: Array<any>) => void;
}

export const MyRemindersContext = createContext<modelContext>(
  new model());

export const AppProviderMyRemindersContext: React.FC<AppProviderAppProviderMyReminders> = ({ children }) => {

  const {
    status,
    resourceSet,
    loading,
    calendarReminders,
    statusModel,
    setStatusModel,
    setLoading,
    setCalendarReminders

  } = useStatusDistributionListAdmin();


  useEffect(() => {
  }, []);



  return (
    <TPLoadingOverlay active={false}>
      <MyRemindersContext.Provider value={{
        status,
        resourceSet,
        loading,
        calendarReminders,
        statusModel,
        setStatusModel,
        setLoading,
        setCalendarReminders
      }}>
        {children}
      </MyRemindersContext.Provider>
    </TPLoadingOverlay>
  );
};


export const useAppMyReminders = () => {
  return useContext(MyRemindersContext);
}