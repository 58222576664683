import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { WorkflowAssignmentViewModel } from "@/models/Workflow/WorkflowAssignmentModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { RelationsService } from "@/services/RelationsService";
import { WorkflowAssignmentService } from "@/services/WorkflowAssignmentService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";

type AdminStateType = {
  organizationsRelationsAreLoaded: boolean;
  currentBaseLevel: string;
  gridColumns: Array<any>;
  gridData: Array<WorkflowAssignmentViewModel>;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface WorkflowAssignmentAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_OrganizationsRelationsAreLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_CurrentBaseLevel" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const WorkflowAssignmentAdmin = React.forwardRef(
  ({ callBackCommands }: WorkflowAssignmentAdminInterface, ref) => {
    const componentFileName: string = "WorkflowAssignmentAdmin.tsx";

    //Function called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "WorkflowAssignmentAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //organizations/relations filter
    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    //grid columns
    const [workflowTypeColumnLabel, setWorkflowTypeColumnLabel] = useState("");
    const [
      classificatorBranchColumnLabel1,
      setClassificatorBranchColumnLabel1,
    ] = useState("");
    const [
      classificatorBranchColumnLabel2,
      setClassificatorBranchColumnLabel2,
    ] = useState("");
    const [
      classificatorBranchColumnLabel3,
      setClassificatorBranchColumnLabel3,
    ] = useState("");
    const [
      classificatorBranchColumnLabel4,
      setClassificatorBranchColumnLabel4,
    ] = useState("");
    const [
      classificatorBranchColumnLabel5,
      setClassificatorBranchColumnLabel5,
    ] = useState("");
    const [
      applyForAllOrganizationsRelationsColumnLabel,
      setApplyForAllOrganizationsRelationsColumnLabel,
    ] = useState("");
    const [weightColumnLabel, setWeightColumnLabel] = useState("");
    const [updateByColumnLabel, setUpdateByColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    //#endregion

    //Load Resources and organizations
    const loadResourcesAndOrganizations = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );

      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "OrganizationRelation")
      );

      //grid columns
      setWorkflowTypeColumnLabel(
        await TPI18N.GetText(resourceSet, "WorkflowType")
      );
      setClassificatorBranchColumnLabel1(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel1")
      );
      setClassificatorBranchColumnLabel2(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel2")
      );
      setClassificatorBranchColumnLabel3(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel3")
      );
      setClassificatorBranchColumnLabel4(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel4")
      );
      setClassificatorBranchColumnLabel5(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel5")
      );
      setApplyForAllOrganizationsRelationsColumnLabel(
        await TPI18N.GetText(
          resourceSet,
          "ApplyForAllOrganizationsRelatiosnLabel"
        )
      );
      setWeightColumnLabel(await TPI18N.GetText(resourceSet, "Weight"));
      setUpdateByColumnLabel(await TPI18N.GetText(resourceSet, "UpdatedBy"));

      //Organizations/Relations
      await getOrganizationsRelationsList();
      let command1: commandType = {
        type: commandsEnum.set_OrganizationsRelationsAreLoaded,
        payload: null,
      };
      dispatchCommand(command1);
    };

    const getRelationsList = async (
      newOrganization: string
    ): Promise<Array<TPKeyValue>> => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    const getOrganizationsRelationsList = async () => {
      let serviceOrganizationRelationClient =
        new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);

        let responseRequestOrgRela =
          await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
            TPActiveOptions.ALL.toString(),
            false,
            true,
            expectedCodes
          );

        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: responseRequestOrgRela[i].id,
            value: responseRequestOrgRela[i].localizedDescription,
          };
          newOrganizationsRelationsListState.push(keyvalue);
        }

        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);
        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`
        );
        setIsLoadingScreen(false);
      }
    };

    const conditionalRowStyles = [
      {
        when: (row: any) => !row.baseLevelId,
        style: { color: "#04b469" },
      },
    ];

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevel: string;
      newBaseLevel = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_CurrentBaseLevel,
        payload: { newBaseLevel: newBaseLevel },
      };
      dispatchCommand(command1);
    };

    //New Workflow Assignment
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Get workflow assignments by Filter organizationRelation
    const reloadDataGrid = async (baseLevelId: string) => {
      let serviceClient = new WorkflowAssignmentService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        if (baseLevelId !== "") {
          let responseRequest =
            await serviceClient.getWorkflowAssignmentByBaseLevel(
              baseLevelId,
              false,
              true,
              expectedCodes
            );
          setIsLoadingScreen(false);
          return [...responseRequest];
        } else {
          let responseRequest = await serviceClient.getAll(
            false,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          return [...responseRequest];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.currentBaseLevel)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Update workflow assignment
    const handleUpdateClick = (id: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        recordDescription: "",
      };
      callBackCommands(command);
    };

    //Modal Question to delete workflow assignment
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete workflow assignment after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new WorkflowAssignmentService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest =
            await serviceClient.deleteWorkflowAssignmentById(
              callBackData.recordId,
              true,
              true,
              expectedCodes
            );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      organizationsRelationsAreLoaded: false,
      currentBaseLevel: "",
      columnsAreLoaded: false,
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<WorkflowAssignmentViewModel> =
      {
        workflowTypeDescription: workflowTypeColumnLabel,
        baseLevelSourceDescription: organizationsRelationsLabel,
        branch1Description: classificatorBranchColumnLabel1,
        branch2Description: classificatorBranchColumnLabel2,
        branch3Description: classificatorBranchColumnLabel3,
        branch4Description: classificatorBranchColumnLabel4,
        branch5Description: classificatorBranchColumnLabel5,
        typistUserDescription: updateByColumnLabel,
      };

    const customColumns: ColumnStyles<WorkflowAssignmentViewModel> = {
      guidId: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          // isDisabled={item.isSystemRecord}
          onClick={() => handleUpdateClick(item.guidId)}
        />
      ),
      // isSystemRecord: ({ value }) => (
      //   <TableChip
      //     value={value}
      //     onLabel={yesLabel}
      //     offLabel={noLabel}
      //     justify="flex-start"
      //   />
      // ),
    };

    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { guidId: string }) => handleUpdateClick(e.guidId),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { guidId: string }) => handleDeleteClick(e.guidId),
      },
    ];

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      let newAdminState: AdminStateType;
      switch (command.type) {
        case commandsEnum.set_OrganizationsRelationsAreLoaded:
          newAdminState = { ...prevState };
          newAdminState.organizationsRelationsAreLoaded = true;
          return newAdminState;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.change_CurrentBaseLevel:
          newAdminState = { ...prevState };
          newAdminState.currentBaseLevel = command.payload.newBaseLevel;
          return newAdminState;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: organizationsRelationsKeyValue,
        label: organizationsRelationsLabel,
        selectedValue: adminState.currentBaseLevel,
        onChange: handleOrganizationRelationChange,
      },
    ];

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizations();
    }, []);

    //Run when organizations/relations are loaded to get columns
    useEffect(() => {
      if (adminState.organizationsRelationsAreLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.organizationsRelationsAreLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change organization/relation
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.currentBaseLevel]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>

        <TPLoadingOverlay>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-additional-data-type"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                hiddenColumns={[
                  "baseLevelId",
                  "baseLevelSourceDescription",
                  "branchTree1Id",
                  "branchTree2Id",
                  "branchTree3Id",
                  "branchTree4Id",
                  "branchTree5Id",
                  "guidId",
                  "sourceBaseLevelId",
                  "typistGuid",
                  "workflowTypeId",
                ]}
                minorOptions={minorOptions}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                // disableMinorOption={(item) => item.isSystemRecord}
                additionalFilters={additionalFilters}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default WorkflowAssignmentAdmin;
