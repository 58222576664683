import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { RelationsInputDTO } from "@/models/Relations/RelationsInputDTO";
import {
  RelationBaseLevelViewModel,
  RelationsViewModel,
  RelationsWithRestrictionsViewModel,
} from "@/models/Relations/RelationsModels";

export class RelationsService {
  serviceFileName: string = "RelationsService.ts";

  public async getRelationsByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<RelationsViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRelationsByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getRelationsByFilter`);
      throw new Error(`Error ${this.serviceFileName} getRelationsByFilter`);
    }
  }

  public async getRelationById(
    relationId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<RelationsViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation/";
    url = url + relationId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRelationById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getRelationById`);
      throw new Error(`Error ${this.serviceFileName} getRelationById`);
    }
  }

  public async deleteRelationById(
    relationId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation/" + relationId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteRelationById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteRelationById`);
      throw new Error(`Error ${this.serviceFileName} deleteRelationById`);
    }
  }

  public async insertRelation(
    inputDTO: RelationsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertRelation ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertRelation`);
      throw new Error(`Error ${this.serviceFileName} insertRelation`);
    }
  }

  public async updateRelation(
    inputDTO: RelationsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateRelation ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateRelation`);
      throw new Error(`Error ${this.serviceFileName} updateRelation`);
    }
  }

  public async getRelationsByOrganizationIdAndProfileRestriction(
    organizationId: string,
    profileId: string,
    restrictionId: string,
    partialRestriction: boolean,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<RelationsWithRestrictionsViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
    url =
      "/relation/organization-profile?OrganizationId=" +
      organizationId +
      "&ProfileId=" +
      profileId;
    url = url + "&RestrictionId=" + restrictionId;
    url =
      url + "&PartialRestriction=" + (partialRestriction ? "true" : "false");

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRelationsByOrganizationIdAndProfileRestriction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getRelationsByOrganizationIdAndProfileRestriction`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getRelationsByOrganizationIdAndProfileRestriction`,
      );
    }
  }

  public async getRelationsByOrganizationAndFilter(
    organizationId: string,
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<RelationBaseLevelViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/relation/organization/" + organizationId;
    url = url + "?FilterIsActive=" + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRelationsByOrganizationAndFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getRelationsByOrganizationAndFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getRelationsByOrganizationAndFilter`,
      );
    }
  }
}
