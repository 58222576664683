import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPBranchSelection2, { TPBranchSelectionValue } from "@/components/TPBranchSelection/TPBranchSelection2";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface ScriptTreeLogicItemProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
  treeId: string;
}

const ScriptTreeLogicItem: FC<ScriptTreeLogicItemProperties> = function ({ availableNextSteps, logic, onLogicAdd, treeId }) {
  const [nextStep, setNextStep] = useState(availableNextSteps.find(a => a.order == -1)?.id || "");
  const [chosenBranch, setChosenBranch] = useState<TPBranchSelectionValue>({
    branchDescription: "",
    branchHierarchyDescription: "",
    branchId: ""
  } as TPBranchSelectionValue);

  return (
    <>
      <TPBranchSelection2
        treeId={treeId}
        emptyLabel="a"
        modalAcceptLabel="_ok"
        modalCancelLabel="_no"
        modalSelectedBranchLabel="a"
        modalTitle="a"
        mustSelectLastLevelBranch={false}
        onChange={(
          branchId: string,
          branchDescription: string,
          branchHierachyDescription: string
        ) => setChosenBranch({
          branchId: branchId,
          branchDescription: branchDescription,
          branchHierarchyDescription: branchHierachyDescription
        })}
        value={chosenBranch}
        withIcon
        autoCloseTreeModalWhenSelect
      />
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => {
          if (chosenBranch.branchId.trim().length == 0) return;
          onLogicAdd({
            key_SSDA: chosenBranch.branchId,
            nextStepId: nextStep,
            value: chosenBranch.branchDescription
          })
        }}
        isDesignSystem
        style={{
          width: 'min-content',
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={(chosenBranch.branchId.trim().length == 0) || !chosenBranch}
      >
        _add_logic
      </TPButton>
    </>
  )
}

export default ScriptTreeLogicItem;