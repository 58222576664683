import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { FC, ReactElement } from "react";
import {
  CockpitDashboardLabels,
  RankingUser,
} from "../../supervisor-cockpit.model";
import CockpitRankingUser from "../../utils/CockpitRankingUser";
import {
  StyledRankingContainer,
  StyledRankingHeader,
  StyledRankingUser,
} from "../cockpit-dashboard-styles";

type EmployeeRankingProps = {
  rankingEmployees: RankingUser[];
  componentLabels: CockpitDashboardLabels;
  callBackCommands: Function;
};

const EmployeeRanking: FC<EmployeeRankingProps> = React.memo(
  ({ rankingEmployees, componentLabels, callBackCommands }): ReactElement => {
    const component: string = "EmployeeRanking";

    function onRedirectToWallboard() {
      let command: any = {
        command: "new_horizontal_tab",
        recordId: "",
        component:
          "TPClientCloudMain.Client.Components.SupervisorCockpit.Wallboard",
      };
      callBackCommands(command);
    }

    return (
      <StyledRankingContainer>
        <StyledRankingHeader>
          <p>
            <strong>{componentLabels.employee}</strong> (
            {componentLabels.lastDays})
          </p>
          <TPButton
            id="go-to-wallboards"
            onClick={onRedirectToWallboard}
            withIcon={TPIconTypes.doubleArrowRight}
            customType={ButtonCustomType.tertiary}
            isDesignSystem
          >
            {componentLabels.goToWallboards}
          </TPButton>
        </StyledRankingHeader>
        <StyledRankingUser>
          {rankingEmployees.map((item, index) => {
            return (
              <CockpitRankingUser
                key={index}
                name={item.userName}
                time={item.averageHandleTime}
                cases={item.managedTickets}
                position={index + 1}
              />
            );
          })}
        </StyledRankingUser>
      </StyledRankingContainer>
    );
  }
);

export default EmployeeRanking;
