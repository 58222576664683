import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { ReactElement, useEffect, useState } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";

interface TPFilterSelectorProps {
  filterList: Array<TPKeyValue>;
  onFilterChange: Function;
  isShown: boolean;
  title: string;
  id?: string;
}

const TPFilterSelector = ({
  filterList,
  onFilterChange,
  isShown,
  title,
  id = "",
}: TPFilterSelectorProps): ReactElement => {
  const componentFileName: string = "TPFilterSelector.tsx";

  //Screen resources
  const resourceSet: string = "TPFilterSelectorComponent";
  const [applyLabel, setApplyLabel] = useState("");
  const [cancelLabel, setCancelLabel] = useState("");

  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const [localfilterList, setLocalFilterList] = useState<Array<TPKeyValue>>([]);

  const loadResources = async () => {
    setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
    setCancelLabel(await TPI18N.GetText(resourceSet, "CancelLabel"));
  };

  const handleToggleCheckbox = (item: TPKeyValue) => {
    let newLocalfilterList: Array<TPKeyValue> = [...localfilterList];
    let isUnselectingElements: boolean = false;
    let isAllSelected: boolean = true;

    if (item.key === newLocalfilterList[0].key) {
      //all
      let currentValue: boolean = newLocalfilterList[0].value2;
      for (let i: number = 0; i <= newLocalfilterList.length - 1; i++) {
        if (!currentValue) {
          newLocalfilterList[i].value2 = true;
        } else {
          newLocalfilterList[i].value2 = false;
        }
      }
    } else {
      //other elements different than all

      for (let i: number = 0; i <= newLocalfilterList.length - 1; i++) {
        if (newLocalfilterList[i].key === item.key) {
          if (newLocalfilterList[i].value2) {
            isUnselectingElements = true;
          }
          newLocalfilterList[i].value2 = !newLocalfilterList[i].value2;
          break;
        }
      }
      if (isUnselectingElements) {
        newLocalfilterList[0].value2 = false;
      }
      for (let i: number = 1; i <= newLocalfilterList.length - 1; i++) {
        if (!newLocalfilterList[i].value2) {
          isAllSelected = false;
          break;
        }
      }
      if (isAllSelected) {
        newLocalfilterList[0].value2 = true;
      }
    }

    setLocalFilterList(newLocalfilterList);
  };

  const handleApplyOnClick = () => {
    let filterToReturn: Array<TPKeyValue> = [...localfilterList];
    onFilterChange(true, filterToReturn);
  };

  const renderComponent = () => {
    let jsxElement: any;
    if (localfilterList && localfilterList.length >= 1) {
      jsxElement = (
        <>
          <div
            id={id}
            className={classModal}
            tabIndex={-1}
            data-bs-backdrop="static"
            style={styleModal}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header justify-content-between">
                  <h5 className="modal-title">{title}</h5>
                  <TPButton
                    type={TPButtonTypes.empty}
                    onClick={() => onFilterChange(false, null)}
                    className={"text-end"}
                  >
                    X
                  </TPButton>
                </div>
                <div className="modal-body">
                  <div
                    className="container-fluid"
                    style={{ minHeight: "300px" }}
                  >
                    <div className="row">
                      <div className="col"></div>
                    </div>
                    <div className="row">
                      <div
                        className="col"
                        style={{ overflow: "auto", maxHeight: "250px" }}
                      >
                        <div>
                          {localfilterList.map(function (
                            item: TPKeyValue,
                            index,
                          ) {
                            let jsxElement: any;
                            jsxElement = (
                              <div
                                className="row"
                                key={"li" + index.toString()}
                              >
                                <div className="col-10">
                                  <div
                                    className="form-group"
                                    style={{ padding: "5px" }}
                                  >
                                    <TPCheckBox
                                      id="IdCheckBox"
                                      checked={item.value2}
                                      labelText={item.value}
                                      onChange={() =>
                                        handleToggleCheckbox(item)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                            return jsxElement;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => handleApplyOnClick()}
                  >
                    <span>{applyLabel}</span>
                  </TPButton>
                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => onFilterChange(false, null)}
                  >
                    <span>{cancelLabel}</span>
                  </TPButton>
                </div>
              </div>
            </div>
          </div>

          <div className={backdropClass}></div>
        </>
      );
      return jsxElement;
    }
    jsxElement = <></>;
    return jsxElement;
  };

  //run when filterList change
  useEffect(() => {
    setLocalFilterList([...filterList]);
  }, [filterList]);

  //Run only once
  useEffect(() => {
    loadResources();
  }, []);

  return renderComponent();
};

export default TPFilterSelector;
