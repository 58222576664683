import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import {
  EmailTemplateInputDTO,
  EmailTemplateAdminDetailInputDTO,
  CloneEmailTemplateInputDTO,
} from "@/models/EmailTemplates/EmailTemplateInputDTO";
import { EmailTemplateViewModel } from "@/models/EmailTemplates/EmailTemplateModels";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

export class EmailTemplateService {
  serviceFileName: string = "EmailTemplateService.ts";

  public async getEmailTemplateFilterByLanguage(
    filterByLanguage: string,
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<EmailTemplateViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplate?languageId=${filterByLanguage}&FilterIsActive=${filterActive}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getEmailTemplateByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getEmailTemplateByFilter`);
      throw new Error(`Error ${this.serviceFileName} getEmailTemplateByFilter`);
    }
  }

  public async getLanguagesOption(
    filterType: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<EmailTemplateViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string = "/languages?FilterType=" + filterType;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getLanguagesByFilterType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getLanguagesByFilterType`);
      throw new Error(`Error ${this.serviceFileName} getLanguagesByFilterType`);
    }
  }

  public async deleteEmailTemplateById(
    emailTemplateId: string,
    languageId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    // url = "/emailtemplate/" + emailTemplateId;
    url = `/emailtemplate/${emailTemplateId}/language/${languageId}`;
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteEmailTemplateById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteEmailTemplateById`);
      throw new Error(`Error ${this.serviceFileName} deleteEmailTemplateById`);
    }
  }

  public async insertEmailTemplate(
    inputDTO: EmailTemplateInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/emailtemplate";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} insertEmailTemplate`);
    }
  }

  public async updateEmailTemplate(
    inputDTO: EmailTemplateInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/emailtemplate";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} updateEmailTemplate`);
    }
  }

  public async cloneEmailTemplate(
    inputDTO: CloneEmailTemplateInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/emailtemplate/clone`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        url,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      if (!showNegativeMessage && !response.responseResult) {
        if (
          response.responseData &&
          response.responseData.responseMessage.code > 0
        ) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.error,
          );
        } else {
          showToast(TPGlobal.TPGeneralHttpException, TPToastTypes.error);
        }
      }
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} updateEmailTemplate`);
    }
  }

  public async getEmailTemplateById(
    emailTemplateId: string,
    languageId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<EmailTemplateViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplate/${emailTemplateId}/language/${languageId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getProfileById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getProfileById`);
      throw new Error(`Error ${this.serviceFileName} getProfileById`);
    }
  }

  public async getEmailTemplateByTemplateId(
    emailTemplateId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<EmailTemplateViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplate/${emailTemplateId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getProfileById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getProfileById`);
      throw new Error(`Error ${this.serviceFileName} getProfileById`);
    }
  }

  // For Language Tamplate
  public async getAllLanguagesByTemplateId(
    templateId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<string>> {
    let httpclient = new TPHTTPService();
    let url: string = `/emailtemplatelanguage?templateId=${templateId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAllLanguagesByTemplateId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getAllLanguagesByTemplateId`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getAllLanguagesByTemplateId`,
      );
    }
  }

  public async insertLanguageForTemplate(
    inputDTO: EmailTemplateAdminDetailInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/emailtemplatelanguage";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} updateEmailTemplate`);
    }
  }

  public async updateLanguageForTemplate(
    inputDTO: EmailTemplateAdminDetailInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/emailtemplatelanguage";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} updateEmailTemplate`);
    }
  }

  public async getLanguageDetailByTemplateId(
    emailTemplateId: string,
    languageId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<EmailTemplateAdminDetailInputDTO> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplatelanguage/${emailTemplateId}/${languageId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getProfileById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getProfileById`);
      throw new Error(`Error ${this.serviceFileName} getProfileById`);
    }
  }

  // Services for tag feature
  public async getTagGroups(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = `/parameters/get-tags-groups?ambit=EMTE`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTagGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTagGroups`);
      throw new Error(`Error ${this.serviceFileName} getTagGroups`);
    }
  }

  public async getTagsByGroup(
    groupCode: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = `/parameters/get-tags-by-group-ambit/${groupCode}?ambit=EMTE`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTagsByGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTagsByGroup`);
      throw new Error(`Error ${this.serviceFileName} getTagsByGroup`);
    }
  }

  public async getAdditionalData(
    categoryId: string,
    words: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = `/additionaldata/get-search?categoryId=${categoryId}&words=${words}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAdditionalData`);
      throw new Error(`Error ${this.serviceFileName} getAdditionalData`);
    }
  }
}
