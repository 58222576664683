/**
 * workflow definition import enum
 */
export enum WorkflowDefinitionImportEnum {
    /**
     * key word
     */
    WorkflowDefinitionImport = "WorkflowDefinitionImport",
    /**
     * file name component
     */
    WorkflowDefinitionImportFilename = "WorkflowDefinitionImport.tsx",
    /**
     * component name
     */
    WorkflowDefinitionImportComponent = "WorkflowDefinitionImportComponent",

    /**
     * COMPONENT LABELS
     */
    /**
     * title label
     */
    TitleLabel = "TitleLabel",
    /**
     * information section title label
     */
    InfoSectionTitleLabel = "InfoSectionTitleLabel",
    /**
     * information section description label
     */
    InfoSectionDescriptionLabel = "InfoSectionDescriptionLabel",
    /**
     * form information label
     */
    FormInformationLabel = "FormInformationLabel",
    /**
     * details information label
     */
    DetailsInformationLabel = "DetailsInformationLabel",
    /**
     * table column description label
     */
    TableColumnDescriptionLabel = "TableColumnDescriptionLabel",
    /**
     * table column promise limit unit label
     */
    TableColumnPromiseLimitUnitLabel = "TableColumnPromiseLimitUnitLabel",
    /**
     * table column promised limit label
     */
    TableColumnPromiseLimitLabel = "TableColumnPromiseLimitLabel",
    /**
     * table column direct solution label
     */
    TableColumnDirectSolutionLabel = "TableColumnDirectSolutionLabel",
    /**
     * table column active label
     */
    TableColumnActiveLabel = "TableColumnActiveLabel",
    /**
     * table detail column order label
     */
    TableDetailColumnOrderLabel = "TableDetailColumnOrderLabel",
    /**
     * table detail column task type label
     */
    TableDetailColumnTaskTypeLabel = "TableDetailColumnTaskTypeLabel",
    /**
     * table detail column responsible label
     */
    TableDetailColumnResponsibleLabel = "TableDetailColumnResponsibleLabel",
    /**
     * table detail column reader function label
     */
    TableDetailColumnReaderLabel = "TableDetailColumnReaderLabel",
    /**
     * table detail column term label
     */
    TableDetailColumnTermLabel = "TableDetailColumnTermLabel",
    /**
     * table detail column predecessor label
     */
    TableDetailColumnPredecessorLabel = "TableDetailColumnPredecessorLabel",
    /**
     * table detail column all pr label
     */
    TableDetailColumnRequiresAllPredecessorsLabel = "TableDetailColumnRequiresAllPredecessorsLabel",
    /**
     * table detail column customer promised label
     */
    TableDetailColumnClosesCustomerLabel = "TableDetailColumnClosesCustomerLabel",
    /**
     * table detail column determine label
     */
    TableDetailColumnDefinesResponsibleLabel = "TableDetailColumnDefinesResponsibleLabel",
    /**
     * table detail column determine label
     */
    TableDetailColumnIsActiveLabel = "TableDetailColumnIsActiveLabel",
    /**
     * table sub column yes or no label
     */
    TableSubColumnYesNoLabel = "YesOrNoLabel",
    /**
     * table sub column yes or no label
     */
    TableSubColumnNumberLabel = "NumberLabel",
    /**
     * title label
     */
    ActionSectionCancelButton = "CancelButton",
    /**
     * title label
     */
    ActionSectionSaveButton = "SaveButton",
    /**
     * notification description field is a value required
     */
    NotificationDescriptionIsRequiredMessage = "NotificationDescriptionIsRequiredMessage",
    /**
     * notification promise limit field is a invalid value
     */
    NotificationPromiseLimitUnitIsInvalidMessage = "NotificationPromiseLimitUnitIsInvalidMessage",
    /**
     * notification promise limit field is a invalid value
     */
    NotificationPromiseLimitIsInvalidMessage = "NotificationPromiseLimitIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationIsDirectSolutionIsInvalidMessage = "NotificationIsDirectSolutionIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationIsActiveIsInvalidMessage = "NotificationIsActiveIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailOrderIsRequiredMessage = "NotificationDetailOrderIsRequiredMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailTaskTypeIsRequiredMessage = "NotificationDetailTaskTypeIsRequiredMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailTermIsRequiredMessage = "NotificationDetailTermIsRequiredMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailPredecessorIsRequiredMessage = "NotificationDetailPredecessorIsRequiredMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailResponsibleIsRequiredMessage = "NotificationDetailResponsibleIsRequiredMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailOrderIsInvalidMessage = "NotificationDetailOrderIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailTaskTypeIsInvalidMessage = "NotificationDetailTaskTypeIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailTermIsInvalidMessage = "NotificationDetailTermIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailPredecessorIsInvalidMessage = "NotificationDetailPredecessorIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailDefinesResponsibleIsInvalidMessage = "NotificationDetailDefinesResponsibleIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailClosesCustomerIsInvalidMessage = "NotificationDetailClosesCustomerIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailRequiresAllPredecessorsIsInvalidMessage = "NotificationDetailRequiresAllPredecessorsIsInvalidMessage",
    /**
     * notification  field is a invalid value
     */
    NotificationDetailActiveIsInvalidMessage = "NotificationDetailActiveIsInvalidMessage",
    /**
     * COMPONENT LABELS END
     */
}

/**
 * language model
 */
export interface LanguageModel {
    /**
     * language identifier
     */
    languageId: string;
    /**
     * value
     */
    localizedValue: string;
    /**
     * priority
     */
    order: number;
}

/**
 * workflow definition export model
 */
export interface WorkflowDefinitionExportModel {
    /**
     * identifier
     */
    id: string;
    /**
     * description
     */
    description: string;
    /**
     * descriptions
     */
    descriptions: Array<LanguageModel>;
    /**
     * promiseLimitUnit
     */
    promiseLimitUnit: string;
    /**
     * promiseLimit
     */
    promiseLimit: number;
    /**
     * isDirectSolution
     */
    isDirectSolution: boolean;
    /**
     * isActive
     */
    isActive: boolean;
    /**
     * isSystemRecord
     */
    isSystemRecord: boolean;
    /**
     * hasDetails
     */
    hasDetails: boolean;
    /**
     * isCasesUsed
     */
    isCasesUsed: boolean;
    /**
     * workflowDetails
     */
    workflowDetails: Array<WorkflowDefinitionDetailExportModel>;
}

/**
 * workflow definition import model
 */
export interface WorkflowDefinitionImportModel {
    /**
     * id
     */
    id: string;
    /**
     * description
     */
    description: string;
    /**
     * descriptions
     */
    descriptions: Array<LanguageModel>;
    /**
     * promiseLimitUnit
     */
    promiseLimitUnit: string;
    /**
     * promiseLimit
     */
    promiseLimit: number;
    /**
     * isDirectSolution
     */
    isDirectSolution: boolean;
    /**
     * isActive
     */
    isActive: boolean;
    /**
     * details
     */
    details: Array<WorkflowDefinitionDetailImportModel>
}

/**
 * workflow definition det
 */
export interface WorkflowDefinitionDetailExportModel {
    /**
     * identifier
     */
    id: string;
    /**
     * workflowTypeId
     */
    workflowTypeId: string;
    /**
     * writerId
     */
    writerId: string;
    /**
     * writerDescription
     */
    writerDescription: string;
    /**
     * readerId
     */
    readerId: string;
    /**
     * readerDescription
     */
    readerDescription: string;
    /**
     * taskTypeId
     */
    taskTypeId: string;
    /**
     * taskDescription
     */
    taskDescription: string;
    /**
     * term
     */
    term: number;
    /**
     * termUnit
     */
    termUnit: string;
    /**
     * order
     */
    order: number;
    /**
     * isActive
     */
    isActive: boolean;
    /**
     * closesCustomer
     */
    closesCustomer: boolean;
    /**
     * definesResponsible
     */
    definesResponsible: boolean;
    /**
     * requiresAllPredecessors
     */
    requiresAllPredecessors: boolean;
    /**
     * isSystemRecord
     */
    isSystemRecord: boolean;
}

/**
 * workflow definition detail import model
 */
export interface WorkflowDefinitionDetailImportModel {
    /**
     * id
     */
    id: number;
    /**
     * workflowTypeId
     */
    workflowTypeId: string;
    /**
     * writerId
     */
    writerId: string;
    /**
     * readerId
     */
    readerId: string;
    /**
     * taskTypeId
     */
    taskTypeId: string;
    /**
     * term
     */
    term: number;
    /**
     * termUnit
     */
    termUnit: string;
    /**
     * order
     */
    order: number;
    /**
     * isActive
     */
    isActive: boolean;
    /**
     * closesCustomer
     */
    closesCustomer: boolean;
    /**
     * definesResponsible
     */
    definesResponsible: boolean;
    /**
     * requiresAllPredecessors
     */
    requiresAllPredecessors: boolean;
}
