import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import {
  FC,
  ReactElement,
  useEffect,
  useReducer,
  useState,
} from "react";
import FormDesignerAdmin from "./FormDesignerAdmin";
import FormDesignerClone from "./FormDesignerClone";


type WorkflowTypeContainerCloneProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: any;
};

enum asideMenuItemTypes {
  "general" = 0,
  "details" = 1,
}

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum commandsEnum {
  "create_general" = 0,
  "create_details_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_details" = 3,
  "clone" = 4
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const CustomSpan: React.FC<React.HTMLProps<HTMLSpanElement>> = (props) => {
  return <span {...props} />;
};

const FormDesignerContainerClone: FC<WorkflowTypeContainerCloneProps> =
  ({ mode, recordId, callBackResult, tabId }): ReactElement => {
    //control state
    const ResourceSet: string = "FormDesignerCloneComponent";
    const ResourceSetGeneral: string = "FormDesignerContainerInsertUpdate";
    const [isReady, setIsReady] = useState(false);
    const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
    const [titleDetails, setTitleDetails] = useState("");
    const [activeDetail, setActiveDetail] = useState(false);
    const [exitLabel, setExitLabel] = useState("Exit");
    const [realRecordId, setRealRecordId] = useState(recordId);

    const [realRecordDescription, setRealRecordDescription] = useState("");

    const handleDescriptionFetched = (description: string) => {
      setRealRecordDescription(description);
    };

    const loadResources = async () => {
      setTitleGeneralInformation(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "GeneralInformationTitle"
        )
      );
      setTitleDetails(await TPI18N.GetText(ResourceSetGeneral, "DetailsTitle"));
      setExitLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel")
      );
      //flag isReady
      setIsReady(true);
    };

    //handler to receive commands from child tabs
    const handleCallBackCommands = (theCommand: any) => {
      if (theCommand.result === "clone_general_insert_and_change_mode") {

        let language = TPGlobal.language;
        callBackResult({
          result: "ChangeCloneToUpdateMode",
          recordId: theCommand.payload,
          tabId: tabId,
          language: language
        });

        onDetailsAsideItemClick();
        return;
      }

      if (theCommand.result === "cancel_general") {
        callBackResult({ result: "CANCEL", recordId: theCommand.recordId });
        return;
      }
    };

    const onGeneralAsideItemClick = () => {
      let currentActive: asideMenuItemTypes;
      if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
        currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
          .itemType;
        if (currentActive === asideMenuItemTypes.general) {
          //already on general
          return;
        }
      }
      let command: commandType = {
        type: commandsEnum.set_active_general,
        payload: null,
      };
      dispatchCommand(command);
      return;
    };

    const onDetailsAsideItemClick = () => {
      let command: commandType = {
        type: commandsEnum.set_active_details,
        payload: null,
      };
      dispatchCommand(command);
    };

    const handleOnExitClick = () => {
      callBackResult({ result: "CANCEL", recordId: realRecordId });
    };

    //create home content
    const realCreateGeneralContentCommand = (
      prevState: Array<asideMenuItemsModel>
    ) => {
      let newStateHome: Array<asideMenuItemsModel> = [...prevState];
      let verticalElement: asideMenuItemsModel = {
        itemLabel: titleGeneralInformation,
        itemType: asideMenuItemTypes.general,
        isActive: true,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onGeneralAsideItemClick,
      };

      newStateHome.push(verticalElement);
      if (mode == "Update") {
        let verticalElement2: asideMenuItemsModel = {
          itemLabel: titleDetails,
          itemType: asideMenuItemTypes.details,
          isActive: false,
          isVisible: true,
          isEnabled: true,
          onClickHandler: onDetailsAsideItemClick,
        };
        newStateHome.push(verticalElement2);
      }
      return newStateHome;
    };

    //create details content
    const realCreateDetailsContentCommandAndChangeMode = (
      prevState: Array<asideMenuItemsModel>
    ) => {
      let newStateHome: Array<asideMenuItemsModel> = [...prevState];
      let i: number;
      for (i = 0; i <= newStateHome.length - 1; i++) {
        newStateHome[i].isActive = false;
      }

      let verticalElement: asideMenuItemsModel = {
        itemLabel: titleDetails,
        itemType: asideMenuItemTypes.details,
        isActive: true,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement);
      return newStateHome;
    };

    //set active item
    const setActiveContent = (
      prevState: Array<asideMenuItemsModel>,
      itemType: asideMenuItemTypes
    ) => {
      let newStateHome: Array<asideMenuItemsModel> = [...prevState];
      let i: number;
      for (i = 0; i <= newStateHome.length - 1; i++) {
        newStateHome[i].isActive = false;
        if (itemType == newStateHome[i].itemType) {
          newStateHome[i].isActive = true;
        }
      }
      return newStateHome;
    };

    //aside menu state with reducer
    let initialState: Array<asideMenuItemsModel> = [];
    const [asideMenuState, dispatchCommand] = useReducer(
      doCommand,
      initialState
    );

    function doCommand(
      prevState: Array<asideMenuItemsModel>,
      command: commandType
    ) {
      switch (command.type) {
        case commandsEnum.create_general:
          return realCreateGeneralContentCommand(prevState);
        case commandsEnum.clone:
          return realCreateGeneralContentCommand(prevState);
        case commandsEnum.create_details_and_change_mode:
          let newRecordId: string;
          newRecordId = command.payload.recordId;
          setRealRecordId(newRecordId);
          return realCreateDetailsContentCommandAndChangeMode(prevState);
        case commandsEnum.set_active_general:
          return setActiveContent(prevState, asideMenuItemTypes.general);
        case commandsEnum.set_active_details:
          return setActiveContent(prevState, asideMenuItemTypes.details);
      }
    }

    //run once to include functionsAdmin in first tab
    useEffect(() => {
      //load resources      
      if (!isReady) {
        loadResources();
      } else {
        let command: commandType = {
          type: commandsEnum.clone,
          payload: null,
        };
        dispatchCommand(command);
      }
    }, [isReady]);

    return (
      <>
        <div className="row">
          <div className="col-10">

            <ul>
              {asideMenuState.map(function (item, index) {
                let jsxFragment: any;
                let visibilityStyle: any;
                if (item.isActive) {
                  visibilityStyle = { display: "block" };
                } else {
                  visibilityStyle = { display: "none" };
                }
                switch (item.itemType) {
                  case asideMenuItemTypes.general:
                    jsxFragment = (
                      <li key={"asidecontent" + index.toString()}>
                        <div style={visibilityStyle}>
                          <FormDesignerClone
                            callBackResult={handleCallBackCommands}
                            mode={mode}
                            recordId={realRecordId}
                          />
                        </div>
                      </li>
                    );
                    break;
                  case asideMenuItemTypes.details:
                    jsxFragment = (
                      <li key={"asidecontent" + index.toString()}>
                        <div style={visibilityStyle}>
                          <FormDesignerAdmin
                            // workflowTypeId={realRecordId}
                            // recordDescription={realRecordDescription}
                            callBackCommands={handleCallBackCommands}
                          ></FormDesignerAdmin>
                        </div>
                      </li>
                    );

                    break;
                  default:
                    jsxFragment = (
                      <li key={"asidecontent" + index.toString()}>
                        <div style={visibilityStyle}>
                          <CustomSpan>error this should not be here</CustomSpan>
                        </div>
                      </li>
                    );
                    break;
                }
                return jsxFragment;
              })}
            </ul>
          </div>
          <RightVerticalMenuContainer className="col-2 mt-4">
            <RightVerticalMenuWrapper>
              <RightVerticalMenuUl>
                {asideMenuState.map(function (item, index) {
                  //render aside menu
                  let jsxFragment: any;
                  let visibilityStyle: any;
                  if (item.isVisible) {
                    visibilityStyle = {
                      display: "block",
                      borderBottom: "1px solid #e8e8e8",
                    };
                  } else {
                    visibilityStyle = {
                      display: "none",
                      borderBottom: "1px solid #e8e8e8",
                    };
                  }
                  switch (item.itemType) {
                    case asideMenuItemTypes.general:
                      jsxFragment = (
                        <RightVerticalMenuLi
                          className={item.isActive ? "active" : ""}
                          key={"asidemenuitem" + index.toString()}
                        >
                          <div style={visibilityStyle}>
                            <CustomSpan onClick={() => item.onClickHandler()}>
                              {item.itemLabel}
                            </CustomSpan>
                          </div>
                        </RightVerticalMenuLi>
                      );
                      break;
                    case asideMenuItemTypes.details:
                      jsxFragment = (
                        <RightVerticalMenuLi
                          className={item.isActive ? "active" : ""}
                          key={"asidemenuitem" + index.toString()}
                        >
                          <div style={visibilityStyle}>
                            <CustomSpan onClick={() => item.onClickHandler()}>
                              {item.itemLabel}
                            </CustomSpan>
                          </div>
                        </RightVerticalMenuLi>
                      );
                      break;
                    default:
                      jsxFragment = (
                        <RightVerticalMenuLi
                          className={item.isActive ? "active" : ""}
                          key={"asidemenuitem" + index.toString()}
                        >
                          <div style={visibilityStyle}>
                            <CustomSpan>error this should not be here</CustomSpan>
                          </div>
                        </RightVerticalMenuLi>
                      );
                      break;
                  }
                  return jsxFragment;
                })}
                <RightVerticalMenuLi
                  key={"asidemenuitem" + asideMenuState.length.toString()}
                  style={{ marginTop: "30px" }}
                ></RightVerticalMenuLi>
                <RightVerticalMenuLi
                  key={"asidemenuitem" + (asideMenuState.length + 1).toString()}
                >
                  <CustomSpan onClick={() => handleOnExitClick()}>{exitLabel}</CustomSpan>
                </RightVerticalMenuLi>
              </RightVerticalMenuUl>
            </RightVerticalMenuWrapper>
          </RightVerticalMenuContainer>
        </div>
      </>
    );
  };

export default FormDesignerContainerClone;
