import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { RoleUserViewModel } from "@/models/Roles/RolesViewModel";

export class RoleUserService {
  serviceFileName: string = "RoleUserService.ts";
  public getRoleUserByRoleId = async (
    roleId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<RoleUserViewModel>> => {
    let httpclient = new TPHTTPService();
    let url: string = `roleuser/assigned-unassigned/role/${roleId}`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRolesByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getRolesByFilter`);
      throw new Error(`Error ${this.serviceFileName} getRolesByFilter`);
    }
  };

  public deleteUser = async (
    roleId: string,
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> => {
    let httpclient = new TPHTTPService();
    let url: string = `roleuser/role/${roleId}/user/${guidUser}`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteUser`);
      throw new Error(`Error ${this.serviceFileName} deleteUser`);
    }
  };

  public assignUser = async (
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> => {
    let httpclient = new TPHTTPService();
    let url: string = `roleuser`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} assignUser`);
      throw new Error(`Error ${this.serviceFileName} assignUser`);
    }
  };
}
