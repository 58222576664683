import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { FC, useState } from "react";

interface ScriptDateLogicItemProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
}

export const ScriptDateLogicItem: FC<ScriptDateLogicItemProperties> = function ({ availableNextSteps, logic, onLogicAdd }) {
  const [date, setDate] = useState<Date | null>(null);
  const [nextStep, setNextStep] = useState(availableNextSteps.find(a => a.order == -1)?.id || "");
  return (
    <>
      <TPDatePicker
        isMandatory={false}
        labelText=""
        onChangeDate={(d: Date) => setDate(d)}
        selectedDate={date}
        containerStyle={{ width: '100%' }}
      />
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => {
          if (date) {
            onLogicAdd({
              key_SSDA: date.toDateString(),
              nextStepId: nextStep,
              value: date.toDateString()
            })
          }
        }}
        isDesignSystem
        style={{
          width: 'min-content',
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={date == null}
      >
        _add_logic
      </TPButton>
    </>
  )
}