import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { FunctionsInputDTO } from "@/models/Functions/FunctionsInputDTO";
import {
  CreateReminderModel,
  ReminderModel,
  ReminderResponse,
  UpdateReminderModel,
} from "@/models/MyReminders/MyReminders";

export class RemindersService {
  private static serviceFileName: string = "RemindersService.ts";
  public static async getByUserId(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<ReminderModel>> {
    let httpclient = new TPHTTPService();
    let finalURL: string = String(TPGlobal.baseAPIGateway).concat(
      "api/tenant/",
      TPGlobal.tenantHex,
      "/cases/reminders?userId=",
      id
    );
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionsByFilter ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionsByFilter`);
      throw new Error(`Error ${this.serviceFileName} getFunctionsByFilter`);
    }
  }

  public static async getRemindersCount(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reminders?userId=";
    url = url + TPGlobal.currentUserGuid;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );

      if (response.responseData.data[0]) {
        return response.responseData.data[0].totalRecords;
      }

      return 0;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionById`);
      throw new Error(`Error ${this.serviceFileName} getFunctionById`);
    }
  }

  public static async getReminderById(
    reminderId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ReminderResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder",
      String(reminderId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getReminderById exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to get a reminder: ${error}`
      );
    }
  }

  public static async getReminderByTaskId(
    taskId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ReminderResponse> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder/by-task/",
      String(taskId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getReminderByTaskId exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to get a reminder by task id: ${error}`
      );
    }
  }

  public static async getRemindersByCaseId(
    caseId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ReminderResponse[]> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder/by-case/",
      String(caseId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getReminderByCaseId exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to get a reminder by case id: ${error}`
      );
    }
  }

  public static async createReminder(
    requestBody: CreateReminderModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder"
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} createReminder exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to create a reminder: ${error}`
      );
    }
  }

  public static async updateReminder(
    requestBody: UpdateReminderModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder"
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateReminder exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to update a reminder: ${error}`
      );
    }
  }

  public static async setReminderAsSent(
    reminderId: number,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder",
      "/change-status-sent"
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      await httpClientInstance.putData(
        url,
        { id: reminderId },
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} setReminderAsSent exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to set reminder as sent: ${error}`
      );
    }
  }

  public static async deleteReminder(
    reminderId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String("").concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/taskreminder/",
      String(reminderId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response = await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteReminder exception`,
        TPLogType.ERROR,
        error
      );
      console.error(error);
      throw new Error(
        `Unexpected error while trying to delete a reminder: ${error}`
      );
    }
  }
}
