import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { AttachmentType } from "@/models/Global/TPGlobalEnums";
import {
  GetFileUriSasViewModel,
  GetFileViewModel,
} from "@/models/Storage/StorageViewModel";

export class StorageService {
  serviceFileName: string = "StorageService.ts";

  public async getFileById(
    blobId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GetFileViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/storage/file/";
    url = url + blobId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: any; //todo bad endpoint (must be fixed to standard)
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFileById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getFileById`);
      throw new Error(`Error ${this.serviceFileName} getFileById`);
    }
  }

  public async getFileUriSas(
    blobId: string,
    attachmentType: AttachmentType,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GetFileUriSasViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/storage/file-sas/${blobId}?attachmentType=${attachmentType}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getStorageFileUriSas ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getStorageFileUriSas`);
      throw new Error(`Error ${this.serviceFileName} getStorageFileUriSas`);
    }
  }
}
