import { ReactNode, createContext, useContext, useEffect } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { useStatusCaseCreationStats } from "./useStatusCaseCreationStatst";



type AppProviderDistributionListAdmin = {
    children: ReactNode;
};

class model {
    status: Array<any> = new Array<any>;
    resourceSet: string = "";
    loading: boolean = false;
    setLoading: (data: boolean) => void = (data: boolean) => void {};
}

type modelContext = {
    status: Array<any>;
    resourceSet: string;
    loading: boolean,
    setLoading: (data: boolean) => void;
}

export const CaseCreationStatsContext = createContext<modelContext>(
    new model());

export const AppProviderCaseCreationStats: React.FC<AppProviderDistributionListAdmin> = ({ children }) => {

    const {
        status,
        resourceSet,
        loading,
        setLoading
    } = useStatusCaseCreationStats();


    return (       
            <CaseCreationStatsContext.Provider value={{
                status,
                resourceSet,
                loading,
                setLoading
            }}>
                {children}
            </CaseCreationStatsContext.Provider>       
    );
};


export const useAppDistributionListAdmin = () => {
    return useContext(CaseCreationStatsContext);
}