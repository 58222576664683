import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";

import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";

import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions } from "@/models/Global/TPGlobalEnums";

import { UserViewModel } from "@/models/Users/UserModels";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { UserService } from "@/services/UserService";
import moment from "moment";
import React, {
  FC,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import {
  AddTaskMasiveInputDTO,
  AddTaskMasiveInputDTOValidator,
} from "@/models/Cases/CaseMassUpdateInputDTO";
import { CaseMassUpdateService } from "@/services/CaseMassUpdateService";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";

export type AddTaskFormState = {
  isShown: boolean;
  callBackData: any;
};

enum commandsAddTaskFormEnum {
  "clear_state" = 0,
  "change_value_property" = 1,
  "set_errors" = 2,
}

type commandType = {
  type: commandsAddTaskFormEnum;
  payload: any;
};

interface screenStateProps {
  taskTypeId: string;
  quantity: number;
  assignTo: string;
  assignToMe: boolean;
  termType: string;
  term: number;
  termUnit: string;
  dueDate: Date | null;
  dueDateHour: string;
  dueDateMinutes: string;
  comments: string;
  reminderDate: Date | null;
  reminderDateHour: string;
  reminderDateMinutes: string;
  reminderComments: string;

  taskTypeIdErrorMessage: string;
  quantityErrorMessage: string;
  assignToErrorMessage: string;
  dueDateErrorMessage: string;
  reminderDateErrorMessage: string;
  commentsErrorMessage: string;
  reminderCommentsErrorMessage: string;
  termErrorMessage: string;
  termUnitErrorMessage: string;
  [key: string]: any;
}

interface IAddTaskMassiveProps {
  CaseListToAddTask: Array<number>;
  callBackSaveSuccess: Function;
}

interface IAnswerEndpointData {
  caseId: string;
  taskAdded: boolean;
  message: string;
}

const AddTaskForm = React.forwardRef(
  ({ CaseListToAddTask, callBackSaveSuccess }: IAddTaskMassiveProps, ref) => {
    const componentFileName: string = "AddTaskForm.tsx";
    const resourceSet: string = "AddTaskFormComponent";
    const resourceSetTPModal: string = "TPModalAddTaskComponent";
    //Screen Resources
    const [titleLabel, setTitleLabel] = useState("");
    const [taskTypeLabel, setTaskTypeLabel] = useState("");
    const [quantityLabel, setQuantityLabel] = useState("");
    const [assignToLabel, setAssignToLabel] = useState("");
    const [currentUserLabel, setCurrentUserLabel] = useState("");
    const [assignToMeLabel, setAssignToMeLabel] = useState("");
    const [termItemLabel, setTermItemLabel] = useState("");
    const [dueDateItemLabel, setDueDateItemLabel] = useState("");
    const [commentsLabel, setCommentsLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");
    const [timeUnitLabel, setTimeUnitLabel] = useState("");
    const [timeLabel, setTimeLabel] = useState("");
    const [dateLabel, setDateLabel] = useState("");
    const [hoursLabel, setHoursLabel] = useState("");
    const [minutesLabel, setMinutesLabel] = useState("");
    const [addReminderLabel, setAddReminderLabel] = useState("");
    const [commentsReminderLabel, setCommentsReminderLabel] = useState("");
    const [showReminderOption, setShowReminderOption] = useState(false);

    const [modalSuccessTitle, setModalSuccessTitle] = useState("");
    const [selectedRowsLabel, setSelectedRowsLabel] = useState("");
    const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
    const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] = useState("");

    const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");
    const [modalConfirmTitle, setModalConfirmTitle] = useState("");

    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [isReminder, setIsReminder] = useState(false);

    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [modalSuccess, setModalSuccess] = useState(false);

    const [autocompleteTaskTypeOptions, setAutocompleteTaskTypeOptions] =
      useState<Array<TPKeyValue>>([]);

    const [
      selectedAutocompleteTaskTypeOption,
      setSelectedAutocompleteTaskTypeOption,
    ] = useState<Array<TPKeyValue>>([]);

    //top n options for arrow icon
    const [
      autocompleteTaskTypeTopNOptions,
      setAutocompleteTaskTypeTopNOptions,
    ] = useState<Array<TPKeyValue>>([]);

    //User assign to
    const [autocompleteUserAssignOptions, setAutocompleteUserAssignOptions] =
      useState<Array<TPKeyValue>>([]);

    const [
      selectedAutocompleteUserAssignOption,
      setSelectedAutocompleteUserAssignOption,
    ] = useState<Array<TPKeyValue>>([{ key: "", value: "", value2: "" }]);

    //top n options for arrow icon
    const [
      autocompleteUserAssignTopNOptions,
      setAutocompleteUserAssignTopNOptions,
    ] = useState<Array<TPKeyValue>>([]);

    const [quantityOptions, setQuantityOptions] = useState<Array<TPKeyValue>>(
      [],
    );
    const [dueTermHoursOptions, setDueTermHoursOptions] = useState<
      Array<TPKeyValue>
    >([]);
    const [dueTermMinutesOptions, setDueTermMinutesOptions] = useState<
      Array<TPKeyValue>
    >([]);

    const [unitTermList, setUnitTermListState] = useState<Array<TPKeyValue>>(
      [],
    );

    const [answerData, setAnswerData] = useState<Array<IAnswerEndpointData>>(
      [],
    );

    useImperativeHandle(ref, () => ({
      async addMassiveTasks() {
        let bolR: boolean = await internalValidation();
        return bolR;
      },
    }));

    const loadResourcesAndTaskType = async () => {
      setModalSuccessTitle(
        await TPI18N.GetText(resourceSet, "ModalSuccessTitle"),
      );
      setSelectedRowsLabel(
        await TPI18N.GetText(resourceSet, "SelectedRowsLabel"),
      );
      setRecordsProcessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsProcessedLabel"),
      );
      setRecordsUnprocessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsUnprocessedLabel"),
      );
      setModalConfirmQuestion(
        await TPI18N.GetText(resourceSet, "RecordModalConfirmQuestion"),
      );
      setModalConfirmTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setCancelButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setOkButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setTitleLabel(await TPI18N.GetText(resourceSetTPModal, "TitleLabel"));
      setTaskTypeLabel(
        await TPI18N.GetText(resourceSetTPModal, "TaskTypeLabel"),
      );
      setQuantityLabel(
        await TPI18N.GetText(resourceSetTPModal, "QuantityLabel"),
      );
      setAssignToLabel(
        await TPI18N.GetText(resourceSetTPModal, "AssignToLabel"),
      );
      setAssignToMeLabel(
        await TPI18N.GetText(resourceSetTPModal, "AssignToMeLabel"),
      );
      setTermItemLabel(
        await TPI18N.GetText(resourceSetTPModal, "TermItemLabel"),
      );
      setDueDateItemLabel(
        await TPI18N.GetText(resourceSetTPModal, "DueDateItemLabel"),
      );
      setCommentsLabel(
        await TPI18N.GetText(resourceSetTPModal, "CommentsLabel"),
      );
      setEmptyLabel(await TPI18N.GetText(resourceSetTPModal, "EmptyLabel"));
      setTimeUnitLabel(
        await TPI18N.GetText(resourceSetTPModal, "TimeUnitLabel"),
      );
      setTimeLabel(await TPI18N.GetText(resourceSetTPModal, "TimeLabel"));
      setDateLabel(await TPI18N.GetText(resourceSetTPModal, "DateLabel"));
      setHoursLabel(await TPI18N.GetText(resourceSetTPModal, "HoursLabel"));
      setMinutesLabel(await TPI18N.GetText(resourceSetTPModal, "MinutesLabel"));
      setAddReminderLabel(
        await TPI18N.GetText(resourceSetTPModal, "AddReminderLabel"),
      );
      setCommentsReminderLabel(
        await TPI18N.GetText(resourceSetTPModal, "CommentsReminderLabel"),
      );

      //Quantity options
      let newQuantityOptions: Array<TPKeyValue> = [];
      for (let i = 1; i <= 10; i++) {
        newQuantityOptions.push({ key: i.toString(), value: i });
      }
      setQuantityOptions(newQuantityOptions);

      //Due Term Hours options
      let newDueTermHoursOptions: Array<TPKeyValue> = [];
      for (let i = 0; i <= 23; i++) {
        newDueTermHoursOptions.push({
          key: i < 10 ? `0${i.toString()}` : i.toString(),
          value: i < 10 ? `0${i.toString()}` : i.toString(),
        });
      }
      setDueTermHoursOptions(newDueTermHoursOptions);

      //Due Term Minutes options
      let newDueTermMinutesOptions: Array<TPKeyValue> = [];
      for (let i = 0; i <= 59; i++) {
        newDueTermMinutesOptions.push({
          key: i < 10 ? `0${i.toString()}` : i.toString(),
          value: i < 10 ? `0${i.toString()}` : i.toString(),
        });
      }
      setDueTermMinutesOptions(newDueTermMinutesOptions);

      let parametersSercice = new ParametersService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);

        let responseRequest =
          await parametersSercice.getByParentIdAndFilterIsActive(
            "TERMUNIT", //TODO
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            expectedCodes,
          );
        let newUnitTermListState: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return { key: item.id, value: item.localizedDescription };
          },
        );
        newUnitTermListState.unshift({ key: "", value: "--" });
        setUnitTermListState(newUnitTermListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndTaskType getByParentIdAndFilterIsActive ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} loadResourcesAndTaskType getByParentIdAndFilterIsActive ex`,
        );
        setIsLoadingScreen(false);
      }

      setIsLoadingScreen(false);
    };

    const handleAssignToMe = async (e: any) => {
      setIsReminder(false);
      handleInputTextChange("assignToMe", e.target.checked);
      if (e.target.checked) {
        let currentUserInList: TPKeyValue | undefined =
          autocompleteUserAssignOptions.find(
            (user) => user.key === TPGlobal.currentUserGuid,
          );
        if (currentUserInList) {
          setSelectedAutocompleteUserAssignOption([
            {
              key: TPGlobal.currentUserGuid,
              value: currentUserInList.value,
              value2: currentUserInList.value2,
            },
          ]);
          setCurrentUserLabel(currentUserInList.value);
        } else {
          let user = await getUserById(TPGlobal.currentUserGuid);
          if (user) {
            setAutocompleteUserAssignOptions([
              {
                key: TPGlobal.currentUserGuid,
                value: user.name,
                value2: user.isGroup,
              },
            ]);
            setSelectedAutocompleteUserAssignOption([
              {
                key: TPGlobal.currentUserGuid,
                value: user.name,
                value2: user.isGroup,
              },
            ]);
            setCurrentUserLabel(user.name);
          }
        }
      } else {
        setSelectedAutocompleteUserAssignOption([]);
      }
    };

    const getUserById = async (
      pRecordId: string,
    ): Promise<UserViewModel | null> => {
      let serviceClient = new UserService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getUserById(
          pRecordId,
          false,
          true,
          expectedCodes,
        );
        let recordInfo: UserViewModel;
        recordInfo = { ...responseRequest };
        setIsLoadingScreen(false);
        return recordInfo;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getUserById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getUserById ex`);
        setIsLoadingScreen(false);
        return null;
      }
    };

    const handleInputTextChange = (propertyName: string, value: any) => {
      let command: commandType = {
        type: commandsAddTaskFormEnum.change_value_property,
        payload: {
          newPropertyName: propertyName,
          newValue: value,
        },
      };
      dispatchCommand(command);
    };

    const handleOnAutocompleteChange = (
      newSelectedValue: Array<TPKeyValue>,
    ) => {
      setSelectedAutocompleteTaskTypeOption(newSelectedValue);
      let command: commandType = {
        type: commandsAddTaskFormEnum.change_value_property,
        payload: {
          newPropertyName: "taskTypeIdErrorMessage",
          newValue: "",
        },
      };
      dispatchCommand(command);
    };

    const handleOnAutocompleteUserAssignChange = (
      newSelectedValue: Array<TPKeyValue>,
    ) => {
      setSelectedAutocompleteUserAssignOption(newSelectedValue);
      let command: commandType = {
        type: commandsAddTaskFormEnum.change_value_property,
        payload: {
          newPropertyName: "assignToErrorMessage",
          newValue: "",
        },
      };
      dispatchCommand(command);
    };

    const handleOnAutocompleteQuery = async (query: string) => {
      let taskTypeService = new TaskTypeService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        let responseRequest = await taskTypeService.getSearchTaskType(
          query,
          TPActiveOptions.ACTIVE, //TODO ask if all or actives only
          1, //Addables
          false,
          true,
          expectedCodes,
        );

        let newTaskTypeList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return { key: item.id, value: item.localizedDescription };
          },
        );
        newTaskTypeList.unshift({ key: "", value: "--" });
        setAutocompleteTaskTypeOptions(newTaskTypeList);
        return newTaskTypeList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleOnAutocompleteQuery ex`,
        );
        return [];
      }
    };

    const handleOnAutocompleteUserAssignQuery = async (query: string) => {
      let userService = new UserService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        let responseRequest = await userService.getActiveUsersBySearchParameter(
          query,
          false,
          true,
          expectedCodes,
        );
        let newUserList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.userGuid,
              value: item.name,
              value2: item.isGroup,
            };
          },
        );
        newUserList.unshift({ key: "", value: "--", value2: false });
        setAutocompleteUserAssignOptions(newUserList);
        return newUserList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleOnAutocompleteUserAssignQuery ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleOnAutocompleteUserAssignQuery ex`,
        );
        return [];
      }
    };

    //Action after question confirmation
    const handleCallBackModalConfirm = async (confirm: boolean) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;

      let dueDatestr: string | null;
      let reminderDatestr: string | null;
      if (screenState.termType === "term") {
        dueDatestr = null;
      } else {
        dueDatestr = `${moment(screenState.dueDate).format("YYYY-MM-DD")}T${
          screenState.dueDateHour
        }:${screenState.dueDateMinutes}:00.000Z`;
      }
      if (!isReminder) {
        reminderDatestr = null;
      } else {
        if (screenState.reminderDate) {
          reminderDatestr = `${moment(screenState.reminderDate).format(
            "YYYY-MM-DD",
          )}T${screenState.reminderDateHour}:${
            screenState.reminderDateMinutes
          }:00.000Z`;
        } else {
          reminderDatestr = null;
        }
      }
      let recordInputDTO: AddTaskMasiveInputDTO = {
        CaseListToAddTask,
        TaskTypeId:
          selectedAutocompleteTaskTypeOption.length > 0
            ? selectedAutocompleteTaskTypeOption[0].key
            : "",
        Quantity: +screenState.quantity,
        ResponsibleGuidUser:
          selectedAutocompleteUserAssignOption.length > 0
            ? selectedAutocompleteUserAssignOption[0].key
            : "",
        Term: screenState.termType === "term" ? +screenState.term : null,
        TermUnitId: screenState.termType === "term" ? screenState.termUnit : "",
        DueDate: dueDatestr,
        Comments: screenState.comments,
        ReminderDate: reminderDatestr,
        ReminderComments: screenState.reminderComments,
        TypistGuidUser: TPGlobal.currentUserGuid,
      };

      if (confirm) {
        //Call endpoint to add task in batch
        let serviceClient = new CaseMassUpdateService();
        let expectedCodes: Array<number> = [200];

        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.addMassivetask(
            recordInputDTO,
            true,
            true,
            expectedCodes,
          );
          setModalSuccess(true);

          if (responseRequest.responseResult) {
            setAnswerData(responseRequest.responseData.data);
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return true;
          } else {
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return false;
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModalConfirm ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} handleCallBackModalConfirm ex`,
          );
          setIsLoadingScreen(false);
          setModalQuestionState(newModalQuestionState);
          return false;
        }
      } else {
        setModalQuestionState(newModalQuestionState);
      }
    };

    const handleOnAutocompleteKeyDown = (event: any) => {
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      if (
        event.keyCode != 37 &&
        event.keyCode != 39 &&
        event.keyCode != 13 &&
        event.keyCode != 35 &&
        event.keyCode != 36
      ) {
        setAutocompleteTaskTypeOptions([]);
        setSelectedAutocompleteTaskTypeOption([]);
      }
    };

    const handleOnAutocompleteUserAssignKeyDown = (event: any) => {
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      if (
        event.keyCode != 37 &&
        event.keyCode != 39 &&
        event.keyCode != 13 &&
        event.keyCode != 35 &&
        event.keyCode != 36
      ) {
        setAutocompleteUserAssignOptions([]);
        setSelectedAutocompleteUserAssignOption([]);
      }
    };

    const handleAutoCompleteTopNClick = async () => {
      let newTopNOptions: Array<TPKeyValue> = [];
      if (autocompleteTaskTypeTopNOptions.length === 0) {
        newTopNOptions = await handleOnAutocompleteQuery("");

        if (newTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteTaskTypeTopNOptions([...newTopNOptions]);
          setAutocompleteTaskTypeOptions([...newTopNOptions]);
          setSelectedAutocompleteTaskTypeOption([]);
        }
      } else {
        //use cached values;
        setAutocompleteTaskTypeOptions([...autocompleteTaskTypeTopNOptions]);
        setSelectedAutocompleteTaskTypeOption([]);
      }
    };

    const handleAutoCompleteTopNUserAssignClick = async () => {
      let newTopNOptions: Array<TPKeyValue> = [];
      if (autocompleteUserAssignTopNOptions.length === 0) {
        newTopNOptions = await handleOnAutocompleteUserAssignQuery("");

        if (newTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteUserAssignTopNOptions([...newTopNOptions]);
          setAutocompleteUserAssignOptions([...newTopNOptions]);
          setSelectedAutocompleteUserAssignOption([]);
        }
      } else {
        //use cached values;
        setAutocompleteUserAssignOptions([
          ...autocompleteUserAssignTopNOptions,
        ]);
        setSelectedAutocompleteUserAssignOption([]);
      }
    };

    const initialStateBll: screenStateProps = {
      taskTypeId: "",
      quantity: 1,
      assignTo: "",
      assignToMe: false,
      termType: "term",
      term: 1,
      termUnit: "TERMUNITDA",
      dueDate: null,
      dueDateHour: "00",
      dueDateMinutes: "00",
      comments: "",
      reminderDateHour: "00",
      reminderDateMinutes: "00",
      reminderDate: null,
      reminderComments: "",
      taskTypeIdErrorMessage: "",
      assignToErrorMessage: "",
      dueDateErrorMessage: "",
      commentsErrorMessage: "",
      quantityErrorMessage: "",
      reminderDateErrorMessage: "",
      reminderCommentsErrorMessage: "",
      termErrorMessage: "",
      termUnitErrorMessage: "",
    };

    //reducer definition
    const [screenState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBll,
    );

    function doCommand(prevState: screenStateProps, command: commandType) {
      let newScreenState: screenStateProps;
      switch (command.type) {
        case commandsAddTaskFormEnum.clear_state:
          return initialStateBll;
        case commandsAddTaskFormEnum.change_value_property:
          newScreenState = { ...prevState };
          if (
            typeof newScreenState[command.payload.newPropertyName] === "boolean"
          ) {
            newScreenState[command.payload.newPropertyName] =
              !newScreenState[command.payload.newPropertyName];
          } else {
            newScreenState[command.payload.newPropertyName] =
              command.payload.newValue;
          }
          newScreenState[command.payload.newPropertyName + "ErrorMessage"] = "";
          return newScreenState;
        case commandsAddTaskFormEnum.set_errors:
          newScreenState = { ...prevState };
          newScreenState.taskTypeIdErrorMessage =
            command.payload.errors.taskTypeIdErrorMessage;
          newScreenState.quantityErrorMessage =
            command.payload.errors.quantityErrorMessage;
          newScreenState.assignToErrorMessage =
            command.payload.errors.assignToErrorMessage;
          newScreenState.commentsErrorMessage =
            command.payload.errors.commentsErrorMessage;
          newScreenState.reminderDateErrorMessage =
            command.payload.errors.reminderDateErrorMessage;
          newScreenState.reminderCommentsErrorMessage =
            command.payload.errors.reminderCommentsErrorMessage;
          newScreenState.termErrorMessage =
            command.payload.errors.termErrorMessage;
          newScreenState.termUnitErrorMessage =
            command.payload.errors.termUnitErrorMessage;
          return newScreenState;
      }
    }

    const internalValidation = async () => {
      let dueDatestr: string | null;
      let reminderDatestr: string | null;
      if (screenState.termType === "term") {
        dueDatestr = null;
      } else {
        dueDatestr = `${moment(screenState.dueDate).format("YYYY-MM-DD")}T${
          screenState.dueDateHour
        }:${screenState.dueDateMinutes}:00.000Z`;
      }
      if (!isReminder) {
        reminderDatestr = null;
      } else {
        if (screenState.reminderDate) {
          reminderDatestr = `${moment(screenState.reminderDate).format(
            "YYYY-MM-DD",
          )}T${screenState.reminderDateHour}:${
            screenState.reminderDateMinutes
          }:00.000Z`;
        } else {
          reminderDatestr = null;
        }
      }
      let recordInputDTO: AddTaskMasiveInputDTO = {
        CaseListToAddTask,
        TaskTypeId:
          selectedAutocompleteTaskTypeOption.length > 0
            ? selectedAutocompleteTaskTypeOption[0].key
            : "",
        Quantity: +screenState.quantity,
        ResponsibleGuidUser:
          selectedAutocompleteUserAssignOption.length > 0
            ? selectedAutocompleteUserAssignOption[0].key
            : "",
        Term: screenState.termType === "term" ? +screenState.term : null,
        TermUnitId: screenState.termType === "term" ? screenState.termUnit : "",
        DueDate: dueDatestr,
        Comments: screenState.comments,
        ReminderDate: reminderDatestr,
        ReminderComments: screenState.reminderComments,
        TypistGuidUser: TPGlobal.currentUserGuid,
      };
      let inputDTOValidator = new AddTaskMasiveInputDTOValidator(
        screenState.termType,
        isReminder,
      );
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let validationsErrores: any = new Object();
        if (resultValidator.TaskTypeId) {
          validationsErrores.taskTypeIdErrorMessage = await TPI18N.GetResource(
            resultValidator.TaskTypeId,
          );
        } else {
          validationsErrores.taskTypeIdErrorMessage = "";
        }
        if (resultValidator.Quantity) {
          validationsErrores.quantityErrorMessage = await TPI18N.GetResource(
            resultValidator.Quantity,
          );
        } else {
          validationsErrores.quantityErrorMessage = "";
        }
        if (resultValidator.ResponsibleGuidUser) {
          validationsErrores.assignToErrorMessage = await TPI18N.GetResource(
            resultValidator.ResponsibleGuidUser,
          );
        } else {
          validationsErrores.assignToErrorMessage = "";
        }
        if (resultValidator.Comments) {
          validationsErrores.commentsErrorMessage = await TPI18N.GetResource(
            resultValidator.Comments,
          );
        } else {
          validationsErrores.commentsErrorMessage = "";
        }
        if (resultValidator.ReminderDate) {
          validationsErrores.reminderDateErrorMessage =
            await TPI18N.GetResource(resultValidator.ReminderDate);
        } else {
          validationsErrores.reminderDateErrorMessage = "";
        }
        if (resultValidator.ReminderComments) {
          validationsErrores.reminderCommentsErrorMessage =
            await TPI18N.GetResource(resultValidator.ReminderComments);
        } else {
          validationsErrores.reminderCommentsErrorMessage = "";
        }
        if (resultValidator.Term) {
          validationsErrores.termErrorMessage = await TPI18N.GetResource(
            resultValidator.Term,
          );
        } else {
          validationsErrores.termErrorMessage = "";
        }
        if (resultValidator.TermUnitId) {
          validationsErrores.termUnitErrorMessage = await TPI18N.GetResource(
            resultValidator.TermUnitId,
          );
        } else {
          validationsErrores.termUnitErrorMessage = "";
        }
        let command: commandType = {
          type: commandsAddTaskFormEnum.set_errors,
          payload: {
            errors: validationsErrores,
          },
        };
        dispatchCommand(command);
        return false;
      }

      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      setModalQuestionState(newModalQuestionState);
      return false;
    };

    useEffect(() => {
      loadResourcesAndTaskType();
    }, []);

    return (
      <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
        <div className="row">
          <div className="col-10">
            <div className="form-group">
              <TPAutoComplete
                isMandatory={true}
                labelText={taskTypeLabel}
                onValueChange={handleOnAutocompleteChange}
                onSearch={(query: string) => {
                  handleOnAutocompleteQuery(query);
                }}
                isLoading={false}
                options={autocompleteTaskTypeOptions}
                withIcon={true}
                emptyLabel={emptyLabel}
                onKeyDown={handleOnAutocompleteKeyDown}
                selected={selectedAutocompleteTaskTypeOption}
                errorMessage={screenState.taskTypeIdErrorMessage}
                downArrowClick={handleAutoCompleteTopNClick}
              ></TPAutoComplete>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <div className="form-group">
              <TPSelect
                id="IdSelect"
                isMandatory={true}
                labelText={quantityLabel}
                onChange={(e: any) =>
                  handleInputTextChange("quantity", e.target.value)
                }
                dataSource={quantityOptions}
                value={screenState.quantity}
                errorMessage={screenState.quantityErrorMessage}
              ></TPSelect>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <div className="form-group">
              {screenState.assignToMe ? (
                <TPTextBox
                  id="IdTextBox"
                  labelText={assignToLabel}
                  isMandatory={false}
                  disabled={true}
                  onChange={() => TPGlobal.foo()}
                  value={currentUserLabel}
                ></TPTextBox>
              ) : (
                <TPAutoComplete
                  isMandatory={true}
                  labelText={assignToLabel}
                  onValueChange={handleOnAutocompleteUserAssignChange}
                  onSearch={(query: string) => {
                    handleOnAutocompleteUserAssignQuery(query);
                  }}
                  isLoading={false}
                  options={autocompleteUserAssignOptions}
                  withIcon={true}
                  emptyLabel={emptyLabel}
                  onKeyDown={handleOnAutocompleteUserAssignKeyDown}
                  selected={selectedAutocompleteUserAssignOption}
                  errorMessage={screenState.assignToErrorMessage}
                  downArrowClick={handleAutoCompleteTopNUserAssignClick}
                ></TPAutoComplete>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <div className="form-group">
              <TPCheckBox
                id="IdCheckBox"
                labelText={assignToMeLabel}
                checked={screenState.assignToMe}
                onChange={(e: any) => handleAssignToMe(e)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPRadioGroup
              id="IdRadioGroup"
              value={screenState.termType}
              source={[
                { key: "term", value: termItemLabel },
                { key: "due", value: dueDateItemLabel },
              ]}
              onChange={(e: any) =>
                handleInputTextChange("termType", e.target.value)
              }
            />
          </div>
        </div>
        {screenState.termType === "term" ? (
          <>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <TPNumeric
                    id="IdTPNumeric"
                    isMandatory={true}
                    onChange={(value: number) =>
                      handleInputTextChange("term", value)
                    }
                    value={screenState.term}
                    labelText={timeLabel}
                    errorMessage={screenState.termErrorMessage}
                  />
                </div>
              </div>
              <div className="col-8">
                <div className="form-group">
                  <TPSelect
                    id="IdSelect"
                    labelText={timeUnitLabel}
                    onChange={(e: any) =>
                      handleInputTextChange("termUnit", e.target.value)
                    }
                    dataSource={unitTermList}
                    value={screenState.termUnit}
                    errorMessage={screenState.termUnitErrorMessage}
                  ></TPSelect>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  {" "}
                  <TPDatePicker
                    labelText={dateLabel}
                    isMandatory={true}
                    selectedDate={screenState.dueDate}
                    onChangeDate={(newDate: Date) =>
                      handleInputTextChange("dueDate", newDate)
                    }
                    minDate={moment(new Date()).toDate()}
                    customDateFormat={TPGlobal.dateFormat}
                    errorMessage={screenState.dueDateErrorMessage}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <TPSelect
                    id="IdSelect"
                    labelText={hoursLabel}
                    onChange={(e: any) =>
                      handleInputTextChange("dueDateHour", e.target.value)
                    }
                    dataSource={dueTermHoursOptions}
                    value={screenState.dueDateHour}
                  ></TPSelect>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <TPSelect
                    id="IdSelect"
                    labelText={minutesLabel}
                    onChange={(e: any) =>
                      handleInputTextChange("dueDateMinutes", e.target.value)
                    }
                    dataSource={dueTermMinutesOptions}
                    value={screenState.dueDateMinutes}
                  ></TPSelect>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">{TPGlobal.currentUserTimeZoneId}</div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col">
            <TPTextArea
              id="IdTextArea"
              isMandatory={true}
              labelText={commentsLabel}
              onChange={(e: any) =>
                handleInputTextChange("comments", e.target.value)
              }
              value={screenState.comments}
              rows={7}
              errorMessage={screenState.commentsErrorMessage}
            />
          </div>
        </div>
        {showReminderOption &&
          selectedAutocompleteUserAssignOption.length > 0 &&
          selectedAutocompleteUserAssignOption[0].value2 && (
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TPCheckBox
                    id="IdCheckBox"
                    labelText={addReminderLabel}
                    checked={isReminder}
                    onChange={(e: any) => setIsReminder(!isReminder)}
                  />
                </div>
              </div>
            </div>
          )}
        {isReminder && (
          <>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <div className="form-group">
                      {" "}
                      <TPDatePicker
                        labelText={dateLabel}
                        isMandatory={true}
                        selectedDate={screenState.reminderDate}
                        onChangeDate={(newDate: Date) =>
                          handleInputTextChange("reminderDate", newDate)
                        }
                        minDate={moment(new Date()).add(1, "day").toDate()}
                        customDateFormat={TPGlobal.dateFormat}
                        errorMessage={screenState.reminderDateErrorMessage}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        labelText={hoursLabel}
                        onChange={(e: any) =>
                          handleInputTextChange(
                            "reminderDateHour",
                            e.target.value,
                          )
                        }
                        dataSource={dueTermHoursOptions}
                        value={screenState.reminderDateHour}
                      ></TPSelect>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        labelText={minutesLabel}
                        onChange={(e: any) =>
                          handleInputTextChange(
                            "reminderDateMinutes",
                            e.target.value,
                          )
                        }
                        dataSource={dueTermMinutesOptions}
                        value={screenState.reminderDateMinutes}
                      ></TPSelect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPTextArea
                  id="IdTextArea"
                  labelText={commentsReminderLabel}
                  onChange={(e: any) =>
                    handleInputTextChange("reminderComments", e.target.value)
                  }
                  value={screenState.reminderComments}
                  rows={7}
                  errorMessage={screenState.reminderCommentsErrorMessage}
                />
              </div>
            </div>
          </>
        )}
        <TPModalQuestion
          title={modalConfirmTitle}
          yesLabel={okButtonLabel}
          noLabel={cancelButtonLabel}
          question={modalConfirmQuestion}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModalConfirm}
        ></TPModalQuestion>
        {modalSuccess && (
          <TPModalSuccess
            modalState={{
              title: modalSuccessTitle,
              yesLabel: okButtonLabel,
              isShown: modalSuccess,
              callBackFunction: () => {
                callBackSaveSuccess();
                setModalSuccess(false);
              },
            }}
          >
            <div>
              <div>
                {selectedRowsLabel} : {CaseListToAddTask.length}
              </div>
              <div>
                {recordsProcessedLabel} :{" "}
                {answerData.filter((item) => Boolean(item.taskAdded)).length}
              </div>
              <div>
                {recordsUnprocessedLabel} :{" "}
                {answerData.filter((item) => Boolean(!item.taskAdded)).length}
              </div>
            </div>
          </TPModalSuccess>
        )}
      </TPLoadingOverlay>
    );
  },
);

export default AddTaskForm;
