import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { FavoritesInputDTO } from "@/models/Favorites/FavoritesInputDTO";

export class FavoritesService {
  serviceFileName: string = "FavoritesService.ts";

  public async convertToFavorite(
    inputDTO: FavoritesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/casesinterest";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} convertToFavorite ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} convertToFavorite`);
      throw new Error(`Error ${this.serviceFileName} convertToFavorite`);
    }
  }

  public async destroyFavorite(
    caseId: number,
    userGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/casesinterest/" + caseId.toString() + "/" + userGuid;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} destroyFavorite ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} destroyFavorite`);
      throw new Error(`Error ${this.serviceFileName} destroyFavorite`);
    }
  }
}
