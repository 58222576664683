import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { GroupTakeNextGroupModel, TakeNextGroupType, TakeNextTicket } from "@/models/GroupTakeNext/GroupTakeNextModel";
import { useGroupTakeNextLabels } from "./labelling";
import { FC } from "react";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import allThemes from "@/assets/styles/theme";
import { Tooltip } from "@mui/material";

interface GroupTakeNextCardProperties {
  item: GroupTakeNextGroupModel;
  onCheck: (checked: boolean) => void;
  checked: boolean;
  selectionDisabled?: boolean;
  isUserOnline: boolean;
  onPlayClick: (id: string, description: string) => void;
  isPlayEnabled: boolean;
}

const caseBgColor = "#FBEBFF";
const caseIconColor = "#C30091";
const eventBgColor = "#E7FDF2";
const eventIconColor = "#00826D";
const takeNextBgColor = "#D3EAFF";
const backofficeBgColor = "#B1EFF2";
const frontofficeBgColor = "#E2D7FF";

const GroupTakeNextCard: FC<GroupTakeNextCardProperties> = function ({
  item,
  checked,
  onCheck,
  selectionDisabled,
  isUserOnline,
  onPlayClick,
  isPlayEnabled
}) {
  const { labels } = useGroupTakeNextLabels();

  const getGroupDescription = function (groupName: string, projectName?: string) {
    if (!projectName) return groupName;
    const fullName = `${groupName}${projectName && String(" / ").concat(projectName)}`;
    return fullName.length > 15 ? String(fullName).slice(0, 15).concat("...") : fullName;
  }

  const getFrontofficeGroupDescription = function (groupName: string, projectName?: string) {
    const fullName = `${labels.GroupHeaderLabel}: ${groupName}${projectName && String(" / ").concat(labels.Project, ": ", projectName)}`;
    return fullName || groupName;
  }

  let bgColor = "";
  let label = "";
  switch (item.groupType) {
    case TakeNextGroupType.TAKENEXT:
      bgColor = takeNextBgColor;
      label = labels.TakeNextLabel;
      break;
    case TakeNextGroupType.FRONTOFFICE:
      bgColor = frontofficeBgColor;
      label = labels.FrontOfficeLabel;
      break;
    case TakeNextGroupType.BACKOFFICE:
      bgColor = backofficeBgColor;
      label = labels.BackOfficeLabel;
      break;
  }

  return (
    <>
      <div className="GTN-card">
        <div
          className="GTN-card-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: '8px' }}>
            <TPCheckBox
              disabled={selectionDisabled}
              checked={checked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheck && onCheck(e.target.checked)}
              checkboxStyle={{
                backgroundColor: selectionDisabled && "#D3D3D3",
                borderColor: selectionDisabled && "7b7b7b"
              }}
            />
            <b>{item.groupType == TakeNextGroupType.FRONTOFFICE ? (
              <Tooltip title={getFrontofficeGroupDescription(item.description, item.projectDescription)}>
                <div>
                  {getGroupDescription(item.description, item.projectDescription)}
                </div>
              </Tooltip>
            ) : (
              <div>{getGroupDescription(item.description)}</div>
            )}
            </b>
          </div>
          <TPChip
            backgroundColor={item.ticket == TakeNextTicket.case ? caseBgColor : eventBgColor}
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <div
                  style={{
                    borderRadius: '50%',
                    aspectRatio: 1,
                    backgroundColor: item.ticket == TakeNextTicket.case ? caseIconColor : eventIconColor,
                    width: '12px',
                    height: '12px'
                  }}
                />
                <label>{item.ticket == TakeNextTicket.case ? labels.case : labels.event}</label>
              </div>}
          />
        </div>
        <div className="GTN-card-body">
          <div className="GTN-data-display">
            {String().concat(labels.Ready, ':')}
            <b>{item.ready}</b>
          </div>
          <div className="GTN-data-display">
            {String().concat(labels.Processing, ':')}
            <b>{item.processing}</b>
          </div>
          <div className="GTN-data-display">
            {String().concat(labels.InQueue, ':')}
            <b>{item.inQueue}</b>
          </div>
        </div>
        <div className="GTN-card-footer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                backgroundColor: isPlayEnabled ? allThemes.base.trueGreen : allThemes.base.falseRed,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                width: '32px',
                height: '32px'
              }}
            >
              <TPIcon
                style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                iconType={checked && isUserOnline ?
                  TPIconTypes.circleCheckOutline
                  :
                  TPIconTypes.warningCircleOutline}
              />
            </div>
            {isPlayEnabled ? labels.Available : labels.NotAvailable}
          </div>
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <TPChip
            backgroundColor={bgColor}
            label={label}
          />
            {item.groupType == TakeNextGroupType.FRONTOFFICE &&
              <button
                type="button"
                style={{
                  border: 'none',
                  background: 'none',
                  color: isPlayEnabled ? allThemes.base.purplePrimary : '#BFBFBF'
                }}
                disabled={!isUserOnline}
                onClick={() => onPlayClick(String(item.groupId), item.description)}
              >
                <TPIcon iconType={TPIconTypes.play} style={{ fontSize: '20px' }} />
              </button>}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupTakeNextCard;
