import React, { ReactElement, FC, useState, useEffect, CSSProperties } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";

import {
    TPPageSectionTitle,
    TPPageTitle,
    TPPageSection,
    TPPageAcceptCancelButtonsContainer,
    TPPageSubTitle,
} from "@/components/TPPage/tpPageStyles";

import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    FunctionsInputDTO,
    FunctionsInputDTOValidator,
} from "@/models/Functions/FunctionsInputDTO";
import { FunctionsViewModel } from "@/models/Functions/FunctionsModels";
import { SequenceGeneratorSequencesNameEnum, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { FunctionsService } from "@/services/FunctionsService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { SequenceService } from "@/services/SequenceService";
import { useAppDistributionListAdmin } from "./ContextDistributionListAdmin";
import { LocationLanguages, LocationNameGlobal, LocationNameInsertUpdate } from "./DistributionListAdminLocalization"
import DistributionListAdmintImportTable from "./DistributionListAdmintImportTable";
import { colors } from "react-select/dist/declarations/src/theme";
import { DistributionListAdminService } from "@/services/DistributionListAdminService";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { DescriptionLocalizedValues, DistributionModel, DistributionUpdateModel, EmailAddressModel } from "@/models/DistributionListAdmin/DistributionListAdmin";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    tabId: string;
    callBackResult: Function;
};
const dataColumnTemp = [
    ['', '', '']
];

type InsertUpdateStateType = {
    idFunction: string;
    recordLanguageList: Array<TPKeyValue>;
    isActive: boolean;
    idErrorMessage: string;
    nameErrorMessages: Array<string>;
};
const data = [
    ['', '', '']
];

export const DistributionListAdminLocalizationInsertUpdate: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
    tabId
}): ReactElement => {
    //#region  Init

    const [errorId, setErrorId] = useState("");
    const [erroName, setErroName] = useState("");

    //screen loading

    const [listColumn, setListColumn] = useState(data);
    const [open, setOpen] = useState(true);

    const [insertUpdate, setInsertUpdate] = useState(new DistributionUpdateModel);
    const [insertUpdateEmail, setInsertUpdateEmail] = useState(new DistributionUpdateModel);

    //Screen resources
    const [location, setLocation] = useState(new LocationNameInsertUpdate);
    const [locationGlobal, setLocationGlobal] = useState(new LocationNameGlobal);

    const [dataColumn, setDataColumn] = useState(data);
    const [loading, setLoading] = useState(false);

    const [referenceId, setReferenceId] = useState("");
    const [originalRecordDescription, setOriginalRecordDescription] =
        useState("");

    //Screen state
    let initialErrorMessages: Array<string> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialErrorMessages.push("");
    }
    let insertUpdateInitialState: InsertUpdateStateType = {
        idFunction: recordId,
        recordLanguageList: [],
        isActive: true,
        idErrorMessage: "",
        nameErrorMessages: initialErrorMessages,
    };
    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState
    );

    //Multilanguage const
    const multilanguageTableName: String = "DistributionList";
    const multilanguageFieldName: String = "Description_DILI";
    //#endregion

    const loadResourcesAndLoadFunctionInfo = async () => {

        let temp = new LocationLanguages();
        await temp.locationDistributionListInsertUpdate(resourceSet);
        setLocation(temp.getLocationNameInsertUpdate);

        let tempGlobal = new LocationLanguages();
        await temp.locationGlobal(TPGlobal.globalResourceSet);
        setLocationGlobal(temp.getLocationNameGlobal);

        let updatedData = [...dataColumn];

        updatedData[0][0] = await TPI18N.GetText(resourceSet, "EmailAddresLabel") + " (*)";
        updatedData[0][1] = await TPI18N.GetText(resourceSet, "EmailNameLabel") + " (*)";
        updatedData[0][2] = await TPI18N.GetText(resourceSet, "ResultLabel") + " (*)";

        setDataColumn(updatedData);

        await handleLoadModeAction();
    };

    const handleLoadModeAction = async () => {
        let i: number;
        if (mode == "Update") {
            let newInsertUpdateState = { ...insertUpdateState };

            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let languages = newInsertUpdateState.recordLanguageList.find(s => s.key == item.id);
                let keyValueElement: TPKeyValue = { key: item.id, value: (languages?.value ? languages.value : "") };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            setInsertUpdateState(newInsertUpdateState);
        }

        if (mode == "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }

            setInsertUpdateState(newInsertUpdateState);
            setLoading(false);
        }
    };


    const handleOnIdFunctionChange = (newIdFunction: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.idFunction = newIdFunction;
        newInsertUpdateState.idErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.nameErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleOkButtonClick = async () => {
        let i: number;
        let n: number;
        let recordInputDTO: FunctionsInputDTO = {
            id: referenceId,
            isActive: insertUpdateState.isActive,
            name: insertUpdateState.recordLanguageList[0].value,
            nameLocalizedValues: [],
        };
        n = insertUpdateState.recordLanguageList.length;
        for (i = 0; i <= n - 1; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageList[i];
            recordInputDTO.nameLocalizedValues.push({
                order: i + 1,
                languageId: item.key,
                localizedValue: item.value,
            });
        }

        let inputDTOValidator = new DistributionListAdminService();

        setChanged((changed + 1));

        if (mode == "Insert") {
            await insertFunction();
        } else {
            await updateFunction();
        }
    };
    //123
    const insertFunction = async () => {
        let serviceClient = new DistributionListAdminService();
        let expectedCodes: Array<number> = [200];
        try {
            setLoading(true);

            let data = new DistributionModel();
            data.id = insertUpdate.id;
            data.isActive = insertUpdateState.isActive;
            data.description = insertUpdateState.recordLanguageList[0].value;

            data.descriptionLocalizedValues = new Array<DescriptionLocalizedValues>();

            insertUpdateState.recordLanguageList.forEach(
                (e) => {
                    data.descriptionLocalizedValues.push({
                        languageId: e.key,
                        localizedValue: e.value
                    }
                    );
                });

            data.isActive = insertUpdateState.isActive;

            let responseRequest = await serviceClient.insertDistributionListAdmin(
                data,
                true,
                true,
                expectedCodes
            );
            setLoading(false);


            if (responseRequest.responseResult) {
                let language = TPGlobal.language;
                callBackResult({
                    result: "ChangeCloneToUpdateMode",
                    recordId: insertUpdate.id,
                    tabId: tabId,
                    language: language
                });
            }


        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} insertFunction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} insertFunction ex`);
            setLoading(false);
        }
    };

    const updateFunction = async () => {
        let serviceClient = new DistributionListAdminService();
        let expectedCodes: Array<number> = [200];
        try {

            setLoading(true);
            let data = new DistributionUpdateModel();

            data.id = insertUpdate.id;
            data.isActive = insertUpdateState.isActive;
            data.description = insertUpdateState.recordLanguageList[0].value;

            data.descriptionLocalizedValues = new Array<DescriptionLocalizedValues>();

            insertUpdateState.recordLanguageList.forEach(
                (e) => {
                    data.descriptionLocalizedValues.push({
                        languageId: e.key,
                        localizedValue: (e.value ? e.value : "")
                    }
                    );
                });

            data.distributionListAddress = new Array();
            hotdataList.forEach((s) => {
                data.distributionListAddress.push({ name: s[1], email: s[0] });
            });

            let temp = data.distributionListAddress.filter(s => s.email != null && s.name != null);
            data.distributionListAddress = temp;
            data.isActive = insertUpdateState.isActive;
            data.distributionListAddress = data.distributionListAddress.filter(s => s.name != "" && s.email != "");

            let responseRequest = await serviceClient.updateDistributionListAdmin(
                data,
                true,
                true,
                expectedCodes
            );

            //setHotDataList([['', '', '']]);         
            setLoading(false);
            callBackResult({
                result: "ReloadGrid",
                recordId: insertUpdate.id,
                tabId: tabId,
                language: ""
            });

        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} updatetFunction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} updatetFunction ex`);
            setLoading(false);
        }
    };




    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes
                );

            let temp = { ...insertUpdateState };

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];

            if (Array.isArray(recordInfo)) {
                recordInfo.forEach((l) => {
                    let data = temp.recordLanguageList;
                    let exist = data.find(e => e.key == l.languageId);
                    if (exist) {
                        exist.value = l.recordDescription;
                    }

                });
            }

            setInsertUpdateState(temp);

            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} getRecordLanguageList ex`);
            return [];
        }
    };


    const handleCancelButtonClick = () => {
        callBackResult({ result: "CANCEL", recordId: recordId });
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];

        try {
            setLoading(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQDILI
            );

            setLoading(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                let data = { ...insertUpdate };
                data.id = result;
                setInsertUpdate(data);
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} updatetFunction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} updatetFunction ex`);
            setLoading(false);
        }
    };


    const getDistributionListAdminById = async () => {
        let serviceClient = new DistributionListAdminService();
        let expectedCodes: Array<number> = [200];
        try {
            setLoading(true);
            setInsertUpdateEmail(new DistributionUpdateModel);
            setHotDataList([['', '', '']]);

            let responseRequest = await serviceClient.getDistributionListAdminById(
                recordId,
                false,
                true,
                expectedCodes
            );

            let data = { ...insertUpdateState };
            let dataInsert = { ...insertUpdate };
            setLoading(false);

            let result = responseRequest?.responseData?.data[0]?.sequenceCode;
            insertUpdateInitialState.isActive = responseRequest.isActive;
            dataInsert.id = responseRequest.id;
            dataInsert.description = responseRequest.description;
            dataInsert.isActive = responseRequest.isActive;

            dataInsert.descriptionLocalizedValues = new Array<DescriptionLocalizedValues>();
            dataInsert.distributionListAddress = new Array<EmailAddressModel>();
            data.isActive = dataInsert.isActive;

            setInsertUpdateState(data);
            setInsertUpdate(dataInsert);
            setReferenceId(responseRequest.id);

        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} getDistributionListAdminById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} getDistributionListAdminById ex`);
        }
        setLoading(false);
    };

    const getDistributionListAddressByDistributionId = async (id: string) => {
        let serviceClient = new DistributionListAdminService();
        let expectedCodes: Array<number> = [200];

        if (id == "") return "";

        try {

            let responseRequest = await serviceClient.getDistributionListAddressByDistributionId(
                id,
                false,
                false,
                expectedCodes
            );

            let dataInsert = { ...insertUpdateEmail };
            dataInsert.distributionListAddress = new Array<EmailAddressModel>();

            let list = Array();
            list = responseRequest;
            let change = false;
            let listHot = { ...hotdataList };
            if (Array.isArray(list)) {
                list.forEach((e) => {
                    dataInsert.distributionListAddress.push({
                        name: e.name,
                        email: e.email
                    });

                    if (Array.isArray(listHot)) {
                        listHot.push([e.email, e.name, '']);
                        change = true;

                    } else {
                        listHot = [[e.email, e.name, '']];
                        change = true;
                    }

                });
            }

            setInsertUpdateEmail(dataInsert);

        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} getDistributionListAddressByDistributionId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} getDistributionListAddressByDistributionId ex`);
        }
        setLoading(false);
    };


    const ValidateInsert = () => {
        let list = [...hotdataList];
        setErroName("");
        setErrorId("");

        let status = true;
        if (mode == "Update") {

            list.forEach((newData: any[]) => {
                if (newData && Array.isArray(newData)) {
                    newData[2] = "";
                }
            });


            list.forEach((newData: any[]) => {
                if (newData && Array.isArray(newData)) {
                    let value = handleValidate(newData);
                    let valueCount = handleValidateRepeated(newData, list);
                    if (value != "") status = false;
                    if (valueCount != "") status = false;
                    if (newData.length > 2) {
                        if (value != "")
                            newData[2] = value;
                        if (valueCount != "")
                            newData[2] = valueCount;
                    }

                }
            });
           
            setHotDataList(list);
        }



        if (insertUpdateState.recordLanguageList == undefined || insertUpdateState.recordLanguageList.length <= 0 || insertUpdateState.recordLanguageList[0].value == "") {
            setErroName(locationGlobal.required);
            status = false;
        }
        if (status) {
                   
            handleOkButtonClick();
        }


    };


    const handleValidate = (updatedRow: Array<any>) => {
        let msg = "";
        let status = true;
        if (updatedRow[0] == "" && updatedRow[1] == "") return "";

        if (updatedRow[0] == "" && updatedRow[0] == null || updatedRow[0] == undefined)
            if (updatedRow[1] == "" && updatedRow[1] == null || updatedRow[1] == undefined)
                status = false;


        if (status) {
            if (!isValidEmail(updatedRow[0])) {
                msg += location.invalidEmailHotTable;
            }

            if (updatedRow[1] == "") {
                msg
                    += (msg ? ", " : "") + location.invalidNameHotTable;
            }
        }
        return msg;

    };

    const handleValidateRepeated = (updatedRow: Array<any>, list: any[][]) => {
        let msg = "";
        const targetValue = updatedRow[0];

        if (updatedRow[0] == "" && updatedRow[1] == "" && updatedRow[2] == "") return "";

        let count = list.reduce((acc, row) => {
            return row[0] === targetValue ? acc + 1 : acc;
        }, 0);

     
        if (count > 1) {
            msg += location.inputDTOEmailDuplicate;
        }

        return msg;
    };


    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleTitle = (title: string) => {
        if (mode == "Insert") return location.titleNew;
        if (mode == "Update") return location.titleEdit;
        return location.titleNew;
    }


    const value = useAppDistributionListAdmin();
    const {
        resourceSet,
        changed,
        hotdataList,
        setHotDataList,
        setChanged
    } = value;


    //Only once to set resources and load function in update mode
    useEffect(() => {
        loadResourcesAndLoadFunctionInfo();
        setLoading(true);

        if (mode == "Insert") {
            generalAutomaticId();
        } else {
            setReferenceId(recordId);
            getDistributionListAdminById();
            getRecordLanguageList(recordId);
        }
    }, []);

    useEffect(() => {
        getDistributionListAddressByDistributionId(recordId);
    }, [insertUpdateState]);

    return (
        //#region  Render
        <>
            <div style={{ position: "relative", backgroundColor: "red" }}>

            </div>

            <ContentVerticalTabInsertUpdateStyled >
                <div style={{ minHeight: "300px" }} >
                    <TPLoadingOverlay active={loading} >
                        <div className="row"   >
                            <div className="col-6" style={{ width: "98%" }}>
                                <TPPageTitle>{handleTitle(location.title)}</TPPageTitle>
                                <hr />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <TPPageSection>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="idLabel"
                                                    labelText={location.idLabel}
                                                    isMandatory={true}
                                                    value={referenceId}
                                                    onChange={
                                                        mode === "Insert"
                                                            ? (e: any) => handleOnIdFunctionChange(e.target.value)
                                                            : () => {
                                                                TPGlobal.foo();
                                                            }
                                                    }
                                                    maxLength={20}
                                                    disabled
                                                    errorMessage={errorId}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {insertUpdateState.recordLanguageList.length > 0 &&
                                            TPGlobal.TPClientAvailableLanguages.map(
                                                (item, index) =>
                                                    index === 0 && (
                                                        <div className="col-6" key={`languageItem-${item.id}`}>
                                                            <div className="form-group">
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    isMandatory={index === 0}
                                                                    labelText={`${location.nameLabel} (${item.name})`}
                                                                    value={
                                                                        insertUpdateState.recordLanguageList[index]
                                                                            .value
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        handleLanguageChange(index, e.target.value)
                                                                    }}
                                                                    maxLength={100}
                                                                    errorMessage={
                                                                        erroName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                        {insertUpdateState.recordLanguageList.length > 1 && (
                                            <>
                                                <div className="col-4">
                                                    <div className="pt-4">
                                                        <TPButton
                                                            type={TPButtonTypes.icon}
                                                            icon={TPIconTypes.language}
                                                            text={`+${insertUpdateState.recordLanguageList.length - 1
                                                                }`}
                                                            tooltip={location.languageList}
                                                            className={"pt-3"}
                                                            onClick={handleOpenModalLanguageList}
                                                        />
                                                    </div>
                                                </div>
                                                <TPModalLanguageList
                                                    isOpen={isOpenModalLanguageList}
                                                    title={location.languageList}
                                                    acceptLabel={location.saveButton}
                                                    cancelLabel={location.cancelButton}
                                                    saveChanges={handleSaveChangesModalLanguageList}
                                                    closeModal={handleCloseModalLanguageList}
                                                >
                                                    <div
                                                        className="row overflow-auto"
                                                        style={{ height: "200px" }}
                                                    >
                                                        {TPGlobal.TPClientAvailableLanguages.map(
                                                            (item, index) =>
                                                                index > 0 && (
                                                                    <div
                                                                        className="col-12"
                                                                        key={`languageItem-${item.id}`}
                                                                    >
                                                                        <div className="form-group">
                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                isMandatory={index === 0}
                                                                                labelText={`${location.nameLabel} (${item.name})`}
                                                                                value={
                                                                                    insertUpdateState.recordLanguageList[
                                                                                        index
                                                                                    ].value
                                                                                }
                                                                                onChange={(e: any) =>
                                                                                    handleLanguageChange(
                                                                                        index,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                                maxLength={100}
                                                                                errorMessage={
                                                                                    insertUpdateState.nameErrorMessages[index]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                        )}
                                                    </div>
                                                </TPModalLanguageList>
                                            </>
                                        )}
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TPPageSectionTitle>{location.parametersSectionLabel}</TPPageSectionTitle>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TPPageSection>
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={location.isActiveLabel}
                                            checked={insertUpdateState.isActive}
                                            onChange={(e: any) => handleIsActiveChange()}
                                        ></TPCheckBox>
                                    </div>
                                </TPPageSection>
                            </div>
                        </div>

                    </TPLoadingOverlay>
                </div>

                {mode == "Update" &&

                    <div className="accordion" style={styles2.container}>
                        <div className="accordion-item">
                            <p className="accordion-header" style={styles2.titleContainer}>
                                <div style={styles2.title}>
                                    <TPIcon iconType={TPIconTypes.circleInfo} style={styles2.icon} />
                                    <span>{location.instructionsLabel}</span>
                                </div>

                                <button
                                    className={`accordion-button${open ? " collapsed" : ""}`}
                                    style={styles2.button}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse1"
                                    onClick={() => setOpen(!open)}
                                />
                            </p>
                            <div id="collapse1" className={`accordion-collapse collapse`}>
                                <div
                                    className={`accordion-body${open ? " show" : ""}`}
                                    style={styles2.description}
                                >
                                    <span>1.{location.copyFromLabel}</span>
                                    <br></br>
                                    {/*<span>2.{location.selectTheEmailLabel}</span>*/}
                                    {/*<br></br>*/}
                                    <span>2.{location.clickSaveAndReviewLabel}</span>
                                    <br></br>
                                    <br></br>
                                    <span>{location.noteLabel}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {mode == "Update" &&
                    <div style={{ width: "97%", overflow: "auto", marginTop: "30px", height: "120px" }}>
                        <DistributionListAdmintImportTable
                            columns={dataColumn}
                        ></DistributionListAdmintImportTable>
                    </div>
                }

                <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "15px", position: "sticky", bottom: "28px", zIndex: "1111" }}>

                    <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                            type={TPButtonTypes.primary}
                            onClick={() => {
                                ValidateInsert();
                            }
                            }
                        >
                            {location.saveButton}
                        </TPButton>
                        <TPButton
                            type={TPButtonTypes.link}
                            onClick={handleCancelButtonClick}
                            className={"ms-2"}
                        >
                            {location.cancelButton}
                        </TPButton>
                    </TPPageAcceptCancelButtonsContainer>

                </div>

            </ContentVerticalTabInsertUpdateStyled>

        </>

        //#endregion
    );
};



const styles2 = {
    container: {
        marginBottom: "20px",
        marginTop: "10px",
        border: "2px solid #c7c8c9",
        borderRadius: "5px",
        padding: "5px",
        width: "97%"
    } as CSSProperties,
    titleContainer: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center space-around",
        alignItems: "center",
        padding: "0 5px",
    } as CSSProperties,
    title: {
        flexDirection: "row",
        boxSizing: "border-box",
        display: "flex",
        flex: "1 1 0%",
        gap: "10px",
    } as CSSProperties,
    button: {
        width: "30px",
        height: "30px",
        flex: "1 1 30px",
        boxSizing: "border-box",
        maxWidth: "30px",
        borderBottom: "0",
    } as CSSProperties,
    icon: {
        boxSizing: "border-box",
        display: "flex",
        placeContent: "center",
        alignItems: "center",
        color: "blue",
    } as CSSProperties,
    description: {
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
    } as CSSProperties,
};

