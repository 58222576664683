import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { SequenceService } from "@/services/SequenceService";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useFormik } from "formik";
import {
  OutboundMailAccountCloneControl,
  OutboundMailAccountCloneEnum as e,
  OutboundMailAccountCloneModel,
} from "@/models/OutboundMailAccount/OutboundMailAccountClone";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  title: {
    textTransform: "none",
  } as CSSProperties,
  form: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginBottom: "20px",
    gap: "10px",
  } as CSSProperties,
  width50: {
    width: "50%",
  } as CSSProperties,
  nameContainer: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    placeContent: "flex-end flex-start",
    alignItems: "flex-end",
    gap: "10px",
  } as CSSProperties,
  actions: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    placeContent: "flex-end",
    alignItems: "flex-end",
    gap: "10px",
  } as SxProps,
  cancelButton: {
    backgroundColor: "white",
    color: "#a00095",
    border: "1px solid #a00095",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface OutboundMailAccountCloneEvents {
  /**
   * allows you to reload the data source
   */
  load: () => void;
}

/**
 * properties that the child component needs
 */
export interface OutboundMailAccountCloneProps {
  /**
   * vertical tab identifier
   */
  tabId: string;
  /**
   * vertical tab event runner
   */
  verticalTabDispatch: Function;
  /**
   * element
   */
  element: any;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of project detail
 */
const OutboundMailAccountClone = forwardRef(
  (
    {
      tabId,
      verticalTabDispatch: dispatch,
      element,
    }: OutboundMailAccountCloneProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * Messages that are rendered in the view depending on the language
     */
    const [m, setMessages] = useState<{ [attribute: string]: string }>({});
    /**
     * loading event
     */
    const [loading, setLoading] = useState(true);
    /**
     * form controller
     */
    const formik = useFormik({
      initialValues: {
        ...OutboundMailAccountCloneControl.initialValues,
        baseAccountId: element.idAccount,
      } as OutboundMailAccountCloneModel,
      validationSchema: OutboundMailAccountCloneControl.validationSchema,
      validateOnChange: false,
      onSubmit: () => {
        onClickSaveButtonHandler();
      },
    });
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as OutboundMailAccountCloneEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources().then(() => setLoading(false));
      loadIdentifier();
    }, []);
    /**
     * event on component close
     */
    useEffect(
      () => () => {
        setMessages({});
        setLoading(false);
      },
      []
    );
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
      const messages = { ...m };
      // title label
      messages[e.TitleLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.TitleLabel
      );
      messages[e.DescriptionLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.DescriptionLabel
      );
      // form labels
      messages[e.FormInputSourceIdLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.FormInputSourceIdLabel
      );
      messages[e.FormInputIdLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.FormInputIdLabel
      );
      messages[e.FormInputNameLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.FormInputNameLabel
      );
      // section action labels
      messages[e.SectionActionsButtonCancelLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.SectionActionsButtonCancelLabel
      );
      messages[e.SectionActionsButtonSaveLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.SectionActionsButtonSaveLabel
      );
      // messages
      messages[e.MessageSourceIdLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.MessageSourceIdLabel
      );
      messages[e.MessageIdLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.MessageIdLabel
      );
      messages[e.MessageNameLabel] = await TPI18N.GetText(
        e.OutboundMailAccountCloneComponent,
        e.MessageNameLabel
      );

      setMessages(messages);
    }
    /**
     * identifier generator
     */
    async function loadIdentifier() {
      const { generalAutomaticId } = new SequenceService();
      generalAutomaticId(
        false,
        true,
        [200],
        SequenceGeneratorSequencesNameEnum.SQMAAC
      )
        .then(({ responseData }) => {
          formik.setFieldValue(
            "newAccountId",
            responseData?.data[0]?.sequenceCode
          );
        })
        .catch((error) => console.error(error));
    }
    /**
     * function in charge of saving the clone
     */
    function onClickSaveButtonHandler() {
      if (!formik.values.baseAccountId) {
        showToast(m?.[e.MessageSourceIdLabel], TPToastTypes.warning);
        return;
      }
      if (!formik.values.newAccountId) {
        showToast(m?.[e.MessageIdLabel], TPToastTypes.warning);
        return;
      }
      if (!formik.values.description) {
        showToast(m?.[e.MessageNameLabel], TPToastTypes.warning);
        return;
      }

      setLoading(true);
      const request = {
        ...formik.values,
      };

      const { clone } = new OutboundMailAccountService();
      clone(request)
        .then((response) => {
          setLoading(false);
          dispatch({ type: event.reloadGrid });
          dispatch({ type: event.vertical_tab_close, payload: tabId });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
    /**
     * function responsible for closing the tab
     */
    function onClickCancelButtonHandler() {
      dispatch({ type: event.vertical_tab_close, payload: tabId });
      dispatch({ type: event.reloadGrid });
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <TPLoadingOverlay active={loading}>
        <div
          id="OutboundMailAccountCloneContainer"
          key="OutboundMailAccountCloneContainer"
          style={styles.container}
        >
          <TPPageTitle
            id="OutboundMailAccountCloneTitle"
            key="OutboundMailAccountCloneTitle"
            style={styles.title}
          >
            {m?.[e.TitleLabel]}
          </TPPageTitle>

          <hr style={styles.width50} />

          <h4
            id="OutboundMailAccountCloneDescription"
            key="OutboundMailAccountCloneDescription"
          >
            {m?.[e.DescriptionLabel]}
          </h4>

          <form
            id="OutboundMailAccountCloneForm"
            key="OutboundMailAccountCloneForm"
            style={styles.form}
          >
            <div style={styles.width50}>
              <TPTextBox
                id="OutboundMailAccountCloneFormInputSourceId"
                key="OutboundMailAccountCloneFormInputSourceId"
                disabled={true}
                labelText={m?.[e.FormInputSourceIdLabel]}
                isMandatory={true}
                value={`${element.localizedDescription} (${element.idAccount})`}
                onChange={() => {}}
                errorMessage={
                  formik.errors.baseAccountId &&
                  m?.[formik.errors.baseAccountId]
                }
              />
            </div>

            <div style={styles.width50}>
              <TPTextBox
                id="OutboundMailAccountCloneFormInputId"
                key="OutboundMailAccountCloneFormInputId"
                disabled={true}
                labelText={m?.[e.FormInputIdLabel]}
                isMandatory={true}
                value={formik.values.newAccountId}
                onChange={() => {}}
                errorMessage={
                  formik.errors.newAccountId && m?.[formik.errors.newAccountId]
                }
              />
            </div>

            <div style={styles.width50}>
              <TPTextBox
                id="OutboundMailAccountCloneFormInputName"
                key="OutboundMailAccountCloneFormInputName"
                isMandatory={true}
                labelText={`${m?.[e.FormInputNameLabel]}`}
                value={formik.values?.description ?? ""}
                onChange={(e: any) =>
                  formik.setFieldValue("description", e.target.value)
                }
                maxLength={100}
                errorMessage={
                  formik.errors.description && m?.[formik.errors.description]
                }
              />
            </div>
          </form>

          <Box
            id="OutboundMailAccountCloneSectionActions"
            key="OutboundMailAccountCloneSectionActions"
            sx={styles.actions}
          >
            <TPButton
              id="OutboundMailAccountCloneSectionActionsButtonCancel"
              key="OutboundMailAccountCloneSectionActionsButtonCancel"
              style={styles.cancelButton}
              type={TPButtonTypes.link}
              onClick={onClickCancelButtonHandler}
            >
              {m?.[e.SectionActionsButtonCancelLabel]}
            </TPButton>

            <TPButton
              id="OutboundMailAccountCloneSectionActionsButtonSave"
              key="OutboundMailAccountCloneSectionActionsButtonSave"
              type={TPButtonTypes.primary}
              onClick={formik.handleSubmit}
            >
              {m?.[e.SectionActionsButtonSaveLabel]}
            </TPButton>
          </Box>
        </div>
      </TPLoadingOverlay>
    );
  }
);

export default OutboundMailAccountClone;
