import styled from "styled-components";
export const TPCheckboxContainer = styled.div`
  padding: 2px;
  padding-left: 0;
  border-radius: 6px;
  justify-content: center;
  display: flex;
  gap: 10px;
  margin-bottom: 2px;

  label {
    font-size: 14px;
  }
`;
export const TPCheckBoxStyled = styled.input(
  ({ theme }) => `
  &.form-check-input {  
    margin-left: 0;
    min-width: 16px;
    min-height: 16px;
    border-radius: 2px;
    cursor: pointer;
    border-color: ${theme.colors.checkbox.border};
    
    &:checked{
        background-color: ${theme.colors.checkbox.checked.backgroundColor};
        border-color: ${theme.colors.checkbox.checked.borderColor};
    }

    &:focus {
      border-color: ${theme.colors.checkbox.focus.borderColor};
      box-shadow: none;      
    }
  
  }
`,
);
