import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { GroupDashboardCaseModel, GroupDashboardUserModel, TaskToReassign } from "../models/GroupDashboard/GroupDashboard";


export class GroupDashboardDataService {


    public async getCasesByFilter(
        idGroup: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<any>> {
        let serviceFileName = "GroupDashboardService.ts";


        let httpclient = new TPHTTPService();
        let url: string;
        url = "/cases/group-follow-up/";
        url += idGroup;
        //"2F40A58B-DCAA-45F3-A6D0-0A62B3E509FD";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );        
            return response.responseData.data;
        } catch (error) {
            console.log(error);
            TPLog.Log(
                `Error ${serviceFileName} getCasesByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getCasesByFilter`);
            throw new Error(`Error ${serviceFileName} getCasesByFilter`);
        }
    }

    public async getUserByFilter(
        filterActive: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<any>> {
        let serviceFileName = "GroupDashboardService.ts";

        let httpclient = new TPHTTPService();
        let url: string;
        url = "/cases/group-user-follow-up/";
        url += filterActive; //TPGlobal.currentUserGuid;
       // url = url + filterActive;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );

            return response.responseData.data;
        } catch (error) {
            console.log(error);
            TPLog.Log(
                `Error ${serviceFileName} getByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getByFilter`);
            throw new Error(`Error ${serviceFileName} getByFilter`);
        }
    }



    public async getActiveGroup(
        filterActive: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<any>> {
        let serviceFileName = "GroupDashboardService.ts";

        let httpclient = new TPHTTPService();
        let url: string;
        url = "/users/superior/"
        url += TPGlobal.currentUserGuid;
        url +=  "?SearchTerms=" + filterActive;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );

            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} getUsersByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getUsersByFilter`);
            throw new Error(`Error ${serviceFileName} getUsersByFilter`);
        }
    }



    public async getUsersResponsibleByFilter(       
        id: string,
        filterActive: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<any>> {
        let serviceFileName = "GroupDashboardService.ts";

        let httpclient = new TPHTTPService();
        let url: string;
        url = "/users/active/";
        url += id; 
        url += "?SearchTerms=" + (filterActive ? filterActive:"") ;

       

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} getUsersByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getUsersByFilter`);
            throw new Error(`Error ${serviceFileName} getUsersByFilter`);
        }
    }


    public async reassignCaseInGroup(
        inputDTO: TaskToReassign,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let serviceFileName = "GroupDashboardService.ts";
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/cases/reassign-group";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} reassignCaseInGroup ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} reassignCaseInGroup`);
            throw new Error(`Error ${serviceFileName} reassignCaseInGroup`);
        }
    }

}
