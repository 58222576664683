import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface InboundMailAzureApplicationDTO {
  id: string;
  description: string;
  // localizedDescription: string;
  descriptionLocalizedValues: Array<RecordLocalizedModel>;
  azureTenantId: string;
  azureClientId: string;
  azureClientSecret: string;
  authenticationURL: string;
  redirectCallbackURL: string;
  isActive: boolean;
}

export class InboundMailAzureApplicationViewModelValidator extends Validator<InboundMailAzureApplicationDTO> {
  constructor() {
    super();

    const resourceSet = "InboundMailAzureApplicationAdminInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notNull()
        .withMessage(resourceSet + "|InputDTOIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")

        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")

        .must((id) => {
          return TPGlobal.TPSanitize(id) === id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notNull()
        .withMessage(resourceSet + "|InputDTODescriptionNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty");

      // .must((Description) => {
      //     return TPGlobal.TPSanitize(Description) === Description;
      // })
      // .withMessage(resourceSet + "|InputDTOInvalidDescription")

      // .maxLength(200)
      // .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("descriptionLocalizedValues")
        .must((descriptionLocalizedValues) => {
          if (
            descriptionLocalizedValues.localizedValue.trim() === "" ||
            descriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return (
            TPGlobal.TPSanitize(descriptionLocalizedValues.localizedValue) ===
            descriptionLocalizedValues.localizedValue
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLocalizedValues")
        .must((descriptionLocalizedValues) => {
          if (
            descriptionLocalizedValues.localizedValue.trim() === "" ||
            descriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return descriptionLocalizedValues.localizedValue.length <= 200;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidDescriptionLocalizedValuesLength",
        );

      this.ruleFor("azureTenantId")
        .notEmpty()
        .withMessage(resourceSet + "|InputAzureTenantIdEmpty");

      this.ruleFor("azureClientId")
        .notEmpty()
        .withMessage(resourceSet + "|InputAzureClientIdEmpty");

      this.ruleFor("azureClientSecret")
        .notEmpty()
        .withMessage(resourceSet + "|InputAzureClientSecretEmpty");

      this.ruleFor("authenticationURL")
        .notEmpty()
        .withMessage(resourceSet + "|InputAuthenticationURLEmpty");

      this.ruleFor("redirectCallbackURL")
        .notEmpty()
        .withMessage(resourceSet + "|InputRedirectCallbackURLEmpty");
    } catch (error) {
      // Handle any specific validation-related errors.
      console.error("Validation error:", error);
    }
  }
}
