import { motion } from "framer-motion";
import styled from "styled-components";

export const TPCopilotWindowStyle = styled (motion.section)`
    
    position: absolute;
    right: 0;
    top: 100%;
    width: 360px;
    height: calc(100vh - 78px);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    padding: 3px;
    background: linear-gradient(90deg, #780096 0%, #8051FF 100%);
    font-size: 14px;
    line-height: 19px;
    transform-origin: top right;
    
    .copilot-container {
        height: 100%;
        background-color: #F4F4F4;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        &.open {
            display: block;
        }
    }
    
    #webchat {
        height: 100%;
        width: 100%;
        .webchat__css-custom-properties {
            & > div[class^='webchat--css'] {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 8px;
            }
        }
        .webchat__send-box__main {
            border-bottom-left-radius: 8px;
        }
    }

    .motion-test {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: red;
    }

`