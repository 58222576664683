import TPModal from "@/layouts/TPModal/TPModal";
import { FC } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";

interface TPModalAlertProperties {
  visible: boolean;
  onDesicion: (confirmed: boolean) => void;
  content?: string | JSX.Element;
  acceptLabel?: string;
  cancelLabel?: string;
  hideCancelButton?: boolean;
  hideAcceptButton?: boolean;
}

export const TPModalAlert: FC<TPModalAlertProperties> = function ({ content, onDesicion, visible, acceptLabel, hideAcceptButton, cancelLabel, hideCancelButton }) {
  return (
    <>
      <TPModal
        modalState={{
          acceptLabel: '',
          callBackAnswer: () => { },
          isShown: visible,
          titleModal: '',
          hiddenHeader: true,
          hideFooterButtons: true,
          hideXButton: true,
          modalWidth: '512px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '32px',
            padding: '16px',
            textAlign: 'center'
          }}
        >
          {content && (typeof content == "string") ? <label>{content}</label> : content}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '50%',
              gap: '16px'
            }}
          >
            {!hideCancelButton &&
            <TPButton
              onClick={() => onDesicion(false)}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: 'white',
                color: 'purple'
              }}
            >
              {cancelLabel || '_cancel'}
              </TPButton>}
            {!hideAcceptButton &&
            <TPButton
              onClick={() => onDesicion(true)}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              {acceptLabel || '_accept'}
              </TPButton>}
          </div>
        </div>
      </TPModal>
    </>
  )
}