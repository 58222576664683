import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "react-loading-skeleton/dist/skeleton.css";

import "bootstrap/dist/js/bootstrap.bundle";

import "react-quill/dist/quill.snow.css";

import { AppContainerStyled } from "./AppStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <AppContainerStyled>
    <App />
  </AppContainerStyled>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
