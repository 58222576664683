import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPIconSelector from "@/components/TPIconSelector/TPIconSelector";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import {
  MenuItemInputDTO,
  MenuItemInputDTOValidator,
} from "@/models/MenuItems/MenuItemInputDTO";
import { MenuItemViewModel } from "@/models/MenuItems/MenuItemsModels";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TPI18N } from "@/services/I18nService";
import { MenuItemsService } from "@/services/MenuItemService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import React, { FC, useEffect, useReducer, useState } from "react";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};
enum MenuItemType {
  EMPTY = "EMPTY",
  NEW_SECTION = "NEW_SECTION",
  NEW_GROUP = "NEW_GROUP",
  NEW_ITEM = "NEW_ITEM",
}

type ScreenState = {
  renderMode: MenuItemType;
  id: string;
  isActive: boolean;
  parent: string;
  externalPublicKey: string;
  type: string;
  uRLLink: string;
  uRLLinkParameters: string;
  image: string;

  recordLanguageList: Array<TPKeyValue>;
  [x: string]: any;
};

type ErrorState = {
  renderModeErrorMessage: string;
  idErrorMessage: string;
  parentErrorMessage: string;
  externalPublicKeyErrorMessage: string;
  typeErrorMessage: string;
  uRLLinkErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  uRLLinkParametersErrorMessage: string;
  imageErrorMessage: string;
  [x: string]: any;
};

enum CommandsEnum {
  "change_render_mode",
  "change_property_value",
  "set_record_language_list",
  "handle_language_change",
  "set_render_mode_and_load_data",
}
type CommandType = {
  type: CommandsEnum;
  payload: any;
};

const MenuDefinitionInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}) => {
  const componentFileName: string = "MenuDefinitionInsertUpdate.tsx";
  const resourceSet: string = "MenuDefinitionInsertUpdateComponent";

  //Multilanguage const
  const multilanguageTableName: String = "MEnuITem";
  const multilanguageFieldName: String = "Description_MEIT";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //#region screen Resources
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  //#end region

  //simple state
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [renderModeList, setRenderModeList] = useState<Array<TPKeyValue>>([]);
  const [sectionList, setSectionList] = useState<Array<TPKeyValue>>([]);
  const [groupList, setGroupList] = useState<Array<TPKeyValue>>([]);
  //error state screen
  const initialErrorState: ErrorState = {
    renderModeErrorMessage: "",
    idErrorMessage: "",
    descriptionErrorMessages: [],
    parentErrorMessage: "",
    externalPublicKeyErrorMessage: "",
    typeErrorMessage: "",
    uRLLinkErrorMessage: "",
    uRLLinkParametersErrorMessage: "",
    imageErrorMessage: "",
  };
  const [errorState, setErrorState] = useState<ErrorState>(initialErrorState);
  const loadResources = async () => {
    let newMenuItemDataGroupFiltered: Array<TPKeyValue> = [];
    setTitleLabel(await TPI18N.GetText(resourceSet, `Title${mode}Label`));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubTitleLabel"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    let newRenderModeList = [...renderModeList];
    newRenderModeList.push({
      key: "EMPTY",
      value: "--",
    });
    newRenderModeList.push({
      key: "NEW_SECTION",
      value: await TPI18N.GetText(resourceSet, "SectionLabel"),
    });
    newRenderModeList.push({
      key: MenuItemType.NEW_GROUP.toString(),
      value: await TPI18N.GetText(resourceSet, "GroupLabel"),
    });
    newRenderModeList.push({
      key: "NEW_ITEM",
      value: await TPI18N.GetText(resourceSet, "ItemLabel"),
    });
    setRenderModeList(newRenderModeList);

    let newMenuItemData: Array<MenuItemViewModel> = await getMenuItemData();
    if (newMenuItemData) {
      let newSectionList = [...sectionList];
      let newGroupList = [...groupList];
      let newMenuItemDataSectionFiltered: Array<MenuItemViewModel> =
        newMenuItemData.filter((x) => x.isSection);
      for (
        let index = 0;
        index < newMenuItemDataSectionFiltered.length;
        index++
      ) {
        const element = newMenuItemDataSectionFiltered[index];
        newSectionList.push({
          key: element.id,
          value: element.localizedDescription,
        });
      }
      newSectionList.unshift({ key: "", value: "--" });
      setSectionList(newSectionList);

      const menuHierarchy = newMenuItemDataSectionFiltered.map((root) => {
        return {
          ...root,
          subMenus: newMenuItemData
            .filter((menuItem) => menuItem.parent === root.id)
            .map((secondLevel) => {
              return {
                ...secondLevel,
                subMenus: newMenuItemData.filter(
                  (menuItem2) => menuItem2.parent === secondLevel.id,
                ),
              };
            }),
        };
      });

      menuHierarchy.map((item) => {
        item.subMenus.map((item2) => {
          let oneValue: TPKeyValue = {
            key: item2.id,
            value: item2.localizedDescription,
          };
          newMenuItemDataGroupFiltered.push(oneValue);
        });
      });
      newMenuItemDataGroupFiltered.unshift({ key: "", value: "--" });
      setGroupList(newMenuItemDataGroupFiltered);
    }

    if (realMode === "Insert") {
      let newLanguageList: Array<TPKeyValue> = [];
      for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
      ) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newLanguageList.push(keyValueElement);
      }
      dispachCommand({
        type: CommandsEnum.set_record_language_list,
        payload: { newLanguageList: newLanguageList },
      });
    } else {
      await getById(realRecordId, newMenuItemDataGroupFiltered);
    }
  };

  const getById = async (
    pRecordId: string,
    newMenuItemDataGroupFiltered: Array<TPKeyValue>,
  ) => {
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];

    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest = await MenuItemsService.getById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );
      let recordInfo: MenuItemViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );
      let newRenderMode: MenuItemType = MenuItemType.EMPTY;
      if (recordInfo.isSection) {
        newRenderMode = MenuItemType.NEW_SECTION;
      } else {
        let foundGroup: boolean = false;
        for (let i = 0; i < newMenuItemDataGroupFiltered.length; i++) {
          const element = newMenuItemDataGroupFiltered[i];
          if (element.key === recordInfo.id) {
            //The elemet is a group
            foundGroup = true;
            break;
          }
        }
        if (foundGroup) {
          newRenderMode = MenuItemType.NEW_GROUP;
        } else {
          newRenderMode = MenuItemType.NEW_ITEM;
        }
      }

      let newRecordLanguageList: Array<TPKeyValue> = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newRecordLanguageList.push(keyValueElement);
      }

      newRecordLanguageList[0].value = recordInfo.description;
      let expectedcodes: Array<number> = [200];
      recordLanguagesList = await getRecordLanguageList(
        pRecordId,
        multilanguageTableName,
        multilanguageFieldName,
        expectedcodes,
      );

      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }

      for (i = 0; i <= newRecordLanguageList.length - 1; i++) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newRecordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newRecordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }

      dispachCommand({
        type: CommandsEnum.set_render_mode_and_load_data,
        payload: {
          newRenderMode: newRenderMode,
          newExternalPublicKey: recordInfo.externalPublicKey,
          newId: recordInfo.id,
          newImage: recordInfo.image,
          newIsActive: recordInfo.isActive,
          newParent: recordInfo.parent,
          newRecordLanguageList: newRecordLanguageList,
          newType: recordInfo.type,
          newURLLink: recordInfo.urlLink,
          newURLLinkParameters: recordInfo.urlLinkParameters,
        },
      });

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
    _multilanguageTableName: String,
    _multilanguageFieldName: String,
    _expectedCodes: Array<number>,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          _multilanguageTableName,
          _multilanguageFieldName,
          pRecordId,
          false,
          true,
          _expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelSection = () => {
    //Close vertical tab
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleOnRenderModeChange = async (e: any) => {
    dispachCommand({
      type: CommandsEnum.change_property_value,
      payload: { propertyName: "renderMode", newPropertyValue: e.target.value },
    });
    let newErrorState = { ...errorState };
    newErrorState.renderModeErrorMessage = "";
    setErrorState(newErrorState);
  };

  const handleOnSectionSave = async () => {
    if (screenState.renderMode === MenuItemType.NEW_SECTION) {
      let n: number;
      let i: number;
      let recordInputDTO: MenuItemInputDTO = {
        id: screenState.id,
        description: screenState.recordLanguageList[0].value,
        parent: null,
        type: null,
        uRLLink: null,
        uRLLinkParameters: null,
        image: null,
        width: null,
        height: null,
        externalPublicKey: null,
        isActive: screenState.isActive,
        isSection: true,
        descriptionLocalizedValues: [],
      };
      n = screenState.recordLanguageList.length;
      for (i = 0; i <= n - 1; i++) {
        let item: TPKeyValue;
        item = screenState.recordLanguageList[i];
        recordInputDTO.descriptionLocalizedValues.push({
          order: i + 1,
          languageId: item.key,
          localizedValue: item.value,
        });
      }

      let inputDTOValidator = new MenuItemInputDTOValidator(
        screenState.renderMode,
      );
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let newErrorState = { ...errorState };
        var listPropertyNames = Object.keys(resultValidator);
        if (listPropertyNames) {
          for (let index = 0; index < listPropertyNames.length; index++) {
            const element = listPropertyNames[index];
            if (element === "descriptionLocalizedValues") {
              let arrayDescriptionError: any =
                resultValidator.descriptionLocalizedValues;
              for (
                let index = 0;
                index < arrayDescriptionError.length;
                index++
              ) {
                const element2 = arrayDescriptionError[index];
                if (element2 != null) {
                  newErrorState.descriptionErrorMessages[index] =
                    await TPI18N.GetResource(element2 as string);
                }
              }
            } else {
              if (element === "description") {
                newErrorState.descriptionErrorMessages[0] =
                  await TPI18N.GetResource(resultValidator[element] as string);
              } else {
                if (resultValidator[element]) {
                  newErrorState[element + "ErrorMessage"] =
                    await TPI18N.GetResource(
                      resultValidator[element] as string,
                    );
                } else {
                  newErrorState[element + "ErrorMessage"] = "";
                }
              }
            }
          }
        }
        setErrorState(newErrorState);
        return;
      }

      if (realMode === "Insert") {
        await insertMenuItem(recordInputDTO);
      } else if (realMode === "Update") {
        await updateMenuItem(recordInputDTO);
      }
    }
  };
  const handleOnGroupSave = async () => {
    if (screenState.renderMode === MenuItemType.NEW_GROUP) {
      let n: number;
      let i: number;
      let recordInputDTO: MenuItemInputDTO = {
        id: screenState.id,
        description: screenState.recordLanguageList[0].value,
        parent: screenState.parent,
        type: null,
        uRLLink: null,
        uRLLinkParameters: null,
        image: screenState.image,
        width: null,
        height: null,
        externalPublicKey: null,
        isActive: screenState.isActive,
        isSection: false,
        descriptionLocalizedValues: [],
      };
      n = screenState.recordLanguageList.length;
      for (i = 0; i <= n - 1; i++) {
        let item: TPKeyValue;
        item = screenState.recordLanguageList[i];
        recordInputDTO.descriptionLocalizedValues.push({
          order: i + 1,
          languageId: item.key,
          localizedValue: item.value,
        });
      }

      let inputDTOValidator = new MenuItemInputDTOValidator(
        screenState.renderMode,
      );
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let newErrorState = { ...errorState };
        var listPropertyNames = Object.keys(resultValidator);
        if (listPropertyNames) {
          for (let index = 0; index < listPropertyNames.length; index++) {
            const element = listPropertyNames[index];
            if (element === "descriptionLocalizedValues") {
              let arrayDescriptionError: any =
                resultValidator.descriptionLocalizedValues;
              for (
                let index = 0;
                index < arrayDescriptionError.length;
                index++
              ) {
                const element2 = arrayDescriptionError[index];
                if (element2 != null) {
                  newErrorState.descriptionErrorMessages[index] =
                    await TPI18N.GetResource(element2 as string);
                }
              }
            } else {
              if (element === "description") {
                newErrorState.descriptionErrorMessages[0] =
                  await TPI18N.GetResource(resultValidator[element] as string);
              } else {
                if (resultValidator[element]) {
                  newErrorState[element + "ErrorMessage"] =
                    await TPI18N.GetResource(
                      resultValidator[element] as string,
                    );
                } else {
                  newErrorState[element + "ErrorMessage"] = "";
                }
              }
            }
          }
        }
        setErrorState(newErrorState);
        return;
      }

      if (realMode === "Insert") {
        await insertMenuItem(recordInputDTO);
      } else if (realMode === "Update") {
        await updateMenuItem(recordInputDTO);
      }
    }
  };
  const handleOnItemSave = async () => {
    if (screenState.renderMode === MenuItemType.NEW_ITEM) {
      let n: number;
      let i: number;
      let recordInputDTO: MenuItemInputDTO = {
        id: screenState.id,
        description: screenState.recordLanguageList[0].value,
        parent: screenState.parent,
        type: null,
        uRLLink: screenState.uRLLink,
        uRLLinkParameters: screenState.uRLLinkParameters,
        image: null,
        width: null,
        height: null,
        externalPublicKey: screenState.externalPublicKey,
        isActive: screenState.isActive,
        isSection: false,
        descriptionLocalizedValues: [],
      };
      n = screenState.recordLanguageList.length;
      for (i = 0; i <= n - 1; i++) {
        let item: TPKeyValue;
        item = screenState.recordLanguageList[i];
        recordInputDTO.descriptionLocalizedValues.push({
          order: i + 1,
          languageId: item.key,
          localizedValue: item.value,
        });
      }

      let inputDTOValidator = new MenuItemInputDTOValidator(
        screenState.renderMode,
      );
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let newErrorState = { ...errorState };
        var listPropertyNames = Object.keys(resultValidator);
        if (listPropertyNames) {
          for (let index = 0; index < listPropertyNames.length; index++) {
            const element = listPropertyNames[index];
            if (element === "descriptionLocalizedValues") {
              let arrayDescriptionError: any =
                resultValidator.descriptionLocalizedValues;
              for (
                let index = 0;
                index < arrayDescriptionError.length;
                index++
              ) {
                const element2 = arrayDescriptionError[index];
                if (element2 != null) {
                  newErrorState.descriptionErrorMessages[index] =
                    await TPI18N.GetResource(element2 as string);
                }
              }
            } else {
              if (element === "description") {
                newErrorState.descriptionErrorMessages[0] =
                  await TPI18N.GetResource(resultValidator[element] as string);
              } else {
                if (resultValidator[element]) {
                  newErrorState[element + "ErrorMessage"] =
                    await TPI18N.GetResource(
                      resultValidator[element] as string,
                    );
                } else {
                  newErrorState[element + "ErrorMessage"] = "";
                }
              }
            }
          }
        }
        setErrorState(newErrorState);
        return;
      }

      if (realMode === "Insert") {
        await insertMenuItem(recordInputDTO);
      } else if (realMode === "Update") {
        await updateMenuItem(recordInputDTO);
      }
    }
  };
  const insertMenuItem = async (inputDTO: MenuItemInputDTO) => {
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await MenuItemsService.insert(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: inputDTO.id,
        });
        //change mode
        setTitleLabel(
          await TPI18N.GetText(resourceSet, "TitleUpdateSectionLabel"),
        );
        setRealRecordId(inputDTO.id);
        setRealMode("Update");
        setOriginalRecordDescription(
          inputDTO.descriptionLocalizedValues[0].localizedValue.length > 100
            ? inputDTO.descriptionLocalizedValues[0].localizedValue.substring(
                0,
                100,
              ) + "..."
            : inputDTO.descriptionLocalizedValues[0].localizedValue,
        );
      }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertMenuItem ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertMenuItem ex`);
      setIsLoadingScreen(false);
    }
  };
  const updateMenuItem = async (inputDTO: MenuItemInputDTO) => {
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await MenuItemsService.update(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      if (responseRequest.responseResult) {
        setRealRecordId(inputDTO.id);
      }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateMenuItem ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateMenuItem ex`);
      setIsLoadingScreen(false);
    }
  };

  const getMenuItemData = async () => {
    let expectedCodes: Array<number> = [200, 404];

    try {
      let response: Array<MenuItemViewModel> =
        await MenuItemsService.getByFilter(
          TPActiveOptions.ALL.toString(),
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      if (response && response.length > 0) {
        return [...response];
      } else {
        return [];
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getMenuItemData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getMenuItemData ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const initialScreenState: ScreenState = {
    renderMode: MenuItemType.EMPTY,
    id: "",
    recordLanguageList: [],
    descriptionErrorMessages: [],
    isActive: false,
    parent: "",
    externalPublicKey: "",
    type: "",
    uRLLink: "",
    uRLLinkParameters: "",
    image: "",
  };
  const [screenState, dispachCommand] = useReducer(
    doCommand,
    initialScreenState,
  );

  function doCommand(prevState: ScreenState, command: CommandType) {
    let newScreenState = { ...prevState };
    switch (command.type) {
      case CommandsEnum.change_property_value:
        newScreenState[command.payload.propertyName] =
          command.payload.newPropertyValue;
        let newErrorState = { ...errorState };
        newErrorState[`${command.payload.propertyName}ErrorMessage`] = "";
        setErrorState(newErrorState);
        return newScreenState;
      case CommandsEnum.change_render_mode:
        newScreenState.renderMode = command.payload.newRenderMode;
        return newScreenState;
      case CommandsEnum.set_record_language_list:
        newScreenState.recordLanguageList = command.payload.newLanguageList;
        return newScreenState;
      case CommandsEnum.handle_language_change:
        newScreenState.recordLanguageList[command.payload.index].value =
          command.payload.newName;
        newScreenState.descriptionErrorMessages[command.payload.index] = "";
        let newErrorState2 = { ...errorState };
        newErrorState2.descriptionErrorMessages[command.payload.index] = "";
        setErrorState(newErrorState2);
        return newScreenState;
      case CommandsEnum.set_render_mode_and_load_data:
        newScreenState.renderMode = command.payload.newRenderMode;
        newScreenState.externalPublicKey = command.payload.newExternalPublicKey;
        newScreenState.id = command.payload.newId;
        newScreenState.image = command.payload.newImage;
        newScreenState.isActive = command.payload.newIsActive;
        newScreenState.parent = command.payload.newParent;
        newScreenState.recordLanguageList = [
          ...command.payload.newRecordLanguageList,
        ];
        newScreenState.type = command.payload.newType;
        newScreenState.uRLLink = command.payload.newURLLink;
        newScreenState.uRLLinkParameters = command.payload.newURLLinkParameters;
        return newScreenState;
      default:
        return prevState;
    }
  }

  const {
    isOpen: isOpenModalSectionLanguageList,
    openModal: handleOpenModalSectionLanguageList,
    closeModal: handleCloseModalSectionLanguageList,
    saveChanges: handleSaveChangesModalSectionLanguageList,
  } = useModal(false);

  const {
    isOpen: isOpenModalGroupLanguageList,
    openModal: handleOpenModalGroupLanguageList,
    closeModal: handleCloseModalGroupLanguageList,
    saveChanges: handleSaveChangesModalGroupLanguageList,
  } = useModal(false);

  const {
    isOpen: isOpenModalItemLanguageList,
    openModal: handleOpenModalItemLanguageList,
    closeModal: handleCloseModalItemLanguageList,
    saveChanges: handleSaveChangesModalItemLanguageList,
  } = useModal(false);

  useEffect(() => {
    loadResources();
    setIsLoadingScreen(false);
  }, []);
  return (
    <>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {realMode == "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel}: ${screenState.id} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
          </div>
          <hr />
        </div>
        <div className="row">
          <div className="col">
            <TPPageSectionTitle>
              <TPLanguage
                resourceSet={resourceSet}
                resourceId="RenderModeLabel"
              />
            </TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TPPageSection>
              <div className="form-group">
                <TPSelect
                  id="IdSelect"
                  labelText={
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId="RenderSectionLabel"
                    />
                  }
                  onChange={handleOnRenderModeChange}
                  dataSource={renderModeList}
                  value={screenState.renderMode}
                  isHorizontal={false}
                  errorMessage={errorState.renderModeErrorMessage}
                  disabled={realMode === "Update"}
                />
              </div>
            </TPPageSection>
          </div>
        </div>
        {screenState.renderMode === MenuItemType.NEW_SECTION && (
          <>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={resourceSet}
                    resourceId="DescriptionSectionLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          onChange={(e: any) =>
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "id",
                                newPropertyValue: e.target.value,
                              },
                            })
                          }
                          value={screenState.id}
                          labelText={
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId="SectionCodeLabel"
                            />
                          }
                          maxLength={20}
                          disabled={realMode == "Insert" ? false : true}
                          errorMessage={errorState.idErrorMessage}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {screenState.recordLanguageList.length > 0 &&
                      TPGlobal.TPClientAvailableLanguages.map(
                        (item, index) =>
                          index === 0 && (
                            <div
                              className="col-11"
                              key={`languageItem-${item.id}`}
                            >
                              <div className="form-group">
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={index === 0}
                                  labelText={`${descriptionLabel} (${item.name})`}
                                  value={
                                    screenState.recordLanguageList[index].value
                                  }
                                  onChange={(e: any) =>
                                    dispachCommand({
                                      type: CommandsEnum.handle_language_change,
                                      payload: {
                                        index: index,
                                        newName: e.target.value,
                                      },
                                    })
                                  }
                                  maxLength={500}
                                  errorMessage={
                                    errorState.descriptionErrorMessages[index]
                                  }
                                />
                              </div>
                            </div>
                          ),
                      )}
                    {screenState.recordLanguageList.length > 1 && (
                      <>
                        <div className="col-1">
                          <div className="pt-4">
                            <TPButton
                              type={TPButtonTypes.icon}
                              icon={TPIconTypes.language}
                              text={`+${
                                screenState.recordLanguageList.length - 1
                              }`}
                              tooltip={languageListLabel}
                              className={"pt-3"}
                              onClick={handleOpenModalSectionLanguageList}
                            />
                          </div>
                        </div>
                        <TPModalLanguageList
                          isOpen={isOpenModalSectionLanguageList}
                          title={languageListLabel}
                          acceptLabel={saveButtonLabel}
                          cancelLabel={cancelButtonLabel}
                          saveChanges={
                            handleSaveChangesModalSectionLanguageList
                          }
                          closeModal={handleCloseModalSectionLanguageList}
                        >
                          <div
                            className="row overflow-auto"
                            style={{ height: "200px" }}
                          >
                            {TPGlobal.TPClientAvailableLanguages.map(
                              (item, index) =>
                                index > 0 && (
                                  <div
                                    className="col-12"
                                    key={`languageItem-${item.id}`}
                                  >
                                    <div className="form-group">
                                      <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={false}
                                        labelText={`${descriptionLabel} (${item.name})`}
                                        value={
                                          screenState.recordLanguageList[index]
                                            .value
                                        }
                                        onChange={(e: any) =>
                                          dispachCommand({
                                            type: CommandsEnum.handle_language_change,
                                            payload: {
                                              index: index,
                                              newName: e.target.value,
                                            },
                                          })
                                        }
                                        maxLength={500}
                                        errorMessage={
                                          errorState.descriptionErrorMessages[
                                            index
                                          ]
                                        }
                                      />
                                    </div>
                                  </div>
                                ),
                            )}
                          </div>
                        </TPModalLanguageList>
                      </>
                    )}
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={TPGlobal.globalResourceSet}
                    resourceId="ParametersSectionLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="isActiveLabel"
                        />
                      }
                      checked={screenState.isActive}
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "isActive",
                            newPropertyValue: e.target.checked,
                          },
                        })
                      }
                    ></TPCheckBox>
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <TPPageAcceptCancelButtonsContainer>
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={handleOnSectionSave}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"SaveButton"}
                    />
                  </TPButton>
                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={handleCancelSection}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"CancelButton"}
                    />
                  </TPButton>
                </TPPageAcceptCancelButtonsContainer>
              </div>
            </div>
          </>
        )}
        {screenState.renderMode === MenuItemType.NEW_GROUP && (
          <>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={resourceSet}
                    resourceId="DescriptionGroupLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "id",
                            newPropertyValue: e.target.value,
                          },
                        })
                      }
                      value={screenState.id}
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="SectionCodeLabel"
                        />
                      }
                      maxLength={20}
                      disabled={realMode == "Insert" ? false : true}
                      errorMessage={errorState.idErrorMessage}
                    />
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TPPageSection>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          isMandatory={true}
                          labelText={
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId={"SectionLabel"}
                            />
                          }
                          onChange={(e: any) =>
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "parent",
                                newPropertyValue: e.target.value,
                              },
                            })
                          }
                          dataSource={sectionList}
                          value={screenState.parent}
                          errorMessage={errorState.parentErrorMessage}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {screenState.recordLanguageList.length > 0 &&
                      TPGlobal.TPClientAvailableLanguages.map(
                        (item, index) =>
                          index === 0 && (
                            <div
                              className="col-11"
                              key={`languageItem-${item.id}`}
                            >
                              <div className="form-group">
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={index === 0}
                                  labelText={`${descriptionLabel} (${item.name})`}
                                  value={
                                    screenState.recordLanguageList[index].value
                                  }
                                  onChange={(e: any) =>
                                    dispachCommand({
                                      type: CommandsEnum.handle_language_change,
                                      payload: {
                                        index: index,
                                        newName: e.target.value,
                                      },
                                    })
                                  }
                                  maxLength={500}
                                  errorMessage={
                                    errorState.descriptionErrorMessages[index]
                                  }
                                />
                              </div>
                            </div>
                          ),
                      )}
                    {screenState.recordLanguageList.length > 1 && (
                      <>
                        <div className="col-1">
                          <div className="pt-4">
                            <TPButton
                              type={TPButtonTypes.icon}
                              icon={TPIconTypes.language}
                              text={`+${
                                screenState.recordLanguageList.length - 1
                              }`}
                              tooltip={languageListLabel}
                              className={"pt-3"}
                              onClick={handleOpenModalGroupLanguageList}
                            />
                          </div>
                        </div>
                        <TPModalLanguageList
                          isOpen={isOpenModalGroupLanguageList}
                          title={languageListLabel}
                          acceptLabel={saveButtonLabel}
                          cancelLabel={cancelButtonLabel}
                          saveChanges={handleSaveChangesModalGroupLanguageList}
                          closeModal={handleCloseModalGroupLanguageList}
                        >
                          <div
                            className="row overflow-auto"
                            style={{ height: "200px" }}
                          >
                            {TPGlobal.TPClientAvailableLanguages.map(
                              (item, index) =>
                                index > 0 && (
                                  <div
                                    className="col-12"
                                    key={`languageItem-${item.id}`}
                                  >
                                    <div className="form-group">
                                      <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={false}
                                        labelText={`${descriptionLabel} (${item.name})`}
                                        value={
                                          screenState.recordLanguageList[index]
                                            .value
                                        }
                                        onChange={(e: any) =>
                                          dispachCommand({
                                            type: CommandsEnum.handle_language_change,
                                            payload: {
                                              index: index,
                                              newName: e.target.value,
                                            },
                                          })
                                        }
                                        maxLength={500}
                                        errorMessage={
                                          errorState.descriptionErrorMessages[
                                            index
                                          ]
                                        }
                                      />
                                    </div>
                                  </div>
                                ),
                            )}
                          </div>
                        </TPModalLanguageList>
                      </>
                    )}
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={TPGlobal.globalResourceSet}
                    resourceId="ParametersSectionLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPIconSelector
                          defaultIconName={screenState.image}
                          onIconSelected={(iconName: string) => {
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "image",
                                newPropertyValue: iconName,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col mt-4">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId="isActiveLabel"
                            />
                          }
                          checked={screenState.isActive}
                          onChange={(e: any) =>
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "isActive",
                                newPropertyValue: e.target.checked,
                              },
                            })
                          }
                        ></TPCheckBox>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col">
                <TPPageAcceptCancelButtonsContainer>
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={handleOnGroupSave}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"SaveButton"}
                    />
                  </TPButton>
                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={handleCancelSection}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"CancelButton"}
                    />
                  </TPButton>
                </TPPageAcceptCancelButtonsContainer>
              </div>
            </div>
          </>
        )}
        {screenState.renderMode === MenuItemType.NEW_ITEM && (
          <>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={resourceSet}
                    resourceId="DescriptionGroupLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "id",
                            newPropertyValue: e.target.value,
                          },
                        })
                      }
                      value={screenState.id}
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="SectionCodeLabel"
                        />
                      }
                      maxLength={20}
                      disabled={realMode == "Insert" ? false : true}
                      errorMessage={errorState.idErrorMessage}
                    />
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId={"GroupLabel"}
                        />
                      }
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "parent",
                            newPropertyValue: e.target.value,
                          },
                        })
                      }
                      dataSource={groupList}
                      value={screenState.parent}
                      errorMessage={errorState.parentErrorMessage}
                    />
                  </div>

                  <div className="row">
                    {screenState.recordLanguageList.length > 0 &&
                      TPGlobal.TPClientAvailableLanguages.map(
                        (item, index) =>
                          index === 0 && (
                            <div
                              className="col-11"
                              key={`languageItem-${item.id}`}
                            >
                              <div className="form-group">
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={index === 0}
                                  labelText={`${descriptionLabel} (${item.name})`}
                                  value={
                                    screenState.recordLanguageList[index].value
                                  }
                                  onChange={(e: any) =>
                                    dispachCommand({
                                      type: CommandsEnum.handle_language_change,
                                      payload: {
                                        index: index,
                                        newName: e.target.value,
                                      },
                                    })
                                  }
                                  maxLength={500}
                                  errorMessage={
                                    errorState.descriptionErrorMessages[index]
                                  }
                                />
                              </div>
                            </div>
                          ),
                      )}
                    {screenState.recordLanguageList.length > 1 && (
                      <>
                        <div className="col-1">
                          <div className="pt-4">
                            <TPButton
                              type={TPButtonTypes.icon}
                              icon={TPIconTypes.language}
                              text={`+${
                                screenState.recordLanguageList.length - 1
                              }`}
                              tooltip={languageListLabel}
                              className={"pt-3"}
                              onClick={handleOpenModalItemLanguageList}
                            />
                          </div>
                        </div>
                        <TPModalLanguageList
                          isOpen={isOpenModalItemLanguageList}
                          title={languageListLabel}
                          acceptLabel={saveButtonLabel}
                          cancelLabel={cancelButtonLabel}
                          saveChanges={handleSaveChangesModalItemLanguageList}
                          closeModal={handleCloseModalItemLanguageList}
                        >
                          <div
                            className="row overflow-auto"
                            style={{ height: "200px" }}
                          >
                            {TPGlobal.TPClientAvailableLanguages.map(
                              (item, index) =>
                                index > 0 && (
                                  <div
                                    className="col-12"
                                    key={`languageItem-${item.id}`}
                                  >
                                    <div className="form-group">
                                      <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={false}
                                        labelText={`${descriptionLabel} (${item.name})`}
                                        value={
                                          screenState.recordLanguageList[index]
                                            .value
                                        }
                                        onChange={(e: any) =>
                                          dispachCommand({
                                            type: CommandsEnum.handle_language_change,
                                            payload: {
                                              index: index,
                                              newName: e.target.value,
                                            },
                                          })
                                        }
                                        maxLength={500}
                                        errorMessage={
                                          errorState.descriptionErrorMessages[
                                            index
                                          ]
                                        }
                                      />
                                    </div>
                                  </div>
                                ),
                            )}
                          </div>
                        </TPModalLanguageList>
                      </>
                    )}
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  <TPLanguage
                    resourceSet={TPGlobal.globalResourceSet}
                    resourceId="ParametersSectionLabel"
                  />
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          labelText={
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId="ExternalPublicKeyLabel"
                            />
                          }
                          onChange={(e: any) =>
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "externalPublicKey",
                                newPropertyValue: e.target.value,
                              },
                            })
                          }
                          value={screenState.externalPublicKey}
                          errorMessage={
                            errorState.externalPublicKeyErrorMessage
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          labelText={
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId="URLLinkLabel"
                            />
                          }
                          onChange={(e: any) =>
                            dispachCommand({
                              type: CommandsEnum.change_property_value,
                              payload: {
                                propertyName: "uRLLink",
                                newPropertyValue: e.target.value,
                              },
                            })
                          }
                          value={screenState.uRLLink}
                          errorMessage={errorState.uRLLinkErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>

              {/* <div className="col-3">
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="ItemTypeLabel"
                        />
                      }
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "type",
                            newPropertyValue: e.target.value,
                          },
                        })
                      }
                      dataSource={[]}
                      value={screenState.type}
                      isHorizontal={false}
                      errorMessage={errorState.typeErrorMessage}
                    />
                  </div>
                </TPPageSection>
              </div> */}
            </div>
            <div className="row">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPTextArea
                      id="IdTextArea"
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="ParametersLabel"
                        />
                      }
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "uRLLinkParameters",
                            newPropertyValue: e.target.value,
                          },
                        })
                      }
                      value={screenState.uRLLinkParameters}
                      rows={3}
                    />
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <TPPageSection>
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="isActiveLabel"
                        />
                      }
                      checked={screenState.isActive}
                      onChange={(e: any) =>
                        dispachCommand({
                          type: CommandsEnum.change_property_value,
                          payload: {
                            propertyName: "isActive",
                            newPropertyValue: e.target.checked,
                          },
                        })
                      }
                    ></TPCheckBox>
                  </div>
                </TPPageSection>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <TPPageAcceptCancelButtonsContainer>
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={handleOnItemSave}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"SaveButton"}
                    />
                  </TPButton>
                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={handleCancelSection}
                  >
                    <TPLanguage
                      resourceSet={TPGlobal.globalResourceSet}
                      resourceId={"CancelButton"}
                    />
                  </TPButton>
                </TPPageAcceptCancelButtonsContainer>
              </div>
            </div>
          </>
        )}
      </TPLoadingOverlay>
    </>
  );
};

export default MenuDefinitionInsertUpdate;
