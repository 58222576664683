import NewCustomerIcon from "@/assets/images/TPIcons/NewCustomerIcon";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import { TPPageSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { searchCiteriaEnum } from "@/pages/NewCase/NewCase";
import { ClientService } from "@/services/ClientService";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TPModalNewClient from "../TPModalNewClient/TPModalNewClient";

export type TPModalSearchCustomerState = {
  isShown: boolean;
  initialSearchCriteria: searchCiteriaEnum;
  initialSearchValue: string;
  initialAddtionaDataSearchId: string;
};

type TPModalSearchClientProps = {
  title: string;
  acceptLabel: string;
  cancelLabel: string;
  initialState: TPModalSearchCustomerState;
  callBackAnswer: Function;
};

type realSearchStateType = {
  realSearchCriteria: searchCiteriaEnum;
  realSearchValue: string;
  realAddtionaDataSearchId: string;
  flagSearch: boolean;
};

type AdminStateType = {
  gridData: Array<any>;
};

enum commandsEnum {
  "set_griddata" = 0,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const TPModalSearchClient: FC<TPModalSearchClientProps> = ({
  title,
  acceptLabel,
  cancelLabel,
  initialState,
  callBackAnswer,
}): ReactElement => {
  //#region Init
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const componentFileName: string = "TPModalSearchClient.tsx";
  const resourceSet: string = "TPModalSearchClient";
  const externalResourceSet: string = "NewCaseComponent";

  const [searchParametersLabel, setSearchParametersLabel] = useState("");
  const [filterWithinResultsLabel, setFilterWithinResultsLabel] = useState("");
  const [resultsLabel, setResultsLabel] = useState("");
  const [noCustomerSelectedYet, setNoCustomerSelectedYet] = useState("");
  const [noDataFoundSearchCriteria, setNoDataFoundSearchCriteria] =
    useState("");
  const [searchCriteriaLabel, setSearchCriteriaLabel] = useState("");
  const [searchCriteriaList, setSearchCriteriaList] = useState<
    Array<TPKeyValue>
  >([]);
  const [guidModal, setGuidModal] = useState(uuidv4().replaceAll("-", ""));
  const [currentSearchCriteria, setCurrentSearchCriteria] =
    useState<searchCiteriaEnum>(searchCiteriaEnum.none);
  const [searchCriteriaValueLabel, setSearchCriteriaValueLabel] = useState("");
  const [selectAdditionalDataLabel, setSelectAdditionalDataLabel] =
    useState("");
  const [currentSearchCustomerValue, setCurrentSearchCustomerValue] =
    useState("");
  const [newCustomerButtonLabel, setNewCustomerButtonLabel] = useState("");
  const [
    selectSearchCriteriaErrorMessageLabel,
    setSelectSearchCriteriaErrorMessageLabel,
  ] = useState("");
  const [
    selectSearchCriteriaErrorMessage,
    setSelectSearchCriteriaErrorMessage,
  ] = useState("");
  const [
    selectSearchValueErrorMessageLabel,
    setSelectSearchValueErrorMessageLabel,
  ] = useState("");
  const [selectSearchValueErrorMessage, setSelectSearchValueErrorMessage] =
    useState("");
  const [searchCustomerLabel, setSearchCustomerLabel] = useState("");

  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  if (initialState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const dummySearchState: realSearchStateType = {
    realSearchCriteria: searchCiteriaEnum.none,
    realSearchValue: "",
    realAddtionaDataSearchId: "",
    flagSearch: false,
  };

  const [realSearchState, setRealSearchState] =
    useState<realSearchStateType>(dummySearchState);
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);
  const [canSetupColumns, setCanSetupColumns] = useState(false);
  const [gridColumns, setGridColumns] = useState<Array<any>>([]);
  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(200);
  const [searchPattern, setSearchPattern] = useState("");

  //columns labels
  const [documentTypeColumnLabel, setDocumentTypeColumnLabel] = useState("");
  const [documentNumberColumnLabel, setDocumentNumberColumnLabel] =
    useState("");
  const [namesColumnLabel, setNamesColumnLabel] = useState("");
  const [customerTypeColumnLabel, setCustomerTypeColumnLabel] = useState("");
  const [phoneColumnLabel, setPhoneColumnLabel] = useState("");
  const [emailColumnLabel, setEmailColumnLabel] = useState("");
  const [internalCodeColumnLabel, setInternalCodeColumnLabel] = useState("");
  const [totalRowsLabel, setTotalRowsLabel] = useState("");

  //#region New client modal
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [isShownModalNewClient, setIsShownModalNewClient] = useState(false);
  const [clientMode, setClientMode] = useState("Insert");
  //#endregion New client modal

  //#endregion

  const loadResources = async () => {
    setNewCustomerButtonLabel(
      await TPI18N.GetText(externalResourceSet, "NewCustomerButtonLabel")
    );
    setSearchCriteriaLabel(
      await TPI18N.GetText(externalResourceSet, "SearchCriteriaLabel")
    );
    setSearchCriteriaValueLabel(
      await TPI18N.GetText(externalResourceSet, "SearchCriteriaValueLabel")
    );
    setSelectAdditionalDataLabel(
      await TPI18N.GetText(externalResourceSet, "SelectAdditionalDataLabel")
    );
    setSearchCustomerLabel(
      await TPI18N.GetText(externalResourceSet, "SearchCustomerLabel")
    );
    setSearchParametersLabel(
      await TPI18N.GetText(resourceSet, "SearchParametersLabel")
    );
    setResultsLabel(await TPI18N.GetText(resourceSet, "ResultsLabel"));
    setFilterWithinResultsLabel(
      await TPI18N.GetText(resourceSet, "FilterWithinResultsLabel")
    );
    setNoCustomerSelectedYet(
      await TPI18N.GetText(resourceSet, "NoCustomerSelectedYet")
    );
    setNoDataFoundSearchCriteria(
      await TPI18N.GetText(resourceSet, "NoDataFoundSearchCriteria")
    );

    setSearchCriteriaList([
      {
        key: searchCiteriaEnum.none,
        value: "--",
      },
      {
        key: searchCiteriaEnum.docnumber,
        value: await TPI18N.GetText(
          externalResourceSet,
          "SearchDocumentNumberLabel"
        ),
      },
      {
        key: searchCiteriaEnum.internalcode,
        value: await TPI18N.GetText(
          externalResourceSet,
          "SearchInternalCodeLabel"
        ),
      },
      {
        key: searchCiteriaEnum.names,
        value: await TPI18N.GetText(
          externalResourceSet,
          "SearchNameOrLastNameLabel"
        ),
      },
      {
        key: searchCiteriaEnum.email,
        value: await TPI18N.GetText(externalResourceSet, "SearchEmailLabel"),
      },
      {
        key: searchCiteriaEnum.phone,
        value: await TPI18N.GetText(externalResourceSet, "SearchPhoneLabel"),
      }, //todo enable
      // {
      //   key: searchCiteriaEnum.additionaldata,
      //   value: await TPI18N.GetText(externalResourceSet, "SearchAdditionalDataLabel"),
      // },
    ]);

    setSelectSearchCriteriaErrorMessageLabel(
      await TPI18N.GetText(
        externalResourceSet,
        "SelectSearchCriteriaErrorMessageLabel"
      )
    );
    setSelectSearchValueErrorMessageLabel(
      await TPI18N.GetText(
        externalResourceSet,
        "SelectSearchValueErrorMessageLabel"
      )
    );

    //todo additional data population

    //column labels
    setDocumentTypeColumnLabel(
      await TPI18N.GetText(resourceSet, "DocumentTypeColumnLabel")
    );
    setDocumentNumberColumnLabel(
      await TPI18N.GetText(resourceSet, "DocumentNumberColumnLabel")
    );
    setNamesColumnLabel(await TPI18N.GetText(resourceSet, "NamesColumnLabel"));
    setCustomerTypeColumnLabel(
      await TPI18N.GetText(resourceSet, "CustomerTypeColumnLabel")
    );
    setPhoneColumnLabel(await TPI18N.GetText(resourceSet, "PhoneColumnLabel"));
    setEmailColumnLabel(await TPI18N.GetText(resourceSet, "EmailColumnLabel"));
    setInternalCodeColumnLabel(
      await TPI18N.GetText(resourceSet, "InternalCodeColumnLabel")
    );
    setTotalRowsLabel(await TPI18N.GetText(resourceSet, "TotalRowsLabel"));

    //New client Modal
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );

    setCanSetupColumns(true);
  };

  const setupGridColumns = () => {
    let newColumns: Array<any> = [];
    try {
      //id
      // newColumns.push({
      //   name: "idxx",
      //   cell: (row: { [x: string]: any }) => {
      //     return <span>{row["id"]}</span>;
      //   },
      //   selector: (row: { [x: string]: any }) => row["id"],
      //   sortable: true,
      // });
      //select this
      newColumns.push({
        name: "",
        width: "50px",
        center: true,
        cell: (row: { [x: string]: any }) => {
          if (!row["tpshowbutton"]) {
            return "";
          } else {
            return (
              <div className="d-flex justify-content-space-evenly">
              <TPIcon
                iconType={TPIconTypes.arrowcircledown}
                className="tprotate-270-and-scale2 mt-2"
                onClick={(e: any) => handleOnArrowButtonClick(e, row)}
              />
              </div>
            );
          }
        },
        selector: (row: { [x: string]: any }) => row["tpshowbutton"],
        sortable: false,
      });
      //customerTypeDescription
      newColumns.push({
        name: customerTypeColumnLabel,
        cell: (row: { [x: string]: any }) => {
          if (row["thumbnail"]) {
            return (
              <>
                <span>{row["customerTypeDescription"]}</span>
                &nbsp; &nbsp;
                <img src={row["thumbnail"]} />
              </>
            );
          }
          return row["customerTypeDescription"];
        },
        selector: (row: { [x: string]: any }) => row["customerTypeDescription"],
        sortable: false,
      });
      //document type
      newColumns.push({
        name: documentTypeColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["documentTypeDescription"];
        },
        selector: (row: { [x: string]: any }) => row["documentTypeDescription"],
        sortable: true,
      });
      //document number
      newColumns.push({
        name: documentNumberColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["document"];
        },
        selector: (row: { [x: string]: any }) => row["document"],
        sortable: true,
      });
      //name
      newColumns.push({
        name: namesColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["name"] + " " + row["lastName"];
        },
        selector: (row: { [x: string]: any }) => row["name"],
        sortable: false,
      });
      //email
      newColumns.push({
        name: emailColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["clientEmail"];
        },
        selector: (row: { [x: string]: any }) => row["clientEmail"],
        sortable: true,
      });
      //phone
      newColumns.push({
        name: phoneColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["clientPhone"];
        },
        selector: (row: { [x: string]: any }) => row["clientPhone"],
        sortable: true,
      });
      //internalcode
      newColumns.push({
        name: internalCodeColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["internalCode"];
        },
        selector: (row: { [x: string]: any }) => row["internalCode"],
        sortable: true,
      });

      setGridColumns(newColumns);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
    }
  };

  const handleCustomerSearchCriteriaChange = (e: any) => {
    let newValue: string = e.target.value;
    setCurrentSearchCriteria(newValue as searchCiteriaEnum);

    setSelectSearchCriteriaErrorMessage("");
    let command1: commandType = {
      type: commandsEnum.set_griddata,
      payload: { newGridData: [] },
    };
    dispatchCommand(command1);
    setShowNoDataAlert(false);
  };

  const handleChangeCustormerSearchValue = (e: any) => {
    let newValue: string = e.target.value;
    setCurrentSearchCustomerValue(newValue);
    setSelectSearchValueErrorMessage("");
    let command1: commandType = {
      type: commandsEnum.set_griddata,
      payload: { newGridData: [] },
    };
    dispatchCommand(command1);
    setShowNoDataAlert(false);
  };

  const handleSearchOnClick = async () => {
    let bolExit: boolean = false;
    //validate
    if (currentSearchCriteria === searchCiteriaEnum.none) {
      setSelectSearchCriteriaErrorMessage(
        selectSearchCriteriaErrorMessageLabel
      );
      bolExit = true;
    }
    if (currentSearchCustomerValue.trim() === "") {
      setSelectSearchValueErrorMessage(selectSearchValueErrorMessageLabel);
      bolExit = true;
    }
    //todo add data
    if (bolExit) {
      return;
    } //flag search
    setRealSearchState({
      realSearchCriteria: currentSearchCriteria,
      realSearchValue: currentSearchCustomerValue,
      realAddtionaDataSearchId: "", //todo
      flagSearch: true,
    });
  };

  const handleOnArrowButtonClick = async (e: any, row: any) => {
    //due to state problems I hack this :(
    e.preventDefault();
    e.stopPropagation();
    let searchcriteriadiv = document.getElementById(
      "searchcriteriadiv" + guidModal
    );
    let child1: any | null = searchcriteriadiv?.childNodes;

    let searchvaluediv = document.getElementById("searchvaluediv" + guidModal);
    let child2: any | null = searchvaluediv?.childNodes;

    callBackAnswer(
      true,
      row,
      child1[1].childNodes[0].value,
      child2[1].childNodes[0].value
    ); //todo adddata
    setRealSearchState(dummySearchState);
  };

  const onSearchPatternChange2 = (newValue: string) => {
    setSearchPattern(newValue);
  };

  const onColumnSort = (columnSelector: any, direction: string) => {
    let sortedGridData: Array<any>;
    sortedGridData = TPGlobal.datatableCustomSort(
      adminState.gridData,
      columnSelector,
      direction
    );
    let command1: commandType = {
      type: commandsEnum.set_griddata,
      payload: { newGridData: sortedGridData },
    };
    dispatchCommand(command1);
  };

  //Filtered data based on selected pattern on search box
  const filteredData = () => {
    //todo more columns
    let searcheableColumns: Array<string> = [
      "id",
      "documentType",
      "document",
      "name",
      "lastName",
      "customerTypeDescription",
    ];
    let i: number;
    let search: string;
    search = searchPattern.trim();
    return adminState.gridData.filter(function (item, index) {
      if (search == "" || search.length <= 2) {
        return item;
      }
      for (i = 0; i <= searcheableColumns.length - 1; i++) {
        let itemany: any;
        itemany = item;
        if (
          itemany[searcheableColumns[i]] &&
          itemany[searcheableColumns[i]]
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return item;
        }
      }
    });
  };

  const handleRowClick = (row: any, event: any) => {
    //only one row selected policy
    let i: number;
    let newGridData: Array<any> = [...adminState.gridData];
    for (i = 0; i <= newGridData.length - 1; i++) {
      if (
        newGridData[i].id === row.id &&
        newGridData[i].subsidiaryId === row.subsidiaryId &&
        newGridData[i].subsidiaryOrganizationId === row.subsidiaryOrganizationId
      ) {
        newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
        if (newGridData[i].tpselectedRow) {
          newGridData[i].tpshowbutton = true;
        }
      } else {
        newGridData[i].tpselectedRow = false;
        newGridData[i].tpshowbutton = false;
      }
    }
    let command1: commandType = {
      type: commandsEnum.set_griddata,
      payload: { newGridData: newGridData },
    };
    dispatchCommand(command1);
  };

  const realSearch = async () => {
    setSearchPattern("");
    //todo include additional data value for search
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200, 404];
    let i: number;
    let responseRequest: any;
    try {
      setIsLoadingScreen(true);
      switch (realSearchState.realSearchCriteria) {
        case searchCiteriaEnum.email:
          responseRequest = await serviceClient.getClientByEmailBasic(
            realSearchState.realSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.docnumber:
          responseRequest = await serviceClient.getClientByDocumentBasic(
            realSearchState.realSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.phone:
          responseRequest = await serviceClient.getClientByPhoneBasic(
            realSearchState.realSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.names:
          responseRequest = await serviceClient.getClientByNamesBasic(
            realSearchState.realSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.internalcode:
          responseRequest = await serviceClient.getClientByInternalCodeBasic(
            realSearchState.realSearchValue,
            false,
            true,
            expectedCodes
          );
          break;
        case searchCiteriaEnum.additionaldata:
          //todo
          break;
      }
      if (responseRequest && responseRequest.length == 0) {
        let command1: commandType = {
          type: commandsEnum.set_griddata,
          payload: { newGridData: [] },
        };
        dispatchCommand(command1);
        setShowNoDataAlert(true);
      } else {
        if (!responseRequest) {
          setShowNoDataAlert(true);
          let command1: commandType = {
            type: commandsEnum.set_griddata,
            payload: { newGridData: [] },
          };
          dispatchCommand(command1);
        } else {
          //alter responseRequest to include selected row
          for (i = 0; i <= responseRequest.length - 1; i++) {
            if (responseRequest.length === 1) {
              responseRequest[i].tpselectedRow = true;
              responseRequest[i].tpshowbutton = true;
            } else {
              responseRequest[i].tpselectedRow = false;
              responseRequest[i].tpshowbutton = false;
            }
            responseRequest[i].tpguid =
              "tprowguid" + uuidv4().replaceAll("-", "");
          }

          let command1: commandType = {
            type: commandsEnum.set_griddata,
            payload: { newGridData: responseRequest },
          };
          dispatchCommand(command1);
        }
      }

      setIsLoadingScreen(false);
      let newRealSearchState: realSearchStateType = { ...realSearchState };
      newRealSearchState.flagSearch = false;
      setRealSearchState(newRealSearchState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} searchCustomerModal ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} searchCustomerModal ex`);
      setIsLoadingScreen(false);
      let newRealSearchState: realSearchStateType = { ...realSearchState };
      newRealSearchState.flagSearch = false;
      setRealSearchState(newRealSearchState);
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: [] },
      };
      dispatchCommand(command1);
      setShowNoDataAlert(true);
    }
  };

  const handleNewCustomerClick = () => {
    setClientMode("Insert");
    setIsShownModalNewClient(true);
  };

  const callbackNewClientModalAnswer = async (response: boolean) => {
    setIsShownModalNewClient(false);
    if (response) {
    }
  };

  const initialAdminStateBLL: AdminStateType = {
    gridData: [],
  };

  const [adminState, dispatchCommand] = useReducer(
    doCommand,
    initialAdminStateBLL
  );
  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_griddata:
        let newGridData: Array<any> = [...command.payload.newGridData];
        let newAdminState: AdminStateType = { gridData: newGridData };
        return newAdminState;
    }
  }

  //run only once
  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    if (canSetupColumns) {
      setupGridColumns();
    }
  }, [canSetupColumns]);

  //run when modal show property change to true
  //reset real values to parameters values
  //flag to perform search
  useEffect(() => {
    if (initialState.isShown) {
      //override search criteria, seach value and addtional data
      setCurrentSearchCriteria(initialState.initialSearchCriteria);
      setCurrentSearchCustomerValue(initialState.initialSearchValue);
      //todo additonal data id

      setRealSearchState({
        realSearchCriteria: initialState.initialSearchCriteria,
        realSearchValue: initialState.initialSearchValue,
        realAddtionaDataSearchId: initialState.initialAddtionaDataSearchId,
        flagSearch: true,
      });
    }
  }, [initialState]);

  //run it when flag search is changed to true
  useEffect(() => {
    if (realSearchState.flagSearch) {
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: [] },
      };
      dispatchCommand(command1);
      setShowNoDataAlert(false);
      realSearch();
    }
  }, [realSearchState.flagSearch]);

  //Validate last name field
  function lastNameFunc() {
    let lastNameFilter = adminState.gridData.filter((row: any) => {
      return row["tpselectedRow"] === true && row["lastName"];
    });
    return lastNameFilter?.length > 0 ? (
      <>{lastNameFilter[0]["lastName"]}</>
    ) : (
      <></>
    );
  }

  return (
    <>
      <TPModalNewClient
        mode={clientMode}
        acceptLabel={saveButtonLabel}
        cancelLabel={cancelButtonLabel}
        isShown={isShownModalNewClient}
        callBackAnswer={callbackNewClientModalAnswer}
        subsidiaryOrgId={0}
      ></TPModalNewClient>
      <div className="container-fluid">
        <div
          className={classModal}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={styleModal}
        >
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={{ height: "700px" }}>
              <div className="modal-header justify-content-between">
                {/* <h5 className="modal-title"> */}
                <TPPageTitle className={"modal-title"} style={{ margin: "0" }}>
                  {title}
                </TPPageTitle>
                {/* </h5> */}
                <TPButton
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    setRealSearchState(dummySearchState);
                    callBackAnswer(false, null, null, null); //todo adddata
                  }}
                  className={"text-end"}
                >
                  X
                </TPButton>
              </div>
              <div className="modal-body">
                <TPLoadingOverlay
                  active={isLoadingScreen}
                  top={"300px"}
                  isModal
                >
                  {/* <div className="row">
                    <div className="col-6">
                      <TPPageSectionTitle>
                        {searchParametersLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div> */}
                  <TPPageSection
                    style={{
                      margin: "0px",
                      padding: "10px",
                      border: "1px solid #dee2e6",
                      paddingTop: "0px",
                    }}
                  >
                    {searchCriteriaList.length > 0 && (
                      <>
                        <div className="row mt-2">
                          <div
                            className="col-3"
                            id={"searchcriteriadiv" + guidModal}
                          >
                            <TPSelect
                              id="IdSelect"
                              labelText={searchCriteriaLabel}
                              onChange={handleCustomerSearchCriteriaChange}
                              dataSource={searchCriteriaList}
                              value={currentSearchCriteria}
                              disabled={false}
                              errorMessage={selectSearchCriteriaErrorMessage}
                            ></TPSelect>
                          </div>
                          {currentSearchCriteria ===
                            searchCiteriaEnum.additionaldata && (
                            <div className="col-3">
                              <TPSelect
                                id="IdSelect"
                                labelText={selectAdditionalDataLabel}
                                onChange={() => {
                                  TPGlobal.foo();
                                }}
                                dataSource={[]}
                                value={null}
                                disabled={false}
                                errorMessage={""}
                              ></TPSelect>
                            </div>
                          )}
                          <div
                            className="col-4"
                            id={"searchvaluediv" + guidModal}
                          >
                            <TPTextBox
                              id="IdTextBox"
                              onChange={(e: any) => {
                                handleChangeCustormerSearchValue(e);
                              }}
                              value={currentSearchCustomerValue}
                              labelText={searchCriteriaValueLabel}
                              isMandatory={false}
                              disabled={false}
                              errorMessage={selectSearchValueErrorMessage}
                            />
                          </div>
                          <div className="col-2" style={{ paddingTop: "22px" }}>
                            <TPButton
                              text=""
                              children={<span>{searchCustomerLabel}</span>}
                              onClick={() => handleSearchOnClick()}
                              type={TPButtonTypes.primary}
                            />
                          </div>
                          <div className="col-3" style={{ paddingTop: "22px" }}>
                            <div className="d-flex justify-content-end">
                              {/* <TPButton
                                type={TPButtonTypes.icon}
                                onClick={() => handleNewCustomerClick()}
                                text={newCustomerButtonLabel}
                                icon={TPIconTypes.newEntity}
                              /> */}
                              <div className="col d-flex justify-content-end">
                                <TPButton
                                  type={TPButtonTypes.toolbar}
                                  onClick={() => handleNewCustomerClick()}
                                >
                                  <NewCustomerIcon />
                                  {newCustomerButtonLabel}
                                </TPButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      {adminState.gridData.length > 0 &&
                        gridColumns.length > 0 && (
                          <TPPageTitle>{resultsLabel}</TPPageTitle>
                        )}
                    </div>
                  </div>
                  <TPPageSection
                    style={{
                      margin: "0px",
                      padding: "10px",
                      border: "1px solid #dee2e6",
                      paddingTop: "14px",
                    }}
                  >
                    {adminState.gridData.length > 0 &&
                      gridColumns.length > 0 && (
                        <>
                          <TPFixedTable
                            keyField={"tpguid"}
                            columns={gridColumns}
                            dataGrid={filteredData()}
                            tableHeight={`${fixedHeaderScrollHeight}px`}
                            onRowClicked={(row: any, event: any) => {
                              handleRowClick(row, event);
                            }}
                            searchLabel={filterWithinResultsLabel}
                            onSearchChange={onSearchPatternChange2}
                            sortFunction={onColumnSort}
                            highlightOnHover={true}
                            selectableRows={true}
                            multipleSelection={false}
                            showTotalRows={true}
                            totalRowsPrefix={totalRowsLabel}
                          />
                        </>
                      )}
                    {showNoDataAlert ? (
                      <div className="row mt-4">
                        <span className="alert alert-danger col-12">
                          {noDataFoundSearchCriteria}
                        </span>
                      </div>
                    ) : null}
                  </TPPageSection>
                </TPLoadingOverlay>
              </div>
              <div className="modal-footer">
                <div className="d-flex gap-3 justify-content-between w-100">
                  {adminState.gridData.some(
                    (row: any) => row["tpselectedRow"] === true
                  ) ? (
                    <div
                      className="alert alert-info"
                      style={{ padding: "6px" }}
                    >
                      <span>
                        {
                          adminState.gridData.filter(
                            (row: any) => row["tpselectedRow"] === true
                          )[0]["name"]
                        }
                      </span>
                      &nbsp;
                      <span>{lastNameFunc()}</span>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger"
                      style={{ padding: "6px" }}
                    >
                      <span>{noCustomerSelectedYet}</span>
                    </div>
                  )}
                  <div>
                    {adminState.gridData.some(
                      (row: any) => row["tpselectedRow"] === true
                    ) && (
                      <TPButton
                        type={TPButtonTypes.primary}
                        onClick={() => {
                          let selectedCustomer: any;
                          selectedCustomer = adminState.gridData.filter(
                            (row: any) => row["tpselectedRow"] === true
                          )[0];
                          callBackAnswer(
                            true,
                            selectedCustomer,
                            realSearchState.realSearchCriteria,
                            realSearchState.realSearchValue
                          ); //todo adddata
                          setRealSearchState(dummySearchState);
                        }}
                      >
                        {acceptLabel}
                      </TPButton>
                    )}

                    <TPButton
                      type={TPButtonTypes.link}
                      onClick={() => {
                        setRealSearchState(dummySearchState);
                        callBackAnswer(false, null, null, null); //todo adddata
                      }}
                    >
                      {cancelLabel}
                    </TPButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={backdropClass}></div>
      </div>
    </>
  );
};

export default TPModalSearchClient;
