import { put, takeEvery, all } from "redux-saga/effects";
import { FormDesignerExportImportFormSlice } from "./FormDesignerExportImportFormSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { TPI18N } from "@/services/I18nService";
import e from "../models/FormDesignerExportImportFormEnum";

function* message({ payload }: PayloadAction<Array<string>>) {
  try {
    for (const attribute of payload) {
      const message: string = yield TPI18N.GetText(
        e.FormDesignerExportImportFormComponent,
        attribute,
      );
      yield put(
        FormDesignerExportImportFormSlice.actions.messageSuccess({
          attribute,
          message,
        }),
      );
    }
  } catch (error) {
    yield put(
      FormDesignerExportImportFormSlice.actions.messageError({
        error,
        message: e.GetMessageErrorMessage,
      }),
    );
  }
}

export function* FormDesignerExportImportFormSaga() {
  yield all([
    takeEvery(FormDesignerExportImportFormSlice.actions.message, message),
  ]);
}
