import { Validator } from "fluentvalidation-ts";
import {
  InboundMailboxInProcessActionsType,
  InboundMailboxInProcessType,
} from "../Global/TPGlobalEnums";

export interface ApplyRulesDTO {
  Action: number;
  FixedClassifier: string | null;
  InboundMailboxInProcessId: number[];
}

export interface ApplyRules {
  InsertionType: string;
  InboundMailbox: string;
  Action: number;
  FixedClassifier: string | null;
  InboundMailboxInProcessId: number[];
}

export const validateLength = (value: number[]) => value.length > 0;

export const isInprocess = (insertTypeValue: string) =>
  insertTypeValue ===
  `${InboundMailboxInProcessType.InboundMailboxTypeInProcess}`;
export const isJunkMail = (insertTypeValue: string) =>
  insertTypeValue === `${InboundMailboxInProcessType.InboundMailboxTypeJunk}`;
export const isWithFixedClassifier = (actionsValue: string) =>
  actionsValue ===
  `${InboundMailboxInProcessActionsType.ActionCreateNewCaseWithFixedClassifier}`;

export class InboundMailboxInProcessInputDTOValidator extends Validator<ApplyRules> {
  constructor() {
    super();
    const resourceSet: string = "InboundMailboxInProcessAdminComponent";

    try {
      this.ruleFor("InsertionType")
        .notNull()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessInsertionType",
        )

        .notEmpty()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessInsertionType",
        );

      this.ruleFor("InboundMailbox")
        .notNull()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessInboundMailbox",
        )

        .notEmpty()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessInboundMailbox",
        );

      this.ruleFor("Action")
        .notNull()
        .withMessage(resourceSet + "|InputDTOInboundMailboxInProcessAction")

        .notEqual(0)
        .withMessage(resourceSet + "|InputDTOInboundMailboxInProcessAction");

      this.ruleFor("FixedClassifier")
        .notEmpty()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessFixedClassifier",
        )

        .notNull()
        .withMessage(
          resourceSet + "|InputDTOInboundMailboxInProcessFixedClassifier",
        )
        .when((x) => isWithFixedClassifier(`${x.Action}`));

      this.ruleFor("InboundMailboxInProcessId")
        .notNull()
        .withMessage(
          resourceSet +
            "|InputDTOInboundMailboxInProcessInboundMailboxInProcessId",
        )

        .must(validateLength)
        .withMessage(
          resourceSet +
            "|InputDTOInboundMailboxInProcessInboundMailboxInProcessId",
        );
    } catch (error) {}
  }
}
