import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import React, { ReactElement, FC, ReactNode } from "react";
import successImg from "@/assets/images/success.png";

export type TPModalSuccessStateType = {
    id?: string;
    isShown: boolean;
    title: string;
    successMessage?: string;
    yesLabel: string;
    callBackFunction: Function;
};

type TPModalSuccessProps = {
    modalState: TPModalSuccessStateType;
    children?: React.ReactNode;
};

const TPModalSuccess: FC<TPModalSuccessProps> = ({
    modalState,
    children,
}): ReactElement => {
    let classModal: string;
    let styleModal: any = {};
    let backdropClass: string;

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }
  return (
    <>
      <div
        id={modalState.id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{modalState.title}</h5>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.empty}
                onClick={() => modalState.callBackFunction()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <img src={successImg} width={80}></img>
                </div>
                <div className="col">
                  {modalState.successMessage && (
                    <p className="tpbold">{modalState.successMessage}</p>
                  )}

                  {!!children && children}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={() => modalState.callBackFunction()}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {modalState.yesLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalSuccess;
