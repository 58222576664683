import { StateModel } from "../../../redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TabsCaseViewerStateModel extends StateModel {
  cases: Array<string>;
  ids: any;
  seconds: any;
  focus?: string;
  focusIndex?: number;
}

export const TabsCaseViewerSlice = createSlice({
  name: "TabsCaseViewer",
  initialState: {
    cases: [] as Array<string>,
    ids: {},
    seconds: {},
    result: {},
  } as TabsCaseViewerStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        cases: {},
        result: { action },
      }) as TabsCaseViewerStateModel,
    addCase: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<{ userGuid: any; caseNumber: string }>,
    ) => {
      const cases = [...state.cases, action.payload.caseNumber];

      return {
        ...state,
        cases,
        focus: action.payload.caseNumber,
        focusIndex: cases.length - 1,
        result: { action },
      } as TabsCaseViewerStateModel;
    },
    addCaseSuccess: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<{ id: string; caseNumber: string } | undefined>,
    ) => {
      const ids = { ...state.ids };
      const seconds = { ...state.seconds };
      if (action?.payload) {
        ids[action.payload.caseNumber] = action.payload.id;
        seconds[action.payload.caseNumber] = 0;
      }

      return {
        ...state,
        ids,
        seconds,
        result: { action },
      } as TabsCaseViewerStateModel;
    },
    addCaseError: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<any>,
    ) =>
      ({
        ...state,
        result: {
          action,
          message: "Error al abrir el caso",
          error: action.payload,
        },
      }) as TabsCaseViewerStateModel,
    increase: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<string>,
    ) => {
      const seconds = { ...state?.seconds };
      const caseNumber = action.payload;
      if (seconds[caseNumber] !== null && seconds[caseNumber] !== undefined)
        seconds[caseNumber] = seconds[caseNumber] + 1;

      return {
        ...state,
        seconds,
        result: { action },
      } as TabsCaseViewerStateModel;
    },
    select: (state: TabsCaseViewerStateModel, action: PayloadAction<string>) =>
      ({
        ...state,
        focus: action.payload,
        focusIndex: state.cases.findIndex((c) => c === action.payload),
        result: { action },
      }) as TabsCaseViewerStateModel,
    remove: (state: TabsCaseViewerStateModel, action: PayloadAction) =>
      ({
        ...state,
        result: { action },
      }) as TabsCaseViewerStateModel,
    close: (state: TabsCaseViewerStateModel, action: PayloadAction<string>) => {
      const cases = state.cases.filter((c) => c !== action.payload);
      const seconds = Object.entries(state.seconds).filter(
        ([key, _]) => key !== action.payload,
      );
      let index = state?.focusIndex;
      let focus = null;
      if (index !== null && index !== undefined) {
        index = index > cases.length - 1 ? index! - 1 : index;
        focus = cases[index];
      }

      return {
        ...state,
        cases,
        seconds: Object.fromEntries(seconds) || ({} as any),
        focus,
        focusIndex: index,
        result: { action },
      } as TabsCaseViewerStateModel;
    },
    notify: (state: TabsCaseViewerStateModel, action: PayloadAction) =>
      ({
        ...state,
        result: { action },
      }) as TabsCaseViewerStateModel,
    notifyLoading: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<{ ids: any; seconds: any }>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as TabsCaseViewerStateModel,
    notifySuccess: (state: TabsCaseViewerStateModel, action: PayloadAction) =>
      ({
        ...state,
        result: { action },
      }) as TabsCaseViewerStateModel,
    notifyError: (
      state: TabsCaseViewerStateModel,
      action: PayloadAction<any>,
    ) =>
      ({
        ...state,
        result: {
          action,
          message: "Error al notificar",
          error: action.payload,
        },
      }) as TabsCaseViewerStateModel,
  },
});
