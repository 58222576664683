import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
  ClientNewCustomerInputDTO,
  ClientNewCustomerInputDTOValidator,
} from "@/models/Client/ClientNewCustomerInputDTO";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import {
  ConfigParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ContainerTabStyle,
  TabActiveStyle,
} from "../FormDesigner/StyleFromDesigner";
import ContactComponent from "./ContactComponent";
import CustomerHistory from "./CustomerHistory";
import "./Search.css";

interface screenStateProps {
  customerType: string;
  documentTypeId: string;
  document: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  internalCode: string;

  //validations
  customerTypeErrorMessage: string;
  documentTypeErrorMessage: string;
  documentErrorMessage: string;
  nameErrorMessage: string;
  lastNameErrorMessage: string;
  emailErrorMessage: string;
  phoneErrorMessage: string;
  internalCodeErrorMessage: string;
  [key: string]: any;
}

const TabButton = ({ id, activeTab, setActiveTab, label }: any) => (
  <TabActiveStyle
    activeBorder={activeTab === id}
    style={{ display: "inline-flex", width: "150px" }}
  >
    <TPButton
      className={activeTab === id ? "active-color" : ""}
      type={TPButtonTypes.empty}
      text={"1"}
      onClick={() => setActiveTab(id)}
      icon={TPIconTypes.default}
    >
      <TPPageSectionTitle
        style={{ color: activeTab === id ? "#a00095" : "gray" }}
      >
        <span style={{ fontSize: "12px" }}> {label}</span>
      </TPPageSectionTitle>
    </TPButton>
  </TabActiveStyle>
);

const ButtonGroup = ({
  activeButton,
  setActiveButton,
  personLabel,
  contactLabel,
}: any) => (
  <ContainerTabStyle style={{ gap: "60px" }}>
    {["btn1", "btn2"].map((btn) => (
      <TabActiveStyle
        activeBorder={activeButton === btn}
        style={{ display: "inline-flex", width: "150px" }}
      >
        <TPButton
          className={activeButton === btn ? "active-color" : ""}
          type={TPButtonTypes.empty}
          text={"1"}
          onClick={() => setActiveButton(btn)}
          icon={TPIconTypes.default}
        >
          <TPPageSectionTitle
            style={{ color: activeButton === btn ? "#a00095" : "gray" }}
          >
            {btn === "btn1" && personLabel}
            {btn === "btn2" && contactLabel}
          </TPPageSectionTitle>
        </TPButton>
      </TabActiveStyle>
    ))}
  </ContainerTabStyle>
);

const SearchResult = ({
  setCurrentComponent,
  userId,
  isEdit,
  mode,
  setIsEdit,
  subsidiaryId,
  clientId,
  setClientMode,
}: any) => {
  const documentTypeUNK: string = "UNK";
  const resourceSet: string = "SearchResultComponent";
  const componentFileName: string = "SearchResult.tsx";
  const screenStateInitialState: screenStateProps = {
    customerType: "",
    documentTypeId: "",
    document: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    internalCode: "",
    customerTypeErrorMessage: "",
    documentTypeErrorMessage: "",
    documentErrorMessage: "",
    nameErrorMessage: "",
    lastNameErrorMessage: "",
    emailErrorMessage: "",
    phoneErrorMessage: "",
    internalCodeErrorMessage: "",
  };
  const [screenState, setScreenState] = useState<screenStateProps>(
    screenStateInitialState
  );
  const [activeTab, setActiveTab] = useState("tab1");
  const [activeButton, setActiveButton] = useState("btn1");
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [
    phoneNumberShouldContainOnlyNumbersLabel,
    setPhoneNumberShouldContainOnlyNumbersLabel,
  ] = useState("");
  const [isUNKCustomer, setIsUNKCustomer] = useState(false);
  const [customerTypeList, setCustomerTypeList] = useState<Array<TPKeyValue>>(
    []
  );
  const [documentsTypeList, setDocumentsTypeList] = useState<Array<TPKeyValue>>(
    []
  );
  const [headingTitle, setHeadingTitle] = useState("");
  const [backMenuLabel, setBackMenuLabel] = useState("");
  const [customerInfoLabel, setCustomerInfoLabel] = useState("");
  const [caseHistoryLabel, setCaseHistoryLabel] = useState("");
  const [customerTypeLabel, setCustomerTypeLabel] = useState("");
  const [documentTypeLabel, setDocumentTypeLabel] = useState("");
  const [documentLabel, setDocumentLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [lastNameLabel, setLastNameLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const [internalCodeLabel, setInternalCodeLabel] = useState("");
  const [otherInfoLabel, setOtherInfoLabel] = useState("");
  const [sectionLabel, setSectionLabel] = useState("");
  const [newFormLabel, setNewFormLabel] = useState("");
  const [personLabel, setPersonLabel] = useState("");
  const [contactLabel, setContactLabel] = useState("");
  const [saveLabel, setSaveLabel] = useState("");
  const [cancelLabel, setCancelLabel] = useState("");

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    // Define validation rules based on propertyName
    switch (propertyName) {
      case "phone":
        if (/^[0-9]*$/.test(newValue)) {
          setScreenState({
            ...screenState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]: "",
          });
        } else {
          setScreenState({
            ...screenState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]:
              phoneNumberShouldContainOnlyNumbersLabel,
          });
        }
        break;
      // Add cases for other specific fields as needed
      default:
        let newScreenState = { ...screenState };
        newScreenState[propertyName] = newValue;
        newScreenState[propertyName + "ErrorMessage"] = "";
        setScreenState(newScreenState);
        break;
    }
  };

  const handleDocumentTypeInputTextChange = (
    propertyName: string,
    newValue: any
  ) => {
    let newScreenState = { ...screenState };
    newScreenState[propertyName] = newValue;
    newScreenState[propertyName + "ErrorMessage"] = "";
    if (newValue === documentTypeUNK) {
      newScreenState["document"] = "";
      newScreenState[document + "ErrorMessage"] = "";
      setIsUNKCustomer(true);
    } else {
      setIsUNKCustomer(false);
    }
    setScreenState(newScreenState);
  };

  const handleOnCancelOrCloseClick = () => {
    setCurrentComponent("SearchCustomer");
    // setScreenState({});
    // callBackAnswer(false, "", "", "");
  };

  const loadResources = async () => {
    setHeadingTitle(await TPI18N.GetText(resourceSet, "HeadingDataLabel"));
    setBackMenuLabel(await TPI18N.GetText(resourceSet, "BackMenuDataLabel"));
    setCustomerInfoLabel(
      await TPI18N.GetText(resourceSet, "CustomerInfoLabel")
    );
    setCaseHistoryLabel(await TPI18N.GetText(resourceSet, "CaseHistoryLabel"));
    setCustomerTypeLabel(
      await TPI18N.GetText(resourceSet, "CustomerTypeLabel")
    );
    setDocumentTypeLabel(
      await TPI18N.GetText(resourceSet, "DocumentTypeLabel")
    );
    setDocumentLabel(await TPI18N.GetText(resourceSet, "DocumentLabel"));
    setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
    setLastNameLabel(await TPI18N.GetText(resourceSet, "LastNameLabel"));
    setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
    setPhoneLabel(await TPI18N.GetText(resourceSet, "PhoneLabel"));
    setInternalCodeLabel(
      await TPI18N.GetText(resourceSet, "InternalCodeLabel")
    );
    setOtherInfoLabel(await TPI18N.GetText(resourceSet, "OtherInfoLabel"));
    setSectionLabel(await TPI18N.GetText(resourceSet, "SectionLabel"));
    setNewFormLabel(await TPI18N.GetText(resourceSet, "NewFormLabel"));
    setPersonLabel(await TPI18N.GetText(resourceSet, "PersonLabel"));
    setContactLabel(await TPI18N.GetText(resourceSet, "ContactLabel"));
    setSaveLabel(await TPI18N.GetText(resourceSet, "SaveLabel"));
    setCancelLabel(await TPI18N.GetText(resourceSet, "CancelLabel"));

    //Load Customer Type
    let customerTypeService = new CustomerTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await customerTypeService.getCustomerTypeByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );

      let newFilteredCustomerTypeList: Array<CustomerTypeViewModel> = [];

      if (mode === "Insert") {
        newFilteredCustomerTypeList = responseRequest.filter(
          (x: CustomerTypeViewModel) => x.canCreate
        );
      } else {
        newFilteredCustomerTypeList = responseRequest.filter(
          (x: CustomerTypeViewModel) => x.canUpdate
        );
      }

      let newCustomerTypeList: Array<TPKeyValue> =
        newFilteredCustomerTypeList.map(function (item) {
          return { key: item.id, value: item.localizedDescription };
        });
      newCustomerTypeList.unshift({ key: "", value: "--" });
      setCustomerTypeList(newCustomerTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`
      );
    }

    //Load document Type
    let parameterService = new ParametersService();
    expectedCodes = [200];
    try {
      let responseRequest =
        await parameterService.getByParentIdAndFilterIsActive(
          ConfigParametersEnum.Document_Type,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );

      let newDocumentsTypeList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newDocumentsTypeList = newDocumentsTypeList.filter((x) => x.key !== "AN");
      newDocumentsTypeList.unshift({ key: "", value: "--" });
      setDocumentsTypeList(newDocumentsTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`
      );
    }

    if (mode !== "Insert") {
      await getCustomerBySubsidiaryOrganizationId(Number(userId));
    }
  };

  const getCustomerBySubsidiaryOrganizationId = async (
    subsidiaryOrgId: number
  ) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getClientBySubsidiaryOrganizationId(
          subsidiaryOrgId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<ClientAdminViewModel>;
      recordInfo = [...responseRequest];

      let newScreenStateState: screenStateProps = { ...screenState };
      newScreenStateState.documentTypeId = recordInfo[0].documentType;
      newScreenStateState.customerTypeDescription =
        recordInfo[0].customerTypeDescription;
      newScreenStateState.documentTypeDescription =
        recordInfo[0].documentTypeDescription;
      newScreenStateState.document = recordInfo[0].document;
      newScreenStateState.email = recordInfo[0].preferredEmail;
      newScreenStateState.internalCode = recordInfo[0].internalCode;
      newScreenStateState.lastName = recordInfo[0].lastName;
      newScreenStateState.name = recordInfo[0].name;
      newScreenStateState.phone = recordInfo[0].preferredPhone;
      newScreenStateState.id = recordInfo[0].id;
      newScreenStateState.customerTypeId = recordInfo[0].customerTypeId;

      setScreenState(newScreenStateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`
      );
      setIsLoadingScreen(false);
    }
  };

  const handlerOnOKClick = async () => {
    let recordInputDTO: ClientNewCustomerInputDTO = {
      documentTypeId: screenState.documentTypeId,
      document: screenState.document,
      name: screenState.name,
      lastName: screenState.lastName,
      preferredEmail: screenState.email,
      preferredPhone: screenState.phone,
      internalCode: screenState.internalCode,
      subsidiaryOrganizationId: mode === "Insert" ? -1 : userId,
      additionalInfo: null,
      isActive: true,
      typistIdGuid: TPGlobal.currentUserGuid,
      subsidiaryName: null,
      preferredAddress: null,
      preferredAddressInfo: null,
      geography: null,
      organizationId: null,
      relationId: null,
      regionId: null,
      channelId: null,
      customerTypeId:
        mode === "Insert"
          ? screenState.customerType
                : screenState.customerTypeId,
        additionalData: new Array()
    };
    // if (recordInputDTO.documentTypeId === documentTypeUNK) {
    //     recordInputDTO.document = uuidv4().replaceAll("-", "");
    // }

    let inputDTOValidator = new ClientNewCustomerInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newScreenState = { ...screenState };
      if (resultValidator.customerTypeId) {
        newScreenState.customerTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.customerTypeId
        );
      } else {
        newScreenState.customerTypeErrorMessage = "";
      }

      if (resultValidator.documentTypeId) {
        newScreenState.documentTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.documentTypeId
        );
      } else {
        newScreenState.documentTypeErrorMessage = "";
      }

      if (resultValidator.document) {
        newScreenState.documentErrorMessage = await TPI18N.GetResource(
          resultValidator.document
        );
      } else {
        newScreenState.documentErrorMessage = "";
      }

      if (resultValidator.name) {
        newScreenState.nameErrorMessage = await TPI18N.GetResource(
          resultValidator.name
        );
      } else {
        newScreenState.nameErrorMessage = "";
      }

      if (resultValidator.preferredEmail) {
        newScreenState.emailErrorMessage = await TPI18N.GetResource(
          resultValidator.preferredEmail
        );
      } else {
        newScreenState.emailErrorMessage = "";
      }

      if (resultValidator.preferredPhone) {
        newScreenState.phoneErrorMessage = await TPI18N.GetResource(
          resultValidator.preferredPhone
        );
      } else {
        newScreenState.phoneErrorMessage = "";
      }

      if (resultValidator.internalCode) {
        newScreenState.internalCodeErrorMessage = await TPI18N.GetResource(
          resultValidator.internalCode
        );
      } else {
        newScreenState.internalCodeErrorMessage = "";
      }
      setScreenState(newScreenState);
      return;
    }
    if (mode === "Insert") {
      await insertNewClient(recordInputDTO);
    } else {
      await updateClientDetails(recordInputDTO);
    }

    // setScreenState(screenStateInitialState);
  };

  //For new client
  const insertNewClient = async (inputDTO: ClientNewCustomerInputDTO) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];
    let Id_SUOR: string;
    let Id_SUBS: string;
    let Id_CLIE: string;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertNewClient(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);

      if (responseRequest.responseResult) {
        //get id_suor
        Id_SUOR = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_SUOR"
        )[0]["value"];
        Id_SUBS = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_SUBS"
        )[0]["value"];
        Id_CLIE = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_CLIE"
        )[0]["value"];
        // callBackAnswer(
        //   true,
        //   Id_SUOR,
        //   Id_SUBS,
        //   Id_CLIE
        // );
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertNewClient ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertNewClient ex`);
      setIsLoadingScreen(false);
    }
  };

  //For existing client
  const updateClientDetails = async (inputDTO: ClientNewCustomerInputDTO) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];
    let Id_SUOR: string;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateClient(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        //get id_suor
        Id_SUOR = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "Id_SUOR"
        )[0]["value"];
        //todo
        // callBackAnswer(true, Id_SUOR, "",);
        // setActiveButton('btn2');
        setCurrentComponent("SearchCustomer");
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateClient ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateClient ex`);
      setIsLoadingScreen(false);
      setCurrentComponent("SearchCustomer");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCustomerBySubsidiaryOrganizationId(Number(userId));
    };
    userId && fetchData();
  }, []);

  useEffect(() => {
    loadResources();
  }, [mode]);

  return (
    <div className="container-fluid">
      <TPLoadingOverlay active={isLoadingScreen}>
        <div style={{ padding: "10px" }}>
          <div className="row">
            <div className="col-8" style={{ paddingLeft: "12px" }}>
              <TPPageTitle style={{ fontSize: "20px" }}>
                {headingTitle}
              </TPPageTitle>
            </div>
            <div className="col-4 mt-4">
              <TPButton
                type={TPButtonTypes.link}
                onClick={() => {
                  setCurrentComponent("SearchCustomer");
                  setIsEdit(false);
                  setClientMode("Insert");
                }}
              >
                {backMenuLabel}
              </TPButton>
            </div>
          </div>

          <div className="row">
            <div className="col-12 nav" style={{ paddingLeft: "15px" }}>
              <div
                className="secondary-style-tp-tabs"
                id="nav-tab"
                role="tablist"
              >
                <TabButton
                  id="tab1"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  label={customerInfoLabel}
                />
                <TabButton
                  id="tab2"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  label={caseHistoryLabel}
                />
              </div>
            </div>
          </div>

          <div className="tab-content mt-1">
            {activeTab === "tab1" && (
              <>
                <ButtonGroup
                  activeButton={activeButton}
                  setActiveButton={setActiveButton}
                  personLabel={personLabel}
                  contactLabel={contactLabel}
                />
                {activeButton === "btn1" && (
                  <>
                    <div>
                      <div className="d-flex justify-content-center">
                        <div>
                          <h4>{newFormLabel} &nbsp;&nbsp;</h4>
                        </div>
                        <div>
                          <TPButton
                            onClick={() => {
                              setIsEdit(true);
                              setClientMode("update");
                            }}
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.edit}
                          />
                        </div>
                      </div>
                      <p className="text-center">{sectionLabel}</p>
                      {isEdit ? (
                        <>
                          <div
                            style={{
                              background: "#EBEFFF",
                              padding: "10px 20px",
                              marginTop: "12px",
                            }}
                          >
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={() => {}}
                                    value={screenState.customerTypeDescription}
                                    labelText={customerTypeLabel}
                                    maxLength={200}
                                    disabled
                                    errorMessage={""}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={() => {}}
                                    value={screenState.documentTypeDescription}
                                    labelText={documentTypeLabel}
                                    maxLength={200}
                                    disabled
                                    errorMessage={
                                      screenState.documentTypeErrorMessage
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      handleInputTextChange(
                                        "document",
                                        e.target.value
                                      )
                                    }
                                    value={screenState.document}
                                    labelText={documentLabel}
                                    maxLength={200}
                                    disabled={
                                      mode === "Insert"
                                        ? isUNKCustomer
                                          ? true
                                          : false
                                        : true
                                    }
                                    errorMessage={
                                      screenState.documentErrorMessage
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      handleInputTextChange(
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    value={screenState.name}
                                    labelText={nameLabel}
                                    errorMessage={screenState.nameErrorMessage}
                                    maxLength={100}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    onChange={(e: any) =>
                                      handleInputTextChange(
                                        "lastName",
                                        e.target.value
                                      )
                                    }
                                    value={screenState.lastName}
                                    labelText={lastNameLabel}
                                    errorMessage={
                                      screenState.lastNameErrorMessage
                                    }
                                    maxLength={100}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      handleInputTextChange(
                                        "email",
                                        e.target.value
                                      )
                                    }
                                    value={screenState.email}
                                    labelText={emailLabel}
                                    errorMessage={screenState.emailErrorMessage}
                                    maxLength={200}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      handleInputTextChange(
                                        "phone",
                                        e.target.value
                                      )
                                    }
                                    value={screenState.phone}
                                    labelText={phoneLabel}
                                    errorMessage={screenState.phoneErrorMessage}
                                    maxLength={200}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <TPPageSectionTitle>
                                  {otherInfoLabel}
                                </TPPageSectionTitle>
                              </div>
                            </div>
                            <TPPageSection>
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <TPTextBox
                                      id="IdTextBox"
                                      onChange={(e: any) =>
                                        handleInputTextChange(
                                          "internalCode",
                                          e.target.value
                                        )
                                      }
                                      value={screenState.internalCode}
                                      labelText={internalCodeLabel}
                                      errorMessage={
                                        screenState.internalCodeErrorMessage
                                      }
                                      maxLength={300}
                                    />
                                  </div>
                                </div>
                              </div>
                            </TPPageSection>
                          </div>

                          <div className="row mt-4 mb-4">
                            <div className="col-12">
                              <TPPageAcceptCancelButtonsContainer>
                                <TPButton
                                  id="IdButton"
                                  type={TPButtonTypes.link}
                                  onClick={() => {
                                    handleOnCancelOrCloseClick();
                                  }}
                                  className={"ms-2"}
                                >
                                  {cancelLabel}
                                </TPButton>
                                <TPButton
                                  id="IdButton"
                                  type={TPButtonTypes.primary}
                                  onClick={handlerOnOKClick}
                                >
                                  {saveLabel}
                                </TPButton>
                              </TPPageAcceptCancelButtonsContainer>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="container grid-container"
                          style={{
                            background: "#EBEFFF",
                            padding: "10px 20px",
                            marginTop: "12px",
                            gap: "14px",
                          }}
                        >
                          <div>
                            {customerTypeLabel} :{" "}
                            {screenState.customerTypeDescription}
                          </div>
                          <div>
                            {nameLabel} : {screenState.name}
                          </div>
                          <div>
                            {documentTypeLabel} :{" "}
                            {screenState.documentTypeDescription}
                          </div>
                          <div>
                            {documentLabel} : {screenState.document}
                          </div>
                          <div>
                            {emailLabel} : {screenState.email}
                          </div>
                          <div>
                            {phoneLabel} : {screenState.phone}
                          </div>
                          <div>
                            {internalCodeLabel} : {screenState.id}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {true && (
                  <div
                    style={activeButton === "btn2" ? {} : { display: "none" }}
                  >
                    <ContactComponent
                      subsidiaryId={subsidiaryId}
                      clientId={clientId}
                    />
                  </div>
                )}
              </>
            )}
            {activeTab === "tab2" && <CustomerHistory userId={userId} />}
          </div>
        </div>
      </TPLoadingOverlay>
    </div>
  );
};

export default SearchResult;
