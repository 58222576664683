import { TPKeyValue } from "@/helpers/TPKeyValue";
import { FC, useState } from "react";

interface ReassignAutocompleteItemProperties {
  item: TPKeyValue;
  onClick: Function;
  query?: string;
}

const splitString = function (fullString: string, query: string): string[] {
  const regex = new RegExp(`(.*?)(${query})(.*)`, "i");
  const matches = fullString.match(regex);
  return matches || [fullString];
};

const ReassignAutocompleteItem: FC<ReassignAutocompleteItemProperties> =
  function ({ item, onClick, query }) {
    const [mouseHovering, setMouseHovering] = useState<boolean>(false);
    const [click, setClick] = useState<boolean>(false);
    return (
      <button
        key={item.key}
        type="button"
        onClick={() => onClick()}
        onMouseEnter={() => setMouseHovering(true)}
        onMouseLeave={() => setMouseHovering(false)}
        onFocus={() => setClick(true)}
        onBlur={() => setClick(false)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          border: "none",
          backgroundColor: click
            ? "darkgray"
            : mouseHovering
              ? "gainsboro"
              : "white",
        }}
      >
        <label style={{ cursor: "pointer" }}>
          {query ? (
            <>
              <label style={{ cursor: "pointer", pointerEvents: "none" }}>
                {splitString(item.value2, query)[1]}
                <span
                  style={{
                    backgroundColor: "#FFB8DC",
                    cursor: "pointer",
                    pointerEvents: "none",
                  }}
                >
                  {splitString(item.value2, query)[2]}
                </span>
                {splitString(item.value2, query)[3]}
              </label>
            </>
          ) : (
            item.value2
          )}
        </label>
        <label style={{ cursor: "pointer", color: "grey" }}>{item.value}</label>
      </button>
    );
  };

export default ReassignAutocompleteItem;
