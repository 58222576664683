import React, { useState } from "react";
import SearchCustomer from "./SearchCustomer";
import SearchResult from "./SearchResult";
import CustomerInformation from "./CustomerInformation";
import CustomerHistory from "./CustomerHistory";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import "./Search.css";

const SearchComponent = () => {
  const [currentComponent, setCurrentComponent] =
    useState<any>("SearchCustomer");
  const [userId, setUserId] = useState("");
  const [subsidiaryId, setSubsidaryId] = useState("");
  const [clientId, setClientId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [mode, setClientMode] = useState("");

  const renderComponent = (component: any) => {
    switch (component) {
      case "SearchCustomer":
        return (
          <SearchCustomer
            setCurrentComponent={setCurrentComponent}
            setUserId={setUserId}
            setSubsidaryId={setSubsidaryId}
            setClientId={setClientId}
            setIsEdit={setIsEdit}
            setClientMode={setClientMode}
          />
        );
      case "SearchResult":
        return (
          <SearchResult
            setCurrentComponent={setCurrentComponent}
            userId={userId}
            subsidiaryId={subsidiaryId}
            clientId={clientId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            mode={mode}
            setClientMode={setClientMode}
          />
        );
      default:
        return null;
    }
  };
  return (
    <ContentVerticalNoTabsStyled>
      <div>{currentComponent && renderComponent(currentComponent)}</div>
    </ContentVerticalNoTabsStyled>
  );
};

export default SearchComponent;
