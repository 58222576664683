import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { CloneBranchContract } from "@/layouts/TPModalCloneBranch/TPModalCloneBranch";
import {
  BranchChangeParentInputDTO,
  BranchInputDTO,
} from "@/models/Branch/BranchInputDTO";
import {
  BranchInfoHierarchyViewModel,
  BranchInfoHierarchyViewModelComplete,
  BranchViewModel,
  ClonedBranchResponse,
} from "@/models/Branch/BranchModels";

export class BranchService {
  serviceFileName: string = "BranchService.ts";

  //#region Branches
  public async getFirstLevelBranches(
    treeId: string,
    filterIsActive: number,
    filterIsVisible: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BranchViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/tree/${treeId}/branch/get-first-level?FilterIsActive=${filterIsActive}&FilterIsVisible=${filterIsVisible}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFirstLevelBranches ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getFirstLevelBranches`);
      throw new Error(`Error ${this.serviceFileName} getFirstLevelBranches`);
    }
  }

  public async getChildBranches(
    parentId: string,
    filterIsActive: number,
    filterIsVisible: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BranchViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/branch/${parentId}/get-child?FilterIsActive=${filterIsActive}&FilterIsVisible=${filterIsVisible}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getChildBranches ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getChildBranches`);
      throw new Error(`Error ${this.serviceFileName} getChildBranches`);
    }
  }

  public async getHierarchyBranch(
    branchId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BranchInfoHierarchyViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/branch/${branchId}/get-hierarchy`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getHierarchyBranch ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getHierarchyBranch`);
      throw new Error(`Error ${this.serviceFileName} getHierarchyBranch`);
    }
  }

  public async getBranchById(
    branchId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<BranchViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/branch/${branchId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getBranchById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getBranchById`);
      throw new Error(`Error ${this.serviceFileName} getBranchById`);
    }
  }

  public async insertBranch(
    inputDTO: BranchInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/branch";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertBranch ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertBranch`);
      throw new Error(`Error ${this.serviceFileName} insertBranch`);
    }
  }

  public async updateBranch(
    inputDTO: BranchInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/branch";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateBranch ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateBranch`);
      throw new Error(`Error ${this.serviceFileName} updateBranch`);
    }
  }

  public async deleteBranchById(
    branchId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/branch/" + branchId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteBranchById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteBranchById`);
      throw new Error(`Error ${this.serviceFileName} deleteBranchById`);
    }
  }

  public async changeParentId(
    inputDTO: BranchChangeParentInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/branch/change-parent";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} changeParentId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} changeParentId`);
      throw new Error(`Error ${this.serviceFileName} changeParentId`);
    }
  }

  public async getSearchContainsInBranchHierarchy(
    treeId: string,
    parentId: string,
    words: string,
    filterIsActive: number,
    filterIsVisible: number,
    mustSelectLastLevelBranch: boolean,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BranchInfoHierarchyViewModelComplete>> {
    let httpclient = new TPHTTPService();
    let url: string;
    let mustselectString: string = mustSelectLastLevelBranch ? "true" : "false";

    url = `/tree/${treeId}/branch/get-search?ParentId=${parentId}&Words=${words}&FilterIsActive=${filterIsActive}&FilterIsVisible=${filterIsVisible}&mustSelectLastBranch=${mustselectString}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getSearchContainsInBranchHierarchy ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getSearchContainsInBranchHierarchy`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getSearchContainsInBranchHierarchy`,
      );
    }
  }

  public async getRenderListValue(
    treeId: string,
    parentId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BranchViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/branch/render-listvalue?ParentId=${parentId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRenderListValue ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getRenderListValue`);
      throw new Error(`Error ${this.serviceFileName} getRenderListValue`);
    }
  }

  public async cloneBranch(
    object: CloneBranchContract,
  ): Promise<ClonedBranchResponse> {
    let httpClient = new TPHTTPService();
    let url: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/branch/clone-branch`;

    try {
      return (
        await httpClient.putData(
          url,
          object,
          [200, 404],
          true,
          true,
          TPGlobal.standardHeaders,
        )
      ).responseData;
    } catch (e) {
      let errorMsj: string = `Error ${this.serviceFileName} cloneBranch exception`;
      TPLog.Log(errorMsj, TPLogType.ERROR, e);
      console.error(errorMsj);
      throw new Error(errorMsj);
    }
  }
}
