import { TPKeyValue } from "@/helpers/TPKeyValue";
import React, { FC } from "react";

interface TPRenderKeyValuesProps {
  items: Array<TPKeyValue>;
  size: number;
  //items: key => Label , value: jsx
}

const TPRenderKeyValues: FC<TPRenderKeyValuesProps> = ({ items, size }) => {
  return (
    <>
      <div
        className="mb-2"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill,minmax(${size}px, 1fr))`,
        }}
      >
        {items.map(function (item1, idx1, array) {
          return (
            <div className="mb-2" key={"renderkeyvalue" + idx1.toString()}>
              <div>
                <span className={`tpbold ${array[idx1].value2 ? "tpred" : ""}`}>
                  {array[idx1].key}
                </span>
              </div>
              <div>{array[idx1].value}</div>
            </div>
          );
        })}
      </div>

      {/* <table className="tptable-key-values">
        {items.map(function (item1, idx1, array) {
          let jsxElement1: any;
          if (idx1 % columns === 0) {
            jsxElement1 = (
              <tr key={"item" + idx1}>
                {items.map(function (item2, idx2) {
                  let jsxElement2: any;
                  let j: number;
                  let idxStart: number;
                  let idxEnd: number;
                  j = Math.trunc(idx1 / columns) + 1;
                  idxStart = (j - 1) * columns;
                  idxEnd = idxStart + columns - 1;
                  if (
                    idx2 >= idxStart &&
                    idx2 <= idxEnd &&
                    idx2 <= array.length - 1
                  ) {
                    jsxElement2 = (
                      <td key={"itemtd" + idx2}>
                        <div>
                          <span
                            className={`tpbold ${
                              array[idx2].value2 ? "tpred" : ""
                            }`}
                          >
                            {array[idx2].key}
                          </span>
                        </div>
                        <div>{array[idx2].value}</div>
                      </td>
                    );
                  } else {
                    jsxElement2 = null;
                  }
                  return jsxElement2;
                })}
              </tr>
            );
          } else {
            jsxElement1 = null;
          }
          return jsxElement1;
        })}
      </table> */}
    </>
  );
};

export default TPRenderKeyValues;
