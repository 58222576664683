import { SxProps } from "@mui/material";

export const StyledTab: SxProps = {
  alignItems: "center",
  textTransform: "none",
  color: "#2E2E2E",
  fontSize: "14px",
  transition: "none",
  paddingLeft: "2em",
  paddingRight: "2em",
  "&.Mui-selected": {
    color: "#780096",
    fontWeight: "700",
    borderColor: "#780096",
  },
  "&:focus": { outline: "none" },
  "&:active": { backgroundColor: "transparent" },
};

export const StyledTabs: SxProps = {
  "& .MuiTabs-indicator": {
    backgroundColor: "#780096",
  },
};
