import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { forwardRef, useEffect, useState } from "react";
import { ContainerTabStyle, TabActiveStyle } from "../FormDesigner/StyleFromDesigner";
import MyRemindersCalendar from "./MyRemindersCalendar";
import MyRemindersList from "./MyRemindersList";
import { CountStyle, NumberCountRemindersStyle } from "./StyeMyReminders";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "./MyRemindersSlice";
import { useDispatch } from "react-redux";
import { RemindersService } from "@/services/MyRemindersService";
import TPGlobal from "@/helpers/TPGlobal";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

interface Model {
  callBackCommands: Function;
}

enum pageEnum {
  Calendar,
  List
}

const MyReminders = forwardRef(({ callBackCommands }: Model, ref) => {
  const [page, setPage] = useState(pageEnum.Calendar);
  const { reminders, remindersLoaded: contentLoaded } = useSelector((s: StoreModel) => s[MyRemindersSlice.name]) as MyRemindersSliceModel;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getReminders = async function () {
    if (loading) return;
    setLoading(true);
    await RemindersService.getByUserId(TPGlobal.currentUserGuid, false, false, [200, 404])
      .then(remindersReponse => {
        dispatch(MyRemindersSlice.actions.setReminders(remindersReponse));
        setLoading(false);
        dispatch(MyRemindersSlice.actions.setContentLoaded(true));
      })
      .catch(err => console.error(err));
  }

  useEffect(() => {
    getReminders();
  }, []);

  useEffect(() => {
    if (!contentLoaded) getReminders();
  }, [contentLoaded]);

  return (
    <TPLoadingOverlay active={loading}>
      <div id="reminders-display" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <ContainerTabStyle id="reminders-display-tabs" style={{ height: "44px" }}>
          <TabActiveStyle
            onClick={() => {
              setPage(pageEnum.List);
            }}
            activeBorder={page == pageEnum.List} >
            <NumberCountRemindersStyle>
              <TPButton
                style={{
                  color: page == pageEnum.List ? "" : "gray"
                }}
                iconStyle={{ fontSize: "24px" }}
                type={TPButtonTypes.icon}
                text={""}
                onClick={() => {
                }}
                icon={TPIconTypes.playlistAddCheck}
              />
            </NumberCountRemindersStyle>
          </TabActiveStyle>
          <TabActiveStyle
            onClick={() => {
              setPage(pageEnum.Calendar);
            }}
            activeBorder={page == pageEnum.Calendar} >
            <NumberCountRemindersStyle>
              <TPButton
                style={{
                  color: page == pageEnum.Calendar ? "" : "gray"
                }}
                iconStyle={{ fontSize: "18px" }}
                type={TPButtonTypes.icon}
                text={""}
                onClick={() => { }}
                icon={TPIconTypes.calendar}
              />
              {reminders.length > 0 &&
                <CountStyle>{reminders.length}</CountStyle>
              }
            </NumberCountRemindersStyle>
          </TabActiveStyle>
        </ContainerTabStyle>
        <>
          < MyRemindersCalendar active={(page == pageEnum.Calendar)} key="calendar1" callBackCommands={callBackCommands} />
          <MyRemindersList active={(page == pageEnum.List)} key="list" callBackCommands={() => { }} />
        </>
      </div>
    </TPLoadingOverlay>
  );
}
);

export default MyReminders;
