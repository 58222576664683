import { ReactElement } from "react";
import { StyledTableChip } from "./micro-components-styles";

type TableChipProps = {
    value: boolean;
    justify?: "center" | "flex-end" | "flex-start";
    onLabel?: string;
    offLabel?: string;
    activeColor?: string;
    inactiveColor?: string;
    underline?: boolean;
    notBoolean?: boolean;
    beforeShape?: boolean;
    style?: React.CSSProperties;
    onClick?: () => void;
};

function TableChip({
    value,
    justify = "center",
    onLabel = "YES",
    offLabel = "NO",
    style,
    activeColor,
    inactiveColor,
    underline = false,
    notBoolean = false,
    beforeShape = false,
    onClick = () => { },
}: TableChipProps): ReactElement {
    const getBooleanValueLabel = (value: boolean): string => {
        return value ? onLabel : offLabel;
    };

    return (
        <StyledTableChip
            style={style}
            justify={justify}
            activeColor={activeColor}
            inactiveColor={inactiveColor}
            underline={underline}
            notBoolean={notBoolean}
            beforeShape={beforeShape}
            value={value}
            onClick={onClick}
        >
            <p>{getBooleanValueLabel(value)}</p>
        </StyledTableChip>
    );
}

export default TableChip;
