import styled from "styled-components";
import { InfoCardColor } from "./supervisor-cockpit.model";

type TabProps = {
  active: boolean;
};

type InformationCardProps = {
  baseStyle: InfoCardColor;
  customStyle?: InfoCardColor;
};

type ScheduleDotProps = {
  color: string;
};

export const StyledCockpitDate = styled.p(
  ({ theme }) => `
  color: #989898;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  margin-top: 7px;
`
);

export const StyledTabContainer = styled.div(
  ({ theme }) => `
  display: flex;
  background-color: #F4F4F4;
  border-radius: 4px;
`
);

export const StyledTabButton = styled.button<TabProps>(
  ({ theme, active }) => `
    border: none;
    background-color: ${active ? theme.colors.cim.button["primary"].background : "#F4F4F4"};
    font-weight: 700;
    color: ${active ? "white" : "black"};
    text-align: center;
    font-size: 14px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:active {
        box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3), 
        inset 0px -2px 5px rgba(255, 255, 255, 0.3);
    }
`
);

export const StyledInformationCard = styled.div<InformationCardProps>(
  ({ theme, baseStyle, customStyle }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 11px 8px 12.5px;
  min-width: 10em;
  border: none;
  border-radius: 0 4.85px 4.85px 0;
  border-left: 7.5px solid ${customStyle ? customStyle.color : baseStyle.color};
  background-color: ${customStyle ? customStyle.variant : baseStyle.variant};

  .card-info {
    p {
      margin: 0;
      font-weight: 600;
    }

    .label {
      color: #2E2E2E;
      font-size: 15px;
    }

    .value {
      color: ${customStyle ? customStyle.color : baseStyle.color};
      font-size: 16px;
      line-height: 1;
    }
  }

  .card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${customStyle ? customStyle.color : baseStyle.color};
    width: 35px;
    height: 35px;
    border-radius: 4.85px;

    .ui-icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: white;
    }
  }
`
);

export const StyledSmallInformationCard = styled.div<InformationCardProps>(
  ({ theme, baseStyle, customStyle }) => `
  display: flex;
  align-items: center;
  gap: 0.8em;
  padding: 0 1em;
  width: 165px;
  height: 24px;
  border: none;
  border-radius: 20px;
  background-color: ${customStyle ? customStyle.variant : baseStyle.variant};

  p {
    margin: 0;
  }

  .form {
    width: 12px;
    height: 12px;
    background-color: ${customStyle ? customStyle.color : baseStyle.color};
    border-radius: 50%;
  }

  .value {
    color: #2E2E2E;
    font-size: 14px;
  }
`
);

export const StyledRankingUserCard = styled.div(
  ({ theme }) => `
  min-width: 19.7em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  background-color: #F4F4F4;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid #E6E6E6;
  padding: 0.5em 1.2em;
  
  .user-rank {
    display: flex;
    flex-direction: column;
    gap: 0.3em;

    p {
      margin: 0;
      font-size: 12px;
    }

    .name {
      padding-left: 5px;
      font-size: 16px;
    }
  }

`
);

export const StyledRemovableItem = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 124px;
  height: 24px;
  background-color: #E6E6E6;
  border-radius: 4px;
  padding: 0 6px;

  p {
    margin: 0;
    color: #2E2E2E;
    font-size: 12px;
    font-weight: 500;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ui-icon {
    cursor: pointer;
    
    &:hover {
      background-color: #D9D9D9;
    }
  }
`
);

export const StyledUserProfileCompleted = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 0.5em;

  .user-name {
    margin: 0;
  }
`
);

export const StyledScheduleContent = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 0.6em;

  p {
    margin: 0;
  }
`
);

export const StyledScheduleDot = styled.div<ScheduleDotProps>(
  ({ theme, color }) => `
  background-color: ${color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  .ui-icon {
    color: white;
    height: 18px;
    width: 18px;
    font-size: 18px;
  }
`
);

export const StyledRefreshButton = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;

  .ui-icon {
    color: #2E2E2E;
    height: 24px;
    width: 24px;
    font-size: 24px;
    transition: all 0.1s ease-in-out;
  }

  p {
    margin: 0;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    text-decoration: underline;
    font-weight: 600;
    width: fit-content;
  }

  &:hover {
    p {
      color: ${theme.colors.cim.button["primary"].background}
    }

    .ui-icon {
      color: ${theme.colors.cim.button["primary"].background}
    }
  }
`
);
