import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { useId, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TPIcon from "../../extend/TPIcons/TPIcon";
import TPLabel from "../TPLabel/TPLabel";

interface TPAutoCompleteInterface {
  id?: string;
  onValueChange: Function;
  onSearch: Function;
  onKeyDown: Function;
  onText?: Function;
  isLoading: boolean;
  options: Array<TPKeyValue>;
  selected?: Array<TPKeyValue>;
  withIcon?: boolean;
  icon?: TPIconTypes | Array<TPIconTypes>;
  iconClick?: any;
  isHorizontal?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  defaultInputValue?: string;
  emptyLabel: string;
  autoCompleteStyle?: any;
  containerStyle?: any;
  labelStyle?: any;
  disabled?: boolean;
  downArrowClick?: any;
  className?: string;
}
const TPAutoComplete = ({
  id,
  onValueChange,
  onSearch,
  onKeyDown,
  onText,
  isLoading,
  options,
  selected = undefined,
  withIcon = false,
  icon = TPIconTypes.default,
  iconClick = undefined,
  isHorizontal = false,
  labelText,
  isMandatory = false,
  errorMessage = "",
  defaultInputValue = "",
  emptyLabel,
  autoCompleteStyle,
  containerStyle,
  labelStyle,
  disabled = false,
  downArrowClick = null,
  className = "",
}: TPAutoCompleteInterface) => {
  const theId = `${id ? id : useId()}-autocomplete-selection`;

  const [isOpenList, setIsOpenList] = useState(false);

  const handlerOnValueChange = (selected: any) => {
    setIsOpenList(false);
    if (onValueChange) {
      onValueChange(selected);
    }
  };
  const handlerOnSearch = (query: string) => {
    if (onSearch) {
      onSearch(query);
    }
  };

  const handlerOnText = (text: string) => {
    if (onText) {
      onText(text);
    }
  };

  const drawLabel = () => {
    return (
      <TPLabel
        htmlFor={theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
    );
  };
  const drawInvalidFeedBack = () => {
    return <div className="invalid-feedback">{errorMessage}</div>;
  };
  const handlerOnKeyDown = (event: any) => {
    setIsOpenList(true);
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handlerOnBlur = (event: any) => {
    setIsOpenList(false);
  };

  const handlerDownArrowKey = () => {
    setIsOpenList(true);
    downArrowClick();
  };

  const handleDropdownToggle = (isOpen: boolean) => {
    if (isOpen) {
      setTimeout(() => {
        const inputElement = document.getElementById(theId);
        if (inputElement) {
          inputElement.focus();
        }
      }, 0);
    }
  };

  const styleContainsWidth: boolean = !containerStyle
    ? false
    : Object.keys(containerStyle as string).findIndex(
          (x: string) => x === "height"
        ) === -1
      ? false
      : true;
  const realContainerStyle: any = styleContainsWidth
    ? { ...containerStyle, paddingRight: "0px" }
    : { ...containerStyle, height: "35px", paddingRight: "0px" };

  const borderErrorStyle = errorMessage
    ? { ...realContainerStyle, border: "1px solid red" }
    : realContainerStyle;
  return (
    // filterBy={['firstName', 'lastName', 'email']}
    <>
      {!isHorizontal && drawLabel()}
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        className={` ${withIcon ? "input-group " : ""} ${errorMessage !== "" ? "is-invalid" : ""}${className}`}
        style={borderErrorStyle}
      >
        {isHorizontal && drawLabel()}
        <div
          className={`form-control autocomplete-container ${errorMessage !== "" ? "is-invalid" : ""}`}
          style={{ height: "32px" }}
        >
          <AsyncTypeahead
            disabled={disabled}
            id={theId}
            isLoading={isLoading}
            key="key"
            labelKey={"value"}
            onChange={handlerOnValueChange}
            onSearch={(query) => {
              handlerOnSearch(query);
            }}
            onInputChange={(text) => {
              handlerOnText(text);
            }}
            defaultInputValue={defaultInputValue}
            options={options}
            emptyLabel={emptyLabel}
            onKeyDown={handlerOnKeyDown}
            selected={selected}
            onBlur={handlerOnBlur}
            open={isOpenList}
            onMenuToggle={handleDropdownToggle}
            inputProps={{ id: theId }}
            className={errorMessage !== "" ? "is-invalid" : ""}
          />
        </div>
        {withIcon && (
          <>
            {downArrowClick && (
              <div className="input-group-append" onClick={handlerDownArrowKey}>
                <span>
                  <TPIcon iconType={TPIconTypes.keyboardArrowDown} />
                </span>
              </div>
            )}

            {!Array.isArray(icon) && (
              <div className="input-group-append" onClick={iconClick}>
                <span>
                  <TPIcon iconType={icon} />
                </span>
              </div>
            )}
            {Array.isArray(icon) &&
              icon.map((theIcon, indx) => (
                <div
                  key={indx}
                  className="input-group-append"
                  onClick={
                    Array.isArray(iconClick) ? iconClick[indx] : iconClick
                  }
                >
                  <span>
                    <TPIcon iconType={theIcon} />
                  </span>
                </div>
              ))}
          </>
        )}

        {!withIcon && errorMessage != "" && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>
      {withIcon && errorMessage != "" && drawInvalidFeedBack()}
    </>
  );
};

export default TPAutoComplete;
