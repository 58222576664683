const ReturnPurpleIcon = ({ ...props }) => {
    return (
        <div 
            {...props}
            dangerouslySetInnerHTML={{
                __html: `<svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 14L8.91 12.59L4.33 8H20.5V6H4.33L8.92 1.41L7.5 0L0.5 7L7.5 14Z" fill="#780096"/>
</svg>
`,
            }}
        ></div>
    )
}

export default ReturnPurpleIcon;