import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { WorkflowDetailPredecessorMultipleInputDTO } from "@/models/Workflow/WorkflowDetailPredecessorInputDTO";
import { WorkflowDetailPredecessorViewModel } from "@/models/Workflow/WorkflowTypeModels";

export class WorkflowDetailPredecessorService {
  serviceFileName: string = "WorkflowDetailPredecessorService.ts";

  public async insert(
    inputDTO: WorkflowDetailPredecessorMultipleInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowdetailpredecessor/multiple";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertFunction`);
      throw new Error(`Error ${this.serviceFileName} insertFunction`);
    }
  }

  public async getPredecessorByWorkflowDetail(
    workflowDetailId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<WorkflowDetailPredecessorViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/workflowdetailpredecessor/${workflowDetailId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getPredecessorByWorkflowDetail ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getPredecessorByWorkflowDetail`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getPredecessorByWorkflowDetail`,
      );
    }
  }

  public async getAvailablesByWorkflowDetailAndWorkflowType(
    workflowDetailId: string,
    workflowTypeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<WorkflowDetailPredecessorViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;

    url = `/workflowdetailpredecessor/availables-predecessor/${workflowDetailId}/${workflowTypeId}`;
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAvailablesByWorkflowDetailAndWorkflowType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getAvailablesByWorkflowDetailAndWorkflowType`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getAvailablesByWorkflowDetailAndWorkflowType`,
      );
    }
  }
}
