import EmailTemplateCloneModel from "./EmailTemplateCloneModel";
import * as yup from "yup";
import e from "./EmailTemplateCloneEnum";

/**
 * email template clone model
 */
export const EmailTemplateCloneFormControl = {
  initialValues: {
    sourceId: "",
    destinationId: "",
    description: "",
    descriptionLocalizedValues: [],
    idAccount: "",
    isActive: false,
    isAddable: false,
  } as EmailTemplateCloneModel,
  validationSchema: yup.object({
    sourceId: yup
      .string()
      .required(e.RequiredFieldLabel)
      .max(20, e.Maximum20CharactersAllowedLabel),
    destinationId: yup
      .string()
      .required(e.RequiredFieldLabel)
      .max(20, e.Maximum20CharactersAllowedLabel),
    description: yup
      .string()
      .required(e.RequiredFieldLabel)
      .max(100, e.Maximum100CharactersAllowedLabel),
  }),
};
