import styled from "styled-components";

export const TPTreeBranchColorConventionsStyled = styled.div(
  ({ theme }) => `
  table,
  th,
  td {
    border: none !important;
  }
  td {
    margin-bottom: 7px;
    word-break: break-word;
  }
  td span.branchActiveVisible {
    color: ${theme.colors.tree.branch.conventions.active.visible.fontColor};
    padding: 5px;
  }
  td span.branchActiveInvisible {
    color: ${theme.colors.tree.branch.conventions.active.invisible.fontColor};
    background-color: ${theme.colors.tree.branch.conventions.active.invisible.backgroundColor};
    padding: 5px;
  }
  td span.branchInactiveVisible {
    color: ${theme.colors.tree.branch.conventions.inactive.visible.fontColor};
    padding: 5px;
  }
  td span.branchInactiveInvisible {
    color: ${theme.colors.tree.branch.conventions.inactive.invisible.fontColor};
    background-color: ${theme.colors.tree.branch.conventions.inactive.invisible.backgroundColor};
    padding: 5px;
  }
`,
);
