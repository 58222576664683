import React, { FC, useEffect, useReducer, useState } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPSearchCustomer from "@/components/bootstrap/forms/TPSearchCustomer/TPSearchCustomer";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";

import {
  AttachmentType,
  InboundMailboxInProcessActionsType,
  InboundMailboxInProcessType,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";

import { TPI18N } from "@/services/I18nService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { InboundMailboxInProcessService } from "@/services/InboundMailboxInProcessService";
import { InboundMailboxInProcessViewModel } from "@/models/InboundMailboxInProcess/InboundMailboxInProcessViewModel";
import moment from "moment";
import { DownloadFileService } from "@/services/DownloadFileService";
import { StorageService } from "@/services/StorageService";
import {
  ApplyRules,
  ApplyRulesDTO,
  InboundMailboxInProcessInputDTOValidator,
  isInprocess,
  isWithFixedClassifier,
} from "@/models/InboundMailboxInProcess/ApplyRulesDTO";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";

import { InboundMailboxInProcessAttachmentService } from "@/services/InboundMailboxInProcessAttachmentService";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import { InboundmailruledefinitionViewModel } from "@/models/InboundMailRule/InboundmailruledefinitionViewModel";
import RuleApplied from "./RuleApplied";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { InboundMailboxInProcessAttachmentViewModel } from "@/models/InboundMailboxInProcessAttachment/InboundMailboxInProcessAttachmentViewModel";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";

interface InboundMailboxInProcessUpdateProps {
  mode: string;
  recordId: string;
  callBackResult: Function;
}

interface InsertUpdateStateType {
  id: number;
  fromAddress: string;
  addressTO: string;
  addressCC: string;
  emailGMTDate: string | Date;
  subject: string;
  insertionType: string;
  ruleDefinitionId: string;
  action: string | null;
  classifier: string | null;

  actionErrorMessage: string;
  classifierErrorMessage: string;
  inboundMailboxInProcessIdErrorMessage: string;

  [key: string]: any;
}

interface ApplyRulesResponseType {
  key: string;
  value: string;
}

const InboundMailboxInProcessUpdate: FC<InboundMailboxInProcessUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}) => {
  const componentFileName: string = "InboundMailboxInProcessUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "InboundMailboxInProcessUpdateComponent";
  //State filter dropdown
  const initialStateFilter: Array<TPKeyValue> = [];
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [caseOrCustomerInfoSectionLabel, setCaseOrCustomerInfoSectionLabel] =
    useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [okButtonLabel, setOkButtonLabel] = useState("");
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [modalConfirmTitle, setModalConfirmTitle] = useState("");
  const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [fromAddressLabel, setFromAddressLabel] = useState("");
  const [addressTOLabel, setAddressTOLabel] = useState("");
  const [addressCCLabel, setAddressCCLabel] = useState("");
  const [emailGMTDateLabel, setEmailGMTDateLabel] = useState("");
  const [subjectLabel, setSubjectLabel] = useState("");
  const [htmlBodyLabel, setHtmlBodyLabel] = useState("");
  const [downloadLabel, setDownloadLabel] = useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [classifierLabel, setClassifierLabel] = useState("");
  const [applyLabel, setApplyLabel] = useState("");
  const [ruleAppliedLabel, setRuleAppliedLabel] = useState("");
  const [mailAttachmentsLabel, setMailAttachmentsLabel] = useState("");

  const [classifierKeyValue, setClassifierKeyValue] =
    useState(initialStateFilter);
  const [actionsKeyValue, setActionsKeyValue] = useState(initialStateFilter);

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  const [inboundMailboxInProcess, setInboundMailboxInProcess] =
    useState<InboundMailboxInProcessViewModel | null>(null);

  const [
    inboundMailboxInProcessAttachment,
    setInboundMailboxInProcessAttachment,
  ] = useState<InboundMailboxInProcessAttachmentViewModel[]>([]);

  const [inboundMailRuledefinition, setInboundMailRuledefinition] =
    useState<InboundmailruledefinitionViewModel | null>(null);

  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };

  const [modalQuestionProcessMails, setModalQuestionProcessMails] = useState(
    modalQuestionInitialState,
  );

  const [modalSuccess, setModalSuccess] = useState(false);
  const [applyRulesResponse, setApplyRulesResponse] = useState<
    ApplyRulesResponseType[]
  >([]);

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: -1,
    fromAddress: "",
    addressTO: "",
    addressCC: "",
    emailGMTDate: "",
    subject: "",
    insertionType: "",
    ruleDefinitionId: "",
    action: "",
    classifier: "",

    actionErrorMessage: "",
    classifierErrorMessage: "",
    inboundMailboxInProcessIdErrorMessage: "",
  };

  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const loadResourcesAndLoadInfo = async () => {
    //resources state
    let i: number;
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + realMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setCaseOrCustomerInfoSectionLabel(
      await TPI18N.GetText(
        resourceSet,
        "CaseOrCustomerInformationSectionLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setModalConfirmTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
    );

    setModalSuccessTitle(
      await TPI18N.GetText(resourceSet, "ModalSuccessTitle"),
    );
    setModalConfirmQuestion(
      await TPI18N.GetText(resourceSet, "ModalConfirmQuestion"),
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setFromAddressLabel(await TPI18N.GetText(resourceSet, "fromNameLabel"));
    setAddressTOLabel(await TPI18N.GetText(resourceSet, "AddressToLabel"));
    setAddressCCLabel(await TPI18N.GetText(resourceSet, "AddressCcLabel"));
    setEmailGMTDateLabel(
      await TPI18N.GetText(resourceSet, "EmailGMTDateLabel"),
    );
    setSubjectLabel(await TPI18N.GetText(resourceSet, "SubjectLabel"));
    setHtmlBodyLabel(await TPI18N.GetText(resourceSet, "HtmlBodyLabel"));
    setDownloadLabel(await TPI18N.GetText(resourceSet, "DownloadLabel"));
    setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
    setClassifierLabel(await TPI18N.GetText(resourceSet, "ClassifierLabel"));
    setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
    setRuleAppliedLabel(await TPI18N.GetText(resourceSet, "RuleAppliedLabel"));
    setMailAttachmentsLabel(
      await TPI18N.GetText(resourceSet, "MailAttachmentsLabel"),
    );

    await getQuickClassifiersByType();
    await getActions();
    await getInboundMailboxInProcessAttachmentById(realRecordId);

    if (realMode === "Update") {
      await init();
    }

    if (realMode === "Insert") {
    }

    setIsLoadingScreen(false);
  };

  const init = async () => {
    const mailboxInProcess = await getInboundMailboxInProcessById(realRecordId);
    if (mailboxInProcess) {
      const {
        id,
        fromAddress,
        addressTO,
        addressCC,
        emailGMTDate,
        subject,
        insertionType,
        ruleDefinitionId,
      } = mailboxInProcess;
      setInboundMailboxInProcess(mailboxInProcess);
      const insertUpdate = {
        ...insertUpdateState,
        id,
        fromAddress,
        addressTO,
        addressCC,
        emailGMTDate,
        subject,
        insertionType,
        ruleDefinitionId,
      };
      setInsertUpdateState(insertUpdate);
      await getInboundMailRuledefinitionById(ruleDefinitionId);
    }
  };

  // const isInprocess = (insertTypeValue: string) =>
  //   insertTypeValue ===
  //   `${InboundMailboxInProcessType.InboundMailboxTypeInProcess}`;
  // const isJunkMail = (insertTypeValue: string) =>
  //   insertTypeValue === `${InboundMailboxInProcessType.InboundMailboxTypeJunk}`;
  // const isWithFixedClassifier = (actionsValue: string) =>
  //   actionsValue ===
  //   `${InboundMailboxInProcessActionsType.ActionCreateNewCaseWithFixedClassifier}`;

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    setInsertUpdateState({
      ...insertUpdateState,
      [propertyName]: newValue,
      [`${propertyName}ErrorMessage`]: "",
    });
  };

  const getInboundMailRuledefinitionById = async (ruleDefinitionId: string) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getInboundMailRuleDefinitionById(
          ruleDefinitionId,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      setInboundMailRuledefinition(responseRequest);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getInboundMailRuledefinitionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getInboundMailRuledefinitionById ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getInboundMailboxInProcessById = async (pRecordId: string) => {
    let serviceClient = new InboundMailboxInProcessService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getInboundMailboxInProcessById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      return responseRequest[0];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getInboundMailboxInProcessById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getInboundMailboxInProcessById ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  //Filter Actions
  const getActions = async () => {
    const actionsEntries = Object.entries(InboundMailboxInProcessActionsType);

    let actionsKeyValue: TPKeyValue[] = await Promise.all(
      actionsEntries.map(async ([key, value]) => {
        return {
          key: value,
          value: await TPI18N.GetText(resourceSet, `${key}Value`),
        };
      }),
    );

    actionsKeyValue = [{ key: "", value: "--" }, ...actionsKeyValue];
    setActionsKeyValue(actionsKeyValue);
  };

  const getInboundMailboxInProcessAttachmentById = async (
    mailboxInProcessId: string,
  ) => {
    const inboundMailboxInProcessAttachment =
      new InboundMailboxInProcessAttachmentService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);

      const responseRequest =
        await inboundMailboxInProcessAttachment.getInboundMailboxInProcessAttachmentById(
          mailboxInProcessId,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      setInboundMailboxInProcessAttachment(responseRequest);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getInboundMailboxInProcessAttachmentById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getInboundMailboxInProcessAttachmentById ex`,
      );
      setIsLoadingScreen(false);
      setInboundMailboxInProcessAttachment([]);
      return [];
    }
  };

  const getQuickClassifiersByType = async () => {
    const quickClassifierClient = new QuickClassifierService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);

      const responseRequest =
        await quickClassifierClient.getQuickClassifiersByType(
          WebServiceClassifierType.MailReader,
          TPActiveOptions.ACTIVE,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      const quickClassifierList = responseRequest.map((res) => ({
        key: res.id,
        value: res.description,
      }));
      setClassifierKeyValue([{ key: "", value: "--" }, ...quickClassifierList]);
      return quickClassifierList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getQuickClassifiersByType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getQuickClassifiersByType ex`);
      setIsLoadingScreen(false);
      setClassifierKeyValue([]);
      return [];
    }
  };

  const applyRulesInboundMailboxInProcess = async (data: ApplyRulesDTO) => {
    let serviceClient = new InboundMailboxInProcessService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.applyRulesInboundMailboxInProcess(
          data,
          false,
          true,
          expectedCodes,
        );

      const res = responseRequest.responseData?.keyList;
      setApplyRulesResponse(res);
      if (res?.length) setModalSuccess(true);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`,
      );
      setIsLoadingScreen(false);
      return [];
    }
  };

  const getFileUriSas = async (
    attachmentType: AttachmentType,
    storageId: string,
  ) => {
    const storageService = new StorageService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await storageService.getFileUriSas(
        storageId,
        attachmentType,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFileUriSas ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFileUriSas ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleDownloadFile = async (
    attachmentType: AttachmentType,
    storageId: string,
  ) => {
    const downloadFileService = new DownloadFileService();
    const res = await getFileUriSas(attachmentType, storageId);
    try {
      if (res) {
        setIsLoadingScreen(true);
        const { sasUri, originalFileName, mimeType } = res;
        await downloadFileService.downloadFileUri({
          // isProxy: true,
          sasUri,
          originalFileName,
          mimeType,
        });
        setIsLoadingScreen(false);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleDownloadFile ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} handleDownloadFile ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleApplyAction = async () => {
    const data = {
      InsertionType: insertUpdateState.insertionType,
      InboundMailbox: "default",
      Action: Number(insertUpdateState.action),
      FixedClassifier: insertUpdateState.classifier || null,
      InboundMailboxInProcessId: [Number(insertUpdateState.id)],
    };
    formValidate(data);
  };

  const formValidate = async (recordInputDTO: ApplyRules) => {
    let newInsertUpdateState = { ...insertUpdateState };
    let inputDTOGroupValidator = new InboundMailboxInProcessInputDTOValidator();
    // let hasError: boolean = false;
    let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);

    if (TPGlobal.TPIsEmpty(resultValidator)) {
      const newModalQuestionProcessMails = {
        ...modalQuestionProcessMails,
        isShown: true,
      };
      setModalQuestionProcessMails(newModalQuestionProcessMails);
    } else {
      // Message Errors
      if (resultValidator?.Action) {
        newInsertUpdateState.actionErrorMessage = await TPI18N.GetResource(
          resultValidator.Action,
        );
      } else {
        newInsertUpdateState.actionErrorMessage = "";
      }

      if (resultValidator?.FixedClassifier) {
        newInsertUpdateState.classifierErrorMessage = await TPI18N.GetResource(
          resultValidator.FixedClassifier,
        );
      } else {
        newInsertUpdateState.classifierErrorMessage = "";
      }

      if (resultValidator?.InboundMailboxInProcessId) {
        newInsertUpdateState.inboundMailboxInProcessIdErrorMessage =
          await TPI18N.GetResource(
            `${resultValidator.InboundMailboxInProcessId}`,
          );
      } else {
        newInsertUpdateState.inboundMailboxInProcessIdErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
    }
  };

  const handleCallBackModalConfirm = async (confirm: boolean) => {
    const newModalQuestionProcessMails = {
      ...modalQuestionProcessMails,
      callBackData: {},
      isShown: false,
    };
    if (confirm) {
      applyRulesInboundMailboxInProcess({
        Action: Number(insertUpdateState.action),
        FixedClassifier: insertUpdateState.classifier || null,
        InboundMailboxInProcessId: [Number(insertUpdateState.id)],
      });
    }
    setModalQuestionProcessMails(newModalQuestionProcessMails);
  };

  const resourceIdsTheadTable = [
    // "#",
    "IdColumnLabel",
    "DownloadColumnLabel",
    "FileNameColumnLabel",
    "FileExtensionColumnLabel",
    "FileSizeColumnLabel",
  ];

  const callBackSaveSuccess = () => {
    setModalSuccess(false);
    const resetInsertUpdateState = {
      action: "",
      classifier: "",
    };
    setInsertUpdateState({ ...insertUpdateState, ...resetInsertUpdateState });
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadInfo();
  }, []);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {/* {realMode === "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel} ${insertUpdateState.id} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null} */}
            <hr />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div> */}

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      value={inboundMailboxInProcess?.id}
                      onChange={(e: any) =>
                        handleInputTextChange("id", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={fromAddressLabel}
                      value={inboundMailboxInProcess?.fromAddress}
                      onChange={(e: any) =>
                        handleInputTextChange("fromAddress", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={addressTOLabel}
                      value={inboundMailboxInProcess?.addressTO}
                      onChange={(e: any) =>
                        handleInputTextChange("addressTO", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={addressCCLabel}
                      value={inboundMailboxInProcess?.addressCC}
                      onChange={(e: any) =>
                        handleInputTextChange("addressCC", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={emailGMTDateLabel}
                      value={moment(
                        inboundMailboxInProcess?.emailGMTDate,
                      ).format("YYYY/MM/DD HH:mm")}
                      onChange={(e: any) =>
                        handleInputTextChange("emailGMTDate", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={subjectLabel}
                      value={inboundMailboxInProcess?.subject}
                      onChange={(e: any) =>
                        handleInputTextChange("subject", e.target.value)
                      }
                      maxLength={50}
                      disabled={realMode !== "Insert"}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-10">
            <TPPageSection>
              <TPLabel
                isMandatory={false}
                labelText={htmlBodyLabel}
                className="mb-1"
              />

              <div className="row mx-1">
                <div
                  style={{
                    height: "200px",
                    overflow: "auto",
                    border: "1px solid #A5A5A5",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `${inboundMailboxInProcess?.htmlBody}`,
                  }}
                />
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-10">
            <TPPageSection>
              <TPLabel
                isMandatory={false}
                labelText={downloadLabel}
                // style={}
              />
              <div className="row">
                <div className="col-2">
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      handleDownloadFile(
                        AttachmentType.MAILREADEREML,
                        `${inboundMailboxInProcess?.storageIdEML}`,
                      )
                    }
                    icon={TPIconTypes.fileDownload}
                    text={`${inboundMailboxInProcess?.id}.eml`}
                    className=""
                  />
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        {/* <div className="row mt-1">
          <div className="col-10">
            <TPPageSection>
              <TPLabel
                isMandatory={false}
                labelText={"Rule Applied"}
                className="mb-1"
              />
              <div className="row mx-1">
                {inboundMailRuledefinition?.jsonRule}
              </div>
            </TPPageSection>
          </div>
        </div> */}

        <div className="row mt-1">
          <div className="col-10">
            <TPPageSection>
              <TPLabel
                isMandatory={false}
                labelText={`${ruleAppliedLabel}: ${inboundMailRuledefinition?.description}`}
                className="mb-1"
              />
              <div className="row mx-1">
                {inboundMailRuledefinition?.jsonRule && (
                  <RuleApplied
                    inboundMailRuleJson={JSON.parse(
                      inboundMailRuledefinition.jsonRule,
                    )}
                    isHorizontal={true}
                  />
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-5">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("action", e.target.value);
                      }}
                      dataSource={actionsKeyValue}
                      value={insertUpdateState.action}
                      labelText={actionLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.actionErrorMessage}
                    />
                  </div>
                </div>
                {isWithFixedClassifier(`${insertUpdateState?.action}`) && (
                  <div className="col-5">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          handleInputTextChange("classifier", e.target.value);
                        }}
                        dataSource={classifierKeyValue}
                        value={insertUpdateState.classifier}
                        labelText={classifierLabel}
                        isMandatory={true}
                        errorMessage={insertUpdateState.classifierErrorMessage}
                      />
                    </div>
                  </div>
                )}
                <div className="col-2 mt-1">
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.primary}
                    onClick={handleApplyAction}
                    className="mt-4"
                  >
                    {applyLabel}
                  </TPButton>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        {inboundMailboxInProcessAttachment.length && (
          <div className="row">
            <div className="col-10">
              <TPPageSection>
                <div style={{ border: "1px solid #A5A5A5" }}>
                  <TPLabel
                    isMandatory={false}
                    labelText={mailAttachmentsLabel}
                    className="h-4 m-2"
                  />
                  <div className="row m-2">
                    <table className="tp-fixed-table">
                      <thead>
                        {resourceIdsTheadTable.map((item, index) => (
                          <th key={`th-${index}`}>
                            <TPLanguage
                              resourceSet={resourceSet}
                              resourceId={item}
                            />
                          </th>
                        ))}
                      </thead>
                      <tbody>
                        {inboundMailboxInProcessAttachment.map(
                          (
                            item: InboundMailboxInProcessAttachmentViewModel,
                            index,
                          ) => (
                            <tr key={`tr-${index}`}>
                              {/* <td>{index + 1}</td> */}
                              <td>{item.id}</td>
                              <td>
                                <TPButton
                                  id="IdButton"
                                  type={TPButtonTypes.icon}
                                  onClick={() => {
                                    handleDownloadFile(
                                      AttachmentType.MAILREADERATTACHMENT,
                                      item.fileNameStorage,
                                    );
                                  }}
                                  className=""
                                  icon={TPIconTypes.fileDownload}
                                ></TPButton>
                              </td>
                              <td>{item.fileName}</td>
                              <td>{item.fileExtension}</td>
                              <td>{item.fileSize}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TPPageSection>
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
      </TPLoadingOverlay>

      <TPModalQuestion
        id="IdModalQuestion"
        title={modalConfirmTitle}
        yesLabel={okButtonLabel}
        noLabel={cancelButtonLabel}
        question={modalConfirmQuestion}
        callBackData={modalQuestionProcessMails.callBackData}
        isShown={modalQuestionProcessMails.isShown}
        callBackAnswer={handleCallBackModalConfirm}
      ></TPModalQuestion>

      <TPModalSuccess
        modalState={{
          id: "IdModalSuccess",
          title: modalSuccessTitle,
          yesLabel: okButtonLabel,
          isShown: modalSuccess,
          callBackFunction: () => callBackSaveSuccess(),
        }}
      >
        {applyRulesResponse &&
          applyRulesResponse.map(
            ({ key, value }, index) =>
              index > 0 && (
                <div key={key} className="d-flex bd-highlight px-2">
                  <div className="p-2 w-100 bd-highlight">
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId={`${key}Label`}
                    />
                  </div>
                  <div className="p-2 flex-shrink-1 bd-highlight">{`${value}`}</div>
                </div>
              ),
          )}
      </TPModalSuccess>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default InboundMailboxInProcessUpdate;
