import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
  LoadHistoryResponse,
  RecordStatus,
  UploadProgressData,
  UserRecord,
} from "@/models/LoadHistory/load-history.model";
import { throwError } from "@/modules/core/utils/errors-management";

interface BaseParams {
  showPositiveMessage?: boolean;
  showNegativeMessage?: boolean;
  expectedCodes?: number[];
}

export interface GetHistoryProps extends BaseParams {
  structureId: string | null;
  projectId: string | null;
}

export interface GetErrorFileProps extends BaseParams {
  eventTemporaryLoadId: string;
}

export interface GetUploadProgressProps extends GetHistoryProps {}

export interface GetUserRecordsProps extends BaseParams {
  eventLoadId: string;
  recordStatus: RecordStatus;
}

export class LoadHistoryService {
  serviceFileName: string = "LoadHistoryService";
  baseUrl: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventload`;
  headers: TPKeyValue[] = TPGlobal.standardHeaders;

  public async getHistoryLoadData({
    projectId,
    structureId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GetHistoryProps): Promise<LoadHistoryResponse[]> {
    const httpClient = new TPHTTPService();

    const params = {
      eventProjectId: projectId,
      eventLoadStructureId: structureId,
    };

    try {
      const response: HTTPResponse = await httpClient.getData(
        this.baseUrl,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers,
        params
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getHistoryLoadData"
      );
      throw new Error(msgError);
    }
  }

  public async getUserRecordsBy({
    eventLoadId,
    recordStatus,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GetUserRecordsProps): Promise<UserRecord[]> {
    const httpClient = new TPHTTPService();

    const url: string = `${this.baseUrl}/${eventLoadId}/status/${recordStatus}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(error, this.serviceFileName, "getUserRecords");
      throw new Error(msgError);
    }
  }

  public async getUploadProgressData({
    projectId,
    structureId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GetHistoryProps): Promise<UploadProgressData[]> {
    const httpClient = new TPHTTPService();

    const url: string = `${this.baseUrl}/eventtemporaryload`;

    const params = {
      eventProjectId: projectId,
      eventLoadStructureId: structureId,
    };

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers,
        params
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getUploadProgressData"
      );
      throw new Error(msgError);
    }
  }

  public async getFileErrorDetails({
    eventTemporaryLoadId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GetErrorFileProps): Promise<any[]> {
    const httpClient = new TPHTTPService();

    const url: string = `${this.baseUrl}/eventtemporaryloaderror/${eventTemporaryLoadId}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getFileErrorDetails"
      );
      throw new Error(msgError);
    }
  }
}
