import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface WorkflowDetailInputDTO {
  id: number;
  workflowTypeId: string;
  writerId: string;
  readerId: string;
  taskTypeId: string;
  term: number | null;
  termUnit: string;
  order: number | null;
  isActive: boolean;
  closesCustomer: boolean;
  definesResponsible: boolean;
  requiresAllPredecessors: boolean;
}

export class WorkflowDetailInputDTOValidator extends Validator<WorkflowDetailInputDTO> {
  constructor() {
    super();

    const resourceSet = "WorkflowDetailInsertUpdateComponent";

    try {
      this.ruleFor("order")
        .must((order) => {
          if (order == null) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOOrderEmpty");

      this.ruleFor("workflowTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOWorkflowTypeIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidWorkflowTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidWorkflowTypeIdLength");

      this.ruleFor("writerId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOWriterEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidWriter")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidWriterLength");

      this.ruleFor("readerId")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReader")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidReaderLength");

      this.ruleFor("taskTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTaskTypeIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeIdLength");

      this.ruleFor("term")
        .must((term) => {
          if (term == null) {
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOTermEmpty");
      this.ruleFor("termUnit")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTermUnitEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTermUnit")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTermUnitLength");
    } catch (error) {}
  }
}
