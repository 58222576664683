const CloudIcon = ({ ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg
                  width="38"
                  height="31"
                  viewBox="0 0 38 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 15.5V29.75"
                    stroke="#989898"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.284 25.6182C33.8283 24.7763 35.0482 23.4441 35.7513 21.8319C36.4543 20.2197 36.6005 18.4192 36.1666 16.7147C35.7328 15.0102 34.7437 13.4986 33.3554 12.4187C31.9671 11.3388 30.2587 10.7519 28.4998 10.7507H26.5048C26.0256 8.89705 25.1323 7.17611 23.8922 5.71732C22.6521 4.25853 21.0975 3.09984 19.3451 2.32838C17.5928 1.55691 15.6884 1.19273 13.775 1.26323C11.8616 1.33372 9.98917 1.83706 8.29834 2.73538C6.60752 3.63371 5.14234 4.90366 4.01297 6.44975C2.88359 7.99584 2.11941 9.77785 1.77787 11.6618C1.43633 13.5457 1.52631 15.4826 2.04107 17.3267C2.55582 19.1709 3.48195 20.8744 4.74982 22.3091"
                    stroke="#989898"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.3332 21.8333L18.9998 15.5L12.6665 21.8333"
                    stroke="#989898"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>`,
      }}
    ></div>
  );
};

export default CloudIcon;
