import styled from "styled-components";

export const TPConditionsTableContainer = styled.div``;
export const TPConditionsContainer = styled.div(
  ({ theme }) => `
  border: 1px solid ${theme.colors.conditionsTable.container.borderColor};
  padding: 10px;
  box-shadow: 7px 10px 5px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 7px 10px 5px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 7px 10px 5px -4px rgba(0, 0, 0, 0.2);
`,
);
export const TPTotalConditions = styled.span(
  ({ theme }) => `
  padding: 3px;
  background-color: ${theme.colors.conditionsTable.totalConditions.backgroundColor};
  color: white;
  font-size: 15px;
  margin-left: 5px;
`,
);
