import { FC, useImperativeHandle, useState } from "react";
import "./resources/TaskReminderStyles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TaskReminderModal } from "./TaskReminderModal";
import { TaskReminderMode } from "@/models/Task/TaskReminder";

interface TaskReminderButtonProperties {
  taskId: number;
  caseId: number;
}

export const TaskReminderButton: FC<TaskReminderButtonProperties> = function ({ taskId, caseId }) {

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {modalVisible &&
      <TaskReminderModal
        taskId={taskId}
        caseId={caseId}
        onAction={() => setModalVisible(false)}
        />}
      <button
        type="button"
        className="reminder-button"
        onClick={(e) => {
          setModalVisible(true);
          e.stopPropagation();
        }}
      >
        <TPIcon
          iconType={TPIconTypes.clock}
        />
      </button>
    </div>
  )
}