import styled from "styled-components";

export const TreeCloneBranchStyles = styled.div(
  (theme) => `
    width: 32%;

    .tree-clone {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        padding: 1em;
        border-radius: 5px;
        border: 2px dashed #a00095;
        height: 25em;
        overflow: auto;
    }

    .tree-clone > span {
        font-size: 0.8rem;
        margin: 0;
    }

    .tree-clone > ul {
        margin: 0 !important;
    }

    .tree-clone > p {
        font-weight: 600;
    }
`,
);
