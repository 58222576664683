
import React, { forwardRef, useEffect, useReducer, useState } from "react";
import { RightRowContainerStyle } from "../FormDesigner/StyleFromDesigner";
import TPGlobal from "../../helpers/TPGlobal";
import { AttachmentType, TPActiveOptions, TPButtonTypes, TPIconTypes, TPQuickSelectDatesEnum, WebServiceClassifierType } from "../../models/Global/TPGlobalEnums";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "../../modules/core/components/dynamic-table/DynamicTable";
import { TPPageTitle } from "../../components/TPPage/tpPageStyles";

import TPTextBox from "../../components/bootstrap/forms/textbox/TPTextBox";
import { AdministrationUsersStationService } from "../../services/AdministrationUsersStationService";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { UserStation, UserStationConcept } from "../../models/AdministrationUsersStation/AdministrationUsersStation";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { MinorOption } from "../../modules/core/components/dynamic-table/TableActionItem";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import TPModalQuestion from "../../layouts/ModalQuestion/TPModalQuestion";


import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPSelect from "../../components/bootstrap/forms/select/TPSelect";

import { QuickClassifierService } from "../../services/QuickClassifierService";
import { InboundMailboxesService } from "../../services/InboundMailboxesService";
import { InboundMailErrorService } from "../../services/IboundMailsWithErrorService";
import { ContainerBodyStyle, PopupContainerChildreStyle, PopupContainerStyle } from "../SearchIncomingMail/StyleSearchIncomingMail";
import { LocationLanguagesInboundMailWithErrors, LocationNameGlobal, LocationNameTable } from "./InboundMailWithErrorsLocalization";
import { InboundMailWithErrorsModel, InputAction } from "../../models/InboundMailWithErrors/InboundMailWithErrorsModel";
import { useStatusInboundMailWithErrors } from "./useStatusInboundMailWithErrors";
import { DownloadFileService } from "../../services/DownloadFileService";
import { StorageService } from "../../services/StorageService";
import { TPToastTypes, showToast } from "../../components/bootstrap/components/toasts/TPToast";



type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type commandType = {
    type: commandsEnum;
    payload: any;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}
interface Model {
    callBackCommands: Function;
}

enum pageEnum {
    filter,
    List
}
enum pageContainerEnum {
    Calendar
}

const IboundMailsWithErrorsAdmin = forwardRef(
    ({ callBackCommands }: Model, ref) => {

        const [location, setLocation] = useState(new LocationNameTable);
        const [locationGlobal, setlocationGlobal] = useState(new LocationNameGlobal);
        const [dataSourceInboundMailBox, setdataSourceInboundMailBox] = useState(Array<TPKeyValue>);
        const [dataSourceAction, setdataSourceAction] = useState(Array<TPKeyValue>);
        const [bodyText, seBodyText] = useState("");
        const [gridTemp, setGridTemp] = useState(new Array<any>());
        const [listActionId, setListActionId] = useState(new Array<string>());

        //Filter 
        const [inboundMailBoxFilter, setInboundMailBoxFilter] = useState<TPKeyValue>({ key: "", value: "" });
        const [actionFilter, setActionFilter] = useState<TPKeyValue>({ key: "", value: "" });

        //Error 
        const [inboundMailBoxError, setInboundMailBoxError] = useState<string>("");
        const [actionError, setActionError] = useState<string>("");

        const [query, setQuery] = useState("");
        const [nameDelete, setNameDelete] = useState("");
        const [idDelete, setIdDelete] = useState("");
        const [modalAlert, setModalAlert] = useState(false);

        const loadResourcesAndLoadInfo = async () => {
            let temp = new LocationLanguagesInboundMailWithErrors();
            await temp.handlelocationTable(resourceSet);
            setLocation(temp.getLocationNameTable);

            let tempGlobal = new LocationLanguagesInboundMailWithErrors();
            await tempGlobal.handlelocationGlobal(TPGlobal.globalResourceSet);
            setlocationGlobal(tempGlobal.getLocationNameGlobal);

            await getInboundMailboxs();
            await getQuickClassifiersByType();


            setLoading(false);
        }

        const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleGetAllData(inboundMailBoxFilter.key);
            }
        };

        //UTIL
        const handleTextLimit = (value: string) => {
            if (!value) return "";          
            if (value.length <= TPGlobal.characterNumberCutStringComments) return value;
            if (value.length > TPGlobal.characterNumberCutStringComments) return value.slice(0, TPGlobal.characterNumberCutStringComments) + "...";
            return value;
        }

        const handleTextLimitActive = (value: string) => {
            if (!value) return false;
            if (value.length <= TPGlobal.characterNumberCutStringComments) return false;
            if (value.length > TPGlobal.characterNumberCutStringComments) return true;
            return true;
        }

        const handleplainTextBody = (id: string): string => {

            let data = [...gridTemp];
            let filter = data.find(s => s.storageIdEML == id);
            if (!filter) return "";
            if (filter.body == "" || filter.body == undefined) return filter.htmlBody;
            return filter.body;
        }

        const handleBody = (id: string): string => {

            let data = [...gridTemp];
            let filter = data.find(s => s.storageIdEML == id);
            if (!filter) return "";
            return filter.htmlBody;
        }

        const handleClearBody = () => {
            seBodyText("");
        }

        const handleConvertData = (data: any): Array<InboundMailWithErrorsModel> => {
            let list = new Array<InboundMailWithErrorsModel>();

            const resultado = data.map((d: any) => ({
                id: d.storageIdEML,
                download: d.storageIdEML,
                viewMail: d.htmlBody,
                type: d.insertionType,
                from: d.fromAddress,
                to: d.addressTO,
                cc: d.numBCC,
                subject: d.subject,
                body: d.htmlBody,
                plainTextBody: d.plainTextBody,
                mailDate: d.emailLocalDate,
                insertDate: d.insertDate,
                inboundMailBox: d.inboundMailbox,
                rule: ""
            }));


            return resultado;
        }

        const dowloadFile = (item: InboundMailWithErrorsModel) => {
          
            let bolbId = "";          

            let data = [...gridTemp];

            var tempError = data.find(s => s.storageIdEML == item.id);

            if (tempError) {               
                bolbId = tempError.storageIdEML;               
            }

            handleAttachmentClick(bolbId);
        }


        //CRUD
        const handleValidate = () => {
            var status = true;
            if (inboundMailBoxFilter.key == "") {
                status = false;
                setInboundMailBoxError(locationGlobal.inputRequire);
            }

            if (!listActionId || listActionId.length <= 0) {
                status = false;
                showToast(
                    locationGlobal.actionError,
                    TPToastTypes.warning
                );
            }
            if (status) handleSetAction();
        }

        const handleSetAction = async () => {
            setLoading(true);
            let tasktypeService = new InboundMailErrorService();
            let expectedCodes: Array<number> = [200, 404];
            var pageStart = 1;
            var pageEnd = 1;

            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.actionByListId(
                        listActionId,
                        true,
                        true,
                        expectedCodes
                    );
                let data = { ...adminState };
               
                handleGetAllData(inboundMailBoxFilter.key);

            } catch {

            }

            setLoading(false);
        }

        const handleGetAllData = async (id: string) => {
            setGridTemp([]);
            setListActionId([]);
            let command0: commandType = {
                type: commandsEnum.reload_grid,
                payload: []
            };
            dispatchCommand(command0);


            setLoading(true);
            let tasktypeService = new InboundMailErrorService();
            let expectedCodes: Array<number> = [200, 404];
            var pageStart = 1;
            var pageEnd = 1;

            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getIboundMailsWithErrorsByFilter(
                        id,
                        false,
                        true,
                        expectedCodes
                    );

                let data = { ...adminState };

                if (responseRequest != undefined && Array.isArray(responseRequest)) {
                    setGridTemp(responseRequest);
                    let list = handleConvertData(responseRequest).map(({
                        id,
                        download,
                        viewMail,
                        type,
                        from,
                        to,
                        cc,
                        subject,
                        body,
                        mailDate,
                        insertDate,
                        inboundMailBox,
                        rule

                    }: InboundMailWithErrorsModel) => {
                        return {
                            id,
                            download,
                            viewMail,
                            type,
                            from,
                            to,
                            cc,
                            subject,
                            body,
                            mailDate,
                            insertDate,
                            inboundMailBox,
                            rule

                        }
                    });


                    let command1: commandType = {
                        type: commandsEnum.reload_grid,
                        payload: list
                    };
                    dispatchCommand(command1);
                }

                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }


        };

        const handleDelete = async (id: string) => {
            setLoading(true);
            let tasktypeService = new AdministrationUsersStationService();
            let expectedCodes: Array<number> = [200, 404];
            setIdDelete(id);
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.deleteById(
                        id,
                        true,
                        true,
                        expectedCodes
                    );

                if (responseRequest) {

                    handleGetAllData(inboundMailBoxFilter.key);
                }

                setNameDelete("");
                setIdDelete("");
                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }

        };

        const getInboundMailboxs = async () => {
            let serviceClient = new InboundMailboxesService();
            let expectedCodes: Array<number> = [200, 404];

            try {
                setLoading(true);

                let responseRequest = await serviceClient.getInboundMailboxesByFilter(
                    "1",
                    false,
                    true,
                    expectedCodes,
                );
                setLoading(false);
                const inboundMailboxesList = responseRequest.map((res: any) => ({
                    key: res.id,
                    value: res.description,
                }));

                if (inboundMailboxesList) {
                    inboundMailboxesList.push({ value: "--", key: "" })
                    setdataSourceInboundMailBox(inboundMailboxesList);
                }

                return inboundMailboxesList;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} getInboundMailboxs ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} getInboundMailboxs ex`);
                setLoading(false);
                return [];
            }
        };

        const getQuickClassifiersByType = async () => {
            const quickClassifierClient = new QuickClassifierService();
            const expectedCodes: Array<number> = [200, 404];

            try {
                setLoading(true);

                const responseRequest =
                    await quickClassifierClient.getQuickClassifiersByType(
                        WebServiceClassifierType.MailReader,
                        TPActiveOptions.ACTIVE,
                        false,
                        true,
                        expectedCodes,
                    );
                setLoading(false);
                const quickClassifierList = responseRequest.map((res) => ({
                    key: res.id,
                    value: res.description,
                }));


                if (quickClassifierList) {
                    quickClassifierList.push({ value: "--", key: "" })
                    setdataSourceAction(quickClassifierList);
                }
                return quickClassifierList;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} getQuickClassifiersByType ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${resourceSet} getQuickClassifiersByType ex`,
                );
                setLoading(false);
                return [];
            }
        };

        const handleAttachmentClick = async (
            blobId: string                  
        ) => {
            const storageService = new StorageService();
            const downloadFileService = new DownloadFileService();
            let expectedCodes: Array<number> = [200];
            let attachmentType: AttachmentType;
           
            let pathFile = TPGlobal.PathEmlStorage;
            let fileName = "mailEML.eml";     
            attachmentType = AttachmentType.MAILREADEREML;
            try {
                let responseRequest = await storageService.getFileUriSas(
                    blobId,
                    attachmentType,
                    false,
                    true,
                    expectedCodes
                );

                if (responseRequest) {
                    const { sasUri, mimeType } = responseRequest;
                    await downloadFileService.downloadFileUri({                       
                        sasUri,
                        originalFileName: fileName,
                        mimeType,
                    });

                }
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleCustomerAttachmentClick ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleCustomerAttachmentClick ex`
                );
                return null;
            }
        };



        // SELECT
        const handleChangedInboundMailBoxFilter = (e: any) => {
            setInboundMailBoxFilter({ key: "", value: "" });
            setInboundMailBoxFilter({ key: e.target.value, value: "" });
            handleGetAllData(e.target.value);
        };


        // TABLE
        const columnsNames: CustomColumnNames<InboundMailWithErrorsModel> = {
            id: location.columnId,
            download: location.columnDownload,
            viewMail: location.columnViewMail,
            type: location.columnType,
            from: location.columnFrom,
            to: location.columnTo,
            cc: location.columnCc,
            subject: location.columnSubject,
            body: location.columnBody,
            mailDate: location.columnMailDate,
            insertDate: location.columnInsertDate,
            inboundMailBox: location.columnInboundMailBox,
            rule: location.columnRule
        };

        const columnStyles: ColumnStyles<InboundMailWithErrorsModel> = {
            download: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "center", width: "30px", alignContent: "center" }} >
                    <TPIcon iconType={TPIconTypes.fileDownload} style={{ color: "rgb(160, 0, 149)" }} onClick={() => { dowloadFile(item); }} />
                </div>
            ),
            subject: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }} >
                    <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(value)} />
                    {handleTextLimitActive(item.id) &&
                        <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                    }
                </div>
            ),
            body: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }}>
                    <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(handleplainTextBody(item.id))} />
                    {handleTextLimitActive(handleplainTextBody(item.id)) &&
                        <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                    }
                </div>
            ),
            viewMail: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }}>
                    <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(handleplainTextBody(item.id))} />
                    {handleTextLimitActive(handleplainTextBody(item.id)) &&
                        <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                    }
                </div>
            ),

        };

        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop) handleGetAllData(inboundMailBoxFilter.key);
        };


        //LOAD
        const value = useStatusInboundMailWithErrors();
        const {
            adminState,
            resourceSet,
            setLoading,
            dispatchCommand,
            loading
        } = value;

        useEffect(() => {          
            setLoading(true);
            loadResourcesAndLoadInfo();
        }, []);

        return (
            <div style={{ position: "relative" }}  >
                <TPLoadingOverlay active={loading}>
                    <>
                        {bodyText != "" &&
                            <PopupContainerStyle onClick={() => { seBodyText("") }}>
                                <PopupContainerChildreStyle  >
                                    <TPIcon iconType={TPIconTypes.close} style={{
                                        display: 'flex',
                                        right: '0px',
                                        position: 'absolute',
                                        height: '40px',
                                        width: '40px',
                                        backgroundColor: '#a00095',
                                        color: "white",
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 0px 5px gray',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }} onClick={() => { seBodyText("") }} />
                                    <ContainerBodyStyle>
                                        <span dangerouslySetInnerHTML={{ __html: bodyText }} />
                                    </ContainerBodyStyle>
                                </PopupContainerChildreStyle>
                            </PopupContainerStyle>
                        }
                        <TPModalQuestion
                            title={locationGlobal.confirmdeleteLabel}
                            yesLabel={locationGlobal.okAlertButton}
                            noLabel={locationGlobal.cancelAlertButton}
                            question={locationGlobal.questionConfirmDelete + " " + nameDelete}
                            callBackData={(e: any) => {

                            }}
                            isShown={modalAlert}
                            callBackAnswer={(e: any) => {
                                if (e == false) {
                                    setModalAlert(false);
                                    setNameDelete("");
                                    setIdDelete("");
                                }
                                else if (e) {
                                    setModalAlert(false);
                                    handleDelete(idDelete);
                                }
                            }}
                        ></TPModalQuestion>
                        <RightRowContainerStyle>
                            <TPPageTitle> {location.title}</TPPageTitle>
                        </RightRowContainerStyle>
                        <hr />
                        <div style={{ display: "flex", position: "relative", justifyContent: "start", width: "80%", height: "40px", gap: "5px", alignItems: "center", paddingLeft: "3px" }} >

                            <div style={{ width: "238px", position: "absolute", top: "10px", left: "10px" }} >
                                <TPSelect
                                    id={"Select_1" + resourceSet}
                                    isMandatory={true}
                                    onChange={handleChangedInboundMailBoxFilter}
                                    dataSource={dataSourceInboundMailBox}
                                    value={
                                        inboundMailBoxFilter.key
                                    }
                                    labelText={location.inboundMailBoxLabel}
                                    errorMessage={inboundMailBoxError}
                                    containerStyle={{}}
                                ></TPSelect>
                            </div>
                            <div style={{ display: "flex", position: "absolute", top: "36px", left: "260px" }} >
                                <TPButton
                                    id={"Btn_Apply_" + resourceSet}
                                    type={TPButtonTypes.empty}
                                    onClick={() => handleValidate()}
                                    text={""}
                                    tooltip={location.actionLabel}
                                    icon={TPIconTypes.fileDownload}
                                    style={{ heigth: "41px", color: "white", backgroundColor: "rgb(160, 0, 149)" }}
                                >
                                    {locationGlobal.applyFilterText}
                                </TPButton>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <DynamicTable
                            id={"Table_" + resourceSet}
                            selectable={true}
                            hideAllTableOnNoData
                            data={adminState.gridData}
                            withPreferences={true}
                            columnStyles={columnStyles}
                            columnNames={columnsNames}
                            hiddenColumns={['id', 'plainTextBody', 'rule', 'type']}
                            onIconClicked={(e: any) => { handleIconClick(e); }}
                            onSelectionChange={(e) => {
                                let list = e.map((s) => {
                                    return s.id
                                });

                                setListActionId(list);
                            }}
                        />

                    </>
                </TPLoadingOverlay>
            </div>

        );
    }
);

export default IboundMailsWithErrorsAdmin;
