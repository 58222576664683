import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { ScheduleViewModel } from "@/models/Schedules/SchedulesModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { SchedulesService } from "@/services/SchedulesService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<ScheduleViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface SchedulesAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const SchedulesAdmin = React.forwardRef(
  ({ callBackCommands }: SchedulesAdminInterface, ref) => {
    const componentFileName: string = "SchedulesAdmin.tsx";

    //Functions called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "ScheduleAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");

    const [searchLabel, setSearchLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [activeColumnLabel, setActiveColumnLabel] = useState("");
    const [mondayColumnLabel, setMondayColumnLabel] = useState("");
    const [tuesdayColumnLabel, setTuesdayColumnLabel] = useState("");
    const [wednesdayColumnLabel, setWednesdayColumnLabel] = useState("");
    const [thursdayColumnLabel, setThursdayColumnLabel] = useState("");
    const [fridayColumnLabel, setFridayColumnLabel] = useState("");
    const [saturdayColumnLabel, setSaturdayColumnLabel] = useState("");
    const [sundayColumnLabel, setSundayColumnLabel] = useState("");
    const [holidayColumnLabel, setHolidayColumnLabel] = useState("");
    const [halfHolidayColumnLabel, setHalfHolidayColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndScheduleFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      //grid columns
      //id
      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      //description
      let languageColumnLabel: string;
      languageColumnLabel = await TPI18N.GetText(ResourceSet, "Description");
      languageColumnLabel = (languageColumnLabel +
        " (" +
        TPGlobal.TPClientAvailableLanguages[0].name) as string;
      languageColumnLabel = languageColumnLabel + ")";
      setDescriptionColumnLabel(languageColumnLabel);
      //isactive
      setActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      //Monday
      setMondayColumnLabel(await TPI18N.GetText(ResourceSet, "Monday"));
      //Tuesday
      setTuesdayColumnLabel(await TPI18N.GetText(ResourceSet, "Tuesday"));
      //Wednesday
      setWednesdayColumnLabel(await TPI18N.GetText(ResourceSet, "Wednesday"));
      //Thursday
      setThursdayColumnLabel(await TPI18N.GetText(ResourceSet, "Thursday"));
      //Friday
      setFridayColumnLabel(await TPI18N.GetText(ResourceSet, "Friday"));
      //Saturday
      setSaturdayColumnLabel(await TPI18N.GetText(ResourceSet, "Saturday"));
      //Sunday
      setSundayColumnLabel(await TPI18N.GetText(ResourceSet, "Sunday"));
      //Holiday
      setHolidayColumnLabel(await TPI18N.GetText(ResourceSet, "Holiday"));
      //HalfHoliday
      setHalfHolidayColumnLabel(
        await TPI18N.GetText(ResourceSet, "HalfHoliday")
      );

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      //filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(ResourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new SchedulesService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getSchedulesByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Schedule
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update Schedule
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete organization relation
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete organization relation after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new SchedulesService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteScheduleById(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<ScheduleViewModel> = {
      id: idColumnLabel,
      description: descriptionColumnLabel,
      isActive: activeColumnLabel,
      monWorkCalculatedTime: mondayColumnLabel,
      tueWorkCalculatedTime: tuesdayColumnLabel,
      wedWorkCalculatedTime: wednesdayColumnLabel,
      thuWorkCalculatedTime: thursdayColumnLabel,
      friWorkCalculatedTime: fridayColumnLabel,
      satWorkCalculatedTime: saturdayColumnLabel,
      sunWorkCalculatedTime: sundayColumnLabel,
      holidayWorkCalculatedTime: holidayColumnLabel,
    };

    const customColumns: ColumnStyles<ScheduleViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          // isDisabled={item.isActive}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const hiddenCol: Array<any> = [
      "initialHourMonday",
      "endHourMonday",
      "initialHourTuesday",
      "endHourTuesday",
      "initialHourWednesday",
      "endHourWednesday",
      "initialHourThursday",
      "endHourThursday",
      "initialHourFriday",
      "endHourFriday",
      "initialHourSaturday",
      "endHourSaturday",
      "initialHourSunday",
      "endHourSunday",
      "initialHourHoliday",
      "endHourHoliday",
      "initialHourHalfHoliday",
      "endHourHalfHoliday",
      "workHalfHoliday",
      "workMonday",
      "workTuesday",
      "workWednesday",
      "workThursday",
      "workFriday",
      "workSaturday",
      "workSunday",
      "workHoliday",
      "localizedDescription",
    ];
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string }) => handleDeleteClick(e.id),
      },
    ];

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];

    const transformedData = adminState?.gridData?.map((item) => ({
      ...item,
      monWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourMonday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourMonday),
      tueWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourTuesday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourTuesday),
      wedWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourWednesday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourWednesday),
      thuWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourThursday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourThursday),
      friWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourFriday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourFriday),
      satWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourSaturday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourSaturday),
      sunWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourSunday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourSunday),
      holidayWorkCalculatedTime:
        TPGlobal.HHMM_FromNumber(item.initialHourHoliday) +
        "-" +
        TPGlobal.HHMM_FromNumber(item.endHourHoliday),
    }));

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndScheduleFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndScheduleFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndScheduleFilter ex`
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-additional-data-type"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={transformedData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                hiddenColumns={hiddenCol}
                minorOptions={minorOptions}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                // disableMinorOption={(item) => item.isActive}
                additionalFilters={additionalFilters}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default SchedulesAdmin;
