import {
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import styled from "styled-components";

interface TPPlaceholderDraggableProps {
  top: number;
  left: number;
  height: number;
  width: number;
}

export const TPDragAndDropSectionStyled = styled.div`
  div.droppable-container {
    background-color: #e5e8ea;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
  }

  div.droppable-container .id-indr {
    font-weight: 400;
  }

  div.droppable-container.empty {
    min-height: 100px;
    border: 1px dashed gray;
    border-radius: 5px;
    background-color: #e5e8ea;
    font-size: xx-large;
    color: #939ea7;

    align-items: center;
    display: flex;
    justify-content: center;
  }

  div.droppable-container-header {
    background-color: #e5e8ea;
    font-weight: 600;
    font-size: 18px;
    word-wrap: break-word;
    align-items: center;
    border-radius: 5px;
    padding: 0.25rem !important;
    border-width: 1px !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  div.droppable-container-header.row.isScroll {
    margin-right: -1px !important;
  }

  div.draggable-container {
    background-color: white;
    border-radius: 5px;
    padding: 0.25rem !important;
    border-width: 1px !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  div.draggable-container:hover {
    background-color: #f5f5f5;
  }
`;

export const TPPlaceholderDraggableStyled =
  styled.div<TPPlaceholderDraggableProps>(
    ({ theme, top, left, height, width }) => `
  position: absolute;
  //background-color: #e5e8ea;
  border-radius: 3px;
  border: dashed 1px rgb(160, 0, 149);

  top: ${top}px;
  left: ${left}px;
  height: ${height}px;
  width: ${width}px;
`,
  );

export const GetDroppableListStyle = (
  snapshot: DroppableStateSnapshot,
  isEmpty: boolean,
) =>
  snapshot.isDraggingOver
    ? {
        fontSize: isEmpty ? "0px" : "",
        //border: "1px dashed gray"
      }
    : undefined;

export const GetDraggableItemStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: any,
) => ({
  background: snapshot.isDragging ? "#F5F5F5" : "",
  ...draggableStyle,
});
