import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";

export class TPI18N {
  static resources: Array<TPKeyValue> = [];
  static GetText = async (
    resourceSet: string,
    resourceId: string,
    language?: string,
  ): Promise<string> => {
    let resourceSetElement: any;
    let resourceIdElement: any;
    let i: number;
    //check if resourceSet,resourceId already exists
    //if exits return it;
    //if not exists get from api and store on array
    //if after all does not exists return resourceId

    if (!language) {
      language = TPGlobal.language;
    }

    //neutral resources are not cached
    if (language.toLowerCase() != "ne") {
      //check if resourceSet exits
      resourceSetElement = TPI18N.resources.find(
        (x) => x.key.toUpperCase() == resourceSet.toUpperCase(),
      );
      if (resourceSetElement) {
        let collection: Array<TPKeyValue> = [];
        collection = resourceSetElement.value;
        resourceIdElement = collection.find(
          (x) => x.key.toUpperCase() == resourceId.toUpperCase(),
        );
        if (resourceIdElement) {
          return new Promise<string>(function (resolve) {
            let returnvalue: string = String(resourceIdElement.value);
            resolve(returnvalue);
          });
        } else {
          return new Promise<string>(function (resolve) {
            return resolve(resourceId);
          });
        }
      }
    }

    //not exits
    let httpclient = new TPHTTPService();
    let finalURL: string =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/resourceset/" +
      resourceSet +
      "?LanguageId=" +
      language;
    let response: HTTPResponse;
    let expectedCodes: Array<number> = [200];
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      let apiResponse = await httpclient.getData(
        finalURL,
        expectedCodes,
        false,
        false,
        headers,
      );
      if (
        apiResponse &&
        apiResponse.responseData &&
        apiResponse.responseData.data
      ) {
        let newResources: Array<TPKeyValue> = [];
        let foundItem: string = "";

        //neutral resources are not cached
        if (language.toLowerCase() == "ne") {
          for (i = 0; i <= apiResponse.responseData.data.length - 1; i++) {
            let item = apiResponse.responseData.data[i];
            let newItem: TPKeyValue = {
              key: item.resourceId,
              value: item.text,
            };
            if (newItem.key.toUpperCase() == resourceId.toUpperCase()) {
              foundItem = item.text;
              break;
            }
          }
          if (foundItem == "") {
            TPLog.Log(
              "I18NService.ts GetText",
              TPLogType.ERROR,
              `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
            );
            console.error(
              `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
            );
            foundItem = resourceId;
          }
          return new Promise<string>(function (resolve) {
            return resolve(foundItem);
          });
        }

        for (i = 0; i <= apiResponse.responseData.data.length - 1; i++) {
          let item = apiResponse.responseData.data[i];
          let newItem: TPKeyValue = {
            key: item.resourceId,
            value: item.text,
          };
          newResources.push(newItem);
          if (newItem.key.toUpperCase() == resourceId.toUpperCase()) {
            foundItem = item.text;
          }
        }
        TPI18N.resources.push({ key: resourceSet, value: newResources });
        if (foundItem == "") {
          TPLog.Log(
            "I18NService.ts GetText",
            TPLogType.ERROR,
            `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
          );
          console.error(
            `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
          );
          foundItem = resourceId;
        }
        return new Promise<string>(function (resolve) {
          return resolve(foundItem);
        });
      } else {
        return new Promise<string>(function (resolve) {
          TPLog.Log(
            "I18NService.ts GetText",
            TPLogType.ERROR,
            `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
          );
          console.error(
            `OH OH: resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
          );
          return resolve(resourceId);
        });
      }
    } catch {
      TPLog.Log(
        "I18NService.ts GetText",
        TPLogType.ERROR,
        `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
      );
      console.error(
        `resource not found resourceSet:${resourceSet} resourceId:${resourceId} language:${language}`,
      );
      return resourceId;
    }
  };

  static GetResource = async (
    resourceSetAndResourceId: string,
    language?: string,
  ): Promise<string> => {
    return await TPI18N.GetText(
      resourceSetAndResourceId.split("|")[0],
      resourceSetAndResourceId.split("|")[1],
      language,
    );
  };
}
