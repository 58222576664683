import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react"

export interface GroupTakeNextLabels {
  Case: string;
  Ready: string;
  InQueue: string;
  Processing: string;
  Available: string;
  NotAvailable: string;
  'TAKENEXT': string;
  'FRONTOFFICE': string;
  'BACKOFFICE': string;
  case: string;
  event: string;
  Search: string;
  SelectAll: string;
  PageTitle: string;
  InfoText: string;
  OnlineLabel: string;
  GoOnlineLabel: string;
  PendingCasesLabel: string;
  OrderByTaskLabel: string;
  OrderByDateLabel: string;
  GroupHeaderLabel: string;
  TypeHeaderLabel: string;
  PriorityHeaderLabel: string;
  ReadyHeaderLabel: string;
  ProcessingHeaderLabel: string;
  InQueueHeaderLabel: string;
  ReadyNotReadyHeaderLabel: string;
  LowPriorityLabel: string;
  MediumPriorityLabel: string;
  HighPriorityLabel: string;
  NotResolvedLabel: string;
  Ticket: string;
  TakeNextLabel: string;
  FrontOfficeLabel: string;
  BackOfficeLabel: string;
  StartLabel: string;
  MyTasks: string;
  NoResultsLabel: string;
  Project: string;
}

const labelsResourceSet = "GroupTakeNext";

export const useGroupTakeNextLabels = function () {

  const [labels, setLabels] = useState<GroupTakeNextLabels>({} as GroupTakeNextLabels);

  const getLabels = async function () {
    setLabels({
      Case: await TPI18N.GetText(labelsResourceSet, "CaseLabel"),
      Ready: await TPI18N.GetText(labelsResourceSet, "ReadyHeaderLabel"),
      Available: await TPI18N.GetText(labelsResourceSet, "AvailableLabel"),
      InQueue: await TPI18N.GetText(labelsResourceSet, "InQueueHeaderLabel"),
      NotAvailable: await TPI18N.GetText(labelsResourceSet, "NotAvailableLabel"),
      Processing: await TPI18N.GetText(labelsResourceSet, "ProcessingHeaderLabel"),
      BACKOFFICE: await TPI18N.GetText(labelsResourceSet, "BACKOFFICE"),
      FRONTOFFICE: await TPI18N.GetText(labelsResourceSet, "FRONTOFFICE"),
      TAKENEXT: await TPI18N.GetText(labelsResourceSet, "TAKENEXT"),
      case: await TPI18N.GetText(labelsResourceSet, "CaseLabel"),
      event: await TPI18N.GetText(labelsResourceSet, "EventLabel"),
      Search: await TPI18N.GetText(labelsResourceSet, "SearchLabel"),
      SelectAll: await TPI18N.GetText(labelsResourceSet, "SelectAll"),
      BackOfficeLabel: await TPI18N.GetText(labelsResourceSet, "BackOfficeLabel"),
      FrontOfficeLabel: await TPI18N.GetText(labelsResourceSet, "FrontOfficeLabel"),
      GoOnlineLabel: await TPI18N.GetText(labelsResourceSet, "GoOnlineLabel"),
      GroupHeaderLabel: await TPI18N.GetText(labelsResourceSet, "GroupHeaderLabel"),
      HighPriorityLabel: await TPI18N.GetText(labelsResourceSet, "HighPriorityLabel"),
      InfoText: await TPI18N.GetText(labelsResourceSet, "InfoText"),
      InQueueHeaderLabel: await TPI18N.GetText(labelsResourceSet, "InQueueHeaderLabel"),
      LowPriorityLabel: await TPI18N.GetText(labelsResourceSet, "LowPriorityLabel"),
      MediumPriorityLabel: await TPI18N.GetText(labelsResourceSet, "MediumPriorityLabel"),
      NotResolvedLabel: await TPI18N.GetText(labelsResourceSet, "NotResolvedLabel"),
      OnlineLabel: await TPI18N.GetText(labelsResourceSet, "OnlineLabel"),
      OrderByDateLabel: await TPI18N.GetText(labelsResourceSet, "OrderByDateLabel"),
      OrderByTaskLabel: await TPI18N.GetText(labelsResourceSet, "OrderByTaskLabel"),
      PageTitle: await TPI18N.GetText(labelsResourceSet, "PageTitle"),
      PendingCasesLabel: await TPI18N.GetText(labelsResourceSet, "PendingCasesLabel"),
      PriorityHeaderLabel: await TPI18N.GetText(labelsResourceSet, "PriorityHeaderLabel"),
      ProcessingHeaderLabel: await TPI18N.GetText(labelsResourceSet, "ProcessingHeaderLabel"),
      ReadyHeaderLabel: await TPI18N.GetText(labelsResourceSet, "ReadyHeaderLabel"),
      ReadyNotReadyHeaderLabel: await TPI18N.GetText(labelsResourceSet, "ReadyNotReadyHeaderLabel"),
      StartLabel: await TPI18N.GetText(labelsResourceSet, "StartLabel"),
      TakeNextLabel: await TPI18N.GetText(labelsResourceSet, "TakeNextLabel"),
      Ticket: await TPI18N.GetText(labelsResourceSet, "Ticket"),
      TypeHeaderLabel: await TPI18N.GetText(labelsResourceSet, "TypeHeaderLabel"),
      MyTasks: await TPI18N.GetText("MainHorizontalTabsComponent", "MyTaskLabel"),
      NoResultsLabel: await TPI18N.GetText("DynamicTable", "NoResultsFound"),
      Project: await TPI18N.GetText(labelsResourceSet, "Project"),
    })
  }

  useEffect(() => {
    getLabels();
  }, [])

  return { labels }
}