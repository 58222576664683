import {CSSProperties, useState} from "react";
import TPGlobal from "@/helpers/TPGlobal";
import TPModalQuestion, {TPModalQuestionState} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import {ProjectNewEnum as e} from "@/models/Project/Projects";

interface ProjectNewStep2SetOptionsProps {
    m: any;
    index: number;
    length?: number;
    onClickCloneHandler: Function;
    onDelete: Function;
}

export default function ProjectNewStep2SetOptions({
                                                      m,
                                                      index,
                                                      length,
                                                      onClickCloneHandler,
                                                      onDelete,
                                                  }: ProjectNewStep2SetOptionsProps) {
    const [modalQuestionState, setModalQuestionState] = useState<TPModalQuestionState>({
        isShown: false,
        callBackData: {},
    });

    function onClickDeleteHandler(i: number) {
        setModalQuestionState({
            ...modalQuestionState,
            isShown: true,
            callBackData: {recordId: i},
        } as TPModalQuestionState);
    }

    async function callBackModalHandler(
        confirmDelete: boolean,
        callBackData: any
    ) {
        setModalQuestionState({
            ...modalQuestionState,
            isShown: false,
            callBackData: {},
        });
        if (confirmDelete) {
            onDelete(index)
        }
    }

    return (
        <div className="dropdown">
            <TPButton
                id={`optionIconButton${index}`}
                className={"menu-button"}
                dataBsToggle={true}
                type={TPButtonTypes.icon}
                icon={TPIconTypes.moreVert}
                onClick={() => TPGlobal.foo()}
            />

            <ul className="dropdown-menu">
                <li>
                    <a className="dropdown-item"
                       style={styles.option}
                       href="#"
                       onClick={() => onClickCloneHandler(index)}
                    >
                        {m?.[e.Step2SetCloneActionLabel]}

                        <TPIcon iconType={TPIconTypes.clone}
                                style={styles.icon}
                        />
                    </a>
                </li>

                {length! > 1 && (
                    <li>
                        <a className="dropdown-item"
                           style={styles.option}
                           href="#"
                           onClick={() => onClickDeleteHandler(index)}
                        >
                            {m?.[e.Step2SetDeleteActionLabel]}

                            <TPIcon iconType={TPIconTypes.delete}
                                    style={styles.icon}
                            />
                        </a>
                    </li>
                )}
            </ul>

            {modalQuestionState?.isShown && index === modalQuestionState.callBackData.recordId && (
                <TPModalQuestion
                    id="IdModalQuestion"
                    title={m?.[e.Step2SetDeleteTitleModalLabel]}
                    yesLabel={m?.[e.Step2SetDeleteYesModalButtonLabel]}
                    noLabel={m?.[e.Step2SetDeleteNoModalButtonLabel]}
                    question={m?.[e.Step2SetDeleteQuestionModalLabel]?.replace("{recordId}", modalQuestionState.callBackData.recordId)}
                    callBackData={modalQuestionState.callBackData}
                    isShown={modalQuestionState.isShown}
                    callBackAnswer={callBackModalHandler}
                />
            )}
        </div>
    );
}

const styles = {
    option: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
    } as CSSProperties,
    icon: {
        display: "flex",
        right: "1px",
        position: "absolute",
    } as CSSProperties,
}
