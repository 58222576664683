import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPModal from "@/layouts/TPModal/TPModal";
import { ClientBasicViewModel } from "@/models/Client/ClientBasicViewModel";
import { FC, useState } from "react";
import "../styles/Styles.css";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { useSelector } from "react-redux";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import { useDispatch } from "react-redux";
import {
  setAltValueInput,
  setConcatenateValueInput,
  setCustomValueInput,
  setFinalMergeData,
  setMainCustomerData,
  setMainValueInput,
  setMergeCustomerData,
} from "../redux/MergeCustomersSlice";
import useLabels from "../styles/labels";

interface ConflictResolveModalProperties {
  shown: boolean;
  showCallback: Function;
  attribute: string;
}

enum ConflictOptions {
  main,
  alternate,
  custom,
  contactenate,
}

const ConflictResolveModal: FC<ConflictResolveModalProperties> = function ({
  shown,
  showCallback,
}) {
  const dispatch = useDispatch();
  const { labels } = useLabels();
  const {
    mainCustomerData,
    mergeCustomerData,
    finalMergeData,
    mainValueInput,
    altValueInput,
    customValueInput,
    concatenateValueInput,
    chosenAttribute,
  } = useSelector((state: MergeCustomersState) => state.mainData);
  const [chosenOption, setChosenOption] = useState<ConflictOptions>(
    ConflictOptions.main,
  );

  const finish = (accept: boolean) => {
    if (accept) {
      let value = "";
      switch (chosenOption) {
        case ConflictOptions.main:
          value = mainValueInput;
          break;
        case ConflictOptions.alternate:
          value = altValueInput;
          break;
        case ConflictOptions.custom:
          value = customValueInput;
          break;
        case ConflictOptions.contactenate:
          value = concatenateValueInput;
          break;
      }
      switch (chosenAttribute) {
        case "documentType":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, documentType: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, documentType: value }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, documentType: value }),
          );
          break;
        case "document":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, document: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, document: value }),
          );
          dispatch(setFinalMergeData({ ...finalMergeData, document: value }));
          break;
        case "name":
          dispatch(setMainCustomerData({ ...mainCustomerData, name: value }));
          dispatch(setMergeCustomerData({ ...mergeCustomerData, name: value }));
          dispatch(setFinalMergeData({ ...finalMergeData, name: value }));
          break;
        case "lastName":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, lastName: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, lastName: value }),
          );
          dispatch(setFinalMergeData({ ...finalMergeData, lastName: value }));
          break;
        case "preferredPhone":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, preferredPhone: value }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              preferredPhone: value,
            }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, preferredPhone: value }),
          );
          break;
        case "preferredEmail":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, preferredEmail: value }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              preferredEmail: value,
            }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, preferredEmail: value }),
          );
          break;
        case "preferredAddress":
          dispatch(
            setMainCustomerData({
              ...mainCustomerData,
              preferredAddress: value,
            }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              preferredAddress: value,
            }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, preferredAddress: value }),
          );
          break;
        case "internalCode":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, internalCode: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, internalCode: value }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, internalCode: value }),
          );
          break;
        case "preferredAddressInfo":
          dispatch(
            setMainCustomerData({
              ...mainCustomerData,
              preferredAddressInfo: value,
            }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              preferredAddressInfo: value,
            }),
          );
          dispatch(
            setFinalMergeData({
              ...finalMergeData,
              preferredAddressInfo: value,
            }),
          );
          break;
        case "geography":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, geography: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, geography: value }),
          );
          dispatch(setFinalMergeData({ ...finalMergeData, geography: value }));
          break;
        case "additionalInfo":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, additionalInfo: value }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              additionalInfo: value,
            }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, additionalInfo: value }),
          );
          break;
        case "typist_Guid":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, typist_Guid: value }),
          );
          dispatch(
            setMergeCustomerData({ ...mergeCustomerData, typist_Guid: value }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, typist_Guid: value }),
          );
          break;
        case "subsidiaryName":
          dispatch(
            setMainCustomerData({ ...mainCustomerData, subsidiaryName: value }),
          );
          dispatch(
            setMergeCustomerData({
              ...mergeCustomerData,
              subsidiaryName: value,
            }),
          );
          dispatch(
            setFinalMergeData({ ...finalMergeData, subsidiaryName: value }),
          );
          break;
      }
    }
    showCallback();
  };
  return (
    <>
      <TPModal
        modalState={{
          titleModal: "Field Detail",
          acceptLabel: "Done",
          cancelLabel: "Cancel",
          callBackAnswer: finish,
          isShown: shown,
          modalWidth: "min-width",
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <b>{labels[chosenAttribute]?.toUpperCase()}</b>
          <br />
          <br />
          <div className="conflict-value-radio">
            <TPRadio
              onClick={() => setChosenOption(ConflictOptions.main)}
              checked={chosenOption == ConflictOptions.main}
              label={labels.Main}
            />
            <TPTextBox
              textStyle={{ backgroundColor: "#EBEFFF" }}
              value={mainValueInput}
              onChange={(event: any) =>
                dispatch(setMainValueInput(event.target.value))
              }
            />
          </div>
          <div className="conflict-value-radio">
            <TPRadio
              onClick={() => setChosenOption(ConflictOptions.alternate)}
              checked={chosenOption == ConflictOptions.alternate}
              label={labels.Alternate}
            />
            <TPTextBox
              textStyle={{ backgroundColor: "#F9E2FF" }}
              value={altValueInput}
              onChange={(event: any) =>
                dispatch(setAltValueInput(event.target.value))
              }
            />
          </div>
          <div className="conflict-value-radio">
            <TPRadio
              onClick={() => setChosenOption(ConflictOptions.custom)}
              checked={chosenOption == ConflictOptions.custom}
              label={labels.Custom}
            />
            <TPTextBox
              textStyle={{ backgroundColor: "#DCFFDD" }}
              value={customValueInput}
              onChange={(event: any) =>
                dispatch(setCustomValueInput(event.target.value))
              }
            />
          </div>
          <div className="conflict-value-radio">
            <TPRadio
              onClick={() => setChosenOption(ConflictOptions.contactenate)}
              checked={chosenOption == ConflictOptions.contactenate}
              label={labels.Concatenate}
            />
            <TPTextBox
              textStyle={{ backgroundColor: "#DCFFDD" }}
              value={concatenateValueInput}
              onChange={(event: any) =>
                dispatch(setConcatenateValueInput(event.target.value))
              }
            />
          </div>
        </div>
      </TPModal>
    </>
  );
};

export default ConflictResolveModal;
