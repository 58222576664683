import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export class Clone {
  id: string = "";
  id_Base: string = "";
  description: string = "";
  isActive: boolean = true;
  otherLocalizedValues: RecordLocalizedModel[] =
    new Array<RecordLocalizedModel>();

  constructor(
    id: string = "",
    baseOSName: string = "",
    description: string = "",
    isActive: boolean = true,
    recordLocalizedModel: RecordLocalizedModel[],
  ) {
    this.id = id;
    this.id_Base = baseOSName;
    this.description = description;
    this.isActive = isActive;
    this.otherLocalizedValues = recordLocalizedModel;
  }
}
