import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState, FC } from "react";
import { Interface } from "readline";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";

type TPIconSelectorProps = {
  defaultIconName: string;
  onIconSelected: Function;
};

const TPIconSelector: FC<TPIconSelectorProps> = ({
  onIconSelected,
  defaultIconName = "",
}) => {
  const resourceSet: string = "TPIconSelectorComponent";
  const [isShownModalIconSelect, setIsShownModalIconSelect] = useState(false);
  const [iconList, setIconList] = useState<Array<string>>([]);
  const [iconName, setIconName] = useState(defaultIconName);
  const [titleModal, setTitleModal] = useState("Select icon");
  const [acceptLabel, setAcceptLabel] = useState("Ok");
  const [cancelLabel, setCancelLabel] = useState("Cancel");

  const loadResourcesAndIconList = async () => {
    setTitleModal(await TPI18N.GetText(resourceSet, "TitleModal"));
    setAcceptLabel(await TPI18N.GetText(resourceSet, "AcceptLabel"));
    setCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    let newIconList = Object.values(TPIconTypes);
    setIconList(newIconList);
  };

  const handleOnSearchClick = () => {
    setIsShownModalIconSelect(true);
  };
  const handleCallbackAnser = (status: boolean, callbackData: any) => {
    setIsShownModalIconSelect(false);
  };
  const handleOnIconClick = (iconName: string) => {
    if (onIconSelected) {
      setIsShownModalIconSelect(false);
      setIconName(iconName);
      onIconSelected(iconName);
    }
  };

  useEffect(() => {
    loadResourcesAndIconList();
  }, []);

  return (
    <>
      <TPModal
        modalState={{
          titleModal: titleModal,
          acceptLabel: acceptLabel,
          cancelLabel: cancelLabel,
          callBackAnswer: handleCallbackAnser,
          callBackData: TPGlobal.foo,
          isShown: isShownModalIconSelect,
          modalWidth: undefined,
        }}
      >
        <div
          className="mb-2"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill,minmax(${90}px, 1fr))`,
          }}
        >
          {iconList.map(function (item1, idx1, array) {
            return (
              <div className="mb-2" key={"renderIcon" + idx1.toString()}>
                <TPIcon
                  style={{ cursor: "pointer", fontSize: "30px" }}
                  iconType={TPIconTypes[item1 as keyof typeof TPIconTypes]}
                  onClick={() => handleOnIconClick(item1)}
                />
              </div>
            );
          })}
        </div>
      </TPModal>
      <TPTextBox
        id="IdTextBox"
        labelText={"Image"}
        onChange={TPGlobal.foo}
        value={iconName}
        withIcon={true}
        icon={TPIconTypes.search}
        disabled={true}
        iconClick={handleOnSearchClick}
      />
    </>
  );
};

export default TPIconSelector;
