import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface GroupTakeNextSliceModel {
  online: boolean;
  currentCase: number;
  currentRecord: number;
  currentMomentId: string;
}

const initialData: GroupTakeNextSliceModel = {
  online: false,
  currentCase: -1,
  currentRecord: -1,
  currentMomentId: "",
}

export const GroupTakeNextSlice = createSlice({
  initialState: initialData,
  name: "GroupTakeNextSlice",
  reducers: {
    setOnline: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload;
    },
    setCurrentCase: (state, action: PayloadAction<number>) => {
      state.currentCase = action.payload;
    },
    setCurrentRecord: (state, action: PayloadAction<number>) => {
      state.currentRecord = action.payload;
    },
    setCurrentMomentId: (state, action: PayloadAction<string>) => {
      state.currentMomentId = action.payload;
    },
  }
})
