import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {
  AdditionalDataCaseViewModel,
  AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { AdditionalDataCaseService } from "@/services/AdditionalDataCaseService";
import { TPI18N } from "@/services/I18nService";
import {
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";
import { ParametersService } from "@/services/ParametersService";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { v4 as uuidv4 } from "uuid";

import { CaseInfoStateType } from "./CaseViewer";
import { ClientService } from "@/services/ClientService";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import { UserService } from "@/services/UserService";
import { CaseService } from "@/services/CaseService";
import { ReassignCaseInputDTO } from "@/models/Cases/ReassignCaseInputDTO";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import { Global } from "recharts";

interface ReclassifyCaseProps {
  caseId: number;
  caseInfo: CaseInfoStateType;
  currentBaseLevelId: string;
  currentClientId: number;
  currentSubsidiaryId: number;
  currentSubsidiaryOrganizationId: number;
  children: JSX.Element;
  handleCallBackModalReassign: (status: boolean, Callback: any) => void;
}

type screenStateType = {
  caseGuid: string;
  baseLevelId: string;
  quickClassifierId: string;
  reclassificationReasonId: string;
  reclassificationOptionId: string;
  reclassificationOptionSameWorkflow: string;

  //customer
  currentCustomerId: number;
  currentSubsidiaryId: number;
  currentSubsidiaryOrgId: number;

  currentTree1: string;
  currentTree2: string;
  currentTree3: string;
  currentTree4: string;
  currentTree5: string;

  mustSelectLastLevelBranch1: boolean;
  mustSelectLastLevelBranch2: boolean;
  mustSelectLastLevelBranch3: boolean;
  mustSelectLastLevelBranch4: boolean;
  mustSelectLastLevelBranch5: boolean;

  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;

  currentBranch1: string;
  currentBranch2: string;
  currentBranch3: string;
  currentBranch4: string;
  currentBranch5: string;

  currentDescriptionBranch1: string;
  currentDescriptionBranch2: string;
  currentDescriptionBranch3: string;
  currentDescriptionBranch4: string;
  currentDescriptionBranch5: string;

  defaultBranchHierarchyDescription1: string;
  defaultBranchHierarchyDescription2: string;
  defaultBranchHierarchyDescription3: string;
  defaultBranchHierarchyDescription4: string;
  defaultBranchHierarchyDescription5: string;

  //key: additional data id
  //value: addtional data value
  //value2: other properties
  //value3: should show
  additionalDataValues: Array<TPKeyValue>;
  showAdditionalData: boolean;

  currentWorkflowType: string;
  currentWorkflowDescription: string;

  classify1BranchIdErrorMessage: string;
  classify2BranchIdErrorMessage: string;
  classify3BranchIdErrorMessage: string;
  classify4BranchIdErrorMessage: string;
  classify5BranchIdErrorMessage: string;

  baseLevelIdErrorMessage: string;
  organizationIdErrorMessage: string;
  relationIdErrorMessage: string;
  quickClassifierIdErrorMessage: string;
  reclassificationOptionIdErrorMessage: string;
  reclassificationReasonIdErrorMessage: string;
  classificationCommentsErrorMessage: string;
  commentsErrorMessage: string;
  [key: string]: any;
};

enum currentAccordionsEnum {
  "NONE" = 0,
  "CURRENT_CLASSIFICATION" = 1,
  "NEW_CLASSIFICATION" = 2,
}

enum commandsEnum {
  change_property_value,
  change_current_organization_relation,
  change_current_branch,
  organization_relation_clear,
  change_quick_classifier,
  change_additional_data_value,
  change_CurrentCustomer,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};
let modalQuestionInitialState: TPModalQuestionState = {
  isShown: false,
  callBackData: {},
};

const ReassignCase = React.forwardRef(
  (
    {
      caseId,
      caseInfo,
      currentBaseLevelId,
      currentClientId,
      currentSubsidiaryId,
      currentSubsidiaryOrganizationId,
      children,
      handleCallBackModalReassign,
    }: ReclassifyCaseProps,
    ref,
  ) => {
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [isErrorLabel, setErrorLabel] = useState({
      toError: "",
      commentError: "",
    });
    const componentFileName: string = "ReassignCase.tsx";
    const resourceSet: string = "ReassignCaseComponent";

    //#region initail states
    const initialScreenState: screenStateType = {
      caseGuid: uuidv4(),
      baseLevelId: "",
      quickClassifierId: "",
      quickClassifierIdErrorMessage: "",
      reclassificationOptionId: "", //"S_RECLAOPTDISO",
      reclassificationOptionSameWorkflow: "S_RECLASWOPTCCS",

      currentCustomerId: 0,
      currentSubsidiaryId: 0,
      currentSubsidiaryOrgId: 0,

      currentTree1: "",
      currentTree2: "",
      currentTree3: "",
      currentTree4: "",
      currentTree5: "",
      mustSelectLastLevelBranch1: false,
      mustSelectLastLevelBranch2: false,
      mustSelectLastLevelBranch3: false,
      mustSelectLastLevelBranch4: false,
      mustSelectLastLevelBranch5: false,
      currentLabelTree1: "",
      currentLabelTree2: "",
      currentLabelTree3: "",
      currentLabelTree4: "",
      currentLabelTree5: "",
      currentBranch1: "",
      currentBranch2: "",
      currentBranch3: "",
      currentBranch4: "",
      currentBranch5: "",
      currentDescriptionBranch1: "",
      currentDescriptionBranch2: "",
      currentDescriptionBranch3: "",
      currentDescriptionBranch4: "",
      currentDescriptionBranch5: "",
      defaultBranchHierarchyDescription1: "",
      defaultBranchHierarchyDescription2: "",
      defaultBranchHierarchyDescription3: "",
      defaultBranchHierarchyDescription4: "",
      defaultBranchHierarchyDescription5: "",

      baseLevelIdErrorMessage: "",
      organizationIdErrorMessage: "",
      relationIdErrorMessage: "",
      additionalDataValues: [],
      reclassificationReasonId: "",
      reclassificationReasonIdErrorMessage: "",
      reclassificationOptionIdErrorMessage: "",
      classificationCommentsErrorMessage: "",
      commentsErrorMessage: "",
      currentWorkflowType: "",
      currentWorkflowDescription: "",
      showAdditionalData: false,
      classify1BranchIdErrorMessage: "",
      classify2BranchIdErrorMessage: "",
      classify3BranchIdErrorMessage: "",
      classify4BranchIdErrorMessage: "",
      classify5BranchIdErrorMessage: "",
    };

    const [autocompleteToOptions, setAutocompleteToOptions] = useState<
      Array<TPKeyValue>
    >([]);
    const [
      selectedAutocompleteTaskTypeOption,
      setSelectedAutocompleteTaskTypeOption,
    ] = useState<Array<TPKeyValue>>([]);
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );
    const [textIsRequiredLabel, setTextIsRequiredLabel] = useState("");

    //modal question reassign task from view resources
    const [reassignQuestion, setReassignQuestion] = useState("");
    const [reassignTitle, setReassignTitle] = useState("");
    const [reassignOkLabel, setReassignOkLabel] = useState("");
    const [reassignCanceLabel, setReassignCanceLabel] = useState("");

    //modal alert
    const [
      alertTitleCaseNotReassignedLabel,
      setAlertTitleCaseNotReassignedLabel,
    ] = useState("");
    const [
      alertCaseNotReassignedMsgALabel,
      setAlertCaseNotReassignedMsgALabel,
    ] = useState("");
    const [
      alertCaseNotReassignedMsgBLabel,
      setAlertCaseNotReassignedMsgBLabel,
    ] = useState("");

    //Input
    const [currenCaseManagerName, setCurrenCaseManagerName] = useState("");
    const [currenCaseManagerId, setCurrenCaseManagerId] = useState("");
    const [commentsValue, setCommentsValue] = useState("");
    const [managerNewId, setManagerNewId] = useState("");
    const [managerNewName, setManagerNewName] = useState("");

    //Screen Resources
    const [commentsLabel, setComments] = useState("");
    const [descriptionLabel, setDescription] = useState("");
    const [currenCaseManagerLabel, setCurrenCaseManager] = useState("");
    const [topReassignLabel, setTopReassignLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");
    const [arrAdditionalDataCase, setArrAdditionalDataCase] =
      useState<Array<AdditionalDataCaseViewModel>>();

    //#region Functions
    const loadResourcesAndOrganizationAndRelation = async () => {
      setTextIsRequiredLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
      setTopReassignLabel(
        await TPI18N.GetText(resourceSet, "TopReassignLabel"),
      );
      setEmptyLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
      );
      setDescription(
        await TPI18N.GetText(resourceSet, "DescriptionReassignLabel"),
      );
      setCurrenCaseManager(
        await TPI18N.GetText(resourceSet, "CurrenCaseManagerReassignLabel"),
      );
      setComments(await TPI18N.GetText(resourceSet, "CommentsReassignLabel"));
      setReassignQuestion(
        await TPI18N.GetText(resourceSet, "ReassignAlertQuestion"),
      );

      setReassignTitle(await TPI18N.GetText(resourceSet, "ReassignAlertTitle"));
      setReassignOkLabel(
        await TPI18N.GetText(resourceSet, "ReassignAlertOkLabel"),
      );
      setReassignCanceLabel(
        await TPI18N.GetText(resourceSet, "ReassignAlertCanceLabel"),
      );
      setAlertTitleCaseNotReassignedLabel(
        await TPI18N.GetText(resourceSet, "AlertTitleCaseNotReassigned"),
      );
      setAlertCaseNotReassignedMsgALabel(
        await TPI18N.GetText(resourceSet, "AlertCaseNotReassignedMsgA"),
      );
      setAlertCaseNotReassignedMsgBLabel(
        await TPI18N.GetText(resourceSet, "AlertCaseNotReassignedMsgB"),
      );

      // Cargando información del cliente
      await realChangeCurrentCustomer(currentSubsidiaryOrganizationId);

      let newReclassificationValueList = await getKeyValueParams(
        SystemParametersEnum.S_RERE,
      );

      // Loading additional data case
      let newArrAdditionalDataCase: Array<AdditionalDataCaseViewModel> | null =
        await getAdditionalDataByCaseId(caseInfo.caseNumber);
      if (newArrAdditionalDataCase && newArrAdditionalDataCase.length > 0) {
        setArrAdditionalDataCase(newArrAdditionalDataCase);
      }

      // Loading current classifiers
      await loadCurrentCaseClassifiers();

      await loadManager();
    };

    const loadCurrentCaseClassifiers = async () => {
      let newWorkflowType: string;
      let newWorkflowDescription: string;
      let additionalDataList: Array<AdditionalDataViewModel> | null = null;
      let newAdditionalDataList: Array<AdditionalDataViewModel> = [];
      let workflowData: WorkflowTypeViewModel | null;

      newWorkflowType = "";
      newWorkflowDescription = "";

      // Call
      workflowData = await getWorkflowType(
        caseInfo.baseLevelId,
        caseInfo.classificator1,
        caseInfo.classificator2,
        caseInfo.classificator3,
        caseInfo.classificator4,
        caseInfo.classificator5,
      );
      if (workflowData) {
        newWorkflowType = workflowData.id;
        newWorkflowDescription =
          workflowData.localizedDescription &&
          workflowData.localizedDescription !== ""
            ? workflowData.localizedDescription
            : workflowData.description;
      }
      // Additional data
      additionalDataList = await getAdditionalDataForClassification(
        caseInfo.classificator1,
        caseInfo.classificator2,
        caseInfo.classificator3,
        caseInfo.classificator4,
        caseInfo.classificator5,
      );
      if (additionalDataList && additionalDataList.length >= 1) {
        newAdditionalDataList = [...additionalDataList];
      }

      let command1: commandType = {
        type: commandsEnum.change_current_branch,
        payload: {
          treeNumber: 1,
          newBranch: caseInfo.classificator1,
          newDescriptionBranch: caseInfo.classificator1Value,
          newWorkflowType: newWorkflowType,
          newWorkflowDescription: newWorkflowDescription,
          newAdditionalDataList: newAdditionalDataList,
          newBranchHierachyDescription: caseInfo.classificator1Value,
        },
      };
      dispatch(command1);
    };

    //called to search a customer
    const getCustomerByClientIdSubsidiaryIdAndSubOrg = async (
      subsidiaryOrganizationId: number,
    ) => {
      let serviceClient = new ClientService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getClientBySubsidiaryOrganizationId(
            subsidiaryOrganizationId,
            false,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        return responseRequest[0];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
        );
        setIsLoadingScreen(false);
        return null;
      }
    };

    //called when change customer after search/insert/modify or select anonymous
    const changeCurrentCustomerId = (
      newCurrentCustomerId: number,
      newCurrentSubsidiaryId: number,
      newCurrentSubsidiaryOrgId: number,
    ) => {
      // let command1: commandType = {
      //   type: commandsEnum.change_CurrentCustomer,
      //   payload: {
      //     newCurrentCustomerId: newCurrentCustomerId,
      //     newCurrentSubsidiaryId: newCurrentSubsidiaryId,
      //     newCurrentSubsidiaryOrgId: newCurrentSubsidiaryOrgId,
      //   },
      // };

      dispatch({
        type: commandsEnum.change_CurrentCustomer,
        payload: {
          newCurrentCustomerId: newCurrentCustomerId,
          newCurrentSubsidiaryId: newCurrentSubsidiaryId,
          newCurrentSubsidiaryOrgId: newCurrentSubsidiaryOrgId,
        },
      });
    };

    //real actions after change customer
    const realChangeCurrentCustomer = async (
      subsidiaryOrganizationId: number,
    ) => {
      let fullCustomerData: ClientAdminViewModel | null;
      fullCustomerData = await getCustomerByClientIdSubsidiaryIdAndSubOrg(
        subsidiaryOrganizationId,
      );
      if (fullCustomerData) {
        changeCurrentCustomerId(
          fullCustomerData.id,
          fullCustomerData.subsidiaryId,
          fullCustomerData.subsidiaryOrganizationId,
        );
      } else {
        //todo logs
      }
    };

    //get workflow based on classificators and base level
    const getWorkflowType = async (
      baseLevelId: string,
      branch1: string,
      branch2: string,
      branch3: string,
      branch4: string,
      branch5: string,
    ) => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200, 404];
      let workflowTypeData: WorkflowTypeViewModel | null = null;
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getByBaseLevelAndClassifiers(
          baseLevelId,
          branch1,
          branch2,
          branch3,
          branch4,
          branch5,
          false,
          true,
          expectedCodes,
        );

        workflowTypeData = responseRequest;
        setIsLoadingScreen(false);
        return workflowTypeData;
      } catch (error) {
        setIsLoadingScreen(false);
        TPLog.Log(
          `Error ${componentFileName} getWorkflowType ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getWorkflowType ex`);
        return null;
      }
    };

    //get additional data based on classificators
    const getAdditionalDataForClassification = async (
      branch1: string,
      branch2: string,
      branch3: string,
      branch4: string,
      branch5: string,
    ) => {
      let serviceClient = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];
      let additionalData: Array<AdditionalDataViewModel> | null = null;
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getByClassifiers(
          branch1,
          branch2,
          branch3,
          branch4,
          branch5,
          "1", //active
          "1", //visible
          false,
          true,
          expectedCodes,
        );

        additionalData = responseRequest;
        setIsLoadingScreen(false);
        return additionalData;
      } catch (error) {
        setIsLoadingScreen(false);
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataForClassification ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataForClassification ex`,
        );
        return null;
      }
    };

    //get additional data by case id
    const getAdditionalDataByCaseId = async (caseNumber: number) => {
      let additionalDataCaseService = new AdditionalDataCaseService();
      let expectedCodes: Array<number> = [200, 404];
      let additionalData: Array<AdditionalDataCaseViewModel> | null = null;

      try {
        setIsLoadingScreen(true);
        let responseRequest = await additionalDataCaseService.getByCaseId(
          caseNumber,
          false,
          true,
          expectedCodes,
        );

        additionalData = responseRequest;
        setIsLoadingScreen(false);
        return additionalData;
      } catch (error) {
        setIsLoadingScreen(false);
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataByCaseId ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataByCaseId ex`,
        );
        return null;
      }
    };

    const getKeyValueParams = async (
      parentId: SystemParametersEnum,
    ): Promise<Array<TPKeyValue> | null> => {
      let parametersService = new ParametersService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest =
          await parametersService.getByParentIdAndFilterIsActive(
            parentId,
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            expectedCodes,
          );

        let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.id,
              value: item.localizedDescription,
            };
          },
        );
        newTypeKeyValueList.unshift({ key: "", value: "--" });
        return newTypeKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getKeyValueParams ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getKeyValueParams ex`);
        return null;
      }
    };

    const onValueChangeTPAutoComplete = (e: any) => {
      if (e != undefined && e[0] && e.length > 0) {
        setManagerNewId(e[0].key);
        setManagerNewName(e[0].value);
        setSelectedAutocompleteTaskTypeOption(e);
      }
    };

    //Load Manager
    const loadManager = async () => {
      let tasktypeService = new UserService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        //Load users by search
        let responseRequest = await tasktypeService.getCasesById(
          caseId.toString(),
          false,
          true,
          expectedCodes,
        );
        let newToKeyValueList: any = responseRequest.map(function (item: any) {
          return {
            nameManager: item.caseResponsibleName,
          };
        });

        if (newToKeyValueList[0].nameManager != "") {
          setCurrenCaseManagerName(" " + newToKeyValueList[0].nameManager);
          setCurrenCaseManagerId(" " + newToKeyValueList[0].nameManager);
        } else setCurrenCaseManagerName(" " + "...");

        return newToKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        );
        return [];
      }
    };

    //handle ref
    useImperativeHandle(ref, () => ({
      async reassignCase() {
        handleCallBackModalAlertReassign(true);
      },
    }));

    //Reassing Case
    const handleReassingCase = async (
      acceptButton: boolean,
      callBackData: any,
    ) => {
      handleCallBackModalAlertReassign(false);
      setIsLoadingScreen(true);
      let caseService = new CaseService();
      let expectedCodes: Array<number> = [200, 404];

      let reassign = new ReassignCaseInputDTO();
      reassign.caseId = caseId;
      reassign.guidUserNew = managerNewId;
      reassign.comments = commentsValue;
      let alertValue =
        alertCaseNotReassignedMsgALabel +
        "  " +
        caseId +
        "  " +
        alertCaseNotReassignedMsgBLabel +
        " " +
        managerNewName;
      if (acceptButton) {
        if (reassign.caseId != 0 && reassign.guidUserNew != "") {
          try {
            //Load users by search
            let responseRequest = await caseService.reassignCase(
              reassign,
              false,
              true,
              expectedCodes,
            );

            if (responseRequest.responseData.responseMessage.code == 200) {
              showToast(
                responseRequest.responseData.responseMessage.message
                  .replaceAll("<b>", "")
                  .replaceAll("</b>", ""),
                TPToastTypes.success,
              );
              handleCallBackModalReassign(false, () => {});
            } else {
              showToast(alertValue, TPToastTypes.error);
            }
          } catch (error) {
            showToast(alertValue, TPToastTypes.error);
            TPLog.Log(
              `Error ${componentFileName} handleReassingCase ex`,
              TPLogType.ERROR,
              error,
            );
            console.error(`Error ${componentFileName} handleReassingCase ex`);
          }
        } else {
          showToast(alertValue, TPToastTypes.error);
        }
      }
      setIsLoadingScreen(false);
    };

    //SearchUser
    const handleToOnAutocompleteQuery = async (query: string) => {
      let tasktypeService = new UserService();
      let expectedCodes: Array<number> = [200, 404];
      setAutocompleteToOptions(new Array<TPKeyValue>());

      if (query == undefined) query = "";
      try {
        //Load users by search
        let responseRequest =
          await tasktypeService.getActiveUsersBySearchParameter(
            query,
            false,
            true,
            expectedCodes,
          );
        let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.userGuid,
              value: `${item.name}`,
            };
          },
        );

        let filter = newToKeyValueList.filter(
          (s) =>
            s.value.toString().trim().toLocaleLowerCase() !=
            currenCaseManagerName.trim().toLocaleLowerCase(),
        );

        setAutocompleteToOptions(filter);
        //  setSelectedAutocompleteTaskTypeOption(newToKeyValueList);
        return filter;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        );
        return [];
      }
    };

    const handleOnAutocompleteKeyDown = (event: any) => {
      //tab 9
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      //ArrowUp  38
      //ArrowDown  40
      if (
        event.keyCode != 9 &&
        event.keyCode != 37 &&
        event.keyCode != 39 &&
        event.keyCode != 13 &&
        event.keyCode != 35 &&
        event.keyCode != 36 &&
        event.keyCode != 38 &&
        event.keyCode != 40
      ) {
        setAutocompleteToOptions([]);
        setSelectedAutocompleteTaskTypeOption([]);
      }
    };

    const handleCallBackModalAlertReassign = async (status: boolean) => {
      if (managerNewId.trim() != "" && commentsValue.trim() != "") {
        let newModalQuestionState: TPModalQuestionState;
        newModalQuestionState = { ...modalQuestionState };
        newModalQuestionState.isShown = status;
        newModalQuestionState.callBackData = {};
        setModalQuestionState(newModalQuestionState);
      } else {
        let error = { ...isErrorLabel };

        if (managerNewId.trim() == "") error.toError = textIsRequiredLabel;
        else error.toError = "";

        if (commentsValue.trim() == "")
          error.commentError = textIsRequiredLabel;
        else error.commentError = "";

        console.log("error", error);
        setErrorLabel(error);
      }
    };

    //Reducer definition
    const reducer = (state: screenStateType, action: commandType) => {
      let newScreenState: screenStateType;
      let found: boolean;
      newScreenState = { ...state };
      switch (action.type) {
        case commandsEnum.change_additional_data_value:
          for (
            let i: number = 0;
            i <= newScreenState.additionalDataValues.length - 1;
            i++
          ) {
            if (
              newScreenState.additionalDataValues[i].key.toUpperCase() ===
              action.payload.additionalDataId.toUpperCase()
            ) {
              newScreenState.additionalDataValues[i].value =
                action.payload.newValue;
              break;
            }
          }
          return newScreenState;
        default:
          return state;
      }
    };

    const [screenState, dispatch] = React.useReducer(
      reducer,
      initialScreenState,
    );

    useEffect(() => {
      loadResourcesAndOrganizationAndRelation();
    }, []);

    return (
      <>
        <TPModalQuestion
          title={reassignTitle}
          yesLabel={reassignOkLabel}
          noLabel={reassignCanceLabel}
          question={reassignQuestion}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleReassingCase}
        ></TPModalQuestion>

        <div className="container-fluid" style={{ height: "495px" }}>
          <TPLoadingOverlay active={isLoadingScreen}>
            <>
              <div className="col-4">
                <div className="form-group">
                  <label>
                    <b>
                      <TPLabel
                        isMandatory={false}
                        labelText={currenCaseManagerLabel}
                      />
                      : &nbsp;
                    </b>
                    <TPLabel
                      isMandatory={false}
                      labelText={currenCaseManagerName}
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <TPLabel isMandatory={false} labelText={descriptionLabel} />
                  <br></br>
                  <br></br>
                  <b>
                    <TPLabel isMandatory={false} labelText={topReassignLabel} />
                  </b>

                  <TPAutoComplete
                    isMandatory={true}
                    labelText={""}
                    onValueChange={(e: any) => {
                      onValueChangeTPAutoComplete(e);
                    }}
                    onSearch={(event: any) => {
                      handleToOnAutocompleteQuery(event);
                    }} // handleToOnAutocompleteQuery(event)
                    isLoading={false}
                    options={autocompleteToOptions}
                    withIcon={true}
                    emptyLabel={emptyLabel}
                    onKeyDown={(event: any) => {
                      handleOnAutocompleteKeyDown(event);
                    }}
                    selected={selectedAutocompleteTaskTypeOption}
                    errorMessage={isErrorLabel.toError}
                    downArrowClick={handleToOnAutocompleteQuery}
                  ></TPAutoComplete>
                  <br />

                  <TPTextArea
                    id="IdTextArea"
                    onChange={(e: any) => setCommentsValue(e.target.value)}
                    value={commentsValue}
                    isMandatory={true}
                    labelText={commentsLabel}
                    errorMessage={isErrorLabel.commentError}
                    rows={4}
                    containerStyle={{ with: "300%", minWidth: "300%" }}
                  />
                </div>
              </div>
            </>
          </TPLoadingOverlay>
        </div>
      </>
    );
  },
);

export default ReassignCase;
