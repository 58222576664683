import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { throwError } from "@/modules/core/utils/errors-management";
import {
  EventGroup,
  EventViewRecord,
  GroupTableItem,
  RankingUser,
  StatusModel,
  TeamMember,
} from "@/pages/SupervisorCockpit/supervisor-cockpit.model";

export interface BaseParams {
  showPositiveMessage?: boolean;
  showNegativeMessage?: boolean;
  expectedCodes?: number[];
}

export interface TeamViewProps extends BaseParams {
  userId: string;
}

export interface GroupViewProps extends BaseParams {
  groupId: string;
}

export interface EventViewProps extends BaseParams {
  projectId: string;
  queueId: string | null;
}

export interface GroupByIdsViewProps extends BaseParams {
  groupIds: string;
}

export interface QueuesProps extends BaseParams {
  eventProjectsId: string;
}

export interface EventRecordsProps extends BaseParams {
  records: EventViewRecord[];
}

export interface TeamViewResponse {
  teamMembersModel: TeamMember[];
  statusModels: StatusModel[];
}

export interface EventViewResponse extends TeamViewResponse {
  rankingModel: RankingUser[];
}

export class CockpitDashboardService {
  serviceFileName: string = "CockpitDashboardService";
  baseUrl: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}`;
  headers: TPKeyValue[] = TPGlobal.standardHeaders;

  public async getTeamViewData({
    userId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: TeamViewProps): Promise<TeamViewResponse[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/supervisorcockpit/user/${userId}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(error, this.serviceFileName, "getTeamViewData");
      throw new Error(msgError);
    }
  }

  public async getGroupViewData({
    groupId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GroupViewProps): Promise<TeamViewResponse[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/supervisorcockpit/group/${groupId}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getGroupViewData"
      );
      throw new Error(msgError);
    }
  }

  public async getEventViewData({
    projectId,
    queueId = null,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: EventViewProps): Promise<EventViewResponse[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/supervisorcockpit/project/${projectId}/queue/${queueId}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getEventViewData"
      );
      throw new Error(msgError);
    }
  }

  public async getGroupList({
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: BaseParams): Promise<EventGroup[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/groups/take-next-all`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(error, this.serviceFileName, "getGroupList");
      throw new Error(msgError);
    }
  }

  public async getGroupsBy({
    groupIds,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: GroupByIdsViewProps): Promise<GroupTableItem[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/supervisorcockpit/groups`;

    const params = {
      groupIds,
    };

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers,
        params
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(error, this.serviceFileName, "getGroupsBy");
      throw new Error(msgError);
    }
  }

  public async getQueuesByProject({
    eventProjectsId,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: QueuesProps): Promise<any[]> {
    const httpClient = new TPHTTPService();

    const url = `${this.baseUrl}/eventProject/${eventProjectsId}/assignedgroups`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getQueuesByProject"
      );
      throw new Error(msgError);
    }
  }

  public async getEventRecordsBy({
    records,
    showNegativeMessage = true,
    showPositiveMessage = false,
    expectedCodes = [200, 404],
  }: EventRecordsProps): Promise<any[]> {
    const httpClient = new TPHTTPService();

    const recordsUTF8 = TPGlobal.stringToUTF8(
      JSON.stringify(records)
    ).toString();

    const url = `${this.baseUrl}/supervisorcockpit/events?jsonProjectsAndQueuesFilter=${recordsUTF8}`;

    try {
      const response: HTTPResponse = await httpClient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        this.headers
      );
      return response.responseData.data;
    } catch (error) {
      let msgError = throwError(
        error,
        this.serviceFileName,
        "getProjectRecordsBy"
      );
      throw new Error(msgError);
    }
  }
}
