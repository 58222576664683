import TPWizardContent, { ITPWizardContent } from "./TPWizardContent";
interface ITPWizardContents {
  children?: any;
  tpWizardContents: ITPWizardContent[];
}

const TPWizardContents = ({
  children,
  tpWizardContents,
}: ITPWizardContents) => {
  return (
    <div className="tab-content">
      {tpWizardContents.map((content: ITPWizardContent) => {
        return (
          <TPWizardContent
            key={content.id}
            id={content.id}
            isActive={content.isActive}
            name={"TPWizardContent"}
          >
            {content.children}
          </TPWizardContent>
        );
      })}
    </div>
  );
};

export default TPWizardContents;
