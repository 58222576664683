import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
  AddTaskInputDTO,
  AddTaskInputDTOValidator,
} from "@/models/Task/TaskInputDTO";
import { UserViewModel } from "@/models/Users/UserModels";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { UserService } from "@/services/UserService";
import moment from "moment";
import { FC, useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export type TPModalAddTaskState = {
  isShown: boolean;
  callBackData: any;
};

type TPModalAddTaskProps = {
  caseNumber: number;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: Function;
};

enum commandsAddTaskEnum {
  "clear_state" = 0,
  "change_value_property" = 1,
  "set_errors" = 2,
}

type commandType = {
  type: commandsAddTaskEnum;
  payload: any;
};

interface screenStateProps {
  taskTypeId: string;
  quantity: number;
  assignTo: string;
  assignToMe: boolean;
  termType: string;
  term: number;
  termUnit: string;
  dueDate: Date | null;
  dueDateHour: string;
  dueDateMinutes: string;
  comments: string;
  reminderDate: Date | null;
  reminderDateHour: string;
  reminderDateMinutes: string;
  reminderComments: string;
  taskTypeIdErrorMessage: string;
  quantityErrorMessage: string;
  assignToErrorMessage: string;
  dueDateErrorMessage: string;
  commentsErrorMessage: string;
  reminderDateErrorMessage: string;
  reminderCommentsErrorMessage: string;
  termErrorMessage: string;
  termUnitErrorMessage: string;
  [key: string]: any;
}

const TPModalAddTask: FC<TPModalAddTaskProps> = ({
  caseNumber,
  acceptLabel,
  cancelLabel,
  isShown,
  callBackAnswer,
}) => {
  const componentFileName: string = "TPModalAddTask.tsx";
  const resourceSet: string = "TPModalAddTaskComponent";
  //Screen Resources
  const [titleLabel, setTitleLabel] = useState("");
  const [taskTypeLabel, setTaskTypeLabel] = useState("");
  const [quantityLabel, setQuantityLabel] = useState("");
  const [assignToLabel, setAssignToLabel] = useState("");
  const [currentUserLabel, setCurrentUserLabel] = useState("");
  const [assignToMeLabel, setAssignToMeLabel] = useState("");
  const [termItemLabel, setTermItemLabel] = useState("");
  const [dueDateItemLabel, setDueDateItemLabel] = useState("");
  const [commentsLabel, setCommentsLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [timeUnitLabel, setTimeUnitLabel] = useState("");
  const [timeLabel, setTimeLabel] = useState("");
  const [dateLabel, setDateLabel] = useState("");
  const [hoursLabel, setHoursLabel] = useState("");
  const [minutesLabel, setMinutesLabel] = useState("");
  const [addReminderLabel, setAddReminderLabel] = useState("");
  const [commentsReminderLabel, setCommentsReminderLabel] = useState("");
  //TODO remove
  const [showReminderOption, setShowReminderOption] = useState(false);

  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [isReminder, setIsReminder] = useState(false);

  const editorRef = useRef<any>(null);
  const [editorErrorMessage, setEditorErrorMessage] = useState("");

  const editorReminderRef = useRef<any>(null);
  const [editorReminderErrorMessage, setEditorReminderErrorMessage] =
    useState("");

  const [autocompleteTaskTypeOptions, setAutocompleteTaskTypeOptions] =
    useState<Array<TPKeyValue>>([]);

  const [
    selectedAutocompleteTaskTypeOption,
    setSelectedAutocompleteTaskTypeOption,
  ] = useState<Array<TPKeyValue>>([]);

  //top n options for arrow icon
  const [autocompleteTaskTypeTopNOptions, setAutocompleteTaskTypeTopNOptions] =
    useState<Array<TPKeyValue>>([]);

  //User assign to
  const [autocompleteUserAssignOptions, setAutocompleteUserAssignOptions] =
    useState<Array<TPKeyValue>>([]);

  const [
    selectedAutocompleteUserAssignOption,
    setSelectedAutocompleteUserAssignOption,
  ] = useState<Array<TPKeyValue>>([{ key: "", value: "", value2: "" }]);

  //top n options for arrow icon
  const [
    autocompleteUserAssignTopNOptions,
    setAutocompleteUserAssignTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  const [quantityOptions, setQuantityOptions] = useState<Array<TPKeyValue>>([]);
  const [dueTermHoursOptions, setDueTermHoursOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [dueTermMinutesOptions, setDueTermMinutesOptions] = useState<
    Array<TPKeyValue>
  >([]);

  const [editorValue, setEditorValue] = useState("");
  const [editorReminderValue, setEditorReminderValue] = useState("");
  const [hasBeenModified, setHasBeenModified] = useState(false);

  const [unitTermList, setUnitTermListState] = useState<Array<TPKeyValue>>([]);
  const guidTask: string = uuidv4().replaceAll("-", "");
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    styleModal["zIndex"] = "1057";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const loadResourcesAndTaskType = async () => {
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setTaskTypeLabel(await TPI18N.GetText(resourceSet, "TaskTypeLabel"));
    setQuantityLabel(await TPI18N.GetText(resourceSet, "QuantityLabel"));
    setAssignToLabel(await TPI18N.GetText(resourceSet, "AssignToLabel"));
    setAssignToMeLabel(await TPI18N.GetText(resourceSet, "AssignToMeLabel"));
    setTermItemLabel(await TPI18N.GetText(resourceSet, "TermItemLabel"));
    setDueDateItemLabel(await TPI18N.GetText(resourceSet, "DueDateItemLabel"));
    setCommentsLabel(await TPI18N.GetText(resourceSet, "CommentsLabel"));
    setEmptyLabel(await TPI18N.GetText(resourceSet, "EmptyLabel"));
    setTimeUnitLabel(await TPI18N.GetText(resourceSet, "TimeUnitLabel"));
    setTimeLabel(await TPI18N.GetText(resourceSet, "TimeLabel"));
    setDateLabel(await TPI18N.GetText(resourceSet, "DateLabel"));
    setHoursLabel(await TPI18N.GetText(resourceSet, "HoursLabel"));
    setMinutesLabel(await TPI18N.GetText(resourceSet, "MinutesLabel"));
    setAddReminderLabel(await TPI18N.GetText(resourceSet, "AddReminderLabel"));
    setCommentsReminderLabel(
      await TPI18N.GetText(resourceSet, "CommentsReminderLabel")
    );

    //Quantity options
    let newQuantityOptions: Array<TPKeyValue> = [];
    for (let i = 1; i <= 10; i++) {
      newQuantityOptions.push({ key: i.toString(), value: i });
    }
    setQuantityOptions(newQuantityOptions);

    //Due Term Hours options
    let newDueTermHoursOptions: Array<TPKeyValue> = [];
    for (let i = 0; i <= 23; i++) {
      newDueTermHoursOptions.push({
        key: i < 10 ? `0${i.toString()}` : i.toString(),
        value: i < 10 ? `0${i.toString()}` : i.toString(),
      });
    }
    setDueTermHoursOptions(newDueTermHoursOptions);

    //Due Term Minutes options
    let newDueTermMinutesOptions: Array<TPKeyValue> = [];
    for (let i = 0; i <= 59; i++) {
      newDueTermMinutesOptions.push({
        key: i < 10 ? `0${i.toString()}` : i.toString(),
        value: i < 10 ? `0${i.toString()}` : i.toString(),
      });
    }
    setDueTermMinutesOptions(newDueTermMinutesOptions);

    let parametersSercice = new ParametersService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await parametersSercice.getByParentIdAndFilterIsActive(
          "TERMUNIT", //TODO
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );
      let newUnitTermListState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newUnitTermListState.unshift({ key: "", value: "--" });
      setUnitTermListState(newUnitTermListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndTaskType getByParentIdAndFilterIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResourcesAndTaskType getByParentIdAndFilterIsActive ex`
      );
      setIsLoadingScreen(false);
    }

    setIsLoadingScreen(false);
  };

  const handleAssignToMe = async (e: any) => {
    setIsReminder(false);
    handleInputTextChange("assignToMe", e.target.checked);

    if (e.target.checked) {
      let currentUserInList: TPKeyValue | undefined =
        autocompleteUserAssignOptions.find(
          (user) => user.key === TPGlobal.currentUserGuid
        );
      if (currentUserInList) {
        setSelectedAutocompleteUserAssignOption([
          {
            key: TPGlobal.currentUserGuid,
            value: currentUserInList.value,
            value2: currentUserInList.value2,
          },
        ]);
        setCurrentUserLabel(currentUserInList.value);
      } else {
        let user = await getUserById(TPGlobal.currentUserGuid);
        if (user) {
          setAutocompleteUserAssignOptions([
            {
              key: TPGlobal.currentUserGuid,
              value: user.name,
              value2: user.isGroup,
            },
          ]);
          setSelectedAutocompleteUserAssignOption([
            {
              key: TPGlobal.currentUserGuid,
              value: user.name,
              value2: user.isGroup,
            },
          ]);
          setCurrentUserLabel(user.name);
        }
      }
    } else {
      setSelectedAutocompleteUserAssignOption([]);
    }
  };

  const getUserById = async (
    pRecordId: string
  ): Promise<UserViewModel | null> => {
    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getUserById(
        pRecordId,
        false,
        true,
        expectedCodes
      );
      let recordInfo: UserViewModel;
      recordInfo = { ...responseRequest };
      setIsLoadingScreen(false);
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getUserById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getUserById ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleOnCancelOrCloseClick = () => {
    let command: commandType = {
      type: commandsAddTaskEnum.clear_state,
      payload: null,
    };
    dispatchCommand(command);
    callBackAnswer(false, null);
  };

  const handleInputTextChange = (propertyName: string, value: any) => {
    let command: commandType = {
      type: commandsAddTaskEnum.change_value_property,
      payload: {
        newPropertyName: propertyName,
        newValue: value,
      },
    };
    dispatchCommand(command);
  };

  const handleOnAutocompleteChange = (newSelectedValue: Array<TPKeyValue>) => {
    setSelectedAutocompleteTaskTypeOption(newSelectedValue);
    let command: commandType = {
      type: commandsAddTaskEnum.change_value_property,
      payload: {
        newPropertyName: "taskTypeIdErrorMessage",
        newValue: "",
      },
    };
    dispatchCommand(command);
  };

  const handleOnAutocompleteUserAssignChange = (
    newSelectedValue: Array<TPKeyValue>
  ) => {
    setSelectedAutocompleteUserAssignOption(newSelectedValue);
    let command: commandType = {
      type: commandsAddTaskEnum.change_value_property,
      payload: {
        newPropertyName: "assignToErrorMessage",
        newValue: "",
      },
    };
    dispatchCommand(command);
  };

  const handleOnAutocompleteQuery = async (query: string) => {
    let taskTypeService = new TaskTypeService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await taskTypeService.getSearchTaskType(
        query,
        TPActiveOptions.ACTIVE, //TODO ask if all or actives only
        1, //Addables
        false,
        true,
        expectedCodes
      );

      let newTaskTypeList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newTaskTypeList.unshift({ key: "", value: "--" });
      setAutocompleteTaskTypeOptions(newTaskTypeList);
      return newTaskTypeList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} handleOnAutocompleteQuery ex`);
      return [];
    }
  };

  const handleOnAutocompleteUserAssignQuery = async (query: string) => {
    let userService = new UserService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await userService.getActiveUsersBySearchParameter(
        query,
        false,
        true,
        expectedCodes
      );
      let newUserList: Array<TPKeyValue> = responseRequest.map(function (item) {
        return { key: item.userGuid, value: item.name, value2: item.isGroup };
      });
      newUserList.unshift({ key: "", value: "--", value2: false });
      setAutocompleteUserAssignOptions(newUserList);
      return newUserList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleOnAutocompleteUserAssignQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleOnAutocompleteUserAssignQuery ex`
      );
      return [];
    }
  };

  const handleOnAutocompleteKeyDown = (event: any) => {
    // Perform a search with the current input value
    const inputValue = event.target.value;
    if (inputValue.length === 1) {
      handleOnAutocompleteQuery("");
    }
  };

  const handleOnAutocompleteUserAssignKeyDown = (event: any) => {
    // Perform a search with the current input value
    const inputValue = event.target.value;
    if (inputValue.length === 1) {
      handleOnAutocompleteUserAssignQuery("");
    }
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteTaskTypeTopNOptions.length === 0) {
      newTopNOptions = await handleOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteTaskTypeTopNOptions([...newTopNOptions]);
        setAutocompleteTaskTypeOptions([...newTopNOptions]);
        setSelectedAutocompleteTaskTypeOption([]);
      }
    } else {
      //use cached values;
      setAutocompleteTaskTypeOptions([...autocompleteTaskTypeTopNOptions]);
      setSelectedAutocompleteTaskTypeOption([]);
    }
  };

  const handleAutoCompleteTopNUserAssignClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteUserAssignTopNOptions.length === 0) {
      newTopNOptions = await handleOnAutocompleteUserAssignQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteUserAssignTopNOptions([...newTopNOptions]);
        setAutocompleteUserAssignOptions([...newTopNOptions]);
        setSelectedAutocompleteUserAssignOption([]);
      }
    } else {
      //use cached values;
      setAutocompleteUserAssignOptions([...autocompleteUserAssignTopNOptions]);
      setSelectedAutocompleteUserAssignOption([]);
    }
  };

  //handle when editor change
  // const handleEditorChange = () => {
  //   setEditorErrorMessage("");
  // };

  const handleEditorChange = (value: any) => {
    const filterData = value.replace(/(<([^>]+)>)/gi, "");
    setEditorErrorMessage("");
    setEditorValue(value);
    setHasBeenModified(filterData.length > 0 ? false : true);
  };

  //handle when editor reminder change
  const handleEditorReminderChange = (value: any) => {
    const filterData = value.replace(/(<([^>]+)>)/gi, "");
    setEditorReminderErrorMessage("");
    setEditorReminderValue(value);
    setHasBeenModified(filterData.length > 0 ? false : true);
  };

  const handlerOnOKClick = async () => {
    let dueDatestr: string | null;
    let reminderDatestr: string | null;
    const termType: string = "term";
    if (screenState.termType === termType) {
      dueDatestr = null;
    } else {
      if (screenState.dueDate === null) {
        dueDatestr = null;
      } else {
        try {
          dueDatestr = `${moment(screenState.dueDate).format("YYYY-MM-DD")}T${screenState.dueDateHour}:${screenState.dueDateMinutes}:00.000Z`;
        } catch {
          dueDatestr = null;
        }
      }
    }

    if (!isReminder) {
      reminderDatestr = null;
    } else {
      if (screenState.reminderDate) {
        reminderDatestr = `${moment(screenState.reminderDate).format("YYYY-MM-DD")}T${screenState.reminderDateHour}:${screenState.reminderDateMinutes}:00.000Z`;
      } else {
        reminderDatestr = null;
      }
    }

    //validate comments
    let comments: string;
    comments = "";

    if (editorValue.trim() == "") {
      setHasBeenModified(true);
    }
    if (editorRef.current.props.value) {
      comments = editorRef.current.props.value;
      comments = comments === null ? "" : comments.trim();
    }
    //validate commentsReminder
    let commentsReminder: string;
    commentsReminder = "";
    if (commentsReminder.trim() == "") {
      setHasBeenModified(true);
    }
    if (isReminder && editorReminderRef.current.props.value) {
      // commentsReminder = TPGlobal.TPSanitizeWithoutLinks(editorReminderRef.current.props.value);
      commentsReminder = editorReminderRef.current.props.value;
      commentsReminder =
        commentsReminder === null ? "" : commentsReminder.trim();
    } else {
      commentsReminder = "";
    }

    comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim();
    commentsReminder = TPGlobal.TPSanitizeWithoutLinks(commentsReminder).trim();
    let recordInputDTO: AddTaskInputDTO = {
      useRPCMethod: true,
      caseId: +caseNumber,
      taskTypeId:
        selectedAutocompleteTaskTypeOption.length > 0
          ? selectedAutocompleteTaskTypeOption[0].key
          : "",
      quantity: +screenState.quantity,
      responsibleGuidUser:
        selectedAutocompleteUserAssignOption.length > 0
          ? selectedAutocompleteUserAssignOption[0].key
          : "",
      term: screenState.termType === "term" ? +screenState.term : null,
      termUnitId: screenState.termType === "term" ? screenState.termUnit : "",
      dueDate: dueDatestr,
      comments: TPGlobal.stringToUTF8(comments).toString(),
      reminderDate: reminderDatestr,
      reminderComments: TPGlobal.stringToUTF8(commentsReminder).toString(),
      typistGuidUser: TPGlobal.currentUserGuid,
      taskGuid: guidTask,
    };
    let inputDTOValidator = new AddTaskInputDTOValidator(
      screenState.termType,
      isReminder
    );
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let validationsErrores: any = new Object();
      if (resultValidator.taskTypeId) {
        validationsErrores.taskTypeIdErrorMessage = await TPI18N.GetResource(
          resultValidator.taskTypeId
        );
      } else {
        validationsErrores.taskTypeIdErrorMessage = "";
      }
      if (resultValidator.quantity) {
        validationsErrores.quantityErrorMessage = await TPI18N.GetResource(
          resultValidator.quantity
        );
      } else {
        validationsErrores.quantityErrorMessage = "";
      }
      if (resultValidator.responsibleGuidUser) {
        validationsErrores.assignToErrorMessage = await TPI18N.GetResource(
          resultValidator.responsibleGuidUser
        );
      } else {
        validationsErrores.assignToErrorMessage = "";
      }
      if (resultValidator.comments) {
        validationsErrores.commentsErrorMessage = await TPI18N.GetResource(
          resultValidator.comments
        );
        setEditorErrorMessage(
          await TPI18N.GetResource(resultValidator.comments)
        );
      } else {
        validationsErrores.commentsErrorMessage = "";
        setEditorErrorMessage("");
      }
      if (resultValidator.reminderDate) {
        validationsErrores.reminderDateErrorMessage = await TPI18N.GetResource(
          resultValidator.reminderDate
        );
      } else {
        validationsErrores.reminderDateErrorMessage = "";
      }
      if (resultValidator.reminderComments) {
        validationsErrores.reminderCommentsErrorMessage =
          await TPI18N.GetResource(resultValidator.reminderComments);
        setEditorReminderErrorMessage(
          await TPI18N.GetResource(
            await TPI18N.GetResource(resultValidator.reminderComments)
          )
        );
      } else {
        validationsErrores.reminderCommentsErrorMessage = "";
        setEditorReminderErrorMessage("");
      }
      if (resultValidator.term) {
        validationsErrores.termErrorMessage = await TPI18N.GetResource(
          resultValidator.term
        );
      } else {
        validationsErrores.termErrorMessage = "";
      }
      if (resultValidator.termUnitId) {
        validationsErrores.termUnitErrorMessage = await TPI18N.GetResource(
          resultValidator.termUnitId
        );
      } else {
        validationsErrores.termUnitErrorMessage = "";
      }
      if (resultValidator.dueDate) {
        validationsErrores.dueDateErrorMessage = await TPI18N.GetResource(
          resultValidator.dueDate
        );
      } else {
        validationsErrores.dueDateErrorMessage = "";
      }
      let command: commandType = {
        type: commandsAddTaskEnum.set_errors,
        payload: {
          errors: validationsErrores,
        },
      };
      dispatchCommand(command);
      return;
    }

    await insertTask(recordInputDTO);
  };

  const insertTask = async (inputDTO: AddTaskInputDTO) => {
    let serviceClient = new TaskService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.AddTask(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackAnswer(true, { guidTask: guidTask });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertTask ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertTask ex`);
      setIsLoadingScreen(false);
      callBackAnswer(false, null);
    }
  };

  const initialStateBll: screenStateProps = {
    taskTypeId: "",
    quantity: 1,
    assignTo: "",
    assignToMe: false,
    termType: "term",
    term: 1,
    termUnit: "TERMUNITDA",
    dueDate: null,
    dueDateHour: "00",
    dueDateMinutes: "00",
    comments: "",
    taskTypeIdErrorMessage: "",
    assignToErrorMessage: "",
    dueDateErrorMessage: "",
    commentsErrorMessage: "",
    quantityErrorMessage: "",
    reminderDate: null,
    reminderComments: "",
    reminderDateErrorMessage: "",
    reminderDateHour: "00",
    reminderDateMinutes: "00",
    reminderCommentsErrorMessage: "",
    termErrorMessage: "",
    termUnitErrorMessage: "",
  };

  //reducer definition
  const [screenState, dispatchCommand] = useReducer(doCommand, initialStateBll);

  function doCommand(prevState: screenStateProps, command: commandType) {
    let newScreenState: screenStateProps;
    switch (command.type) {
      case commandsAddTaskEnum.clear_state:
        return initialStateBll;
      case commandsAddTaskEnum.change_value_property:
        newScreenState = { ...prevState };
        if (
          typeof newScreenState[command.payload.newPropertyName] === "boolean"
        ) {
          newScreenState[command.payload.newPropertyName] =
            !newScreenState[command.payload.newPropertyName];
        } else {
          newScreenState[command.payload.newPropertyName] =
            command.payload.newValue;
        }
        newScreenState[command.payload.newPropertyName + "ErrorMessage"] = "";
        return newScreenState;
      case commandsAddTaskEnum.set_errors:
        newScreenState = { ...prevState };
        newScreenState.taskTypeIdErrorMessage =
          command.payload.errors.taskTypeIdErrorMessage;
        newScreenState.quantityErrorMessage =
          command.payload.errors.quantityErrorMessage;
        newScreenState.assignToErrorMessage =
          command.payload.errors.assignToErrorMessage;
        newScreenState.commentsErrorMessage =
          command.payload.errors.commentsErrorMessage;
        newScreenState.reminderDateErrorMessage =
          command.payload.errors.reminderDateErrorMessage;
        newScreenState.reminderCommentsErrorMessage =
          command.payload.errors.reminderCommentsErrorMessage;
        newScreenState.termErrorMessage =
          command.payload.errors.termErrorMessage;
        newScreenState.termUnitErrorMessage =
          command.payload.errors.termUnitErrorMessage;
        newScreenState.dueDateErrorMessage =
          command.payload.errors.dueDateErrorMessage;
        return newScreenState;
    }
  }

  useEffect(() => {
    loadResourcesAndTaskType();
  }, []);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{titleLabel}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => handleOnCancelOrCloseClick()}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"} isModal>
                {/* <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {customerInfoSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPAutoComplete
                        isMandatory={true}
                        labelText={taskTypeLabel}
                        onValueChange={handleOnAutocompleteChange}
                        onSearch={(query: string) => {
                          handleOnAutocompleteQuery(query);
                        }}
                        isLoading={false}
                        options={autocompleteTaskTypeOptions}
                        withIcon={true}
                        emptyLabel={emptyLabel}
                        onKeyDown={handleOnAutocompleteKeyDown}
                        selected={selectedAutocompleteTaskTypeOption}
                        errorMessage={screenState.taskTypeIdErrorMessage}
                        downArrowClick={handleAutoCompleteTopNClick}
                      ></TPAutoComplete>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        labelText={quantityLabel}
                        onChange={(e: any) =>
                          handleInputTextChange("quantity", e.target.value)
                        }
                        dataSource={quantityOptions}
                        value={screenState.quantity}
                        errorMessage={screenState.quantityErrorMessage}
                      ></TPSelect>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      {screenState.assignToMe ? (
                        <TPTextBox
                          id="IdTextBox"
                          labelText={assignToLabel}
                          isMandatory={false}
                          disabled={true}
                          onChange={() => TPGlobal.foo()}
                          value={currentUserLabel}
                        ></TPTextBox>
                      ) : (
                        <TPAutoComplete
                          isMandatory={true}
                          labelText={assignToLabel}
                          onValueChange={handleOnAutocompleteUserAssignChange}
                          onSearch={(query: string) => {
                            handleOnAutocompleteUserAssignQuery(query);
                          }}
                          isLoading={false}
                          options={autocompleteUserAssignOptions}
                          withIcon={true}
                          emptyLabel={emptyLabel}
                          onKeyDown={handleOnAutocompleteUserAssignKeyDown}
                          selected={selectedAutocompleteUserAssignOption}
                          errorMessage={screenState.assignToErrorMessage}
                          downArrowClick={handleAutoCompleteTopNUserAssignClick}
                        ></TPAutoComplete>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPCheckBox
                        id="IdCheckBox"
                        labelText={assignToMeLabel}
                        checked={screenState.assignToMe}
                        onChange={(e: any) => handleAssignToMe(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TPRadioGroup
                      id="IdRadioGroup"
                      value={screenState.termType}
                      source={[
                        { key: "term", value: termItemLabel },
                        { key: "due", value: dueDateItemLabel },
                      ]}
                      onChange={(e: any) =>
                        handleInputTextChange("termType", e.target.value)
                      }
                    />
                  </div>
                </div>
                {screenState.termType === "term" ? (
                  <>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <TPNumeric
                            id="IdTPNumeric"
                            isMandatory={true}
                            onChange={(value: number) =>
                              handleInputTextChange("term", value)
                            }
                            value={screenState.term}
                            labelText={timeLabel}
                            errorMessage={screenState.termErrorMessage}
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="form-group">
                          <TPSelect
                            id="IdSelect"
                            labelText={timeUnitLabel}
                            onChange={(e: any) =>
                              handleInputTextChange("termUnit", e.target.value)
                            }
                            dataSource={unitTermList}
                            value={screenState.termUnit}
                            errorMessage={screenState.termUnitErrorMessage}
                          ></TPSelect>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-8">
                        <div className="form-group">
                          {" "}
                          <TPDatePicker
                            labelText={dateLabel}
                            isMandatory={true}
                            selectedDate={screenState.dueDate}
                            onChangeDate={(newDate: Date) =>
                              handleInputTextChange("dueDate", newDate)
                            }
                            minDate={moment(new Date()).toDate()}
                            customDateFormat={TPGlobal.dateFormat}
                            errorMessage={screenState.dueDateErrorMessage}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group">
                          <TPSelect
                            id="IdSelect"
                            labelText={hoursLabel}
                            onChange={(e: any) =>
                              handleInputTextChange(
                                "dueDateHour",
                                e.target.value
                              )
                            }
                            dataSource={dueTermHoursOptions}
                            value={screenState.dueDateHour}
                          ></TPSelect>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group">
                          <TPSelect
                            id="IdSelect"
                            labelText={minutesLabel}
                            onChange={(e: any) =>
                              handleInputTextChange(
                                "dueDateMinutes",
                                e.target.value
                              )
                            }
                            dataSource={dueTermMinutesOptions}
                            value={screenState.dueDateMinutes}
                          ></TPSelect>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {TPGlobal.currentUserTimeZoneId}
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col">
                    <div className="mb-1">
                      <TPLabel labelText={commentsLabel} />
                      <TPLabel style={{ color: "red" }} labelText="*" />
                    </div>
                    <TPEditor
                      referece={(editor: any) => (editorRef.current = editor)}
                      value={editorValue}
                      onChange={handleEditorChange}
                      initialValue=""
                      isVisible={false}
                      placeholder=""
                    />
                    <span style={{ color: "#dc3545", fontSize: "14px" }}>
                      {editorErrorMessage}
                    </span>
                  </div>
                </div>
                {showReminderOption &&
                  selectedAutocompleteUserAssignOption.length > 0 &&
                  selectedAutocompleteUserAssignOption[0].value2 && (
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <TPCheckBox
                            id="IdCheckBox"
                            labelText={addReminderLabel}
                            checked={isReminder}
                            onChange={(e: any) => setIsReminder(!isReminder)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {isReminder && (
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-8">
                            <div className="form-group">
                              {" "}
                              <TPDatePicker
                                labelText={dateLabel}
                                isMandatory={true}
                                selectedDate={screenState.reminderDate}
                                onChangeDate={(newDate: Date) =>
                                  handleInputTextChange("reminderDate", newDate)
                                }
                                minDate={moment(new Date())
                                  .add(1, "day")
                                  .toDate()}
                                customDateFormat={TPGlobal.dateFormat}
                                errorMessage={
                                  screenState.reminderDateErrorMessage
                                }
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <TPSelect
                                id="IdSelect"
                                labelText={hoursLabel}
                                onChange={(e: any) =>
                                  handleInputTextChange(
                                    "reminderDateHour",
                                    e.target.value
                                  )
                                }
                                dataSource={dueTermHoursOptions}
                                value={screenState.reminderDateHour}
                              ></TPSelect>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <TPSelect
                                id="IdSelect"
                                labelText={minutesLabel}
                                onChange={(e: any) =>
                                  handleInputTextChange(
                                    "reminderDateMinutes",
                                    e.target.value
                                  )
                                }
                                dataSource={dueTermMinutesOptions}
                                value={screenState.reminderDateMinutes}
                              ></TPSelect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-1">
                          <TPLabel labelText={commentsReminderLabel} />
                          <TPLabel style={{ color: "red" }} labelText="*" />
                        </div>
                        <TPEditor
                          referece={(editor: any) =>
                            (editorReminderRef.current = editor)
                          }
                          placeholder=""
                          value={editorReminderValue}
                          onChange={handleEditorReminderChange}
                          initialValue=""
                          isVisible={false}
                        />
                        <span style={{ color: "#dc3545", fontSize: "14px" }}>
                          {editorReminderErrorMessage}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      handlerOnOKClick();
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => {
                      handleOnCancelOrCloseClick();
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ zIndex: 1056 }} className={backdropClass}></div>
    </>
  );
};

export default TPModalAddTask;
