import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import { downloadCSV } from "@/helpers/TPFunctions";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  TPActiveOptions,
  TPAssignedOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RoleUserViewModel } from "@/models/Roles/RolesViewModel";
import { TPI18N } from "@/services/I18nService";
import { RoleUserService } from "@/services/RoleUserService";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<RoleUserViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
  "set_griddata" = 5,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface RoleUserAdminInterface {
  roleId: string;
}

const RolesAssignUsersAdmin = forwardRef(
  ({ roleId }: RoleUserAdminInterface, ref) => {
    const componentFileName: string = "RoleAssignUsersAdmin.tsx";

    //Functions called form parent RolesContainerInsertUpdate
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "RoleAssignUsersAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [filterIsAssignedLabel, setFilterIsAssignedLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    //TODO check if has modal

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);
    const [searchPattern, setSearchPattern] = useState("");

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndOrganizationsRelationsFilter = async () => {
      //screen
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubTitleLabel"));
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );

      setFilterIsAssignedLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "FilterText"),
      );

      let newFilterKeyValue: Array<TPKeyValue> = [];

      newFilterKeyValue.push({
        key: TPAssignedOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPAssignedOptions.ASSIGNED.toString(),
        value: await TPI18N.GetText(resourceSet, "Assigned"),
      });
      newFilterKeyValue.push({
        key: TPAssignedOptions.NOTASSIGNED.toString(),
        value: await TPI18N.GetText(resourceSet, "Not assigned"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    //grid columns
    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //User login
        newColumns.push({
          name: "User login XX",
          cell: (row: { [x: string]: any }) => {
            return row["user"]["login"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["login"],
          sortable: true,
        });

        //User name
        newColumns.push({
          name: "User login XX",
          cell: (row: { [x: string]: any }) => {
            return row["user"]["name"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["name"],
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get permissions by Filter
    const reloadDataGrid = async (selectedFilter?: string) => {
      let serviceClient = new RoleUserService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getRoleUserByRoleId(
          roleId,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        let filteredResponse: RoleUserViewModel[];

        if (selectedFilter === "0") {
          //assigned
          filteredResponse = responseRequest.filter(
            (rolePermission) => rolePermission.user.roleUserAssigned === true,
          );
          return [...filteredResponse];
        }

        if (selectedFilter === "1") {
          //unassigned
          filteredResponse = responseRequest.filter(
            (rolePermission) => rolePermission.user.roleUserAssigned === false,
          );
          return [...filteredResponse];
        }

        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const handleRowClick = async (row: any, event: any) => {
      //only one row selected policy

      let i: number;
      let isChecked: boolean = false;
      let newGridData: Array<any> = [...adminState.gridData];
      for (i = 0; i <= newGridData.length - 1; i++) {
        if (row.user.userGuid === newGridData[i].user.userGuid) {
          isChecked = newGridData[i].tpselectedRow;
        }
      }

      if (isChecked) {
        const removeUserResponse: any = await removeUser(row.user.userGuid);

        if (removeUserResponse) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      } else {
        const recordInputDTO: any = {
          roleId: roleId,
          guid_USER: row.user.userGuid,
        };

        if (await assignUser(recordInputDTO)) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      }

      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: newGridData },
      };
      dispatchCommand(command1);
    };

    const removeUser = async (guidUser: string) => {
      const expectedCodes: Array<number> = [200];
      const serviceClient = new RoleUserService();
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.deleteUser(
          roleId,
          guidUser,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return responseRequest?.responseData?.responseCode !== 500 || "";
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} removePermission ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} removePermission ex`);
        setIsLoadingScreen(false);
      }
    };

    const assignUser = async (inputDTO: any) => {
      const expectedCodes: Array<number> = [200];
      const serviceClient = new RoleUserService();
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.assignUser(
          inputDTO,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return responseRequest?.responseData?.responseCode !== 500 || "";
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} removePermission ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} removePermission ex`);
      }
      setIsLoadingScreen(false);
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let newGridData: Array<any> = [...result];

          for (let i: number = 0; i <= newGridData.length - 1; i++) {
            newGridData[i].tpselectedRow = newGridData[i].user.roleUserAssigned;
          }
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    const onSearchPatternChange2 = (newValue: string) => {
      setSearchPattern(newValue);
    };

    const onColumnSort = (columnSelector: any, direction: string) => {
      let sortedGridData: Array<any>;
      sortedGridData = TPGlobal.datatableCustomSort(
        adminState.gridData,
        columnSelector,
        direction,
      );
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: sortedGridData },
      };
      dispatchCommand(command1);
    };

    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "login", "name"];
      let i: number;
      let search: string;
      search = searchPattern.trim();
      return adminState.gridData.filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany.user[searcheableColumns[i]] &&
            itemany.user[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.set_griddata:
          let newGridData: Array<any> = [...command.payload.newGridData];
          let newAdminState: AdminStateType = { ...prevState };
          newAdminState.gridData = newGridData;
          return newAdminState;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizationsRelationsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    return (
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <TPPageSubTitle>
              {subTitleLabel}
              {roleId}
            </TPPageSubTitle>
            <hr />
            <TPPageFirstRow>
              <TPPageActions>
                <TPButton
                  type={TPButtonTypes.icon}
                  onClick={() => handleRefreshClick()}
                  text={refreshLabel}
                  icon={TPIconTypes.refresh}
                />
              </TPPageActions>
              <TPFilterAndSearch>
                <TPPageFilterContainer>
                  <TPSelect
                    id="IdSelect"
                    onChange={handleFilterChange}
                    dataSource={filterKeyValue}
                    value={adminState.selectedFilter}
                    labelText={filterIsAssignedLabel}
                    isHorizontal={true}
                  ></TPSelect>
                </TPPageFilterContainer>
              </TPFilterAndSearch>
            </TPPageFirstRow>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TPFixedTable
              keyField={"tpguid"}
              columns={adminState.gridColumns}
              dataGrid={filteredData()}
              tableHeight={`${fixedHeaderScrollHeight}px`}
              onRowClicked={(row: any, event: any) => {
                handleRowClick(row, event);
              }}
              searchLabel={searchLabel}
              onSearchChange={onSearchPatternChange2}
              sortFunction={onColumnSort}
              highlightOnHover={true}
              selectableRows={true}
              multipleSelection={false}
            />
          </div>
        </div>
      </TPLoadingOverlay>
    );
  },
);

export default RolesAssignUsersAdmin;
