export class RecursiveRoutes {
    id: number = -1;
    folderName: string = "";
    localizedFolderName: string = "";
    folderCode: string = "";
    type: string = "";
    parent?: number = undefined;
    fileName: string = "";
    extension: string = "";
    dateUpload: string = "";
    userUpload: string = "";
    keywords: string = "";
    size: number = 0;
    dimensions: string = "";
    blobId: string = "";
    isActive: boolean = true;
    imageUrl: string = "";
    thumbnailUrl: string = "";
    folderNameLocalizedValues: LocalizedValues[] = new Array<LocalizedValues>();
    childrens: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
}

export class Response {
    responseCode: string = "";
    traceId: string = "";
    responseMessage: ResponseMessage = new ResponseMessage();
    data: Data[] = new Array<Data>();
}

export class ResponseMessage {
    code: string = "";
    message: string = "";
}

export class Data {
    id: number = 0;
    folderName: string = "";
    folderCode: string = "";
    localizedFolderName: string = "";
    fileName: string = "";
    type: string = "";
    parent?: number = 0;
    extension: string = "";
    dateUpload: string = "";
    guidUserUpload: string = "";
    keywords: string = "";
    size: number = 0;
    dimensions: string = "";
    blobId: string = "";
    isActive: boolean = true;
    imageUrl?: string = "";
    thumbnailUrl?: string = "";
    folderNameLocalizedValues: LocalizedValuesFileAndFolder[] =
        new Array<LocalizedValuesFileAndFolder>();
}

export class LocalizedValues {
    languageId: string = "es";
    localizedValue: string = "System";
}

export class InserFolder {
    folderName: string = "es";
    parent?: number = 0;
    keywords: string = "";
    isActive: boolean = false;
    guidUserUpload: string = "";
    folderNameLocalizedValues: LocalizedValues[] = new Array<LocalizedValues>();
}

export class InserFolderAndFile {
    id: number = 0;
    folderName: string = "";
    folderCode: string = "";
    fileName: string = "";
    parent?: number = 0;
    keywords: string = "";
    isActive: boolean = false;
    guidUserUpload: string = "";
    folderNameLocalizedValues: LocalizedValues[] =
        new Array<LocalizedValues>();
}

export class LocalizedValuesFileAndFolder {
    languageId: string = "es";
    localizedValue: string = "System";
    order: number = 0;
}
