import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type CustomerTypeInputDTO = {
  id: string;
  description: string;
  customerFormId: string;
  isActive: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  imageId: string;
  otherLocalizedValues: Array<RecordLocalizedModel>;
};

export class CustomerTypeInputDTOValidator extends Validator<CustomerTypeInputDTO> {
  constructor() {
    super();

    const resourceSet = "CustomerTypeInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((description) => {
          return TPGlobal.TPSanitize(description) == description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 500;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("customerFormId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCustomerFormIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCustomerFormIdLength");

      this.ruleFor("imageId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOImageIdEmpty")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidImageIdLength");
    } catch (error) {}
  }
}
