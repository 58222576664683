import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type CaseCustomStatesInputDTO = {
  id: string;
  description: string;
  imageId: string;
  customStateTypeBranchId: string;
  order: number;
  otherLocalizedValues: Array<RecordLocalizedModel>;
};

export class CaseCustomStateInputDTOValidator extends Validator<CaseCustomStatesInputDTO> {
  constructor() {
    super();

    const resourceSet = "CaseCustomStatesInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((description) => {
          return TPGlobal.TPSanitize(description) == description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 100;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("imageId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOimageIdEmpty")
        .must((imageId) => {
          return TPGlobal.TPSanitize(imageId) == imageId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidimageId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidimageIdLength");

      this.ruleFor("order")
        .must((x) => {
          return x.toString().trim() !== "";
        })
        .withMessage(resourceSet + "|InputDTOInvalidOrder")
        .must((x) => {
          return !isNaN(+x);
        })
        .withMessage(resourceSet + "|InputDTOOrderNumeric")
        .must((x) => {
          return x.toString().length <= 20;
        })
        .withMessage(resourceSet + "|InputDTOInvalidOrderLength")
        .must((x) => {
          return x >= 0;
        })
        .withMessage(resourceSet + "|InputDTOInvalidOrderNegative");

      this.ruleFor("customStateTypeBranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypeEmpty")
        .must((description) => {
          return TPGlobal.TPSanitize(description) == description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidType")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidTypeLength");
    } catch (error) {}
  }
}
