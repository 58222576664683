const DefaultAvatarIcon = ({ ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50 0C43.4339 0 36.9321 1.29329 30.8658 3.80602C24.7995 6.31876 19.2876 10.0017 14.6447 14.6447C5.26784 24.0215 0 36.7392 0 50C0 63.2608 5.26784 75.9785 14.6447 85.3553C19.2876 89.9983 24.7995 93.6812 30.8658 96.194C36.9321 98.7067 43.4339 100 50 100C63.2608 100 75.9785 94.7322 85.3553 85.3553C94.7322 75.9785 100 63.2608 100 50C100 43.4339 98.7067 36.9321 96.194 30.8658C93.6812 24.7995 89.9983 19.2876 85.3553 14.6447C80.7124 10.0017 75.2005 6.31876 69.1342 3.80602C63.0679 1.29329 56.5661 0 50 0ZM25.35 81.4C27.5 76.9 40.6 72.5 50 72.5C59.4 72.5 72.5 76.9 74.65 81.4C67.6469 86.9801 58.9544 90.0128 50 90C40.7 90 32.15 86.8 25.35 81.4ZM81.8 74.15C74.65 65.45 57.3 62.5 50 62.5C42.7 62.5 25.35 65.45 18.2 74.15C12.8809 67.2231 9.99833 58.7335 10 50C10 27.95 27.95 10 50 10C72.05 10 90 27.95 90 50C90 59.1 86.9 67.5 81.8 74.15ZM50 20C40.3 20 32.5 27.8 32.5 37.5C32.5 47.2 40.3 55 50 55C59.7 55 67.5 47.2 67.5 37.5C67.5 27.8 59.7 20 50 20ZM50 45C48.0109 45 46.1032 44.2098 44.6967 42.8033C43.2902 41.3968 42.5 39.4891 42.5 37.5C42.5 35.5109 43.2902 33.6032 44.6967 32.1967C46.1032 30.7902 48.0109 30 50 30C51.9891 30 53.8968 30.7902 55.3033 32.1967C56.7098 33.6032 57.5 35.5109 57.5 37.5C57.5 39.4891 56.7098 41.3968 55.3033 42.8033C53.8968 44.2098 51.9891 45 50 45Z" fill="#5D5D5D"/>
                </svg>
                `,
      }}
    ></div>
  );
};

export default DefaultAvatarIcon;
