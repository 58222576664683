import TPGlobal from "../helpers/TPGlobal";
import { TPHTTPService } from "../helpers/TPHttpService";
import { TPKeyValue } from "../helpers/TPKeyValue";
import { TPLog, TPLogType } from "../helpers/TPLog";
import { HTTPResponse } from "../helpers/TPStandardResponse";
import { inputDashboardModel } from "../models/CaseCreationStats/CaseCreationStats";
import { EmailData, SearchIncomingMailModelInput } from "../models/SearchIncomingMail/SearchIncomingMail";



export class SearchIncomingMailAdminService {

    public async getFilter(      
        data: SearchIncomingMailModelInput,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<EmailData>> {
        let serviceFileName = "SearchIncomingMailAdminService.ts";

        let httpclient = new TPHTTPService();
        let url: string;
        url = "/inboundmailboxread/incoming-mail"      
        
      
        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.postData(
                finalURL,
                data,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
          
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} getUsersByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getUsersByFilter`);
            throw new Error(`Error ${serviceFileName} getUsersByFilter`);
        }
    }


}
