import TPGlobal from "@/helpers/TPGlobal";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { CalendarHeaderStyle, ContainerCalendarStyle } from "./StyeMyReminders";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "./MyRemindersSlice";
import { useDispatch } from "react-redux";
import { EventContentArg, EventSourceInput } from "@fullcalendar/core";
import "./MyRemindersStyles.css";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";

interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

const MyRemindersCalendar = forwardRef(
    ({ callBackCommands, active }: InputModel, ref) => {
        const { reminders, remindersLoaded: contentLoaded } = useSelector(
            (s: StoreModel) => s[MyRemindersSlice.name]
        ) as MyRemindersSliceModel;
    const dispatch = useDispatch();
        const casecontext: any = useContext(TPCaseViewerContext);

    const renderReminder = function (event: EventContentArg) {
        const fixedDate = new Date(event.event.start?.toISOString() || "");
        fixedDate.setUTCHours(fixedDate.getHours());
        fixedDate.setUTCMinutes(fixedDate.getMinutes());
        return (
            <div className="calendar-reminder">
                <button
                    type="button"
                    className="reminder-case-id-button"
                    onClick={() => casecontext.handleAddNewCaseViewerCallBack(event.event.id)}
                >
                    <b>{event.event.id}</b>
                </button>
                <label>{event.event.title}</label>
                <i>{event.event.start ? fixedDate.toLocaleString() : "???"}</i>
            </div>
        );
    };

    return (
        <>
            {active && (
                <CalendarHeaderStyle>
                    <FullCalendar
                        initialView="dayGridMonth"
                        buttonText={{
                            prevYear: "<<",
                            prev: "<",
                            next: ">",
                            nextYear: ">>",
                            today: "Hoy",
                        }}
                        initialDate={new Date()}
                        themeSystem="Journal"
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                        ]}
                        events={
                            reminders.map((r) => {
                                return {
                                    id: r.caseId,
                                    title: r.comments,
                                    date: r.date,
                                };
                            }) as EventSourceInput
                        }
                        locale={TPGlobal.language}
                        locales={allLocales}
                        headerToolbar={{
                            left: "prevYear,prev,next,nextYear today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        stickyHeaderDates={false}
                        eventContent={renderReminder}
                    />
                </CalendarHeaderStyle>
            )}
        </>
    );

    // return (
    //     <>
    //         {active &&
    //             <ContainerCalendarStyle>
    //                 <CalendarHeaderStyle id="reminders-calendar-container">
    //                     <FullCalendar
    //                         initialDate={new Date()}
    //                         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
    //                         initialView="dayGridMonth"
    //                         headerToolbar={{
    //                             left: 'prevYear,prev,next,nextYear today',
    //                             center: 'title',
    //                             right: 'dayGridMonth,timeGridWeek,timeGridDay'
    //                         }}
    //                         buttonText={{
    //                             prevYear: '<<',
    //                             prev: '<',
    //                             next: '>',
    //                             nextYear: '>>',
    //                             today: 'Hoy',
    //                         }}
    //                         editable={true}
    //                         selectable={true}
    //                         events={reminders.map(r => {
    //                             return {
    //                                 id: r.caseId,
    //                                 title: r.comments,
    //                                 date: r.date
    //                             }
    //                         }) as EventSourceInput}
    //                         eventContent={(eventInfo) => (
    //                             <div className="tooltip-container">
    //                                 {eventInfo.event.title}
    //                                 <div className="my-tooltip" >
    //                                     {eventInfo.event.id}
    //                                 </div>
    //                             </div>
    //                         )}
    //                         eventClick={(info: any) => { }}
    //                         dateClick={(info: any) => { }}
    //                         stickyHeaderDates={false}
    //                         locale={TPGlobal.language}
    //                         locales={allLocales}
    //                     />
    //                 </CalendarHeaderStyle>
    //             </ContainerCalendarStyle>}
    //         </>
    //     );
    }
);

export default MyRemindersCalendar;
