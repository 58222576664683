import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { AdditionalDataCompareInputDTO } from "@/models/AdditionalData/AdditionalDataInputDTO";
import { CalculateTaskResponsibleInputDTO } from "@/models/Task/CalculateTaskResponsibleInputDTO";
import { CalculateTaskResponsibleViewModel } from "@/models/Task/CalculateTaskResponsibleModel";
import { ReassignTaskInputDTO } from "@/models/Task/ReassignTaskInputDTO";
import { AddTaskInputDTO } from "@/models/Task/TaskInputDTO";
import { AdditionalDataCompareViewModel, TaskViewModel } from "@/models/Task/TaskModels";
import { TaskRejectInputDTO } from "@/models/Task/TaskRejectInputDTO";
import {
  TaskAutomaticInputDTO,
  TaskReportInputDTO,
  TaskSendMailReportInputDTO,
  UpdateTaskStatusInputDTO,
} from "@/models/Task/TaskReportInputDTO";

export class TaskService {
  serviceFileName: string = "TaskService.ts";

  public async getByCaseAndFilterIsWorkFlowTask(
    caseId: number,
    filterIsWorkflowTask: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TaskViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/task/workflow?caseId=${caseId}&filterIsWorkflowTask=${filterIsWorkflowTask}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByCaseAndFilterIsWorkFlowTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getByCaseAndFilterIsWorkFlowTask`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getByCaseAndFilterIsWorkFlowTask`,
      );
    }
  }

  public async AddTask(
    inputDTO: AddTaskInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/add-task";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} AddTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} AddTask`);
      throw new Error(`Error ${this.serviceFileName} AddTask`);
    }
  }

  public async CalculateTaskResponsible(
    inputDTO: CalculateTaskResponsibleInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CalculateTaskResponsibleViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/calculate-task-responsible";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} CalculateTaskResponsible ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} CalculateTaskResponsible`);
      throw new Error(`Error ${this.serviceFileName} CalculateTaskResponsible`);
    }
  }

  //check if task was already created by workflow engine
  //using taskGuid
  public async checkCaseById(
    taskGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<boolean> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/task/check/${taskGuid}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} checkCaseById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} checkCaseById`);
      throw new Error(`Error ${this.serviceFileName} checkCaseById`);
    }
  }

  public async UpdateTaskReport(
    inputDTO: TaskReportInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/update-task-report";

    // Use RPC method
    inputDTO.useRPCMethod = true;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} UpdateTaskReport ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} UpdateTaskReport`);
      throw new Error(`Error ${this.serviceFileName} UpdateTaskReport`);
    }
  }

  public async UpdateSendMailTaskReport(
    inputDTO: TaskSendMailReportInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/update-email-task-report";

    // Use RPC method
    inputDTO.useRPCMethod = true;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} UpdateTaskReport ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} UpdateTaskReport`);
      throw new Error(`Error ${this.serviceFileName} UpdateTaskReport`);
    }
  }

  public async getTaskById(
    taskId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TaskViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/task/${taskId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTaskById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTaskById`);
      throw new Error(`Error ${this.serviceFileName} getTaskById`);
    }
  }

  public async previewAdditonalDataDifferences(
    inputDTO: AdditionalDataCompareInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataCompareViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldata/compare-additional-data-values`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
      // let fakeData: Array<SaveTaskAdditionalDataDifferences> = [];
      // fakeData.push({
      //   idAddata: "juani1",
      //   currentValue: "sn345",
      //   newValue: "sn456",
      //   source: "Case",
      //   sourceValue: 75320,
      //   localizedDescription_ADDA: "Numero serial",
      // });
      // fakeData.push({
      //   idAddata: "compesationvalue",
      //   currentValue: "1600",
      //   newValue: "1700",
      //   source: "Task",
      //   sourceValue: 79800,
      //   localizedDescription_ADDA: "Compensation value",
      // });
      // fakeData.push({
      //   idAddata: "compesationvalue",
      //   currentValue: "1400",
      //   newValue: "1700",
      //   source: "Task",
      //   sourceValue: 79900,
      //   localizedDescription_ADDA: "Compensation value",
      // });
      // return fakeData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} previewAdditonalDataDifferences ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} previewAdditonalDataDifferences`,
      );
      throw new Error(
        `Error ${this.serviceFileName} previewAdditonalDataDifferences`,
      );
    }
  }

  public async reassignTaskResponsible(
    inputDTO: ReassignTaskInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/reassign-task";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignTaskResponsible ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reassignTaskResponsible`);
      throw new Error(`Error ${this.serviceFileName} reassignTaskResponsible`);
    }
  }

  public async rejectTask(
    inputDTO: TaskRejectInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/reject-task";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} rejectTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} rejectTask`);
      throw new Error(`Error ${this.serviceFileName} rejectTask`);
    }
  }

  public async getTemplateLanguages(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<string>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/emailtemplate/languages";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTemplateLanguages ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTemplateLanguages`);
      throw new Error(`Error ${this.serviceFileName} getTemplateLanguages`);
    }
  }

  public async getInboundMailboxReadByCaseId(
    caseId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxread/by-case-id?caseId=${caseId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxReadByCaseId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailboxReadByCaseId`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailboxReadByCaseId`,
      );
    }
  }

  public async getInboundMailboxReadById(
    mailboxId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxread/${mailboxId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxReadById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getInboundMailboxReadById`);
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailboxReadById`,
      );
    }
  }

  public async AutomaticTask(
    inputDTO: TaskAutomaticInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/automatic-task";
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} AutomaticTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} AutomaticTask`);
      throw new Error(`Error ${this.serviceFileName} AutomaticTask`);
    }
  }

  public async UpdateTaskGroupsAndTypist(
    inputDTO: UpdateTaskStatusInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/task/update-task-typist";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} UpdateTaskGroupsAndTypist ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} UpdateTaskGroupsAndTypist`);
      throw new Error(
        `Error ${this.serviceFileName} UpdateTaskGroupsAndTypist`,
      );
    }
  }

  public async renderEmailTemplate(
    templateData: any, // Assuming templateData is still needed
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/emailtemplate/render-template`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        templateData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} renderEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} renderEmailTemplate`);
      throw new Error(`Error ${this.serviceFileName} renderEmailTemplate`);
    }
  }

  public async getInboundMailboxReadByTypeAndCaseId(
    caseID: any,
    templateData: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxread/by-type-case-id?type=NEWCASE&caseId=${caseID}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        templateData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} Inbound Mail Box Read ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
      throw new Error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
    }
  }
  public async createCaseTimer(
    templateData: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/casestimer`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        templateData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} Create Case Timer ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} casesTimer`);
      throw new Error(`Error ${this.serviceFileName} casesTimer`);
    }
  }

  public async massiveUpdateCaseTimer(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/casestimer/massive";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} massive update timer ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} massive update timer`);
      throw new Error(`Error ${this.serviceFileName} massive update timer`);
    }
  }

  public async getHandleTime(caseId: number): Promise<number> {
    return new Promise((resolve) => {
      const savedTime = localStorage.getItem(`handleTime-${caseId}`);
      resolve(savedTime ? parseInt(savedTime, 10) : 0);
    });
  }

  public async saveHandleTime(caseId: number, time: number): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(`handleTime-${caseId}`, time.toString());
      resolve();
    });
  }

  public async translateOutbound(
    from: string,
    languageCode: string,
    content: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/storyfai/translate`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        {
          from: from,
          to: languageCode,
          content: content,
        },
        [200],
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return Object.values(response.responseData.data);
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} translateOutbound ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} translateOutbound`);
      throw new Error(`Error ${this.serviceFileName} translateOutbound`);
    }
  }

  public async trackOutbound(trackingData: any): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/storyfai/tracking`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        trackingData,
        [200],
        false,
        false,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} trackOutbound ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} trackOutbound`);
      throw new Error(`Error ${this.serviceFileName} trackOutbound`);
    }
    }




    public async getsentimentAnalysis(
        text: string,    
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/storyfai/sentiment-analysis`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.postData(
                finalURL,
                { text: text },
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
           
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} Inbound Mail Box Read ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
            throw new Error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
        }
    }


    public async getSentimentAnalysisByTaskId(
        idCase: number,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/inboundmailboxreadsentiment/tasks/${idCase}`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,                
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );

            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} Inbound Mail Box Read ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
            throw new Error(`Error ${this.serviceFileName} Inbound Mail Box Read`);
        }
    }


}
