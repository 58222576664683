import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  TableContainer,
  DataTableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPFilterAndSearch,
  TPPageAcceptCancelButtonsContainer,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPWizard from "@/components/TPWizard/TPWizard";
import TPWizardContent from "@/components/TPWizard/TPWizardContent";
import TPWizardStep from "@/components/TPWizard/TPWizardStep";
import { downloadCSV } from "@/helpers/TPFunctions";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  TPButtonTypes,
  TPIconTypes,
  TPQuickSelectDatesEnum,
  ModalSizeEnum,
} from "@/models/Global/TPGlobalEnums";
import { MailViewModel } from "@/models/Mail/MailModels";
import { TPI18N } from "@/services/I18nService";
import { MailService } from "@/services/MailService";
import { TimeZonesService } from "@/services/TimeZonesService";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import questionImg from "@/assets/images/question.png";
import { MailUpdateStatusAndRetryMassiveInputDTO } from "@/models/Mail/MailUpdateStatusAndRetryMassiveInputDTO";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import TPModalQuestion from "@/layouts/ModalQuestion/TPModalQuestion";

interface OutboundMailAccountUnsentMailAdminInterface {
  idAccount: string;
}

type resultMailsProcessing = {
  message: string;
  selectedRows: number;
  recordsProcessed: number;
  unprocessedRecords: number;
  isShownResultModal: boolean;
};

const OutboundMailAccountUnsentMailAdmin = ({
  idAccount,
}: OutboundMailAccountUnsentMailAdminInterface) => {
  const componentFileName: string = "OutboundMailAccountUnsentMailAdmin.tsx";
  const resourceSet: string = "OutboundMailAccountUnsentMailAdminComponent";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //#region Resources
  const [pageTitleLabel, setPageTitleLabel] = useState("");
  const [filterLabel, setFilterLabel] = useState("");
  const [quickSelectDatesLabel, setQuickSelectDatesLabel] = useState("");
  const [todayLabel, setTodayLabel] = useState("");
  const [yesterdayLabel, setYesterdayLabel] = useState("");
  const [currentWeekLabel, setCurrentWeekLabel] = useState("");
  const [lastWeekLabel, setLastWeekLabel] = useState("");
  const [currentMonthLabel, setCurrentMonthLabel] = useState("");
  const [lastMonthLabel, setLastMonthLabel] = useState("");
  const [currentYearLabel, setCurrentYearLabel] = useState("");
  const [lastTwelveMonthsLabel, setLastTwelveMonthsLabel] = useState("");
  const [startDateLabel, setStartDateLabel] = useState("");
  const [endDateLabel, setEndDateLabel] = useState("");
  const [moreFiltersLabel, setMoreFiltersLabel] = useState("");
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const [resultsLabel, setResultsLabel] = useState("");
  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [searchLabel, setSearchLabel] = useState("");
  const [textViewTitle, setTextViewTitle] = useState("");
  const [titleQuestion, setTitleQuestion] = useState("");
  const [questionLabel, setQuestionLabel] = useState("");
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [selectedRowsLabel, setSelectedRowsLabel] = useState("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
  const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] = useState("");
  const [okButtonLabel, setOkButtonLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  //grid columns
  const [idColumnLabel, setIdColumnLabel] = useState("");
  const [toColumnLabel, setToColumnLabel] = useState("");
  const [ccColumnLabel, setCcColumnLabel] = useState("");
  const [bccColumnLabel, setbccColumnLabel] = useState("");
  const [subjectColumnLabel, setSubjectColumnLabel] = useState("");
  const [bodyColumnLabel, setBodyColumnLabel] = useState("");
  const [statusColumnLabel, setStatusColumnLabel] = useState("");
  const [currentTryColumnLabel, setCurrentTryColumnLabel] = useState("");
  const [lastTryDateColumnLabel, setLastTryDateColumnLabel] = useState("");
  const [insertDateColumnLabel, setInsertDateColumnLabel] = useState("");
  const [applyLabel, setApplyLabel] = useState("");
  const [resetTriesLabel, setResetTriesLabel] = useState("");
  //#endregion

  //#region simple state
  const [quickSelectDates, setQuickSelectDates] = useState(
    TPQuickSelectDatesEnum.today,
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [timezone, setTimezone] = useState(TPGlobal.currentUserTimeZoneId);
  const [timeZoneErrorMessage, setTimeZoneErrorMessage] = useState("");
  const [startDateErrorMessage, setStartDateErrorMessage] = useState("");
  const [endDateErrorMessage, setEndDateErrorMessage] = useState("");
  const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
  const [gridColumns, setGridColumns] = useState<Array<any>>([]);
  const [gridData, setGridData] = useState<Array<MailViewModel>>([]);
  const [searchPattern, setSearchPattern] = useState<string>("");
  const [actionsList, setActionList] = useState<Array<TPKeyValue>>([]);
  const [actionApplySelected, setActionApplySelected] = useState<number>();
  const [actionApplyErrorMessage, setActionApplyErrorMessage] = useState("");
  const [resetTries, setResetTries] = useState(false);
  const [textCompleteToShow, setTextCompleteToShow] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [isShownQuestion, setIsShownQuestion] = useState(false);
  const [emailList, setEmailList] = useState<Array<number>>([]);
  const [emailListErrorMessage, setEmailListErrorMessage] = useState("");
  const resultMailsProcessingInitialState: resultMailsProcessing = {
    message: "",
    selectedRows: 0,
    recordsProcessed: 0,
    unprocessedRecords: 0,
    isShownResultModal: false,
  };
  const [resultMailsProcessingState, setResultMailsProcessingState] = useState(
    resultMailsProcessingInitialState,
  );
  //#endregion

  const quickSelectDatesValue = [
    {
      key: TPQuickSelectDatesEnum.today,
      value: todayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.yesterday,
      value: yesterdayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentWeek,
      value: currentWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastWeek,
      value: lastWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentMonth,
      value: currentMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastMonth,
      value: lastMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentYear,
      value: currentYearLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastTwelveMonths,
      value: lastTwelveMonthsLabel,
    },
  ];

  const loadResources = async () => {
    setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));
    setFilterLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "FilterLabel"),
    );
    setQuickSelectDatesLabel(
      await TPI18N.GetText(resourceSet, "QuickSelectDatesLabel"),
    );
    setTodayLabel(await TPI18N.GetText(resourceSet, "TodayLabel"));
    setYesterdayLabel(await TPI18N.GetText(resourceSet, "YesterdayLabel"));
    setCurrentWeekLabel(await TPI18N.GetText(resourceSet, "CurrentWeekLabel"));
    setLastWeekLabel(await TPI18N.GetText(resourceSet, "LastWeekLabel"));
    setCurrentMonthLabel(
      await TPI18N.GetText(resourceSet, "CurrentMonthLabel"),
    );
    setLastMonthLabel(await TPI18N.GetText(resourceSet, "LastMonthLabel"));
    setCurrentYearLabel(await TPI18N.GetText(resourceSet, "CurrentYearLabel"));
    setLastTwelveMonthsLabel(
      await TPI18N.GetText(resourceSet, "LastTwelveMonthsLabel"),
    );
    setStartDateLabel(await TPI18N.GetText(resourceSet, "StartDateLabel"));
    setEndDateLabel(await TPI18N.GetText(resourceSet, "EndDateLabel"));
    setMoreFiltersLabel(await TPI18N.GetText(resourceSet, "MoreFiltersLabel"));
    setTimezoneLabel(await TPI18N.GetText(resourceSet, "TimezoneLabel"));

    setResultsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ResultsLabel"),
    );
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DataTableNoCurrentData",
      ),
    );
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
    );
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
    );
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setTextViewTitle(await TPI18N.GetText(resourceSet, "TextViewTitle"));
    setTitleQuestion(await TPI18N.GetText(resourceSet, "TitleQuestion"));

    setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
    setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
    setResetTriesLabel(await TPI18N.GetText(resourceSet, "ResetTriesLabel"));
    setQuestionLabel(await TPI18N.GetText(resourceSet, "QuestionLabel"));
    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel"),
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel"),
    );
    setSelectedRowsLabel(
      await TPI18N.GetText(resourceSet, "SelectedRowsLabel"),
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(resourceSet, "RecordsProcessedLabel"),
    );
    setRecordsUnprocessedLabel(
      await TPI18N.GetText(resourceSet, "RecordsUnprocessedLabel"),
    );
    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    //grid columns
    setIdColumnLabel(await TPI18N.GetText(resourceSet, "IdColumnLabel"));
    setToColumnLabel(await TPI18N.GetText(resourceSet, "ToColumnLabel"));
    setCcColumnLabel(await TPI18N.GetText(resourceSet, "CcColumnLabel"));
    setbccColumnLabel(await TPI18N.GetText(resourceSet, "BccColumnLabel"));
    setSubjectColumnLabel(
      await TPI18N.GetText(resourceSet, "SubjectColumnLabel"),
    );
    setBodyColumnLabel(await TPI18N.GetText(resourceSet, "BodyColumnLabel"));
    setStatusColumnLabel(
      await TPI18N.GetText(resourceSet, "StatusColumnLabel"),
    );
    setCurrentTryColumnLabel(
      await TPI18N.GetText(resourceSet, "CurrentTryColumnLabel"),
    );
    setLastTryDateColumnLabel(
      await TPI18N.GetText(resourceSet, "LastTryDateColumnLabel"),
    );
    setInsertDateColumnLabel(
      await TPI18N.GetText(resourceSet, "InsertDateColumnLabel"),
    );

    const newActionsList = [...actionsList];
    newActionsList.push({ key: "", value: "--" });
    newActionsList.push({
      key: "1",
      value: await TPI18N.GetText(resourceSet, "ChangeStatusToPendingLabel"),
    });
    newActionsList.push({
      key: "2",
      value: await TPI18N.GetText(resourceSet, "ChangeStatusToRepeatedLabel"),
    });
    setActionList(newActionsList);
    await getTimezones();
    setIsLoadingScreen(false);
  };

  const handleTextViewClick = (text: string) => {
    setTextCompleteToShow(text);
    setIsShown(true);
  };

  const setupGridColumns = () => {
    try {
      let newGridColumnsState: Array<any>;
      newGridColumnsState = [...gridColumns];
      let newColumns: Array<any> = [];

      //id
      newColumns.push({
        name: idColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["id"];
        },
        selector: (row: { [x: string]: any }) => row["id"],
        sortable: true,
      });
      //to
      newColumns.push({
        name: toColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["to"];
        },
        selector: (row: { [x: string]: any }) => row["to"],
        sortable: true,
      });

      //cc
      newColumns.push({
        name: ccColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["cc"];
        },
        selector: (row: { [x: string]: any }) => row["cc"],
        sortable: true,
      });
      //bcc
      newColumns.push({
        name: bccColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["bcc"];
        },
        selector: (row: { [x: string]: any }) => row["bcc"],
        sortable: true,
      });
      //subject
      newColumns.push({
        name: subjectColumnLabel,
        cell: (row: { [x: string]: any }) => {
          let tmpSubject: string;
          if (row["subject"] && row["subject"].toString().length > 40) {
            tmpSubject = `${row["subject"].toString().substring(0, 40)}...`;
            return (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: tmpSubject,
                  }}
                ></span>
                <TPIcon
                  iconType={TPIconTypes.zoomIn}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => handleTextViewClick(row["subject"])}
                />
              </>
            );
          } else {
            tmpSubject = row["subject"].toString();
            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: tmpSubject,
                }}
              ></span>
            );
          }
        },
        selector: (row: { [x: string]: any }) => row["subject"],
        sortable: true,
      });
      //body
      newColumns.push({
        name: bodyColumnLabel,
        cell: (row: { [x: string]: any }) => {
          let tmpBody: string;
          if (row["body"] && row["body"].toString().length > 40) {
            tmpBody = `${row["body"].toString().substring(0, 40)}...`;
            return (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: tmpBody,
                  }}
                ></span>
                <TPIcon
                  iconType={TPIconTypes.zoomIn}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => handleTextViewClick(row["body"])}
                />
              </>
            );
          } else {
            tmpBody = row["body"].toString();
            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: tmpBody,
                }}
              ></span>
            );
          }
        },
        selector: (row: { [x: string]: any }) => row["body"],
        sortable: true,
      });
      //status
      newColumns.push({
        name: statusColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["status"]; //TODO: Localize;
        },
        selector: (row: { [x: string]: any }) => row["status"],
        sortable: true,
      });
      //current tries
      newColumns.push({
        name: currentTryColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["currentTries"];
        },
        selector: (row: { [x: string]: any }) => row["currentTries"],
        sortable: true,
      });
      //last Try Date
      newColumns.push({
        name: lastTryDateColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["lastTryDateFormatted"];
        },
        selector: (row: { [x: string]: any }) => row["lastTryDateFormatted"],
        sortable: true,
      });
      //insert Date
      newColumns.push({
        name: insertDateColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["insertDateFormatted"];
        },
        selector: (row: { [x: string]: any }) => row["insertDateFormatted"],
        sortable: true,
      });
      newGridColumnsState = [...newColumns];
      setGridColumns(newGridColumnsState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
    }
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes,
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setTimeZonesToSelect(timezonesToSelectConverted);
  };

  const changeDateSelectorHandler = (value: any) => {
    setQuickSelectDates(value);
    setStartDateErrorMessage("");
    setEndDateErrorMessage("");
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth();
    const previousMonday = getPreviousMonday(today);
    const previousSunday = getPreviousSunday(today);
    switch (value) {
      case TPQuickSelectDatesEnum.today:
        setStartDate(today);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.yesterday:
        const yesterdayMilliseconds = today.getTime() - 24 * 60 * 60 * 1000;
        const yesterdayDate = new Date(yesterdayMilliseconds);
        setStartDate(yesterdayDate);
        setEndDate(yesterdayDate);
        break;
      case TPQuickSelectDatesEnum.currentWeek:
        setStartDate(previousMonday);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastWeek:
        const mondayLastWeek =
          previousSunday.getTime() - 6 * 24 * 60 * 60 * 1000;
        const mondayLastWeekDate = new Date(mondayLastWeek);
        setStartDate(mondayLastWeekDate);
        setEndDate(previousSunday);
        break;
      case TPQuickSelectDatesEnum.currentMonth:
        const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
        setStartDate(firstDayOfThisMonth);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastMonth:
        const firstDayPreviousMonth = new Date(thisYear, thisMonth - 1, 1);
        setStartDate(firstDayPreviousMonth);
        const lastDayPreviousMonth = new Date(thisYear, thisMonth, 0);
        setEndDate(lastDayPreviousMonth);
        break;
      case TPQuickSelectDatesEnum.currentYear:
        const firstDayOfThisYear = new Date(thisYear, 0, 1);
        setStartDate(firstDayOfThisYear);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastTwelveMonths:
        const twelveMonthsAgo = new Date(thisYear - 1, thisMonth, 1);
        setStartDate(twelveMonthsAgo);
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const getPreviousMonday = (date: any) => {
    const previousMonday = new Date();

    previousMonday.setDate(date.getDate() - date.getDay() + 1);

    return previousMonday;
  };

  const getPreviousSunday = (date: any) => {
    const previousSunday = new Date();

    previousSunday.setDate(date.getDate() - date.getDay());

    return previousSunday;
  };

  const getFirstDayOfTheMonth = (year: any, month: any) => {
    return new Date(year, month, 1);
  };

  const handleChangeStartDate = (date: Date) => {
    setStartDate(date);
    setStartDateErrorMessage("");
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDate(date);
    setEndDateErrorMessage("");
  };

  const handleSearchClick = async () => {
    //validations
    let errorControl: boolean = false;
    if (!timezone) {
      setTimeZoneErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTOTimezoneEmpty"),
      );
      errorControl = true;
    }
    if (!startDate) {
      setStartDateErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTOStartDateEmpty"),
      );
      errorControl = true;
    }
    if (!endDate) {
      setEndDateErrorMessage(
        await TPI18N.GetText(resourceSet, "InputDTOEndDateEmpty"),
      );
      errorControl = true;
    }
    if (errorControl) {
      return;
    }

    let tabcontent1 = document.getElementById("tabFiltersOMA");
    let tabcontent2 = document.getElementById("tabResultsOMA");

    tabcontent1?.classList.remove("active");
    tabcontent2?.classList.remove("active");

    tabcontent2?.classList.add("active");

    let li1 = document.getElementById("idtabFiltersOMA");
    let li2 = document.getElementById("idtabResultsOMA");

    li1?.classList.remove("active");
    li2?.classList.remove("active");
    li2?.classList.add("active");

    await reloadGrid();
  };

  const handleRowsPerPageChanged = (e: any) => {
    const recordSize = fixedHeaderScrollHeight / 10;
    const newRecordSize = recordSize * e;
    setFixedHeaderScrollHeight(newRecordSize);
  };

  //Handler to filter data inside data grid
  const handleSearchPatternChange = (newValue: string) => {
    setSearchPattern(newValue);
  };

  //Filtered data based on selected pattern on search box
  const filteredData = () => {
    let searcheableColumns: Array<string> = ["id", "to"];
    let i: number;
    let search: string;
    search = searchPattern.trim();
    return gridData.filter(function (item, index) {
      if (search == "" || search.length <= 2) {
        return item;
      }
      for (i = 0; i <= searcheableColumns.length - 1; i++) {
        let itemany: any;
        itemany = item;
        if (
          itemany[searcheableColumns[i]] &&
          itemany[searcheableColumns[i]]
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return item;
        }
      }
    });
  };

  //Refresh
  const handleRefreshClick = () => {
    reloadGrid();
  };

  const reloadGrid = async () => {
    let serviceClient = new MailService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getUnSentMails(
        idAccount,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        timezone,
        1, //TODO
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      setGridData([...responseRequest]);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadGrid ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} reloadGrid ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const handleChangeSelectedRow = ({ selectedRows }: any) => {
    setEmailListErrorMessage("");
    if (selectedRows.length === 0) {
      setEmailList([]);
    } else {
      let newEmailList: Array<number> = selectedRows.map(
        (item: any) => item.id,
      );
      setEmailList(newEmailList);
    }
  };

  const handleModalAction = async () => {
    //Validations
    let controlValidation: boolean = true;
    if (!actionApplySelected) {
      setActionApplyErrorMessage(
        await TPI18N.GetText(resourceSet, "actionInputDTOEmpty"),
      );
      controlValidation = false;
    }
    if (emailList.length === 0) {
      setEmailListErrorMessage(
        await TPI18N.GetText(resourceSet, "emailListInputDTOEmpty"),
      );
      controlValidation = false;
    }
    if (controlValidation) {
      setIsShownQuestion(true);
    }
  };

  const handleResetTriesChange = () => {
    setResetTries(!resetTries);
  };

  const handleCallBackAnswer = (status: boolean, data: any) => {
    setIsShown(false);
  };

  const handleCallBackAnswerQuestion = async (status: boolean, data: any) => {
    setIsShownQuestion(false);
    if (status) {
      let inputDTO: MailUpdateStatusAndRetryMassiveInputDTO = {
        mailsListToUpdate: emailList,
        status: actionApplySelected,
        resetTries: resetTries,
      };
      let serviceClient = new MailService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.updateStatusAndResetTriesMail(
          inputDTO,
          false,
          true,
          expectedCodes,
        );

        setIsLoadingScreen(false);
        if (responseRequest.responseResult) {
          let data: Array<any> = responseRequest.responseData.data;
          let unprocessedRecords: number = data.filter(
            (x: any) => !x.mailUpdated,
          ).length;
          let newResultMailsProcessingState = { ...resultMailsProcessingState };
          newResultMailsProcessingState.message =
            responseRequest.responseData.responseMessage.message;
          newResultMailsProcessingState.unprocessedRecords = unprocessedRecords;
          newResultMailsProcessingState.selectedRows = data.length;
          newResultMailsProcessingState.recordsProcessed =
            unprocessedRecords === 0
              ? data.length
              : data.length - unprocessedRecords;
          newResultMailsProcessingState.isShownResultModal = true;
          setResultMailsProcessingState(newResultMailsProcessingState);

          //callBackResult({ result: "OK", recordId: recordId });
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCallBackAnswerQuestion ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleCallBackAnswerQuestion ex`,
        );
        setIsLoadingScreen(false);
      }
    }
  };

  const handleModalSuccessCallBack = async () => {
    let newResultMailsProcessingState = { ...resultMailsProcessingState };
    newResultMailsProcessingState.isShownResultModal = false;
    setResultMailsProcessingState(newResultMailsProcessingState);
    await reloadGrid();
  };

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    if (idColumnLabel) {
      setupGridColumns();
    }
  }, [idColumnLabel]);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <TPModal
          key={"TPModalTextView"}
          modalState={{
            titleModal: textViewTitle,
            acceptLabel: "",
            cancelLabel: "",
            callBackAnswer: handleCallBackAnswer,
            callBackData: null,
            isShown: isShown,
            modalWidth: ModalSizeEnum.MODALXL,
            hideFooterButtons: true,
          }}
        >
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: textCompleteToShow,
              }}
            ></span>
          </p>
        </TPModal>
        <TPModalQuestion
            id="IdModalQuestion"
            title={titleQuestion}
            yesLabel={modalAcceptLabel}
            noLabel={modalCancelLabel}
            question={questionLabel}
            callBackAnswer={handleCallBackAnswerQuestion}
            callBackData={null}
            isShown={isShownQuestion}
        />
        <TPModalSuccess
          modalState={{
            id: "IdModalSuccess",
            isShown: resultMailsProcessingState.isShownResultModal,
            title: "",
            successMessage: resultMailsProcessingState.message,
            yesLabel: okButtonLabel,
            callBackFunction: handleModalSuccessCallBack,
          }}
        >
          <div>
            <div>
              {selectedRowsLabel} : {resultMailsProcessingState.selectedRows}
            </div>
            <div>
              {recordsProcessedLabel} :
              {resultMailsProcessingState.recordsProcessed}
            </div>
            <div>
              {recordsUnprocessedLabel} :
              {resultMailsProcessingState.unprocessedRecords}
            </div>
          </div>
        </TPModalSuccess>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{pageTitleLabel}</TPPageTitle>

            <TPPageSubTitle>{`${subTitleLabel}: ${idAccount}`}</TPPageSubTitle>

            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPWizard>
              <TPWizardStep
                isActive={true}
                id="tabFiltersOMA"
                labelText={filterLabel}
                numberText="1"
                name="TPWizardStep"
              />
              <TPWizardContent
                id="tabFiltersOMA"
                isActive={true}
                name="TPWizardContent"
              >
                <div className="row">
                  <div className="col">
                    <TPPageSectionTitle>
                      {quickSelectDatesLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <div className="row">
                  <div className="col-11">
                    <TPPageSection>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <TPRadioGroup
                              id="IdRadioGroup"
                              onChange={(e: any) =>
                                changeDateSelectorHandler(e.target.value)
                              }
                              value={quickSelectDates}
                              source={quickSelectDatesValue}
                            ></TPRadioGroup>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <TPDatePicker
                              selectedDate={startDate}
                              onChangeDate={(e: Date) =>
                                handleChangeStartDate(e)
                              }
                              labelText={startDateLabel}
                              isHorizontal={false}
                              isMandatory={true}
                              errorMessage={startDateErrorMessage}
                            ></TPDatePicker>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <TPDatePicker
                              selectedDate={endDate}
                              onChangeDate={(e: Date) => handleChangeEndDate(e)}
                              labelText={endDateLabel}
                              isHorizontal={false}
                              isMandatory={true}
                              errorMessage={endDateErrorMessage}
                            ></TPDatePicker>
                          </div>
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TPPageSectionTitle>{moreFiltersLabel}</TPPageSectionTitle>
                  </div>
                </div>
                <div className="row">
                  <div className="col-11">
                    <TPPageSection>
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <TPSelect
                                  id="IdSelect"
                                  labelText={timezoneLabel}
                                  isMandatory={true}
                                  value={timezone}
                                  onChange={(e: any) => {
                                    setTimezone(e.target.value);
                                    setTimeZoneErrorMessage("");
                                  }}
                                  dataSource={timezonesToSelect}
                                  errorMessage={timeZoneErrorMessage}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-11">
                    <TPPageAcceptCancelButtonsContainer>
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.primary}
                        onClick={handleSearchClick}
                      >
                        {searchLabel}
                      </TPButton>
                    </TPPageAcceptCancelButtonsContainer>
                  </div>
                </div>
              </TPWizardContent>

              <TPWizardStep
                isActive={false}
                id="tabResultsOMA"
                labelText={resultsLabel}
                numberText="2"
                name="TPWizardStep"
              />

              <TPWizardContent
                id="tabResultsOMA"
                isActive={false}
                name="TPWizardContent"
              >
                <div className="row">
                  <div
                    className="col d-flex gap-3 justify-content-start"
                    style={{ alignItems: "flex-start" }}
                  >
                    <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      labelText={actionLabel}
                      onChange={(e: any) => {
                        setActionApplySelected(e.target.value);
                        setActionApplyErrorMessage("");
                      }}
                      dataSource={actionsList}
                      value={actionApplySelected}
                      errorMessage={actionApplyErrorMessage}
                    ></TPSelect>
                    <div 
                      style={{
                        margin: `20px 0 0`,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <TPCheckBox
                        id="IdCheckBox"
                        labelText={resetTriesLabel}
                        onChange={handleResetTriesChange}
                        checked={resetTries}
                      />
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.primary}
                        onClick={handleModalAction}
                      >
                        {applyLabel}
                      </TPButton>
                    </div>
                  </div>
                </div>
                {emailListErrorMessage && (
                  <div className="row mt-4">
                    <div className="col">
                      <div style={{ color: "#dc3545" }}>
                        {emailListErrorMessage}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col">
                    <TPPageFirstRow>
                      <TPPageActions>
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          onClick={() => handleRefreshClick()}
                          text={refreshLabel}
                          icon={TPIconTypes.refresh}
                        />
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          onClick={() =>
                            downloadCSV(filteredData(), filteredData)
                          }
                          text={exportLabel}
                          icon={TPIconTypes.fileDownload}
                        />
                      </TPPageActions>
                      <TPFilterAndSearch>
                        <TPPageSearchContainer>
                          <TPTextBox
                            id="IdTextBox"
                            icon={TPIconTypes.search}
                            withIcon={true}
                            value={searchPattern}
                            placeholder={searchLabel}
                            onChange={(e: any) =>
                              handleSearchPatternChange(e.target.value)
                            }
                            isHorizontal={true}
                          />
                        </TPPageSearchContainer>
                      </TPFilterAndSearch>
                    </TPPageFirstRow>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TableContainer>
                      <DataTableContainer>
                        <DataTable
                          selectableRows
                          onSelectedRowsChange={handleChangeSelectedRow}
                          persistTableHead={true}
                          fixedHeader={true}
                          fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                          responsive={true}
                          striped={true}
                          highlightOnHover={true}
                          pagination
                          paginationPerPage={10}
                          paginationComponentOptions={
                            TPGlobal.paginationComponentOptions
                          }
                          columns={gridColumns}
                          data={filteredData()}
                          noDataComponent={thereAreNoRecordsToShow}
                          sortFunction={TPGlobal.datatableCustomSort}
                          customStyles={tableStyles}
                          onChangeRowsPerPage={handleRowsPerPageChanged}
                        />
                      </DataTableContainer>
                    </TableContainer>
                  </div>
                </div>
              </TPWizardContent>
            </TPWizard>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default OutboundMailAccountUnsentMailAdmin;
