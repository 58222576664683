import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import React, { ReactElement, FC, ReactNode } from "react";
import successImg from "@/assets/images/admiration.png";
import error from "@/assets/images/errorNew.png";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";

export type TPModalSuccessStateType = {
    id?: string;
    isShown: boolean;
    modeAlert: enumTypeAlert;    
    yesLabel: string;
    callBackFunction: Function;
};

export enum enumTypeAlert{
    success,
    error
}

type TPModalSuccessProps = {
    modalState: TPModalSuccessStateType;
    children?: React.ReactNode;
};

const TPModalMsgAlertOk: FC<TPModalSuccessProps> = ({
    modalState,
    children,
}): ReactElement => {
    let classModal: string;
    let styleModal: any = {};
    let backdropClass: string;

    const handleModeImage = () => {
        if (enumTypeAlert.success == modalState.modeAlert) return successImg;
        if (enumTypeAlert.error == modalState.modeAlert) return error;

        return successImg;
    }

    if (modalState.isShown) {
        classModal = "modal show";
        styleModal["display"] = "block";
        backdropClass = "modal-backdrop show";
    }
    else {
        classModal = "modal";
        styleModal["display"] = "none";
        backdropClass = "";
    }
    return (
        <>
            <div
                id={modalState.id}
                className={classModal}
                tabIndex={-1}
                data-bs-backdrop="static"
                style={styleModal}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>

                        <div className="modal-body">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div className="col-3" style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={handleModeImage()} width={100}></img>
                                </div>

                                <div style={{ height: "10px" }} ></div>
                                <div className="col">
                                    {!!children && (children)}
                                </div>                              
                                <div style={{ height: "10px" }} ></div>
                               
                            </div>
                        </div>
                        <div className="modal-footer" style={{ borderTop: "none" }}>
                            <TPButton
                                id="IdButton"
                                type={TPButtonTypes.primary}
                                onClick={() => modalState.callBackFunction()}
                            >
                                {modalState.yesLabel}
                            </TPButton>
                        </div>
                    </div>
                </div>
            </div >

            <div className={backdropClass}></div>
        </>
    );
};

export default TPModalMsgAlertOk;
