import { FC } from "react";
import { CSSProperties } from "styled-components";
import './Styles.css';

interface TPSwitchProperties {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
  label?: string,
  style?: CSSProperties,
  disabled?: boolean
}

const TPSwitch: FC<TPSwitchProperties> = function ({ onChange, checked, label, style, disabled }) {
  return (
    <div className="form-check form-switch" style={style}>
      <input
        className="form-check-input shadow-none"
        type="checkbox"
        id="flexSwitchCheckDefault"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{label}</label>
    </div>
  )
}

export default TPSwitch;