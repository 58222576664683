import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { CustomerMergeRequest } from "@/models/CustomerMerge/CustomerMergeModel";

export class MergeService {
  public static async mergeCustomers(
    requestBody: CustomerMergeRequest,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/client/merge-customer";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get TPGenAI response: ${error}`,
      );
    }
  }
}
