import styled from "styled-components";

export const TPLabelStyled = styled.label<{ withCircleText?: boolean }>(
  ({ withCircleText }) => `
  margin: 0;
  white-space: nowrap;
  font-size: 13px;
  user-select: none;

  ${
    withCircleText &&
    `
    margin-bottom: 0.2rem;
  `
  }
`
);

export default TPLabelStyled;
