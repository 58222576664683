import React, {useEffect, useState} from "react";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import TPGlobal from "@/helpers/TPGlobal";
import {useModal} from "@/layouts/TPModalLanguageList/useModal";
import {ProjectNewEnum as e} from "@/models/Project/Projects";

interface ProjectNewStep1LanguageModalProps {
    m: { [attribute: string]: any };
    languages: { [attribute: string]: any };
    setLanguages: Function;
}

export default function ProjectNewStep1LanguageModal({
                                                         m,
                                                         languages,
                                                         setLanguages
                                                     }: ProjectNewStep1LanguageModalProps) {
    const l = TPGlobal.language
    const ls = TPGlobal.TPClientAvailableLanguages.filter(({id}) => id !== l)
    const {isOpen, openModal, closeModal, saveChanges} = useModal(false);
    const [languagesState, setLanguagesState] = useState<any>({});

    useEffect(() => {
    }, []);

    useEffect(() => () => {
    }, []);

    function handleLanguageChange(
        event: any,
        language: string
    ) {
        const l: any = {...languages};
        l[language] = event.target.value;
        setLanguages(l);
    }

    function onClickIconButtonHandler() {
        setLanguagesState({...languages});
        openModal();
    }

    function onClickCancelButtonHandler() {
        setLanguages({...languagesState});
        closeModal();
    }

    return (
        <>
            <TPButton
                type={TPButtonTypes.icon}
                icon={TPIconTypes.language}
                text={`+${ls.length}`}
                tooltip={m?.[e.LanguageButtonTooltipLabel]}
                onClick={onClickIconButtonHandler}
            />

            <TPModalLanguageList
                isOpen={isOpen}
                title={m?.[e.LanguageModalTitleLabel]}
                acceptLabel={m?.[e.LanguageModalOkButtonLabel]}
                cancelLabel={m?.[e.LanguageModalCancelButtonLabel]}
                saveChanges={saveChanges}
                closeModal={onClickCancelButtonHandler}
            >
                {ls.map((item) => (
                    <TPTextBox
                        key={`languageItem-${item.id}`}
                        className="col-12"
                        labelText={`${m?.[e.FormInputNameLabel]} (${item.name})`}
                        value={languages?.[item.id] || ""}
                        onChange={(e: any) => handleLanguageChange(e, item.id)}
                        maxLength={100}
                    />
                ))}
            </TPModalLanguageList>
        </>
    );
}
