import styled, { css } from "styled-components";

export const verticalSpace = "8px";
export const horizontalTabHeight = "69px";
export const expandedMenuWidth = "300px";
export const collapsedMenuWidth = "64px";
export const spaceBetweenMenuAndVerticalTabs = "10px";
export const expandedLeftPosition = `calc(${expandedMenuWidth} + ${spaceBetweenMenuAndVerticalTabs})`;
export const collapsedLeftPosition = `calc(${collapsedMenuWidth} + ${spaceBetweenMenuAndVerticalTabs})`;
export const verticalTabWidth = "100px";
export const menuFooterHeight = "70px";

export const formControlGeneralStyle = css(
  ({ theme }) => `
  &.form-control {
    min-height: 35px;
    font-size: 15px;

    &:focus, &.focus {
      box-shadow: none;
      border: 1px solid ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      outline: 1px solid ${theme.colors.primary};

      &:invalid,
      &.is-invalid {
        border-color: ${theme.colors.errorColor};
        outline: 1px solid ${theme.colors.errorColor};
      }
    }

    &.with-icon {
      &:focus, &.focus {
        border-right: 2px solid ${theme.colors.primary} !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        margin-left: -5px !important;
      }

      &:invalid,
      &.is-invalid {
        border-color: ${theme.colors.errorColor} !important;
        outline: 1px solid ${theme.colors.errorColor} !important;
      } 
    }
      ::placeholder {
        color: #989898 !important;
      }
  }

  &.form-select {
    min-height: 35px;
    font-size: 15px;

    &:focus, &.focus {
      box-shadow: none;
      border-color: ${theme.colors.primary} !important;
      outline: 1px solid ${theme.colors.primary} !important;

      &:invalid,
      &.is-invalid {
        border-color: ${theme.colors.errorColor} !important;
        outline: 1px solid ${theme.colors.errorColor} !important;
      }
    }
  }

  &.rbt-input-main:focus, &.focus {
    outline: 2px solid ${theme.colors.primary};
  }
`
);

interface TPFormControlContainerStyledInterface {
  isHorizontal?: boolean;
  customBgColor?: string;
  style?: any;
  isTag?: boolean;
  isDynamic?: boolean;
}

export const TPFormControlContainerStyled = styled.div<TPFormControlContainerStyledInterface>`
  display: ${(props: any) =>
    props.isTag ? "flex" : props.isHorizontal ? "flex" : ""};
  align-items: ${(props: any) =>
    props.isTag ? "center" : props.isHorizontal ? "center" : ""};
  gap: ${(props: any) => (props.isHorizontal ? "5px" : "")};
  justify-content: ${(props: any) => (props.isTag ? "center" : "")};
  border: ${(props: any) => (props.isTag ? "1px solid #dee2e6" : "none")};
  border-radius: ${(props: any) => (props.isTag ? "0.375rem" : "")};
  position: relative;

  label {
    font-size: 13px;
    font-weight: 500;

    ${(props: any) =>
      props.isDynamic &&
      `
			position: absolute;
			top: -20px;
			`};
  }

  &.input-group {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding-right: 10px;

    input {
      border: none;
      margin-bottom: 0;
    }

    .input-group-append,
    .input-group-prepend {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      font-size: 22px;
      cursor: pointer;
    }

    .input-group-prepend {
      padding-right: 10px;
    }
  }

  .form-check-input:checked {
    background-color: ${({ customBgColor, theme }) => customBgColor || theme.colors.primary};
    border-color: ${({ customBgColor, theme }) => customBgColor || theme.colors.primary};
    outline: none;
  }

  .form-check-input:focus {
    outline: none;
    box-shadow: none;
  }

  .autocomplete-container {
    height: 35px;
    border: none;

    &:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.primary};
      outline: 1px solid ${({ theme }) => theme.colors.primary};
    }

    &.autocomplete-container .rbt-input {
      padding: 1px;
    }

    &.autocomplete-container .rbt-input:focus {
      box-shadow: none;
      outline: none;
    }

    &.is-invalid {
      z-index: auto !important; /* Ensure the input field has a lower z-index than the dropdown menu */
    }
  }

  .react-datepicker-popper {
    z-index: 20;
  }
`;
