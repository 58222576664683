import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { Tooltip, Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import { StyledRefreshButton } from "../supervisor-cockpit-styles";
import { CockpitView } from "../supervisor-cockpit.model";

type CockpitRefreshProps = {
  view?: CockpitView;
  label: string;
  onClickIcon: () => void;
};

const CockpitRefresh: FC<CockpitRefreshProps> = ({
  view = "team",
  label,
  onClickIcon,
}): ReactElement => {
  const styledTooltipText =
    view !== "team" ? (
      <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
        {label}
      </Typography>
    ) : (
      ""
    );

  return (
    <Tooltip title={styledTooltipText} placement={"right"}>
      <StyledRefreshButton onClick={onClickIcon}>
        <TPIcon iconType={TPIconTypes.loop} />
        {view === "team" && <p>{label}</p>}
      </StyledRefreshButton>
    </Tooltip>
  );
};

export default CockpitRefresh;
