import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  CustomStateCommandAction,
  CustomStateEvents,
  customStateInitialStateValue,
  CustomStateProps,
  CustomStateCommandType,
  CustomStateFocus,
  CustomStateState,
  CustomStatePage,
  CustomStateAdminEvents,
} from "@/models/CustomState/CustomState";
import CustomStateAdmin from "./CustomStateAdmin/CustomStateAdmin";
import CustomStateInsertUpdate from "./CustomStateInsertUpdate/CustomStateInsertUpdate";

/**
 * STYLES
 */
const styles = {
  column: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of custom state
 */
const CustomState = forwardRef(
  ({ workflowDefinitionId }: CustomStateProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * admin component reference
     */
    const adminRef = useRef<CustomStateAdminEvents>(null);
    /**
     * state of the component
     */
    const [state, dispatch] = useReducer(command, customStateInitialStateValue);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {
            refreshList();
          },
        }) as CustomStateEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {}, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * command of the component
     */
    function command(s: CustomStateState, action: CustomStateCommandAction) {
      switch (action.type) {
        case CustomStateCommandType.redirectToList:
          return switchFocus(s, CustomStateFocus.LIST);
        case CustomStateCommandType.redirectToNew:
          return switchFocus(s, CustomStateFocus.NEW);
        case CustomStateCommandType.redirectToUpdate:
          return switchFocus(s, CustomStateFocus.UPDATE, action.payload);
        case CustomStateCommandType.redirectToClone:
          return switchFocus(s, CustomStateFocus.CLONE, action.payload);
        case CustomStateCommandType.refreshList:
          refreshList();
          return s;
      }
    }
    /**
     * dispatch action
     */
    function dispatchAction(action: CustomStateCommandAction) {
      dispatch(action);
    }
    /**
     * switch focus
     */
    function switchFocus(
      s: CustomStateState,
      type: CustomStateFocus,
      element?: any
    ) {
      let exist = false;
      let pages = s.pages;

      if (type === CustomStateFocus.LIST) {
        pages = pages.filter((p) => p.type === CustomStateFocus.LIST);
      }

      pages = pages.map((p) => {
        if (p.type === type) {
          exist = true;
        }

        return {
          ...p,
          focus: p.type === type,
        };
      });

      if (!exist) {
        pages.push({
          id: uuidv4(),
          type,
          focus: true,
          element,
        } as CustomStatePage);
      }

      return {
        ...s,
        pages,
      };
    }
    /**
     * refresh list
     */
    function refreshList() {
      adminRef.current?.load();
    }
    /**
     * FUNCTIONS END
     */

    /**
     * RENDER
     */
    return (
      <div id="CustomStateContainer" key="CustomStateContainer">
        {state.pages.map((p) => {
          return p.type === CustomStateFocus.LIST ? (
            <div
              style={{
                display: p.focus ? "block" : "none",
              }}
            >
              <CustomStateAdmin
                key={p.id}
                id={p.id}
                ref={adminRef}
                workflowDefinitionId={workflowDefinitionId}
                pageDispatch={dispatchAction}
              />
            </div>
          ) : p.type === CustomStateFocus.NEW && p.focus ? (
            <CustomStateInsertUpdate
              key={p.id}
              pageDispatch={dispatchAction}
              workflowDefinitionId={workflowDefinitionId}
            />
          ) : p.type === CustomStateFocus.UPDATE && p.focus ? (
            <CustomStateInsertUpdate
              key={p.id}
              pageDispatch={dispatchAction}
              workflowDefinitionId={workflowDefinitionId}
              element={p.element}
            />
          ) : (
            <></>
          );
        })}
      </div>
    );
  }
);

export default CustomState;
