import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { QuickClassifierCloneModel, QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { QuickClassifierInputDTO } from "@/models/QuickClassifier/QuickClassifierInputDTO";
import {
  TPActiveOptions,
  WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";

export class QuickClassifierService {
    serviceFileName: string = "QuickClassifierService.ts";

    public async getQuickClassifierByFilter(
        filterActive: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<QuickClassifierViewModel>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier?FilterIsActive=";
        url = url + filterActive;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        }
        catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getQuickClassifierByFilter ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getQuickClassifierByFilter`);
            throw new Error(
                `Error ${this.serviceFileName} getQuickClassifierByFilter`
            );
        }
    }

    public async getSearchQuickClassifiers(
        baseLevelId: string,
        webServiceClassifierType: number,
        filterActive: TPActiveOptions,
        search: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<QuickClassifierViewModel>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/webserviceclassifier/search?BaselevelId=${baseLevelId}&WebServiceClassifierType=${webServiceClassifierType}&FilterIsActive=${filterActive}&Search=${search}`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        }
        catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getSearchQuickClassifiers ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${this.serviceFileName} getSearchQuickClassifiers`
            );
            throw new Error(
                `Error ${this.serviceFileName} getSearchQuickClassifiers`
            );
        }
    }

    public async getQuickClassifiersByType(
        filterClassifierType: WebServiceClassifierType,
        filterIsActive: TPActiveOptions,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<QuickClassifierViewModel>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/webserviceclassifier-by-filter-type?filterClassifierType=${filterClassifierType}&FilterIsActive=${filterIsActive}`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        }
        catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getQuickClassifiersByType ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${this.serviceFileName} getQuickClassifiersByType`
            );
            throw new Error(
                `Error ${this.serviceFileName} getQuickClassifiersByType`
            );
        }
    }

    public async getQuickClassifiersByBaseLevel(
        baseLevelId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<QuickClassifierViewModel>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/webserviceclassifier/baselevel/${baseLevelId}`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`
            );
            throw new Error(
                `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`
            );
        }
    }

    public async getQuickClassifierById(
        quickClassifierId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<QuickClassifierViewModel> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier/";
        url = url + quickClassifierId;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data[0];
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getQuickClassifierById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getQuickClassifierById`);
            throw new Error(`Error ${this.serviceFileName} getQuickClassifierById`);
        }
    }

    public async deleteQuickClassifierById(
        quickClassifierId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier/" + quickClassifierId;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.deleteData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} deleteQuickClassifierById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} deleteQuickClassifierById`);
            throw new Error(
                `Error ${this.serviceFileName} deleteQuickClassifierById`
            );
        }
    }

    public async insertQuickClassifier(
        inputDTO: QuickClassifierInputDTO,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} insertQuickClassifier ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} insertQuickClassifier`);
            throw new Error(`Error ${this.serviceFileName} insertQuickClassifier`);
        }
    }

    public async updateQuickClassifier(
        inputDTO: QuickClassifierInputDTO,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} updateQuickClassifier ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} updateQuickClassifier`);
            throw new Error(`Error ${this.serviceFileName} updateQuickClassifier`);
        }
    }

    public async cloneQuickClassifier(
        inputDTO: QuickClassifierCloneModel,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/webserviceclassifier/clone";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} cloneQuickClassifier ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} cloneQuickClassifier`);
            throw new Error(`Error ${this.serviceFileName} cloneQuickClassifier`);
        }
    }
}
