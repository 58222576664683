import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { MailViewModel } from "@/models/Mail/MailModels";
import { MailUpdateStatusAndRetryMassiveInputDTO } from "@/models/Mail/MailUpdateStatusAndRetryMassiveInputDTO";

export class MailService {
  serviceFileName: string = "MailService.ts";
  public async getUnSentMails(
    idAccount: string,
    initialDate: string,
    endDate: string,
    timeZone: string,
    sourceType: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<MailViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/mail/get-unsent?idAccount=${idAccount}&initialDate=${initialDate}&endDate=${endDate}&timeZone=${timeZone}&sourceType=${sourceType}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getUnSentMails ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getUnSentMails`);
      throw new Error(`Error ${this.serviceFileName} getUnSentMails`);
    }
  }

  public async updateStatusAndResetTriesMail(
    inputDTO: MailUpdateStatusAndRetryMassiveInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mail/update-massive-mail-status";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateStatusAndResetTriesMail ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateStatusAndResetTriesMail`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateStatusAndResetTriesMail`,
      );
    }
  }
}
