import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import {
  CompletePendingTaskMasiveInputDTOValidator,
  CompletePendingTasksMasiveInputDTO,
} from "@/models/Cases/CaseMassUpdateInputDTO";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { CaseMassUpdateService } from "@/services/CaseMassUpdateService";
import { TPI18N } from "@/services/I18nService";
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

interface ICompletePendingTaskProps {
  caseList: Array<number>;
  taskType?: string;
  callBackSaveSuccess: Function;
}

enum commandsEnum {
  change_property_value,
  set_errors,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

type screenStateType = {
  Report: string;
  DecisionPath: string;

  ReportErrorMessage: string;
  DecisionPathErrorMessage: string;
  [key: string]: any;
};

interface IAnswerEndpointData {
  taskId: string;
  updated: boolean;
  message: string;
}

const CompletePendingTasks = React.forwardRef(
  (
    { caseList, callBackSaveSuccess, taskType }: ICompletePendingTaskProps,
    ref,
  ) => {
    const componentFileName: string = "CompletePendingTaks.tsx";
    const resourceSet: string = "CompletePendingTasksComponent";

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [modalSuccessTitle, setModalSuccessTitle] = useState("");
    const [selectedRowsLabel, setSelectedRowsLabel] = useState("");
    const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
    const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] = useState("");

    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");
    const [modalConfirmTitle, setModalConfirmTitle] = useState("");

    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");

    const [isDecisionPath, setIsDecisionPath] = useState(false);

    const [answerData, setAnswerData] = useState<Array<IAnswerEndpointData>>(
      [],
    );
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const editorRef = useRef<any>(null);
    const [editorValue, setEditorValue] = useState("");
    const [hasBeenModified, setHasBeenModified] = useState(false);

    useImperativeHandle(ref, () => ({
      async completePendingTasks() {
        let bolR: boolean = await internalValidation();
        return bolR;
      },
    }));

    const initialScreenState: screenStateType = {
      DecisionPath: "",
      DecisionPathErrorMessage: "",
      Report: "",
      ReportErrorMessage: "",
    };

    //Reducer definition
    const reducer = (state: screenStateType, action: commandType) => {
      let newScreenState: screenStateType;
      newScreenState = { ...state };
      switch (action.type) {
        case commandsEnum.change_property_value:
          newScreenState[action.payload.propertyName] =
            action.payload.newPropertyValue;
          newScreenState[`${action.payload.propertyName}ErrorMessage`] = "";
          return newScreenState;
        case commandsEnum.set_errors:
          newScreenState = { ...state };
          newScreenState.ReportErrorMessage =
            action.payload.errors.ReportErrorMessage;
          newScreenState.DecisionPathErrorMessage =
            action.payload.errors.DecisionPathErrorMessage;
          return newScreenState;
        default:
          return state;
      }
    };

    const [screenState, dispatch] = React.useReducer(
      reducer,
      initialScreenState,
    );

    const loadResources = async () => {
      setModalConfirmQuestion(
        await TPI18N.GetText(resourceSet, "RecordModalConfirmQuestion"),
      );
      setModalSuccessTitle(
        await TPI18N.GetText(resourceSet, "ModalSuccessTitle"),
      );
      setSelectedRowsLabel(
        await TPI18N.GetText(resourceSet, "SelectedRowsLabel"),
      );
      setRecordsProcessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsProcessedLabel"),
      );
      setRecordsUnprocessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsUnprocessedLabel"),
      );
      setModalConfirmTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setCancelButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setOkButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
      setIsLoadingScreen(false);
      setIsDecisionPath(
        ["CAREDETA", "CAREDERETRY"].some((item) => item === taskType),
      );
    };

    //Action after question confirmation
    const handleCallBackModalConfirm = async (confirm: boolean) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      let comments: string;

      if (editorValue.trim() == "") {
        setHasBeenModified(true);
      }

      if (editorRef.current.props.value)
        comments = editorRef.current.props.value;
      comments = editorRef.current.props.value;
      comments = comments === null ? "" : comments.trim();

      let recordInputDTO: CompletePendingTasksMasiveInputDTO = {
        Report: TPGlobal.stringToUTF8(comments).toString(), //editorRef.current.getContent({ format: "text" }),
        DecisionPath: screenState.DecisionPath,
        EscalatedTaskReport: "",
        TypistGuidUser: TPGlobal.currentUserGuid,
        TEmpAdditionalDataTask: [
          {
            AdditionalDataId: "ADDA01",
            Value: "Valorinformeradda11",
            IsMandatory: true,
          },
        ],

        TasksListToUpdate: caseList,
      };

      if (confirm) {
        //Call endpoint to complete pending tasks
        let serviceClient = new CaseMassUpdateService();
        let expectedCodes: Array<number> = [200];

        try {
          setIsLoadingScreen(false);
          let responseRequest = await serviceClient.completePendingTasks(
            recordInputDTO,
            true,
            true,
            expectedCodes,
          );
          setModalSuccess(true);

          if (responseRequest.responseResult) {
            setAnswerData(responseRequest.responseData.data);
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return true;
          } else {
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return false;
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModalConfirm ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} handleCallBackModalConfirm ex`,
          );
          setIsLoadingScreen(false);
          setModalQuestionState(newModalQuestionState);
          return false;
        }
      } else {
        setModalQuestionState(newModalQuestionState);
      }
    };

    const internalValidation = async () => {
      let recordInputDTO: CompletePendingTasksMasiveInputDTO = {
        Report: editorRef.current.getContent({ format: "text" }),
        DecisionPath: screenState.DecisionPath,
        EscalatedTaskReport: "",
        TypistGuidUser: TPGlobal.currentUserGuid,
        TEmpAdditionalDataTask: [],
        TasksListToUpdate: caseList,
      };
      let inputDTOValidator = new CompletePendingTaskMasiveInputDTOValidator();
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let validationsErrores: any = new Object();
        if (resultValidator.Report) {
          validationsErrores.ReportErrorMessage = await TPI18N.GetResource(
            resultValidator.Report,
          );
        } else {
          validationsErrores.ReportErrorMessage = "";
        }
        if (resultValidator.DecisionPath) {
          validationsErrores.DecisionPathErrorMessage =
            await TPI18N.GetResource(resultValidator.DecisionPath);
        } else {
          validationsErrores.DecisionPathErrorMessage = "";
        }

        let command: commandType = {
          type: commandsEnum.set_errors,
          payload: {
            errors: validationsErrores,
          },
        };
        dispatch(command);
        return false;
      }

      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      setModalQuestionState(newModalQuestionState);
      return false;
    };

    const handleChangeEditor = (value: any) => {
      const filterData = value.replace(/(<([^>]+)>)/gi, "");
      setEditorValue(value);
      screenState.ReportErrorMessage =
        filterData.length > 0 ? "" : screenState.ReportErrorMessage;
    };

    useEffect(() => {
      loadResources();
    }, []);

    return (
      <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
        <div className="row">
          <div className="col">
            <div className="alert alert-info">
              <TPIcon iconType={TPIconTypes.info}></TPIcon> <p>Help for user</p>
              <p className="mb-0">
                * For task type (Mail, Facebook and twiter), the report will be
                for internal use only, it will not be sent.
              </p>
              <p className="mb-0">
                * For task type Yes/No/retry, will only show the Yes/No options.
              </p>
            </div>
          </div>
        </div>
        {isDecisionPath && (
          <div className="row">
            <div className="col">
              <TPRadioGroup
                id="IdRadioGroup"
                value={screenState.DecisionPath}
                onChange={(e: any) => {
                  dispatch({
                    type: commandsEnum.change_property_value,
                    payload: {
                      propertyName: "DecisionPath",
                      newPropertyValue: e.target.value,
                    },
                  });
                }}
                source={[
                  { key: "Y", value: yesLabel },
                  { key: "N", value: noLabel },
                ]}
                errorMessage={screenState.DecisionPathErrorMessage}
              ></TPRadioGroup>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <div>
              <TPLabel
                labelText={
                  <TPLanguage
                    resourceSet={resourceSet}
                    resourceId={"TaskReportLabel"}
                  />
                }
              />
            </div>
            <TPEditor
              referece={(editor: any) => (editorRef.current = editor)}
              value={editorValue}
              placeholder=""
              onChange={(value: any) => {
                dispatch({
                  type: commandsEnum.change_property_value,
                  payload: {
                    propertyName: "ReportErrorMessage",
                    newPropertyValue: "",
                  },
                });
                handleChangeEditor(value); // Pass the value here
              }}
              initialValue=""
              isVisible={false}
            />
            <span style={{ color: "#dc3545", fontSize: "14px" }}>
              {screenState.ReportErrorMessage}
            </span>
          </div>
        </div>

        <TPModalQuestion
          title={modalConfirmTitle}
          yesLabel={okButtonLabel}
          noLabel={cancelButtonLabel}
          question={modalConfirmQuestion}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModalConfirm}
        ></TPModalQuestion>

        {modalSuccess && (
          <TPModalSuccess
            modalState={{
              title: modalSuccessTitle,
              yesLabel: okButtonLabel,
              isShown: modalSuccess,
              callBackFunction: () => {
                callBackSaveSuccess();
                setModalSuccess(false);
              },
            }}
          >
            <div>
              <div>
                {selectedRowsLabel} : {caseList.length}
              </div>
              <div>
                {recordsProcessedLabel} :{" "}
                {answerData.filter((item) => Boolean(item.updated)).length}
              </div>
              <div>
                {recordsUnprocessedLabel} :{" "}
                {answerData.filter((item) => Boolean(!item.updated)).length}
              </div>
            </div>
          </TPModalSuccess>
        )}
      </TPLoadingOverlay>
    );
  },
);

export default CompletePendingTasks;
