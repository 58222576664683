import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { AdditionalDataOpenTextMultilineTypesEnum } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataOpenTextMultilineProps = {
  subType: AdditionalDataOpenTextMultilineTypesEnum;
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: string;
  onValueChange?: Function | null;
  maxLength: number;
  whiteList: string;
  rows: number;
  cols: number;
};

const TPAdditionalDataOpenTextMultiline = React.forwardRef(
  (
    {
      subType,
      idControl,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory,
      defaultValue,
      onValueChange = null,
      maxLength,
      whiteList,
      rows,
      cols,
    }: TPAdditionalDataOpenTextMultilineProps,
    ref,
  ) => {
    //#region  Init
    //state
    const [textValue, setTextValue] = useState(defaultValue);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    //Screen resources
    const [invalidLengthErrorMessage, setInvalidLengthErrorMessage] =
      useState("");
    const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
      useState("");
    const [characterInvalidErrorMessage, setCharacterInvalidErrorMessage] =
      useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
    //#endregion

    const loadResources = async () => {
      setInvalidLengthErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidLength",
        ),
      );
      setInvalidCharactersErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidIdChar",
        ),
      );
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
      setCharacterInvalidErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "CharacterInvalidErrorMessage",
        ),
      );
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        return textValue;
      },
      validateFromParent() {
        if (isMandatory && (!textValue || textValue.trim() === "")) {
          setErrorMessageValue(mandatoryErrorMessage);
          return false;
        }
        if (textValue && textValue.trim() !== "") {
          if (textValue.trim().length > maxLength) {
            setErrorMessageValue(invalidLengthErrorMessage);
            return false;
          }
          if (textValue !== TPGlobal.TPSanitize(textValue)) {
            setErrorMessageValue(invalidCharactersErrorMessage);
            return false;
          }
          if (
            subType == AdditionalDataOpenTextMultilineTypesEnum.whiteList &&
            whiteList
          ) {
            //validate white list --> List whit T!
            let objValid: any = validateWhitelist(textValue, whiteList);
            if (!objValid.result) {
              setErrorMessageValue(
                `${characterInvalidErrorMessage}: ${objValid.car}`,
              );
              return false;
            }
          }
        }
        return true;
      },
    }));

    const validateWhitelist = (chars: string, whiteList: string): any => {
      let j: number;
      let found: boolean;
      let car: string;
      chars = chars.replace(/(?:\r\n|\r|\n)/g, "");
      for (let i = 0; i < chars.length; i++) {
        car = chars[i];
        found = false;
        for (let j = 0; j < whiteList.length; j++) {
          const element = whiteList[j];
          if (car == element) {
            found = true;
            break;
          }
        }
        if (!found) {
          return { result: false, car: car };
        }
      }
      return { result: true, car: "" };
    };

    const handlerOnTextChange = (e: any) => {
      setErrorMessageValue("");
      setTextValue(e.target.value);
      if (onValueChange) {
        onValueChange(e.target.value, idControl);
      }
    };

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group">
          <TPTextArea
            id="IdTextArea"
            labelText={labelText}
            isMandatory={isMandatory}
            value={textValue}
            onChange={handlerOnTextChange}
            maxLength={maxLength}
            errorMessage={errorMessageValue}
            rows={rows}
            columns={cols}
          />
        </div>
      );
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <div className="form-group">
          <TPTextArea
            id="IdTextArea"
            labelText={labelText}
            isMandatory={isMandatory}
            value={textValue}
            onChange={handlerOnTextChange}
            maxLength={maxLength}
            errorMessage={errorMessageValue}
            disabled={true}
            rows={7}
          />
        </div>
      );
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{textValue}</span>
            </div>
          </div>
        </React.Fragment>
      );
    };

    useEffect(() => {
      //load validation error messages
      loadResources();
    }, []);

    useEffect(() => {
      setTextValue(defaultValue);
    }, [defaultValue]);

    return (
      <React.Fragment>
        {modeUI == TPAddtionalDataUIModes.Collect
          ? renderCollectMode()
          : modeUI == TPAddtionalDataUIModes.View1
            ? renderViewMode1()
            : renderViewMode2()}
      </React.Fragment>
    );
  },
);

export default TPAdditionalDataOpenTextMultiline;
