const DefaultSmallAvatarIcon = ({ ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 0C13.0302 0 11.0796 0.387987 9.25975 1.14181C7.43986 1.89563 5.78628 3.00052 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C5.78628 26.9995 7.43986 28.1044 9.25975 28.8582C11.0796 29.612 13.0302 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387987 16.9698 0 15 0ZM7.605 24.42C8.25 23.07 12.18 21.75 15 21.75C17.82 21.75 21.75 23.07 22.395 24.42C20.2941 26.094 17.6863 27.0038 15 27C12.21 27 9.645 26.04 7.605 24.42ZM24.54 22.245C22.395 19.635 17.19 18.75 15 18.75C12.81 18.75 7.605 19.635 5.46 22.245C3.86428 20.1669 2.9995 17.6201 3 15C3 8.385 8.385 3 15 3C21.615 3 27 8.385 27 15C27 17.73 26.07 20.25 24.54 22.245ZM15 6C12.09 6 9.75 8.34 9.75 11.25C9.75 14.16 12.09 16.5 15 16.5C17.91 16.5 20.25 14.16 20.25 11.25C20.25 8.34 17.91 6 15 6ZM15 13.5C14.4033 13.5 13.831 13.2629 13.409 12.841C12.9871 12.419 12.75 11.8467 12.75 11.25C12.75 10.6533 12.9871 10.081 13.409 9.65901C13.831 9.23705 14.4033 9 15 9C15.5967 9 16.169 9.23705 16.591 9.65901C17.0129 10.081 17.25 10.6533 17.25 11.25C17.25 11.8467 17.0129 12.419 16.591 12.841C16.169 13.2629 15.5967 13.5 15 13.5Z" fill="#5D5D5D"/>
                 </svg>`,
      }}
    ></div>
  );
};

export default DefaultSmallAvatarIcon;
