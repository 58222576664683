import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import "./TakeNextQueueStyles.css";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import DownArrowIcon from "@/assets/images/TPIcons/DownArrowIcon";
import UpArrowIcon from "@/assets/images/TPIcons/UpArrowIcon";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import allThemes from "@/assets/styles/theme";
import { StructureService } from "@/services/StructureService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { GroupsService } from "@/services/GroupsService";
import { GroupsViewModel, QueueAdminModel } from "@/models/Groups/GroupsModels";
import { ListsServices } from "@/services/EventsManager/ListsService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React from "react";
import { RecordService } from "@/services/RecordService";
import { TakeNextQueueViewModel } from "@/models/TakeNextQueue/TakeNextQueueViewModel";
import { LoadRecordTimerInputDTO, TakeNextQueueInputDTO } from "@/models/TakeNextQueue/TakeNextQueueInputDTO";
import TPGlobal from "@/helpers/TPGlobal";
import FormView from "../FormDesigner/FormView/FormView";
import { set } from "react-hook-form";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { GroupTakeNextSlice, GroupTakeNextSliceModel } from "./GroupTakeNextSlice";
import { EventProjectService } from "@/services/EventProjectService";
import { addRecordFOViewModel } from "@/models/ActiveTeamMembers/TeamViewViewModel";
import QueueAdminHome from "../EventsManager/QueueAdmin/QueueAdminHome";
import { Comments } from "handsontable/plugins";

interface CustomField {
  key: string;
  value: any;
}

interface TakeNextQueuePropState {
  outcome: string;
  activityType: string;
  customFields: CustomField[];
}

type props = {
    componentProps: any;
}

const TakeNextQueueComponent = ({
    componentProps
}: props) => {

    const componentFileName = "TakeNextQueue.tsx";

    const resourceSet = "TakeNextQueue";

    const [pageTitle, setPageTitle] = useState<string>("");
    const [leftInfoText, setInfoText] = useState<string>("");
    const [rightInfoText, setRightInfoText] = useState<string>("");
    const [informativeFieldsLabel, setInformativeFieldsLabel] = useState<string>("");
    const [otherInformativeFieldsLabel, setOtherInformativeFieldsLabel] = useState<string>("");
    const [customFieldsLabel, setCustomFieldsLabel] = useState<string>("");
    const [outcomeLabel, setOutcomeLabel] = useState<string>("");
    const [activityTypeLabel, setActivityTypeLabel] = useState<string>("");

    const [saveAndLogOutLabel, setSaveAndLogOutLabel] = useState<string>("");
    const [saveAndContinueLabel, setSaveAndContinueLabel] = useState<string>("");

    const casecontext: any = React.useContext(TPCaseViewerContext);

    const [loadingContent, setLoadingContent] = useState<boolean>(true);

    const [showOtherInformativeFields, setShowOtherInformativeFields] = useState<boolean>(false);

    const [hasInformativefields, setHasInformativeFields] = useState<boolean>(false);
    const [hasCustomFields, setHasCustomFields] = useState<boolean>(false);
    const [hasActivityType, setHasActivityType] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [readyForm, setReadyForm] = useState<boolean>(false);

    const [structureInfo, setStructureInfo] = useState<Array<TakeNextQueueViewModel>>([]);
    const [queueInfo, setQueueInfo] = useState<QueueAdminModel>();
    const [outcomeList, setOutcomeList] = useState<Array<TPKeyValue>>([]);
    const [activityTypeList, setActivityTypeList] = useState<Array<TPKeyValue>>([]);
    const [customFieldsId, setCustomFieldsId] = useState<string>("");

    const dispatch = useDispatch();
    const { online } = useSelector((state: StoreModel) => state[GroupTakeNextSlice.name]) as GroupTakeNextSliceModel;

    const [groupName, setGroupName] = useState("");

    const loadResources = async () => {
        setPageTitle((await TPI18N.GetText(resourceSet, "PageTitle")) + " ");
        setInfoText(await TPI18N.GetText(resourceSet, "InfoText"));
        setRightInfoText(await TPI18N.GetText(resourceSet, "RightInfoText"));
        setInformativeFieldsLabel(await TPI18N.GetText(resourceSet, "InformativeFieldsLabel"));
        setOtherInformativeFieldsLabel(await TPI18N.GetText(resourceSet, "OtherInformativeFieldsLabel"));
        setCustomFieldsLabel(await TPI18N.GetText(resourceSet, "CustomFieldsLabel"));
        setOutcomeLabel(await TPI18N.GetText(resourceSet, "OutcomeLabel"));
        setActivityTypeLabel(await TPI18N.GetText(resourceSet, "ActivityTypeLabel"));

        setSaveAndLogOutLabel(await TPI18N.GetText(resourceSet, "SaveAndLogOutLabel"));
        setSaveAndContinueLabel(await TPI18N.GetText(resourceSet, "SaveAndContinueLabel"));
    }

    let takeNextQueueInitialPropState: TakeNextQueuePropState = {
        outcome: "",
        activityType: "",
        customFields: [],
    }

    const [queueType, setQueueType] = useState<string>("Frontoffice");
    const [saveAction, setSaveAction] = useState<string>("");

    const [stpValue, setStpValue] = useState<Array<TPKeyValue>>([]);

    const [takeNextQueuePropState, setTakeNextQueuePropState] = useState(takeNextQueueInitialPropState);

    const [timer, setTimer] = useState<number>(-1);
    const [timerWarn, setTimerWarn] = useState<boolean>(false);

    useEffect(() => {
        if (timer >= 0) {
            const intervalId = setInterval(() => {
            setTimer(timer + 1);
            if (queueInfo) {
                if (timer === (queueInfo?.activateWarningAfterMinutes * 60 + queueInfo?.activateWarningAfterSeconds)) {
                    setTimerWarn(true);
                }
            }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer]);

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secondsRemaining = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;
    };

    const handleTakeNextQueueStateChange = (event: React.ChangeEvent<HTMLInputElement>, mode: string) => {
        if (mode === "outcome") {
            setTakeNextQueuePropState((prevState) => ({
                ...prevState,
                outcome: event.target.value
            }));
        } else if (mode === "activityType") {
            setTakeNextQueuePropState((prevState) => ({
                ...prevState,
                activityType: event.target.value
            }));
        }
    };

    const [idEventLoadRecord, setIdEventLoadRecord] = useState<number>(componentProps.recordId);
    const [idEventLoadGroup, setIdEventLoadGroup] = useState<number>(Number(componentProps.groupId));

    const getRecordInfo = async (id?: string) => {
        let serviceRecord = new RecordService();
        let expectedCodes = [200, 404];

        try {
            let responseRequest = await serviceRecord.getRecordInfo(
                id ? id : idEventLoadRecord.toString(),
                false,
                false,
                expectedCodes
            );

            if (responseRequest) {
                if (responseRequest.length > 0) {
                    setHasInformativeFields(true);
                }
                let structureInfoFilter: Array<TakeNextQueueViewModel> = [];
                responseRequest.filter((item: TakeNextQueueViewModel) => {
                    if (item.displayAgentField) {
                        structureInfoFilter.push(item);
                    }
                })
                setStructureInfo(structureInfoFilter.sort((a: TakeNextQueueViewModel, b: TakeNextQueueViewModel) => a.displayAgentOrder- b.displayAgentOrder));
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRecordInfo ex`);
            setLoadingContent(false);
        }
    }
   
    const getQueueInfo = async (id?: number) => {
        let groupService = new GroupsService();
        let expectedCodes = [200];
        let responseRequest : QueueAdminModel;
        let groupResponse : GroupsViewModel;
        
        try {
            if (componentProps.recordId != -1) { //backoffice
                responseRequest = await groupService.getQueueGroupId(
                    idEventLoadGroup.toString(), 
                    false,
                    false,
                    expectedCodes
                );
            }else{ //frontoffice
                responseRequest = await groupService.getQueueByUserGroupId(
                    idEventLoadGroup.toString(), 
                    false,
                    false,
                    expectedCodes
                );   
            }

            if (responseRequest) {
                setQueueInfo(responseRequest);

                if (responseRequest.customFields){
                    setHasCustomFields(true);
                }

                if (responseRequest.clasificationOptions){
                    setHasActivityType(true);
                }
            }

            //Get group name
            if (componentProps.recordId != -1) { //backoffice
                groupResponse = await groupService.getGroupById(
                    idEventLoadGroup.toString(),
                    false,
                    true,
                    expectedCodes
                )
            }else{ //frontoffice
                groupResponse = await groupService.getGroupByUserGroupId(
                    idEventLoadGroup.toString(),
                    false,
                    true,
                    expectedCodes
                )
            }
            
            if (groupResponse){
                setGroupName(groupResponse.user.firstName);
            }
            
            getRecord(id);
            
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getQueueInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getQueueInfo ex`);
            setLoadingContent(false);
        }
    }

    const getActivityTypeList = async () => {
        let expectedCodes = [200];

        if (queueInfo?.clasificationOptions) {
            try {
                let responseRequest = ListsServices.getListById(
                    queueInfo?.clasificationOptions,
                    false,
                    false,
                    expectedCodes
                );

                let activityTypes: Array<TPKeyValue> = [];
    
                if (responseRequest) {
                    (await responseRequest).items.map((item) => {
                        activityTypes.push({
                            key: item.id,
                            value: item.description
                        })
                    })
                    
                    setActivityTypeList(activityTypes);
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getActivityTypeList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getActivityTypeList ex`);
            }
        }
    }

    const getOutcomeList = async () => {
        let expectedCodes = [200];

        if (queueInfo) {
            try {
                let responseRequest = ListsServices.getListById(
                    queueInfo?.interactionOutcomes,
                    false,
                    true,
                    expectedCodes
                );

                let outcomes: Array<TPKeyValue> = [];
                let activityTypes: Array<TPKeyValue> = [];
    
                if (responseRequest) {
                    (await responseRequest).items.map((item) => {
                        outcomes.push({
                            key: item.id,
                            value: item.description
                        })
                    })
                    
                    setOutcomeList(outcomes);
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getOutcomeList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getOutcomeList ex`);
            }
        }
        
    }

    const handleSaveRecord = async (action: string) => {
        let serviceRecord = new RecordService();
        let expectedCodes = [200];
          
        let inputDTO: TakeNextQueueInputDTO = {
            idEventLoadRecord: idEventLoadRecord,
            interactionOutcomes: takeNextQueuePropState.outcome,
            clasificationOptions: hasActivityType ? takeNextQueuePropState.activityType : null,
            jsonCustomFields: new TextEncoder().encode(JSON.stringify(takeNextQueuePropState.customFields)).join(','),
            comments: null,
            agentUpdate: TPGlobal.currentUserGuid,
            timeWorkedSeconds: timer
        }

        try {
            let responseRequest = await serviceRecord.saveRecord(
                inputDTO,
                true,
                true,
                expectedCodes
            );

            if (action === "saveAndLogOut")
            {
                dispatch(GroupTakeNextSlice.actions.setOnline(false));
            }
            dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
            dispatch(GroupTakeNextSlice.actions.setCurrentRecord(-1));
            casecontext.closeTakeNextQueue(saveAction);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleSaveRecord ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} handleSaveRecord ex`);
        }
    }

    const getRecord = async (id?: number) => {
        let serviceRecord = new RecordService();
        let expectedCodes = [200];

        try {
            let responseRequest = await serviceRecord.getRecord(
                id ? id.toString() : idEventLoadRecord.toString(),
                false,
                true,
                expectedCodes
            );

            if (responseRequest && responseRequest[0]) {
                let newTakeNextQueuePropState = { ...takeNextQueuePropState };
                newTakeNextQueuePropState.outcome = componentProps.readOnly ? responseRequest[0].interactionOutcomes : "";

                newTakeNextQueuePropState.activityType = componentProps.readOnly ? responseRequest[0].clasificationOptions : "";

                let parseInfo = JSON.parse(responseRequest[0].jsonCustomFields);
                
                setStpValue(parseInfo);

                setTakeNextQueuePropState(newTakeNextQueuePropState);
                setLoadingContent(false);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecord ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRecord ex`);
            setLoadingContent(false);
        }
                
    }

    const addRecordFrontoffice = async () => {
        let projectService = new EventProjectService();
        
        let inputDTO: addRecordFOViewModel = {
            ProjectId: componentProps.projectId,
            GroupId: idEventLoadGroup,
            GuidUser: TPGlobal.currentUserGuid,
        }

        try {

            let responseRequest = await projectService.addRecordFrontoffice(
                inputDTO
            )

            if (responseRequest) {
                setIdEventLoadRecord(responseRequest[0].value);
                setTimer(0);
                getQueueInfo(responseRequest[0].value);
            }
            
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} addRecordFrontoffice ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} addRecordFrontoffice ex`);
        }
    }

    useEffect(() => {
        setLoadingContent(true);
        
        if (componentProps.recordId != -1) {
            setQueueType("Backoffice");
            setTimer(0);
            getRecordInfo();
            getQueueInfo();
        } else {
            setQueueType("Frontoffice");
            addRecordFrontoffice();
        }
        
        loadResources();
    }, []);

    useEffect(() => {
        if (queueInfo) {
            getOutcomeList();

            if (hasActivityType){
                getActivityTypeList();
            }

            if (queueInfo.customFields) {
                setCustomFieldsId(queueInfo.customFields);
            }
        }
    }, [queueInfo]);

    useEffect(() => {
        console.log("customFieldsId", customFieldsId);
    }, [customFieldsId]);

    useEffect(() => {
        if (queueInfo && activityTypeList && outcomeList && structureInfo) {
            setLoadingContent(false);
        }
    }, [structureInfo, queueInfo, activityTypeList, outcomeList]);

    const saveRecordTime = async () => {
        let serviceRecord = new RecordService();
        let expectedCodes = [200];

        let inputDTO: LoadRecordTimerInputDTO = {
            agentUpdate: TPGlobal.currentUserGuid,
            idEventLoadRecord: idEventLoadRecord,
            timeWorkedSeconds: timer
        }

        try {
            let responseRequest = await serviceRecord.timeWorkedRecord(
                inputDTO,
                false,
                false,
                expectedCodes
            );
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} saveRecordTime ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} saveRecordTime ex`);
        }
    }

    useEffect(() => {
        if (timer%20 === 0 && !componentProps.readOnly && idEventLoadRecord != -1) {
            saveRecordTime();
        }
    }, [timer]);

    const handleChangeCustomFields = (event: any) => {
        let newTakeNextQueuePropState = { ...takeNextQueuePropState };
        newTakeNextQueuePropState.customFields = event;
        setTakeNextQueuePropState(newTakeNextQueuePropState);
    }

    useEffect(() => {
        let enableSave = true;

        // console.log(takeNextQueuePropState);
        // console.log(hasActivityType, hasCustomFields);

        if (takeNextQueuePropState.outcome === ""){
            enableSave = false;
        }
        if (hasActivityType && takeNextQueuePropState.activityType === ""){
            enableSave = false;
        }
        if (hasCustomFields && !readyForm){
            enableSave = false;
        } 

        if (enableSave){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [takeNextQueuePropState]);

    return (
        <ContentVerticalNoTabsStyled>
            <div
                    id="TNQ-take-next-queue"
                    className="container-fluid"
                    style={{ overflow: "auto", height: "85vh", flex: 1 }}
                >
                    <TPLoadingOverlay active={loadingContent}>
                        <TPPageTitle>{pageTitle}{groupName}</TPPageTitle>
                        <div className="master-container">
                            {!componentProps.readOnly && (
                                <div id="TNQ-header">
                                    <div id="TNQ-info">
                                        <Icon
                                            icon="tabler:alert-circle"
                                            width="20px"
                                            height="20px"
                                            color="#3047B0"
                                        />
                                        <label>{leftInfoText}</label>
                                        <div id="TNQ-timer" style={{ background: timerWarn ? "red" : "#780096" }}>
                                            <Icon
                                                icon="lucide:clock-8"
                                                width="20px"
                                                height="20px"
                                                color="white"
                                            />
                                            <label style={{ color: "white", fontWeight: "bold" }}>{formatTime(timer)}</label>
                                        </div>
                                        <label>{rightInfoText}</label>
                                    </div>
                                </div>
                            )}
                            <div className="TNQ-informativeFields-Container">
                                {hasInformativefields && queueType == "Backoffice" && (
                                    <>
                                        <label style={{ fontWeight: "bold"}}>{informativeFieldsLabel}</label>
                                        <div className="TNQ-informativeFields-Container-inner">
                                            {structureInfo.map((item, index) =>
                                                index < 5 && (
                                                <div className="TNQ-informativeField">
                                                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>{item.additionalDataDescription}</label>
                                                    <label style={{ fontSize: "14px" }}>{item.columnValue}</label>
                                                </div>
                                            ))}
                                        </div>
                                        {structureInfo.length > 5 && (
                                            <>
                                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", cursor: "pointer"}} onClick={() => setShowOtherInformativeFields(!showOtherInformativeFields)}>
                                                    <u>{otherInformativeFieldsLabel}</u>
                                                    {showOtherInformativeFields ? <UpArrowIcon/> : <DownArrowIcon/>}
                                                </div>
                                                {showOtherInformativeFields && (
                                                    <>
                                                        <div className="TNQ-informativeFields-Container-inner">
                                                            {structureInfo.map((item, index) =>
                                                                index >= 5 && (
                                                                <div className="TNQ-informativeField">
                                                                    <label style={{ fontWeight: "bold", fontSize: "14px" }}>{item.additionalDataDescription}</label>
                                                                    <label style={{ fontSize: "14px" }}>{item.columnValue}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="TNQ-customField-Container">
                                    {hasCustomFields && customFieldsId !== "" && (
                                        <>
                                            {componentProps.readOnly ? (
                                                <>
                                                    {stpValue && stpValue.length > 0 && (
                                                        <div style={{ position: 'relative', width: '100%'}}>
                                                            <div style={{ position: 'absolute', minWidth: '100%', minHeight: '100%', zIndex: 1}}>
                                                            </div>
                                                            <label style={{ fontWeight: "bold"}}>{customFieldsLabel}</label>
                                                            <div className="TNQ-customField-Container-inner">
                                                                <FormView inputTryingToSave={true} inputFormId={customFieldsId} onChangedJson={(e: any) => {}} inputValueDefault={ componentProps.readOnly && stpValue} onChangedReadyForm={(e: boolean) => {setReadyForm(e)}}  />
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <label style={{ fontWeight: "bold"}}>{customFieldsLabel}</label>
                                                    <div className="TNQ-customField-Container-inner">
                                                        <FormView inputTryingToSave={true} inputFormId={customFieldsId} onChangedJson={(e: any) => { handleChangeCustomFields(e) }} inputValueDefault={takeNextQueuePropState.customFields} onChangedReadyForm={(e: boolean) => {setReadyForm(e)}} />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                            </div>
                            <div className="TQN-radioGroup-Container">
                                <label style={{ fontWeight: "bold" }}>{outcomeLabel}</label>
                                <TPRadioGroup 
                                    id="TNQ-outcome-radioGroup"
                                    value={takeNextQueuePropState.outcome}
                                    source={outcomeList}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { !componentProps.readOnly && handleTakeNextQueueStateChange(e, "outcome")}}
                                />
                                {hasActivityType && (
                                    <>
                                        <label style={{ fontWeight: "bold", marginTop: "30px" }}>{activityTypeLabel}</label>
                                        <TPRadioGroup
                                            id="TNQ-activityType-radioGroup"
                                            value={takeNextQueuePropState.activityType}
                                            source={activityTypeList}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { !componentProps.readOnly && handleTakeNextQueueStateChange(e, "activityType")}}
                                        />
                                    </>
                                )}
                                
                            </div>
                            {!componentProps.readOnly && (
                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "flex-end"}}>
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.link}
                                        onClick={() => {
                                            handleSaveRecord("saveAndLogOut");
                                        }}
                                        isDesignSystem
                                        disabled={disabled}
                                        style={{
                                            paddingLeft:'16px', paddingRight: '16px',
                                            backgroundColor:'white', color:allThemes.base.primary
                                        }}
                                    >
                                        {saveAndLogOutLabel}
                                    </TPButton>
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.primary}
                                        onClick={() => {
                                            handleSaveRecord("saveAndContinue");
                                        }}
                                        isDesignSystem
                                        disabled={disabled}
                                        style={{
                                            paddingLeft:'16px', paddingRight: '16px'
                                        }}
                                    >
                                        {saveAndContinueLabel}
                                    </TPButton>
                                </div>
                            )}
                            
                        </div>
                    </TPLoadingOverlay>
                </div>
        </ContentVerticalNoTabsStyled>
    )
};

export default TakeNextQueueComponent
