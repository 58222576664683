import GroupCloneFormModel from "./GroupCloneFormModel";
import * as yup from "yup";
import e from "./GroupCloneFormEnum";

/**
 * group clone form model
 */
export const GroupCloneFormControl = {
  initialValues: {
    sourceGroupId: "",
    groupId: "",
    description: "",
    isActive: true,
  } as GroupCloneFormModel,
  validationSchema: yup.object({
    sourceGroupId: yup.string().required(e.RequiredFieldLabel),
    groupId: yup.string().required(e.RequiredFieldLabel),
    description: yup
      .string()
      .required(e.RequiredFieldLabel)
      .max(20, e.Maximum20CharactersAllowedLabel),
    isActive: yup.boolean().required(e.RequiredFieldLabel),
  }),
};
