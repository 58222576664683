import { FC, ReactNode, useState } from "react";
import "./TPTooltipStyles.css";

interface TPTooltipProperties {
  children: ReactNode;
  text: string;
  isRichText?: boolean;
  width?: string | number;
  backgroundColor?: string;
  foregroundColor?: string;
}

export const TPTooltip: FC<TPTooltipProperties> = function ({ children, text, isRichText, width, backgroundColor, foregroundColor }) {

  return (
    <div className="tp-tooltip">
      {children}
      <label
        className="tp-tooltip-text"
        style={{
          width: width,
          backgroundColor: backgroundColor,
          color: foregroundColor
        }}
      >
        {isRichText ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
      </label>
    </div>
  )
}