import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  AdditionalDataTaskTypeInputDTO,
  AdditionalDataTaskTypeInputDTOValidator,
} from "@/models/AdditionalData/AdditionalDataInputDTO";
import { AdditionalDataTaskTypeViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
  AdditionalDataService,
  AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState } from "react";
import { AdditionalDataRelatedTypesEnum } from "../AdditionalData/AdditionalDataRelateAdmin";

type InsertUpdateProps = {
  topic: AdditionalDataRelatedTypesEnum;
  mode: string;
  parentRecordId: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  taskTypeId: string;
  additionalDataId: string;
  order: number;
  isMandatory: boolean;
  //validator
  taskTypeIdErrorMessage: string;
  orderErrorMessage: string;
  additionalDataIdErrorMessage: string;
  [key: string]: any;
};
const TaskTypeAdditionalDataInsertUpdate: FC<InsertUpdateProps> = ({
  topic,
  mode,
  parentRecordId,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "TaskTypeAdditionalDataInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "TaskTypeAdditionalDataInsertUpdate";
  const [additionalDataIdLabel, setAdditionalDataIdLabel] = useState("");
  const [orderLabel, setOrderLabel] = useState("");
  const [isMandatoryLabel, setIsMandatoryLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  let insertUpdateInitialState: InsertUpdateStateType = {
    taskTypeId: recordId,
    additionalDataId: "",
    order: 0,
    isMandatory: false,
    taskTypeIdErrorMessage: "",
    additionalDataIdErrorMessage: "",
    orderErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );
  let initialStateAdditionalDataList: Array<TPKeyValue> = [];
  const [additionalDataKeyValueList, setAdditionalDataKeyValueList] = useState(
    initialStateAdditionalDataList,
  );
  const loadResourcesAndLoadAddaAndTaskTypeInfo = async () => {
    let i: number;
    //resources state
    setAdditionalDataIdLabel(
      await TPI18N.GetText(resourceSet, "AdditionalDataIdLabel"),
    );
    setOrderLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
    setIsMandatoryLabel(await TPI18N.GetText(resourceSet, "IsMandatoryLabel"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    //screen state
    //Load Additional data Select
    if (mode == "Insert") {
      await getAdditionalDataList();
    }
    if (mode == "Update") {
      switch (topic) {
        case AdditionalDataRelatedTypesEnum.taskType:
          await getAdditionalDataTaskTypeById(recordId);
          break;

        default:
          break;
      }
    }
    setIsLoadingScreen(false);
  };

  const getAdditionalDataTaskTypeById = async (pRecordId: string) => {
    let serviceClient = new AdditionalDataTaskTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getAdditionalDataByIdTaskTypeAndIdAdda(
          parentRecordId,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let newAdditionalDataKeyValueListState: Array<TPKeyValue> = [];
      newAdditionalDataKeyValueListState.unshift({ key: "", value: "--" });
      newAdditionalDataKeyValueListState.push({
        key: responseRequest.additionalDataId,
        value: responseRequest.localizedAdditionalDataDescription,
      });
      setAdditionalDataKeyValueList(newAdditionalDataKeyValueListState);

      let recordInfo: AdditionalDataTaskTypeViewModel;
      recordInfo = { ...responseRequest };
      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.additionalDataId = recordInfo.additionalDataId;
      newInsertUpdateState.isMandatory = recordInfo.isMandatory;
      newInsertUpdateState.order = recordInfo.order;
      newInsertUpdateState.taskTypeId = recordInfo.taskTypeId;
      setInsertUpdateState(newInsertUpdateState);

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAdditionalDataTaskTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getAdditionalDataTaskTypeById ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const getAdditionalDataList = async () => {
    let componentService = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await componentService.getAll(
        false,
        true,
        expectedCodes,
      );

      let newAdditionalDataKeyValueListState: Array<TPKeyValue> =
        responseRequest.map(function (item) {
          return { key: item.id, value: item.localizedDescription };
        });
      newAdditionalDataKeyValueListState.unshift({ key: "", value: "--" });
      setAdditionalDataKeyValueList(newAdditionalDataKeyValueListState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAdditionalDataList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getAdditionalDataList ex`);
      return;
    }
  };

  const handleOnChange = (propertyName: string, newValue: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState[propertyName] = newValue;
    newInsertUpdateState[propertyName + "ErrorMessage"] = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handlerMaxValueNumericChange = (newMaxValue: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.order = newMaxValue;
    newInsertUpdateState.orderErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleOnCheckboxChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isMandatory = !newInsertUpdateState.isMandatory;
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };
  const handleOkButtonClick = async () => {
    let isInValid: boolean = false;

    let recordInputDTO: AdditionalDataTaskTypeInputDTO = {
      taskTypeId: parentRecordId,
      additionalDataId: insertUpdateState.additionalDataId,
      order: insertUpdateState.order,
      isMandatory: insertUpdateState.isMandatory,
    };

    let inputDTOValidator = new AdditionalDataTaskTypeInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.additionalDataId) {
        newInsertUpdateState.additionalDataIdErrorMessage =
          await TPI18N.GetResource(resultValidator.additionalDataId);
      } else {
        newInsertUpdateState.additionalDataIdErrorMessage = "";
      }
      if (resultValidator.taskTypeId) {
        newInsertUpdateState.taskTypeIdErrorMessage = await TPI18N.GetResource(
          resultValidator.taskTypeId,
        );
      } else {
        newInsertUpdateState.taskTypeIdErrorMessage = "";
      }
      if (resultValidator.order) {
        newInsertUpdateState.orderErrorMessage = await TPI18N.GetResource(
          resultValidator.order,
        );
      } else {
        newInsertUpdateState.orderErrorMessage = "";
      }
      setInsertUpdateState(newInsertUpdateState);
      isInValid = true;
    }

    if (!isInValid) {
      if (mode == "Insert") {
        switch (topic) {
          case AdditionalDataRelatedTypesEnum.taskType:
            await insertAdditionalDataTaskType(recordInputDTO);
            break;

          default:
            break;
        }
      } else {
        switch (topic) {
          case AdditionalDataRelatedTypesEnum.taskType:
            await updateAdditionalDataTaskType(recordInputDTO);
            break;

          default:
            break;
        }
      }
    }
  };

  const insertAdditionalDataTaskType = async (
    inputDTO: AdditionalDataTaskTypeInputDTO,
  ) => {
    let serviceClient = new AdditionalDataTaskTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertAdditionalDataTaskType(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      if (responseRequest.responseResult) {
        setIsLoadingScreen(false);
        if (responseRequest.responseResult) {
          callBackResult({ result: "OK", recordId: recordId });
        }
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateAdditionalDataTaskType = async (
    inputDTO: AdditionalDataTaskTypeInputDTO,
  ) => {
    let serviceClient = new AdditionalDataTaskTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateAdditionalDataTaskType(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateAdditionalDataTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} updateAdditionalDataTaskType ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadAddaAndTaskTypeInfo();
  }, []);

  return (
    <>
      <TPLoadingOverlay active={isLoadingScreen}>
        {/* <div className="row">
        <div className="col-12">
          {mode == "insert" ? (
            <TPPageSubTitle>
              {`Task typexx ${parentRecordId} / DescriptionTaskxx`}
            </TPPageSubTitle>
          ) : null}
        </div>
      </div> */}
        <div className="row">
          <div className="col-12">
            <TPPageSection>
              <div className="form-group">
                <TPSelect
                  id="IdSelect"
                  disabled={mode == "Update" ? true : false}
                  isMandatory={true}
                  onChange={(e: any, item: TPKeyValue) =>
                    handleOnChange("additionalDataId", item.key)
                  }
                  dataSource={additionalDataKeyValueList}
                  value={insertUpdateState.additionalDataId}
                  labelText={additionalDataIdLabel}
                  errorMessage={insertUpdateState.additionalDataIdErrorMessage}
                ></TPSelect>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="form-group">
                <div className="form-group">
                  <TPNumeric
                    id="IdTPNumeric"
                    labelText={orderLabel}
                    isMandatory={true}
                    value={insertUpdateState.order}
                    onChange={handlerMaxValueNumericChange}
                    maxLength={20}
                    errorMessage={insertUpdateState.orderErrorMessage}
                  />
                </div>
              </div>
            </TPPageSection>
          </div>
          <div className="col-6 mt-4">
            <TPPageSection>
              <div className="form-group">
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={isMandatoryLabel}
                  checked={insertUpdateState.isMandatory}
                  onChange={(e: any) => handleOnCheckboxChange()}
                ></TPCheckBox>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
                className={"ms-2"}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default TaskTypeAdditionalDataInsertUpdate;
