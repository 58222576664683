import React, {CSSProperties, useEffect, useState} from "react";
import {ContentVerticalTabInsertUpdateStyled} from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {TPPageTitle} from "@/components/TPPage/tpPageStyles";
import {
    ProjectNewControl,
    ProjectNewEnum as e,
    ProjectNewModel, SetConditionModel, SetModel,
} from "@/models/Project/Projects";
import {TPI18N} from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import ProjectNewStepper from "@/pages/Projects/ProjectNew/ProjectNewStepper";
import {EventProjectService} from "@/services/EventProjectService";

interface ProjectNewProps {
    tabId: string;
    id?: string;
    dispatch: Function;
}

export default function ProjectNew({tabId, id, dispatch}: ProjectNewProps) {
    const [isUpdate, setIsUpdate] = useState<boolean | null>(null);
    const [project, setProject] = useState<ProjectNewModel>(ProjectNewControl.initialValues);
    const [m, setMessages] = useState<any>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        load().then(() => setLoading(false))
    }, []);

    async function load() {
        const messages = {...m};
        // title label
        messages[e.TitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.TitleLabel);
        messages[e.UpdateTitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.UpdateTitleLabel);
        // step label
        messages[e.Step1TitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step1TitleLabel);
        messages[e.Step1DescriptionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step1DescriptionLabel);
        // step label
        messages[e.Step2FrontTitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2FrontTitleLabel);
        messages[e.Step2FrontSubtitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2FrontSubtitleLabel);
        messages[e.Step2FrontDescriptionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2FrontDescriptionLabel);
        messages[e.Step2BackTitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2BackTitleLabel);
        messages[e.Step2InputQueueLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2InputQueueLabel);
        messages[e.Step2AlertLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2AlertLabel);
        messages[e.Step2DescriptionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2DescriptionLabel);
        messages[e.Step2SetLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetLabel);
        // action option and modal
        messages[e.Step2SetCloneActionLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetCloneActionLabel);
        messages[e.Step2SetDeleteActionLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetDeleteActionLabel);
        messages[e.Step2SetDeleteTitleModalLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetDeleteTitleModalLabel);
        messages[e.Step2SetDeleteYesModalButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetDeleteYesModalButtonLabel);
        messages[e.Step2SetDeleteNoModalButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetDeleteNoModalButtonLabel);
        messages[e.Step2SetDeleteQuestionModalLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetDeleteQuestionModalLabel);
        // set form
        messages[e.Step2SetConfigurationLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetConfigurationLabel);
        messages[e.Step2SetInputDefaultAssignLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputDefaultAssignLabel);
        messages[e.Step2SetInputPriorityLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputPriorityLabel);
        messages[e.Step2SetInputAssignLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputAssignLabel);
        messages[e.Step2SetConditionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetConditionLabel);
        messages[e.Step2SetInputBaseFieldLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputBaseFieldLabel);
        messages[e.Step2SetInputComparisonOperationLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputComparisonOperationLabel);
        messages[e.Step2SetInputReferenceValueLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetInputReferenceValueLabel);
        messages[e.Step2SetInputAndOptionLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetInputAndOptionLabel);
        messages[e.Step2SetInputOrOptionLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.Step2SetInputOrOptionLabel);
        messages[e.Step2SetAddNewButtonLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.Step2SetAddNewButtonLabel);
        // form inputs label
        messages[e.FormInputIdLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputIdLabel);
        messages[e.FormInputNameLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputNameLabel);
        // language modal label
        messages[e.LanguageButtonTooltipLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.LanguageButtonTooltipLabel);
        messages[e.LanguageModalTitleLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.LanguageModalTitleLabel);
        messages[e.LanguageModalOkButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalOkButtonLabel);
        messages[e.LanguageModalCancelButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalCancelButtonLabel);
        // form inputs label
        messages[e.FormInputStructureTypeLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputStructureTypeLabel);
        messages[e.FormInputSupervisorLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputSupervisorLabel);
        messages[e.FormInputTypeLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputTypeLabel);
        // type option
        messages[e.FormInputTypeFrontOfficeOptionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputTypeFrontOfficeOptionLabel);
        messages[e.FormInputTypeBackOfficeOptionLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputTypeBackOfficeOptionLabel);
        // form inputs label
        messages[e.FormInputActiveLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormInputActiveLabel);
        // form actions label
        messages[e.FormActionCancelLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.FormActionCancelLabel);
        messages[e.FormActionContinueLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormActionContinueLabel);
        messages[e.FormActionNewProjectLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormActionNewProjectLabel);
        messages[e.FormActionEditProjectLabel] = await TPI18N.GetText(e.ProjectNewComponent, e.FormActionEditProjectLabel);
        // messages
        messages[e.MessageQueueAlreadyExists] = await TPI18N.GetText(e.ProjectNewComponent, e.MessageQueueAlreadyExists);


        setMessages(messages)

        if (id) {
            const {findOne} = new EventProjectService()
            const element = await findOne(id)
            setProject({
                projectId: element.id,
                name: element.name,
                nameLanguages: element?.names,
                recordType: element?.recordType,
                structureTypeId: element?.structureId,
                defaultQueueId: element?.defaultQueueId,
                supervisorId: element?.supervisorId,
                conditionsOrQueues: element?.conditionsOrQueues.map(s => {
                    const set = {
                        id: s.id,
                        eventProjectId: s.eventProjectId,
                        groupId: s.groupId,
                    } as SetModel;

                    if (s?.name) {
                        set.name = s?.name
                    }

                    if (s?.priority) {
                        set.priority = s?.priority
                    }

                    if (s?.conditions) {
                        set.conditions = s?.conditions?.map(c => {
                            const condition = {
                                eventLoadStructureDetailId: c.eventLoadStructureDetailId,
                                field: c?.field,
                                operation: c?.operation,
                                value: c?.value,
                            } as SetConditionModel;

                            if (c?.conditionType) {
                                condition.conditionType = c?.conditionType
                            }

                            return condition
                        })
                    }

                    return set
                }),
                isActive: element?.isActive,
                projectInUse: element?.projectInUse,

            } as ProjectNewModel)
        }
    }

    return (
        <ContentVerticalTabInsertUpdateStyled>
            <TPLoadingOverlay active={loading}>
                <div style={styles.container}>
                    {isUpdate !== null && (
                        <TPPageTitle style={styles.title}>
                            {m?.[isUpdate ? e.UpdateTitleLabel : e.TitleLabel]}
                        </TPPageTitle>
                    )}

                    {(!id || (id && project.projectId)) && (
                        <ProjectNewStepper
                            tabId={tabId}
                            dispatch={dispatch}
                            m={m}
                            isUpdate={isUpdate}
                            setIsUpdate={setIsUpdate}
                            setLoading={setLoading}
                            project={project}
                            setProject={setProject}
                        />
                    )}
                </div>
            </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled>
    );
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        paddingRight: "20px",
    } as CSSProperties,
    title: {
        textTransform: "none",
    } as CSSProperties
}
