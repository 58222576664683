import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import React, { ReactElement, useState } from "react";
import { camelCaseToId, camelCaseToPhrase } from "../../utils/text-regex";
import {
  StyledCheckPreferences,
  StyledPreferences,
} from "./dynamic-table-styles";
import { CustomColumnNames } from "./DynamicTable";

type TablePreferencesProps<T extends object> = {
  id?: string;
  tableData: T[];
  visibleColumns: (keyof T)[];
  hiddenColumns?: (keyof T)[];
  columnNames?: CustomColumnNames<T>;
  placeholder?: string;
  buttonValue?: string;
  lastColumn: keyof T | null;
  resetColumns: () => void;
  handleColumnVisibilityChange: (column: keyof T) => void;
};

function TablePreferences<T extends object>({
  id,
  tableData,
  visibleColumns,
  hiddenColumns,
  columnNames,
  lastColumn,
  placeholder,
  buttonValue,
  resetColumns,
  handleColumnVisibilityChange,
}: TablePreferencesProps<T>): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const sectionId = `${id ? `${id}-` : ""}table-preferences-selection`;
  const buttonId = `${id ? `${id}-` : ""}reset-preferences`;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCheckboxId = (value: string): string => {
    return `${camelCaseToId(value).toLocaleLowerCase()}-preference-check`;
  };

  const isCheckActive = (key: keyof T): string => {
    return visibleColumns.includes(key)
      ? `${disabledClassName(key)} active`
      : "";
  };

  const isCheckDisabled = (key: keyof T): boolean => {
    return visibleColumns.length === 1 && lastColumn === key;
  };

  const disabledClassName = (key: keyof T): string => {
    return isCheckDisabled(key) ? "disabled" : "";
  };

  const isCustomColumnsEnabled = (key: keyof T): boolean => {
    return (columnNames && columnNames[key] !== undefined) || false;
  };

  return (
    <>
      <StyledPreferences>
        <section
          className="preference-selection"
          id={sectionId}
          onClick={handleClick}
        >
          <p>{placeholder ? placeholder : "Preferences"}</p>
          <TPIcon iconType={TPIconTypes.expandMore} />
        </section>
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <StyledCheckPreferences>
            <TPButton
              id={buttonId}
              isDesignSystem={true}
              onClick={resetColumns}
            >
              {buttonValue ? buttonValue : "Reset to Default"}
            </TPButton>
            {Object.keys(tableData[0])
              .filter((key) => !hiddenColumns?.includes(key as keyof T))
              .map((key) => (
                <label
                  key={key}
                  htmlFor={getCheckboxId(key)}
                  className={isCheckActive(key as keyof T)}
                >
                  <TPCheckBox
                    id={getCheckboxId(key)}
                    checked={visibleColumns.includes(key as keyof T)}
                    onChange={() =>
                      handleColumnVisibilityChange(key as keyof T)
                    }
                    disabled={isCheckDisabled(key as keyof T)}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Noto Sans",
                      fontSize: "14px",
                      userSelect: "none",
                    }}
                  >
                    {isCustomColumnsEnabled(key as keyof T)
                      ? (columnNames![key as keyof T] ?? "")
                      : camelCaseToPhrase(key)}
                  </Typography>
                </label>
              ))}
          </StyledCheckPreferences>
        </Popover>
      </StyledPreferences>
    </>
  );
}

export default TablePreferences;
