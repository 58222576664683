import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const RESOURCE_SET = "TaskReminder";

interface TaskReminderLabels {
  Create: string;
  Update: string;
  Date: string;
  Time: string;
  Comments: string;
  Cancel: string;
  Save: string;
}

export const useTaskReminderLabels = function () {

  const [labels, setLabels] = useState<TaskReminderLabels>({} as TaskReminderLabels);

  const loadLabels = async function () {
    setLabels({
      Update: await TPI18N.GetText(RESOURCE_SET, "Update"),
      Cancel: await TPI18N.GetText(RESOURCE_SET, "Cancel"),
      Comments: await TPI18N.GetText(RESOURCE_SET, "Comments"),
      Create: await TPI18N.GetText(RESOURCE_SET, "Create"),
      Date: await TPI18N.GetText(RESOURCE_SET, "Date"),
      Save: await TPI18N.GetText(RESOURCE_SET, "Save"),
      Time: await TPI18N.GetText(RESOURCE_SET, "Time"),
    })
  }

  useEffect(() => {
    loadLabels();
  }, [])

  return { labels }
}