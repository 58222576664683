import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface WorkflowDetailPredecessorInputDTO {
  successorId: number;
  predecessorId: number;
  lag: number | null;
  lagUnit: string | null;
  dateField: string;
  conditionalPath: string;
  reopen: boolean;
}
export interface WorkflowDetailPredecessorMultipleInputDTO {
  workflowDetailId: string;
  workflowDetailPredecessorList: WorkflowDetailPredecessorInputDTO[];
}

export class WorkflowDetailPredecessorMultipleInputDTOValidator extends Validator<WorkflowDetailPredecessorMultipleInputDTO> {
  constructor() {
    super();

    const resourceSet = "WorkflowDetailPredecessorInsertUpdateComponent";

    try {
      this.ruleForEach("workflowDetailPredecessorList")
        .must((x) => {
          if (!x.lagUnit) {
            return true;
          }
          return x.lagUnit.length < 20;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLagUnitLength")

        .must((x) => {
          if (!x.lagUnit) {
            return true;
          }
          return TPGlobal.TPSanitize(x.lagUnit) == x.lagUnit;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLagUnit")

        .must((x) => {
          return x.dateField.length < 20;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDateFieldLength")

        .must((x) => {
          if (!x.lagUnit) {
            return true;
          }
          return TPGlobal.TPSanitize(x.lagUnit) == x.lagUnit;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDateField")

        .must((x) => {
          if (!x.lag) {
            return true;
          }
          return x.lag > 0;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLag");
    } catch (error) {}
  }
}
