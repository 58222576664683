import TPGlobal from "@/helpers/TPGlobal";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPFixedTable from "../TPFixedTable/TPFixedTable";
import {
  TPConditionsContainer,
  TPConditionsTableContainer,
  TPTotalConditions,
} from "./TPConditionsTableStyles";

interface TPCondition {
  id: string;
  organizationRelation: string;
  filterType: string;
  conditionText: string;
}

interface TPConditionsTableProps {
  conditions: TPCondition[];
  onDelete: Function;
  deletable: boolean;
  showQuery: boolean;
}

const TPConditionsTable = ({
  conditions,
  onDelete,
  deletable,
  showQuery,
}: TPConditionsTableProps) => {
  const resourceSet = "TPConditionsTableComponent";
  //   resources
  const [queryExpressionLabel, setqueryExpressionLabel] = useState("");
  const [andLabel, setAndLabel] = useState("");
  const [orLabel, setOrLabel] = useState("");
  const [conditionLabel, setconditionLabel] = useState("");
  const [filterTypeLabel, setfilterTypeLabel] = useState("");
  const [organizationRelationLabel, setorganizationRelationLabel] =
    useState("");
  const [columns, setColumns] = useState<Array<any>>([]);
  const [orderedConditions, setOrderedConditions] = useState<
    Array<TPCondition>
  >([]);

  const handleDeleteClickButton = (condition: string) => {
    if (onDelete && typeof onDelete == "function") onDelete(condition);
  };

  const [types, setTypes] = useState<TPCondition[]>();

  const loadResources = async () => {
    setqueryExpressionLabel(
      await TPI18N.GetText(resourceSet, "queryExpressionLabel"),
    );
    setAndLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "AndLabel"));
    setOrLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OrLabel"));
    setconditionLabel(await TPI18N.GetText(resourceSet, "conditionLabel"));
    setfilterTypeLabel(await TPI18N.GetText(resourceSet, "filterTypeLabel"));
    setorganizationRelationLabel(
      await TPI18N.GetText(resourceSet, "organizationRelationLabel"),
    );
  };

  const fixedColumns = [
    {
      style: { width: "100px" },
      name: "",
      cell: (row: { [x: string]: any }) => {
        return (
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            icon={TPIconTypes.delete}
            onClick={() => {
              handleDeleteClickButton(row["id"]);
            }}
            style={{ color: "red" }}
          />
        );
      },
      selector: (row: { [x: string]: any }) => row["id"],
      sortable: false,
    },
    {
      name: organizationRelationLabel,
      cell: (row: { [x: string]: any }) => {
        return row["organizationRelation"];
      },
      selector: (row: { [x: string]: any }) => row["organizationRelation"],
      sortable: false,
    },
    {
      name: filterTypeLabel,
      cell: (row: { [x: string]: any }) => {
        return row["filterType"];
      },
      selector: (row: { [x: string]: any }) => row["filterType"],
      sortable: false,
    },
    {
      name: conditionLabel,
      cell: (row: { [x: string]: any }) => {
        return row["conditionText"];
      },
      selector: (row: { [x: string]: any }) => row["conditionText"],
      sortable: false,
    },
  ];

  const fixedColumnsNoDelete = [
    {
      name: organizationRelationLabel,
      cell: (row: { [x: string]: any }) => {
        return row["organizationRelation"];
      },
      selector: (row: { [x: string]: any }) => row["organizationRelation"],
      sortable: false,
    },
    {
      name: filterTypeLabel,
      cell: (row: { [x: string]: any }) => {
        return row["filterType"];
      },
      selector: (row: { [x: string]: any }) => row["filterType"],
      sortable: false,
    },
    {
      name: conditionLabel,
      cell: (row: { [x: string]: any }) => {
        return row["conditionText"];
      },
      selector: (row: { [x: string]: any }) => row["conditionText"],
      sortable: false,
    },
  ];

  const queryJsx = (
    <div className="row mt-1">
      <TPConditionsContainer>
        <div>
          <h5>
            {queryExpressionLabel}
            <TPTotalConditions>{orderedConditions.length}</TPTotalConditions>
          </h5>
        </div>
        <span>
          <span>(</span>
          {types &&
            types.length > 0 &&
            types.map((type: TPCondition, typeIndex: number) => {
              const filteredConditions = orderedConditions.filter(
                (k) => k.filterType == type.filterType,
              );
              return (
                <span key={typeIndex}>
                  <span>{"("}</span>
                  {filteredConditions &&
                    filteredConditions.length > 0 &&
                    filteredConditions.map(
                      (condition: TPCondition, conditionIndex: number) => {
                        return (
                          <span key={conditionIndex}>
                            <strong>{type.filterType}</strong>
                            <span>{" = "}</span>
                            <span style={{ wordBreak: "break-all" }}>
                              {condition.conditionText}
                            </span>
                            {conditionIndex + 1 < filteredConditions.length
                              ? ` ${orLabel} `
                              : ""}
                          </span>
                        );
                      },
                    )}
                  <span>{")"}</span>
                  {typeIndex + 1 < types.length ? ` ${andLabel} ` : ""}
                </span>
              );
            })}
          <span>)</span>
        </span>
      </TPConditionsContainer>
    </div>
  );

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    setOrderedConditions(
      [...conditions].sort((a, b) => {
        if (a.filterType < b.filterType) {
          return -1;
        }
        if (a.filterType > b.filterType) {
          return 1;
        }
        return 0;
      }),
    );

    const newTypes: any[] = [];

    conditions.forEach((item: TPCondition) => {
      var indexItem = newTypes.findIndex(
        (k) => k.filterType == item.filterType,
      );
      if (indexItem == -1) {
        newTypes.push(item);
      }
    });

    setTypes(newTypes);
  }, [conditions]);

  // useEffect(() => {
  //   if (queryExpressionLabel && conditionLabel && filterTypeLabel) {
  //     setupGridColumns();
  //   }
  // }, [queryExpressionLabel, conditionLabel, filterTypeLabel]);

  return (
    <TPConditionsTableContainer>
      <TPFixedTable
        columns={deletable ? fixedColumns : fixedColumnsNoDelete}
        dataGrid={orderedConditions}
        keyField={"id"}
        tableHeight={"700px"}
      />
      {showQuery && queryJsx}
    </TPConditionsTableContainer>
  );
};

export default TPConditionsTable;
