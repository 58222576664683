import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useFormik } from "formik";
import { GroupCloneFormControl } from "./models/GroupCloneFormControl";
import e from "./models/GroupCloneFormEnum";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import GroupCloneFormModel from "./models/GroupCloneFormModel";
import { Box, SxProps } from "@mui/material";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { SequenceService } from "@/services/SequenceService";
import { GroupsService } from "@/services/GroupsService";
import { commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  title: {
    textTransform: "none",
  } as CSSProperties,
  form: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginBottom: "20px",
    gap: "10px",
  } as CSSProperties,
  width50: {
    width: "50%",
  } as CSSProperties,
  nameContainer: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    placeContent: "flex-end flex-start",
    alignItems: "flex-end",
    gap: "10px",
  } as CSSProperties,
  actions: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    placeContent: "flex-end",
    alignItems: "flex-end",
    gap: "10px",
  } as SxProps,
  cancelButton: {
    backgroundColor: "white",
    color: "#a00095",
    border: "1px solid #a00095",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface GroupCloneEvents {
  /**
   * allows you to reload the data source
   */
  load: () => void;
}

/**
 * properties that the child component needs
 */
export interface GroupCloneProps {
  /**
   * vertical tab identifier
   */
  tabId: string;
  /**
   * vertical tab event runner
   */
  verticalTabDispatch: Function;
  /**
   * element
   */
  element: any;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of project detail
 */
const GroupClone = forwardRef(
  ({ tabId, verticalTabDispatch: dispatch, element }: GroupCloneProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * Messages that are rendered in the view depending on the language
     */
    const [m, setMessages] = useState<{ [attribute: string]: string }>({});
    /**
     * loading event
     */
    const [loading, setLoading] = useState(true);
    /**
     * form controller
     */
    const formik = useFormik({
      initialValues: {
        ...GroupCloneFormControl.initialValues,
        sourceGroupId: element.groupId,
      } as GroupCloneFormModel,
      validationSchema: GroupCloneFormControl.validationSchema,
      validateOnChange: false,
      onSubmit: () => {
        onClickSaveButtonHandler();
      },
    });
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as GroupCloneEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources().then(() => setLoading(false));
      loadIdentifier();
    }, []);
    /**
     * event on component close
     */
    useEffect(
      () => () => {
        setMessages({});
        setLoading(false);
      },
      []
    );
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
      const messages = { ...m };
      // title label
      messages[e.TitleLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.TitleLabel
      );
      messages[e.DescriptionLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.DescriptionLabel
      );
      // form labels
      messages[e.ElementToCloneLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.ElementToCloneLabel
      );
      messages[e.NewElementIdLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.NewElementIdLabel
      );
      messages[e.DescriptionLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.DescriptionLabel
      );
      messages[e.IsActiveLabel] = await TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.IsActiveLabel
      );
      // section action labels
      messages[e.CancelButtonLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.CancelButtonLabel
      );
      messages[e.OkButtonLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.OkButtonLabel
      );

      setMessages(messages);
    }
    /**
     * identifier generator
     */
    async function loadIdentifier() {
      const { generalAutomaticId } = new SequenceService();
      generalAutomaticId(
        false,
        true,
        [200],
        SequenceGeneratorSequencesNameEnum.SQGROU
      )
        .then(({ responseData }) => {
          formik.setFieldValue("groupId", responseData?.data[0]?.sequenceCode);
        })
        .catch((error) => console.error(error));
    }
    /**
     * function in charge of saving the clone
     */
    function onClickSaveButtonHandler() {
      const request = {
        ...formik.values,
      };

      const { clone } = new GroupsService();
      clone(request)
        .then((response) => {
          dispatch({ type: event.reloadGrid });
          dispatch({ type: event.vertical_tab_close, payload: tabId });
          dispatch({
            type: event.update_vertical_tab,
            payload: {
              recordId: response?.responseData?.keyList?.[0].value,
              recordDescription: request.groupId,
            },
          });
        })
        .catch((error) => console.error(error));
    }
    /**
     * function responsible for closing the tab
     */
    function onClickCancelButtonHandler() {
      dispatch({ type: event.reloadGrid });
      dispatch({ type: event.vertical_tab_close, payload: tabId });
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <TPLoadingOverlay active={loading}>
        <div
          id="GroupCloneContainer"
          key="GroupCloneContainer"
          style={styles.container}
        >
          <TPPageTitle
            id="GroupCloneTitle"
            key="GroupCloneTitle"
            style={styles.title}
          >
            {m?.[e.TitleLabel]}
          </TPPageTitle>

          <hr style={styles.width50} />

          <form id="GroupCloneForm" key="GroupCloneForm" style={styles.form}>
            <div style={styles.width50}>
              <TPTextBox
                id="GroupCloneFormInputSourceId"
                key="GroupCloneFormInputSourceId"
                disabled={true}
                labelText={m?.[e.ElementToCloneLabel]}
                isMandatory={true}
                value={`${element.user?.name} (${element.user?.login})`}
                onChange={() => {}}
                errorMessage={
                  formik.errors.sourceGroupId &&
                  m?.[formik.errors.sourceGroupId]
                }
              />
            </div>

            <div style={styles.width50}>
              <TPTextBox
                id="GroupCloneFormInputId"
                key="GroupCloneFormInputId"
                disabled={true}
                labelText={m?.[e.NewElementIdLabel]}
                isMandatory={true}
                value={formik.values.groupId}
                onChange={() => {}}
                errorMessage={
                  formik.errors.groupId && m?.[formik.errors.groupId]
                }
              />
            </div>

            <div style={styles.width50}>
              <TPTextBox
                id="GroupCloneFormInputName"
                key="GroupCloneFormInputName"
                isMandatory={true}
                labelText={`${m?.[e.DescriptionLabel]}`}
                value={formik.values?.description ?? ""}
                onChange={(e: any) =>
                  formik.setFieldValue("description", e.target.value)
                }
                maxLength={100}
                errorMessage={
                  formik.errors.description && m?.[formik.errors.description]
                }
              />
            </div>

            <TPCheckBox
              id="GroupCloneFormInputIsActive"
              labelText={m?.[e.IsActiveLabel]}
              checked={formik.values.isActive}
              onChange={() =>
                formik.setFieldValue("isActive", !formik.values.isActive)
              }
            />
          </form>

          <Box
            id="GroupCloneSectionActions"
            key="GroupCloneSectionActions"
            sx={styles.actions}
          >
            <TPButton
              id="GroupCloneSectionActionsButtonCancel"
              key="GroupCloneSectionActionsButtonCancel"
              style={styles.cancelButton}
              type={TPButtonTypes.link}
              onClick={onClickCancelButtonHandler}
            >
              {m?.[e.CancelButtonLabel]}
            </TPButton>

            <TPButton
              id="GroupCloneSectionActionsButtonSave"
              key="GroupCloneSectionActionsButtonSave"
              type={TPButtonTypes.primary}
              onClick={onClickSaveButtonHandler}
            >
              {m?.[e.OkButtonLabel]}
            </TPButton>
          </Box>
        </div>
      </TPLoadingOverlay>
    );
  }
);

export default GroupClone;
