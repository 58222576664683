import { RecordLocalizedModel } from "../Global/TPGlobalModels";



export class DistributionModel {
    id: string = "";
    description: string = "";
    isActive: boolean = false;
    descriptionLocalizedValues: Array<DescriptionLocalizedValues> = [];
}


export class DistributionUpdateModel {
    id: string = "";
    description: string = "";
    isActive: boolean = false;
    descriptionLocalizedValues: Array<DescriptionLocalizedValues> = [];
    distributionListAddress: Array<EmailAddressModel> = new Array<EmailAddressModel>();   
}

export class EmailAddressModel {
    name: string = "";
    email: string = "";
}

export class TableDistributionModel {
    id: string = "";
    description: string = "";
    isActive: boolean = false;
    descriptionLocalizedValues: string = "";
    localizedDescription:string="";
}


export class EmailDistributionModel {
    id: string = "";
    eamilAddress: string = "";
    emailName: string = "";
    result: string = "";
}


export type DescriptionLocalizedValues = {
    languageId: string;
    localizedValue: string;
};