import { Validator } from "fluentvalidation-ts";
import { RuleActionType } from "../Global/TPGlobalEnums";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { InboundMailRuleDefinitionJsonRuleViewModel } from "./InboundMailRuleDefinitionViewModel";

export interface InboundMailRuleDefinitionDTO {
  id: string;
  description: string;
  webServiceCLassifierId: string;
  actionType: string;
  jsonRule: InboundMailRuleDefinitionJsonRuleViewModel[];
  isActive: boolean;
  descriptionLocalizedValues: RecordLocalizedModel[];
}

export const validateNotEmpty = ({
  languageId,
  localizedValue,
  order,
}: RecordLocalizedModel) =>
  order === 1 ? Boolean(languageId && localizedValue && order) : true;

export const validate = (item: InboundMailRuleDefinitionJsonRuleViewModel) => {
  const {
    RuleNumber,
    ParenthesisOpen,
    BaseField,
    ComparisonOperator,
    ReferenceValue,
    ParenthesisClose,
  } = item;

  // return Object.values(item).find((item) => !item);
  return Boolean(RuleNumber && BaseField && ReferenceValue);
};

export const isCreateCase = (insertTypeValue: string) =>
  insertTypeValue === `${RuleActionType.CREATECASE}`;

export class InboundMailRuleDefinitionInputDTOValidator extends Validator<InboundMailRuleDefinitionDTO> {
  constructor() {
    super();
    const resourceSet: string = "InboundMailboxesRulesContainerInsertUpdate"; //InboundMailboxesRulesInsertUpdateComponent

    try {
      this.ruleFor("id")
        .notNull()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserId")

        .notEmpty()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserId");

      this.ruleFor("description")
        .notNull()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserDescription")

        .notEmpty()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserDescription");

      this.ruleForEach("descriptionLocalizedValues")
        .notNull()
        .withMessage(
          resourceSet + "|InboundMailboxesRulesInserDescriptionLocalizedValues",
        )

        .must(validateNotEmpty)
        .withMessage(
          resourceSet + "|InboundMailboxesRulesInserDescriptionLocalizedValues",
        );

      this.ruleFor("webServiceCLassifierId")
        .notNull()
        .withMessage(
          resourceSet + "|InboundMailboxesRulesInserWebServiceCLassifierId",
        )

        .notEmpty()
        .withMessage(
          resourceSet + "|InboundMailboxesRulesInserWebServiceCLassifierId",
        )
        .when((x) => isCreateCase(x.actionType));

      this.ruleFor("actionType")
        .notNull()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserActionType")

        .notEmpty()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserActionType");

      this.ruleFor("isActive")
        .notNull()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserIsActive");

      this.ruleForEach("jsonRule")
        .notNull()
        .withMessage(resourceSet + "|InboundMailboxesRulesInserJsonRule")

        .must(validate)
        .withMessage(resourceSet + "|InboundMailboxesRulesInserJsonRule");
    } catch (error) {}
  }
}
