import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ProjectDetailEnum as e } from "@/models/Project/Projects";
import { UserGroupViewModel } from "@/models/UserGroups/UserGroupModels";
import TablePager from "@/modules/core/components/dynamic-table/TablePager";
import { Tooltip } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";

/**
 * STYLES
 */
const styles = {
    row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    column: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
    spaceBetween: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center space-between",
        alignItems: "center",
    } as CSSProperties,
    input: {
        width: "250px",
    } as CSSProperties,
    userContainer: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center flex-start",
        alignItems: "center",
        flex: "1 1 100%",
        maxWidth: "32%",
        gap: "5px",
    } as CSSProperties,
    user: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 0%",
        overflow: "hidden",
    } as CSSProperties,
    name: {
        fontSize: "14px",
        fontWeight: "bold",
    } as CSSProperties,
    email: {
        fontSize: "12px",
    } as CSSProperties,
    online: {
        width: "10px",
        height: "10px",
        background: "#72f6dd78",
        borderRadius: "50%",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * 
 */
interface ProjectReassignTableProps {
    /**
     * Messages that are rendered in the view depending on the language
     */
    m: { [attribute: string]: string };
    /**
     * system users by group
     */
    users: Array<UserGroupViewModel>;
    /**
     * users selected to be reassigned
     */
    selected: Array<string>;
    /**
     * set users selected to be reassigned
     */
    setSelected: Function;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * project reassign table component
 */
export default function ProjectReassignTable({
    m,
    users,
    selected,
    setSelected
}: ProjectReassignTableProps) {
    /**
     * ATTRIBUTES
     */
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(12);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<{ key: keyof any; direction: any } | null>(null);
    /**
     * ATTRIBUTES END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
    }, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    const filteredData = React.useMemo(() => {
        if (!searchTerm) return users;

        return users.filter(({ user }) =>
            Object.values(user).some(val => String(val).toLowerCase().includes(searchTerm.toLocaleLowerCase()))
        );
    }, [users, searchTerm]);

    const sortedData = React.useMemo(() => {
        if (sortConfig !== null) {
            return [...filteredData].sort((a: any, b: any) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredData;
    }, [filteredData, sortConfig]);

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);

    const paginatedData = React.useMemo(() => {
        if (rowsPerPage === -1) return sortedData;
        const start = (currentPage - 1) * rowsPerPage;
        return sortedData.slice(start, start + rowsPerPage);
    }, [sortedData, currentPage, rowsPerPage]);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setRowsPerPage(parseInt(value));
        setCurrentPage(1);
    };

    function isSelected(id: string): boolean {
        return selected.includes(id)
    }

    function onChangeHandler(id: string) {
        const s = [...selected];
        const index = selected.indexOf(id);

        if (index === -1) {
            s.push(id)
        } else {
            s.splice(index, 1);
        }
        setSelected(s)
    }

    function isAllSelected(): boolean {
        return users.length === selected.length
    }

    function onChangeAllHandler() {
        if (isAllSelected()) {
            setSelected([])
        } else {
            setSelected(users.map(({ user }) => user.userGuid))
        }
    }

    function show() {
        const data: Array<Array<any>> = []

        paginatedData.map(d => {
            if (data.length === 0) {
                data.push([d])
            } else {
                if (data[data.length - 1].length === 3) {
                    data.push([d])
                } else {
                    data[data.length - 1].push(d)
                }
            }

            return d
        })

        return (
            <div style={{ ...styles.column, overflowY: "auto" }}>
                {data?.map((r, i) => (
                    <div key={`row-${i}`} style={{ ...styles.row, padding: "5px 10px", gap: "10px", backgroundColor: i % 2 !== 0 ? "" : "#f7f7f7" }}>
                        {r?.map(({ user }, index) => (
                            <div key={`user-${index}`} style={styles.userContainer}>
                                <div style={{
                                    ...styles.online,
                                    background: user.isOffline ? "#72f6dd78" : "transparent" 
                                }} />

                                <TPCheckBox
                                    id={`user-${user.userGuid}`}
                                    key={`user-${user.userGuid}`}
                                    checked={isSelected(user.userGuid)}
                                    onChange={() => onChangeHandler(user.userGuid)}
                                />

                                <div style={styles.user}>
                                    {!!user.name && (
                                        <Tooltip title={user.name}>
                                            <span style={styles.name}>
                                                {user.name}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {!!user.email && (
                                        <Tooltip title={user.email}>
                                            <span style={styles.email}>
                                                {user.email}
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        ))}

                    </div>
                ))}
            </div>
        )
    }
    /**
     * FUNCTIONS EMD
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <div>
            <div style={{ ...styles.spaceBetween, marginBottom: "10px" }}>
                {users.length > 0 ? (
                    <TPCheckBox
                        id={`all-user`}
                        key={`all-user`}
                        labelText={`${m?.[e.ModalActionSelectAllLabel]} (${users.length})`}
                        checked={isAllSelected()}
                        onChange={onChangeAllHandler}
                    />
                ) : (<div></div>)}

                <TPTextBox
                    id="actionInputSearch"
                    key="actionInputSearch"
                    icon={TPIconTypes.search}
                    containerStyle={styles.input}
                    withIcon={true}
                    isHorizontal={true}
                    value={`${searchTerm ?? ''}`}
                    placeholder={m?.[e.ActionInputSearchLabel]}
                    onChange={({ target }: any) => handleSearch(target.value)}
                />
            </div>

            {show()}

            <TablePager
                page={currentPage}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                resultsPerPage={paginatedData.length}
                allResults={filteredData.length}
                selectedResults={selectedItems.length}
                ofLabel={''}
                allLabel={`${m?.[e.ModalPaginationSelectAllLabel]}`}
                pageLabel={''}
                nextPageLabel={m?.[e.ModalActionNextPageLabel]}
                onPagerChange={setCurrentPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                customRowsPerPageOptions={[12, 24, 48, 96, -1]}
            />
        </div>
    );
}
