import { appInsights } from "@/helpers/AppInsights";
//log types
export enum TPLogType {
  INFO = "INFO",
  ERROR = "ERROR",
}

export class TPLog {
  public static Log(
    fromModule: string,
    type: TPLogType,
    messageToSend: any,
  ): void {
    //todo implent to send data to server
    let d = new Date();

    if (type == TPLogType.INFO) {
      let messagePayloadInfo: any = {
        message: messageToSend,
        properties: { module: fromModule },
        severityLevel: 1,
      };
      appInsights.trackTrace(messagePayloadInfo);
    } else {
      let messagePayloadError: any = {
        message: messageToSend,
        properties: { module: fromModule },
        severityLevel: 3,
      };
      appInsights.trackTrace(messagePayloadError);
    }
  }
}
