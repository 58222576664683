/**
 * email template clone enum
 */
enum EmailTemplateCloneEnum {
  /**
   * store attribute name and component name
   */
  EmailTemplateCloneForm = "EmailTemplateCloneForm",

  /**
   * keyword resource set local
   */
  EmailTemplateCloneFormComponent = "EmailTemplateCloneFormComponent",

  /**
   * labels
   */

  /**
   * Title label
   */
  TitleLabel = "TitleLabel",

  /**
   * element to clone label
   */
  ElementToCloneLabel = "ElementToCloneLabel",

  /**
   * new template id label
   */
  NewTemplateIdLabel = "NewTemplateIdLabel",

  /**
   * description label
   */
  DescriptionLabel = "DescriptionLabel",

  /**
   * email account label
   */
  EmailAccountLabel = "EmailAccountLabel",

  /**
   * parameters label
   */
  ParametersLabel = "ParametersLabel",

  /**
   * is active label
   */
  IsActiveLabel = "IsActiveLabel",

  /**
   * can be used in a send email tast label
   */
  CanBeUsedLabel = "CanBeUsedLabel",

  /**
   * required field label
   */
  RequiredFieldLabel = "RequiredFieldLabel",

  /**
   * maximum 20 characters allowed label
   */
  Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

  /**
   * maximum 100 characters allowed label
   */
  Maximum100CharactersAllowedLabel = "Maximum100CharactersAllowedLabel",

  /**
   * save label when clicking the save button
   */
  OkButtonLabel = "OkButtonLabel",

  /**
   * cancel label when clicking the cancel button
   */
  CancelButtonLabel = "CancelButtonLabel",

  /**
   * language IconButton tooltip
   */
  LanguageButtonTooltipLabel = "LanguageButtonTooltipLabel",

  /**
   * modal title label
   */
  LanguageModalTitleLabel = "LanguageModalTitleLabel",

  /**
   *
   */
  ConfirmModalTitleLabel = "ConfirmModalTitleLabel",

  /**
   *
   */
  ConfirmModalQuestionLabel = "ConfirmModalQuestionLabel",

  /**
   *
   */
  ConfirmModalOkButtonLabel = "ConfirmModalOkButtonLabel",

  /**
   * Notification messages
   */

  /**
   * Notification message when a template is successfully cloned
   */
  CloneSaveSuccessMessage = "CloneSaveSuccessMessage",

  /**
   * Notification message when you clone a template and get an error
   */
  CloneSaveErrorMessage = "CloneSaveErrorMessage",

  /**
   * Notification message when you clone a template and get an error
   */
  CloneGetMessageErrorMessage = "CloneGetMessageErrorMessage",
}

export default EmailTemplateCloneEnum;
