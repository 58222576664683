export const CopilotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="url(#paint0_linear_9511_2972)"
        d="M18.318.27l-1.26 2.75-2.74 1.25 2.74 1.26 1.26 2.74 1.25-2.74 2.75-1.26-2.75-1.25m-11.25.25l-2.5 5.5-5.5 2.5 5.5 2.5 2.5 5.5 2.5-5.5 5.5-2.5-5.5-2.5m7.5 5.5l-1.26 2.74-2.74 1.26 2.74 1.25 1.26 2.75 1.25-2.75 2.75-1.25-2.75-1.26"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_9511_2972"
          x1="0.318"
          x2="22.581"
          y1="11.271"
          y2="11.271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#780096"></stop>
          <stop offset="1" stopColor="#8051FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}