import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { FunctionsInputDTO } from "@/models/Functions/FunctionsInputDTO";
import { FunctionsViewModel } from "@/models/Functions/FunctionsModels";

export class AdministrationUsersStationService {




    public async deleteById(
        id: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let serviceFileName = "AdministrationUsersStationService.ts";
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/mem_clientlist/" + id;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.deleteData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} deleteFunctionById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} deleteFunctionById`);
            throw new Error(`Error ${serviceFileName} deleteFunctionById`);
        }
    }

    public async getByIdOfCase(
        id: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<any> {
        let serviceFileName = "AdministrationUsersStationService.ts";
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/mem_clientlist/station/";
        url = url + id;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData;
        } catch (error) {
            TPLog.Log(
                `Error ${serviceFileName} getFunctionById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${serviceFileName} getFunctionById`);
            throw new Error(`Error ${serviceFileName} getFunctionById`);
        }
    }



}
