import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalImageSelector, {
  TPModalImageSelectorState,
} from "@/layouts/TPModalImageSelector/TPModalImageSelector";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  CaseCustomStateInputDTOValidator,
  CaseCustomStatesInputDTO,
} from "@/models/CaseCustomStates/CaseCustomStatesInputDTO";
import { CaseCustomStateViewModel } from "@/models/CaseCustomStates/CaseCustomStatesModel";
import { SequenceGeneratorSequencesNameEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { BranchService } from "@/services/BranchService";
import { CaseCustomStatesService } from "@/services/CaseCustomStatesService";
import { ImageService } from '@/services/ImageService';
import { HierarchyFolderTypeService } from "@/services/HierarchyFolderService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { FC, ReactElement, useEffect, useState } from "react";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  imageId: string;
  image: string | undefined;
  customStateType: string;
  order: number;

  //validator
  idErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  imageErrorMessage: string;
  orderErrorMessage: string;
  typeErrorMessage: string;
};

type dataSourceXX = {
  key: string;
  value: string;
};

const CaseCustomStatesInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "CaseCustomStatesInsertUpdate.tsx";
  let i: number;
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "CaseCustomStateInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [imageLabel, setImageLabel] = useState("");
  const [orderLabel, setOrderLabel] = useState("");
  const [typeLabel, setTypeLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [acceptButtonLabel, setAcceptButtonLabel] = useState("");
  const [
    selectCaseCustomStatesImageTitle,
    setSelectCaseCustomStatesImageTitle,
  ] = useState("");
  const [
    selectedCaseCustomStatesImageLabel,
    setSelectedCaseCustomStatesImageLabel,
  ] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");

  //State modal
  let modalImageSelectorInitialState: TPModalImageSelectorState = {
    isShown: false,
    callBackData: {},
  };

  const [modalImageSelectorState, setModalImageSelectorState] = useState(
    modalImageSelectorInitialState,
  );

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    recordLanguageList: [],
    imageId: "",
    image: "",
    customStateType: "",
    order: 0,
    idErrorMessage: "",
    descriptionErrorMessages: initialErrorMessages,
    imageErrorMessage: "",
    orderErrorMessage: "",
    typeErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  let initialStateTypeList: Array<TPKeyValue> = [];
  const [typeKeyValue, setTypeKeyValue] = useState(initialStateTypeList);

  //Multilanguage const
  const multilanguageTableName: String = "CuStomSTate";
  const multilanguageFieldName: String = "Description_CSST";
  //#endregion

  const loadResourcesAndLoadFunctionInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setAcceptButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setImageLabel(await TPI18N.GetText(resourceSet, "ImageLabel"));
    setOrderLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
    setTypeLabel(await TPI18N.GetText(resourceSet, "TypeLabel"));
    setSelectCaseCustomStatesImageTitle(
      await TPI18N.GetText(resourceSet, "SelectCustomStatesImageTitle"),
    );
    setSelectedCaseCustomStatesImageLabel(
      await TPI18N.GetText(resourceSet, "SelectedCustomStatesImageLabel"),
    );

    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    //screen state

    let branchService = new BranchService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await branchService.getFirstLevelBranches(
        TPGlobal.idTreeForCustomStates, // todo
        TPActiveOptions.ACTIVE,
        TPActiveOptions.ACTIVE,
        false,
        true,
        expectedCodes,
      );

      let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        },
      );
      newTypeKeyValueList.unshift({ key: "", value: "--" });
      setTypeKeyValue(newTypeKeyValueList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    if (mode == "Update") {
      await getCaseCustomStateById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleCustomStateTypeChange = (newCustomStateType: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.customStateType = newCustomStateType;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOrderChange = (newOrder: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.order = newOrder;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;
    let recordInputDTO: CaseCustomStatesInputDTO = {
      // id: insertUpdateState.id,
      id: mode === "Insert" ? referenceId : recordId,
      description: insertUpdateState.recordLanguageList[0].value,
      imageId: insertUpdateState.imageId,
      customStateTypeBranchId: insertUpdateState.customStateType,
      order: insertUpdateState.order,
      otherLocalizedValues: [],
    };

    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new CaseCustomStateInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }
      if (resultValidator.otherLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }
      if (resultValidator.imageId) {
        newInsertUpdateState.imageErrorMessage = await TPI18N.GetResource(
          resultValidator.imageId,
        );
      } else {
        newInsertUpdateState.imageErrorMessage = "";
      }
      if (resultValidator.order) {
        newInsertUpdateState.orderErrorMessage = await TPI18N.GetResource(
          resultValidator.order,
        );
      } else {
        newInsertUpdateState.orderErrorMessage = "";
      }
      if (resultValidator.customStateTypeBranchId) {
        newInsertUpdateState.typeErrorMessage = await TPI18N.GetResource(
          resultValidator.customStateTypeBranchId,
        );
      } else {
        newInsertUpdateState.typeErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    if (mode == "Insert") {
      await insertCaseCustomState(recordInputDTO);
    } else {
      await updateCaseCustomState(recordInputDTO);
    }
  };

  const insertCaseCustomState = async (inputDTO: CaseCustomStatesInputDTO) => {
    let serviceClient = new CaseCustomStatesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertCaseCustomState(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertCaseCustomState ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertCaseCustomState ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateCaseCustomState = async (inputDTO: CaseCustomStatesInputDTO) => {
    let serviceClient = new CaseCustomStatesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateCaseCustomState(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateCaseCustomState ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateCaseCustomState ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const getHierarchyById = async (hierarchyId: string) => {
    let serviceClient = new HierarchyFolderTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      let file = await serviceClient.getHierarchyFolderById(
        hierarchyId,
        false,
        true,
        expectedCodes,
      );
      const fileName: string = file.filename || file.description;
      return fileName;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getHierarchyById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getHierarchyById ex`);
    }
  };

  const getCaseCustomStateById = async (recordId: string) => {
    let serviceClient = new CaseCustomStatesService();
    let expectedCodes: Array<number> = [200];
    let imageService = new ImageService(expectedCodes);
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getCaseCustomStateById(
        recordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: CaseCustomStateViewModel;
      recordInfo = { ...responseRequest };

      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.imageId = recordInfo.imageId;
      if (recordInfo.imageId !== null) {
        let imageResponse = await imageService.getById(responseRequest.imageId);
				newInsertUpdateState.image = imageResponse[0].fileName;
      }
      newInsertUpdateState.customStateType = recordInfo.customStateTypeId;
      newInsertUpdateState.order = recordInfo.order;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(recordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getCaseCustomStateById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getCaseCustomStateById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCaseCustomStateById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCaseCustomStateById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    recordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          recordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleShowModal = () => {
    let newTPModalImageSelectorState: TPModalImageSelectorState;
    newTPModalImageSelectorState = { ...modalImageSelectorState };
    newTPModalImageSelectorState.isShown = true;
    setModalImageSelectorState(newTPModalImageSelectorState);
  };

  const handleCallBackModal = (
    confirmSelection: boolean,
    callBackData: any,
  ) => {
    let newTPModalImageSelectorState: TPModalImageSelectorState;
    newTPModalImageSelectorState = { ...modalImageSelectorState };
    newTPModalImageSelectorState.isShown = false;
    newTPModalImageSelectorState.callBackData = {};
    setModalImageSelectorState(newTPModalImageSelectorState);
    if (confirmSelection) {
      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.imageId = callBackData.selectedFile.hierarchyId;
      newInsertUpdateState.image = callBackData.selectedFile.fileName;
      setInsertUpdateState(newInsertUpdateState);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQCSST,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadFunctionInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  return (
    <>
      <TPModalImageSelector
        title={selectCaseCustomStatesImageTitle}
        isShown={modalImageSelectorState.isShown}
        callBackAnswer={handleCallBackModal}
        callBackData={modalImageSelectorState.callBackData}
        acceptLabel={acceptButtonLabel}
        cancelLabel={cancelButtonLabel}
        topic={"ImagesCSST"}
        selectedFileDescriptionLabel={selectedCaseCustomStatesImageLabel}
        mode="view"                // Pass the mode as 'view'
				startFolder={''}
      />
      {/* //#region  Render */}
      <ContentVerticalTabInsertUpdateStyled>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col-6">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              {mode == "Update" ? (
                <TPPageSubTitle>
                  {subTitleLabel}&nbsp;
                  {recordId}&nbsp;/&nbsp;
                  {originalRecordDescription}
                </TPPageSubTitle>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TPPageSection>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <TPTextBox
                        id="IdTextBox"
                        labelText={idLabel}
                        isMandatory={true}
                        // value={insertUpdateState.id}
                        value={mode === "Insert" ? referenceId : recordId}
                        onChange={
                          mode === "Insert"
                            ? (e: any) => handleOnIdChange(e.target.value)
                            : () => {
                                TPGlobal.foo();
                              }
                        }
                        maxLength={20}
                        // disabled={mode !== "Insert"}
                        disabled
                        errorMessage={insertUpdateState.idErrorMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {insertUpdateState.recordLanguageList.length > 0 &&
                    TPGlobal.TPClientAvailableLanguages.map(
                      (item, index) =>
                        index === 0 && (
                          <div
                            className="col-6"
                            key={`languageItem-${item.id}`}
                          >
                            <div className="form-group">
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={index === 0}
                                labelText={`${descriptionLabel} (${item.name})`}
                                value={
                                  insertUpdateState.recordLanguageList[index]
                                    .value
                                }
                                onChange={(e: any) =>
                                  handleLanguageChange(index, e.target.value)
                                }
                                maxLength={100}
                                errorMessage={
                                  insertUpdateState.descriptionErrorMessages[
                                    index
                                  ]
                                }
                              />
                            </div>
                          </div>
                        ),
                    )}
                  {insertUpdateState.recordLanguageList.length > 1 && (
                    <>
                      <div className="col-4">
                        <div className="pt-4">
                          <TPButton
                            id="IdButton"
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.language}
                            text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                            tooltip={languageListLabel}
                            className={"pt-3"}
                            onClick={handleOpenModalLanguageList}
                          />
                        </div>
                      </div>
                      <TPModalLanguageList
                        id="IdModalLanguageList"
                        isOpen={isOpenModalLanguageList}
                        title={languageListLabel}
                        acceptLabel={saveButtonLabel}
                        cancelLabel={cancelButtonLabel}
                        saveChanges={handleSaveChangesModalLanguageList}
                        closeModal={handleCloseModalLanguageList}
                      >
                        <div
                          className="row overflow-auto"
                          style={{ height: "200px" }}
                        >
                          {insertUpdateState.recordLanguageList.length > 0 &&
                            TPGlobal.TPClientAvailableLanguages.map(
                              (item, index) =>
                                index > 0 && (
                                  <div
                                    className="col-12"
                                    key={`languageItem-${item.id}`}
                                  >
                                    <div className="form-group">
                                      <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={index === 0}
                                        labelText={`${descriptionLabel} (${item.name})`}
                                        value={
                                          insertUpdateState.recordLanguageList[
                                            index
                                          ].value
                                        }
                                        onChange={(e: any) =>
                                          handleLanguageChange(
                                            index,
                                            e.target.value,
                                          )
                                        }
                                        maxLength={100}
                                        errorMessage={
                                          insertUpdateState
                                            .descriptionErrorMessages[index]
                                        }
                                      />
                                    </div>
                                  </div>
                                ),
                            )}
                        </div>
                      </TPModalLanguageList>
                    </>
                  )}
                </div>
              </TPPageSection>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TPPageSection>
                <div className="form-group">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={imageLabel}
                    value={insertUpdateState.image}
                    isMandatory={true}
                    errorMessage={insertUpdateState.imageErrorMessage}
                    maxLength={20}
                    onChange={(e: any) => {
                      TPGlobal.foo();
                    }}
                    withIcon={true}
                    icon={TPIconTypes.image}
                    iconClick={handleShowModal}
                    disabled
                  />
                </div>
              </TPPageSection>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TPPageSection>
                <div className="form-group">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={orderLabel}
                    isMandatory={true}
                    value={insertUpdateState.order}
                    // onChange={
                    //   mode == "Insert"
                    //     ? (e: any) => handleOrderChange(e.target.value)
                    //     : () => {
                    //         TPGlobal.foo();
                    //       }
                    // }
                    onChange={(e: any) => handleOrderChange(e.target.value)}
                    maxLength={20}
                    errorMessage={insertUpdateState.orderErrorMessage}
                  />
                </div>
              </TPPageSection>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TPPageSection>
                <div className="form-group">
                  <TPSelect
                    id="IdSelect"
                    isMandatory={true}
                    onChange={(e: any) =>
                      handleCustomStateTypeChange(e.target.value)
                    }
                    dataSource={typeKeyValue}
                    value={insertUpdateState.customStateType}
                    labelText={typeLabel}
                    errorMessage={insertUpdateState.typeErrorMessage}
                  ></TPSelect>
                </div>
              </TPPageSection>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={handleOkButtonClick}
                >
                  {saveButtonLabel}
                </TPButton>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.link}
                  onClick={handleCancelButtonClick}
                  className={"ms-2"}
                >
                  {cancelButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </ContentVerticalTabInsertUpdateStyled>
      {/* //#endregion */}
    </>
  );
};

export default CaseCustomStatesInsertUpdate;
