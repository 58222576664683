import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { ListItemModel } from "@/models/ListAdministration/ListAdministrationModels";
import { FC, useEffect, useState } from "react";
import useListAdminLabels from "../assets/Labeling";

interface ListLocalizedNamesModalProperties {
  item: ListItemModel;
  callBackAnswer: (accept: boolean, newItem: ListItemModel) => void;
  visible: boolean;
}

export const ListItemUpdateModal: FC<ListLocalizedNamesModalProperties> = function ({ callBackAnswer, visible, item }) {

  const { labels } = useListAdminLabels();
  const [itemCopy, setItemCopy] = useState<ListItemModel>(structuredClone(item));

  const handleItemIdChange = function (input: string) {
    setItemCopy({
      ...itemCopy,
      id: input
    })
  }

  const handleItemNameChange = function (input: string) {
    setItemCopy({
      ...itemCopy,
      description: input
    })
  }

  const handleListLocalizedNameInput = function (languageId: string, input: string) {
    const newValues = [...itemCopy.descriptionLocalizedValues];
    newValues.map((entry) => {
      if (entry.languageId === languageId) entry.localizedValue = input;
    })
    setItemCopy({
      ...itemCopy,
      descriptionLocalizedValues: newValues
    })
  }

  const handleItemActiveChange = function (input: boolean) {
    setItemCopy({
      ...itemCopy,
      isActive: input
    })
  }

  useEffect(() => {
    setItemCopy({
      ...itemCopy,
      descriptionLocalizedValues: itemCopy.descriptionLocalizedValues.length == 0 ?
        [{
          languageId: TPGlobal.language,
          localizedValue: itemCopy.description,
          languageName: TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === TPGlobal.language)?.name
        }]
        :
        [...itemCopy.descriptionLocalizedValues.map(name => {
          return name.languageId == TPGlobal.language ? {
            languageId: TPGlobal.language,
            localizedValue: itemCopy.description,
            languageName: TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === TPGlobal.language)?.name
          }
            :
            name
        })]
    })
  }, [itemCopy])

  return (
    <TPModal
      modalState={{
        acceptLabel: labels.Save,
        callBackAnswer: (accept: boolean) => callBackAnswer(accept, itemCopy),
        cancelLabel: labels.Cancel,
        isShown: visible,
        titleModal: labels.EditItem,
        enableAcceptButton: itemCopy.description.trim().length > 0
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
          <div>
            <TPTextBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleItemIdChange(e.target.value)}
              disabled
              value={itemCopy.id}
              labelText={labels.Id}
            />
          </div>
          <div>
            <TPTextBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleItemNameChange(e.target.value)}
              value={itemCopy.description}
              labelText={labels.ItemName}
              isMandatory
            />
          </div>
        </div>
        <TPCheckBox
          checked={itemCopy.isActive}
          labelText={labels.ShowAsActive}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleItemActiveChange(e.target.checked)}
        />
        <div className="accordion" id='item-localized-names-accordion'>
          <div className="accordion-item">
            <label className="accordion-header" id='item-localized-names-accordion-header'>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#item-localized-names-accordion-body"
                style={{
                  border: 'none',
                  marginRight: '8px'
                }}
              >
                <u>{labels.OtherLanguagesForTheName}</u>
              </button>
            </label>
            <div id='item-localized-names-accordion-body' className="accordion-collapse collapse show" data-bs-parent='#item-localized-names-accordion'>
              <div style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {itemCopy.descriptionLocalizedValues.filter(lang => lang.languageId !== TPGlobal.language).map((language, index) => (
                  <div>
                    <TPTextBox
                      key={index}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleListLocalizedNameInput(language.languageId, String(event.target.value))}
                      value={language.localizedValue}
                      labelText={`${labels.ItemName} (${language.languageName})`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TPModal>
  )
}