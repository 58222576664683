import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailboxInProcessViewModel } from "@/models/InboundMailboxInProcess/InboundMailboxInProcessViewModel";
import { InboundMailboxInProcessDTO } from "@/models/InboundMailboxInProcess/InboundMailboxInProcessDTO";
import { ApplyRulesDTO } from "@/models/InboundMailboxInProcess/ApplyRulesDTO";

export class InboundMailboxInProcessService {
  serviceFileName: string = "InboundMailboxInProcessService.ts";

  public async getInboundMailboxInProcess(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxInProcessViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailboxinprocess";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxInProcess ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcess`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcess`,
      );
    }
  }

  public async getInboundMailboxInProcessById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxInProcessViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxinprocess/${id}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxInProcessById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessById`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessById`,
      );
    }
  }

  public async getInboundMailboxInProcessByFilter(
    insertType: string,
    inboundMailboxId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxInProcessViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxinprocess/type/${insertType}/inboundmailbox/${inboundMailboxId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxInProcessById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessById`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessById`,
      );
    }
  }

  public async applyRulesInboundMailboxInProcess(
    inputDTO: ApplyRulesDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailboxinprocess/process-mails";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} applyRulesInboundMailboxInProcess ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} applyRulesInboundMailboxInProcess`,
      );
      throw new Error(
        `Error ${this.serviceFileName} applyRulesInboundMailboxInProcess`,
      );
    }
  }

  public async updateInboundMailboxes(
    inputDTO: InboundMailboxInProcessDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateInboundMailboxes ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateInboundMailboxes`);
      throw new Error(`Error ${this.serviceFileName} updateInboundMailboxes`);
    }
  }
}
