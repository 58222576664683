export class ReassignCaseInputDTO {
  caseId: number = 0;
  guidUserNew: string = "";
  comments: string = "";

  constructor(
    caseId: number = 0,
    guidUserNew: string = "",
    comments: string = "",
  ) {
    this.caseId = caseId;
    this.guidUserNew = guidUserNew;
    this.comments = comments;
  }
}
