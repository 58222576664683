import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  AAdditionalDataInputDTOValidator,
  AdditionalDataInputDTO,
} from "@/models/AdditionalData/AdditionalDataInputDTO";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  AdditionalDataCategoryEnum,
  AdditionalDataDateTypeEnum,
  AdditionalDataOpenTextTypesEnum,
  AdditionalDataTypeEnum,
  SequenceGeneratorSequencesNameEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { AdditionalDataTypeService } from "@/services/AdditionalDataTypeService";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { TreeService } from "@/services/TreeService";
import moment from "moment";
import { FC, ReactElement, useEffect, useRef, useState } from "react";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  idAdditionalData: string;
  recordLanguageList: Array<TPKeyValue>;
  idAdditionalDataCategory: string;
  idAdditionalDataType: string;
  enableEditingModifyBasicData: boolean;
  comments: string;
  jsonParameters: string;
  validationUrl: string;
  idErrorMessage: string;
  descriptionsErrorMessages: Array<string>;
  idAdditionalDataCategoryErrorMessage: string;
  idAdditionalDataTypeErrorMessage: string;
  enableEditingModifyBasicDataErrorMessage: string;
  commentsErrorMessage: string;
  validationUrlErrorMessage: string;
};

interface DateProps {
  type: string;
  minDateType: string;
  maxDateType: string;
  minDateSymbol: string;
  maxDateSymbol: string;
  maxDateDays: number;
  minDateDays: number;
  minDate?: Date;
  maxDate?: Date;
  //validations
  minDateErrorMessage: string;
  maxDateErrorMessage: string;
}

interface IntegerListProps {
  initialValue: number;
  endValue: number;
  initialValueErrorMessage: string;
}

interface LinkProps {
  url: string;
  urlErrorMessage: string;
}

interface PhoneProps {
  validationType: string;
  minimumCharacters: number;
  maximumCharacters: number;
  charactersWhiteList: string;
  regularExpresion: string;
  minimumCharactersErrorMessage: string;
  charactersWhiteListErrorMessage: string;
  regularExpresionErrorMessage: string;
}

interface NumericProps {
  minValueValidationType: string;
  maxValueValidationType: string;
  minValue: number;
  maxValue: number;
  percentage: boolean;
  fixedDecimal: string;
  currencySymbol: string;
  currencyPlacement: string;
  decimalSeparator: string;
  thousandsSeparator: string;
  negativeNumber: string;
  currencySymbolDisabled: boolean;
  currencyplacementDisabled: boolean;
  minValueTypeErrorMessage: string;
  maxValueTypeErrorMessage: string;
  minValueErrorMessage: string;
  maxValueErrorMessage: string;
  currencyPlacementErrorMessage: string;
  thousandsSeparatorErrorMessage: string;
}

interface OpenTextProps {
  type: string;
  maxLength: number;
  whiteList: string;
  inputMask: string;
  placeHolder: string;
  targetTable: string;
  inputMaskErrorMessage: string;
  whiteListErrorMessage: string;
  maxLengthErrorMessage?: string;
}

interface OpenTextMultilineProps {
  type: string;
  maxLength: number;
  whiteList: string;
  whiteListErrorMessage: string;
  maxLengthErrorMessage?: string;
}

interface YesNoProps {
  renderMethod: string;
  caption: string;
  renderMethodErrorMessage: string;
  captionErrorMessage: string;
}

interface ListValueProps {
  treeId: string;
  renderMethod: string;
  treeIdErrorMessage: string;
  branchSelectedErrorMessage: string;
  renderMethodErrorMessage: string;
}

interface TreeProps {
  treeId: string;
  treeIdErrorMessage: string;
  parentIdErrorMessage: string;
}

const AdditionalDataInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  const componentFileName: string = "AdditionalDataInsertUpdate.tsx";

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const resourceSet: string = "AdditionalDataInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [configurationSectionLabel, setconfigurationSectionLabel] =
    useState("");
  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [additionalDataCategoryLabel, setadditionalDataCategoryLabel] =
    useState("");
  const [additionalDataTypeLabel, setadditionalDataTypeLabel] = useState("");
  const [additionalDataCommentsLabel, setadditionalDataCommentsLabel] =
    useState("");
  const [
    additionalDataValidationUrlLabel,
    setadditionalDataValidationUrlLabel,
  ] = useState("");
  const [
    enableEditingModifyBasicDataLabel,
    setenableEditingModifyBasicDataLabel,
  ] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [additionalDataCategoryList, setAdditionalDataCategoryList] = useState<
    Array<TPKeyValue>
  >([]);
  const [dataAddaTypeListState, setDataAddaTypeListState] = useState<
    Array<TPKeyValue>
  >([]);
  const [targetTableLabel, setTargetTableLabel] = useState("");
  const [targetTableListState, setTargetTableListState] = useState<
    Array<TPKeyValue>
  >([]);
  const [targetTableSelectedValue, setTargetTableSelectedValue] = useState("");
  const [targetTableErrorMessage, setTargetTableErrorMessage] = useState("");
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [dateTypeLabel, setDateTypeLabel] = useState("");
  const [minDateLabel, setMinDateLabel] = useState("");
  const [minimumDateValueLabel, setMinimumDateValueLabel] = useState("");
  const [maxDateLabel, setMaxDateLabel] = useState("");
  const [maximumDateValueLabel, setMaximumDateValueLabel] = useState("");
  const [fixedValueLabel, setFixedValueLabel] = useState("");
  const [variableValueLabel, setVariableValueLabel] = useState("");
  const [todayDateLabel, setTodayDateLabel] = useState("");
  const [daysDateLabel, setDaysDateLabel] = useState("");
  const [anyDateItemLabel, setAnyDateItemLabel] = useState("");
  const [dateRangeItemLabel, setDateRangeItemLabel] = useState("");
  const [initialValueLabel, setInitialValueLabel] = useState("");
  const [endValueLabel, setEndValueLabel] = useState("");
  const [urlLabel, setUrlLabel] = useState("");
  const [validUrlErrorMessage, setValidUrlErrorMessage] = useState("");
  const [minimumValueLabel, setMinimumValueLabel] = useState("");
  const [maximumValueLabel, setMaximumValueLabel] = useState("");
  const [minimumValueTypeLabel, setMinimumValueTypeLabel] = useState("");
  const [maximumValueTypeLabel, setMaximumValueTypeLabel] = useState("");
  const [naLabel, setNaLabel] = useState("");
  const [valueLabel, setValueLabel] = useState("");
  const [percentageLabel, setPercentageLabel] = useState("");
  const [fixedDecimalPlacesLabel, setFixedDecimalPlacesLabel] = useState("");
  const [currencySymbolLabel, setCurrencySymbolLabel] = useState("");
  const [currencyPlacementLabel, setCurrencyPlacementLabel] = useState("");
  const [decimalSeparatorLabel, setDecimalSeparatorLabel] = useState("");
  const [thousandsSeparatorLabel, setThousandsSeparatorLabel] = useState("");
  const [noneLabel, setNoneLabel] = useState("");
  const [beforeLabel, setBeforeLabel] = useState("");
  const [afterLabel, setAfterLabel] = useState("");
  const [spaceLabel, setSpaceLabel] = useState("");
  const [textboxTypeLabel, setTextboxTypeLabel] = useState("");
  const [openLabel, setOpenLabel] = useState("");
  const [whiteListLabel, setWhiteListLabel] = useState("");
  const [inputMaskLabel, setInputMaskLabel] = useState("");
  const [maximumCharactersLabel, setMaximumCharactersLabel] = useState("");
  const [charactersWhiteListLabel, setCharactersWhiteListLabel] = useState("");
  const [placeholderLabel, setPlaceholderLabel] = useState("");
  const [validationTypeLabel, setValidationTypeLabel] = useState("");
  const [minimumCharactersLabel, setMinimumCharactersLabel] = useState("");
  const [basicLabel, setBasicLabel] = useState("");
  const [advanceLabel, setAdvanceLabel] = useState("");
  const [regularExpressionLabel, setRegularExpressionLabel] = useState("");
  const [treeLabel, setTreeLabel] = useState("");
  const [parentIdLabel, setParentIdLabel] = useState("");
  const [controlLabel, setControlLabel] = useState("");
  const [comboboxLabel, setComboboxLabel] = useState("");
  const [optionLabel, setOptionLabel] = useState("");
  const [checkboxLabel, setCheckboxLabel] = useState("");
  const [isYesNoError, setIsYesNoError] = useState(false);
  const [captionLabel, setCaptionLabel] = useState("");
  const [yesNoLabel, setYesNoLabel] = useState("");
  const [trueFalseLabel, setTrueFalseLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");

  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    idAdditionalData: recordId,
    recordLanguageList: [],
    idErrorMessage: "",
    descriptionsErrorMessages: [],
    idAdditionalDataCategory: "",
    idAdditionalDataType: "",
    enableEditingModifyBasicData: false,
    comments: "",
    jsonParameters: "",
    idAdditionalDataCategoryErrorMessage: "",
    idAdditionalDataTypeErrorMessage: "",
    enableEditingModifyBasicDataErrorMessage: "",
    commentsErrorMessage: "",
    validationUrl: "",
    validationUrlErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );
  let datePropsInitialState: DateProps = {
    minDateType: "fixed",
    maxDateType: "fixed",
    minDateSymbol: "+",
    maxDateSymbol: "+",
    maxDateDays: 0,
    minDateDays: 0,
    minDate: undefined,
    maxDate: undefined,
    minDateErrorMessage: "",
    maxDateErrorMessage: "",
    type: "AnyDate",
  };
  const [datePropsState, setDatePropsState] = useState<DateProps>(
    datePropsInitialState,
  );

  let integerListPropsInitialState: IntegerListProps = {
    initialValue: 0,
    endValue: 1,
    initialValueErrorMessage: "",
  };
  const [integerListPropsState, setIntegerListPropsState] =
    useState<IntegerListProps>(integerListPropsInitialState);

  let linkPropsInitialState: LinkProps = {
    url: "",
    urlErrorMessage: "",
  };
  const [linkPropsState, setLinkPropsState] = useState<LinkProps>(
    linkPropsInitialState,
  );

  let phonePropsInitialState: PhoneProps = {
    validationType: "BASIC",
    minimumCharacters: 1,
    maximumCharacters: 1,
    charactersWhiteList: "0123456789()+- ,;#p",
    regularExpresion: "",
    minimumCharactersErrorMessage: "",
    charactersWhiteListErrorMessage: "",
    regularExpresionErrorMessage: "",
  };
  const [phonePropsState, setPhonePropsState] = useState<PhoneProps>(
    phonePropsInitialState,
  );

  let numericPropsInitialState: NumericProps = {
    minValue: 0,
    maxValue: 1,
    percentage: false,
    fixedDecimal: "None",
    currencySymbol: "",
    currencyPlacement: "",
    decimalSeparator: "",
    thousandsSeparator: "",
    negativeNumber: "",
    minValueValidationType: "",
    maxValueValidationType: "",
    currencySymbolDisabled: false,
    currencyplacementDisabled: false,
    currencyPlacementErrorMessage: "",
    minValueErrorMessage: "",
    minValueTypeErrorMessage: "",
    maxValueTypeErrorMessage: "",
    maxValueErrorMessage: "",
    thousandsSeparatorErrorMessage: "",
  };
  const [numericPropsState, setNumericPropsState] = useState<NumericProps>(
    numericPropsInitialState,
  );

  let openTextPropsInitialState: OpenTextProps = {
    type: "Open",
    maxLength: 1,
    whiteList: "",
    inputMask: "",
    placeHolder: "",
    targetTable: "",
    inputMaskErrorMessage: "",
    whiteListErrorMessage: "",
  };
  const [openTextPropsState, setOpenTextPropsState] = useState<OpenTextProps>(
    openTextPropsInitialState,
  );

  let openTextMultilinePropsInitialState: OpenTextMultilineProps = {
    type: "Open",
    maxLength: 1,
    whiteList: "",
    whiteListErrorMessage: "",
  };
  const [openTextMultilinePropsState, setOpenTextMultilinePropsState] =
    useState<OpenTextMultilineProps>(openTextMultilinePropsInitialState);
  //Yes-No multiline Parameters
  let yesNoPropsInitialState: YesNoProps = {
    renderMethod: "",
    caption: "",
    renderMethodErrorMessage: "",
    captionErrorMessage: "",
  };
  const [yesNoPropsState, setYesNoPropsState] = useState<YesNoProps>(
    yesNoPropsInitialState,
  );

  const [treeSourceList, setTreeSourceList] = useState<Array<TPKeyValue>>([]);
  //Value list from tree branch Parameters
  let listValuePropsInitialState: ListValueProps = {
    treeId: "",
    renderMethod: "",
    //allowMultipleSelection: false,
    renderMethodErrorMessage: "",
    treeIdErrorMessage: "",
    branchSelectedErrorMessage: "",
  };
  const [listValuePropsState, setListValuePropsState] =
    useState<ListValueProps>(listValuePropsInitialState);

  const initialSelectedBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedBranchListValue, setSelectedBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedBranch);

  //End Value List from tree branch parameters

  //Tree Parameters
  let treePropsInitialState: TreeProps = {
    treeId: "",
    parentIdErrorMessage: "",
    treeIdErrorMessage: "",
  };
  const [treePropsState, setTreePropsState] = useState<TreeProps>(
    treePropsInitialState,
  );

  const initialSelectedTreeBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedTreeBranchListValue, setSelectedTreeBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedTreeBranch);

  //End Tree parameters

  let treeRef: any = useRef(null);
  //Multilanguage const
  const multilanguageTableName: String = "ADditionalDAta";
  const multilanguageFieldName: String = "Description_ADDA";
  //#endregion
  const loadResourcesAndLoadAdditionalDataInfo = async () => {
    let i: number;

    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setadditionalDataCategoryLabel(
      await TPI18N.GetText(resourceSet, "CategoryLabel"),
    );
    setadditionalDataTypeLabel(await TPI18N.GetText(resourceSet, "TypeLabel"));
    setadditionalDataCommentsLabel(
      await TPI18N.GetText(resourceSet, "CommentsLabel"),
    );
    setadditionalDataValidationUrlLabel(
      await TPI18N.GetText(resourceSet, "ValidationUrlLabel"),
    );
    setenableEditingModifyBasicDataLabel(
      await TPI18N.GetText(resourceSet, "EnableEditingModifyBasicLabel"),
    );
    setconfigurationSectionLabel(
      await TPI18N.GetText(resourceSet, "ConfigurationSectionLabel"),
    );

    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel"),
    );
    setmodalTitleLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectBranchTitleLabel",
      ),
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel"),
    );
    setModalSelectedBranchLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectedBranchLabel",
      ),
    );
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
    );

    setTargetTableLabel(await TPI18N.GetText(resourceSet, "TargetTableLabel"));

    //#region Additional types load Resources
    //Date
    setDateTypeLabel(await TPI18N.GetText(resourceSet, "DateTypeLabel"));
    setMinDateLabel(await TPI18N.GetText(resourceSet, "MinDateLabel"));
    setMinimumDateValueLabel(
      await TPI18N.GetText(resourceSet, "MinimumDateValueLabel"),
    );
    setMaxDateLabel(await TPI18N.GetText(resourceSet, "MaxDateLabel"));
    setMaximumDateValueLabel(
      await TPI18N.GetText(resourceSet, "MaximumDateValueLabel"),
    );
    setFixedValueLabel(await TPI18N.GetText(resourceSet, "FixedValueLabel"));
    setVariableValueLabel(
      await TPI18N.GetText(resourceSet, "VariableValueLabel"),
    );
    setTodayDateLabel(await TPI18N.GetText(resourceSet, "TodayDateLabel"));
    setDaysDateLabel(await TPI18N.GetText(resourceSet, "DaysDateLabel"));
    setAnyDateItemLabel(await TPI18N.GetText(resourceSet, "AnyDateItemLabel"));
    setDateRangeItemLabel(
      await TPI18N.GetText(resourceSet, "DateRangeItemLabel"),
    );

    //Integer List
    setInitialValueLabel(
      await TPI18N.GetText(resourceSet, "InitialValueLabel"),
    );
    setEndValueLabel(await TPI18N.GetText(resourceSet, "EndValueLabel"));

    //URL
    setUrlLabel(await TPI18N.GetText(resourceSet, "UrlLabel"));

    //ValidUrlErrorMessage
    setValidUrlErrorMessage(
      await TPI18N.GetText(resourceSet, "ValidUrlErrorMessage"),
    );

    //Numeric
    setMinimumValueLabel(
      await TPI18N.GetText(resourceSet, "MinimumValueLabel"),
    );
    setMaximumValueLabel(
      await TPI18N.GetText(resourceSet, "MaximumValueLabel"),
    );

    setMinimumValueTypeLabel(
      await TPI18N.GetText(resourceSet, "MinimumValueTypeLabel"),
    );
    setMaximumValueTypeLabel(
      await TPI18N.GetText(resourceSet, "MaximumValueTypeLabel"),
    );

    setNaLabel(await TPI18N.GetText(resourceSet, "NaLabel"));
    setValueLabel(await TPI18N.GetText(resourceSet, "ValueLabel"));
    setPercentageLabel(await TPI18N.GetText(resourceSet, "PercentageLabel"));
    setFixedDecimalPlacesLabel(
      await TPI18N.GetText(resourceSet, "FixedDecimalPlacesLabel"),
    );
    setCurrencySymbolLabel(
      await TPI18N.GetText(resourceSet, "CurrencySymbolLabel"),
    );
    setCurrencyPlacementLabel(
      await TPI18N.GetText(resourceSet, "CurrencyPlacementLabel"),
    );
    setDecimalSeparatorLabel(
      await TPI18N.GetText(resourceSet, "DecimalSeparatorLabel"),
    );
    setThousandsSeparatorLabel(
      await TPI18N.GetText(resourceSet, "ThousandsSeparatorLabel"),
    );
    setNoneLabel(await TPI18N.GetText(resourceSet, "NoneLabel"));
    setBeforeLabel(await TPI18N.GetText(resourceSet, "BeforeLabel"));
    setAfterLabel(await TPI18N.GetText(resourceSet, "AfterLabel"));
    setSpaceLabel(await TPI18N.GetText(resourceSet, "SpaceLabel"));

    //Open text and open text multiline
    setTextboxTypeLabel(await TPI18N.GetText(resourceSet, "TextboxTypeLabel"));
    setOpenLabel(await TPI18N.GetText(resourceSet, "OpenLabel"));
    setWhiteListLabel(await TPI18N.GetText(resourceSet, "WhiteListLabel"));
    setInputMaskLabel(await TPI18N.GetText(resourceSet, "InputMaskLabel"));
    setMaximumCharactersLabel(
      await TPI18N.GetText(resourceSet, "MaximumCharactersLabel"),
    );
    setCharactersWhiteListLabel(
      await TPI18N.GetText(resourceSet, "CharactersWhiteListLabel"),
    );
    setPlaceholderLabel(await TPI18N.GetText(resourceSet, "PlaceholderLabel"));

    //Phone
    setValidationTypeLabel(
      await TPI18N.GetText(resourceSet, "ValidationTypeLabel"),
    );
    setMinimumCharactersLabel(
      await TPI18N.GetText(resourceSet, "MinimumCharactersLabel"),
    );
    setBasicLabel(await TPI18N.GetText(resourceSet, "BasicLabel"));
    setAdvanceLabel(await TPI18N.GetText(resourceSet, "AdvanceLabel"));
    setRegularExpressionLabel(
      await TPI18N.GetText(resourceSet, "RegularExpressionLabel"),
    );

    //Tree
    setTreeLabel(await TPI18N.GetText(resourceSet, "TreeLabel"));
    setParentIdLabel(await TPI18N.GetText(resourceSet, "ParentIdLabel"));

    //list value
    setControlLabel(await TPI18N.GetText(resourceSet, "ControlLabel"));
    // setListboxLabel(await TPI18N.GetText(resourceSet, "ListboxLabel"));
    setComboboxLabel(await TPI18N.GetText(resourceSet, "ComboboxLabel"));
    setOptionLabel(await TPI18N.GetText(resourceSet, "OptionLabel"));
    setCheckboxLabel(await TPI18N.GetText(resourceSet, "CheckboxLabel"));
    // setAllowMultipleSelectionLabel(
    //   await TPI18N.GetText(resourceSet, "AllowMultipleSelectionLabel")
    // );

    //Yes/No
    setCaptionLabel(await TPI18N.GetText(resourceSet, "CaptionLabel"));
    setYesNoLabel(await TPI18N.GetText(resourceSet, "YesNoLabel"));
    setTrueFalseLabel(await TPI18N.GetText(resourceSet, "TrueFalseLabel"));

    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    //#endregion Additional type load Resources

    //screen state

    let parametersService = new ParametersService();
    let additionalDataTypeService = new AdditionalDataTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await parametersService.getByParentIdAndFilterIsActive(
          "S_ADDACATE",
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes,
        );

      let newadditionalDataCategoryList: Array<TPKeyValue> =
        responseRequest.map(function (item) {
          return { key: item.id, value: item.localizedDescription };
        });
      newadditionalDataCategoryList.unshift({ key: "", value: "--" });
      setAdditionalDataCategoryList(newadditionalDataCategoryList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    try {
      let responseRequest = await additionalDataTypeService.getByFilterIsActive(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes,
      );

      let newadditionalDataTypeList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        },
      );
      newadditionalDataTypeList.unshift({ key: "", value: "--" });
      setDataAddaTypeListState(newadditionalDataTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    //load targetTableListState
    let newTargetTableListState = new Array<TPKeyValue>();
    newTargetTableListState.push({ key: "", value: "--" });

    newTargetTableListState.push({
      key: "CONTACTS",
      value: await TPI18N.GetText(resourceSet, "ContactsItemLabel"),
    });
    newTargetTableListState.push({
      key: "CLIENT",
      value: await TPI18N.GetText(resourceSet, "CustomerItemLabel"),
    });
    newTargetTableListState.push({
      key: "SUBSIDIARY",
      value: await TPI18N.GetText(resourceSet, "SubsidiaryItemLabel"),
    });
    newTargetTableListState.push({
      key: "SUBSIDIARYORGANIZATION",
      value: await TPI18N.GetText(
        resourceSet,
        "SubsidiaryOrganizationItemLabel",
      ),
    });

    setTargetTableListState(newTargetTableListState);

    //Load Tree source (for Value List from tree/branch Type only)
    await loadTreeList();

    if (mode == "Update") {
      await getAdditionalDataById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };
  const getBranchInfoService = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        true,
        expectedCodes,
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };
  const getBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedBranchListValue: TPBranchSelectionValue = {
      ...selectedBranchListValue,
    };
    newSelectedBranchListValue.branchId = parentId;
    newSelectedBranchListValue.branchDescription = branchData.description;
    newSelectedBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedBranchListValue(newSelectedBranchListValue);
  };
  const getTreeBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedTreeBranchListValue: TPBranchSelectionValue = {
      ...selectedTreeBranchListValue,
    };
    newSelectedTreeBranchListValue.branchId = parentId;
    newSelectedTreeBranchListValue.branchDescription = branchData.description;
    newSelectedTreeBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedTreeBranchListValue(newSelectedTreeBranchListValue);
  };
  const loadTreeList = async () => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTreeByFilterIsClassifier(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes,
      );
      let newListValue: Array<TPKeyValue> = [];

      let treesListData: Array<TreeViewModel>;
      treesListData = responseRequest;
      for (i = 0; i <= treesListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: treesListData[i].id,
          value: treesListData[i].localizedName,
        };
        newListValue.push(keyvalue);
      }
      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newListValue.unshift(intitialKeyValue);
      //Change state
      setTreeSourceList(newListValue);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadTreeList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadTreeList ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdAdditionalDataChange = (newIdAdditionalData: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalData = newIdAdditionalData;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionsErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleEnableEditingModifyBasicDataChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.enableEditingModifyBasicData =
      !newInsertUpdateState.enableEditingModifyBasicData;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAdditionalDataCategoryChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalDataCategory = e.target.value;
    newInsertUpdateState.idAdditionalDataCategoryErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAdditionalDataTypeChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalDataType = e.target.value;
    newInsertUpdateState.idAdditionalDataTypeErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handlerTargetTableChange = (e: any) => {
    setTargetTableSelectedValue(e.target.value);
    setTargetTableErrorMessage("");
  };

  const handleAdditionalDataCommentsChange = (
    newAdditionalDataComments: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.comments = newAdditionalDataComments;
    newInsertUpdateState.commentsErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAdditionalDataValidationUrlChange = (
    newAdditionalDataValidationUrl: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.validationUrl = newAdditionalDataValidationUrl;
    newInsertUpdateState.validationUrlErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  function conditionIsValidByType() {
    switch (insertUpdateState.idAdditionalDataType) {
      case AdditionalDataTypeEnum.date:
        return true;
      case AdditionalDataTypeEnum.label:
        return true;
      case AdditionalDataTypeEnum.integerlist:
        return true;
      case AdditionalDataTypeEnum.link:
        return true;
      case AdditionalDataTypeEnum.phone:
        return true;
      case AdditionalDataTypeEnum.numeric:
        return true;
      case AdditionalDataTypeEnum.textbox:
        switch (openTextPropsState.type) {
          case AdditionalDataOpenTextTypesEnum.whiteList:
            return true;
          case AdditionalDataOpenTextTypesEnum.inputMask:
            return true;
          default:
            return false;
        }
      case AdditionalDataTypeEnum.textarea:
        switch (openTextMultilinePropsState.type) {
          case "Open":
            return true;
          case "WhiteList":
            return true;
          default:
            return false;
        }
      case AdditionalDataTypeEnum.yesno:
        return true;
      case AdditionalDataTypeEnum.listvalue:
        return true;
      case AdditionalDataTypeEnum.tree:
        return true;
      default:
        return false;
    }

  }

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;
    let recordInputDTO: AdditionalDataInputDTO = {
      id: mode === "Insert" ? referenceId : recordId,
      description: insertUpdateState.recordLanguageList[0].value,
      otherLocalizedValues: [],
      validationURL: insertUpdateState.validationUrl,
      comments: insertUpdateState.comments,
      additionalDataTypeId: insertUpdateState.idAdditionalDataType,
      jsonParameters: insertUpdateState.jsonParameters,
      categoryId: insertUpdateState.idAdditionalDataCategory,
      enableEditingModifyBasicData: insertUpdateState.enableEditingModifyBasicData,
    };

    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new AAdditionalDataInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    let controlValidation: boolean = true;

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.descriptionsErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionsErrorMessages[0] = "";
      }
      if (resultValidator.comments) {
        newInsertUpdateState.commentsErrorMessage = await TPI18N.GetResource(
          resultValidator.comments,
        );
      } else {
        newInsertUpdateState.commentsErrorMessage = "";
      }
      if (resultValidator.otherLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionsErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionsErrorMessages[i] = "";
        }
      }
      if (resultValidator.categoryId) {
        newInsertUpdateState.idAdditionalDataCategoryErrorMessage =
          await TPI18N.GetResource(resultValidator.categoryId);
      } else {
        newInsertUpdateState.idAdditionalDataCategoryErrorMessage = "";
      }
      if (resultValidator.additionalDataTypeId) {
        newInsertUpdateState.idAdditionalDataTypeErrorMessage =
          await TPI18N.GetResource(resultValidator.additionalDataTypeId);
      } else {
        newInsertUpdateState.idAdditionalDataTypeErrorMessage = "";
      }
      setInsertUpdateState(newInsertUpdateState);
      controlValidation = false;
    }

    const dataTypesNotRequiredForValidation = ["ATTACHMENT", "EMAIL", "TEXTBOX", "PruebaADTY", "TEXTBOX_PRUEBA_INS",
      "TEXTBOX_PRUEBA1", "TEXTBOX_PRUEBA12", "TEXTBOX_PRUEBA123", "TEXTBOX_PRUEBA1234"];

    if (
      (insertUpdateState.idAdditionalDataCategory ==
        AdditionalDataCategoryEnum.S_CUSTCONT)
      &&
      ((!dataTypesNotRequiredForValidation.includes(insertUpdateState.idAdditionalDataType))
        ||
        (insertUpdateState.idAdditionalDataType === "TEXTBOX" && openTextPropsState.type === AdditionalDataOpenTextTypesEnum.whiteList))
    ) {
      if (!targetTableSelectedValue) {
        setTargetTableErrorMessage(
          await TPI18N.GetText(resourceSet, "TargetTableErrorMessage"),
        );
        controlValidation = false;
      }
    }

    let objJson: any = new Object();
    switch (insertUpdateState.idAdditionalDataType) {
      case AdditionalDataTypeEnum.date:
        let controlDate: boolean = true;
        let newDatePropsState: DateProps = { ...datePropsState };
        objJson.type = datePropsState.type;
        if (datePropsState.type == AdditionalDataDateTypeEnum.dateRange) {
          if (
            datePropsState.minDateType === "fixed" &&
            datePropsState.maxDateType === "fixed"
          ) {
            if (!datePropsState.minDate) {
              newDatePropsState.minDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMinDateEmpty",
              );
              controlDate = false;
            }
            if (!datePropsState.maxDate) {
              newDatePropsState.maxDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxDateEmpty",
              );
              controlDate = false;
            }
            if (!controlDate) {
              setDatePropsState(newDatePropsState);
              return;
            }
          }

          if (datePropsState.minDateType === "variable") {
            objJson.minDate = `Now${datePropsState.minDateSymbol}${datePropsState.minDateDays}`;
          } else {
            if (!datePropsState.minDate) {
              newDatePropsState.minDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMinDateEmpty",
              );
              setDatePropsState(newDatePropsState);
              return;
            }
            objJson.minDate = moment(datePropsState.minDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase(),
            );
          }
          if (datePropsState.maxDateType === "variable") {
            objJson.maxDate = `Now${datePropsState.maxDateSymbol}${datePropsState.maxDateDays}`;
          } else {
            if (!datePropsState.maxDate) {
              newDatePropsState.maxDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxDateEmpty",
              );
              setDatePropsState(newDatePropsState);
              return;
            }
            objJson.maxDate = moment(datePropsState.maxDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase(),
            );
          }
        } else {
          objJson.minDate = "";
          objJson.maxDate = "";
        }

        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.email:
      case AdditionalDataTypeEnum.label:
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.integerlist:
        if (
          parseInt(integerListPropsState.initialValue.toString(), 10) >=
          parseInt(integerListPropsState.endValue.toString(), 10)
        ) {
          let newIntegerListPropsState: IntegerListProps = {
            ...integerListPropsState,
          };
          newIntegerListPropsState.initialValueErrorMessage =
            await TPI18N.GetText(resourceSet, "MinValueError");
          setIntegerListPropsState(newIntegerListPropsState);
          return;
        }
        objJson.initialValue = integerListPropsState.initialValue.toString();
        objJson.endValue = integerListPropsState.endValue.toString();
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.link:
        let newLinkPropsState = { ...linkPropsState };
        if (!linkPropsState.url) {
          newLinkPropsState.urlErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOUrlEmpty",
          );
          setLinkPropsState(newLinkPropsState);
          return;
        }
        try {
          new URL(linkPropsState.url);
          newLinkPropsState.urlErrorMessage = "";
        } catch (_) {
          newLinkPropsState.urlErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOUrlInvalid",
          );
          setLinkPropsState(newLinkPropsState);
          return;
        }
        setLinkPropsState(newLinkPropsState);
        objJson.url = encodeURIComponent(linkPropsState.url);
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.phone:
        if (
          phonePropsState.validationType.toUpperCase() === "ADVANCE" &&
          !phonePropsState.regularExpresion
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.regularExpresionErrorMessage =
            await TPI18N.GetText(resourceSet, "InputDTORegularExpresionEmpty");
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (
          phonePropsState.validationType.toUpperCase() === "BASIC" &&
          !phonePropsState.charactersWhiteList
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.charactersWhiteListErrorMessage =
            await TPI18N.GetText(
              resourceSet,
              "InputDTOCharacterWhiteListEmpty",
            );
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (
          phonePropsState.validationType.toUpperCase() === "BASIC" &&
          phonePropsState.minimumCharacters >= phonePropsState.maximumCharacters
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.minimumCharactersErrorMessage =
            await TPI18N.GetText(resourceSet, "MinimumCharactersErrorMessage");
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (phonePropsState.validationType.toUpperCase() === "ADVANCE") {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = "..";
          objJson.maxLength = "..";
          objJson.phoneRegEXPRE = phonePropsState.regularExpresion;
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory ==
            AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        } else {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = phonePropsState.minimumCharacters;
          objJson.maxLength = phonePropsState.maximumCharacters;
          objJson.phoneRegEXPRE = "";
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory ==
            AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        }
        break;
      case AdditionalDataTypeEnum.numeric:
        let control: boolean = true;
        let newNumericPropsState: NumericProps = { ...numericPropsState };
        if (!numericPropsState.minValueValidationType) {
          newNumericPropsState.minValueTypeErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOMinValueTypeEmpty",
          );
          control = false;
        }
        if (!numericPropsState.maxValueValidationType) {
          newNumericPropsState.maxValueTypeErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOMaxValueTypeEmpty",
          );

          control = false;
        }
        if (!control) {
          setNumericPropsState(newNumericPropsState);
          return;
        }
        if (numericPropsState.minValue <= 0 && numericPropsState.minValueValidationType != 'IN') {
          newNumericPropsState.minValueErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MinValueMustBePositive",
          );
          setNumericPropsState(newNumericPropsState);
          return;
        }
        if (numericPropsState.maxValue <= 0 && numericPropsState.maxValueValidationType != 'IN') {
          newNumericPropsState.maxValueErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MaxValueMustBePositive",
          );
          setNumericPropsState(newNumericPropsState);
          return;
        }

        if (
          numericPropsState.minValueValidationType.toUpperCase() === "VAL" &&
          numericPropsState.maxValueValidationType.toUpperCase() === "VAL"
        ) {
          if (numericPropsState.minValue >= numericPropsState.maxValue) {
            let newNumericPropsState: NumericProps = { ...numericPropsState };
            newNumericPropsState.minValueErrorMessage = await TPI18N.GetText(
              resourceSet,
              "MinValueError",
            );
            setNumericPropsState(newNumericPropsState);
            return;
          }
        }
        if (
          !numericPropsState.percentage &&
          !numericPropsState.currencyPlacement
        ) {
          if (numericPropsState.currencySymbol) {
            let newNumericPropsState: NumericProps = { ...numericPropsState };
            newNumericPropsState.currencyPlacementErrorMessage =
              await TPI18N.GetText(
                resourceSet,
                "InputDTOCurrencyPlacementEmpty",
              );
            setNumericPropsState(newNumericPropsState);
            return;
          }
        }

        if (
          numericPropsState.decimalSeparator ==
          numericPropsState.thousandsSeparator
        ) {
          newNumericPropsState.thousandsSeparatorErrorMessage =
            await TPI18N.GetText(
              resourceSet,
              "DecimalAndThousandsSeparatorNotEqualsErrorMessage",
            );
          setNumericPropsState(newNumericPropsState);
          return;
        }
        //End Validations

        if (numericPropsState.minValueValidationType === "VAL") {
          objJson.minValue = numericPropsState.minValue;
        } else {
          objJson.minValue = numericPropsState.minValueValidationType;
        }
        if (numericPropsState.maxValueValidationType === "VAL") {
          objJson.maxValue = numericPropsState.maxValue;
        } else {
          objJson.maxValue = numericPropsState.maxValueValidationType;
        }
        objJson.percentage = numericPropsState.percentage;
        objJson.fixedDecimal = numericPropsState.fixedDecimal;
        objJson.currencySymbol = numericPropsState.currencySymbol;
        objJson.currencyPlacement = numericPropsState.currencyPlacement;
        objJson.decimalSeparator = numericPropsState.decimalSeparator;
        objJson.thousandsSeparator = numericPropsState.thousandsSeparator;
        objJson.negativeNumber = "-";
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.textbox:
        objJson.type = openTextPropsState.type;
        //1. Validations
        switch (openTextPropsState.type) {
          case AdditionalDataOpenTextTypesEnum.open:
            if (
              openTextPropsState.maxLength &&
              !isNaN(Number(openTextPropsState.maxLength)) &&
              Number(openTextPropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOMaxLengthMustBePositive",
                );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextPropsState.maxLength;
            break;
          case AdditionalDataOpenTextTypesEnum.whiteList:
            if (!openTextPropsState.whiteList) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.whiteListErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOCharacterWhiteListEmpty",
                );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            if (
              openTextPropsState.maxLength &&
              !isNaN(Number(openTextPropsState.maxLength)) &&
              Number(openTextPropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOMaxLengthMustBePositive",
                );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextPropsState.maxLength;
            objJson.whiteList = openTextPropsState.whiteList;
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory ==
              AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";

            break;
          case AdditionalDataOpenTextTypesEnum.inputMask:
            if (!openTextPropsState.inputMask) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.inputMaskErrorMessage =
                await TPI18N.GetText(resourceSet, "InputDTOInputMaskEmpty");
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = "..";
            objJson.whiteList = "..";
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory ==
              AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          default:
            break;
        }
        //End Validations

        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.textarea:
        objJson.type = openTextMultilinePropsState.type;
        //1. Validations
        switch (openTextMultilinePropsState.type) {
          case "Open":
            if (
              openTextMultilinePropsState.maxLength &&
              !isNaN(Number(openTextMultilinePropsState.maxLength)) &&
              Number(openTextMultilinePropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOMaxLengthMustBePositive",
                );
              setOpenTextMultilinePropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory ==
              AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          case "WhiteList":
            if (!openTextMultilinePropsState.whiteList) {
              let newOpenTextMultilinePropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextMultilinePropsState.whiteListErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOCharacterWhiteListEmpty",
                );
              setOpenTextMultilinePropsState(newOpenTextMultilinePropsState);
              return;
            }
            if (
              openTextMultilinePropsState.maxLength &&
              !isNaN(Number(openTextMultilinePropsState.maxLength)) &&
              Number(openTextMultilinePropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage =
                await TPI18N.GetText(
                  resourceSet,
                  "InputDTOMaxLengthMustBePositive",
                );
              setOpenTextMultilinePropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.whiteList = openTextMultilinePropsState.whiteList;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory ==
              AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;

          default:
            break;
        }
        //End Validations

        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.yesno:
        //1. Validations
        let controlYesNo: boolean = true;
        let newYesNoPropsState: YesNoProps = { ...yesNoPropsState };

        if (!yesNoPropsState.renderMethod) {
          newYesNoPropsState.renderMethodErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTORenderMethodEmpty",
          );
          setYesNoPropsState(newYesNoPropsState);
          controlYesNo = false;
        }

        if (!yesNoPropsState.caption) {
          newYesNoPropsState.captionErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOCampionEmpty",
          );
          setYesNoPropsState(newYesNoPropsState);
          controlYesNo = false;
        }
        if (!controlYesNo) {
          setYesNoPropsState(newYesNoPropsState);
          return;
        }
        //End validations
        objJson.renderMethod = yesNoPropsState.renderMethod;
        objJson.caption = yesNoPropsState.caption;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.listvalue:
        //1. Validations
        let controlListValue: boolean = true;
        let newListValuePropsState: ListValueProps = {
          ...listValuePropsState,
        };
        if (!listValuePropsState.treeId) {
          newListValuePropsState.treeIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOTreeIdEmpty",
          );
          controlListValue = false;
        }

        if (!selectedBranchListValue.branchId) {
          newListValuePropsState.branchSelectedErrorMessage =
            await TPI18N.GetText(resourceSet, "InputDTOParentIdEmpty");
          controlListValue = false;
        }

        if (!listValuePropsState.renderMethod) {
          newListValuePropsState.renderMethodErrorMessage =
            await TPI18N.GetText(resourceSet, "InputDTORenderMethodEmpty");
          controlListValue = false;
        }
        if (!controlListValue) {
          setListValuePropsState(newListValuePropsState);
          return;
        }
        //End validations
        objJson.parentId = selectedBranchListValue.branchId;
        objJson.renderMethod = listValuePropsState.renderMethod;
        objJson.treeId = listValuePropsState.treeId;
        // objJson.allowMultipleSelection =
        //   listValuePropsState.allowMultipleSelection;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.tree:
        //1. Validations
        let controlTree: boolean = true;
        let newTreePropsState: TreeProps = {
          ...treePropsState,
        };
        if (!treePropsState.treeId) {
          newTreePropsState.treeIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOTreeIdEmpty",
          );
          controlTree = false;
        }

        if (!selectedTreeBranchListValue.branchId) {
          newTreePropsState.parentIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOParentIdEmpty",
          );
          controlTree = false;
        }

        if (!controlTree) {
          setTreePropsState(newTreePropsState);
          return;
        }
        //End validations
        objJson.parentId = selectedTreeBranchListValue.branchId;
        objJson.treeId = treePropsState.treeId;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory ==
          AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      default:
        recordInputDTO.jsonParameters = "";
        break;
    }

    if (!controlValidation) {
      return;
    }

    if(recordInputDTO.jsonParameters){
      recordInputDTO.jsonParameters = TPGlobal.stringToUTF8(recordInputDTO.jsonParameters).toString();
    }

    if (mode == "Insert") {
      await insertAdditionalData(recordInputDTO);
    } else {
      await updateAdditionalData(recordInputDTO);
    }
  };
  

  const insertAdditionalData = async (inputDTO: AdditionalDataInputDTO) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertAdditionalData(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertAdditionalData ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateAdditionalData = async (inputDTO: AdditionalDataInputDTO) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateAdditionalData(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateAdditionalData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateAdditionalData ex`);
      setIsLoadingScreen(false);
    }
  };

  const getAdditionalDataById = async (pRecordId: string) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getAdditionalDataById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: AdditionalDataViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.idAdditionalDataType =
        recordInfo.additionalDataTypeId;
      newInsertUpdateState.idAdditionalDataCategory = recordInfo.categoryId;
      newInsertUpdateState.comments = recordInfo.comments;
      newInsertUpdateState.enableEditingModifyBasicData = recordInfo.enableEditingModifyBasicData;
      newInsertUpdateState.validationUrl = recordInfo.validationURL;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }

      switch (recordInfo.additionalDataTypeId) {
        case AdditionalDataTypeEnum.date:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newDatePropsState: DateProps = { ...datePropsState };
            if (
              objJson.type &&
              objJson.type === AdditionalDataDateTypeEnum.dateRange
            ) {
              newDatePropsState.type = objJson.type;
              if (objJson.minDate.includes("Now")) {
                newDatePropsState.minDateSymbol = objJson.minDate.substring(
                  4,
                  3,
                );
                newDatePropsState.minDateType = "variable";
                newDatePropsState.minDateDays = objJson.minDate.substring(4);
              } else {
                newDatePropsState.minDate = moment(
                  objJson.minDate,
                  TPGlobal.dateFormatAdditionalData.toUpperCase(),
                ).toDate();
              }
              if (objJson.maxDate.includes("Now")) {
                newDatePropsState.maxDateSymbol = objJson.maxDate.substring(
                  4,
                  3,
                );
                newDatePropsState.maxDateType = "variable";
                newDatePropsState.maxDateDays = objJson.maxDate.substring(4);
              } else {
                newDatePropsState.maxDate = moment(
                  objJson.maxDate,
                  TPGlobal.dateFormatAdditionalData.toUpperCase(),
                ).toDate();
              }
            } else {
              newDatePropsState.type = objJson.type;
            }

            setTargetTableSelectedValue(objJson.targetTable);
            setDatePropsState(newDatePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object date`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json object date`,
            );
          }

          break;
        case AdditionalDataTypeEnum.email:
        case AdditionalDataTypeEnum.label:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            setTargetTableSelectedValue(objJson.targetTable);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json objects email or label`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json objects email or label`,
            );
          }

          break;
        case AdditionalDataTypeEnum.integerlist:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newIntegerListPropsState: IntegerListProps = {
              ...integerListPropsState,
            };
            newIntegerListPropsState.initialValue = objJson.initialValue;
            newIntegerListPropsState.endValue = objJson.endValue;
            setTargetTableSelectedValue(objJson.targetTable);
            setIntegerListPropsState(newIntegerListPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object integer list`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json object integer list`,
            );
          }
          break;
        case AdditionalDataTypeEnum.link:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newLinkPropsState: LinkProps = {
              ...linkPropsState,
            };
            newLinkPropsState.url = decodeURIComponent(objJson.url);
            setTargetTableSelectedValue(objJson.targetTable);
            setLinkPropsState(newLinkPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object link`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json object link`,
            );
          }
          break;
        case AdditionalDataTypeEnum.phone:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newPhonePropsState: PhoneProps = {
              ...phonePropsState,
            };
            if (objJson.phoneValType === "ADVANCE") {
              //advance
              newPhonePropsState.validationType = "ADVANCE";
              newPhonePropsState.regularExpresion = objJson.phoneRegEXPRE;
            } else {
              newPhonePropsState.validationType = "BASIC";
              newPhonePropsState.charactersWhiteList = objJson.whiteList;
              newPhonePropsState.minimumCharacters =
                objJson.minLength === ".." ? 0 : +objJson.minLength;
              newPhonePropsState.maximumCharacters =
                objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            }
            setTargetTableSelectedValue(objJson.targetTable);
            setPhonePropsState(newPhonePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object link`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json object link`,
            );
          }
          break;
        case AdditionalDataTypeEnum.numeric:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newNumericPropsState: NumericProps = {
              ...numericPropsState,
            };
            if (objJson.minValue !== "IN" && objJson.minValue !== "") {
              newNumericPropsState.minValueValidationType = "VAL";
              newNumericPropsState.minValue = objJson.minValue;
            } else {
              newNumericPropsState.minValueValidationType = objJson.minValue;
            }
            if (objJson.maxValue !== "IN" && objJson.maxValue !== "") {
              newNumericPropsState.maxValueValidationType = "VAL";
              newNumericPropsState.maxValue = objJson.maxValue;
            } else {
              newNumericPropsState.maxValueValidationType = objJson.maxValue;
            }
            newNumericPropsState.percentage = objJson.percentage;
            newNumericPropsState.currencySymbolDisabled = objJson.percentage;
            newNumericPropsState.currencyplacementDisabled = objJson.percentage;
            newNumericPropsState.fixedDecimal = objJson.fixedDecimal;
            newNumericPropsState.currencySymbol = objJson.currencySymbol;
            newNumericPropsState.currencyPlacement = objJson.currencyPlacement;
            newNumericPropsState.decimalSeparator = objJson.decimalSeparator;
            newNumericPropsState.thousandsSeparator =
              objJson.thousandsSeparator;
            setTargetTableSelectedValue(objJson.targetTable);
            setNumericPropsState(newNumericPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert numeric json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert numeric json object`,
            );
          }
          break;
        case AdditionalDataTypeEnum.textbox:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newOpenTextPropsState: OpenTextProps = {
              ...openTextPropsState,
            };
            newOpenTextPropsState.type = objJson.type;
            newOpenTextPropsState.maxLength =
              objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            newOpenTextPropsState.inputMask = objJson.inputMask;
            newOpenTextPropsState.placeHolder = objJson.placeHolder;
            newOpenTextPropsState.whiteList = objJson.whiteList;
            setTargetTableSelectedValue(objJson.targetTable);
            setOpenTextPropsState(newOpenTextPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert open text json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert open textjson object`,
            );
          }
          break;
        case AdditionalDataTypeEnum.textarea:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newOpenTextMultilinePropsState: OpenTextMultilineProps = {
              ...openTextMultilinePropsState,
            };
            newOpenTextMultilinePropsState.type = objJson.type;
            newOpenTextMultilinePropsState.maxLength =
              objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            newOpenTextMultilinePropsState.whiteList = objJson.whiteList;
            setTargetTableSelectedValue(objJson.targetTable);
            setOpenTextMultilinePropsState(newOpenTextMultilinePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert open text multiline json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert open textjson multiline object`,
            );
          }
          break;
        case AdditionalDataTypeEnum.yesno:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newYesNoPropsState: YesNoProps = {
              ...yesNoPropsState,
            };
            newYesNoPropsState.renderMethod = objJson.renderMethod;
            newYesNoPropsState.caption = objJson.caption;
            setTargetTableSelectedValue(objJson.targetTable);
            setYesNoPropsState(newYesNoPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert Yes-No json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert Yes-No json object`,
            );
          }
          break;
        case AdditionalDataTypeEnum.listvalue:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newListValuePropsState: ListValueProps = {
              ...listValuePropsState,
            };
            newListValuePropsState.treeId = objJson.treeId;
            // newListValuePropsState.allowMultipleSelection =
            //   objJson.allowMultipleSelection;
            newListValuePropsState.renderMethod = objJson.renderMethod;
            setTargetTableSelectedValue(objJson.targetTable);
            setListValuePropsState(newListValuePropsState);
            await getBranchInfo(objJson.parentId);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert list value json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert list value json object`,
            );
          }
          break;
        case AdditionalDataTypeEnum.tree:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newTreePropsState: TreeProps = {
              ...treePropsState,
            };
            newTreePropsState.treeId = objJson.treeId;
            setTargetTableSelectedValue(objJson.targetTable);
            setTreePropsState(newTreePropsState);
            await getTreeBranchInfo(objJson.parentId);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert list value json object`,
              TPLogType.ERROR,
              error,
            );
            console.error(
              `Error ${componentFileName} Error while try to convert list value json object`,
            );
          }
          break;
        default:
          break;
      }

      setInsertUpdateState(newInsertUpdateState);

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAdditionalDataById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getAdditionalDataById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  //#region handlers Date Type
  const handleMinDateTypeChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateType = e.target.value;
    setDatePropsState(newDatePropsState);
  };

  const handleMinDateSymbolChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateSymbol = e.target.value;
    setDatePropsState(newDatePropsState);
  };

  const handleMinDateChange = (newMinDate: number) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateDays = newMinDate;
    setDatePropsState(newDatePropsState);
  };

  const handleMinDateValueChange = (newMinDate: Date) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDate = newMinDate;
    newDatePropsState.minDateErrorMessage = "";
    setDatePropsState(newDatePropsState);
  };

  const handleMaxDateTypeChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateType = e.target.value;
    setDatePropsState(newDatePropsState);
  };

  const handleMaxDateSymbolChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateSymbol = e.target.value;
    setDatePropsState(newDatePropsState);
  };

  const handleMaxDateChange = (newMinDate: number) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateDays = newMinDate;
    setDatePropsState(newDatePropsState);
  };

  const handleMaxDateValueChange = (newMaxDate: Date) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDate = newMaxDate;
    newDatePropsState.maxDateErrorMessage = "";
    setDatePropsState(newDatePropsState);
  };

  const handlerDateTypeChange = (e: any) => {
    let newDateState = { ...datePropsState };
    newDateState.type = e.target.value;
    setDatePropsState(newDateState);
  };
  //#endregion handlers Date Type

  //#region handlers Integer List Type
  const handlerInitialValueChange = (newInitialValue: number) => {
    let newIntegerListPropsState = { ...integerListPropsState };
    newIntegerListPropsState.initialValue = newInitialValue;
    newIntegerListPropsState.initialValueErrorMessage = "";
    setIntegerListPropsState(newIntegerListPropsState);
  };
  const handlerEndValueChange = (newEndValue: number) => {
    let newIntegerListPropsState = { ...integerListPropsState };
    newIntegerListPropsState.endValue = newEndValue;
    setIntegerListPropsState(newIntegerListPropsState);
  };
  //#endregion Integer List Type

  // const handlerOnUrlChange = (e: any) => {
  //   let newLinkPropsState = { ...linkPropsState };
  //   newLinkPropsState.url = e.target.value;
  //   newLinkPropsState.urlErrorMessage = "";
  //   setLinkPropsState(newLinkPropsState);
  // };

  //#region link type
  const handlerOnUrlChange = (e: any) => {
    const url = e.target.value.trim(); // Trim whitespace from the input
    let newLinkPropsState = { ...linkPropsState };

    // Basic URL validation regex pattern
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)*$/;

    if (url === "" || urlPattern.test(url)) {
      // Valid URL
      newLinkPropsState.url = url;
      newLinkPropsState.urlErrorMessage = "";
    } else {
      // Invalid URL
      newLinkPropsState.url = url;
      newLinkPropsState.urlErrorMessage = validUrlErrorMessage;
    }

    setLinkPropsState(newLinkPropsState);
  };
  //#endregion link type

  //#region Phone type
  const handleValidationTypeChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.validationType = e.target.value;
    setPhonePropsState(newPhonePropsState);
  };

  const handlerOnMinimumValueChange = (newMinimumCharacters: number) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.minimumCharacters = newMinimumCharacters;
    newPhonePropsState.minimumCharactersErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };

  const handlerOnMaximumValueChange = (newMaximumCharacters: number) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.maximumCharacters = newMaximumCharacters;
    newPhonePropsState.minimumCharactersErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };

  const handlerOnWhiteListChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.charactersWhiteList = e.target.value;
    newPhonePropsState.charactersWhiteListErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };

  const handlerOnRegularExpresionChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.regularExpresion = e.target.value;
    newPhonePropsState.regularExpresionErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };
  //#endregion Phone type

  //#region Numeric Type
  const handlerMinValueValidationChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.minValueValidationType = e.target.value;
    newNumericPropsState.minValueTypeErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerMaxValueValidationChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.maxValueValidationType = e.target.value;
    newNumericPropsState.maxValueTypeErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerMinValueNumericChange = (newMinValue: number) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.minValue = newMinValue;
    newNumericPropsState.minValueErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerMaxValueNumericChange = (newMaxValue: number) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.maxValue = newMaxValue;
    newNumericPropsState.maxValueErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerPercentageChange = () => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.percentage = !newNumericPropsState.percentage;
    newNumericPropsState.currencySymbolDisabled =
      newNumericPropsState.percentage;
    newNumericPropsState.currencyplacementDisabled =
      newNumericPropsState.percentage;
    newNumericPropsState.currencyPlacement = "";
    newNumericPropsState.currencySymbol = "";
    newNumericPropsState.currencyPlacementErrorMessage =
      newNumericPropsState.percentage
        ? ""
        : newNumericPropsState.currencyPlacementErrorMessage;
    setNumericPropsState(newNumericPropsState);
  };

  const handlerFixedDecimalPlacesChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.fixedDecimal = e.target.value;
    setNumericPropsState(newNumericPropsState);
  };

  const handlerOnCurrencySymbolChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.currencySymbol = e.target.value;
    newNumericPropsState.currencyPlacementErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerPlacementChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.currencyPlacement = e.target.value;
    newNumericPropsState.currencyPlacementErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerDecimalSeparatorChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.decimalSeparator = e.target.value;
    newNumericPropsState.thousandsSeparatorErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };

  const handlerThousandsSeparatorChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.thousandsSeparator = e.target.value;
    newNumericPropsState.thousandsSeparatorErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  //#endregion Numeric Type

  //#region Open text Type
  const handlerOpenTextTypeChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.type = e.target.value;
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerMaxLengthValueChange = (newMaxLength: number) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.maxLength = newMaxLength;
    newOpenTextState.maxLengthErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerOnInputMaskChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.inputMask = e.target.value;
    newOpenTextState.inputMaskErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };

  const handlerOnCharactersWhiteListChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.whiteList = e.target.value;
    newOpenTextState.whiteListErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };

  const handlerOnPlaceHolderChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.placeHolder = e.target.value;
    setOpenTextPropsState(newOpenTextState);
  };
  //#endregion Open text Type

  //#region open text multiline

  const handlerOpenTextMultilineTypeChange = (e: any) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.type = e.target.value;
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };
  const handlerMaxLengthMultilineValueChange = (newMaxLength: number) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.maxLength = newMaxLength;
    newOpenTextMultilineState.maxLengthErrorMessage = "";
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };

  const handlerOnCharactersWhiteListMultilineChange = (e: any) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.whiteList = e.target.value;
    newOpenTextMultilineState.whiteListErrorMessage = "";
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };
  //#endregion open text multiline

  //#region Yes-No
  const handlerRenderMethodChange = (e: any) => {
    let newYesNoState = { ...yesNoPropsState };
    newYesNoState.renderMethod = e.target.value;
    newYesNoState.renderMethodErrorMessage = "";
    if (newYesNoState.renderMethod.length > 0) {
      setIsYesNoError(false);
    }
    setYesNoPropsState(newYesNoState);
  };

  const handlerCaptionChange = (e: any) => {
    let newYesNoState = { ...yesNoPropsState };
    newYesNoState.caption = e.target.value;
    newYesNoState.captionErrorMessage = "";
    setYesNoPropsState(newYesNoState);
  };
  //#endregion Yes-No

  //#region Value List from tree branch
  const handlerTreeIdChange = (e: any, topic: string) => {
    switch (topic) {
      case "listvalue":
        let newListValueState = { ...listValuePropsState };
        newListValueState.treeId = e.target.value;
        newListValueState.treeIdErrorMessage = "";
        setListValuePropsState(newListValueState);

        let newselectedBranch: TPBranchSelectionValue = {
          ...selectedBranchListValue,
        };
        newselectedBranch.branchId = "";
        newselectedBranch.branchDescription = "";
        newselectedBranch.branchHierarchyDescription = "";
        setSelectedBranchListValue(newselectedBranch);
        break;
      case "tree":
        let newTreeState = { ...treePropsState };
        newTreeState.treeId = e.target.value;
        newTreeState.treeIdErrorMessage = "";
        setTreePropsState(newTreeState);

        let newselectedTreeBranch: TPBranchSelectionValue = {
          ...selectedTreeBranchListValue,
        };
        newselectedTreeBranch.branchId = "";
        newselectedTreeBranch.branchDescription = "";
        newselectedTreeBranch.branchHierarchyDescription = "";
        setSelectedTreeBranchListValue(newselectedTreeBranch);
        break;
      default:
        break;
    }
  };

  const handleOnBranchChange = (
    index: number,
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newListValueState = { ...listValuePropsState };
    newListValueState.branchSelectedErrorMessage = "";
    setListValuePropsState(newListValueState);

    let newSelectedBranchListValue: TPBranchSelectionValue = {
      ...selectedBranchListValue,
    };
    newSelectedBranchListValue.branchId = newBranchId;
    newSelectedBranchListValue.branchDescription = newBranchDescription;
    newSelectedBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedBranchListValue(newSelectedBranchListValue);
  };

  const handlerListValueRenderMethodChange = (e: any) => {
    let newListValueState = { ...listValuePropsState };
    newListValueState.renderMethod = e.target.value;
    newListValueState.renderMethodErrorMessage = "";
    setListValuePropsState(newListValueState);
  };

  // const handlerAllowMultipleSelectionChange = () => {
  //   let newListValuePropsState = { ...listValuePropsState };
  //   newListValuePropsState.allowMultipleSelection =
  //     !newListValuePropsState.allowMultipleSelection;
  //   setListValuePropsState(newListValuePropsState);
  // };

  //#endregion Value list from tree branch
  //#region Tree
  const handleOnTreeBranchChange = (
    index: number,
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newTreeState = { ...treePropsState };
    newTreeState.parentIdErrorMessage = "";
    setTreePropsState(newTreeState);

    let newSelectedTreeBranchListValue: TPBranchSelectionValue = {
      ...selectedTreeBranchListValue,
    };
    newSelectedTreeBranchListValue.branchId = newBranchId;
    newSelectedTreeBranchListValue.branchDescription = newBranchDescription;
    newSelectedTreeBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedTreeBranchListValue(newSelectedTreeBranchListValue);
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  //#endregion Tree
  const renderSwitch = (param: string) => {
    return null;
    //not part of the MVP
    // switch (param) {
    //   case AdditionalDataTypeEnum.phone:
    //   case AdditionalDataTypeEnum.numeric:
    //     return (
    //       <>
    //         <div className="row">
    //           <div className="col-10">
    //             <TPPageSection>
    //               <div className="row">
    //                 <div className="col-6">
    //                   <div className="form-group">
    //                     <TPTextArea
    //                       labelText={additionalDataValidationUrlLabel}
    //                       value={insertUpdateState.validationUrl}
    //                       onChange={(e: any) =>
    //                         handleAdditionalDataValidationUrlChange(
    //                           e.target.value
    //                         )
    //                       }
    //                       rows={3}
    //                       columns={7}
    //                     ></TPTextArea>
    //                   </div>
    //                 </div>
    //               </div>
    //             </TPPageSection>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   default:
    //
    // }
  };

  const renderFirtsRowOpenTextType = (param: string) => {
    switch (param) {
      case "Open":
        return (
          <>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPNumeric
                          id="max-length-value-numeric"
                          onChange={handlerMaxLengthValueChange}
                          value={openTextPropsState.maxLength}
                          labelText={maximumCharactersLabel}
                          errorMessage={
                            openTextPropsState.maxLengthErrorMessage
                          }
                        ></TPNumeric>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        );
      case "WhiteList":
        return (
          <>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPNumeric
                          id="max-length-value-numeric"
                          onChange={handlerMaxLengthValueChange}
                          value={openTextPropsState.maxLength}
                          labelText={maximumCharactersLabel}
                          errorMessage={
                            openTextPropsState.maxLengthErrorMessage
                          }
                        ></TPNumeric>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="white-list-input"
                          onChange={handlerOnCharactersWhiteListChange}
                          value={openTextPropsState.whiteList}
                          labelText={charactersWhiteListLabel}
                          isMandatory={true}
                          errorMessage={
                            openTextPropsState.whiteListErrorMessage
                          }
                        ></TPTextBox>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        );
      case "InputMask":
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSection>
                  <div className="form-group">
                    <TPTextBox
                      id="input-mask-text"
                      onChange={handlerOnInputMaskChange}
                      value={openTextPropsState.inputMask}
                      labelText={inputMaskLabel}
                      isMandatory={true}
                      errorMessage={openTextPropsState.inputMaskErrorMessage}
                      // withIcon={true}
                      // icon={TPIconTypes.notifications}
                    ></TPTextBox>
                  </div>
                </TPPageSection>
              </div>
              <div className="col-5">
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      id="placeholder"
                      onChange={handlerOnPlaceHolderChange}
                      dataSource={[
                        { key: " ", value: "" },
                        { key: "LP", value: "--" },
                      ]}
                      value={openTextPropsState.placeHolder}
                      labelText={placeholderLabel}
                      isHorizontal={false}
                    ></TPSelect>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };
  const renderFirtsRowOpenTextMultilineType = (param: string) => {
    switch (param) {
      case "Open":
        return (
          <>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPNumeric
                          id="max-multi-length-numeric"
                          onChange={handlerMaxLengthMultilineValueChange}
                          value={openTextMultilinePropsState.maxLength}
                          labelText={maximumCharactersLabel}
                          errorMessage={
                            openTextMultilinePropsState.maxLengthErrorMessage
                          }
                        ></TPNumeric>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        );
      case "WhiteList":
        return (
          <>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPNumeric
                          id="max-length-multi-text"
                          onChange={handlerMaxLengthMultilineValueChange}
                          value={openTextMultilinePropsState.maxLength}
                          labelText={maximumCharactersLabel}
                          errorMessage={
                            openTextMultilinePropsState.maxLengthErrorMessage
                          }
                        ></TPNumeric>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="white-list-multi-text"
                          onChange={handlerOnCharactersWhiteListMultilineChange}
                          value={openTextMultilinePropsState.whiteList}
                          labelText={charactersWhiteListLabel}
                          isMandatory={true}
                          errorMessage={
                            openTextMultilinePropsState.whiteListErrorMessage
                          }
                        ></TPTextBox>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };

  //render only min or max or both for numbers

  const renderMinMaxValues = (toRender: any) => {
    const jsxMin = (
      <div className="form-group">
        <TPNumeric
          id="min-value-numeric"
          onChange={handlerMinValueNumericChange}
          value={numericPropsState.minValue}
          labelText={minimumValueLabel}
          isMandatory={numericPropsState.minValueValidationType === "VAL"}
          errorMessage={numericPropsState.minValueErrorMessage}
        ></TPNumeric>
      </div>
    );
    const jsxMax = (
      <div className="form-group">
        <TPNumeric
          id="max-value-numeric"
          onChange={handlerMaxValueNumericChange}
          value={numericPropsState.maxValue}
          labelText={maximumValueLabel}
          isMandatory={numericPropsState.maxValueValidationType === "VAL"}
          errorMessage={numericPropsState.maxValueErrorMessage}
        ></TPNumeric>
      </div>
    );
    let jsxToReturn;
    switch (toRender) {
      case "min":
        jsxToReturn = (
          <div className="row">
            <div className="col-6">{jsxMin}</div>
            <div className="col-6">&nbsp;</div>
          </div>
        );
        break;
      case "max":
        jsxToReturn = (
          <div className="row">
            <div className="col-6">&nbsp;</div>
            <div className="col-6">{jsxMax}</div>
          </div>
        );
        break;
      case "both":
        jsxToReturn = (
          <div className="row">
            <div className="col-6">{jsxMin}</div>
            <div className="col-6">{jsxMax}</div>
          </div>
        );
        break;
      default:
        break;
    }

    return jsxToReturn;
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQADDA,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    loadResourcesAndLoadAdditionalDataInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {mode == "Update" ? (
              <TPPageSubTitle>{`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}</TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="record-id-text"
                      labelText={idLabel}
                      isMandatory={true}
                      value={mode === "Insert" ? referenceId : recordId}
                      onChange={
                        mode === "Insert"
                          ? (e: any) =>
                              handleOnIdAdditionalDataChange(e.target.value)
                          : () => {
                              TPGlobal.foo();
                            }
                      }
                      maxLength={20}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={"languageItem" + item.id}>
                          <div className="form-group">
                            <TPTextBox
                              id={`language-${item.id}-text`}
                              isMandatory={index === 0}
                              labelText={`${descriptionLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={100}
                              errorMessage={
                                insertUpdateState.descriptionsErrorMessages[
                                  index
                                ]
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-4">
                      <div className="pt-4">
                        <TPButton
                          id="open-modal-languages"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                    <TPModalLanguageList
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id={`md-language-${item.id}-text`}
                                    isMandatory={index === 0}
                                    labelText={`${descriptionLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value,
                                      )
                                    }
                                    maxLength={200}
                                    errorMessage={
                                      insertUpdateState
                                        .descriptionsErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="category"
                      isMandatory={true}
                      onChange={handleAdditionalDataCategoryChange}
                      dataSource={additionalDataCategoryList}
                      value={insertUpdateState.idAdditionalDataCategory}
                      labelText={additionalDataCategoryLabel}
                      errorMessage={
                        insertUpdateState.idAdditionalDataCategoryErrorMessage
                      }
                    ></TPSelect>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPTextArea
                      id="additional-comments-area"
                      labelText={additionalDataCommentsLabel}
                      value={insertUpdateState.comments}
                      onChange={(e: any) =>
                        handleAdditionalDataCommentsChange(e.target.value)
                      }
                      maxLength={500}
                      errorMessage={insertUpdateState.commentsErrorMessage}
                      rows={3}
                      columns={7}
                    ></TPTextArea>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="type"
                      isMandatory={true}
                      onChange={handleAdditionalDataTypeChange}
                      dataSource={dataAddaTypeListState}
                      value={insertUpdateState.idAdditionalDataType}
                      labelText={additionalDataTypeLabel}
                      errorMessage={
                        insertUpdateState.idAdditionalDataTypeErrorMessage
                      }
                    ></TPSelect>
                  </div>
                </div>
                {insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT &&
                  insertUpdateState.idAdditionalDataType &&
                  conditionIsValidByType() && (
                  <div className="col-6">
                    <div className="form-group">
                      <TPSelect
                        id="target-table"
                        isMandatory={true}
                        onChange={handlerTargetTableChange}
                        dataSource={targetTableListState}
                        value={targetTableSelectedValue}
                        labelText={targetTableLabel}
                        errorMessage={targetTableErrorMessage}
                      ></TPSelect>
                    </div>
                  </div>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6 mt-4">
                  <div className="form-group">
                    <TPCheckBox
                      id="modify-data-check"
                      labelText={enableEditingModifyBasicDataLabel}
                      checked={insertUpdateState.enableEditingModifyBasicData}
                      onChange={(e: any) =>
                        handleEnableEditingModifyBasicDataChange()
                      }
                    ></TPCheckBox>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        {insertUpdateState &&
        insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.date ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10 ">
                <TPPageSection>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <TPRadioGroup
                          id="additional-data"
                          value={datePropsState.type}
                          onChange={handlerDateTypeChange}
                          isHorizontal={false}
                          source={[
                            {
                              key: AdditionalDataDateTypeEnum.anyDate,
                              value: anyDateItemLabel,
                            },
                            {
                              key: AdditionalDataDateTypeEnum.dateRange,
                              value: dateRangeItemLabel,
                            },
                          ]}
                          labelText={dateTypeLabel}
                        ></TPRadioGroup>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
            {datePropsState.type == AdditionalDataDateTypeEnum.dateRange && (
              <>
                <div className="row mt-3">
                  <div className="col-10 ">
                    <TPPageSection>
                      <div className="row">
                        <div className="col-12 d-flex gap-3">
                          <div className="form-group">
                            <TPSelect
                              id="min-date"
                              isMandatory={false}
                              onChange={handleMinDateTypeChange}
                              dataSource={[
                                { key: "fixed", value: fixedValueLabel },
                                { key: "variable", value: variableValueLabel },
                              ]}
                              value={datePropsState.minDateType}
                              labelText={minDateLabel}
                            ></TPSelect>
                            </div>
                          {datePropsState.minDateType == "variable" ? (
                            <>
                              <div className="form-group">
                                <TPSelect
                                  id="min-date-symbol"
                                  isMandatory={false}
                                  onChange={handleMinDateSymbolChange}
                                  dataSource={[
                                    { key: "+", value: "+" },
                                    { key: "-", value: "-" },
                                  ]}
                                  value={datePropsState.minDateSymbol}
                                  labelText={todayDateLabel}
                                ></TPSelect>
                              </div>

                              <div className="form-group">
                                <TPNumeric
                                  id="min-date-numeric"
                                  onChange={handleMinDateChange}
                                  value={datePropsState.minDateDays}
                                  labelText={daysDateLabel}
                                ></TPNumeric>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="form-group">
                                <TPDatePicker
                                  labelText={minimumDateValueLabel}
                                  isMandatory={true}
                                  onChangeDate={handleMinDateValueChange}
                                  customDateFormat={TPGlobal.dateFormat}
                                  selectedDate={datePropsState.minDate}
                                  errorMessage={
                                    datePropsState.minDateErrorMessage
                                  }
                                  maxDate={datePropsState.maxDate}
                                ></TPDatePicker>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 ">
                    <TPPageSection>
                      <div className="row">
                        <div className="col-12 d-flex gap-3">
                          <div className="form-group">
                            <TPSelect
                              id="max-date"
                              isMandatory={false}
                              onChange={handleMaxDateTypeChange}
                              dataSource={[
                                { key: "fixed", value: fixedValueLabel },
                                { key: "variable", value: variableValueLabel },
                              ]}
                              value={datePropsState.maxDateType}
                              labelText={maxDateLabel}
                            ></TPSelect>
                          </div>

                          {datePropsState.maxDateType == "variable" ? (
                            <>
                              <div className="form-group">
                                <TPSelect
                                  id="max-date-symbol"
                                  isMandatory={false}
                                  onChange={handleMaxDateSymbolChange}
                                  dataSource={[
                                    { key: "+", value: "+" },
                                    { key: "-", value: "-" },
                                  ]}
                                  value={datePropsState.maxDateSymbol}
                                  labelText={todayDateLabel}
                                ></TPSelect>
                                </div>
                              <div className="form-group">
                                <TPNumeric
                                  id="max-date-numeric"
                                  onChange={handleMaxDateChange}
                                  value={datePropsState.maxDateDays}
                                  labelText={daysDateLabel}
                                ></TPNumeric>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="form-group">
                                <TPDatePicker
                                  labelText={maximumDateValueLabel}
                                  isMandatory={true}
                                  onChangeDate={handleMaxDateValueChange}
                                  customDateFormat={TPGlobal.dateFormat}
                                  selectedDate={datePropsState.maxDate}
                                  errorMessage={
                                    datePropsState.maxDateErrorMessage
                                  }
                                  minDate={datePropsState.minDate}
                                ></TPDatePicker>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>
              </>
            )}
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.integerlist ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>

            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <TPNumeric
                          id="initial-value-numeric"
                          onChange={handlerInitialValueChange}
                          value={integerListPropsState.initialValue}
                          labelText={initialValueLabel}
                          errorMessage={
                            integerListPropsState.initialValueErrorMessage
                          }
                        ></TPNumeric>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <TPNumeric
                          id="end-value-numeric"
                          onChange={handlerEndValueChange}
                          value={integerListPropsState.endValue}
                          labelText={endValueLabel}
                        ></TPNumeric>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.link ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextArea
                          id="url-text-area"
                          isMandatory={true}
                          onChange={handlerOnUrlChange}
                          value={linkPropsState.url}
                          rows={3}
                          columns={4}
                          labelText={urlLabel}
                          errorMessage={linkPropsState.urlErrorMessage}
                        ></TPTextArea>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.phone ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>

            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <TPSelect
                          id="validation-type"
                          isMandatory={true}
                          onChange={handleValidationTypeChange}
                          dataSource={[
                            { key: "BASIC", value: basicLabel },
                            { key: "ADVANCE", value: advanceLabel },
                          ]}
                          value={phonePropsState.validationType}
                          labelText={validationTypeLabel}
                          isHorizontal={false}
                        ></TPSelect>
                      </div>
                    </div>
                    {phonePropsState.validationType === "BASIC" ? (
                      <>
                        <div className="col-4">
                          <div className="form-group">
                            <TPNumeric
                              id="minimum-characters-numeric"
                              onChange={handlerOnMinimumValueChange}
                              value={phonePropsState.minimumCharacters}
                              labelText={minimumCharactersLabel}
                              errorMessage={
                                phonePropsState.minimumCharactersErrorMessage
                              }
                            ></TPNumeric>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <TPNumeric
                              id="maximum-characters-numeric"
                              onChange={handlerOnMaximumValueChange}
                              value={phonePropsState.maximumCharacters}
                              labelText={maximumCharactersLabel}
                            ></TPNumeric>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <TPTextBox
                                id="whitelist-characters-text"
                                onChange={handlerOnWhiteListChange}
                                value={phonePropsState.charactersWhiteList}
                                labelText={charactersWhiteListLabel}
                                isMandatory={true}
                                errorMessage={
                                  phonePropsState.charactersWhiteListErrorMessage
                                }
                              ></TPTextBox>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-8">
                          <div className="form-group">
                            <TPTextBox
                              id="regular-expression-text"
                              onChange={handlerOnRegularExpresionChange}
                              value={phonePropsState.regularExpresion}
                              labelText={regularExpressionLabel}
                              isMandatory={true}
                              errorMessage={
                                phonePropsState.regularExpresionErrorMessage
                              }
                            ></TPTextBox>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </TPPageSection>
              </div>
            </div>

            {renderSwitch(insertUpdateState.idAdditionalDataType)}
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.numeric ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
                      </div>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="min-value"
                          isMandatory={true}
                          onChange={handlerMinValueValidationChange}
                          dataSource={[
                            { key: "", value: "--" },
                            { key: "IN", value: naLabel },
                            { key: "VAL", value: valueLabel },
                          ]}
                          value={numericPropsState.minValueValidationType}
                          labelText={minimumValueTypeLabel}
                          isHorizontal={false}
                          errorMessage={
                            numericPropsState.minValueTypeErrorMessage
                          }
                        ></TPSelect>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="max-value"
                          isMandatory={true}
                          onChange={handlerMaxValueValidationChange}
                          dataSource={[
                            { key: "", value: "--" },
                            { key: "IN", value: naLabel },
                            { key: "VAL", value: valueLabel },
                          ]}
                          value={numericPropsState.maxValueValidationType}
                          labelText={maximumValueTypeLabel}
                          isHorizontal={false}
                          errorMessage={
                            numericPropsState.maxValueTypeErrorMessage
                          }
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                  {numericPropsState.minValueValidationType === "VAL" &&
                    numericPropsState.maxValueValidationType !== "VAL" &&
                    renderMinMaxValues("min")}
                  {numericPropsState.minValueValidationType !== "VAL" &&
                    numericPropsState.maxValueValidationType === "VAL" &&
                    renderMinMaxValues("max")}
                  {numericPropsState.minValueValidationType === "VAL" &&
                    numericPropsState.maxValueValidationType === "VAL" &&
                    renderMinMaxValues("both")}
                  <div className="row">
                    <div className="col-6 mt-3">
                      <div className="form-group">
                        <TPCheckBox
                          id="percentage-text"
                          labelText={percentageLabel}
                          checked={numericPropsState.percentage}
                          onChange={(e: any) => handlerPercentageChange()}
                        ></TPCheckBox>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="currency-symbol-text"
                          onChange={handlerOnCurrencySymbolChange}
                          value={numericPropsState.currencySymbol}
                          labelText={currencySymbolLabel}
                          maxLength={3}
                          disabled={numericPropsState.currencySymbolDisabled}
                        ></TPTextBox>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="currency-placement"
                          isMandatory={
                            numericPropsState.currencySymbol ? true : false
                          }
                          onChange={handlerPlacementChange}
                          dataSource={[
                            { key: "", value: ".." },
                            { key: "Before", value: beforeLabel },
                            { key: "After", value: afterLabel },
                          ]}
                          value={numericPropsState.currencyPlacement}
                          labelText={currencyPlacementLabel}
                          isHorizontal={false}
                          disabled={numericPropsState.currencyplacementDisabled}
                          errorMessage={
                            numericPropsState.currencyPlacementErrorMessage
                          }
                        ></TPSelect>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="decimal-places"
                          isMandatory={true}
                          onChange={handlerFixedDecimalPlacesChange}
                          dataSource={[
                            { key: "None", value: noneLabel },
                            { key: "0", value: "0" },
                            { key: "1", value: "1" },
                            { key: "2", value: "2" },
                            { key: "3", value: "3" },
                            { key: "4", value: "4" },
                            { key: "5", value: "5" },
                            { key: "6", value: "6" },
                          ]}
                          value={numericPropsState.fixedDecimal}
                          labelText={fixedDecimalPlacesLabel}
                          isHorizontal={false}
                        ></TPSelect>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="decimal-separator"
                          isMandatory={true}
                          onChange={handlerDecimalSeparatorChange}
                          dataSource={[
                            { key: "", value: "--" },
                            { key: ".", value: "." },
                            { key: ",", value: "," },
                          ]}
                          value={numericPropsState.decimalSeparator}
                          labelText={decimalSeparatorLabel}
                          isHorizontal={false}
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="thousand-separator"
                          isMandatory={true}
                          onChange={handlerThousandsSeparatorChange}
                          dataSource={[
                            { key: "", value: noneLabel },
                            { key: ".", value: "." },
                            { key: ",", value: "," },
                            { key: " ", value: spaceLabel },
                          ]}
                          value={numericPropsState.thousandsSeparator}
                          labelText={thousandsSeparatorLabel}
                          isHorizontal={false}
                          errorMessage={
                            numericPropsState.thousandsSeparatorErrorMessage
                          }
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>

            {renderSwitch(insertUpdateState.idAdditionalDataType)}
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.textbox ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row ">
              <div className="col justify-content-center">
                <TPPageSection>
                  <div className="form-group">
                    <TPRadioGroup
                      id="state"
                      value={openTextPropsState.type}
                      onChange={handlerOpenTextTypeChange}
                      isHorizontal={false}
                      source={[
                        { key: "Open", value: openLabel },
                        { key: "WhiteList", value: whiteListLabel },
                      ]}
                      labelText={textboxTypeLabel}
                    ></TPRadioGroup>
                  </div>
                </TPPageSection>
              </div>
            </div>

            {renderFirtsRowOpenTextType(openTextPropsState.type)}
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.textarea ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row ">
              <div className="col justify-content-center">
                <TPPageSection>
                  <div className="form-group">
                    <TPRadioGroup
                      id="multiline"
                      value={openTextMultilinePropsState.type}
                      onChange={handlerOpenTextMultilineTypeChange}
                      isHorizontal={false}
                      source={[
                        { key: "Open", value: openLabel },
                        { key: "WhiteList", value: whiteListLabel },
                      ]}
                      labelText={textboxTypeLabel}
                    ></TPRadioGroup>
                  </div>
                </TPPageSection>
              </div>
            </div>
            {renderFirtsRowOpenTextMultilineType(
              openTextMultilinePropsState.type,
            )}
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.yesno ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      id="render-method"
                      isMandatory={true}
                      onChange={handlerRenderMethodChange}
                      dataSource={[
                        { key: "", value: ".." },
                        { key: "Combobox", value: comboboxLabel },
                        { key: "Option", value: optionLabel },
                        { key: "Checkbox", value: checkboxLabel },
                      ]}
                      value={yesNoPropsState.renderMethod}
                      errorMessage={yesNoPropsState.renderMethodErrorMessage}
                      labelText={controlLabel}
                      className={isYesNoError ? "is-invalid" : ""}
                    ></TPSelect>
                  </div>
                </TPPageSection>
              </div>
              <div className="col-5">
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      id="caption"
                      isMandatory={true}
                      onChange={handlerCaptionChange}
                      dataSource={[
                        { key: "", value: ".." },
                        { key: "YesNoOption", value: yesNoLabel },
                        { key: "TrueFalseOption", value: trueFalseLabel },
                      ]}
                      value={yesNoPropsState.caption}
                      errorMessage={yesNoPropsState.captionErrorMessage}
                      labelText={captionLabel}
                    ></TPSelect>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.listvalue ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="tree"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handlerTreeIdChange(e, "listvalue")
                          }
                          dataSource={treeSourceList}
                          value={listValuePropsState.treeId}
                          errorMessage={listValuePropsState.treeIdErrorMessage}
                          labelText={treeLabel}
                        ></TPSelect>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPBranchSelection2
                          isMandatory={true}
                          labelText={parentIdLabel}
                          treeId={listValuePropsState.treeId}
                          modalAcceptLabel={modalAcceptLabel}
                          modalCancelLabel={modalCancelLabel}
                          modalSelectedBranchLabel={modalSelectedBranchLabel}
                          modalTitle={modalTitleLabel}
                          mustSelectLastLevelBranch={false}
                          value={selectedBranchListValue}
                          onChange={(
                            branchId: string,
                            branchDescription: string,
                            branchHierachyDescription: string,
                          ) => {
                            handleOnBranchChange(
                              1,
                              branchId,
                              branchDescription,
                              branchHierachyDescription,
                            );
                          }}
                          emptyLabel={emptyLabel}
                          errorMessage={
                            listValuePropsState.branchSelectedErrorMessage
                          }
                          onIsLoadingProgress={(value: boolean) => {
                            setIsLoadingScreen(value);
                          }}
                        ></TPBranchSelection2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="render-value-method"
                          isMandatory={true}
                          onChange={handlerListValueRenderMethodChange}
                          dataSource={[
                            { key: "", value: ".." },
                            { key: "Combobox", value: comboboxLabel },
                            { key: "Option", value: optionLabel },
                            { key: "Checkbox", value: checkboxLabel },
                          ]}
                          value={listValuePropsState.renderMethod}
                          errorMessage={
                            listValuePropsState.renderMethodErrorMessage
                          }
                          labelText={controlLabel}
                        ></TPSelect>
                      </div>
                                      </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        ) : insertUpdateState.idAdditionalDataType ==
          AdditionalDataTypeEnum.tree ? (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>
                  {configurationSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="tree-id"
                          isMandatory={true}
                          onChange={(e: any) => handlerTreeIdChange(e, "tree")}
                          dataSource={treeSourceList}
                          value={treePropsState.treeId}
                          errorMessage={treePropsState.treeIdErrorMessage}
                          labelText={treeLabel}
                        ></TPSelect>
                      </div>
                    </div>
                    <div className="col-6">
                      <TPBranchSelection2
                        isMandatory={true}
                        labelText={parentIdLabel}
                        treeId={treePropsState.treeId}
                        modalAcceptLabel={modalAcceptLabel}
                        modalCancelLabel={modalCancelLabel}
                        modalSelectedBranchLabel={modalSelectedBranchLabel}
                        modalTitle={modalTitleLabel}
                        mustSelectLastLevelBranch={false}
                        value={selectedTreeBranchListValue}
                        onChange={(
                          branchId: string,
                          branchDescription: string,
                          branchHierachyDescription: string,
                        ) => {
                          handleOnTreeBranchChange(
                            1,
                            branchId,
                            branchDescription,
                            branchHierachyDescription,
                          );
                        }}
                        emptyLabel={emptyLabel}
                        errorMessage={treePropsState.parentIdErrorMessage}
                        onIsLoadingProgress={(value: boolean) => {
                          setIsLoadingScreen(value);
                        }}
                      ></TPBranchSelection2>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        ) : null}
        <div className="row mt-4">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="save-additional-data"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="cancel"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default AdditionalDataInsertUpdate;
