import { ReactNode, createContext, useContext, useEffect } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { useStatusFormDesigner } from "./useStatusFormDesignerAdmin";
import {
  FormDeignerModel,
  FormDesignerRowModel,
  FormModel,
  ItemRow,
  SectionModel,
} from "@/models/FormDesigner/FormDesigner";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";

type AppProviderImagesAdminProps = {
  children: ReactNode;
};

class model {
  status: Array<FormDeignerModel> = new Array<FormDeignerModel>();
  rowsFormDesingner: FormModel = new FormModel();
  sectionFormDesingner: Array<SectionModel> = new Array<SectionModel>();
  statusLoading: boolean = false;
  isUpdate: boolean = false;
  additionalData: Array<AdditionalDataViewModel> =
    new Array<AdditionalDataViewModel>();
  setAdditionalData: (list: Array<AdditionalDataViewModel>) => void = (
    list: Array<AdditionalDataViewModel>,
  ) => void {};
  handleEditForm: (form: FormModel) => void = (form: FormModel) => void {};
  handleBuilderAddNewRowForm: (item: FormDesignerRowModel) => void = (
    item: FormDesignerRowModel,
  ) => {};
  handleBuilderUpdateRowForm: (item: FormDesignerRowModel) => void = (
    item: FormDesignerRowModel,
  ) => {};
  handleBuilderDeleteRowForm: (item: FormDesignerRowModel) => void = (
    item: FormDesignerRowModel,
  ) => {};
  handleOrderUpAndDown: (item: FormDesignerRowModel) => void = (
    item: FormDesignerRowModel,
  ) => {};
  handleBuilderAddNewControl: (item: ItemRow) => void = (item: ItemRow) =>
    void {};
  handleBuilderAddNewSectionForm: (item: SectionModel) => void = (
    item: SectionModel,
  ) => {};
  handleBuilderDeleteSection: (item: SectionModel) => void = (
    item: SectionModel,
  ) => {};
  handleOrderUpAndDownSection: (item: SectionModel) => void = (
    item: SectionModel,
  ) => {};
  handleColorForm: (color: string) => void = (color: string) => void {};
  handleEditTitleSection: (title: SectionModel) => void = (
    title: SectionModel,
  ) => void {};
  handleTitleForm: (title: string) => void = (title: string) => void {};
  setStatusLoading: (status: boolean) => void = (status: boolean) => void {};
  setIsUpdate: (status: boolean) => void = (status: boolean) => void {};
  handleLoadSection: () => void = () => void {};
  handleOrderUpAndDownColumn: (columns: Array<ItemRow>) => void = (
    columns: Array<ItemRow>,
  ) => void {};
}

type modelContext = {
  status: Array<FormDeignerModel>;
  rowsFormDesingner: FormModel;
  sectionFormDesingner: Array<SectionModel>;
  statusLoading: boolean;
  isUpdate: boolean;
  additionalData: Array<AdditionalDataViewModel>;
  setAdditionalData: (list: Array<AdditionalDataViewModel>) => void;
  handleEditForm: (form: FormModel) => void;
  handleBuilderAddNewRowForm: (item: FormDesignerRowModel) => void;
  handleBuilderUpdateRowForm: (item: FormDesignerRowModel) => void;
  handleBuilderDeleteRowForm: (item: FormDesignerRowModel) => void;
  handleOrderUpAndDown: (item: FormDesignerRowModel) => void;
  handleBuilderAddNewControl: (item: ItemRow) => void;
  handleBuilderAddNewSectionForm: (item: SectionModel) => void;
  handleBuilderDeleteSection: (item: SectionModel) => void;
  handleOrderUpAndDownSection: (item: SectionModel) => void;
  handleColorForm: (item: string) => void;
  handleEditTitleSection: (title: SectionModel) => void;
  handleTitleForm: (title: string) => void;
  setStatusLoading: (status: boolean) => void;
  setIsUpdate: (status: boolean) => void;
  handleLoadSection: () => void;
  handleOrderUpAndDownColumn: (columns: Array<ItemRow>) => void;
};

export const FormDesignerContext = createContext<modelContext>(new model());

export const AppProviderFormDesigner: React.FC<AppProviderImagesAdminProps> = ({
  children,
}) => {
  const {
    status,
    rowsFormDesingner,
    sectionFormDesingner,
    statusLoading,
    isUpdate,
    additionalData,
    setAdditionalData,
    handleEditForm,
    handleBuilderAddNewRowForm,
    handleBuilderUpdateRowForm,
    handleBuilderDeleteRowForm,
    handleOrderUpAndDown,
    handleBuilderAddNewControl,
    handleBuilderAddNewSectionForm,
    handleBuilderDeleteSection,
    handleOrderUpAndDownSection,
    handleColorForm,
    handleEditTitleSection,
    handleTitleForm,
    setStatusLoading,
    setIsUpdate,
    handleLoadSection,
    handleOrderUpAndDownColumn,
  } = useStatusFormDesigner();

  useEffect(() => {}, []);

  return (
    <TPLoadingOverlay active={statusLoading}>
      <FormDesignerContext.Provider
        value={{
          status,
          rowsFormDesingner,
          sectionFormDesingner,
          statusLoading,
          isUpdate,
          additionalData,
          setAdditionalData,
          handleEditForm,
          handleBuilderAddNewRowForm,
          handleBuilderUpdateRowForm,
          handleBuilderDeleteRowForm,
          handleOrderUpAndDown,
          handleBuilderAddNewControl,
          handleBuilderAddNewSectionForm,
          handleBuilderDeleteSection,
          handleOrderUpAndDownSection,
          handleColorForm,
          handleEditTitleSection,
          handleTitleForm,
          setStatusLoading,
          setIsUpdate,
          handleLoadSection,
          handleOrderUpAndDownColumn,
        }}
      >
        {children}
      </FormDesignerContext.Provider>
    </TPLoadingOverlay>
  );
};

export const useAppConctextFormDesigner = () => {
  return useContext(FormDesignerContext);
};
