import TPGlobal from "@/helpers/TPGlobal";
import { SignalRSubtopicEnum } from "@/models/Global/TPGlobalEnums";
import { HubConnectionBuilder } from "@microsoft/signalr";

export enum TPRealTime_OriginDestinationType {
  "BROWSER" = 1,
  "MICROSERVICE" = 2,
}

export enum TPRealTimeDestinationsEnum {
  "BROWSER" = "BROWSER",
  "CORE_MICROSERVICE" = "CORE_MICROSERVICE",
}

export type TPRealTimeMessageType = {
  originType: TPRealTime_OriginDestinationType;
  originId: string;
  destinationType: TPRealTime_OriginDestinationType;
  destinationId: string;
  encryptedPayload: string;
  //TODO Validar si se van a usar estas props
  data: any;
  subtopic: string;
};

export abstract class TPRealTimeMessages {
  private static isOpen: boolean = false;
  private static connection: any = null;

  //todo implement a lock heuristic
  //https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio

  public static async start(
    sessionId: string,
    userId: string,
    tenantid: string,
    languageid: string,
    url: string,
    onDisconnect: Function,
    onNewMessage: Function,
    encryptedAESKey: string,
  ): Promise<boolean> {
    TPRealTimeMessages.connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          return TPGlobal.jwt;
        },
      })
      .build();

    TPRealTimeMessages.isOpen = false;
    try {
      await TPRealTimeMessages.connection.start();
      TPRealTimeMessages.isOpen = true;
      //handler for disconnection
      TPRealTimeMessages.connection.onclose(async () => {
        TPRealTimeMessages.isOpen = false;
        onDisconnect();
      });
      TPRealTimeMessages.connection.on(
        "NewMessage",
        (payload: TPRealTimeMessageType) => {
          onNewMessage(payload);
        },
      );
      try {
        //register userId
        TPRealTimeMessages.connection.invoke(
          "RegisterClientTP",
          sessionId,
          userId,
          tenantid,
          languageid,
          encryptedAESKey,
        );
      } catch (e) {
        //todo
        throw "Fail to send register browser";
      }
      return true;
    } catch (error) {
      TPRealTimeMessages.isOpen = false;
      throw "Fail to connect to realtime infrastructure....";
    }
  }

  public static getIsOpen(): boolean {
    return TPRealTimeMessages.isOpen;
  }

  public static async sendMessage(
    payload: TPRealTimeMessageType,
  ): Promise<boolean> {
    if (TPRealTimeMessages.isOpen && TPRealTimeMessages.connection) {
      try {
        await TPRealTimeMessages.connection.invoke("FromUserToServer", payload);
        return true;
      } catch (e) {
        //todo
        throw "Fail to send message";
      }
    } else {
      //todo
      throw "Fail to send message";
    }
  }
}
