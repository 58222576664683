import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { QueueTabs } from "@/models/Groups/GroupsModels";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import useQueueAdminLabels from "../assets/labels";
import QueueAdministrationTab from "./tabs/QueueAdministrationTab";
import QueueAssignUsersTab from "./tabs/QueueAssignUsersTab";
import QueueGeneralInfo from "./tabs/QueueGeneralInfoTab";
import "../assets/Styles.css";

interface QueueAdminPanelProperties {
  tabCallback: Function;
  updateCallback: Function;
}

const QueueAdminNewPanel: FC<QueueAdminPanelProperties> = function ({ tabCallback, updateCallback }) {
  const dispatch = useDispatch();
  const { labels } = useQueueAdminLabels();
  const [currentTab, setCurrentTab] = useState<QueueTabs>(QueueTabs.GeneralInfo);

  return (
    <div className="queues-main-container">
      <TPPageTitle>{labels?.NewQueue}</TPPageTitle>
      <div id="queue-admin-nav-tab" className="secondary-style-tp-tabs nav nav-tabs" role="tablist">
        <button
          id="queue-admin-general-info-nav-tab"
          className={`nav-link ${currentTab == QueueTabs.GeneralInfo && 'active'}`}
          type="button" role="tab"
          onClick={() => setCurrentTab(QueueTabs.GeneralInfo)}
        >
          {labels?.GeneralInfo}
        </button>
        <button
          id="queue-admin-queue-admin-nav-tab"
          className={`nav-link ${currentTab == QueueTabs.Administration && 'active'}`}
          type="button" role="tab"
          onClick={() => setCurrentTab(QueueTabs.Administration)}
          disabled
          style={{color:'#969696'}}
        >
          {labels?.QueueAdmin}
        </button>
        <button
          id="queue-admin-assign-users-nav-tab"
          className={`nav-link ${currentTab == QueueTabs.AssignUsers && 'active'}`}
          type="button" role="tab"
          onClick={() => setCurrentTab(QueueTabs.AssignUsers)}
          disabled
          style={{color:'#969696'}}
        >
          {labels?.AssignUsers}
        </button>
      </div>
      <QueueGeneralInfo panelMode={'new'} groupId={0} tabCallback={tabCallback} active={currentTab == QueueTabs.GeneralInfo} updateCallback={updateCallback} />
      <QueueAdministrationTab groupId={0} tabCallback={tabCallback} active={currentTab == QueueTabs.Administration} updateCallback={updateCallback} />
      <QueueAssignUsersTab groupId={0} tabCallback={tabCallback} active={currentTab == QueueTabs.AssignUsers} updateCallback={updateCallback} />

    </div>
  )
}

export default QueueAdminNewPanel;