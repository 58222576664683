import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import React, {
  ReactElement,
  FC,
  useState,
  useEffect,
  useReducer,
} from "react";
import CalendarInsertUpdate from "./CalendarInsertUpdate";
import HolidaysAdmin from "./HolidaysAdmin";

type CalendarContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: string;
};

enum asideMenuItemTypes {
  "general" = 0,
  "holidays" = 1,
}

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum commandsEnum {
  "create_general" = 0,
  "create_holidays_data_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_holidays" = 3,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const CalendarContainerInsertUpdate: FC<CalendarContainerInsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  tabId,
}): ReactElement => {
  //control state
  const resourceSet: string = "CalendarContainerInsertUpdate";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleHolidays, setTitleHolidays] = useState("");
  const [exitLabel, setExitLabel] = useState("Exit");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);

  const loadResources = async () => {
    //todo recursos del asidemenu

    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle",
      ),
    );
    setTitleHolidays(await TPI18N.GetText(resourceSet, "HolidaysTitle"));
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create holidays content
      let command: commandType = {
        type: commandsEnum.create_holidays_data_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }
    if (theCommand.result === "OK") {
      callBackResult({ result: "OK", recordId: theCommand.recordId });
      return;
    }
    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: "" });
      return;
    }
    if (theCommand.result === "save_general_update") {
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "CANCEL", recordId: realRecordId });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_holidays,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "CANCEL", recordId: realRecordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode == "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleHolidays,
        itemType: asideMenuItemTypes.holidays,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = true;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleHolidays,
      itemType: asideMenuItemTypes.holidays,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType,
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_holidays_data_and_change_mode:
        let newRecordId: string;
        newRecordId = command.payload.recordId;
        setRealRecordId(newRecordId);
        return realCreateDetailsContentCommand(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_holidays:
        return setActiveContent(prevState, asideMenuItemTypes.holidays);
    }
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  return (
    <>
      <div className="row">
        <div className="col-10">
          <ul>
            {asideMenuState.map(function (item, index) {
              let jsxFragment: any;
              let visibilityStyle: any;
              if (item.isActive) {
                visibilityStyle = { display: "block" };
              } else {
                visibilityStyle = { display: "none" };
              }
              switch (item.itemType) {
                case asideMenuItemTypes.general:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <CalendarInsertUpdate
                          callBackResult={handleCallBackCommands}
                          mode={mode}
                          recordId={realRecordId}
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.holidays:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <HolidaysAdmin
                          calendarId={realRecordId}
                        ></HolidaysAdmin>
                      </div>
                    </li>
                  );
                  break;
                default:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <span>error this should not be here</span>
                      </div>
                    </li>
                  );
                  break;
              }
              return jsxFragment;
            })}
          </ul>
        </div>

        <RightVerticalMenuContainer className="col-2 mt-4">
          <RightVerticalMenuWrapper>
            <RightVerticalMenuUl>
              {asideMenuState.map(function (item, index) {
                //render aside menu
                let jsxFragment: any;
                let visibilityStyle: any;
                if (item.isVisible) {
                  visibilityStyle = {
                    display: "block",
                    borderBottom: "1px solid #e8e8e8",
                  };
                } else {
                  visibilityStyle = {
                    display: "none",
                    borderBottom: "1px solid #e8e8e8",
                  };
                }
                switch (item.itemType) {
                  case asideMenuItemTypes.general:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.holidays:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  default:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span>error this should not be here</span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                }
                return jsxFragment;
              })}
              <RightVerticalMenuLi
                key={"asidemenuitem" + asideMenuState.length.toString()}
                style={{ marginTop: "30px" }}
              ></RightVerticalMenuLi>
              <RightVerticalMenuLi
                key={"asidemenuitem" + (asideMenuState.length + 1).toString()}
              >
                <span onClick={() => handleOnExitClick()}>{exitLabel}</span>
              </RightVerticalMenuLi>
            </RightVerticalMenuUl>
          </RightVerticalMenuWrapper>
        </RightVerticalMenuContainer>
      </div>
    </>
  );
};

export default CalendarContainerInsertUpdate;
