import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  SequenceGeneratorSequencesNameEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import {
  QuickClassifierInputDTO,
  QuickClassifierInputDTOValidator,
} from "@/models/QuickClassifier/QuickClassifierInputDTO";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { RelationsService } from "@/services/RelationsService";
import { SequenceService } from "@/services/SequenceService";
import { TreeService } from "@/services/TreeService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { FC, ReactElement, useEffect, useReducer, useState } from "react";

type quickClassifiersInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

enum commandsEnum {
  "set_InitialLanguageList" = 0,
  "change_Id" = 1,
  "change_changeCurrentLanguage" = 2,
  "change_OrganizationRelation" = 3,
  "change_CurrentBranch" = 4,
  "change_IsActive" = 5,
  "change_Errors" = 6,
  "set_ScreenForUpdate" = 7,
  "calculate_workflow" = 8,
  "change_Type" = 9,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  type: string;
  currentBaseLevelId: string;
  currentTree1: string;
  currentTree2: string;
  currentTree3: string;
  currentTree4: string;
  currentTree5: string;

  mustSelectLastLevelBranch1: boolean;
  mustSelectLastLevelBranch2: boolean;
  mustSelectLastLevelBranch3: boolean;
  mustSelectLastLevelBranch4: boolean;
  mustSelectLastLevelBranch5: boolean;

  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;
  currentBranch1: string;
  currentBranch2: string;
  currentBranch3: string;
  currentBranch4: string;
  currentBranch5: string;
  currentDescriptionBranch1: string;
  currentDescriptionBranch2: string;
  currentDescriptionBranch3: string;
  currentDescriptionBranch4: string;
  currentDescriptionBranch5: string;
  workflowTypeNoteJsx: any;
  additionalDataNoteJsx: any;
  isActive: boolean;
  alreadyLoadedRecordForUpdate: boolean;

  //validators
  idErrorMessage: string;
  typeErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  baselevelErrorMessage: string;
  classificator1ErrorMessage: string;
  classificator2ErrorMessage: string;
  classificator3ErrorMessage: string;
  classificator4ErrorMessage: string;
  classificator5ErrorMessage: string;
  [key: string]: any;
};

const QuickClassifiersInsertUpdate: FC<quickClassifiersInsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "QuickClassifiersInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "QuickClassifiersInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [typeLabel, setTypeLabel] = useState("");
  const [classifiersSectionLabel, setClassifiersSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
    useState("");
  const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [
    errorMustSelectAtLeastOneClassificatorLabel,
    setErrorMustSelectAtLeastOneClassificatorLabel,
  ] = useState("");

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  //TPBranchSelect Resources
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
    useState<Array<TPKeyValue>>([]);

  const [
    defaultBranchHierarchyDescription1,
    setDefaultBranchHierarchyDescription1,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription2,
    setDefaultBranchHierarchyDescription2,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription3,
    setDefaultBranchHierarchyDescription3,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription4,
    setDefaultBranchHierarchyDescription4,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription5,
    setDefaultBranchHierarchyDescription5,
  ] = useState("");

  // Type filter
  let initialStateFilter: Array<TPKeyValue> = [];
  const [typeKeyValue, setTypeKeyValue] = useState(initialStateFilter);

  const [referenceId, setReferenceId] = useState("");

  //#region Changes TPBranchSelection2

  const initialselectedBranchClassificator1: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedBranchClassificator1, setSelectedBranchClassificator1] =
    useState<TPBranchSelectionValue>(initialselectedBranchClassificator1);

  //#endregion Changes TPBranchSelection2

  //#endregion

  const loadResourcesAndLoadQuickClassifierInfo = async () => {
    let bolR: boolean;

    // Quick classifier type
    await getTypes();

    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(resourceSet, "DescriptionLabel")
    );
    setTypeLabel(await TPI18N.GetText(resourceSet, "TypeLabel"));
    setClassifiersSectionLabel(
      await TPI18N.GetText(resourceSet, "ClassifiersSectionLabel")
    );
    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );
    setOrganizationsRelationsLabel(
      await TPI18N.GetText(resourceSet, "OrganizationRelationLabel")
    );
    setDummyClassfierLabel(
      await TPI18N.GetText(resourceSet, "ClassifierLabel")
    );

    setErrorMustSelectAtLeastOneClassificatorLabel(
      await TPI18N.GetText(
        resourceSet,
        "MustSelectAtLeastOneClassificatorLabel"
      )
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setNameLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));

    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );

    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel")
    );
    setmodalTitleLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectBranchTitleLabel"
      )
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel")
    );
    setModalSelectedBranchLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectedBranchLabel"
      )
    );
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList")
    );
    //screen state
    setIsLoadingScreen(true);
    await getOrganizationsRelationsList();

    if (mode === "Insert") {
      let command1: commandType = {
        type: commandsEnum.set_InitialLanguageList,
        payload: null,
      };
      dispatchCommand(command1);
      setIsLoadingScreen(false);
    }

    if (mode == "Update") {
      setIsLoadingScreen(true);
      bolR = await getQuickClassifierById(recordId);
      if (!bolR) {
        setIsLoadingScreen(false);
        //TODO
        return;
      }
      setIsLoadingScreen(false);
    }
    setIsLoadingScreen(false);
  };

  // Filter types
  const getTypes = async () => {
    const typesEntries = Object.entries(WebServiceClassifierType);
    let typesKeyValue: TPKeyValue[] = await Promise.all(
      typesEntries.map(async (element: any) => {
        return {
          key: element[1],
          value: await TPI18N.GetText(resourceSet, `${element[0]}_TypeLabel`),
        };
      })
    );
    typesKeyValue.unshift({ key: "", value: "--" });
    setTypeKeyValue(typesKeyValue);
  };

  const handleOnIdChange = (newId: string) => {
    let command1: commandType = {
      type: commandsEnum.change_Id,
      payload: { newId: newId },
    };
    dispatchCommand(command1);
  };

  const handleOnTypeChange = (newType: string) => {
    let command1: commandType = {
      type: commandsEnum.change_Type,
      payload: { newType: newType },
    };
    dispatchCommand(command1);
  };

  const getRelationsList = async (
    newOrganization: string
  ): Promise<Array<TPKeyValue>> => {
    let serviceClient = new RelationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      let responseRequest =
        await serviceClient.getRelationsByOrganizationAndFilter(
          newOrganization,
          TPActiveOptions.ALL.toString(),
          false,
          false,
          expectedCodes
        );

      let newRelationsListState: Array<TPKeyValue> = [];
      let relationsListData: Array<RelationsWithRestrictionsViewModel>;
      relationsListData = responseRequest;
      for (i = 0; i <= relationsListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: relationsListData[i].baseLevelId,
          value: relationsListData[i].localizedName
            ? relationsListData[i].localizedName
            : relationsListData[i].name,
        };
        newRelationsListState.push(keyvalue);
      }
      return newRelationsListState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRelationsList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getRelationsList ex`);
      return [];
    }
  };

  const getOrganizationsRelationsList = async () => {
    let serviceOrganizationRelationClient = new OrganizationsRelationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      let responseRequestOrgRela =
        await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
          TPActiveOptions.ALL.toString(),
          false,
          true,
          expectedCodes
        );

      let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
      for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: responseRequestOrgRela[i].id,
          value: responseRequestOrgRela[i].localizedDescription,
        };
        newOrganizationsRelationsListState.push(keyvalue);
      }

      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newOrganizationsRelationsListState.unshift(intitialKeyValue);
      setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getOrganizationsRelationsList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} getOrganizationsRelationsList ex`
      );
    }
  };

  const getBaseLevelInfo = async (BaseLevelId: string) => {
    let serviceClient = new OrganizationsRelationsService();
    let expectedCodes: Array<number> = [200];
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getById(
        BaseLevelId,
        false,
        true,
        expectedCodes
      );

      baseLevelData = responseRequest;
      return baseLevelData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBaseLevelInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
      return null;
    }
  };

  const getTreeInfo = async (treeId: string) => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let treeData: TreeViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getTreeById(
        treeId,
        false,
        true,
        expectedCodes
      );
      treeData = responseRequest;
      return treeData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTreeInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getTreeInfo ex`);
      return null;
    }
  };

  const getBranchInfo = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        true,
        expectedCodes
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
    multilanguageTableName: string,
    multilanguageFieldName: string
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let command1: commandType = {
      type: commandsEnum.change_changeCurrentLanguage,
      payload: {
        index: index,
        newValue: newName,
      },
    };
    dispatchCommand(command1);
  };

  const handleOrganizationRelationChange = async (e: any) => {
    let newBaseLevelId: string = e.target.value;
    let newTree1: string;
    let newTree2: string;
    let newTree3: string;
    let newTree4: string;
    let newTree5: string;

    let mustSelectLastBranch1: boolean;
    let mustSelectLastBranch2: boolean;
    let mustSelectLastBranch3: boolean;
    let mustSelectLastBranch4: boolean;
    let mustSelectLastBranch5: boolean;

    let newLabelTree1: string | null;
    let newLabelTree2: string | null;
    let newLabelTree3: string | null;
    let newLabelTree4: string | null;
    let newLabelTree5: string | null;
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    let treeData: TreeViewModel | null = null;
    let localizedField: Array<MultilanguageFieldValueViewModel> = [];
    let i;
    let found: boolean;
    newTree1 = "";
    newTree2 = "";
    newTree3 = "";
    newTree4 = "";
    newTree5 = "";

    mustSelectLastBranch1 = false;
    mustSelectLastBranch2 = false;
    mustSelectLastBranch3 = false;
    mustSelectLastBranch4 = false;
    mustSelectLastBranch5 = false;

    newLabelTree1 = "";
    newLabelTree2 = "";
    newLabelTree3 = "";
    newLabelTree4 = "";
    newLabelTree5 = "";

    if (
      mode === "Update" &&
      insertUpdateState.alreadyLoadedRecordForUpdate === false
    ) {
      return;
    }
    setIsLoadingScreen(true);

    if (newBaseLevelId !== "") {
      //get base level info
      baseLevelData = await getBaseLevelInfo(newBaseLevelId);
      if (baseLevelData === null) {
        //todo logs
        return;
      }
      newTree1 = baseLevelData.tree1Id;
      newTree2 = baseLevelData.tree2Id;
      newTree3 = baseLevelData.tree3Id;
      newTree4 = baseLevelData.tree4Id;
      newTree5 = baseLevelData.tree5Id;
      if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 1
        treeData = await getTreeInfo(newTree1);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          mustSelectLastBranch1 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C1",
            "BAseLEvel",
            "Tree1_Classify1Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree1 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 2
        treeData = await getTreeInfo(newTree2);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          mustSelectLastBranch2 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C2",
            "BAseLEvel",
            "Tree2_Classify2Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree2 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 3
        treeData = await getTreeInfo(newTree3);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          mustSelectLastBranch3 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C3",
            "BAseLEvel",
            "Tree3_Classify3Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree3 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 4
        treeData = await getTreeInfo(newTree4);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          mustSelectLastBranch4 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C4",
            "BAseLEvel",
            "Tree4_Classify4Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree4 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 5
        treeData = await getTreeInfo(newTree5);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          mustSelectLastBranch5 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C5",
            "BAseLEvel",
            "Tree5_Classify5Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree5 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
    }
    setIsLoadingScreen(false);
    setDefaultBranchHierarchyDescription1("");
    setDefaultBranchHierarchyDescription2("");
    setDefaultBranchHierarchyDescription3("");
    setDefaultBranchHierarchyDescription4("");
    setDefaultBranchHierarchyDescription5("");
    let command1: commandType = {
      type: commandsEnum.change_OrganizationRelation,
      payload: {
        newBaseLevelId: newBaseLevelId,
        newTree1: newTree1,
        newTree2: newTree2,
        newTree3: newTree3,
        newTree4: newTree4,
        newTree5: newTree5,

        newMustSelectLastLevelBranch1: mustSelectLastBranch1,
        newMustSelectLastLevelBranch2: mustSelectLastBranch2,
        newMustSelectLastLevelBranch3: mustSelectLastBranch3,
        newMustSelectLastLevelBranch4: mustSelectLastBranch4,
        newMustSelectLastLevelBranch5: mustSelectLastBranch5,

        newLabelTree1: newLabelTree1,
        newLabelTree2: newLabelTree2,
        newLabelTree3: newLabelTree3,
        newLabelTree4: newLabelTree4,
        newLabelTree5: newLabelTree5,
      },
    };
    dispatchCommand(command1);
  };

  const getQuickClassifierById = async (
    pRecordId: string
  ): Promise<boolean> => {
    let recordLanguagesList: Array<TPKeyValue> = [];

    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    let treeData: TreeViewModel | null = null;
    let branchData: BranchViewModel | null = null;
    let localizedField: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    let n: number;
    let found: boolean;
    let newLabelTree1: string = "";
    let newLabelTree2: string = "";
    let newLabelTree3: string = "";
    let newLabelTree4: string = "";
    let newLabelTree5: string = "";

    let mustSelectLastBranch1: boolean;
    let mustSelectLastBranch2: boolean;
    let mustSelectLastBranch3: boolean;
    let mustSelectLastBranch4: boolean;
    let mustSelectLastBranch5: boolean;

    let serviceClient = new QuickClassifierService();
    let expectedCodes: Array<number> = [200];

    mustSelectLastBranch1 = false;
    mustSelectLastBranch2 = false;
    mustSelectLastBranch3 = false;
    mustSelectLastBranch4 = false;
    mustSelectLastBranch5 = false;
    try {
      let responseRequest = await serviceClient.getQuickClassifierById(
        pRecordId,
        false,
        true,
        expectedCodes
      );
      if (!responseRequest) {
        //todo logs
        return false;
      }
      //get base level info
      baseLevelData = await getBaseLevelInfo(responseRequest.baseLevelId);
      if (baseLevelData === null) {
        //todo logs
        return false;
      }

      if (baseLevelData.tree1Id !== TPGlobal.Tree.NA_TreeCode) {
        if (responseRequest.classifier1Id) {
          //get branch by id in order to get hierarchy description of branch
          branchData = await getBranchInfo(responseRequest.classifier1Id);
          if (branchData == null) {
            //todo logs
            return false;
          }
          setDefaultBranchHierarchyDescription1(
            branchData.hierarchyDescription
          );
        }

        //get tree Info 1
        treeData = await getTreeInfo(baseLevelData.tree1Id);
        if (treeData === null) {
          //todo logs
          return false;
        }
        mustSelectLastBranch1 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          responseRequest.baseLevelId + "_C1",
          "BAseLEvel",
          "Tree1_Classify1Caption"
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return false;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree1 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return false;
          }
        }
      }

      if (baseLevelData.tree2Id !== TPGlobal.Tree.NA_TreeCode) {
        if (responseRequest.classifier2Id) {
          //get branch by id in order to get hierarchy description of branch
          branchData = await getBranchInfo(responseRequest.classifier2Id);
          if (branchData == null) {
            //todo logs
            return false;
          }
          setDefaultBranchHierarchyDescription2(
            branchData.hierarchyDescription
          );
        }

        //get tree Info 2
        treeData = await getTreeInfo(baseLevelData.tree2Id);
        if (treeData === null) {
          //todo logs
          return false;
        }
        mustSelectLastBranch2 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          responseRequest.baseLevelId + "_C2",
          "BAseLEvel",
          "Tree2_Classify2Caption"
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return false;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree2 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return false;
          }
        }
      }

      if (baseLevelData.tree3Id !== TPGlobal.Tree.NA_TreeCode) {
        if (responseRequest.classifier3Id) {
          //get branch by id in order to get hierarchy description of branch
          branchData = await getBranchInfo(responseRequest.classifier3Id);
          if (branchData == null) {
            //todo logs
            return false;
          }
          setDefaultBranchHierarchyDescription3(
            branchData.hierarchyDescription
          );
        }

        //get tree Info 3
        treeData = await getTreeInfo(baseLevelData.tree3Id);
        if (treeData === null) {
          //todo logs
          return false;
        }
        mustSelectLastBranch3 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          responseRequest.baseLevelId + "_C3",
          "BAseLEvel",
          "Tree3_Classify3Caption"
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return false;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree3 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return false;
          }
        }
      }

      if (baseLevelData.tree4Id !== TPGlobal.Tree.NA_TreeCode) {
        if (responseRequest.classifier4Id) {
          //get branch by id in order to get hierarchy description of branch
          branchData = await getBranchInfo(responseRequest.classifier4Id);
          if (branchData == null) {
            //todo logs
            return false;
          }
          setDefaultBranchHierarchyDescription4(
            branchData.hierarchyDescription
          );
        }

        //get tree Info 4
        treeData = await getTreeInfo(baseLevelData.tree4Id);
        if (treeData === null) {
          //todo logs
          return false;
        }
        mustSelectLastBranch4 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          responseRequest.baseLevelId + "_C4",
          "BAseLEvel",
          "Tree4_Classify4Caption"
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return false;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree4 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return false;
          }
        }
      }

      if (baseLevelData.tree5Id !== TPGlobal.Tree.NA_TreeCode) {
        if (responseRequest.classifier5Id) {
          //get branch by id in order to get hierarchy description of branch
          branchData = await getBranchInfo(responseRequest.classifier5Id);
          if (branchData == null) {
            //todo logs
            return false;
          }
          setDefaultBranchHierarchyDescription5(
            branchData.hierarchyDescription
          );
        }

        //get tree Info 5
        treeData = await getTreeInfo(baseLevelData.tree5Id);
        if (treeData === null) {
          //todo logs
          return false;
        }
        mustSelectLastBranch5 = treeData.mustSelectLastBranch;
        //get label from multilanguage field value
        localizedField = await getRecordLanguageList(
          responseRequest.baseLevelId + "_C5",
          "BAseLEvel",
          "Tree5_Classify5Caption"
        );
        if (localizedField === null || localizedField.length === 0) {
          //todo logs
          return false;
        }
        found = false;
        for (i = 0; i <= localizedField.length - 1; i++) {
          if (localizedField[i].languageId === TPGlobal.language) {
            newLabelTree5 = localizedField[i].recordDescription;
            found = true;
            break;
          }
        }
        if (!found) {
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (
              localizedField[i].languageId ===
              TPGlobal.TPClientAvailableLanguages[0].id
            ) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            //todo logs
            return false;
          }
        }
      }

      n = TPGlobal.TPClientAvailableLanguages.length;
      for (i = 0; i <= n - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        recordLanguagesList.push(keyValueElement);
      }

      localizedField = await getRecordLanguageList(
        pRecordId,
        "WebServiceCLassifier",
        "Description_WSCL"
      );
      if (localizedField && localizedField.length >= 1) {
        n = TPGlobal.TPClientAvailableLanguages.length;
        for (i = 0; i <= n - 1; i++) {
          for (j = 0; j <= localizedField.length - 1; j++) {
            if (
              recordLanguagesList[i].key.toLowerCase() ==
              localizedField[j].languageId.toLowerCase()
            ) {
              recordLanguagesList[i].value =
                localizedField[j].recordDescription;
            }
          }
        }
      }

      let command1: commandType = {
        type: commandsEnum.set_ScreenForUpdate,
        payload: {
          id: responseRequest.id,
          type: responseRequest.type,
          recordLanguageList: recordLanguagesList,
          currentBaseLevelId: responseRequest.baseLevelId,
          currentTree1: baseLevelData.tree1Id,
          currentTree2: baseLevelData.tree2Id,
          currentTree3: baseLevelData.tree3Id,
          currentTree4: baseLevelData.tree4Id,
          currentTree5: baseLevelData.tree5Id,

          mustSelectLastLevelBranch1: mustSelectLastBranch1,
          mustSelectLastLevelBranch2: mustSelectLastBranch2,
          mustSelectLastLevelBranch3: mustSelectLastBranch3,
          mustSelectLastLevelBranch4: mustSelectLastBranch4,
          mustSelectLastLevelBranch5: mustSelectLastBranch5,

          currentLabelTree1: newLabelTree1,
          currentLabelTree2: newLabelTree2,
          currentLabelTree3: newLabelTree3,
          currentLabelTree4: newLabelTree4,
          currentLabelTree5: newLabelTree5,

          currentBranch1: responseRequest.classifier1Id
            ? responseRequest.classifier1Id
            : "",
          currentBranch2: responseRequest.classifier2Id
            ? responseRequest.classifier2Id
            : "",
          currentBranch3: responseRequest.classifier3Id
            ? responseRequest.classifier3Id
            : "",
          currentBranch4: responseRequest.classifier4Id
            ? responseRequest.classifier4Id
            : "",
          currentBranch5: responseRequest.classifier5Id
            ? responseRequest.classifier5Id
            : "",
          currentDescriptionBranch1: responseRequest.localizedClassifier1
            ? responseRequest.localizedClassifier1
            : "",
          currentDescriptionBranch2: responseRequest.localizedClassifier2
            ? responseRequest.localizedClassifier2
            : "",
          currentDescriptionBranch3: responseRequest.localizedClassifier3
            ? responseRequest.localizedClassifier3
            : "",
          currentDescriptionBranch4: responseRequest.localizedClassifier4
            ? responseRequest.localizedClassifier4
            : "",
          currentDescriptionBranch5: responseRequest.localizedClassifier5
            ? responseRequest.localizedClassifier5
            : "",
          isActive: responseRequest.isActive,
          workflowTypeNoteJsx: await renderWorkflowTypeNote(
            responseRequest.baseLevelId,
            responseRequest.classifier1Id ? responseRequest.classifier1Id : "",
            responseRequest.classifier2Id ? responseRequest.classifier2Id : "",
            responseRequest.classifier3Id ? responseRequest.classifier3Id : "",
            responseRequest.classifier4Id ? responseRequest.classifier4Id : "",
            responseRequest.classifier5Id ? responseRequest.classifier5Id : ""
          ),
          //todo
          //additionalDataNoteJsx: await renderAdditionalDataTableNote(),
          additionalDataNoteJsx: null,
        },
      };
      dispatchCommand(command1);
      return true;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getQuickClassifierById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getQuickClassifierById ex`);
      return false;
    }
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;
    let hasError: boolean = false;
    let recordInputDTO: QuickClassifierInputDTO = {
      // id: insertUpdateState.id,
      id: mode === "Insert" ? referenceId : recordId,
      emailTemplateHeaderId: "",
      description: insertUpdateState.recordLanguageList[0].value,
      baseLevelId: insertUpdateState.currentBaseLevelId,
      caseComments: "",
      classifier1Id:
        insertUpdateState.currentTree1 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch1,
      classifier2Id:
        insertUpdateState.currentTree2 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch2,
      classifier3Id:
        insertUpdateState.currentTree3 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch3,
      classifier4Id:
        insertUpdateState.currentTree4 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch4,
      classifier5Id:
        insertUpdateState.currentTree5 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch5,
      isActive: insertUpdateState.isActive,
      type: insertUpdateState.type,
      otherLocalizedValues: [],
    };

    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }
    let inputDTOValidator = new QuickClassifierInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    let command1: commandType = {
      type: commandsEnum.change_Errors,
      payload: {},
    };
    command1.payload.idErrorMessage = "";
    command1.payload.typeErrorMessage = "";
    command1.payload.descriptionErrorMessages = [];
    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      command1.payload.descriptionErrorMessages.push("");
    }
    command1.payload.baselevelErrorMessage = "";
    command1.payload.classificator1ErrorMessage = "";
    command1.payload.classificator2ErrorMessage = "";
    command1.payload.classificator3ErrorMessage = "";
    command1.payload.classificator4ErrorMessage = "";
    command1.payload.classificator5ErrorMessage = "";
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      hasError = true;
      //id
      if (resultValidator.id) {
        command1.payload.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id
        );
      }
      //description
      if (resultValidator.description) {
        command1.payload.descriptionErrorMessages[0] = await TPI18N.GetResource(
          resultValidator.description
        );
      }
      //Type
      if (resultValidator.type) {
        command1.payload.typeErrorMessage = await TPI18N.GetResource(
          resultValidator.type
        );
      }
      if (resultValidator.otherLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            command1.payload.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i])
              );
          }
        }
      }
      //base level
      if (resultValidator.baseLevelId) {
        command1.payload.baselevelErrorMessage = await TPI18N.GetResource(
          resultValidator.baseLevelId
        );
      }
      //classifier1Id
      if (resultValidator.classifier1Id) {
        command1.payload.classificator1ErrorMessage = await TPI18N.GetResource(
          resultValidator.classifier1Id
        );
      }
      //classifier2Id
      if (resultValidator.classifier2Id) {
        command1.payload.classificator2ErrorMessage = await TPI18N.GetResource(
          resultValidator.classifier2Id
        );
      }
      //classifier3Id
      if (resultValidator.classifier3Id) {
        command1.payload.classificator3ErrorMessage = await TPI18N.GetResource(
          resultValidator.classifier3Id
        );
      }
      //classifier4Id
      if (resultValidator.classifier4Id) {
        command1.payload.classificator4ErrorMessage = await TPI18N.GetResource(
          resultValidator.classifier4Id
        );
      }
      //classifier5Id
      if (resultValidator.classifier5Id) {
        command1.payload.classificator5ErrorMessage = await TPI18N.GetResource(
          resultValidator.classifier5Id
        );
      }
    }
    if (
      insertUpdateState.currentBranch1 === "" &&
      insertUpdateState.currentBranch2 === "" &&
      insertUpdateState.currentBranch3 === "" &&
      insertUpdateState.currentBranch4 === "" &&
      insertUpdateState.currentBranch5 === ""
    ) {
      command1.payload.baselevelErrorMessage =
        errorMustSelectAtLeastOneClassificatorLabel;
      hasError = true;
    }
    dispatchCommand(command1);
    if (hasError) {
      return;
    }
    //revert not available tree branches
    if (insertUpdateState.currentTree1 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.classifier1Id = "";
    }
    if (insertUpdateState.currentTree2 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.classifier2Id = "";
    }
    if (insertUpdateState.currentTree3 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.classifier3Id = "";
    }
    if (insertUpdateState.currentTree4 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.classifier4Id = "";
    }
    if (insertUpdateState.currentTree5 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.classifier5Id = "";
    }
    if (mode == "Insert") {
      await insertQuickClassifier(recordInputDTO);
    } else {
      await updateQuickClassifier(recordInputDTO);
    }
  };

  const insertQuickClassifier = async (inputDTO: QuickClassifierInputDTO) => {
    let serviceClient = new QuickClassifierService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insertQuickClassifier(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertQuickClassifier ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertQuickClassifier ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateQuickClassifier = async (inputDTO: QuickClassifierInputDTO) => {
    let serviceClient = new QuickClassifierService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateQuickClassifier(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateQuickClassifier ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateQuickClassifier ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const getWorkflowType = async (
    baseLevelId: string,
    branch1: string,
    branch2: string,
    branch3: string,
    branch4: string,
    branch5: string
  ) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200, 404];
    let workflowTypeData: WorkflowTypeViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getByBaseLevelAndClassifiers(
        baseLevelId,
        branch1,
        branch2,
        branch3,
        branch4,
        branch5,
        false,
        true,
        expectedCodes
      );

      workflowTypeData = responseRequest;
      return workflowTypeData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getWorkflowType ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getWorkflowType ex`);
      return null;
    }
  };

  const renderWorkflowTypeNote = async (
    currentBaseLevelId: string,
    currentBranch1: string,
    currentBranch2: string,
    currentBranch3: string,
    currentBranch4: string,
    currentBranch5: string
  ) => {
    let noteNoWorkflowLabel: string;
    let noteWithWorkflowLabel: string;
    //calculate workflow type
    let workflowType: WorkflowTypeViewModel | null;
    workflowType = await getWorkflowType(
      currentBaseLevelId,
      currentBranch1,
      currentBranch2,
      currentBranch3,
      currentBranch4,
      currentBranch5
    );
    noteNoWorkflowLabel = await TPI18N.GetText(
      resourceSet,
      "NoteNoWorkflowLabel"
    );
    noteWithWorkflowLabel = await TPI18N.GetText(
      resourceSet,
      "NoteWithWorkflowLabel"
    );

    //todo labels and link
    if (!workflowType) {
      //todo
      return (
        <span className="alert alert-danger mt-2   tpblock tpw100per">
          {noteNoWorkflowLabel}
        </span>
      );
    } else {
      //todo link diagram
      return (
        <span className="alert alert-info mt-2   tpblock tpw100per">
          {noteWithWorkflowLabel}&nbsp;&nbsp;
          {workflowType.description}
        </span>
      );
    }
  };

  const renderAdditionalDataTableNote = (
    currentBaseLevelId: string,
    currentBranch1: string,
    currentBranch2: string,
    currentBranch3: string,
    currentBranch4: string,
    currentBranch5: string
  ) => {
    //calculate additional data for current branches
    //todo
    return null;
  };

  const handleIsActiveChange = () => {
    let command1: commandType = {
      type: commandsEnum.change_IsActive,
      payload: null,
    };
    dispatchCommand(command1);
  };

  const handleOnBranchChange = async (
    index: number,
    branchId: string,
    branchDescription: string,
    branchHierachyDescription: string
  ) => {
    let newInsertUpdateState: InsertUpdateStateType = { ...insertUpdateState };

    switch (index) {
      case 1:
        setDefaultBranchHierarchyDescription1(branchHierachyDescription);
        newInsertUpdateState.currentBranch1 = branchId;
        break;
      case 2:
        setDefaultBranchHierarchyDescription2(branchHierachyDescription);
        newInsertUpdateState.currentBranch2 = branchId;
        break;
      case 3:
        setDefaultBranchHierarchyDescription3(branchHierachyDescription);
        newInsertUpdateState.currentBranch3 = branchId;
        break;
      case 4:
        setDefaultBranchHierarchyDescription4(branchHierachyDescription);
        newInsertUpdateState.currentBranch4 = branchId;
        break;
      case 5:
        setDefaultBranchHierarchyDescription5(branchHierachyDescription);
        newInsertUpdateState.currentBranch5 = branchId;
        break;
      default:
        break;
    }

    let command1: commandType = {
      type: commandsEnum.change_CurrentBranch,
      payload: {
        treeNumber: index,
        newBranch: branchId,
        newDescriptionBranch: branchDescription,
        workflowTypeNoteJsx: await renderWorkflowTypeNote(
          newInsertUpdateState.currentBaseLevelId,
          newInsertUpdateState.currentBranch1
            ? newInsertUpdateState.currentBranch1
            : "",
          newInsertUpdateState.currentBranch2
            ? newInsertUpdateState.currentBranch2
            : "",
          newInsertUpdateState.currentBranch3
            ? newInsertUpdateState.currentBranch3
            : "",
          newInsertUpdateState.currentBranch4
            ? newInsertUpdateState.currentBranch4
            : "",
          newInsertUpdateState.currentBranch5
            ? newInsertUpdateState.currentBranch5
            : ""
        ),
      },
    };
    dispatchCommand(command1);
  };

  //Initial State
  const initialStateBLL: InsertUpdateStateType = {
    id: "",
    recordLanguageList: [],
    currentBaseLevelId: "",
    type: "",
    currentTree1: "",
    currentTree2: "",
    currentTree3: "",
    currentTree4: "",
    currentTree5: "",

    mustSelectLastLevelBranch1: false,
    mustSelectLastLevelBranch2: false,
    mustSelectLastLevelBranch3: false,
    mustSelectLastLevelBranch4: false,
    mustSelectLastLevelBranch5: false,

    currentLabelTree1: "",
    currentLabelTree2: "",
    currentLabelTree3: "",
    currentLabelTree4: "",
    currentLabelTree5: "",
    currentBranch1: "",
    currentBranch2: "",
    currentBranch3: "",
    currentBranch4: "",
    currentBranch5: "",
    currentDescriptionBranch1: "",
    currentDescriptionBranch2: "",
    currentDescriptionBranch3: "",
    currentDescriptionBranch4: "",
    currentDescriptionBranch5: "",
    workflowTypeNoteJsx: null,
    additionalDataNoteJsx: null,
    isActive: true,
    //validators
    idErrorMessage: "",
    descriptionErrorMessages: [...initialErrorMessages],
    baselevelErrorMessage: "",
    typeErrorMessage: "",
    classificator1ErrorMessage: "",
    classificator2ErrorMessage: "",
    classificator3ErrorMessage: "",
    classificator4ErrorMessage: "",
    classificator5ErrorMessage: "",
    alreadyLoadedRecordForUpdate: false,
  };

  //reducer definition
  const [insertUpdateState, dispatchCommand] = useReducer(
    doCommand,
    initialStateBLL
  );
  function doCommand(prevState: InsertUpdateStateType, command: commandType) {
    let newInsertUpdateState: InsertUpdateStateType;
    let i: number;
    switch (command.type) {
      case commandsEnum.set_InitialLanguageList:
        newInsertUpdateState = { ...prevState };
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
          let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
          let keyValueElement: TPKeyValue = { key: item.id, value: "" };
          newInsertUpdateState.recordLanguageList.push(keyValueElement);
        }
        return newInsertUpdateState;
        break;
      case commandsEnum.change_Id:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.id = command.payload.newId;
        newInsertUpdateState.idErrorMessage = "";
        return newInsertUpdateState;
        break;
      case commandsEnum.change_Type:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.type = command.payload.newType;
        newInsertUpdateState.typeErrorMessage = "";
        return newInsertUpdateState;
        break;
      case commandsEnum.change_changeCurrentLanguage:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.recordLanguageList[command.payload.index].value =
          command.payload.newValue;
        newInsertUpdateState.descriptionErrorMessages[command.payload.index] =
          "";
        return newInsertUpdateState;
        break;
      case commandsEnum.change_OrganizationRelation:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.currentBaseLevelId =
          command.payload.newBaseLevelId;
        newInsertUpdateState.currentTree1 = command.payload.newTree1;
        newInsertUpdateState.currentTree2 = command.payload.newTree2;
        newInsertUpdateState.currentTree3 = command.payload.newTree3;
        newInsertUpdateState.currentTree4 = command.payload.newTree4;
        newInsertUpdateState.currentTree5 = command.payload.newTree5;

        newInsertUpdateState.mustSelectLastLevelBranch1 =
          command.payload.newMustSelectLastLevelBranch1;
        newInsertUpdateState.mustSelectLastLevelBranch2 =
          command.payload.newMustSelectLastLevelBranch2;
        newInsertUpdateState.mustSelectLastLevelBranch3 =
          command.payload.newMustSelectLastLevelBranch3;
        newInsertUpdateState.mustSelectLastLevelBranch4 =
          command.payload.newMustSelectLastLevelBranch4;
        newInsertUpdateState.mustSelectLastLevelBranch5 =
          command.payload.newMustSelectLastLevelBranch5;

        newInsertUpdateState.currentLabelTree1 = command.payload.newLabelTree1;
        newInsertUpdateState.currentLabelTree2 = command.payload.newLabelTree2;
        newInsertUpdateState.currentLabelTree3 = command.payload.newLabelTree3;
        newInsertUpdateState.currentLabelTree4 = command.payload.newLabelTree4;
        newInsertUpdateState.currentLabelTree5 = command.payload.newLabelTree5;
        newInsertUpdateState.currentBranch1 = "";
        newInsertUpdateState.currentBranch2 = "";
        newInsertUpdateState.currentBranch3 = "";
        newInsertUpdateState.currentBranch4 = "";
        newInsertUpdateState.currentBranch5 = "";

        newInsertUpdateState.currentDescriptionBranch1 = "";
        newInsertUpdateState.currentDescriptionBranch2 = "";
        newInsertUpdateState.currentDescriptionBranch3 = "";
        newInsertUpdateState.currentDescriptionBranch4 = "";
        newInsertUpdateState.currentDescriptionBranch5 = "";

        newInsertUpdateState.workflowTypeNoteJsx = null;
        newInsertUpdateState.additionalDataNoteJsx = null;

        newInsertUpdateState.classificator1ErrorMessage = "";
        newInsertUpdateState.classificator2ErrorMessage = "";
        newInsertUpdateState.classificator3ErrorMessage = "";
        newInsertUpdateState.classificator4ErrorMessage = "";
        newInsertUpdateState.classificator5ErrorMessage = "";

        newInsertUpdateState.baselevelErrorMessage = "";
        newInsertUpdateState.classificator1ErrorMessage = "";
        newInsertUpdateState.classificator2ErrorMessage = "";
        newInsertUpdateState.classificator3ErrorMessage = "";
        newInsertUpdateState.classificator4ErrorMessage = "";
        newInsertUpdateState.classificator5ErrorMessage = "";

        return newInsertUpdateState;
      case commandsEnum.change_CurrentBranch:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.workflowTypeNoteJsx =
          command.payload.workflowTypeNoteJsx;
        newInsertUpdateState.additionalDataNoteJsx =
          command.payload.additionalDataNoteJsx;
        newInsertUpdateState.baselevelErrorMessage = "";
        switch (command.payload.treeNumber) {
          case 1:
            newInsertUpdateState.currentBranch1 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch1 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator1ErrorMessage = "";
            break;
          case 2:
            newInsertUpdateState.currentBranch2 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch2 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator2ErrorMessage = "";
            break;
          case 3:
            newInsertUpdateState.currentBranch3 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch3 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator3ErrorMessage = "";
            break;
          case 4:
            newInsertUpdateState.currentBranch4 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch4 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator4ErrorMessage = "";
            break;
          case 5:
            newInsertUpdateState.currentBranch5 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch5 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator5ErrorMessage = "";
            break;
        }

        return newInsertUpdateState;
      case commandsEnum.change_IsActive:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        return newInsertUpdateState;
      case commandsEnum.change_Errors:
        newInsertUpdateState = { ...prevState };
        const keys = Object.keys(command.payload);
        for (i = 0; i <= keys.length - 1; i++) {
          newInsertUpdateState[keys[i]] = command.payload[keys[i]];
        }
        return newInsertUpdateState;
      case commandsEnum.set_ScreenForUpdate:
        newInsertUpdateState = { ...prevState };

        newInsertUpdateState.id = command.payload.id;
        newInsertUpdateState.type = command.payload.type;
        newInsertUpdateState.recordLanguageList =
          command.payload.recordLanguageList;
        newInsertUpdateState.currentBaseLevelId =
          command.payload.currentBaseLevelId;
        newInsertUpdateState.currentTree1 = command.payload.currentTree1;
        newInsertUpdateState.currentTree2 = command.payload.currentTree2;
        newInsertUpdateState.currentTree3 = command.payload.currentTree3;
        newInsertUpdateState.currentTree4 = command.payload.currentTree4;
        newInsertUpdateState.currentTree5 = command.payload.currentTree5;

        newInsertUpdateState.mustSelectLastLevelBranch1 =
          command.payload.mustSelectLastLevelBranch1;
        newInsertUpdateState.mustSelectLastLevelBranch2 =
          command.payload.mustSelectLastLevelBranch2;
        newInsertUpdateState.mustSelectLastLevelBranch3 =
          command.payload.mustSelectLastLevelBranch3;
        newInsertUpdateState.mustSelectLastLevelBranch4 =
          command.payload.mustSelectLastLevelBranch4;
        newInsertUpdateState.mustSelectLastLevelBranch5 =
          command.payload.mustSelectLastLevelBranch5;

        newInsertUpdateState.currentLabelTree1 =
          command.payload.currentLabelTree1;
        newInsertUpdateState.currentLabelTree2 =
          command.payload.currentLabelTree2;
        newInsertUpdateState.currentLabelTree3 =
          command.payload.currentLabelTree3;
        newInsertUpdateState.currentLabelTree4 =
          command.payload.currentLabelTree4;
        newInsertUpdateState.currentLabelTree5 =
          command.payload.currentLabelTree5;
        newInsertUpdateState.currentBranch1 = command.payload.currentBranch1;
        newInsertUpdateState.currentBranch2 = command.payload.currentBranch2;
        newInsertUpdateState.currentBranch3 = command.payload.currentBranch3;
        newInsertUpdateState.currentBranch4 = command.payload.currentBranch4;
        newInsertUpdateState.currentBranch5 = command.payload.currentBranch5;
        newInsertUpdateState.currentDescriptionBranch1 =
          command.payload.currentDescriptionBranch1;
        newInsertUpdateState.currentDescriptionBranch2 =
          command.payload.currentDescriptionBranch2;
        newInsertUpdateState.currentDescriptionBranch3 =
          command.payload.currentDescriptionBranch3;
        newInsertUpdateState.currentDescriptionBranch4 =
          command.payload.currentDescriptionBranch4;
        newInsertUpdateState.currentDescriptionBranch5 =
          command.payload.currentDescriptionBranch5;
        newInsertUpdateState.isActive = command.payload.isActive;

        newInsertUpdateState.workflowTypeNoteJsx =
          command.payload.workflowTypeNoteJsx;
        newInsertUpdateState.additionalDataNoteJsx =
          command.payload.additionalDataNoteJsx;

        newInsertUpdateState.alreadyLoadedRecordForUpdate = true;
        return newInsertUpdateState;

      default:
        return prevState;
    }
  }

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQWSCL
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load quick classifier in update mode
  useEffect(() => {
    loadResourcesAndLoadQuickClassifierInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  //#region  Render
  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={mode === "Insert" ? referenceId : recordId}
                      onChange={
                        mode === "Insert"
                          ? (e: any) => handleOnIdChange(e.target.value)
                          : () => {
                              TPGlobal.foo();
                            }
                      }
                      maxLength={20}
                      // disabled={mode !== "Insert"}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              {/* Description */}
              <div className="row">
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${nameLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={200}
                              errorMessage={
                                insertUpdateState.descriptionErrorMessages[
                                  index
                                ]
                              }
                            />
                          </div>
                        </div>
                      )
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-2">
                      <div className="pt-4">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* Type */}
                <div className="col-4">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => handleOnTypeChange(e.target.value)}
                      dataSource={typeKeyValue}
                      value={insertUpdateState.type}
                      labelText={typeLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.typeErrorMessage}
                    />
                  </div>
                </div>

                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <TPModalLanguageList
                      id="IdModalLanguageList"
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={false}
                                    labelText={`${nameLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    maxLength={200}
                                    errorMessage={
                                      insertUpdateState
                                        .descriptionErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{classifiersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              {/* Organization Relation */}
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={handleOrganizationRelationChange}
                      dataSource={organizationsRelationsKeyValue}
                      value={insertUpdateState.currentBaseLevelId}
                      labelText={organizationsRelationsLabel}
                      isHorizontal={false}
                      isMandatory={true}
                      errorMessage={insertUpdateState.baselevelErrorMessage}
                    ></TPSelect>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Classificator 1 */}
                {insertUpdateState.currentBaseLevelId === "" ? (
                  <>
                    <div className="col-6 mt-2">
                      <div className="form-group">
                        <span className="tpcircle-text">1</span>&nbsp;{"-"}
                        &nbsp;
                        <TPTextBox
                          id="IdTextBox"
                          onChange={() => {
                            TPGlobal.foo();
                          }}
                          value=""
                          disabled={true}
                          labelText={dummyClassfierLabel}
                          withCircleText
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {insertUpdateState.currentTree1 !== "" &&
                      insertUpdateState.currentTree1 !==
                        TPGlobal.Tree.NA_TreeCode && (
                        <div className="col-6 mt-2">
                          <div className="form-group">
                            <span className="tpcircle-text">1</span>&nbsp;{"-"}
                            &nbsp;
                            <TPBranchSelection2
                              labelText={insertUpdateState.currentLabelTree1}
                              disabledTextBox={false}
                              treeId={insertUpdateState.currentTree1}
                              modalAcceptLabel={modalAcceptLabel}
                              modalCancelLabel={modalCancelLabel}
                              modalSelectedBranchLabel={
                                modalSelectedBranchLabel
                              }
                              modalTitle={modalTitleLabel}
                              mustSelectLastLevelBranch={
                                insertUpdateState.mustSelectLastLevelBranch1
                              }
                              value={{
                                branchId: insertUpdateState.currentBranch1,
                                branchDescription:
                                  insertUpdateState.currentDescriptionBranch1,
                                branchHierarchyDescription:
                                  defaultBranchHierarchyDescription1,
                              }}
                              onChange={(
                                branchId: string,
                                branchDescription: string,
                                branchHierachyDescription: string
                              ) => {
                                handleOnBranchChange(
                                  1,
                                  branchId,
                                  branchDescription,
                                  branchHierachyDescription
                                );
                              }}
                              emptyLabel={emptyLabel}
                              errorMessage={
                                insertUpdateState.classificator1ErrorMessage
                              }
                              onIsLoadingProgress={(value: boolean) => {
                                setIsLoadingScreen(value);
                              }}
                            ></TPBranchSelection2>
                          </div>
                        </div>
                      )}
                  </>
                )}
                {/* Classificator 2 */}
                {insertUpdateState.currentBaseLevelId === "" ? (
                  <>
                    <div className="col-6 mt-2">
                      <div className="form-group">
                        <span className="tpcircle-text">2</span>&nbsp;{"-"}
                        &nbsp;
                        <TPTextBox
                          id="IdTextBox"
                          onChange={() => {
                            TPGlobal.foo();
                          }}
                          value=""
                          disabled={true}
                          labelText={dummyClassfierLabel}
                          withCircleText
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {insertUpdateState.currentTree2 !== "" &&
                      insertUpdateState.currentTree2 !==
                        TPGlobal.Tree.NA_TreeCode && (
                        <div className="col-6 mt-2">
                          <div className="form-group">
                            <span className="tpcircle-text">2</span>&nbsp;{"-"}
                            &nbsp;
                            <TPBranchSelection2
                              labelText={insertUpdateState.currentLabelTree2}
                              disabledTextBox={false}
                              treeId={insertUpdateState.currentTree2}
                              modalAcceptLabel={modalAcceptLabel}
                              modalCancelLabel={modalCancelLabel}
                              modalSelectedBranchLabel={
                                modalSelectedBranchLabel
                              }
                              modalTitle={modalTitleLabel}
                              mustSelectLastLevelBranch={
                                insertUpdateState.mustSelectLastLevelBranch2
                              }
                              value={{
                                branchId: insertUpdateState.currentBranch2,
                                branchDescription:
                                  insertUpdateState.currentDescriptionBranch2,
                                branchHierarchyDescription:
                                  defaultBranchHierarchyDescription2,
                              }}
                              onChange={(
                                branchId: string,
                                branchDescription: string,
                                branchHierachyDescription: string
                              ) => {
                                handleOnBranchChange(
                                  2,
                                  branchId,
                                  branchDescription,
                                  branchHierachyDescription
                                );
                              }}
                              emptyLabel={emptyLabel}
                              errorMessage={
                                insertUpdateState.classificator2ErrorMessage
                              }
                              onIsLoadingProgress={(value: boolean) => {
                                setIsLoadingScreen(value);
                              }}
                            ></TPBranchSelection2>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>

              <div className="row">
                {/* Classificator 3 */}
                {insertUpdateState.currentBaseLevelId === "" ? (
                  <>
                    <div className="col-6 mt-2">
                      <div className="form-group">
                        <span className="tpcircle-text">3</span>&nbsp;{"-"}
                        &nbsp;
                        <TPTextBox
                          id="IdTextBox"
                          onChange={() => {
                            TPGlobal.foo();
                          }}
                          value=""
                          disabled={true}
                          labelText={dummyClassfierLabel}
                          withCircleText
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {insertUpdateState.currentTree3 !== "" &&
                      insertUpdateState.currentTree3 !==
                        TPGlobal.Tree.NA_TreeCode && (
                        <div className="col-6 mt-2">
                          <div className="form-group">
                            <span className="tpcircle-text">3</span>&nbsp;{"-"}
                            &nbsp;
                            <TPBranchSelection2
                              labelText={insertUpdateState.currentLabelTree3}
                              disabledTextBox={false}
                              treeId={insertUpdateState.currentTree3}
                              modalAcceptLabel={modalAcceptLabel}
                              modalCancelLabel={modalCancelLabel}
                              modalSelectedBranchLabel={
                                modalSelectedBranchLabel
                              }
                              modalTitle={modalTitleLabel}
                              mustSelectLastLevelBranch={
                                insertUpdateState.mustSelectLastLevelBranch3
                              }
                              value={{
                                branchId: insertUpdateState.currentBranch3,
                                branchDescription:
                                  insertUpdateState.currentDescriptionBranch3,
                                branchHierarchyDescription:
                                  defaultBranchHierarchyDescription3,
                              }}
                              onChange={(
                                branchId: string,
                                branchDescription: string,
                                branchHierachyDescription: string
                              ) => {
                                handleOnBranchChange(
                                  3,
                                  branchId,
                                  branchDescription,
                                  branchHierachyDescription
                                );
                              }}
                              emptyLabel={emptyLabel}
                              errorMessage={
                                insertUpdateState.classificator3ErrorMessage
                              }
                              onIsLoadingProgress={(value: boolean) => {
                                setIsLoadingScreen(value);
                              }}
                            ></TPBranchSelection2>
                          </div>
                        </div>
                      )}
                  </>
                )}
                {/* Classificator 4 */}
                {insertUpdateState.currentBaseLevelId === "" ? (
                  <>
                    <div className="col-6 mt-2">
                      <div className="form-group">
                        <span className="tpcircle-text">4</span>&nbsp;{"-"}
                        &nbsp;
                        <TPTextBox
                          id="IdTextBox"
                          onChange={() => {
                            TPGlobal.foo();
                          }}
                          value=""
                          disabled={true}
                          labelText={dummyClassfierLabel}
                          withCircleText
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {insertUpdateState.currentTree4 !== "" &&
                      insertUpdateState.currentTree4 !==
                        TPGlobal.Tree.NA_TreeCode && (
                        <div className="col-6 mt-2">
                          <div className="form-group">
                            <span className="tpcircle-text">4</span>&nbsp;{"-"}
                            &nbsp;
                            <TPBranchSelection2
                              labelText={insertUpdateState.currentLabelTree4}
                              disabledTextBox={false}
                              treeId={insertUpdateState.currentTree4}
                              modalAcceptLabel={modalAcceptLabel}
                              modalCancelLabel={modalCancelLabel}
                              modalSelectedBranchLabel={
                                modalSelectedBranchLabel
                              }
                              modalTitle={modalTitleLabel}
                              mustSelectLastLevelBranch={
                                insertUpdateState.mustSelectLastLevelBranch4
                              }
                              value={{
                                branchId: insertUpdateState.currentBranch4,
                                branchDescription:
                                  insertUpdateState.currentDescriptionBranch4,
                                branchHierarchyDescription:
                                  defaultBranchHierarchyDescription4,
                              }}
                              onChange={(
                                branchId: string,
                                branchDescription: string,
                                branchHierachyDescription: string
                              ) => {
                                handleOnBranchChange(
                                  4,
                                  branchId,
                                  branchDescription,
                                  branchHierachyDescription
                                );
                              }}
                              emptyLabel={emptyLabel}
                              errorMessage={
                                insertUpdateState.classificator4ErrorMessage
                              }
                              onIsLoadingProgress={(value: boolean) => {
                                setIsLoadingScreen(value);
                              }}
                            ></TPBranchSelection2>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
              <div className="row">
                {/* Classificator 5 */}
                {insertUpdateState.currentBaseLevelId === "" ? (
                  <>
                    <div className="col-6 mt-2">
                      <div className="form-group">
                        <span className="tpcircle-text">5</span>&nbsp;{"-"}
                        &nbsp;
                        <TPTextBox
                          id="IdTextBox"
                          onChange={() => {
                            TPGlobal.foo();
                          }}
                          value=""
                          disabled={true}
                          labelText={dummyClassfierLabel}
                          withCircleText
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {insertUpdateState.currentTree5 !== "" &&
                      insertUpdateState.currentTree5 !==
                        TPGlobal.Tree.NA_TreeCode && (
                        <div className="col-6 mt-2">
                          <div className="form-group">
                            <span className="tpcircle-text">5</span>&nbsp;{"-"}
                            &nbsp;
                            <TPBranchSelection2
                              labelText={insertUpdateState.currentLabelTree5}
                              disabledTextBox={false}
                              treeId={insertUpdateState.currentTree5}
                              modalAcceptLabel={modalAcceptLabel}
                              modalCancelLabel={modalCancelLabel}
                              modalSelectedBranchLabel={
                                modalSelectedBranchLabel
                              }
                              modalTitle={modalTitleLabel}
                              mustSelectLastLevelBranch={
                                insertUpdateState.mustSelectLastLevelBranch5
                              }
                              value={{
                                branchId: insertUpdateState.currentBranch5,
                                branchDescription:
                                  insertUpdateState.currentDescriptionBranch5,
                                branchHierarchyDescription:
                                  defaultBranchHierarchyDescription5,
                              }}
                              onChange={(
                                branchId: string,
                                branchDescription: string,
                                branchHierachyDescription: string
                              ) => {
                                handleOnBranchChange(
                                  5,
                                  branchId,
                                  branchDescription,
                                  branchHierachyDescription
                                );
                              }}
                              emptyLabel={emptyLabel}
                              errorMessage={
                                insertUpdateState.classificator5ErrorMessage
                              }
                              onIsLoadingProgress={(value: boolean) => {
                                setIsLoadingScreen(value);
                              }}
                            ></TPBranchSelection2>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  {insertUpdateState.workflowTypeNoteJsx}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {insertUpdateState.additionalDataNoteJsx}
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="form-group">
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={isActiveLabel}
                  checked={insertUpdateState.isActive}
                  onChange={(e: any) => handleIsActiveChange()}
                ></TPCheckBox>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );

  //#endregion
};

export default QuickClassifiersInsertUpdate;
