import {} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {
  TPPageSearchContainer,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TPPlaceholderDraggableStyled,
  TPDragAndDropSectionStyled,
  GetDroppableListStyle,
  GetDraggableItemStyle,
} from "./InboundMailboxesAssignRulesStyles";
import { InboundMailRuleDefinitionService } from "@/services/InboundMailRuleDefinitionService";
import { InboundMailRuleDefinitionViewModel } from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";
import {
  InboundMailRuleAssignedRuleInputDTO,
  InboundMailRuleInputDTO,
  InboundMailRuleInputDTOValidator,
} from "@/models/InboundMailRule/InboundMailRuleDefinitionInputDTO";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import { InboundMailRuleService } from "@/services/InboundMailRuleService";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

interface InboundMailboxesAssignRulesInterface {
  recordId: string;
  recordDescription: string;
  id?: string;
}

interface InboundMailRuleDefinitionInterface
  extends InboundMailRuleDefinitionViewModel {
  isShown: boolean;
}

//Placeholder
interface DimensionPlaceholderInterface {
  height: number;
  width: number;
  y: number;
  x: number;
}

const InboundMailboxesAssignRules = React.forwardRef(
  (
    {
      recordId,
      recordDescription,
      id = "",
    }: InboundMailboxesAssignRulesInterface,
    ref,
  ) => {
    const componentFileName: string = "InboundMailboxesAssignRules.tsx";
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "InboundMailboxesAssignRulesComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [assignedRulesSectionLabel, setAssignedRulesSectionLabel] =
      useState("");
    const [availableRulesSectionLabel, setAvailableRulesSectionLabel] =
      useState("");
    const [assignedRulesSectionEmptyLabel, setAssignedRulesSectionEmptyLabel] =
      useState("");
    const [
      availableRulesSectionEmptyLabel,
      setAvailableRulesSectionEmptyLabel,
    ] = useState("");
    const [helpDragAndDropLabel, setHelpDragAndDropLabel] =
      useState<string>("");

    const [idHeaderLabel, setIdHeaderLabel] = useState("");
    const [descriptionHeaderLabel, setDescriptionHeaderLabel] = useState("");
    const [actionTypeHeaderLabel, setActionTypeHeaderLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [searchAssignRulesValue, setSearchAssignRulesValue] = useState("");
    const [searchAvailableRulesValue, setSearchAvailableRulesValue] =
      useState("");

    //Placeholders
    const queryAttrDraggable = "data-rbd-drag-handle-draggable-id";
    const queryAttrDroppable = "data-rbd-droppable-id";

    const [assignedRulesPlaceholderProps, setAssignedRulesPlaceholderProps] =
      useState<DimensionPlaceholderInterface>();
    const [availableRulesPlaceholderProps, setAvailableRulesPlaceholderProps] =
      useState<DimensionPlaceholderInterface>();

    const [assignedRulesList, setAssignedRulesList] = useState<
      Array<InboundMailRuleDefinitionInterface>
    >(Array<any>);
    const [availableRulesList, setAvailableRulesList] = useState<
      Array<InboundMailRuleDefinitionInterface>
    >(Array<any>);
    const [isEmptyAssignedRules, setIsEmptyAssignedRules] =
      useState<boolean>(false);
    const [isEmptyAvailableRules, setIsEmptyAvailableRules] =
      useState<boolean>(false);
    const [isUpdateRules, setIsUpdateRules] = useState<boolean>(false);
    const [isHelpShown, setIsHelpShown] = useState<boolean>(false);
    const [isVisiblePlaceholder, setIsVisiblePlaceholder] =
      useState<boolean>(false);

    const loadResourcesAndLoadInfo = async () => {
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleAssignRulesLabel"));
      setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
      setAssignedRulesSectionLabel(
        await TPI18N.GetText(resourceSet, "AssignedRulesSectionLabel"),
      );
      setAvailableRulesSectionLabel(
        await TPI18N.GetText(resourceSet, "AvailableRulesSectionLabel"),
      );
      setAssignedRulesSectionEmptyLabel(
        await TPI18N.GetText(resourceSet, "AssignedRulesSectionEmptyLabel"),
      );
      setAvailableRulesSectionEmptyLabel(
        await TPI18N.GetText(resourceSet, "AvailableRulesSectionEmptyLabel"),
      );
      setIdHeaderLabel(await TPI18N.GetText(resourceSet, "IdHeaderLabel"));
      setDescriptionHeaderLabel(
        await TPI18N.GetText(resourceSet, "DescriptionHeaderLabel"),
      );
      setActionTypeHeaderLabel(
        await TPI18N.GetText(resourceSet, "ActionTypeHeaderLabel"),
      );
      setSearchLabel(await TPI18N.GetText(resourceSet, "SearchLabel"));
      setHelpDragAndDropLabel(
        await TPI18N.GetText(resourceSet, "HelpDragAndDropLabel"),
      );

      await realoadData();
      setIsLoadingScreen(false);
    };

    const realoadData = async () => {
      await getRuleDefinitionAvailablesByIdMailBox(recordId);
      await getRuleDefinitionAssignedByIdMailBox(recordId);
    };

    const getRuleDefinitionAvailablesByIdMailBox = async (
      inboundMailboxId: string,
    ) => {
      let serviceClient = new InboundMailRuleDefinitionService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getInboundMailRuleDefinitionAvailablesById(
            inboundMailboxId,
            false,
            true,
            expectedCodes,
          );
        let recordInfo: Array<InboundMailRuleDefinitionViewModel>;
        recordInfo = [...responseRequest];

        setAvailableRulesList(
          recordInfo
            .filter((x) => x.isActive)
            .map<InboundMailRuleDefinitionInterface>((item) => {
              return {
                ...item,
                isShown: true,
              };
            }),
        );

        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRuleDefinitionAvailablesByIdMailBox ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getRuleDefinitionAvailablesByIdMailBox ex`,
        );
        setIsLoadingScreen(false);
      }
    };

    const getRuleDefinitionAssignedByIdMailBox = async (
      inboundMailboxId: string,
    ) => {
      let serviceClient = new InboundMailRuleDefinitionService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getInboundMailRuleDefinitionAssignedById(
            inboundMailboxId,
            false,
            true,
            expectedCodes,
          );
        let recordInfo: Array<InboundMailRuleDefinitionViewModel>;
        recordInfo = [...responseRequest];

        setAssignedRulesList(
          recordInfo
            .filter((x) => x.isActive)
            .sort((a, b) => (a.orderRule ?? 0) - (b.orderRule ?? 0))
            .map<InboundMailRuleDefinitionInterface>((item) => {
              return {
                ...item,
                isShown: true,
              };
            }),
        );

        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRuleDefinitionAssignedByIdMailBox ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getRuleDefinitionAssignedByIdMailBox ex`,
        );
        setIsLoadingScreen(false);
      }
    };

    const updateInboundMailRules = async () => {
      let assignedRulesDTO: Array<InboundMailRuleAssignedRuleInputDTO> = [];

      assignedRulesList.map(
        (item: InboundMailRuleDefinitionViewModel, index: number) => {
          assignedRulesDTO.push({
            RuleId: item.id,
            Order: index,
          });
        },
      );

      let recordInputDTO: InboundMailRuleInputDTO = {
        InboundMailboxId: recordId,
        AssignedRulesList: assignedRulesDTO,
      };

      let inputDTOInboundMailRulesValidator =
        new InboundMailRuleInputDTOValidator();
      let hasError: boolean = false;
      let resultValidator =
        inputDTOInboundMailRulesValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        if (resultValidator.InboundMailboxId) {
          showToast(
            await TPI18N.GetResource(resultValidator.InboundMailboxId),
            TPToastTypes.error,
          );
        }

        if (resultValidator.AssignedRulesList) {
          for (let i = 1; i < resultValidator.AssignedRulesList.length; i++) {
            showToast(
              await TPI18N.GetResource(
                String(resultValidator.AssignedRulesList[i]),
              ),
              TPToastTypes.error,
            );
          }
        }

        hasError = true;
      }

      if (hasError) {
        await realoadData();
        return;
      }

      await realUpdateInboundMailRules(recordInputDTO);
    };

    const realUpdateInboundMailRules = async (
      inputDTO: InboundMailRuleInputDTO,
    ) => {
      let serviceClient = new InboundMailRuleService();
      let expectedCodes: Array<number> = [200];
      try {
        await serviceClient.updateInboundMailRulesByMailboxId(
          inputDTO,
          false,
          true,
          expectedCodes,
        );
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} updateInboundMailbox ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} updateInboundMailbox ex`);
        await realoadData();
      }
    };

    //#region "UI DragDrop"
    const getElementDom = (queryAttr: string, elementId: string): any => {
      const domQuery = `[${queryAttr}='${elementId}']`;
      const element: any = document.querySelector(domQuery);
      return element;
    };

    const createDimensionPlaceholder = (
      draggedDOM: any,
      clientY: number,
    ): DimensionPlaceholderInterface => {
      const { clientHeight, clientWidth } = draggedDOM;
      return {
        height: clientHeight,
        width: clientWidth,
        y: clientY,
        x: parseFloat(
          window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
        ),
      };
    };

    const handleDragStart = (event: any) => {
      const draggedDOM: any = getElementDom(
        queryAttrDraggable,
        event.draggableId,
      );

      if (!draggedDOM) {
        return;
      }
      const sourceTable = getElementDom(
        queryAttrDroppable,
        event.source.droppableId,
      );
      const sourceChildrenArray = [...sourceTable.children];
      const movedItem = sourceChildrenArray[event.source.index];

      var beforeArray = sourceChildrenArray.slice(1, event.source.index + 1);

      const afterArray = sourceChildrenArray.slice(event.source.index + 1);

      let sourceUpdatedArray = [...beforeArray, movedItem, ...afterArray];

      let clientY =
        parseFloat(window.getComputedStyle(sourceTable).paddingTop) +
        sourceUpdatedArray
          .slice(0, event.source.index + 1)
          .reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.offsetHeight + marginBottom;
          }, 0);

      const containerHeightDOM: number =
        document
          .querySelector(".assigned-rules-container")
          ?.getBoundingClientRect().y || 0;
      const itemY: number = draggedDOM?.getBoundingClientRect().y || 0;
      clientY =
        clientY + sourceTable.getBoundingClientRect().y - containerHeightDOM;

      clientY = itemY - containerHeightDOM;

      if (event.source.droppableId === "assignedRulesDroppableId") {
        setAssignedRulesPlaceholderProps(
          createDimensionPlaceholder(draggedDOM, clientY),
        );
      } else {
        setAvailableRulesPlaceholderProps(
          createDimensionPlaceholder(draggedDOM, clientY),
        );
      }
    };

    const handleDragUpdate = (event: any) => {
      if (!event.destination) {
        return;
      }

      const draggedDOM: any = getElementDom(
        queryAttrDraggable,
        event.draggableId,
      );

      if (!draggedDOM) {
        return;
      }

      setIsVisiblePlaceholder(false);
      setTimeout(() => {
        const sourceTable = getElementDom(
          queryAttrDroppable,
          event.source.droppableId,
        );
        const sourceChildrenArray = [...sourceTable.children];
        const sourceItem = sourceChildrenArray[event.source.index];

        const destinationTable = getElementDom(
          queryAttrDroppable,
          event.destination.droppableId,
        );
        let destinationChildrenArray = [...destinationTable.children];
        destinationChildrenArray = destinationChildrenArray.filter(
          (x) => x !== sourceItem,
        );

        const containerY: number =
          document
            .querySelector(".assigned-rules-container")
            ?.getBoundingClientRect().y || 0;

        let length: number = 0;

        for (let i = 0; i < destinationChildrenArray.length; i++) {
          let DOMReact = destinationChildrenArray[i].getBoundingClientRect();

          if (i === 0) {
            length = DOMReact.y;
          } else {
            length += DOMReact.height;
          }

          let total: number = 0;
          if (event.destination.droppableId === "assignedRulesDroppableId") {
            total = assignedRulesList.length;
          } else {
            total = availableRulesList.length;
          }

          if (event.destination.droppableId === event.source.droppableId) {
            total = total - 1;
          }

          if (
            Math.round(length) < Math.round(DOMReact.y) ||
            event.destination.index === 0 ||
            i === total
          ) {
            let sourceHeight = sourceItem.getBoundingClientRect().height;

            let clientY = 0;
            if (i === total) {
              clientY = DOMReact.y - containerY;
            } else {
              clientY = DOMReact.y - containerY - sourceHeight;
            }
            if (event.destination.droppableId === "assignedRulesDroppableId") {
              setAssignedRulesPlaceholderProps(
                createDimensionPlaceholder(draggedDOM, clientY),
              );
            } else {
              setAvailableRulesPlaceholderProps(
                createDimensionPlaceholder(draggedDOM, clientY),
              );
            }
            setIsVisiblePlaceholder(true);
            break;
          }
        }
      }, 300);
    };

    const handleOnDragEnd = async (result: any) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }

      // reorder(source.droppableId, source.index, destination.droppableId, destination.index)
      reorder(source, destination);
      setIsUpdateRules(true);
    };

    const reorder = (source: any, destination: any) => {
      let assignedRulesArray: Array<any> = [];
      let availableRulesArray: Array<any> = [];

      assignedRulesArray = [...assignedRulesList];
      availableRulesArray = [...availableRulesList];

      if (
        source.droppableId === destination.droppableId &&
        source.droppableId === "assignedRulesDroppableId"
      ) {
        let [removed] = assignedRulesArray.splice(source.index, 1);
        assignedRulesArray.splice(destination.index, 0, removed);
        setAssignedRulesList(assignedRulesArray);
      } else if (
        source.droppableId === destination.droppableId &&
        source.droppableId === "availableRulesDroppableId"
      ) {
        let [removed] = availableRulesArray.splice(source.index, 1);
        availableRulesArray.splice(destination.index, 0, removed);
        setAvailableRulesList(availableRulesArray);
      } else if (
        source.droppableId === "assignedRulesDroppableId" &&
        destination.droppableId === "availableRulesDroppableId"
      ) {
        let [removed] = assignedRulesArray.splice(source.index, 1);
        availableRulesArray.splice(destination.index, 0, removed);

        setAssignedRulesList(assignedRulesArray);
        setAvailableRulesList(availableRulesArray);
      } else if (
        source.droppableId === "availableRulesDroppableId" &&
        destination.droppableId === "assignedRulesDroppableId"
      ) {
        let [removed] = availableRulesArray.splice(source.index, 1);
        assignedRulesArray.splice(destination.index, 0, removed);

        setAssignedRulesList(assignedRulesArray);
        setAvailableRulesList(availableRulesArray);
      }
    };

    const getDroppableHeader = (
      list: Array<InboundMailRuleDefinitionViewModel>,
    ) => {
      if (list.length > 0) {
        return (
          <div>
            <div className="droppable-container-header row">
              <div className="col-2">{idHeaderLabel}</div>
              <div className="col-lg-8">{descriptionHeaderLabel}</div>
              <div className="col-lg-2">{actionTypeHeaderLabel}</div>
            </div>
          </div>
        );
      } else return null;
    };

    //#endregion "UI DragDrop"

    const handleAssignRulesSearchChange = (newValue: string) => {
      setSearchAssignRulesValue(newValue);
      newValue = newValue.toLowerCase();

      setAssignedRulesList(
        assignedRulesList.map((item: InboundMailRuleDefinitionInterface) => {
          item.isShown =
            item.id.toLowerCase().includes(newValue) ||
            item.localizedDescription?.toLowerCase().includes(newValue) ||
            item.actionType.toLowerCase().includes(newValue);
          return item;
        }),
      );
    };

    const handleAvailableRulesSearchChange = (newValue: string) => {
      setSearchAvailableRulesValue(newValue);
      newValue = newValue.toLowerCase();
      setAvailableRulesList(
        availableRulesList.map((item: InboundMailRuleDefinitionInterface) => {
          item.isShown =
            item.id.toLowerCase().includes(newValue) ||
            item.localizedDescription?.toLowerCase().includes(newValue) ||
            item.actionType.toLowerCase().includes(newValue);
          return item;
        }),
      );
    };

    //Only once to set resources and load function in update mode
    useEffect(() => {
      loadResourcesAndLoadInfo();
    }, [recordId, recordDescription]);

    useEffect(() => {
      if (isUpdateRules) {
        updateInboundMailRules();
        setIsUpdateRules(false);
      }
    }, [isUpdateRules, assignedRulesList]);

    useEffect(() => {
      setIsEmptyAssignedRules(assignedRulesList.length === 0);
      setIsEmptyAvailableRules(availableRulesList.length === 0);
    }, [assignedRulesList, availableRulesList]);

    return (
      <div id={id} className="assigned-rules-container">
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <TPPageSubTitle>
                {`${subTitleLabel} ${recordId} / ${recordDescription}`}
              </TPPageSubTitle>
              <hr />
            </div>
          </div>

          <div className="row">
            <div>
              <h3>
                <TPIcon
                  iconType={TPIconTypes.help}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsHelpShown(!isHelpShown)}
                />
              </h3>
              {isHelpShown && (
                <div className="alert alert-primary">
                  {helpDragAndDropLabel}
                </div>
              )}
            </div>
          </div>

          <DragDropContext
            onDragStart={(e: any) => handleDragStart(e)}
            onDragUpdate={(e: any) => handleDragUpdate(e)}
            onDragEnd={(e: any) => handleOnDragEnd(e)}
          >
            <TPDragAndDropSectionStyled>
              <div className="row">
                <div className="col-8">
                  <TPPageSectionTitle>
                    {assignedRulesSectionLabel}
                  </TPPageSectionTitle>
                </div>
                {!isEmptyAssignedRules && (
                  <div className="col-4">
                    <TPPageSearchContainer>
                      <TPTextBox
                        id="IdTextBox"
                        icon={TPIconTypes.search}
                        withIcon={true}
                        value={searchAssignRulesValue}
                        placeholder={searchLabel}
                        onChange={(e: any) =>
                          handleAssignRulesSearchChange(e.target.value)
                        }
                        isHorizontal={true}
                      />
                    </TPPageSearchContainer>
                  </div>
                )}
              </div>
              <div className="row mx-0">
                {getDroppableHeader(assignedRulesList)}

                <Droppable droppableId="assignedRulesDroppableId">
                  {(droppableProvided, droppableSnapshot) => (
                    <div
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      className={
                        "droppable-container " +
                        (isEmptyAssignedRules && "empty")
                      }
                      style={GetDroppableListStyle(
                        droppableSnapshot,
                        isEmptyAssignedRules,
                      )}
                    >
                      {assignedRulesList
                        .filter((assignedRule) => assignedRule.isShown)
                        .map((assignedRule, index) => (
                          <Draggable
                            key={assignedRule.id}
                            draggableId={assignedRule.id}
                            index={index}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.dragHandleProps}
                                className="draggable-container row"
                                style={GetDraggableItemStyle(
                                  draggableSnapshot,
                                  draggableProvided.draggableProps.style,
                                )}
                              >
                                <div className="col-2 id-indr">
                                  {assignedRule.id}
                                </div>
                                <div className="col-lg-8">
                                  {assignedRule.localizedDescription}
                                </div>
                                <div className="col-lg-2">
                                  {assignedRule.actionType}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}

                      {isEmptyAssignedRules && assignedRulesSectionEmptyLabel}

                      {droppableProvided.placeholder}

                      {assignedRulesPlaceholderProps &&
                        droppableSnapshot.isDraggingOver &&
                        isVisiblePlaceholder && (
                          <TPPlaceholderDraggableStyled
                            top={assignedRulesPlaceholderProps.y}
                            left={assignedRulesPlaceholderProps.x}
                            height={assignedRulesPlaceholderProps.height}
                            width={assignedRulesPlaceholderProps.width}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </div>

              <br></br>
              <br></br>

              <div className="row">
                <div className="col-8">
                  <TPPageSectionTitle>
                    {availableRulesSectionLabel}
                  </TPPageSectionTitle>
                </div>
                {!isEmptyAvailableRules && (
                  <div className="col-4">
                    <TPPageSearchContainer>
                      <TPTextBox
                        id="IdTextBox"
                        icon={TPIconTypes.search}
                        withIcon={true}
                        value={searchAvailableRulesValue}
                        placeholder={searchLabel}
                        onChange={(e: any) =>
                          handleAvailableRulesSearchChange(e.target.value)
                        }
                        isHorizontal={true}
                      />
                    </TPPageSearchContainer>
                  </div>
                )}
              </div>
              <div className="row mx-0">
                {getDroppableHeader(availableRulesList)}
                <Droppable droppableId="availableRulesDroppableId">
                  {(droppableProvided, droppableSnapshot) => (
                    <div
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      className={
                        "droppable-container " +
                        (isEmptyAvailableRules && "empty")
                      }
                      style={GetDroppableListStyle(
                        droppableSnapshot,
                        isEmptyAvailableRules,
                      )}
                    >
                      {availableRulesList
                        .filter((availableRule) => availableRule.isShown)
                        .map((availableRule, index) => (
                          <Draggable
                            key={availableRule.id}
                            draggableId={availableRule.id}
                            index={index}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.dragHandleProps}
                                className="draggable-container row"
                                style={GetDraggableItemStyle(
                                  draggableSnapshot,
                                  draggableProvided.draggableProps.style,
                                )}
                              >
                                <div className="col-2 id-indr">
                                  {availableRule.id}
                                </div>
                                <div className="col-lg-8">
                                  {availableRule.localizedDescription}
                                </div>
                                <div className="col-lg-2">
                                  {availableRule.actionType}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}

                      {isEmptyAvailableRules && availableRulesSectionEmptyLabel}

                      {droppableProvided.placeholder}
                      {availableRulesPlaceholderProps &&
                        droppableSnapshot.isDraggingOver &&
                        isVisiblePlaceholder && (
                          <TPPlaceholderDraggableStyled
                            top={availableRulesPlaceholderProps.y}
                            left={availableRulesPlaceholderProps.x}
                            height={availableRulesPlaceholderProps.height}
                            width={availableRulesPlaceholderProps.width}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </div>
            </TPDragAndDropSectionStyled>
          </DragDropContext>
        </TPLoadingOverlay>
      </div>
    );
  },
);

export default InboundMailboxesAssignRules;
