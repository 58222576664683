import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react"

const resourceSet = "ScriptGenerics";

export interface ScriptsLabels {
  ScriptAdministration: string;
  NewScript: string;
  Favorites: string;
  Id: string;
  Name: string;
  Language: string;
  Favorite: string;
  Active: string;
  Yes: string;
  No: string;
  Delete: string;
  UpdateScript: string;
  GeneralInfo: string;
  Steps: string;
  Preview: string;
  Diagram: string;
  ShowAsActive: string;
  SaveHeader: string;
  Cancel: string;
  SaveScript: string;
  Search: string;
  NewStep: string;
  Actions: string;
  Order: string;
  Description: string;
  AdditionalData: string;
  DefaultNextStep: string;
  LogicDetermination: string;
  End: string;
  CheckLogic: string;
  Ok: string;
  ScriptLocalizedNames: string;
  NameExample: string;
  CancelScriptCreation: string;
  YouMadeSomeChanges: string;
  ErrorOrderNumeric: string;
  ErrorOrderPositive: string;
  ErrorOrderTaken: string;
  TypeYourDescription: string;
  ExampleOrder: string;
  HelpText: string;
  TypeYourHelp: string;
  SaveStep: string;
  Clone: string;
  CloneScript: string;
  Pending: string;
  StepDescription: string;
  Label: string;
  Add: string;
  WhiteSpace: string;
  SaveInCase: string;
  Mandatory: string;
  AddInNewLine: string;
  AddLogic: string;
  Save: string;
  ThisIsOnlyAPreview: string;
  InputRequired: string;
  InvalidEmail: string;
  InvalidNumber: string;
  InputGtEq: string;
  InputLtEq: string;
  InvalidPhone: string;
  PhoneMaxLength: string;
  PhoneMinLength: string;
  PhoneWhitelist: string;
  SelectFile: string;
  File: string;
  NoTrees: string;
  SelectTree: string;
  Next: string;
  EditStep: string;
  NoDiagram: string;
  AdditionalDataStepDefinition: string;
  AnotherAddData: string;
}

export const useScriptsLabels = function () {

  const [labels, setLabels] = useState<ScriptsLabels>({} as ScriptsLabels);
  const [labelsLoaded, setLabelsLoaded] = useState(false);

  const loadLabels = async function () {
    setLabelsLoaded(true);
    setLabels({
      Actions: await TPI18N.GetText(resourceSet, "Actions"),
      Active: await TPI18N.GetText(TPGlobal.globalResourceSet, "Active"),
      AdditionalData: await TPI18N.GetText(resourceSet, "AdditionalData"),
      Cancel: await TPI18N.GetText(resourceSet, "Cancel"),
      CheckLogic: await TPI18N.GetText(resourceSet, "CheckLogic"),
      DefaultNextStep: await TPI18N.GetText(resourceSet, "DefaultNextStep"),
      Delete: await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      Description: await TPI18N.GetText(TPGlobal.globalResourceSet, "DescriptionSectionLabel"),
      Diagram: await TPI18N.GetText(resourceSet, "Diagram"),
      End: await TPI18N.GetText(resourceSet, "End"),
      Favorite: await TPI18N.GetText(resourceSet, "Favorite"),
      Favorites: await TPI18N.GetText(resourceSet, "Favorites"),
      GeneralInfo: await TPI18N.GetText(resourceSet, "GeneralInfo"),
      Id: await TPI18N.GetText(TPGlobal.globalResourceSet, "Id"),
      Language: await TPI18N.GetText(TPGlobal.globalResourceSet, "Language"),
      LogicDetermination: await TPI18N.GetText(resourceSet, "LogicDetermination"),
      Name: await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
      NewScript: await TPI18N.GetText(resourceSet, "NewScript"),
      NewStep: await TPI18N.GetText(resourceSet, "NewStep"),
      No: await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"),
      Order: await TPI18N.GetText(resourceSet, "Order"),
      Preview: await TPI18N.GetText(resourceSet, "Preview"),
      SaveHeader: await TPI18N.GetText(resourceSet, "SaveHeader"),
      SaveScript: await TPI18N.GetText(resourceSet, "SaveScript"),
      ScriptAdministration: await TPI18N.GetText(resourceSet, "ScriptAdministration"),
      Search: await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      ShowAsActive: await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"),
      Steps: await TPI18N.GetText(resourceSet, "Steps"),
      UpdateScript: await TPI18N.GetText(resourceSet, "UpdateScript"),
      Yes: await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"),
      Ok: await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      ScriptLocalizedNames: await TPI18N.GetText(resourceSet, "ScriptLocalizedNames"),
      NameExample: await TPI18N.GetText(resourceSet, "NameExample"),
      CancelScriptCreation: await TPI18N.GetText(resourceSet, "CancelScriptCreation"),
      YouMadeSomeChanges: await TPI18N.GetText(resourceSet, "YouMadeSomeChanges"),
      ErrorOrderNumeric: await TPI18N.GetText(resourceSet, "ErrorOrderNumeric"),
      ErrorOrderPositive: await TPI18N.GetText(resourceSet, "ErrorOrderPositive"),
      ErrorOrderTaken: await TPI18N.GetText(resourceSet, "ErrorOrderTaken"),
      TypeYourDescription: await TPI18N.GetText(resourceSet, "TypeYourDescription"),
      ExampleOrder: await TPI18N.GetText(resourceSet, "ExampleOrder"),
      SaveStep: await TPI18N.GetText(resourceSet, "SaveStep"),
      HelpText: await TPI18N.GetText(resourceSet, "HelpText"),
      TypeYourHelp: await TPI18N.GetText(resourceSet, "TypeYourHelp"),
      Clone: await TPI18N.GetText(resourceSet, "Clone"),
      CloneScript: await TPI18N.GetText(resourceSet, "CloneScript"),
      Add: await TPI18N.GetText(resourceSet, "Add"),
      AddInNewLine: await TPI18N.GetText(resourceSet, "AddInNewLine"),
      AddLogic: await TPI18N.GetText(resourceSet, "AddLogic"),
      File: await TPI18N.GetText(resourceSet, "File"),
      InputGtEq: await TPI18N.GetText(resourceSet, "InputGtEq"),
      InputLtEq: await TPI18N.GetText(resourceSet, "InputLtEq"),
      InputRequired: await TPI18N.GetText(resourceSet, "InputRequired"),
      InvalidEmail: await TPI18N.GetText(resourceSet, "InvalidEmail"),
      InvalidNumber: await TPI18N.GetText(resourceSet, "InvalidNumber"),
      InvalidPhone: await TPI18N.GetText(resourceSet, "InvalidPhone"),
      Label: await TPI18N.GetText(resourceSet, "Label"),
      Mandatory: await TPI18N.GetText(resourceSet, "Mandatory"),
      Next: await TPI18N.GetText(resourceSet, "Next"),
      NoTrees: await TPI18N.GetText(resourceSet, "NoTrees"),
      Pending: await TPI18N.GetText(resourceSet, "Pending"),
      PhoneMaxLength: await TPI18N.GetText(resourceSet, "PhoneMaxLength"),
      PhoneMinLength: await TPI18N.GetText(resourceSet, "PhoneMinLength"),
      PhoneWhitelist: await TPI18N.GetText(resourceSet, "PhoneWhitelist"),
      Save: await TPI18N.GetText(resourceSet, "Save"),
      SaveInCase: await TPI18N.GetText(resourceSet, "SaveInCase"),
      SelectFile: await TPI18N.GetText(resourceSet, "SelectFile"),
      SelectTree: await TPI18N.GetText(resourceSet, "SelectTree"),
      StepDescription: await TPI18N.GetText(resourceSet, "StepDescription"),
      ThisIsOnlyAPreview: await TPI18N.GetText(resourceSet, "ThisIsOnlyAPreview"),
      WhiteSpace: await TPI18N.GetText(resourceSet, "WhiteSpace"),
      EditStep: await TPI18N.GetText(resourceSet, "EditStep"),
      NoDiagram: await TPI18N.GetText(resourceSet, "NoDiagram"),
      AdditionalDataStepDefinition: await TPI18N.GetText(resourceSet, "AdditionalDataStepDefinition"),
      AnotherAddData: await TPI18N.GetText(resourceSet, "AnotherAddData"),
    })
  }

  useEffect(() => {
    if (!labelsLoaded) loadLabels();
  }, [])

  return { labels };
}