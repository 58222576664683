import { useEffect, useState } from "react";
import { DirectLine } from "botframework-directlinejs";
import ReactWebChat from "botframework-webchat";
import { AnimatePresence } from "framer-motion";
import TPGlobal from "@/helpers/TPGlobal";
import TPLoadingSpinner from "../bootstrap/extend/TPLoadingSpinner/TPLoadingSpinner";
import { showToast, TPToastTypes } from '@/components/bootstrap/components/toasts/TPToast';
import TPCopilotIcon from "@/assets/svgs/copilot-icon.svg";
import { TPCopilotWindowStyle } from "./TPCopilotWindowStyles";

const baseUrl: string = TPGlobal.copilotBaseUrl;

enum Region {
    EUROPE = 'eu',
    INDIA = 'in',
    USA = 'us',
}

const getBaseUrl = (baseUrl: string, conversationId?: string) => {
    
    if (!baseUrl) {
        console.error('Invalid baseUrl:', baseUrl);
        return baseUrl;
    }
    
    if (!conversationId) {
        console.log('baseURL retornado cuando no llega conversationId', { baseUrl });
        return baseUrl;
    }

    let result = baseUrl;

    const splittedConversationId = conversationId.split('-');
    const region = splittedConversationId[splittedConversationId.length - 1] as Region;

    const regionDomains = {
        [Region.EUROPE]: 'europe.',
        [Region.INDIA]: 'india.',
        [Region.USA]: '',
    };

    const regionDomain = regionDomains[region];
    if (regionDomain === undefined) {
        console.error('Invalid region extracted from conversationId:', region);
        return baseUrl;
    }

    result = `https://${regionDomains[region] ?? ''}${result.substring(8)}`;
    console.log('Baseurl que se retorna como resultado de modificarla ---> ', { result });
    return result;
};

const fetchToken = async (uri: string): Promise<{ token: string, conversationId?: string } | undefined> => {
    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`It's not possible to get Hey Wiki token, ${data.message}`);
        }
        console.log('Data del fetchToken ---> ', { data });
        return data;
    } catch (error) {
        showToast(
            `${error}`,
            TPToastTypes.error
        );
    }
};

const fetchNewToken = async (prevToken: string, newBaseUrl: string) => {
    try {
        const response = await fetch(`${newBaseUrl}/tokens/refresh`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${prevToken}`
            }
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`It's not possible to refresh Hey Wiki token, ${data.error.message}`);
        }
        console.log('Data del fetchNewToken ---> ', { data });
        return data;
    } catch (error) {
        showToast(
            `${error}`,
            TPToastTypes.error
        );
    }
};

export const TPCopilotWindow = ({ open }: { open: boolean }) => {

    const [directLine, setDirectLine] = useState<DirectLine | null>(null);
    const [newBaseUrl, setNewBaseUrl] = useState<string>(baseUrl);
    const [token, setToken] = useState<string | undefined>(undefined);

    console.log('Token fuera de los useEffect ---> ', { token });
    console.log('Valor del newBaseUrl fuera de los useEffect ---> ', { newBaseUrl });

    useEffect(() => {
        const startingTime = setTimeout(async () => {
            if (!token) {
                const data = await fetchToken(TPGlobal.getCopilotUrlToken());
                const initialToken = data?.token;
                const regionBaseUrl = getBaseUrl(baseUrl, data?.conversationId);
                console.log('Token inicial dentro del useEffect ---> ', { initialToken });
                console.log('Data del fetchToken dentro de useEffect ---> ', { data });
                setNewBaseUrl(regionBaseUrl);
                setDirectLine(new DirectLine({ token: initialToken, domain: regionBaseUrl }));
                setToken(initialToken);
                console.log('Nueva base URL ---> ', { newBaseUrl });
                console.log('Nuevo token dentro del useEffect ---> ', { token });
            }
        }, 3000);

        return () => clearTimeout(startingTime);
    }, []);

    useEffect(() => {
        if (!token) return;
        
        const interval = setInterval(async () => {
            console.log('Nueva base URL para hacer refresh ---> ', { newBaseUrl });
            const data = await fetchNewToken(token, newBaseUrl);
            const newToken = data?.token;
            console.log('Token nuevo dentro del refresh ---> ', { newToken });
            console.log('Data del fetchToken dentro de useEffect refresh ---> ', { data });
            setDirectLine(new DirectLine({ token: newToken, domain: newBaseUrl }));
            setToken(newToken);
            console.log('Nuevo token dentro del useEffect Refresh ---> ', { token });
            let now = new Date();
            console.log('Esta es la hora del refresh -->', `${now.getHours()} : ${now.getMinutes()} : ${now.getSeconds()}`)

        }, 50 * 60 * 1000); // minutes * segs * miliseconds

        return () => clearInterval(interval);
    }, []);

    const styleOptions = {
        // Hide upload button.
        borderRadius: '50px',
        hideUploadButton: true,
        backgroundColor: '#F4F4F4',
        accent: '#780096',
        fontSizeSmall: '71.5%',
        // Avatar
        botAvatarBackgroundColor: 'transparent',
        botAvatarImage: TPCopilotIcon,
        botAvatarInitials: '',
        avatarSize: 24,
        // Bubble
        bubbleBackground: 'White',
        bubbleBorderColor: '#E3E3E3',
        bubbleBorderRadius: 8,
        bubbleTextColor: '#5D5D5D',
        bubbleFromUserBackground: '#E6E6E6',
        bubbleFromUserBorderColor: '#E3E3E3',
        bubbleFromUserBorderRadius: 8,
        bubbleFromUserTextColor: '#5D5D5D',
        // Timestamp
        timestampColor: '#989898',
        // Send box
        sendBoxHeight: 47,
        sendBoxTextColor: '#5D5D5D',
        sendBoxPlaceholderColor: '#5D5D5D',
        sendBoxButtonColor: '#780096',
        // Suggested actions
        suggestedActionBorderColor: '#a10195',
        suggestedActionBackground: 'White',
        suggestedActionBorderRadius: 8,
        suggestedActionHeight: 30,
        suggestedActionTextColor: '#5D5D5D',
    };

    const appearVariants = {
        enter: { x: 0 },
        leave: { x: '100%' }
    }

    return (
        <AnimatePresence>
            <TPCopilotWindowStyle
                variants={appearVariants}
                initial='leave'
                animate={open ? 'enter' : 'leave'}
            >
                <div className="copilot-container">
                    <div className="copilot" id="webchat" role="main">
                        {directLine ? (
                            <ReactWebChat directLine={directLine} styleOptions={styleOptions} />
                        ) : (
                            <TPLoadingSpinner style={{
                                position: 'absolute',
                                height: "50px",
                                left: "50%",
                                top: "50%",
                                transform: 'translate(-50%, -50%)',
                            }} />
                        )}
                    </div>
                </div>
            </TPCopilotWindowStyle>
        </AnimatePresence>
    )
}
