export class EmailData {
    mailboxLocalized: string = '';
    id: number = 0;
    mailboxId: string = '';
    mailboxRuleDefinitionId: any = null;
    validationKey: string = '';
    uidl: string = '';
    messageID: string = '';
    fromAddress: string = '';
    fromName: string = '';
    replyTo: any = null;
    numTO: number = 0;
    addressTO: string = '';
    numCC: number = 0;
    addressCC: string = '';
    numBCC: number = 0;
    addressBCC: string = '';
    subject: string = '';
    htmlBody: string = '';
    plainTextBody: string = '';
    emailGMTDate: string = '';
    emailLocalDate: string = '';
    emailCustomHeaders: any = null;
    numAttachments: number = 0;
    numEmbeddedImages: number = 0;
    insertionType: string = '';
    insertDate: string = '';
    caseId: number = 0;
    taskId: number = 0;
    translatedLanguageFrom: any = null;
    translatedLanguageTo: any = null;
    subjectTranslated: any = null;
    htmlBodyTranslated: any = null;
    tpGenAILanguageToKeyPoints: string = '';
    tpGenAIKeyPoints: string = '';
    tpGenAILanguageToSummary: any = null;
    tpGenAISummary: string = '';
    insertDateString: string = '';
}

export class SearchIncomingMailModel {
    id: number = -1;
    sourceType: string = "";
    accountDescription: string = "";
    caseNumber: string = "";  
    sender: string = "";
    subject: string = "";
    textBody: string = "";
    insertDate: string = "";
    insertDateString: string = "";
    date: string = "";
}


export class SearchIncomingMailModelInput {
    timeZone: string = "";
    mailboxId: string = "";
    initialDate: Date = new Date();
    endDate: Date = new Date();
}
