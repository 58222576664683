import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { LoadRecordTimerInputDTO, TakeNextQueueInputDTO } from "@/models/TakeNextQueue/TakeNextQueueInputDTO";
import { TakeNextQueueViewModel } from "@/models/TakeNextQueue/TakeNextQueueViewModel";

export class RecordService {
    serviceFileName: string = "RecordService.ts";

    public async getRecordInfo(
        recordId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<Array<TakeNextQueueViewModel>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/eventload/eventLoadRecordId/" + recordId;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getRecordInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getRecordInfo`);
            throw new Error(`Error ${this.serviceFileName} getRecordInfo`);
        }
    }

    public async saveRecord(
        inputDTO: TakeNextQueueInputDTO,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/eventLoad/eventloadrecordFromEventView";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} saveRecord ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} saveRecord`);
            throw new Error(`Error ${this.serviceFileName} saveRecord`);
        }
    }

    public async timeWorkedRecord(
        inputDTO: LoadRecordTimerInputDTO,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/eventLoad/eventloadrecordTime";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} timeWorkedRecord ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} timeWorkedRecord`);
            throw new Error(`Error ${this.serviceFileName} timeWorkedRecord`);
        }
    }

    public async getRecord(
        recordId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/eventload/eventloadrecord/eventLoadRecordId/" + recordId;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            
            return response.responseData.data;

        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getRecord ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getRecord`);
            throw new Error(`Error ${this.serviceFileName} getRecord`);
        }
    }
}