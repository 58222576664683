import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { StyledTab, StyledTabs } from "./load-history-styles";
import { LoadHistoryTableLabels } from "./load-history.model";
import SuccessfulTable from "./SuccessfulTable";
import UploadProgressTable from "./UploadProgressTable";

interface LoadHistoryCallBacks {
  setUserRecords: Function;
  callBackCommands: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{
        display: value === index ? "block" : "none",
      }}
      id={`panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const LoadHistoryAdmin = React.forwardRef(
  ({ callBackCommands, setUserRecords }: LoadHistoryCallBacks, ref) => {
    //logic useState variables
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
    const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(false);
    const [value, setValue] = React.useState(0);

    //label useState variables
    const component: string = "LoadHistoryComponent";

    const initialComponentLabels: LoadHistoryTableLabels =
      {} as LoadHistoryTableLabels;

    const [componentLabels, setComponentLabels] =
      useState<LoadHistoryTableLabels>(initialComponentLabels);
    const [titleLabel, setTitleLabel] = useState<string>("Load History");

    const loadUtilsResources = async () => {
      setTitleLabel(await TPI18N.GetText(component, "Title"));
      setComponentLabels({
        project: await TPI18N.GetText(component, "Project"),
        structure: await TPI18N.GetText(component, "Structure"),
        projectPlaceholder: await TPI18N.GetText(
          component,
          "ProjectPlaceholder"
        ),
        structurePlaceholder: await TPI18N.GetText(
          component,
          "StructurePlaceholder"
        ),
        structureName: await TPI18N.GetText(component, "StructureName"),
        uploaded: await TPI18N.GetText(component, "Uploaded"),
        date: await TPI18N.GetText(component, "Date"),
        resolved: await TPI18N.GetText(component, "Resolved"),
        pending: await TPI18N.GetText(component, "Pending"),
        total: await TPI18N.GetText(component, "Total"),
        active: await TPI18N.GetText(component, "Active"),
        noRecords: await TPI18N.GetText(component, "NoRecords"),
        noHistories: await TPI18N.GetText(component, "NoHistories"),
        yes: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
        no: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
        inProgress: await TPI18N.GetText(component, "InProgress"),
        failed: await TPI18N.GetText(component, "Failed"),
        uploadFile: await TPI18N.GetText(component, "UploadFile"),
        successLoad: await TPI18N.GetText(component, "SuccessLoad"),
        errorLoad: await TPI18N.GetText(component, "ErrorLoad"),
        status: await TPI18N.GetText(component, "Status"),
        errors: await TPI18N.GetText(component, "Errors"),
        successTab: await TPI18N.GetText(component, "SuccessTab"),
        progressTab: await TPI18N.GetText(component, "ProgressTab"),
        errorDetails: await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "ErrorTitle"
        ),
      });
    };

    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        console.log("refreshGridFromParent called");
      },
    }));

    useEffect(() => {
      loadUtilsResources().then(() => setIsResourcesLoaded(true));
    }, []);

    return (
      <TPLoadingOverlay active={isLoadingScreen}>
        {isResourcesLoaded && (
          <div className="row">
            <div className="col">
              <CIMTitleSection isCIM>
                <TPPageTitle style={{ margin: 0 }}>{titleLabel}</TPPageTitle>
              </CIMTitleSection>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={(_e, value) => setValue(value)}
                  aria-label="history load tabs"
                  sx={StyledTabs}
                >
                  <Tab
                    label={componentLabels.successTab}
                    id="success-tab"
                    sx={StyledTab}
                    disableRipple
                  />
                  <Tab
                    label={componentLabels.progressTab}
                    id="progress-tab"
                    sx={StyledTab}
                    disableRipple
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ marginTop: "3em" }}>
                  <SuccessfulTable
                    tableLabels={componentLabels}
                    setIsLoadingScreen={setIsLoadingScreen}
                    setUserRecords={setUserRecords}
                    callBackCommands={callBackCommands}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ marginTop: "3em" }}>
                  <UploadProgressTable
                    tableLabels={componentLabels}
                    setIsLoadingScreen={setIsLoadingScreen}
                    callBackCommands={callBackCommands}
                  />
                </Box>
              </CustomTabPanel>
            </div>
          </div>
        )}
      </TPLoadingOverlay>
    );
  }
);

export default LoadHistoryAdmin;
