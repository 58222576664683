import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export type PreferredAgentInputDTO = {
  id: number;
  idSubsidiary: number;
  idUser: number;
  priority: number;
  agentLogin: string;
  agentName: string;
};

export class PreferredAgentInputDTOValidator extends Validator<PreferredAgentInputDTO> {
  constructor() {
    super();
    const resourceSet: string = "PreferredAgentInsertUpdateComponent";

    this.ruleFor("idSubsidiary")
      .must((idSubsidiary) => idSubsidiary.toString().trim() !== "")
      .withMessage(resourceSet + "|InputDTOIdSubsidiaryEmpty")
      .must((idSubsidiary) => {
        return (
          TPGlobal.TPSanitize(idSubsidiary.toString()) ==
          idSubsidiary.toString()
        );
      })
      .withMessage(resourceSet + "|InputDTOInvalidIdSubsidiary");

    this.ruleFor("idUser")
      .must((idUser) => {
        // Ensure idUser is not empty and not equal to placeholder '--'
        return idUser.toString().trim() !== "" && idUser.toString() !== "--";
      })
      .withMessage(resourceSet + "|InputDTOInvalidIdUser")
      .must((idUser) => {
        // Ensure idUser is not empty
        return idUser > 0;
      })
      .withMessage(resourceSet + "|InputDTOInvalidIdUser")
      .must((idUser) => {
        // Ensure idUser is a valid number and not a placeholder string
        return typeof idUser === "number" && !isNaN(idUser);
      })
      .withMessage(resourceSet + "|InputDTOInvalidIdUser")
      .must((idUser) => {
        // Perform sanitization check
        return TPGlobal.TPSanitize(idUser.toString()) == idUser.toString();
      })
      .withMessage(resourceSet + "|InputDTOInvalidIdUser");

    this.ruleFor("priority")
      .must((priority) => priority.toString().trim() !== "")
      .withMessage(resourceSet + "|InputDTOPriorityEmpty")
      .must((priority) => priority >= 1 && priority <= 10)
      .withMessage(resourceSet + "|InputDTOInvalidPriority")
      .must((priority) => {
        return TPGlobal.TPSanitize(priority.toString()) == priority.toString();
      })
      .withMessage(resourceSet + "|InputDTOInvalidPriority");

    this.ruleFor("agentLogin")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOAgentLoginEmpty")
      .must((agentLogin) => {
        return TPGlobal.TPSanitize(agentLogin) == agentLogin;
      })
      .withMessage(resourceSet + "|InputDTOInvalidAgentLogin")
      .maxLength(100)
      .withMessage(resourceSet + "|InputDTOInvalidAgentLoginLength");

    this.ruleFor("agentName")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOAgentNameEmpty")
      .must((agentName) => {
        return TPGlobal.TPSanitize(agentName) == agentName;
      })
      .withMessage(resourceSet + "|InputDTOInvalidAgentName")
      .maxLength(200)
      .withMessage(resourceSet + "|InputDTOInvalidAgentNameLength");
  }
}
