import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement, useState } from "react";
import { TableIcon } from "./TableIcons";
import { StyledSwitchView } from "./dynamic-table-styles";

export enum TableViews {
  TABLE = "table",
  CARDS = "cards",
}

type SwitchTableViewProps = {
  id?: string;
  active: TableViews;
  handleViewChanged: (type: TableViews) => void;
};

interface ActionButton extends TableIcon {
  action: TableViews;
}

function SwitchTableView({
  id,
  active,
  handleViewChanged,
}: SwitchTableViewProps): ReactElement {
  const [buttonActive, setButtonActive] = useState<TableViews>(active);
  const iconButtons: ActionButton[] = [
    {
      action: TableViews.CARDS,
      type: TPIconTypes.cardsView,
      tooltip: "Card View",
      status: true,
    },
    {
      action: TableViews.TABLE,
      type: TPIconTypes.tableView,
      tooltip: "Table View",
      status: true,
    },
  ];

  const getIconId = (iconType: TPIconTypes): string | undefined => {
    return id ? `${id}-${iconType}-button` : undefined;
  };

  const viewChanged = (action: TableViews) => {
    setButtonActive(action);
    handleViewChanged(action);
  };

  return (
    <StyledSwitchView>
      {iconButtons.map((icon) => (
        <TPIcon
          className={`${icon.type} ${buttonActive === icon.action ? "ui-active" : ""}`}
          id={getIconId(icon.type)}
          key={icon.type}
          iconType={icon.type}
          tooltip={icon.tooltip}
          isTooltip={true}
          onClick={() => viewChanged(icon.action)}
        />
      ))}
    </StyledSwitchView>
  );
}

export default SwitchTableView;
