import { UserViewModel } from "../Users/UserModels";

export interface GroupsViewModel {
  userLogin: string,      // Using dot notation for nested properties
  userFirstName: string,
  userIsActive: boolean,
  groupId: string;
  isRotating: boolean;
  isUpdatable: boolean;
  isPicking: boolean;
  finishPendingCaseBeforeRecivingNewOne: boolean;
  preferredAgent: boolean;
  relatedCase: boolean;
  recentAgent: boolean;
  prioritizeRelatedCases: boolean;
  topSearchCoefficient: number;
  assignCasesToUsersLogged: boolean;
  minutesLogoutReassign?: number;
  maximumCasesPerUser?: number;
  taskResponsibleForSocialMediaAnswer: number;
  priorityWeight: number;
  isEventTakeNextGroup: boolean;
  isChatGroup:boolean;
  isKBGroup:boolean;
  user: UserViewModel;
}

export interface QueueAdminModel {
  groupId: number;
  interactionOutcomes: string;
  clasificationOptions: string | null;
  customFields: string | null;
  activateWarningAfterMinutes: number;
  activateWarningAfterSeconds: number;
  enventGroupType: string;
}

export enum QueueFilter {
  Profile = "PROF",
  Team = "TEAM",
  Function = "FUNC",
  Superior = "SUPE",
}

export interface AssignableGroupUser {
  groupId: number;
  user: UserViewModel;
}

export interface GroupSearchFilter {
  itemId: string;
  itemDescription: string;
  itemType: string;
}

export interface UserSearchByFilterModel {
  groupId: number;
  id_FUNC: string | null;
  id_PROF: string | null;
  superior_Id_USER: string | null;
  team_Id_BRAN: string | null;
  name: string | null;
}

export interface GroupUsersReassignModel {
  usersIds: number[];
  idGroup: number;
}

export enum QueueTabs {
  GeneralInfo,
  Administration,
  AssignUsers,
}

export interface UserTakeNext {
  id: number;
  userGuid: string;
  login: string;
  firstName: string;
  lastName: string;
  name: string;
  initials: string;
  profileId: string;
  profileDescription: string;
  superiorIdGuid: string;
  email: string;
  teamId: string;
  teamDescription: string;
  functionId: string;
  functionDescription: string;
  areaId: string;
  areaDescription: string;
  phone: string;
  mobile: string;
  typistIdGuid: string;
  calendarId: string;
  calendarDescription: string;
  timeZoneId: string;
  scheduleId: string;
  scheduleDescription: string;
  authenticationMethodId: string;
  microsoftIdentity: string;
  defaultLanguage: string;
  maxSimultaneousChat: string;
  isActive: boolean;
  isGroup: boolean;
  roleUserAssigned: string;
  isSystem: boolean;
  isOffline: boolean;
}

export interface GroupTakeNext {
  groupId: number;
  isUpdatable: boolean;
  isRotating: boolean;
  isPicking: boolean;
  isChatGroup: boolean;
  isKBGroup: boolean;
  isEventTakeNextGroup: boolean;
  finishPendingCaseBeforeRecivingNewOne: boolean;
  preferredAgent: boolean;
  recentAgent: boolean;
  prioritizeRelatedCases: boolean;
  topSearchCoefficient: number;
  assignCasesToUsersLogged: boolean;
  minutesLogoutReassign: string;
  maximumCasesPerUser: string;
  taskResponsibleForSocialMediaAnswer: number;
  priorityWeight: number;
  user: UserTakeNext;
}
