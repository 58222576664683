import { TPI18N } from "@/services/I18nService";
import { SearchIncomingMailModel } from "../../models/SearchIncomingMail/SearchIncomingMail";

export class LocationLanguagesSearchIncomingMail {
  getLocationNameTable: LocationNameTable = new LocationNameTable();
  getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();

  public handlelocationTable = async (resourceSet: string) => {
    this.getLocationNameTable.title = await TPI18N.GetText(
      resourceSet,
      "SearchIncomingMailComponentLabel"
    );
    this.getLocationNameTable.accountFilter = await TPI18N.GetText(
      resourceSet,
      "accountFilterLabel"
    );
    this.getLocationNameTable.msgOkAlert = await TPI18N.GetText(
      "CaseCreationStatsComponent",
      "MsgOkAlertLabel"
    );
    this.getLocationNameTable.msgError = await TPI18N.GetText(
      "CaseCreationStatsComponent",
      "MsgErrorLabel"
    );

    this.getLocationNameTable.sourceType = await TPI18N.GetText(
      resourceSet,
      "SourceTypeLabel"
    );
    this.getLocationNameTable.accountDescription = await TPI18N.GetText(
      resourceSet,
      "AccountDescriptionLabel"
    );
    this.getLocationNameTable.caseNumber = await TPI18N.GetText(
      resourceSet,
      "CaseNumberLabel"
    );
    this.getLocationNameTable.status = await TPI18N.GetText(
      resourceSet,
      "StatusLabel"
    );
    this.getLocationNameTable.sender = await TPI18N.GetText(
      resourceSet,
      "SenderLabel"
    );
    this.getLocationNameTable.subject = await TPI18N.GetText(
      resourceSet,
      "SubjectLabel"
    );
    this.getLocationNameTable.textBody = await TPI18N.GetText(
      resourceSet,
      "TextBodyLabel"
    );
    this.getLocationNameTable.insertDate = await TPI18N.GetText(
      resourceSet,
      "InsertDateLabel"
    );

    this.getLocationNameTable.incomingMail = await TPI18N.GetText(
      resourceSet,
      "IncomingMail"
    );
    this.getLocationNameTable.moreFilter = await TPI18N.GetText(
      resourceSet,
      "MoreFilter"
    );

    this.getLocationNameTable.timezoneLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "TimezoneLabel"
      );

    this.getLocationNameTable.startDateLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "StartDateLabel"
    );
    this.getLocationNameTable.endDateLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "EndDateLabel"
    );
    this.getLocationNameTable.todayLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "TodayLabel"
    );
    this.getLocationNameTable.yesterdayLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "YesterdayLabel"
    );
    this.getLocationNameTable.currentWeekLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "CurrentWeekLabel"
    );
    this.getLocationNameTable.lastWeekLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "LastWeekLabel"
    );
    this.getLocationNameTable.currentMonthLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "CurrentMonthLabel"
    );
    this.getLocationNameTable.lastMonthLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "LastMonthLabel"
    );
    this.getLocationNameTable.currentYearLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "CurrentYearLabel"
    );
    this.getLocationNameTable.lastTwelveMonthsLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "LastTwelveMonthsLabel"
    );
    this.getLocationNameTable.quickSelectDatesLabel = await TPI18N.GetText(
      "CaseSearchByFilterComponent",
      "QuickSelectDatesLabel"
    );
  };

  public handlelocationGlobal = async (resourceSet: string) => {
    this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(
      resourceSet,
      "ConfirmTitle"
    );
    this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(
      resourceSet,
      "OkButton"
    );
    this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(
      resourceSet,
      "CancelButton"
    );
    this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(
      resourceSet,
      "DeleteLabel"
    );
    this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(
      resourceSet,
      "UpdateLabel"
    );
    this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(
      resourceSet,
      "BuikLoad"
    );
    this.getLocationNameGlobal.search = await TPI18N.GetText(
      resourceSet,
      "Search"
    );
    this.getLocationNameGlobal.filterLabel = await TPI18N.GetText(
      resourceSet,
      "FilterLabel"
    );
    this.getLocationNameGlobal.resultsLabel = await TPI18N.GetText(
      resourceSet,
      "ResultsLabel"
    );
    this.getLocationNameGlobal.inputRequire = await TPI18N.GetText(
      resourceSet,
      "InputDTORequired"
    );
  };
}

export class LocationNameTable extends SearchIncomingMailModel {
  title: string = "";
  timezoneLabel: string = "";
  startDateLabel: string = "";
  endDateLabel: string = "";
  todayLabel: string = "";
  yesterdayLabel: string = "";
  currentWeekLabel: string = "";
  lastWeekLabel: string = "";
  currentMonthLabel: string = "";
  lastMonthLabel: string = "";
  currentYearLabel: string = "";
  lastTwelveMonthsLabel: string = "";
  quickSelectDatesLabel: string = "";
  accountFilter: string = "";
  msgOkAlert: string = "";
  msgError: string = "";
  incomingMail: string = "";
  moreFilter: string = "";
  status: string = "";
}

export class LocationNameGlobal {
  confirmAlertTitle: string = "";
  okAlertButton: string = "";
  cancelAlertButton: string = "";
  deleteLabel: string = "";
  updateLabel: string = "";
  buikLoad: string = "";
  search: string = "";
  filterLabel: string = "";
  resultsLabel: string = "";
  inputRequire: string = "";
}
