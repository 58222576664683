import { ReactElement, useState } from "react";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  TPCollapsableSectionTitle,
  TPCollapsableSectionContainer,
  TPCollapsableSectionHeader,
  TPCollapsableSectionContent,
  TPCollapsableSectionDescription,
  TPCollapsableSectionBody,
} from "./TPCollapsableSectionStyles";

interface TPCollapsableSectionProps {
  title: string | ReactElement;
  description?: string | ReactElement;
  children?: string | ReactElement;
  initialCollapsed?: boolean;
}

const TPCollapsableSection = ({
  title,
  description,
  children,
  initialCollapsed = true,
}: TPCollapsableSectionProps): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(initialCollapsed);

  return (
    <TPCollapsableSectionContainer>
      <TPCollapsableSectionHeader
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <TPCollapsableSectionTitle>{title}</TPCollapsableSectionTitle>

        <TPIcon
          iconType={
            isCollapsed ? TPIconTypes.expandMore : TPIconTypes.expandLess
          }
        />
      </TPCollapsableSectionHeader>

      <TPCollapsableSectionContent collapsed={isCollapsed}>
        {description && (
          <TPCollapsableSectionDescription>
            {description}
          </TPCollapsableSectionDescription>
        )}

        {children && (
          <TPCollapsableSectionBody>{children}</TPCollapsableSectionBody>
        )}
      </TPCollapsableSectionContent>
    </TPCollapsableSectionContainer>
  );
};

export default TPCollapsableSection;
