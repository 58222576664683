import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MergeCustomerSliceModel {
  currentView: "search" | "conflict";
  mainCustomerData: ClientAdminViewModel;
  mergeCustomerData: ClientAdminViewModel;
  finalMergeData: ClientAdminViewModel;
  conflicts: boolean;
  mainValueInput: string;
  altValueInput: string;
  customValueInput: string;
  concatenateValueInput: string;
  chosenAttribute: string;
  interestValues: string[];
  mergeWarningVisible: boolean;
  canMerge: boolean;
}

const blankCustomer: ClientAdminViewModel = {
  id: -1,
  documentType: "",
  document: "",
  name: "",
  lastName: "",
  isActive: false,
  subsidiaryId: 0,
  subsidiaryName: "",
  geography: "",
  subsidiaryOrganizationId: 0,
  customerTypeId: "",
  customerTypeDescription: "",
  internalCode: "",
  additionalInfo: "",
  typist_Guid: "",
  typist_FullName: "",
  typist_Initials: "",
  dateUpdate: "",
  cRCDocument: "",
  preferredAddress: "",
  preferredAddressInfo: "",
  preferredPhone: "",
  preferredEmail: "",
  regionId: "",
  channelId: "",
  organizationId: "",
  organizationName: "",
  organizationImage: "",
  relationId: "",
  customerName: "",
  documentTypeDescription: "",
};

const initialState: MergeCustomerSliceModel = {
  currentView: "search",
  mainCustomerData: structuredClone(blankCustomer),
  mergeCustomerData: structuredClone(blankCustomer),
  finalMergeData: structuredClone(blankCustomer),
  conflicts: false,
  mainValueInput: "",
  altValueInput: "",
  customValueInput: "",
  concatenateValueInput: "",
  chosenAttribute: "",
  interestValues: [
    "name",
    "lastName",
    "preferredPhone",
    "preferredEmail",
    "preferredAddress",
    "preferredAddressInfo",
    "geography",
    "additionalInfo",
    "typist_Guid",
    "subsidiaryName",
    "internalCode",
  ],
  mergeWarningVisible: false,
  canMerge: false,
};

const MergeCustomersSlice = createSlice({
  initialState: initialState,
  name: "MergeCustomersSlice",
  reducers: {
    setCurrentView: function (
      state,
      action: PayloadAction<"search" | "conflict">,
    ) {
      state.currentView = action.payload;
    },
    setMainCustomerData: function (
      state,
      action: PayloadAction<ClientAdminViewModel>,
    ) {
      state.mainCustomerData = { ...action.payload };
    },
    setMergeCustomerData: function (
      state,
      action: PayloadAction<ClientAdminViewModel>,
    ) {
      state.mergeCustomerData = { ...action.payload };
    },
    setFinalMergeData: function (
      state,
      action: PayloadAction<ClientAdminViewModel>,
    ) {
      state.finalMergeData = { ...action.payload };
    },
    setConflicts: function (state, action: PayloadAction<boolean>) {
      state.conflicts = action.payload;
    },
    setMainValueInput: function (state, action: PayloadAction<string>) {
      state.mainValueInput = action.payload;
    },
    setAltValueInput: function (state, action: PayloadAction<string>) {
      state.altValueInput = action.payload;
    },
    setCustomValueInput: function (state, action: PayloadAction<string>) {
      state.customValueInput = action.payload;
    },
    setConcatenateValueInput: function (state, action: PayloadAction<string>) {
      state.concatenateValueInput = action.payload;
    },
    setChosenAttribute: function (state, action: PayloadAction<string>) {
      state.chosenAttribute = action.payload;
    },
    setMergeWarningVisible: function (state, action: PayloadAction<boolean>) {
      state.mergeWarningVisible = action.payload;
    },
    resetCustomerMerge: function (state, action: PayloadAction<void>) {
      state.mainCustomerData = structuredClone(blankCustomer);
      state.mergeCustomerData = structuredClone(blankCustomer);
      state.finalMergeData = structuredClone(blankCustomer);
      state.mainValueInput = "";
      state.altValueInput = "";
      state.customValueInput = "";
      state.concatenateValueInput = "";
      state.conflicts = false;
      state.chosenAttribute = "";
      state.mergeWarningVisible = false;
      state.currentView = "search";
    },
    setCanMerge: function (state, action: PayloadAction<boolean>) {
      state.canMerge = action.payload;
    },
  },
});

export const {
  setCurrentView,
  setMainCustomerData,
  setMergeCustomerData,
  setFinalMergeData,
  setConflicts,
  setMainValueInput,
  setAltValueInput,
  setCustomValueInput,
  setConcatenateValueInput,
  setChosenAttribute,
  setMergeWarningVisible,
  resetCustomerMerge,
  setCanMerge,
} = MergeCustomersSlice.actions;

export default MergeCustomersSlice.reducer;
