import { put, takeEvery, all } from "redux-saga/effects";
import { TabsCaseViewerSlice } from "./TabsCaseViewerSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { TaskService } from "@/services/TaskService";
import { CaseService } from "@/services/CaseService";
import { CasesViewModel } from "@/models/Cases/CasesViewModels";
import TPGlobal from "@/helpers/TPGlobal";
import { CaseStatus } from "@/models/Cases/CaseStatus";

const { massiveUpdateCaseTimer, createCaseTimer } = new TaskService();
const { getCaseInfoByCaseNumber } = new CaseService();

function* addCaseSaga({
  payload,
}: PayloadAction<{ userGuid: any; caseNumber: string }>) {
  try {
    // @ts-ignore
    const caseInfo: CasesViewModel = yield getCaseInfoByCaseNumber(
      Number(payload.caseNumber),
      false,
      false,
      [200],
    );
    if (
      CaseStatus.SO !== caseInfo.caseStatusId &&
      TPGlobal.enableCaseAverageHandleTime
    ) {
      // @ts-ignore
      const result: any = yield createCaseTimer(
        {
          CaseId: payload.caseNumber,
          UserGuid: payload.userGuid,
        },
        false,
        false,
        [200],
      );
      yield put(
        TabsCaseViewerSlice.actions.addCaseSuccess({
          id: result.responseData.keyList[0].value, // result.id consultar cual es el resultado real de result
          caseNumber: payload.caseNumber,
        }),
      );
    } else {
      yield put(TabsCaseViewerSlice.actions.addCaseSuccess());
    }
  } catch (e) {
    yield put(TabsCaseViewerSlice.actions.addCaseError(e));
  }
}

function* notifySaga({ payload }: PayloadAction<{ ids: any; seconds: any }>) {
  try {
    const CasesTimerList = Object.entries(payload.seconds).map(
      ([key, value]) => ({
        Id: payload.ids[key],
        Seconds: value,
      }),
    );
    yield massiveUpdateCaseTimer({ CasesTimerList }, false, false, [200]);
    yield put(TabsCaseViewerSlice.actions.notifySuccess());
  } catch (e) {
    yield put(TabsCaseViewerSlice.actions.notifyError(e));
  }
}

export function* TabsCaseViewerWatchAsync() {
  yield all([
    takeEvery(TabsCaseViewerSlice.actions.addCase, addCaseSaga),
    takeEvery(TabsCaseViewerSlice.actions.notifyLoading, notifySaga),
  ]);
}
