export class GroupDashboardCaseModel {
    caseId: number = 0;
    stateId: number = 0;
    stateImage: string = "";
    stateDescription: string = "";
    promiseDate: string = "";
    term: number = 0;
    responsibleFullName: string = "";
    dateWriterRead: string = "";
    timeLabor: number = 0;
    customStateId: number = 0;
    customStateImage: string = "";
    customStateDescription: string = "";
    stateWeight: number = 0;
    isPrivate: boolean = false;
    taskId: number = 0;
    taskId2: number = 0;
    workingTaskTime: number = 0;
    taskTypeDescription: string = "";
}



export class GroupDashboardUserModel {
    id: number = 0;
    userGuid: string = "";
    login: string = "";
    fullname: string = "";
    workingCaseId: string = "";
    workingTimeInCases: number = 0;
    workingTaskId: string = "";
    workingTaskTime: string = "";
    localizedDescription: string = "";
    workedCases: number = 0;
    closedCases: number = 0;
    timeLogged: number = 0;
    timeReady: number = 0;
    status: string = ""
    workingTime: string = "";
}


export class UserAndGroupDataModel {
    userId: number = 0;
    userLogin: string = '';
    userFirstName: string = '';
    userLastName: string = '';
    isUserGroup: boolean = false;
}


export class TaskToReassign {
    groupIdUser: number =-1;
    guidUser: string = '';
    taskToReassign: Array<CaseAndTaskModel> = new Array<CaseAndTaskModel>();
}

export class CaseAndTaskModel {
    caseId: number = -1;
    taskId: number = -1;
    constructor(caseId: number, taskId: number) {
        this.caseId = caseId;
        this.taskId = taskId;
    }
}
