import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { QueueModel } from "@/models/EventsManager/CreateQueueModels";

export class QueueService {

  static serviceFileName: string = "QueueService.ts";

  public static async getQueues(
    filterActive: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<QueueModel>> {
    const httpClientInstance = new TPHTTPService();
    const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/groups/EventTakeNextType?FilterIsActive=' + String(filterActive);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
          url,
          expectedCodes,
          showPositiveMessage,
          showNegativeMessage,
          headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getQueues exception`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getQueues`);
      throw new Error(`Error ${this.serviceFileName} getQueues`);
    }
  }

  public static async getActiveLists( // TODO: WRONG SERVICE
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      'api/tenant/' +
      TPGlobal.tenantHex +
      '/lists?filterIsActive=1&type=S_EVENTLISTTYPE';
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
          url,
          expectedCodes,
          showPositiveMessage,
          showNegativeMessage,
          headers
      )
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getActiveLists exception`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getQueues`);
      throw new Error(`Error ${this.serviceFileName} getQueues`);
    }
  }
}