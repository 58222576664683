import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPModal from "@/layouts/TPModal/TPModal";
import { TaskReminderMode } from "@/models/Task/TaskReminder";
import { FC, useEffect, useState } from "react";
import "./resources/TaskReminderStyles.css";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { useTaskReminderLabels } from "./resources/TaskReminderLabels";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { RemindersService } from "@/services/MyRemindersService";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "@/pages/MyReminders/MyRemindersSlice";
import { useDispatch } from "react-redux";

interface TaskReminderModalProperties {
  taskId: number;
  caseId: number;
  onAction: Function;
}

const minHours = 0;
const maxHours = 23;
const minMinutes = 0;
const maxMinutes = 59;

const hourOptions = Array.from({ length: maxHours - minHours + 1 }, (_, i) => {
  return { key: String(i), value: i < 10 ? String("0").concat(String(i)) : String(i) }
});
const minutesOptions = Array.from({ length: maxMinutes - minMinutes + 1 }, (_, i) => {
  return { key: String(i), value: i < 10 ? String("0").concat(String(i)) : String(i) }
})

export const TaskReminderModal: FC<TaskReminderModalProperties> = function ({ taskId, caseId, onAction }) {

  const { labels } = useTaskReminderLabels();
  const [date, setDate] = useState<Date>(new Date());
  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [reminderId, setReminderId] = useState<number | null>();
  const dispatch = useDispatch();

  const getReminder = function () {
    if (taskId && caseId) {
      setLoading(true);
      RemindersService.getReminderByTaskId(taskId, false, false, [200, 404])
        .then(reminder => {
          if (reminder) {
            const retrievedDate = new Date(reminder.date);
            retrievedDate.setUTCDate(retrievedDate.getDate());
            retrievedDate.setUTCHours(retrievedDate.getHours());
            retrievedDate.setUTCMinutes(retrievedDate.getMinutes());
            setReminderId(reminder.id);
            setDate(retrievedDate);
            setHours(retrievedDate.getHours().toString());
            setMinutes(retrievedDate.getMinutes().toString());
            setComments(reminder.comments);
          }
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }

  const handleSave = function () {
    reminderId ? updateReminder() : saveReminder();
  }

  const saveReminder = function () {
    setLoading(true);
    const fixedDate = date;
    fixedDate.setHours(Number(hours));
    fixedDate.setMinutes(Number(minutes));
    fixedDate.setSeconds(0);
    RemindersService.createReminder({
      comments: comments,
      date: fixedDate,
      taskId: taskId,
      type: "M"
    }, true, true, [200])
      .then(() => {
        onAction();
        dispatch(MyRemindersSlice.actions.setContentLoaded(false));
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  }

  const updateReminder = function () {
    if (!reminderId) return;
    setLoading(true);
    const fixedDate = date;
    fixedDate.setHours(Number(hours));
    fixedDate.setMinutes(Number(minutes));
    fixedDate.setSeconds(0);
    RemindersService.updateReminder({
      comments: comments,
      date: fixedDate,
      id: reminderId,
      type: "M",
      status: "P"
    }, true, true, [200])
      .then(() => {
        onAction();
        dispatch(MyRemindersSlice.actions.setContentLoaded(false));
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getReminder();
  }, [])

  return (
    <>
        <TPModal
        modalState={{
          acceptLabel: labels.Save,
          cancelLabel: labels.Cancel,
          callBackAnswer: (saved: boolean) => saved ? handleSave() : onAction(saved),
            isShown: true,
          titleModal: reminderId ? labels.Update : labels.Create,
          hideXButton: true,
          zIndex: 3,
          enableAcceptButton: Boolean(date) && comments.trim().length > 0
        }}
      >
        <div className="reminder-modal-content">
          <TPLoadingOverlay active={loading}>
          <div className="reminder-form-row">
            <div>
              <TPDatePicker
                isMandatory
                labelText={labels.Date}
                minDate={new Date()}
                onChangeDate={(d: Date) => setDate(d)}
                selectedDate={date}
              />
            </div>
            <div>
              <label style={{ fontSize: "13px" }}>{labels.Time}<b style={{ color: 'red' }}>*</b></label>
              <div className="reminder-form-row">
                <div className="reminder-form-row" style={{ gap: "8px", alignItems: 'flex-end' }}>
                  <TPSelect
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHours(e.target.value)}
                    value={hours}
                    dataSource={hourOptions}
                  />
                  <b style={{ fontSize: "12px" }}>HH</b>
                </div>
                <label>:</label>
                <div className="reminder-form-row" style={{ gap: "8px", alignItems: 'flex-end' }}>
                  <TPSelect
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMinutes(e.target.value)}
                    value={minutes}
                    dataSource={minutesOptions}
                  />
                  <b style={{ fontSize: "12px" }}>MM</b>
                </div>
              </div>

            </div>
          </div>
          <TPTextArea
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComments(e.target.value)}
            rows={5}
            value={comments}
            isMandatory
            textAreaStyle={{ minHeight: "128px", maxHeight: "128px" }}
            labelText={labels.Comments}
          />

          </TPLoadingOverlay>
        </div>
      </TPModal>
    </>
  )
}