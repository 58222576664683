import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export type UserInputDTO = {
  userGuid: string;
  login: string;
  firstName: string;
  lastName: string;
  name: string;
  initials: string;
  profileId: string | null;
  profileDescription: string;
  superiorIdGuid: string | null;
  email: string;
  teamId: string | null;
  teamDescription: string;
  functionId: string | null;
  functionDescription: string;
  areaId: string | null;
  areaDescription: string;
  phone: string;
  mobile: string;
  typistIdGuid: string;
  calendarId: string;
  calendarDescription: string;
  timeZoneId: string;
  scheduleId: string;
  scheduleDescription: string;
  authenticationMethodId: string;
  microsoftIdentity: string;
  defaultLanguage: string;
  maxSimultaneousChat: number | null;
  isActive: boolean;
  isSystemRecord: boolean;
  [key: string]: any;
};

export enum ValidationUserGroupModeEnum {
  user = "user",
  group = "group",
}
export class UserInputDTOValidator extends Validator<UserInputDTO> {
  constructor(mode: ValidationUserGroupModeEnum) {
    super();

    const resourceSet = "UsersInsertUpdateComponent";

    try {
      if (mode === ValidationUserGroupModeEnum.user) {
        this.ruleFor("login")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOLoginEmpty")
          .maxLength(100)
          .withMessage(resourceSet + "|InputDTOInvalidLoginLength")
          .must((id) => {
            let check = TPGlobal.checkSpecialUserLoginCharacters(id);
            return check.result;
          })
          .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
          .must((value) => {
            return TPGlobal.TPSanitize(value) === value;
          })
          .withMessage(resourceSet + "|InputDTOInvalidLogin");
      } else if (mode === ValidationUserGroupModeEnum.group) {
        this.ruleFor("login")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOIdGroupEmpty")
          .maxLength(100)
          .withMessage(resourceSet + "|InputDTOInvalidIdGroupLength")
          .must((id) => {
            let check = TPGlobal.checkSpecialUserLoginCharacters(id);
            return check.result;
          })
          .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
          .must((value) => {
            return TPGlobal.TPSanitize(value) === value;
          })
          .withMessage(resourceSet + "|InputDTOInvalidIdGroup");
      }

      //FirstName
      if (mode === ValidationUserGroupModeEnum.user) {
        this.ruleFor("firstName")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOFirstNameEmpty")
          .maxLength(50)
          .withMessage(resourceSet + "|InputDTOInvalidFirstNameLength")
          .must((value) => {
            return TPGlobal.TPSanitize(value) === value;
          })
          .withMessage(resourceSet + "|InputDTOInvalidFirstName");
      } else if (mode === ValidationUserGroupModeEnum.group) {
        this.ruleFor("firstName")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTODescriptionEmpty")
          .maxLength(50)
          .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength")
          .must((value) => {
            return TPGlobal.TPSanitize(value) === value;
          })
          .withMessage(resourceSet + "|InputDTOInvalidDescription");
      }

      //LastName
      this.ruleFor("lastName")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidLastNameLength")
        .must((lastName) => {
          return TPGlobal.TPSanitize(lastName) === lastName;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLastName");

      //initials
      this.ruleFor("initials")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInitialsEmpty")
        .maxLength(10)
        .withMessage(resourceSet + "|InputDTOInvalidInitialsLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidInitials");
      if (mode == ValidationUserGroupModeEnum.user) {
        //ProfileId
        this.ruleFor("profileId")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOProfileIdEmpty")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
          .must((value: any) => {
            return TPGlobal.TPSanitize(value) === value;
          })
          .withMessage(resourceSet + "|InputDTOInvalidProfileId");
      } else {
        //ProfileId
        this.ruleFor("profileId")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
          .must((value: any) => {
            if (value) {
              return TPGlobal.TPSanitize(value) === value;
            } else {
              return true;
            }
          })
          .withMessage(resourceSet + "|InputDTOInvalidProfileId");
      }

      //Email
      this.ruleFor("email")
        .maxLength(450)
        .withMessage(resourceSet + "|InputDTOInvalidEmailLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmail")
        .must((value) => {
          if (value) {
            if (!TPGlobal.regularExpressionCheckEmail.test(value)) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmail");

      //Team
      this.ruleFor("teamId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTeamLength")
        .must((value: any) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTeam");

      //FunctionId
      this.ruleFor("functionId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidFunctionIdLength")
        .must((value: any) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidFunctionId");

      //AreaId
      this.ruleFor("areaId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidAreaIdLength")
        .must((value: any) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAreaId");

      //Phone
      this.ruleFor("phone")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidPhoneLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidPhone");

      //Mobile
      this.ruleFor("mobile")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidMobileLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidMobile");

      //CalendarId
      this.ruleFor("calendarId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCalendarIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCalendarIdLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidCalendarId");

      //ID TimeZone
      this.ruleFor("timeZoneId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTimeZoneEmpty")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidTimeZoneLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTimeZone");

      //ID Schedule
      this.ruleFor("scheduleId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOScheduleIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidScheduleIdLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidScheduleId");

      //ID Typist
      this.ruleFor("typistIdGuid")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypistIdEmpty")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidTypistIdLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTypistId");

      //ID Authentication
      this.ruleFor("authenticationMethodId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationIdLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationId");

      //Microsoft Identity
      this.ruleFor("microsoftIdentity")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidMicrosoftIdentityLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidMicrosoftIdentity");

      //Default Language
      this.ruleFor("defaultLanguage")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODefaultLanguageEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidDefaultLanguageLength")
        .must((value) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDefaultLanguage");
    } catch (error) {}
  }
}
