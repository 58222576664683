import styled from "styled-components";

interface ColorStyleProperty {
    color?: string;
}

interface VisibleProperty {
    active?: boolean;
}

export const ContainerViewStyle = styled.div`
 display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: center;
  min-width:280px;
  width: 100%;
  height: 100%;
`;



export const FormViewStyle = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 99%;
  height: 100%;
`;

export const RowViewStyle = styled.div<ColorStyleProperty>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${(props) =>
        props.color != "" && props.color != undefined ? props.color : ""};
  width: 100%;
  height: 100%;
  gap: 5px;
`;


export const ChildStyle = styled.div<ColorStyleProperty>`
  width: 100%;
  min-height: 20px;
  border: 1px solid #989898;
  border-radius: 5px;

  background-color: ${(props) =>
        props.color != "" && props.color != undefined ? props.color : ""};

     @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const IcoAddStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const RequiredStyle = styled.div<VisibleProperty>`
    position: absolute;  
    border-radius: 2px 2px 0px 0px;
    top: 29px;
    left: 10px;
    padding-left: 2px;
    height: 20px;   
    padding-right: 2px;
    display: ${(props) => props.active != undefined && props.active ? "flex" : "none"};
`;

export const ControlStyle = styled.div`
  width: 99%;
  min-height: 90px;
  padding: 3px;
  position: relative;  
  padding: 10px;
`;