import styled from "styled-components";

export const AppContainerStyled = styled.div(
  ({ theme }) => `
  * {
    scrollbar-width: thin;
    scrollbar-color: #00000040 #ffffff;
    font-family: 'Noto Sans', sans-serif !important;
  }

  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #FFF;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #00000040;
    border-radius: 10px;
    border: 3px solid #FFF;
  }

  *::-webkit-scrollbar-track:hover {
    background: #00000005;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #00000080;
    border-radius: 10px;
    border: 3px solid #FFF;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  hr {
    background-color: #a5a5a5;
  }

  ul.dropdown-menu.show {
    background: #FFF;
    opacity: 0.9;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
  }

  ul.dropdown-menu.show > li {
    padding: 5px 24px;
  }

  ul.dropdown-menu.show > li a {
    color: #2e2e2e;
  }

  ul.dropdown-menu.show > li:hover a {
    background-color: #a00095;
    color: #FFF;
  }

  .tpReportTableComments {
    color: #a00095;
  }
  
  .customStep span span svg {
    color: #a00095;
  }
`,
);
