import React, { useEffect, ReactElement } from "react";
import { useParams, useNavigate } from "react-router-dom";

const AppTransfer = (): ReactElement => {
  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.setItem("tenantidhex", params.tenantidhex as string);
    window.sessionStorage.setItem("languageid", params.languageid as string);
    let s: string;
    s = params.previousurlhex as string;
    let r = [];
    for (let i = 0; i < s.length - 1; i += 2) {
      r.push(String.fromCharCode(parseInt(s.charAt(i) + s.charAt(i + 1), 16)));
    }
    let data = r.join("");
    window.sessionStorage.setItem("tenantSelectorURL", data);
    navigate("../../../start", { replace: true });
  }, []);

  return (
    <div>
      <React.Fragment>...</React.Fragment>
    </div>
  );
};

export default AppTransfer;
