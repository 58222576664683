import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";

//todo move from here
export interface TEmpAdditionalDataInformerViewModel {
  additionalDataId: string;
  value: string;
  templateId: string;
}

//todo move from here
export interface TEmpAdditionalDataCaseViewModel {
  additionalDataId: string;
  value: string;
  isMandatory: boolean;
}

export interface CasesInputDTO {
  useRPCMethod: boolean;
  clientId: number;
  subsidiaryId: number;
  subsidiaryOrganizationId: number;
  baseLevelId: string;
  classify1BranchId: string;
  classify2BranchId: string;
  classify3BranchId: string;
  classify4BranchId: string;
  classify5BranchId: string;
  comments: string;
  typistGuidUser: string;
  workflowTypeId: string;
  parentCaseId: number | null;
  isActive: boolean;
  isPrivate: boolean;
  isAnonymized: boolean;
  contactId: number | null;
  tEmpAdditionalDataCase: TEmpAdditionalDataCaseViewModel[];
  guidCase: string;
  caseType: string;
}

export class CasesInputDTOValidator extends Validator<CasesInputDTO> {
  constructor() {
    super();

    const resourceSet = "CasesInsertUpdateComponent";

    try {
      this.ruleFor("baseLevelId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBaseLevelIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidBaseLevelId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelIdLength");

      this.ruleFor("classify1BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify1BranchIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidClassify1BranchId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify1BranchIdLength");

      this.ruleFor("classify2BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify2BranchIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidClassify2BranchId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify2BranchIdLength");

      this.ruleFor("classify3BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify3BranchIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidClassify3BranchId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify3BranchIdLength");

      this.ruleFor("classify4BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify4BranchIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidClassify4BranchId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify4BranchIdLength");

      this.ruleFor("classify5BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify5BranchIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidClassify5BranchId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify5BranchIdLength");

      this.ruleFor("comments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCommentsEmpty")
        .maxLength(4999)
        .withMessage(resourceSet + "|InputDTOInvalidCommentsLength");

      this.ruleFor("workflowTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOWorkflowTypeIdEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidWorkflowTypeId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidWorkflowTypeIdLength");

      this.ruleFor("typistGuidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypistGuidUserEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidTypistGuidUser",
        )
        .maxLength(150)
        .withMessage(resourceSet + "|InputDTOInvalidTypistGuidUser");
    } catch (error) {}
  }
}
