import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { Menu } from "@mui/material";
import { ReactElement, useState } from "react";
import { MenuItemButton, StyledTableCell } from "./dynamic-table-styles";

export type MinorOption<T extends object> = {
  key: string;
  type: string;
  icon?: TPIconTypes;
  onOptionChange: (item: T) => void;
};

type TableActionsProps<T extends object> = {
  item: T;
  minorOptions: MinorOption<T>[];
  index: number;
  disableMinorOption?: boolean;
};

function TableActionItem<T extends object>({
  item,
  minorOptions,
  index,
  disableMinorOption = false,
}: TableActionsProps<T>): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSelectedItem, setMenuSelectedItem] = useState<number | null>(null);

  const open = Boolean(anchorEl);

  const handleOpenMoreOptions = (
    event: React.MouseEvent<HTMLElement>,
    item: number
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuSelectedItem(item);
  };

  const handleCloseMoreOptions = () => {
    setAnchorEl(null);
    setMenuSelectedItem(null);
  };

  const iconColorManager = () => {
    if (disableMinorOption) {
      return "lightgray";
    } else {
      return menuSelectedItem === index ? "#780096" : "#2e2e2e";
    }
  };

  return (
    <StyledTableCell key={`${index}-cell`} align="center">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TPIcon
          key={`${index}-icon`}
          id={`${index}-${TPIconTypes.more}-button`}
          iconType={TPIconTypes.more}
          style={{
            color: iconColorManager(),
            cursor: disableMinorOption ? "default" : "pointer",
          }}
          onClick={(e: any) =>
            !disableMinorOption && handleOpenMoreOptions(e, index)
          }
        ></TPIcon>
      </div>

      {menuSelectedItem === index && (
        <Menu
          key={`${index}-menu`}
          anchorEl={anchorEl}
          id="table-action-menu"
          open={open}
          onClose={handleCloseMoreOptions}
          onClick={handleCloseMoreOptions}
          PaperProps={{
            sx: {
              "& ul": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {minorOptions.map((option, i) => (
            <MenuItemButton
              key={`${i}-option`}
              id={`${option.type}-menu-item`}
              onClick={() => option.onOptionChange(item)}
            >
              {option.key}
              {option.icon && <TPIcon iconType={option.icon}></TPIcon>}
            </MenuItemButton>
          ))}
        </Menu>
      )}
    </StyledTableCell>
  );
}

export default TableActionItem;
