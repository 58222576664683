import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import React, {
  FC,
  ReactElement,
  useEffect,
  useReducer,
  useState,
} from "react";
import AdditionalDataRelateAdmin, {
  AdditionalDataRelatedTypesEnum,
} from "../AdditionalData/AdditionalDataRelateAdmin";
import TaskTypeInsertUpdate from "./TaskTypeInsertUpdate";
import { Box, Tab, Tabs } from "@mui/material";
import { StyledTab, StyledTabs } from "../LoadHistory/load-history-styles";
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { commandType as Action, commandsEnum as ActionType } from "../../layouts/VerticalTabs/VerticalTabsAdminContainer";

type TaskTypeContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: string;
  verticalTabDispatch?: (action: Action) => void;
};

enum asideMenuItemTypes {
  "general" = 0,
  "additionalData" = 1,
}
interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum commandsEnum {
  "create_general" = 0,
  "create_additional_data_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_additional_data" = 3,
  "remove_item_from_aside_menu" = 4,
  "add_Additional_data_item_for_aside_menu",
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const TaskTypeContainerInsertUpdate: FC<TaskTypeContainerInsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  tabId,
  verticalTabDispatch,
}): ReactElement => {
  //control state
  const resourceSet: string = "TaskTypeInsertUpdateComponent";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [activeMenu, setActiveMenu] = useState(false);
  const [titleAdditionalData, setTitleAdditionalData] = useState("");
  const [titleLabel, setTitleLabel] = useState("");
  const [exitLabel, setExitLabel] = useState("Exit");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [tabValue, setTabValue] = useState(0);
  
  const loadResources = async () => {
    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle"
      )
    );
    setTitleAdditionalData(
      await TPI18N.GetText(resourceSet, "TitleAdditionalDataLabel")
    );
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    setTitleLabel(
      await TPI18N.GetText(
        resourceSet,
        `Title${mode[0].toUpperCase()}${mode.slice(1).toLowerCase()}Label`
      )
    );
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    switch (theCommand.result) {
      case "save_general_insert_and_change_mode":
        if (verticalTabDispatch) {
            verticalTabDispatch({ type: ActionType.reloadGrid });
            verticalTabDispatch({ type: ActionType.vertical_tab_close, payload: tabId });
            verticalTabDispatch({ type: ActionType.update_vertical_tab, payload: { recordId: theCommand.recordId } });
        }
        return;
      case "remove_item_from_aside_menu":
        let commandRemove: commandType = {
          type: commandsEnum.remove_item_from_aside_menu,
          payload: { itemLabel: theCommand.itemLabel },
        };
        dispatchCommand(commandRemove);
        return;
      case "remove_additional_data_item_from_aside_menu":
        dispatchCommand({
          type: commandsEnum.remove_item_from_aside_menu,
          payload: { itemLabel: titleAdditionalData },
        } as commandType);
        return;
      case "save_general_insert":
        callBackResult({ result: "OK", recordId: "" });
        return;
      case "save_general_update":
        if (verticalTabDispatch) {
            verticalTabDispatch({ type: ActionType.reloadGrid });
            verticalTabDispatch({ type: ActionType.vertical_tab_close, payload: tabId });
            verticalTabDispatch({ type: ActionType.update_vertical_tab, payload: { recordId: theCommand.recordId } });
        }
        return;
      case "cancel_general":
        callBackResult({ result: "CANCEL", recordId: recordId });
        return;
      case "add_additional_data_item_for_aside_menu":
        let commandAddItem: commandType = {
          type: commandsEnum.add_Additional_data_item_for_aside_menu,
          payload: null,
        };
        dispatchCommand(commandAddItem);
        return;
      default:
        break;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_additional_data,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "CANCEL", recordId: realRecordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode == "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleAdditionalData,
        itemType: asideMenuItemTypes.additionalData,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommandAndChangeMode = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleAdditionalData,
      itemType: asideMenuItemTypes.additionalData,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  const realRemoveItemFromAsideMenu = (
    prevState: Array<asideMenuItemsModel>,
    itemLabel: string
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    newStateHome = newStateHome.filter((item) => item.itemLabel !== itemLabel);
    setActiveMenu(true);
    return newStateHome;
  };

  const realAddAdditionalDataItemForAsideMenu = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let found = false;
    for (let i = 0; i < newStateHome.length; i++) {
      const element = newStateHome[i];
      if (element.itemType === asideMenuItemTypes.additionalData) {
        found = true;
        break;
      }
    }
    if (!found) {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleAdditionalData,
        itemType: asideMenuItemTypes.additionalData,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }

    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_additional_data_and_change_mode:
        let newRecordId: string;
        newRecordId = command.payload.recordId;
        setRealRecordId(newRecordId);
        return realCreateDetailsContentCommandAndChangeMode(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_additional_data:
        return setActiveContent(prevState, asideMenuItemTypes.additionalData);
      case commandsEnum.remove_item_from_aside_menu:
        return realRemoveItemFromAsideMenu(
          prevState,
          command.payload.itemLabel
        );
      case commandsEnum.add_Additional_data_item_for_aside_menu:
        return realAddAdditionalDataItemForAsideMenu(prevState);
    }
  }

  //handler to receive commands from child tabs
  const handleCallBackCommandAdditionalDataRelated = (theCommand: any) => {
    // if (theCommand.result === "save_general_insert") {
    //   //create details content
    //   let command: commandType = {
    //     type: commandsEnum.create_holidays,
    //     payload: null,
    //   };
    //   dispatchCommand(command);
    //   return;
    // }
    // if (theCommand.result === "save_general_update") {
    //   return;
    // }
    // if (theCommand.result === "cancel_general") {
    //   callBackResult({ result: "CANCEL", recordId: recordId });
    //   return;
    // }
  };
  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  return (
    <div
      style={{
        width: "99%",
        height: "calc(100vh - 260px)",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <TPPageTitle style={{ margin: "10px 0 30px" }}>{titleLabel}</TPPageTitle>
      {/* {mode == "Update" ? (
        <TPPageSubTitle>{`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}</TPPageSubTitle>
      ) : null} */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(_e, value) => setTabValue(value)}
          aria-label="history load tabs"
          sx={StyledTabs}
        >
          {asideMenuState.map((tab, index) => (
            <Tab
              id={`Tab${index}`}
              key={`Tab${index}`}
              label={tab.itemLabel}
              sx={{ ...StyledTab, alignItems: "center" }}
              onClick={() => {
                dispatchCommand({
                  type:
                    tab.itemType === asideMenuItemTypes.general
                      ? commandsEnum.set_active_general
                      : commandsEnum.set_active_additional_data,
                  payload: null,
                });
              }}
              disableRipple
            />
          ))}
        </Tabs>
      </Box>

      <div
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          overflowY: "auto",
          paddingTop: "20px",
        }}
      >
        {asideMenuState.map((item, index) => {
          let jsxFragment: any;
          let visibilityStyle: any;
          if (item.isActive) {
            visibilityStyle = { display: "block" };
          } else {
            visibilityStyle = { display: "none" };
          }
          switch (item.itemType) {
            case asideMenuItemTypes.general:
              jsxFragment = (
                <div style={visibilityStyle}>
                  <TaskTypeInsertUpdate
                    callBackResult={handleCallBackCommands}
                    mode={mode}
                    recordId={realRecordId}
                  />
                </div>
              );
              break;
            case asideMenuItemTypes.additionalData:
              jsxFragment = (
                <div style={visibilityStyle}>
                  <AdditionalDataRelateAdmin
                    type={AdditionalDataRelatedTypesEnum.taskType}
                    inputRecord={recordId}
                    callBackCommands={
                      handleCallBackCommandAdditionalDataRelated
                    }
                  />
                </div>
              );
              break;
            default:
              jsxFragment = (
                <div style={visibilityStyle}>
                  <span>error this should not be here</span>
                </div>
              );
              break;
          }
          return jsxFragment;
        })}
      </div>
    </div>
  );
};

export default TaskTypeContainerInsertUpdate;
