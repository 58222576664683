import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ContactViewModel } from "@/models/Contacts/ContactViewModel";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ContactsService } from "@/services/ContactsService";
import { FC, useEffect, useState } from "react";
import "../../styles/Styles.css";
import useLabels from "../../styles/labels";

interface CustomerInfoContactsTab {
  customerId: number;
  subsidiaryId: number;
}

const CustomerInfoContactsTab: FC<CustomerInfoContactsTab> = function ({
  customerId,
  subsidiaryId,
}) {
  const { labels, labelsLoaded } = useLabels();
  const [loading, setLoading] = useState<boolean>(false);

  const [customerContacts, setCustomerContacts] = useState<ContactViewModel[]>(
    [],
  );
  const [query, setQuery] = useState<string>("");
  const [filteredContacts, setFilteredContacts] = useState<ContactViewModel[]>(
    [],
  );
  const loadContacts = function () {
    setLoading(true);
    const contactServiceInstance = new ContactsService();
    contactServiceInstance
      .getCustomerContacts(customerId, subsidiaryId, false, false, [200, 404])
      .then((response) => {
        console.log(response);
        if (response) setCustomerContacts(response);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (customerId && subsidiaryId) loadContacts();
  }, [customerId, subsidiaryId]);
  useEffect(() => {
    if (customerContacts && customerContacts.length > 0)
      setFilteredContacts(customerContacts.map((contact) => contact));
  }, [customerContacts]);
  useEffect(() => {
    setFilteredContacts([
      ...customerContacts.filter((contact) => {
        return (
          contact.address.includes(query) ||
          String(contact.contactId).includes(query) ||
          String(contact.phone).includes(query) ||
          String(contact.email).includes(query) ||
          String(contact.comments).includes(query)
        );
      }),
    ]);
  }, [query]);
  return (
    <>
      <TPLoadingOverlay active={loading}>
        <div id="merge-customers-contacts-data-table">
          <DynamicTable
            data={filteredContacts.map(item => {
              return {
                contactId: item.contactId,
                phone: item.phone,
                email: item.email,
                address: item.address,
                comments: item.comments
              } as ContactViewModel
            })}
            hiddenColumns={["name","relationshipId","subsidiaryId","tpguidrow","tpselectedRow","clientId"]}
            columnNames={{
              contactId: labels.ContactId?.toUpperCase(),
              phone: labels.preferredPhone?.toUpperCase(),
              email: labels.preferredEmail?.toUpperCase(),
              address: labels.preferredAddress?.toUpperCase(),
              comments: labels.Comments?.toUpperCase()
            }}
            columnStyles={{
              contactId: (entry) => (<div>{entry.value || ''}</div>),
              phone: (entry) => (<div>{entry.value || ''}</div>),
              email: (entry) => (<div>{entry.value || ''}</div>),
              address: (entry) => (<div>{entry.value || ''}</div>),
              comments: (entry) => (<div>{entry.value || ''}</div>),
            }}
            onIconClicked={(icon) => {
              if (icon ==TPIconTypes.loop || icon == TPIconTypes.refresh) loadContacts();
            }}
          />
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default CustomerInfoContactsTab;
