import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { CustomerTypeInputDTO } from "@/models/CustomerType/CustomerTypeInputDTO";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";

export class CustomerTypeService {
  serviceFileName: string = "CustomerTypeService.ts";
  public async getCustomerTypeByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CustomerTypeViewModel>> {
    const httpclient = new TPHTTPService();
    const url: string = `/customertype?FilterIsActive=${filterActive}`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getCustomerTypeByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getCustomerTypeByFilter`);
      throw new Error(`Error ${this.serviceFileName} getCustomerTypeByFilter`);
    }
  }

  public async getCustomerTypeById(
    customerTypeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<CustomerTypeViewModel> {
    const httpclient = new TPHTTPService();
    const url: string = `/customertype/${customerTypeId}`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getCustomerTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getCustomerTypeById`);
      throw new Error(`Error ${this.serviceFileName} getCustomerTypeById`);
    }
  }

  public async deleteCustomerTypeById(
    customerTypeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/customertype/" + customerTypeId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteCustomerTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteCustomerTypeById`);
      throw new Error(`Error ${this.serviceFileName} deleteCustomerTypeById`);
    }
  }

  public async insertCustomerType(
    intpuDTO: CustomerTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/customertype";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertCustomerType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertCustomerType`);
      throw new Error(`Error ${this.serviceFileName} insertCustomerType`);
    }
  }

  public async updateCustomerType(
    intpuDTO: CustomerTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/customertype";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateCustomerType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateCustomerType`);
      throw `Error ${this.serviceFileName} updateCustomerType`;
    }
  }
}
