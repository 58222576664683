import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import AnimatedNumber from "@/modules/core/design-system/animations/AnimatedNumber";
import React, { FC, ReactElement } from "react";
import {
  StyledInformationCard,
  StyledSmallInformationCard,
} from "../supervisor-cockpit-styles";
import {
  InfoCardColor,
  InfoCardTypes
} from "../supervisor-cockpit.model";

type CockpitInfoCardProps = {
  type: InfoCardTypes;
  label: string;
  value?: number;
  customStyle?: InfoCardColor;
  isSmallCard?: boolean;
};

type BaseColors = {
  [K in InfoCardTypes]: InfoCardColor;
};

const CockpitInfoCard: FC<CockpitInfoCardProps> = React.memo(
  ({ type, label, value, customStyle, isSmallCard }): ReactElement => {
    const baseColors: BaseColors = {
      offline: {
        color: "#989898",
        variant: "#E6E6E6",
      },
      working: {
        color: "#780096",
        variant: "#F4E0FE",
      },
      online: {
        color: "#3047B0",
        variant: "#D3EAFF",
      },
      ready: {
        color: "#009A58",
        variant: "#B1F2D7",
      },
      worked: {
        color: "#E16403",
        variant: "#FFE4B3",
      },
      case: {
        color: "#c30092",
        variant: "#fbebff"
      },
      event: {
        color: "#00826c",
        variant: "#e7fdf2"
      },
      chat: {
        color: "#E16403",
        variant: "#FFE4B3",
      },
      mail: {
        color: "#3047B0",
        variant: "#D3EAFF",
      },
      expired: {
        color: "#eb0027",
        variant: "#ffd7dd"
      },
      pending: {
        color: "#E16403",
        variant: "#FFE4B3",
      },
      inProgress: {
        color: "#009A58",
        variant: "#B1F2D7",
      },
      processed: {
        color: "#009A58",
        variant: "#B1F2D7",
      },
    };

    return (
      <>
        {isSmallCard ? (
          <StyledSmallInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="form"></div>
            <p className="value">{label}</p>
          </StyledSmallInformationCard>
        ) : (
          <StyledInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="card-info">
              <p className="label">{label}</p>
              <p className="value">
                <AnimatedNumber targetNumber={value ?? 0} />
              </p>
            </div>
            <div className="card-icon">
              <TPIcon iconType={type as TPIconTypes} />
            </div>
          </StyledInformationCard>
        )}
      </>
    );
  }
);

export default CockpitInfoCard;
