import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { CasesFollowUpInputDTO } from "@/models/FollowUp/CasesFollowUpInputDTO";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReportDetailInputDTO } from "@/models/Reports/ReportDetailInputDTO";
import { FollowUpService } from "@/services/FollowUpService";
import { TPI18N } from "@/services/I18nService";
import { ReportsService } from "@/services/ReportsService";
import React, { useEffect, useState, useReducer } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

enum commandsEnum {
  "change_followupcounters" = 0,
}

export enum realFoldersTypeEnum {
  "PendingCases" = 0,
  "PendingRead" = 1,
  "InterestCases" = 2,
  "TypedCases" = 3,
  "ResponsibilitiesCases" = 4,
  "MyTeamCases" = 5,
  "MyTeamOnlyUserCases" = 6,
  "Reminders" = 7,
  "TeamReminders" = 8,
  "ALL" = 9,
}

type AdminStateType = {
  //counters
  allCounter: number;
  myTaskCounter: number;
  myTeamsCounter: number;
  myFavoritesCounter: number;
  myTypedCasesCounter: number;
  myRespobilitityCounter: number;
};

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum folderTypesEnum {
  "ALL" = "ALL",
  "MYTASKS" = "MYTASKS",
  "MYTEAM" = "MYTEAM",
  "FAVORITES" = "FAVORITES",
  "TYPED" = "TYPED",
  "RESPONSIBLE" = "RESPONSIBLE",
}

export enum orderColumnsEnum {
  "BASELEVEL" = "S_BALEIDORDEREDCOL",
  "CASENUMBER" = "S_CASEIDORDEREDCOL",
  "CLOSEDATE" = "S_CDATEORDEREDCOL",
  "CLASSIFY1" = "S_CLASSID1ORDEREDCOL",
  "CLASSIFY2" = "S_CLASSID2ORDEREDCOL",
  "CLASSIFY3" = "S_CLASSID3ORDEREDCOL",
  "CLASSIFY4" = "S_CLASSID4ORDEREDCOL",
  "CLASSIFY5" = "S_CLASSID5ORDEREDCOL",
  "COMMENTS" = "S_COMMENTSORDEREDCOL",
  "CASESTATUS" = "S_CSSTIDORDEREDCOL",
  "CUSTOMERTYPE" = "S_CUTYIDORDEREDCOL",
  "INSERTDATE" = "S_INSDATEORDEREDCOL",
  "LASTMODIFYDATE" = "S_LMDATEORDEREDCOL",
  "PROMISEDATE" = "S_CPROMIORDEREDCOL",
  "CUSTOMERNAME" = "S_CLIORDEREDCOL",
  "RESPONSIBLE" = "S_CASERESPORDEREDCOL",
}

const DashBoard = () => {
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const componentFileName: string = "Dashboard.tsx";
  //Screen resources
  const resourceSet: string = "DashboardComponent";
  const resourceSetMyTask: string = "MyTasksComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [last15Days, setLast15Days] = useState("");
  const [followUpStatus, setFollowUpStatus] = useState("");
  const [data1, setData1] = useState<Array<any>>([]);

  const [allFolder, setAllFolder] = useState("");
  const [myTaskFolder, setMyTaskFolder] = useState("");
  const [myTeamsFolder, setMyTeamsFolder] = useState("");
  const [favoritesFolder, setFavoritesFolder] = useState("");
  const [myTypedCasesFolder, setMyTypedCasesFolder] = useState("");
  const [responsibleFolder, setResponsibleFolder] = useState("");

  const sleep = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const loadResources = async () => {
    let newData1: Array<any> = [];
    let newData2: Array<any> = [];
    let inputDTO: ReportDetailInputDTO;
    let counters: any;
    let foundUser: boolean = false;

    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setLast15Days(await TPI18N.GetText(resourceSet, "Last15Days"));
    setFollowUpStatus(await TPI18N.GetText(resourceSet, "FollowUpStatus"));

    setAllFolder(await TPI18N.GetText(resourceSetMyTask, "AllFolder"));
    setMyTaskFolder(await TPI18N.GetText(resourceSetMyTask, "MyTaskFolder"));
    setMyTeamsFolder(await TPI18N.GetText(resourceSetMyTask, "MyTeamsFolder"));
    setFavoritesFolder(
      await TPI18N.GetText(resourceSetMyTask, "FavoritesFolder"),
    );
    setMyTypedCasesFolder(
      await TPI18N.GetText(resourceSetMyTask, "MyTypedCasesFolder"),
    );
    setResponsibleFolder(
      await TPI18N.GetText(resourceSetMyTask, "ResponsibleFolder"),
    );

    for (let i: number = 0; i <= 20; i++) {
      await sleep(1000);
      if (TPGlobal.currentUserGuid) {
        foundUser = true;
        break;
      }
    }

    inputDTO = {
      reportId: "REPODASHLAST15DAYS",
      userGuid: TPGlobal.currentUserGuid,
      startRecord: 1,
      endRecord: 2,
      startDate: "2022-12-22T05:00:00.000",
      endDate: "2022-12-22T06:00:00.000",
      dateType: 0,
      parameter1: "",
      parameter2: "",
      tempReportFilter: [],
    };

    newData1 = await getLast15DaysData(inputDTO);

    if (newData1 && newData1.length >= 1) {
      //extract substring col2Value and col3Value
      for (let i: number = 0; i <= newData1.length - 1; i++) {
        newData2.push({
          date: newData1[i]["col2Value"].substring(0, 10),
          count: newData1[i]["col3Value"],
        });
      }
    }

    setData1(newData2);

    //counters
    counters = await calcFoldersCounter();
    let command1: commandType = {
      type: commandsEnum.change_followupcounters,
      payload: {
        newCounters: counters,
      },
    };
    dispatchCommand(command1);

    setIsLoadingScreen(false);
  };

  const getLast15DaysData = async (inputDTO: ReportDetailInputDTO) => {
    let serviceClient = new ReportsService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await serviceClient.getReportData(
        inputDTO,
        false,
        false,
        expectedCodes,
      );

      if (
        responseRequest.responseData.data &&
        responseRequest.responseData.data.length >= 1
      ) {
        return [...responseRequest.responseData.data[0].reportDetail];
      } else {
        return [];
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getLast15DaysData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getLast15DaysData ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const reloadGridData = async (
    folder: folderTypesEnum,
    startRecord: number,
    endRecord: number,
    orderedColumn: orderColumnsEnum,
    orderDirection: string,
  ) => {
    let serviceClient = new FollowUpService();
    let expectedCodes: Array<number> = [200, 404];
    let realFolder: realFoldersTypeEnum;
    let inputDTO: CasesFollowUpInputDTO;

    try {
      setIsLoadingScreen(true);
      switch (folder) {
        case folderTypesEnum.FAVORITES:
          realFolder = realFoldersTypeEnum.InterestCases;
          break;
        case folderTypesEnum.MYTASKS:
          realFolder = realFoldersTypeEnum.PendingCases;
          break;
        case folderTypesEnum.MYTEAM:
          realFolder = realFoldersTypeEnum.MyTeamCases;
          break;
        case folderTypesEnum.TYPED:
          realFolder = realFoldersTypeEnum.TypedCases;
          break;
        case folderTypesEnum.RESPONSIBLE:
          realFolder = realFoldersTypeEnum.ResponsibilitiesCases;
          break;
        case folderTypesEnum.ALL:
          realFolder = realFoldersTypeEnum.ALL;
          break;
      }
      inputDTO = {
        followUpType: realFolder,
        userGuid: TPGlobal.currentUserGuid,
        startRecord: startRecord,
        endRecord: endRecord,
        orderedColumn: orderedColumn,
        orderDirection: orderDirection,
        tempTaskSearchFilter: [],
      };
      //todo

      let responseRequest =
        await serviceClient.getFollowUpCasesByFolderAndFilter(
          inputDTO,
          false,
          false,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const calcFoldersCounter = async () => {
    let newGridData: Array<any> | null = [];
    let newAllCounter: number;
    let newMyTaskCounter: number;
    let newMyTeamsCounter: number;
    let newMyFavoritesCounter: number;
    let newMyTypedCasesCounter: number;
    let newMyRespobilitityCounter: number;

    newAllCounter = 0;
    newMyTaskCounter = 0;
    newMyTeamsCounter = 0;
    newMyFavoritesCounter = 0;
    newMyTypedCasesCounter = 0;
    newMyRespobilitityCounter = 0;

    //all
    newGridData = await reloadGridData(
      folderTypesEnum.ALL,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newAllCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //my task
    newGridData = await reloadGridData(
      folderTypesEnum.MYTASKS,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTaskCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //my team
    newGridData = await reloadGridData(
      folderTypesEnum.MYTEAM,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTeamsCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //favorites
    newGridData = await reloadGridData(
      folderTypesEnum.FAVORITES,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyFavoritesCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //typed cases
    newGridData = await reloadGridData(
      folderTypesEnum.TYPED,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTypedCasesCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //responsabilities
    newGridData = await reloadGridData(
      folderTypesEnum.RESPONSIBLE,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyRespobilitityCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    return {
      newAllCounter: newAllCounter,
      newMyTaskCounter: newMyTaskCounter,
      newMyTeamsCounter: newMyTeamsCounter,
      newMyFavoritesCounter: newMyFavoritesCounter,
      newMyTypedCasesCounter: newMyTypedCasesCounter,
      newMyRespobilitityCounter: newMyRespobilitityCounter,
    };
  };

  //reducer
  const initialStateBLL: AdminStateType = {
    allCounter: 0,
    myTaskCounter: 0,
    myTeamsCounter: 0,
    myFavoritesCounter: 0,
    myTypedCasesCounter: 0,
    myRespobilitityCounter: 0,
  };

  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    let newAdminState: AdminStateType;
    switch (command.type) {
      case commandsEnum.change_followupcounters:
        newAdminState = { ...prevState };
        //counters
        newAdminState.allCounter = command.payload.newCounters.newAllCounter;
        newAdminState.myTaskCounter =
          command.payload.newCounters.newMyTaskCounter;
        newAdminState.myTeamsCounter =
          command.payload.newCounters.newMyTeamsCounter;
        newAdminState.myFavoritesCounter =
          command.payload.newCounters.newMyFavoritesCounter;
        newAdminState.myTypedCasesCounter =
          command.payload.newCounters.newMyTypedCasesCounter;
        newAdminState.myRespobilitityCounter =
          command.payload.newCounters.newMyRespobilitityCounter;
        return newAdminState;
      default:
        newAdminState = { ...prevState };
        return newAdminState;
    }
  }

  //run only once
  useEffect(() => {
    loadResources();
  }, []);

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="tpDashBoard-container">
            <div className="row">
              <div className="col-10">
                <div className="tpColumnsTaskContainer">
                  <TPPageTitle>{last15Days}</TPPageTitle>
                  <ResponsiveContainer width={"100%"} height={350}>
                    <BarChart data={data1}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis dataKey="count" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#a00095" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-2">
                <div className="tpColumnsTaskContainer">
                  <TPPageTitle>{followUpStatus}</TPPageTitle>
                  <div className="tpFollowUpFolders">
                    {/* folders zone */}
                    <ul>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.task} />
                          <span className={"tptasksfoldertitle"}>
                            {myTaskFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.myTaskCounter}
                        </span>
                      </li>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.teams} />
                          <span className={"tptasksfoldertitle"}>
                            {myTeamsFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.myTeamsCounter}
                        </span>
                      </li>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.favorite} />
                          <span className={"tptasksfoldertitle"}>
                            {favoritesFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.myFavoritesCounter}
                        </span>
                      </li>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.keyboard} />
                          <span className={"tptasksfoldertitle"}>
                            {myTypedCasesFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.myTypedCasesCounter}
                        </span>
                      </li>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.personSearch} />
                          <span className={"tptasksfoldertitle"}>
                            {responsibleFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.myRespobilitityCounter}
                        </span>
                      </li>
                      <li>
                        <span className={"tpaligncenter"}>
                          <TPIcon iconType={TPIconTypes.all} />
                          <span className={"tptasksfoldertitle"}>
                            {allFolder}
                          </span>
                        </span>
                        <span
                          className={
                            "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
                          }
                        >
                          {adminState.allCounter}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

export default DashBoard;
