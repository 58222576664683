import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { FC } from "react";
import "../Assets/styles.css";

interface AdditionalDataOptionProperties {
  key: any;
  addData: AdditionalDataViewModel;
  backgroundColor?: string;
  onClick?: (addData: AdditionalDataViewModel) => void;
}

const maxCharacterDisplay = 30;

export const AdditionalDataOption: FC<AdditionalDataOptionProperties> = function ({ key, addData, onClick }) {
  return (
    <button
      type="button"
      id={addData.id}
      className="add-data-option"
      key={key}
      onClick={() => onClick && onClick(addData)}
    >
      <div style={{ width: '70%', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <label>
          {addData.description.length > maxCharacterDisplay ?
            String(addData.description.slice(0, maxCharacterDisplay)).concat('...')
            :
            addData.description}
        </label>
        <label style={{ fontSize: '10pt' }}>{addData.id}</label>
      </div>
      <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#780096', whiteSpace: 'normal' }}>
        <b>{addData.additionalDataTypeDescription}</b>
      </div>
    </button>
  )
}