import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import {
  HolidayInputDTO,
  HolidayInputDTOValidator,
} from "@/models/Calendars/Holidays/HolidayInputDTO";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { HolidayService } from "@/services/HolidayService";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

type InsertUpdateProps = {
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  calendarId: string;
  idHoliday: string;
  recordLanguageList: Array<TPKeyValue>;
  //TODO change date
  holidayDate: Date;
  isHalfHoliday: boolean;
  //validator
  calendarIdErrorMessage: string;
  holidayDateErrorMessage: string;
  descriptionErrorMessages: Array<string>;
};

const HolidaysInsertUpdate: FC<InsertUpdateProps> = ({
  recordId,
  callBackResult,
}) => {
  const componentFileName: string = "HolidaysInsertUpdate.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "HolidayInsertUpdateComponent";
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [holidayDateLabel, setHolidayDateLabel] = useState("");
  const [isHalfHolidayLabel, setIsHalfHolidayLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    calendarId: recordId,
    idHoliday: "-1",
    recordLanguageList: [],
    holidayDate: new Date(),
    isHalfHoliday: false,
    holidayDateErrorMessage: "",
    descriptionErrorMessages: initialErrorMessages,
    calendarIdErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //#endregion

  const loadResources = async () => {
    let i: number;
    //resources state
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setIsHalfHolidayLabel(
      await TPI18N.GetText(resourceSet, "IsHalfHolidayLabel"),
    );
    setHolidayDateLabel(await TPI18N.GetText(resourceSet, "HolidayDateLabel"));

    //screen state

    let newInsertUpdateState = { ...insertUpdateState };
    for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
      let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
      let keyValueElement: TPKeyValue = { key: item.id, value: "" };
      newInsertUpdateState.recordLanguageList.push(keyValueElement);
    }
    setInsertUpdateState(newInsertUpdateState);
    setIsLoadingScreen(false);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleHolidayDateChange = (newHolidayDate: Date) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.holidayDate = newHolidayDate;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsHalfHolidayChange = () => {
    setInsertUpdateState({
      ...insertUpdateState,
      isHalfHoliday: !insertUpdateState.isHalfHoliday,
    });
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;
    let recordInputDTO: HolidayInputDTO = {
      calendarId: insertUpdateState.calendarId,
      holiday: insertUpdateState.holidayDate,
      isHalfHoliday: insertUpdateState.isHalfHoliday,
      description: insertUpdateState.recordLanguageList[0].value,
      otherLocalizedValues: [],
    };
    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new HolidayInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.calendarId) {
        newInsertUpdateState.calendarIdErrorMessage = await TPI18N.GetResource(
          resultValidator.calendarId,
        );
      } else {
        newInsertUpdateState.calendarIdErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }
      if (resultValidator.otherLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    await insertHoliday(recordInputDTO);
  };

  const insertHoliday = async (inputDTO: HolidayInputDTO) => {
    let serviceClient = new HolidayService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertHoliday(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertHoliday ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertHoliday ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResources();
  }, []);

  return (
    //#region  Render

    <TPLoadingOverlay active={isLoadingScreen}>
      <div className="row">
        <div className="col-11">
          <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
        </div>
      </div>

      <div className="row">
        <div className="col-11">
          <TPPageSection>
            <div className="row">
              {insertUpdateState.recordLanguageList.length > 0 &&
                TPGlobal.TPClientAvailableLanguages.map(
                  (item, index) =>
                    index === 0 && (
                      <div className="col-11" key={`languageItem-${item.id}`}>
                        <div className="form-group">
                          <TPTextBox
                            id="IdTextBox"
                            isMandatory={index === 0}
                            labelText={`${descriptionLabel} (${item.name})`}
                            value={
                              insertUpdateState.recordLanguageList[index].value
                            }
                            onChange={(e: any) =>
                              handleLanguageChange(index, e.target.value)
                            }
                            maxLength={100}
                            errorMessage={
                              insertUpdateState.descriptionErrorMessages[index]
                            }
                          />
                        </div>
                      </div>
                    ),
                )}
              {insertUpdateState.recordLanguageList.length > 1 && (
                <>
                  <div className="col-1">
                    <div className="pt-4">
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        icon={TPIconTypes.language}
                        text={`+${
                          insertUpdateState.recordLanguageList.length - 1
                        }`}
                        tooltip={languageListLabel}
                        className={"pt-3"}
                        onClick={handleOpenModalLanguageList}
                      />
                    </div>
                  </div>
                  <TPModalLanguageList
                    id="IdModalLanguageList"
                    isOpen={isOpenModalLanguageList}
                    title={languageListLabel}
                    acceptLabel={saveButtonLabel}
                    cancelLabel={cancelButtonLabel}
                    saveChanges={handleSaveChangesModalLanguageList}
                    closeModal={handleCloseModalLanguageList}
                  >
                    <div
                      className="row overflow-auto"
                      style={{ height: "200px" }}
                    >
                      {TPGlobal.TPClientAvailableLanguages.map(
                        (item, index) =>
                          index > 0 && (
                            <div
                              className="col-12"
                              key={`languageItem-${item.id}`}
                            >
                              <div className="form-group">
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={index === 0}
                                  labelText={`${descriptionLabel} (${item.name})`}
                                  value={
                                    insertUpdateState.recordLanguageList[index]
                                      .value
                                  }
                                  onChange={(e: any) =>
                                    handleLanguageChange(index, e.target.value)
                                  }
                                  maxLength={100}
                                  errorMessage={
                                    insertUpdateState.descriptionErrorMessages[
                                      index
                                    ]
                                  }
                                />
                              </div>
                            </div>
                          ),
                      )}
                    </div>
                  </TPModalLanguageList>
                </>
              )}
            </div>
          </TPPageSection>
        </div>
      </div>

      <div className="row">
        <div className="col-11">
          <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <TPPageSection>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <TPDatePicker
                    selectedDate={insertUpdateState.holidayDate}
                    onChangeDate={(e: any) => handleHolidayDateChange(e)}
                    labelText={holidayDateLabel}
                    isHorizontal={false}
                    isMandatory={true}
                  ></TPDatePicker>
                  {/* <TPTextBox
                labelText={holidayDateLabel}
                onChange={(e: any) => handleHolidayDateChange(e.target.value)}
                value={insertUpdateState.holidayDate}
              ></TPTextBox> */}
                </div>
              </div>
              <div className="col-8 mt-4">
                <div className="form-group">
                  <TPCheckBox
                    id="IdCheckBox"
                    labelText={isHalfHolidayLabel}
                    checked={insertUpdateState.isHalfHoliday}
                    onChange={(e: any) => handleIsHalfHolidayChange()}
                  ></TPCheckBox>
                </div>
              </div>
            </div>
          </TPPageSection>
        </div>
      </div>
      <div className="row"></div>
      <div className="row">
        <div className="col-11">
          <TPPageAcceptCancelButtonsContainer>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={handleOkButtonClick}
            >
              {saveButtonLabel}
            </TPButton>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.link}
              onClick={handleCancelButtonClick}
              className={"ms-2"}
            >
              {cancelButtonLabel}
            </TPButton>
          </TPPageAcceptCancelButtonsContainer>
        </div>
      </div>
    </TPLoadingOverlay>

    //#endregion
  );
};

export default HolidaysInsertUpdate;
