import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { TEmpAdditionalDataCaseViewModel } from "./CasesInputDTO";

export type CaseMassUpdateInputDTO = {
  actionApply: string;
};

export type AddTaskMasiveInputDTO = {
  CaseListToAddTask: Array<number>;
  TaskTypeId: string;
  Quantity: number;
  ResponsibleGuidUser: string;
  Term: number | null;
  TermUnitId: string | null;
  DueDate: string | null;
  Comments: string;
  ReminderDate: string | null;
  ReminderComments: string | null;
  TypistGuidUser: string;
};

export interface ReclassifyMassiveTasksInputDTO {
  caseListToReclassify: Array<number>;
  clientId: number;
  subsidiaryId: number;
  subsidiaryOrganizationId: number;
  baseLevelId: string;
  classify1BranchId: string;
  classify2BranchId: string;
  classify3BranchId: string;
  classify4BranchId: string;
  classify5BranchId: string;
  comments: string;
  reclassificationComments: string;
  reclassificationReasonId: string;
  reclassificationOptionId: string;
  reclassificationOptionSameWorkflowId: string;
  typistGuidUser: string;
  tEmpAdditionalDataCase: TEmpAdditionalDataCaseViewModel[];
  [key: string]: any;
}

export type InactiveCasesClosedMasiveInputDTO = {
  CaseListToInactivate: Array<number>;
};

type AdditionData = {
  AdditionalDataId: string;
  Value: string;
  IsMandatory: boolean;
};

export type CompletePendingTasksMasiveInputDTO = {
  Report: string;
  DecisionPath: string | null;
  EscalatedTaskReport: string | null;
  TypistGuidUser: string;
  TEmpAdditionalDataTask: Array<AdditionData>;
  TasksListToUpdate: Array<number>;
};

export class CaseMassUpdateInputDTOValidator extends Validator<CaseMassUpdateInputDTO> {
  /**
   *
   */
  constructor() {
    super();
    const resourceSet = "CaseMassUpdateInputDTOValidator";

    try {
      this.ruleFor("actionApply")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOActionApplyEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidActionApply")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidActionApply");
    } catch (error) {
      //TODO log
    }
  }
}

export class AddTaskMasiveInputDTOValidator extends Validator<AddTaskMasiveInputDTO> {
  /**
   *
   */
  constructor(termType: string, isReminder: boolean) {
    super();
    const resourceSet = "AddTaskInputDTOValidator";

    try {
      this.ruleFor("TaskTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTaskTaskTypeIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeIdLength");

      this.ruleFor("Quantity")
        .greaterThan(0)
        .withMessage(resourceSet + "|InputDTOInvalidQuantity");

      this.ruleFor("ResponsibleGuidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOResponsibleGuidUserEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidResponsibleGuidUser",
        )
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidResponsibleGuidUserLength");

      if (termType === "term") {
        this.ruleFor("Term")
          .greaterThan(0)
          .withMessage(resourceSet + "|InputDTOInvalidTerm")
          .when((model) => !!model.TermUnitId, "AppliesToCurrentValidator");

        this.ruleFor("TermUnitId")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOTaskTermUnitIdEmpty")
          .when(
            (model) => (model.Term ? model.Term : 0) > 0,
            "AppliesToCurrentValidator",
          )
          .must((id: any) => {
            if (!id) {
              return true;
            }
            let check = TPGlobal.checkSpecialCharacters(id);
            return check.result;
          })
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitId")
          .must((id: any) => {
            if (!id) {
              return true;
            }
            return TPGlobal.TPSanitize(id) == id;
          })
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitId")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitIdLength");
      } else {
        this.ruleFor("DueDate")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTODueDateIdEmpty")
          .when((model) => model.DueDate !== null);
      }

      this.ruleFor("Comments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCommentsEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments")
        .maxLength(4000)
        .withMessage(resourceSet + "|InputDTOInvalidCommentsLength");

      if (isReminder) {
        this.ruleFor("ReminderDate")
          .notNull()
          .withMessage(resourceSet + "|InputDTOReminderDateIdEmpty");
        this.ruleFor("ReminderComments")
          .must((id: any) => {
            if (!id) {
              return true;
            }
            return TPGlobal.TPSanitize(id) == id;
          })
          .withMessage(resourceSet + "|InputDTOInvalidComments")
          .maxLength(4000)
          .withMessage(resourceSet + "|InputDTOInvalidCommentsLength")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOTaskReminderCommentsEmpty");
      }
    } catch (error) {
      //TODO log
    }
  }
}

export class ReclassifyMassiveTasksInputDTOValidator extends Validator<ReclassifyMassiveTasksInputDTO> {
  /**
   *
   */
  constructor() {
    super();
    const resourceSet = "ReclassifyCaseComponent";

    try {
      this.ruleFor("baseLevelId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBaseLevelIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId");

      this.ruleFor("classify1BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify1BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify1BranchIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify1BranchId");

      this.ruleFor("classify2BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify2BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify2BranchIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify2BranchId");

      this.ruleFor("classify3BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify3BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify3BranchIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify3BranchId");

      this.ruleFor("classify4BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify4BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify4BranchIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify4BranchId");

      this.ruleFor("classify5BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify5BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify5BranchIdLength")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify5BranchId");

      this.ruleFor("comments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCommentsEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments");

      this.ruleFor("reclassificationComments")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationComments");

      this.ruleFor("reclassificationReasonId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReclassificationReasonIdEmpty")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationReasonIdLength",
        )
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationReasonId");

      this.ruleFor("reclassificationOptionId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReclassificationOptionIdEmpty")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionIdLength",
        )
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationOptionId");

      this.ruleFor("reclassificationOptionSameWorkflowId")
        .maxLength(20)
        .withMessage(
          resourceSet +
            "|InputDTOInvalidReclassificationOptionSameWorkflowIdLength",
        )
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionSameWorkflowId",
        );

      this.ruleFor("typistGuidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypistGuidUserEmpty")
        .maxLength(150)
        .withMessage(resourceSet + "|InputDTOInvalidTypistGuidUser")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionSameWorkflowId",
        );

      //TODO Validate additional Data
      // this.ruleForEach("tEmpAdditionalDataCase")
      // .must((x) => {
      //     if( x.additionalDataId.length > 20){
      //         return false;
      //     }
      //     return true;
      // }).
    } catch (error) {
      //TODO log
    }
  }
}

export class CompletePendingTaskMasiveInputDTOValidator extends Validator<CompletePendingTasksMasiveInputDTO> {
  /**
   *
   */
  constructor() {
    super();
    const resourceSet = "CaseMassUpdateInputDTOValidator";

    try {
      this.ruleFor("DecisionPath")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODecisionPathEmpty")
        .must((id: any) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDecisionPath")
        .maxLength(4000)
        .withMessage(resourceSet + "|InputDTOInvalidDecisionPathLength");

      this.ruleFor("Report")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReportEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReport")
        .maxLength(4000)
        .withMessage(resourceSet + "|InputDTOInvalidReportLength");
    } catch (error) {
      //TODO log
    }
  }
}
