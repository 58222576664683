import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { DistributionModel } from "@/models/DistributionListAdmin/DistributionListAdmin";
import { useReducer, useState } from "react";




enum commandsEnum {
    defaul,
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

const dataColumnTemp = [
    ['', '', '']
  ];

export const useStatusDistributionListAdmin = () => {
    const resourceSet: string = "DistributionListAdmin";
    const [changed, setChanged] = useState(0);
    const [statusModel, setStatusModel] = useState<Array<DistributionModel>>([]);
    const [hotdataList, setHotDataList] = useState<Array<Array<string>>>(dataColumnTemp);
    const [loading, setLoading] = useState(false);


    const reducerAction = (status = statusModel, action: { type: commandsEnum, payload: any }) => {

        switch (action.type) {
            
            case commandsEnum.defaul:
                if (status == undefined || status == null) status = Array<any>();
                var temp = [...status];
                temp.push(action.payload);
                return temp;
                break;
            default:
                return status;
        }
    }

    const [status, dispatch] = useReducer(reducerAction, new Array<any>);


    return {
        status,      
        resourceSet,
        hotdataList,
        setHotDataList,
        setLoading,
        loading,
        setChanged,
        changed
    };
}


