import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { addRecordFOViewModel, TeamViewViewModel, TicketsViewModel } from "@/models/ActiveTeamMembers/TeamViewViewModel";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import { ProjectModel } from "@/models/Project/Projects";
import { TeamActivityTickets } from "@/pages/SupervisorCockpit/ActiveTeamMembers/ActiveTeamMembersTickets";
import { A } from "@fullcalendar/core/internal-common";

export class ActiveTeamMembersService {
    serviceFileName = "ActiveTeamMembersService";

    public async getAllProjects(
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<ProjectModel>> {
        const { getData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `eventProject`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getAllQueues ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getAllQueues`);
        }
    }

    public async getAllQueues(
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<GroupsViewModel>> {
        const { getData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `groups/take-next-all`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getAllQueues ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getAllQueues`);
        }
    }

    public async getQueueByProjectId(
        eventProjectsId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<GroupsViewModel>> {
        const { getData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `eventProject/${eventProjectsId}/assignedgroups`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getQueueById ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getQueueById`);
        }
    }

    public async getTeamData(
        userGuid: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TeamViewViewModel>> {
        const { getData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/teamViewPeople/${userGuid}`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getGroupData(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TeamViewViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/groupViewPeople`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getEventData(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TeamViewViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/eventViewPeople`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getTeamTickets(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TicketsViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/teamViewTicket`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getGroupTickets(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TicketsViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/groupViewTicket`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getEventTickets(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TicketsViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/eventViewTicket`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async getUserTickets(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<TicketsViewModel>> {
        const { postData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `teamActivity/ticketByUser`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await postData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }

    public async reassignTicket(
        inputDTO: any,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        const { putData } = new TPHTTPService();
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let url = `cases/reassign-user-massive`;
        const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
        let response: HTTPResponse;
        try {
            response = await putData(
                finalURL,
                inputDTO,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            throw new Error(`Error ${this.serviceFileName} getTeamData`);
        }
    }
}