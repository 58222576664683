import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type BranchInputDTO = {
  id: string;
  description: string;
  parentId: string | null;
  descriptionLocalizedValues: Array<RecordLocalizedModel>;
  value: string;
  order: number;
  jsonParameters: string;
  helpCommentsLocalizedValues: Array<RecordLocalizedModel>;
  isActive: boolean;
  isVisible: boolean;
  treeId: string;
  helpComments: string;
};

export class BranchInputDTOValidator extends Validator<BranchInputDTO> {
  constructor() {
    super();

    const resourceSet = "BranchInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("descriptionLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 500;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("value")
        .must((value) => {
          if (value.trim() == "") {
            return true;
          } else {
            return TPGlobal.TPSanitize(value) === value;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidValue");

      this.ruleFor("jsonParameters")
        .must((jsonParameters) => {
          if (jsonParameters.trim() == "") {
            return true;
          } else {
            return TPGlobal.TPSanitize(jsonParameters) === jsonParameters;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidJsonParameters");

      // this.ruleFor("helpComments")
      //   .notEmpty()
      //   .withMessage(resourceSet + "|InputDTODescriptionEmpty")
      //   .must((name) => {
      //     return TPGlobal.TPSanitize(name) == name;
      //   })
      //   .withMessage(resourceSet + "|InputDTOInvalidHelpComments")
      //   .maxLength(500)
      //   .withMessage(
      //     resourceSet + "|InputDTOInvalidLocalizedCommentsValueLength"
      //   );

      this.ruleFor("helpComments")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidHelpComments")
        .maxLength(500)
        .withMessage(
          resourceSet + "|InputDTOInvalidLocalizedCommentsValueLength",
        );

      this.ruleFor("helpCommentsLocalizedValues")
        .must((x) => {
          let result: boolean = true;
          let i: number;
          for (i = 0; i <= x.length - 1; i++) {
            if (i == 0) {
              if (x[i].localizedValue.trim() != "") {
                result = true;
                break;
              }
            } else {
              if (
                x[0].localizedValue.trim() == "" &&
                x[i].localizedValue.trim() != ""
              ) {
                result = false;
                break;
              }
            }
          }
          return result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidHelpComments");

      this.ruleForEach("helpCommentsLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidHelpComments")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 500;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidLocalizedCommentsValueLength",
        );
    } catch (error) {}
  }
}

export type BranchChangeParentInputDTO = {
  treeId: string;
  currentBranchId: string;
  newParentId: string;
};

export interface AdditionalDataBranchInputDTO {
  branchId: string;
  additionalDataId: string;
  order: number;
  isMandatory: boolean;
}
