export const copyToClipboard = async function (text: string): Promise<void> {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
    .writeText(text)
    .catch((error) => {
      console.log(
        "Unexpected error while trying to copy to the clipboard: ",
        error,
      );
    });
  }
};
