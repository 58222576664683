import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { LocalizedValue } from "@/models/ListAdministration/ListAdministrationModels";
import { FC, useState } from "react";
import useListAdminLabels from "../assets/Labeling";

interface ListLocalizedNamesModalProperties {
  listLocalizedValues: LocalizedValue[];
  callBackAnswer: (accept: boolean, newItem: LocalizedValue[]) => void;
}

export const ListLocalizedNamesModal: FC<ListLocalizedNamesModalProperties> = function ({ callBackAnswer, listLocalizedValues }) {

  const { labels } = useListAdminLabels();
  const [localizedListNames, setLocalizedListNames] = useState<LocalizedValue[]>(structuredClone(listLocalizedValues));

  const handleListNameInput = function (languageId: string, input: string) {
    const newValues = [...localizedListNames];
    newValues.map((entry) => {
      if (entry.languageId == languageId) entry.localizedValue = input;
    })
    setLocalizedListNames(newValues);
  }

  return (
    <TPModal
      modalState={{
        acceptLabel: labels.Save,
        callBackAnswer:
          (accept: boolean) => callBackAnswer(accept, localizedListNames),
        cancelLabel: labels.Cancel,
        isShown: true,
        titleModal: labels.LanguageList
      }}
    >
      <div style={{display:'flex', flexDirection:'column', gap:'16px'}}>
        {localizedListNames.filter(lang => lang.languageId !== TPGlobal.language).map((language, index) => (
          <div>
            <TPTextBox
              key={index}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleListNameInput(language.languageId, event.target.value)}
              value={language.localizedValue}
              labelText={`${labels.ListName} (${language.languageName})`}
            />
          </div>
        ))}
      </div>
    </TPModal>
  )
}