import styled from "styled-components";

export const PopupContainerStyle = styled.div`
    width: 83vw;
    height: 90vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.22);
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PopupContainerChildreStyle = styled.div`
    overflow: auto;
    max-height: 500px;
    background-color: white;
    border-radius: 5px;
     position: relative;
     padding:20px;
`;

export const ContainerBodyStyle = styled.div`
    display: flex;
    flex-direction: column;  
    background-color: #f5f5f5;
    padding: 8px;
    width: 700px;
    border-radius: 2px;
`;
