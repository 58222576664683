import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailboxesInputDTO } from "@/models/InboundMailboxes/InboundMailboxesInputDTO";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import { InboundmailruledefinitionViewModel } from "@/models/InboundMailRule/InboundmailruledefinitionViewModel";
import { InboundMailRuleDefinition2DTO } from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";
import { InboundMailRuleDefinitionDTO } from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionDTO";

export class InboundMailboxesRulesService {
  serviceFileName: string = "InboundMailboxesRulesService.ts";

  public async getInboundMailboxesByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxesViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxesByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
      );
    }
  }

  public async getInboundMailRuleDefinitionById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<InboundmailruledefinitionViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionById  ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionById `,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionById `,
      );
    }
  }

  public async deleteInboundMailboxById(
    Id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition/" + Id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteInboundMailboxById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
      throw new Error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
    }
  }

  public async insertInboundMailRuleDefinition(
    inputDTO: InboundMailRuleDefinition2DTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} insertInboundMailRuleDefinition`,
      );
      throw new Error(
        `Error ${this.serviceFileName} insertInboundMailRuleDefinition`,
      );
    }
  }

  public async updateInboundMailRuleDefinition(
    inputDTO: InboundMailRuleDefinition2DTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateInboundMailRuleDefinition`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateInboundMailRuleDefinition`,
      );
    }
  }
}
