import { FC } from "react";
import "./Style.css";

interface Properties {
  onClick: () => any;
  checked: boolean;
  id?: string;
  label?: string;
}

export const TPRadio: FC<Properties> = function ({
  onClick,
  checked,
  id,
  label,
}) {
  return (
    <>
      <div id={id} className="tp-radio-form">
        <input
          type="radio"
          name="tp-radio"
          onClick={onClick}
          checked={checked}
        />
        <label className="tp-radio-form-label" htmlFor="tp-radio">
          {label}
        </label>
      </div>
    </>
  );
};
