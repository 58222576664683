import { FC } from "react";
import "../Assets/styles.css";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPChip } from "@/components/TPChip/TPChip";
import { ScriptAdditionalData, ScriptsMode } from "@/models/Scripts/ScriptModel";
import { useScriptsLabels } from "../Assets/labelling";

interface ScriptAdditionalDataItemProperties {
  key: string;
  data: string | ScriptAdditionalData[];
  defineAdditionalDataCallback: (data: ScriptAdditionalData | null) => void;
  onAdditionalDataDelete: (dataId: string) => void;
}

export const ScriptAdditionalDataStepTable: FC<ScriptAdditionalDataItemProperties> = function ({
  key,
  data,
  defineAdditionalDataCallback,
  onAdditionalDataDelete
}) {
  const { labels } = useScriptsLabels();
  return (
    <>
      <div className="step-additional-data-item" key={key}>
        <table style={{ width: '100%' }}>
          <tr style={{ backgroundColor: "white", borderBottom: "1px solid #BFBFBF" }}>
            <th style={{ fontSize: '11px', width: '5%' }}></th>
            <th style={{ fontSize: '11px', width: '15%', textAlign: 'start' }}>{labels.Order}</th>
            <th style={{ fontSize: '11px', width: '50%', textAlign: 'start' }}>{labels.Description}</th>
            <th style={{ fontSize: '11px', width: '20%', textAlign: 'center' }}>
              <div style={{
                display: 'flex',
                gap: '4px',
                width: '100%',
                padding: '6px',
                alignItems: 'center',
                textWrap: "nowrap",
                justifyContent: "center"
              }}
              >
                {labels.LogicDetermination}
              </div>
            </th>
            <th style={{ fontSize: '11px', width: '10%' }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <button
                  type="button"
                  className="step-additional-data-item-add-button"
                  onClick={() => defineAdditionalDataCallback(null)}
                >
                  <div style={{ fontSize: "14px" }}>+</div>
                </button>
              </div>
            </th>
          </tr>
          {Array.isArray(data) && data.map((row, idx) => (
            <tr className="script-add-data-table-row" key={idx} style={{ height: '25px', fontSize: '12px' }}>
              <td style={{ fontSize: '12px', border: "none" }}>
                <button
                  type="button"
                  style={{ color: 'red', border: 'none', background: 'none' }}
                  onClick={() => onAdditionalDataDelete(row.id)}
                >
                  <TPIcon iconType={TPIconTypes.delete} />
                </button>
              </td>
              <td style={{ textAlign: 'start', border: "none" }}>
                <button
                  type="button"
                  onClick={() => defineAdditionalDataCallback(row)}
                  style={{ border: 'none', background: 'none', color: 'purple' }}
                >
                  <b><u>{row.order}</u></b>
                </button>
              </td>
              <td style={{ textAlign: 'start', border: "none" }}>{row.isWhiteSpace ? "" : row.label}</td>
              <td style={{ display: 'flex', border: "none", justifyContent: 'center', padding: '6px' }}>
                <TPChip
                  backgroundColor={row.isLogicDetermination ? '#B1F2D7' : '#FFD7DD'}
                  label={row.isLogicDetermination ? labels.Yes : labels.No}
                  style={{ height: '18px' }}
                />
              </td>
              <td style={{ border: "none" }}></td>
            </tr>
          ))}
        </table>
      </div>
    </>
  )
}