import { ReminderModel } from "@/models/MyReminders/MyReminders";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MyRemindersSliceModel {
  reminders: ReminderModel[];
  remindersLoaded: boolean;
}

const initialState: MyRemindersSliceModel = {
  reminders: [],
  remindersLoaded: false,
};

export const MyRemindersSlice = createSlice({
  initialState: initialState,
  name: "MyRemindersSlice",
  reducers: {
    setReminders: (state, action: PayloadAction<ReminderModel[]>) => {
      state.reminders = action.payload;
    },
    setContentLoaded: (state, action: PayloadAction<boolean>) => {
      state.remindersLoaded = action.payload;
    },
  },
});
