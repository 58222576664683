import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useReducer, useState } from "react";

type CaseSearchByNumberStateType = {
  currentSearchNumber: string;
  currentSearchNumberErrorMessage: string;
};

enum commandsEnum {
  "change_case_number",
  "change_error_message",
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const CaseSearchBuNumber = () => {
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const componentFileName: string = "CaseSearchByNumber.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //#region Resources
  const resourceSet: string = "CaseSearchByNumberComponent";
  const [caseSearchByNumberLabel, setCaseSearchByNumberLabel] = useState("");
  const [caseSearchByNumberTitle, setCaseSearchByNumberTitle] = useState("");
  const [searchLabel, setSearchLabel] = useState("");

  //#endregion

  const loadResources = async () => {
    setCaseSearchByNumberLabel(
      await TPI18N.GetText(resourceSet, "SearchByNumberLabel"),
    );
    setCaseSearchByNumberTitle(
      await TPI18N.GetText(resourceSet, "SearchByNumberTitle"),
    );
    setSearchLabel(await TPI18N.GetText(resourceSet, "SearchLabel"));
    setIsLoadingScreen(false);
  };

  const handleOnCaseNumberchange = (caseNumber: number) => {
    let command1: commandType = {
      type: commandsEnum.change_case_number,
      payload: { newCurrentSearchNumber: caseNumber },
    };
    dispatchCommand(command1);
  };

  const handleOnKeyDownCallBack = async (e: any) => {
    if (e.keyCode == 13) {
      await handleSearchButtonClick();
    }
  };

  const handleSearchButtonClick = async () => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200];
    if (!screenState.currentSearchNumber) {
      let command1: commandType = {
        type: commandsEnum.change_error_message,
        payload: {
          newCurrentSearchNumberErrorMessage: await TPI18N.GetText(
            resourceSet,
            "InputDTOCaseNumberEmpty",
          ),
        },
      };
      dispatchCommand(command1);
      return;
    }
    try {
      setIsLoadingScreen(true);

      let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
        +screenState.currentSearchNumber,
        false,
        true,
        expectedCodes,
      );

      if (isValidCaseNumber) {
        casecontext.handleAddNewCaseViewerCallBack(
          screenState.currentSearchNumber,
        );
      }
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleSearchButtonClick ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} handleSearchButtonClick ex`);
      setIsLoadingScreen(false);
    }
  };

  //Initial state
  const initialStateBLL: CaseSearchByNumberStateType = {
    currentSearchNumber: "",
    currentSearchNumberErrorMessage: "",
  };
  //reducer definition
  const [screenState, dispatchCommand] = useReducer(doCommand, initialStateBLL);

  function doCommand(
    prevState: CaseSearchByNumberStateType,
    command: commandType,
  ) {
    let newCaseSearchByNumberState = { ...prevState };
    switch (command.type) {
      case commandsEnum.change_case_number:
        newCaseSearchByNumberState.currentSearchNumber =
          command.payload.newCurrentSearchNumber;
        newCaseSearchByNumberState.currentSearchNumberErrorMessage = "";
        return newCaseSearchByNumberState;
      case commandsEnum.change_error_message:
        newCaseSearchByNumberState.currentSearchNumberErrorMessage =
          command.payload.newCurrentSearchNumberErrorMessage;
        return newCaseSearchByNumberState;
      default:
        return prevState;
    }
  }

  useEffect(() => {
    loadResources();
  }, []);

  return (
    <ContentVerticalNoTabsStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="container-fluid">
          <TPPageTitle>{caseSearchByNumberTitle}</TPPageTitle>
          <hr />
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-12">
              <div className="form-group">
                <TPNumeric
                  id="IdTPNumeric"
                  onChange={(caseNumber: number) => {
                    handleOnCaseNumberchange(caseNumber);
                  }}
                  value={screenState.currentSearchNumber}
                  labelText={caseSearchByNumberLabel}
                  errorMessage={screenState.currentSearchNumberErrorMessage}
                  onKeyDownCallBack={handleOnKeyDownCallBack}
                  maxLength={10}
                ></TPNumeric>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-4 col-md-5 col-sm-12">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={handleSearchButtonClick}
                  isDesignSystem
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "11px",
                    paddingBottom: "11px"
                  }}
                >
                  {searchLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalNoTabsStyled>
  );
};

export default CaseSearchBuNumber;
