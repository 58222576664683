import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState } from "react";
import {
  StyledTabButton,
  StyledTabContainer,
} from "../supervisor-cockpit-styles";
import {
  CockpitTabButton,
  CockpitTabLabels,
  CockpitView,
} from "../supervisor-cockpit.model";

type CockpitTabsProps = {
  activeView?: CockpitView;
  handleViewChange?: (view: CockpitView) => void;
};

const CockpitTabs: FC<CockpitTabsProps> = ({
  activeView,
  handleViewChange,
}): ReactElement => {
  const component: string = "CockpitTabs";

  const [componentLabels, setComponentLabels] = useState<CockpitTabLabels>(
    {} as CockpitTabLabels
  );

  const [currentView, setCurrentView] = useState<CockpitView>("team");

  const loadUtilsResources = async () => {
    setComponentLabels({
      event: await TPI18N.GetText(component, "EventView"),
      group: await TPI18N.GetText(component, "GroupView"),
      team: await TPI18N.GetText(component, "TeamView"),
    });
  };

  const tabsButtons: CockpitTabButton[] = [
    {
      label: componentLabels.team,
      view: "team",
      isActive: false,
    },
    {
      label: componentLabels.group,
      view: "group",
      isActive: false,
    },
    {
      label: componentLabels.event,
      view: "event",
      isActive: false,
    },
  ];

  const handleTabClick = (view: CockpitView) => {
    if (view === currentView) return;

    setCurrentView(view);
    handleViewChange && handleViewChange(view);
  };

  useEffect(() => {
    if (activeView) handleTabClick(activeView);
  }, [activeView]);

  useEffect(() => {
    loadUtilsResources().then();
  }, [])

  return (
    <StyledTabContainer>
      {tabsButtons.map((button) => (
        <StyledTabButton
          active={currentView === button.view}
          key={button.view}
          id={`${button.view}-view-tab-btn`}
          onClick={() => handleTabClick(button.view)}
        >
          {button.label}
        </StyledTabButton>
      ))}
    </StyledTabContainer>
  );
};

export default CockpitTabs;
