import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  AdditionalDataExportDescriptionModel,
  AdditionalDataExportModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import { SequenceService } from "@/services/SequenceService";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import { FC, useEffect, useRef, useState } from "react";

interface Properties {
  ids?: string[];
}

interface hotTableColumnModel {
  label: string;
  colspan: number;
  id?: string | number;
  readOnly?: boolean;
}

const defaultColumnSetUp = [
  [
    { label: "Id", colspan: 1 },
    { label: "Descriptions", colspan: 1 },
    { label: "Category Id", colspan: 1 },
    { label: "Categories", colspan: 1 },
    { label: "Type Id", colspan: 1 },
    { label: "Types", colspan: 1 },
    { label: "Comments", colspan: 1 },
    { label: "JSON Parameters", colspan: 1 },
    { label: "Is editing enabled? (1: Yes, 0: No)", colspan: 1 },
    { label: "Validation URL", colspan: 1 },
    { label: "Is system record? (1: Yes, 0: No)", colspan: 1 },
    { label: "Message", colspan: 1 },
  ],
];

const blankDataExportItem = {
  id: "",
  descriptions: [],
  additionalDataTypeDescriptions: [],
  categoryDescriptions: [],
  categoryId: "",
  additionalDataTypeId: "",
  comments: "",
  jsonParametersUtf8: "",
  enableEditingModifyBasicData: false,
  isSystemRecordADDA: false,
  validationURL: "",
  message: "",
};

const blankDataExportDescItem: AdditionalDataExportDescriptionModel = {
  languageId: "",
  localizedValue: "",
  order: null,
};

const resourceSet = "AdditionalDataBulkEditor";

const AdditionalBulkLoad: FC<Properties> = ({ ids }) => {
  const hotTableRef = useRef<any>(null);
  const [dataIds, setDataIds] = useState<string[]>(ids || []);
  const [realData, setRealData] = useState<AdditionalDataExportModel[]>([
    structuredClone(blankDataExportItem),
  ]);
  const [columnSetUp, setColumnSetUp] =
    useState<Array<Array<hotTableColumnModel | string>>>(defaultColumnSetUp);
  const [configuredData, setConfiguredData] = useState<any[][]>([
    columnSetUp[0].map((_) => ""),
  ]);
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [canImport, setCanImport] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tabTitle, setTabTitle] = useState("");
  const [howToLabel, setHowToLabel] = useState("");
  const [helpIntro, setHelpIntro] = useState("");
  const [help1, setHelp1] = useState("");
  const [help2, setHelp2] = useState("");
  const [whatClientWillDoLabel, setWhatClientWillDoLabel] = useState("");
  const [whatClientWillNotDoLabel, setWhatClientWillNotDoLabel] = useState("");
  const [clientActions1, setClientActions1] = useState("");
  const [noClientAction1, setNoClientAction1] = useState("");
  const [noClientAction2, setNoClientAction2] = useState("");
  const [noClientAction3, setNoClientAction3] = useState("");
  const [noClientAction4, setNoClientAction4] = useState("");
  const [tableTitle, setTableTitle] = useState("");
  const [saveLabel, setSaveLabel] = useState("");
  const [clearLabel, setClearLabel] = useState("");
  const [idColumnLabel, setIdColumnLabel] = useState("");
  const [descriptionsColumnLabel, setDescriptionsColumnLabel] = useState("");
  const [categoryIdColumnLabel, setCategoryIdColumnLabel] = useState("");
  const [categoriesColumnLabel, setCategoriesColumnLabel] = useState("");
  const [typeIdColumnLabel, setTypeIdColumnLabel] = useState("");
  const [typesColumnLabel, setTypesColumnLabel] = useState("");
  const [commentsColumnLabel, setCommentsColumnLabel] = useState("");
  const [jsonParametersColumnLabel, setJsonParametersColumnLabel] =
    useState("");
  const [editingEnabledColumnLabel, setEditingEnabledColumnLabel] =
    useState("");
  const [validationURLColumnLabel, setValidationURLColumnLabel] = useState("");
  const [systemRecordColumnLabel, setSystemRecordColumnLabel] = useState("");
  const [messageColumnLabel, setMessageColumnLabel] = useState("");
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>();

  const loadLabels = async function (): Promise<void> {
    setHowToLabel(await TPI18N.GetText(resourceSet, "HowToLabel"));
    setTabTitle(await TPI18N.GetText(resourceSet, "TabTitle"));
    setHelpIntro(await TPI18N.GetText(resourceSet, "HelpIntro"));
    setHelp1(await TPI18N.GetText(resourceSet, "Help1"));
    setHelp2(await TPI18N.GetText(resourceSet, "Help2"));
    setWhatClientWillDoLabel(
      await TPI18N.GetText(resourceSet, "WhatClientWillDoLabel"),
    );
    setWhatClientWillNotDoLabel(
      await TPI18N.GetText(resourceSet, "WhatClientWillNotDoLabel"),
    );
    setClientActions1(await TPI18N.GetText(resourceSet, "ClientActions1"));
    setNoClientAction1(await TPI18N.GetText(resourceSet, "NoClientAction1"));
    setNoClientAction2(await TPI18N.GetText(resourceSet, "NoClientAction2"));
    setNoClientAction3(await TPI18N.GetText(resourceSet, "NoClientAction3"));
    setNoClientAction4(await TPI18N.GetText(resourceSet, "NoClientAction4"));
    setTableTitle(await TPI18N.GetText(resourceSet, "TableTitle"));
    setSaveLabel(await TPI18N.GetText(resourceSet, "SaveLabel"));
    setClearLabel(await TPI18N.GetText(resourceSet, "ClearLabel"));
    setIdColumnLabel(await TPI18N.GetText(resourceSet, "IdColumnLabel"));
    setDescriptionsColumnLabel(
      await TPI18N.GetText(resourceSet, "DescriptionsColumnLabel"),
    );
    setCategoryIdColumnLabel(
      await TPI18N.GetText(resourceSet, "CategoryIdColumnLabel"),
    );
    setCategoriesColumnLabel(
      await TPI18N.GetText(resourceSet, "CategoriesColumnLabel"),
    );
    setTypeIdColumnLabel(
      await TPI18N.GetText(resourceSet, "TypeIdColumnLabel"),
    );
    setTypesColumnLabel(await TPI18N.GetText(resourceSet, "TypesColumnLabel"));
    setCommentsColumnLabel(
      await TPI18N.GetText(resourceSet, "CommentsColumnLabel"),
    );
    setJsonParametersColumnLabel(
      await TPI18N.GetText(resourceSet, "JsonParametersColumnLabel"),
    );
    setEditingEnabledColumnLabel(
      await TPI18N.GetText(resourceSet, "EditingEnabledColumnLabel"),
    );
    setValidationURLColumnLabel(
      await TPI18N.GetText(resourceSet, "ValidationURLColumnLabel"),
    );
    setSystemRecordColumnLabel(
      await TPI18N.GetText(resourceSet, "SystemRecordColumnLabel"),
    );
    setMessageColumnLabel(
      await TPI18N.GetText(resourceSet, "MessageColumnLabel"),
    );
    setLabelsLoaded(true);
  };

  const handleClear = () => {
    setDataIds([]);
    setColumnSetUp(defaultColumnSetUp);
    setRealData([structuredClone(blankDataExportItem)]);
    setConfiguredData([columnSetUp[0].map((_) => "")]);
  };

  const handleSave = () => {
    if (!hotTableRef.current) return;
    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;
    if (!canImport) return;

    setCanImport(false);
    const additionalDataServiceInstance = new AdditionalDataService();
    additionalDataServiceInstance
      .importAdditionalData(realData, false, false, [200])
      .then((response) => {
        const dataCopy = [...realData];
        if (response)
          response.map((message, index: number) => {
            dataCopy[index].message = message.message;
          });
        setRealData(dataCopy);
        setCanImport(true);
      })
      .catch((error) => console.error(error));
  };

  const componentFileName = "AdditionalDataBulkLoad.tsx";

  const generateNewId = async (row?: number) => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoading(true);
      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQADDA,
      );
      setIsLoading(false);
      if (responseRequest.responseResult) {
        const result = responseRequest?.responseData?.data[0]?.sequenceCode;
        if (row) afterChange([[row, 0, "", result]], "");
        return result;
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoading(false);
    }
  };

  const addBlankRow = function (data: AdditionalDataExportModel[]): void {
    const dataCopy = [...data];
    const lastRow = configuredData[configuredData.length - 1];
    let filled = false;
    for (let i = 0; i < lastRow.length && !filled; i++) {
      if (lastRow[i] != "" && lastRow[i] != Boolean(0)) {
        filled = true;
      }
    }
    if (filled) {
      const blankData = structuredClone(blankDataExportItem);
      generateNewId().then((id) => {
        blankData.id = id;
        dataCopy.push(blankData);
        setRealData(dataCopy);
      });
    }
  };

  const afterChange = (changes: any, source: string) => {
    if (source === "loadData") return;
    if (!hotTableRef.current) return;
    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;
    if (!changes) return;

    const dataCopy = [...realData];

    changes.forEach((change: any[]) => {
      const row: number = change[0];
      const column: number = change[1];
      const oldValue: number = change[2];
      const newValue: any = change[3];
      let newObject = structuredClone(blankDataExportDescItem);
      const languages: number = TPGlobal.TPClientAvailableLanguages.length;
      let pos: number = 0;

      let idIsRepeated = false;

      for (let i = 0; i < dataCopy.length && !idIsRepeated; i++) {
        idIsRepeated =
          dataCopy[i].id == newValue && column == 0 && oldValue != newValue;
      }

      if (!dataCopy[row]) return;

      if (column == 0) dataCopy[row].id = newValue;
      if (column >= 1 && column <= languages) {
        newObject.languageId =
          TPGlobal.TPClientAvailableLanguages[column - 1].id;
        newObject.localizedValue = newValue;
        dataCopy[row].descriptions[column - 1] = newObject;
      }
      pos = languages + 1;
      if (column == pos) dataCopy[row].categoryId = newValue;
      if (column >= pos + 1 && column <= pos + languages) {
        newObject.languageId =
          TPGlobal.TPClientAvailableLanguages[column - languages - 2].id;
        newObject.localizedValue = newValue;
        dataCopy[row].categoryDescriptions[column - languages - 2] = newObject;
      }
      pos += languages + 1;
      if (column == pos) dataCopy[row].additionalDataTypeId = newValue;
      if (column >= pos + 1 && column <= pos + languages) {
        newObject.languageId =
          TPGlobal.TPClientAvailableLanguages[column - languages * 2 - 3].id;
        newObject.localizedValue = newValue;
        dataCopy[row].additionalDataTypeDescriptions[
          column - languages * 2 - 3
        ] = newObject;
      }
      pos += languages + 1;
      if (column == pos) dataCopy[row].comments = newValue;
      if (column == pos + 1) dataCopy[row].jsonParametersUtf8 = newValue;
      if (column == pos + 2)
        dataCopy[row].enableEditingModifyBasicData = newValue == "1";
      if (column == pos + 3) dataCopy[row].validationURL = newValue;
      if (column == pos + 4) dataCopy[row].isSystemRecordADDA = newValue == "1";
      if (column == pos + 5) dataCopy[row].message = newValue;

      if (idIsRepeated) generateNewId(row);
    });
    setRealData(dataCopy);
  };

  const afterRender = () => {
    if (!hotTableRef.current) return;
    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;
    hotInstance?.getData()?.forEach((row: any[], rowIndex: any) => {
      const cell = hotInstance.getCell(rowIndex, 0);
      if (cell && row[0]) {
        cell.style.fontWeight = "bold";
      }
    });
  };

  const localeFill = function (
    localeArray: AdditionalDataExportDescriptionModel[],
    row: any[],
    position: number,
  ): number {
    for (let i = 0; i < TPGlobal.TPClientAvailableLanguages.length; i++) {
      const locale = localeArray.find(
        (locale) =>
          locale?.languageId == TPGlobal.TPClientAvailableLanguages[i].id,
      );
      row[position] = locale ? locale.localizedValue : "";
      position += 1;
    }
    return position;
  };

  useEffect(() => {
    setIsLoading(true);
    if (dataIds && dataIds.length > 0) {
      const additionalDataServiceInstance = new AdditionalDataService();
      additionalDataServiceInstance
        .getDataByIds(dataIds, false, false, [200])
        .then((realDataResponse) => {
          setIsLoading(false);
          setRealData(
            realDataResponse.map((responseItem: AdditionalDataExportModel) => {
              const retrievedJsonParameters = responseItem.jsonParametersUtf8?.split(",")
                .map((charCode: string) =>
                  String.fromCharCode(Number(charCode)),
                )
                .join("");
              return {
                ...responseItem,
                message: "",
                jsonParametersUtf8: retrievedJsonParameters,
                descriptions: [
                  ...TPGlobal.TPClientAvailableLanguages.map((language) => {
                    return {
                      languageId: language.id,
                      localizedValue:
                        responseItem.descriptions.find(
                          (desc) => desc.languageId == language.id,
                        )?.localizedValue || "",
                      order: null,
                    } as AdditionalDataExportDescriptionModel;
                  }),
                ],
                additionalDataTypeDescriptions: [
                  ...TPGlobal.TPClientAvailableLanguages.map((language) => {
                    return {
                      languageId: language.id,
                      localizedValue:
                        responseItem.additionalDataTypeDescriptions.find(
                          (desc) => desc.languageId == language.id,
                        )?.localizedValue || "",
                      order: null,
                    } as AdditionalDataExportDescriptionModel;
                  }),
                ],
                categoryDescriptions: [
                  ...TPGlobal.TPClientAvailableLanguages.map((language) => {
                    return {
                      languageId: language.id,
                      localizedValue:
                        responseItem.categoryDescriptions.find(
                          (desc) => desc.languageId == language.id,
                        )?.localizedValue || "",
                      order: null,
                    } as AdditionalDataExportDescriptionModel;
                  }),
                ],
              };
            }),
          );
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    } else setIsLoading(false);
    addBlankRow(realData);
  }, [dataIds]);

  useEffect(() => {
    if (realData && realData.length > 0) {
      const availableLanguages = TPGlobal.TPClientAvailableLanguages;
      const newColumnsSetUp: hotTableColumnModel[][] = [
        [
          // Top headers
          { label: idColumnLabel, colspan: 1 },
          {
            label: descriptionsColumnLabel,
            colspan: availableLanguages.length,
          },
          { label: categoryIdColumnLabel, colspan: 1 },
          { label: categoriesColumnLabel, colspan: availableLanguages.length },
          { label: typeIdColumnLabel, colspan: 1 },
          { label: typesColumnLabel, colspan: availableLanguages.length },
          { label: commentsColumnLabel, colspan: 1 },
          { label: jsonParametersColumnLabel, colspan: 1 },
          { label: editingEnabledColumnLabel, colspan: 1 },
          { label: validationURLColumnLabel, colspan: 1 },
          { label: systemRecordColumnLabel, colspan: 1 },
          { label: messageColumnLabel, colspan: 1 },
        ],
        [
          // Nested headers
          { label: "", colspan: 1 },
          ...availableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          ...availableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          ...availableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
        ],
      ];
      setColumnSetUp(newColumnsSetUp);

      let newData: any[][] = [];
      realData.map((entry: AdditionalDataExportModel) => {
        let row: any[] = [];
        let position = 0;

        row.push(entry.id);
        position += 1;
        position = localeFill(entry.descriptions, row, position);

        row.push(entry.categoryId);
        position += 1;
        position = localeFill(entry.categoryDescriptions, row, position);

        row.push(entry.additionalDataTypeId);
        position += 1;
        position = localeFill(
          entry.additionalDataTypeDescriptions,
          row,
          position,
        );

        row.push(entry.comments || "");
        row.push(entry.jsonParametersUtf8 || "");
        row.push(entry.enableEditingModifyBasicData ? "1" : "0");
        row.push(entry.validationURL || "");
        row.push(entry.isSystemRecordADDA ? "1" : "0");
        row.push(entry.message || "");

        newData.push(row);
      });
      setConfiguredData(newData);
    }
  }, [realData]);

  useEffect(() => {
    const hot = hotTableRef.current?.hotInstance;
    hot?.updateSettings({
      cells(_row: number, col: number) {
        const cellProperties: Handsontable.CellMeta = {};
        let lastColumn: number = -1;
        columnSetUp[0].map((header) => {
          typeof header === "object"
            ? (lastColumn += header.colspan)
            : (lastColumn += 1);
        });
        if (col == lastColumn) cellProperties.readOnly = true;
        return cellProperties;
      },
    });
  });

  useEffect(() => {
    setCanImport(configuredData.length > 0);
  }, [configuredData]);

  useEffect(() => {
    if (labelsLoaded) {
      setColumnSetUp([
        [
          // Top headers
          { label: idColumnLabel, colspan: 1 },
          {
            label: descriptionsColumnLabel,
            colspan: TPGlobal.TPClientAvailableLanguages.length,
          },
          { label: categoryIdColumnLabel, colspan: 1 },
          {
            label: categoriesColumnLabel,
            colspan: TPGlobal.TPClientAvailableLanguages.length,
          },
          { label: typeIdColumnLabel, colspan: 1 },
          {
            label: typesColumnLabel,
            colspan: TPGlobal.TPClientAvailableLanguages.length,
          },
          { label: commentsColumnLabel, colspan: 1 },
          { label: jsonParametersColumnLabel, colspan: 1 },
          { label: editingEnabledColumnLabel, colspan: 1 },
          { label: validationURLColumnLabel, colspan: 1 },
          { label: systemRecordColumnLabel, colspan: 1 },
          { label: messageColumnLabel, colspan: 1 },
        ],
        [
          // Nested headers
          { label: "", colspan: 1 },
          ...TPGlobal.TPClientAvailableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          ...TPGlobal.TPClientAvailableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          ...TPGlobal.TPClientAvailableLanguages.map((lang) => {
            return {
              label: lang.name,
              id: lang.id,
              colspan: 1,
            };
          }),
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
          { label: "", colspan: 1 },
        ],
      ]);
    }
  }, [labelsLoaded]);

  // Function to add custom ids to the cells
  const cellRenderer = function (
    this: Handsontable.CellProperties,
    instance: any,
    td: any,
    row: any,
    col: any,
    prop: any,
    value: any,
    cellProperties: any,
  ) {
    Handsontable.renderers.TextRenderer.apply(this, [
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties,
    ]);
    td.id = `cell-${row}-${col}`;
  };

  useEffect(() => {
    loadLabels();
  });

  return (
    <>
      <TPLoadingOverlay active={isLoading}>
        <TPPageTitle>{tabTitle}</TPPageTitle>
        <div style={{ margin: "16px" }}>
          <div id="additional-data-export-import-help" className="accordion">
            <div className="accordion-item">
              <div className="accordion-header">
                <button
                  id="additional-data-export-import-help-button"
                  className="accordion-button"
                  onClick={() => setHelpVisible(!helpVisible)}
                  data-bs-toggle="collapse"
                  data-bs-target="#add-data-export-import-help"
                >
                  <div className="export-import-help-button">
                    <TPIcon
                      iconType={TPIconTypes.alert}
                      className="alert-icon"
                    />
                    {howToLabel}
                  </div>
                </button>
              </div>
              <div
                id="add-data-export-import-help"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body show">
                  <p>{helpIntro}</p>
                  <p>
                    <strong>1. </strong>
                    {help1}
                  </p>
                  <p>
                    <strong>2.</strong> {help2}
                  </p>
                  <strong>{whatClientWillDoLabel}:</strong>
                  <ul>
                    <li>- {clientActions1}</li>
                  </ul>
                  <br />
                  <strong>{whatClientWillNotDoLabel}</strong>
                  <ul>
                    <li>- {noClientAction1}</li>
                    <li>- {noClientAction2}</li>
                    <li>- {noClientAction3}</li>
                    <li>- {noClientAction4}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-4">{tableTitle}</h5>
          <button
            id="additional-data-bulk-add-row"
            onClick={() => addBlankRow(realData)}
          >
            <TPIcon iconType={TPIconTypes.plus} />
          </button>
          <div id="additional-data-export-import-table">
            <HotTable
              className="htCenter"
              colWidths={150}
              data={configuredData}
              nestedHeaders={columnSetUp || defaultColumnSetUp}
              ref={hotTableRef}
              contextMenu={["copy", "cut"]}
              licenseKey="non-commercial-and-evaluation"
              afterChange={afterChange}
              afterRender={afterRender}
              manualColumnResize
              selectionMode="multiple"
              autoWrapCol
              autoWrapRow
              rowHeaders
              colHeaders
              width="100%"
              height="100%"
              cells={() => {
                return {
                  renderer: cellRenderer,
                };
              }}
            />
          </div>
          <div className="row">
            <div className="col-11">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  id="save-data"
                  type={TPButtonTypes.primary}
                  onClick={() => handleSave()}
                  disabled={!canImport}
                >
                  {saveLabel}
                </TPButton>
                <TPButton
                  id="cancel-process"
                  type={TPButtonTypes.light}
                  onClick={() => handleClear()}
                  className={"ms-2"}
                >
                  {clearLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default AdditionalBulkLoad;
