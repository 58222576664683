import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse, RequestResponse } from "@/helpers/TPStandardResponse";

export class TemporaryAttachmentService {
  serviceFileName: string = "TemporaryAttachmentService.ts";

  public async insertTemporaryAttachment(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/temporary";
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let contentHeader: TPKeyValue = {
      key: "Content-Type",
      value: "multipart/form-data",
    };
    headers.push(contentHeader);

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertTemporaryAttachment ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} insertTemporaryAttachment`);
      throw new Error(
        `Error ${this.serviceFileName} insertTemporaryAttachment`
      );
    }
  }

  public async deleteTemporaryAttachmentById(
    fileGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/temporary/" + fileGuid;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentById`
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentById`
      );
    }
  }

  public async deleteTemporaryAttachmentByBLOBId(
    blobId: string,
    pathFile: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/temporary/by-blobid/" + blobId + "?pathFile=" + pathFile;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentByBLOBId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentByBLOBId`
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteTemporaryAttachmentByBLOBId`
      );
    }
  }

  public async validateColumnsFile(
    inputDTO: any,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = true,
    expectedCodes: number[] = [404, 200]
  ): Promise<RequestResponse> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/eventLoad/uploadExcelFile";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let contentHeader: TPKeyValue = {
      key: "Content-Type",
      value: "multipart/form-data",
    };

    headers.push(contentHeader);

    let response: HTTPResponse;

    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} validateColumnsFile ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} validateColumnsFile`);
      throw new Error(`Error ${this.serviceFileName} validateColumnsFile`);
    }
  }

  public async processFile(
    eventLoadId: string,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = true,
    expectedCodes: number[] = [404, 200]
  ): Promise<RequestResponse> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/eventLoad/ProcessExcelFile";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;

    let object = {
      idEventLoad: eventLoadId,
    };

    try {
      response = await httpclient.postData(
        finalURL,
        object,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} validateColumnsFile ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} validateColumnsFile`);
      throw new Error(`Error ${this.serviceFileName} validateColumnsFile`);
    }
  }
}
