
export class inputDashboardModel {
    timeZone: string = "";
    initialDate: Date = new Date();
    endDate: Date = new Date();
}

export class UserStatsModel {
    fullname: string = "";
    status: string = "";
    guid: string = "";
    id: number = -1;
    login: string = "";
    createdCases: number = -1;
    timeLogged: number = -1;
    workingTime: number = -1;
    average: number = -1;
    directSolution: number = -1;
} 


export class UserStatsModelGrid {
    fullname: string = "";
    status: string = "";   
    createdCases: number = -1;
    timeLogged: number = -1;
    workingTime: number = -1;
    average: number = -1;
    directSolution: number = -1;
} 
