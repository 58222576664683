import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
    DataTableContainer,
    IsActiveIcon,
    TableContainer,
    tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    CIMTitleSection,
    TPFilterAndSearch,
    TPPageActions,
    TPPageFilterContainer,
    TPPageFirstRow,
    TPPageSearchContainer,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import {
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { HierarchyFolderTypeService } from "@/services/HierarchyFolderService";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import React, {
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import DynamicTable, { ColumnStyles } from "@/modules/core/components/dynamic-table/DynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import FormView from "../FormDesigner/FormView/FormView";
import { ClientService } from "../../services/ClientService";

interface CustomerForm {
    handleJsonDataCustomer: Function;
    handleJsonParameters: Function;
    handleFormReadyToSave: Function;
    customerFormId: string;
    formViewValueKey?: TPKeyValue[];
    tryingToSaveForm: boolean;
    disableEdit?: boolean;
    subsidiaryOrgId: number;
    mode: string;
}

enum ClientNewCustomerFields {
    DocumentTypeId = "documentTypeId",
    Document = "document",
    Name = "name",
    LastName = "lastName",
    PreferredEmail = "preferredEmail",
    PreferredPhone = "preferredPhone",
    InternalCode = "internalCode",
    SubsidiaryOrganizationId = "subsidiaryOrganizationId",
    AdditionalInfo = "additionalInfo",
    IsActive = "isActive",
    TypistIdGuid = "typistIdGuid",
    SubsidiaryName = "subsidiaryName",
    PreferredAddress = "preferredAddress",
    PreferredAddressInfo = "preferredAddressInfo",
    Geography = "geography",
    OrganizationId = "organizationId",
    RelationId = "relationId",
    RegionId = "regionId",
    ChannelId = "channelId",
    CustomerTypeId = "customerTypeId",
    AdditionalData = "additionalData"
}


type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<CustomerTypeViewModel>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}
type commandType = {
    type: commandsEnum;
    payload: any;
};

const CustomerInsertUpdateForm = React.forwardRef(
    ({ handleJsonDataCustomer,
        handleFormReadyToSave,
        handleJsonParameters,
        customerFormId,
        formViewValueKey,
        tryingToSaveForm,
        disableEdit,
        subsidiaryOrgId,
        mode }: CustomerForm) => {
        const componentFileName: string = "CustomerInsertUpdateForm.tsx";
        const ResourceSet: string = "CustomerInsertUpdateFormComponent";

        const [isLoadingScreen, setIsLoadingScreen] = useState(true);


        const loadResources = async () => {

        };

     

        useEffect(() => {
            loadResources();
        }, []);

         

        return (
            <>
                {(customerFormId && customerFormId != "") && formViewValueKey &&
                    <TPLoadingOverlay active={false}>
                        <FormView
                            onChangedAllAdditionalJsonParameters={(e: any) => { handleJsonParameters(e); } }
                            onChangedJson={(e: string) => { handleJsonDataCustomer(e) }}
                            onChangedReadyForm={(e: boolean) => { handleFormReadyToSave(e) }}
                            inputFormId={customerFormId}
                            inputValueDefault={formViewValueKey}
                            inputTryingToSave={tryingToSaveForm}
                            disable={disableEdit}
                        />
                    </TPLoadingOverlay>
                }
            </>
        );
    },
);

export default CustomerInsertUpdateForm;
