import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface QuickClassifierViewModel {
    id: string;
    description: string;
    emailTemplateHeaderId: string;
    baseLevelId: string;
    localizedBaseLevel: string;
    caseComments: string;
    classifier1Id: string;
    classifier2Id: string;
    classifier3Id: string;
    classifier4Id: string;
    classifier5Id: string;
    isActive: boolean;
    type: string;
    isSystemRecord: boolean;
    localizedDescription: string;
    localizedClassifier1: string;
    localizedClassifier2: string;
    localizedClassifier3: string;
    localizedClassifier4: string;
    localizedClassifier5: string;
    localizedHierarchyClassifier1: string;
    localizedHierarchyClassifier2: string;
    localizedHierarchyClassifier3: string;
    localizedHierarchyClassifier4: string;
    localizedHierarchyClassifier5: string;
}


export class QuickClassifierCloneModel {
    baseId: string = "";
    newId: string = "";
    description: String = "";
    isActive: boolean = false;
    otherLocalizedValues: Array<RecordLocalizedModelNew> = new Array<RecordLocalizedModelNew>();
}


export type RecordLocalizedModelNew = {
    languageId: string;
    localizedValue: string;   
};