import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import {
    FormDeignerModel,
    FormDesignerRowModel,
    FormModel,
    ItemRow,
    SectionModel,
} from "@/models/FormDesigner/FormDesigner";
import { useReducer, useState } from "react";

enum commandsEnum {
    defaul,
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

export const useStatusFormDesigner = () => {
    const resourceSet: string = "FormDesignerComponent";

    const [sectionFormDesingner, setSectionFormDesingner] = useState<
        Array<SectionModel>
    >(new Array<SectionModel>());
    const [rowsFormDesingner, setRowFormDesingner] = useState<FormModel>(
        new FormModel(),
    );
    const [statusLoading, setStatusLoading] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [additionalData, setAdditionalData] = useState<
        Array<AdditionalDataViewModel>
    >([]);

    const reducerAction = (
        status = Array<FormDeignerModel>(),
        action: { type: commandsEnum; payload: FormDeignerModel },
    ) => {
        switch (action.type) {
            case commandsEnum.defaul:
                if (status == undefined || status == null)
                    status = Array<FormDeignerModel>();
                var temp = [...status];
                temp.push(action.payload);
                return temp;
                break;
            default:
                return status;
        }
    };

    const [status, dispatch] = useReducer(
        reducerAction,
        new Array<FormDeignerModel>(),
    );

    //Util
    const handleGenerateUniqueId = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            },
        );
    };

    const handleAddId = (data: FormDesignerRowModel): FormDesignerRowModel => {
        let temp = { ...rowsFormDesingner };
        let count = 0;
        data.id = handleGenerateUniqueId();

        if (temp.rows) {
            let exist = temp.rows.filter((s) => s.id == data.id);
            while (exist.length > 0 && count <= 100) {
                exist = temp.rows.filter((s) => s.id == data.id);
                data.id = handleGenerateUniqueId();
                count++;
            }
        }
        return data;
    };

    //Form
    const handleColorForm = (data: string) => {
        let temp = { ...rowsFormDesingner };
        temp.color = data;
        setRowFormDesingner(temp);
        return data;
    };

    const handleTitleForm = (data: string) => {
        let temp = { ...rowsFormDesingner };
        temp.title = data;
        setRowFormDesingner(temp);
        return data;
    };

    const handleEditForm = (data: FormModel) => {
        let temp = { ...rowsFormDesingner };
        temp = data;
        setRowFormDesingner(temp);
        return data;
    };

    const handleSectionModelId = (data: SectionModel): SectionModel => {
        let temp = { ...rowsFormDesingner };
        let count = 0;
        data.id = handleGenerateUniqueId();
        if (temp.rows) {
            let exist = temp.rows.filter((s) => s.id == data.id);
            while (exist.length > 0 && count <= 100) {
                exist = temp.rows.filter((s) => s.id == data.id);
                data.id = handleGenerateUniqueId();
                count++;
            }
        }
        return data;
    };

    //Section
    const handleBuilderAddNewSectionForm = (item: SectionModel) => {
        let temp = { ...rowsFormDesingner };
        item = handleSectionModelId(item);

        if (temp.sections && temp.sections.length > 0)
            item.order = temp.sections.length;
        else item.order = 1;

        if (!temp.sections) temp.sections = new Array<SectionModel>();

        temp.sections.push(item);
        setSectionFormDesingner(temp.sections);
        setRowFormDesingner(temp);
    };

    const handleLoadSection = () => {
        let temp = { ...rowsFormDesingner };
        setSectionFormDesingner(temp.sections);
        setRowFormDesingner(temp);
    };

    const handleEditTitleSection = (item: SectionModel) => {
        let temp = { ...rowsFormDesingner };
        let tempDeleteRow = { ...rowsFormDesingner };

        let section = temp.sections.find((s) => s.id == item.id);
        if (section) section.title = item.title;
        setRowFormDesingner(temp);
    };

    const handleBuilderDeleteSection = (item: SectionModel) => {
        let temp = { ...rowsFormDesingner };
        let tempDeleteRow = { ...rowsFormDesingner };

        temp.rows.forEach((e) => {
            handleBuilderDeleteRowForm(e);
        });

        temp.rows = temp.rows.filter((s) => s.idSection != item.id);
        let newList = temp.sections.filter((s) => s.id != item.id);
        temp.sections = newList;

        setSectionFormDesingner(newList);
        setRowFormDesingner(temp);
    };

    const handleOrderUpAndDownSection = (rowItem: SectionModel) => {
        let temp = { ...rowsFormDesingner };
        let listOrder = [...temp.sections];
        let currentIndex = listOrder.findIndex((item) => item.id === rowItem.id);

        if (currentIndex === -1) {
            return; // Elemento no encontrado
        }

        listOrder.splice(currentIndex, 1);
        listOrder.splice(rowItem.order, 0, rowItem);

        listOrder = listOrder.map((item, index) => {
            item.order = index;
            return item;
        });

        // Actualizar el estado
        temp.sections = listOrder;

        setSectionFormDesingner(temp.sections);
        setRowFormDesingner(temp);
    };

    const handleBuilderAddNewControl = (control: ItemRow) => {
        let temp = { ...rowsFormDesingner };
        let row = temp.rows.find((item) => item.id === control.idRow);

        if (row != undefined) {
            if (control.id != "") {
          
                let tempControl = row.controls.find((s) => s.id === control.id);

              
                if (tempControl) {
                    tempControl = { ...control } ;
                   

                    temp.rows.forEach((e) => {
                        if (e.id === control.idRow) {
                            e.controls.forEach((c) => {
                                if (c.id === control.id) {
                                    c.id = control.id;
                                    c.idControl = control.idControl;
                                    c.idRow = control.idRow;
                                    c.title = control.title;
                                    c.aditionalDataName = control.aditionalDataName;
                                    c.isMandatory = control.isMandatory;
                                    c.isVisible = control.isVisible;
                                    c.isSearchable = control.isSearchable;
                                    c.hidePersonalData = control.hidePersonalData;
                                    c.isReadOnly = control.isReadOnly;
                                    c.placeholder = control.placeholder;
                                    c.order = control.order;
                                   
                                }
                            })
                        }
                    })
                   
                    setRowFormDesingner(temp);
                }
                // exist.render = control.render;
            } else {
  
                for (let index = 0; index < row.numberOfColumns; index++) {
                    if (
                        row?.controls[index] == undefined ||
                        row?.controls[index].idControl == ""
                    ) {
                        control.order = index;
                        if (control.id == "") control.id = handleGenerateUniqueId();
                        row?.controls.push(control);
                        break;
                    }
                }

            setRowFormDesingner(temp);
            }
        }
    


};

//Order
const handleOrderUpAndDown = (rowItem: FormDesignerRowModel) => {
    let temp = { ...rowsFormDesingner };
    let listOrder = [...temp.rows];
    let currentIndex = listOrder.findIndex((item) => item.id === rowItem.id);

    if (currentIndex === -1) {
        return; // Elemento no encontrado
    }

    listOrder.splice(currentIndex, 1);
    listOrder.splice(rowItem.order, 0, rowItem);

    listOrder = listOrder.map((item, index) => {
        item.order = index;
        return item;
    });

    // Actualizar el estado
    temp.rows = listOrder;
    setRowFormDesingner(temp);
};

const handleOrderUpAndDownColumn = (columns: Array<ItemRow>) => {
    let temp = { ...rowsFormDesingner };

    let row = temp.rows.find((s) => s.id == columns[0].idRow);

    if (row) row.controls = columns;

    setRowFormDesingner(temp);
};

//Builder Form
const handleBuilderAddNewRowForm = (item: FormDesignerRowModel) => {
    let temp = { ...rowsFormDesingner };
    item = handleAddId(item);

    if (temp.rows && temp.rows.length > 0) item.order = temp.rows.length;
    else item.order = 0;
    if (!temp.rows) temp.rows = new Array<FormDesignerRowModel>();
    temp.rows.push(item);
    setRowFormDesingner(temp);
};

const handleBuilderUpdateRowForm = (item: FormDesignerRowModel) => {
    let temp = { ...rowsFormDesingner };
    item = handleAddId(item);
    temp.rows.push(item);
};

const handleBuilderDeleteRowForm = (itemToDelete: FormDesignerRowModel) => {
    let temp = { ...rowsFormDesingner };
    let listOrder = [...temp.rows];

    let currentIndex = listOrder.findIndex(
        (item) => item.id === itemToDelete.id,
    );

    if (currentIndex === -1) {
        return;
    }

    listOrder.splice(currentIndex, 1);

    listOrder = listOrder.map((item, index) => {
        item.order = index;
        return item;
    });

    temp.rows = listOrder;
    setRowFormDesingner(temp);
};

const handleGetRowForm = (): FormModel => {
    if (rowsFormDesingner) return rowsFormDesingner;
    else return new FormModel();
};

return {
    status,
    rowsFormDesingner,
    sectionFormDesingner,
    statusLoading,
    isUpdate,
    additionalData,
    setAdditionalData,
    handleEditForm,
    handleBuilderAddNewRowForm,
    handleBuilderUpdateRowForm,
    handleBuilderDeleteRowForm,
    handleOrderUpAndDown,
    handleBuilderAddNewControl,
    handleBuilderAddNewSectionForm,
    handleBuilderDeleteSection,
    handleOrderUpAndDownSection,
    handleColorForm,
    handleEditTitleSection,
    handleTitleForm,
    setStatusLoading,
    setIsUpdate,
    handleLoadSection,
    handleOrderUpAndDownColumn,
};
};
