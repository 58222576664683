import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { TEmpAdditionalDataCaseViewModel } from "./CasesInputDTO";

export interface ReclassifyCaseInputDTO {
  caseId: number;
  clientId: number;
  subsidiaryId: number;
  subsidiaryOrganizationId: number;
  baseLevelId: string;
  classify1BranchId: string;
  classify2BranchId: string;
  classify3BranchId: string;
  classify4BranchId: string;
  classify5BranchId: string;
  comments: string;
  reclassificationComments: string;
  reclassificationReasonId: string;
  reclassificationOptionId: string;
  reclassificationOptionSameWorkflowId: string;
  typistGuidUser: string;
  tEmpAdditionalDataCase: TEmpAdditionalDataCaseViewModel[];
  guidCase: string;
  [key: string]: any;
}

export class ReclassifyCaseInputDTOValidator extends Validator<ReclassifyCaseInputDTO> {
  /**
   *
   */
  constructor() {
    super();
    const resourceSet = "ReclassifyCaseComponent";

    try {
      this.ruleFor("baseLevelId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBaseLevelIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId");

      this.ruleFor("classify1BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify1BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify1BranchIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify1BranchId");

      this.ruleFor("classify2BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify2BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify2BranchIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify2BranchId");

      this.ruleFor("classify3BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify3BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify3BranchIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify3BranchId");

      this.ruleFor("classify4BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify4BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify4BranchIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify4BranchId");

      this.ruleFor("classify5BranchId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOClassify5BranchIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidClassify5BranchIdLength")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassify5BranchId");

      this.ruleFor("comments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCommentsEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments");

      this.ruleFor("reclassificationComments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReclassificationCommentsEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationComments");

      this.ruleFor("reclassificationReasonId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReclassificationReasonIdEmpty")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationReasonIdLength",
        )
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationReasonId");

      this.ruleFor("reclassificationOptionId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOReclassificationOptionIdEmpty")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionIdLength",
        )
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidReclassificationOptionId");

      this.ruleFor("reclassificationOptionSameWorkflowId")
        .maxLength(20)
        .withMessage(
          resourceSet +
            "|InputDTOInvalidReclassificationOptionSameWorkflowIdLength",
        )
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionSameWorkflowId",
        );

      this.ruleFor("typistGuidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTypistGuidUserEmpty")
        .maxLength(150)
        .withMessage(resourceSet + "|InputDTOInvalidTypistGuidUser")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidReclassificationOptionSameWorkflowId",
        );
    } catch (error) {
      //TODO log
    }
  }
}
