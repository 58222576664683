import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ProjectDetailEnum as e, TableProjectDetailModel } from "@/models/Project/Projects";
import { UserGroupViewModel } from "@/models/UserGroups/UserGroupModels";
import ProjectReassignTable from "@/pages/Projects/ProjectReassign/ProjectReassignTable";
import { UserGroupService } from "@/services/UserGroupService";
import { CSSProperties, useEffect, useState } from "react";

/**
 * STYLES
 */
const styles = {
    column: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    reassign: {
        color: "#A00095",
        cursor: "pointer",
        textDecoration: "underline",
    } as CSSProperties,
    containerModal: {
        minWidth: "70vw",
    } as CSSProperties,
    modal: {
        borderRadius: "5px",
        padding: "40px 30px",
        border: "none",
        outline: " none",
        gap: "20px",
    } as CSSProperties,
    title: {
        fontWeight: "bold",
        margin: "0",
    } as CSSProperties,
    p: {
        fontSize: "14px",
        margin: "0",
    } as CSSProperties,
    input: {
        width: "250px",
    } as CSSProperties,
    currentQueueLabel: {
        fontSize: "14px",
        fontWeight: "bold",
        margin: "0",
    } as CSSProperties,
    actionSection: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "flex-end",
        alignItems: "flex-end",
        gap: "10px",
    } as CSSProperties,
    cancelButton: {
        backgroundColor: "white",
        color: "#a00095",
        border: "1px solid #a00095",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * project detail card component
 */
export interface ProjectReassignProps {
    /**
     * Messages that are rendered in the view depending on the language
     */
    m: { [attribute: string]: string };
    /**
     * queues available
     */
    queues: Array<TPKeyValue>;
    /**
     * detail of the project queue
     */
    detail: TableProjectDetailModel;
    /**
     * Function responsible for consulting the records that detail the component
     */
    loadDataSource: (sort?: string) => void;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * project reassign component
 */
export default function ProjectReassign({
    m,
    detail,
    queues,
    loadDataSource,
}: ProjectReassignProps) {
    /**
     * ATTRIBUTES
     */
    /**
     * value of the modal if it is open or closed
     */
    const [open, setOpen] = useState<boolean>(false);
    /**
     * selected queue
     */
    const [queue, setQueue] = useState<string>('');
    /**
     * system users by group
     */
    const [users, setUsers] = useState<Array<UserGroupViewModel>>([]);
    /**
     * users selected to be reassigned
     */
    const [selected, setSelected] = useState<Array<string>>([]);
    /**
     * validation to activate or deactivate remapping
     */
    const [disabled, setDisabled] = useState<boolean>(true);
    /**
     * ATTRIBUTES END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
    }, []);
    /**
     * Validates if there are users selected to enable or 
     * disable the reassignment button
     */
    useEffect(() => {
        setDisabled(!(!!queue && selected.length > 0));
    }, [queue, selected]);
    /**
     * event on component close
     */
    useEffect(() => () => {
    }, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * get users from a queue by their respective group
     * @param groupId group identifier
     */
    function getAllGroupByGroupId(groupId: string) {
        const { getAllGroupByGroupId } = new UserGroupService()
        getAllGroupByGroupId(groupId, false, true, [200, 404])
            .then(response => setUsers(response))
            .catch(error => console.error(error))
    }
    /**
     * Open and close the dialog
     */
    function onClickSwitchModalHandler() {
        if (!open) {
            getAllGroupByGroupId(detail.groupId!);
        }
        setOpen(!open);
    }
    /**
     * sets the ID of the selected group
     * @param groupId group identifier
     */
    function onChangeSelectHandler(groupId: string) {
        setQueue(groupId)
    }
    /**
     * is responsible for carrying out the reassignment
     */
    function onClickButtonSaveHandler() {
        const request: any = {
            GroupCurrent: Number(detail.groupId),
            GroupNew: Number(queue),
            GuidUsers: selected,
        }

        const { reassign } = new UserGroupService()
        reassign(request)
            .then(() => {
                loadDataSource()
                setQueue('')
                setSelected([])
                setUsers([])
                setOpen(!open);
            })
            .catch(error => console.error(error))
    }
    /**
     * It is responsible for closing the dialog and clearing the entered data.
     */
    function onClickButtonCloseHandler() {
        setQueue('')
        setSelected([])
        setUsers([])
        setOpen(!open);
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <>
            <TPButton
                id={`reassign-${detail?.queueName}-item`}
                key={`reassign-${detail?.queueName}-item`}
                isDesignSystem
                customType={ButtonCustomType.tertiary}
                onClick={onClickSwitchModalHandler}
            >
                {m?.[e.TableColumnActionReassignCELabel]}
            </TPButton>

            {open && !!detail && (
                <>
                    <div className={`modal${open ? " show" : ""}`}
                        style={{ ...styles.modal, display: open ? "block" : "none" }}
                        tabIndex={-1}
                        data-bs-backdrop="static">
                        <div className="modal-dialog modal-dialog-centered"
                            style={styles.containerModal}>
                            <div className="modal-content"
                                style={styles.modal}>
                                <h5 style={styles.title}>{m?.[e.ModalTitleLabel]}</h5>

                                <div style={{ ...styles.row, gap: "10px" }}>
                                    <p style={styles.currentQueueLabel}>{m?.[e.ModalCurrentQueueLabel]}</p>

                                    <p style={styles.p}>{queues?.find(({ key }) => Number(key) === Number(detail.groupId))?.value}</p>
                                </div>

                                <p style={styles.p}>{m?.[e.ModalOption1Label]}</p>

                                <TPSelect
                                    id="actionInputSort"
                                    key="actionInputSort"
                                    containerStyle={styles.input}
                                    dataSource={queues.filter(({ key }) => Number(key) !== Number(detail.groupId))}
                                    value={`${queue ? queue : ''}`}
                                    onChange={({ target }: any) => onChangeSelectHandler(target.value)}
                                />

                                <p style={styles.p}>{m?.[e.ModalOption2Label]}</p>

                                <ProjectReassignTable
                                    m={m}
                                    users={users}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <div
                                    id={`projectReassignActions${detail.queueName}`}
                                    key={`projectReassignActions${detail.queueName}`}
                                    style={styles.actionSection}
                                >
                                    <TPButton
                                        id={`cancel-reassign-${detail.queueName}-process`}
                                        key={`cancel-reassign-${detail.queueName}-process`}
                                        customType={ButtonCustomType.secondary}
                                        onClick={onClickButtonCloseHandler}
                                        style={{ padding: "1px 18px" }}
                                        isDesignSystem
                                    >
                                        {m?.[e.ModalActionCancelLabel]}
                                    </TPButton>

                                    <TPButton
                                        id={`reassign-${detail.queueName}`}
                                        key={`reassign-${detail.queueName}`}
                                        disabled={disabled}
                                        onClick={onClickButtonSaveHandler}
                                        style={{ padding: "1px 18px" }}
                                        isDesignSystem
                                    >
                                        {m?.[e.ModalActionReassignLabel]}
                                    </TPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${open ? "modal-backdrop show" : ""}`} />
                </>
            )}
        </>
    );
}
