import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { WorkflowDefinitionImportEnum as e } from "@/models/Workflow/WorkflowDefinition";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

/**
 * STYLES
 */
const styles = {
  row: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  } as CSSProperties,
  accordion: {
    marginBottom: "20px",
    border: "2px solid #c7c8c9",
    borderRadius: "5px",
    padding: "5px",
  } as CSSProperties,
  accordionHeader: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    placeContent: "center space-between",
    alignItems: "center",
    padding: "0 5px",
  } as CSSProperties,
  button: {
    width: "30px",
    height: "30px",
    flex: "1 1 30px",
    boxSizing: "border-box",
    maxWidth: "30px",
    borderBottom: "0",
  } as CSSProperties,
  icon: {
    boxSizing: "border-box",
    display: "flex",
    placeContent: "center",
    alignItems: "center",
    color: "blue",
  } as CSSProperties,
  collapse: {
    padding: "0 20px",
  } as CSSProperties,
  description: {
    margin: "0",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface WorkflowDefinitionImportInfoEvents {
  /**
   * allows you to reload the table detail data
   */
  load: () => void;
}
/**
 * properties that the child component needs
 */
export interface WorkflowDefinitionImportInfoProps {
  /**
   * Messages that are rendered in the view depending on the language
   */
  m: { [attribute: string]: string };
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of workflow definition import information
 */
const WorkflowDefinitionImportInfo = forwardRef((
  {
    m,
  }: WorkflowDefinitionImportInfoProps,
  ref
) => {
  /**
   * ATTRIBUTES
   */
  const [open, setOpen] = useState(true);
  /**
   * ATTRIBUTES END
   */

  /**
   * CALLED FATHER COMPONENT
   */
  useImperativeHandle(ref, () => ({
    load() {
    },
  } as WorkflowDefinitionImportInfoEvents));
  /**
   * CALLED FATHER COMPONENT END
   */

  /**
   * EVENT LISTENERS
   */
  /**
   * event when component starts
   */
  useEffect(() => {
  }, []);
  /**
   * event on component close
   */
  useEffect(() => () => {
  }, []);
  /**
   * EVENT LISTENERS END
   */

  /**
   * COMPONENT TO RENDER
   */
  return (
    <div
      id="accordion"
      key="accordion"
      className="accordion"
      style={styles.accordion}
    >
      <div
        id="accordionContainer"
        key="accordionContainer"
        className="accordion-item"
      >
        <div
          id="accordion-header"
          className="accordion-header"
          style={styles.accordionHeader}
        >
          <div
            style={styles.row}>
            <TPIcon
              iconType={TPIconTypes.circleInfo}
              style={styles.icon}
            />

            <span>{m?.[e.InfoSectionTitleLabel]}</span>
          </div>
          
          <button className="accordion-button"
                  style={styles.button}
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapse"
                  aria-expanded={`${open}`} aria-controls="collapse">
          </button>
        </div>

        <div 
          id="collapse"
          className={`accordion-collapse collapse${open ? ' show' : ''}`}
          aria-labelledby="set"
          data-bs-parent="#accordionSets"
          style={styles.collapse}
        >
            <p style={styles.description}>{m?.[e.InfoSectionDescriptionLabel]}</p>
        </div>
      </div>
    </div>
  );
})

export default WorkflowDefinitionImportInfo;
