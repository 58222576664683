import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  ConnectionProtocol,
  IMAPFolder,
  MailboxType,
  SecurityType,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { InboundMailAzureApplicationViewModel } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationViewModel";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPI18N } from "@/services/I18nService";
import { InboundMailAzureApplicationService } from "@/services/InboundMailAzureApplicationService";
import { InboundMailboxesService } from "@/services/InboundMailboxesService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<InboundMailboxesViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface InboundMailboxesAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const InboundMailboxesAdmin = React.forwardRef(
  ({ callBackCommands }: InboundMailboxesAdminInterface, ref) => {
    //Groups called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const componentFileName: string = "InboundMailboxesAdmin.tsx";
    const resourceSet: string = "InboundMailboxesAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [loginColumnLabel, setLoginColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [userEmailAccountColumnLabel, setUserEmailAccountColumnLabel] =
      useState("");
    const [serverColumnLabel, setServerColumnLabel] = useState("");
    const [mailboxTypeColumnLabel, setMailboxTypeColumnLabel] = useState("");
    const [connectionProtocolColumnLabel, setConnectionProtocolColumnLabel] =
      useState("");
    const [securityTypeColumnLabel, setSecurityTypeColumnLabel] = useState("");
    const [portColumnLabel, setPortColumnLabel] = useState("");
    const [folderToReadColumnLabel, setFolderToReadColumnLabel] = useState("");
    const [protocolColumnLabel, setProtocolColumnLabel] = useState("");
    const [
      emailTemplatesNotifyErrorsColumnLabel,
      setEmailTemplatesNotifyErrorsColumnLabel,
    ] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //Value resources
    const [otherMailboxTypeValue, setOtherMailboxTypeValue] = useState("");
    const [basicMailboxTypeValue, setBasicMailboxTypeValue] = useState("");
    const [exchangeOnlineMailboxTypeValue, setExchangeOnlineMailboxTypeValue] =
      useState("");
    const [pop3ConnectionProtocolValue, setPop3ConnectionProtocolValue] =
      useState("");
    const [imapConnectionProtocolValue, setImapConnectionProtocolValue] =
      useState("");
    const [noneSecurityTypeValue, setNoneSecurityTypeValue] = useState("");
    const [autoSecurityTypeValue, setAutoSecurityTypeValue] = useState("");
    const [sslOnConnectSecurityTypeValue, setSslOnConnectSecurityTypeValue] =
      useState("");
    const [startTlsSecurityTypeValue, setStartTlsSecurityTypeValue] =
      useState("");
    const [
      startTlsWhenAvailableSecurityTypeValue,
      setStartTlsWhenAvailableSecurityTypeValue,
    ] = useState("");
    const [flaggedIMAPFolderValue, setFlaggedIMAPFolderValue] = useState("");
    const [draftsIMAPFolderValue, setDraftsIMAPFolderValue] = useState("");
    const [trashIMAPFolderValue, setTrashIMAPFolderValue] = useState("");
    const [inboxIMAPFolderValue, setInboxIMAPFolderValue] = useState("");
    const [junkIMAPFolderValue, setJunkIMAPFolderValue] = useState("");
    const [sentIMAPFolderValue, setSentIMAPFolderValue] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      //Value resources
      setOtherMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "OtherMailboxTypeValue"),
      );
      setBasicMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "BasicMailboxTypeValue"),
      );
      setExchangeOnlineMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "ExchangeOnlineMailboxTypeValue"),
      );
      setPop3ConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "Pop3ConnectionProtocolValue"),
      );
      setImapConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "ImapConnectionProtocolValue"),
      );
      setNoneSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "NoneSecurityTypeValue"),
      );
      setAutoSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "AutoSecurityTypeValue"),
      );
      setSslOnConnectSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "SslOnConnectSecurityTypeValue"),
      );
      setStartTlsSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "StartTlsSecurityTypeValue"),
      );
      setStartTlsWhenAvailableSecurityTypeValue(
        await TPI18N.GetText(
          resourceSet,
          "StartTlsWhenAvailableSecurityTypeValue",
        ),
      );
      setFlaggedIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "FlaggedIMAPFolderValue"),
      );
      setDraftsIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "DraftsIMAPFolderValue"),
      );
      setTrashIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "TrashIMAPFolderValue"),
      );
      setInboxIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "InboxIMAPFolderValue"),
      );
      setJunkIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "JunkIMAPFolderValue"),
      );
      setSentIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "SentIMAPFolderValue"),
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setLoginColumnLabel(await TPI18N.GetText(resourceSet, "Login"));
      setNameColumnLabel(await TPI18N.GetText(resourceSet, "Description"));
      setUserEmailAccountColumnLabel(
        await TPI18N.GetText(resourceSet, "UserEmailAccount"),
      );
      setServerColumnLabel(
        await TPI18N.GetText(resourceSet, "ServerInboundMailbox"),
      );
      setMailboxTypeColumnLabel(
        await TPI18N.GetText(resourceSet, "EmailAccountType"),
      );
      setConnectionProtocolColumnLabel(
        await TPI18N.GetText(resourceSet, "connectionProtocol"),
      );
      setSecurityTypeColumnLabel(
        await TPI18N.GetText(resourceSet, "SecurityType"),
      );
      setPortColumnLabel(await TPI18N.GetText(resourceSet, "Port"));
      setFolderToReadColumnLabel(
        await TPI18N.GetText(resourceSet, "FolderToRead"),
      );
      setProtocolColumnLabel(await TPI18N.GetText(resourceSet, "Protocol"));
      setEmailTemplatesNotifyErrorsColumnLabel(
        await TPI18N.GetText(resourceSet, "EmailTemplatesToNotifyErrors"),
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));


      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const getConnectionTypeValue = (value: any) => {
      switch (value) {
        case ConnectionProtocol.POP3:
          return pop3ConnectionProtocolValue;

        case ConnectionProtocol.IMAP:
          return imapConnectionProtocolValue;

        default:
          return "";
      }
    };

    const getSecurityTypeValue = (value: any) => {
      switch (value.toString()) {
        case SecurityType.None.toString():
          return noneSecurityTypeValue;

        case SecurityType.Auto.toString():
          return autoSecurityTypeValue;

        case SecurityType.SslOnConnect.toString():
          return sslOnConnectSecurityTypeValue;

        case SecurityType.StartTls.toString():
          return startTlsSecurityTypeValue;

        case SecurityType.StartTlsWhenAvailable.toString():
          return startTlsWhenAvailableSecurityTypeValue;

        default:
          return "";
      }
    };

    const getMailboxTypeValue = (value: any) => {
      switch (value) {
        case MailboxType.BASIC:
          return basicMailboxTypeValue;

        // case MailboxType.OTHER:
        //     return otherMailboxTypeValue;

        case MailboxType.EXCHANGEONLINE:
          return exchangeOnlineMailboxTypeValue;

        default:
          return "";
      }
    };

    const getIMAPFolderValue = (value: any) => {
      switch (value) {
        case IMAPFolder.Flagged:
          return flaggedIMAPFolderValue;

        case IMAPFolder.Drafts:
          return draftsIMAPFolderValue;

        case IMAPFolder.Trash:
          return trashIMAPFolderValue;

        case IMAPFolder.Inbox:
          return inboxIMAPFolderValue;

        case IMAPFolder.Junk:
          return junkIMAPFolderValue;

        case IMAPFolder.Sent:
          return sentIMAPFolderValue;

        default:
          return "";
      }
    };

    const getInboundMailAzureApplicationById = async (
      idInboundMailAzureApp: string,
    ) => {
      let serviceClient = new InboundMailAzureApplicationService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest =
          await serviceClient.getInboundMailAzureApplicationById(
            idInboundMailAzureApp,
            false,
            true,
            expectedCodes,
          );
        let recordInfo: InboundMailAzureApplicationViewModel;
        recordInfo = { ...responseRequest };

        return recordInfo;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailAzureApplicationById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getInboundMailAzureApplicationById ex`,
        );
        return null;
      }
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    const handleLoginClick = async (
      idInboundMailbox: string,
      authenticationAzureAppId: string,
    ) => {
      let inboundMailAzureApp: InboundMailAzureApplicationViewModel | null;

      inboundMailAzureApp = await getInboundMailAzureApplicationById(
        authenticationAzureAppId,
      );

      window.open(
        `${inboundMailAzureApp?.authenticationURL}?tenantIdHex=${TPGlobal.tenantHex}&inboundMailboxId=${idInboundMailbox}`,
        "loginAuthentication",
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=600,left=100,top=100",
      );
    };

    //Get groups by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new InboundMailboxesService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getInboundMailboxesByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);

        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Group
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete Group after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new InboundMailboxesService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteInboundMailboxById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "description"];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search === "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemValue: any;
          let itemany: any;
          itemany = item;
          if (searcheableColumns[i].split(".").length === 2) {
            let itemSub: any = searcheableColumns[i].split(".");
            itemValue = itemany[itemSub[0]][itemSub[1]];
          } else {
            itemValue = itemany[searcheableColumns[i]];
          }

          if (
            itemValue &&
            itemValue.toString().toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = dataDownloadFormat(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    const dataDownloadFormat = () => {
      const data = filteredData();
      let newData: any = [];
      data.forEach((item) => {
        newData.push({
          [idColumnLabel]: item.id,
          [nameColumnLabel]: item.localizedDescription,
          [userEmailAccountColumnLabel]: item.emailAccount,
          [serverColumnLabel]: item.serverEmailAccount,
          [mailboxTypeColumnLabel]: item.authenticationType,
          [connectionProtocolColumnLabel]: item.protocolTypeEmailAccount,
          [securityTypeColumnLabel]: getSecurityTypeValue(item.sslType),
          [portColumnLabel]: item.port,
          [folderToReadColumnLabel]: item.imapFolder,
          [protocolColumnLabel]: item.sslProtocol,
          [emailTemplatesNotifyErrorsColumnLabel]: item.templateProcessErrorId,
        });
      });
      return newData;
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    const InboundMailAzureColumns: CustomColumnNames<InboundMailboxesViewModel> = {
      id: idColumnLabel,
      authenticationAzureAppId:loginColumnLabel,
      description:nameColumnLabel,
      emailAccount:userEmailAccountColumnLabel,
      serverEmailAccount:serverColumnLabel,
      authenticationType:mailboxTypeColumnLabel,
      protocolTypeEmailAccount:connectionProtocolColumnLabel,
      sslType:securityTypeColumnLabel,
      port:portColumnLabel,
      imapFolder:folderToReadColumnLabel,
      sslProtocol:protocolColumnLabel,
      templateProcessErrorId:emailTemplatesNotifyErrorsColumnLabel,
      isActive: filterIsActiveLabel,
    };
 
    const customColumns: ColumnStyles<InboundMailboxesViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
     
    };

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];
 
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string; }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string; }) => handleDeleteClick(e.id),
      },
    ];
 
    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    const getHideenColumns = (): (keyof InboundMailboxesViewModel)[] => {
      const hiddenColumns: (keyof InboundMailboxesViewModel)[] = [
      "customerTypeId",
      "defaultClassifierId",
      "emailAddressProcessError",
      "enableEditingCaseComments",
      "isActive",
      "languageId",
      "localizedDescription",
      "onExistingCustomerAction",
      "onExistingCustomerFixedSubsidiaryOrganizationId",
      "onNewCustomerAction",
      "onNewCustomerFixedSubsidiaryOrganizationId",
      "passwordEmailAccount",
      "reopenCaseOnAdd",
      "responsibleGroupId",
      "templateAutoRespondeId",
      "templateAutoResponderSubsequentEmailId",
      ];
      return hiddenColumns;
    };

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
         <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                      <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                      <TPButton
                        id="new-inbound-type"
                        isDesignSystem={true}
                        onClick={handleNewClick}
                        withIcon={TPIconTypes.add}
                        orientationIcon="left"
                        style={{ padding: "1px 18px" }}
                      >
                        {newLabel}
                      </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={InboundMailAzureColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={getHideenColumns()}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
      </TPLoadingOverlay>
        
      </>
    );
  },
);

export default InboundMailboxesAdmin;
