import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailboxesInputDTO } from "@/models/InboundMailboxes/InboundMailboxesInputDTO";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import { InputAction } from "../models/InboundMailWithErrors/InboundMailWithErrorsModel";

export class InboundMailErrorService {
    serviceFileName: string = "IboundMailsWithErrors.ts";

    public async getIboundMailsWithErrorsByFilter(
        inboundMailboxId: string,       
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<Array<any>> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/inboundmailboxread/errors?mainboxId=";
        url += inboundMailboxId;
      

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getInboundMailboxesByFilter ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
            );
            throw new Error(
                `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
            );
        }
    }


    public async deleteIboundMailsWithErrorsById(
        Id: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/inboundmailbox/" + Id;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.deleteData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} deleteInboundMailboxById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
            throw new Error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
        }
    }

    public async actionByListId(
        mailIDs: Array<string>,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>,
    ): Promise<any> {
        let httpclient = new TPHTTPService();
        let url: string;
        url = "/inboundmailboxread/mailwitherrorretries";

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let data = new InputAction();              
        data.mailIDs = mailIDs.join("|");
        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                data,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} deleteInboundMailboxById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
            throw new Error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
        }
    }



}
