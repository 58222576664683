import { TPI18N } from "@/services/I18nService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import e from "../models/EmailTemplateCloneEnum";
import { EmailTemplateCloneFormSlice } from "./EmailTemplateCloneFormSlice";

const { getAllMailAccounts } = new OutboundMailAccountService();

function* message({ payload }: PayloadAction<Array<string>>) {
  try {
    for (const attribute of payload) {
      const message: string = yield TPI18N.GetText(
        e.EmailTemplateCloneFormComponent,
        attribute
      );
      yield put(
        EmailTemplateCloneFormSlice.actions.messageSuccess({
          attribute,
          message,
        })
      );
    }
  } catch (error) {
    const message: string = yield TPI18N.GetText(
      e.EmailTemplateCloneFormComponent,
      e.CloneGetMessageErrorMessage
    );
    yield put(
      EmailTemplateCloneFormSlice.actions.messageError({ error, message })
    );
  }
}

function* getMailAccounts() {
  try {
    // @ts-ignore
    const response: any = yield getAllMailAccounts(false, true, [200, 404]);
    yield put(
      EmailTemplateCloneFormSlice.actions.getMailAccountsSuccess(response)
    );
  } catch (error) {
    const message: string = yield TPI18N.GetText(
      e.EmailTemplateCloneFormComponent,
      e.CloneSaveErrorMessage
    );
    yield put(
      EmailTemplateCloneFormSlice.actions.getMailAccountsError({
        error,
        message,
      })
    );
  }
}

export function* EmailTemplateCloneFormSaga() {
  yield all([
    takeEvery(EmailTemplateCloneFormSlice.actions.message, message),
    takeEvery(
      EmailTemplateCloneFormSlice.actions.getMailAccounts,
      getMailAccounts
    ),
  ]);
}
