
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { CaseService } from "@/services/CaseService";
import React, { forwardRef, useEffect, useReducer, useState } from "react";
import { RightRowContainerStyle } from "../FormDesigner/StyleFromDesigner";
import { TPPageSection, TPPageSectionTitle, TPPageTitle } from "../../components/TPPage/tpPageStyles";
import TPWizard from "../../components/TPWizard/TPWizard";
import TPWizardStep from "../../components/TPWizard/TPWizardStep";
import TPGlobal from "../../helpers/TPGlobal";
import TPWizardContent from "../../components/TPWizard/TPWizardContent";
import TPSelect from "../../components/bootstrap/forms/select/TPSelect";
import { TimeZonesService } from "../../services/TimeZonesService";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPDatePicker from "../../components/TPDatePicker/TPDatePicker";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import TPRadioGroup from "../../components/bootstrap/forms/radio/TPRadioGroup";
import { TPActiveOptions, TPButtonTypes, TPIconTypes, TPQuickSelectDatesEnum } from "../../models/Global/TPGlobalEnums";
import { CaseCreationStatsService } from "../../services/CaseCreationStatsService";
import TPModalSuccess from "../../layouts/TPModalSuccess/TPModalSuccess";
import TPModalMsgAlertOk, { enumTypeAlert } from "../../layouts/TPModalMsgAlertOk/TPModalMsgAlertOk";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { DateEditor } from "handsontable/editors";
import DynamicTable, { ColumnStyles, CustomColumnNames, ExportCustomValues } from "../../modules/core/components/dynamic-table/DynamicTable";
import { UserStatsModel, UserStatsModelGrid, inputDashboardModel } from "../../models/CaseCreationStats/CaseCreationStats";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { useStatusSearchIncomingMail } from "./useStatusSearchIncomingMail";
import { LocationLanguagesSearchIncomingMail, LocationNameGlobal, LocationNameTable } from "./SearchIncomingMailLocalization";
import { SearchIncomingMailAdminService } from "../../services/SearchIncomingMailAdminService";
import { EmailData, SearchIncomingMailModel, SearchIncomingMailModelInput } from "../../models/SearchIncomingMail/SearchIncomingMail";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import { ContainerBodyStyle, PopupContainerChildreStyle, PopupContainerStyle } from "./StyleSearchIncomingMail";
import { InboundMailboxesService } from "../../services/InboundMailboxesService";




interface Model {
    callBackCommands: Function;
}

enum pageEnum {
    filter,
    List
}

enum pageContainerEnum {
    Calendar
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type commandType = {
    type: commandsEnum;
    payload: any;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}


const SearchIncomingMailAdmin = forwardRef(
    ({ callBackCommands }: Model, ref) => {

        const casecontext: any = React.useContext(TPCaseViewerContext);
        const [location, setLocation] = useState(new LocationNameTable);
        const [locationGlobal, setlocationGlobal] = useState(new LocationNameGlobal);
        const [gridTemp, setGridTemp] = useState(new Array<EmailData>());


        const [timezone, setTimezone] = useState(TPGlobal.currentUserTimeZoneId);
        const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>([]);
        const [organizationListKeyValue, setOrganizationListKeyValue] = useState<Array<TPKeyValue>>([]);
        const [startDate, setStartDate] = useState<Date>(new Date());
        const [endDate, setEndDate] = useState<Date>(new Date());
        const [quickSelectDates, setQuickSelectDates] = useState(TPQuickSelectDatesEnum.today);
        const [modeAlert, setModeAlert] = useState(enumTypeAlert.success);
        const [page, setPage] = useState(pageEnum.filter);
        const [modalAlert, setModalAlert] = useState(false);
        const [groupSelected, setGroupSelected] = useState("");
        const [groupError, setGroupError] = useState("");
        const [timeZoneErrorMessage, setTimeZoneErrorMessage] = useState("");
        const [groupChangeErrorMessage, setGroupChangeErrorMessage] = useState("");
        const [msgAlert, setMsgAlert] = useState("");
        const [pageActive, setPageActive] = useState("1");
        const [dateStartActive, setDateStartActive] = useState("");
        const [bodyText, seBodyText] = useState("");

        const loadResourcesAndLoadInfo = async () => {
            setLoading(true);
            let temp = new LocationLanguagesSearchIncomingMail();
            await temp.handlelocationTable(resourceSet);
            setLocation(temp.getLocationNameTable);

            let tempGlobal = new LocationLanguagesSearchIncomingMail();
            await tempGlobal.handlelocationGlobal(TPGlobal.globalResourceSet);
            setlocationGlobal(tempGlobal.getLocationNameGlobal);

            await handleGetTimezones();
            await handleGetGroupList();

            setLoading(false);
        }

        //UTIL
        const quickSelectDatesValue = [
            {
                key: TPQuickSelectDatesEnum.today,
                value: location.todayLabel,
            },
            {
                key: TPQuickSelectDatesEnum.yesterday,
                value: location.yesterdayLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentWeek,
                value: location.currentWeekLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastWeek,
                value: location.lastWeekLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentMonth,
                value: location.currentMonthLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastMonth,
                value: location.lastMonthLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentYear,
                value: location.currentYearLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastTwelveMonths,
                value: location.lastTwelveMonthsLabel,
            },
        ];
        function capitalizeFirstLetter(text: string): string {
            if (!text) return text;
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }

        const handleValidate = () => {
            let status = true;

            setGroupChangeErrorMessage("");
            setGroupError("");

            if (timezone == "") {
                setTimeZoneErrorMessage(locationGlobal.inputRequire);
                status = false;
            }


            if (groupSelected == "") {
                setGroupError(locationGlobal.inputRequire);
                setGroupChangeErrorMessage(locationGlobal.inputRequire);
                status = false;
            }

            if (status) setPage(pageEnum.List);

            if (startDate <= endDate && (status)) {
                setModalAlert(false);
                setLoading(true);
                handleGetData();
            } else if (status) {
                // setModeAlert(enumTypeAlert.error);
                //setModalAlert(true);
            }
        }

        const handleValidateSelect = (e: string) => {

            let item = Number.parseInt(e);
            let select = 1;

            if (page == pageEnum.filter) select = 1;
            if (page == pageEnum.List) select = 2;

            if (item < select) return true;

            return false;
        }

        const getFirstDayOfTheMonth = (year: any, month: any) => {
            return new Date(year, month, 1);
        };

        const getPreviousMonday = (date: any) => {
            const previousMonday = new Date();
            previousMonday.setDate(date.getDate() - date.getDay() + 1);
            return previousMonday;
        };

        const getPreviousSunday = (date: any) => {
            const previousSunday = new Date();
            previousSunday.setDate(date.getDate() - date.getDay());
            return previousSunday;
        };

        const handleConvertoMailSearch = (emailDataList: EmailData[]): Array<SearchIncomingMailModel> => {

            return emailDataList.map(emailData => mapEmailDataToSearchIncomingMail(emailData));
        }

        const mapEmailDataToSearchIncomingMail = (emailData: EmailData): SearchIncomingMailModel => {
            return {
                id: emailData.id,
                sourceType: "Inbound Mail",
                accountDescription: emailData.mailboxLocalized,
                caseNumber: emailData.caseId + "",
                insertDateString: emailData.insertDateString,
                sender: emailData.fromName + "--" + emailData.fromAddress,
                subject: emailData.subject,
                textBody: emailData.htmlBody,
                insertDate: emailData.insertDate,
                date: emailData.emailLocalDate                
            };
        }

        const handleStatus = (value: string): TPKeyValue => {

            if (value == "Pendig") return { key: "MailWithError", value: "green" };

            return { key: "Processed", value: "red" };
        }

        const handleTextLimit = (value: string) => {
            if (!value) return "";
            if (value.length <= 25) return value;
            return value.slice(0, 25) + "...";
        }

        const handleplainTextBody = (id: number): string => {

            let data = [...gridTemp];
            let filter = data.find(s => s.id == id);
            if (!filter) return "";

            return filter.plainTextBody;
        }

        const handleClearBody = () => {
            seBodyText("");
        }

        //CHANGED
        const handleGroupChange = async (e: any) => {
            setGroupChangeErrorMessage("");
            let newOrganization: string = e.target.value;
            setGroupSelected(newOrganization);
        };

        const handleChangeStartDate = (date: Date) => {
            setStartDate(date);
        };

        const handleChangeEndDate = (date: Date) => {
            setEndDate(date);
        };

        const changeDateSelectorHandler = (value: any) => {
            setQuickSelectDates(value);
            const today = new Date();
            const thisYear = today.getFullYear();
            const thisMonth = today.getMonth();
            const previousMonday = getPreviousMonday(today);
            const previousSunday = getPreviousSunday(today);
            switch (value) {
                case TPQuickSelectDatesEnum.today:
                    setStartDate(today);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.yesterday:
                    const yesterdayMilliseconds = today.getTime() - 24 * 60 * 60 * 1000;
                    const yesterdayDate = new Date(yesterdayMilliseconds);
                    setStartDate(yesterdayDate);
                    setEndDate(yesterdayDate);
                    break;
                case TPQuickSelectDatesEnum.currentWeek:
                    setStartDate(previousMonday);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastWeek:
                    const mondayLastWeek =
                        previousSunday.getTime() - 6 * 24 * 60 * 60 * 1000;
                    const mondayLastWeekDate = new Date(mondayLastWeek);
                    setStartDate(mondayLastWeekDate);
                    setEndDate(previousSunday);
                    break;
                case TPQuickSelectDatesEnum.currentMonth:
                    const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
                    setStartDate(firstDayOfThisMonth);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastMonth:
                    const firstDayPreviousMonth = new Date(thisYear, thisMonth - 1, 1);
                    setStartDate(firstDayPreviousMonth);
                    const lastDayPreviousMonth = new Date(thisYear, thisMonth, 0);
                    setEndDate(lastDayPreviousMonth);
                    break;
                case TPQuickSelectDatesEnum.currentYear:
                    const firstDayOfThisYear = new Date(thisYear, 0, 1);
                    setStartDate(firstDayOfThisYear);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastTwelveMonths:
                    const twelveMonthsAgo = new Date(thisYear - 1, thisMonth, 1);
                    setStartDate(twelveMonthsAgo);
                    setEndDate(today);
                    break;
                default:
                    break;
            }
        };

        const handleSearchButtonClick = async (id: string) => {
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];

            try {
                setLoading(true);

                let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
                    Number.parseInt(id),
                    false,
                    false,
                    expectedCodes
                );

                if (isValidCaseNumber) {
                    casecontext.handleAddNewCaseViewerCallBack(id);
                }
                setLoading(false);
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleSearchButtonClick ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} handleSearchButtonClick ex`);
                setLoading(false);
            }
        };


        //CRUD
        const handleGetGroupList = async (query?: string) => {
            let tasktypeService = new InboundMailboxesService();
            let expectedCodes: Array<number> = [200, 404];
            // setBaseLanguageOptions(new Array<TPKeyValue>);

            if (query == undefined) query = "";
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getInboundMailboxesByFilter(
                        "1",
                        false,
                        true,
                        expectedCodes
                    );

                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.id,
                            value: `${item.description}`,
                        };
                    }
                );
                newToKeyValueList.push({ key: "", value: "--" });
                setGroupSelected("");
                setOrganizationListKeyValue(newToKeyValueList);
                setLoading(false);
                return newToKeyValueList; // filter
            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }


        };

        const handleGetData = async () => {
            let tasktypeService = new SearchIncomingMailAdminService();
            let expectedCodes: Array<number> = [200, 404];
          
            setLoading(true);

            let data = new SearchIncomingMailModelInput();
            data.endDate = endDate;
            data.initialDate = startDate;
            data.timeZone = timezone;
            data.mailboxId = groupSelected


            try {               
                let responseRequest =
                    await tasktypeService.getFilter(
                        data,
                        false,
                        false,
                        expectedCodes
                    );

                setGridTemp(responseRequest);

                let gridList = [...handleConvertoMailSearch(responseRequest)];
                gridList = gridList.filter(s => s.id != -1);

                let invalidDateRangeTemp = [...handleConvertoMailSearch(responseRequest)].find(s => s.id == -1);

                let dateNew;


                if (invalidDateRangeTemp) {

                    const dateString: string = invalidDateRangeTemp.date;
                    const [year, month, day] = dateString.split('/');
                    dateNew = new Date(Number(year), Number(month) - 1, Number(day));                    
                }


                if (invalidDateRangeTemp != undefined && dateNew && startDate < dateNew) {

                    setDateStartActive(invalidDateRangeTemp.date);
                    setModeAlert(enumTypeAlert.error);
                    setModalAlert(true);
                }
                else
                    if (!Array.isArray(gridList) || gridList.length <= 0) {
                        setModeAlert(enumTypeAlert.success);
                        setModalAlert(true);
                    }


                let command1: commandType = {
                    type: commandsEnum.reload_grid,
                    payload: gridList.map(({
                        id,
                        sourceType,
                        accountDescription,
                        caseNumber,
                        sender,
                        subject,
                        textBody,
                        insertDate,
                        insertDateString,
                    }: SearchIncomingMailModel) => {
                        return {
                            id,
                            sourceType,
                            accountDescription,
                            caseNumber,
                            sender,
                            subject,
                            textBody,
                            insertDate,
                            insertDateString,
                        };
                    })
                }
                dispatchCommand(command1);
                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }
        };

        const handleGetTimezones = async () => {
            const TimeZoneClient = new TimeZonesService();
            const expectedCodes: Array<number> = [200, 404];
            const timezones = await TimeZoneClient.getTimeZonesCollection(
                false,
                true,
                expectedCodes
            );
            const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
                return {
                    key: k.id,
                    value: k.displayName,
                };
            });
            timezonesToSelectConverted.unshift({ key: "", value: "--" });
            setTimezone("");
            setTimeZonesToSelect(timezonesToSelectConverted);
            setLoading(false);
        };

        //TABLE
        const columnStyles: ColumnStyles<SearchIncomingMailModel> = {
            sourceType: ({ value, item }) => (
                <div
                    style={{
                        textAlign: "start",
                        display: "flex",
                        justifyContent: "start",
                        gap: "3px",
                        alignItems: "center"                      
                    }}
                >
                    <TPIcon iconType={TPIconTypes.email} style={{ color: "#a00095", cursor:"auto" }} /> <TPLabel labelText={value} />
                </div>
            ),
            accountDescription: ({ value, item }) => (
                <TPLabel style={{ textAlign: "start" }} labelText={value} />
            ),
            caseNumber: ({ value, item }) => (
                <div style={{
                    display: "flex", alignItems: "center", width: "100%", justifyContent: "center"
                }} onClick={() => { handleSearchButtonClick(value); }} >
                    <TPLabel style={{ textAlign: "center", color: "#b276be", cursor: "pointer" }} labelText={value} />
                </div>
            ),           
            sender: ({ value, item }) => (
                <div>
                    <TPLabel style={{ textAlign: "start" }} labelText={value} />
                </div>
            ),
            subject: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }} >
                    <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(value)} />
                    <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                </div>
            ),
            textBody: ({ value, item }) => (
                <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }}>
                    <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(handleplainTextBody(item.id))} />
                    <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                </div>
            ),
            insertDateString: ({ value, item }) => (
                <div>
                    <TPLabel style={{ textAlign: "start" }} labelText={value} />
                </div>
            )


        };

        const treeColumns: CustomColumnNames<SearchIncomingMailModel> = {
            sourceType: location.sourceType,
            accountDescription: location.accountDescription,
            caseNumber: location.caseNumber,
            insertDateString: location.insertDate,
            sender: location.sender,
            subject: location.subject,
            textBody: location.textBody,
            insertDate: location.insertDate            
        };

       
        const customExport: ExportCustomValues<SearchIncomingMailModel> = {
            textBody: ({ value, item }) => (handleplainTextBody(item.id))
        };

        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop) handleGetData();
        };

        const setTreeTableData = (prevState: AdminStateType): AdminStateType => {
            let newState: AdminStateType = { ...prevState };
            newState.gridColumns = [prevState.gridData];
            return newState;
        };


        const value = useStatusSearchIncomingMail();
        const {
            adminState,
            dispatchCommand,
            resourceSet,
            setLoading,
            loading
        } = value;

        useEffect(() => {
            setLoading(true);
            loadResourcesAndLoadInfo();
        }, []);


        return (
            <div style={{ position: "relative" }}  >
                {bodyText != "" &&
                    <PopupContainerStyle onClick={() => { seBodyText("") }}>
                        <PopupContainerChildreStyle  >
                            <TPIcon iconType={TPIconTypes.home} style={{
                                display: 'flex',
                                right: '0px',
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                backgroundColor: '#a00095',
                                color: "white",
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 0px 5px gray',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }} onClick={() => { seBodyText("") }} />
                            <ContainerBodyStyle>
                                <span dangerouslySetInnerHTML={{ __html: bodyText }} />
                            </ContainerBodyStyle>
                        </PopupContainerChildreStyle>
                    </PopupContainerStyle>
                }

                <TPLoadingOverlay active={loading}>
                    <>
                        {modalAlert &&
                            <TPModalMsgAlertOk
                                modalState={{
                                    modeAlert: modeAlert,
                                    yesLabel: locationGlobal.okAlertButton,
                                    isShown: true,
                                    callBackFunction: () => {
                                        setPage(pageEnum.filter);
                                        setModalAlert(false);
                                    },
                                }} >
                                <TPLabel
                                    labelText={modeAlert == enumTypeAlert.error ? location.msgError.replace("yyyy/mm/dd", " " + dateStartActive) : location.msgOkAlert} />
                            </TPModalMsgAlertOk>
                        }

                        <RightRowContainerStyle>
                            <TPPageTitle> {location.title}</TPPageTitle>
                        </RightRowContainerStyle>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <TPWizard>
                                    <TPWizardStep
                                        activeNewComponet={true}
                                        isCheck={handleValidateSelect("1")}
                                        addLine={true}
                                        isActive={(page == pageEnum.filter)}
                                        id={"id_TP_wizar_1" + resourceSet}
                                        labelText={locationGlobal.filterLabel}
                                        numberText="1"
                                        name="TPWizardStep"
                                        callBackCommands={(e: string) => {
                                            setPage(pageEnum.filter);
                                        }}
                                    />
                                    <TPWizardContent
                                        id="tabFilters"
                                        isActive={(page == pageEnum.filter)}
                                        name="TPWizardContent"
                                    >
                                        <div className="row">
                                            <div className="col-11">
                                                <TPPageSection>
                                                    <div style={{ display: "flex", gap: "5px", justifyContent: "start", marginTop: "10px" }}>
                                                        <div style={{ display: "flex", gap:"10px" }} >
                                                        <TPDatePicker
                                                            selectedDate={startDate}
                                                            onChangeDate={(e: Date) =>
                                                                handleChangeStartDate(e)
                                                            }
                                                            labelText={location.startDateLabel}
                                                            isHorizontal={false}
                                                            isMandatory={true}
                                                        ></TPDatePicker>
                                                        <TPDatePicker
                                                            selectedDate={endDate}
                                                            onChangeDate={(e: Date) =>
                                                                handleChangeEndDate(e)
                                                            }
                                                            labelText={location.endDateLabel}
                                                            isHorizontal={false}
                                                            isMandatory={true}
                                                            ></TPDatePicker>
                                                            {timeZoneErrorMessage != "" &&
                                                                <div style={{ height:"85px" }} ></div>
                                                            }
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} >
                                                            <TPLabel
                                                                labelText={location.timezoneLabel}
                                                                isMandatory={true}
                                                            />
                                                            <TPSelect
                                                                id={"id_select2_" + resourceSet}
                                                                labelText={""}
                                                                isMandatory={false}
                                                                withIcon={false}
                                                                value={timezone}
                                                                containerStyle={{ display: "flex", flexDirection: "column" }}
                                                                onChange={(e: any) => {
                                                                    setTimezone(e.target.value);
                                                                    setTimeZoneErrorMessage("");
                                                                }}
                                                                dataSource={timezonesToSelect}
                                                                errorMessage={timeZoneErrorMessage}
                                                            />
                                                        </div>
                                                    </div>
                                                </TPPageSection>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle>
                                                    {location.quickSelectDatesLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <TPRadioGroup
                                                        id={"IdRadioGroup_1" + resourceSet}
                                                        onChange={(e: any) =>
                                                            changeDateSelectorHandler(e.target.value)
                                                        }
                                                        value={quickSelectDates}
                                                        source={quickSelectDatesValue}
                                                    ></TPRadioGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle>
                                                    {location.moreFilter}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div style={{ maxWidth: "300px", marginTop: "10px" }}>
                                            <TPSelect
                                                id={"ISelect1_" + resourceSet}
                                                onChange={handleGroupChange}
                                                dataSource={organizationListKeyValue}
                                                value={groupSelected}
                                                labelText={location.accountFilter}
                                                isHorizontal={false}
                                                isMandatory={true}
                                                errorMessage={groupChangeErrorMessage}
                                            />
                                        </div>

                                        <div
                                            style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "20px" }}
                                        >
                                            <TPButton
                                                id={"Id_btn_1" + resourceSet}
                                                type={TPButtonTypes.primary}
                                                onClick={() => { handleValidate(); }}
                                            >
                                                {locationGlobal.search}
                                            </TPButton>
                                        </div>
                                        <div style={{ paddingBottom: "240px" }} ></div>
                                    </TPWizardContent>
                                    <TPWizardStep
                                        activeNewComponet={true}
                                        addLine={false}
                                        isCheck={handleValidateSelect("2")}
                                        isActive={(page == pageEnum.List)}
                                        id={"Id_results_2" + resourceSet}
                                        labelText={locationGlobal.resultsLabel}
                                        numberText="2"
                                        name="TPWizardStep"
                                        callBackCommands={(e: string) => {
                                            setPage(pageEnum.List);
                                        }}
                                    />
                                    <TPWizardContent
                                        id={"Id_tab_results_" + resourceSet}
                                        isActive={(page == pageEnum.List)}
                                        name="TPWizardContent"
                                    >
                                        <br></br>
                                        <DynamicTable
                                            id={resourceSet}
                                            data={adminState.gridData}
                                            withPreferences={true}
                                            columnNames={treeColumns}
                                            exportCustomValues={customExport}
                                            hiddenColumns={["id", 'insertDate']}
                                            onIconClicked={event => handleIconClick(event)}
                                            columnStyles={columnStyles}
                                        />
                                    </TPWizardContent>
                                </TPWizard>
                            </div>
                        </div>
                    </>
                </TPLoadingOverlay>
            </div >
        );
    }
);

export default SearchIncomingMailAdmin;
