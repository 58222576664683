import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { Validator } from "fluentvalidation-ts";

export interface WorkflowTypeInputDTO {
  id: string;
  description: string;
  promiseLimitUnit: string | null;
  promiseLimit: number | null;
  isDirectSolution: boolean;
  isActive: boolean;
  otherLocalizedValues: RecordLocalizedModel[];
}

export class WorkflowTypeInputDTOValidator extends Validator<WorkflowTypeInputDTO> {
  constructor() {
    super();

    const resourceSet = "WorkflowTypeInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("promiseLimitUnit")
        .must((name) => {
          if (name) {
            return TPGlobal.TPSanitize(name) == name;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidPromiseLimitUnit")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidPromiseLimitUnitLength");

      this.ruleFor("promiseLimitUnit")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmptyWhenPromiseLimit")
        .notNull()
        .withMessage(resourceSet + "|InputDTODescriptionEmptyWhenPromiseLimit")
        .when((x) => {
          if (x.promiseLimit) {
            if (x.promiseLimit > 0) {
              return true;
            }
          }
          return false;
        });
      this.ruleFor("promiseLimit")
        .greaterThan(0)
        .withMessage(resourceSet + "|InputDTOPromiseLimitGreaterThan")
        .when((x) => {
          if (x.promiseLimitUnit) {
            return true;
          }
          return false;
        });

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 100;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
    } catch (error) {}
  }
}
