
import { useReducer, useState } from "react";




enum commandsEnum {
    defaul,
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

const calendar = [
    {
        name: "",
        date: "1999-01-01",
        id: "",
        idCase: "",
        description: ""
    }
];

//Array<CalendaryDataModel>


export const useStatusDistributionListAdmin = () => {
    const resourceSet: string = "MyReminders";

    const [statusModel, setStatusModel] = useState<Array<any>>([]);
    const [calendarReminders, setCalendarReminders] = useState(calendar);
    const [loading, setLoading] = useState(false);


    const reducerAction = (status = statusModel, action: { type: commandsEnum, payload: any }) => {

        switch (action.type) {

            case commandsEnum.defaul:
                if (status == undefined || status == null) status = Array<any>();
                var temp = [...status];
                temp.push(action.payload);
                return temp;
                break;
            default:
                return status;
        }
    }

    const [status, dispatch] = useReducer(reducerAction, new Array<any>);


    return {
        status,
        statusModel,
        setStatusModel,
        resourceSet,
        loading,
        calendarReminders,
        setLoading,
        setCalendarReminders
    };
}


