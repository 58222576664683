import React from "react";
import TPWizardStep, { ITPWizardStep } from "./TPWizardStep";

interface ITPWizardSteps {
  children?: any;
  tpWizardSteps: ITPWizardStep[];
}

const TPWizardSteps = ({ children, tpWizardSteps }: ITPWizardSteps) => {
  return (
    tpWizardSteps && (
      <ul className="nav nav-pills" role="tablist">
        {tpWizardSteps.map((step: ITPWizardStep) => {
          return <TPWizardStep key={step.id} {...step}></TPWizardStep>;
        })}
      </ul>
    )
  );
};

export default TPWizardSteps;
