import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";

import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./Styles.css";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { AdditionalFilter } from "@/helpers/TPKeyValue";
import { TableIcon } from "../Projects/ProjectDetail/ProjectDetailTableIcons";

type AdminStateType = {
  gridColumns: Array<any>;
  gridData: Array<AdditionalDataViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
  selectedRows: string[];
};

interface AdditionalDataAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "row_select" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

let modalQuestionInitialState: TPModalQuestionState = {
  isShown: false,
  callBackData: {},
};

const resourceSet: string = "AdditionalDataAdminComponent";

const AdditionalDataAdmin = React.forwardRef(
  ({ callBackCommands }: AdditionalDataAdminInterface, ref) => {
    const componentFileName: string = "AdditionalDataAdmin.tsx";
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const [titleLabel, setTitleLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [additionalBulkLoadLabel, setAdditionalBulkLoadLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [dataTypeColumnLabel, setDataTypeColumnLabel] = useState("");
    const [dataCategoryColumnLabel, setDataCategoryColumnLabel] = useState("");
    const [dataCommentsColumnLabel, setDataCommentsColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [exportSelectionLabel, setExportSelectionLabel] = useState("");
    const [selectAllLabel, setSelectAllLabel] = useState("");

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const loadResourcesAndAdditionalDataFilter = async () => {
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setAdditionalBulkLoadLabel(
        await TPI18N.GetText(resourceSet, "AdditionalBulkLoadButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "Description")
      );
      setDataTypeColumnLabel(await TPI18N.GetText(resourceSet, "DataType"));
      setDataCategoryColumnLabel(
        await TPI18N.GetText(resourceSet, "DataCategory")
      );
      setDataCommentsColumnLabel(
        await TPI18N.GetText(resourceSet, "DataComments")
      );
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(resourceSet, "isSystemRecordLabel")
      );
      setSelectAllLabel(await TPI18N.GetText(resourceSet, "SelectAllLabel"));
      setExportSelectionLabel(
        await TPI18N.GetText(resourceSet, "ExportSelectionLabel")
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
    };

    //Get functions by Filter
    const reloadDataGrid = async () => {
      let serviceClient = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getAll(
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result.map((entry: any) => {
              return { ...entry, selected: false };
            }),
          };
          setData(result);
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };
    //New Additional data
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update Additional data
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();

      if (event === TPIconTypes.downloadTemplate) handleNewBulLoadTab();
    };

    //Modal Question to delete additional data
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new AdditionalDataService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteById(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      gridColumns: [],
      gridData: [],
      searchPattern: "",
      selectedRows: [],
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<AdditionalDataViewModel> = {
      id: idColumnLabel,
      description: descriptionColumnLabel,
      additionalDataTypeDescription: dataTypeColumnLabel,
      categoryId: dataCategoryColumnLabel,
      comments: dataCommentsColumnLabel,
      isSystemRecord: isSystemRecordColumnLabel,
    };

    const customColumns: ColumnStyles<AdditionalDataViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          isDisabled={item.isSystemRecord}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isSystemRecord: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string }) => handleDeleteClick(e.id),
      },
    ];

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        case commandsEnum.row_select:
          let newStateSelection: AdminStateType = setupGridColumns(prevState);
          newStateSelection.selectedRows = command.payload;
          return newStateSelection;
        default:
          return prevState;
      }
    }
    useEffect(() => {
      dispatchCommand({
        type: commandsEnum.row_select,
        payload: adminState.selectedRows,
      });
    }, [adminState.selectedRows]);

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndAdditionalDataFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndAdditionalDataFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndAdditionalDataFilter ex`
          );
        });
    }, []);

    //Additional Bulk Load Function
    const handleNewBulLoadTab = (payload?: any) => {
      callBackCommands({
        command: "new_additional_bulk_load",
        payload: payload,
      });
    };

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded]);

    const tableIcons: TableIcon[] = [
      {
        type: TPIconTypes.downloadTemplate,
        tooltip: "Download Template",
        status: true,
      },
    ];

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        />
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-additional-data-type"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                icons={tableIcons}
                hiddenColumns={[
                  "validationURL",
                  "localizedDescription",
                  "additionalDataCategoryDescription",
                  "additionalDataTypeId",
                  "enableEditingModifyBasicData",
                  "jsonParameters",
                  "categoryId",
                ]}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                disableMinorOption={(item) => item.isSystemRecord}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);
export default AdditionalDataAdmin;
