import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { BrowserClientViewModel } from "@/models/BrowserClient/BrowserClientModels";
import {
  BrowserClientInputDTO,
  BrowserClientUpdateInputDTO,
} from "@/models/BrowserClient/BrowserClientInputDTO";

export class BrowserKeepAliveService {
  serviceFileName: string = "BrowserKeepAliveService.ts";

  public async getBrowserClientBySesionId(
    sessionId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<BrowserClientViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mem_clientlist/";
    url = url + sessionId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getBrowserClientBySesionId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getBrowserClientBySesionId`);
      throw new Error(
        `Error ${this.serviceFileName} getBrowserClientBySesionId`,
      );
    }
  }

  public async insertBrowserClient(
    inputDTO: BrowserClientInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mem_clientlist";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertBrowserClient ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertBrowserClient`);
      throw new Error(`Error ${this.serviceFileName} insertBrowserClient`);
    }
  }

  public async updateBrowserClient(
    inputDTO: BrowserClientUpdateInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mem_clientlist/update-keep-alive";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateBrowserClient ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateBrowserClient`);
      throw new Error(`Error ${this.serviceFileName} updateBrowserClient`);
    }
  }
}
