import { TPKeyValue } from "@/helpers/TPKeyValue";
import React, { useImperativeHandle } from "react";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataLinkProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  description: string;
  labelStyles?: Array<TPKeyValue>;
  url: string;
};
const TPAdditionalDataLink = React.forwardRef(
  (
    {
      idControl,
      modeUI,
      description,
      labelStyles = [],
      url,
    }: TPAdditionalDataLinkProps,
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        return url;
      },
      validateFromParent() {
        return true;
      },
    }));

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group mt-2">
          <a href={`${decodeURIComponent(url)}`} target={"_blank"}>
            {description}
          </a>
        </div>
      );
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${description}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col text-break">
              <a href={`${decodeURIComponent(url)}`} target={"_blank"}>
                {decodeURIComponent(url)}
              </a>
            </div>
          </div>
        </React.Fragment>
      );
    };

    return (
      <>
        {modeUI == TPAddtionalDataUIModes.Collect
          ? renderCollectMode()
          : modeUI == TPAddtionalDataUIModes.View1 ||
              modeUI == TPAddtionalDataUIModes.View2
            ? renderViewMode1()
            : null}
      </>
    );
  },
);

export default TPAdditionalDataLink;
