import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { StyledClassicPager } from "../design-system-styles";
import { DefaultPagerProps } from "./DefaultPager";

interface ClassicPagerProps extends DefaultPagerProps {
  pageLabel: string;
  ofLabel: string;
}

function ClassicPager({
  id,
  currentPage,
  totalPages,
  previousLabel,
  pageLabel,
  ofLabel,
  nextPageLabel,
  onPageChange,
}: ClassicPagerProps): ReactElement {
  const getPagerButtonId = (orientation: string) => {
    return `${id && `${id}-`}${orientation}-pager-button`;
  };

  const iconDisabledStyle = (validation: boolean) => {
    return {
      color: `${validation ? "inherit" : "lightgray"}`,
      cursor: `${validation ? "pointer" : "auto"}`,
      backgroundColor: "white",
    };
  };

  return (
    <StyledClassicPager>
      <TPIcon
        id={getPagerButtonId("left")}
        iconType={TPIconTypes.doubleArrowLeft}
        onClick={() => currentPage !== 1 && onPageChange(1)}
        style={iconDisabledStyle(currentPage !== 1)}
      />
      <button
        id={getPagerButtonId("previous")}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {previousLabel}
      </button>
      <p>
        {pageLabel} <strong>{currentPage}</strong> {ofLabel}{" "}
        <strong>{totalPages < 0 ? "1" : totalPages}</strong>
      </p>
      <button
        id={getPagerButtonId("next")}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {nextPageLabel}
      </button>
      <TPIcon
        id={getPagerButtonId("right")}
        iconType={TPIconTypes.doubleArrowRight}
        onClick={() => currentPage !== totalPages && onPageChange(totalPages)}
        style={iconDisabledStyle(currentPage !== totalPages)}
      />
    </StyledClassicPager>
  );
}

export default ClassicPager;
