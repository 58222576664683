import TPModal from "@/layouts/TPModal/TPModal";
import { Icon } from "@iconify/react";
import { FC } from "react";
import useLabels from "../styles/labels";

interface MergeConfirmationModalProperties {
  onAction: (confirm: boolean) => void;
  shown: boolean;
}

const MergeConfirmationModal: FC<MergeConfirmationModalProperties> = function ({
  onAction,
  shown,
}) {
  const { labels } = useLabels();
  return (
    <>
      <TPModal
        modalState={{
          id: "merge-confirm-modal",
          titleModal: "",
          acceptLabel: labels.Merge,
          cancelLabel: labels.Cancel,
          callBackAnswer: onAction,
          isShown: shown,
          modalWidth: "min-content",
          enableAcceptButton: true,
        }}
      >
        <div id="merge-confirm-modal-body">
          <Icon
            icon="material-symbols:warning"
            color="red"
            height="48px"
            width="48px"
          />
          <b>{labels.Confirm}</b>
          <div>
            <ul>
              <li>{labels.OnlyOneListItem}</li>
              <li>{labels.AllCasesListItem}</li>
              <li>{labels.IrreversibleListItem}</li>
            </ul>
          </div>
          {labels.AreYouSure}
        </div>
      </TPModal>
    </>
  );
};

export default MergeConfirmationModal;
