import { useEffect, useState } from "react";
import ChildrenFolder from "./ImagesAdminChildrenFolder";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";

import {
    ImagesAdminFolderDivStyle,
    IcoFolderIStyle,
    MenuItemDivStyle,
    NameFolderPStyle,
    NivelDivStyle,
    NameFolderInputStyle,
    IcoMenuIStyle,
} from "./StyleImageAdmin";
import React from "react";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import { DataType, OperationMode } from "./useStatusImagesAdmin";
import Select from "react-select/dist/declarations/src/Select";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";

import { TPKeyValue } from "../../helpers/TPKeyValue";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import { LanguagesViewModel } from "../../models/Languages/LanguagesViewModel";
import TPGlobal from "../../helpers/TPGlobal";
import { MultilanguageFieldValueViewModel } from "../../models/multilanguage/MultilanguageFieldValueModel";
import { MultilanguageFieldValueService } from "../../services/MultilanguageFieldValueService";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import { TPI18N } from "../../services/I18nService";
import InputLanguages from "./InputLanguages";

type ContainerFolderProps = {
    folders: RecursiveRoutes;
};

const ParentFolder: React.FC<ContainerFolderProps> = ({ folders }) => {
    const resourceSet: string = "ImagesAdminComponent";
    const [activeAction, setActiveAction] = React.useState<Boolean>(false);
    const [editNameFolder, setEditNameFolder] = React.useState<string>("");
    const [editCodeFolder, setEditCodeFolder] = React.useState<string>("");
    const [nameLabel, setNameLabel] = useState("");
    const [codeFolderLabel, setCodeFolderLabel] = useState("");
    const [nameFolderLabel, setNameFolderLabel] = useState("");

    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>([]);
    const [baseLanguageOptions, setBaseLanguageOptions] = useState<Array<TPKeyValue>>([]);

    const multilanguageTableName: String = "IMAGes";
    const multilanguageFieldName: String = "FolderName_IMAG";



    const loadResourcesAndLoadInfo = async () => {

        setNameLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
        );
        setCodeFolderLabel(
            await TPI18N.GetText(resourceSet, "FolderCodeLabel"),
        );
        setNameFolderLabel(
            await TPI18N.GetText(resourceSet, "FolderNameLabel"),
        );
    }



    const value = useAppConctextImageAdmin();
    const {
        operationMode,
        selectedItem,
        selectedFolderParent,
        handleLoading,
        handleMultiSelectedFileClear,
        handleSelected,
        handleUpdateFolder,
        handleNewFolder,
        handleDisableFolder,
    } = value;

    const handleActivate = () => {
        if (folders.isActive) {
            Cancel();
            if (!activeAction) handleLoading(true);
            setActiveAction(!activeAction);          
            if (!activeAction)  handleSelected(folders, OperationMode.SelectFolder);
        }
    };

    const handleActivateMenu = () => {
        if (folders.id != selectedItem.id) {
            Cancel();
        }

        handleSelected(folders, OperationMode.ActiveMenu);
    };

    const handleActiveEditNameFolder = () => {
        if (operationMode == OperationMode.ActiveMenu) {
            handleSelected(folders, OperationMode.EditFolder);
            getRecordLanguageList(folders.id + "");
            setEditCodeFolder(folders.folderCode);
            setEditNameFolder(folders.folderName);
        } else {
            folders.folderName = editNameFolder != "" ? editNameFolder : folders.folderName;
            folders.folderCode = editCodeFolder != "" ? editCodeFolder : folders.folderCode;
            folders.localizedFolderName = editNameFolder != "" ? editNameFolder : folders.localizedFolderName;
            folders.folderNameLocalizedValues = recordLocalizedModel;            
            handleUpdateFolder(folders);
            handleSelected(selectedFolderParent, OperationMode.SelectFolder);
           // loadLanguages();
        }
    };


    const loadLanguages = () => {
        let i: number;
        let expectedCodes: Array<number> = [200];

        let listLanguages: Array<TPKeyValue> = [];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
            listLanguages.push(keyValueElement);
        }

        if (!recordLocalizedModel)
            setRecordLocalizedModel(new Array<RecordLocalizedModel>);

        let listFormName = [...recordLocalizedModel];
        for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
            let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
            let keyValueElement: RecordLocalizedModel = {
                languageId: item.id,
                localizedValue: "",
                order: i,
            };
            listFormName.push(keyValueElement);
        }
        setRecordLocalizedModel(listFormName);

        if (listLanguages) {
            listLanguages.unshift({ key: "", value: "--" });
            let language = TPGlobal.language;
            //setBaseLanguageOptions(listLanguages);
        }
    };

    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes,
                );


            let recordLocalizedModelTemp = [...recordLocalizedModel];

            recordLocalizedModelTemp.forEach((r) => {
                if (responseRequest) {
                    const foundRecord = responseRequest.find(s => s.languageId == r.languageId);

                    if (foundRecord) {
                        r.languageId = foundRecord.languageId || "";
                        r.localizedValue = foundRecord.recordDescription || "";
                    }
                }
            });
                      
            setRecordLocalizedModel(recordLocalizedModelTemp);

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];

            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ParentFolder getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ParentFolder getRecordLanguageList ex`);
            return [];
        }
    };



    const handleAddFolder = () => {
        handleNewFolder(folders);
    };

    const Cancel = () => {
        setActiveAction(false);
        handleSelected(new RecursiveRoutes(), OperationMode.Defaul);
        handleMultiSelectedFileClear();
    };

    const CancelActionMenu = () => {
       // handleSelected(folders, OperationMode.SelectFolder);

        setActiveAction(false);
        handleSelected(new RecursiveRoutes(), OperationMode.Defaul);
        handleMultiSelectedFileClear();
    };

    const handleDisableFolderAction = () => {
        folders.isActive = !folders.isActive;
        handleDisableFolder(folders);
    };


    useEffect(() => {
        loadLanguages();
        loadResourcesAndLoadInfo();
    }, []);

    return (
        <>
            <ImagesAdminFolderDivStyle onContextMenu={handleActivateMenu}>
                <IcoFolderIStyle
                    onClick={handleActivate}
                    className={
                        selectedItem.id === folders.id
                            ? "bi bi-eye-fill"
                            : activeAction
                                ? "bi bi-chevron-down"
                                : "bi-chevron-right"
                    }
                ></IcoFolderIStyle>
                <IcoFolderIStyle
                    onClick={handleActivate}
                    color={!folders.isActive ? "red" : "Black"}
                    className={
                        folders.isActive
                            ? folders.childrens && folders.childrens.length
                                ? "bi bi-folder-fill"
                                : "bi bi-folder"
                            : "bi bi-lock-fill"
                    }
                ></IcoFolderIStyle>

                <NameFolderPStyle onClick={handleActivate}>
                    {" "}
                    {folders.localizedFolderName}
                </NameFolderPStyle>


                {/*  <IcoFolderIStyle onClick={handleActivateMenu} className="bi-three-dots-vertical">
        </IcoFolderIStyle> */}

                {selectedItem.id != undefined &&
                    selectedItem.id === folders.id &&
                    (operationMode == OperationMode.ActiveMenu ||
                        operationMode == OperationMode.EditFolder) ? (
                    <ImagesAdminPopupMenu
                        right={"320px"}
                        top="13px"
                        Cancel={CancelActionMenu}
                        handleAdd={handleAddFolder}
                        handleActiveEditName={handleActiveEditNameFolder}
                        handleRemove={handleDisableFolderAction}
                        select={selectedItem}
                        operationMode={operationMode}
                        context={folders}
                    ></ImagesAdminPopupMenu>
                ) : (
                    ""
                )}
            </ImagesAdminFolderDivStyle>


            <div style={{ backgroundColor: "rgb(245, 245, 245)", display: "flex", justifyContent: "colum", padding: "0px", }}>
                {selectedItem.id != null &&
                    selectedItem.id === folders.id &&
                    operationMode == OperationMode.EditFolder &&
                    <div style={{ display: "flex", flexDirection: "column", width: "260px", borderTop: "1px solid gray", padding: "5px" }} >
                        <TPLabel
                            labelText={nameFolderLabel}
                            style={{
                                fontSize: "12px",
                                marginBottom: "-6px",
                                marginTop: "6px"
                            }}
                        ></TPLabel>
                        <InputLanguages
                            folderName={folders.folderName}
                            recordLocalized={recordLocalizedModel}
                            baseLanguageOptions={baseLanguageOptions}
                            handleChangedName={(e: string) => { setEditNameFolder(e) }}
                            handleChangedList={(e: any) => {                                                   
                                setRecordLocalizedModel(e);                               
                            }}
                            activeSave={false}
                        ></InputLanguages>
                        <TPLabel
                            labelText={codeFolderLabel}
                            style={{
                                fontSize: "12px",
                                marginBottom: "-6px",
                                marginTop: "6px"
                            }}
                        ></TPLabel>
                        <NameFolderInputStyle
                            type="text"
                            value={editCodeFolder}
                            placeholder={folders.folderCode}
                            onChange={(e: any) => {                               
                                setEditCodeFolder(e.target.value);
                            }}
                            width={"182px"}
                        />
                    </div>
                }

            </div>


            {activeAction
                ? folders.childrens.map((folder) => (
                    <NivelDivStyle>
                        <ChildrenFolder folders={folder} key={folder.id}></ChildrenFolder>
                    </NivelDivStyle>
                ))
                : null}
        </>
    );
};

export default ParentFolder;

