import DefaultAvatarIcon from "@/assets/images/TPIcons/DefaultAvatarIcon";
import NewCustomerIcon from "@/assets/images/TPIcons/NewCustomerIcon";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HorizontalTabsSlice, HorizontalTabsStateModel } from "@/layouts/Main/_redux/HorizonatalTabsSlice";
import TPModalContactsInsertUpdate, {
    TPModalContactState,
} from "@/layouts/TPModalContactsInsertUpdate/TPModalContactsInsertUpdate";
import TPModalError, {
    TPModalErrorStateType,
} from "@/layouts/TPModalError/TPModalError";
import TPModalNewClient from "@/layouts/TPModalNewClient/TPModalNewClient";
import TPModalPreferredAgentInsertUpdate, {
    TPModalPreferredAgentState,
} from "@/layouts/TPModalPreferredAgentInsertUpdate/TPModalPreferredAgentInsertUpdate";
import TPModalSearchClient, {
    TPModalSearchCustomerState,
} from "@/layouts/TPModalSearchClient/TPModalSearchClient";
import TPModalSuccess, {
    TPModalSuccessStateType,
} from "@/layouts/TPModalSuccess/TPModalSuccess";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import {
    CasesInputDTO,
    CasesInputDTOValidator,
    TEmpAdditionalDataCaseViewModel,
} from "@/models/Cases/CasesInputDTO";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
    WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { PreferredAgentViewModel } from "@/models/PreferredAgent/PreferredAgentViewModel";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { StoreModel } from "@/redux/store";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { CaseService } from "@/services/CaseService";
import { ClientService } from "@/services/ClientService";
import { ContactsService } from "@/services/ContactsService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { PreferredAgentService } from "@/services/PreferredAgentService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { QuickClassifierShortcutService } from "@/services/QuickClassifierShortcutService";
import { RelationsService } from "@/services/RelationsService";
import { StorageService } from "@/services/StorageService";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { TreeService } from "@/services/TreeService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

enum currentTabsEnum {
    "comments" = 0,
    "attachments" = 1,
    "workflow" = 2,
}

type temporayAttachmentType = {
    fileId: string;
    fileGuid: string;
    fileName: string;
};

enum commandsEnum {
    "start_newCase" = 0,
    "change_CurrentOrganization" = 1,
    "change_CurrentRelation" = 2,
    "change_CurrentBranch" = 3,
    "add_temporaryAttachment" = 4,
    "remove_temporaryAttachment" = 5,
    "change_CurrentTab" = 6,
    "change_AdditionalDataValue" = 7,
    "change_QuickClassifier" = 8,
    "change_CurrentCustomer" = 9,
    "change_alreadyClickHistory" = 10,
    "change_alreadyClickCustomerAttachments" = 11,
    "change_alreadyClickContacts" = 12,
    "change_alreadyClickPreferredAgent" = 13,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

type AdminStateType = {
    caseGuid: string;
    currentOrganization: string;
    currentRelation: string;
    currentBaseLevel: string;
    currentTree1: string;
    currentTree2: string;
    currentTree3: string;
    currentTree4: string;
    currentTree5: string;
    mustSelectLastLevelBranch1: boolean;
    mustSelectLastLevelBranch2: boolean;
    mustSelectLastLevelBranch3: boolean;
    mustSelectLastLevelBranch4: boolean;
    mustSelectLastLevelBranch5: boolean;
    currentLabelTree1: string;
    currentLabelTree2: string;
    currentLabelTree3: string;
    currentLabelTree4: string;
    currentLabelTree5: string;
    currentBranch1: string;
    currentBranch2: string;
    currentBranch3: string;
    currentBranch4: string;
    currentBranch5: string;
    currentDescriptionBranch1: string;
    currentDescriptionBranch2: string;
    currentDescriptionBranch3: string;
    currentDescriptionBranch4: string;
    currentDescriptionBranch5: string;
    temporaryAttachmentList: Array<temporayAttachmentType>;
    currentTab: currentTabsEnum;
    showAdditionalData: boolean;
    currentWorkflowType: string;
    currentWorkflowDescription: string;
    currentWorkflowImage: string;
    //key: additional data id
    //value: addtional data value
    //value2: other properties
    //value3: should show
    additionalDataValues: Array<TPKeyValue>;
    //customer
    currentCustomerId: number;
    currentSubsidiaryId: number;
    currentSubsidiaryOrgId: number;
    //history
    alreadyClickHistory: boolean;
    gridHistoryData: Array<any>;
    //attachments
    alreadyClickCustomerAttachments: boolean;
    gridCustomerAttachmentsData: Array<any>;
    //contacts
    alreadyClickCustomerContacts: boolean;
    gridCustomerContacts: Array<any>;
    selectedContactAsReporter: number;
    selectedContactName: string;
    //  preferred agents
    alreadyClickPreferredAgent: boolean;
    gridPreferredAgentData: Array<PreferredAgentViewModel>;
};

enum cacheCommandsEnum {
    "store_cache_classificatorData" = 1,
    "store_cache_quickClassifiers" = 2,
    "store_cache_CustomerType" = 3,
}

type cacheCommandType = {
    type: cacheCommandsEnum;
    payload: any;
};

type CacheStateType = {
    classificatorsRelatedData: Array<TPKeyValue>;
    quickClassifiersRelatedData: Array<TPKeyValue>;
    customerTypeRelatedData: Array<TPKeyValue>;
};

export enum searchCiteriaEnum {
    "none" = "none",
    "docnumber" = "docnumber",
    "internalcode" = "internalcode",
    "names" = "names",
    "email" = "email",
    "phone" = "phone",
    "additionaldata" = "additionaldata",
}

type DiagramFullScreenModalType = {
    classModalDiagram: string;
    styleModalDiagram: any;
    backdropClass: string;
    title: string;
};

enum currentAccordionsEnum {
    "history" = 0,
    "customer_attachments" = 1,
    "customer_contacts" = 2,
    "preferred_agent" = 3,
}

const NewCase = () => {
    const casecontext: any = React.useContext(TPCaseViewerContext);
    //#region Init

    //references
    const childRefArray: any = useRef([]);
    const refAccordionHistory1: any = useRef(null);
    const refAccordionHistory2: any = useRef(null);
    const refAccordionContacts1: any = useRef(null);
    const refAccordionContacts2: any = useRef(null);
    const refAccordionAttachments1: any = useRef(null);
    const refAccordionAttachments2: any = useRef(null);
    const refAccordionPreferredAgent1: any = useRef(null);
    const refAccordionPreferredAgent2: any = useRef(null);
    const branch1SelectorRef: any = useRef(null);
    const branch2SelectorRef: any = useRef(null);
    const branch3SelectorRef: any = useRef(null);
    const branch4SelectorRef: any = useRef(null);
    const branch5SelectorRef: any = useRef(null);
    const editorRef = useRef<any>(null);

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const componentFileName: string = "NewCase.tsx";

    //Screen resources
    const resourceSet: string = "NewCaseComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [organizationLabel, setOrganizationLabel] = useState("");
    const [errorMessageOrganization, setErrorMessageOrganization] = useState("");
    const [relationLabel, setRelationLabel] = useState("");
    const [errorMessageRelation, setErrorMessageRelation] = useState("");

    const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");
    const [quickClassfierLabel, setQuickClassfierLabel] = useState("");
    const [
        autocompleteQuickClassifierOptions,
        setAutocompleteQuickClassifierOptions,
    ] = useState<Array<TPKeyValue>>([]);
    const [
        selectedAutocompleteQuickClassifierOption,
        setSelectedAutocompleteQuickClassifierOption,
    ] = useState<Array<TPKeyValue>>([]);
    //top n options for arrow icon
    const [
        autocompleteQuickClassifierTopNOptions,
        setAutocompleteQuickClassifierTopNOptions,
    ] = useState<Array<TPKeyValue>>([]);
    const [tabClassificatorLabel, setTabClassificatorLabel] = useState("");
    const [tabAttachmentsLabel, setTabAttachmentsLabel] = useState("");
    const [tabWorkflowLabel, setTabWorkflowLabel] = useState("");
    const [commentsLabel, setCommentsLabel] = useState("");
    const [saveCaseButtonLabel, setSaveCaseButtonLabel] = useState("");
    const [additionalDataLabel, setAdditionalDataLabel] = useState("");
    const [workflowTypeLabel, setWorkflowTypeLabel] = useState("");
    const [isDirectSolutionLabel, setIsDirectSolutionLabel] = useState("");

    const [searchCriteriaLabel, setSearchCriteriaLabel] = useState("");
    const [searchCriteriaList, setSearchCriteriaList] = useState<
        Array<TPKeyValue>
    >([]);
    const [currentSearchCriteria, setCurrentSearchCriteria] =
        useState<searchCiteriaEnum>(searchCiteriaEnum.none);
    const [searchCriteriaValueLabel, setSearchCriteriaValueLabel] = useState("");
    const [selectAdditionalDataLabel, setSelectAdditionalDataLabel] =
        useState("");

    const [currentSearchCustomerValue, setCurrentSearchCustomerValue] =
        useState("");

    const [newCustomerButtonLabel, setNewCustomerButtonLabel] = useState("");

    const [
        selectSearchCriteriaErrorMessageLabel,
        setSelectSearchCriteriaErrorMessageLabel,
    ] = useState("");
    const [
        selectSearchCriteriaErrorMessage,
        setSelectSearchCriteriaErrorMessage,
    ] = useState("");

    const [
        selectSearchValueErrorMessageLabel,
        setSelectSearchValueErrorMessageLabel,
    ] = useState("");
    const [selectSearchValueErrorMessage, setSelectSearchValueErrorMessage] =
        useState("");

    //todo search additional labels and adddata listbox

    const [anonymousCustomerLabel, setAnonymousCustomerLabel] = useState("");
    const [searchCustomerLabel, setSearchCustomerLabel] = useState("");

    const [customerResultsNamesLabel, setCustomerResultsNamesLabel] =
        useState("");
    const [customerResultsTypeLabel, setCustomerResultsTypeLabel] = useState("");
    const [
        customerResultsDocumentTypeLabel,
        setCustomerResultsDocumentTypeLabel,
    ] = useState("");
    const [customerResultsDocumentLabel, setCustomerResultsDocumentLabel] =
        useState("");
    const [customerResultsPhoneLabel, setCustomerResultsPhoneLabel] =
        useState("");
    const [customerResultsEmailLabel, setCustomerResultsEmailLabel] =
        useState("");
    const [
        customerResultsInternalCodeLabel,
        setCustomerResultsInternalCodeLabel,
    ] = useState("");
    const [editCustomerButtonLabel, setEditCustomerButtonLabel] = useState("");
    const [searchAgainCustomerButtonLabel, setSearchAgainCustomerButtonLabel] =
        useState("");

    const [createContactLabel, setCreateContactLabel] = useState("");

    const [dataCustomerResultsNames, setDataCustomerResultsNames] = useState("");
    const [dataCustomerResultsType, setDataCustomerResultsType] = useState("");
    const [dataCustomerResultsDocumentType, setDataCustomerResultsDocumentType] =
        useState("");
    const [dataCustomerResultsDocument, setDataCustomerResultsDocument] =
        useState("");
    const [dataCustomerResultsPhone, setDataCustomerResultsPhone] = useState("");
    const [dataCustomerResultsEmail, setDataCustomerResultsEmail] = useState("");
    const [dataCustomerResultsInternalCode, setDataCustomerResultsInternalCode] =
        useState("");

    const [alreadyHaveCustomer, setAlreadyHaveCustomer] = useState(false);
    const [cancelAlreadyHaveCustomer, setCancelAlreadyHaveCustomer] =
        useState(false);
    const [isAnonymousCustomer, setIsAnonymousCustomer] = useState(false);
    const [disabledQuickClassifiers, setDisabledQuickClassifiers] =
        useState(true);

    const [editorErrorMessage, setEditorErrorMessage] = useState("");

    const [dropFilesHereLabel, setDropFilesHereLabel] = useState("");

    const [uploadFileButtonLabel, setUploadFileButtonLabel] = useState("");
    const [fileNameLabel, setFileNameLabel] = useState("");
    const [modalAcceptLabel, setModalAcceptLabel] = useState("");
    const [modalCancelLabel, setModalCancelLabel] = useState("");

    const [organizationListKeyValue, setOrganizationListKeyValue] = useState<
        Array<TPKeyValue>
    >([]);

    const [relationListKeyValue, setRelationListKeyValue] = useState<
        Array<TPKeyValue>
    >([]);
    const [isDirectSolution, setIsDirectSolution] = useState(false);
    const [hasPendingTask, setHasPendingTask] = useState(false);
    const [idCASE, setIdCASE] = useState<number>(0);

    const [filesDraggedList, setFilesDraggedList] = useState(null);

    const [editorValue, setEditorValue] = useState("");

    const initialDiagramModal: DiagramFullScreenModalType = {
        classModalDiagram: "modal",
        styleModalDiagram: { display: "none" },
        backdropClass: "",
        title: "",
    };

    const [diagramModal, setDiagramModal] =
        useState<DiagramFullScreenModalType>(initialDiagramModal);

    //branch select
    const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
    const [modalTitleLabel, setModalTitleLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");

    const [
        defaultBranchHierarchyDescription1,
        setDefaultBranchHierarchyDescription1,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription2,
        setDefaultBranchHierarchyDescription2,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription3,
        setDefaultBranchHierarchyDescription3,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription4,
        setDefaultBranchHierarchyDescription4,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription5,
        setDefaultBranchHierarchyDescription5,
    ] = useState("");

    const [errorClassificatorBranch1, setErrorClassificatorBranch1] =
        useState("");
    const [errorClassificatorBranch2, setErrorClassificatorBranch2] =
        useState("");
    const [errorClassificatorBranch3, setErrorClassificatorBranch3] =
        useState("");
    const [errorClassificatorBranch4, setErrorClassificatorBranch4] =
        useState("");
    const [errorClassificatorBranch5, setErrorClassificatorBranch5] =
        useState("");

    //modify customer button
    const [enableEditCustomer, setEnableEditCustomer] = useState(false);
    const [canCreateAnyCustomer, setCanCreateAnyCustomer] = useState(false);

    //modal search client
    const [searchClientModalTitle, setSearchClientModalTitle] = useState("");
    const [okLabel, setOkLabel] = useState("");
    const [cancelLabel, setCancelLabel] = useState("");
    const initialModalSearchState: TPModalSearchCustomerState = {
        isShown: false,
        initialSearchCriteria: searchCiteriaEnum.none,
        initialSearchValue: "",
        initialAddtionaDataSearchId: "",
    };
    const [modalSearchCustomerState, setModalSearchCustomerState] =
        useState<TPModalSearchCustomerState>(initialModalSearchState);

    //history
    const [historyCaseLabel, setHistoryCaseLabel] = useState("");
    const [gridHistoryColumns, setGridHistoryColumns] = useState<Array<any>>([]);
    const [historyCaseNumberColumnLabel, setHistoryCaseNumberColumnLabel] =
        useState("");
    const [historyCaseStatusColumnLabel, setHistoryCaseStatusColumnLabel] =
        useState("");
    const [
        historyCaseCreationDateColumnLabel,
        setHistoryCaseCreationDateColumnLabel,
    ] = useState("");

    //atachments
    const [gridCustomerAttachmentsColumns, setCustomerAttachmentsColumns] =
        useState<Array<any>>([]);
    const [
        attachmentsCaseNumberColumnLabel,
        setAttachmentsCaseNumberColumnLabel,
    ] = useState("");
    const [attachmentsFileNameColumnLabel, setAttachmentsFileNameColumnLabel] =
        useState("");
    const [
        attachmentsCreationDateColumnLabel,
        setAttachmentsCreationDateColumnLabel,
    ] = useState("");

    //contacts
    const [personWhoReportsLabel, setPersonWhoReportsLabel] = useState("");
    const [contactsLabel, setContactsLabel] = useState("");
    const [gridContactsColumns, setGridContactsColumns] = useState<Array<any>>(
        []
    );
    const [contactNameColumnLabel, setContactNameColumnLabel] = useState("");
    const [contactEmailColumnLabel, setContactEmailColumnLabel] = useState("");
    const [contactPhoneColumnLabel, setContactPhoneColumnLabel] = useState("");
    const [contactReportingPersonNote, setContactReportingPersonNote] =
        useState("");

    // preferred Agents
    const [gridPreferredAgentColumns, setGridPreferredAgentColumns] = useState<
        Array<any>
    >([]);
    const [agentsLabel, setAgentsLabel] = useState("");
    const [priorityLabel, setPriorityLabel] = useState("");
    const [preferredAgentNote, setPreferredAgentNote] = useState("");
    const [newPreferredAgent, setNewPreferredAgent] = useState("");

    const initialModalPreferredAgent: TPModalPreferredAgentState = {
        isShown: false,
        mode: "Insert",
        clientId: 0,
        id: 0,
        subsidiaryId: 0,
        agentId: 0,
        priority: 0,
    };
    const [modalPreferredAgentState, setModalPreferredAgentState] =
        useState<TPModalPreferredAgentState>(initialModalPreferredAgent);

    // initialModalPreferredAgent
    //#region New client modal
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [isShownModalNewClient, setIsShownModalNewClient] = useState(false);
    const [clientMode, setClientMode] = useState("Insert");
    //#endregion New client modal

    //#region New contact modal
    const initialModalContact: TPModalContactState = {
        mode: "",
        isShown: false,
        clientId: 0,
        subsidiaryId: 0,
        contactId: 0,
    };

    const [modalContactState, setModalContactState] =
        useState<TPModalContactState>(initialModalContact);

    //#endregion New client modal

    //modal error
    const initialModalError: TPModalErrorStateType = {
        isShown: false,
        title: "",
        errorMessage: "",
        yesLabel: "",
        callBackFunction: TPGlobal.foo,
    };
    const [modalErrorState, setModalErrorState] =
        useState<TPModalErrorStateType>(initialModalError);

    //modal success
    const initialModalSuccess: TPModalSuccessStateType = {
        isShown: false,
        title: "",
        successMessage: "",
        yesLabel: "",
        callBackFunction: TPGlobal.foo,
    };
    const [modalSuccessState, setModalSuccessState] =
        useState<TPModalSuccessStateType>(initialModalSuccess);

    //#endregion init

    //Load resources and init screen for the first time
    const loadResourcesAndOrganizations = async () => {
        let newGridHistoryColumns: Array<any> = [];
        let newGridAttachmentsColumns: Array<any> = [];
        let newGridContactsColumns: Array<any> = [];
        let newGridPreferredAgentColumns: Array<any> = [];

        //load resources
        setOrganizationLabel(
            await TPI18N.GetText(resourceSet, "OrganizationLabel")
        );
        setRelationLabel(await TPI18N.GetText(resourceSet, "RelationLabel"));
        setDummyClassfierLabel(
            await TPI18N.GetText(resourceSet, "ClassifierLabel")
        );
        setQuickClassfierLabel(
            await TPI18N.GetText(resourceSet, "QuickClassifierLabel")
        );
        setTabClassificatorLabel(
            await TPI18N.GetText(resourceSet, "TabClassificatorLabel")
        );
        setTabAttachmentsLabel(
            await TPI18N.GetText(resourceSet, "TabAttachmentsLabel")
        );
        setTabWorkflowLabel(await TPI18N.GetText(resourceSet, "TabWorkflowLabel"));
        setCommentsLabel(await TPI18N.GetText(resourceSet, "CommentsLabel"));
        setSaveCaseButtonLabel(
            await TPI18N.GetText(resourceSet, "SaveCaseButtonLabel")
        );
        setAdditionalDataLabel(
            await TPI18N.GetText(resourceSet, "AdditionalDataLabel")
        );
        setWorkflowTypeLabel(
            await TPI18N.GetText(resourceSet, "WorkflowTypeLabel")
        );
        setIsDirectSolutionLabel(
            await TPI18N.GetText(resourceSet, "IsDirectSolutionLabel")
        );
        setNewCustomerButtonLabel(
            await TPI18N.GetText(resourceSet, "NewCustomerButtonLabel")
        );
        setSearchCriteriaLabel(
            await TPI18N.GetText(resourceSet, "SearchCriteriaLabel")
        );
        setSearchCriteriaValueLabel(
            await TPI18N.GetText(resourceSet, "SearchCriteriaValueLabel")
        );
        setSelectAdditionalDataLabel(
            await TPI18N.GetText(resourceSet, "SelectAdditionalDataLabel")
        );
        setSearchClientModalTitle(
            await TPI18N.GetText(resourceSet, "SearchClientModalTitle")
        );
        setOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
        setCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );

        setSearchCriteriaList([
            {
                key: searchCiteriaEnum.none,
                value: "--",
            },
            {
                key: searchCiteriaEnum.docnumber,
                value: await TPI18N.GetText(resourceSet, "SearchDocumentNumberLabel"),
            },
            {
                key: searchCiteriaEnum.internalcode,
                value: await TPI18N.GetText(resourceSet, "SearchInternalCodeLabel"),
            },
            {
                key: searchCiteriaEnum.names,
                value: await TPI18N.GetText(resourceSet, "SearchNameOrLastNameLabel"),
            },
            {
                key: searchCiteriaEnum.email,
                value: await TPI18N.GetText(resourceSet, "SearchEmailLabel"),
            },
            {
                key: searchCiteriaEnum.phone,
                value: await TPI18N.GetText(resourceSet, "SearchPhoneLabel"),
            }, //todo enable
            // {
            //   key: searchCiteriaEnum.additionaldata,
            //   value: await TPI18N.GetText(resourceSet, "SearchAdditionalDataLabel"),
            // },
        ]);

        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
        setSelectSearchCriteriaErrorMessageLabel(
            await TPI18N.GetText(resourceSet, "SelectSearchCriteriaErrorMessageLabel")
        );
        setSelectSearchValueErrorMessageLabel(
            await TPI18N.GetText(resourceSet, "SelectSearchValueErrorMessageLabel")
        );
        setAnonymousCustomerLabel(
            await TPI18N.GetText(resourceSet, "AnonymousCustomerLabel")
        );
        setSearchCustomerLabel(
            await TPI18N.GetText(resourceSet, "SearchCustomerLabel")
        );
        setCustomerResultsNamesLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsNamesLabel")
        );
        setCustomerResultsTypeLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsTypeLabel")
        );
        setCustomerResultsDocumentTypeLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsDocumentTypeLabel")
        );
        setCustomerResultsDocumentLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsDocumentLabel")
        );
        setCustomerResultsEmailLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsEmailLabel")
        );
        setCustomerResultsPhoneLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsPhoneLabel")
        );
        setCustomerResultsInternalCodeLabel(
            await TPI18N.GetText(resourceSet, "CustomerResultsInternalCodeLabel")
        );
        setEditCustomerButtonLabel(
            await TPI18N.GetText(resourceSet, "EditCustomerButtonLabel")
        );
        setSearchAgainCustomerButtonLabel(
            await TPI18N.GetText(resourceSet, "SearchAgainCustomerButtonLabel")
        );
        setCreateContactLabel(
            await TPI18N.GetText(resourceSet, "CreateContactLabel")
        );
        setModalSelectedBranchLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ModalSelectedBranchLabel"
            )
        );
        setModalTitleLabel(
            await TPI18N.GetText(resourceSet, "SelectBranchClassificatorTitleLabel")
        );
        setEmptyLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
        );
        setDropFilesHereLabel(
            await TPI18N.GetText(resourceSet, "DropFilesHereLabel")
        );
        setUploadFileButtonLabel(
            await TPI18N.GetText(resourceSet, "UploadFileButtonLabel")
        );
        setFileNameLabel(await TPI18N.GetText(resourceSet, "FileNameLabel"));
        setModalAcceptLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
        );
        setModalCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );

        //history
        setHistoryCaseLabel(await TPI18N.GetText(resourceSet, "HistoryCaseLabel"));
        setHistoryCaseNumberColumnLabel(
            await TPI18N.GetText(resourceSet, "HistoryCaseNumberColumnLabel")
        );
        setHistoryCaseStatusColumnLabel(
            await TPI18N.GetText(resourceSet, "HistoryCaseStatusColumnLabel")
        );
        setHistoryCaseCreationDateColumnLabel(
            await TPI18N.GetText(resourceSet, "HistoryCaseCreationDateColumnLabel")
        );

        //attachments
        setAttachmentsCaseNumberColumnLabel(
            await TPI18N.GetText(resourceSet, "AttachmentsCaseNumberColumnLabel")
        );
        setAttachmentsFileNameColumnLabel(
            await TPI18N.GetText(resourceSet, "AttachmentsFileNameColumnLabel")
        );
        setAttachmentsCreationDateColumnLabel(
            await TPI18N.GetText(resourceSet, "AttachmentsCreationDateColumnLabel")
        );

        //contacts
        setPersonWhoReportsLabel(
            await TPI18N.GetText(resourceSet, "PersonWhoReportsLabel")
        );
        setContactsLabel(await TPI18N.GetText(resourceSet, "ContactsLabel"));
        setContactNameColumnLabel(
            await TPI18N.GetText(resourceSet, "ContactNameColumnLabel")
        );
        setContactEmailColumnLabel(
            await TPI18N.GetText(resourceSet, "ContactEmailColumnLabel")
        );
        setContactPhoneColumnLabel(
            await TPI18N.GetText(resourceSet, "ContactPhoneColumnLabel")
        );
        setContactReportingPersonNote(
            await TPI18N.GetText(resourceSet, "ContactReportingPersonNote")
        );

        // preferred Agents
        setAgentsLabel(await TPI18N.GetText(resourceSet, "AgentsLabel"));
        setPriorityLabel(await TPI18N.GetText(resourceSet, "PriorityLabel"));
        setPreferredAgentNote(
            await TPI18N.GetText(resourceSet, "PreferredAgentNote")
        );
        setNewPreferredAgent(
            await TPI18N.GetText(resourceSet, "NewPreferredAgent")
        );

        //New client Modal
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );

        //History Grid Columns
        newGridHistoryColumns.push({
            name: await TPI18N.GetText(resourceSet, "HistoryCaseNumberColumnLabel"),
            selector: (row: { [x: string]: any }) => row["caseId"],
            cell: (row: { [x: string]: any }) => {
                return (
                    <span
                        onClick={() =>
                            casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
                        }
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {row["caseId"]}
                    </span>
                );
            },
        });
        newGridHistoryColumns.push({
            name: await TPI18N.GetText(resourceSet, "HistoryCaseStatusColumnLabel"),
            selector: (row: { [x: string]: any }) =>
                row["localizedCaseStatusDescription"],
            cell: (row: { [x: string]: any }) => {
                return row["localizedCaseStatusDescription"];
            },
        });
        newGridHistoryColumns.push({
            name: await TPI18N.GetText(
                resourceSet,
                "HistoryCaseCreationDateColumnLabel"
            ),
            selector: (row: { [x: string]: any }) => row["insertDateFormatted"],
            cell: (row: { [x: string]: any }) => {
                return row["insertDateFormatted"];
            },
        });
        setGridHistoryColumns(newGridHistoryColumns);

        //customer attachments
        newGridAttachmentsColumns.push({
            name: await TPI18N.GetText(
                resourceSet,
                "AttachmentsCaseNumberColumnLabel"
            ),
            selector: (row: { [x: string]: any }) => row["caseId"],
            cell: (row: { [x: string]: any }) => {
                return (
                    <span
                        onClick={() =>
                            casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
                        }
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {row["caseId"]}
                    </span>
                );
            },
        });
        newGridAttachmentsColumns.push({
            name: await TPI18N.GetText(resourceSet, "AttachmentsFileNameColumnLabel"),
            selector: (row: { [x: string]: any }) => row["fileName"],
            cell: (row: { [x: string]: any }) => {
                return (
                    <span
                        onClick={() =>
                            handleCustomerAttachmentClick(row["blobId"], row["fileName"])
                        }
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {row["fileName"]}
                    </span>
                );
            },
        });
        newGridAttachmentsColumns.push({
            name: await TPI18N.GetText(
                resourceSet,
                "AttachmentsCreationDateColumnLabel"
            ),
            selector: (row: { [x: string]: any }) => row["insertDateFormatted"],
            cell: (row: { [x: string]: any }) => {
                return row["insertDateFormatted"];
            },
        });
        setCustomerAttachmentsColumns(newGridAttachmentsColumns);

        //contacts
        newGridContactsColumns.push({
            name: await TPI18N.GetText(resourceSet, "ContactNameColumnLabel"),
            selector: (row: { [x: string]: any }) => row["name"],
            cell: (row: { [x: string]: any }) => {
                return (
                    <span
                        onClick={(e: any) =>
                            handleContactUpdateClick(
                                e,
                                row["contactId"],
                                row["clientId"],
                                row["subsidiaryId"]
                            )
                        }
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {row["name"]}
                    </span>
                );
            },
        });
        newGridContactsColumns.push({
            name: await TPI18N.GetText(resourceSet, "ContactEmailColumnLabel"),
            selector: (row: { [x: string]: any }) => row["email"],
            cell: (row: { [x: string]: any }) => {
                return row["email"];
            },
        });
        // newGridContactsColumns.push({
        //   name: await TPI18N.GetText(resourceSet, "ContactPhoneColumnLabel"),
        //   selector: (row: { [x: string]: any }) => row["phone"],
        //   cell: (row: { [x: string]: any }) => {
        //     return row["phone"];
        //   },
        // });
        setGridContactsColumns(newGridContactsColumns);

        newGridPreferredAgentColumns.push({
            selector: (row: { [x: string]: any }) => null, // No need for a selector
            cell: (row: { [x: string]: any }) => (
                <TPIcon
                    iconType={TPIconTypes.delete}
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDeletePreferredAgent(row)}
                />
            ),
        });
        newGridPreferredAgentColumns.push({
            name: await TPI18N.GetText(resourceSet, "AgentId"),
            selector: (row: { [x: string]: any }) => row["idUser"],
            cell: (row: { [x: string]: any }) => (
                <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={(e: any) =>
                        handlePreferredAgentUpdateClick(
                            e,
                            row["id"],
                            row["clientId"],
                            row["subsidiaryId"],
                            row["agentId"],
                            row["priority"]
                        )
                    }
                >
                    {row["idUser"]}
                </span>
            ),
        });
        newGridPreferredAgentColumns.push({
            name: await TPI18N.GetText(resourceSet, "AgentLabel"),
            selector: (row: { [x: string]: any }) => row["agentName"],
            cell: (row: { [x: string]: any }) => row["agentName"],
        });
        newGridPreferredAgentColumns.push({
            name: await TPI18N.GetText(resourceSet, "PriorityLabel"),
            selector: (row: { [x: string]: any }) => row["priority"],
            cell: (row: { [x: string]: any }) => row["priority"],
        });
        setGridPreferredAgentColumns(newGridPreferredAgentColumns);
        //123

        //load organizations
        await getOrganizationsList();

        await getCustomerTypes();

        await setupNewCase();
    };

    //called from loadResourcesAndOrganizations
    const setupNewCase = async () => {
        let newQuickClassifierList: Array<TPKeyValue> = [];
        let classificationData: any;
        let newCurrentOrganization: string = "";
        let newCurrentRelation: string = "";
        let newCurrentBaseLevel: string = "";
        let newTree1: string;
        let newTree2: string;
        let newTree3: string;
        let newTree4: string;
        let newTree5: string;

        let mustSelectLastBranch1: boolean;
        let mustSelectLastBranch2: boolean;
        let mustSelectLastBranch3: boolean;
        let mustSelectLastBranch4: boolean;
        let mustSelectLastBranch5: boolean;

        let newLabelTree1: string | null;
        let newLabelTree2: string | null;
        let newLabelTree3: string | null;
        let newLabelTree4: string | null;
        let newLabelTree5: string | null;

        newTree1 = "";
        newTree2 = "";
        newTree3 = "";
        newTree4 = "";
        newTree5 = "";

        mustSelectLastBranch1 = false;
        mustSelectLastBranch2 = false;
        mustSelectLastBranch3 = false;
        mustSelectLastBranch4 = false;
        mustSelectLastBranch5 = false;

        newLabelTree1 = "";
        newLabelTree2 = "";
        newLabelTree3 = "";
        newLabelTree4 = "";
        newLabelTree5 = "";

        setDefaultBranchHierarchyDescription1("");
        setDefaultBranchHierarchyDescription2("");
        setDefaultBranchHierarchyDescription3("");
        setDefaultBranchHierarchyDescription4("");
        setDefaultBranchHierarchyDescription5("");

        if (TPGlobal.cookieOrganization) {
            //check if or organizationListKeyValue contains TPGlobal.cookieOrganization
            if (
                organizationListKeyValue.findIndex(
                    (x: TPKeyValue) => x.key === TPGlobal.cookieOrganization
                )
            ) {
                newCurrentOrganization = TPGlobal.cookieOrganization;
                await getRelationsList(newCurrentOrganization);
                if (TPGlobal.cookieRelation) {
                    //check if relationListKeyValue cointains TPGlobal.cookieRelation
                    if (
                        relationListKeyValue.findIndex(
                            (x: TPKeyValue) => x.key === TPGlobal.cookieRelation
                        )
                    ) {
                        newCurrentRelation = TPGlobal.cookieRelation;
                        newCurrentBaseLevel = newCurrentRelation.split("|")[0];
                        setIsLoadingScreen(true);

                        classificationData = await getClassificatorsConfiguration(
                            newCurrentRelation,
                            newCurrentBaseLevel
                        );
                        if (classificationData === null) {
                            newCurrentRelation = "";
                            newCurrentBaseLevel = "";
                            //todo logs
                        } else {
                            newTree1 = classificationData.newTree1;
                            newTree2 = classificationData.newTree2;
                            newTree3 = classificationData.newTree3;
                            newTree4 = classificationData.newTree4;
                            newTree5 = classificationData.newTree5;
                            mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
                            mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
                            mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
                            mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
                            mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
                            newLabelTree1 = classificationData.newLabelTree1;
                            newLabelTree2 = classificationData.newLabelTree2;
                            newLabelTree3 = classificationData.newLabelTree3;
                            newLabelTree4 = classificationData.newLabelTree4;
                            newLabelTree5 = classificationData.newLabelTree5;

                            //save on cache
                            let newCachedValues1: any;
                            let newCacheElement1: TPKeyValue;

                            newCachedValues1 = {};
                            newCachedValues1.newBaseLevel = newCurrentBaseLevel;
                            newCachedValues1.newTree1 = newTree1;
                            newCachedValues1.newTree2 = newTree2;
                            newCachedValues1.newTree3 = newTree3;
                            newCachedValues1.newTree4 = newTree4;
                            newCachedValues1.newTree5 = newTree5;
                            newCachedValues1.newMustSelectLastLevelBranch1 =
                                mustSelectLastBranch1;
                            newCachedValues1.newMustSelectLastLevelBranch2 =
                                mustSelectLastBranch2;
                            newCachedValues1.newMustSelectLastLevelBranch3 =
                                mustSelectLastBranch3;
                            newCachedValues1.newMustSelectLastLevelBranch4 =
                                mustSelectLastBranch4;
                            newCachedValues1.newMustSelectLastLevelBranch5 =
                                mustSelectLastBranch5;
                            newCachedValues1.newLabelTree1 = newLabelTree1;
                            newCachedValues1.newLabelTree2 = newLabelTree2;
                            newCachedValues1.newLabelTree3 = newLabelTree3;
                            newCachedValues1.newLabelTree4 = newLabelTree4;
                            newCachedValues1.newLabelTree5 = newLabelTree5;

                            newCacheElement1 = {
                                key: newCurrentBaseLevel,
                                value: newCachedValues1,
                            };

                            let command2: cacheCommandType = {
                                type: cacheCommandsEnum.store_cache_classificatorData,
                                payload: {
                                    newClassificatorRelatedData: { ...newCacheElement1 },
                                },
                            };
                            cacheDispatchCommand(command2);

                            //get quick classifiers
                            newQuickClassifierList =
                                await getQuickClassifiersForOrganizationRelation(
                                    newCurrentBaseLevel
                                );

                            console.log("newQuickClassifierList", newQuickClassifierList)

                            //save on cache
                            if (newQuickClassifierList.length >= 1) {
                                let newCacheElement2: TPKeyValue;

                                newCacheElement2 = {
                                    key: newCurrentBaseLevel,
                                    value: newQuickClassifierList,
                                };

                                let command3: cacheCommandType = {
                                    type: cacheCommandsEnum.store_cache_quickClassifiers,
                                    payload: {
                                        newQuickClassifiersData: { ...newCacheElement2 },
                                    },
                                };
                                cacheDispatchCommand(command3);
                            }
                        }

                        setIsLoadingScreen(false);
                    }
                }
            }
        }

        let command1: commandType = {
            type: commandsEnum.start_newCase,
            payload: {
                newCurrentOrganization: newCurrentOrganization,
                newCurrentRelation: newCurrentRelation,
                newCurrentBaseLevel: newCurrentBaseLevel,
                newTree1: newTree1,
                newTree2: newTree2,
                newTree3: newTree3,
                newTree4: newTree4,
                newTree5: newTree5,

                newMustSelectLastLevelBranch1: mustSelectLastBranch1,
                newMustSelectLastLevelBranch2: mustSelectLastBranch2,
                newMustSelectLastLevelBranch3: mustSelectLastBranch3,
                newMustSelectLastLevelBranch4: mustSelectLastBranch4,
                newMustSelectLastLevelBranch5: mustSelectLastBranch5,

                newLabelTree1: newLabelTree1,
                newLabelTree2: newLabelTree2,
                newLabelTree3: newLabelTree3,
                newLabelTree4: newLabelTree4,
                newLabelTree5: newLabelTree5,
            },
        };
        dispatchCommand(command1);
    };

    //called after close modal error
    const callBackModalError = () => {
        //hide modal and reset state
        //preserve callback function, title,and button level
        let newModalErrorState: TPModalErrorStateType = { ...modalErrorState };
        newModalErrorState.errorMessage = "";
        newModalErrorState.isShown = false;
        setModalErrorState(newModalErrorState);
    };

    //called after close modal success
    const callBackModalSuccess = () => {
        let cacheIndex1: number;
        let newTree1: string = "";
        let newTree2: string = "";
        let newTree3: string = "";
        let newTree4: string = "";
        let newTree5: string = "";
        let mustSelectLastBranch1: boolean = false;
        let mustSelectLastBranch2: boolean = false;
        let mustSelectLastBranch3: boolean = false;
        let mustSelectLastBranch4: boolean = false;
        let mustSelectLastBranch5: boolean = false;
        let newLabelTree1: string = "";
        let newLabelTree2: string = "";
        let newLabelTree3: string = "";
        let newLabelTree4: string = "";
        let newLabelTree5: string = "";
        //reset all
        //customer part
        clearCustomerData();
        setAlreadyHaveCustomer(false);
        setCurrentSearchCriteria(searchCiteriaEnum.none);
        setSelectSearchCriteriaErrorMessage("");
        setCurrentSearchCustomerValue("");
        setSelectSearchValueErrorMessage("");
        //todo adddata combo and value
        setIsAnonymousCustomer(false);
        //case part
        childRefArray.current = [];
        // editorRef.current.setContent("");
        setEditorValue("");
        setIsDirectSolution(false);
        let newQuickClassifierList: Array<TPKeyValue> = [];
        setSelectedAutocompleteQuickClassifierOption(newQuickClassifierList);
        setDefaultBranchHierarchyDescription1("");
        setDefaultBranchHierarchyDescription2("");
        setDefaultBranchHierarchyDescription3("");
        setDefaultBranchHierarchyDescription4("");
        setDefaultBranchHierarchyDescription5("");
        if (branch1SelectorRef.current) {
            branch1SelectorRef.current.resetData();
        }
        if (branch2SelectorRef.current) {
            branch2SelectorRef.current.resetData();
        }
        if (branch3SelectorRef.current) {
            branch3SelectorRef.current.resetData();
        }
        if (branch4SelectorRef.current) {
            branch4SelectorRef.current.resetData();
        }
        if (branch5SelectorRef.current) {
            branch5SelectorRef.current.resetData();
        }

        cacheIndex1 =
            cacheOrganizationRelationRelatedData.classificatorsRelatedData.findIndex(
                (x: TPKeyValue) => x.key === adminState.currentBaseLevel
            );
        if (cacheIndex1 !== -1) {
            newTree1 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newTree1;
            newTree2 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newTree2;
            newTree3 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newTree3;
            newTree4 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newTree4;
            newTree5 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newTree5;
            mustSelectLastBranch1 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newMustSelectLastLevelBranch1;
            mustSelectLastBranch2 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newMustSelectLastLevelBranch2;
            mustSelectLastBranch3 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newMustSelectLastLevelBranch3;
            mustSelectLastBranch4 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newMustSelectLastLevelBranch4;
            mustSelectLastBranch5 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newMustSelectLastLevelBranch5;
            newLabelTree1 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newLabelTree1;
            newLabelTree2 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newLabelTree2;
            newLabelTree3 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newLabelTree3;
            newLabelTree4 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newLabelTree4;
            newLabelTree5 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                    cacheIndex1
                ].value.newLabelTree5;

            let command1: commandType = {
                type: commandsEnum.start_newCase,
                payload: {
                    newCurrentOrganization: adminState.currentOrganization,
                    newCurrentRelation: adminState.currentRelation,
                    newCurrentBaseLevel: adminState.currentBaseLevel,
                    newTree1: newTree1,
                    newTree2: newTree2,
                    newTree3: newTree3,
                    newTree4: newTree4,
                    newTree5: newTree5,

                    newMustSelectLastLevelBranch1: mustSelectLastBranch1,
                    newMustSelectLastLevelBranch2: mustSelectLastBranch2,
                    newMustSelectLastLevelBranch3: mustSelectLastBranch3,
                    newMustSelectLastLevelBranch4: mustSelectLastBranch4,
                    newMustSelectLastLevelBranch5: mustSelectLastBranch5,

                    newLabelTree1: newLabelTree1,
                    newLabelTree2: newLabelTree2,
                    newLabelTree3: newLabelTree3,
                    newLabelTree4: newLabelTree4,
                    newLabelTree5: newLabelTree5,
                },
            };
            dispatchCommand(command1);
        } else {
            //todo logs
        }

        //hide modal and reset state
        //preserve callback function, title,and button level
        let newModalSuccessState: TPModalSuccessStateType = {
            ...modalSuccessState,
        };
        newModalSuccessState.successMessage = "";
        newModalSuccessState.isShown = false;
        setModalSuccessState(newModalSuccessState);

        // checking the new case in order to check if this user has pending tasks in it
        // if (hasPendingTask == true) {
        //   casecontext.handleAddNewCaseViewerCallBack(idCASE);
        // }
    };

    //called when change customer serch criteria
    const handleCustomerSearchCriteriaChange = (e: any) => {
        let newValue: string = e.target.value;
        setCurrentSearchCriteria(newValue as searchCiteriaEnum);
        setSelectSearchCriteriaErrorMessage("");
        clearCustomerData();
    };

    //called when change customer serch criteria
    const handleChangeCustormerSearchValue = (e: any) => {
        let newValue: string = e.target.value;
        setCurrentSearchCustomerValue(newValue);
        setSelectSearchValueErrorMessage("");
        clearCustomerData();
    };

    //called to search a customer
    const getCustomerByClientIdSubsidiaryIdAndSubOrg = async (
        subsidiaryOrganizationId: number
    ) => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest =
                await serviceClient.getClientBySubsidiaryOrganizationId(
                    subsidiaryOrganizationId,
                    false,
                    true,
                    expectedCodes
                );
            setIsLoadingScreen(false);
            return responseRequest[0];
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`
            );
            setIsLoadingScreen(false);
            return null;
        }
    };

    //called to get customer types from loadResourcesAndOrganizations
    const getCustomerTypes = async () => {
        let serviceClient = new CustomerTypeService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        let newCustomerTypeRelatedData: Array<TPKeyValue> = [];
        let alreadySetCanCreateNewClient: boolean = false;
        try {
            let responseRequest = await serviceClient.getCustomerTypeByFilter(
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes
            );
            for (i = 0; i <= responseRequest.length - 1; i++) {
                let element = responseRequest[i];
                if (element.isActive && !element.isSystemRecord) {
                    newCustomerTypeRelatedData.push({
                        key: element.id,
                        value: element.canCreate,
                        value2: element.canUpdate,
                    });
                    if (!alreadySetCanCreateNewClient && element.canCreate) {
                        alreadySetCanCreateNewClient = true;
                        setCanCreateAnyCustomer(true);
                    }
                }
            }
            let command: cacheCommandType = {
                type: cacheCommandsEnum.store_cache_CustomerType,
                payload: {
                    newCustomerTypeRelatedData: newCustomerTypeRelatedData,
                },
            };
            cacheDispatchCommand(command);
            return;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerTypes ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getCustomerTypes ex`);
            return;
        }
    };

    //called when change customer after search/insert/modify or select anonymous
    const changeCurrentCustomerId = (
        newCurrentCustomerId: number,
        newCurrentSubsidiaryId: number,
        newCurrentSubsidiaryOrgId: number
    ) => {
        let command1: commandType = {
            type: commandsEnum.change_CurrentCustomer,
            payload: {
                newCurrentCustomerId: newCurrentCustomerId,
                newCurrentSubsidiaryId: newCurrentSubsidiaryId,
                newCurrentSubsidiaryOrgId: newCurrentSubsidiaryOrgId,
            },
        };
        dispatchCommand(command1);
    };

    //called to clear customer state
    const clearCustomerData = () => {
        changeCurrentCustomerId(-1, -1, -1); //no customer

        setDataCustomerResultsNames("");
        setDataCustomerResultsType("");
        setDataCustomerResultsDocumentType("");
        setDataCustomerResultsDocument("");
        setDataCustomerResultsEmail("");
        setDataCustomerResultsPhone("");
        setDataCustomerResultsInternalCode("");
        setEnableEditCustomer(false);

        let command1: commandType = {
            type: commandsEnum.change_alreadyClickHistory,
            payload: {
                newAlreadyClickHistory: false,
                newGridHistoryData: [],
            },
        };
        dispatchCommand(command1);

        let command2: commandType = {
            type: commandsEnum.change_alreadyClickCustomerAttachments,
            payload: {
                newAlreadyClickCustomerAttachments: false,
                newGridCustomerAttachmentsData: [],
            },
        };
        dispatchCommand(command2);

        let command3: commandType = {
            type: commandsEnum.change_alreadyClickContacts,
            payload: {
                newAlreadyClickCustomerContacts: false,
                newGridCustomerContacts: [],
                newSelectedContactAsReporter: 0,
                newSelectedContactName: "",
            },
        };
        dispatchCommand(command3);

        let command4: commandType = {
            type: commandsEnum.change_alreadyClickPreferredAgent,
            payload: {
                newAlreadyClickPreferredAgents: false,
                newGridPreferredAgents: [],
            },
        };
        dispatchCommand(command4);
    };

    //called to handle search customer
    const handleSearchOnClick = async () => {
        let bolExit: boolean = false;

        clearCustomerData();

        //validate
        if (currentSearchCriteria === searchCiteriaEnum.none) {
            setSelectSearchCriteriaErrorMessage(
                selectSearchCriteriaErrorMessageLabel
            );
            bolExit = true;
        }
        if (currentSearchCustomerValue.trim() === "") {
            setSelectSearchValueErrorMessage(selectSearchValueErrorMessageLabel);
            bolExit = true;
        }
        //todo add data
        if (bolExit) {
            return;
        }

        //perform real search
        let newModalSearchCustomerState: TPModalSearchCustomerState = {
            isShown: true,
            initialSearchCriteria: currentSearchCriteria,
            initialSearchValue: currentSearchCustomerValue,
            initialAddtionaDataSearchId: "", //todo
        };
        setModalSearchCustomerState(newModalSearchCustomerState);
    };

    //called to handle search again
    const handleSearchAgainOnClick = () => {
        setCurrentSearchCriteria(searchCiteriaEnum.none);
        setCurrentSearchCustomerValue("");
        setSelectSearchCriteriaErrorMessage("");
        setSelectSearchValueErrorMessage("");
        setAlreadyHaveCustomer(false);
        setCancelAlreadyHaveCustomer(true);
    };

    //called to handle cancel customer
    const handleCancelCustomerOnClick = () => {
        setCurrentSearchCriteria(searchCiteriaEnum.none);
        setCurrentSearchCustomerValue("");
        setSelectSearchCriteriaErrorMessage("");
        setSelectSearchValueErrorMessage("");
        setAlreadyHaveCustomer(true);
        setCancelAlreadyHaveCustomer(false);
    };

    //called to handle anonymous
    const handleAnonymousOnChange = () => {
        if (!isAnonymousCustomer) {
            setCurrentSearchCriteria(searchCiteriaEnum.none);
            setCurrentSearchCustomerValue("");
            setSelectSearchCriteriaErrorMessage("");
            setSelectSearchValueErrorMessage("");
            changeCurrentCustomerId(0, 0, 0); //anonymous
        } else {
            changeCurrentCustomerId(-1, -1, -1); //no customer
        }
        setIsAnonymousCustomer(!isAnonymousCustomer);
    };

    //callback to handle customer search
    const callBackModalCustomerSearch = async (
        successResult: boolean,
        selectedClient: any,
        usedSearchCriteria: string,
        usedSearchValue: string
        //todo adddata
    ) => {
        setModalSearchCustomerState(initialModalSearchState);
        if (successResult) {
            setCurrentSearchCriteria(usedSearchCriteria as searchCiteriaEnum);
            setCurrentSearchCustomerValue(usedSearchValue);
            setCancelAlreadyHaveCustomer(false);

            await realChangeCurrentCustomerCallback(
                selectedClient.id,
                selectedClient.subsidiaryId,
                selectedClient.subsidiaryOrganizationId
            );
        } else {
            //todo logs
        }
    };

    //real actions after change customer
    const realChangeCurrentCustomerCallback = async (
        id: number,
        subsidiaryId: number,
        subsidiaryOrganizationId: number
    ) => {
        let fullCustomerData: ClientAdminViewModel | null;
        let cacheIndex: number;
        changeCurrentCustomerId(id, subsidiaryId, subsidiaryOrganizationId);
        fullCustomerData = await getCustomerByClientIdSubsidiaryIdAndSubOrg(
            subsidiaryOrganizationId
        );
        if (fullCustomerData) {
            setDataCustomerResultsNames(fullCustomerData.customerName);
            setDataCustomerResultsType(fullCustomerData.customerTypeDescription);
            setDataCustomerResultsDocumentType(
                fullCustomerData.documentTypeDescription
            );
            setDataCustomerResultsDocument(
                fullCustomerData.document //todo document description
            );
            setDataCustomerResultsEmail(fullCustomerData.preferredEmail);
            setDataCustomerResultsPhone(fullCustomerData.preferredPhone);
            setDataCustomerResultsInternalCode(fullCustomerData.internalCode);
            setAlreadyHaveCustomer(true);
            cacheIndex = -1;
            if (fullCustomerData.customerTypeId !== null) {
                let customerTypeId: string = fullCustomerData.customerTypeId;
                cacheIndex =
                    cacheOrganizationRelationRelatedData.customerTypeRelatedData.findIndex(
                        (x: TPKeyValue) => x.key === customerTypeId
                    );
            }
            if (cacheIndex !== -1) {
                setEnableEditCustomer(
                    cacheOrganizationRelationRelatedData.customerTypeRelatedData[
                        cacheIndex
                    ].value2
                );
            } else {
                //todo logs
                setEnableEditCustomer(false);
            }
        } else {
            //todo logs
        }
    };

    //called to show new customer modal
    const handleNewCustomerClick = () => {
        setClientMode("Insert");
        setIsShownModalNewClient(true);
    };

    //called to show update customer modal
    const handleEditCustomerOnClick = () => {
        setClientMode("Update");
        setIsShownModalNewClient(true);
    };

    //called when new/update customer is back
    const callbackNewClientAndUpdateClientModalAnswer = async (
        response: boolean,
        id_suorResponse: string,
        id_subsResponse: string,
        id_clieResponse: string
    ) => {
        setIsShownModalNewClient(false);
        if (response) {
            if (clientMode === "Insert") {
                await realChangeCurrentCustomerCallback(
                    parseInt(id_clieResponse, 10),
                    parseInt(id_subsResponse, 10),
                    parseInt(id_suorResponse, 10)
                );
                //todo change search criteria values
            } else {
                await realChangeCurrentCustomerCallback(
                    adminState.currentCustomerId,
                    adminState.currentSubsidiaryId,
                    adminState.currentSubsidiaryOrgId
                );
                //todo change search criteria values
            }
        }
    };

    //customer accordions handler
    const handleAccordionChange = async (newAccordion: currentAccordionsEnum) => {
        let newGridHistoryData: Array<any> | null = [];
        let newGridCustomerAttachmentsData: Array<any> | null = [];
        let newGridCustomerContacts: Array<any> | null = [];
        let newGridPreferredAgents: Array<PreferredAgentViewModel> | null = [];
        switch (newAccordion) {
            case currentAccordionsEnum.history:
                if (!adminState.alreadyClickHistory) {
                    newGridHistoryData = await getCustomerHistory();
                    if (newGridHistoryData) {
                        let command1: commandType = {
                            type: commandsEnum.change_alreadyClickHistory,
                            payload: {
                                newAlreadyClickHistory: true,
                                newGridHistoryData: newGridHistoryData,
                            },
                        };
                        dispatchCommand(command1);
                    } else {
                        //todo logs
                    }
                }
                break;
            case currentAccordionsEnum.customer_attachments:
                if (!adminState.alreadyClickCustomerAttachments) {
                    newGridCustomerAttachmentsData = await getCustomerAttachments();
                    if (newGridCustomerAttachmentsData) {
                        let command1: commandType = {
                            type: commandsEnum.change_alreadyClickCustomerAttachments,
                            payload: {
                                newAlreadyClickCustomerAttachments: true,
                                newGridCustomerAttachmentsData: newGridCustomerAttachmentsData,
                            },
                        };
                        dispatchCommand(command1);
                    } else {
                        //todo logs
                    }
                }
                break;
            case currentAccordionsEnum.customer_contacts:
                if (!adminState.alreadyClickCustomerContacts) {
                    newGridCustomerContacts = await getCustomerContacts();
                    if (newGridCustomerContacts) {
                        let command1: commandType = {
                            type: commandsEnum.change_alreadyClickContacts,
                            payload: {
                                newAlreadyClickCustomerContacts: true,
                                newGridCustomerContacts: newGridCustomerContacts,
                                newSelectedContactAsReporter: 0,
                                newSelectedContactName: "",
                            },
                        };
                        dispatchCommand(command1);
                    } else {
                        //todo logs
                    }
                }
                break;
            case currentAccordionsEnum.preferred_agent:
                if (!adminState.alreadyClickPreferredAgent) {
                    newGridPreferredAgents = await getPreferredAgents();
                    if (newGridPreferredAgents) {
                        let command1: commandType = {
                            type: commandsEnum.change_alreadyClickPreferredAgent,
                            payload: {
                                newAlreadyClickPreferredAgents: true,
                                newGridPreferredAgents: newGridPreferredAgents,
                            },
                        };
                        dispatchCommand(command1);
                    } else {
                        //todo logs
                    }
                }
                break;
        }
    };

    //get customer history
    const getCustomerHistory = async () => {
        let serviceClient = new CaseService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest =
                await serviceClient.getHistoryCasesBySubsidiaryOrgId(
                    adminState.currentSubsidiaryOrgId,
                    TPGlobal.currentUserGuid,
                    TPActiveOptions.ALL,
                    false,
                    true,
                    expectedCodes
                );

            if (responseRequest && responseRequest.length > 0) {
                for (i = 0; i <= responseRequest.length - 1; i++) {
                    responseRequest[i].tpguidrow =
                        "historyrow" + uuidv4().replaceAll("-", "");
                }
            }
            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerHistory ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getCustomerHistory ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    //get customer attachments
    const getCustomerAttachments = async () => {
        let serviceClient = new ClientService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getAttachmentsClient(
                adminState.currentCustomerId,
                TPGlobal.currentUserGuid,
                false,
                true,
                expectedCodes
            );
            if (responseRequest && responseRequest.length > 0) {
                for (i = 0; i <= responseRequest.length - 1; i++) {
                    responseRequest[i].tpguidrow =
                        "tpcustomerattachmentsrow" + uuidv4().replaceAll("-", "");
                }
            }
            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerAttachments ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getCustomerAttachments ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    // get preferred Agent
    const getPreferredAgents = async () => {
        let serviceClient = new PreferredAgentService();
        let expectedCodes: Array<number> = [200, 404];
        try {
            setIsLoadingScreen(true);
            let responseRequest =
                await serviceClient.getPreferredAgentsBySubsidiaryId(
                    adminState.currentSubsidiaryId,
                    false,
                    true,
                    expectedCodes
                );
            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getPreferredAgents ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getPreferredAgents ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    //get attachment by id
    const getAttachmentById = async (blobId: string) => {
        let serviceClient = new StorageService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getFileById(
                blobId,
                false,
                true,
                expectedCodes
            );
            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getAttachmentById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getAttachmentById ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    const handleCustomerAttachmentClick = async (
        blobId: string,
        fileName: string
    ) => {
        let attachmentData: any;

        attachmentData = await getAttachmentById(blobId);
        if (attachmentData) {
            const tempLink = document.createElement("a");
            tempLink.href = `data:application/octec-stream;base64,${attachmentData.file}`;
            tempLink.setAttribute("download", fileName);
            tempLink.click();
        }
    };

    //get custmoter contacts
    const getCustomerContacts = async () => {
        let serviceClient = new ContactsService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getCustomerContacts(
                adminState.currentCustomerId,
                adminState.currentSubsidiaryId,
                false,
                true,
                expectedCodes
            );
            if (responseRequest && responseRequest.length > 0) {
                for (i = 0; i <= responseRequest.length - 1; i++) {
                    responseRequest[i].tpguidrow =
                        "contactsrow" + uuidv4().replaceAll("-", "");
                    responseRequest[i].tpselectedRow = false;
                }
            }
            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerContacts ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getCustomerContacts ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    //get organizations list
    const getOrganizationsList = async () => {
        let serviceClient = new OrganizationsService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
            let responseRequest =
                await serviceClient.getOrganizationsByProfileAndRestrictions(
                    "ADMIN",
                    "S_ADWFRE",
                    false,
                    false,
                    true,
                    expectedCodes
                );
            let newOrganizationsListState: Array<TPKeyValue> = [];

            let organizationsListData: Array<OrganizationsViewModel>;
            organizationsListData = responseRequest;
            for (i = 0; i <= organizationsListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: organizationsListData[i].id,
                    value: organizationsListData[i].localizedName,
                };
                newOrganizationsListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newOrganizationsListState.unshift(intitialKeyValue);
            setOrganizationListKeyValue(newOrganizationsListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getOrganizationsList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getOrganizationsList ex`);
            setIsLoadingScreen(false);
        }
    };

    //get relations list bases on selected organization
    const getRelationsList = async (newOrganization: string) => {
        let serviceClient = new RelationsService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        let newRelationsListState: Array<TPKeyValue> = [];
        try {
            setIsLoadingScreen(true);
            if (newOrganization === "") {
                setRelationListKeyValue(newRelationsListState);
                setIsLoadingScreen(false);
                return;
            }

            //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
            let responseRequest =
                await serviceClient.getRelationsByOrganizationIdAndProfileRestriction(
                    newOrganization,
                    "ADMIN",
                    "S_ADWFRE",
                    false,
                    false,
                    true,
                    expectedCodes
                );
            let relationsListData: Array<RelationsWithRestrictionsViewModel>;
            relationsListData = responseRequest;
            for (i = 0; i <= relationsListData.length - 1; i++) {
                //legacy stuff :(   baselevel|relationid on baseLevelAndRelationlId field)
                //to avoid two arrays
                //todo fix Typo baseLevelAndRelationlId remove 'l' character
                let keyvalue: TPKeyValue = {
                    key: relationsListData[i].baseLevelAndRelationlId,
                    value: relationsListData[i].localizedName
                        ? relationsListData[i].localizedName
                        : relationsListData[i].name,
                };
                newRelationsListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newRelationsListState.unshift(intitialKeyValue);
            setRelationListKeyValue(newRelationsListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRelationsList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRelationsList ex`);
            setIsLoadingScreen(false);
        }
    };

    //get base level info (organization and relation pair)
    const getBaseLevelInfo = async (BaseLevelId: string) => {
        let serviceClient = new OrganizationsRelationsService();
        let expectedCodes: Array<number> = [200];
        let baseLevelData: OrganizationsRelationsViewModel | null = null;
        try {
            let responseRequest = await serviceClient.getById(
                BaseLevelId,
                false,
                true,
                expectedCodes
            );

            baseLevelData = responseRequest;
            return baseLevelData;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getBaseLevelInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
            return null;
        }
    };

    //get a tree info
    const getTreeInfo = async (treeId: string) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        let treeData: TreeViewModel | null = null;
        try {
            let responseRequest = await serviceClient.getTreeById(
                treeId,
                false,
                true,
                expectedCodes
            );
            treeData = responseRequest;
            return treeData;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTreeInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTreeInfo ex`);
            return null;
        }
    };

    //get multilanguage field value
    const getRecordLanguageList = async (
        recordId: string,
        multilanguageTableName: string,
        multilanguageFieldName: string
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    recordId,
                    false,
                    true,
                    expectedCodes
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRecordLanguageList ex`);
            return [];
        }
    };

    //get classificators for a baselevel
    const getClassificatorsConfiguration = async (
        newRelation: string,
        newBaseLevel: string
    ) => {
        let newTree1: string;
        let newTree2: string;
        let newTree3: string;
        let newTree4: string;
        let newTree5: string;

        let mustSelectLastBranch1: boolean;
        let mustSelectLastBranch2: boolean;
        let mustSelectLastBranch3: boolean;
        let mustSelectLastBranch4: boolean;
        let mustSelectLastBranch5: boolean;

        let newLabelTree1: string | null;
        let newLabelTree2: string | null;
        let newLabelTree3: string | null;
        let newLabelTree4: string | null;
        let newLabelTree5: string | null;

        let baseLevelData: OrganizationsRelationsViewModel | null = null;
        let treeData: TreeViewModel | null = null;
        let localizedField: Array<MultilanguageFieldValueViewModel> = [];
        let i;
        let found: boolean;

        newTree1 = "";
        newTree2 = "";
        newTree3 = "";
        newTree4 = "";
        newTree5 = "";

        mustSelectLastBranch1 = false;
        mustSelectLastBranch2 = false;
        mustSelectLastBranch3 = false;
        mustSelectLastBranch4 = false;
        mustSelectLastBranch5 = false;

        newLabelTree1 = "";
        newLabelTree2 = "";
        newLabelTree3 = "";
        newLabelTree4 = "";
        newLabelTree5 = "";

        baseLevelData = await getBaseLevelInfo(newBaseLevel);
        if (baseLevelData === null) {
            //todo logs
            return null;
        }
        newTree1 = baseLevelData.tree1Id;
        newTree2 = baseLevelData.tree2Id;
        newTree3 = baseLevelData.tree3Id;
        newTree4 = baseLevelData.tree4Id;
        newTree5 = baseLevelData.tree5Id;
        //get tree Info 1
        if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
            treeData = await getTreeInfo(newTree1);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch1 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C1",
                    "BAseLEvel",
                    "Tree1_Classify1Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree1 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree1 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 2
            treeData = await getTreeInfo(newTree2);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch2 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C2",
                    "BAseLEvel",
                    "Tree2_Classify2Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree2 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree2 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 3
            treeData = await getTreeInfo(newTree3);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch3 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C3",
                    "BAseLEvel",
                    "Tree3_Classify3Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree3 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree3 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 4
            treeData = await getTreeInfo(newTree4);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch4 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C4",
                    "BAseLEvel",
                    "Tree4_Classify4Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree4 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree4 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 5
            treeData = await getTreeInfo(newTree5);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch5 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C5",
                    "BAseLEvel",
                    "Tree5_Classify5Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree5 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree5 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }

        return {
            newTree1,
            newTree2,
            newTree3,
            newTree4,
            newTree5,
            mustSelectLastBranch1,
            mustSelectLastBranch2,
            mustSelectLastBranch3,
            mustSelectLastBranch4,
            mustSelectLastBranch5,
            newLabelTree1,
            newLabelTree2,
            newLabelTree3,
            newLabelTree4,
            newLabelTree5,
        };
    };

    //get quick classificators for a baselevel
    const getQuickClassifiersForOrganizationRelation = async (
        newBaseLevel: string
    ): Promise<Array<TPKeyValue>> => {
        let newQuickClassifierList: Array<TPKeyValue> = [];
        let serviceClient = new QuickClassifierService();
        let expectedCodes: Array<number> = [200];
        let quickData: Array<QuickClassifierViewModel> = [];
        try {
            //todo use another method to get only valid branches
            setDisabledQuickClassifiers(true);
            //due to must select last level branch
            let responseRequest = await serviceClient.getQuickClassifiersByBaseLevel(
                newBaseLevel,
                false,
                false,
                expectedCodes
            );
            quickData = responseRequest;
            if (quickData) {
                for (let i: number = 0; i <= quickData.length - 1; i++) {
                    if (
                        quickData[i].isActive &&
                        quickData[i].type == WebServiceClassifierType.QuickClassifiers
                    ) {
                        setDisabledQuickClassifiers(false);
                        newQuickClassifierList.push({
                            key: quickData[i].id,
                            value: quickData[i].localizedDescription
                                ? quickData[i].localizedDescription
                                : quickData[i].description,
                            value2: {
                                classifier1Id: quickData[i].classifier1Id,
                                classifier2Id: quickData[i].classifier2Id,
                                classifier3Id: quickData[i].classifier3Id,
                                classifier4Id: quickData[i].classifier4Id,
                                classifier5Id: quickData[i].classifier5Id,
                            },
                            value3: {
                                localizedClassifier1: quickData[i].localizedClassifier1,
                                localizedClassifier2: quickData[i].localizedClassifier2,
                                localizedClassifier3: quickData[i].localizedClassifier3,
                                localizedClassifier4: quickData[i].localizedClassifier4,
                                localizedClassifier5: quickData[i].localizedClassifier5,
                            },
                            value4: {
                                localizedHierarchyClassifier1:
                                    quickData[i].localizedHierarchyClassifier1,
                                localizedHierarchyClassifier2:
                                    quickData[i].localizedHierarchyClassifier2,
                                localizedHierarchyClassifier3:
                                    quickData[i].localizedHierarchyClassifier3,
                                localizedHierarchyClassifier4:
                                    quickData[i].localizedHierarchyClassifier4,
                                localizedHierarchyClassifier5:
                                    quickData[i].localizedHierarchyClassifier5,
                            },
                        });
                    }
                }
            }
            setAutocompleteQuickClassifierOptions(newQuickClassifierList);
            return newQuickClassifierList;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`
            );
            return [];
        }
    };

    //handler when organization change
    const handlerOrganizationChange = async (e: any) => {
        let newQuickClassifierList: Array<TPKeyValue> = [];
        setErrorMessageOrganization("");
        setErrorMessageRelation("");
        setErrorClassificatorBranch1("");
        setErrorClassificatorBranch2("");
        setErrorClassificatorBranch3("");
        setErrorClassificatorBranch4("");
        setErrorClassificatorBranch5("");

        setDefaultBranchHierarchyDescription1("");
        setDefaultBranchHierarchyDescription2("");
        setDefaultBranchHierarchyDescription3("");
        setDefaultBranchHierarchyDescription4("");
        setDefaultBranchHierarchyDescription5("");

        let newOrganization: string = e.target.value;
        setAutocompleteQuickClassifierOptions(newQuickClassifierList);
        setSelectedAutocompleteQuickClassifierOption(newQuickClassifierList);

        await getRelationsList(newOrganization);
        let command1: commandType = {
            type: commandsEnum.change_CurrentOrganization,
            payload: { newOrganization: newOrganization },
        };
        dispatchCommand(command1);

        TPGlobal.cookieOrganization = newOrganization;
        TPGlobal.cookieRelation = "";
    };

    //handler when relation change
    const handlerRelationChange = async (e: any) => {
        setErrorMessageRelation("");
        setErrorClassificatorBranch1("");
        setErrorClassificatorBranch2("");
        setErrorClassificatorBranch3("");
        setErrorClassificatorBranch4("");
        setErrorClassificatorBranch5("");
        let newQuickClassifierList: Array<TPKeyValue> = [];
        setDefaultBranchHierarchyDescription1("");
        setDefaultBranchHierarchyDescription2("");
        setDefaultBranchHierarchyDescription3("");
        setDefaultBranchHierarchyDescription4("");
        setDefaultBranchHierarchyDescription5("");
        let newRelation: string = e.target.value;
        let newBaseLevel: string;

        let newTree1: string;
        let newTree2: string;
        let newTree3: string;
        let newTree4: string;
        let newTree5: string;

        let mustSelectLastBranch1: boolean;
        let mustSelectLastBranch2: boolean;
        let mustSelectLastBranch3: boolean;
        let mustSelectLastBranch4: boolean;
        let mustSelectLastBranch5: boolean;

        let newLabelTree1: string | null;
        let newLabelTree2: string | null;
        let newLabelTree3: string | null;
        let newLabelTree4: string | null;
        let newLabelTree5: string | null;
        let classificationData: any;
        let cacheIndex1: number;
        let cacheIndex2: number;

        newBaseLevel = "";

        newTree1 = "";
        newTree2 = "";
        newTree3 = "";
        newTree4 = "";
        newTree5 = "";

        mustSelectLastBranch1 = false;
        mustSelectLastBranch2 = false;
        mustSelectLastBranch3 = false;
        mustSelectLastBranch4 = false;
        mustSelectLastBranch5 = false;

        newLabelTree1 = "";
        newLabelTree2 = "";
        newLabelTree3 = "";
        newLabelTree4 = "";
        newLabelTree5 = "";
        setIsLoadingScreen(true);

        if (newRelation == "") {
            setAutocompleteQuickClassifierOptions(newQuickClassifierList);
            setSelectedAutocompleteQuickClassifierOption(newQuickClassifierList);
        }

        if (newRelation !== "") {
            //get base level info
            newBaseLevel = newRelation.split("|")[0];

            //check if classification data is in cache
            cacheIndex1 =
                cacheOrganizationRelationRelatedData.classificatorsRelatedData.findIndex(
                    (x: TPKeyValue) => x.key === newBaseLevel
                );
            if (cacheIndex1 !== -1) {
                newTree1 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newTree1;
                newTree2 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newTree2;
                newTree3 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newTree3;
                newTree4 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newTree4;
                newTree5 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newTree5;
                mustSelectLastBranch1 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newMustSelectLastLevelBranch1;
                mustSelectLastBranch2 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newMustSelectLastLevelBranch2;
                mustSelectLastBranch3 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newMustSelectLastLevelBranch3;
                mustSelectLastBranch4 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newMustSelectLastLevelBranch4;
                mustSelectLastBranch5 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newMustSelectLastLevelBranch5;
                newLabelTree1 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newLabelTree1;
                newLabelTree2 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newLabelTree2;
                newLabelTree3 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newLabelTree3;
                newLabelTree4 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newLabelTree4;
                newLabelTree5 =
                    cacheOrganizationRelationRelatedData.classificatorsRelatedData[
                        cacheIndex1
                    ].value.newLabelTree5;
            } else {
                //clasificators
                classificationData = await getClassificatorsConfiguration(
                    newRelation,
                    newBaseLevel
                );
                if (classificationData === null) {
                    //todo logs
                    return;
                }
                newTree1 = classificationData.newTree1;
                newTree2 = classificationData.newTree2;
                newTree3 = classificationData.newTree3;
                newTree4 = classificationData.newTree4;
                newTree5 = classificationData.newTree5;
                mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
                mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
                mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
                mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
                mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
                newLabelTree1 = classificationData.newLabelTree1;
                newLabelTree2 = classificationData.newLabelTree2;
                newLabelTree3 = classificationData.newLabelTree3;
                newLabelTree4 = classificationData.newLabelTree4;
                newLabelTree5 = classificationData.newLabelTree5;
            }
            //check if quick classifiers is in cache
            cacheIndex2 =
                cacheOrganizationRelationRelatedData.quickClassifiersRelatedData.findIndex(
                    (x: TPKeyValue) => x.key === newBaseLevel
                );
            if (cacheIndex2 !== -1) {
                newQuickClassifierList =
                    cacheOrganizationRelationRelatedData.quickClassifiersRelatedData[
                        cacheIndex2
                    ].value;
                setAutocompleteQuickClassifierOptions(newQuickClassifierList);
            } else {
                newQuickClassifierList =
                    await getQuickClassifiersForOrganizationRelation(newBaseLevel);
            }
        }

        let command1: commandType = {
            type: commandsEnum.change_CurrentRelation,
            payload: {
                newRelation: newRelation,
                newBaseLevel: newBaseLevel,

                newTree1: newTree1,
                newTree2: newTree2,
                newTree3: newTree3,
                newTree4: newTree4,
                newTree5: newTree5,

                newMustSelectLastLevelBranch1: mustSelectLastBranch1,
                newMustSelectLastLevelBranch2: mustSelectLastBranch2,
                newMustSelectLastLevelBranch3: mustSelectLastBranch3,
                newMustSelectLastLevelBranch4: mustSelectLastBranch4,
                newMustSelectLastLevelBranch5: mustSelectLastBranch5,

                newLabelTree1: newLabelTree1,
                newLabelTree2: newLabelTree2,
                newLabelTree3: newLabelTree3,
                newLabelTree4: newLabelTree4,
                newLabelTree5: newLabelTree5,
            },
        };
        dispatchCommand(command1);

        if (newRelation != "") {
            if (
                cacheOrganizationRelationRelatedData.classificatorsRelatedData.findIndex(
                    (x: TPKeyValue) => x.key === newBaseLevel
                ) === -1
            ) {
                //save cache
                let newCachedValues1: any;
                let newCacheElement1: TPKeyValue;

                newCachedValues1 = {};
                newCachedValues1.newBaseLevel = newBaseLevel;
                newCachedValues1.newTree1 = newTree1;
                newCachedValues1.newTree2 = newTree2;
                newCachedValues1.newTree3 = newTree3;
                newCachedValues1.newTree4 = newTree4;
                newCachedValues1.newTree5 = newTree5;
                newCachedValues1.newMustSelectLastLevelBranch1 = mustSelectLastBranch1;
                newCachedValues1.newMustSelectLastLevelBranch2 = mustSelectLastBranch2;
                newCachedValues1.newMustSelectLastLevelBranch3 = mustSelectLastBranch3;
                newCachedValues1.newMustSelectLastLevelBranch4 = mustSelectLastBranch4;
                newCachedValues1.newMustSelectLastLevelBranch5 = mustSelectLastBranch5;
                newCachedValues1.newLabelTree1 = newLabelTree1;
                newCachedValues1.newLabelTree2 = newLabelTree2;
                newCachedValues1.newLabelTree3 = newLabelTree3;
                newCachedValues1.newLabelTree4 = newLabelTree4;
                newCachedValues1.newLabelTree5 = newLabelTree5;

                newCacheElement1 = { key: newBaseLevel, value: newCachedValues1 };

                let command2: cacheCommandType = {
                    type: cacheCommandsEnum.store_cache_classificatorData,
                    payload: {
                        newClassificatorRelatedData: { ...newCacheElement1 },
                    },
                };
                cacheDispatchCommand(command2);
            }
            if (
                cacheOrganizationRelationRelatedData.quickClassifiersRelatedData.findIndex(
                    (x: TPKeyValue) => x.key === newBaseLevel
                ) === -1 &&
                newQuickClassifierList.length >= 1
            ) {
                let newCacheElement2: TPKeyValue;

                newCacheElement2 = {
                    key: newBaseLevel,
                    value: newQuickClassifierList,
                };

                let command3: cacheCommandType = {
                    type: cacheCommandsEnum.store_cache_quickClassifiers,
                    payload: {
                        newQuickClassifiersData: { ...newCacheElement2 },
                    },
                };
                cacheDispatchCommand(command3);
            }
        }

        setIsLoadingScreen(false);
        TPGlobal.cookieRelation = newRelation;
    };

    //get workflow based on classificators and base level
    const getWorkflowType = async (
        baseLevelId: string,
        branch1: string,
        branch2: string,
        branch3: string,
        branch4: string,
        branch5: string
    ) => {
        let serviceClient = new WorkflowTypeService();
        let expectedCodes: Array<number> = [200, 404];
        let workflowTypeData: WorkflowTypeViewModel | null = null;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getByBaseLevelAndClassifiers(
                baseLevelId,
                branch1,
                branch2,
                branch3,
                branch4,
                branch5,
                false,
                true,
                expectedCodes
            );

            workflowTypeData = responseRequest;
            setIsLoadingScreen(false);
            return workflowTypeData;
        } catch (error) {
            setIsLoadingScreen(false);
            TPLog.Log(
                `Error ${componentFileName} getWorkflowType ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getWorkflowType ex`);
            return null;
        }
    };

    //get additional data based on classificators
    const getAdditionalDataForClassification = async (
        branch1: string,
        branch2: string,
        branch3: string,
        branch4: string,
        branch5: string
    ) => {
        let serviceClient = new AdditionalDataService();
        let expectedCodes: Array<number> = [200, 404];
        let additionalData: Array<AdditionalDataViewModel> | null = null;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getByClassifiers(
                branch1,
                branch2,
                branch3,
                branch4,
                branch5,
                "1", //active
                "1", //visible
                false,
                true,
                expectedCodes
            );

            additionalData = responseRequest;
            setIsLoadingScreen(false);
            return additionalData;
        } catch (error) {
            setIsLoadingScreen(false);
            TPLog.Log(
                `Error ${componentFileName} getAdditionalDataForClassification ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} getAdditionalDataForClassification ex`
            );
            return null;
        }
    };

    //handle classificator branch change
    const handleOnBranchChange = async (
        index: number,
        branchId: string,
        branchDescription: string,
        branchHierachyDescription: string
    ) => {
        let branch1: string;
        let branch2: string;
        let branch3: string;
        let branch4: string;
        let branch5: string;
        let calculateWorkflowAndAdditionalData: boolean = true;
        let newWorkflowType: string;
        let newWorkflowDescription: string;
        let additionalDataList: Array<AdditionalDataViewModel> | null = null;
        let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

        switch (index) {
            case 1:
                setDefaultBranchHierarchyDescription1(branchHierachyDescription);
                setErrorClassificatorBranch1("");
                break;
            case 2:
                setDefaultBranchHierarchyDescription2(branchHierachyDescription);
                setErrorClassificatorBranch2("");
                break;
            case 3:
                setDefaultBranchHierarchyDescription3(branchHierachyDescription);
                setErrorClassificatorBranch3("");
                break;
            case 4:
                setDefaultBranchHierarchyDescription4(branchHierachyDescription);
                setErrorClassificatorBranch4("");
                break;
            case 5:
                setDefaultBranchHierarchyDescription5(branchHierachyDescription);
                setErrorClassificatorBranch5("");
                break;
            default:
                break;
        }

        branch1 = "";
        branch2 = "";
        branch3 = "";
        branch4 = "";
        branch5 = "";
        newWorkflowType = "";
        newWorkflowDescription = "";

        //check to calculate workflow and additional data
        if (adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 1) {
                branch1 = branchId;
            } else {
                branch1 = adminState.currentBranch1;
            }
            if (branch1 === "") {
                calculateWorkflowAndAdditionalData = false;
            }
        }
        if (adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 2) {
                branch2 = branchId;
            } else {
                branch2 = adminState.currentBranch2;
            }
            if (branch2 === "") {
                calculateWorkflowAndAdditionalData = false;
            }
        }
        if (adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 3) {
                branch3 = branchId;
            } else {
                branch3 = adminState.currentBranch3;
            }
            if (branch3 === "") {
                calculateWorkflowAndAdditionalData = false;
            }
        }
        if (adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 4) {
                branch4 = branchId;
            } else {
                branch4 = adminState.currentBranch4;
            }
            if (branch4 === "") {
                calculateWorkflowAndAdditionalData = false;
            }
        }
        if (adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 5) {
                branch5 = branchId;
            } else {
                branch5 = adminState.currentBranch5;
            }
            if (branch5 === "") {
                calculateWorkflowAndAdditionalData = false;
            }
        }
        //calculate workflow
        if (calculateWorkflowAndAdditionalData) {
            let workflowData: WorkflowTypeViewModel | null;
            workflowData = await getWorkflowType(
                adminState.currentBaseLevel,
                branch1,
                branch2,
                branch3,
                branch4,
                branch5
            );
            if (workflowData) {
                newWorkflowType = workflowData.id;
                newWorkflowDescription =
                    workflowData.localizedDescription &&
                        workflowData.localizedDescription !== ""
                        ? workflowData.localizedDescription
                        : workflowData.description;
            }
        }
        //calculate additional data list
        if (calculateWorkflowAndAdditionalData) {
            additionalDataList = await getAdditionalDataForClassification(
                branch1,
                branch2,
                branch3,
                branch4,
                branch5
            );
            if (additionalDataList && additionalDataList.length >= 1) {
                newAdditionalDataList = [...additionalDataList];
            }
        }

        let command1: commandType = {
            type: commandsEnum.change_CurrentBranch,
            payload: {
                treeNumber: index,
                newBranch: branchId,
                newDescriptionBranch: branchDescription,
                newWorkflowType: newWorkflowType,
                newWorkflowDescription: newWorkflowDescription,
                newAdditionalDataList: newAdditionalDataList,
            },
        };
        dispatchCommand(command1);
    };

    const handleEditorChange = (value: any) => {
        setEditorValue(value);
        if (value.length > 0) {
            setEditorErrorMessage("");
        }
    };

    //handle remove attachment
    const handlerOnDeleteTemporaryAttachment = async (fileId: string) => {
        let serviceClient = new TemporaryAttachmentService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.deleteTemporaryAttachmentById(
                fileId,
                false,
                true,
                expectedCodes
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                let command1: commandType = {
                    type: commandsEnum.remove_temporaryAttachment,
                    payload: {
                        fileId: fileId,
                    },
                };
                dispatchCommand(command1);
            } else {
                //todo logs
                return;
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handlerOnDeleteTemporaryAttachment ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} handlerOnDeleteTemporaryAttachment ex`
            );
            setIsLoadingScreen(false);
        }
    };

    //handle upload attachment change
    const handleUploadFilesDraggedChange = (filesList: any) => {
        setFilesDraggedList(filesList);
    };

    //get workflow diagram
    const getWorkflowDiagram = async () => {
        let serviceClient = new WorkflowTypeService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest =
                await serviceClient.getDiagramByBaseLevelAndClassifiers(
                    adminState.currentWorkflowType,
                    adminState.currentBaseLevel,
                    adminState.currentBranch1,
                    adminState.currentBranch2,
                    adminState.currentBranch3,
                    adminState.currentBranch4,
                    adminState.currentBranch5,
                    false,
                    "TB",
                    false,
                    true,
                    expectedCodes
                );

            setIsLoadingScreen(false);
            return responseRequest;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getWorkflowDiagram ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getWorkflowDiagram ex`);
            setIsLoadingScreen(false);
            return null;
        }
    };

    //handle change tab
    const handleChangeTab = async (newTab: currentTabsEnum) => {
        let newWorkflowImage: string;
        let imageData: any;

        newWorkflowImage = "";
        if (
            newTab === currentTabsEnum.workflow &&
            adminState.currentWorkflowType !== "" &&
            adminState.currentWorkflowImage === ""
        ) {
            imageData = await getWorkflowDiagram();
            if (imageData) {
                newWorkflowImage = imageData.value;
            }
        }

        let command1: commandType = {
            type: commandsEnum.change_CurrentTab,
            payload: {
                newCurrentTab: newTab,
                newWorkflowImage: newWorkflowImage,
            },
        };
        dispatchCommand(command1);
    };

    //handle additional data change
    const handleChangeAditionalDataValue = (
        idControl: string,
        newValue: any,
        additionalDataId: string
    ) => {
        let command1: commandType = {
            type: commandsEnum.change_AdditionalDataValue,
            payload: {
                newValue: newValue,
                additionalDataId: additionalDataId,
            },
        };
        dispatchCommand(command1);
    };

    //check if there is any additional data visible
    //additonal data can appear and dissapear if you change classificators
    //previously enterered values are preserved but hidden if not exist for current classification
    const hasAnyVisibleAdditionalData = () => {
        for (
            let i: number = 0;
            i <= adminState.additionalDataValues.length - 1;
            i++
        ) {
            if ((adminState.additionalDataValues[i].value3 as boolean) === true) {
                return true;
            }
        }
        return false;
    };

    // Called when preferred agent modal returns insert/update
    const callbackPreferredAgentModalAnswer = async (
        response: boolean,
        id: number
    ) => {
        setModalPreferredAgentState(initialModalPreferredAgent);
        if (response) {
            let newGridPreferredAgents = await getPreferredAgents();
            if (newGridPreferredAgents) {
                let command1: commandType = {
                    type: commandsEnum.change_alreadyClickPreferredAgent,
                    payload: {
                        newAlreadyClickPreferredAgents: true,
                        newGridPreferredAgents: newGridPreferredAgents,
                    },
                };
                dispatchCommand(command1);
            } else {
                // todo: handle error
            }
        }
    };

    // Handler to create a new preferred agent
    const handleCreatePreferredAgentClick = () => {
        let newState: TPModalPreferredAgentState = {
            mode: "Insert",
            isShown: true,
            clientId: adminState.currentCustomerId,
            subsidiaryId: adminState.currentSubsidiaryId,
            id: 0,
            agentId: 0, // Initialize with appropriate default value
            priority: 1, // Initialize with appropriate default value
        };
        setModalPreferredAgentState(newState);
        // callbackPreferredAgentModalAnswer();
    };

    // Handler to select/unselect a preferred agent
    const handlePreferredAgentRowClick = async (row: any, event: any) => {
        let newGridPreferredAgents: Array<any> = [
            ...adminState.gridPreferredAgentData,
        ];
        let usePreferredAgent: boolean = false;
        let agentName: string = "";
        for (let i: number = 0; i <= newGridPreferredAgents.length - 1; i++) {
            if (newGridPreferredAgents[i].id == row["id"]) {
                newGridPreferredAgents[i].tpselectedRow =
                    !newGridPreferredAgents[i].tpselectedRow;
                if (newGridPreferredAgents[i].tpselectedRow) {
                    usePreferredAgent = true;
                    agentName = newGridPreferredAgents[i].agentName;
                }
            } else {
                newGridPreferredAgents[i].tpselectedRow = false;
            }
        }

        let command1: commandType = {
            type: commandsEnum.change_alreadyClickPreferredAgent,
            payload: {
                newAlreadyClickPreferredAgents: true,
                newGridPreferredAgents: newGridPreferredAgents,
            },
        };
        dispatchCommand(command1);

        setTimeout(async () => {
            let newGridPreferredAgents1 = await getPreferredAgents();
            if (newGridPreferredAgents1) {
                let command2: commandType = {
                    type: commandsEnum.change_alreadyClickPreferredAgent,
                    payload: {
                        newAlreadyClickPreferredAgents: true,
                        newGridPreferredAgents: newGridPreferredAgents1,
                    },
                };
                dispatchCommand(command2);
            }
        }, 2000)

    };

    // Handler to update a preferred agent
    const handlePreferredAgentUpdateClick = (
        e: any,
        id: number,
        clientId: number,
        subsidiaryId: number,
        agentId: number, // add this parameter if necessary
        priority: number // add this parameter if necessary
    ) => {
        e.preventDefault();
        e.stopPropagation();
        let newState: TPModalPreferredAgentState = {
            mode: "Update",

            isShown: true,
            clientId: clientId,
            subsidiaryId: subsidiaryId,
            id: id,
            agentId: agentId, // Use the appropriate value
            priority: priority, // Use the appropriate value
        };
        setModalPreferredAgentState(newState);
    };

    const handleDeletePreferredAgent = async (row: any) => {
        let preferredAgentService = new PreferredAgentService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoadingScreen(true);
            // Make an API call to delete the agent
            let response = await preferredAgentService.deletePreferredAgent(
                row.id, // Assuming `row.id` is the ID of the preferred agent
                true,
                false,
                expectedCodes
            );
            setIsLoadingScreen(false);
            if (response.responseResult) {
                // Filter out the deleted agent from the current state
                setGridPreferredAgentColumns((prevColumns) =>
                    prevColumns.filter((agent) => agent.id !== row.id)
                );
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleDeletePreferredAgent ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} handleDeletePreferredAgent ex`);
            setIsLoadingScreen(false);
        }


    };

    //called when contact modal returns insert/update
    const callbackContactModalAnswer = async (
        response: boolean,
        useContactAsPersonWhoReports: boolean,
        id_cont: number
    ) => {
        let contactName: string = "";
        setModalContactState(initialModalContact);

        if (response) {
            let newGridCustomerContacts = await getCustomerContacts();
            if (newGridCustomerContacts) {
                if (useContactAsPersonWhoReports) {
                    for (
                        let i: number = 0;
                        i <= newGridCustomerContacts.length - 1;
                        i++
                    ) {
                        if (newGridCustomerContacts[i].contactId == id_cont) {
                            newGridCustomerContacts[i].tpselectedRow = true;
                            contactName = newGridCustomerContacts[i].name;
                        } else {
                            newGridCustomerContacts[i].tpselectedRow = false;
                        }
                    }
                }
                let command1: commandType = {
                    type: commandsEnum.change_alreadyClickContacts,
                    payload: {
                        newAlreadyClickCustomerContacts: true,
                        newGridCustomerContacts: newGridCustomerContacts,
                        newSelectedContactAsReporter: useContactAsPersonWhoReports
                            ? id_cont
                            : 0,
                        newSelectedContactName: contactName,
                    },
                };
                dispatchCommand(command1);
            } else {
                //todo logs
            }
        }
    };

    //handler to create new concat
    const handleCreateContactClick = () => {
        let newState: TPModalContactState = {
            mode: "Insert",
            isShown: true,
            clientId: adminState.currentCustomerId,
            subsidiaryId: adminState.currentSubsidiaryId,
            contactId: 0,
        };
        setModalContactState(newState);
    };

    //handler to select/unselect a contact as person who reports case
    const handleContactRowClick = (row: any, event: any) => {
        let newGridCustomerContacts: Array<any> = [
            ...adminState.gridCustomerContacts,
        ];
        let useContactAsPersonWhoReports: boolean = false;
        let contactName: string = "";
        for (let i: number = 0; i <= newGridCustomerContacts.length - 1; i++) {
            if (newGridCustomerContacts[i].contactId == row["contactId"]) {
                newGridCustomerContacts[i].tpselectedRow =
                    !newGridCustomerContacts[i].tpselectedRow;
                if (newGridCustomerContacts[i].tpselectedRow) {
                    useContactAsPersonWhoReports = true;
                    contactName = newGridCustomerContacts[i].name;
                }
            } else {
                newGridCustomerContacts[i].tpselectedRow = false;
            }
        }
        let command1: commandType = {
            type: commandsEnum.change_alreadyClickContacts,
            payload: {
                newAlreadyClickCustomerContacts: true,
                newGridCustomerContacts: newGridCustomerContacts,
                newSelectedContactAsReporter: useContactAsPersonWhoReports
                    ? row["contactId"]
                    : 0,
                newSelectedContactName: contactName,
            },
        };
        dispatchCommand(command1);
    };

    //handler to update a contact
    const handleContactUpdateClick = (
        e: any,
        contactId: number,
        clientId: number,
        subsidiaryId: number
    ) => {
        e.preventDefault();
        e.stopPropagation();
        let newState: TPModalContactState = {
            mode: "Update",
            isShown: true,
            clientId: clientId,
            subsidiaryId: subsidiaryId,
            contactId: contactId,
        };
        setModalContactState(newState);
    };

    //called before save case to validate additional data
    const validateAdditonalData = (): boolean => {
        let atLeastOneError: boolean = false;
        for (
            let i: number = 0;
            i <= adminState.additionalDataValues.length - 1;
            i++
        ) {
            let element: TPKeyValue;
            element = adminState.additionalDataValues[i];
            //key: additional data id
            //value: addtional data value
            //value2: other properties
            //value3: should show

            if (element.value3) {
                if (childRefArray.current) {
                    const keys = Object.keys(childRefArray.current);
                    if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
                        let controlref: any = childRefArray.current[element.key];
                        if (!controlref.validateFromParent()) {
                            atLeastOneError = true;
                        }
                    }
                }
            }
        }
        if (atLeastOneError) {
            return false;
        }

        return true;
    };

    //called before save case to get additional data values
    const getFinalAdditonalDataValues =
        (): Array<TEmpAdditionalDataCaseViewModel> => {
            let result: Array<TEmpAdditionalDataCaseViewModel> = [];
            for (
                let i: number = 0;
                i <= adminState.additionalDataValues.length - 1;
                i++
            ) {
                let element: TPKeyValue;
                element = adminState.additionalDataValues[i];
                //key: additional data id
                //value: addtional data value
                //value2: other properties
                //value3: should show

                if (element.value3) {
                    if (childRefArray.current) {
                        const keys = Object.keys(childRefArray.current);
                        if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
                            let controlref: any = childRefArray.current[element.key];

                            let stringValue: string;
                            stringValue = controlref.getValueFromParent();
                            if (stringValue) {
                                let oneAddData: TEmpAdditionalDataCaseViewModel;
                                oneAddData = {
                                    additionalDataId: element.key,
                                    value: stringValue,
                                    isMandatory: element.value2.isMandatory,
                                };
                                result.push(oneAddData);
                            }
                        }
                    }
                }
            }
            return result;
        };

    //handler to save case
    const handlerSaveOnClick = async () => {
        let newModalErrorState: TPModalErrorStateType;
        let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> =
            [];
        let id_CASE: string;
        let successMessage: string = await TPI18N.GetText(
            resourceSet,
            "CaseNumber"
        );
        let newModalSuccessState: TPModalSuccessStateType;
        let atLeastOneError: boolean = false;
        let alreadyShowModalError: boolean = false;
        let comments: string;

        let branch1: string;
        let branch2: string;
        let branch3: string;
        let branch4: string;
        let branch5: string;

        branch1 =
            adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode
                ? adminState.currentBranch1
                : TPGlobal.Branch.NA_BranchCode;
        branch2 =
            adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode
                ? adminState.currentBranch2
                : TPGlobal.Branch.NA_BranchCode;
        branch3 =
            adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode
                ? adminState.currentBranch3
                : TPGlobal.Branch.NA_BranchCode;
        branch4 =
            adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode
                ? adminState.currentBranch4
                : TPGlobal.Branch.NA_BranchCode;
        branch5 =
            adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode
                ? adminState.currentBranch5
                : TPGlobal.Branch.NA_BranchCode;

        newModalErrorState = {
            callBackFunction: callBackModalError,
            title: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ModalErrorTitle"
            ),
            yesLabel: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ModalAcceptLabel"
            ),
            errorMessage: "",
            isShown: false,
        };

        setIsLoadingScreen(true);
        //validate customer
        if (!isAnonymousCustomer) {
            if (!alreadyHaveCustomer) {
                newModalErrorState.errorMessage = await TPI18N.GetText(
                    resourceSet,
                    "ErrorMustSelectAcustomerOrAnonymous"
                );
                newModalErrorState.isShown = true;
                setModalErrorState(newModalErrorState);
                atLeastOneError = true;
                alreadyShowModalError = true;
            }
        }

        //validate organization
        if (!adminState.currentOrganization) {
            setErrorMessageOrganization(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectOrganization")
            );
            atLeastOneError = true;
        }

        //validate relation
        if (!adminState.currentRelation) {
            setErrorMessageRelation(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectRelation")
            );
            atLeastOneError = true;
        }

        if (!branch1) {
            setErrorClassificatorBranch1(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectClassificator1")
            );
            atLeastOneError = true;
        }

        if (!branch2) {
            setErrorClassificatorBranch2(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectClassificator2")
            );
            atLeastOneError = true;
        }

        if (!branch3) {
            setErrorClassificatorBranch3(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectClassificator3")
            );
            atLeastOneError = true;
        }

        if (!branch4) {
            setErrorClassificatorBranch4(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectClassificator4")
            );
            atLeastOneError = true;
        }

        if (!branch5) {
            setErrorClassificatorBranch5(
                await TPI18N.GetText(resourceSet, "ErrorMustSelectClassificator5")
            );
            atLeastOneError = true;
        }

        //validate comments

        comments = editorValue.trim(); // Initial trim to remove whitespace around the content
        comments = comments.replace(/(<([^>]+)>)/gi, ""); // Strip HTML tags

        if (comments === "") {
            setEditorErrorMessage(
                await TPI18N.GetText(resourceSet, "ErrorNoComments")
            );
            atLeastOneError = true;
        } else {
            comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim(); // Sanitize and re-check

            if (comments === "") {
                setEditorErrorMessage(
                    await TPI18N.GetText(resourceSet, "ErrorNoComments")
                );
                atLeastOneError = true;
            }
        }

        //workflow type
        if (
            branch1 &&
            branch2 &&
            branch3 &&
            branch4 &&
            branch5 &&
            !isDirectSolution &&
            !adminState.currentWorkflowType
        ) {
            if (!alreadyShowModalError) {
                newModalErrorState.errorMessage = await TPI18N.GetText(
                    resourceSet,
                    "ErrorNoWorkflowAssigned"
                );
                newModalErrorState.isShown = true;
                setModalErrorState(newModalErrorState);
                atLeastOneError = true;
                alreadyShowModalError = true;
            }
        }

        //validate additional data
        if (!validateAdditonalData()) {
            atLeastOneError = true;
        }

        if (atLeastOneError) {
            setIsLoadingScreen(false);
            if (!alreadyShowModalError) {
                newModalErrorState.errorMessage = await TPI18N.GetText(
                    resourceSet,
                    "ErrorAtleastOneError"
                );
                newModalErrorState.isShown = true;
                setModalErrorState(newModalErrorState);
                atLeastOneError = true;
                alreadyShowModalError = true;
            }
            return;
        }
        newTempAdditionalDataValues = getFinalAdditonalDataValues();

        let recordInputDTO: CasesInputDTO = {
            useRPCMethod: true, // Use RPC method
            clientId: isAnonymousCustomer ? 0 : adminState.currentCustomerId,
            subsidiaryId: isAnonymousCustomer ? 0 : adminState.currentSubsidiaryId,
            subsidiaryOrganizationId: isAnonymousCustomer
                ? 0
                : adminState.currentSubsidiaryOrgId,
            baseLevelId: adminState.currentBaseLevel,
            classify1BranchId: branch1,
            classify2BranchId: branch2,
            classify3BranchId: branch3,
            classify4BranchId: branch4,
            classify5BranchId: branch5,
            comments: comments,
            typistGuidUser: TPGlobal.currentUserGuid,
            workflowTypeId: isDirectSolution
                ? "DISO"
                : adminState.currentWorkflowType,
            parentCaseId: null,
            isActive: true,
            isPrivate: false,
            isAnonymized: isAnonymousCustomer,
            contactId:
                adminState.selectedContactAsReporter !== 0
                    ? adminState.selectedContactAsReporter
                    : null,
            tEmpAdditionalDataCase: newTempAdditionalDataValues,
            guidCase: adminState.caseGuid,
            caseType: TPGlobal.CaseType.CASE_MANAGEMENT.toString(),
        };

        let inputDTOValidator = new CasesInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);

        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            setIsLoadingScreen(false);

            if (resultValidator.baseLevelId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.baseLevelId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.classify1BranchId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.classify1BranchId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.classify2BranchId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.classify2BranchId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.classify3BranchId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.classify3BranchId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.classify4BranchId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.classify4BranchId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.classify5BranchId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.classify5BranchId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.comments) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.comments
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.workflowTypeId) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.workflowTypeId
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }

            if (resultValidator.typistGuidUser) {
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetResource(
                        resultValidator.typistGuidUser
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
                return;
            }
        }
        comments = TPGlobal.stringToUTF8(comments).toString();
        let serviceClient = new CaseService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            recordInputDTO.comments = comments;
            let responseRequest = await serviceClient.insertNewCase(
                recordInputDTO,
                false,
                true,
                expectedCodes
            );

            let pendingTask: string;
            setHasPendingTask(false);
            if (responseRequest.responseResult) {
                id_CASE = responseRequest.responseData.keyList[0].value;
                pendingTask = responseRequest.responseData.keyList.find(
                    (item: { key: string }) => item.key === "HasPendingTask"
                )?.value;
                if (pendingTask != null && pendingTask.trim() !== "") {
                    if (pendingTask.toLowerCase() === "true") {
                        // Loading the case on case viewer
                        setHasPendingTask(true);
                        setIdCASE(parseInt(id_CASE, 10));
                    }
                }

                successMessage = `${successMessage} ${id_CASE}`;
                newModalSuccessState = {
                    callBackFunction: callBackModalSuccess,
                    title: await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "ModalSuccessTitle"
                    ),
                    yesLabel: await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "ModalAcceptLabel"
                    ),
                    successMessage: successMessage,
                    isShown: true,
                };
                setModalSuccessState(newModalSuccessState);

                setIsLoadingScreen(false);
            } else {
                //todo logs
                setIsLoadingScreen(false);
                if (!alreadyShowModalError) {
                    newModalErrorState.errorMessage = await TPI18N.GetText(
                        resourceSet,
                        "ErrorSaveCase"
                    );
                    newModalErrorState.isShown = true;
                    setModalErrorState(newModalErrorState);
                    alreadyShowModalError = true;
                }
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertNewCase ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} insertNewCase ex`);
            setIsLoadingScreen(false);
        }
    };

    //New Case Initial State
    const initialStateBLL: AdminStateType = {
        caseGuid: "",
        currentOrganization: "",
        currentRelation: "",
        currentBaseLevel: "",

        currentTree1: "",
        currentTree2: "",
        currentTree3: "",
        currentTree4: "",
        currentTree5: "",

        mustSelectLastLevelBranch1: false,
        mustSelectLastLevelBranch2: false,
        mustSelectLastLevelBranch3: false,
        mustSelectLastLevelBranch4: false,
        mustSelectLastLevelBranch5: false,

        currentLabelTree1: "",
        currentLabelTree2: "",
        currentLabelTree3: "",
        currentLabelTree4: "",
        currentLabelTree5: "",

        currentBranch1: "",
        currentBranch2: "",
        currentBranch3: "",
        currentBranch4: "",
        currentBranch5: "",
        currentDescriptionBranch1: "",
        currentDescriptionBranch2: "",
        currentDescriptionBranch3: "",
        currentDescriptionBranch4: "",
        currentDescriptionBranch5: "",
        temporaryAttachmentList: [],
        currentTab: currentTabsEnum.comments,
        showAdditionalData: false,
        currentWorkflowType: "",
        currentWorkflowDescription: "",
        currentWorkflowImage: "",

        //key: additional data id
        //value: addtional data value
        //value2: other properties
        //value3: should show
        additionalDataValues: [],

        //customer
        currentCustomerId: -1,
        currentSubsidiaryId: -1,
        currentSubsidiaryOrgId: -1,

        alreadyClickHistory: false,
        gridHistoryData: [],

        alreadyClickCustomerAttachments: false,
        gridCustomerAttachmentsData: [],

        alreadyClickCustomerContacts: false,
        gridCustomerContacts: [],
        selectedContactAsReporter: 0,
        selectedContactName: "",

        alreadyClickPreferredAgent: false,
        gridPreferredAgentData: [],
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
    function doCommand(prevState: AdminStateType, command: commandType) {
        let newAdminState: AdminStateType;
        let found: boolean;

        switch (command.type) {
            case commandsEnum.start_newCase:
                newAdminState = { ...initialStateBLL };
                newAdminState.caseGuid = uuidv4();

                //other parameter to override on command.payload
                if (command.payload.newCurrentOrganization) {
                    newAdminState.currentOrganization =
                        command.payload.newCurrentOrganization;
                    if (command.payload.newCurrentRelation) {
                        newAdminState.currentRelation = command.payload.newCurrentRelation;
                        newAdminState.currentBaseLevel =
                            command.payload.newCurrentBaseLevel;
                        newAdminState.currentTree1 = command.payload.newTree1;
                        newAdminState.currentTree2 = command.payload.newTree2;
                        newAdminState.currentTree3 = command.payload.newTree3;
                        newAdminState.currentTree4 = command.payload.newTree4;
                        newAdminState.currentTree5 = command.payload.newTree5;

                        newAdminState.mustSelectLastLevelBranch1 =
                            command.payload.newMustSelectLastLevelBranch1;
                        newAdminState.mustSelectLastLevelBranch2 =
                            command.payload.newMustSelectLastLevelBranch2;
                        newAdminState.mustSelectLastLevelBranch3 =
                            command.payload.newMustSelectLastLevelBranch3;
                        newAdminState.mustSelectLastLevelBranch4 =
                            command.payload.newMustSelectLastLevelBranch4;
                        newAdminState.mustSelectLastLevelBranch5 =
                            command.payload.newMustSelectLastLevelBranch5;

                        newAdminState.currentLabelTree1 = command.payload.newLabelTree1;
                        newAdminState.currentLabelTree2 = command.payload.newLabelTree2;
                        newAdminState.currentLabelTree3 = command.payload.newLabelTree3;
                        newAdminState.currentLabelTree4 = command.payload.newLabelTree4;
                        newAdminState.currentLabelTree5 = command.payload.newLabelTree5;
                    }
                }

                return newAdminState;
            case commandsEnum.change_CurrentOrganization:
                newAdminState = { ...prevState };
                newAdminState.currentOrganization = command.payload.newOrganization;
                newAdminState.currentTree1 = "";
                newAdminState.currentTree2 = "";
                newAdminState.currentTree3 = "";
                newAdminState.currentTree4 = "";
                newAdminState.currentTree5 = "";

                newAdminState.mustSelectLastLevelBranch1 = false;
                newAdminState.mustSelectLastLevelBranch2 = false;
                newAdminState.mustSelectLastLevelBranch3 = false;
                newAdminState.mustSelectLastLevelBranch4 = false;
                newAdminState.mustSelectLastLevelBranch5 = false;

                newAdminState.currentLabelTree1 = "";
                newAdminState.currentLabelTree2 = "";
                newAdminState.currentLabelTree3 = "";
                newAdminState.currentLabelTree4 = "";
                newAdminState.currentLabelTree5 = "";
                newAdminState.currentRelation = "";
                newAdminState.currentBaseLevel = "";

                newAdminState.currentBranch1 = "";
                newAdminState.currentBranch2 = "";
                newAdminState.currentBranch3 = "";
                newAdminState.currentBranch4 = "";
                newAdminState.currentBranch5 = "";
                newAdminState.currentDescriptionBranch1 = "";
                newAdminState.currentDescriptionBranch2 = "";
                newAdminState.currentDescriptionBranch3 = "";
                newAdminState.currentDescriptionBranch4 = "";
                newAdminState.currentDescriptionBranch5 = "";

                newAdminState.currentTab = currentTabsEnum.comments;
                newAdminState.showAdditionalData = false;
                newAdminState.currentWorkflowType = "";
                newAdminState.currentWorkflowDescription = "";
                newAdminState.currentWorkflowImage = "";

                newAdminState.additionalDataValues = [];

                return newAdminState;
            case commandsEnum.change_CurrentRelation:
                newAdminState = { ...prevState };
                newAdminState.currentRelation = command.payload.newRelation;
                newAdminState.currentBaseLevel = command.payload.newBaseLevel;

                newAdminState.currentTree1 = command.payload.newTree1;
                newAdminState.currentTree2 = command.payload.newTree2;
                newAdminState.currentTree3 = command.payload.newTree3;
                newAdminState.currentTree4 = command.payload.newTree4;
                newAdminState.currentTree5 = command.payload.newTree5;

                newAdminState.mustSelectLastLevelBranch1 =
                    command.payload.newMustSelectLastLevelBranch1;
                newAdminState.mustSelectLastLevelBranch2 =
                    command.payload.newMustSelectLastLevelBranch2;
                newAdminState.mustSelectLastLevelBranch3 =
                    command.payload.newMustSelectLastLevelBranch3;
                newAdminState.mustSelectLastLevelBranch4 =
                    command.payload.newMustSelectLastLevelBranch4;
                newAdminState.mustSelectLastLevelBranch5 =
                    command.payload.newMustSelectLastLevelBranch5;

                newAdminState.currentLabelTree1 = command.payload.newLabelTree1;
                newAdminState.currentLabelTree2 = command.payload.newLabelTree2;
                newAdminState.currentLabelTree3 = command.payload.newLabelTree3;
                newAdminState.currentLabelTree4 = command.payload.newLabelTree4;
                newAdminState.currentLabelTree5 = command.payload.newLabelTree5;
                newAdminState.currentBranch1 = "";
                newAdminState.currentBranch2 = "";
                newAdminState.currentBranch3 = "";
                newAdminState.currentBranch4 = "";
                newAdminState.currentBranch5 = "";
                newAdminState.currentDescriptionBranch1 = "";
                newAdminState.currentDescriptionBranch2 = "";
                newAdminState.currentDescriptionBranch3 = "";
                newAdminState.currentDescriptionBranch4 = "";
                newAdminState.currentDescriptionBranch5 = "";

                newAdminState.currentTab = currentTabsEnum.comments;
                newAdminState.showAdditionalData = false;
                newAdminState.currentWorkflowType = "";
                newAdminState.currentWorkflowDescription = "";
                newAdminState.currentWorkflowImage = "";

                newAdminState.additionalDataValues = [];

                return newAdminState;
            case commandsEnum.change_CurrentBranch:
                newAdminState = { ...prevState };

                switch (command.payload.treeNumber) {
                    case 1:
                        newAdminState.currentBranch1 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch1 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 2:
                        newAdminState.currentBranch2 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch2 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 3:
                        newAdminState.currentBranch3 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch3 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 4:
                        newAdminState.currentBranch4 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch4 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 5:
                        newAdminState.currentBranch5 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch5 =
                            command.payload.newDescriptionBranch;
                        break;
                }

                //workflow
                if (command.payload.newWorkflowType !== "") {
                    newAdminState.currentWorkflowType = command.payload.newWorkflowType;
                    newAdminState.currentWorkflowDescription =
                        command.payload.newWorkflowDescription;
                    newAdminState.currentWorkflowImage = "";
                } else {
                    if (newAdminState.currentTab === currentTabsEnum.workflow) {
                        newAdminState.currentTab = currentTabsEnum.comments;
                    }
                    newAdminState.currentWorkflowType = "";
                    newAdminState.currentWorkflowDescription = "";
                    newAdminState.currentWorkflowImage = "";
                }

                //additionalData
                if (command.payload.newAdditionalDataList.length >= 1) {
                    newAdminState.showAdditionalData = true;

                    //insert new additonal data. Found new elementes that exists on command.payload.newAdditionalDataList
                    //that not exists on newAdminState.additionalDataValues
                    for (
                        let i: number = 0;
                        i <= command.payload.newAdditionalDataList.length - 1;
                        i++
                    ) {
                        found = false;
                        for (
                            let j: number = 0;
                            j <= newAdminState.additionalDataValues.length - 1;
                            j++
                        ) {
                            if (
                                command.payload.newAdditionalDataList[i].id.toUpperCase() ===
                                newAdminState.additionalDataValues[j].key.toUpperCase()
                            ) {
                                found = true;
                                newAdminState.additionalDataValues[j].value3 = true;
                                break;
                            }
                        }
                        if (!found) {
                            newAdminState.additionalDataValues.push({
                                key: command.payload.newAdditionalDataList[i].id,
                                value: "",
                                value2: command.payload.newAdditionalDataList[i],
                                value3: true,
                            });
                        }
                    }
                    //change value3 to false for elements from newAdminState.additionalDataValues that does not exists on
                    //command.payload.newAdditionalDataList
                    for (
                        let i: number = 0;
                        i <= newAdminState.additionalDataValues.length - 1;
                        i++
                    ) {
                        found = false;
                        for (
                            let j: number = 0;
                            j <= command.payload.newAdditionalDataList.length - 1;
                            j++
                        ) {
                            if (
                                newAdminState.additionalDataValues[i].key.toUpperCase() ===
                                command.payload.newAdditionalDataList[j].id.toUpperCase()
                            ) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            newAdminState.additionalDataValues[i].value3 = false;
                        }
                    }
                    //todo order newAdminState.additionalDataValues by order in value2
                } else {
                    newAdminState.showAdditionalData = false;
                    //dont clear newAdminState.additionalDataValues to cache previously gathered
                    //values but hide everything
                    for (
                        let i: number = 0;
                        i <= newAdminState.additionalDataValues.length - 1;
                        i++
                    ) {
                        newAdminState.additionalDataValues[i].value3 = false;
                    }
                }
                return newAdminState;
            case commandsEnum.change_QuickClassifier:
                newAdminState = { ...prevState };
                newAdminState.currentBranch1 = command.payload.newbranch1;
                newAdminState.currentBranch2 = command.payload.newbranch2;
                newAdminState.currentBranch3 = command.payload.newbranch3;
                newAdminState.currentBranch4 = command.payload.newbranch4;
                newAdminState.currentBranch5 = command.payload.newbranch5;
                newAdminState.currentDescriptionBranch1 =
                    command.payload.newDescriptionBranch1;
                newAdminState.currentDescriptionBranch2 =
                    command.payload.newDescriptionBranch2;
                newAdminState.currentDescriptionBranch3 =
                    command.payload.newDescriptionBranch3;
                newAdminState.currentDescriptionBranch4 =
                    command.payload.newDescriptionBranch4;
                newAdminState.currentDescriptionBranch5 =
                    command.payload.newDescriptionBranch5;

                if (command.payload.newWorkflowType !== "") {
                    newAdminState.currentWorkflowType = command.payload.newWorkflowType;
                    newAdminState.currentWorkflowDescription =
                        command.payload.newWorkflowDescription;
                    newAdminState.currentWorkflowImage = "";
                } else {
                    if (newAdminState.currentTab === currentTabsEnum.workflow) {
                        newAdminState.currentTab = currentTabsEnum.comments;
                    }
                    newAdminState.currentWorkflowType = "";
                    newAdminState.currentWorkflowDescription = "";
                    newAdminState.currentWorkflowImage = "";
                }

                //additionalData
                if (command.payload.newAdditionalDataList.length >= 1) {
                    newAdminState.showAdditionalData = true;
                    //insert new additonal data. Found new elementes that exists on command.payload.newAdditionalDataList
                    //that not exists on newAdminState.additionalDataValues
                    for (
                        let i: number = 0;
                        i <= command.payload.newAdditionalDataList.length - 1;
                        i++
                    ) {
                        found = false;
                        for (
                            let j: number = 0;
                            j <= newAdminState.additionalDataValues.length - 1;
                            j++
                        ) {
                            if (
                                command.payload.newAdditionalDataList[i].id.toUpperCase() ===
                                newAdminState.additionalDataValues[j].key.toUpperCase()
                            ) {
                                found = true;
                                newAdminState.additionalDataValues[j].value3 = true;
                                break;
                            }
                        }
                        if (!found) {
                            newAdminState.additionalDataValues.push({
                                key: command.payload.newAdditionalDataList[i].id,
                                value: "",
                                value2: command.payload.newAdditionalDataList[i],
                                value3: true,
                            });
                        }
                    }
                    //change value3 to false for elements from newAdminState.additionalDataValues that does not exists on
                    //command.payload.newAdditionalDataList
                    for (
                        let i: number = 0;
                        i <= newAdminState.additionalDataValues.length - 1;
                        i++
                    ) {
                        found = false;
                        for (
                            let j: number = 0;
                            j <= command.payload.newAdditionalDataList.length - 1;
                            j++
                        ) {
                            if (
                                newAdminState.additionalDataValues[i].key.toUpperCase() ===
                                command.payload.newAdditionalDataList[j].id.toUpperCase()
                            ) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            newAdminState.additionalDataValues[i].value3 = false;
                        }
                    }
                    //todo order newAdminState.additionalDataValues by order in value2
                } else {
                    newAdminState.showAdditionalData = false;
                    //dont clear newAdminState.additionalDataValues to cache previously gathered
                    //values but hide everything
                    for (
                        let i: number = 0;
                        i <= newAdminState.additionalDataValues.length - 1;
                        i++
                    ) {
                        newAdminState.additionalDataValues[i].value3 = false;
                    }
                }

                if (branch1SelectorRef.current) {
                    branch1SelectorRef.current.resetData();
                }
                if (branch2SelectorRef.current) {
                    branch2SelectorRef.current.resetData();
                }
                if (branch3SelectorRef.current) {
                    branch3SelectorRef.current.resetData();
                }
                if (branch4SelectorRef.current) {
                    branch4SelectorRef.current.resetData();
                }
                if (branch5SelectorRef.current) {
                    branch5SelectorRef.current.resetData();
                }

                return newAdminState;
            case commandsEnum.change_AdditionalDataValue:
                newAdminState = { ...prevState };
                for (
                    let i: number = 0;
                    i <= newAdminState.additionalDataValues.length - 1;
                    i++
                ) {
                    if (
                        newAdminState.additionalDataValues[i].key.toUpperCase() ===
                        command.payload.additionalDataId.toUpperCase()
                    ) {
                        newAdminState.additionalDataValues[i].value =
                            command.payload.newValue;
                        break;
                    }
                }
                return newAdminState;
            case commandsEnum.add_temporaryAttachment:
                newAdminState = { ...prevState };
                let newAttachment: temporayAttachmentType;
                newAttachment = {
                    fileId: command.payload.fileId,
                    fileGuid: command.payload.fileGuid,
                    fileName: command.payload.fileName,
                };
                newAdminState.temporaryAttachmentList.push(newAttachment);
                setFilesDraggedList(null);
                return newAdminState;
            case commandsEnum.remove_temporaryAttachment:
                newAdminState = { ...prevState };
                let i;
                let newFileList: Array<temporayAttachmentType> = [];
                for (
                    i = 0;
                    i <= newAdminState.temporaryAttachmentList.length - 1;
                    i++
                ) {
                    if (
                        newAdminState.temporaryAttachmentList[i].fileId !==
                        command.payload.fileId
                    ) {
                        newFileList.push(newAdminState.temporaryAttachmentList[i]);
                    }
                }
                newAdminState.temporaryAttachmentList = newFileList;
                setFilesDraggedList(null);
                return newAdminState;
            case commandsEnum.change_CurrentTab:
                newAdminState = { ...prevState };
                newAdminState.currentTab = command.payload.newCurrentTab;
                if (
                    command.payload.newCurrentTab === currentTabsEnum.workflow &&
                    command.payload.newWorkflowImage !== ""
                ) {
                    newAdminState.currentWorkflowImage = command.payload.newWorkflowImage;
                }
                return newAdminState;
            case commandsEnum.change_CurrentCustomer:
                newAdminState = { ...prevState };
                newAdminState.currentCustomerId = command.payload.newCurrentCustomerId;
                newAdminState.currentSubsidiaryId =
                    command.payload.newCurrentSubsidiaryId;
                newAdminState.currentSubsidiaryOrgId =
                    command.payload.newCurrentSubsidiaryOrgId;
                newAdminState.alreadyClickHistory = false;
                newAdminState.gridHistoryData = [];
                newAdminState.alreadyClickCustomerAttachments = false;
                newAdminState.gridCustomerAttachmentsData = [];
                newAdminState.alreadyClickCustomerContacts = false;
                newAdminState.gridCustomerContacts = [];
                newAdminState.selectedContactAsReporter = 0;
                newAdminState.selectedContactName = "";
                return newAdminState;
            case commandsEnum.change_alreadyClickHistory:
                newAdminState = { ...prevState };
                newAdminState.alreadyClickHistory =
                    command.payload.newAlreadyClickHistory;
                newAdminState.gridHistoryData = command.payload.newGridHistoryData;

                if (!command.payload.newAlreadyClickHistory) {
                    if (refAccordionHistory1) {
                        refAccordionHistory1.current.classList.add("collapsed");
                        refAccordionHistory2.current.classList.remove("show");
                    }
                }
                return newAdminState;
            case commandsEnum.change_alreadyClickCustomerAttachments:
                newAdminState = { ...prevState };
                newAdminState.alreadyClickCustomerAttachments =
                    command.payload.newAlreadyClickCustomerAttachments;
                newAdminState.gridCustomerAttachmentsData =
                    command.payload.newGridCustomerAttachmentsData;

                if (!command.payload.newAlreadyClickCustomerAttachments) {
                    if (refAccordionAttachments1) {
                        refAccordionAttachments1.current.classList.add("collapsed");
                        refAccordionAttachments2.current.classList.remove("show");
                    }
                }

                return newAdminState;
            case commandsEnum.change_alreadyClickContacts:
                newAdminState = { ...prevState };
                newAdminState.alreadyClickCustomerContacts =
                    command.payload.newAlreadyClickCustomerContacts;
                newAdminState.gridCustomerContacts =
                    command.payload.newGridCustomerContacts;
                newAdminState.selectedContactAsReporter =
                    command.payload.newSelectedContactAsReporter;
                newAdminState.selectedContactName =
                    command.payload.newSelectedContactName;
                if (!command.payload.newAlreadyClickCustomerContacts) {
                    if (refAccordionContacts1) {
                        refAccordionContacts1.current.classList.add("collapsed");
                        refAccordionContacts2.current.classList.remove("show");
                    }
                }
                return newAdminState;

            case commandsEnum.change_alreadyClickPreferredAgent:
                newAdminState = { ...prevState };
                newAdminState.alreadyClickPreferredAgent =
                    command.payload.newAlreadyClickPreferredAgents;
                newAdminState.gridPreferredAgentData =
                    command.payload.newGridPreferredAgents;
                if (!command.payload.newAlreadyClickPreferredAgents) {
                    if (refAccordionPreferredAgent1) {
                        refAccordionPreferredAgent1.current.classList.add("collapsed");
                        refAccordionPreferredAgent2.current.classList.remove("show");
                    }
                }
                return newAdminState;

            default:
                return prevState;
        }
    }

    const handleToChange = async (newSelectedValue: Array<TPKeyValue>) => {
        setErrorClassificatorBranch1("");
        setErrorClassificatorBranch2("");
        setErrorClassificatorBranch3("");
        setErrorClassificatorBranch4("");
        setErrorClassificatorBranch5("");

        let newValue: string = newSelectedValue[0].key;
        let index: number;

        let branch1: string;
        let branch2: string;
        let branch3: string;
        let branch4: string;
        let branch5: string;
        let branchDescription1: string;
        let branchDescription2: string;
        let branchDescription3: string;
        let branchDescription4: string;
        let branchDescription5: string;
        let calculateWorkflowAndAdditionalData: boolean = true;
        let newWorkflowType: string;
        let newWorkflowDescription: string;
        let additionalDataList: Array<AdditionalDataViewModel> | null = null;
        let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

        branch1 = "";
        branch2 = "";
        branch3 = "";
        branch4 = "";
        branch5 = "";
        branchDescription1 = "";
        branchDescription2 = "";
        branchDescription3 = "";
        branchDescription4 = "";
        branchDescription5 = "";
        newWorkflowType = "";
        newWorkflowDescription = "";
        setSelectedAutocompleteQuickClassifierOption(newSelectedValue);

        if (newValue != "") {
            index = autocompleteQuickClassifierOptions.findIndex(
                (x: TPKeyValue) => x.key === newValue
            );
            if (index !== -1) {
                //should not happen

                if (
                    autocompleteQuickClassifierOptions[index].value2.classifier1Id &&
                    autocompleteQuickClassifierOptions[index].value2.classifier1Id !==
                    "" &&
                    adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode
                ) {
                    branch1 =
                        autocompleteQuickClassifierOptions[index].value2.classifier1Id;
                    branchDescription1 =
                        autocompleteQuickClassifierOptions[index].value3
                            .localizedClassifier1;
                    setDefaultBranchHierarchyDescription1(
                        autocompleteQuickClassifierOptions[index].value4
                            .localizedHierarchyClassifier1
                    );
                } else {
                    setDefaultBranchHierarchyDescription1("");
                }
                if (
                    autocompleteQuickClassifierOptions[index].value2.classifier2Id &&
                    autocompleteQuickClassifierOptions[index].value2.classifier2Id !==
                    "" &&
                    adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode
                ) {
                    branch2 =
                        autocompleteQuickClassifierOptions[index].value2.classifier2Id;
                    branchDescription2 =
                        autocompleteQuickClassifierOptions[index].value3
                            .localizedClassifier2;
                    setDefaultBranchHierarchyDescription2(
                        autocompleteQuickClassifierOptions[index].value4
                            .localizedHierarchyClassifier2
                    );
                } else {
                    setDefaultBranchHierarchyDescription2("");
                }
                if (
                    autocompleteQuickClassifierOptions[index].value2.classifier3Id &&
                    autocompleteQuickClassifierOptions[index].value2.classifier3Id !==
                    "" &&
                    adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode
                ) {
                    branch3 =
                        autocompleteQuickClassifierOptions[index].value2.classifier3Id;
                    branchDescription3 =
                        autocompleteQuickClassifierOptions[index].value3
                            .localizedClassifier3;
                    setDefaultBranchHierarchyDescription3(
                        autocompleteQuickClassifierOptions[index].value4
                            .localizedHierarchyClassifier3
                    );
                } else {
                    setDefaultBranchHierarchyDescription3("");
                }
                if (
                    autocompleteQuickClassifierOptions[index].value2.classifier4Id &&
                    autocompleteQuickClassifierOptions[index].value2.classifier4Id !==
                    "" &&
                    adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode
                ) {
                    branch4 =
                        autocompleteQuickClassifierOptions[index].value2.classifier4Id;
                    branchDescription4 =
                        autocompleteQuickClassifierOptions[index].value3
                            .localizedClassifier4;
                    setDefaultBranchHierarchyDescription4(
                        autocompleteQuickClassifierOptions[index].value4
                            .localizedHierarchyClassifier4
                    );
                } else {
                    setDefaultBranchHierarchyDescription4("");
                }
                if (
                    autocompleteQuickClassifierOptions[index].value2.classifier5Id &&
                    autocompleteQuickClassifierOptions[index].value2.classifier5Id !==
                    "" &&
                    adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode
                ) {
                    branch5 =
                        autocompleteQuickClassifierOptions[index].value2.classifier5Id;
                    branchDescription5 =
                        autocompleteQuickClassifierOptions[index].value3
                            .localizedClassifier5;
                    setDefaultBranchHierarchyDescription5(
                        autocompleteQuickClassifierOptions[index].value4
                            .localizedHierarchyClassifier5
                    );
                } else {
                    setDefaultBranchHierarchyDescription5("");
                }
                if (
                    adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode &&
                    branch1 === ""
                ) {
                    calculateWorkflowAndAdditionalData = false;
                }
                if (
                    adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode &&
                    branch2 === ""
                ) {
                    calculateWorkflowAndAdditionalData = false;
                }
                if (
                    adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode &&
                    branch3 === ""
                ) {
                    calculateWorkflowAndAdditionalData = false;
                }
                if (
                    adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode &&
                    branch4 === ""
                ) {
                    calculateWorkflowAndAdditionalData = false;
                }
                if (
                    adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode &&
                    branch5 === ""
                ) {
                    calculateWorkflowAndAdditionalData = false;
                }
                //calculate workflow
                if (calculateWorkflowAndAdditionalData) {
                    let workflowData: WorkflowTypeViewModel | null;
                    workflowData = await getWorkflowType(
                        adminState.currentBaseLevel,
                        branch1,
                        branch2,
                        branch3,
                        branch4,
                        branch5
                    );
                    if (workflowData) {
                        newWorkflowType = workflowData.id;
                        newWorkflowDescription =
                            workflowData.localizedDescription &&
                                workflowData.localizedDescription !== ""
                                ? workflowData.localizedDescription
                                : workflowData.description;
                    }
                }
                //calculate additional data list
                if (calculateWorkflowAndAdditionalData) {
                    additionalDataList = await getAdditionalDataForClassification(
                        branch1,
                        branch2,
                        branch3,
                        branch4,
                        branch5
                    );
                    if (additionalDataList && additionalDataList.length >= 1) {
                        newAdditionalDataList = [...additionalDataList];
                    }
                }

                //dispatch command to change 5 classifiers at a time

                let command1: commandType = {
                    type: commandsEnum.change_QuickClassifier,
                    payload: {
                        newbranch1: branch1,
                        newbranch2: branch2,
                        newbranch3: branch3,
                        newbranch4: branch4,
                        newbranch5: branch5,
                        newDescriptionBranch1: branchDescription1,
                        newDescriptionBranch2: branchDescription2,
                        newDescriptionBranch3: branchDescription3,
                        newDescriptionBranch4: branchDescription4,
                        newDescriptionBranch5: branchDescription5,
                        newWorkflowType: newWorkflowType,
                        newWorkflowDescription: newWorkflowDescription,
                        newAdditionalDataList: newAdditionalDataList,
                    },
                };
                dispatchCommand(command1);
            }
        }
    };

    //get quick classificators for a baselevel
    const handleToOnAutocompleteQuery = async (
        query: string,
        newBaseLevel: string
    ) => {
        let newQuickClassifierList: Array<TPKeyValue> = [];
        let serviceClient = new QuickClassifierService();
        let expectedCodes: Array<number> = [200];
        let quickData: Array<QuickClassifierViewModel> = [];
        try {
            //todo use another method to get only valid branches
            //due to must select last level branch
            let responseRequest = await serviceClient.getSearchQuickClassifiers(
                newBaseLevel,
                4, //===>Quick Classifiers
                TPActiveOptions.ACTIVE,
                query,
                false,
                false,
                expectedCodes
            );
            quickData = responseRequest;
            if (quickData) {
                for (let i: number = 0; i <= quickData.length - 1; i++) {
                    if (quickData[i].isActive) {
                        newQuickClassifierList.push({
                            key: quickData[i].id,
                            value: quickData[i].localizedDescription
                                ? quickData[i].localizedDescription
                                : quickData[i].description,
                            value2: {
                                classifier1Id: quickData[i].classifier1Id,
                                classifier2Id: quickData[i].classifier2Id,
                                classifier3Id: quickData[i].classifier3Id,
                                classifier4Id: quickData[i].classifier4Id,
                                classifier5Id: quickData[i].classifier5Id,
                            },
                            value3: {
                                localizedClassifier1: quickData[i].localizedClassifier1,
                                localizedClassifier2: quickData[i].localizedClassifier2,
                                localizedClassifier3: quickData[i].localizedClassifier3,
                                localizedClassifier4: quickData[i].localizedClassifier4,
                                localizedClassifier5: quickData[i].localizedClassifier5,
                            },
                            value4: {
                                localizedHierarchyClassifier1:
                                    quickData[i].localizedHierarchyClassifier1,
                                localizedHierarchyClassifier2:
                                    quickData[i].localizedHierarchyClassifier2,
                                localizedHierarchyClassifier3:
                                    quickData[i].localizedHierarchyClassifier3,
                                localizedHierarchyClassifier4:
                                    quickData[i].localizedHierarchyClassifier4,
                                localizedHierarchyClassifier5:
                                    quickData[i].localizedHierarchyClassifier5,
                            },
                        });
                    }
                }
            }
            setAutocompleteQuickClassifierOptions(newQuickClassifierList);
            return newQuickClassifierList;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} handleToOnAutocompleteQuery ex`
            );
            return [];
        }
    };

    const handleQuickClassifierOnAutocompleteKeyDown = (event: any) => {
        //tab 9
        //left arrow 37
        //right arror 39
        //enter 13
        //home 36
        //end  35
        //ArrowUp  38
        //ArrowDown  40
        if (
            event.keyCode != 9 &&
            event.keyCode != 37 &&
            event.keyCode != 39 &&
            event.keyCode != 13 &&
            event.keyCode != 35 &&
            event.keyCode != 36 &&
            event.keyCode != 38 &&
            event.keyCode != 40
        ) {
            setSelectedAutocompleteQuickClassifierOption([]);
        }
    };

    const handleAutoCompleteTopNClick = async () => {
        let newTopNOptions: Array<TPKeyValue> = [];

        // Check if cached options are available and match the current base level
        const isCacheValid =
            autocompleteQuickClassifierTopNOptions.length > 0 &&
            autocompleteQuickClassifierTopNOptions.some(
                (option) => option.value2?.baseLevel === adminState.currentBaseLevel
            );

        if (!isCacheValid) {
            // Fetch new data if cache is empty or not valid for the current base level
            newTopNOptions = await handleToOnAutocompleteQuery(
                "",
                adminState.currentBaseLevel
            );

            if (newTopNOptions.length >= 1) {
                // Save fetched options in cache and update the displayed options
                setAutocompleteQuickClassifierTopNOptions([...newTopNOptions]);
                setAutocompleteQuickClassifierOptions([...newTopNOptions]);
                setSelectedAutocompleteQuickClassifierOption([]); // Reset the selected option if necessary
            }
        } else {
            // Use cached values if they are valid
            setAutocompleteQuickClassifierOptions([
                ...autocompleteQuickClassifierTopNOptions,
            ]);
            setSelectedAutocompleteQuickClassifierOption([]); // Reset the selected option if necessary
        }
    };

    //inital cache
    const initialCacheState: CacheStateType = {
        classificatorsRelatedData: [],
        quickClassifiersRelatedData: [],
        customerTypeRelatedData: [],
    };

    //cache reducer
    const [cacheOrganizationRelationRelatedData, cacheDispatchCommand] =
        useReducer(doCacheCommand, initialCacheState);
    function doCacheCommand(
        prevState: CacheStateType,
        command: cacheCommandType
    ) {
        let newCacheState: CacheStateType;
        switch (command.type) {
            case cacheCommandsEnum.store_cache_classificatorData:
                newCacheState = { ...prevState };
                newCacheState.classificatorsRelatedData.push(
                    command.payload.newClassificatorRelatedData
                );
                return newCacheState;
            case cacheCommandsEnum.store_cache_quickClassifiers:
                newCacheState = { ...prevState };
                newCacheState.quickClassifiersRelatedData.push(
                    command.payload.newQuickClassifiersData
                );
                return newCacheState;
            case cacheCommandsEnum.store_cache_CustomerType:
                newCacheState = { ...prevState };
                newCacheState.customerTypeRelatedData = [
                    ...command.payload.newCustomerTypeRelatedData,
                ];
                return newCacheState;
            default:
                return prevState;
        }
    }

    //Run only once when page is loaded
    useEffect(() => {
        loadResourcesAndOrganizations();
    }, []);

    //run when attachments are dragged to upload it
    useEffect(() => {
        const handleUploadFile = async (filesDraggedList: any) => {
            let serviceClient = new TemporaryAttachmentService();
            let expectedCodes: Array<number> = [200];
            let filename: string;
            let extension: string;
            let parts: Array<string> = [];
            let operationGuid: string;
            let blobId: string;
            try {
                setIsLoadingScreen(true);
                for (let i: number = 0; i <= filesDraggedList.length - 1; i++) {
                    filename = filesDraggedList[i].name;
                    parts = filename.split(".");
                    if (parts.length === 1) {
                        extension = "";
                    } else {
                        extension = parts[parts.length - 1];
                    }
                    //todo send sender, guid user, etc
                    operationGuid = adminState.caseGuid;
                    blobId = "caseblob" + uuidv4().replaceAll("-", "");
                    const fileData = new FormData();
                    fileData.append("fileData", filesDraggedList[i]);
                    fileData.append("operationGuid", operationGuid);
                    fileData.append("blobId", blobId);
                    fileData.append("filename", filename);
                    fileData.append("extensionName", extension);
                    fileData.append("sender", "senderxx");
                    fileData.append("description", "descriptionxxxx");
                    fileData.append("guid_USER", TPGlobal.currentUserGuid);
                    let responseRequest = await serviceClient.insertTemporaryAttachment(
                        fileData,
                        false,
                        true,
                        expectedCodes
                    );
                    if (responseRequest.responseResult) {
                        let command1: commandType = {
                            type: commandsEnum.add_temporaryAttachment,
                            payload: {
                                fileId: responseRequest.responseData.keyList[0].value,
                                fileGuid: blobId,
                                fileName: filename,
                            },
                        };
                        dispatchCommand(command1);
                    }
                }
                setIsLoadingScreen(false);
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleUploadFile ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} handleUploadFile ex`);
                setIsLoadingScreen(false);
            }
        };

        if (filesDraggedList) {
            handleUploadFile(filesDraggedList);
        }
    }, [filesDraggedList]);

    useEffect(() => {
        const handleKeyDown = async (event: KeyboardEvent) => {
            if (
                event.altKey &&
                event.key >= "0" &&
                event.key <= "9" &&
                adminState.currentOrganization &&
                adminState.currentRelation
            ) {
                const hotkey = Number(event.key);

                // Call the API to get the quick classifier details based on the hotkey
                const quickClassifierShortcutService =
                    new QuickClassifierShortcutService();
                try {
                    const classifierResponse =
                        await quickClassifierShortcutService.getQuickClassifierShortcutHotkeyNewcaseById(
                            adminState.currentBaseLevel,
                            TPGlobal.currentUserGuid,
                            hotkey,
                            false,
                            true,
                            [200, 404]
                        );

                    if (classifierResponse && classifierResponse.length) {
                        fillQuickClassifierDetails(classifierResponse[0]);
                    } else {
                        console.warn("No classifier found for this hotkey");
                    }
                } catch (error) {
                    console.error("Error fetching classifier for hotkey:", error);
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [
        adminState.currentBaseLevel,
        adminState.currentOrganization,
        adminState.currentRelation,
    ]);

    const fillQuickClassifierDetails = (classifier: any) => {
        handleToChange([
            {
                key: classifier.webserviceClassifierUserId,
                value: classifier.localizedDescriptionWebServiceClassifier,
            },
        ]);

        // Set classifier hierarchy levels
        if (classifier.localizedClassifier1) {
            handleToChange([
                { key: "classifier1", value: classifier.localizedClassifier1 },
            ]);
        }
        if (classifier.localizedClassifier2) {
            handleToChange([
                { key: "classifier2", value: classifier.localizedClassifier2 },
            ]);
        }
        if (classifier.localizedClassifier3) {
            handleToChange([
                { key: "classifier3", value: classifier.localizedClassifier3 },
            ]);
        }
        if (classifier.localizedClassifier4) {
            handleToChange([
                { key: "classifier4", value: classifier.localizedClassifier4 },
            ]);
        }
        if (classifier.localizedClassifier5) {
            handleToChange([
                { key: "classifier5", value: classifier.localizedClassifier5 },
            ]);
        }

        if (classifier.localizedDescriptionWebServiceClassifier) {
            handleToChange([
                {
                    key: "baseLevelId",
                    value: classifier.localizedDescriptionWebServiceClassifier,
                },
            ]);
        }
    };

    const { focus: horizontalTabFocus } = useSelector(
        (s: StoreModel) => s[HorizontalTabsSlice.name],
    ) as HorizontalTabsStateModel;
    
    const [newCaseId, setNewCaseId] = useState(0);

    const [timer, setTimer] = useState<number>(-1);

    const getNewCaseId = async () => {
        let serviceCase = new CaseService();

        let inputDTO = {
            id: 0,
            UserGuid: TPGlobal.currentUserGuid
        }

        try {
            let response = await serviceCase.getNewCaseId(
                inputDTO
            )

            if (response) {
                setNewCaseId(response.keyList[0].value);
                setTimer(0);
            }

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getNewCaseId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getNewCaseId ex`);
        }
    }

    const saveNewCaseTime = async () => {
        let serviceCase = new CaseService();

        let inputDTO = {
            id: newCaseId,
            UserGuid: TPGlobal.currentUserGuid
        }

        try {
            await serviceCase.newCaseKeepAlive(
                inputDTO
            )
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} saveNewCaseTime ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} saveNewCaseTime ex`);
        }
    }

    useEffect(() => {
        if (timer%30 == 0 && newCaseId !== 0) {
            saveNewCaseTime();
        }
    }, [timer]);

    useEffect(() => {
        if (timer > -1) {
            const intervalId = setInterval(() => {
                if (horizontalTabFocus === "TPClientCloudMain.Client.Components.Cases.NewCaseComponent") {
                    setTimer((prevTimer) => prevTimer + 1);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [horizontalTabFocus, timer]);

    useEffect(() => {
        if (TPGlobal.currentUserGuid !== ""){
            getNewCaseId();
        }
    }, [TPGlobal.currentUserGuid]);
    

    return (
        <ContentVerticalNoTabsStyled>
            <div className="container-fluid">
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div className="row">
                        <div className="col">
                            {isShownModalNewClient && (
                                <TPModalNewClient
                                    mode={clientMode}
                                    acceptLabel={saveButtonLabel}
                                    cancelLabel={cancelButtonLabel}
                                    isShown={isShownModalNewClient}
                                    callBackAnswer={callbackNewClientAndUpdateClientModalAnswer}
                                    subsidiaryOrgId={adminState.currentSubsidiaryOrgId}
                                ></TPModalNewClient>
                            )}
                            {modalContactState.isShown && (
                                <TPModalContactsInsertUpdate
                                    acceptLabel={saveButtonLabel}
                                    cancelLabel={cancelButtonLabel}
                                    callBackAnswer={callbackContactModalAnswer}
                                    showCheckboxToUseConctactAsReporterPerson={true}
                                    modalState={modalContactState}
                                />
                            )}
                            {modalPreferredAgentState.isShown && (
                                <TPModalPreferredAgentInsertUpdate
                                    acceptLabel={saveButtonLabel}
                                    cancelLabel={cancelButtonLabel}
                                    callBackAnswer={callbackPreferredAgentModalAnswer}
                                    modalState={modalPreferredAgentState}
                                />
                            )}
                        </div>
                    </div>
                    {/* customer column */}
                    <div className="row">
                        {/* customer search zone */}
                        <div className="col-3">
                            <div
                                style={{
                                    border: "1px solid  #dee2e6",
                                    borderBottom: "none",
                                    padding: "5px",
                                }}
                            >
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        {/* <img src={buddyImg} width={80}></img> */}
                                        <DefaultAvatarIcon style={{ cursor: "pointer" }} />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: alreadyHaveCustomer ? "none" : "block",
                                    }}
                                >
                                    {!isAnonymousCustomer && (
                                        <div className="row mt-2 d-flex align-items-center justify-content-between">
                                            <div className="col-6">
                                                <TPPageTitle style={{ fontSize: "20px" }}>
                                                    {titleLabel}
                                                </TPPageTitle>
                                            </div>
                                            <div className="col-6">
                                                <TPCheckBox
                                                    id="anonymous-check"
                                                    className="mt-2"
                                                    checked={isAnonymousCustomer}
                                                    labelText={anonymousCustomerLabel}
                                                    onChange={handleAnonymousOnChange}
                                                />
                                            </div>


                                        </div>
                                    )}
                                    {currentSearchCriteria !== searchCiteriaEnum.additionaldata &&
                                        searchCriteriaList.length > 0 && (
                                            <>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <div>
                                                            <TPSelect
                                                                id="search-customer-by"
                                                                isMandatory={true}
                                                                labelText={searchCriteriaLabel}
                                                                onChange={handleCustomerSearchCriteriaChange}
                                                                dataSource={searchCriteriaList}
                                                                value={currentSearchCriteria}
                                                                disabled={isAnonymousCustomer}
                                                                errorMessage={selectSearchCriteriaErrorMessage}
                                                            ></TPSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <div>
                                                            <TPTextBox
                                                                id="look-value-text"
                                                                onChange={(e: any) => {
                                                                    handleChangeCustormerSearchValue(e);
                                                                }}
                                                                onKeyPress={(e:any)=>{
                                                                    if(e.key === "Enter"){
                                                                        handleSearchOnClick();
                                                                    }
                                                                }}
                                                                value={currentSearchCustomerValue}
                                                                labelText={searchCriteriaValueLabel}
                                                                isMandatory={true}
                                                                disabled={isAnonymousCustomer}
                                                                errorMessage={selectSearchValueErrorMessage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    {currentSearchCriteria ===
                                        searchCiteriaEnum.additionaldata && (
                                            <>
                                                <div className="row mt-2">
                                                    {searchCriteriaList.length > 0 && (
                                                        <div className="col-12">
                                                            <div>
                                                                <TPSelect
                                                                    id="search-customer-criteria"
                                                                    isMandatory={true}
                                                                    labelText={searchCriteriaLabel}
                                                                    onChange={handleCustomerSearchCriteriaChange}
                                                                    dataSource={searchCriteriaList}
                                                                    value={currentSearchCriteria}
                                                                    disabled={isAnonymousCustomer}
                                                                    errorMessage={selectSearchCriteriaErrorMessage}
                                                                ></TPSelect>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <TPSelect
                                                            id="search-additonal-data-criteria"
                                                            labelText={selectAdditionalDataLabel}
                                                            onChange={() => {
                                                                TPGlobal.foo();
                                                            }}
                                                            dataSource={[]}
                                                            value={null}
                                                            disabled={isAnonymousCustomer}
                                                            errorMessage={""}
                                                        ></TPSelect>
                                                    </div>
                                                    <div className="col-6">
                                                        <TPTextBox
                                                            id="criterial-text"
                                                            isMandatory={true}
                                                            onChange={(e: any) => {
                                                                handleChangeCustormerSearchValue(e);
                                                            }}
                                                            value={currentSearchCustomerValue}
                                                            labelText={searchCriteriaValueLabel}
                                                            disabled={isAnonymousCustomer}
                                                            errorMessage={selectSearchValueErrorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    <div className="row mt-2">
                                        <div className="col d-flex ">
                                            {canCreateAnyCustomer && (
                                                <TPButton
                                                    id="new-customer"
                                                    type={TPButtonTypes.toolbar}
                                                    onClick={() => handleNewCustomerClick()}
                                                    style={{ color:"#780096"}}
                                                >
                                                  {/*  <NewCustomerIcon />*/}
                                                    {newCustomerButtonLabel} 
                                                </TPButton>
                                            )}
                                        </div>
                                        {/*123*/}
                                        <div className="col d-flex justify-content-end">
                                            {cancelAlreadyHaveCustomer && (
                                                <TPButton
                                                    id="cancel-customer"
                                                    type={TPButtonTypes.link}
                                                    onClick={handleCancelCustomerOnClick}
                                                >
                                                    {cancelButtonLabel}
                                                </TPButton>
                                            )}
                                            <div className="col d-flex justify-content-end">
                                                {!isAnonymousCustomer && (
                                                    <TPButton
                                                        id="search-customer"
                                                        children={<span>{searchCustomerLabel}</span>}
                                                        onClick={handleSearchOnClick}
                                                        type={TPButtonTypes.primary}
                                                        style={(selectAdditionalDataLabel == "" || currentSearchCustomerValue=="") ?
                                                            {
                                                                backgroundColor: "#e6e6e6",
                                                                color: "#989898",
                                                                border: "none"
                                                            } : { } }
                                                        disabled={(selectAdditionalDataLabel == "" || currentSearchCustomerValue == "")}
                                                    />
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/* Customer information  */}
                                <div
                                    style={{ display: alreadyHaveCustomer ? "block" : "none" }}
                                >
                                    <div className="row">
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-type-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsType}
                                                labelText={customerResultsTypeLabel}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-type-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsNames}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-document-type-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsDocumentType}
                                                labelText={customerResultsDocumentTypeLabel}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-document-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsDocument}
                                                labelText={customerResultsDocumentLabel}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-email-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsEmail}
                                                labelText={customerResultsEmailLabel}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-phone-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsPhone}
                                                labelText={customerResultsPhoneLabel}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TPTextBox
                                                id="customer-result-code-text"
                                                onChange={() => {
                                                    TPGlobal.foo();
                                                }}
                                                value={dataCustomerResultsInternalCode}
                                                labelText={customerResultsInternalCodeLabel}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-6" style={{ marginTop: "30px" }}>
                                            {enableEditCustomer && (
                                                <TPButton
                                                    id="edit-customer"
                                                    children={<span>{editCustomerButtonLabel}</span>}
                                                    onClick={() => {
                                                        handleEditCustomerOnClick();
                                                    }}
                                                    type={TPButtonTypes.primary}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            {adminState.selectedContactAsReporter !== 0 && (
                                                <>
                                                    <span style={{ color: "red", fontWeight: "600" }}>
                                                        {personWhoReportsLabel}
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: "14px" }}>
                                                        {adminState.selectedContactName}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TPButton
                                                id="search-again-customer"
                                                children={<span>{searchAgainCustomerButtonLabel}</span>}
                                                onClick={handleSearchAgainOnClick}
                                                type={TPButtonTypes.link}
                                                style={{ padding: "0" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* accordions zone */}
                            <div style={{ border: "1px solid  #dee2e6", borderTop: "none" }}>
                                <div
                                    className="accordion"
                                    style={{
                                        visibility:
                                            isAnonymousCustomer || !alreadyHaveCustomer
                                                ? "hidden"
                                                : "visible",
                                    }}
                                >
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                id="change-accordion-btn"
                                                ref={refAccordionHistory1}
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse1"
                                                onClick={() =>
                                                    handleAccordionChange(currentAccordionsEnum.history)
                                                }
                                            >
                                                {historyCaseLabel}
                                            </button>
                                        </h2>
                                        <div
                                            ref={refAccordionHistory2}
                                            id="collapse1"
                                            className="accordion-collapse collapse"
                                        >
                                            <div className="accordion-body">
                                                {adminState.alreadyClickHistory && (
                                                    <TPFixedTable
                                                        dataGrid={adminState.gridHistoryData}
                                                        columns={gridHistoryColumns}
                                                        tableHeight={"200px"}
                                                        keyField={"tpguidrow"}
                                                        highlightOnHover={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                id="change-accordion-second-btn"
                                                ref={refAccordionContacts1}
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse3"
                                                onClick={() =>
                                                    handleAccordionChange(
                                                        currentAccordionsEnum.customer_contacts
                                                    )
                                                }
                                            >
                                                {contactsLabel}
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse3"
                                            ref={refAccordionContacts2}
                                            className="accordion-collapse collapse"
                                        >
                                            <div className="accordion-body">
                                                <div className="row mt-1">
                                                    <div className="col d-flex justify-content-end">
                                                        <div className="form-group">
                                                            <TPButton
                                                                id="create-contact"
                                                                type={TPButtonTypes.icon}
                                                                onClick={handleCreateContactClick}
                                                                text={createContactLabel}
                                                                icon={TPIconTypes.newEntity}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    {adminState.alreadyClickCustomerContacts && (
                                                        <>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <TPFixedTable
                                                                        dataGrid={adminState.gridCustomerContacts}
                                                                        columns={gridContactsColumns}
                                                                        tableHeight={"200px"}
                                                                        keyField={"tpguidrow"}
                                                                        highlightOnHover={true}
                                                                        selectableRows={true}
                                                                        multipleSelection={false}
                                                                        onRowClicked={(row: any, event: any) => {
                                                                            handleContactRowClick(row, event);
                                                                        }}
                                                                        styleSelectedRow={{
                                                                            backgroundColor: "#cff4fc",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-1">
                                                                <div className="col-12">
                                                                    <div className="alert alert-info">
                                                                        {contactReportingPersonNote}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                id="change-accordion-third-btn"
                                                ref={refAccordionAttachments1}
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse2"
                                                onClick={() =>
                                                    handleAccordionChange(
                                                        currentAccordionsEnum.customer_attachments
                                                    )
                                                }
                                            >
                                                {tabAttachmentsLabel}
                                            </button>
                                        </h2>
                                        <div
                                            ref={refAccordionAttachments2}
                                            id="collapse2"
                                            className="accordion-collapse    collapse"
                                        >
                                            <div className="accordion-body">
                                                {adminState.alreadyClickCustomerAttachments && (
                                                    <TPFixedTable
                                                        dataGrid={adminState.gridCustomerAttachmentsData}
                                                        columns={gridCustomerAttachmentsColumns}
                                                        tableHeight={"200px"}
                                                        keyField={"tpguidrow"}
                                                        highlightOnHover={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                id="change-accordion-fourth-btn"
                                                ref={refAccordionPreferredAgent1}
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse4"
                                                aria-expanded="false"
                                                onClick={() =>
                                                    handleAccordionChange(
                                                        currentAccordionsEnum.preferred_agent
                                                    )
                                                }
                                            >
                                                {agentsLabel}
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse4"
                                            ref={refAccordionPreferredAgent2}
                                            className="accordion-collapse collapse"
                                        >
                                            <div className="accordion-body">
                                                <div className="row mt-1">
                                                    <div className="col d-flex justify-content-end">
                                                        <div className="form-group">
                                                            <TPButton
                                                                id="create-preferred-agent"
                                                                type={TPButtonTypes.icon}
                                                                onClick={handleCreatePreferredAgentClick}
                                                                text={newPreferredAgent}
                                                                icon={TPIconTypes.newEntity}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    {adminState.alreadyClickPreferredAgent && (
                                                        <>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <TPFixedTable
                                                                        dataGrid={adminState.gridPreferredAgentData}
                                                                        columns={gridPreferredAgentColumns}
                                                                        tableHeight={"200px"}
                                                                        keyField={"tpguidrow"}
                                                                        highlightOnHover={true}
                                                                        selectableRows={false}
                                                                        multipleSelection={false}
                                                                        onRowClicked={(row: any, event: any) => {
                                                                            handlePreferredAgentRowClick(row, event);
                                                                        }}
                                                                        styleSelectedRow={{
                                                                            backgroundColor: "#cff4fc",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-1">
                                                                <div className="col-12">
                                                                    <div className="alert alert-info">
                                                                        {preferredAgentNote}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* case column */}
                        <div className="col-9">
                            {/* Organization relation row */}
                            <div className="row">
                                <div className="col-6">
                                    <TPSelect
                                        id="case-client-select"
                                        onChange={handlerOrganizationChange}
                                        dataSource={organizationListKeyValue}
                                        value={adminState.currentOrganization}
                                        labelText={organizationLabel}
                                        isHorizontal={true}
                                        isMandatory={true}
                                        errorMessage={errorMessageOrganization}
                                    />
                                </div>
                                <div className="col-6">
                                    <TPSelect
                                        id="case-service-select"
                                        onChange={handlerRelationChange}
                                        dataSource={relationListKeyValue}
                                        value={adminState.currentRelation}
                                        labelText={relationLabel}
                                        isHorizontal={true}
                                        isMandatory={true}
                                        errorMessage={errorMessageRelation}
                                    />
                                </div>
                            </div>
                            {/* Tabs */}
                            <div className="row mt-3">
                                <div className="col-12 nav" style={{ paddingLeft: "15px" }}>
                                    <div
                                        className="secondary-style-tp-tabs nav nav-tabs"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        <button
                                            className={`nav-link ${adminState.currentTab === currentTabsEnum.comments ? "active" : ""}`}
                                            id="nav-comments-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-comments"
                                            aria-selected="true"
                                            onClick={() => {
                                                handleChangeTab(currentTabsEnum.comments);
                                            }}
                                        >
                                            {tabClassificatorLabel}
                                        </button>
                                        <button
                                            className={`nav-link ${adminState.currentTab === currentTabsEnum.attachments ? "active" : ""}`}
                                            id="nav-adddata-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-adddata"
                                            aria-selected="false"
                                            onClick={() => {
                                                handleChangeTab(currentTabsEnum.attachments);
                                            }}
                                        >
                                            {tabAttachmentsLabel}
                                        </button>
                                        <button
                                            className={`nav-link ${adminState.currentTab === currentTabsEnum.workflow ? "active" : ""}`}
                                            id="nav-workflow-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-workflow"
                                            aria-selected="false"
                                            onClick={() => {
                                                handleChangeTab(currentTabsEnum.workflow);
                                            }}
                                        >
                                            {tabWorkflowLabel}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Tab Content */}
                            <div
                                className="tab-content"
                                id={"nav-tabContent-newCase-" + adminState.caseGuid}
                            >
                                {/* Classification and comments */}
                                <div
                                    className={`tab-pane fade ${adminState.currentTab === currentTabsEnum.comments ? "show active" : ""}`}
                                    id="nav-comments"
                                    role="tabpanel"
                                    aria-labelledby="nav-comments-tab"
                                    style={{
                                        minHeight: "650px",
                                        border: "1px solid #dee2e6",
                                        padding: "10px",
                                    }}
                                >
                                    <div className="row" style={{ overflow: "unset" }}>
                                        {/* classification */}
                                        <div className="col-6">
                                            {/* Classification */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {autocompleteQuickClassifierOptions.length >=
                                                                1 ? (
                                                                <>
                                                                    <TPAutoComplete
                                                                        id="quick-classifier"
                                                                        isMandatory={false}
                                                                        labelText={quickClassfierLabel}
                                                                        onValueChange={handleToChange}
                                                                        onSearch={(query: string) => {
                                                                            handleToOnAutocompleteQuery(
                                                                                query,
                                                                                adminState.currentBaseLevel
                                                                            );
                                                                        }}
                                                                        isLoading={false}
                                                                        options={autocompleteQuickClassifierOptions}
                                                                        withIcon={true}
                                                                        emptyLabel={emptyLabel}
                                                                        onKeyDown={
                                                                            handleQuickClassifierOnAutocompleteKeyDown
                                                                        }
                                                                        selected={
                                                                            selectedAutocompleteQuickClassifierOption
                                                                        }
                                                                        errorMessage={""}
                                                                        downArrowClick={handleAutoCompleteTopNClick}
                                                                    ></TPAutoComplete>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <TPAutoComplete
                                                                        id="quick-classifier"
                                                                        isMandatory={false}
                                                                        labelText={quickClassfierLabel}
                                                                        onValueChange={handleToChange}
                                                                        onSearch={(query: string) => {
                                                                            handleToOnAutocompleteQuery(
                                                                                query,
                                                                                adminState.currentBaseLevel
                                                                            );
                                                                        }}
                                                                        isLoading={false}
                                                                        options={autocompleteQuickClassifierOptions}
                                                                        withIcon={true}
                                                                        emptyLabel={emptyLabel}
                                                                        onKeyDown={
                                                                            handleQuickClassifierOnAutocompleteKeyDown
                                                                        }
                                                                        selected={
                                                                            selectedAutocompleteQuickClassifierOption
                                                                        }
                                                                        errorMessage={""}
                                                                        disabled={disabledQuickClassifiers}
                                                                    ></TPAutoComplete>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Classificator 1 */}
                                                    {adminState.currentRelation === "" &&
                                                        adminState.currentTree1 !==
                                                        TPGlobal.Tree.NA_TreeCode ? (
                                                        <>
                                                            <div className="col-12 mt-2">
                                                                <div className="form-group">
                                                                    <span className="tpcircle-text">1</span>&nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPTextBox
                                                                        id="classifier-text"
                                                                        onChange={() => {
                                                                            TPGlobal.foo();
                                                                        }}
                                                                        value=""
                                                                        disabled={true}
                                                                        labelText={dummyClassfierLabel}
                                                                        isMandatory={true}
                                                                        withCircleText
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {adminState.currentTree1 !==
                                                                TPGlobal.Tree.NA_TreeCode && (
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <span className="tpcircle-text">1</span>
                                                                            &nbsp;
                                                                            {"-"}
                                                                            &nbsp;
                                                                            <TPBranchSelection2
                                                                                ref={(element: any) => {
                                                                                    branch1SelectorRef.current = element;
                                                                                }}
                                                                                labelText={adminState.currentLabelTree1}
                                                                                treeId={adminState.currentTree1}
                                                                                modalAcceptLabel={modalAcceptLabel}
                                                                                modalCancelLabel={modalCancelLabel}
                                                                                modalSelectedBranchLabel={
                                                                                    modalSelectedBranchLabel
                                                                                }
                                                                                modalTitle={modalTitleLabel}
                                                                                mustSelectLastLevelBranch={
                                                                                    adminState.mustSelectLastLevelBranch1
                                                                                }
                                                                                value={{
                                                                                    branchId: adminState.currentBranch1,
                                                                                    branchDescription:
                                                                                        adminState.currentDescriptionBranch1,
                                                                                    branchHierarchyDescription:
                                                                                        defaultBranchHierarchyDescription1,
                                                                                }}
                                                                                onChange={(
                                                                                    branchId: string,
                                                                                    branchDescription: string,
                                                                                    branchHierachyDescription: string
                                                                                ) => {
                                                                                    handleOnBranchChange(
                                                                                        1,
                                                                                        branchId,
                                                                                        branchDescription,
                                                                                        branchHierachyDescription
                                                                                    );
                                                                                }}
                                                                                emptyLabel={emptyLabel}
                                                                                errorMessage={errorClassificatorBranch1}
                                                                                autoCloseTreeModalWhenSelect={true}
                                                                                onIsLoadingProgress={(value: boolean) => {
                                                                                    setIsLoadingScreen(value);
                                                                                }}
                                                                                isMandatory={true}
                                                                            ></TPBranchSelection2>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                    {/* Classificator 2 */}
                                                    {adminState.currentRelation === "" &&
                                                        adminState.currentTree2 !==
                                                        TPGlobal.Tree.NA_TreeCode ? (
                                                        <>
                                                            <div className="col-12 mt-2">
                                                                <div className="form-group">
                                                                    <span className="tpcircle-text">2</span>&nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPTextBox
                                                                        id="two-classifier-text"
                                                                        onChange={() => {
                                                                            TPGlobal.foo();
                                                                        }}
                                                                        value=""
                                                                        disabled={true}
                                                                        labelText={dummyClassfierLabel}
                                                                        isMandatory={true}
                                                                        withCircleText
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {adminState.currentTree2 !==
                                                                TPGlobal.Tree.NA_TreeCode && (
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <span className="tpcircle-text">2</span>
                                                                            &nbsp;
                                                                            {"-"}
                                                                            &nbsp;
                                                                            <TPBranchSelection2
                                                                                ref={(element: any) => {
                                                                                    branch2SelectorRef.current = element;
                                                                                }}
                                                                                labelText={adminState.currentLabelTree2}
                                                                                treeId={adminState.currentTree2}
                                                                                modalAcceptLabel={modalAcceptLabel}
                                                                                modalCancelLabel={modalCancelLabel}
                                                                                modalSelectedBranchLabel={
                                                                                    modalSelectedBranchLabel
                                                                                }
                                                                                modalTitle={modalTitleLabel}
                                                                                mustSelectLastLevelBranch={
                                                                                    adminState.mustSelectLastLevelBranch2
                                                                                }
                                                                                value={{
                                                                                    branchId: adminState.currentBranch2,
                                                                                    branchDescription:
                                                                                        adminState.currentDescriptionBranch2,
                                                                                    branchHierarchyDescription:
                                                                                        defaultBranchHierarchyDescription2,
                                                                                }}
                                                                                onChange={(
                                                                                    branchId: string,
                                                                                    branchDescription: string,
                                                                                    branchHierachyDescription: string
                                                                                ) => {
                                                                                    handleOnBranchChange(
                                                                                        2,
                                                                                        branchId,
                                                                                        branchDescription,
                                                                                        branchHierachyDescription
                                                                                    );
                                                                                }}
                                                                                emptyLabel={emptyLabel}
                                                                                errorMessage={errorClassificatorBranch2}
                                                                                autoCloseTreeModalWhenSelect={true}
                                                                                onIsLoadingProgress={(value: boolean) => {
                                                                                    setIsLoadingScreen(value);
                                                                                }}
                                                                                isMandatory={true}
                                                                            ></TPBranchSelection2>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                    {/* Classificator 3 */}
                                                    {adminState.currentRelation === "" &&
                                                        adminState.currentTree3 !==
                                                        TPGlobal.Tree.NA_TreeCode ? (
                                                        <>
                                                            <div className="col-12 mt-2">
                                                                <div className="form-group">
                                                                    <span className="tpcircle-text">3</span>&nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPTextBox
                                                                        id="tree-classifier-text"
                                                                        onChange={() => {
                                                                            TPGlobal.foo();
                                                                        }}
                                                                        value=""
                                                                        disabled={true}
                                                                        labelText={dummyClassfierLabel}
                                                                        isMandatory={true}
                                                                        withCircleText
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {adminState.currentTree3 !==
                                                                TPGlobal.Tree.NA_TreeCode && (
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <span className="tpcircle-text">3</span>
                                                                            &nbsp;
                                                                            {"-"}
                                                                            &nbsp;
                                                                            <TPBranchSelection2
                                                                                ref={(element: any) => {
                                                                                    branch3SelectorRef.current = element;
                                                                                }}
                                                                                labelText={adminState.currentLabelTree3}
                                                                                treeId={adminState.currentTree3}
                                                                                modalAcceptLabel={modalAcceptLabel}
                                                                                modalCancelLabel={modalCancelLabel}
                                                                                modalSelectedBranchLabel={
                                                                                    modalSelectedBranchLabel
                                                                                }
                                                                                modalTitle={modalTitleLabel}
                                                                                mustSelectLastLevelBranch={
                                                                                    adminState.mustSelectLastLevelBranch3
                                                                                }
                                                                                value={{
                                                                                    branchId: adminState.currentBranch3,
                                                                                    branchDescription:
                                                                                        adminState.currentDescriptionBranch3,
                                                                                    branchHierarchyDescription:
                                                                                        defaultBranchHierarchyDescription3,
                                                                                }}
                                                                                onChange={(
                                                                                    branchId: string,
                                                                                    branchDescription: string,
                                                                                    branchHierachyDescription: string
                                                                                ) => {
                                                                                    handleOnBranchChange(
                                                                                        3,
                                                                                        branchId,
                                                                                        branchDescription,
                                                                                        branchHierachyDescription
                                                                                    );
                                                                                }}
                                                                                emptyLabel={emptyLabel}
                                                                                errorMessage={errorClassificatorBranch3}
                                                                                autoCloseTreeModalWhenSelect={true}
                                                                                onIsLoadingProgress={(value: boolean) => {
                                                                                    setIsLoadingScreen(value);
                                                                                }}
                                                                                isMandatory={true}
                                                                            ></TPBranchSelection2>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                    {/* Classificator 4 */}
                                                    {adminState.currentRelation === "" &&
                                                        adminState.currentTree4 !==
                                                        TPGlobal.Tree.NA_TreeCode ? (
                                                        <>
                                                            <div className="col-12 mt-2">
                                                                <div className="form-group">
                                                                    <span className="tpcircle-text">4</span>&nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPTextBox
                                                                        id="four-classifier-text"
                                                                        onChange={() => {
                                                                            TPGlobal.foo();
                                                                        }}
                                                                        value=""
                                                                        disabled={true}
                                                                        labelText={dummyClassfierLabel}
                                                                        isMandatory={true}
                                                                        withCircleText
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {adminState.currentTree4 !==
                                                                TPGlobal.Tree.NA_TreeCode && (
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <span className="tpcircle-text">4</span>
                                                                            &nbsp;
                                                                            {"-"}
                                                                            &nbsp;
                                                                            <TPBranchSelection2
                                                                                ref={(element: any) => {
                                                                                    branch4SelectorRef.current = element;
                                                                                }}
                                                                                labelText={adminState.currentLabelTree4}
                                                                                treeId={adminState.currentTree4}
                                                                                modalAcceptLabel={modalAcceptLabel}
                                                                                modalCancelLabel={modalCancelLabel}
                                                                                modalSelectedBranchLabel={
                                                                                    modalSelectedBranchLabel
                                                                                }
                                                                                modalTitle={modalTitleLabel}
                                                                                mustSelectLastLevelBranch={
                                                                                    adminState.mustSelectLastLevelBranch4
                                                                                }
                                                                                value={{
                                                                                    branchId: adminState.currentBranch4,
                                                                                    branchDescription:
                                                                                        adminState.currentDescriptionBranch4,
                                                                                    branchHierarchyDescription:
                                                                                        defaultBranchHierarchyDescription4,
                                                                                }}
                                                                                onChange={(
                                                                                    branchId: string,
                                                                                    branchDescription: string,
                                                                                    branchHierachyDescription: string
                                                                                ) => {
                                                                                    handleOnBranchChange(
                                                                                        4,
                                                                                        branchId,
                                                                                        branchDescription,
                                                                                        branchHierachyDescription
                                                                                    );
                                                                                }}
                                                                                emptyLabel={emptyLabel}
                                                                                errorMessage={errorClassificatorBranch4}
                                                                                autoCloseTreeModalWhenSelect={true}
                                                                                onIsLoadingProgress={(value: boolean) => {
                                                                                    setIsLoadingScreen(value);
                                                                                }}
                                                                                isMandatory={true}
                                                                            ></TPBranchSelection2>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                    {/* Classificator 5 */}
                                                    {adminState.currentRelation === "" &&
                                                        adminState.currentTree5 !==
                                                        TPGlobal.Tree.NA_TreeCode ? (
                                                        <>
                                                            <div className="col-12 mt-2">
                                                                <div className="form-group">
                                                                    <span className="tpcircle-text">5</span>&nbsp;
                                                                    {"-"}
                                                                    &nbsp;
                                                                    <TPTextBox
                                                                        id="five-classifier-text"
                                                                        onChange={() => {
                                                                            TPGlobal.foo();
                                                                        }}
                                                                        value=""
                                                                        disabled={true}
                                                                        labelText={dummyClassfierLabel}
                                                                        isMandatory={true}
                                                                        withCircleText
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {adminState.currentTree5 !==
                                                                TPGlobal.Tree.NA_TreeCode && (
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <span className="tpcircle-text">5</span>
                                                                            &nbsp;
                                                                            {"-"}
                                                                            &nbsp;
                                                                            <TPBranchSelection2
                                                                                ref={(element: any) => {
                                                                                    branch5SelectorRef.current = element;
                                                                                }}
                                                                                labelText={adminState.currentLabelTree5}
                                                                                treeId={adminState.currentTree5}
                                                                                modalAcceptLabel={modalAcceptLabel}
                                                                                modalCancelLabel={modalCancelLabel}
                                                                                modalSelectedBranchLabel={
                                                                                    modalSelectedBranchLabel
                                                                                }
                                                                                modalTitle={modalTitleLabel}
                                                                                mustSelectLastLevelBranch={
                                                                                    adminState.mustSelectLastLevelBranch5
                                                                                }
                                                                                value={{
                                                                                    branchId: adminState.currentBranch5,
                                                                                    branchDescription:
                                                                                        adminState.currentDescriptionBranch5,
                                                                                    branchHierarchyDescription:
                                                                                        defaultBranchHierarchyDescription5,
                                                                                }}
                                                                                onChange={(
                                                                                    branchId: string,
                                                                                    branchDescription: string,
                                                                                    branchHierachyDescription: string
                                                                                ) => {
                                                                                    handleOnBranchChange(
                                                                                        5,
                                                                                        branchId,
                                                                                        branchDescription,
                                                                                        branchHierachyDescription
                                                                                    );
                                                                                }}
                                                                                emptyLabel={emptyLabel}
                                                                                errorMessage={errorClassificatorBranch5}
                                                                                autoCloseTreeModalWhenSelect={true}
                                                                                onIsLoadingProgress={(value: boolean) => {
                                                                                    setIsLoadingScreen(value);
                                                                                }}
                                                                                isMandatory={true}
                                                                            ></TPBranchSelection2>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {/* Comments */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-1">
                                                        <TPLabel
                                                            labelText={commentsLabel}
                                                            isMandatory={true}
                                                        />
                                                    </div>
                                                    <TPEditor
                                                        id="new-case"
                                                        referece={(editor: any) =>
                                                            (editorRef.current = editor)
                                                        }
                                                        placeholder=""
                                                        value={editorValue}
                                                        onChange={handleEditorChange}
                                                        initialValue=""
                                                        isVisible={false}
                                                    />
                                                    <span style={{ color: "#dc3545", fontSize: "14px" }}>
                                                        {editorErrorMessage}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {hasAnyVisibleAdditionalData() && (
                                        <>
                                            <div className="row mt-4">
                                                <div className="col-12">
                                                    {hasAnyVisibleAdditionalData() && (
                                                        <h2 className="tpbold tpfont16">
                                                            {additionalDataLabel}
                                                        </h2>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row new-case__additional_data_container">
                                                {adminState.additionalDataValues.map(function (item) {
                                                    let elementJsx;
                                                    let index: number;
                                                    let value: any;
                                                    if (item.value3 === true) {
                                                        index = adminState.additionalDataValues.findIndex(
                                                            (x: TPKeyValue) => x.key == item.key
                                                        );
                                                        value =
                                                            adminState.additionalDataValues[index].value;
                                                        elementJsx = (
                                                            <div
                                                                className="col-4 mb-2"
                                                                key={"adda" + adminState.caseGuid + item.key}
                                                            >
                                                            123
                                                                <TPRenderAdditionalDataSwitch
                                                                    guidControl={adminState.caseGuid}
                                                                    modeUI={TPAddtionalDataUIModes.Collect}
                                                                    defaultValue={value}
                                                                    itemToRender={item}
                                                                    ref={(element: any) => {
                                                                        childRefArray.current[item.key] = element;
                                                                    }}
                                                                    onChange={(
                                                                        idControl: any,
                                                                        newValue: string,
                                                                        addtionalDataId: string
                                                                    ) =>
                                                                        handleChangeAditionalDataValue(
                                                                            idControl,
                                                                            newValue,
                                                                            item.key
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        elementJsx = null;
                                                    }
                                                    return elementJsx;
                                                })}
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* Attachments */}
                                <div
                                    className={`tab-pane fade ${adminState.currentTab === currentTabsEnum.attachments
                                            ? "show active"
                                            : ""
                                        }`}
                                    id="nav-adddata"
                                    role="tabpanel"
                                    aria-labelledby="nav-adddata-tab"
                                    style={{
                                        minHeight: "650px",
                                        border: "1px solid #dee2e6",
                                        padding: "10px",
                                    }}
                                >
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <FileUploader
                                                        id="case"
                                                        label={dropFilesHereLabel}
                                                        multiple={true}
                                                        handleChange={handleUploadFilesDraggedChange}
                                                        UploadButtonText={uploadFileButtonLabel}
                                                    />
                                                    {adminState.temporaryAttachmentList.length >= 1 && (
                                                        <div className="mt-2">
                                                            <h5 className="tpbold tp-primary-color">
                                                                {tabAttachmentsLabel}
                                                            </h5>
                                                            <hr />
                                                            <ul className="new_case__attachments-container">
                                                                {adminState.temporaryAttachmentList.length >=
                                                                    1 &&
                                                                    adminState.temporaryAttachmentList.map(
                                                                        function (item, index) {
                                                                            return (
                                                                                <li
                                                                                    className="d-flex mt-1"
                                                                                    key={
                                                                                        "litempoaryfile" + index.toString()
                                                                                    }
                                                                                >
                                                                                    <span>{item.fileName}</span>
                                                                                    <span className="new_case__attachments-btn-delete">
                                                                                        <TPIcon
                                                                                            className="tphover"
                                                                                            key={
                                                                                                "deletetempoaryfile" +
                                                                                                index.toString()
                                                                                            }
                                                                                            style={{
                                                                                                fontSize: "25px",
                                                                                            }}
                                                                                            iconType={TPIconTypes.delete}
                                                                                            onClick={async () =>
                                                                                                handlerOnDeleteTemporaryAttachment(
                                                                                                    item.fileId
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* workflow */}
                                <div
                                    className={`tab-pane fade ${adminState.currentTab === currentTabsEnum.workflow ? "show active" : ""}`}
                                    id="nav-workflow"
                                    role="tabpanel"
                                    aria-labelledby="nav-workflow-tab"
                                    style={{
                                        minHeight: "650px",
                                        border: "1px solid #dee2e6",
                                        padding: "10px",
                                    }}
                                >
                                    {adminState.currentWorkflowImage && (
                                        <div>
                                            <TPIcon
                                                iconType={TPIconTypes.fullScreen}
                                                onClick={() => {
                                                    let newState: DiagramFullScreenModalType = {
                                                        classModalDiagram: "modal show",
                                                        styleModalDiagram: { display: "block" },
                                                        backdropClass: "modal-backdrop show",
                                                        title: `${workflowTypeLabel}: ${adminState.currentWorkflowDescription}`,
                                                    };
                                                    setDiagramModal(newState);
                                                }}
                                                style={{ fontSize: "20px" }}
                                            />
                                            <div className="row">
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    {/* <TransformWrapper>
                        <TransformComponent contentClass="tpdiagram"> */}
                                                    <img
                                                        src={
                                                            "data:image/png;base64," +
                                                            adminState.currentWorkflowImage
                                                        }
                                                    />
                                                    {/* </TransformComponent>
                      </TransformWrapper> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 d-flex justify-content-between align-items-center gap-4 ">
                                    <div className="new-case__footer_buttons-actions">
                                        <TPCheckBox
                                            id="is-direct-soluction-check"
                                            checked={isDirectSolution}
                                            labelText={isDirectSolutionLabel}
                                            onChange={() => {
                                                setIsDirectSolution(!isDirectSolution);
                                            }}
                                            labelStyle={{ color: "red", fontWeight: "600" }}
                                        />
                                        {adminState.currentWorkflowType != "" &&
                                            !isDirectSolution ? (
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="tpbold" style={{ fontSize: "14px" }}>
                                                    {workflowTypeLabel}:
                                                </span>

                                                <a
                                                    id="case-change-tab"
                                                    style={{
                                                        fontSize: "14px",
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e: any) => {
                                                        e.preventDefault();
                                                        handleChangeTab(currentTabsEnum.workflow);
                                                    }}
                                                >
                                                    {adminState.currentWorkflowDescription}
                                                </a>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="new-case__footer_buttons-buttons">
                                        <TPButton
                                            id="save-case"
                                            onClick={handlerSaveOnClick}
                                            type={TPButtonTypes.primary}
                                            children={<span>{saveCaseButtonLabel}</span>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TPLoadingOverlay>
            </div>
            <div
                className={diagramModal.classModalDiagram}
                tabIndex={-1}
                data-bs-backdrop={diagramModal.backdropClass}
                style={diagramModal.styleModalDiagram}
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between">
                            <h5 className="modal-title">{diagramModal.title}</h5>
                            <button
                                id="close-md-btn"
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setDiagramModal(initialDiagramModal);
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4">
                                    {adminState.currentWorkflowImage && (
                                        <div className="tpw100per">
                                            {/* <TransformWrapper>
                    <TransformComponent contentClass="tpdiagram"> */}
                                            <img
                                                src={
                                                    "data:image/png;base64," +
                                                    adminState.currentWorkflowImage
                                                }
                                                alt=""
                                            />
                                            {/* </TransformComponent>
                  </TransformWrapper> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            TPClient Copyright &copy; {TPGlobal.currentYear}
                        </div>
                    </div>
                </div>
            </div>
            {modalErrorState.isShown && <TPModalError modalState={modalErrorState} />}
            {modalSuccessState.isShown && (
                <TPModalSuccess modalState={modalSuccessState} />
            )}
            <TPModalSearchClient
                title={searchClientModalTitle}
                acceptLabel={okLabel}
                cancelLabel={cancelLabel}
                callBackAnswer={callBackModalCustomerSearch}
                initialState={modalSearchCustomerState}
            />
        </ContentVerticalNoTabsStyled>
    );
};

export default NewCase;
