import * as yup from "yup";

/**
 * outbound mail account clone enum
 */
export enum OutboundMailAccountCloneEnum {
  /**
   * key word
   */
  OutboundMailAccountClone = "OutboundMailAccountClone",
  /**
   * file name component
   */
  OutboundMailAccountCloneFilename = "OutboundMailAccountClone.tsx",
  /**
   * component name
   */
  OutboundMailAccountCloneComponent = "OutboundMailAccountCloneComponent",

  /**
   * COMPONENT LABELS
   */
  /**
   * title label
   */
  TitleLabel = "TitleLabel",
  /**
   * description label
   */
  DescriptionLabel = "DescriptionLabel",
  /**
   * form input source identifier label
   */
  FormInputSourceIdLabel = "FormInputSourceIdLabel",
  /**
   * form input identifier label
   */
  FormInputIdLabel = "FormInputIdLabel",
  /**
   * form input name label
   */
  FormInputNameLabel = "FormInputNameLabel",
  /**
   * section actions button cancel label
   */
  SectionActionsButtonCancelLabel = "Cancel",
  /**
   * section actions button save label
   */
  SectionActionsButtonSaveLabel = "Save",
  /**
   * message source identifier label
   */
  MessageSourceIdLabel = "MessageSourceIdLabel",
  /**
   * message identifier label
   */
  MessageIdLabel = "MessageIdLabel",
  /**
   * message name label
   */
  MessageNameLabel = "MessageNameLabel",

  /**
   * COMPONENT LABELS END
   */
}

/**
 * language model
 */
export interface LanguageModel {
  /**
   * language identifier
   */
  languageId: string;
  /**
   * value
   */
  localizedValue: string;
  /**
   * order
   */
  order: number;
}

/**
 * outbound mail account clone model
 */
export interface OutboundMailAccountCloneModel {
  /**
   * source identifier
   */
  baseAccountId: string;
  /**
   * identifier
   */
  newAccountId: string;
  /**
   * name
   */
  description: string | null;
}

/**
 * outbound mail account clone control
 */
export const OutboundMailAccountCloneControl = {
  initialValues: {
    baseAccountId: "",
    newAccountId: "",
    description: "",
  } as OutboundMailAccountCloneModel,
  validationSchema: yup.object({
    baseAccountId: yup.string().required(OutboundMailAccountCloneEnum.MessageSourceIdLabel),
    newAccountId: yup.string().required(OutboundMailAccountCloneEnum.MessageIdLabel),
    description: yup
      .string()
      .required(OutboundMailAccountCloneEnum.MessageNameLabel),
  }),
};
