import { useEffect, useState } from "react";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import { TEmpAdditionalDataTaskViewModel } from "../../models/Task/TaskReportInputDTO";
import FormView from "../FormDesigner/FormView/FormView";


interface TaskFormViewInterface {
    formId: string;
    modeEdit: string;
    visible: boolean;
    additionalDataTask: Array<TPKeyValue>;
    inputTryingToSave: boolean;
    onChangedDatForm?: (list: Array<TPKeyValue>) => void;
    onChangedReadyForm: Function;
}

const componentFileName: string = "TaskFormView.tsx";
const resourceSet: string = "TaskFormViewComponent";


const TaskFormView = ({
    formId,
    modeEdit,
    onChangedDatForm,
    onChangedReadyForm,
    additionalDataTask,
    inputTryingToSave, 
    visible}: TaskFormViewInterface) => {

    const [inputValueDefault, setInputValueDefault] = useState<Array<TPKeyValue>>();



    const handleConverToAdditionalData = (data: string) => {       
        let dataForm = JSON.parse(data) as Array<TPKeyValue>;
            if (onChangedDatForm) onChangedDatForm(dataForm);
    };

   
    return (
        < div style={{ marginTop:"10px" }}>
            {visible &&
                <FormView
                    onChangedJson={(e: any) => { handleConverToAdditionalData(e); }}
                    onChangedReadyForm={onChangedReadyForm}
                    inputFormId={formId}
                    inputValueDefault={additionalDataTask}
                    inputTryingToSave={inputTryingToSave}
                    disable={!(modeEdit.toUpperCase() === "EDIT")}
                />
            }
        </div>
    );

};

export default TaskFormView;
