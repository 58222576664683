import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useState } from "react";
import { ContainerTabStyle, TabActiveStyle } from "../FormDesigner/StyleFromDesigner";
import StructureAdminClone from "./StructureAdminClone";
import StructureAdminUpdateInsert from "./StructureAdminUpdateInsert";
import StructureAdminUpdateInsertDetails from "./StructureAdminUpdateInsertDetails";
import { Box, Tab, Tabs } from "@mui/material";
import { FaPage4 } from "react-icons/fa";
import { StyledTab, StyledTabs } from "../LoadHistory/load-history-styles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    description: string;
    verticalTabCallback: Function;
    updateCallback: Function;
    tabid?: string;
};

enum pageEnum {
    general,
    details
}

const StructureAdminUpdateTab: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    description,
    verticalTabCallback,
    updateCallback,
    tabid
}) => {


    const componentFileName: string = "StructureAdminUpdateTab.tsx";
    const resourceSet: string = "StructureAdminUpdateTab";

    const [page, setPage] = useState(pageEnum.general);

    const [detailsLabel, setDetailsLabel] = useState('');
    const [generalLabel, setGeneralLabel] = useState('');

    const [titleLabel, setTitleLabel] = useState("");

    const [newItemLabel, setNewItemLabel] = useState<string>("");

    const [detailsZIndex, setDetailsZIndex] = useState(0);

    const loadResourcesAndLoadInfo = async () => {
        setDetailsLabel(await TPI18N.GetText(resourceSet, "DetailsTitle"));
        setGeneralLabel(await TPI18N.GetText(resourceSet, "GeneralInformationTitle"));
        setNewItemLabel(await TPI18N.GetText("StructureAdminUpdateInsertDetails", "NewItemButton"));

        //ResourcesAndLoadInfo
        if (mode === 'CloneEmail') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "CloneTitle"));
        } else if (mode === 'Update') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "UpdateTitle"));
        } else if (mode === 'Insert') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "InsertTitle"));
        }
    }

    const indexCallback = (index: number) => {
        setDetailsZIndex(index);
    }

    const [openNewItem, setOpenNewItem] = useState(false);

    const callbackModal = (status: boolean) => {
        setOpenNewItem(false);
    }

    useEffect(() => {
        loadResourcesAndLoadInfo();
    }, []);

    return (
        <>
        <TPLoadingOverlay active={false}>
            <div className="row">
                <div className="col">
                    <CIMTitleSection>
                        <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                        {page == pageEnum.details && (
                            <TPButton
                                id="new-tree"
                                isDesignSystem={true}
                                onClick={() => setOpenNewItem(true)}
                                withIcon={TPIconTypes.add}
                                orientationIcon="left"
                                style={{ padding: "1px 18px", margin: "0" }}
                            >
                                {newItemLabel}
                            </TPButton>
                        )}
                    </CIMTitleSection>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                        value={page}
                        onChange={(_e, value) => setPage(value)}
                        aria-label="structure admin tabs"
                        sx={StyledTabs}
                        >
                        <Tab
                            label={generalLabel}
                            id="general-tab"
                            sx={StyledTab}
                            disableRipple
                        />
                        {mode == "Update" && (
                            <Tab
                                label={detailsLabel}
                                id="details-tab"
                                sx={StyledTab}
                                disableRipple
                            />
                        )}
                        </Tabs>
                    </Box>
                </div>
            </div>
        </TPLoadingOverlay>
            
            <br />
            {page == pageEnum.general && (
                <>
                    {mode === 'CloneEmail' ? (
                        <>
                            <div style={{ position: "absolute", top: "180px", left: "20px", zIndex: 0, width: "95%" }}>
                                <StructureAdminClone
                                    mode={mode}
                                    recordInput={recordId}
                                    recordDescription={description}
                                    verticalTabCallback={verticalTabCallback}
                                    updateCallback={updateCallback}
                                />
                            </div>
                            
                        </>

                    ) : (
                        <div style={{ position: "absolute", top: "180px", left: "20px", zIndex: 2, width: "95%", height: "110%" }}>
                            <StructureAdminUpdateInsert
                                mode={mode}
                                recordId={recordId}
                                verticalTabCallback={verticalTabCallback}
                                updateCallback={updateCallback}
                                tabid={tabid}
                            />
                        </div>
                    )}
                </>
            )}
            {page == pageEnum.details && (
                <>
                    {mode === 'Update' && (
                        <>
                            <StructureAdminUpdateInsertDetails
                                    mode={mode}
                                    recordInput={recordId}
                                    recordDescription=""
                                    callBackComands={indexCallback}
                                    openModal={openNewItem}
                                    callbackModal={callbackModal}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default StructureAdminUpdateTab;