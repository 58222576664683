import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
    ProjectNewEnum as e,
    LanguageModel,
    ProjectNewModel,
    ProjectType,
    SetModel
} from "@/models/Project/Projects";
import ProjectNewStep1 from "@/pages/Projects/ProjectNew/ProjectNewStep1";
import ProjectNewStep2Back from "@/pages/Projects/ProjectNew/ProjectNewStep2Back";
import ProjectNewStep2Front from "@/pages/Projects/ProjectNew/ProjectNewStep2Front";
import { EventProjectService } from "@/services/EventProjectService";
import { Box, Step, StepButton, Stepper } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { Fragment, useEffect, useState } from "react";
import { CSSProperties } from "styled-components";

const defaulGroup = {key: '', value: '---'} as TPKeyValue;

interface ProjectNewStepperProps {
    tabId: string;
    dispatch: Function;
    m: { [attribute: string]: any };
    isUpdate: boolean | null;
    setIsUpdate: Function;
    setLoading: Function;
    project: ProjectNewModel;
    setProject?: Function;
}

export default function ProjectNewStepper({
                                              tabId,
                                              dispatch,
                                              m,
                                              isUpdate,
                                              setIsUpdate,
                                              setLoading,
                                              project,
                                              setProject,
                                          }: ProjectNewStepperProps) {
    const steps = [m?.[e.Step1TitleLabel], ""];
    const [activeStep, setActiveStep] = useState(0);
    const [type, setType] = useState<ProjectType>();

    useEffect(() => {
        setIsUpdate(!!project.projectId)
    }, []);

    function handleStep(step: number) {
        setActiveStep(step);
    }

    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function isValid(type: 'step1' | 'step2') {
        switch (type) {
            case 'step1':
                if (project?.name && project?.recordType) {
                    switch (project?.recordType) {
                        case ProjectType.FRONTOFFICE:
                            return true
                        case ProjectType.BACKOFFICE:
                            return !!project?.structureTypeId;
                    }
                } else {
                    return false
                }
                break
            case 'step2':
                if (ProjectType.FRONTOFFICE == project.recordType) {
                    return (project.conditionsOrQueues ?? []).length > 0
                } else {
                    return !project.conditionsOrQueues
                        ?.map(cq => {
                            const conditionsIsValid = !cq?.conditions
                                ?.map((c, index) => {
                                    const conditionTypeIsValid = (index !== cq?.conditions!.length - 1) ? !!c?.conditionType : !c?.conditionType

                                    return !!c?.eventLoadStructureDetailId && !!c?.field && !!c?.operation && !!c?.value && conditionTypeIsValid
                                })
                                .includes(false);

                            return !!cq?.name && !!cq?.groupId && !!cq?.priority && conditionsIsValid
                        })
                        .includes(false)
                }
                break
            default:
                return false
        }
    }

    function onChangeStep1Handler(p: ProjectNewModel) {
        if (setProject) {
            //console.log({...project, ...p})
            setProject({...project, ...p})
            if (type !== p.recordType) {
                setType(p.recordType)
            }
        }
    }

    function onChangeStep2Handler(conditionsOrQueues: Array<SetModel>) {
        if (setProject) {
            //console.log({...project, conditionsOrQueues})
            setProject({...project, conditionsOrQueues})
        }
    }

    function onCancel() {
        dispatch({type: event.vertical_tab_close, payload: tabId})
        dispatch({type: event.reloadGrid})
    }

    function onClickSaveHandler() {
        let request: any = {
            projectId: project.projectId,
            name: project.name,
            nameLanguages: [
                {
                    languageId: TPGlobal.language,
                    localizedValue: project.name,
                    order: 0
                } as LanguageModel,
                ...project.nameLanguages.map((l: LanguageModel, index) => ({...l, order: index + 1}))
            ],
            recordType: project.recordType,
            conditionsOrQueues: project.conditionsOrQueues?.map(set => ({
                ...set,
                eventProjectId: project.projectId
            })),
            isActive: project.isActive,
            updateOnlyProjectInformation: project.projectInUse,
        } as ProjectNewModel

        if (project?.structureTypeId)
            request.structureTypeId = project.structureTypeId

        if (project?.defaultQueueId)
            request.defaultQueueId = project.defaultQueueId

        if (project?.supervisorId)
            request.supervisorId = project.supervisorId

        const {save, update} = new EventProjectService()
        setLoading(true)
        if (isUpdate) {
            update(request)
                .then(response => {
                    setLoading(false)
                    dispatch({type: event.vertical_tab_close, payload: tabId})
                    dispatch({type: event.reloadGrid})
                    dispatch({type: event.detail, payload: response})
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                })
        } else {
            save(request)
                .then(response => {
                    setLoading(false)
                    dispatch({type: event.vertical_tab_close, payload: tabId})
                    dispatch({type: event.reloadGrid})
                    dispatch({type: event.detail, payload: response})
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                })
        }
    }

    return (
        <Box sx={styles.container}>
            <Stepper activeStep={activeStep} color="primary" style={styles.stepper}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={`step-${index}`} {...stepProps}>
                            <StepButton className="customStep"
                                        onClick={(_) => {
                                            handleStep(index)
                                        }}>
                                {index === 0 ? label : m?.[e.Step2FrontTitleLabel]}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <Fragment>
                <br/>
                {activeStep === 0 && isUpdate !== null ? (
                    <ProjectNewStep1
                        m={m}
                        project={project}
                        isUpdate={isUpdate}
                        onChange={onChangeStep1Handler}
                    />
                ) : (
                    type === ProjectType.FRONTOFFICE ?
                        <ProjectNewStep2Front m={m}
                                              project={project}
                                              onChange={onChangeStep2Handler}/> :
                        type === ProjectType.BACKOFFICE ?
                            <ProjectNewStep2Back
                                m={m}
                                project={project}
                                onChange={onChangeStep2Handler}
                                onChangeProject={onChangeStep1Handler}
                            /> :
                            <></>
                )}

                <Box sx={styles.actions}>
                    <TPButton id={`projectDetailButtonCancel`}
                              key={`projectDetailButtonCancel`}
                              style={styles.cancelButton}
                              type={TPButtonTypes.primary}
                              onClick={onCancel}>
                        {m?.[e.FormActionCancelLabel]}
                    </TPButton>

                    {activeStep !== steps.length - 1 && (
                        <TPButton
                            type={TPButtonTypes.primary}
                            disabled={!isValid('step1')}
                            onClick={handleNext}
                        >
                            {m?.[e.FormActionContinueLabel]}
                        </TPButton>
                    )}

                    {activeStep === steps.length - 1 && (
                        <TPButton
                            type={TPButtonTypes.primary}
                            disabled={!isValid('step2')}
                            onClick={onClickSaveHandler}
                        >
                            {m?.[isUpdate ? e.FormActionEditProjectLabel : e.FormActionNewProjectLabel]}
                        </TPButton>
                    )}
                </Box>
            </Fragment>
        </Box>
    );
}

const styles = {
    container: {
        width: '100%'
    } as SxProps,
    stepper: {
        width: '50%',
    } as CSSProperties,
    actions: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: "border-box",
        placeContent: "flex-end",
        alignItems: "flex-end",
        gap: "10px",
        marginBottom: "10px",
    } as SxProps,
    cancelButton: {
        backgroundColor: "white",
        color: "#a00095",
        border: "1px solid #a00095",
    } as CSSProperties,
};
