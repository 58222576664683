import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreModel, TypeOf } from "@/redux/store";
import {
  TabsCaseViewerSlice,
  TabsCaseViewerStateModel,
} from "./_redux/TabsCaseViewerSlice";
import VerticalTabCaseTimeListener from "@/layouts/VerticalTabs/VerticalTabCaseTimeListener";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import {
  HorizontalTabsSlice,
  HorizontalTabsStateModel,
} from "../Main/_redux/HorizonatalTabsSlice";

function valid(value: any) {
  return value !== null && value !== undefined;
}

const HORIZONTAL_TAB_REQUIRED =
  "TPClientCloudMain.Client.Components.Cases.CaseViewer";

interface VerticalTabCaseProps {
  caseNumber: string;
}

export default function VerticalTabCase({ caseNumber }: VerticalTabCaseProps) {
  const dispatch = useDispatch();

  const { focus: horizontalTabFocus } = useSelector(
    (s: StoreModel) => s[HorizontalTabsSlice.name],
  ) as HorizontalTabsStateModel;
  const { seconds, focus, result } = useSelector(
    (s: StoreModel) => s[TabsCaseViewerSlice.name],
  ) as TabsCaseViewerStateModel;
  const caseViewerContext: any = React.useContext(TPCaseViewerContext);

  const [chronometerInterval, setChronometerInterval] = useState<any>();

  // useEffect(() => {
  //   if (
  //     !chronometerInterval &&
  //     valid(seconds[caseNumber]) &&
  //     HORIZONTAL_TAB_REQUIRED === horizontalTabFocus &&
  //     focus === caseNumber
  //   )
  //     setChronometerInterval(
  //       setInterval(() => {
  //         //dispatch(TabsCaseViewerSlice.actions.increase(caseNumber));
  //       }, 1000)
  //     );
  // }, []);

  useEffect(
    () => () => {
      clearInterval(chronometerInterval);
      setChronometerInterval(null);
    },
    [],
  );

  useEffect(() => {
    if (TypeOf(result, TabsCaseViewerSlice.actions.remove)) {
      clearInterval(chronometerInterval);
      setChronometerInterval(null);
      dispatch(TabsCaseViewerSlice.actions.close(caseNumber));
      caseViewerContext.handleRemoveNewCaseViewerCallBack(caseNumber);
    }
  }, [result]);

  useEffect(() => {
    if (
      chronometerInterval &&
      (HORIZONTAL_TAB_REQUIRED !== horizontalTabFocus || focus !== caseNumber)
    ) {
      clearInterval(chronometerInterval);
      setChronometerInterval(null);
    }

    if (
      !chronometerInterval &&
      valid(seconds[caseNumber]) &&
      HORIZONTAL_TAB_REQUIRED === horizontalTabFocus &&
      focus === caseNumber
    ) {
      setChronometerInterval(
        setInterval(() => {
          dispatch(TabsCaseViewerSlice.actions.increase(caseNumber));
        }, 1000),
      );
    }
  }, [seconds, horizontalTabFocus, focus]);

  function onClickSelectHandler() {
    dispatch(TabsCaseViewerSlice.actions.select(caseNumber));
    caseViewerContext.handleChangeCurrentCaseViewerCallBack(caseNumber);
  }

  function onClickCloseHandler(event: any) {
    event.stopPropagation();
    clearInterval(chronometerInterval);
    setChronometerInterval(null);
    dispatch(TabsCaseViewerSlice.actions.close(caseNumber));
    caseViewerContext.handleRemoveNewCaseViewerCallBack(caseNumber);
    if (caseViewerContext.currentCaseResolveCallback)
      caseViewerContext.currentCaseResolveCallback();
  }

  return (
    <li
      key={"verticalTablink" + caseNumber}
      className={`${focus === caseNumber ? " active" : ""} ${
        "" //state?.recordData?.isHome ? " home " : ""
      }`}
      onClick={onClickSelectHandler}
    >
      <div
        className="col"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div style={{ fontSize: "14px" }}>{caseNumber}</div>
          {seconds[caseNumber] && (
            <VerticalTabCaseTimeListener caseNumber={caseNumber} />
          )}
        </div>
      </div>
      <div
        className="tp-vertical-tab-close-button"
        onClick={onClickCloseHandler}
      >
        X
      </div>
    </li>
  );
}
