import TPButton from "@/components/bootstrap/components/buttons/TPButton"
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay"
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox"
import TPSelect from "@/components/bootstrap/forms/select/TPSelect"
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox"
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel"
import TPGlobal from "@/helpers/TPGlobal"
import { TPLog, TPLogType } from "@/helpers/TPLog"
import { SequenceGeneratorSequencesNameEnum, TPButtonTypes } from "@/models/Global/TPGlobalEnums"
import { GroupsViewModel } from "@/models/Groups/GroupsModels"
import { GroupsService } from "@/services/GroupsService"
import { SequenceService } from "@/services/SequenceService"
import { FC, useEffect, useState } from "react"
import { useQueuesAdminSelectOptions } from "../../assets/controller"
import useQueueAdminLabels from "../../assets/labels"
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast"

const componentFileName = "QueueGeneralInfoTab.tsx";

interface QueueGeneralInfoProperties {
  queueData?: any;
  panelMode: 'new' | 'update';
  tabCallback: Function;
  groupId: number;
  active: boolean;
  updateCallback: Function;
}

const QueueGeneralInfo: FC<QueueGeneralInfoProperties> = function ({ panelMode, tabCallback, updateCallback, groupId, active }) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [idInput, setIdInput] = useState<string>("");
  const [nameInput, setNameInput] = useState<string>("");
  const [scheduleInput, setScheduleInput] = useState<string>("");
  const [timezoneInput, setTimezoneInput] = useState<string>("");
  const [calendarInput, setCalendarInput] = useState<string>("");
  const [superiorInput, setSuperiorInput] = useState<string>("");
  const [preferredCustomerSelected, setPreferredCustomerSelected] = useState<boolean>(false);
  const [relatedCaseSelected, setRelatedCaseSelected] = useState<boolean>(false);
  const [recentAgentSelected, setRecentAgentSelected] = useState<boolean>(false);
  const [showAsActive, setShowAsActive] = useState<boolean>(true);
  const [currentGroup, setCurrentGroup] = useState<GroupsViewModel>();
  const [canSave, setCanSave] = useState(true);

  const {
    optionsLoaded,
    scheduleOptions,
    timeZoneOptions,
    calendarOptions,
    defaultLanguageOptions,
    superiorOptions
  } = useQueuesAdminSelectOptions();

  useEffect(() => {
    if (scheduleOptions.length > 0) setScheduleInput(scheduleOptions[0].key);
    if (timeZoneOptions.length > 0) setTimezoneInput(timeZoneOptions[0].key);
    if (calendarOptions.length > 0) setCalendarInput(calendarOptions[0].key);
  }, [scheduleOptions, timeZoneOptions, calendarOptions])

  const handleQueueSave = function () {
    if (!canSave) return;
    setCanSave(false);
    setContentLoaded(false);
    if (panelMode == "new") {
      const groupServiceInstance = new GroupsService();
      groupServiceInstance.insertGroup({
        isRotating: false,
        isUpdatable: false,
        isPicking: false,
        finishPendingCaseBeforeRecivingNewOne: false,
        preferredAgent: preferredCustomerSelected,
        relatedCase: relatedCaseSelected,
        recentAgent: recentAgentSelected,
        prioritizeRelatedCases:  relatedCaseSelected,
        topSearchCoefficient: 2,
        assignCasesToUsersLogged: false,
        taskResponsibleForSocialMediaAnswer: 1,
        priorityWeight: 2,
        groupId: 0,
        minutesLogoutReassign: 0,
        maximumCasesPerUser: 0,
        isEventTakeNextGroup: true,
        user: {
          userGuid: "",
          login: idInput,
          firstName: nameInput,
          lastName: "",
          name: nameInput,
          initials: "",
          profileId: null,
          profileDescription: "",
          superiorIdGuid: superiorInput.trim().length == 0 ? null : superiorInput,
          email: "",
          teamId: "",
          teamDescription: "",
          functionId: "",
          functionDescription: "",
          areaId: "",
          areaDescription: "",
          phone: "",
          mobile: "",
          typistIdGuid: TPGlobal.currentUserGuid,
          calendarId: calendarInput,
          calendarDescription: "",
          timeZoneId: timezoneInput,
          scheduleId: scheduleInput,
          scheduleDescription: "",
          authenticationMethodId: "",
          microsoftIdentity: "",
          defaultLanguage: TPGlobal.language,
          maxSimultaneousChat: 0,
          isActive: showAsActive,
          isSystemRecord: false
        }
      }, false, true, [200])
        .then((response) => {
          if (response.responseData.responseCode === 200 && panelMode === "new") {
            tabCallback({
              command: 'delete',
              recordId: '--',
              languageId: '',
              recordDescription: ''
            })
            tabCallback({
              command: 'update',
              recordId: response.responseData.keyList[0].value,
              languageId: TPGlobal.language,
              recordDescription: nameInput
            })
            updateCallback({
              result: 'ReloadGrid'
            })
            showToast(labels.InsertSuccess, TPToastTypes.success);
          }
        })
        .catch((error) => { console.error(error) })
        .finally(() => {
          setCanSave(true);
          setContentLoaded(true);
        })
    } else if (panelMode === "update" && currentGroup !== undefined) {
      const groupServiceInstance = new GroupsService();
      groupServiceInstance.updateGroup({
        isRotating: currentGroup.isRotating,
        isUpdatable: currentGroup.isUpdatable,
        isPicking: currentGroup.isPicking,
        finishPendingCaseBeforeRecivingNewOne: currentGroup.finishPendingCaseBeforeRecivingNewOne,
        preferredAgent: preferredCustomerSelected,
        relatedCase: relatedCaseSelected,
        recentAgent: recentAgentSelected,
        prioritizeRelatedCases: relatedCaseSelected,
        topSearchCoefficient: currentGroup.topSearchCoefficient,
        assignCasesToUsersLogged: currentGroup.assignCasesToUsersLogged,
        taskResponsibleForSocialMediaAnswer: currentGroup.taskResponsibleForSocialMediaAnswer,
        priorityWeight: currentGroup.priorityWeight,
        groupId: Number(currentGroup.groupId),
        minutesLogoutReassign: Number(currentGroup.minutesLogoutReassign),
        maximumCasesPerUser: Number(currentGroup.maximumCasesPerUser),
        isEventTakeNextGroup: true,
        user: {
          userGuid: currentGroup.user.userGuid,
          login: currentGroup.user.login,
          firstName: nameInput,
          lastName: currentGroup.user.lastName,
          name: nameInput,
          initials: currentGroup.user.initials,
          profileId: currentGroup.user.profileId,
          profileDescription: currentGroup.user.profileDescription,
          superiorIdGuid: superiorInput.trim().length == 0 ? null : superiorInput,
          email: currentGroup.user.email,
          teamId: currentGroup.user.teamId,
          teamDescription: "...",
          functionId: currentGroup.user.functionId,
          functionDescription: "...",
          areaId: currentGroup.user.areaId,
          areaDescription: "...",
          phone: currentGroup.user.phone,
          mobile: currentGroup.user.mobile,
          typistIdGuid: TPGlobal.currentUserGuid,
          calendarId: calendarInput,
          calendarDescription: "...",
          timeZoneId: timezoneInput,
          scheduleId: scheduleInput,
          scheduleDescription: "...",
          authenticationMethodId: currentGroup.user.authenticationMethodId,
          microsoftIdentity: currentGroup.user.microsoftIdentity,
          defaultLanguage: TPGlobal.language,
          maxSimultaneousChat: currentGroup.user.maxSimultaneousChat,
          isActive: showAsActive,
          isSystemRecord: currentGroup.user.isSystem
        }
      }, false, true, [200])
        .then((response) => {
          if (response.responseData.responseCode === 200) {
            showToast(labels.UpdateSuccess, TPToastTypes.success);
            updateCallback({
              result: 'ReloadGrid'
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setCanSave(true);
          setContentLoaded(true);
        })
    }
  }

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setContentLoaded(false);
      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQEVQU
      );
      setContentLoaded(true);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setIdInput(result);
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} updatetFunction ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setContentLoaded(true);
    }
  };

  useEffect(() => {
    if (panelMode === "new") {
      generalAutomaticId();
    }
    else if (panelMode === "update" && groupId) {
      setContentLoaded(false);
      const userServiceInstance = new GroupsService();
      userServiceInstance.getGroupById(String(groupId), false, false, [200])
        .then((response) => {
          if (response) {
            setCurrentGroup(response);
            setIdInput(response.user.login || '');
            setNameInput(response.user.firstName || '');
            setCalendarInput(response.user.calendarId || '');
            setScheduleInput(response.user.scheduleId || '');
            setSuperiorInput(response.user.superiorIdGuid || '');
            setShowAsActive(response.user.isActive || false);
            setPreferredCustomerSelected(response.preferredAgent || false);
            setRelatedCaseSelected(response.prioritizeRelatedCases || false)
            setRecentAgentSelected(response.recentAgent || false);
            setTimezoneInput(response.user.timeZoneId || '');
            setContentLoaded(true);
          }
        })
    }
  }, [])

  return (
    <>
      {active && <TPLoadingOverlay active={!(optionsLoaded && labelsLoaded && contentLoaded)}>
        <div id='queue-general-info-container' className="queue-admin-tab">
          <h5><b>{labels.Description}</b></h5>
          <div id='queue-general-info-desc-form' className="form-grid3" style={{ gap: '16px' }}>
            <div id='queue-general-info-id-input'>
              <TPTextBox labelText={labels.ID} isMandatory onChange={() => { }} value={idInput} disabled />
            </div>
            <div id='queue-general-info-name-input'>
              <TPTextBox
                onChange={(event: any) => setNameInput(event.target.value)}
                value={nameInput}
                labelText={labels.Name}
                isMandatory
              />
            </div>
          </div>
          <h5><b>{labels.Parameters || 'Params'}</b></h5>
          <div id='queue-general-info-params-form' className="queue-form-grid2">
            {/* <div id='queue-general-info-schedule-input'>
              <TPSelect
                dataSource={scheduleOptions}
                isMandatory
                labelText={labels.Schedule}
                onChange={(event: any) => setScheduleInput(event.target.value)}
                value={scheduleInput}
              />
            </div>
            <div id='queue-general-info-timezone-input'>
              <TPSelect
                labelText={labels.TimeZone}
                isMandatory
                dataSource={timeZoneOptions}
                onChange={(event: any) => setTimezoneInput(event.target.value)}
                value={timezoneInput}
              />
            </div>
            <div id='queue-general-info-calendar-input'>
              <TPSelect
                labelText={labels.Calendar}
                isMandatory
                dataSource={calendarOptions}
                onChange={(event: any) => setCalendarInput(event.target.value)}
                value={calendarInput}
              />
            </div> */}
            <div id='queue-general-info-superior-input'>
              <TPSelect
                labelText={labels.Superior}
                dataSource={superiorOptions}
                onChange={(event: any) => setSuperiorInput(event.target.value)}
                value={superiorInput}
              />
            </div>
          </div>
          <div id='queue-general-info-params-form-lower' className="queue-form-grid2">

            <div id='queue-general-info-group-type-input' style={{ width: '100%' }}>
              <TPTextBox onChange={() => {}} labelText={labels.GroupType} isMandatory value={labels.EventTakeNext} disabled />
            </div>

            <div id='queue-general-info-group-behavior-input' style={{ width: '100%' }}>
              <TPLabel labelText={labels.GroupBehavior} />
              <div className="vertical-radio-group" style={{ width: 'min-content', padding: '8px' }}>
                <TPCheckBox
                  checked={preferredCustomerSelected}
                  onChange={(event: any) => setPreferredCustomerSelected(event.target.checked)}
                  labelText={"1. " + labels.preferredCustomers}
                />
                <TPCheckBox
                  checked={relatedCaseSelected}
                  onChange={(event: any) => setRelatedCaseSelected(event.target.checked)}
                  labelText={"2. " + labels.RelatedCase}
                />
                <TPCheckBox
                  checked={recentAgentSelected}
                  onChange={(event: any) => setRecentAgentSelected(event.target.checked)}
                  labelText={"3. " + labels.RecentAgent}
                />
              </div>
            </div>
            <TPCheckBox
              checked={showAsActive}
              labelText={labels.ShowAsActive}
              onChange={(event: any) => setShowAsActive(event.target.checked)}
            />
          </div>
          <br />
          <div className="option-button-pair">
            <TPButton
              onClick={() => tabCallback({
                command: 'delete',
                recordId: String(groupId)
              })}
              isDesignSystem
              style={{
                backgroundColor:'white',
                color:'purple',
                paddingTop:'11px',
                paddingBottom:'11px',
                paddingLeft:'16px',
                paddingRight:'16px'
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => handleQueueSave()}
              type={TPButtonTypes.primary}
              isDesignSystem
              style={{paddingTop:'11px', paddingBottom:'11px', paddingLeft:'16px', paddingRight:'16px'}}
              disabled={
                (
                  nameInput.trim().length == 0
                  // scheduleInput.replace('--', '').trim().length == 0 ||
                  // timezoneInput.replace('--', '').trim().length == 0 ||
                  // calendarInput.replace('--', '').trim().length == 0
                )
                ||
                !canSave
              }
            >
              {labels.Save}
            </TPButton>
          </div>
        </div>
      </TPLoadingOverlay>}
    </>
  )
}

export default QueueGeneralInfo;