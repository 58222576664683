import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { DragEvent, ReactElement, ReactNode, useState } from "react";
import {
  LoaderRing,
  LoaderWrapper,
  StyledDragFile,
  StyledDragText,
  StyledFileStatus,
  StyledLoadFileDialog,
} from "../design-system-styles";
import {
  DragAndDropFileLabels,
  FileStatusMessages,
  StatusKey,
} from "../design-system.model";

type DragAndDropFileProps = {
  componentLabels: DragAndDropFileLabels;
  isLoading: boolean;
  withErrors: boolean;
  isValidationCompleted: boolean;
  setIsValidFile: Function;
  setValidFile: Function;
  handleCancelFile: Function;
  handleDownloadTemplate: Function;
  maxMBFileSize?: number;
  children?: ReactNode;
};

function DragAndDropFile({
  componentLabels,
  isLoading,
  withErrors,
  isValidationCompleted,
  setIsValidFile,
  setValidFile,
  handleCancelFile,
  handleDownloadTemplate,
  maxMBFileSize = 500,
  children,
}: DragAndDropFileProps): ReactElement {
  const [hideButton, setHideButton] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [isErrorFile, setIsErrorFile] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFileToLoad(event.target.files[0]);
      event.target.value = "";
    }
  };

  const setFileToLoad = (file: File): void => {
    let validationResult: boolean = isValidFile(file);
    setIsErrorFile(!validationResult);
    setHideButton(validationResult);
    setIsValidFile(validationResult);
    setFile(file);
    setValidFile(file);
  };

  const getSizeFormatBy = (file: File): string => {
    let bytes = file.size;
    if (bytes < 1024) return bytes + " bytes";
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + " KB";
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  const isValidFormatFile = (file: File): boolean => {
    let fileType = file.type;
    return (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  };

  const isValidSizeFile = (file: File): boolean => {
    const fileSizeInMB = file.size / (1024 * 1024);
    return fileSizeInMB <= maxMBFileSize;
  };

  const isValidFile = (file: File): boolean => {
    return isValidFormatFile(file) && isValidSizeFile(file);
  };

  const cancelFileProcess = () => {
    setFile(null);
    setValidFile(file);
    setIsValidFile(false);
    setHideButton(false);
    handleCancelFile();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFileToLoad(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const openFileExplorer = () => {
    const input = document.getElementById("load-document") as HTMLInputElement;
    if (input) {
      input.click();
    }
  };

  const getFileStatusDetails = (status: boolean): string => {
    let statusType: StatusKey = status ? "valid" : "process";

    const statusMessage: FileStatusMessages = {
      process: isErrorFile
        ? componentLabels.fileError
        : componentLabels.fileSuccess,
      valid: withErrors
        ? componentLabels.validError
        : componentLabels.validSuccess,
    };

    return statusMessage[statusType];
  };

  return (
    <StyledLoadFileDialog>
      <p className="dialog-title">{componentLabels.title}</p>
      {children}
      <div className="download-button-ct">
        <TPButton
          id="download-template"
          isDesignSystem
          customType={ButtonCustomType.tertiary}
          onClick={handleDownloadTemplate}
        >
          {componentLabels.downloadTemplate}
        </TPButton>
      </div>
      <StyledDragFile
        dragging={dragging}
        fileExist={!!file}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {file ? (
          <StyledFileStatus
            isErrorFile={isErrorFile || (isValidationCompleted && withErrors)}
          >
            {isLoading ? (
              <LoaderWrapper>
                <LoaderRing />
              </LoaderWrapper>
            ) : (
              <TPIcon
                iconType={
                  isErrorFile || (isValidationCompleted && withErrors)
                    ? TPIconTypes.close
                    : TPIconTypes.done
                }
              />
            )}
            <div className="text-container">
              <p className="drop-label">{`${file.name} (${getSizeFormatBy(file)})`}</p>
              <p className="status-label">
                {getFileStatusDetails(isValidationCompleted)}
              </p>
            </div>
          </StyledFileStatus>
        ) : (
          <StyledDragText>
            <TPIcon iconType={TPIconTypes.cloud} />
            <div className="text-container">
              <p className="drop-label">{componentLabels.drop}</p>
              <p className="size-label">{`${componentLabels.maxSize} ${maxMBFileSize} MB`}</p>
            </div>
          </StyledDragText>
        )}

        <input
          type="file"
          id="load-document"
          name="load-document"
          accept=".xlsx"
          onChange={onFileSelected}
          style={{ display: "none" }}
        />

        <div className="buttons-container">
          {!hideButton && (
            <TPButton
              id="select-file"
              isDesignSystem
              customType={ButtonCustomType.secondary}
              onClick={openFileExplorer}
              style={{ padding: "1px 18px" }}
            >
              {file && isErrorFile
                ? componentLabels.selectNewFile
                : componentLabels.selectFile}
            </TPButton>
          )}
          {file && (
            <TPIcon
              className="cancel-btn"
              id="cancel-file"
              onClick={cancelFileProcess}
              iconType={TPIconTypes.close}
            />
          )}
        </div>
      </StyledDragFile>
    </StyledLoadFileDialog>
  );
}

export default DragAndDropFile;
