import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import {
  CustomStateCloneModel,
  CustomStateListModel,
  CustomStateModel,
  CustomStateNewModel,
} from "@/models/CustomState/CustomState";

export class CustomStateService {
  serviceFileName: string = "CustomStateService.tsx";

  public async save(element: CustomStateNewModel): Promise<void> {
    const { postData } = new TPHTTPService();
    try {
      await postData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule`,
        element,
        [200, 404],
        true,
        true,
        [...TPGlobal.standardHeaders]
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} save ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} save`);
      throw new Error(`Error ${this.serviceFileName} save`);
    }
  }

  public async update(element: CustomStateNewModel): Promise<void> {
    const { putData } = new TPHTTPService();
    try {
      await putData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule`,
        element,
        [200, 404],
        true,
        true,
        [...TPGlobal.standardHeaders]
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} update ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} update`);
      throw new Error(`Error ${this.serviceFileName} update`);
    }
  }

  public async clone(element: CustomStateCloneModel): Promise<void> {
    const { postData } = new TPHTTPService();
    try {
      await postData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule/clone`,
        element,
        [200, 404],
        true,
        true,
        [...TPGlobal.standardHeaders]
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} clone ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} clone`);
      throw new Error(`Error ${this.serviceFileName} clone`);
    }
  }

  public async deleteOne(id: string): Promise<void> {
    const { deleteData } = new TPHTTPService();
    try {
      await deleteData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule/${id}`,
        [200, 404],
        true,
        true,
        [...TPGlobal.standardHeaders]
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} delete ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} delete`);
      throw new Error(`Error ${this.serviceFileName} delete`);
    }
  }

  public async findOne(id: string): Promise<CustomStateModel> {
    const { getData } = new TPHTTPService();
    try {
      const response = await getData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule/${id}`,
        [200, 404],
        false,
        false,
        [...TPGlobal.standardHeaders]
      );
      return response?.responseData?.data?.[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} findOne ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} findOne`);
      throw new Error(`Error ${this.serviceFileName} findOne`);
    }
  }

  public async find(id: string): Promise<Array<CustomStateListModel>> {
    const { getData } = new TPHTTPService();
    let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/customstate-flow-rule/workflowtype/${id}/detail`;
    try {
      const response = await getData(url, [200, 404], false, true, [
        ...TPGlobal.standardHeaders,
      ]);
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} find ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} find`);
      throw new Error(`Error ${this.serviceFileName} find`);
    }
  }
}
