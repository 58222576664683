export interface LocalizedValue {
  languageId: string;
  localizedValue: string;
  languageName?: string;
}

export interface LocalizedValueNoName extends Omit<LocalizedValue, 'languageName'> {}

export interface ListItemModel {
  id: string
  description: string
  isActive: boolean
  order: number
  descriptionLocalizedValues: LocalizedValue[]
}

export interface ListModel {
  id: string;
  description: string;
  type: string;
  isActive: boolean;
  alphabeticalOrder: boolean;
  descriptionLocalizedValues: LocalizedValue[];
  items: ListItemModel[];
  createdBy: string;
}

export interface ListCloneModel {
  sourceId: string;
  id: string;
  description: string;
  isActive: boolean;
  descriptionLocalizedValues: LocalizedValue[];
}

export interface ListTableModel extends Omit<ListModel, 'descriptionLocalizedValues' | 'items' | 'type'> {}

export enum ListTypes {
  S_EVENTLISTTYPE = 'S_EVENTLISTTYPE'
}
