import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RelationsViewModel, RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPI18N } from "@/services/I18nService";
import { RelationsService } from "@/services/RelationsService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<RelationsViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface RelationsAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const RelationsAdmin = React.forwardRef(
  ({ callBackCommands }: RelationsAdminInterface, ref) => {
    const componentFileName: string = "RelationsAdmin.tsx";

    //Functions called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "RelationAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");

    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndRelationsFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setNameColumnLabel(await TPI18N.GetText(ResourceSet, "Name"));
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(ResourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    // const setupGridColumns = (prevState: AdminStateType) => {
    //   try {
    //     let newState: AdminStateType;
    //     newState = { ...prevState };
    //     let newColumns: Array<any> = [];
    //     //delete
    //     newColumns.push({
    //       name: "",
    //       width: "50px",
    //       center: true,
    //       cell: (row: { [x: string]: any }) => {
    //         return (
    //           <div className="dropdown">
    //             <TPButton
    //               id="IdButton"
    //               dataBsToggle={true}
    //               type={TPButtonTypes.empty}
    //               onClick={() => {
    //                 TPGlobal.foo();
    //               }}
    //               className={"menu-button"}
    //             >
    //               <TPIcon iconType={TPIconTypes.moreVert} />
    //             </TPButton>
    //             <ul className="dropdown-menu">
    //               <li>
    //                 <a
    //                   onClick={(id: any) => handleDeleteClick(row["id"])}
    //                   className="dropdown-item"
    //                   href="#"
    //                 >
    //                   {deleteLabel}
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={(id: any) => handleUpdateClick(row["id"])}
    //                   className="dropdown-item"
    //                   href="#"
    //                 >
    //                   {updateLabel}
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         );
    //       },
    //     });
    //     //update
    //     newColumns.push({
    //       width: "50px",
    //       style: { padding: 0 },
    //       cell: (row: { [x: string]: any }) => {
    //         return (
    //           <TPButton
    //             id="IdButton"
    //             type={TPButtonTypes.primary}
    //             onClick={(id: string) => handleUpdateClick(row["id"])}
    //             className="update-button"
    //           >
    //             <TPIcon iconType={TPIconTypes.chevronRight} />
    //           </TPButton>
    //         );
    //       },
    //       selector: (row: { [x: string]: any }) => row["id"],
    //       sortable: true,
    //     });
    //     //id
    //     newColumns.push({
    //       width: "250px",
    //       name: idColumnLabel,
    //       cell: (row: { [x: string]: any }) => {
    //         return row["id"];
    //       },
    //       selector: (row: { [x: string]: any }) => row["id"],
    //       sortable: true,
    //     });
    //     //name primary language
    //     let languageColumnLabel: string;
    //     languageColumnLabel = nameColumnLabel;
    //     languageColumnLabel = (languageColumnLabel +
    //       " (" +
    //       TPGlobal.TPClientAvailableLanguages[0].name) as string;
    //     languageColumnLabel = languageColumnLabel + ")";
    //     newColumns.push({
    //       width: "350px",
    //       name: languageColumnLabel,
    //       selector: (row: { [x: string]: any }) => row["name"],
    //       sortable: true,
    //     });
    //     //name current language if it is different
    //     if (TPGlobal.TPClientAvailableLanguages[0].id !== TPGlobal.language) {
    //       languageColumnLabel = nameColumnLabel;
    //       languageColumnLabel = (languageColumnLabel +
    //         " (" +
    //         TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)) as string;
    //       languageColumnLabel = languageColumnLabel + ")";
    //       newColumns.push({
    //         width: "350px",
    //         name: languageColumnLabel,
    //         selector: (row: { [x: string]: any }) => row["localizedName"],
    //         sortable: true,
    //       });
    //     }
    //     //isActive
    //     newColumns.push({
    //       name: isActiveColumnLabel,
    //       selector: (row: { [x: string]: any }) => row["isActive"],
    //       width: "150px",
    //       center: true,
    //       cell: (row: { [x: string]: any }) => {
    //         let currentValue: boolean = true;
    //         currentValue = row["isActive"];
    //         return (
    //           <IsActiveIcon
    //             className={currentValue ? "active" : "inactive"}
    //             iconType={TPIconTypes.activeInactive}
    //           />
    //         );
    //       },
    //     });

    //     newState.gridColumns = [...newColumns];
    //     return newState;
    //   } catch (error) {
    //     TPLog.Log(
    //       `Error ${componentFileName} setupGridColumns ex`,
    //       TPLogType.ERROR,
    //       error,
    //     );
    //     console.error(`Error ${componentFileName} setupGridColumns ex`);
    //     return prevState;
    //   }
    // };

    //Get relations by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getRelationsByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Relation
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update relation
    const handleUpdateClick = (id: string, languageId: string) => {
      let command: any = {
        command: "update",
        recordId: id,
      };
      callBackCommands(command);
    };

    //Modal Question to delete relation
    const handleDeleteClick = (id: string, languageId: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = {
        recordId: id,
        languageId: languageId,
      };
      setModalQuestionState(newModalQuestionState);
    };

    //Clone 
    // const handleCloneClick = (id: string) => {
    //   let element = emailTemplateData.find((item) => item.id === id);

    //   callBackCommands({
    //     command: "clone",
    //     recordId: id,
    //     form: element,
    //   });
    // };

    //Delete relation after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new RelationsService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteRelationById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "name", "localizedName"];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndRelationsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndRelationsFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndRelationsFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    const emailTemplateColumns: CustomColumnNames<RelationsViewModel> = {
      id: idColumnLabel,
      name: nameColumnLabel,
      localizedName: "languageColumnLabel",
      isActive: isActiveColumnLabel,
    };

    const customColumns: ColumnStyles<RelationsViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          isDisabled={item.isSystemRecord}
          onClick={() =>
            !item.isSystemRecord && handleUpdateClick(item.id, item.languageId)
          }
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const minorOptions: MinorOption<RelationsViewModel>[] = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e) => handleUpdateClick(e.id, e.languageId),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e) => handleDeleteClick(e.id, e.languageId),
      },
      // {
      //   key: "cloneLabel",
      //   type: "clone",
      //   icon: TPIconTypes.clone,
      //   onOptionChange: (e) => handleCloneClick(e.id),
      // },
    ];

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
      // {
      //   key: "language",
      //   data: filterByLanguage,
      //   label: filterByLanguageLabel,
      //   selectedValue: adminState.filterByLanguage,
      //   width: 160,
      //   onChange: handleFilterByLanguageChange,
      // },
    ];

    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        {/* <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />

              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "relations-admin-data")
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="IdSelect"
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal={true}
                    ></TPSelect>
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>

          <div className="row">
            <div className="col">

              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay> */}

        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-email-template"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={emailTemplateColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={["languageId", "isSystemRecord", "localizedName"]}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                disableMinorOption={(item) => item.isSystemRecord}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default RelationsAdmin;
