import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import { TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import { RequestResponse } from "@/helpers/TPStandardResponse";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { StructureService } from "@/services/StructureService";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { Alert, Dialog, DialogProps } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import DynamicTable, {
  CustomColumnNames,
} from "../../components/dynamic-table/DynamicTable";
import { exportDataToExcel } from "../../utils/export-utils";
import {
  StyledActionButtons,
  StyledDialogContent,
} from "../design-system-styles";
import {
  DragFileModalLabels,
  FileSummary,
  FileSummaryType,
  RenderAlertProps,
} from "../design-system.model";
import { useToast } from "../toasts/ToastContext";
import DragAndDropFile from "./DragAndDropFile";

type DragFileModalProps = {
  status: boolean;
  projectId: string;
  eventLoadStructureId: string;
  isToReplaceFile?: boolean;
  handleModalOpen?: () => void;
  handleModalClose: (status: boolean, code?: number, file?: File) => void;
};

function DragFileModal({
  status,
  projectId,
  eventLoadStructureId,
  isToReplaceFile,
  handleModalOpen = () => {},
  handleModalClose,
}: DragFileModalProps): ReactElement {
  const component: string = "DragFileModal";

  const { showToast } = useToast();

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isScreenLoading, setIsScreenLoading] = useState<boolean>(false);
  const [isValidFile, setIsValidFile] = useState<boolean>(false);
  const [fileWithErrors, setFileWithErrors] = useState<boolean>(false);
  const [isValidationCompleted, setIsValidationCompleted] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [requestErrorLabel, setErrorRequestLabel] = useState<string>("");
  const [fileColumns, setFileColumns] = useState<FileSummary[]>([]);
  const [eventLoadId, setEventLoadId] = useState<string>("");

  const initialComponentLabels: DragFileModalLabels = {} as DragFileModalLabels;

  const [componentLabels, setComponentLabels] = useState<DragFileModalLabels>(
    initialComponentLabels
  );

  const loadUtilsResources = async () => {
    setComponentLabels({
      cancel: await TPI18N.GetText(component, "Cancel"),
      validate: await TPI18N.GetText(component, "Validate"),
      upload: await TPI18N.GetText(component, "Upload"),
      summary: await TPI18N.GetText(component, "Summary"),
      item: await TPI18N.GetText(component, "Item"),
      errorType: await TPI18N.GetText(component, "ErrorType"),
      downloadErrorFile: await TPI18N.GetText(component, "DownloadFile"),
      replaceFile: await TPI18N.GetText(component, "ReplaceFile"),
      validSuccessAlert: await TPI18N.GetText(component, "ValidSuccessAlert"),
      dragAndDropFileLabels: {
        title: await TPI18N.GetText(component, "LoadNewFile"),
        downloadTemplate: await TPI18N.GetText(component, "DownloadTemplate"),
        selectFile: await TPI18N.GetText(component, "SelectFile"),
        selectNewFile: await TPI18N.GetText(component, "SelectNewFile"),
        drop: await TPI18N.GetText(component, "DropFile"),
        maxSize: await TPI18N.GetText(component, "MaxSize"),
        fileError: await TPI18N.GetText(component, "FileError"),
        fileSuccess: await TPI18N.GetText(component, "FileSuccess"),
        validSuccess: await TPI18N.GetText(component, "ValidSuccess"),
        validError: await TPI18N.GetText(component, "ValidError"),
      },
    });
  };

  const columnNames: CustomColumnNames<FileSummary> = {
    item: componentLabels.item,
    error: componentLabels.errorType,
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsValidFile(false);
    setIsValidationCompleted(false);
    setFileWithErrors(false);
    setEventLoadId("");
    setFileColumns([]);
    handleModalOpen();
  };

  const handleClose: DialogProps["onClose"] = (_event, reason) => {
    if (reason && reason === "backdropClick") return;

    setOpen(false);
    handleModalClose(false);
  };

  const RenderAlert = ({ message, type }: RenderAlertProps): JSX.Element => (
    <Alert sx={{ fontFamily: "Noto Sans", fontSize: "14px" }} severity={type}>
      {message}
    </Alert>
  );

  const handleValidateFile = () => {
    if (file) {
      setIsLoading(true);
      validateColumnsBy(file).then((response) => {
        if (response.data) setFileColumns(response.data);

        if (response.keyList && response.keyList.length > 0)
          setEventLoadId(response.keyList[0].value);

        setFileWithErrors(response.responseCode !== 200);
        setErrorRequestLabel(response.responseMessage.message);
        setIsValidationCompleted(true);
        setIsLoading(false);
      });
    }
  };

  const validateColumnsBy = async (file: File): Promise<RequestResponse> => {
    const serviceClient = new TemporaryAttachmentService();

    const fileData = new FormData();

    fileData.append("projectId", projectId);
    fileData.append("fileName", file.name);
    fileData.append("extensionName", ".xlsx");
    fileData.append("description", file.name);
    fileData.append("guid_USER", TPGlobal.currentUserGuid);
    fileData.append("fileData", file);

    return await serviceClient.validateColumnsFile(fileData, false, false);
  };

  const handleCancelFileProcess = () => {
    setIsValidationCompleted(false);
    setFileWithErrors(false);
    setIsValidFile(false);
    setEventLoadId("");
    setFile(null);
    setFileColumns([]);
  };

  const getStructureDetails = async (id: string) => {
    let serviceClient = new StructureService();

    return await serviceClient.downloadStructureDetailsBy(id);
  };

  const downloadFileTemplate = () => {
    getStructureDetails(eventLoadStructureId).then((response) => {
      if (response.responseCode === 200) {
        let templateName: string = `structure-template-${eventLoadStructureId}`;
        const columnNameObject = TPGlobal.createColumnNameObject(response.data);
        exportDataToExcel(columnNameObject, templateName);
      } else {
        showToast(response.responseMessage.message, TPToastTypes.error);
      }
    });
  };

  const getExportData = () => {
    const dataColumns: any[] = [];

    fileColumns.forEach((item) => {
      const filteredItem: Partial<any> = {};

      Object.keys(fileColumns[0]).map((key) => {
        const customName = columnNames[key as FileSummaryType];

        if (customName) {
          filteredItem[customName] = item[key as FileSummaryType];
        } else {
          filteredItem[key] = item[key as FileSummaryType];
        }
      });

      dataColumns.push(filteredItem);
    });

    return dataColumns;
  };

  const handleExportErrorFile = () => {
    exportDataToExcel(
      getExportData(),
      `${file?.name.replace(".xlsx", "")}-errors`
    );
  };

  const handleUploadFile = () => {
    if (file) {
      setIsScreenLoading(true);
      uploadFile().then((response) => {
        setIsScreenLoading(false);
        handleModalClose(true, response.responseCode, file);
      });
    }
  };

  const uploadFile = async (): Promise<RequestResponse> => {
    const serviceClient = new TemporaryAttachmentService();
    return await serviceClient.processFile(eventLoadId);
  };

  useEffect(() => {
    setOpen(status);
    status ? handleClickOpen() : handleModalClose(false);
  }, [status]);

  useEffect(() => {
    loadUtilsResources().then();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ fontFamily: "Noto Sans" }}
      maxWidth="lg"
      aria-labelledby="upload-file-dialog"
    >
      <StyledDialogContent>
        <TPLoadingOverlay active={isScreenLoading} isModal>
          <DragAndDropFile
            componentLabels={componentLabels.dragAndDropFileLabels}
            isLoading={isLoading}
            withErrors={fileWithErrors}
            isValidationCompleted={isValidationCompleted}
            setValidFile={setFile}
            setIsValidFile={setIsValidFile}
            handleCancelFile={handleCancelFileProcess}
            handleDownloadTemplate={downloadFileTemplate}
          >
            {isToReplaceFile && !fileWithErrors && (
              <RenderAlert message={componentLabels.replaceFile} type="info" />
            )}
            {fileWithErrors && (
              <RenderAlert message={requestErrorLabel} type="error" />
            )}
            {!fileWithErrors && eventLoadId !== "" && (
              <RenderAlert
                message={componentLabels.validSuccessAlert}
                type="success"
              />
            )}
          </DragAndDropFile>
          {fileColumns.length !== 0 && fileWithErrors && (
            <>
              <p style={{ margin: 0, fontSize: "18px", fontWeight: "700" }}>
                {componentLabels.summary}
              </p>
              <DynamicTable
                id="file-fields"
                data={fileColumns}
                style={{
                  height: "auto",
                  maxHeight: "35vh",
                  scrollbarWidth: "thin",
                }}
                columnNames={columnNames}
                hideControls
                hidePager
              />
            </>
          )}
          <StyledActionButtons isErrorFile={fileWithErrors}>
            {fileWithErrors && (
              <TPButton
                id="download-conflict-file"
                isDesignSystem
                withIcon={TPIconTypes.downloadTemplate}
                customType={ButtonCustomType.tertiary}
                orientationIcon="left"
                onClick={() => handleExportErrorFile()}
              >
                {componentLabels.downloadErrorFile}
              </TPButton>
            )}

            <div className="process-buttons">
              <TPButton
                id="cancel-upload"
                isDesignSystem
                customType={ButtonCustomType.secondary}
                onClick={handleClose}
                style={{ padding: "1px 18px" }}
              >
                {componentLabels.cancel}
              </TPButton>

              {!isValidationCompleted ? (
                <TPButton
                  id="validate-file"
                  isDesignSystem
                  onClick={handleValidateFile}
                  style={{ padding: "1px 18px" }}
                  disabled={!isValidFile || isLoading}
                >
                  {componentLabels.validate}
                </TPButton>
              ) : (
                <TPButton
                  id="upload-file"
                  isDesignSystem
                  onClick={handleUploadFile}
                  style={{ padding: "1px 18px" }}
                  disabled={fileWithErrors}
                >
                  {componentLabels.upload}
                </TPButton>
              )}
            </div>
          </StyledActionButtons>
        </TPLoadingOverlay>
      </StyledDialogContent>
    </Dialog>
  );
}

export default DragFileModal;
