const ExpandMoreIcon = ({ ...props }) => {
  return (
    <span
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.590027 10.58L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.58Z"
                            fill="black"
                        />
                    </svg>
                    `,
      }}
    ></span>
  );
};

export default ExpandMoreIcon;
