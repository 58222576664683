import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  ProjectDetailEnum as e,
  ProjectType,
  TableProjectDetailModel,
} from "@/models/Project/Projects";
import DynamicTable, {
  CustomActionProps,
  CustomColumnNames,
  GroupedColumns,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import DefaultTableCard from "@/modules/core/design-system/cards/DefaultTableCard";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { ColumnStyles } from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import ProjectReassign from "@/pages/Projects/ProjectReassign/ProjectReassign";
import { TPI18N } from "@/services/I18nService";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  CockpitDashboardLabels,
  CockpitView,
  GroupTableItem,
} from "../../supervisor-cockpit.model";

type DashboardTableProps = {
  view?: CockpitView;
  data: GroupTableItem[] | any[];
  groups: TPKeyValue[];
  componentLabels: CockpitDashboardLabels;
  setIsLoadingScreen: Function;
  goToProjectDetails?: (projectId: string) => void;
};

const DashboardTable: FC<DashboardTableProps> = React.memo(
  ({
    componentLabels,
    data,
    groups,
    setIsLoadingScreen,
    view = "group",
    goToProjectDetails = () => { },
  }): ReactElement => {
    const component: string = "DashboardTable";

    const baseColumns: string[] = [
      "projectId",
      "groupId",
      "onSchedule",
      "offSchedule",
    ];

    const excludeColumns = {
      group: ["projectName", ...baseColumns],
      event: baseColumns,
    };

    const [modalLabels, setModalLabels] = useState<any>({});

    const tableLabels = componentLabels.groupsTable;

    const groupColumnNames: CustomColumnNames<GroupTableItem> = {
      projectName: tableLabels.projectName,
      groupName: tableLabels.groupName,
      totalCE: tableLabels.total,
      readyStatus: tableLabels.ready,
      onlineStatus: tableLabels.online,
      workingStatus: tableLabels.working,
      offlineStatus: tableLabels.offline,
      totalTickets: view === "group" ? tableLabels.totalTickets : tableLabels.events,
      workedTickets: tableLabels.workedTickets,
      pendingTickets: tableLabels.pendingTickets,
      type: tableLabels.type,
    };

    const groupColumns: GroupedColumns<GroupTableItem> = {
      [tableLabels.customerExpert]: [
        "totalCE",
        "readyStatus",
        "onlineStatus",
        "workingStatus",
        "offlineStatus",
      ],
      [tableLabels.tickets]: [
        "totalTickets",
        "workedTickets",
        "pendingTickets",
        "type",
      ],
    };

    async function loadResources() {
      const messages = { ...modalLabels };

      messages[e.TableColumnActionReassignCELabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.TableColumnActionReassignCELabel
      );
      messages[e.ModalTitleLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalTitleLabel
      );
      messages[e.ModalCurrentQueueLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalCurrentQueueLabel
      );
      messages[e.ModalOption1Label] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalOption1Label
      );
      messages[e.ModalOption2Label] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalOption2Label
      );
      messages[e.ModalActionCancelLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalActionCancelLabel
      );
      messages[e.ModalActionReassignLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalActionReassignLabel
      );
      messages[e.ModalActionNextPageLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalActionNextPageLabel
      );
      messages[e.ModalActionSelectAllLabel] = await TPI18N.GetText(
        e.ProjectDetailComponent,
        e.ModalActionSelectAllLabel
      );
      messages[e.ActionInputSearchLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.ActionInputSearchLabel
      );

      setModalLabels(messages);
    }

    const parseItemSelected = (
      item: GroupTableItem
    ): TableProjectDetailModel => {
      return {
        queueId: `${item.groupId}`,
        groupId: `${item.groupId}`,
        queueName: item.groupName ?? "",
        totalCe: `${item.totalCE}`,
        ceReady: `${item.readyStatus}`,
        online: `${item.onlineStatus}`,
        working: `${item.workingStatus}`,
        offline: `${item.offlineStatus}`,
        events: `${item.totalTickets}`,
        worked: `${item.workedTickets}`,
        pending: `${item.pendingTickets}`,
        type: item.type as ProjectType,
        reassign: "",
      };
    };

    const getUniqGroups = (): TPKeyValue[] => {
      return removeDuplicatedIn(groups);
    }

    const removeDuplicatedIn = (items: TPKeyValue[]): TPKeyValue[] => {
      const uniqValues = new Set();
      return items.filter((item) => {
        const valor = item.key;
        if (uniqValues.has(valor)) {
          return false;
        } else {
          uniqValues.add(valor);
          return true;
        }
      });
    };

    const AdditionalAction: React.FC<CustomActionProps<GroupTableItem>> = ({
      item,
    }) => {
      return (
        <>
          {view === "group" ? (
            <ProjectReassign
              m={modalLabels}
              queues={getUniqGroups()}
              detail={parseItemSelected(item)}
              loadDataSource={() => console.log("loaded")}
            />
          ) : (
            <>
              {item.groupName ? (
                <>
                  {item.type === "BACKOFFICE" && (
                    <ProjectReassign
                      m={modalLabels}
                      queues={getUniqGroups()}
                      detail={parseItemSelected(item)}
                      loadDataSource={() => console.log("loaded")}
                    />
                  )}
                </>
              ) : (
                <TPButton
                  id={`go-to-${item.projectId}`}
                  key={`go-to-${item.projectId}`}
                  isDesignSystem
                  customType={ButtonCustomType.tertiary}
                  onClick={() =>
                    goToProjectDetails(item.projectId?.toUpperCase() ?? "")
                  }
                >
                  {componentLabels.goToDetail}
                </TPButton>
              )}
            </>
          )}
        </>
      );
    };

    const getChipColorByView = (item: GroupTableItem): string => {
      if (view === "group") return "#D3EAFF";

      if (view === "event") {
        return item.type === "BACKOFFICE" ? "#B1EFF2" : "#E2D7FF";
      };

      return "";
    }

    const getTypeResourceBy = (item: GroupTableItem): string => {
      return componentLabels.groupTypes[item.type as ProjectType] as ProjectType ?? componentLabels.groupTypes.TAKENEXT as ProjectType;
    }

    const columnStyles: ColumnStyles<GroupTableItem> = {
      type: ({ value, item }) => (
        <TableChip
          value={true}
          onLabel={getTypeResourceBy(item)}
          activeColor={getChipColorByView(item)}
          inactiveColor={getChipColorByView(item)}
        />
      ),
    };

    useEffect(() => {
      setIsLoadingScreen(true);
      loadResources().then(() => setIsLoadingScreen(false));
    }, []);

    return (
      <>
        <DynamicTable
          id={`${view}-records`}
          data={data}
          hiddenColumns={
            excludeColumns[
            view as keyof typeof excludeColumns
            ] as (keyof GroupTableItem)[]
          }
          columnNames={groupColumnNames}
          groupColumns={groupColumns}
          CustomCard={({ item }) => (
            <DefaultTableCard
              view={view}
              item={item}
              modalLabels={modalLabels}
              tableLabels={tableLabels}
              typeResource={getTypeResourceBy(item)}
              goToDetailLabel={componentLabels.goToDetail}
              groupLabel={componentLabels.group}
              projectLabel={componentLabels.project}
              selectedItem={parseItemSelected(item)}
              goToProjectDetails={goToProjectDetails}
              groups={getUniqGroups()}
            />
          )}
          CustomAction={AdditionalAction}
          columnStyles={columnStyles}
          searchPosition="right"
          maxWidthTableContainer="100%"
          switchable
          hideRefresh
        />
      </>
    );
  }
);

export default DashboardTable;
