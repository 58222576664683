import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  WallboardEnum as e,
  TabTypeEnum,
  WallboardEvents,
  WallboardStateActionType,
  WallboardTeamViewProps,
} from "@/models/Wallboard/WallboardModel";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { EventProjectService } from "@/services/EventProjectService";
import { WallboardService } from "@/services/WallboardService";
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CockpitRemovableItem from "../SupervisorCockpit/utils/CockpitRemovableItem";
import WallboardListContainer from "./WallboardListContainer";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "flex-end",
  } as CSSProperties,
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    overflowY: "auto",
  } as CSSProperties,
  sectionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box",
    marginBottom: "20px",
  } as CSSProperties,
  sectionSelected: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    boxSizing: "border-box",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard Event view
 */
const WallboardEventView = forwardRef(
  ({ id, style, children, s, dispatch }: WallboardTeamViewProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * project
     */
    const [project, setProject] = useState("");
    const [projectApply, setProjectApply] = useState("");
    /**
     * projects
     */
    const [projects, setProjects] = useState([
      { key: "", value: s.m.SectionFilterSelectChoosePlaceholderLabel },
    ]);
    /**
     * group
     */
    const [group, setGroup] = useState("");
    /**
     * groups selected
     */
    const [groupsSelected, setGroupsSelected] = useState<Array<TPKeyValue>>([]);
    const [groupsSelectedApply, setGroupsSelectedApply] = useState<
      Array<TPKeyValue>
    >([]);
    /**
     * groups
     */
    const [groups, setGroups] = useState<Array<TPKeyValue>>([]);
    /**
     * sorts
     */
    const sorts: Array<TPKeyValue> = [
      { key: "", value: s.m?.[e.SectionActionSelectSortByLabel] },
      { key: "0", value: s.m?.[e.SectionActionOptionTimeMoreLessLabel] },
      { key: "1", value: s.m?.[e.SectionActionOptionTimeLessMoreLabel] },
      { key: "2", value: s.m?.[e.SectionActionOptionNameAZLabel] },
      { key: "3", value: s.m?.[e.SectionActionOptionNameZALabel] },
    ];
    /**
     * sort by
     */
    const [sort, setSort] = useState("");
    /**
     *
     */
    const removeGroupRef = useRef<(valueToRemove: string) => void | null>(null);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {
            loadDataSources();
          },
        }) as WallboardEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadProjects();
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     *
     */
    useEffect(() => {
      if (project) {
        loadGroups();
      } else {
        dispatch({
          type: WallboardStateActionType.setData,
          payload: { type: TabTypeEnum.EventView, data: [] },
        });
      }
    }, [project]);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * load projects
     */
    function loadProjects(): void {
      const { getProjectsBySuperior } = new EventProjectService();

      getProjectsBySuperior(TPGlobal.currentUserGuid, false, true, [200, 404])
        .then((data) => {
          setProjects([
            { key: "", value: s.m.SectionFilterSelectChoosePlaceholderLabel },
            ...data.map((e) => ({ key: e.id, value: e.name })),
          ]);
        })
        .catch((error) => {
          console.error(`Error ${e.nameEvent} loadProjects`, error);
        });
    }
    /**
     * load queues
     */
    function loadGroups(): void {
      if (!project) {
        showToast("The project must be selected", TPToastTypes.warning);
        return;
      }

      const { getQueuesByProjectId } = new EventProjectService();

      getQueuesByProjectId(project)
        .then((data) => {
          setGroups(
            data.map((e) => ({
              key: `${e.groupId}`,
              value: `${e.user.firstName}${e.user.lastName ? ` ${e.user.lastName}` : ""}`,
            }))
          );
        })
        .catch((error) => {
          console.error(`Error ${e.nameEvent} loadGroups`, error);
        });
    }
    /**
     * load data sources
     */
    function loadDataSources(): void {
      setProjectApply(`${project}`);
      setGroupsSelectedApply([...groupsSelected]);

      if (!project) {
        showToast(s.m.MessageProjectMustBeSelected!, TPToastTypes.warning);
        return;
      }

      const { getEvents } = new WallboardService();

      dispatch({
        type: WallboardStateActionType.setLoading,
        payload: true,
      });
      getEvents(
        project,
        groupsSelected.map((e) => Number(e.key))
      )
        .then((data) => {
          dispatch({
            type: WallboardStateActionType.setData,
            payload: { type: TabTypeEnum.EventView, data },
          });
        })
        .catch((error) => {
          dispatch({
            type: WallboardStateActionType.setLoading,
            payload: false,
          });
          console.error(`Error ${e.nameEvent} loadDataSources`, error);
        });
    }
    /**
     * project
     */
    function onChangeProjectHandler(event: any): void {
      const value = event.target.value;
      setGroupsSelectedApply([]);
      setGroupsSelected([]);
      setGroups([]);
      setProjectApply("");
      setProject(value);
    }
    /**
     * group
     */
    function onChangeGroupHandler(event: any): void {
      const value = event.target.value;

      if (groupsSelected.find(({ key }) => key === value)) {
        showToast(s.m.MessageGroupAlreadySelected!, TPToastTypes.warning);
        return;
      }

      const sel = groups.find(({ key }) => key === value)!;
      setGroupsSelected([...groupsSelected, sel]);

      setGroup("");
    }
    /**
     * on delete chip
     *
     * @param index index of chip
     */
    function onDeleteChipHandler(index: number) {
      const q = [...groupsSelected];
      q.splice(index, 1);
      setGroupsSelected(q);
      loadDataSources();
    }
    /**
     * sort
     */
    function onChangeSortHandler(event: any): void {
      const value = event.target.value;
      setSort(value);

      dispatch({
        type: WallboardStateActionType.setData,
        payload: { type: TabTypeEnum.EventView, sort: value },
      });
    }
    /**
     *
     */
    function handleGroupsChange(values: TPKeyValue[]) {
      setGroupsSelectedApply([]);
      setGroupsSelected(values);
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={e.nameTeam}
        key={e.nameTeam}
        style={{
          ...styles.container,
          ...style,
        }}
      >
        <div
          id={`${e.nameEvent}SectionAction`}
          key={`${e.nameEvent}SectionAction`}
          style={styles.sectionAction}
        >
          <div style={{ ...styles.row, gap: "20px" }}>
            <TPSelect
              id={`${e.nameEvent}SelectProject`}
              key={`${e.nameEvent}SelectProject`}
              isMandatory={true}
              labelText={s.m.SectionFilterSelectProjectLabel}
              dataSource={projects}
              value={project}
              onChange={onChangeProjectHandler}
            />

            <SearchSelect
              id={`${e.nameEvent}SelectQueue`}
              key={`${e.nameEvent}SelectQueue`}
              width="200px"
              options={groups}
              optionSelected={groupsSelected}
              label={s.m.SectionFilterSelectQueueLabel}
              placeholder={s.m.SectionFilterSelectChoosePlaceholderLabel}
              handleMultiChange={handleGroupsChange}
              removeSelectedOptionRef={removeGroupRef}
              orientation="vertical"
              isMulti
            />

            <TPButton
              id={`${e.nameGroup}ButtonApply`}
              key={`${e.nameGroup}ButtonApply`}
              onClick={() => loadDataSources()}
              style={{ padding: "0 15px" }}
              disabled={!project}
              isDesignSystem
            >
              {s.m.SectionFilterButtonApplyLabel}
            </TPButton>
          </div>

          <div style={{ ...styles.row, gap: "20px" }}>
            <TPButton
              id={`${e.nameEvent}IconButtonRefresh`}
              key={`${e.nameEvent}IconButtonRefresh`}
              iconStyle={{ fontSize: "26px" }}
              type={TPButtonTypes.icon}
              icon={TPIconTypes.loop}
              tooltip={s.m?.[e.SectionActionTooltipRefreshLabel]}
              onClick={() => loadDataSources()}
            />

            <TPSelect
              id={`${e.nameEvent}SelectSortBy`}
              key={`${e.nameEvent}SelectSortBy`}
              dataSource={sorts}
              value={sort}
              onChange={onChangeSortHandler}
            />
          </div>
        </div>

        <div
          id={`${e.nameEvent}SectionSelectedGroups`}
          key={`${e.nameEvent}SectionSelectedGroups`}
          style={styles.sectionSelected}
        >
          {groupsSelected.map((s, index) => (
            <CockpitRemovableItem
              key={`${e.nameEvent}Chip${s.key}`}
              item={groups.find(({ key }) => key === s.key) as any}
              onRemoveItem={() => {
                onDeleteChipHandler(index);
                removeGroupRef.current?.(s.key);
              }}
            />
          ))}
        </div>

        <WallboardListContainer
          m={s.m}
          data={s.events}
          queuesSelected={
            projectApply
              ? JSON.stringify(groupsSelected) ===
                JSON.stringify(groupsSelectedApply)
                ? groupsSelectedApply.length > 0
                  ? groupsSelectedApply
                  : groups
                : []
              : []
          }
          type="queue"
          noDataMessage={`${s.m.TableEventNoDataLabel}`}
        />

        {children}
      </div>
    );
  }
);

export default WallboardEventView;
