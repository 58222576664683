import { FC, useState, useEffect, useCallback } from "react";
import "./Style.css";

interface TPAvatarProps {
  src: string | Blob | ArrayBuffer | null | undefined;
  size: number;
  circular?: boolean;
  enableFullscreen?: boolean;
}

export const TPAvatar: FC<TPAvatarProps> = ({
  src,
  size,
  circular = false,
  enableFullscreen = false,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getImagePrefix = (src: string): string => {
    if (src.startsWith("/9j/")) return "data:image/jpeg;base64,";
    if (src.startsWith("iVBORw0KGgo")) return "data:image/png;base64,";
    if (src.startsWith("R0lGOD")) return "data:image/gif;base64,";
    if (src.startsWith("/9A/")) return "data:image/jpg;base64,";
    return "";
  };

  const generateImageSrc = useCallback((src: Blob | ArrayBuffer) => {
    const blob = src instanceof Blob ? src : new Blob([src]);
    return URL.createObjectURL(blob);
  }, []);

  useEffect(() => {
    if (!src) {
      setImageSrc(null);
      return;
    }

    if (typeof src === "string") {
      const prefix = getImagePrefix(src);
      setImageSrc(`${prefix}${src}`);
    } else {
      const objectURL = generateImageSrc(src);
      setImageSrc(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [src, generateImageSrc]);

  const openModal = useCallback(() => {
    if (enableFullscreen && imageSrc) setIsModalOpen(true);
  }, [enableFullscreen, imageSrc]);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <>
      <div
        className={`tp-avatar ${circular ? "tp-avatar-circular" : "tp-avatar-square"}`}
        style={{ width: size, height: size, backgroundColor: imageSrc ? 'transparent' : 'white' }}
        onClick={openModal}
      >
        {imageSrc ? (
          <img src={imageSrc} alt="avatar" className="tp-avatar-img" />
        ) : (
          <div className="tp-avatar-placeholder"></div>
        )}
      </div>

      {isModalOpen && (
        <div className="tp-avatar-modal" onClick={closeModal}>
          <div className="tp-avatar-modal-content">
            {imageSrc && <img src={imageSrc} alt="avatar-fullscreen" className="tp-avatar-modal-img" />}
          </div>
        </div>
      )}
    </>
  );
};
