import { ReactElement, useEffect, useState } from "react";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";

interface TPFixedTableProps {
  dataGrid: Array<any>;
  columns: Array<any>;
  tableHeight: string;
  keyField: string;
  onRowClicked?: Function;
  searchLabel?: string;
  onSearchChange?: Function;
  sortFunction?: Function;
  highlightOnHover?: boolean;
  selectableRows?: boolean;
  multipleSelection?: boolean;
  onSelectedRowsChange?: Function;
  showTotalRows?: boolean;
  totalRowsPrefix?: string;
  styleSelectedRow?: any;
}

interface TPSortStateType {
  currentSortColumn?: number;
  currentSortAscendingOrder: boolean;
}

const TPFixedTable = ({
  dataGrid,
  columns,
  tableHeight,
  keyField,
  onRowClicked = undefined,
  searchLabel = "",
  onSearchChange = undefined,
  sortFunction = undefined,
  highlightOnHover = false,
  selectableRows = false,
  multipleSelection = false,
  onSelectedRowsChange = undefined,
  showTotalRows = false,
  totalRowsPrefix = "",
  styleSelectedRow = undefined,
}: TPFixedTableProps): ReactElement => {
  const [searchPattern, setSearchPattern] = useState("");

  const initialSortState: TPSortStateType = {
    currentSortColumn: undefined,
    currentSortAscendingOrder: true,
  };

  const [sortState, setSortState] = useState<TPSortStateType>(initialSortState);

  const [selectedRowsState, setSelectedRowsState] = useState<Array<any>>([]);

  const [totalRows, setTotalRows] = useState(0);

  const onSearchPatternChange = (e: any) => {
    let newValue: string = e.target.value;
    setSearchPattern(newValue);
    if (onSearchChange) {
      onSearchChange(newValue);
    }
  };

  const handleRowClick = (row: any, event: any) => {
    if (onRowClicked) {
      onRowClicked(row, event);
    }
  };

  const handleSortClick = (column: any, columnIndex: number) => {
    if (sortFunction) {
      {
        if (column.selector) {
          //todo change state (direction and current field order)
          if (sortState.currentSortColumn === columnIndex) {
            if (sortState.currentSortAscendingOrder) {
              sortFunction(column.selector, "desc");
            } else {
              sortFunction(column.selector, "asc");
            }
            let newSortState: TPSortStateType = { ...sortState };
            newSortState.currentSortAscendingOrder =
              !newSortState.currentSortAscendingOrder;
            setSortState(newSortState);
          } else {
            let newSortState: TPSortStateType = { ...sortState };
            newSortState.currentSortAscendingOrder = true;
            newSortState.currentSortColumn = columnIndex;
            setSortState(newSortState);
            sortFunction(column.selector, "asc");
          }
        }
      }
    }
  };

  const handleSelectRow = (row: any, event: any) => {
    let newSelectedRowsState: Array<any> = [...selectedRowsState];
    let index: number;
    if (multipleSelection) {
      index = -1;
      for (let i = 0; i <= newSelectedRowsState.length - 1; i++) {
        if (newSelectedRowsState[i][keyField] === row[keyField]) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        newSelectedRowsState.push(row);
      } else {
        newSelectedRowsState.splice(index, 1);
      }
    } else {
      newSelectedRowsState = [];
      newSelectedRowsState.push(row);
    }
    setSelectedRowsState(newSelectedRowsState);
    if (onSelectedRowsChange) {
      onSelectedRowsChange(newSelectedRowsState);
    }
  };

  //   const calcSelectedRowCheckboxValue = (row: any) => {
  //     let newSelectedRowsState: Array<any> = [...selectedRowsState];
  //     let index: number;
  //     index = -1;
  //     for (let i: number = 0; i <= newSelectedRowsState.length - 1; i++) {
  //       if (newSelectedRowsState[i][keyField] === row[keyField]) {
  //         index = i;
  //         break;
  //       }
  //     }
  //     if (index != -1) {
  //       return true;
  //     }
  //     return false;
  //   };

  const renderColumnHeader = (column: any, columnIndex: number) => {
    let jsx: any;
    let ascTriangle: any = <>&#9650;</>;
    let descTriangle: any = <>&#9660;</>;

    if (column && column.name) {
      if (sortFunction && column.sortable) {
        jsx = (
          <div onClick={() => handleSortClick(column, columnIndex)}>
            {columnIndex === sortState.currentSortColumn ? (
              <span>
                {column.name}&nbsp;
                {sortState.currentSortAscendingOrder
                  ? ascTriangle
                  : descTriangle}
              </span>
            ) : (
              <span className="tpOrderHover" data-hover="&#9650;">
                {column.name}&nbsp;
              </span>
            )}
          </div>
        );
        return jsx;
      } else {
        jsx = column.name;
        return jsx;
      }
    } else {
      return "";
    }
  };

  const renderColumnData = (column: any, row: any) => {
    let jsx: any;
    if (column && column.cell) {
      jsx = column.cell(row);
      return jsx;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (dataGrid) {
      setTotalRows(dataGrid.length);
    } else {
      setTotalRows(0);
    }
  }, [dataGrid]);

  return (
    <>
      {onSearchChange && (
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end align-items-center gap-3">
              <TPTextBox
                id="IdTextBox"
                onChange={onSearchPatternChange}
                value={searchPattern}
                labelText={searchLabel ? searchLabel : ""}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={`tpFixedHeadTable mt-2 ${highlightOnHover ? "highlightOnHover" : ""}`}
        style={{ maxHeight: tableHeight }}
      >
        <table className="tp-fixed-table">
          <thead
            style={{
              zIndex: 4,
              position: "relative",
              top: "-2px",
              transform: "translate(0px, -3px)",
            }}
          >
            <tr>
              {selectableRows && <th>&nbsp;</th>}
              {columns.map(function (column: any, columIndex: number) {
                return (
                  <th key={"th" + columIndex.toString()}>
                    {renderColumnHeader(column, columIndex)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataGrid.map(function (row: any, index: number) {
              let finalStyleRow: any = null;
              if (styleSelectedRow && row.tpselectedRow) {
                finalStyleRow = styleSelectedRow;
              }
              return (
                <tr
                  key={row[keyField]}
                  onClick={(e: any) => handleRowClick(row, e)}
                  style={finalStyleRow}
                >
                  {selectableRows && (
                    <td key={"tdcheck" + index.toString()}>
                      <TPCheckBox
                        id="IdCheckBox"
                        onChange={(e: any) => handleSelectRow(row, e)}
                        checked={row.tpselectedRow}
                      />
                    </td>
                  )}
                  {columns.map(function (column: any, columnindex: number) {
                    return (
                      <td
                        key={
                          "tddata" +
                          index.toString() +
                          "x" +
                          columnindex.toString()
                        }
                      >
                        {renderColumnData(column, row)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="tpFixedHeadTableshowTotalRows">
        {showTotalRows && (
          <div className="row mt-3">
            <div className="col-12 text-end ">
              <span>
                {totalRowsPrefix ? totalRowsPrefix : ""}
                &nbsp; &nbsp;
                {totalRows}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TPFixedTable;
