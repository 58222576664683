import {
  ICategory,
  IMenu,
} from "@/pages/ConfigurationValues/ConfigurationValuesNew";

interface TPConfigKeyValueItemProps {
  children?: any;
  category: ICategory;
  menu: IMenu;
}

const TPConfigKeyValueItem = ({
  children,
  menu,
}: TPConfigKeyValueItemProps) => {
  return <div id={menu?.id}>{children}</div>;
};

export default TPConfigKeyValueItem;
