export enum TaskTypeAdditionalDataType {
  /**
   * additional data
   */
  ADDITIONAL_DATA = "ADDITIONAL_DATA",
  /**
   * form
   */
  FORM = "FORM",
}

export interface TaskTypeAdditionalDataModel {
  /**
   * identifier
   */
  id: string;
  /**
   * type
   */
  type: TaskTypeAdditionalDataType;
  /**
   * description
   */
  description?: null | string;
  /**
   * order
   */
  order?: null | number;
  /**
   * is mandatory
   */
  isMandatory?: null | boolean;
  /**
   * json
   */
  json?: null | any;
}
