import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { getNameAbbreviation } from "@/helpers/nameAbbreviation";
import { FC } from "react";
import "../assets/Styles.css";

interface QueueAdminUserCheckboxModel {
  name: string;
  email: string;
  selected: boolean;
  active: boolean;
  onChange: Function;
  backgroundColor?: string;
}

const QueueAdminUserCheckbox: FC<QueueAdminUserCheckboxModel> = function ({ name, email, selected, onChange, active, backgroundColor }) {
  return (
    <>
      <div className="queue-user-checkbox">
        <TPCheckBox checked={selected} onChange={onChange} />
        <div style={{ width: '34px', height: '34px' }}>
          <label
            className="queue-user-checkbox-image"
            style={{
              backgroundColor: active ? '#ffd2fb' : '#c0c0c0',
              opacity: active ? '1' : '0.5',
              fontSize: '14px',
              overflow: 'hidden',
              objectFit: 'cover',
              textAlign: 'center',
              lineHeight: '34px'
            }}
          >
          {getNameAbbreviation(name)}
          </label>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            opacity: active ? '1' : '0.5',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflowX: 'scroll',
            scrollbarWidth: 'none'
          }}>
          <b style={{ color: active ? '' : '#a0a0a0' }}>{name}</b>
          <label style={{ color: active ? '' : '#a0a0a0' }}>{email}</label>
        </div>
      </div>
    </>
  )
}

export default QueueAdminUserCheckbox;