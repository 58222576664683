import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { OutboundMailAccountCloneModel } from "@/models/OutboundMailAccount/OutboundMailAccountClone";
import { OutboundMailAccountInputDTO } from "@/models/OutboundMailAccount/OutboundMailAccountInputDTO";
import { OutboundMailAccountViewModel } from "@/models/OutboundMailAccount/OutboundMailAccountModel";

export class OutboundMailAccountService {
  serviceFileName: string = "OutboundMailAccountService.ts";
  public async getAllMailAccounts(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ) {
    const httpclient = new TPHTTPService();
    const url: string = `/mailaccounts`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAllMailAccounts ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAllMailAccounts`);
      throw new Error(`Error ${this.serviceFileName} getAllMailAccounts`);
    }
  }

  public async getMailAccountById(
    MailAccountId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<OutboundMailAccountViewModel> {
    const httpclient = new TPHTTPService();
    const url: string = `/mailaccounts/${MailAccountId}`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getMailAccountById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getMailAccountById`);
      throw new Error(`Error ${this.serviceFileName} getMailAccountById`);
    }
  }

  public async insertMailAccount(
    inputDTO: OutboundMailAccountInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mailaccounts";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertMailAccount ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertMailAccount`);
      throw new Error(`Error ${this.serviceFileName} insertMailAccount`);
    }
  }

  public async updateMailAccount(
    inputDTO: OutboundMailAccountInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/mailaccounts";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateRole ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateRole`);
      throw new Error(`Error ${this.serviceFileName} updateRole`);
    }
  }

  public async deleteMailAccountById(
    idAccount: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    const url: string = `/mailaccounts/${idAccount}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteMailAccountById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteMailAccountById`);
      throw new Error(`Error ${this.serviceFileName} deleteMailAccountById`);
    }
  }
  
  public async clone(
    element: OutboundMailAccountCloneModel,
    showPositiveMessage: boolean = true,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200, 404],
  ): Promise<OutboundMailAccountCloneModel> {
    const { postData } = new TPHTTPService();
    try {
      const response = await postData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/mailaccounts/clone`,
        element,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders],
      );
      return response ? element : response;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} clone ex`, TPLogType.ERROR, error);
      console.error(`Error ${this.serviceFileName} clone`);
      throw new Error(`Error ${this.serviceFileName} clone`);
    }
  }
}
