import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { useReducer, useState } from "react";
import { TPActiveOptions } from "../../models/Global/TPGlobalEnums";



type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type commandType = {
    type: commandsEnum;
    payload: any;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

export const useStatusSearchIncomingMail = () => {
    const resourceSet: string = "SearchIncomingMailComponent";

    const [loading, setLoading] = useState(false);

    const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
    function doCommand(prevState: AdminStateType, command: commandType) {
        switch (command.type) {
            case commandsEnum.set_filterIsLoaded:
                let newStateFilter: AdminStateType;
                newStateFilter = { ...prevState };
                newStateFilter.filterIsLoaded = true;
                return newStateFilter;

            case commandsEnum.setup_grid_columns:
                let newStateColumns: AdminStateType = setTableData(prevState);
                newStateColumns.columnsAreLoaded = true;
                return newStateColumns;

            case commandsEnum.reload_grid:
                let newStateGrid: AdminStateType;
                newStateGrid = { ...prevState };
                newStateGrid.gridData = command.payload;
                return newStateGrid;

            case commandsEnum.change_selectedFilter:
                let newStateChangeFilter: AdminStateType;
                newStateChangeFilter = { ...prevState };
                newStateChangeFilter.selectedFilter = command.payload;
                return newStateChangeFilter;

            default:
                return prevState;
        }
    }


    const setTableData = (prevState: AdminStateType): AdminStateType => {
        let newState: AdminStateType = { ...prevState };
        newState.gridColumns = [prevState.gridData];
        return newState;
    };


    return {
        dispatchCommand,
        adminState,
        resourceSet,
        setLoading,
        loading
    };
}


