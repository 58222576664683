import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  WallboardEnum as e,
  QueueWallboardModel,
  WallboardEvents,
  WallboardModel,
  WallboardListContainerProps,
} from "@/models/Wallboard/WallboardModel";
import TableNotFound from "@/modules/core/components/dynamic-table/TableNotFound";
import WallboardList from "./WallboardList";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    flex: "1 1 0%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    overflowY: "auto",
  } as CSSProperties,
  queueContainer: {
    display: "flex",
    flexFlow: "wrap",
    boxSizing: "border-box",
  } as CSSProperties,
  queue: {
    width: "50%",
    height: "max-content",
    maxHeight: "830px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflowY: "hidden",
  } as CSSProperties,
  containerTitle: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    placeContent: "center space-between",
    alignItems: "center",
    gap: "10px",
  } as CSSProperties,
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "space-between",
    gap: "10px",
    padding: "10px 20px",
  } as CSSProperties,
  cell: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  } as CSSProperties,
  button: {
    width: "30px",
    height: "30px",
    flex: "1 1 30px",
    boxSizing: "border-box",
    maxWidth: "30px",
    borderBottom: "0",
  } as CSSProperties,
  sectionTitle: {
    padding: "0 10px",
    boxSizing: "border-box",
    display: "flex",
    flex: "1 1 0%",
    flexDirection: "row",
    placeContent: "center flex-start",
    alignItems: "center",
    gap: "10px",
    border: "2px solid #c7c8c9",
    borderRadius: "5px",
  } as CSSProperties,
  title: {
    margin: "0",
    flex: "1 1 0%",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard team view
 */
const WallboardListContainer = forwardRef(
  (
    {
      id,
      key,
      style,
      m,
      data,
      noDataMessage,
      queuesSelected = [],
      type = "group",
    }: WallboardListContainerProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * queues
     */
    const [queues, setQueues] = useState<Array<QueueWallboardModel>>([]);
    /**
     * data source
     */
    const [dataSource, setDataSource] = useState<Array<WallboardModel>>([]);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as WallboardEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {}, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * on change data
     */
    useEffect(() => {
      const grouped = data.reduce(
        (acc, record) => {
          if (
            queuesSelected.length === 0 &&
            queuesSelected.find((q) => q.key === `${record.queueId}`) &&
            !acc[record.queueId]
          ) {
            acc[record.queueId] = {
              queueId: record.queueId,
              queueName: record.queueName,
              wallboards: [],
            };
          }
          if (acc[record.queueId]) {
            acc[record.queueId].wallboards.push(record);
          }
          return acc;
        },
        (queuesSelected?.length > 0
          ? Object.fromEntries([
              ...queuesSelected.map((q) => [
                q.key,
                {
                  queueId: q.key,
                  queueName: q.value,
                  wallboards: [],
                },
              ]),
            ])
          : {}) as Record<number, QueueWallboardModel>
      );

      const result = Object.values(grouped);

      if (result.length === 1) {
        setDataSource(data);
        setQueues([]);
      } else if (result.length > 1) {
        setQueues(result);
        setDataSource([]);
      } else {
        setDataSource([]);
        setQueues([]);
      }
    }, [data, queuesSelected]);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={id ?? e.nameListContainer}
        key={key ?? e.nameListContainer}
        style={{
          ...styles.container,
          ...style,
        }}
      >
        {queues.length === 0 && dataSource.length === 0 && (
          <TableNotFound
            text=""
            newOneLabel=""
            noResultsLabel={noDataMessage}
          />
        )}

        {queues.length > 0 && (
          <div style={styles.queueContainer}>
            {queues.map((item, index) => (
              <div
                id={`${e.nameListContainer}Accordion`}
                key={`${e.nameListContainer}Accordion`}
                className="accordion"
                style={{ ...styles.queue, maxHeight: "720px" }}
              >
                <div
                  id={`AccordionItem${index}`}
                  key={`AccordionItem${index}`}
                  className="accordion-item"
                  style={styles.column}
                >
                  <div
                    id={`AccordionHeader${index}`}
                    key={`AccordionHeader${index}`}
                    style={styles.containerTitle}
                    className="accordion-header"
                  >
                    <TPButton
                      id={`AccordionButtonOption${index}`}
                      key={`AccordionButtonOption${index}`}
                      type={TPButtonTypes.icon}
                      icon={TPIconTypes.mdDragIndicator}
                      tooltip={``}
                      onClick={() => {}}
                    />

                    <div
                      id={`AccordionSectionTitle${index}`}
                      key={`AccordionSectionTitle${index}`}
                      style={styles.sectionTitle}
                    >
                      <p
                        id={`AccordionTitle${index}`}
                        key={`AccordionTitle${index}`}
                        style={styles.title}
                      >{`${
                        type === "group"
                          ? m.AccordionGroupLabel
                          : type === "queue"
                            ? m.SectionFilterSelectQueueLabel
                            : "undefined"
                      }: ${item.queueName ?? ""}`}</p>

                      <button
                        id={`AccordionIconButton${index}`}
                        key={`AccordionIconButton${index}`}
                        style={styles.button}
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#AccordionCollapse${index}`}
                        aria-expanded={`true`}
                        aria-controls={`AccordionCollapse${index}`}
                      ></button>
                    </div>
                  </div>

                  <div
                    id={`AccordionCollapse${index}`}
                    key={`AccordionCollapse${index}`}
                    className={`accordion-collapse collapse show`}
                    aria-labelledby={`AccordionItem${index}`}
                    data-bs-parent={`#${e.nameListContainer}Accordion`}
                  >
                    {item.wallboards.length === 0 ? (
                      <TableNotFound
                        text=""
                        newOneLabel=""
                        noResultsLabel={`${m.MessageNothingToSee} ${
                          type === "group"
                            ? m.AccordionGroupLabel
                            : type === "queue"
                              ? m.SectionFilterSelectQueueLabel
                              : "undefined"
                        }`}
                      />
                    ) : (
                      <WallboardList
                        type="simple"
                        m={m}
                        data={item.wallboards}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {dataSource.length > 0 && <WallboardList m={m} data={dataSource} />}
      </div>
    );
  }
);

export default WallboardListContainer;
