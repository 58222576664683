import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useStatusDistributionListAdmin } from "./useStatusGroupDashboard";
import { TPKeyValue } from "../../helpers/TPKeyValue";



type AppProviderDistributionListAdmin = {
    children: ReactNode;
};

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

class model {
    status: Array<any> = new Array<any>;
    resourceSet: string = "";   
    loading: boolean = false;  
    taskToReassign: Array<modelTPKeyValue> = new Array<modelTPKeyValue>;
    groupSelected: TPKeyValue = {key:"", value:""}
;
    setGroupSelected: (value: TPKeyValue) => void = (value: TPKeyValue) => void {};
    setTaskToReassign: (value: Array<TPKeyValue>) => void = (value: Array<TPKeyValue>) => void {};
    setLoading: (data: boolean) => void = (data: boolean) => void {};   
}

type modelContext = {
    status: Array<any>;
    resourceSet: string;
    loading: boolean,
    taskToReassign: Array<modelTPKeyValue>,
    groupSelected: TPKeyValue ,
    setGroupSelected: (value: TPKeyValue) => void, 
    setTaskToReassign: (value: Array<TPKeyValue>) => void;
    setLoading: (data: boolean) => void;
}

export const GroupDashboardContext = createContext<modelContext>(
    new model());

export const AppProviderGroupDashboard: React.FC<AppProviderDistributionListAdmin> = ({ children }) => {

    const {
        status,
        resourceSet,
        loading,
        setLoading,
        setTaskToReassign,
        taskToReassign,
        groupSelected,
        setGroupSelected
       
    } = useStatusDistributionListAdmin();



    return (
        <TPLoadingOverlay active={loading}>
            <GroupDashboardContext.Provider value={{
                status,
                resourceSet,
                loading,
                setLoading,
                setTaskToReassign,
                taskToReassign,
                groupSelected,
                setGroupSelected
            }}>
                {children}
            </GroupDashboardContext.Provider>
        </TPLoadingOverlay>
    );
};


export const useAppGroupDashboard = () => {
    return useContext(GroupDashboardContext);
}