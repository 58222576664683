import { TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  LoadHistoryResponse,
  RecordStatus,
} from "@/models/LoadHistory/load-history.model";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { useToast } from "@/modules/core/design-system/toasts/ToastContext";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TableDateItem from "@/modules/core/utils/table-micro-components/TableDateItem";
import {
  GetHistoryProps,
  GetUserRecordsProps,
  LoadHistoryService,
} from "@/services/LoadHistoryService";
import { ReactElement, useEffect, useState } from "react";
import { LoadHistoryTableLabels } from "./load-history.model";

export interface SuccessfulTableTableProps {
  tableLabels: LoadHistoryTableLabels;
  setIsLoadingScreen: Function;
  setUserRecords: Function;
  callBackCommands: Function;
}

interface SuccessfulTableData {
  id: string;
  project: string;
  structure: string;
  uploadedBy: string;
  uploadedTime: string;
  resolved: number;
  pending: number;
  total: number;
  active: boolean;
}

function SuccessfulTable({
  tableLabels,
  setIsLoadingScreen,
  setUserRecords,
  callBackCommands,
}: SuccessfulTableTableProps): ReactElement {
  const { showToast } = useToast();
  const component: string = "SuccessfulTable";

  //logic useState variables
  const [firstReRendering, setFirstReRendering] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SuccessfulTableData[]>([]);
  const [projects, setProjects] = useState<TPKeyValue[]>([]);
  const [structures, setStructures] = useState<TPKeyValue[]>([]);
  const [projectSelected, setProjectSelected] = useState<string | null>(null);
  const [structureSelected, setStructureSelected] = useState<string | null>(
    null
  );

  const successfulFilters: AdditionalFilter[] = [
    {
      key: "project",
      data: projects,
      label: tableLabels.project,
      selectedValue: projectSelected,
      placeholder: tableLabels.projectPlaceholder,
      width: 254,
      onChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
        setProjectSelected(event.target.value),
    },
    {
      key: "structure",
      data: structures,
      label: tableLabels.structure,
      selectedValue: structureSelected,
      placeholder: tableLabels.structurePlaceholder,
      width: 254,
      onChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
        setStructureSelected(event.target.value),
    },
  ];

  const columnsNames: CustomColumnNames<SuccessfulTableData> = {
    project: tableLabels.project,
    structure: tableLabels.structureName,
    uploadedBy: tableLabels.uploaded,
    uploadedTime: tableLabels.date,
    resolved: tableLabels.resolved,
    pending: tableLabels.pending,
    total: tableLabels.total,
    active: tableLabels.active,
  };

  const getRecordsBy = async (id: string) => {
    const loadService = new LoadHistoryService();

    const params: GetUserRecordsProps = {
      eventLoadId: id,
      recordStatus: RecordStatus.resolved,
    };

    try {
      setIsLoadingScreen(true);
      return await loadService.getUserRecordsBy(params);
    } catch (error) {
      setIsLoadingScreen(false);
      throwAlertError(error, component, "getRecordsBy");
      return [];
    }
  };

  const validateResolvedRecords = (id: string) => {
    getRecordsBy(id).then((response) => {
      if (response.length > 0) {
        setUserRecords(response);
        goToResolvedPage(id);
      } else {
        showToast(tableLabels.noRecords, TPToastTypes.error);
      }
      setIsLoadingScreen(false);
    });
  };

  const goToResolvedPage = (id: string) => {
    let command: any = {
      command: "update",
      recordId: id,
    };
    callBackCommands(command);
  };

  const customColumns: ColumnStyles<SuccessfulTableData> = {
    resolved: ({ value, item }) => (
      <TableChip
        value={true}
        onLabel={`${value}`}
        offLabel={`${value}`}
        justify="flex-start"
        onClick={() => value !== 0 && validateResolvedRecords(item.id)}
        underline
        notBoolean
      />
    ),
    pending: ({ value }) => (
      <TableChip
        value={false}
        onLabel={`${value}`}
        offLabel={`${value}`}
        justify="flex-start"
        underline
        notBoolean
      />
    ),
    active: ({ value }) => (
      <TableChip
        value={value}
        onLabel={tableLabels.yes}
        offLabel={tableLabels.no}
        justify="flex-start"
      />
    ),
    uploadedTime: ({ value }) => <TableDateItem value={value} />,
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) setTableResponse(true);
  };

  const getTableData = async (): Promise<LoadHistoryResponse[]> => {
    const loadService = new LoadHistoryService();

    const params: GetHistoryProps = {
      projectId: projectSelected,
      structureId: structureSelected,
    };

    try {
      setIsLoadingScreen(true);
      return await loadService.getHistoryLoadData(params);
    } catch (error) {
      setIsLoadingScreen(false);
      throwAlertError(error, component, "getTableData");
      return [];
    }
  };

  const setResponseToTableItem = (
    response: LoadHistoryResponse[]
  ): SuccessfulTableData[] => {
    return response.map((item) => {
      return {
        id: item.eventLoadId,
        project: item.projectName,
        structure: item.structureDescription,
        uploadedBy: item.userLoadName,
        uploadedTime: item.insertDateEventLoad,
        resolved: item.resolved,
        pending: item.pending,
        total: item.total,
        active: item.isActiveEventLoad,
      };
    });
  };

  const setProjectsAndStructures = (response: LoadHistoryResponse[]) => {
    const projectMap = new Map<string, string>();
    const structureMap = new Map<string, string>();

    response.forEach((item) => {
      projectMap.set(item.projectId, item.projectName);
      structureMap.set(item.eventLoadStructureId, item.structureDescription);
    });

    const allProjects = Array.from(projectMap, ([key, value]) => ({
      key,
      value,
    }));

    const allStructures = Array.from(structureMap, ([key, value]) => ({
      key,
      value,
    }));

    const resetValue: TPKeyValue = {
      key: "",
      value: "--",
    };

    setProjects([resetValue, ...allProjects]);
    setStructures([resetValue, ...allStructures]);
  };

  const setTableResponse = (isRefresh: boolean = false) => {
    getTableData().then((res) => {
      if (res && res.length > 0) {
        setTableData(setResponseToTableItem(res));
        !isRefresh && setProjectsAndStructures(res);
      }
      setIsLoadingScreen(false);
    });
  };

  useEffect(() => {
    if (!firstReRendering) {
      setFirstReRendering(true);
      setTableResponse();
    }
  }, [tableData]);

  useEffect(() => {
    setTableResponse(true);
  }, [projectSelected, structureSelected]);

  return (
    <DynamicTable
      id="successful"
      data={tableData}
      searchPosition="right"
      hiddenColumns={["id"]}
      additionalFilters={successfulFilters}
      columnStyles={customColumns}
      columnNames={columnsNames}
      noDataMessage={tableLabels.noHistories}
      onIconClicked={handleIconClick}
    />
  );
}

export default SuccessfulTable;
