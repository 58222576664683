import { TPKeyValue } from "@/helpers/TPKeyValue";
import { AdditionalDataViewModel } from "../AdditionalData/AdditionalDataModels";

export interface LocalizedScriptName {
  languageId: string;
  localizedValue: string;
  order: number | null;
}

export interface ScriptLanguage {
  id: string;
  name: string;
}

export interface ScriptHeader {
  id: string;
  name: string;
  localizedName: LocalizedScriptName[];
  language: ScriptLanguage;
  isFavorite: boolean;
  isActive: boolean;
}

export interface CloneScriptModel {
  scriptId_Base: string;
  id: string;
  name: string;
  localizedNames: LocalizedScriptName[];
  languageId: string;
}

export interface ScriptNextStepUpdateModel {
  scriptStepId: string;
  defaultNextStep: number;
}

export interface ScriptHeaderRequestModel {
  id: string;
  name: string;
  localizedNames: LocalizedScriptName[];
  languageId: string;
  isFavorite: boolean;
  isActive: boolean;
}

export interface ScriptAdditionalDataLogic {
  value: string;
  nextStepId: string;
  key_SSDA: string;
}

export interface ScriptAdditionalData {
  id: string;
  scriptStepId: string;
  additionalDataId: string;
  label: string;
  isWhiteSpace: boolean;
  order: number;
  isSaveCase: boolean;
  isMandatory: boolean;
  isAddNewLine: boolean;
  isLogicDetermination: boolean;
  logics: ScriptAdditionalDataLogic[];
}

export interface CreateScriptStepModel {
  scriptId: string;
  order: number;
  description: string;
  helpText: string;
}

export interface UpdateScriptStepModel extends CreateScriptStepModel {
  id: string;
  defaultNextStepId: string;
}

export interface ScriptStep {
  id: string;
  scriptId: string;
  scriptName: string;
  order: number;
  description: string;
  additionalData: ScriptAdditionalData[];
  defaultNextStepId: string;
  helpText: string;
}

export interface ScriptNextStepRequest {
  scriptStepId: string;
  defaultNextStep: string;
}

export interface ScriptFavoriteUpdateModel {
  scriptId: string;
  isFavorite: boolean;
}

export enum ScriptsMode {
  new,
  update,
  clone,
}

export enum ScriptsTab {
  general_info,
  steps,
  preview,
  diagram,
}

export enum ScriptAvailableDataTypes {
  TEXTBOX = "TEXTBOX", //
  ATTACHMENT = "ATTACHMENT", //
  DATE = "DATE", //
  EMAIL = "EMAIL", //
  LABEL = "LABEL", //
  LINK = "LINK", //
  NUMERIC = "NUMERIC", //
  TEXTAREA = "TEXTAREA", //
  PHONE = "PHONE", //
  TREE = "TREE",
  LISTVALUE = "LISTVALUE",
  YESNO = "YESNO", //
  INTEGERLIST = "INTEGERSLIST",
}

export interface ScriptLogicStep {
  key: any;
  nextStep: number;
  value: any;
}
