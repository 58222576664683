import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import "./Style.css";
import { CSSProperties } from "styled-components";

interface Properties {
  icon?: TPIconTypes;
  label?: string | number | JSX.Element;
  backgroundColor?: string;
  accentColor?: string;
  iconColor?: string;
  style?: CSSProperties;
  iconSize?: string;
}

export const TPChip: FC<Properties> = function ({
  icon,
  label,
  backgroundColor,
  iconColor,
  accentColor,
  style,
  iconSize
}) {
  return (
    <div
      className="tp-chip"
      style={{
        ...style,
        backgroundColor: backgroundColor || "#f0f0f0",
        color: accentColor
      }}
    >
      {icon && (
        <TPIcon iconType={icon} style={{ color: iconColor || "#a0a0a0", fontSize: iconSize }} />
      )}
      {label}
    </div>
  );
};
