import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type SchedulesInputDTO = {
  id: string;
  description: string;
  initialHourMonday: number;
  endHourMonday: number;
  initialHourTuesday: number;
  endHourTuesday: number;
  initialHourWednesday: number;
  endHourWednesday: number;
  initialHourThursday: number;
  endHourThursday: number;
  initialHourFriday: number;
  endHourFriday: number;
  initialHourSaturday: number;
  endHourSaturday: number;
  initialHourSunday: number;
  endHourSunday: number;
  initialHourHoliday: number;
  endHourHoliday: number;
  initialHourHalfHoliday: number;
  endHourHalfHoliday: number;
  isActive: boolean;
  workMonday: boolean;
  workTuesday: boolean;
  workWednesday: boolean;
  workThursday: boolean;
  workFriday: boolean;
  workSaturday: boolean;
  workSunday: boolean;
  workHoliday: boolean;
  workHalfHoliday: boolean;
  otherLocalizedValues: Array<RecordLocalizedModel>;
};

export class SchedulesInputDTOValidator extends Validator<SchedulesInputDTO> {
  constructor() {
    super();

    const resourceSet = "ScheduleInsertUpdateComponent";

    try {
      //id
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");
      //description
      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
      //otherLocalizedValues
      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 50;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
      //monday
      this.ruleFor("initialHourMonday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourMonday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //tuesday
      this.ruleFor("initialHourTuesday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourTuesday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Wednesday
      this.ruleFor("initialHourWednesday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourWednesday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Thursday
      this.ruleFor("initialHourThursday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourThursday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Friday
      this.ruleFor("initialHourFriday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourFriday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Saturday
      this.ruleFor("initialHourSaturday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourSaturday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Sunday
      this.ruleFor("initialHourSunday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourSunday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //Holiday
      this.ruleFor("initialHourHoliday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourHoliday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      //HalfHoliday
      this.ruleFor("initialHourHalfHoliday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
      this.ruleFor("endHourHalfHoliday")
        .must((value) => {
          return value >= 0 && value <= 24;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InvalidValue");
    } catch (error) {}
  }
}
