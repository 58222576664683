import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { FC, ReactElement, useEffect, useReducer, useState } from "react";
import EmailTemplatesAdminDetail from "./EmailTemplatesAdminDetail";
import EmailTemplatesInsertUpdate from "./EmailTemplatesInsertUpdate";
import EmailTemplatesAttachment from "./EmailTemplateAttachment";

type EmailTemplateContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  description?: string;
  callBackResult: Function;
  tabId: string;
  languageId: string;
};

enum asideMenuItemTypes {
  "general" = 0,
  "details" = 1,
  "previews" = 2,
  "clone" = 3,
  "attachment" = 4,
}

enum commandsEnum {
  "create_general" = 0,
  "create_details_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_details" = 3,
  "set_preview" = 4,
  "set_active_clone" = 5,
  "set_attachment" = 6,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};
interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

const EmailTemplateContainerInsertUpdate: FC<
  EmailTemplateContainerInsertUpdateProps
> = ({
  mode,
  recordId,
  callBackResult,
  tabId,
  languageId,
  description,
}): ReactElement => {
  const resourceSet: string = "EmailTemplateContainerInsertUpdate";
  //control state
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleDetails, setTitleDetails] = useState("");
  const [titleCloneEmail, setTitleCloneEmail] = useState("");
  const [exitLabel, setExitLabel] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [realRecordDescription, setRealRecordDescription] = useState("");
  //make a state for the preview button
  const [previewLabel, setPreviewLabel] = useState<string>("Preview");
  const [attachmentLabel, setAttachmentLabel] = useState<string>("");
  const [isPreview, setIsPreview] = useState<boolean>(true);

  const [caseNumber, setCaseNumber] = useState("");
  const [storeResponse, setStoreResponse] = useState("");

  const handlePreview = (val: any) => setIsPreview(val);

  // Function to handle changes in the number box
  const handleCaseNumber = (value: any) => {
    setCaseNumber(value);
  };

  const handlePreviewAPI = () => {
    if (caseNumber) {
      const fetchData = async () => {
        let taskService = new TaskService();
        let expectedCodes: Array<number> = [200];
        const data = {
          EmailTemplateId: recordId,
          LanguageId: languageId,
          CaseId: Number(caseNumber),
          TaskId: null,
          UserSessionGuid: TPGlobal.currentUserGuid,
          RemoveDecisionTaskArea: false,
        };
        try {
          let responseRequest = await taskService.renderEmailTemplate(
            data,
            false,
            true,
            expectedCodes
          );
          setStoreResponse(responseRequest);
        } catch (error) {
          console.error("Error:", error);
        }
      };
      fetchData();
    }
  };

  const loadResources = async () => {
    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle"
      )
    );
    setTitleDetails(await TPI18N.GetText(resourceSet, "DetailsTitle"));
    setTitleCloneEmail(await TPI18N.GetText(resourceSet, "CloneEmailTitle"));
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    setAttachmentLabel(await TPI18N.GetText(resourceSet, "Attachments"));
    //flag isReady
    setIsReady(true);
  };
  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create holidays content
      let command: commandType = {
        type: commandsEnum.create_details_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }

    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: realRecordId });
      return;
    }
    if (theCommand.result === "save_general_update") {
      callBackResult({ result: "OK", recordId: realRecordId });
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "cancel_general", recordId: realRecordId });
      return;
    }

    if (theCommand.result === "clone_email_success") {
      callBackResult({ result: "cancel_general", recordId: realRecordId });
      callBackResult({
        result: "clone_email_success",
        recordId: realRecordId,
        languageId,
      });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    //     let currentActive: asideMenuItemTypes;
    //     if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
    //       currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
    //         .itemType;
    //       if (currentActive === asideMenuItemTypes.general) {
    //         //already on general
    //         return;
    //       }
    //     }
    //     let command: commandType = {
    //       type: commandsEnum.set_active_general,
    //       payload: null,
    //     };
    //     dispatchCommand(command);
    //     return;
    let newStateHome: Array<asideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 0;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    handlePreview(true);
    dispatchCommand(command);
    return newStateHome;
  };

  const onPreviewAsideItemClick = () => {
    let newStateHome: Array<asideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 2;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_preview,
      payload: null,
    };
    handlePreview(false);
    dispatchCommand(command);
    return;
  };

  const onAttachmentAsideItemClick = () => {
    let newStateHome: Array<asideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 2;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_attachment,
      payload: null,
    };
    handlePreview(false);
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_details,
      payload: null,
    };
    dispatchCommand(command);
  };

  const onCloneAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_clone,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "cancel_general", recordId: realRecordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome = [...prevState];
    let verticalElement = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    let verticalElementPreview = {
      itemLabel: previewLabel,
      itemType: asideMenuItemTypes.previews,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onPreviewAsideItemClick,
    };
    if (mode == "Update") {
      let verticalElement2 = {
        itemLabel: titleDetails,
        itemType: asideMenuItemTypes.details,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2, verticalElementPreview);
    }
    if (mode === "CloneEmail") {
      newStateHome[0].isActive = false;
      let verticalElement2 = {
        itemLabel: titleCloneEmail,
        itemType: asideMenuItemTypes.clone,
        isActive: true,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onCloneAsideItemClick,
      };
      newStateHome.push(verticalElement2, verticalElementPreview);
    }
    let verticalElementAttachment = {
      itemLabel: attachmentLabel,
      itemType: asideMenuItemTypes.attachment,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onAttachmentAsideItemClick,
    };
    newStateHome.push(verticalElementAttachment);
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommandAndChangeMode = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleDetails,
      itemType: asideMenuItemTypes.details,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);
  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_details_and_change_mode:
        const newRecordId: string = command.payload.recordId;
        const newRecordDescription: string = command.payload.recordDescription;
        setRealRecordId(newRecordId);
        setRealRecordDescription(newRecordDescription);
        return realCreateDetailsContentCommandAndChangeMode(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_details:
        return setActiveContent(prevState, asideMenuItemTypes.details);
      case commandsEnum.set_active_clone:
        return setActiveContent(prevState, asideMenuItemTypes.clone);
      case commandsEnum.set_preview:
        return setActiveContent(prevState, asideMenuItemTypes.previews);
      case commandsEnum.set_attachment:
        return setActiveContent(prevState, asideMenuItemTypes.attachment);
      // No state change required, just return the previous state
    }
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  return (
    <>
      <div className="row">
        <div className="col-10">
          <ul>
            {asideMenuState.map(function (item, index) {
              let jsxFragment: any;
              let visibilityStyle: any;
              if (item.isActive) {
                visibilityStyle = { display: "block" };
              } else {
                visibilityStyle = { display: "none" };
              }
              switch (item.itemType) {
                case asideMenuItemTypes.general:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <EmailTemplatesInsertUpdate
                          callBackResult={handleCallBackCommands}
                          mode={mode}
                          recordId={realRecordId}
                          languageId={languageId}
                          preview={isPreview}
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.details:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <EmailTemplatesAdminDetail
                          languageId={languageId}
                          recordInput={realRecordId}
                          recordDescription={realRecordDescription}
                          callBackComands={handleCallBackCommands}
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.previews:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <EmailTemplatesInsertUpdate
                          callBackResult={handleCallBackCommands}
                          mode={mode}
                          recordId={realRecordId}
                          languageId={languageId}
                          preview={isPreview}
                          caseNumber={caseNumber}
                          storeResponse={storeResponse}
                          handleCaseNumber={handleCaseNumber}
                          handlePreviewAPI={handlePreviewAPI}
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.attachment:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <EmailTemplatesAttachment     
                          recordId={realRecordId}
                        />
                      </div>
                    </li>
                  );
                  break;
                default:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <span>error this should not be here</span>
                      </div>
                    </li>
                  );
                  break;
              }
              return jsxFragment;
            })}
          </ul>
        </div>
        <RightVerticalMenuContainer className="col-2 mt-4">
          <RightVerticalMenuWrapper>
            <RightVerticalMenuUl>
              {asideMenuState.map(function (item, index) {
                //render aside menu
                let jsxFragment: any;
                let visibilityStyle: any;
                if (item.isVisible) {
                  visibilityStyle = {
                    display: "block",
                    borderBottom: "1px solid #e8e8e8",
                  };
                } else {
                  visibilityStyle = {
                    display: "none",
                    borderBottom: "1px solid #e8e8e8",
                  };
                }
                switch (item.itemType) {
                  case asideMenuItemTypes.general:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.details:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.previews:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.attachment:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.clone:
                    jsxFragment = <></>;
                    break;
                  default:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span>error this should not be here</span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                }
                return jsxFragment;
              })}
              <RightVerticalMenuLi
                key={"asidemenuitem" + asideMenuState.length.toString()}
              >
                <span onClick={() => handleOnExitClick()}>{exitLabel}</span>
              </RightVerticalMenuLi>
            </RightVerticalMenuUl>
          </RightVerticalMenuWrapper>
        </RightVerticalMenuContainer>
      </div>
    </>
  );
};

export default EmailTemplateContainerInsertUpdate;
