import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { ReactElement, FC } from "react";

export type TPModalTaskStatusConventionsStateType = {
  isShown: boolean;
  title: string;
  yesLabel: string;
  callBackFunction: Function;
};

type TPModalTaskStatusConventionsProps = {
  modalState: TPModalTaskStatusConventionsStateType;
};

const TPModalTaskStatusConventions: FC<TPModalTaskStatusConventionsProps> = ({
  modalState,
}): ReactElement => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  const resourceSet: string = "ModalTaskStatusConventionComponent";

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{modalState.title}</h5>
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => modalState.callBackFunction()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: "18px" }}>
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId={"Solved"}
                    />
                  </span>
                </div>
                <div className="col-8">
                  <TPIcon
                    iconType={TPIconTypes.checkCircle}
                    style={{ color: "#69c169", fontSize: "18px" }}
                    inline={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: "18px" }}>
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId={"Pending"}
                    />
                  </span>
                </div>
                <div className="col-8">
                  <TPIcon
                    iconType={TPIconTypes.watchLater}
                    style={{ color: "#69c169", fontSize: "18px" }}
                    inline={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: "18px" }}>
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId={"Expired"}
                    />
                  </span>
                </div>
                <div className="col-8">
                  <TPIcon
                    iconType={TPIconTypes.warning}
                    style={{ color: "red", fontSize: "18px" }}
                    inline={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: "18px" }}>
                    <TPLanguage
                      resourceSet={resourceSet}
                      resourceId={"DateLag"}
                    />
                  </span>
                </div>
                <div className="col-8">
                  <TPIcon
                    iconType={TPIconTypes.hourglassTop}
                    style={{ color: "#e57348", fontSize: "18px" }}
                    inline={true}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <TPButton
                type={TPButtonTypes.primary}
                onClick={() => modalState.callBackFunction()}
              >
                {modalState.yesLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalTaskStatusConventions;
