import { ScriptAdditionalData, ScriptAdditionalDataLogic, ScriptAvailableDataTypes, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { FC, useEffect, useState } from "react";
import ScriptYesNoLogicItem from "./LogicDeterminationItems/ScriptYesNoLogic";
import ScriptOpenTextLogic from "./LogicDeterminationItems/ScriptOpenTextLogic";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ScriptAttachmentLogicItem } from "./LogicDeterminationItems/ScriptAttachmentLogic";
import { ScriptDateLogicItem } from "./LogicDeterminationItems/ScriptDateLogic";
import ScriptEmailLogicItem from "./LogicDeterminationItems/ScriptEmailLogic";
import ScriptNumberLogicItem from "./LogicDeterminationItems/ScriptNumberLogic";
import ScriptPhoneLogicItem from "./LogicDeterminationItems/ScriptPhoneLogic";
import ScriptLinkLogicItem from "./LogicDeterminationItems/ScriptLinkLogic";
import ScriptTreeLogicItem from "./LogicDeterminationItems/ScriptTreeLogic";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { areEqual } from "@/helpers/ValidateChanges";
import ScriptListLogic from "./LogicDeterminationItems/ScriptListValueLogic";
import ScriptListValueLogic from "./LogicDeterminationItems/ScriptListValueLogic";
import ScriptIntegerLogic from "./LogicDeterminationItems/ScriptIntegerLogic";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface LogicDeterminationContainerProperties {
  title: string;
  data: ScriptAdditionalData;
  currentOrder: number;
  availableNextSteps: ScriptStep[];
  onLogicChange?: (logicSteps: ScriptAdditionalDataLogic[]) => void;
  chosenData?: AdditionalDataViewModel;
}

export const LogicDeterminationContainer: FC<LogicDeterminationContainerProperties> = function ({
  title,
  data,
  currentOrder,
  availableNextSteps,
  onLogicChange,
  chosenData
}) {
  const [logicSteps, setLogicSteps] = useState<ScriptAdditionalDataLogic[]>(data.logics || []);

  const addLogicStep = function (step: ScriptAdditionalDataLogic) {
    if (logicSteps.find(l => areEqual(l.value, step.value))) return;
    const logicStepsCopy = logicSteps;
    logicStepsCopy.push({ ...step });
    setLogicSteps([...logicStepsCopy]);
  }

  const deleteLogicStep = function (step: ScriptAdditionalDataLogic) {
    if (!logicSteps.find(l => l.value == step.value)) return;
    const logicStepsCopy = logicSteps;
    logicStepsCopy.splice(logicStepsCopy.indexOf(step), 1);
    setLogicSteps([...logicStepsCopy]);
  }

  useEffect(() => {
    onLogicChange && onLogicChange(logicSteps);
  }, [logicSteps])

  return (
    <div id="logic-determination-container" className="logic-determination-container">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <label>{extractTextFromHTML(title)}<b style={{ color: 'red' }}>*</b></label>
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.YESNO &&
          <ScriptYesNoLogicItem
          logic={data.logics}
            availableNextSteps={availableNextSteps}
            onCompletion={(logic) => setLogicSteps(logic)} />}
        {(chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.TEXTBOX ||
          chosenData?.additionalDataTypeId.includes(ScriptAvailableDataTypes.TEXTBOX)) &&
          <ScriptOpenTextLogic
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {/* {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.ATTACHMENT &&
          <ScriptAttachmentLogicItem
            availableNextSteps={availableNextSteps}
            onLogicAdd={(logic) => addLogicStep(logic)}
            logic={data.logics}
          />} */}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.DATE &&
          <ScriptDateLogicItem
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.EMAIL &&
          <ScriptEmailLogicItem
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.LABEL &&
          <ScriptOpenTextLogic
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.NUMERIC &&
          <ScriptNumberLogicItem
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.PHONE &&
          <ScriptPhoneLogicItem
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.LINK &&
          <ScriptLinkLogicItem
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.TREE &&
          <ScriptTreeLogicItem
          treeId={chosenData?.jsonParameters ? JSON.parse(chosenData.jsonParameters).treeId : ""}
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.LISTVALUE &&
          <ScriptListValueLogic
            availableNextSteps={availableNextSteps}
          onLogicAdd={(logic) => addLogicStep(logic)}
          logic={data.logics}
          renderMethod={chosenData?.jsonParameters ? JSON.parse(chosenData?.jsonParameters).renderMethod : ""}
          branchId={chosenData?.jsonParameters ? JSON.parse(chosenData?.jsonParameters).parentId : ""}
          />}
        {chosenData?.additionalDataTypeId == ScriptAvailableDataTypes.INTEGERLIST &&
          <ScriptIntegerLogic
            availableNextSteps={availableNextSteps}
            onLogicAdd={(logic) => addLogicStep(logic)}
            logic={data.logics}
            minValue={chosenData?.jsonParameters ?
              JSON.parse(chosenData?.jsonParameters).initialValue : 0}
            maxValue={chosenData?.jsonParameters ?
              JSON.parse(chosenData?.jsonParameters).endValue : 0}
          />}
      </div>
      {logicSteps.length > 0 &&
        <div id='additional-data-logic-steps' className="additional-data-logic-step-table">
          <table style={{ width: "100%" }}>
            <tr>
              {chosenData?.additionalDataTypeId != ScriptAvailableDataTypes.YESNO &&
                <th style={{ width: '10%', textAlign: "center" }}></th>}
              <th style={{ width: '45%', textAlign: "center" }}>_value</th>
              <th style={{ width: '45%', textAlign: "center" }}>_next_step</th>
            </tr>
            {logicSteps.map(st => (
              <tr>
                {chosenData?.additionalDataTypeId != ScriptAvailableDataTypes.YESNO && (
                  <td style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', color: 'red' }}
                      onClick={() => deleteLogicStep(st)}
                    >
                      <TPIcon iconType={TPIconTypes.delete} />
                    </button>
                  </td>)}
                <td style={{ textAlign: "center" }}>{String(st.value)}</td>
                <td style={{ textAlign: "center" }}>{Number(st.nextStepId) > 0 &&
                  `${Boolean(availableNextSteps.find(av => (av.id == st.nextStepId) && (av.order != -1)))
                    ?
                    availableNextSteps.find(av => (av.id == st.nextStepId) && (av.order != -1))?.order + ". "
                    :
                    ""
                  }`}
                  {extractTextFromHTML(availableNextSteps.find(av => st.nextStepId == av.id)?.description || "")}
                </td>
              </tr>
            ))}
          </table>
        </div>}
    </div>
  )
}