import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { HierarchyFoldersViewModel } from "@/models/HierarchyFolders/HierarchyFoldersModels";
// import { CustomerTypeInputDTO } from "@/models/CustomerType/CustomerTypeInputDTO";

export class HierarchyFolderTypeService {
  serviceFileName: string = "HierarchyFolderService.ts";
  public async getHierarchyFolderByTopic(
    topic: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<HierarchyFoldersViewModel>> {
    const httpclient = new TPHTTPService();
    const url: string = `/hierarchyfolders?topic=${topic}`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getHierarchyFolderByTopic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getHierarchyFolderByTopic`);
      throw new Error(
        `Error ${this.serviceFileName} getHierarchyFolderByTopic`,
      );
    }
  }

  public async getHierarchyFolderById(
    hierarchyId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<HierarchyFoldersViewModel> {
    const httpclient = new TPHTTPService();
    const url: string = `/hierarchyfolders/${hierarchyId}`;

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getHierarchyFolderById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getHierarchyFolderById`);
      throw new Error(`Error ${this.serviceFileName} getHierarchyFolderById`);
    }
  }

  // public async deleteCustomerTypeById(
  //   customerTypeId: string,
  //   showPositiveMessage: boolean,
  //   showNegativeMessage: boolean,
  //   expectedCodes: Array<number>
  // ): Promise<any> {
  //   let httpclient = new TPHTTPService();
  //   let url: string;
  //   url = "/customertype/" + customerTypeId;

  //   let finalURL: string =
  //     TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //   let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //   let response: HTTPResponse;
  //   try {
  //     response = await httpclient.deleteData(
  //       finalURL,
  //       expectedCodes,
  //       showPositiveMessage,
  //       showNegativeMessage,
  //       headers
  //     );
  //     return response;
  //   } catch (error) {
  //     TPLog.Log(
  //       `Error ${this.serviceFileName} deleteCustomerTypeById ex`,
  //       TPLogType.ERROR,
  //       error
  //     );
  //     console.error(`Error ${this.serviceFileName} deleteCustomerTypeById`);
  //     throw new Error(`Error ${this.serviceFileName} deleteCustomerTypeById`);
  //   }
  // }

  // public async insertCustomerType(
  //   intpuDTO: CustomerTypeInputDTO,
  //   showPositiveMessage: boolean,
  //   showNegativeMessage: boolean,
  //   expectedCodes: Array<number>
  // ): Promise<any> {
  //   const httpclient = new TPHTTPService();
  //   const url: string = "/customertype";

  //   const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
  //   const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //   let response: HTTPResponse;
  //   try {
  //     response = await httpclient.postData(
  //       finalURL,
  //       intpuDTO,
  //       expectedCodes,
  //       showPositiveMessage,
  //       showNegativeMessage,
  //       headers
  //     );
  //     return response;
  //   } catch (error) {
  //     TPLog.Log(
  //       `Error ${this.serviceFileName} insertCustomerType ex`,
  //       TPLogType.ERROR,
  //       error
  //     );
  //     console.error(`Error ${this.serviceFileName} insertCustomerType`);
  //     throw new Error(`Error ${this.serviceFileName} insertCustomerType`);
  //   }
  // }

  // public async updateCustomerType(
  //   intpuDTO: CustomerTypeInputDTO,
  //   showPositiveMessage: boolean,
  //   showNegativeMessage: boolean,
  //   expectedCodes: Array<number>
  // ): Promise<any> {
  //   const httpclient = new TPHTTPService();
  //   const url: string = "/customertype";

  //   const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
  //   let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //   let response: HTTPResponse;
  //   try {
  //     response = await httpclient.putData(
  //       finalURL,
  //       intpuDTO,
  //       expectedCodes,
  //       showPositiveMessage,
  //       showNegativeMessage,
  //       headers
  //     );
  //     return response;
  //   } catch (error) {
  //     TPLog.Log(
  //       `Error ${this.serviceFileName} updateFunction ex`,
  //       TPLogType.ERROR,
  //       error
  //     );
  //     console.error(`Error ${this.serviceFileName} updateFunction`);
  //     throw new Error(`Error ${this.serviceFileName} updateFunction`);
  //   }
  // }
}
