import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import TPModalError, {
  TPModalErrorStateType,
} from "@/layouts/TPModalError/TPModalError";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { WorkflowReassignmentService } from "@/services/WorkflowReassignmentService";
import { Icon } from "@iconify/react";
import { useCallback, useEffect, useMemo, useState } from "react";

// #region Interfaces
type Workflow = {
  key: string;
  value: string;
};

type WorkflowAssignmentRow = {
  guidId: string;
  typistGuid: string;
  organization: string;
  typistUserDescription: string;
  contactMethod: string;
  type: string;
  process: string;
  location: string;
  priority: number | null;
};

interface WorkflowStat {
  label: string;
  value: number;
  bgColor: string;
}

// #endregion

const WorkflowReAssignment = () => {
  // #region State Management

  const workflowService = new WorkflowReassignmentService();
  const expectedCodes = [200, 404];

  const [dynamicTableKey, setDynamicTableKey] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [reloadWorkflows, setReloadWorkflows] = useState<boolean>(false);

  const [workflowStats, setWorkflowStats] = useState<WorkflowStat[]>([]);

  const resourceSet: string = "WorkflowReAssignmentComponent";
  const componentFileName: string = "WorkflowReAssignment.tsx";

  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [workflowAssignments, setWorkflowAssignments] = useState<
    WorkflowAssignmentRow[]
  >([]);
  const [workflowReAssignmentSelected, setWorkflowReAssignmentSelected] =
    useState<any[]>([]);

  const [currentWorkflow, setCurrentWorkflow] = useState<string>("");
  const [newWorkflow, setNewWorkflow] = useState<string>("");

  const [pageTitleLabel, setPageTitleLabel] = useState<string>("");
  const [okButtonLabel, setOkButtonLabel] = useState<string>("");
  const [currentWorkflowLabel, setCurrentWorkflowLabel] = useState<string>("");
  const [newWorkflowLabel, setNewWorkflowLabel] = useState<string>("");
  const [reAssignWorkflowLabel, setReAssignWorkflowLabel] =
    useState<string>("");
  const [successWorkflowLabel, setSuccessWorkflowLabel] = useState<string>("");
  const [selectedRecordsLabel, setSelectedRecordsLabel] = useState<string>("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] =
    useState<string>("");
  const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] =
    useState<string>("");
  const [organizationRelationLabel, setOrganizationRelationLabel] =
    useState<string>("");
  const [contactMethodSourceLabel, setContactMethodSourceLabel] =
    useState<string>("");
  const [typeLabel, setTypeLabel] = useState<string>("");
  const [processLabel, setProcessLabel] = useState<string>("");
  const [locationLabel, setLocationLabel] = useState<string>("");
  const [priorityLabel, setPriorityLabel] = useState<string>("");

  const isReassignButtonDisabled = useMemo(() => {
    return (
      currentWorkflow === "" ||
      newWorkflow === "" ||
      !workflowReAssignmentSelected.length
    );
  }, [currentWorkflow, newWorkflow, workflowReAssignmentSelected]);

  // #endregion

  // #region Error Handling

  const initialModalError: TPModalErrorStateType = {
    isShown: false,
    title: "",
    errorMessage: "",
    yesLabel: "",
    callBackFunction: TPGlobal.foo,
  };

  const [modalErrorState, setModalErrorState] =
    useState<TPModalErrorStateType>(initialModalError);

  const handleModalErrorClose = useCallback(() => {
    setModalErrorState((prevState) => ({
      ...prevState,
      isShown: false,
      errorMessage: "",
    }));
  }, []);

  // #endregion

  // #region Load Functions

  const refreshDynamicTable = useCallback(() => {
    setDynamicTableKey((prevKey) => prevKey + 1);
  }, []);

  const loadWorkflowAssignments = useCallback(
    async (workflowId: string) => {
      if (workflowId === "") {
        setWorkflowAssignments([]);
        return;
      }

      try {
        setIsLoadingScreen(true);
        const assignmentsData =
          await workflowService.getWorkflowAssignmentByWorkflow(
            workflowId,
            false,
            true,
            expectedCodes
          );

        const mappedAssignments = assignmentsData.map((assignment: any) => ({
          guidId: assignment.guidId || "",
          typistGuid: assignment.typistGuid || "",
          organization: assignment.baseLevelSourceDescription || "",
          contactMethod: assignment.branch1Description || "",
          type: assignment.branch2Description || "",
          typistUserDescription: assignment.typistUserDescription || "",
          process: assignment.branch3Description || "",
          location: assignment.branch4Description || "",
          priority: assignment.weight || null,
        }));

        setWorkflowAssignments(mappedAssignments);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadWorkflowAssignments ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} loadWorkflowAssignments ex`);
      } finally {
        setIsLoadingScreen(false);
      }
    },
    [workflowService]
  );

  const loadResourcesAndOrganizations = useCallback(async () => {
    setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));
    setCurrentWorkflowLabel(
      await TPI18N.GetText(resourceSet, "CurrentWorkflow")
    );
    setNewWorkflowLabel(await TPI18N.GetText(resourceSet, "NewWorkflow"));
    setReAssignWorkflowLabel(
      await TPI18N.GetText(resourceSet, "ReAssignWorkflow")
    );
    setSuccessWorkflowLabel(
      await TPI18N.GetText(resourceSet, "SuccessWorkflow")
    );
    setSelectedRecordsLabel(
      await TPI18N.GetText(resourceSet, "SelectedRecords")
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(resourceSet, "RecordsProcessed")
    );
    setRecordsUnprocessedLabel(
      await TPI18N.GetText(resourceSet, "RecordsUnprocessed")
    );
    setOrganizationRelationLabel(
      await TPI18N.GetText(resourceSet, "OrganizationRelation")
    );
    setContactMethodSourceLabel(
      await TPI18N.GetText(resourceSet, "ContactMethodSource")
    );
    setTypeLabel(await TPI18N.GetText(resourceSet, "Type"));
    setProcessLabel(await TPI18N.GetText(resourceSet, "Process"));
    setLocationLabel(await TPI18N.GetText(resourceSet, "Location"));
    setPriorityLabel(await TPI18N.GetText(resourceSet, "Priority"));

    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
    );
  }, [resourceSet]);

  const loadWorkflows = useCallback(async () => {
    try {
      const workflowsData = await workflowService.getAssignedWorkflowTypes(
        false,
        true,
        expectedCodes
      );

      const translatedWorkflows: Workflow[] = await Promise.all(
        workflowsData.map(async (workflow: any) => {
          const label = await TPI18N.GetText(
            resourceSet,
            workflow.localizedDescription
          );
          return {
            key: workflow.id,
            value: label || workflow.localizedDescription,
          } as Workflow;
        })
      );

      const removeDuplicates = (workflows: Workflow[]) => {
        const uniqueWorkflows: { [key: string]: Workflow } = {};
        workflows.forEach((workflow) => {
          uniqueWorkflows[workflow.key] = workflow;
        });
        return Object.values(uniqueWorkflows);
      };

      const uniqueWorkflows = removeDuplicates(translatedWorkflows);

      uniqueWorkflows.unshift({
        key: "",
        value: "--",
      });

      setWorkflowReAssignmentSelected([]);
      setWorkflows(uniqueWorkflows);
      setCurrentWorkflow("");
      setNewWorkflow("");
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadWorkflows ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} loadWorkflows ex`);
    }
  }, [resourceSet, reloadWorkflows]);

  const reloadWorkflowsCallback = useCallback(
    () => setReloadWorkflows((prev) => !prev),
    []
  );

  const loadData = useCallback(async () => {
    try {
      setIsLoadingScreen(true);
      await loadResourcesAndOrganizations();
      await loadWorkflows();
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadData ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} loadData ex`);
    } finally {
      setIsLoadingScreen(false);
    }
  }, [loadResourcesAndOrganizations, loadWorkflows]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadWorkflowAssignments(currentWorkflow);
  }, [currentWorkflow]);

  useEffect(() => {
    refreshDynamicTable();
  }, [workflowAssignments]);

  // #endregion

  // #region Handlers

  const handleCurrentWorkflowChange = useCallback(
    (selectedValue: string) => {
      setCurrentWorkflow(selectedValue);
    },
    [loadWorkflowAssignments]
  );

  const handleNewWorkflowChange = useCallback((selectedValue: string) => {
    setNewWorkflow(selectedValue);
  }, []);

  const handleReassignWorkflow = useCallback(async () => {
    try {
      setIsLoadingScreen(true);

      const groupedByTypistGuid: Record<string, string[]> =
        workflowReAssignmentSelected.reduce(
          (acc, item) => {
            if (!acc[item.typistGuid]) {
              acc[item.typistGuid] = [];
            }
            acc[item.typistGuid].push(item.guidId);
            return acc;
          },
          {} as Record<string, string[]>
        );

      const responses = await Promise.all(
        Object.entries(groupedByTypistGuid).map(([typistGuid, guidIds]) =>
          workflowService.reassignWorkflow(
            guidIds,
            newWorkflow,
            currentWorkflow,
            typistGuid,
            false,
            true,
            expectedCodes
          )
        )
      );

      let totalRecords = 0;
      let totalProcessed = 0;
      let totalUnprocessed = 0;

      responses.flat().forEach((response: any) => {
        switch (response.key) {
          case "Records":
            totalRecords += parseInt(response.value, 10);
            break;
          case "Processed":
            totalProcessed += parseInt(response.value, 10);
            break;
          case "Unprocessed":
            totalUnprocessed += parseInt(response.value, 10);
            break;
          default:
            break;
        }
      });

      setWorkflowStats([
        {
          label: selectedRecordsLabel,
          value: totalRecords,
          bgColor: "#780096",
        },
        {
          label: recordsProcessedLabel,
          value: totalProcessed,
          bgColor: "#009a58",
        },
        {
          label: recordsUnprocessedLabel,
          value: totalUnprocessed,
          bgColor: "#cc0000",
        },
      ]);

      if (totalProcessed > 0) reloadWorkflowsCallback();
      setCurrentWorkflow("");
      setNewWorkflow("");
      setOpenModal(true);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleReassignWorkflow ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} handleReassignWorkflow ex`);
    } finally {
      setIsLoadingScreen(false);
    }
  }, [
    newWorkflow,
    currentWorkflow,
    workflowReAssignmentSelected,
    selectedRecordsLabel,
    recordsProcessedLabel,
    recordsUnprocessedLabel,
  ]);

  const handleRefreshClick = function (icon: TPIconTypes) {
    if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop) {
      refreshDynamicTable();
      loadWorkflowAssignments(currentWorkflow);
    }
  };

  // #endregion

  // #region Render

  return (
    <>
      <ContentVerticalNoTabsStyled>
        <div className="container-fluid">
          <TPLoadingOverlay active={isLoadingScreen}>
            <TPPageTitle>{pageTitleLabel}</TPPageTitle>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "70%",
                margin: "0",
                gap: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <TPSelect
                  id="currentWorkflow"
                  dataSource={workflows}
                  onChange={(e: any) =>
                    handleCurrentWorkflowChange(e.target.value)
                  }
                  value={currentWorkflow}
                  labelText={currentWorkflowLabel}
                  isMandatory
                />
              </div>

              <div style={{ flex: 1 }}>
                <TPSelect
                  id="newWorkflow"
                  dataSource={workflows.filter(
                    (workflow) =>
                      workflow.key !== currentWorkflow || !workflow.key
                  )}
                  onChange={(e: any) => handleNewWorkflowChange(e.target.value)}
                  value={newWorkflow}
                  labelText={newWorkflowLabel}
                  isMandatory
                />
              </div>

              <div style={{ marginTop: 25 }}>
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={handleReassignWorkflow}
                  style={{ height: "100%" }}
                  disabled={isReassignButtonDisabled}
                >
                  {reAssignWorkflowLabel}
                </TPButton>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col">
                <DynamicTable
                  key={dynamicTableKey}
                  data={workflowAssignments}
                  selectable
                  withPreferences
                  onSelectionChange={setWorkflowReAssignmentSelected}
                  hiddenColumns={["guidId", "typistGuid", "type"]}
                  hiddenSearch={!workflowAssignments.length}
                  columnNames={{
                    organization: organizationRelationLabel,
                    contactMethod: contactMethodSourceLabel,
                    typistUserDescription: typeLabel,
                    process: processLabel,
                    location: locationLabel,
                    priority: priorityLabel,
                  }}
                  onIconClicked={handleRefreshClick}
                />
              </div>
            </div>

            {modalErrorState.isShown && (
              <TPModalError
                modalState={{
                  ...modalErrorState,
                  yesLabel: okButtonLabel,
                  callBackFunction: handleModalErrorClose,
                }}
              />
            )}
          </TPLoadingOverlay>
        </div>
      </ContentVerticalNoTabsStyled>

      <TPModal
        modalState={{
          id: "merge-confirm-modal",
          titleModal: "",
          acceptLabel: (
            <TPLanguage
              resourceSet={TPGlobal.globalResourceSet}
              resourceId={"ok"}
            />
          ),
          callBackAnswer: () => setOpenModal(false),
          isShown: openModal,
          modalWidth: "min-content",
          enableAcceptButton: true,
          showCancelButton: false,
          hiddenHeader: true,
        }}
      >
        <div id="merge-confirm-modal-body" style={{ textAlign: "center" }}>
          <Icon
            icon="material-symbols:check-circle-outline"
            color="#009a58"
            height="80px"
            width="80px"
          />
          <b style={{ display: "block", margin: "20px 0" }}>
            {successWorkflowLabel}
          </b>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            {workflowStats.map(({ label, value, bgColor }, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  marginTop: "10px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    marginRight: "50px",
                  }}
                >
                  {label}
                </p>
                <div
                  style={{
                    backgroundColor: bgColor,
                    color: "#fff",
                    padding: "5px 12px",
                    borderRadius: "8px",
                    minWidth: "30px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </TPModal>
    </>
  );
  // #endregion
};

export default WorkflowReAssignment;
