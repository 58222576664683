import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import { KeyIdValue } from "./ClientAdminViewModel";

export interface ClientNewCustomerInputDTO {
    subsidiaryOrganizationId: number;
    documentTypeId: string;
    document: string;
    name: string;
    lastName: string;
    additionalInfo: string | null;
    isActive: boolean;
    typistIdGuid: string;
    internalCode: string;
    subsidiaryName: string | null;
    preferredPhone: string;
    preferredAddress: string | null;
    preferredAddressInfo: string | null;
    preferredEmail: string;
    geography: string | null;
    organizationId: string | null;
    relationId: string | null;
    regionId: string | null;
    channelId: string | null;
    customerTypeId: string;
    additionalData: Array<KeyIdValue>
}


export class ClientNewCustomerInputDTOValidator extends Validator<ClientNewCustomerInputDTO> {
    constructor() {
        super();
        const resourceSet: string = "ClientInsertUpdateComponent";
        try {
            this.ruleFor("customerTypeId")
                .notEmpty()
                .withMessage(`${resourceSet}|InputDTOCustomerTypeEmpty`)
                .must((id) => {
                    let check = TPGlobal.checkSpecialCharacters(id);
                    return check.result;
                })
                .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
                .must((customerType) => {
                    return TPGlobal.TPSanitize(customerType) == customerType;
                })
                .withMessage(
                    TPGlobal.globalResourceSet + "|InputDTOInvalidCustomerType",
                );

            this.ruleFor("documentTypeId")
                .notEmpty()
                .withMessage(resourceSet + "|InputDTODocumentTypeEmpty")
                .must((id) => {
                    let check = TPGlobal.checkSpecialCharacters(id);
                    return check.result;
                })
                .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
                .must((documentTypeId) => {
                    return TPGlobal.TPSanitize(documentTypeId) == documentTypeId;
                })
                .withMessage(resourceSet + "|InputDTOInvalidDocumentType")
                .maxLength(20)
                .withMessage(resourceSet + "|InputDTOInvalidDocumentTypeLength");

            this.ruleFor("document")
                .notEmpty()
                .withMessage(resourceSet + "|InputDTODocumentEmpty")
                .must((document) => {
                    return TPGlobal.TPSanitize(document) == document;
                })
                .withMessage(resourceSet + "|InputDTOInvalidDocument")
                .maxLength(200)
                .withMessage(resourceSet + "|InputDTOInvalidDocumentLength");

            this.ruleFor("name")
                .notEmpty()
                .withMessage(resourceSet + "|InputDTONameEmpty")
                .must((name) => {
                    return TPGlobal.TPSanitize(name) == name;
                })
                .withMessage(resourceSet + "|InputDTOInvalidName")
                .maxLength(100)
                .withMessage(resourceSet + "|InputDTOInvalidNameLength");

            this.ruleFor("preferredEmail")
                .notEmpty()
                .withMessage(resourceSet + "|InputDTOEmailEmpty")
                .must((preferredEmail) => {
                    return TPGlobal.TPSanitize(preferredEmail) == preferredEmail;
                })
                .withMessage(resourceSet + "|InputDTOInvalidEmail")
                .maxLength(200)
                .withMessage(resourceSet + "|InputDTOInvalidEmailLength")
                .must((value) => {
                    if (!TPGlobal.regularExpressionCheckEmail.test(value)) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .withMessage(resourceSet + "|InputDTOInvalidEmail");

            this.ruleFor("preferredPhone")
                .notEmpty()
                .withMessage(resourceSet + "|InputDTOPhoneEmpty")
                .must((preferredPhone) => {
                    return TPGlobal.TPSanitize(preferredPhone) == preferredPhone;
                })
                .withMessage(resourceSet + "|InputDTOInvalidPhone")
                .maxLength(200)
                .withMessage(resourceSet + "|InputDTOInvalidPhoneLength");

            this.ruleFor("internalCode")
                // .notEmpty()
                // .withMessage(resourceSet + "|InputDTOInternalCodeEmpty")
                .must((internalCode) => {
                    return TPGlobal.TPSanitize(internalCode) == internalCode;
                })
                .withMessage(resourceSet + "|InputDTOInvalidInternalCode")
                .maxLength(100)
                .withMessage(resourceSet + "|InputDTOInvalidInternalCodeLength");
        } catch (error) {
            //TODO logs
        }
    }
}
