import TPGlobal from '@/helpers/TPGlobal';
import React, { createContext, useContext, useState, useEffect } from 'react';

interface CopilotContextType {
    copilotEnable: boolean;
    setCopilotEnable: (enabled: boolean) => void;
}

const CopilotContext = createContext<CopilotContextType | undefined>(undefined);

export const CopilotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const globalValue = TPGlobal.TPClientConfigurationValues.find(
        (configItem) => configItem.key == "CopilotEnable",
    )?.value === '1';

    const [copilotEnable, setCopilotEnable] = useState<boolean>( globalValue );

    useEffect(()=>{
        setCopilotEnable(globalValue)
    },[globalValue])
   
    return (
        <CopilotContext.Provider value={{ copilotEnable, setCopilotEnable }}>
            {children}
        </CopilotContext.Provider>
    );
};

export const useCopilot = () => {
    const context = useContext(CopilotContext);
    if (!context) {
        throw new Error("useCopilot must be used within a CopilotProvider");
    }
    return context;
};
