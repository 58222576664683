import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  SystemTaskTypesEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { AddTaskInputDTO } from "@/models/Task/TaskInputDTO";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { FC, useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export type TPModalAddTaskCommentsState = {
  isShown: boolean;
  callBackData: any;
};

type TPModalAddTaskCommentProps = {
  caseNumber: number;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: Function;
};

enum commandsAddTaskEnum {
  "change_value_property" = 0,
}

type commandType = {
  type: commandsAddTaskEnum;
  payload: any;
};

interface screenStateProps {
  comments: string;
}

const TPModalAddTaskComment: FC<TPModalAddTaskCommentProps> = ({
  caseNumber,
  acceptLabel,
  cancelLabel,
  isShown,
  callBackAnswer,
}) => {
  const componentFileName: string = "TPModalAddTaskComment.tsx";
  const resourceSet: string = "TPModalAddTaskCommentComponent";
  //Screen Resources
  const [titleLabel, setTitleLabel] = useState("");

  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  const editorRef = useRef<any>(null);

  const [editorValue, setEditorValue] = useState("");
  const [hasBeenModified, setHasBeenModified] = useState(false);

  const [editorErrorMessage, setEditorErrorMessage] = useState("");

  const guidTask: string = uuidv4().replaceAll("-", "");
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    styleModal["zIndex"] = "1057";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const loadResourcesAndTaskType = async () => {
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));

    setIsLoadingScreen(false);
  };

  const handleOnCancelOrCloseClick = () => {
    callBackAnswer(false, null);
  };

  //handle when editor change
  // const handleEditorChange = () => {
  //   setEditorErrorMessage("");
  // };

  const handleEditorChange = (value: any) => {
    const filterData = value.replace(/(<([^>]+)>)/gi, "");
    setEditorErrorMessage("");
    setEditorValue(value);
  };

  const handlerOnOKClick = async () => {
    //validate comments
    let comments: string = "";
    if (editorValue.trim() == "") {
      // setHasBeenModified(true);
      setEditorErrorMessage(
        await TPI18N.GetText(resourceSet, "ErrorNoComments")
      );
    }

    if (editorRef.current.props.value) {
      comments = editorRef.current.props.value;
      comments = comments === null ? "" : comments.trim();
      if (comments === "") {
        setEditorErrorMessage(
          await TPI18N.GetText(resourceSet, "ErrorNoComments")
        );
        return;
      } else if (TPGlobal.TPSanitizeWithoutLinks(comments).trim() === "") {
        setEditorErrorMessage(
          await TPI18N.GetText(resourceSet, "ErrorNoComments")
        );
        return;
      }
    }
    comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim();

    let recordInputDTO: AddTaskInputDTO = {
      useRPCMethod: true,
      caseId: +caseNumber,
      taskTypeId: SystemTaskTypesEnum.comment,
      quantity: 1,
      responsibleGuidUser: TPGlobal.currentUserGuid,
      term: null,
      termUnitId: null,
      dueDate: null,
      comments: TPGlobal.stringToUTF8(comments).toString(),
      reminderDate: null,
      reminderComments: null,
      typistGuidUser: TPGlobal.currentUserGuid,
      taskGuid: guidTask,
    };

    await insertTask(recordInputDTO);
  };

  const insertTask = async (inputDTO: AddTaskInputDTO) => {
    let serviceClient = new TaskService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.AddTask(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackAnswer(true, { guidTask: guidTask });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertTask ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertTask ex`);
      setIsLoadingScreen(false);
      callBackAnswer(false, null);
    }
  };

  const initialStateBll: screenStateProps = {
    comments: "",
  };

  //reducer definition
  const [screenState, dispatchCommand] = useReducer(doCommand, initialStateBll);

  function doCommand(prevState: screenStateProps, command: commandType) {
    let newScreenState: screenStateProps;
    switch (command.type) {
      case commandsAddTaskEnum.change_value_property:
        newScreenState = { ...prevState };
        newScreenState.comments = command.payload.newComments;
        return newScreenState;
    }
  }

  useEffect(() => {
    loadResourcesAndTaskType();
  }, []);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{titleLabel}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  handleOnCancelOrCloseClick();
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"100px"} isModal>
                <div className="row">
                  <div className="col-12">
                    <TPEditor
                      referece={(editor: any) => (editorRef.current = editor)}
                      value={editorValue}
                      onChange={handleEditorChange}
                      initialValue=""
                      isVisible={false}
                      placeholder=""
                    />
                    <span style={{ color: "#dc3545", fontSize: "14px" }}>
                      {editorErrorMessage}
                    </span>
                  </div>
                </div>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      handlerOnOKClick();
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => {
                      handleOnCancelOrCloseClick();
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ zIndex: 1056 }} className={backdropClass}></div>
    </>
  );
};

export default TPModalAddTaskComment;
