import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  CustomStateInsertUpdateFormProps,
  CustomStateNewTableEvents,
} from "@/models/CustomState/CustomState";
import {
  CustomStateNewEnum as e,
  CustomStateNewControl,
} from "@/models/CustomState/CustomState";
import { useFormik } from "formik";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { BranchService } from "@/services/BranchService";
import { CaseCustomStatesService } from "@/services/CaseCustomStatesService";
import { ParametersService } from "@/services/ParametersService";
import { CaseService } from "@/services/CaseService";
import { WorkflowDetailService } from "@/services/WorkflowDetailService";
import TPGlobal from "@/helpers/TPGlobal";

const styles = {
  column: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  row: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  form: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "20px",
  } as CSSProperties,
  flex40: {
    flex: "1 1 40%",
  } as CSSProperties,
  marginBottom: {
    marginBottom: "20px",
  } as CSSProperties,
  marginBottom10: {
    marginBottom: "10px",
  } as CSSProperties,
  type: {
    fontWeight: "bold",
  } as CSSProperties,
  iconButton: {
    margin: "auto 0",
  } as CSSProperties,
  supervisor: {
    marginBottom: "10px",
  } as CSSProperties,
};

const defaultValue = { key: "", value: "--" };

/**
 * custom state insert update form component
 */
const CustomStateInsertUpdateForm = forwardRef(
  ({ m, mode, element, onChange }: CustomStateInsertUpdateFormProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * form controller
     */
    const formik = useFormik({
      initialValues: {
        ...CustomStateNewControl.initialValues,
        ...element,
      },
      validationSchema: CustomStateNewControl.validationSchema,
      validateOnChange: false,
      onSubmit: () => {},
    });
    /**
     * custom state types
     */
    const [customStateType, setCustomStateType] = useState<string>();
    /**
     * custom state types
     */
    const [customStateTypes, setCustomStateTypes] = useState<Array<TPKeyValue>>(
      [defaultValue]
    );
    /**
     * custom states
     */
    const [customStates, setCustomStates] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * custom states
     */
    const [baseField1, setBaseField1] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * custom states
     */
    const [referenceValue1, setReferenceValue1] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * custom states
     */
    const [baseField2, setBaseField2] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * custom states
     */
    const [referenceValue2, setReferenceValue2] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * custom states
     */
    const [comparators, setComparators] = useState<Array<TPKeyValue>>([
      defaultValue,
    ]);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
          validateForm: formik.validateForm,
        }) as CustomStateNewTableEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      if (mode === "update") {
        setCustomStateType(element.customStateType!);
        setTimeout(() => {
          formik.setFieldValue("nextState", element.nextState);
        }, 500);
      }
      loadCustomStateTypes();
      loadBaseField1();
      loadReferenceValue1();
      loadBaseField2();
      loadReferenceValue2();
      loadComparator2();
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     *
     */
    useEffect(() => {
      onChange(formik.values);
    }, [formik.values]);
    /**
     * event when component starts
     */
    useEffect(() => {
      if (customStateType) {
        loadCustomStates();
      } else {
        setCustomStates([defaultValue]);
      }
      formik.setFieldValue("nextState", null);
    }, [customStateType]);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     *
     */
    function loadCustomStateTypes() {
      const { getFirstLevelBranches } = new BranchService();
      getFirstLevelBranches("S_CCSTTY", 1, 1, false, true, [200, 404])
        .then((response) => {
          setCustomStateTypes([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadCustomStates() {
      const { getCaseCustomStatesByType } = new CaseCustomStatesService();
      getCaseCustomStatesByType(customStateType!, false, true, [200, 404])
        .then((response) => {
          setCustomStates([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadBaseField1() {
      const { getByWorkflowType } = new WorkflowDetailService();
      getByWorkflowType(element?.workFlowTypeId!, false, true, [200, 404])
        .then((response) => {
          setBaseField1([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: `${TPGlobal.TASK_STATUS}.${k.taskTypeId}`,
                value: `${m?.[e.TaskStatus]} - ${k.taskDescription}`,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadReferenceValue1() {
      const { getByParentIdAndFilterIsActive } = new ParametersService();
      getByParentIdAndFilterIsActive(
        "REF_VALUE_1",
        "1",
        false,
        true,
        [200, 404]
      )
        .then((response) => {
          setReferenceValue1([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadBaseField2() {
      const { getByParentIdAndFilterIsActive } = new ParametersService();
      getByParentIdAndFilterIsActive(
        "BASE_FIELD_2",
        "1",
        false,
        true,
        [200, 404]
      )
        .then((response) => {
          setBaseField2([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadReferenceValue2() {
      const { getCasesStatus } = new CaseService();
      getCasesStatus(false, true, [200, 404])
        .then((response) => {
          setReferenceValue2([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     *
     */
    function loadComparator2() {
      const { getByParentIdAndFilterIsActive } = new ParametersService();
      getByParentIdAndFilterIsActive("COMP_OP_2", "1", false, true, [200, 404])
        .then((response) => {
          setComparators([
            { key: "", value: "--" },
            ...response.map((k) => {
              return {
                key: k.id,
                value: k.description,
              };
            }),
          ]);
        })
        .catch((error) => console.error(error));
    }
    /**
     * FUNCTIONS END
     */

    /**
     * RENDER
     */
    return (
      <form style={styles.form}>
        <div style={styles.flex40}>
          <TPTextBox
            id="CustomStateFormInputId"
            key="CustomStateFormInputId"
            disabled={true}
            labelText={m?.[e.FormInputIdLabel]}
            isMandatory={true}
            value={`${formik.values?.id ?? ""}`}
            onChange={() => {}}
            errorMessage={formik.errors?.id && m?.[formik.errors.id]}
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectCustomStateTypeId"
            key="CustomStateFormSelectCustomStateTypeId"
            isMandatory={true}
            labelText={m?.[e.FormSelectCustomStateTypeIdLabel]}
            value={`${customStateType ?? ""}`}
            dataSource={customStateTypes}
            onChange={({ target }: any) => {
              formik.setFieldValue("customStateType", target.value);
              setCustomStateType(target.value);
            }}
            errorMessage={
              formik.errors?.customStateType && m?.[formik.errors.customStateType]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectCustomStateId"
            key="CustomStateFormSelectCustomStateId"
            isMandatory={true}
            labelText={m?.[e.FormSelectCustomStateLabel]}
            value={`${formik.values?.nextState ?? ""}`}
            dataSource={customStates}
            onChange={({ target }: any) => {
              formik.setFieldValue("nextState", target.value);
            }}
            errorMessage={
              formik.errors?.nextState && m?.[formik.errors.nextState]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectBaseField1"
            key="CustomStateFormSelectBaseField1"
            isMandatory={true}
            labelText={m?.[e.FormSelectBaseField1Label]}
            value={`${formik.values?.baseField1 ?? ""}`}
            dataSource={baseField1}
            onChange={({ target }: any) => {
              formik.setFieldValue("baseField1", target.value);
            }}
            errorMessage={
              formik.errors?.baseField1 && m?.[formik.errors.baseField1]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectReferenceValue1"
            key="CustomStateFormSelectReferenceValue1"
            isMandatory={true}
            labelText={m?.[e.FormSelectReferenceValue1Label]}
            value={`${formik.values?.referenceValue1 ?? ""}`}
            dataSource={referenceValue1}
            onChange={({ target }: any) => {
              formik.setFieldValue("referenceValue1", target.value);
            }}
            errorMessage={
              formik.errors?.referenceValue1 &&
              m?.[formik.errors.referenceValue1]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectBaseField2"
            key="CustomStateFormSelectBaseField2"
            labelText={m?.[e.FormSelectBaseField2Label]}
            value={`${formik.values?.baseField2 ?? ""}`}
            dataSource={baseField2}
            onChange={({ target }: any) => {
              formik.setFieldValue("baseField2", target.value);
            }}
            errorMessage={
              formik.errors?.baseField2 && m?.[formik.errors.baseField2]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectComparison2"
            key="CustomStateFormSelectComparison2"
            labelText={m?.[e.FormSelectComparisonOperatorLabel]}
            value={`${formik.values?.comparison2 ?? ""}`}
            dataSource={comparators}
            onChange={({ target }: any) => {
              formik.setFieldValue("comparison2", target.value);
            }}
            errorMessage={
              formik.errors?.comparison2 && m?.[formik.errors.comparison2]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPSelect
            id="CustomStateFormSelectReferenceValue2"
            key="CustomStateFormSelectReferenceValue2"
            labelText={m?.[e.FormSelectReferenceValue2Label]}
            value={`${formik.values?.referenceValue2 ?? ""}`}
            dataSource={referenceValue2}
            onChange={({ target }: any) => {
              formik.setFieldValue("referenceValue2", target.value);
            }}
            errorMessage={
              formik.errors?.referenceValue2 &&
              m?.[formik.errors.referenceValue2]
            }
          />
        </div>

        <div style={styles.flex40}>
          <TPTextBox
            id="CustomStateFormInputWeight"
            key="CustomStateFormInputWeight"
            labelText={m?.[e.FormInputWeightLabel]}
            isMandatory={true}
            type="number"
            value={`${formik.values?.weight ?? ""}`}
            onChange={({ target }: any) => {
              formik.setFieldValue("weight", target.value);
            }}
            errorMessage={formik.errors?.weight && m?.[formik.errors.weight]}
          />
        </div>
      </form>
    );
  }
);

export default CustomStateInsertUpdateForm;
