// TPToastMessagesStyled.ts
import styled from "styled-components";

// Styling for the flex container wrapping the text input and button
export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

// Styling for the text input wrapper
export const TextInputWrapper = styled.div`
  width: 100%;
`;

// Base styling for buttons
const BaseButton = styled.button`
  background: #ffffff;
  color: rgb(33, 37, 41);
  border: 1px solid #dee2e6;
  margin-top: 24px;
  border-left: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:active {
    background: rgb(160, 0, 149); /* Change to desired active color */
    color: #ffffff; /* Change to desired active text color */
    border: 1px solid rgb(160, 0, 149); /* Ensure border matches the background if needed */
  }
`;

export const AddButton = styled(BaseButton)`
  height: 36.7px;
`;

export const AddButtonSmall = styled(BaseButton)`
  height: 35px;
`;

export const AddButtonSmaller = styled(BaseButton)`
  height: 35px;
`;

// Styling for the text box container
export const TextBoxContainer = styled.div`
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

// Styling for the autocomplete container
export const AutoCompleteContainer = styled.div`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

// -------------------------------------

// export const TPStyledTable = styled.table`
//   border-collapse: collapse;
//   width: 100%;
//   display: block;
//   max-height: 350px;
//   overflow-y: auto;
//   margin: 4px;
// `;

export const TPStyledTable = styled.table`
  border-collapse: collapse;
  width: 100%; // Ensures the table takes up the full width
  display: block;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 2px;
`;

export const TPStyledHead = styled.thead``;

export const TPStyledHeaderRow = styled.tr``;

export const TPStyledHeadCell = styled.th`
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
`;

export const TPStyledBody = styled.tbody``;

export const TPStyledBodyRow = styled.tr`
  width: 100%;
`;

export const TPStyledBodyCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  width: ${(props: { width?: string }) =>
    props.width || "auto"}; // Allows setting width via props
`;

export const LanguageCountBadge = styled.div`
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(37, 41, 45);
  white-space: nowrap;
  background: #19875475;
  max-height: 24px;
  border-radius: 3px;
`;

export const TPStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 168px;
`;

export const DescriptionMessage = styled.div`
  background: rgba(160, 0, 149, 0.17);
  color: #a00095;
  padding: 12px;
  margin-bottom: 12px;
`;
