import TPImagesAdmin from "@/components/TPImagesAdmin/TPImagesAdmin";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { FC, useEffect, useState } from "react";
import ContainerFolder from "@/pages/ImagesAdmin/ImagesAdminContainerFolder";
import ImagesAdminContainerFiles from "@/pages/ImagesAdmin/ImagesAdminContainerFiles";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { AppProviderImagesAdmin } from "@/pages/ImagesAdmin/ImagesAdminContextImagesAdmin";
import { ContainerImageAdminDivStyle, ContainerVerticalDivStyle } from "@/pages/ImagesAdmin/StyleImageAdmin";
import ImagesAdminContainerFilter from "@/pages/ImagesAdmin/ImagesAdminContainerFilter";

export type TPModalImageSelectorState = {
  isShown: boolean;
  callBackData: any;
};

type ContainerFolderArgs = {
  mode?: string;
  startFolder?: string;
};

type TPModalImageSelectorProps = {
  title: string;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: any;
  topic: string;
  selectedFileDescriptionLabel: string;
  mode?: string; // optional mode prop
  startFolder?: string; // optional startFolder prop
};
type selectedFileType = {
  fileName: string;
  fileGuid: string;
  hierarchyId: string;
};

const TPModalImageSelector: FC<TPModalImageSelectorProps> = ({
  isShown,
  title,
  acceptLabel,
  cancelLabel,
  callBackData,
  callBackAnswer,
  topic,
  selectedFileDescriptionLabel,
  mode = "view", // default mode is "view"
  startFolder, // startFolder can be passed optionally
}) => {
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const initialStateSelectedFile: selectedFileType = {
    fileName: "",
    fileGuid: "",
    hierarchyId: "",
  };
  const [selectedFile, setSelectedFile] = useState<selectedFileType | null>(null);
  // To store filtered folder data
  const [containerFolderArgs, setContainerFolderArgs] = useState<ContainerFolderArgs>({
    mode: mode,
    startFolder: startFolder || "",
  });

  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  // const handleCallBackResponse = (data: HierarchyFoldersViewModel) => {
  //   setSelectedFile(data);
  // };

  // Handle callback response from selected file
  const handleCallBackResponse = (data: selectedFileType) => {
    setSelectedFile(data);
  };
  const handleImageSelect = (selectedImage: RecursiveRoutes | null) => {
    if (selectedImage) {
      setSelectedFile({
        fileName: selectedImage.fileName,
        fileGuid: selectedImage.blobId,
        hierarchyId: selectedImage.id.toString(),
      });
    } else {
      setSelectedFile(null);
    }
  };

  const handleAcceptOnClick = () => {
    callBackData.selectedFile = selectedFile;
    callBackAnswer(true, callBackData);
  };

   // Update container folder args when startFolder or mode changes
   useEffect(() => {
    setSelectedFile(initialStateSelectedFile); // Reset selected file when modal is reopened
  }, [startFolder, mode]);
  
  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => callBackAnswer(false, callBackData)}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "500px", paddingTop:"1.5px"}}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
                <AppProviderImagesAdmin>
                  <ContainerImageAdminDivStyle>
                    <ContainerFolder mode={mode} startFolder={startFolder} />
                    <ContainerVerticalDivStyle>
                      <ImagesAdminContainerFilter></ImagesAdminContainerFilter>
                      <ImagesAdminContainerFiles mode={"view"} startFolder={startFolder} onSelect={handleImageSelect} ></ImagesAdminContainerFiles>
                    </ContainerVerticalDivStyle>
                  </ContainerImageAdminDivStyle>
                </AppProviderImagesAdmin>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                   {selectedFile && selectedFile.fileName !== "" && (
                    <h6 style={{ color: "#5C636A" }}>
                      <span style={{ fontWeight: "600" }}>
                        {selectedFileDescriptionLabel}
                      </span>
                      &nbsp;&nbsp;
                      {selectedFile.fileName.length > 20
                        ? selectedFile.fileName.substring(0, 20) + "..."
                        : selectedFile.fileName}
                      &nbsp;&nbsp;({selectedFile?.hierarchyId})
                    </h6>
                  )}
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => handleAcceptOnClick()}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => callBackAnswer(false, callBackData)}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
              {/* <TPButton
                type={TPButtonTypes.primary}
                onClick={() => handleAcceptOnClick()}
              >
                {acceptLabel}
              </TPButton>
              <TPButton
                type={TPButtonTypes.link}
                onClick={() => callBackAnswer(false, callBackData)}
              >
                {cancelLabel}
              </TPButton> */}
            </div>
          </div>
        </div>
      </div>
      <div className={backdropClass} />
    </>
  );
};

export default TPModalImageSelector;

//TODO:
//resaltar archivo seleccionado solo si es archivo, no para carpetas
//botones de ok, cancel, botón de ok disponible unicamente si hay un archiovo seleccionado
