import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { areEqual } from "@/helpers/ValidateChanges";
import { ScriptsMode, ScriptStep } from "@/models/Scripts/ScriptModel";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { FC, useEffect, useState } from "react"
import { useScriptsLabels } from "../Assets/labelling";

interface StepManagementProperties {
  onCompletion: (saved: boolean) => void;
  unavailableOrders: number[];
  stepId: string;
  scriptId: string;
  scriptName: string;
}

interface ScriptStepFormHandlerModel {
  order: (value: number) => void;
  description: (value: string) => void;
  helpText: (value: string) => void;
}

const blankForm: ScriptStep = {
  additionalData: [],
  defaultNextStepId: "-1",
  description: '',
  order: 0,
  helpText: '',
  id: "0",
  scriptId: "",
  scriptName: ""
}

export const StepManagement: FC<StepManagementProperties> = function ({
  onCompletion,
  stepId,
  unavailableOrders,
  scriptId,
  scriptName
}) {
  const { labels } = useScriptsLabels();
  const [initialForm, setInitialForm] = useState<ScriptStep>(structuredClone(blankForm));
  const [currentForm, setCurrentForm] = useState<ScriptStep>(structuredClone(blankForm));
  const [orderInput, setOrderInput] = useState("");
  const [orderError, setOrderError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formHandler: ScriptStepFormHandlerModel = {
    order: (newOrder) => {
      setCurrentForm(oldForm => {
        return {
          ...oldForm,
          order: newOrder
        }
      })
    },
    description: (newDescription) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(newDescription, 'text/html');
      setCurrentForm(oldForm => {
        return {
          ...oldForm,
          description: newDescription,
        }
      });
    }
    ,
    helpText: (newHelpText) =>
      setCurrentForm(oldForm => {
        return {
          ...oldForm,
          helpText: newHelpText
        }
      })
  }

  const saveStep = async function () {
    setIsLoading(true);
    if (!stepId) {
      ScriptsService.createStep({
        order: Number(orderInput),
        scriptId: scriptId,
        description: TPGlobal.stringToUTF8(currentForm.description).join(", "),
        helpText: TPGlobal.stringToUTF8(currentForm.helpText).join(", ")
      }, true, true, [200])
        .then(() => onCompletion(true))
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
    } else {
      ScriptsService.updateStep(
        {
          defaultNextStepId: currentForm.defaultNextStepId,
          description: TPGlobal.stringToUTF8(currentForm.description).join(", "),
          helpText: TPGlobal.stringToUTF8(currentForm.helpText).join(", "),
          id: currentForm.id,
          order: Number(orderInput),
          scriptId: currentForm.scriptId
        },
        true, true, [200])
        .then(() => onCompletion(true))
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false))
    }
  }

  useEffect(() => {
    let errorMessage = '';
    const num = Number(orderInput);
    formHandler.order(num);
    if (Number.isNaN(num)) {
      errorMessage = labels.ErrorOrderNumeric;
    } else if (num <= 0) {
      errorMessage = labels.ErrorOrderPositive;
    } else if ((num !== initialForm.order) && unavailableOrders.includes(num)) {
      errorMessage = labels.ErrorOrderTaken;
    } else if ((num >= 10000)) {
      errorMessage = "_order_must_be_less_than_10000";
    }
    setOrderError(errorMessage);
  }, [orderInput])

  useEffect(() => {
    if (stepId.trim().length > 0) {
      setIsLoading(true);
      ScriptsService.getStepById(stepId, false, true, [200])
        .then(st => {
          setInitialForm({ ...st });
          setCurrentForm({ ...st });
          setOrderInput(String(st.order));
        })
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [])

  return (
    <TPLoadingOverlay active={isLoading}>
      <div id='step-add-menu' className="scripts-main-container">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', width: '50%' }}>
          <div className="script-admin-header">
            <TPPageTitle>{stepId ? labels.EditStep : labels.NewStep}</TPPageTitle>
          </div>
          <div style={{ width: "50%" }}>
            <TPTextBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOrderInput(e.target.value);
                formHandler.order(Number(e.target.value));
              }}
              value={orderInput}
              isMandatory
              placeholder={labels.ExampleOrder}
              labelText={labels.Order}
              errorMessage={orderError}
            />
          </div>
          <div>
            <label style={{ fontSize: '13px' }}>{labels.Description}<b style={{ color: 'red' }}>*</b></label>
            <TPEditor
              onChange={(value: string) => formHandler.description(value)}
              value={currentForm.description}
              style={{ maxHeight: '200px', overflow: 'auto' }}
              placeholder={labels.TypeYourDescription}
            />
          </div>
          <div>
            <label style={{ fontSize: '13px' }}>{labels.HelpText}</label>
            <TPEditor
              onChange={(value: string) => formHandler.helpText(value)}
              value={currentForm.helpText}
              style={{ maxHeight: '200px', overflow: 'auto' }}
              placeholder={labels.TypeYourHelp}
            />
          </div>
        </div>
        <div className="script-management-footer">
          <div className="script-footer-buttons-container">
            <TPButton
              onClick={() => onCompletion(false)}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: 'white',
                color: 'purple'
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => saveStep()}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
              disabled={
                currentForm.description.trim().length == 0 ||
                orderError != '' ||
                orderInput.trim().length == 0 ||
                areEqual(initialForm, currentForm)
              }
            >
              {labels.SaveStep}
            </TPButton>
          </div>
        </div>
      </div>
    </TPLoadingOverlay>
  )
}