const ExpandLessIcon = ({ ...props }) => {
  return (
    <span
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M1.41 0.580078L6 5.17008L10.59 0.580078L12 2.00008L6 8.00008L0 2.00008L1.41 0.580078Z"
                        fill="black"
                    />
                </svg>
                  `,
      }}
    ></span>
  );
};

export default ExpandLessIcon;
