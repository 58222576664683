import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type TreeInputDTO = {
  id: string;
  name: string;
  isAlphabeticOrder: boolean;
  mustSelectLastBranch: boolean;
  comments: string;
  isAutoincrement: boolean;
  isClassifier: boolean;
  isActive: boolean;
  nameLocalizedValues: Array<RecordLocalizedModel>;
};

export class TreeInputDTOValidator extends Validator<TreeInputDTO> {
  constructor() {
    super();

    const resourceSet = "TreeInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("name")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTONameEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidName")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidNameLength");

      this.ruleForEach("nameLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidName")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 200;
        })
        .withMessage(resourceSet + "|InputDTOInvalidNameLength");

      this.ruleFor("comments")
        .must((comments) => {
          if (comments.trim() === "") {
            return true;
          }
          return TPGlobal.TPSanitize(comments) == comments;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments")
        .maxLength(5000)
        .withMessage(resourceSet + "|InputDTOInvalidCommentsLength");
    } catch (error) {}
  }
}
