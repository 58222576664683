import { TPI18N } from "@/services/I18nService";

export class LocationLanguagesGroupDashboard {

    getLocationNameTable: LocationNameTableCase = new LocationNameTableCase();
    getLocationNamUser: LocationNameTableUser = new LocationNameTableUser();
    getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();

    public handlelocationCase = async (resourceSet: string) => {
        this.getLocationNameTable.title = await TPI18N.GetText(resourceSet, "TitleLabel");
        this.getLocationNameTable.tabCase = await TPI18N.GetText(resourceSet, "TabCaseLabel");
        this.getLocationNameTable.tabUser = await TPI18N.GetText(resourceSet, "TabUserLabel");
        this.getLocationNameTable.groupLabel = await TPI18N.GetText(resourceSet, "GroupLabelLabel");
        this.getLocationNameTable.columnStatus = await TPI18N.GetText(resourceSet, "ColumnStatusLabel");
        this.getLocationNameTable.columnCustomState = await TPI18N.GetText(resourceSet, "ColumnCustomStateLabel");
        this.getLocationNameTable.columnCaseNumber = await TPI18N.GetText(resourceSet, "ColumnCaseNumberLabel");
        this.getLocationNameTable.columnPromiseDate = await TPI18N.GetText(resourceSet, "ColumnPromiseDateLabel");
        this.getLocationNameTable.columnTerm = await TPI18N.GetText(resourceSet, "ColumnTermLabel");
        this.getLocationNameTable.columnResponsible = await TPI18N.GetText(resourceSet, "ColumnCaseHandingStartedLabel"); 
        this.getLocationNameTable.columnCaseHandingStarted = await TPI18N.GetText(resourceSet, "ColumnResponsibleLabel");
        this.getLocationNameTable.columnCaseHandingTime = await TPI18N.GetText(resourceSet, "ColumnCaseHandingTimeLabel");
        this.getLocationNameTable.columnCurrentTask = await TPI18N.GetText(resourceSet, "ColumnCurrentTaskLabel");
        this.getLocationNameTable.columnTimeInCurrentTask = await TPI18N.GetText(resourceSet, "ColumnTimeInCurrentTaskLabel");
        this.getLocationNameTable.thereAreNoRecordsToShow = await TPI18N.GetText(resourceSet, "thereAreNoRecordsToShowLabel");
        this.getLocationNameTable.btnReassignment = await TPI18N.GetText(resourceSet, "BtnReassignment");
        this.getLocationNameTable.responsible = await TPI18N.GetText(resourceSet, "ResponsibleLabel");
        this.getLocationNameTable.closeCase = await TPI18N.GetText(resourceSet, "CloseCaseLabel");
        this.getLocationNameTable.localizedDescription = await TPI18N.GetText(resourceSet, "columnDescription");
        this.getLocationNameTable.timeLogged = await TPI18N.GetText(resourceSet, "TimeLoggedLabel");
        this.getLocationNameTable.timeReady = await TPI18N.GetText(resourceSet, "TimeReadyLabel");
        this.getLocationNameTable.workingTimeInCase = await TPI18N.GetText(resourceSet, "WorkingTimeInCase");
    };

    public handleLocationUser = async (resourceSet: string) => {
        this.getLocationNamUser.user = await TPI18N.GetText(resourceSet, "UserLabel");
        this.getLocationNamUser.status = await TPI18N.GetText(resourceSet, "ColumnStatusLabel");
        this.getLocationNamUser.currenCase = await TPI18N.GetText(resourceSet, "CurrenCaseLabel");
        this.getLocationNamUser.timeinCurrenCase = await TPI18N.GetText(resourceSet, "TimeinCurrenCaseLabel");
        this.getLocationNamUser.currenTask = await TPI18N.GetText(resourceSet, "ColumnCurrentTaskLabel");
        this.getLocationNamUser.timeInCurrentTask = await TPI18N.GetText(resourceSet, "TimeInCurrentTaskLabel");
        this.getLocationNamUser.workedCase = await TPI18N.GetText(resourceSet, "WorkedCaseLabel");
        this.getLocationNamUser.closeCase = await TPI18N.GetText(resourceSet, "CloseCaseLabel");
        this.getLocationNamUser.timeLogged = await TPI18N.GetText(resourceSet, "TimeLoggedLabel");
        this.getLocationNamUser.timeReady = await TPI18N.GetText(resourceSet, "TimeReadyLabel");
        this.getLocationNamUser.workingTimeInCase = await TPI18N.GetText(resourceSet, "WorkingTimeInCase");
        this.getLocationNamUser.thereAreNoRecordsToShow = await TPI18N.GetText(resourceSet, "thereAreNoRecordsToShowLabel");
        this.getLocationNamUser.description = await TPI18N.GetText(resourceSet, "columnDescription");
    }


    public locationGlobal = async (resourceSet: string) => {
        this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(resourceSet, "OkButton");
        this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(resourceSet, "DeleteLabel");
        this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(resourceSet, "BuikLoad");
        this.getLocationNameGlobal.search = await TPI18N.GetText(resourceSet, "Search");
        this.getLocationNameGlobal.refreshLabel = await TPI18N.GetText(resourceSet, "ReloadButton");
        this.getLocationNameGlobal.exportLabel = await TPI18N.GetText(resourceSet, "ExportButton");
        this.getLocationNameGlobal.require = await TPI18N.GetText(resourceSet, "InputDTORequired");
    };


}



export class LocationNameTableUser {
    user: string = "";
    status: string = "";
    currenCase: string = "";
    timeinCurrenCase: string = "";
    currenTask: string = "";
    timeInCurrentTask: string = "";
    workedCase: string = "";
    closeCase: string = "";
    timeLogged: string = "";
    timeReady: string = "";
    workingCase: string = "";
    workingTimeInCase: string = "";
    description: string = "";
    thereAreNoRecordsToShow: string = "";

}

export class LocationNameTableCase {
    title: string = "";
    tabCase: string = "";
    tabUser: string = "";
    groupLabel: string = "";
    columnStatus: string = "";
    columnCustomState: string = "";
    columnCaseNumber: string = "";
    columnPromiseDate: string = "";
    columnTerm: string = "";
    columnResponsible: string = "";
    columnCaseHandingStarted: string = "";
    columnCaseHandingTime: string = "";
    columnCurrentTask: string = "";
    columnTimeInCurrentTask: string = "";
    confirmReassignment: string = "";
    thereAreNoRecordsToShow: string = "";
    btnReassignment: string = "";
    responsible: string = "";
    localizedDescription: string = "";
    closeCase: string = "";
    timeLogged: string = "";
    timeReady: string = "";
    workingTimeInCase: string = "";
}

export class LocationNameGlobal {
    confirmAlertTitle: string = "";
    okAlertButton: string = "";
    cancelAlertButton: string = "";
    deleteLabel: string = "";
    updateLabel: string = "";
    buikLoad: string = "";
    search: string = "";
    refreshLabel: string = "";
    exportLabel: string = "";
    require: string = "";
}


