import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataEmailProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: string;
  onValueChange?: Function | null;
};

const TPAdditionalDataEmail = React.forwardRef(
  (
    {
      idControl,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory,
      defaultValue,
      onValueChange = null,
    }: TPAdditionalDataEmailProps,
    ref,
  ) => {
    const [textValue, setTextValue] = useState(defaultValue);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    //Screen resources
    const [invalidEmailErrorMessage, setInvalidemailErrorMessage] =
      useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");

    const loadResources = async () => {
      setInvalidemailErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidEmail",
        ),
      );
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        return textValue;
      },
      validateFromParent() {
        if (isMandatory && (!textValue || textValue.trim() === "")) {
          setErrorMessageValue(mandatoryErrorMessage);
          return false;
        }
        if (textValue && textValue.trim() !== "") {
          if (textValue.includes(";")) {
            textValue.split(";").map((item) => {
              if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                setErrorMessageValue(invalidEmailErrorMessage);
                return false;
              }
            });
          } else {
            if (!TPGlobal.regularExpressionCheckEmail.test(textValue)) {
              setErrorMessageValue(invalidEmailErrorMessage);
              return false;
            }
          }
        }
        return true;
      },
    }));

    const handlerOnTextChange = (e: any) => {
      setErrorMessageValue("");
      setTextValue(e.target.value);
      if (onValueChange) {
        onValueChange(e.target.value, idControl);
      }
    };

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group">
          <TPTextBox
            id="IdTextBox"
            labelText={labelText}
            isMandatory={isMandatory}
            value={textValue}
            onChange={handlerOnTextChange}
            errorMessage={errorMessageValue}
          />
        </div>
      );
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group">
          <TPTextBox
            id="IdTextBox"
            labelText={labelText}
            isMandatory={isMandatory}
            value={textValue}
            onChange={() => {
              TPGlobal.foo();
            }}
            disabled={true}
          />
        </div>
      );
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col text-break">
              <span>{textValue}</span>
            </div>
          </div>
        </React.Fragment>
      );
    };

    useEffect(() => {
      //load validation error messages
      loadResources();
    }, []);

    useEffect(() => {
      setTextValue(defaultValue);
    }, [defaultValue]);

    return (
      <>
        {modeUI == TPAddtionalDataUIModes.Collect
          ? renderCollectMode()
          : modeUI == TPAddtionalDataUIModes.View1
            ? renderViewMode1()
            : renderViewMode2()}
      </>
    );
  },
);

export default TPAdditionalDataEmail;
