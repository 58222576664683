import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "../bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export enum AdditionalDataYesNoRenderMethodEnum {
  Combobox = "Combobox",
  Checkbox = "Checkbox",
  Option = "Option",
}

export enum AdditionalDataYesNoCaption {
  YesNoOption = "YesNoOption",
  TrueFalseOption = "TrueFalseOption",
}

export type TPAdditionalDataYesNoProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: string;
  onValueChange?: Function | null;
  renderMethod: AdditionalDataYesNoRenderMethodEnum;
  caption: AdditionalDataYesNoCaption;
};

const TPAdditionalDataYesNo = React.forwardRef(
  (
    {
      idControl,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory,
      defaultValue,
      onValueChange = null,
      renderMethod,
      caption,
    }: TPAdditionalDataYesNoProps,
    ref,
  ) => {
    var defaultValueBool: boolean = false;
    if (renderMethod == AdditionalDataYesNoRenderMethodEnum.Checkbox) {
      defaultValueBool = defaultValue === "1";
    }

    const [additionalDataBoolValue, setAdditionalDataBoolValue] =
      useState(defaultValueBool);
    const [additionalDataStrValue, setAdditionalDataStrValue] =
      useState(defaultValue);
    const [optionList, setOptionList] = useState<Array<TPKeyValue>>([]);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
      useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
    const [optionYesLabel, seOptionYesLabel] = useState("");
    const [optionNoLabel, setOptionNoLabel] = useState("");
    const [isReady, setIsReady] = useState(false);

    const loadResources = async () => {
      let newOptionList = [...optionList];
      if (renderMethod == AdditionalDataYesNoRenderMethodEnum.Combobox) {
        newOptionList.push({ key: "", value: "--" });
      }
      switch (caption) {
        case AdditionalDataYesNoCaption.TrueFalseOption:
          seOptionYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionTrue"),
          );
          setOptionNoLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionFalse"),
          );

          newOptionList.push({
            key: "1",
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              "OptionTrue",
            ),
          });
          newOptionList.push({
            key: "0",
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              "OptionFalse",
            ),
          });

          break;
        case AdditionalDataYesNoCaption.YesNoOption:
          seOptionYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
          );
          setOptionNoLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
          );

          newOptionList.push({
            key: "1",
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              "OptionYes",
            ),
          });
          newOptionList.push({
            key: "0",
            value: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
          });

          break;
      }
      setOptionList(newOptionList);

      setInvalidCharactersErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidIdChar",
        ),
      );
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );

      setIsReady(true);
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        switch (renderMethod) {
          case AdditionalDataYesNoRenderMethodEnum.Checkbox:
            return additionalDataBoolValue ? "1" : "0";
          case AdditionalDataYesNoRenderMethodEnum.Combobox:
          case AdditionalDataYesNoRenderMethodEnum.Option:
            return additionalDataStrValue;
          default:
            return "";
        }
      },
      validateFromParent() {
        switch (renderMethod) {
          case AdditionalDataYesNoRenderMethodEnum.Checkbox:
            return true;
          case AdditionalDataYesNoRenderMethodEnum.Combobox:
          case AdditionalDataYesNoRenderMethodEnum.Option:
            if (isMandatory && additionalDataStrValue.trim() === "") {
              setErrorMessageValue(mandatoryErrorMessage);
              return false;
            }
            if (
              additionalDataStrValue.trim() !==
              TPGlobal.TPSanitize(additionalDataStrValue.trim())
            ) {
              setErrorMessageValue(invalidCharactersErrorMessage);
              return false;
            }
            break;

          default:
            return false;
        }
        return true;
      },
    }));

    const handleOnchangeBoolValue = () => {
      let newValue: boolean = !additionalDataBoolValue;

      setAdditionalDataBoolValue(newValue);
      if (onValueChange) {
        onValueChange(newValue ? "1" : "0", idControl);
      }
    };

    const handleOnchangeStrValue = (e: any) => {
      setErrorMessageValue("");
      setAdditionalDataStrValue(e.target.value);
      if (onValueChange) {
        onValueChange(e.target.value, idControl);
      }
    };
    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }
      switch (renderMethod) {
        case AdditionalDataYesNoRenderMethodEnum.Checkbox:
          return (
            <TPCheckBox
              id="IdCheckBox"
              labelText={labelText}
              checked={additionalDataBoolValue}
              onChange={(e: any) => handleOnchangeBoolValue()}
            ></TPCheckBox>
          );
        case AdditionalDataYesNoRenderMethodEnum.Combobox:
          return (
            <TPSelect
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
              labelText={labelText}
            ></TPSelect>
          );
        case AdditionalDataYesNoRenderMethodEnum.Option:
          return (
            <TPRadioGroup
              id="IdRadioGroup"
              labelText={labelText}
              value={additionalDataStrValue}
              source={optionList}
              onChange={handleOnchangeStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
            ></TPRadioGroup>
          );
        default:
          return <div>Error</div>;
      }
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      switch (renderMethod) {
        case AdditionalDataYesNoRenderMethodEnum.Checkbox:
          return (
            <TPCheckBox
              id="IdCheckBox"
              disabled={true}
              labelText={labelText}
              checked={additionalDataBoolValue}
              onChange={(e: any) => handleOnchangeBoolValue()}
            ></TPCheckBox>
          );
        case AdditionalDataYesNoRenderMethodEnum.Combobox:
          return (
            <TPSelect
              labelText={labelText}
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              disabled={true}
            ></TPSelect>
          );
        case AdditionalDataYesNoRenderMethodEnum.Option:
          return (
            <TPRadioGroup
              id="IdRadioGroup"
              labelText={labelText}
              value={additionalDataStrValue}
              source={optionList}
              onChange={handleOnchangeStrValue}
              disabled={true}
            ></TPRadioGroup>
          );
        default:
          return <div>Error</div>;
      }
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {renderMethod == AdditionalDataYesNoRenderMethodEnum.Checkbox && (
                <span>
                  {additionalDataBoolValue ? optionYesLabel : optionNoLabel}
                </span>
              )}
              {renderMethod !==
                AdditionalDataYesNoRenderMethodEnum.Checkbox && (
                <span>
                  {additionalDataStrValue == "1"
                    ? optionYesLabel
                    : optionNoLabel}
                </span>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    };

    useEffect(() => {
      //if (renderMethod != AdditionalDataYesNoRenderMethodEnum.Checkbox) {
      loadResources();
      //}
    }, []);

    useEffect(() => {
      let defaultValueBool: boolean = false;
      if (renderMethod == AdditionalDataYesNoRenderMethodEnum.Checkbox) {
        defaultValueBool = defaultValue === "1";
      }
      setAdditionalDataBoolValue(defaultValueBool);

      setAdditionalDataStrValue(defaultValue);
    }, [defaultValue]);

    return (
      <>
        {isReady && (
          <React.Fragment>
            {modeUI == TPAddtionalDataUIModes.Collect
              ? renderCollectMode()
              : modeUI == TPAddtionalDataUIModes.View1
                ? renderViewMode1()
                : renderViewMode2()}
          </React.Fragment>
        )}
      </>
    );
  },
);

export default TPAdditionalDataYesNo;
