import TPGlobal from "@/helpers/TPGlobal";
import { FC } from "react";
import { StyledCockpitDate } from "../supervisor-cockpit-styles";

const CockpitSubDate: FC = () => {
  const formatDate = (): string => {
    const today = new Date();

    return new Intl.DateTimeFormat(TPGlobal.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(today);
  };

  return <StyledCockpitDate>{formatDate()}</StyledCockpitDate>;
};

export default CockpitSubDate;
