import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
export interface WorkflowAssignmentInputDTO {
  guidId: string;
  workflowTypeId: string;
  baseLevelId: string;
  branchTree1Id: string;
  branchTree2Id: string;
  branchTree3Id: string;
  branchTree4Id: string;
  branchTree5Id: string;
  weight: string;
  typistGuid: string;
  baseLevelSourceId: string;
}

export class WorkflowAssignmentInputDTOValidator extends Validator<WorkflowAssignmentInputDTO> {
  constructor() {
    super();

    const resourceSet = "WorkflowAssignmentInsertUpdateComponent";

    try {
      //assignment guid id
      this.ruleFor("guidId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmptyGuidId")
        .must((guidId) => {
          return TPGlobal.TPSanitize(guidId) == guidId;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidGuidId")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidGuidIdLength");

      //workflowTypeId
      this.ruleFor("workflowTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmptyWorkflowTypeId")
        .must((workflowTypeId) => {
          let check = TPGlobal.checkSpecialCharacters(workflowTypeId);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidWorkflowTypeId")
        .must((workflowTypeId) => {
          return TPGlobal.TPSanitize(workflowTypeId) == workflowTypeId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidWorkflowTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvaliWorkflowTypeIdLength");

      //baselevel is similar to next validator
      this.ruleFor("baseLevelId")
        .must((baseLevelId) => {
          let check = TPGlobal.checkSpecialCharacters(baseLevelId);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceId")
        .must((baseLevelId) => {
          return TPGlobal.TPSanitize(baseLevelId) == baseLevelId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceIdLength");

      //baseLevelSourceId is similar to previous validator
      this.ruleFor("baseLevelSourceId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBaseLevelSourceIdEmpty")
        .must((baseLevelId) => {
          let check = TPGlobal.checkSpecialCharacters(baseLevelId);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceId")
        .must((baseLevelSourceId) => {
          return TPGlobal.TPSanitize(baseLevelSourceId) == baseLevelSourceId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelSourceIdLength");

      //branch 1
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("branchTree1Id")
        .must((branchTree1Id) => {
          let check = TPGlobal.checkSpecialCharacters(branchTree1Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree1Id")
        .must((branchTree1Id) => {
          return TPGlobal.TPSanitize(branchTree1Id) == branchTree1Id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidBranchTree1Id",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree1IdLength");

      //branch 2
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("branchTree2Id")
        .must((branchTree2Id) => {
          let check = TPGlobal.checkSpecialCharacters(branchTree2Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree2Id")
        .must((branchTree2Id) => {
          return TPGlobal.TPSanitize(branchTree2Id) == branchTree2Id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidBranchTree2Id",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree2IdLength");

      //branch 3
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("branchTree3Id")
        .must((branchTree3Id) => {
          let check = TPGlobal.checkSpecialCharacters(branchTree3Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree3Id")
        .must((branchTree3Id) => {
          return TPGlobal.TPSanitize(branchTree3Id) == branchTree3Id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidBranchTree3Id",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree3IdLength");

      //branch 4
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("branchTree4Id")
        .must((branchTree4Id) => {
          let check = TPGlobal.checkSpecialCharacters(branchTree4Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree4Id")
        .must((branchTree4Id) => {
          return TPGlobal.TPSanitize(branchTree4Id) == branchTree4Id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidBranchTree4Id",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree4IdLength");

      //branch 5
      //even though it should be empty when you use it must pass a value
      //but remember to remove it before post it to server
      this.ruleFor("branchTree5Id")
        .must((branchTree5Id) => {
          let check = TPGlobal.checkSpecialCharacters(branchTree5Id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree5Id")
        .must((branchTree5Id) => {
          return TPGlobal.TPSanitize(branchTree5Id) == branchTree5Id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidBranchTree5Id",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBranchTree5IdLength");

      //weight
      this.ruleFor("weight")
        .must((x) => {
          return x.toString().trim() !== "";
        })
        .withMessage(resourceSet + "|InputDTOEmptyWeight")
        .must((x) => {
          return !isNaN(+x);
        })
        .withMessage(resourceSet + "|InputDTOInvalidWeight")
        .must((x) => {
          return parseFloat(x) >= 0;
        })
        .withMessage(resourceSet + "|InputDTOInvalidWeight");
    } catch (error) {}
  }
}
