import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCustomAutocomplete from "@/components/TPCustomAutocomplete/TPCustomAutocomplete";
import TPModifiedFileUploader from "@/components/TPModifiedFileUploader/ModifiedFileUploader";
import { TPPageSectionTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import { CustomerPreferredService } from "@/services/CustomerPreferredService";
import { FunctionAssignmentsService } from "@/services/FunctionAssignmentsService";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import { UserService } from "@/services/UserService";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import {
  ContainerTabStyle,
  TabActiveStyle,
} from "../FormDesigner/StyleFromDesigner";
import ReassignAutocompleteItem from "./components/ReassignAutocompleteItem";
import ReassignUsersFunctionsComponent from "./ReassignUsersFunctionsComponent";
import ReassignUsersGroupsComponent from "./ReassignUsersGroupsComponent";
import ReassignUsersPreferredCustomersComponent from "./ReassignUsersPreferredCustomersComponent";
import ReassignUsersSubordinatesComponent from "./ReassignUsersSubordinatesComponent";
import ReassignUsersTasksComponent from "./ReassignUsersTasksComponent";

enum pageEnum {
  functions,
  tasks,
  groups,
  subordinates,
  preferredCustomers,
}

const ReassignUsersAdmin = () => {
  const componentFileName: string = "ReassignUsersAdmin.tsx";

  const resourceSetSelectReassignUsers = "SelectReassignUsersComponent";

  const [currentUserAutocompleteOptions, setCurrentUserAutocompleteOptions] =
    useState<Array<TPKeyValue>>([]);
  const [newUserAutocompleteOptions, setNewUserAutocompleteOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [selectedCurrentUser, setSelectedCurrentUser] =
    useState<TPKeyValue | null>(null);
  const [selectedNewUser, setSelectedNewUser] = useState<TPKeyValue | null>(
    null
  );
  const [page, setPage] = useState(pageEnum.functions);
  const [disableAutocomplete, setDisableAutocomplete] = useState(true);

  const [pageTitleLabel, setPageTitleLabel] = useState("");
  const [currentUserLabel, setCurrentUserLabel] = useState("");
  const [newUserLabel, setNewUserLabel] = useState("");
  const [functionsLabel, setFunctionsLabel] = useState("");
  const [tasksLabel, setTasksLabel] = useState("");
  const [groupsLabel, setGroupsLabel] = useState("");
  const [subordinatesLabel, setSubordinatesLabel] = useState("");
  const [preferredCustomersLabel, setPreferredCustomersLabel] = useState("");
  const [currentUserQuery, setCurrentUserQuery] = useState<string>("");
  const [newUserQuery, setNewUserQuery] = useState<string>("");

  const loadResources = async () => {
    setPageTitleLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "PageTitleLabel")
    );
    setCurrentUserLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "CurrentUserLabel")
    );
    setNewUserLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "NewUserLabel")
    );
    setFunctionsLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "FunctionsLabel")
    );
    setTasksLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "TasksLabel")
    );
    setGroupsLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "GroupsLabel")
    );
    setSubordinatesLabel(
      await TPI18N.GetText(resourceSetSelectReassignUsers, "SubordinatesLabel")
    );
    setPreferredCustomersLabel(
      await TPI18N.GetText(
        resourceSetSelectReassignUsers,
        "PreferredCustomersLabel"
      )
    );
  };

  const [functionsNumber, setFunctionsNumber] = useState(0);
  const [tasksNumber, setTasksNumber] = useState(0);
  const [groupsNumber, setGroupsNumber] = useState(0);
  const [subordinatesNumber, setSubordinatesNumber] = useState(0);
  const [preferredCustomersNumber, setPreferredCustomersNumber] = useState(0);

  const [loading, setLoading] = useState(false);

  // const handleOnAutocompleteChange = (value: any) => {
  //     setSelectedCurrentUser(value[0]);
  //     if (value[0] !== undefined) {
  //         getTabCount(value[0].key);

  //     }
  //     setDisableAutocomplete(false);
  //     setFunctionsNumber(0);
  //     setTasksNumber(0);
  //     setGroupsNumber(0);
  //     setSubordinatesNumber(0);
  //     setPreferredCustomersNumber(0);
  // };

  useEffect(() => {
    if (currentUserQuery.trim().length == 0) {
      setSelectedCurrentUser(null);
    }
    if (newUserQuery.trim().length == 0) setSelectedNewUser(null);
    const intervalId = setTimeout(() => {
      if (currentUserQuery.trim().length > 0) {
        const tasktypeServiceInstance = new UserService();
        tasktypeServiceInstance
          .getActiveUsersBySearchParameter(currentUserQuery, false, false, [
            200,
          ])
          .then((response) =>
            setCurrentUserAutocompleteOptions(
              response.map((item) => {
                return {
                  key: item.userGuid,
                  value: item.login,
                  value2: item.name,
                };
              })
            )
          );
      }
      if (newUserQuery.trim().length > 0) {
        const tasktypeServiceInstance = new UserService();
        tasktypeServiceInstance
          .getActiveUsersBySearchParameter(newUserQuery, false, false, [200])
          .then((response) =>
            setNewUserAutocompleteOptions(
              response.map((item) => {
                return {
                  key: item.userGuid,
                  value: item.login,
                  value2: item.name,
                };
              })
            )
          );
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentUserQuery, newUserQuery]);

  useEffect(() => {
    if (selectedCurrentUser) {
      getTabCount(selectedCurrentUser.key);
      setDisableAutocomplete(false);
      setFunctionsNumber(0);
      setTasksNumber(0);
      setGroupsNumber(0);
      setSubordinatesNumber(0);
      setPreferredCustomersNumber(0);
    }
  }, [selectedCurrentUser]);

  const getTabCount = async (guid: string) => {
    let serviceFunctionsAssignment = new FunctionAssignmentsService();
    let serviceTasks = new CaseService();
    let serviceGroups = new GroupsService();
    let serviceSubordinates = new UserService();
    let servicePreferredCustomers = new CustomerPreferredService();

    let expectedCodes: Array<number> = [200, 404];

    try {
      setLoading(true);

      let functionsResponse =
        await serviceFunctionsAssignment.getFunctionAssignmentsByUserId(
          guid,
          false,
          true,
          expectedCodes
        );

      setFunctionsNumber(functionsResponse.length);

      let servicesResponse = await serviceTasks.getCaseTasksByUserGuid(
        guid,
        false,
        true,
        expectedCodes
      );

      setTasksNumber(servicesResponse.length);

      let groupsResponse = await serviceGroups.getGroupsByUserId(
        guid,
        false,
        true,
        expectedCodes
      );

      setGroupsNumber(groupsResponse.length);

      let subordinatesResponse = await serviceSubordinates.getUserSubalterns(
        guid,
        false,
        true,
        expectedCodes
      );

      setSubordinatesNumber(subordinatesResponse.length);

      let preferredCustomersResponse =
        await servicePreferredCustomers.getPreferredCustomers(
          guid,
          false,
          true,
          expectedCodes
        );

      setPreferredCustomersNumber(preferredCustomersResponse.length);

      setLoading(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTabCount ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getTabCount ex`);
      return 0;
    }
  };

  useEffect(() => {
    loadResources();
  }, []);

  const setFunctions = (quantity: number) => {
    setFunctionsNumber(quantity);
    setLoading(false);
  };

  const setTasks = (quantity: number) => {
    setTasksNumber(quantity);
    setLoading(false);
  };

  const setGroups = (quantity: number) => {
    setGroupsNumber(quantity);
    setLoading(false);
  };

  const setSubordinates = (quantity: number) => {
    setSubordinatesNumber(quantity);
    setLoading(false);
  };

  const setPreferredCustomers = (quantity: number) => {
    setPreferredCustomersNumber(quantity);
    setLoading(false);
  };

  const handleUserSwap = function () {
    const currentUser = structuredClone(selectedCurrentUser);
    const currentUserOptions = structuredClone(currentUserAutocompleteOptions);
    const currentUserQueryCopy = String(currentUserQuery);
    const newUser = structuredClone(selectedNewUser);
    const newUserQueryCopy = String(newUserQuery);
    const newUserOptions = structuredClone(newUserAutocompleteOptions);

    setSelectedCurrentUser(newUser);
    setCurrentUserQuery(newUserQueryCopy);
    setCurrentUserAutocompleteOptions(newUserOptions);

    setSelectedNewUser(currentUser);
    setNewUserQuery(currentUserQueryCopy);
    setNewUserAutocompleteOptions(currentUserOptions);
  };

  const [focusCurrent, setFocusCurrent] = useState("on");
  const [focusNew, setFocusNew] = useState("on");

  return (
    <ContentVerticalNoTabsStyled>
        <TPLoadingOverlay active={loading}>
        <div className="container-fluid">
          <TPPageTitle>{pageTitleLabel}</TPPageTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "30px",
            }}
          >
            <TPCustomAutocomplete
              input={currentUserQuery}
              children={currentUserAutocompleteOptions
                .filter(
                  (item) =>
                    !newUserAutocompleteOptions.find(
                      (newUser) => newUser.key === item.key
                    )
                )
                .map((item) => (
                  <ReassignAutocompleteItem
                    key={item.value + item.value2}
                    item={item}
                    onClick={() => {
                      setSelectedCurrentUser({ ...item });
                      setCurrentUserQuery(item.value2);
                      setFocusCurrent("off");
                      const setOn = setTimeout(
                        () => setFocusCurrent("on"),
                        500
                      );
                    }}
                    query={currentUserQuery}
                  />
                ))}
              onQuery={(input) => setCurrentUserQuery(input)}
              suggestionTimeout={100}
              title={currentUserLabel}
              placeholder=""
              mandatory
              focused={focusCurrent}
            />
            <button
              type="button"
              onClick={() => handleUserSwap()}
              style={{ border: "none", background: "none" }}
            >
              <Icon
                icon="fontisto:arrow-swap"
                width="24"
                height="24"
                style={{ color: "black", marginTop: "40px" }}
              />
            </button>
            <TPCustomAutocomplete
              input={newUserQuery}
              children={newUserAutocompleteOptions
                .filter(
                  (item) =>
                    !currentUserAutocompleteOptions.find(
                      (currentUser) => currentUser.key === item.key
                    )
                )
                .map((item) => (
                  <ReassignAutocompleteItem
                    key={item.value + item.value2}
                    item={item}
                    onClick={() => {
                      setSelectedNewUser(item);
                      setNewUserQuery(item.value2);
                      setFocusNew("off");
                      const setOn = setTimeout(() => setFocusNew("on"), 500);
                    }}
                  />
                ))}
              onQuery={(input) => setNewUserQuery(input)}
              suggestionTimeout={100}
              title={newUserLabel}
              placeholder=""
              mandatory
              disabled={!selectedCurrentUser}
              focused={focusNew}
            />
          </div>
          <hr />
          {selectedCurrentUser &&
          selectedCurrentUser.key != selectedNewUser?.key ? (
            <>
              <ContainerTabStyle style={{ gap: "30px" }}>
                <TabActiveStyle
                  activeBorder={page == pageEnum.functions}
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <TPButton
                    style={{
                      color: page == pageEnum.tasks ? "" : "gray",
                    }}
                    type={TPButtonTypes.empty}
                    text={"1"}
                    onClick={() => {
                      setPage(pageEnum.functions), setLoading(true);
                    }}
                    icon={TPIconTypes.default}
                  >
                    <TPPageSectionTitle
                      style={{
                        color: page == pageEnum.functions ? "#a00095" : "gray",
                        fontSize: "18px",
                      }}
                    >
                      {functionsLabel} {functionsNumber}
                    </TPPageSectionTitle>
                  </TPButton>
                </TabActiveStyle>
                <TabActiveStyle
                  activeBorder={page == pageEnum.tasks}
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <TPButton
                    style={{
                      color: page == pageEnum.tasks ? "" : "gray",
                    }}
                    type={TPButtonTypes.empty}
                    text={"1"}
                    onClick={() => {
                      setPage(pageEnum.tasks), setLoading(true);
                    }}
                    icon={TPIconTypes.default}
                  >
                    <TPPageSectionTitle
                      style={{
                        color: page == pageEnum.tasks ? "#a00095" : "gray",
                        fontSize: "18px",
                      }}
                    >
                      {tasksLabel} {tasksNumber}
                    </TPPageSectionTitle>
                  </TPButton>
                </TabActiveStyle>
                <TabActiveStyle
                  activeBorder={page == pageEnum.groups}
                  style={{
                    display: "inline-flex",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <TPButton
                    style={{
                      color: page == pageEnum.groups ? "" : "gray",
                    }}
                    type={TPButtonTypes.empty}
                    text={"1"}
                    onClick={() => {
                      setPage(pageEnum.groups), setLoading(true);
                    }}
                    icon={TPIconTypes.default}
                  >
                    <TPPageSectionTitle
                      style={{
                        color: page == pageEnum.groups ? "#a00095" : "gray",
                        fontSize: "18px",
                      }}
                    >
                      {groupsLabel} {groupsNumber}
                    </TPPageSectionTitle>
                  </TPButton>
                </TabActiveStyle>
                <TabActiveStyle
                  activeBorder={page == pageEnum.subordinates}
                  style={{
                    display: "inline-flex",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <TPButton
                    style={{
                      color: page == pageEnum.subordinates ? "" : "gray",
                    }}
                    type={TPButtonTypes.empty}
                    text={"1"}
                    onClick={() => {
                      setPage(pageEnum.subordinates), setLoading(true);
                    }}
                    icon={TPIconTypes.default}
                  >
                    <TPPageSectionTitle
                      style={{
                        color:
                          page == pageEnum.subordinates ? "#a00095" : "gray",
                        fontSize: "18px",
                      }}
                    >
                      {subordinatesLabel} {subordinatesNumber}
                    </TPPageSectionTitle>
                  </TPButton>
                </TabActiveStyle>
                <TabActiveStyle
                  activeBorder={page == pageEnum.preferredCustomers}
                  style={{
                    display: "inline-flex",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <TPButton
                    style={{
                      color: page == pageEnum.preferredCustomers ? "" : "gray",
                    }}
                    type={TPButtonTypes.empty}
                    text={"1"}
                    onClick={() => {
                      setPage(pageEnum.preferredCustomers), setLoading(true);
                    }}
                    icon={TPIconTypes.default}
                  >
                    <TPPageSectionTitle
                      style={{
                        color:
                          page == pageEnum.preferredCustomers
                            ? "#a00095"
                            : "gray",
                        fontSize: "18px",
                      }}
                    >
                      {preferredCustomersLabel} {preferredCustomersNumber}
                    </TPPageSectionTitle>
                  </TPButton>
                </TabActiveStyle>
              </ContainerTabStyle>
              {page == pageEnum.tasks && (
                <ReassignUsersTasksComponent
                  currentUser={selectedCurrentUser}
                  newUser={selectedNewUser}
                  callback={setTasks}
                />
              )}
              {page == pageEnum.functions && (
                <ReassignUsersFunctionsComponent
                  currentUser={selectedCurrentUser}
                  newUser={selectedNewUser}
                  callBack={setFunctions}
                />
              )}
              {page == pageEnum.groups && (
                <ReassignUsersGroupsComponent
                  currentUser={selectedCurrentUser}
                  newUser={selectedNewUser}
                  callback={setGroups}
                />
              )}
              {page == pageEnum.subordinates && (
                <ReassignUsersSubordinatesComponent
                  currentUser={selectedCurrentUser}
                  newUser={selectedNewUser}
                  callback={setSubordinates}
                />
              )}
              {page == pageEnum.preferredCustomers && (
                <ReassignUsersPreferredCustomersComponent
                  currentUser={selectedCurrentUser}
                  newUser={selectedNewUser}
                  callback={setPreferredCustomers}
                />
              )}
            </>
          ) : null}
        </div>
      </TPLoadingOverlay>
    </ContentVerticalNoTabsStyled>
  );
};
export default ReassignUsersAdmin;
