import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageSection,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  ContactInputDTO,
  ContactInputDTOValidator,
} from "@/models/Contacts/ContactInputDTO";
import { ContactViewModel } from "@/models/Contacts/ContactViewModel";
import {
  ConfigParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { ContactsService } from "@/services/ContactsService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { FC, useEffect, useState } from "react";

export type TPModalContactState = {
  mode: string;
  isShown: boolean;
  clientId: number;
  subsidiaryId: number | null;
  contactId: number;
};

type TPModalContactsInsertUpdateProps = {
  acceptLabel: string;
  cancelLabel: string;
  callBackAnswer: Function;
  modalState: TPModalContactState;
  showCheckboxToUseConctactAsReporterPerson?: boolean;
};

interface screenStateProps {
  clientId: number;
  subsidiaryId: number | null;
  name: string;
  contactType: string;
  phone: string;
  email: string;
  address: string;
  comments: string;
  useThisConctactAsPersonWhoReportsThisCase: boolean;

  //validaciones
  nameErrorMessage: string;
  contactTypeErrorMessage: string;
  phoneErrorMessage: string;
  emailErrorMessage: string;
  addressErrorMessage: string;
  commentsErrorMessage: string;
  [key: string]: any;
}

const TPModalContactsInsertUpdate: FC<TPModalContactsInsertUpdateProps> = ({
  acceptLabel,
  cancelLabel,
  callBackAnswer,
  modalState,
  showCheckboxToUseConctactAsReporterPerson = false,
}) => {
  //#region Init
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const componentFileName: string = "TPModalContactsInsertUpdate.tsx";
  const resourceSet: string = "TPModalContactsInsertUpdate";
  const screenStateInitialState: screenStateProps = {
    clientId: modalState.clientId,
    subsidiaryId: modalState.subsidiaryId,
    name: "",
    contactType: "",
    phone: "",
    email: "",
    address: "",
    comments: "",
    useThisConctactAsPersonWhoReportsThisCase: true,
    nameErrorMessage: "",
    contactTypeErrorMessage: "",
    phoneErrorMessage: "",
    emailErrorMessage: "",
    addressErrorMessage: "",
    commentsErrorMessage: "",
  };
  const [screenState, setScreenState] = useState<screenStateProps>(
    screenStateInitialState
  );

  const [contactTypeList, setContactTypeList] = useState<Array<TPKeyValue>>([]);

  //screen reosurces
  const [titleModal, setTitleModal] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [contactTypeLabel, setContactTypeLabel] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [addressLabel, setAddressLabel] = useState("");
  const [commentsLabel, setCommentsLabel] = useState("");
  const [contactSectionLabel, setContactSectionLabel] = useState("");
  const [commentsSectionLabel, setCommentsSectionLabel] = useState("");
  const [associateWithCaseSectionLabel, setAssociateWithCaseSectionLabel] =
    useState("");
  const [associateWithCaseLabel, setAssociateWithCaseLabel] = useState("");
  const [personWhoReportsNoteLabel, setPersonWhoReportsNoteLabel] =
    useState("");

  //#endregion Init

  const loadResources = async () => {
    setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
    setContactTypeLabel(await TPI18N.GetText(resourceSet, "ContactTypeLabel"));
    setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
    setPhoneLabel(await TPI18N.GetText(resourceSet, "PhoneLabel"));
    setAddressLabel(await TPI18N.GetText(resourceSet, "AddressLabel"));
    setCommentsLabel(await TPI18N.GetText(resourceSet, "CommentsLabel"));
    setTitleModal(
      await TPI18N.GetText(resourceSet, `Title${modalState.mode}Modal`)
    );
    setContactSectionLabel(
      await TPI18N.GetText(resourceSet, "ContactSectionLabel")
    );
    setCommentsSectionLabel(
      await TPI18N.GetText(resourceSet, "CommentsSectionLabel")
    );
    setAssociateWithCaseSectionLabel(
      await TPI18N.GetText(resourceSet, "AssociateWithCaseSectionLabel")
    );
    setAssociateWithCaseLabel(
      await TPI18N.GetText(resourceSet, "AssociateWithCaseLabel")
    );
    setPersonWhoReportsNoteLabel(
      await TPI18N.GetText(resourceSet, "PersonWhoReportsNoteLabel")
    );

    //Load contact Type
    let parameterService = new ParametersService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await parameterService.getByParentIdAndFilterIsActive(
          ConfigParametersEnum.Custom_Contacts_Relationships,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );

      let newContactTypeList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newContactTypeList.unshift({ key: "", value: "--" });
      setContactTypeList(newContactTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`
      );
    }
  };

  const handlerOnOKClick = async () => {
    let recordInputDTO: ContactInputDTO = {
      contactId: modalState.contactId,
      clientId: screenState.clientId,
      subsidiaryId: screenState.subsidiaryId,
      name: screenState.name,
      relationshipId: screenState.contactType,
      phone: screenState.phone,
      email: screenState.email,
      address: screenState.address,
      comments: screenState.comments,
    };
    let inputDTOValidator = new ContactInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newScreenState = { ...screenState };
      if (resultValidator.name) {
        newScreenState.nameErrorMessage = await TPI18N.GetResource(
          resultValidator.name
        );
      } else {
        newScreenState.nameErrorMessage = "";
      }
      if (resultValidator.relationshipId) {
        newScreenState.contactTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.relationshipId
        );
      } else {
        newScreenState.contactTypeErrorMessage = "";
      }
      if (resultValidator.phone) {
        newScreenState.phoneErrorMessage = await TPI18N.GetResource(
          resultValidator.phone
        );
      } else {
        newScreenState.phoneErrorMessage = "";
      }
      if (resultValidator.email) {
        newScreenState.emailErrorMessage = await TPI18N.GetResource(
          resultValidator.email
        );
      } else {
        newScreenState.emailErrorMessage = "";
      }
      if (resultValidator.address) {
        newScreenState.addressErrorMessage = await TPI18N.GetResource(
          resultValidator.address
        );
      } else {
        newScreenState.addressErrorMessage = "";
      }
      if (resultValidator.comments) {
        newScreenState.commentsErrorMessage = await TPI18N.GetResource(
          resultValidator.comments
        );
      } else {
        newScreenState.commentsErrorMessage = "";
      }
      setScreenState(newScreenState);
      return;
    }

    if (modalState.mode === "Insert") {
      await insertContact(recordInputDTO);
    } else {
      await updateContact(recordInputDTO);
    }
  };

  const insertContact = async (inputDTO: ContactInputDTO) => {
    let serviceClient = new ContactsService();
    let expectedCodes: Array<number> = [200];
    let Id_Cont: number = 0;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertContact(
        inputDTO,
        false,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        Id_Cont = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_CONT" //todo bug different than update
        )[0]["value"];
        callBackAnswer(
          true,
          screenState.useThisConctactAsPersonWhoReportsThisCase,
          Id_Cont
        );
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertContact ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertContact ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateContact = async (inputDTO: ContactInputDTO) => {
    let serviceClient = new ContactsService();
    let expectedCodes: Array<number> = [200];
    let Id_Cont: number = 0;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateContact(
        inputDTO,
        false,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        Id_Cont = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "Id_CONT" //todo bug different than insert
        )[0]["value"];
        callBackAnswer(
          true,
          screenState.useThisConctactAsPersonWhoReportsThisCase,
          Id_Cont
        );
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateContact ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateContact ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    let newScreenState = { ...screenState };
    newScreenState[propertyName] = newValue;
    newScreenState[propertyName + "ErrorMessage"] = "";
    setScreenState(newScreenState);
  };

  const getContactByClientIdAndSubsidiaryId = async (
    clientId: number,
    subsidiaryId: number
  ) => {
    let serviceClient = new ContactsService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getContactByClientIdAndSubsidiaryId(
          clientId,
          subsidiaryId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<ContactViewModel>;
      recordInfo = [...responseRequest];
      let newScreenStateState: screenStateProps = { ...screenState };
      for (let i: number = 0; i <= recordInfo.length - 1; i++) {
        if (recordInfo[i].contactId == modalState.contactId) {
          newScreenStateState.address = recordInfo[i].address;
          newScreenStateState.contactType = recordInfo[i].relationshipId;
          newScreenStateState.email = recordInfo[i].email;
          newScreenStateState.name = recordInfo[i].name;
          newScreenStateState.phone = recordInfo[i].phone;
          newScreenStateState.comments = recordInfo[i].comments;
        }
      }

      setScreenState(newScreenStateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`
      );
      setIsLoadingScreen(false);
    }
  };

  const handleCaseAssociationChange = () => {
    let newScreenState = { ...screenState };
    newScreenState.useThisConctactAsPersonWhoReportsThisCase =
      !newScreenState.useThisConctactAsPersonWhoReportsThisCase;
    setScreenState(newScreenState);
  };

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (modalState.isShown) {
        if (modalState.mode === "Update") {
          //get contact Info
          await getContactByClientIdAndSubsidiaryId(
            modalState.clientId,
            modalState.subsidiaryId ?? 0
          );
        }
      }
    };

    loadData();
  }, [modalState]);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{titleModal}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  callBackAnswer(false, false);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"} isModal>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {contactSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          isMandatory={true}
                          labelText={contactTypeLabel}
                          onChange={(e: any) =>
                            handleInputTextChange("contactType", e.target.value)
                          }
                          dataSource={contactTypeList}
                          value={screenState.contactType}
                          errorMessage={screenState.contactTypeErrorMessage}
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handleInputTextChange("name", e.target.value)
                          }
                          value={screenState.name}
                          labelText={nameLabel}
                          errorMessage={screenState.nameErrorMessage}
                          maxLength={200}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={false}
                          onChange={(e: any) =>
                            handleInputTextChange("phone", e.target.value)
                          }
                          value={screenState.phone}
                          labelText={phoneLabel}
                          errorMessage={screenState.phoneErrorMessage}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={false}
                          onChange={(e: any) =>
                            handleInputTextChange("email", e.target.value)
                          }
                          value={screenState.email}
                          labelText={emailLabel}
                          errorMessage={screenState.emailErrorMessage}
                          maxLength={300}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={false}
                          onChange={(e: any) =>
                            handleInputTextChange("address", e.target.value)
                          }
                          value={screenState.address}
                          labelText={addressLabel}
                          errorMessage={screenState.addressErrorMessage}
                          maxLength={200}
                        />
                      </div>
                    </div>
                  </div>
                </TPPageSection>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {commentsSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextArea
                          id="IdTextArea"
                          onChange={(e: any) =>
                            handleInputTextChange("comments", e.target.value)
                          }
                          value={screenState.comments}
                          labelText={commentsLabel}
                          errorMessage={screenState.commentsErrorMessage}
                          maxLength={5000}
                          rows={7}
                        />
                      </div>
                    </div>
                  </div>
                </TPPageSection>

                {showCheckboxToUseConctactAsReporterPerson && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <TPPageSectionTitle>
                          {associateWithCaseSectionLabel}
                        </TPPageSectionTitle>
                      </div>
                    </div>
                    <TPPageSection>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <TPCheckBox
                              id="IdCheckBox"
                              labelText={associateWithCaseLabel}
                              checked={
                                screenState.useThisConctactAsPersonWhoReportsThisCase
                              }
                              onChange={(e: any) =>
                                handleCaseAssociationChange()
                              }
                            ></TPCheckBox>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mb-2">
                        <div className="col">
                          <div className="alert alert-info">
                            <span>{personWhoReportsNoteLabel}</span>
                          </div>
                        </div>
                      </div>
                    </TPPageSection>
                  </>
                )}
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      handlerOnOKClick();
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => {
                      callBackAnswer(false, false);
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalContactsInsertUpdate;
