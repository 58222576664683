// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
const convertArrayOfObjectsToCSV = (array: any, filteredData: any) => {
  let result: any;

  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  const keys = Object.keys(filteredData()[0]);

  // keys = keys.map(
  //   (item: any) => (item = item.toString().replaceAll(";", ":"))
  // );

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (item[key]) {
        result += item[key].toString().replaceAll(";", ":");
      }

      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
};

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export const downloadCSV = (array: any, filteredData: any) => {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array, filteredData);
  //   if (csv == null) return;
  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     debugger;
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();

  // var content = [
  //   ["#$%#$", "2nd title", "3rd title", "4th title"],
  //   ["a a a", "bb\nb", "cc,c", 'dd"d'],
  //   ["www", "xxx", "yyy", "zzz"],
  // ];

  let data = filteredData();

  const keys = Object.keys(data[0]);
  let finalData = [];
  let ix: number;
  let firstRow = [];
  for (ix = 0; ix <= keys.length - 1; ix++) {
    firstRow.push(keys[ix]);
  }
  finalData.push(firstRow);

  for (ix = 0; ix <= data.length - 1; ix++) {
    let row = Object.keys(data[ix]).map(function (key) {
      return data[ix][key] ? data[ix][key].toString() : "";
    });

    finalData.push(row);
  }
  var content = finalData;

  var finalVal = "";

  for (var i = 0; i < content.length; i++) {
    var value = content[i];

    for (var j = 0; j < value.length; j++) {
      var innerValue = value[j];
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ";";
      finalVal += result;
    }

    finalVal += "\n";
  }

  var pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(finalVal),
  );
  pom.setAttribute("download", "export.csv");
  pom.click();
};

export const downloadCSVByArray = (data: any) => {
  const keys = Object.keys(data[0]);
  let finalData = [];
  let ix: number;
  let firstRow = [];
  for (ix = 0; ix <= keys.length - 1; ix++) {
    firstRow.push(keys[ix]);
  }
  finalData.push(firstRow);

  for (ix = 0; ix <= data.length - 1; ix++) {
    let row = Object.keys(data[ix]).map(function (key) {
      return data[ix][key] ? data[ix][key].toString() : "";
    });

    finalData.push(row);
  }
  var content = finalData;

  var finalVal = "";

  for (var i = 0; i < content.length; i++) {
    var value = content[i];

    for (var j = 0; j < value.length; j++) {
      var innerValue = value[j];
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ";";
      finalVal += result;
    }

    finalVal += "\n";
  }

  var pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(finalVal),
  );
  pom.setAttribute("download", "export.csv");
  pom.click();
};
