import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";

export class AttachmentService {
  serviceFileName: string = "AttachmentService.ts";

  public async deleteAttachmentByBlobId(
    blobId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/" + blobId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteAttachmentByBlobId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteAttachmentByBlobId`);
      throw new Error(`Error ${this.serviceFileName} deleteAttachmentByBlobId`);
    }
  }

  //   public async insertTemporaryAttachment(
  //     inputDTO: any,
  //     showPositiveMessage: boolean,
  //     showNegativeMessage: boolean,
  //     expectedCodes: Array<number>
  //   ): Promise<any> {
  //     let httpclient = new TPHTTPService();
  //     let url: string;
  //     url = "/attachment/temporary";

  //     let finalURL: string =
  //       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
  //     //headers
  //     //add this special header
  //     let contentHeader: TPKeyValue = {
  //       key: "Content-Type",
  //       value: "multipart/form-data",
  //     };
  //     headers.push(contentHeader);

  //     let response: HTTPResponse;
  //     try {
  //       response = await httpclient.postData(
  //         finalURL,
  //         inputDTO,
  //         expectedCodes,
  //         showPositiveMessage,
  //         showNegativeMessage,
  //         headers
  //       );
  //       return response;
  //     } catch (error) {
  //       TPLog.Log(
  //         `Error ${this.serviceFileName} insertTemporaryAttachment ex`,
  //         TPLogType.ERROR,
  //         error
  //       );
  //       console.error(`Error ${this.serviceFileName} insertTemporaryAttachment`);
  //       throw new Error(
  //         `Error ${this.serviceFileName} insertTemporaryAttachment`
  //       );
  //     }
  //   }
}
