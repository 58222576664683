import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { Validator } from "fluentvalidation-ts";

export interface HolidayInputDTO {
  calendarId: string;
  holiday: Date;
  description: string;
  isHalfHoliday: boolean;
  otherLocalizedValues: Array<RecordLocalizedModel>;
}

export class HolidayInputDTOValidator extends Validator<HolidayInputDTO> {
  constructor() {
    super();

    const resourceSet = "HolidayInsertUpdateComponent";

    try {
      this.ruleFor("calendarId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOCalendarIdEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidCalendarId")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCalendarIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("otherLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 100;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
    } catch (error) {}
  }
}
