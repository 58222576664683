import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { SchedulesInputDTO } from "@/models/Schedules/SchedulesInputDTO";
import { ScheduleViewModel } from "@/models/Schedules/SchedulesModels";

export class SchedulesService {
  serviceFileName: string = "SchedulesService.ts";

  public async getSchedulesByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ScheduleViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/schedule?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getSchedulesByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getSchedulesByFilter`);
      throw new Error(`Error ${this.serviceFileName} getSchedulesByFilter`);
    }
  }

  public async getScheduleById(
    relationId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<ScheduleViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/schedule/";
    url = url + relationId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getScheduleById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getScheduleById`);
      throw new Error(`Error ${this.serviceFileName} getScheduleById`);
    }
  }

  public async deleteScheduleById(
    scheduleId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/schedule/" + scheduleId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteScheduleById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteScheduleById`);
      throw new Error(`Error ${this.serviceFileName} deleteScheduleById`);
    }
  }

  public async insertSchedule(
    inputDTO: SchedulesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/schedule";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertSchedule ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertSchedule`);
      throw new Error(`Error ${this.serviceFileName} insertSchedule`);
    }
  }

  public async updateSchedule(
    inputDTO: SchedulesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/schedule";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateSchedule ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateSchedule`);
      throw new Error(`Error ${this.serviceFileName} updateSchedule`);
    }
  }
}
