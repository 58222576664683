import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPAvatar } from "@/components/TPAvatar/TPAvatar";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { UserActiveInactiveInputDTO } from "@/models/Users/UserActiveInactiveInputDTO";
import { UserViewModel } from "@/models/Users/UserModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { UserService } from "@/services/UserService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { TableIcon } from "../Projects/ProjectDetail/ProjectDetailTableIcons";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<UserViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface UserAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const UserAdmin = React.forwardRef(
  ({ callBackCommands }: UserAdminInterface, ref) => {
    const componentFileName: string = "UserAdmin.tsx";

    //Functions called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "UserAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [bulkLoadLabel, setBulkLoadLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [inactiveLabel, setInactiveLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //grid columns
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemColumnLabel] = useState("");
    const [loginColumnLabel, setLoginColumnLabel] = useState("");
    const [initialsColumnLabel, setInitialsColumnLabel] = useState("");
    const [calendarColumnLabel, setCalendarColumnLabel] = useState("");
    const [timeZoneColumnLabel, setTimeZoneColumnLabel] = useState("");
    const [
      authenticationMethodColumnLabel,
      setAuthenticationMethodColumnLabel,
    ] = useState("");

    //modal resources
    const [inactiveQuestion, setInactiveQuestion] = useState("");
    const [inactiveTitle, setInactiveTitle] = useState("");
    const [inactiveOkLabel, setInactiveOkLabel] = useState("");
    const [inactiveCanceLabel, setInactiveCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndUsersFilter = async () => {
      //modal
      setInactiveQuestion(
        await TPI18N.GetText(ResourceSet, "RecordInactiveConfirm")
      );
      setInactiveTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setInactiveOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setInactiveCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      //screen
      setInactiveLabel(await TPI18N.GetText(ResourceSet, "InactiveLabel"));
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setBulkLoadLabel(await TPI18N.GetText(ResourceSet, "BulkLoadButton"));
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      //grid columns
      setNameColumnLabel(await TPI18N.GetText(ResourceSet, "Name"));
      setLoginColumnLabel(
        await TPI18N.GetText(ResourceSet, "LoginColumnLabel")
      );
      setInitialsColumnLabel(
        await TPI18N.GetText(ResourceSet, "InitialsColumnLabel")
      );
      setCalendarColumnLabel(
        await TPI18N.GetText(ResourceSet, "CalendarColumnLabel")
      );
      setTimeZoneColumnLabel(
        await TPI18N.GetText(ResourceSet, "TimeZoneColumnLabel")
      );
      setAuthenticationMethodColumnLabel(
        await TPI18N.GetText(ResourceSet, "AuthenticationMethodColumnLabel")
      );
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      setIsSystemColumnLabel(await TPI18N.GetText(ResourceSet, "IsSystem"));

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(ResourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new UserService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getUsersByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);

          loadUserAvatars(result);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const loadUserAvatars = async (users: any[]) => {
      const validLogins = users
        .filter(({ login }) => login.includes("@"))
        .map(({ login }) => login);

      const avatarDimensions = { height: 648, width: 648 };
      const notificationSettings = {
        showPositiveMessage: false,
        showNegativeMessage: true,
        expectedCodes: [200],
      };

      const userService = new UserService();

      try {
        const avatarsResponse = await userService.postUserPhotos(
          avatarDimensions.height,
          avatarDimensions.width,
          validLogins,
          notificationSettings.showPositiveMessage,
          notificationSettings.showNegativeMessage,
          notificationSettings.expectedCodes
        );

        const usersWithAvatars = users.map((user) => {
          const avatarData = avatarsResponse.find(
            ({ userLogin }: any) => userLogin === user.login
          );
          return avatarData ? { ...user, avatar: avatarData.image } : user;
        });

        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: usersWithAvatars,
        };

        dispatchCommand(command1);
      } catch (error) {
        console.error("Failed to load user avatars:", error);
        throw error;
      }
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Function
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Bulk Load Function
    const handleNewBulLoadTab = () => {
      let command: any = { command: "new_bulk_load" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update function
    const handleUpdateClick = (id: string, description: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        recordDescription: description,
      };
      callBackCommands(command);
    };

    //Modal Question to delete function
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Inactivate user after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new UserService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        let recordInputDTO: UserActiveInactiveInputDTO = {
          guidUser: callBackData.recordId,
          isActive: false,
        };
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.activeInactiveUser(
            recordInputDTO,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
      if (event === TPIconTypes.downloadTemplate) handleNewBulLoadTab();
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<UserViewModel> = {
      isActive: isActiveColumnLabel,
      timeZoneId: timeZoneColumnLabel,
      authenticationMethodId: authenticationMethodColumnLabel,
      userGuid: "",
    };

    const customColumns: ColumnStyles<UserViewModel> = {
      userGuid: ({ value, item }) => (
        <TPAvatar
          src={item.avatar}
          size={30}
          circular={true}
          enableFullscreen={true}
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    const tableIcons: TableIcon[] = [
      {
        type: TPIconTypes.downloadTemplate,
        tooltip: bulkLoadLabel,
        status: true,
      },
    ];

    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { userGuid: string; name: string }) =>
          handleUpdateClick(e.userGuid, e.name),
      },
      {
        key: inactiveLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { userGuid: string }) =>
          handleDeleteClick(e.userGuid),
      },
    ];

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndUsersFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndUsersFilter ex`
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={inactiveTitle}
          yesLabel={inactiveOkLabel}
          noLabel={inactiveCanceLabel}
          question={inactiveQuestion}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-additional-data-type"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                hiddenColumns={[
                  "typistIdGuid",
                  "areaDescription",
                  "areaId",
                  "calendarId",
                  "defaultLanguage",
                  "email",
                  "firstName",
                  "functionDescription",
                  "id",
                  "functionId",
                  "isGroup",
                  "isOffline",
                  "isSystem",
                  "lastName",
                  "maxSimultaneousChat",
                  "microsoftIdentity",
                  "mobile",
                  "phone",
                  "profileDescription",
                  "profileId",
                  "roleUserAssigned",
                  "scheduleDescription",
                  "scheduleId",
                  "superiorIdGuid",
                  "teamDescription",
                  "teamId",
                ]}
                minorOptions={minorOptions}
                icons={tableIcons}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                // disableMinorOption={(item) => item.isActive}
                additionalFilters={additionalFilters}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default UserAdmin;
