import { Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { ReactElement } from "react";

type TableDateItemProps = {
  value: string;
};

function TableDateItem({ value }: TableDateItemProps): ReactElement {
  const timeZoneCities: { [key: string]: string } = {
    "America/Bogota": "Bogotá, Lima, Quito, Rio Branco",
    "America/Lima": "Bogotá, Lima, Quito, Rio Branco",
    "America/Caracas": "Caracas",
    "America/New_York": "New York, Washington D.C.",
    "America/Chicago": "Chicago, Houston, Dallas",
    "America/Denver": "Denver, Phoenix, Salt Lake City",
    "America/Los_Angeles": "Los Angeles, San Francisco, Seattle",
    "America/Toronto": "Toronto, Ottawa",
    "America/Vancouver": "Vancouver",
    "America/Mexico_City": "Mexico City",
    "America/Argentina/Buenos_Aires": "Buenos Aires",
    "Europe/London": "London, Lisbon, Casablanca",
    "Europe/Paris": "Paris, Brussels, Madrid",
    "Europe/Berlin": "Berlin, Rome, Vienna",
    "Europe/Istanbul": "Istanbul, Athens, Kyiv",
    "Europe/Moscow": "Moscow, St. Petersburg",
    "Asia/Dubai": "Dubai, Abu Dhabi",
    "Asia/Kolkata": "New Delhi, Kolkata, Mumbai",
    "Asia/Shanghai": "Shanghai, Beijing, Hong Kong",
    "Asia/Tokyo": "Tokyo, Osaka, Sapporo",
    "Asia/Singapore": "Singapore, Kuala Lumpur",
    "Australia/Sydney": "Sydney, Melbourne",
    "Australia/Perth": "Perth",
    "Africa/Johannesburg": "Johannesburg, Pretoria",
    "Africa/Nairobi": "Nairobi",
    "Pacific/Auckland": "Auckland, Wellington",
    "Pacific/Honolulu": "Honolulu",
  };

  const getTimeZoneTooltip = (): string => {
    const citiesZone = moment.tz.guess();
    const zoneName = timeZoneCities[citiesZone] || citiesZone;
    const offset = moment.tz(citiesZone).format("Z");
    return `YYYY/MM/DD HH:mm:ss | (UTC${offset}) ${zoneName}`;
  };

  const getDateFormatted = (date: string): string => {
    return moment(date).format("YYYY/MM/DD HH:mm:ss");
  };

  const styledTooltipText = (
    <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
      {getTimeZoneTooltip()}
    </Typography>
  );

  return (
    <Tooltip title={styledTooltipText}>
      <p style={{ margin: 0, cursor: "default" }}>{getDateFormatted(value)}</p>
    </Tooltip>
  );
}

export default TableDateItem;
