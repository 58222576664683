import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { CaseCustomStateViewModel } from "@/models/CaseCustomStates/CaseCustomStatesModel";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { BranchService } from "@/services/BranchService";
import { CaseCustomStatesService } from "@/services/CaseCustomStatesService";
import { HierarchyFolderTypeService } from "@/services/HierarchyFolderService";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

interface CaseCustomStatesAdminInterface {
  callBackCommands: Function;
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<CaseCustomStateViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const CaseCustomStatesAdmin = React.forwardRef(
  ({ callBackCommands }: CaseCustomStatesAdminInterface, ref) => {
    const componentFileName: string = "CaseCustomStateAdmin.tsx";

    //Functions called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "CaseCustomStatesAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [filterCustomStateTypeLabel, setFilterCustomStateTypeLabel] =
      useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //grid columns
    const [orderColumnLabel, setOrderColumnLabel] = useState("");
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [imageColumnLabel, setImageColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [typeColumnLabel, setTypeColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemColumnLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");


    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );
    //#endregion
    const loadResourcesAndCaseCustomStatesFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterCustomStateTypeLabel(
        await TPI18N.GetText(ResourceSet, "CustomStateTypeLabel"),
      );

      //grid columns
      setOrderColumnLabel(await TPI18N.GetText(ResourceSet, "Order"));
      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setImageColumnLabel(await TPI18N.GetText(ResourceSet, "Image"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(ResourceSet, "Description"),
      );
      setTypeColumnLabel(await TPI18N.GetText(ResourceSet, "Type"));
      setIsSystemColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsSystemRecord"),
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));


      //Filter
      let branchService = new BranchService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest = await branchService.getFirstLevelBranches(
          TPGlobal.idTreeForCustomStates, // todo
          TPActiveOptions.ACTIVE,
          TPActiveOptions.ACTIVE,
          false,
          true,
          expectedCodes,
        );

        let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return { key: item.id, value: item.localizedDescription };
          },
        );
        newTypeKeyValueList.unshift({ key: "", value: "--" });
        setFilterKeyValue(newTypeKeyValueList);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
        return;
      }
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            return (
              <div className="dropdown">
                <TPButton
                  id="IdButton"
                  dataBsToggle={true}
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    TPGlobal.foo();
                  }}
                  className={"menu-button"}
                >
                  <TPIcon iconType={TPIconTypes.moreVert} />
                </TPButton>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      onClick={(id: any) => handleDeleteClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {deleteLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(id: any) => handleUpdateClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {updateLabel}
                    </a>
                  </li>
                </ul>
              </div>
            );
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            return (
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={(id: string) => handleUpdateClick(row["id"])}
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            );
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //order
        newColumns.push({
          width: "100px",
          name: orderColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["order"];
          },
          selector: (row: { [x: string]: any }) => row["order"],
          sortable: true,
        });
        //id
        newColumns.push({
          width: "150px",
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["id"];
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //Imagen
        newColumns.push({
          name: imageColumnLabel,
          selector: (row: { [x: string]: any }) => row["imageThumbnail"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let image: string = row["imageThumbnail"];
            return <img style={{ width: "30%" }} src={image} alt="" />;
          },
        });
        //description primary language
        let languageColumnLabel: string;
        languageColumnLabel = descriptionColumnLabel;
        languageColumnLabel = (languageColumnLabel +
          " (" +
          TPGlobal.TPClientAvailableLanguages[0].name) as string;
        languageColumnLabel = languageColumnLabel + ")";
        newColumns.push({
          width: "250px",
          name: languageColumnLabel,
          selector: (row: { [x: string]: any }) => row["description"],
          sortable: true,
        });
        //description current language if it is different
        if (TPGlobal.TPClientAvailableLanguages[0].id !== TPGlobal.language) {
          languageColumnLabel = descriptionColumnLabel;
          languageColumnLabel = (languageColumnLabel +
            " (" +
            TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)) as string;
          languageColumnLabel = languageColumnLabel + ")";
          newColumns.push({
            width: "250px",
            name: languageColumnLabel,
            selector: (row: { [x: string]: any }) =>
              row["localizedDescription"],
            sortable: true,
          });
        }
        //Type
        newColumns.push({
          width: "400px",
          name: typeColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["localizedTypeDescription"];
          },
          selector: (row: { [x: string]: any }) =>
            row["localizedTypeDescription"],
          sortable: true,
        });
        //IsSystemRecord
        newColumns.push({
          name: isSystemRecordColumnLabel,
          selector: (row: { [x: string]: any }) => row["isSystemRecord"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isSystemRecord"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get caseCustomStates by filter

    // const getThumbnails = async (imageId: string) => {
    //   let serviceClient = new HierarchyFolderTypeService();
    //   let expectedCodes: Array<number> = [200];
    //   let responseRequest: any;
    //   try {
    //     if (imageId) {
    //       responseRequest = await serviceClient.getHierarchyFolderById(
    //         imageId,
    //         false,
    //         true,
    //         expectedCodes,
    //       );
    //     } else {
    //       responseRequest = "";
    //     }
    //     return responseRequest;
    //   } catch (error) {
    //     TPLog.Log(
    //       `Error ${componentFileName} getThumbnails ex`,
    //       TPLogType.ERROR,
    //       error,
    //     );
    //     console.error(`Error ${componentFileName} getThumbnails ex`);
    //     setIsLoadingScreen(false);
    //     return "";
    //   }
    // };

    const reloadDataGrid = async () => {
      let serviceClient = new CaseCustomStatesService();
      let expectedCodes: Array<number> = [200, 404];
      let responseRequest: any;
      try {
        setIsLoadingScreen(true);

        if (adminState.selectedFilter === "") {
          responseRequest = await serviceClient.getCaseCustomStates(
            false, //show positive message
            true, //show negative message
            expectedCodes,
          );
        } else {
          responseRequest = await serviceClient.getCaseCustomStatesByType(
            adminState.selectedFilter,
            false, //show positive message
            true, //show negative message
            expectedCodes,
          );
        }

        for (let i: number = 0; i <= responseRequest.length - 1; i++) {
          let thumbnailData = responseRequest[i].thumbnail;
          responseRequest[i].imageThumbnail = (thumbnailData == null ? "" : thumbnailData);
        }
        setIsLoadingScreen(false);

        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = async (e: any) => {
      let newSelectedFilter: string = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: newSelectedFilter,
      };
      dispatchCommand(command1);
    };

    //New Custom State
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update Custom State
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete Custom State
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete Custom State after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new CaseCustomStatesService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteCaseCustomStateById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "description",
        "localizedDescription",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: "",
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }


    const calendarTypeColumns: CustomColumnNames<CaseCustomStateViewModel> = {
      id: idColumnLabel,
      imageThumbnail:imageColumnLabel,
      description: (descriptionColumnLabel+` (${TPGlobal.TPClientAvailableLanguages[0].name})`),
      localizedTypeDescription:typeColumnLabel,
      isSystemRecord:isSystemRecordColumnLabel,
      
    };
 
    const customColumns: ColumnStyles<CaseCustomStateViewModel> = {
     
      isSystemRecord: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
      imageThumbnail: ({ value, item }) => (
        <img
          src={item.thumbnail}
          alt={item.thumbnail}
        />
      ),
     
    };

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterCustomStateTypeLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];
 
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string; }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string; }) => handleDeleteClick(e.id),
      },
    ];
 
    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndCaseCustomStatesFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndCaseCustomStatesFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndCaseCustomStatesFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);


    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        />

        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                      <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                      <TPButton
                        id="new-customer-type"
                        isDesignSystem={true}
                        onClick={handleNewClick}
                        withIcon={TPIconTypes.add}
                        orientationIcon="left"
                        style={{ padding: "1px 18px" }}
                      >
                        {newLabel}
                      </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={calendarTypeColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={["localizedDescription","imageId","thumbnail","customStateTypeId"]}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
      </TPLoadingOverlay>
      </>
    );
  },
);

export default CaseCustomStatesAdmin;
