import { ReactNode, createContext, useContext, useEffect } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPActiveOptions } from "../../models/Global/TPGlobalEnums";
import { useStatusAdministrationUsersStation } from "./useStatusAdministrationUsersStation";


type commandType = {
    type: commandsEnum;
    payload: any;
};
enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}
type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

type AppProviderDistributionListAdmin = {
    children: ReactNode;
};

class model {
    adminState: AdminStateType = initialStateBLL;
    dispatchCommand: React.Dispatch<commandType> = () => { };
    resourceSet: string = "";
    loading: boolean = false;
    setLoading: (data: boolean) => void = (data: boolean) => void {};
}

type modelContext = {
    dispatchCommand: React.Dispatch<commandType> ;
    adminState: AdminStateType;
    resourceSet: string;
    loading: boolean,
    setLoading: (data: boolean) => void;
}

export const AdministrationUsersStationContext = createContext<modelContext>(
    new model());

export const AppProviderAdministrationUsersStation: React.FC<AppProviderDistributionListAdmin> = ({ children }) => {

    const {
        dispatchCommand,
        adminState,
        resourceSet,
        loading,
        setLoading
    } = useStatusAdministrationUsersStation();


    useEffect(() => {
    }, []);


    return (
            <AdministrationUsersStationContext.Provider value={{
                dispatchCommand,
                adminState,
                resourceSet,
                loading,
                setLoading
            }}>      
                {children}      
            </AdministrationUsersStationContext.Provider>
    );
};


export const useAppDistributionListAdmin = () => {
    return useContext(AdministrationUsersStationContext);
}