import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";

type TPLanguageProps = {
  resourceSet: string;
  resourceId: string;
};

const TPLanguage = ({ resourceSet, resourceId }: TPLanguageProps) => {
  const [text, setText] = useState(resourceId);
  const [loading, setLoading] = useState(false);
  const loadResource = async () =>
    TPI18N.GetText(resourceSet, resourceId)
      .then((textTranslated) => {
        setText(textTranslated);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  useEffect(() => {
    //setLoading(true);
    loadResource();
  }, []);

  return (
    <>
      {loading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      {!loading && text ? text : resourceId}
    </>
  );
};

export default TPLanguage;
