/**
 * states through which a case can pass
 */
export enum CaseStatus {
  /**
   * Alert
   */
  AL = "AL",
  /**
   * Expired
   */
  EX = "EX",
  /**
   * Inserted case data
   */
  ID = "ID",
  /**
   * Inserted header data
   */
  IH = "IH",
  /**
   * On Hold
   */
  OH = "OH",
  /**
   * Pending
   */
  PE = "PE",
  /**
   * Reclassification
   */
  RC = "RC",
  /**
   * Received
   */
  RE = "RE",
  /**
   * Solved
   */
  SO = "SO",
}
