import { configureStore } from "@reduxjs/toolkit";
import mergeCustomersReducer from "./MergeCustomersSlice";

export const MergeCustomersStore = configureStore({
  reducer: {
    mainData: mergeCustomersReducer,
  },
});

export type MergeCustomersState = ReturnType<
  typeof MergeCustomersStore.getState
>;
export type MergeCustomersDispatch = typeof MergeCustomersStore.dispatch;
