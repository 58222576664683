import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { Profile, Restriction } from "@/models/ProfileRestrictions/ProfileRestrictionsModels";

export class ProfileRestrictionsServices {
    serviceFileName: string = "ProfileRestrictionsServices.ts";

    public async getProfiles(
        filterIsActive: number,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Profile[]> {
        const httpclient = new TPHTTPService();
        const url: string = `/profiles?FilterIsActive=${filterIsActive}`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getProfiles ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getProfiles`);
            throw new Error(`Error ${this.serviceFileName} getProfiles`);
        }
    }

    public async getRestrictions(
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<Restriction>> {
        const httpclient = new TPHTTPService();
        const url: string = "/parameters/parent/S_PRRE?FilterIsActive=1";

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getRestrictions ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getRestrictions`);
            throw new Error(`Error ${this.serviceFileName} getRestrictions`);
        }
    }

    public async getProfileRestrictions(
        baseLevelId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<Array<any>> {
        const httpclient = new TPHTTPService();
        const url: string = `/profiles/restrictions?baseLevelId=${baseLevelId}`;

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        try {
            const response: HTTPResponse = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getProfileRestrictions ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} getProfileRestrictions`);
            throw new Error(`Error ${this.serviceFileName} getProfileRestrictions`);
        }
    }

    public async toggleRestriction(
        profileId: string,
        restrictionId: string,
        baseLevelId: string,
        showPositiveMessage: boolean,
        showNegativeMessage: boolean,
        expectedCodes: Array<number>
    ): Promise<void> {
        const httpclient = new TPHTTPService();
        const url: string = "/profiles/restrictions/toggle";

        const finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
        const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        const body = {
            profileId: profileId,
            restrictionId: restrictionId,
            baseLevelId: baseLevelId,
        };

        try {
            const response: HTTPResponse = await httpclient.putData(
                finalURL,
                body,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers
            );

            return response.responseData.keyList;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} toggleRestriction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${this.serviceFileName} toggleRestriction`);
            throw new Error(`Error ${this.serviceFileName} toggleRestriction`);
        }
    }
}
