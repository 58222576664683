import TPGlobal from "@/helpers/TPGlobal";
import { BaseFieldType } from "@/models/Global/TPGlobalEnums";
import { InboundMailRuleDefinitionJsonRuleViewModel } from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

interface IRuleApplied {
  inboundMailRuleJson: InboundMailRuleDefinitionJsonRuleViewModel[];
  isHorizontal?: Boolean;
}

const RuleApplied = ({ inboundMailRuleJson, isHorizontal }: IRuleApplied) => {
  const [ruleApplied, setRuleApplied] = useState("");

  const getDescriptionFullRulesWithFormat = async (): Promise<string> => {
    let oneStringBuilder: string[] = [];

    for (const inboundMailRule of inboundMailRuleJson) {
      oneStringBuilder.push(
        inboundMailRule.ParenthesisOpen.trim()
          ? `<span style='color:black;font-weight:600;' >${inboundMailRule.ParenthesisOpen}</span>`
          : "",
      );
      oneStringBuilder.push(
        await getBaseFieldDescriptionWithFormat(
          inboundMailRule.BaseField.trim(),
        ),
      );
      oneStringBuilder.push(
        `&nbsp;<span style='color:red;font-weight:normal;' >${await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          `OPERATOR${inboundMailRule.ComparisonOperator}`,
        )}</span>`,
      );
      oneStringBuilder.push(
        `&nbsp;<span style='color:black;font-weight:normal;' >${inboundMailRule.ReferenceValue}</span>`,
      );
      oneStringBuilder.push(
        inboundMailRule.ParenthesisClose.trim()
          ? `<span style='color:black;font-weight:600;' >${inboundMailRule.ParenthesisClose}</span>&nbsp;`
          : "&nbsp;",
      );
      oneStringBuilder.push(
        inboundMailRule.Connector.trim()
          ? `${
              isHorizontal ? "" : "<br />"
            } <span style='color:red;font-weight:600;' >${await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `LogicalOperator${inboundMailRule.Connector}`,
            )}</span>`
          : "",
      );
      oneStringBuilder.push("&nbsp;");
    }

    return oneStringBuilder.join("");
  };

  const getBaseFieldDescriptionWithFormat = async (
    baseField: string,
  ): Promise<string> => {
    let text = "";

    const [baseFieldType, baseFieldValue] = baseField.split(".");
    const baseFieldTypeLabel = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      `BaseField${baseFieldType}`,
    );

    const baseFieldValueLabel = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      `${baseFieldType}${baseFieldValue}`,
    );

    text = `<span style='color:green;font-weight:normal;' >${baseFieldTypeLabel} -- </span>`;
    text += `<span style='color:green;font-weight:600;' > ${baseFieldValueLabel}</span>`;

    return text;
  };

  useEffect(() => {
    (async () => {
      let rule = await getDescriptionFullRulesWithFormat();
      setRuleApplied(rule);
    })();
  }, []);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `${ruleApplied}`,
        }}
      />
    </>
  );
};

const RuleToString = async (inboundMailRuleJson: any[]) => {
  const getBaseFieldDescriptionWithFormat2 = async (
    baseField: string,
  ): Promise<string> => {
    let text = "";

    const [baseFieldType, baseFieldValue] = baseField.split(".");
    const baseFieldTypeLabel = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      `BaseField${baseFieldType}`,
    );

    const baseFieldValueLabel = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      `${baseFieldType}${baseFieldValue}`,
    );

    text = `${baseFieldTypeLabel} -- ${baseFieldValueLabel}`;
    return text;
  };

  const result = await Promise.all(
    inboundMailRuleJson.map(async (inboundMailRule: any) => {
      let oneStringBuilder: string = "";

      oneStringBuilder += inboundMailRule.ParenthesisOpen.trim()
        ? `${inboundMailRule.ParenthesisOpen}`
        : "";

      const baseField: string = inboundMailRule.BaseField.trim();
      oneStringBuilder += await getBaseFieldDescriptionWithFormat2(baseField);

      oneStringBuilder += ` ${await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        `OPERATOR${inboundMailRule.ComparisonOperator}`,
      )}`;

      oneStringBuilder += ` ${inboundMailRule.ReferenceValue}`;

      oneStringBuilder += inboundMailRule.ParenthesisClose.trim()
        ? `${inboundMailRule.ParenthesisClose} `
        : " ";

      oneStringBuilder += inboundMailRule.Connector.trim()
        ? `${" "} ${await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            `LogicalOperator${inboundMailRule.Connector}`,
          )}`
        : "";
      return oneStringBuilder;
    }),
  );
  return result.join(" ");
};

export { RuleToString };

export default RuleApplied;
