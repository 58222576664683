import styled from "styled-components";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

export const TableContainer = styled.div(
  ({ theme }) => `
  /*border-radius: 10px;
  border: 1px solid ${theme.colors.table.tableContainer.borderColor};
  background-color: ${theme.colors.table.tableContainer.backgroundColor};
  padding: 10px;*/
`,
);

export const TableHeaderContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`,
);

export const TableToolbar = styled.div`
  display: flex;
  gap: 10px;

  .btn {
    &.tp-btn-icon {
      font-size: 18px;
    }
  }
`;

export const TableSearchContainer = styled.div`
  margin-bottom: 10px;
`;

export const DataTableContainer = styled.div(
  ({ theme }) => `
  .rdt_Table {
    min-height: 550px;
    border: 1px solid ${theme.colors.table.dataTableContainer.borderColor};

    input[type="checkbox"] {
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;
    
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 0.25em;
      transform: translateY(-0.075em);
    
      display: grid;
      place-content: center;
      }
      
      input[type="checkbox"]::before{
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        /* Windows High Contrast Mode */
        background-color: CanvasText;
        } 
      
        input[type="checkbox"]:checked {
          background-color:  ${theme.colors.primary};
          border-color: #a00095;
          background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e')
      } 
      
      
        
     
  }

  .rdt_TableHead {
    .rdt_TableHeadRow {
      /*background-color: #edecec;*/
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
    }

    .btn {
      &.tp-btn-empty {
        // color: red;
        font-size: 25px;

        &.menu-button{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.btn-primary{
        &.update-button{
          padding: 0;
          min-width: 0;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
    }

   
    
     
    .dropdown{
      .drowpdown-menu{

        a.dropdown-item{
          color: ${theme.colors.table.dataTableContainer.dropdown.menu.item.fontColor};

          &:hover{
            background-color: ${theme.colors.table.dataTableContainer.dropdown.menu.item.hover.backgroundColor};
          }
        }
      }
      
    }
    a{
      color: ${theme.colors.link.fontColor};
    }

    
  }
`,
);
//TODO: Ask to Juan how to use here the context to get theme variables
export const tableStyles: any = {
  rows: {
    style: {
      color: "#2E2E2E",
      backgroundColor: "#FFF",
      minHeight: "46px", // override the row height
      padding: "5px",
    },
    stripedStyle: {
      color: "#2E2E2E",
      backgroundColor: "#F7F7F7",

      "&:hover": {
        backgroundColor: "#c !important",
      },
    },
  },
  headCells: {
    style: {
      backgroundColor: "#FFF",
      fontSize: "14px",
      fontWeight: "600",
      "&:hover": {
        color: "black",
      },
      ">div>div": {
        whiteSpace: "normal",
        textOverflow: "initial",
        wordBreak: "break-word",
        textAlign: "center",
      },
    },
  },

  cells: {
    style: {
      fontSize: "13px",
      // borderBottom: "1px solid black",
      display: "flex",

      "&[data-column-id='1']": {
        /*backgroundColor: "#edecec",
        /*borderBottom: "1px solid black",*/
      },
    },
  },
  checkbox: {
    style: {
      "&:hover": {
        backgroundColor: "transparent", // override the background color on hover
      },
      borderColor: "1px solid red",
      backgroundColor: "red", // override the background color
    },
  },
};

export const IsActiveIcon = styled(TPIcon)(
  ({ theme }) => `
  font-size: 22px;
  color: ${theme.colors.table.icon.isActive.fontColor};
`,
);

export const TPTableCondensedContainer = styled.table(
  ({ theme }) => `
  &.tpcondensed-table,
  th,
  td {
    border: 1px solid ${theme.colors.table.condensed.td.borderColor};
    border-collapse: collapse;
    padding: 2px;
  }
  &.tpcondensed-table .form-control {
    padding: 1px;
    font-size: 14px;
    margin: 0 !important;
  }
  &.tpcondensed-table .form-select {
    padding: 1px;
    font-size: 14px;
    margin: 0 !important;
    padding-right: 22px;
  }

  &.tpcondensed-table .form-check {
    padding: 1px;
    margin: 0 !important;
  }

  tbody {
    font-size: 14px;
    tr {
      cursor: pointer;
      &.selected {
        background-color: ${theme.colors.table.condensed.tbody.tr.selected.backgroundColor};
      }
    }

    td {
      text-align: center;

      i {
        justify-content: space-around;
      }
    }
  }
`,
);
