import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { CasesFollowUpViewModel } from "@/models/FollowUp/CasesFollowUpViewModel";
import {
  filterColumnsCodesEnum,
  orderColumnsEnum,
  realFoldersTypeEnum,
} from "@/pages/MyTasks/MyTasks";
import { CasesFollowUpFilterDistinctViewModel } from "@/models/FollowUp/CasesFollowUpFilterDistinctViewModel";
import { CasesFollowUpInputDTO } from "@/models/FollowUp/CasesFollowUpInputDTO";

export class FollowUpService {
  serviceFileName: string = "FollowUpService.ts";
  public async getFollowUpCasesByFolderAndFilter(
    inputDTO: CasesFollowUpInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CasesFollowUpViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/followup";
    // url = url + "?followUpType=" + folderType.toString();
    // url = url + "&userGuid=" + userGuid;
    // url = url + "&startRecord=" + startRecord.toString();
    // url = url + "&endRecord=" + endRecord.toString();
    // if (orderedColumn && orderDirection) {
    //   url = url + "&orderedColumn=" + orderedColumn;
    //   url = url + "&orderDirection=" + orderDirection;
    // }

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFollowUpCasesByFolderAndFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getFollowUpCasesByFolderAndFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getFollowUpCasesByFolderAndFilter`,
      );
    }
  }

  public async getFilterByFolderUserAndColumn(
    folderType: realFoldersTypeEnum,
    userGuid: string,
    column: filterColumnsCodesEnum,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CasesFollowUpFilterDistinctViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/followup/filters";
    url = url + "?followUpType=" + folderType.toString();
    url = url + "&userGuid=" + userGuid;
    url = url + "&distinctColumnParameterId=" + column.toString();

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFilterByFolderUserAndColumn ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getFilterByFolderUserAndColumn`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getFilterByFolderUserAndColumn`,
      );
    }
  }
}
