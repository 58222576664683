import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";

export class WorkflowReassignmentService {
  serviceFileName: string = "WorkflowReassignmentService.ts";

  public async getAssignedWorkflowTypes(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<any>> {
    let httpclient = new TPHTTPService();
    let url: string = "/workflowtype/get-assigned";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAssignedWorkflowTypes ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getAssignedWorkflowTypes`);
      throw new Error(
        `Error ${this.serviceFileName} getAssignedWorkflowTypes`
      );
    }
  }

  public async getWorkflowAssignmentByWorkflow(
    workflow: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<any>> {
    let httpclient = new TPHTTPService();
    let url: string = `/workflowassignment/workflowtype/${workflow}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getWorkflowAssignmentByWorkflow ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${this.serviceFileName} getWorkflowAssignmentByWorkflow`
      );
      throw new Error(
        `Error ${this.serviceFileName} getWorkflowAssignmentByWorkflow`
      );
    }
  }

  public async reassignWorkflow(
    guidIds: Array<string>,
    workflowTypeIdNew: string,
    workflowTypeIdCurrent: string,
    guidTypistId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<void> {
    let httpclient = new TPHTTPService();
    let url: string = "/workflowassignment/reassign-workflow";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let body = {
      GuidIds: guidIds,
      WorkflowTypeIdNew: workflowTypeIdNew,
      WorkflowTypeIdCurrent: workflowTypeIdCurrent,
      GuidTypistId: guidTypistId,
    };

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        body,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      
      return response.responseData.keyList;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignWorkflow ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} reassignWorkflow`);
      throw new Error(`Error ${this.serviceFileName} reassignWorkflow`);
    }
  }
}
