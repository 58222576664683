import { FC, useState } from "react";
import "../styles/Styles.css";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import allThemes from "@/assets/styles/theme";
import CustomerInfoContactsTab from "./tabs/CustomerInfoContactsTab";
import CustomerPersonDataTab from "./tabs/CustormerPersonDataTab";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import useLabels from "../styles/labels";
import CustomerPreferredAgentsTab from "./tabs/CustomerPreferredAgentsTab";

interface CustomerInfoTabsProperties {
  customer: ClientAdminViewModel;
}

enum Tabs {
  Person = "person",
  Contact = "contact",
  Products = "products",
  Prefered = "prefered-agents",
}

const CustomerInfoTabs: FC<CustomerInfoTabsProperties> = function ({
  customer,
}) {
  const { labels } = useLabels();
  const [currentTab, setCurrentTab] = useState<string>(Tabs.Person);
  return (
    <>
      <div id="merge-customers-info-tabs-container">
        <div id="merge-customers-tab-buttons">
          <TPButton
            style={{
              padding: "8px",
              color: allThemes.base.primary,
              backgroundColor: currentTab == Tabs.Person && "#F4F4F4",
            }}
            id="merge-customers-person-tab"
            type={TPButtonTypes.empty}
            onClick={() => setCurrentTab(Tabs.Person)}
          >
            {labels.Person}
          </TPButton>
          <TPButton
            style={{
              padding: "8px",
              color: allThemes.base.primary,
              backgroundColor: currentTab == Tabs.Contact && "#F4F4F4",
            }}
            id="merge-customers-contact-tab"
            type={TPButtonTypes.empty}
            onClick={() => setCurrentTab(Tabs.Contact)}
          >
            {labels.Contacts}
          </TPButton>
          <TPButton
            style={{
              padding: "8px",
              color: allThemes.base.primary,
              backgroundColor: currentTab == Tabs.Prefered && "#F4F4F4",
            }}
            id="merge-customers-prefered-tab"
            type={TPButtonTypes.empty}
            onClick={() => setCurrentTab(Tabs.Prefered)}
          >
            {labels.PreferredAgents}
          </TPButton>
        </div>
        {currentTab == Tabs.Contact && (
          <CustomerInfoContactsTab
            customerId={customer.id}
            subsidiaryId={customer.subsidiaryId}
          />
        )}
        {currentTab == Tabs.Person && (
          <CustomerPersonDataTab customer={customer} />
        )}
        {currentTab == Tabs.Prefered && (
          <CustomerPreferredAgentsTab subsidiaryId={customer.subsidiaryId} />
        )}
      </div>
    </>
  );
};

export default CustomerInfoTabs;
