import React, { useEffect, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";
import {
  ContainerViewDetail,
  IcoIStyle,
  ImageIcoStyle,
  ImageStyle,
  NameFolderInputStyle,
  PopupDetailDivStyle,
  TextPStyle,
} from "./StyleImageAdmin";
import { OperationMode } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { TPI18N } from "@/services/I18nService";

type ContainerFolderProps = {
  file: RecursiveRoutes;
};

const ImagesAdminDetail: React.FC<ContainerFolderProps> = ({ file }) => {
  const componentFileName: string = "ImagesAdminDetail.tsx";
  const resourceSet: string = "ImagesAdminComponent";

  const [editName, setEditName] = React.useState<string>("");

  //Label
  const [namePopuLabel, setNamePopuLabel] = useState("");
  const [sizePopuLabel, setSizePopuLabelLabel] = useState("");
  const [dimensionPopuLabel, setDimensionPopuLabel] = useState("");
  const [keyWordsPopuLabel, setKeyWordsLabel] = useState("");
  const [creationDatePopuLabel, setCreationDatePopuLabel] = useState("");

  useEffect(() => {
    loadResourcesAndOrganizations();
  }, []);

  const loadResourcesAndOrganizations = async () => {
    setNamePopuLabel(await TPI18N.GetText(resourceSet, "NamePopuLabel"));
    setSizePopuLabelLabel(await TPI18N.GetText(resourceSet, "SizePopuLabel"));
    setDimensionPopuLabel(
      await TPI18N.GetText(resourceSet, "DimensionPopuLabel"),
    );
    setKeyWordsLabel(await TPI18N.GetText(resourceSet, "KeyWordsPopuLabel"));
    setCreationDatePopuLabel(
      await TPI18N.GetText(resourceSet, "CreationDatePopuLabel"),
    );
  };

  const value = useAppConctextImageAdmin();
  const {
    handleSelected,
    handleMultiSelectedFileClear,
    handleAllSelectedFile,
    handleUpdateFolder,
    handleMultiSelectedFile,
    selectedItem,
    listMultiFileSelected,
    selectedFolderParent,
    operationMode,
  } = value;

  const handleSeletedFileAction = () => {
    handleSelected(file, OperationMode.SelectFile);
  };

  const Cancel = () => {
    if (listMultiFileSelected.length <= 0) {
      handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
    } else {
      handleMultiSelectedFileClear();
    }
  };

  const handleActiveEditName = () => {
    if (operationMode == OperationMode.ActiveMenuFile) {
      handleSelected(file, OperationMode.EditFile);
    } else {
      file.fileName = editName;
      handleUpdateFolder(file);
      handleSelected(selectedFolderParent, OperationMode.SelectFolder);
    }
  };

  const handleDisableFolderActive = () => {
    Cancel();
    if (listMultiFileSelected.length <= 0) {
      file.isActive = !file.isActive;
      handleUpdateFolder(file);
    } else {
      listMultiFileSelected.forEach((e) => {
        e.isActive = !e.isActive;
        handleUpdateFolder(e);
      });
    }
  };
  const handleActivateMenu = (event: any) => {
    handleSelected(file, OperationMode.ActiveMenuFile);
    event.preventDefault();
  };

  const formatDate = (dateTemp: string) => {
    const date = new Date(dateTemp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();

    return `${year}/${month}/${day}`;
  };

  const handleMultiSelectedFileAction = (e: any) => {
    e.preventDefault();
    handleMultiSelectedFile(file);
  };

  return (
    <>
      <ContainerViewDetail onContextMenu={handleActivateMenu}>
        <ImageIcoStyle
          onClick={handleSeletedFileAction}
          src={file.thumbnailUrl}
          alt={file.fileName}
        ></ImageIcoStyle>

        {selectedItem.id != null &&
        selectedItem.id === file.id &&
        operationMode == OperationMode.EditFile &&
        listMultiFileSelected.length <= 0 ? (
          <NameFolderInputStyle
            type="text"
            placeholder={file.fileName}
            onChange={(e) => {
              setEditName(e.target.value);
            }}
            width={"150px"}
          />
        ) : (
          <TextPStyle> {file.fileName}</TextPStyle>
        )}

        <TextPStyle>{file.size}</TextPStyle>
        <TextPStyle>{formatDate(file.dateUpload)}</TextPStyle>

        {!file.isActive ? (
          <IcoIStyle
            color="red"
            bottom={"6px"}
            right={"94%"}
            background_color="none"
            className={"bi bi-lock-fill"}
          ></IcoIStyle>
        ) : null}
        <IcoIStyle
          onClick={handleMultiSelectedFileAction}
          color={"black"}
          bottom={"10px"}
          right={"5px"}
          background_color="white"
          className={
            listMultiFileSelected.find((s) => s.id == file.id)
              ? "bi-check-square-fill"
              : "bi-dash-square"
          }
        ></IcoIStyle>

        {selectedItem.id != null &&
        selectedItem.id === file.id &&
        (operationMode == OperationMode.ActiveMenuFile ||
          operationMode == OperationMode.EditFile) ? (
          <ImagesAdminPopupMenu
            right={"-0px"}
            top="0px"
            Cancel={Cancel}
            handleAdd={() => {}}
            handleMultiSelect={handleAllSelectedFile}
            handleActiveEditName={handleActiveEditName}
            handleRemove={handleDisableFolderActive}
            select={selectedItem}
            operationMode={operationMode}
            context={file}
          ></ImagesAdminPopupMenu>
        ) : (
          ""
        )}

        <PopupDetailDivStyle>
          <p>
            {namePopuLabel} : {file.fileName}
            <br />
            {sizePopuLabel}: {file.size}
            <br />
            {dimensionPopuLabel}: {file.dimensions}
            <br />
            {keyWordsPopuLabel}: {file.keywords}
            <br />
            {creationDatePopuLabel}: {file.dateUpload}{" "}
          </p>
        </PopupDetailDivStyle>
      </ContainerViewDetail>
    </>
  );
};

export default ImagesAdminDetail;
