import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import React, { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { BranchService } from "@/services/BranchService";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface ScriptListValueLogicProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
  branchId: string;
  renderMethod: "Combobox" | "Option" | "Checkbox"
}

const ScriptListValueLogic: FC<ScriptListValueLogicProperties> = function ({
  availableNextSteps,
  logic,
  onLogicAdd,
  branchId,
  renderMethod
}) {
  const [options, setOptions] = useState<BranchViewModel[]>([]);
  const [chosenOption, setChosenOption] = useState<string>("");
  const [nextStep, setNextStep] = useState(availableNextSteps.find(a => a.order == -1)?.id || "");

  useEffect(() => {
    if (branchId) {
      const branchServiceInstance = new BranchService();
      branchServiceInstance.getChildBranches(branchId, 1, 1, false, false, [200, 404])
        .then((items) => setOptions(items))
        .catch(err => console.error(err))
    }
  }, [])
  return (
    <>
      {renderMethod === "Combobox" &&
        <TPSelect
          dataSource={[...options.map(opt => {
            return {
              key: opt.id,
              value: opt.description
            }
          })]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChosenOption(e.target.value)}
          value={chosenOption}
          labelText=""
          placeholder="--"
        />}
      {renderMethod === "Option" && <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TPRadioGroup
            source={[...options.map(opt => {
              return {
                key: opt.id,
                value: opt.description
              }
            })]}
            value={chosenOption}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChosenOption(e.target.value)}
          />
        </div>

      </>}
      {/* {renderMethod === "Checkbox" &&
        <>
          {options.map(opt => (
            <TPCheckBox
              checked={Boolean(chosenOptions.find(o => o === opt.id))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.checked ?
                  setChosenOptions([...chosenOptions, opt.id])
                  :
                  setChosenOptions([...chosenOptions.filter(o => o !== opt.id)])
              }}
              key={opt.id}
              labelText={opt.localizedDescription}
            />
          ))}
        </>} */}
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => onLogicAdd({
          key_SSDA: chosenOption,
          nextStepId: nextStep,
          value: options.find(o => o.id === chosenOption)?.localizedDescription || ""
        })}
        isDesignSystem
        style={{
          width: 'min-content',
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={chosenOption.trim().length == 0}
      >
        _add_logic
      </TPButton>
    </>
  )
}

export default ScriptListValueLogic;