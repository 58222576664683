import { CustomError, StateModel } from "@/redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import e from "../models/FormDesignerExportImportFormEnum";
import FormDesignerExportImportFormModel from "../models/FormDesignerExportImportFormModel";

export interface FormDesignerExportImportFormStateModel extends StateModel {
  message: any;
  datasource?: any;
}

export const FormDesignerExportImportFormSlice = createSlice({
  name: e.FormDesignerExportImportForm,
  initialState: {
    message: {},
    result: {},
  } as FormDesignerExportImportFormStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        message: {},
        result: { action },
      }) as FormDesignerExportImportFormStateModel,
    message: (
      state: FormDesignerExportImportFormStateModel,
      action: PayloadAction<Array<string>>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as FormDesignerExportImportFormStateModel,
    messageSuccess: (
      state: FormDesignerExportImportFormStateModel,
      action: PayloadAction<{
        attribute: string;
        message: string;
      }>,
    ) => {
      const message = { ...state.message };
      message[action.payload.attribute] = action.payload.message;

      return {
        ...state,
        message,
        result: { action },
      } as FormDesignerExportImportFormStateModel;
    },
    messageError: (
      state: FormDesignerExportImportFormStateModel,
      action: PayloadAction<CustomError>,
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as FormDesignerExportImportFormStateModel,
  },
});
