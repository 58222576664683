import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  WallboardEnum as e,
  TabTypeEnum,
  WallboardEvents,
  WallboardStateActionType,
  WallboardTeamViewProps,
} from "@/models/Wallboard/WallboardModel";
import SearchInput from "@/modules/core/design-system/inputs/SearchInput";
import { WallboardService } from "@/services/WallboardService";
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import WallboardListContainer from "./WallboardListContainer";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    overflowY: "auto",
  } as CSSProperties,
  sectionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    margin: "20px 0",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard team view
 */
const WallboardTeamView = forwardRef(
  (
    { id, itemKey, style, children, s, dispatch }: WallboardTeamViewProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * search
     */
    const [search, setSearch] = useState("");
    /**
     * sorts
     */
    const sorts: Array<TPKeyValue> = [
      { key: "", value: s.m[e.SectionActionSelectSortByLabel] },
      { key: "0", value: s.m.SectionActionOptionTimeMoreLessLabel },
      { key: "1", value: s.m.SectionActionOptionTimeLessMoreLabel },
      { key: "2", value: s.m.SectionActionOptionNameAZLabel },
      { key: "3", value: s.m.SectionActionOptionNameZALabel },
    ];
    /**
     * sort by
     */
    const [sort, setSort] = useState("0");
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {
            loadDataSources();
          },
        }) as WallboardEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadDataSources();
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * load data sources
     */
    function loadDataSources(): void {
      const { getTeams } = new WallboardService();

      dispatch({
        type: WallboardStateActionType.setLoading,
        payload: true,
      });
      getTeams(TPGlobal.currentUserGuid)
        .then((data) => {
          dispatch({
            type: WallboardStateActionType.setData,
            payload: { type: TabTypeEnum.TeamView, data, search, sort },
          });
        })
        .catch((error) => {
          dispatch({
            type: WallboardStateActionType.setLoading,
            payload: false,
          });
          console.error(`Error ${e.nameTeam} loadDataSources`, error);
        });
    }
    /**
     * search
     */
    function onChangeSearchHandler(value: string): void {
      setSearch(value);

      dispatch({
        type: WallboardStateActionType.setData,
        payload: { type: TabTypeEnum.TeamView, search: value, sort },
      });
    }
    /**
     * sort
     */
    function onChangeSortHandler(event: any): void {
      const value = event.target.value;
      setSort(value);

      dispatch({
        type: WallboardStateActionType.setData,
        payload: { type: TabTypeEnum.TeamView, search, sort: value },
      });
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={id ?? e.nameTeam}
        key={itemKey ?? e.nameTeam}
        style={{
          ...styles.container,
          ...style,
        }}
      >
        <div
          id={`${e.nameTeam}SectionAction`}
          key={`${e.nameTeam}SectionAction`}
          style={styles.sectionAction}
        >
          <div>
            <SearchInput
              id={`${e.nameTeam}InputSearch`}
              key={`${e.nameTeam}InputSearch`}
              placeholder={s.m?.[e.SectionActionInputSearchLabel]}
              onChange={onChangeSearchHandler}
            />
          </div>

          <div style={{ ...styles.row, gap: "20px" }}>
            <TPButton
              id={`${e.nameTeam}IconButtonRefresh`}
              key={`${e.nameTeam}IconButtonRefresh`}
              iconStyle={{ fontSize: "26px" }}
              type={TPButtonTypes.icon}
              icon={TPIconTypes.loop}
              tooltip={s.m?.[e.SectionActionTooltipRefreshLabel]}
              onClick={() => loadDataSources()}
            />

            <TPSelect
              id={`${e.nameTeam}SelectSortBy`}
              key={`${e.nameTeam}SelectSortBy`}
              dataSource={sorts}
              value={sort}
              onChange={onChangeSortHandler}
            />
          </div>
        </div>

        <WallboardListContainer
          m={s.m}
          queuesSelected={[{ key: "1", value: "1" }]}
          data={s.teams.map((item) => ({ ...item, queueId: 1 }))}
          noDataMessage={`${s.m.TableTeamNoDataLabel}`}
        />

        {children}
      </div>
    );
  }
);

export default WallboardTeamView;
