import React, { ReactElement } from "react";
import { TPMainHeaderContainerStyle } from "./mainMenuStyles";

import TPClientLogoCollapsed from "@/assets/images/temp/tp-logo-collapsed.png";
import TPClientLogoExpanded from "@/assets/images/temp/tp-logo-expanded.png";
import TPGeneralContext from "@/contexts/TPGeneralContext";
//TODO: Cambiar estas dos variables por las variables que vengan de lo que esté guardado del cliente
import CustomerLogoCollapsed from "@/assets/images/temp/customer-logo-collapsed.png";
import CustomerLogoExpanded from "@/assets/images/temp/customer-logo-expanded.png";

import MenuCollapsedIcon from "@/assets/images/TPIcons/MenuCollapsedIcon";
import MenuExpandedIcon from "@/assets/images/TPIcons/MenuExpandedIcon";

const MainHeader = (): ReactElement => {
  const contextData: any = React.useContext(TPGeneralContext);
  const handleClick = () => {
    contextData.setIsLeftMenuCollapsed(!contextData.isLeftMenuCollapsed);
  };

  return (
    <>
      <TPMainHeaderContainerStyle className="main-header-container">
        <div className="logos-container">
          {contextData.isLeftMenuCollapsed ? (
            <MenuCollapsedIcon
              style={{ cursor: "pointer" }}
              onClick={handleClick}
              id="collapse-menu-btn"
            />
          ) : (
            <MenuExpandedIcon
              style={{ cursor: "pointer" }}
              onClick={handleClick}
              id="expand-menu-btn"
            />
          )}

          <img className="img-logo collapsed" src={TPClientLogoCollapsed} />
          <img className="img-logo expanded" src={TPClientLogoExpanded} />

          {contextData.companyLogo && (
            <>
              <img className="img-logo collapsed" src={CustomerLogoCollapsed} />
              <img className="img-logo expanded" src={CustomerLogoExpanded} />
            </>
          )}
        </div>
      </TPMainHeaderContainerStyle>
    </>
  );
};

export default MainHeader;
