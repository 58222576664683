import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export type OrganizationsRelationsInputDTO = {
  id: string;
  organizationId: string;
  relationId: string;
  tree1Id: string;
  tree2Id: string;
  tree3Id: string;
  tree4Id: string;
  tree5Id: string;
  calendarId: string;
  timeZoneId: string;
  scheduleId: string;
  informerDataId: string;
  isActive: boolean;
  jSonTree1DescriptionLanguages: Array<RecordLocalizedModel>;
  jSonTree2DescriptionLanguages: Array<RecordLocalizedModel>;
  jSonTree3DescriptionLanguages: Array<RecordLocalizedModel>;
  jSonTree4DescriptionLanguages: Array<RecordLocalizedModel>;
  jSonTree5DescriptionLanguages: Array<RecordLocalizedModel>;
};

export class OrganizationsRelationsInputDTOValidator extends Validator<OrganizationsRelationsInputDTO> {
  constructor(
    tree1Id: string,
    tree2Id: string,
    tree3Id: string,
    tree4Id: string,
    tree5Id: string,
  ) {
    super();
    const resourceSet = "BaseLevelInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("organizationId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOOrganizationEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidOrganizationId",
        )
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidOrganizationIdLength");

      this.ruleFor("relationId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTORelationEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidRelationId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidRelationIdLength");

      this.ruleFor("tree1Id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTree1IdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTree1Id")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTree1IdLength");

      this.ruleFor("tree2Id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTree2IdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTree2Id")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTree2IdLength");

      this.ruleFor("tree3Id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTree3IdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTree3Id")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTree3IdLength");

      this.ruleFor("tree4Id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTree4IdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTree4Id")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTree4IdLength");

      this.ruleFor("tree5Id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTree5IdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTree5Id")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTree5IdLength");

      this.ruleFor("calendarId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmptyCalendar")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidCalendarId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCalendarIdLength");

      this.ruleFor("timeZoneId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTimeZoneIdEmpty")
        // .must((id) => {
        //   let check = TPGlobal.checkSpecialCharacters(id);
        //   return check.result;
        // })
        // .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidTimeZoneId")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidTimeZoneIdLength");

      this.ruleFor("scheduleId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOScheduleIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidScheduleId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidScheduleIdLength");

      this.ruleFor("informerDataId")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidInformerDataId",
        )
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidInformerDataIdLength");

      this.ruleForEach("jSonTree1DescriptionLanguages")
        .must((x) => {
          if (x.order == 1 && x.localizedValue == "") {
            if (
              tree1Id.toUpperCase() === TPGlobal.Tree.NA_TreeCode ||
              !tree1Id
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidLocalizedHelpValue",
        )
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(
          TPGlobal.globalResourceSet +
            "|InputDTOInvalidLocalizedHelpValueLength",
        );

      this.ruleForEach("jSonTree2DescriptionLanguages")
        .must((x) => {
          if (x.order == 1 && x.localizedValue == "") {
            if (
              tree2Id.toUpperCase() === TPGlobal.Tree.NA_TreeCode ||
              !tree2Id
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidLocalizedHelpValue",
        )
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(
          TPGlobal.globalResourceSet +
            "|InputDTOInvalidLocalizedHelpValueLength",
        );

      this.ruleForEach("jSonTree3DescriptionLanguages")
        .must((x) => {
          if (x.order == 1 && x.localizedValue == "") {
            if (
              tree3Id.toUpperCase() === TPGlobal.Tree.NA_TreeCode ||
              !tree3Id
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidLocalizedHelpValue",
        )
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(
          TPGlobal.globalResourceSet +
            "|InputDTOInvalidLocalizedHelpValueLength",
        );

      this.ruleForEach("jSonTree4DescriptionLanguages")
        .must((x) => {
          if (x.order == 1 && x.localizedValue == "") {
            if (
              tree4Id.toUpperCase() === TPGlobal.Tree.NA_TreeCode ||
              !tree4Id
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidLocalizedHelpValue",
        )
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(
          TPGlobal.globalResourceSet +
            "|InputDTOInvalidLocalizedHelpValueLength",
        );

      this.ruleForEach("jSonTree5DescriptionLanguages")
        .must((x) => {
          if (x.order == 1 && x.localizedValue == "") {
            if (
              tree5Id.toUpperCase() === TPGlobal.Tree.NA_TreeCode ||
              !tree5Id
            ) {
              return true;
            }
            return false;
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidLocalizedHelpValue",
        )
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(
          TPGlobal.globalResourceSet +
            "|InputDTOInvalidLocalizedHelpValueLength",
        );
    } catch (error) {
      //TODO Logs
    }
  }
}
