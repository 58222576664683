import { ReactElement } from "react";
import { CSSProperties } from "styled-components";

type TablePrimaryItemProps = {
  value: string;
  isDisabled?: boolean;
  onClick: () => void;
};

function TablePrimaryItem({
  value,
  isDisabled,
  onClick,
}: TablePrimaryItemProps): ReactElement {
  let idStyle: CSSProperties = {
    textDecoration: isDisabled ? "initial" : "underline",
    color: isDisabled ? "inherit" : "purple",
    cursor: isDisabled ? "initial" : "pointer",
    fontWeight: isDisabled ? "inherit" : "700",
    margin: "0px",
  };

  return (
    <p style={idStyle} onClick={() => onClick()}>
      {value}
    </p>
  );
}

export default TablePrimaryItem;
