import { DataTableContainer, TableContainer, tableStyles } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPFilterAndSearch, TPPageActions, TPPageFirstRow, TPPageSearchContainer } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { taskStatusEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { RemindersService } from "@/services/MyRemindersService";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useAppMyReminders } from "./ContextMyReminders";
import { LocationLanguagesReminders, LocationNameReminders } from "./MyRemindersLocation";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ReminderModel } from "@/models/MyReminders/MyReminders";
import { exportToCSV } from "@/helpers/ExportToCSV";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MyRemindersSlice, MyRemindersSliceModel } from "./MyRemindersSlice";
import { TPTooltip } from "@/components/TPTooltip/TPTooltip";
import allThemes from "@/assets/styles/theme";
import { TaskReminderModal } from "@/modules/core/components/task-reminder/TaskReminderModal";

interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

const resourceSet = "MyReminders";

const MyRemindersList = forwardRef(({ callBackCommands, active }: InputModel, ref) => {

    const { reminders, remindersLoaded: contentLoaded } = useSelector((s: StoreModel) => s[MyRemindersSlice.name]) as MyRemindersSliceModel;
    const dispatch = useDispatch();
    const casecontext: any = useContext(TPCaseViewerContext);
    const [location, setLocation] = useState(new LocationNameReminders);
    const [chosenCaseId, setChosenCaseId] = useState<number | null>(null);
    const [chosenTaskId, setChosenTaskId] = useState<number | null>(null);
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    const loadResources = async () => {
        let temp = new LocationLanguagesReminders();
        await temp.handleLocationNameReminders(resourceSet);
        setLocation(temp.getLocationNameReminders);
        let newFilterKeyValue: Array<TPKeyValue> = [];
        newFilterKeyValue.push({
            key: TPActiveOptions.ALL.toString(),
            value: await TPI18N.GetText(resourceSet, "All"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.ACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Active"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.INACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Inactive"),
        });
        setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
        setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"));
    }

    const deleteReminder = function (reminderId: string | number) {
        RemindersService.deleteReminder(reminderId, true, true, [200])
            .catch(err => console.error(err))
            .finally(() => dispatch(MyRemindersSlice.actions.setContentLoaded(false)))
    }

    useEffect(() => {
        loadResources();
    }, [])

    return (
        <>
            {chosenCaseId && chosenTaskId && (
                <TaskReminderModal
                    caseId={chosenCaseId}
                    onAction={() => {
                        setChosenCaseId(null);
                        setChosenTaskId(null);
                    }}
                    taskId={chosenTaskId}
                />
            )}
            {active &&
                <div id="reminders-list-view">
                    <DynamicTable
                        id="my-reminders"
                        data={reminders.map(r => (
                            {
                                tareId: r.tareId,
                                taskId: r.taskId,
                                statusId: r.statusId,
                                customStateId: r.customStateId,
                                caseId: r.caseId,
                                customerTypeLocalizedDescription: r.customerTypeLocalizedDescription,
                                clientName: r.clientName,
                                comments: r.comments,
                                localDate: r.localDate,
                                description: r.description
                            } as ReminderModel
                        ))}
                        onIconClicked={icon => {
                            if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop)
                                dispatch(MyRemindersSlice.actions.setContentLoaded(false));
                        }}
                        hiddenColumns={["taskId", "tareId"]}
                        withPreferences
                        minorOptions={[
                            {
                                key: deleteLabel,
                                onOptionChange: (reminder) => deleteReminder(reminder.tareId),
                                type: "delete",
                                icon: TPIconTypes.delete
                            },
                            {
                                key: updateLabel,
                                onOptionChange: (reminder) => {
                                    setChosenCaseId(reminder.caseId);
                                    setChosenTaskId(reminder.taskId);
                                },
                                type: "update",
                                icon: TPIconTypes.edit
                            }
                        ]}
                        columnStyles={{
                            statusId: (row) => {
                                if (!row.item.statusId) return <></>;
                                switch (row.item.statusId) {
                                    case taskStatusEnum.Solved:
                                        return (
                                            <TPIcon
                                                iconType={TPIconTypes.checkCircle}
                                                style={{ color: "#69c169", fontSize: "20px" }}
                                                inline={true}
                                            />
                                        );
                                    case taskStatusEnum.Pending:
                                        return (
                                            <TPIcon
                                                iconType={TPIconTypes.watchLater}
                                                style={{ color: "rgb(11 122 187)", fontSize: "20px" }}
                                                inline={true}
                                            />
                                        );
                                    case taskStatusEnum.Expired:
                                        return (
                                            <TPIcon
                                                iconType={TPIconTypes.warning}
                                                style={{ color: "red", fontSize: "20px" }}
                                                inline={true}
                                            />
                                        );
                                    case taskStatusEnum.Lag:
                                        return (
                                            <TPIcon
                                                iconType={TPIconTypes.hourglassTop}
                                                style={{ color: "#e57348", fontSize: "20px" }}
                                                inline={true}
                                            />
                                        );
                                    default:
                                        return <label>{row.item.statusId}</label>;
                                }
                            },
                            caseId: (row) => (
                                <>
                                    <button
                                        type="button"
                                        style={{
                                            color: "purple",
                                            border: "none",
                                            background: "none"
                                        }}
                                        onClick={() => casecontext.handleAddNewCaseViewerCallBack(row.item.caseId)}
                                    >
                                        <b><u>{row.item.caseId}</u></b>
                                    </button>
                                </>
                            )
                        }}
                        columnNames={{
                            statusId: location.stateColumn,
                            customStateId: location.customerStateColumn,
                            caseId: location.caseNumberColumn,
                            customerTypeLocalizedDescription: location.customerTypeColumn,
                            clientName: location.customerColumn,
                            comments: location.commentsColumn,
                            localDate: location.reminderDateColumn,
                            description: location.reminderColumn
                        }}
                    />
                </div>}
        </>
    );
}
);

export default MyRemindersList;
