import { TPI18N } from "@/services/I18nService";

export class LocationLanguagesCaseCreationStats {

    getLocationNameTable: LocationNameTable = new LocationNameTable();
    getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();


    public handlelocationTable = async (resourceSet: string) => {
        this.getLocationNameTable.title = await TPI18N.GetText(resourceSet, "CaseCreationStatsTitleLabel");
        this.getLocationNameTable.group = await TPI18N.GetText(resourceSet, "GroupLAbel");
        this.getLocationNameTable.msgOkAlert = await TPI18N.GetText(resourceSet, "MsgOkAlertLabel");
        this.getLocationNameTable.msgError = await TPI18N.GetText(resourceSet, "MsgErrorLabel");

        this.getLocationNameTable.userColumn = await TPI18N.GetText(resourceSet, "UserColumn");
        this.getLocationNameTable.statusColumn = await TPI18N.GetText(resourceSet, "StatusColumn");
        this.getLocationNameTable.createCaseColumn = await TPI18N.GetText(resourceSet, "CreateCaseColumn");
        this.getLocationNameTable.timmeLoggedColumn = await TPI18N.GetText(resourceSet, "TimmeLoggedColumn");
        this.getLocationNameTable.timmeCreateCasesColumn = await TPI18N.GetText(resourceSet, "TimmeCreateCasesColumn");
        this.getLocationNameTable.averageCaseTimeColumn = await TPI18N.GetText(resourceSet, "AverageCaseTimeColumn");
        this.getLocationNameTable.directSolutionCasesColumn = await TPI18N.GetText(resourceSet, "DirectSolutionCases");



        this.getLocationNameTable.timezoneLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "TimezoneLabel");
        this.getLocationNameTable.startDateLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "StartDateLabel");
        this.getLocationNameTable.endDateLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "EndDateLabel");
        this.getLocationNameTable.todayLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "TodayLabel");
        this.getLocationNameTable.yesterdayLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "YesterdayLabel");
        this.getLocationNameTable.currentWeekLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "CurrentWeekLabel");
        this.getLocationNameTable.lastWeekLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "LastWeekLabel");
        this.getLocationNameTable.currentMonthLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "CurrentMonthLabel");
        this.getLocationNameTable.lastMonthLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "LastMonthLabel");
        this.getLocationNameTable.currentYearLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "CurrentYearLabel");
        this.getLocationNameTable.lastTwelveMonthsLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "LastTwelveMonthsLabel");
        this.getLocationNameTable.quickSelectDatesLabel = await TPI18N.GetText("CaseSearchByFilterComponent", "QuickSelectDatesLabel");
      

    };


    public handlelocationGlobal = async (resourceSet: string) => {
        this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(resourceSet, "OkButton");
        this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(resourceSet, "DeleteLabel");
        this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(resourceSet, "BuikLoad");
        this.getLocationNameGlobal.search = await TPI18N.GetText(resourceSet, "Search");
        this.getLocationNameGlobal.filterLabel = await TPI18N.GetText(resourceSet, "FilterLabel");
        this.getLocationNameGlobal.resultsLabel = await TPI18N.GetText(resourceSet, "ResultsLabel");
        this.getLocationNameGlobal.inputRequire = await TPI18N.GetText(resourceSet, "InputDTORequired");
    };


}


export class LocationNameTable {
    title: string = "";
    timezoneLabel: string = "";
    startDateLabel: string = "";
    endDateLabel: string = "";
    todayLabel: string = "";
    yesterdayLabel: string = "";
    currentWeekLabel: string = "";
    lastWeekLabel: string = "";
    currentMonthLabel: string = "";
    lastMonthLabel: string = "";
    currentYearLabel: string = "";
    lastTwelveMonthsLabel: string = "";
    quickSelectDatesLabel: string = "";
    group: string = "";
    msgOkAlert: string = "";
    msgError: string = "";

    userColumn: string = "";
    statusColumn: string = "";
    createCaseColumn: string = "";
    timmeLoggedColumn: string = "";
    timmeCreateCasesColumn: string = "";
    averageCaseTimeColumn: string = "";
    directSolutionCasesColumn: string = "";
   

}

export class LocationNameGlobal {
    confirmAlertTitle: string = "";
    okAlertButton: string = "";
    cancelAlertButton: string = "";
    deleteLabel: string = "";
    updateLabel: string = "";
    buikLoad: string = "";
    search: string = "";
    filterLabel: string = "";
    resultsLabel: string = "";
    inputRequire: string = "";
}


