import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    ActionTypeEnum,
    ModalSizeEnum,
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TaskViewModel } from "@/models/Task/TaskModels";
import {
    TaskSendMailReportInputDTO,
    TEmpAdditionalDataTaskViewModel,
} from "@/models/Task/TaskReportInputDTO";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import {
    TPPageAcceptCancelButtonsContainer,
    TPPageTitle,
} from "../TPPage/tpPageStyles";
import {
    showToast,
    TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import { CasesViewModel } from "@/models/Cases/CasesViewModels";
import {
    ReassignTaskInputDTO,
    ReassignTaskInputDTOValidator,
} from "@/models/Task/ReassignTaskInputDTO";
import {
    TaskRejectInputDTO,
    TaskRejectInputDTOValidator,
} from "@/models/Task/TaskRejectInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import {
    AdditionalDataService,
    AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { CaseService } from "@/services/CaseService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { ParametersService } from "@/services/ParametersService";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { UserService } from "@/services/UserService";
import { Icon } from "@iconify/react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPAddtionalDataUIModes } from "../TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "../TPAdditionalData/TPRenderAdditionalDataSwitch";
import allThemes from "@/assets/styles/theme";
import { TPEditor } from "../TPEditor/TPEditor";
import ComposeWithAIAlert from "../TPGenAIMailKeyPoints/ComposeWithAI";
import { TPGenAIHeader } from "../TPGenAIMailKeyPoints/TPGenAIHeader";
import TPLoadingSpinner from "../bootstrap/extend/TPLoadingSpinner/TPLoadingSpinner";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import FormView from "@/pages/FormDesigner/FormView/FormView";
import TaskFormView from "../../pages/CaseViewer/TaskFormView";

enum commandsCaseAndCustomerInfoEnum {
    "load_case_info" = 0,
    "load_customer_info" = 1,
    "change_currentTab" = 2,
    "change_case_accordion_info" = 3,
    "change_customer_accordion_info" = 4,
    "change_case_status" = 5,
}

type commandCaseAndCustomerInfoType = {
    type: commandsCaseAndCustomerInfoEnum;
    payload: any;
};

enum taskType {
    receivedMail = "S_RECEMA",
}

enum currentAccordionsCaseEnum {
    "NONE" = 0,
    "CLASSIFICATION" = 1,
    "COMMENTS" = 2,
    "ATTACHMENTS" = 3,
    "ADDITIONALDATA" = 4,
}
interface TPEmailTaskInterface {
    caseNumber: number;
    caseType?: string;
    taskIdToView: number;
    mode: string;
    isWorkflowTask: boolean;
    closeTaskCallBack: Function;
    saveTaskCallBack: Function;
    actionsTaskCallBack?: Function;
    additionalDataValuesSend?: Array<any>;
    formValueSend?: any;
}

enum currentAccordionsEmailMenu {
    "ORIGIN" = 0,
    "TRANSLATION" = 1,
}

type modalReassignScreenType = {
    newResponsibleGuidUser: Array<TPKeyValue>;
    report: string;
    newResponsibleGuidUserErrorMessage: string;
    reportErrorMessage: string;
    [x: string]: any;
};

type modalTemplateScreenType = {
    newTemplate: Array<TPKeyValue>;
    reportTemplate: string;
    newTemplateErrorMessage: string;
    reportTemplateErrorMessage: string;
    [x: string]: any;
};

type modalRejectScreenType = {
    reasonId: string;
    comments: string;
    reasonIdErrorMessage: string;
    commentsErrorMessage: string;
    [x: string]: any;
};

type CaseAttachmentType = {
    sender: string;
    description: string;
    filesDraggedList: Array<any>;
    senderErrorMessage: string;
    descriptionErrorMessage: string;
    filesDraggedListErrorMessage: Array<string>;
    notFilesErrorMessage: string;
    [key: string]: any;
};

export const TPEmailTask = React.forwardRef(
    (
        {
            caseNumber,
            taskIdToView,
            mode,
            isWorkflowTask,
            closeTaskCallBack,
            saveTaskCallBack,
            actionsTaskCallBack,
            caseType,
            additionalDataValuesSend,
            formValueSend,
        }: TPEmailTaskInterface,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            refreshTaskFromParent() {
                loadTaskInfo();
            },
            canClose() {
                return !hasBeenModified;
            },
            clearHasBeenModified() {
                setHasBeenModified(false);
            },
            refreshAdditionalData: (newAdditionaData: Array<TEmpAdditionalDataTaskViewModel>) => {
                realRefreshAdditionalData(newAdditionaData);
            },
            activeSaveButton() {
                setSaveButtonIsActive(false);
            }
        }));

        //Form View
        const [isFormActive, setIsFormActive] = useState("");
        const [inputTryingToSave, setInputTryingToSave] = useState(false);
        const [formReadyToSave, setFormReadyToSave] = useState(false);

        const [httpSentEmail, setHttpSentEmail] = useState(true);
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        const [isLoadingModalScreen, setIsLoadingModalScreen] = useState(true);
        const componentFileName: string = 'TPEmailTask.tsx';
        const resourceSet = 'EmailTaskComponent';
        const resourceSetReassignModal: string = 'ReassignTaskModal';
        const resourceSetRejectModal: string = 'RejectTaskModal';
        const resourceSetTaskViewer = 'TaskViewerComponent';
        const resourceTranslationPreviewModal = 'TranslationPreviewModal';

        const editorRef = useRef<any>(null);

        const [taskData, setTaskData] = useState<any>();

        const [responsibleLabel, setResponsibleLabel] = useState('');
        const [creationDateLabel, setCreationDateLabel] = useState('');
        const [startDateLabel, setStartDateLabel] = useState('');
        const [dueToDateLabel, setDueToDateLabel] = useState('');
        const [readerLabel, setReaderLabel] = useState('');
        const [reportSummaryLabel, setReportSummaryLabel] = useState('');
        const [fullViewLabel, setFullViewLabel] = useState('');

        const [translationFeatureLabel, setTranslationFeatureLabel] = useState('');
        const [previewOptionLabel, setPreviewOptionLabel] = useState('');
        const [useTranslationFeatureLabel, setUseTranslationFeatureLabel] = useState('');
        const [translationPreviewTitleModal, setTranslationPreviewTitleModal] = useState('');
        const [languageforTranslationLabel, setLanguageforTranslationLabel] = useState('');

        const [editorErrorMessage, setEditorErrorMessage] = useState('');
        const [saveButtonLabel, setSaveButtonLabel] = useState('');
        const [saveButtonIsActive, setSaveButtonIsActive] = useState(false);
        const [currentTask, setCurrentTask] = useState<TaskViewModel | null>(null);
        const [currentTaskType, setCurrentTaskType] = useState<TaskTypeViewModel | null>(null);
        const [hasBeenModified, setHasBeenModified] = useState(false);
        const [currentActionType, setCurrentActionType] = useState<ActionTypeEnum>(ActionTypeEnum.Reassign);
        const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
        const [autocompleteTemplateOptions, setAutocompleteTemplateOptions] = useState<Array<TPKeyValue>>([]);

        const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<Array<TPKeyValue>>([]);
        const [autocompleteTemplateTopNOptions, setAutocompleteTemplateTopNOptions] = useState<Array<TPKeyValue>>([]);

        const [emptyLabel, setEmptyLabel] = useState('');
        const [isShownActionModal, setIsShownActionModal] = useState(false);
        const [isShownModalSave, setisShownModalSave] = useState(false);
        const [fromLabel, setFromLabel] = useState('');
        const [toLabel, setToLabel] = useState('');
        const [ccLabel, setCCLabel] = useState('');
        const [bccLabel, setBccLabel] = useState('');
        const [subjectLabel, setSubjectLabel] = useState('');
        const [messageLabel, setMessageLabel] = useState('');
        const [taskTitleModal, setTaskTitleModal] = useState('');
        const [modalAcceptLabel, setModalAcceptLabel] = useState('');
        const [modalSaveLabel, setModalSavetLabel] = useState('');
        const [modalCancelLabel, setModalCancelLabel] = useState('');
        const [actionsLabel, setActionsLabel] = useState('');
        const [reassignMenuItemLabel, setReassignMenuItemLabel] = useState('');
        const [rejectMenuItemLabel, setRejectMenuItemLabel] = useState('');
        const [internalReportNotForSendingLabel, setInternalReportNotForSendingLabel] = useState('');
        const [replyToAllLabel, setReplyToAllLabel] = useState('');
        const [useTemplateLabel, setUseTemplateLabel] = useState('');
        const [replyToLabel, setReplyToLabel] = useState('');
        const [templateLanguageLabel, setTemplateLanguageLabel] = useState('');
        const [templateLabel, setTemplateLabel] = useState('');
        const [ccButtonLabel, setCcButtonLabel] = useState('');
        const [bccButtonLabel, setBccButtonLabel] = useState('');
        const [writeSomethingHereLabel, setWriteSomethingHereLabel] = useState('');
        const [titleLabel, setTitleLabel] = useState('');
        const [okLabel, setOkLabel] = useState('');
        const [cancelLabel, setCancelLabel] = useState('');
        const [attachmentLabel, setAttachmentLabel] = useState('');
        const [newAttachmentLabel, setNewAttachmentLabel] = useState('');
        const [mailReplyPrefix, setMailReplyPrefix] = useState('');

        const [senderLabel, setSenderLabel] = useState('');
        const [descriptionLabel, setDescriptionLabel] = useState('');
        const [dropFilesHereLabel, setDropFilesHereLabel] = useState('');
        const [uploadFileButtonLabel, setUploadFileButtonLabel] = useState('');
        const [sendCaseLabel, setSendCaseLabel] = useState('');
        const [sendTasksLabel, setSendTasksLabel] = useState('');
        const [attachNewFileLabel, setAttachNewFileLabel] = useState('');
        const [deleteLabel, setDeleteLabel] = useState('');
        const [fileLabel, setFileLabel] = useState('');
        const [saveInCaseLabel, setSaveInCaseLabel] = useState('');
        const [addCaseAttachmentLabel, setAddCaseAttachmentLabel] = useState('');
        const [allLabel, setAllLabel] = useState('');
        const [selectedLanguageError, setSelectedLanguageError] = useState('');
        const [pleaseEnterAMessageLabel, setPleaseEnterAMessageLabel] = useState('');
        const [attachmentsLable, setAttachmentsLable] = useState('');

        const [subjectTranslation, setSubjectTranslation] = useState('');

        const [commentsLabel, setCommentsLabel] = useState('');
        const [reasonsLabel, setReasonsLabel] = useState('');
        const [cognitiveAnalysisLabel, setCognitiveAnalysisLabel] = useState('');
        const [cognitiveAnalysCheck, setCognitiveAnalysCheck] = useState(false);

        let initialStateFromList: Array<TPKeyValue> = [];
        const [fromKeyValue, setFromKeyValue] = useState(initialStateFromList);
        const [fromValue, setFromValue] = useState('--');
        const [fromValueErrorMessage, setFromValueErrorMessage] = useState('');
        const [isToValue, setIsToValue] = useState(false);
        const [toValue, setToValue] = useState('');
        const [toValueErrorMessage, setToValueErrorMessage] = useState('');
        const [ccValue, setCCValue] = useState('');
        const [isCcValue, setIsCCValue] = useState(false);
        const [ccValueErrorMessage, setCCValueErrorMessage] = useState('');
        const [bccValue, setBccValue] = useState('');
        const [isBccValue, setIsBccValue] = useState(false);
        const [bccValueErrorMessage, setBccValueErrorMessage] = useState('');
        const [subjectValue, setSubjectValue] = useState('');
        const [subjectValueErrorMessage, setSubjectValueErrorMessage] = useState('');

        const [isShowCc, setIsShowCc] = useState(false);
        const [isShowBcc, setIsShowBcc] = useState(false);
        const [templateLanguage, setTemplateLanguage] = useState<Array<TPKeyValue>>([]);
        const [replyTo, setReplyTo] = useState<Array<TPKeyValue>>([]);
        const [isReplyToDataExist, setIsReplyToDataExist] = useState(false);
        const [templateLanguageValue, setTemplateLanguageValue] = useState('--');
        const [templateLanguageValueErrorMessage, setTemplateLanguagevalueErrorMessage] = useState('');
        const [replyToValue, setReplyToValue] = useState('--');
        const [showReplyAll, setShowReplyAll] = useState(false);
        const [replyToValueErrorMessage, setReplyToValueErrorMessage] = useState('');
        const [isInternalReportNotForSending, setIsInternalReportNotForSending] = useState(false);
        const [isReplyToAll, setIsReplyToAll] = useState(false);
        const [isUseTemplate, setIsUseTemplate] = useState(false);
        const [isSendCase, setIsSendCase] = useState(false);
        const [isSendTasks, setIsSendTasks] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [operationGuid, setOperationGuid] = useState('');
        const [inboundMailSentiment, setInboundMailSentiment] = useState('');
        const [editorValue, setEditorValue] = useState('');
        const [editorValueTemp, setEditorValueTemp] = useState('');
        const [activeSentimentEditor, setActiveSentimentEditor] = useState(false);
        const [loadingSentimentEditor, setLoadingSentimentEditor] = useState(false);
        const [translatedValue, setTranslatedValue] = useState('');
        const [emailStatus, setEmailStatus] = useState<currentAccordionsEmailMenu>(currentAccordionsEmailMenu.TRANSLATION);
        const [originalEmail, setOriginalEmail] = useState<string>('');
        const [isSownModalAttachment, setIsSownModalAttachment] = useState(false);
        const [isLoadingScreenModalAttachment, setIsLoadingScreenModalAttachment] = useState(false);
        const [isMessageError, setIsMessageError] = useState<boolean>(false);
        const [isTranslateMessageError, setIsTranslateMessageError] = useState<boolean>(false);
        const [StoryFaiAvailableLanguages, setStoryFaiAvailableLanguages] = useState<Array<any>>([]);

        const initialCaseAttachmentType: CaseAttachmentType = {
            sender: TPGlobal.currentUserName,
            description: '',
            senderErrorMessage: '',
            descriptionErrorMessage: '',
            filesDraggedList: [],
            existFilesDraggedList: [],
            filesDraggedListErrorMessage: [],
            existFilesDraggedListErrorMessage: [],
            notFilesErrorMessage: ''
        };

        const [caseAttachmentState, setCaseAttachmentState] = useState(initialCaseAttachmentType);
        const [attachment, setAttachment] = useState<any[]>([]);
        const [addCaseExistAttachment, setAddCaseExistAttachment] = useState<any[]>([]);
        const [caseInformation, setCaseInformation] = useState<CasesViewModel | null>(null);

        const [mailboxID, setMailboxID] = useState<any>(0);
        const [generalSentiment, setGeneralSentiment] = useState("");

        const handleChange = (value: any) => {
            const filterData = value.replace(/(<([^>]+)>)/gi, '');

            if (!activeSentimentEditor) {
                setEditorValueTemp(value);
                setEditorValue(value);
            }


            if (filterData.length > 0) {
                setIsMessageError(false);
            }
        };

        const handleTranslateChange = (value: any) => {
            setTranslatedValue(value);
        };

        const handleModalPopup = () => {
            setIsSownModalAttachment(true);
        };

        const handleCallBackNewAttachmentModal = async (confirmAdd: boolean, callBackData: any) => {
            if (confirmAdd) {
                let serviceClient = new TemporaryAttachmentService();
                let expectedCodes: Array<number> = [200];

                let filename: string;
                let extension: string;
                let parts: Array<string> = [];
                let blobId: string;

                let newCaseAttachmentState = { ...caseAttachmentState };
                if (attachment && attachment.length > 0) {
                    setIsLoadingScreenModalAttachment(true);
                    for (let i = 0; i < attachment.length; i++) {
                        const element = attachment[i];
                        filename = element.name;
                        parts = filename.split('.');
                        if (parts.length === 1) {
                            extension = '';
                        } else {
                            extension = parts[parts.length - 1];
                        }
                        blobId = 'caseblob' + uuidv4().replaceAll('-', '');
                        const fileData = new FormData();
                        fileData.append('fileData', element);
                        fileData.append('operationGuid', operationGuid);
                        fileData.append('blobId', blobId);
                        fileData.append('filename', filename);
                        fileData.append('extensionName', extension);
                        fileData.append('sender', caseAttachmentState.sender);
                        fileData.append('description', caseAttachmentState.description);
                        fileData.append('guid_USER', TPGlobal.currentUserGuid);
                        let responseRequest = await serviceClient.insertTemporaryAttachment(fileData, true, true, expectedCodes);
                        if (responseRequest.responseResult) {
                            const taskAttacment = {
                                BlobId: blobId,
                                Filename: filename,
                                ExtensionName: extension,
                                Sender: caseAttachmentState.sender,
                                Description: caseAttachmentState.description,
                                Guid_USER: TPGlobal.currentUserGuid,
                                SaveInCase: true
                            };
                            newCaseAttachmentState.filesDraggedList.push(taskAttacment);
                            newCaseAttachmentState.notFilesErrorMessage = '';
                            setCaseAttachmentState(newCaseAttachmentState);
                        }
                    }
                    setIsLoadingScreenModalAttachment(false);
                } else {
                    newCaseAttachmentState.notFilesErrorMessage = await TPI18N.GetText(resourceSet, 'NotFilesErrorMessageLabel');
                    setCaseAttachmentState(newCaseAttachmentState);
                    return;
                }
            }
            setIsSownModalAttachment(false);
        };

        useEffect(() => {
            setOperationGuid(uuidv4().replaceAll('-', ''));
            getCaseAttachments(caseNumber);
        }, []);

        const getCaseAttachments = async (caseId: number) => {

            try {
                setIsLoadingScreen(true);
                let responseRequest = null as any;
                if (responseRequest && responseRequest.length > 0) {
                    const formattedResponse = responseRequest.map((item: any) => ({
                        BlobId: item.blobId,
                        Filename: item.filename,
                        ExtensionName: item.extensionName,
                        Sender: item.sender,
                        Description: item.description,
                        Guid_USER: item.userGuid,
                        SaveInCase: true
                    }));

                    setAddCaseExistAttachment(formattedResponse);
                    let newCaseAttachmentState = { ...caseAttachmentState };
                    setCaseAttachmentState({
                        ...newCaseAttachmentState,
                        existFilesDraggedList: formattedResponse
                    });
                }
                setIsLoadingScreen(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getCaseAttachments ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getCaseAttachments ex`);
                setIsLoadingScreen(false);
                return null;
            }
        };

        const initialModalReassignScreenState: modalReassignScreenType = {
            newResponsibleGuidUser: [],
            report: '',
            newResponsibleGuidUserErrorMessage: '',
            reportErrorMessage: ''
        };
        const [modalReassignScreenState, setModalReassignScreenState] = useState<modalReassignScreenType>(
            initialModalReassignScreenState
        );

        const initialModalTemplateScreenState: modalTemplateScreenType = {
            newTemplate: [],
            reportTemplate: '',
            newTemplateErrorMessage: '',
            reportTemplateErrorMessage: ''
        };
        const [modalTemplateScreenState, setModalTemplateScreenState] = useState<modalTemplateScreenType>(
            initialModalTemplateScreenState
        );

        const initialModalRejectScreenState: modalRejectScreenType = {
            reasonId: '',
            comments: '',
            reasonIdErrorMessage: '',
            commentsErrorMessage: ''
        };
        const [modalRejectScreenState, setModalRejectScreenState] =
            useState<modalRejectScreenType>(initialModalRejectScreenState);

        const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);

        useEffect(() => {
            if (templateLanguageValue && modalTemplateScreenState.newTemplate.length > 0) {
                const fetchData = async () => {
                    let taskService = new TaskService();
                    let expectedCodes: Array<number> = [200];
                    const data = {
                        EmailTemplateId: modalTemplateScreenState.newTemplate[0]?.key,
                        LanguageId: templateLanguageValue,
                        CaseId: caseInformation?.caseId,
                        TaskId: taskIdToView,
                        SubsidiaryOrganizationId: caseInformation?.subsidiaryOrganizationId,
                        UserSessionGuid: TPGlobal.currentUserGuid,
                        RemoveDecisionTaskArea: false
                    };
                    try {
                        setIsLoading(true);
                        let responseRequest = await taskService.renderEmailTemplate(data, false, true, expectedCodes);
                        replyToValue.replace('--', '').trim().length == 0 && setSubjectValue(responseRequest?.responseData?.renderedSubject);
                        setToValue(responseRequest?.responseData?.renderedTo);
                        setCCValue(responseRequest?.responseData?.renderedCc);
                        setBccValue(responseRequest?.responseData?.renderedBcc);
                        setIsCCValue(responseRequest?.responseData?.renderedCc?.length > 0 ? true : false);
                        setIsBccValue(responseRequest?.responseData?.renderedBcc?.length > 0 ? true : false);
                        const separatorIndex = editorValue.indexOf('<p><br></p><p>_____________________________</p>');
                        if (separatorIndex !== -1) {
                            const contentAfterSeparator = editorValue.substring(separatorIndex);
                            const newEditorValue = responseRequest?.responseData?.renderedBody + contentAfterSeparator;
                            setEditorValue(newEditorValue);
                        } else {
                            setEditorValue(responseRequest?.responseData?.renderedBody);
                        }
                        setIsLoading(false);
                    } catch (error) {
                        setIsLoading(false);
                        console.error('Error:', error);
                    }
                };
                fetchData();
            }
        }, [templateLanguageValue, modalTemplateScreenState.newTemplate.length > 0]);

        const [additionalDataValues, setAdditionalDataValues] = useState<Array<TPKeyValue>>([]);
        const childRefArray: any = useRef([]);
        const taskguid: string = uuidv4();

        const loadResourcesAndTaskInfo = async () => {
            setResponsibleLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ResponsibleLabel'));
            setCreationDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'CreationDateLabel'));
            setStartDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'StartDateLabel'));
            setDueToDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'DueToDateLabel'));
            setReaderLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ReaderLabel'));
            setReportSummaryLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ReportSummaryLabel'));
            setFromLabel(await TPI18N.GetText(resourceSet, 'FromLabel'));
            setCCLabel(await TPI18N.GetText(resourceSet, 'CCLabel'));
            setBccLabel(await TPI18N.GetText(resourceSet, 'BCCLabel'));
            setSubjectLabel(await TPI18N.GetText(resourceSet, 'SubjectLabel'));
            setMessageLabel(await TPI18N.GetText(resourceSet, 'MessageLabel'));
            setFullViewLabel(await TPI18N.GetText(resourceSet, 'FullViewLabel'));
            setInternalReportNotForSendingLabel(await TPI18N.GetText(resourceSet, 'InternalReportNotForSendingLabel'));
            setReplyToAllLabel(await TPI18N.GetText(resourceSet, 'ReplyToAllLabel'));
            setUseTemplateLabel(await TPI18N.GetText(resourceSet, 'UseTemplateLabel'));
            setReplyToLabel(await TPI18N.GetText(resourceSet, 'ReplyToLabel'));
            setTemplateLanguageLabel(await TPI18N.GetText(resourceSet, 'TemplateLanguageLabel'));
            setTemplateLabel(await TPI18N.GetText(resourceSet, 'TemplateLabel'));
            setCcButtonLabel(await TPI18N.GetText(resourceSet, 'CcButtonLabel'));
            setBccButtonLabel(await TPI18N.GetText(resourceSet, 'BccButtonLabel'));
            setWriteSomethingHereLabel(await TPI18N.GetText(resourceSet, 'WriteSomethingHereLabel'));
            setTitleLabel(await TPI18N.GetText(resourceSet, 'TitleLabel'));
            setOkLabel(await TPI18N.GetText(resourceSet, 'OkLabel'));
            setCancelLabel(await TPI18N.GetText(resourceSet, 'CancelLabel'));
            setAttachmentLabel(await TPI18N.GetText(resourceSet, 'AttachmentLabel'));
            setNewAttachmentLabel(await TPI18N.GetText(resourceSet, 'NewAttachmentLabel'));
            setSenderLabel(await TPI18N.GetText(resourceSet, 'SenderLabel'));
            setDescriptionLabel(await TPI18N.GetText(resourceSet, 'DescriptionLabel'));
            setDropFilesHereLabel(await TPI18N.GetText(resourceSet, 'DropFilesHereLabel'));
            setUploadFileButtonLabel(await TPI18N.GetText(resourceSet, 'UploadFileButtonLabel'));
            setSendCaseLabel(await TPI18N.GetText(resourceSet, 'SendCaseLabel'));
            setSendTasksLabel(await TPI18N.GetText(resourceSet, 'SendTasksLabel'));
            setAttachNewFileLabel(await TPI18N.GetText(resourceSet, 'AttachNewFileLabel'));
            setDeleteLabel(await TPI18N.GetText(resourceSet, 'DeleteLabel'));
            setFileLabel(await TPI18N.GetText(resourceSet, 'FileLabel'));
            setSaveInCaseLabel(await TPI18N.GetText(resourceSet, 'SaveInCaseLabel'));
            setAddCaseAttachmentLabel(await TPI18N.GetText(resourceSet, 'AddCaseAttachmentLabel'));
            setAllLabel(await TPI18N.GetText(resourceSet, 'AllLabel'));
            setPleaseEnterAMessageLabel(await TPI18N.GetText(resourceSet, 'PleaseEnterAMessageLabel'));
            setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'SaveButtonLabel'));
            setEmptyLabel(await TPI18N.GetText(resourceSetTaskViewer, 'EmptyLabel'));
            setAttachmentsLable(await TPI18N.GetText(resourceSetTaskViewer, 'AttachmentsLable'));
            setTranslationFeatureLabel(await TPI18N.GetText(resourceSet, 'TranslationFeatureLabel'));
            setPreviewOptionLabel(await TPI18N.GetText(resourceSet, 'PreviewOptionLabel'));
            setUseTranslationFeatureLabel(await TPI18N.GetText(resourceSet, 'UseTranslationFeatureLabel'));
            setLanguageforTranslationLabel(await TPI18N.GetText(resourceSet, 'languageforTranslationLabel'));
            setCognitiveAnalysisLabel(await TPI18N.GetText(resourceSet, 'CognitiveAnalysisLabel'));
            setTranslationPreviewTitleModal(
                await TPI18N.GetText(resourceTranslationPreviewModal, 'TranslationPreviewTitleModal')
            );
            setToLabel(await TPI18N.GetText(resourceSetReassignModal, 'ToLabel'));
            setModalAcceptLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'OkButton'));
            setModalSavetLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'SaveButton'));
            setModalCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CancelButton'));
            setActionsLabel(await TPI18N.GetText(resourceSetReassignModal, 'ActionsLabel'));
            setReassignMenuItemLabel(await TPI18N.GetText(resourceSetReassignModal, 'ReassignMenuItemLabel'));
            setRejectMenuItemLabel(await TPI18N.GetText(resourceSetReassignModal, 'RejectMenuItemLabel'));
            setCommentsLabel(await TPI18N.GetText(resourceSetReassignModal, 'CommentsLabel'));
            setReasonsLabel(await TPI18N.GetText(resourceSetRejectModal, 'ReasonsLabel'));
            setMailReplyPrefix(await TPI18N.GetText(TPGlobal.globalResourceSet, 'MailReplyPrefix'));


            setIsLoadingScreen(true);

            if (mode !== "PREVIEW") {
                await loadFromInfo();
                await loadTaskInfo();
              
            }


            setIsLoadingScreen(false);
        };

        const loadFromInfo = async () => {
            let serviceClient = new OutboundMailAccountService();
            let expectedCodes: Array<number> = [200, 404];
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.getAllMailAccounts(false, true, expectedCodes);
                let newEmailAccountListState: Array<TPKeyValue> = [];
                responseRequest.forEach((item: any) => {
                    newEmailAccountListState.push({
                        key: item.idAccount,
                        value: item.description,
                        ...item
                    });
                });

                newEmailAccountListState.unshift({ key: '--', value: '--' });
                setFromKeyValue(newEmailAccountListState);
                setIsLoadingScreen(false);
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getAllMailAccounts ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getAllMailAccounts ex`);
                setIsLoadingScreen(false);
            }

            let taskService = new TaskService();
            let _expectedCodes: Array<number> = [200];
            try {
                let responseRequest = await taskService.getTemplateLanguages(false, true, _expectedCodes);
                let languageOptions: Array<TPKeyValue> = [];
                responseRequest.forEach((item: any) => {
                    languageOptions.push({
                        key: item.id,
                        value: item.name,
                        ...item
                    });
                });

                languageOptions.unshift({
                    key: '--',
                    value: '--'
                });

                setTemplateLanguage(languageOptions);
            } catch (error) {
                console.error('error', error);
            }

            let __expectedCodes: Array<number> = [200, 404];
            try {
                let responseRequest = await taskService.getInboundMailboxReadByCaseId(caseNumber, false, true, __expectedCodes);
                let replyToOptions: Array<TPKeyValue> = [];
                responseRequest.forEach((item: any) => {
                    replyToOptions.push({
                        key: item.id,
                        value: item.replyToLocalized,
                        ...item
                    });
                });

                if (responseRequest.length > 0) {
                    setIsReplyToDataExist(true)
                } else {
                    setIsReplyToDataExist(false)
                }

                replyToOptions.unshift({
                    key: '--',
                    value: '--'
                });

                setReplyTo(replyToOptions);
            } catch (error) {
                console.error('error', error);
            }
        };

        const getCaseInfoByNumber = async (caseNumber: number): Promise<CasesViewModel | null> => {
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];
            let responseRequest: CasesViewModel;
            try {
                setIsLoadingScreen(true);
                responseRequest = await serviceClient.getCaseInfoByCaseNumber(caseNumber, false, true, expectedCodes);
                setIsLoadingScreen(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`);
                setIsLoadingScreen(false);
                return null;
            }
        };

        const loadTaskInfo = async () => {
            let currentTaskElement: TaskViewModel | null;
            let currentTaskTypeElement: TaskTypeViewModel | null;
            let caseInfo: CasesViewModel | null;

            try {
                currentTaskElement = await getTaskInforById(taskIdToView);
                if (currentTaskElement) {
                    currentTaskElement.report = TPGlobal.TPReplaceEnter(currentTaskElement.report);
                    setCurrentTask(currentTaskElement);
                    currentTaskTypeElement = await getTaskTypeInformationById(currentTaskElement.taskTypeId);
                    if (currentTaskTypeElement) {
                        setCurrentTaskType(currentTaskTypeElement);
                        caseInfo = await getCaseInfoByNumber(currentTaskElement.caseId);
                        if (caseInfo) {
                            setCaseInformation(caseInfo);
                        }

                        if (currentTaskTypeElement && currentTaskTypeElement.formTypeId && currentTaskTypeElement.formTypeId != "") {
                            setIsFormActive(currentTaskTypeElement.formTypeId);
                        }
                        //get additional data                 
                        await getAdditionalDataForTaskId();
                    }
                }
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} loadTaskInfo ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} loadTaskInfo ex`);
                setIsLoadingScreen(false);
            }
        };

        const getTaskTypeInformationById = async (idTaskType: string) => {
            let taskTypeService = new TaskTypeService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await taskTypeService.getTaskTypeById(idTaskType, false, true, expectedCodes);
                if (responseRequest) {
                    return responseRequest;
                } else {
                    return null;
                }
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getTaskTypeInforById ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getTaskTypeInforById ex`);
                return null;
            }
        };

        const [languageValue, setLanguageValue] = useState('--');
        const [fromLanguage, setFromLanguage] = useState('--');

        const getTranslation = async () => {
            let taskService = new TaskService();

            let content = TPGlobal.stringToUTF8(editorValue).toString();

            try {
                let responseRequest = await taskService.translateOutbound('', languageValue, content, false, true);

                return responseRequest;
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getTranslation ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getTranslation ex`);
                setIsModalTranslateLoading(false);
                setFromLanguage('--');
                setTranslatedValue(await TPI18N.GetText(resourceTranslationPreviewModal, 'translateError'));
                return null;
            }
        };

        const getTaskInforById = async (idTask: number) => {
            let taskService = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest = await taskService.getTaskById(idTask, false, true, expectedCodes);
                if (responseRequest && responseRequest.length >= 1) {
                    setTaskData(responseRequest[0]);
                    return responseRequest[0];
                } else {
                    return null;
                }
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getTaskInforById ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getTaskInforById ex`);
                return null;
            }
        };

        const getAdditionalDataForTaskId = async () => {
            let taskService = new AdditionalDataTaskTypeService();
            let additionalDataService = new AdditionalDataService();
            let expectedCodes: Array<number> = [200, 404];
            let expectedCodes2: Array<number> = [200];
            let newAddtionalDataValues: Array<TPKeyValue> = [];
            let additionalDataInfo: AdditionalDataViewModel;

            try {
                let responseRequest = await taskService.getByCaseTaskId(taskIdToView, false, true, expectedCodes);

                if (responseRequest && responseRequest.length >= 1) {
                    for (let i = 0; i <= responseRequest.length - 1; i++) {
                        additionalDataInfo = await additionalDataService.getAdditionalDataById(
                            responseRequest[i].additionalDataId,
                            false,
                            true,
                            expectedCodes2
                        );
                        additionalDataInfo.order = responseRequest[i].order;
                        additionalDataInfo.isMandatory = responseRequest[i].isMandatory;
                        let oneKeyValue: TPKeyValue = {
                            key: responseRequest[i].additionalDataId,
                            value: responseRequest[i].value,
                            value2: additionalDataInfo
                        };
                        newAddtionalDataValues.push(oneKeyValue);
                    }
                    setAdditionalDataValues(newAddtionalDataValues);
                }
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getAdditionalDataForTaskId ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getAdditionalDataForTaskId ex`);
            }
        };

        const handleFromValueChange = (e: any) => {
            let fromValue: string = e.target.value;
            setFromValue(fromValue);
            setFromValueErrorMessage('');
        };
        const handleTemplateLanguageValueChange = (e: any) => {
            let fromValue: string = e.target.value;
            setTemplateLanguageValue(fromValue);
            setTemplateLanguagevalueErrorMessage('');
        };
        const handleReplyToValueChange = async (e: any) => {
            const fromValue: string = e.target.value;
            const taskService = new TaskService();
            const expectedCodes: Array<number> = [200];
            setReplyToValue(fromValue);
            setShowReplyAll(fromValue !== '--');
            setReplyToValueErrorMessage('');
            if (fromValue.replace('--', '').trim().length == 0) return;
            try {
                const responseRequest = await taskService.getInboundMailboxReadById(
                    Number(fromValue), false, true, expectedCodes
                );
                if (responseRequest && responseRequest.length > 0) {
                    setToValue(responseRequest[0]?.fromAddress.split("|")[0]);
                    setSubjectValue(mailReplyPrefix + ': ' + responseRequest[0]?.subject);
                    setCCValue(responseRequest[0]?.addressCC);
                    setBccValue(responseRequest[0]?.addressBCC);
                    setIsCCValue(responseRequest[0]?.addressCC?.length > 0 ? true : false);
                    setIsBccValue(responseRequest[0]?.addressBCC?.length > 0 ? true : false);
                    setEditorValue(
                        `<p><br></p><p>_____________________________</p>
						${responseRequest?.[0]?.htmlBody}
					`);
                }
            } catch (error) {
                if (fromValue === '--') {
                    let editorValueData = editorValue;
                    const searchTerm = '<p><br></p><p>_____________________________</p>';
                    const startIndex = editorValueData.indexOf(searchTerm);
                    if (startIndex !== -1) {
                        const newEditorValue = editorValue.substring(0, startIndex);
                        setEditorValue(newEditorValue);
                    }
                }
                console.error('error', error);
            }
        };

        const getSubjectTranslation = async (content: string) => {
            let taskService = new TaskService();
            try {
                let subject = TPGlobal.stringToUTF8(content).toString();
                let responseRequest = await taskService.translateOutbound('', languageValue, subject, false, false);

                return {
                    responseResult: true,
                    responseData: responseRequest
                };
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} getTranslation ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} getTranslation ex`);
                return null;
            }
        };

        const handleToValueChange = (e: any) => {
            let toValue: string = e.target.value;
            setToValue(toValue);
            setToValueErrorMessage('');
        };
        const handleCCValueChange = (e: any) => {
            let ccValue: string = e.target.value;
            setCCValue(ccValue);
            setCCValueErrorMessage('');
        };
        const handleBccValueChange = (e: any) => {
            let bccValue: string = e.target.value;
            setBccValue(bccValue);
            setBccValueErrorMessage('');
        };
        const handleSubjectValueChange = (e: any) => {
            let subjectValue: string = e.target.value;
            setSubjectValue(subjectValue);
            setSubjectValueErrorMessage('');
        };

        const handleShowCc = () => {
            setIsShowCc(!isShowCc);
        };
        const handleShowBcc = () => {
            setIsShowBcc(!isShowBcc);
        };

        const handleCloseTask = () => {
            if (mode === 'EDIT') {
                if (hasBeenModified) {
                    closeTaskCallBack(taskIdToView, true);
                    return;
                }
            }
            closeTaskCallBack(taskIdToView, false);
        };

        const handleOnAttachmentValueChange = (newValue: string, propertyName: string) => {
            let newCaseAttachmentState = { ...caseAttachmentState };
            newCaseAttachmentState[propertyName] = newValue;
            newCaseAttachmentState[`${propertyName}ErrorMessage`] = '';
            setCaseAttachmentState(newCaseAttachmentState);
        };
        const handleUploadFilesDraggedChange = (filesList: any) => {
            setAttachment(filesList);
        };

        const handleOnDeleteAttachment = (itemName: string) => {
            let newCaseAttachmentState = { ...caseAttachmentState };
            let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];
            newFilesDraggedList = newFilesDraggedList.filter((x: any) => x.Filename !== itemName);
            newCaseAttachmentState.filesDraggedList = newFilesDraggedList;
            setCaseAttachmentState(newCaseAttachmentState);
        };
        const handleInternalReportNotForSending = () => {
            setIsInternalReportNotForSending(!isInternalReportNotForSending);
            setUseTranslation(false);
            setLanguageValue('--');
            setIsReplyToAll(false);
            setIsUseTemplate(false);
            setIsShowCc(false);
            setIsShowBcc(false);
        };
        const handleReplyToAll = () => {
            setIsReplyToAll(!isReplyToAll);
        };

        const handleSendCase = () => {
            setIsSendCase(!isSendCase);

            if (isSendCase === true && isSendTasks === true) {
                setIsSendTasks(false);
            }
        };
        const handleSaveInCase = (blobId: string) => {
            let newCaseAttachmentState = { ...caseAttachmentState };
            let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];
            newFilesDraggedList = newFilesDraggedList.map(item => {
                if (item.BlobId === blobId) {
                    return {
                        ...item,
                        SaveInCase: !item.SaveInCase
                    };
                }
                return item;
            });
            newCaseAttachmentState.filesDraggedList = newFilesDraggedList;
            setCaseAttachmentState(newCaseAttachmentState);
        };

        const handleAddCaseExistAttacment = (item: any) => {
            setAddCaseExistAttachment(prevState => {
                let newCaseAttachmentState = { ...caseAttachmentState };
                let newExistFilesDraggedList = [...newCaseAttachmentState.existFilesDraggedList];

                const updatedPrevState = prevState.map(i => {
                    if (i.BlobId === item.BlobId) {
                        const updatedItem = {
                            ...i,
                            SaveInCase: !i.SaveInCase
                        };
                        if (updatedItem.SaveInCase) {
                            newExistFilesDraggedList.push(updatedItem);
                        } else {
                            newExistFilesDraggedList = newExistFilesDraggedList.filter(file => file.BlobId !== updatedItem.BlobId);
                        }
                        return updatedItem;
                    }
                    return i;
                });
                setCaseAttachmentState({
                    ...newCaseAttachmentState,
                    existFilesDraggedList: newExistFilesDraggedList
                });

                return updatedPrevState;
            });
        };

        const [isSelectAll, setIsSelectAll] = useState(false);

        const handleSelectAllChange = () => {
            const newSelectAllValue = !isSelectAll;
            setIsSelectAll(newSelectAllValue);

            const formattedResponse = addCaseExistAttachment.map(item => ({
                ...item,
                SaveInCase: newSelectAllValue
            }));
            setAddCaseExistAttachment(formattedResponse);
            const newExistFilesDraggedList = newSelectAllValue ? formattedResponse : [];
            setCaseAttachmentState(prevState => ({
                ...prevState,
                existFilesDraggedList: newExistFilesDraggedList
            }));
        };
        const handleSendTasks = () => {
            setIsSendTasks(!isSendTasks);
        };

        const handleCurrentTaskReport = (): string => {
            let data = '';
            if (currentAccordionsEmailMenu.TRANSLATION == emailStatus) {
                if (currentTask?.report) data = currentTask?.report;
            } else {
                data = originalEmail;
            }
            return data;
        };

        const handleUseTemplate = () => {
            setIsUseTemplate(!isUseTemplate);
        };

        const [isShownModalTranslate, setIsShownModalTranslate] = useState(false);
        const [isModalTranslateLoading, setIsModalTranslateLoading] = useState(false);
        const [useTranslation, setUseTranslation] = useState(false);
        const [isSelectedLanguageError, setIsSelectedLanguageError] = useState(false);

        const handleSaveTask = async () => {
            setInputTryingToSave(true);

            let inputDTO: TaskSendMailReportInputDTO;
            let atLeastOneError: boolean = false;
            let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> = [];
            let tEmpAttachmentsTaskArray = [
                ...caseAttachmentState.filesDraggedList,
                ...caseAttachmentState.existFilesDraggedList
            ];
            var invalidEmailErrorMessage: string = await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                'invalidEmailErrorMessage'
            );

            if (!isInternalReportNotForSending) {
                if (fromValue.trim() == '--') {
                    setFromValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorFromMessage'));
                    atLeastOneError = true;
                }
                if (isUseTemplate) {
                    if (templateLanguageValue.trim() == '--') {
                        setTemplateLanguagevalueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorTemplateLanguageMessage'));
                        atLeastOneError = true;
                    }
                    if (modalTemplateScreenState?.newTemplate.length === 0) {
                        let newmodalTemplateScreenState = { ...modalTemplateScreenState };
                        newmodalTemplateScreenState.newTemplateErrorMessage = await TPI18N.GetText(
                            resourceSet,
                            'ErrorTemplateErrorMessage'
                        );
                        setModalTemplateScreenState(newmodalTemplateScreenState);
                        atLeastOneError = true;
                    }
                }
                if (toValue.trim() == '') {
                    setToValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorToMessage'));
                    atLeastOneError = true;
                } else if (toValue && toValue.trim() !== '') {
                    if (toValue.includes(';')) {
                        toValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setToValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(toValue)) {
                            setToValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (ccValue && ccValue.trim() !== '') {
                    if (ccValue.includes(';')) {
                        ccValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setCCValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(ccValue)) {
                            setCCValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (bccValue && bccValue.trim() !== '') {
                    if (bccValue.includes(';')) {
                        bccValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setBccValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(bccValue)) {
                            setBccValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (subjectValue.trim() == '') {
                    setSubjectValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorSubjectMessage'));
                    atLeastOneError = true;
                }

                const filterData = editorValue.replace(/(<([^>]+)>)/gi, '');
                if (filterData.length > 0) {
                    setIsMessageError(false);
                } else {
                    setIsMessageError(true);
                }
                if (!validateAdditonalData()) {
                    atLeastOneError = true;
                }
                if (atLeastOneError) {
                    return;
                }

                if (!isFormActive)
                    newTempAdditionalDataValues = getFinalAdditonalDataValues();
                else
                    newTempAdditionalDataValues = getFinalAdditonalDataValuesForm();
                setSaveButtonIsActive(true);
            }
            inputDTO = {
                useRPCMethod: true,
                id: taskIdToView,
                report: useTranslation ? TPGlobal.stringToUTF8(translatedValue).toString() : TPGlobal.stringToUTF8(editorValue).toString(),
                decisionPath: '',
                escalatedTaskReport: '',
                typistGuidUser: TPGlobal.currentUserGuid,
                tEmpAdditionalDataTask: isInternalReportNotForSending === true ? [] : newTempAdditionalDataValues,
                fromEmailAccountId: isInternalReportNotForSending === true ? '' : fromValue,
                to: isInternalReportNotForSending === true ? '' : toValue,
                cc: isInternalReportNotForSending === true ? '' : ccValue,
                bcc: isInternalReportNotForSending === true ? '' : bccValue,
                subject: TPGlobal.stringToUTF8(subjectValue).toString(),
                internalReportNotForSending: isInternalReportNotForSending,
                replyTo: isInternalReportNotForSending === true ? '' : replyToValue,
                replyToAll: isReplyToAll,
                useTemplate: isUseTemplate,
                templateLanguageId:
                    isInternalReportNotForSending === true && isUseTemplate === true ? templateLanguageValue : '',
                templateId:
                    isInternalReportNotForSending === true && isUseTemplate === true
                        ? modalTemplateScreenState?.newTemplate[0]?.key
                        : '',
                sendCase: isSendCase,
                sendTasks: isSendTasks,
                tEmpAttachmentsTask: isInternalReportNotForSending === true ? [] : tEmpAttachmentsTaskArray
            };

            if (!formReadyToSave && mode.toUpperCase() === "EDIT") return null;

            saveTaskCallBack(inputDTO, true);
        };

        const handleLanguageChange = (event: any) => {
            let translateValue: string = event.target.value;
            setLanguageValue(translateValue);
        };

        const handleUseTranslation = () => {
            setUseTranslation(!useTranslation);
        };

        const [acceptLabel, setAcceptLabel] = useState('Ok');

        const loadResourcesAndIconList = async () => {
            setAcceptLabel(await TPI18N.GetText(resourceSet, 'AcceptLabel'));
        };

        const sendTracking = async () => {
            let taskService = new TaskService();
            let DTO = {
                CaseId: currentTask?.caseId,
                TaskId: currentTask?.id,
                InboundMailboxReadID: mailboxID,
                ResultType: 'Success',
                LanguageFrom: TPGlobal.stringToUTF8(fromLanguage).toString(),
                LanguageTo: TPGlobal.stringToUTF8(languageValue).toString(),
                JsonResult: TPGlobal.stringToUTF8(translatedValue).toString(),
                Guid_USER: TPGlobal.currentUserGuid
            };
            try {
                let responseRequest = await taskService.trackOutbound(DTO);
                return true;
            } catch (error) {
                TPLog.Log(`Error ${componentFileName} sendTracking ex`, TPLogType.ERROR, error);
                console.error(`Error ${componentFileName} sendTracking ex`);
                return false;
            }
        };

        const handleCallbackAnser = (status: boolean, callbackData: any) => {
            if (status) {
                if (translatedValue.replace(/(<([^>]+)>)/gi, '').length > 0) {
                    setIsShownModalTranslate(false);
                    sendTracking();
                    handleSaveTask();
                    setIsTranslateMessageError(false);
                } else {
                    setIsTranslateMessageError(true);
                }
            } else {
                setIsShownModalTranslate(false);
                setIsTranslateMessageError(false);
            }
        };

        useEffect(() => {
            let firstSplit: Array<any> = [];
            let languageList: Array<any> = [
                {
                    languageCode: '',
                    languageName: '--'
                }
            ];
            firstSplit = TPGlobal.StoryFaiAvailableLanguages.split('|');
            for (let i = 0; i < firstSplit.length; i++) {
                let secondSplit: any[] = [];
                secondSplit = firstSplit[i].split('$');
                languageList.push({
                    languageCode: secondSplit[0],
                    languageName: secondSplit[1]
                });
            }
            setStoryFaiAvailableLanguages(languageList);
        }, []);

        const handleTracking = async () => {
            setInputTryingToSave(true);
            setIsSelectedLanguageError(false);
            let atLeastOneError: boolean = false;
            var invalidEmailErrorMessage: string = await TPI18N.GetText(TPGlobal.globalResourceSet, "invalidEmailErrorMessage");
            if (useTranslation) {
                if (languageValue.trim() == '--') {
                    setIsSelectedLanguageError(true);
                    setSelectedLanguageError(await TPI18N.GetText(resourceSet, 'ErrorLanguageMessage'));
                    atLeastOneError = true;
                }
                if (fromValue.trim() == '--') {
                    setFromValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorFromMessage'));
                    atLeastOneError = true;
                }
                if (isUseTemplate) {
                    if (templateLanguageValue.trim() == '--') {
                        setTemplateLanguagevalueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorTemplateLanguageMessage'));
                        atLeastOneError = true;
                    }
                    if (modalTemplateScreenState?.newTemplate.length === 0) {
                        let newmodalTemplateScreenState = { ...modalTemplateScreenState };
                        newmodalTemplateScreenState.newTemplateErrorMessage = await TPI18N.GetText(
                            resourceSet,
                            'ErrorTemplateErrorMessage'
                        );
                        setModalTemplateScreenState(newmodalTemplateScreenState);
                        atLeastOneError = true;
                    }
                }
                if (toValue.trim() == '') {
                    setToValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorToMessage'));
                    atLeastOneError = true;
                } else if (toValue && toValue.trim() !== '') {
                    if (toValue.includes(';')) {
                        toValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setToValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(toValue)) {
                            setToValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (ccValue && ccValue.trim() !== '') {
                    if (ccValue.includes(';')) {
                        ccValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setCCValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(ccValue)) {
                            setCCValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (bccValue && bccValue.trim() !== '') {
                    if (bccValue.includes(';')) {
                        bccValue.split(';').map(item => {
                            if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
                                setBccValueErrorMessage(invalidEmailErrorMessage);
                                return false;
                            }
                        });
                    } else {
                        if (!TPGlobal.regularExpressionCheckEmail.test(bccValue)) {
                            setBccValueErrorMessage(invalidEmailErrorMessage);
                            return false;
                        }
                    }
                }
                if (subjectValue.trim() == '') {
                    setSubjectValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorSubjectMessage'));
                    atLeastOneError = true;
                }

                const filterData = editorValue.replace(/(<([^>]+)>)/gi, '');
                if (filterData.length > 0) {
                    setIsMessageError(false);
                } else {
                    setIsMessageError(true);
                    atLeastOneError = true;
                }
                if (!validateAdditonalData()) {
                    atLeastOneError = true;
                }
                if (atLeastOneError) {
                    return;
                }
                let response = getSubjectTranslation(subjectValue);
                response.then(data => {
                    if (data) {
                        setSubjectTranslation(data.responseData);
                    }
                });
                setIsModalTranslateLoading(true);
                if (mode.toUpperCase() === 'EDIT') {
                    if (subjectValue !== "") {
                        let responseSubject = getSubjectTranslation(subjectValue);
                        responseSubject.then(data => {
                            if (data) {
                                setSubjectTranslation(data.responseData);
                            }
                        });
                    }
                    if (editorValue !== "") {
                        let response = getTranslation();
                        response.then(result => {
                            if (result) {
                                setFromLanguage(result[0]);
                                setTranslatedValue(result[2]);

                                setIsModalTranslateLoading(false);
                            }
                        });
                    }
                }
                setIsShownModalTranslate(true);
            } else {
                if (!formReadyToSave && mode.toUpperCase() === "EDIT") return null;
                handleSaveTask();
            }
        };

        const renderCollectMode = () => {
            let jsxElement: any;
            if (!currentTask && mode !== "PREVIEW") {
                return null;
            }
            jsxElement = (
                <div className="container-fluid">
                    {mode !== "PREVIEW" && (
                        <div className="row">
                            <div className="col d-flex justify-content-end" style={{ margin: '10px 0px' }}>
                                <button
                                    className="btn-close"
                                    aria-label="Close"
                                    style={{ fontSize: '12px' }}
                                    onClick={() => handleCloseTask()}
                                ></button>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <TPPageTitle style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        {mode !== "PREVIEW" ? currentTask?.taskTypeLocalizedDescription : "Text"}
                                    </TPPageTitle>
                                </div>
                                {isWorkflowTask && (
                                    <div>
                                        <div className="dropdown">
                                            <TPButton
                                                iconAtRight={true}
                                                dataBsToggle={true}
                                                type={TPButtonTypes.icon}
                                                onClick={() => {
                                                    TPGlobal.foo();
                                                }}
                                                icon={TPIconTypes.moreVert}
                                                text={actionsLabel}
                                                className={'menu-button'}
                                            ></TPButton>
                                            <ul className="dropdown-menu">
                                                {TPGlobal.globalPermissions.canReassignTask && (
                                                    <li>
                                                        <a
                                                            onClick={() => handleOnActionClick(ActionTypeEnum.Reassign)}
                                                            className="dropdown-item"
                                                            href="#"
                                                        >
                                                            {reassignMenuItemLabel}
                                                        </a>
                                                    </li>
                                                )}

                                                {mode.toUpperCase() !== 'EDIT' && TPGlobal.globalPermissions.canRejectTask && (
                                                    <li>
                                                        <a
                                                            onClick={() => handleOnActionClick(ActionTypeEnum.Reject)}
                                                            className="dropdown-item"
                                                            href="#"
                                                        >
                                                            {rejectMenuItemLabel}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ComposeWithAIAlert caseId={caseNumber} />
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <TPCheckBox
                                labelText={internalReportNotForSendingLabel}
                                checked={isInternalReportNotForSending}
                                onChange={(e: any) => handleInternalReportNotForSending()}
                            ></TPCheckBox>
                        </div>
                        {!isInternalReportNotForSending && (
                            <div className="col-md-4">
                                <TPCheckBox
                                    labelText={useTemplateLabel}
                                    checked={isUseTemplate}
                                    onChange={(e: any) => handleUseTemplate()}
                                ></TPCheckBox>
                            </div>
                        )}
                    </div>
                    {!isInternalReportNotForSending && (
                        <>
                            {TPGlobal.EnableStoryFai === '1' && (
                                <div className="row">
                                    <div
                                        style={{
                                            borderRadius: '4px',
                                            backgroundColor: '#e4ccea',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: '16px',
                                            marginTop: '8px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '8px',
                                                alignContent: 'baseline',
                                                marginTop: '15px',
                                                marginLeft: '15px'
                                            }}
                                        >
                                            <Icon icon="ep:warn-triangle-filled" width="24" height="24" style={{ color: '#3e004e' }} />
                                            <p style={{ color: '#3e004e' }}>{translationFeatureLabel}</p>
                                            {TPGlobal.StoryFaiPreview === '1' && (
                                                <strong style={{ color: '#3e004e', marginRight: '15px' }}>{previewOptionLabel}</strong>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '8px',
                                                alignContent: 'baseline',
                                                marginLeft: '15px'
                                            }}
                                        >
                                            <TPCheckBox
                                                labelText={<p style={{ color: '#3e004e' }}>{useTranslationFeatureLabel}</p>}
                                                checked={useTranslation}
                                                onChange={(e: any) => handleUseTranslation()}
                                            ></TPCheckBox>
                                        </div>
                                        {useTranslation && (
                                            <div style={{ marginLeft: '15px', marginBottom: '15px', marginRight: '15px' }}>
                                                <TPSelect
                                                    isMandatory={useTranslation}
                                                    dataSource={StoryFaiAvailableLanguages.map(
                                                        item => ({ key: item.languageCode, value: item.languageName }) as TPKeyValue
                                                    )}
                                                    value={languageValue}
                                                    labelText={<strong style={{ color: '#3e004e' }}>{languageforTranslationLabel}</strong>}
                                                    onChange={handleLanguageChange}
                                                ></TPSelect>
                                                {isSelectedLanguageError && (
                                                    <div
                                                        style={{
                                                            color: "#DC3545",
                                                            fontSize: "14px",
                                                            margin: "4px 0px 0px 0px",
                                                        }}
                                                    >
                                                        {selectedLanguageError}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                {isReplyToDataExist &&
                                    <div className="col-md-9">
                                        <TPSelect
                                            id='tp-send-email-task-reply-to-select'
                                            isMandatory={false}
                                            onChange={handleReplyToValueChange}
                                            dataSource={replyTo}
                                            value={replyToValue}
                                            labelText={replyToLabel}
                                            errorMessage={replyToValueErrorMessage}
                                        />
                                    </div>}
                                {showReplyAll && !isInternalReportNotForSending && (
                                    <div className="col-md-3" style={{ marginTop: '33px' }}>
                                        <TPCheckBox
                                            labelText={replyToAllLabel}
                                            checked={isReplyToAll}
                                            onChange={(e: any) => handleReplyToAll()}
                                        />
                                    </div>)}
                            </div>
                        </>
                    )}
                    {isUseTemplate && (
                        <div className="row">
                            <div className="col-md-6">
                                <TPSelect
                                    id='tp-send-email-task-template-lang-select'
                                    isMandatory={isUseTemplate}
                                    onChange={handleTemplateLanguageValueChange}
                                    dataSource={templateLanguage}
                                    value={templateLanguageValue}
                                    labelText={templateLanguageLabel}
                                    errorMessage={templateLanguageValueErrorMessage}
                                />
                            </div>
                            <div className="col-md-6">
                                <TPAutoComplete
                                    id='tp-send-email-task-template-select'
                                    isMandatory={isUseTemplate}
                                    labelText={templateLabel}
                                    onValueChange={handleTemplateChange}
                                    onSearch={(query: string) => handleTemplateOnAutocompleteQuery(query)}
                                    isLoading={isLoading}
                                    options={autocompleteTemplateOptions}
                                    withIcon={true}
                                    emptyLabel={emptyLabel}
                                    onKeyDown={handleTemplateOnAutocompleteKeyDown}
                                    selected={modalTemplateScreenState.newTemplate}
                                    errorMessage={modalTemplateScreenState.newTemplateErrorMessage}
                                    downArrowClick={handleAutoCompleteTemplatepNClick}
                                />
                            </div>
                        </div>
                    )}
                    {!isInternalReportNotForSending && (
                        <div className="row">
                            <div className="col-md-12">
                                <TPSelect
                                    isMandatory={!isInternalReportNotForSending}
                                    id="tp-send-email-task-from-select"
                                    onChange={handleFromValueChange}
                                    dataSource={fromKeyValue}
                                    value={fromValue}
                                    labelText={fromLabel}
                                    errorMessage={fromValueErrorMessage}
                                />
                            </div>
                        </div>
                    )}
                    {!isInternalReportNotForSending && (
                        <div className="row position-relative">
                            <div className="col-md-9">
                                <TPTextBox
                                    id="tp-send-email-task-to-select"
                                    labelText={toLabel}
                                    isMandatory={!isInternalReportNotForSending}
                                    value={toValue}
                                    onChange={handleToValueChange}
                                    maxLength={200}
                                    errorMessage={toValueErrorMessage}
                                />
                            </div>
                            <div className="col-md-3 d-flex" style={{ marginTop: '30px', height: '30px' }}>
                                <div className="d-flex">
                                    <div>{ccButtonLabel}</div>
                                    <div className="mt-2 mx-2" style={{ color: isReplyToAll ? '#000' : '#A00095' }}>
                                        <TPIcon iconType={TPIconTypes.email} onClick={handleShowCc} />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>{bccButtonLabel}</div>
                                    <div className="mt-2 mx-2" style={{ color: isReplyToAll ? '#000' : '#A00095' }}>
                                        <TPIcon iconType={TPIconTypes.email} onClick={handleShowBcc} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isInternalReportNotForSending && (
                        <div className="row">
                            {(isReplyToAll || isShowCc || isCcValue) &&
                                <div className="col-md-6">
                                    <TPTextBox
                                        id="tp-send-email-task-cc-select"
                                        labelText={ccLabel}
                                        isMandatory={isReplyToAll}
                                        value={ccValue}
                                        onChange={handleCCValueChange}
                                        maxLength={200}
                                        errorMessage={ccValueErrorMessage}
                                    />
                                </div>}
                            {(isReplyToAll || isShowBcc || isBccValue) &&
                                <div className="col-md-6">
                                    <TPTextBox
                                        id="tp-send-email-task-bcc-select"
                                        labelText={bccLabel}
                                        isMandatory={isReplyToAll}
                                        value={bccValue}
                                        onChange={handleBccValueChange}
                                        maxLength={200}
                                        errorMessage={bccValueErrorMessage}
                                    />
                                </div>}
                        </div>
                    )}
                    {!isInternalReportNotForSending && (
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <TPTextBox
                                    id="tp-send-email-task-subject-select"
                                    labelText={subjectLabel}
                                    isMandatory={!isInternalReportNotForSending}
                                    value={subjectValue}
                                    onChange={handleSubjectValueChange}
                                    maxLength={200}
                                    errorMessage={subjectValueErrorMessage}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginTop: "12px", marginBottom: "6px" }}>
                                {messageLabel}
                                {!isInternalReportNotForSending &&
                                    <span style={{ color: "#FF0000" }}>*</span>}
                            </div>
                            {/*123*/}
                            <div className="text-editor">
                                {!loadingSentimentEditor &&
                                    <TPEditor
                                        id="email-task"
                                        isEdit={!activeSentimentEditor}
                                        referece={(editor: any) =>
                                            (editorRef.current = editor)
                                        }
                                        placeholder={writeSomethingHereLabel}
                                        value={editorValue}
                                        onChange={(e: any) => {
                                            if (!isShownModalSave) {
                                                if (!activeSentimentEditor)
                                                    handleChange(e);
                                            }
                                        }}

                                        initialValue=""
                                        isVisible={false}
                                        icons={handleActiveSentiment("Outbound") ? [(!activeSentimentEditor ? TPIconTypes.sentimentNeutral : TPIconTypes.close), TPIconTypes.fullScreen] : []}
                                        onClickCustomIcon={(e: any) => {
                                            if (e == TPIconTypes.fullScreen.toString()) setisShownModalSave(true);
                                            if (e == TPIconTypes.sentimentNeutral.toString()) {
                                                setActiveSentimentEditor(true);
                                                getsentimentAnalysis("Outbound");
                                            }
                                            if (e == TPIconTypes.close.toString()) {

                                                setEditorValue(editorValueTemp);
                                                setActiveSentimentEditor(false);
                                            }
                                        }}
                                    />
                                }

                                {loadingSentimentEditor &&
                                    <div style={{
                                        height: "200px",
                                        width: "100%",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 0px 2px gray"
                                    }}  >
                                        <TPLoadingSpinner style={{
                                            height: "50px",
                                            left: "50%"
                                        }} top={top} />
                                    </div>
                                }



                                {isMessageError && (
                                    <div
                                        style={{
                                            color: "#DC3545",
                                            fontSize: "14px",
                                            margin: "4px 0px 0px 0px",
                                        }}
                                    >
                                        {pleaseEnterAMessageLabel}
                                    </div>
                                )}
                            </div>
                            <span style={{ color: "#dc3545", fontSize: "14px" }}>
                                {editorErrorMessage}
                            </span>
                            {isSownModalAttachment && (
                                <TPModal
                                    modalState={{
                                        titleModal: newAttachmentLabel,
                                        acceptLabel: okLabel,
                                        cancelLabel: cancelLabel,
                                        callBackAnswer: handleCallBackNewAttachmentModal,
                                        callBackData: null,
                                        isShown: isSownModalAttachment,
                                        modalWidth: ModalSizeEnum.MODALMD,
                                    }}
                                >
                                    <TPLoadingOverlay active={isLoadingScreenModalAttachment}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <TPTextBox
                                                        id="IdTextBox"
                                                        onChange={(e: any) =>
                                                            handleOnAttachmentValueChange(
                                                                e.target.value,
                                                                "sender"
                                                            )
                                                        }
                                                        value={caseAttachmentState.sender}
                                                        isMandatory={true}
                                                        labelText={senderLabel}
                                                        errorMessage={
                                                            caseAttachmentState.senderErrorMessage
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <TPTextArea
                                                        id="IdTextArea"
                                                        onChange={(e: any) =>
                                                            handleOnAttachmentValueChange(
                                                                e.target.value,
                                                                "description"
                                                            )
                                                        }
                                                        value={caseAttachmentState.description}
                                                        isMandatory={false}
                                                        labelText={descriptionLabel}
                                                        errorMessage={
                                                            caseAttachmentState.descriptionErrorMessage
                                                        }
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col p-0">
                                                <FileUploader
                                                    label={
                                                        attachment[0]?.name
                                                            ? attachment[0]?.name
                                                            : dropFilesHereLabel
                                                    }
                                                    multiple={true}
                                                    handleChange={handleUploadFilesDraggedChange}
                                                    UploadButtonText={uploadFileButtonLabel}
                                                />
                                            </div>
                                        </div>
                                    </TPLoadingOverlay>
                                </TPModal>
                            )}
                            {!isInternalReportNotForSending && (
                                <>
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "5px",
                                            fontSize: "22px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {attachmentsLable}
                                    </div>
                                    <div
                                        style={{ border: "1px solid gainsboro", padding: "10px" }}
                                    >
                                        <div className="row" style={{ marginTop: "15px" }}>
                                            <div className="col-md-3">
                                                <TPCheckBox
                                                    labelText={sendCaseLabel}
                                                    checked={isSendCase}
                                                    onChange={(e: any) => handleSendCase()}
                                                ></TPCheckBox>
                                            </div>

                                            <div className="col-md-3">
                                                <TPCheckBox
                                                    disabled={!isSendCase}
                                                    labelText={sendTasksLabel}
                                                    checked={isSendTasks}
                                                    onChange={(e: any) => handleSendTasks()}
                                                ></TPCheckBox>
                                            </div>
                                        </div>
                                        <div className="fw-bold mt-3 mb-3">
                                            {attachNewFileLabel}{" "}
                                            <button
                                                className="ql-handlePopupClick p-0"
                                                style={{ border: "none", background: "transparent" }}
                                                onClick={handleModalPopup}
                                            >
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-paperclip"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        <table className="w-100">
                                            <tr
                                                style={{
                                                    border: "1px solid #dddddd",
                                                    padding: "8px 12px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                <th
                                                    className="col-2 ps-3 "
                                                    style={{ padding: "12px 0px" }}
                                                >
                                                    {deleteLabel}
                                                </th>
                                                <th
                                                    className="col-8 ps-3  border-start"
                                                    style={{ padding: "12px 0px" }}
                                                >
                                                    {fileLabel}
                                                </th>
                                                <th
                                                    className="col-2 ps-1  border-start"
                                                    style={{ padding: "12px 0px" }}
                                                >
                                                    {saveInCaseLabel}
                                                </th>
                                            </tr>
                                            {caseAttachmentState.filesDraggedList.length >= 1 &&
                                                caseAttachmentState.filesDraggedList.map(function (
                                                    item: any,
                                                    index
                                                ) {
                                                    return (
                                                        <tr
                                                            style={{ border: "1px solid #dddddd" }}
                                                            key={"lifile" + index.toString()}
                                                        >
                                                            <td style={{ border: "1px solid #dddddd" }}>
                                                                <TPIcon
                                                                    className="tphover"
                                                                    key={"deletefile" + index.toString()}
                                                                    style={{
                                                                        fontSize: "25px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    iconType={TPIconTypes.delete}
                                                                    onClick={() =>
                                                                        handleOnDeleteAttachment(item.Filename)
                                                                    }
                                                                />
                                                            </td>
                                                            <td>{item.Filename}</td>
                                                            <td style={{ border: "1px solid #dddddd" }}>
                                                                <TPCheckBox
                                                                    labelText={""}
                                                                    id={item.BlobId}
                                                                    checked={item.SaveInCase}
                                                                    onChange={(e: any) =>
                                                                        handleSaveInCase(item.BlobId)
                                                                    }
                                                                ></TPCheckBox>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </table>
                                        <div className="fw-bold mt-3 mb-3">
                                            {addCaseAttachmentLabel}
                                        </div>
                                        <table className="w-100">
                                            <tr style={{ border: "1px solid #dddddd" }}>
                                                <td style={{ border: "1px solid #dddddd" }}>
                                                    <TPCheckBox
                                                        labelText={""}
                                                        checked={isSelectAll}
                                                        onChange={(e: any) => handleSelectAllChange()}
                                                    ></TPCheckBox>
                                                </td>
                                                <td
                                                    style={{ border: "1px solid #dddddd", width: "100%" }}
                                                >
                                                    {allLabel}
                                                </td>
                                            </tr>
                                            {addCaseExistAttachment.length >= 1 &&
                                                addCaseExistAttachment.map(function (item: any, index) {
                                                    return (
                                                        <tr
                                                            style={{ border: "1px solid #dddddd" }}
                                                            key={"lifile" + index.toString()}
                                                        >
                                                            <td style={{ border: "1px solid #dddddd" }}>
                                                                <TPCheckBox
                                                                    labelText={""}
                                                                    checked={item.SaveInCase}
                                                                    onChange={(e: any) =>
                                                                        handleAddCaseExistAttacment(item)
                                                                    }
                                                                ></TPCheckBox>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    border: "1px solid #dddddd",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {item.Filename}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {(isFormActive == "" || mode === "PREVIEW" && formValueSend )&&
                        <div
                            className="row"
                            style={{
                                maxHeight: "150px",
                                overflowY: "auto",
                                marginTop: "5px",
                                marginBottom: "5px",
                                borderRadius: "5px",
                            }}
                        >
                            {mode === "PREVIEW" && formValueSend ? (
                                <FormView
                                    onChangedJson={(e: any) => { }}
                                    onChangedReadyForm={(e: any) => { }}
                                    inputFormId={formValueSend}
                                    inputTryingToSave={false}
                                />
                            ) : renderAdditionalDataList(TPAddtionalDataUIModes.Collect)}
                        </div>
                    }
                  
                    {
                        isShownModalTranslate && (
                            <TPModal
                                modalState={{
                                    titleModal: translationPreviewTitleModal,
                                    acceptLabel: acceptLabel,
                                    cancelLabel: cancelLabel,
                                    callBackAnswer: handleCallbackAnser,
                                    isShown: true,
                                    modalWidth: undefined,
                                }}
                            >
                                <TPLoadingOverlay active={isModalTranslateLoading}>
                                    <div className="text-editor">
                                        <TPEditor
                                            Id="translate-editor"
                                            referece={(editor: any) => (editorRef.current = editor)}
                                            placeholder={writeSomethingHereLabel}
                                            value={translatedValue}
                                            onChange={handleTranslateChange}
                                            initialValue=""
                                            isVisible={true}
                                        />
                                        {isTranslateMessageError && (
                                            <div
                                                style={{
                                                    color: "#DC3545",
                                                    fontSize: "14px",
                                                    margin: "4px 0px 0px 0px",
                                                }}
                                            >
                                                {pleaseEnterAMessageLabel}
                                            </div>
                                        )}
                                    </div>
                                </TPLoadingOverlay>
                            </TPModal>
                        )
                    }

                </div >
            );
            return jsxElement;
        };

        const renderViewMode = () => {
            let jsxElement: any;
            if (!currentTask) return null;
            jsxElement = (
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col d-flex justify-content-end"
                            style={{ margin: "10px 0px" }}
                        >
                            <button
                                className="btn-close"
                                aria-label="Close"
                                style={{ fontSize: "12px" }}
                                onClick={() => handleCloseTask()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <TPPageTitle
                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                    >
                                        {currentTask.taskTypeLocalizedDescription}
                                    </TPPageTitle>
                                </div>
                                {isWorkflowTask && (
                                    <div>
                                        <div className="dropdown">
                                            <TPButton
                                                dataBsToggle={true}
                                                type={TPButtonTypes.icon}
                                                onClick={() => {
                                                    TPGlobal.foo();
                                                }}
                                                icon={TPIconTypes.moreVert}
                                                text={actionsLabel}
                                                className={"menu-button"}
                                            ></TPButton>
                                            <ul className="dropdown-menu">
                                                {TPGlobal.globalPermissions.canRejectTask && (
                                                    <li>
                                                        <a
                                                            onClick={() =>
                                                                handleOnActionClick(ActionTypeEnum.Reject)
                                                            }
                                                            className="dropdown-item"
                                                            href="#"
                                                        >
                                                            {rejectMenuItemLabel}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {currentTask.taskTypeId == taskType.receivedMail && (
                        <TPGenAIHeader
                            id={currentTask.taskTypeId}
                            caseNumber={caseNumber}
                        />
                    )}
                    <div
                        className="container"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        {handleIsActiveBtnOriginalEmail() ? (
                            <button
                                type="button"
                                style={{
                                    ...allThemes.base.accordion.collapseButton,
                                    borderRadius: "4px",
                                    width: "100%",
                                    marginTop: "16px",
                                    marginBottom: "16px",
                                }}
                                onClick={() =>
                                    setEmailStatus(
                                        emailStatus == currentAccordionsEmailMenu.TRANSLATION
                                            ? currentAccordionsEmailMenu.ORIGIN
                                            : currentAccordionsEmailMenu.TRANSLATION
                                    )
                                }
                            >
                                {emailStatus == currentAccordionsEmailMenu.TRANSLATION ? (
                                    <Icon icon="ph:caret-up-bold" />
                                ) : (
                                    <Icon icon="ph:caret-down-bold" />
                                )}
                            </button>
                        ) : null}
                    </div>
                    <div style={{
                        display: "flex", justifyContent: "end"
                    }} >

                        {handleActiveSentiment("Inbound") &&
                            <TPCheckBox
                                id="id-cognitive-analysis"
                                key={"id-cognitive-analysis"}
                                labelText={cognitiveAnalysisLabel}
                                checked={cognitiveAnalysCheck}
                                onChange={(e: any) => {
                                    setCognitiveAnalysCheck(!cognitiveAnalysCheck);
                                    //if (!cognitiveAnalysCheck)
                                    //    getsentimentAnalysis("Inbound");
                                }
                                }
                            ></TPCheckBox>
                        }
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div>{reportSummaryLabel}</div>

                            <div
                                className="form-control"
                                style={{
                                    minHeight: "100px",
                                    overflowY: "auto",
                                    textAlign: "justify",
                                    alignItems: "center"
                                }}

                                dangerouslySetInnerHTML={{ __html: (cognitiveAnalysCheck ? inboundMailSentiment : handleCurrentTaskReport()) }}

                            >
                            </div>

                            {
                                cognitiveAnalysCheck && inboundMailSentiment && inboundMailSentiment != "" && (

                                    <div style={{ display: "flex", justifyContent: "start", gap: "8px", alignItems: "center" }} >
                                        <TPLabel labelText={cognitiveAnalysisLabel} />:
                                        <TPIcon iconType={TPIconTypes.sentimentDissatisfied} style={generalSentiment == "negative" ? { color: "red", fontSize: "35px", boxShadow: "black 0px 13px 10px -11px", borderRadius: "100%" } : { color: "red", fontSize: "30px" }} /> {/*123*/}
                                        <TPIcon iconType={TPIconTypes.sentimentNeutral} style={generalSentiment == "neutral" ? { color: "#ef7f37", fontSize: "35px", boxShadow: "black 0px 13px 10px -11px", borderRadius: "100%" } : { color: "#ef7f37", fontSize: "30px" }} />
                                        <TPIcon iconType={TPIconTypes.sentimentSatisfiedAlt} style={generalSentiment == "positive" ? { color: "green", fontSize: "35px", boxShadow: "black 0px 13px 10px -11px", borderRadius: "100%" } : { color: "green", fontSize: "30px" }} />
                                    </div>
                                )
                            }


                        </div>
                    </div>
                    {isFormActive == "" &&
                        <div
                            className="row"
                            style={{
                                maxHeight: "150px",
                                overflowY: "auto",
                                marginTop: "5px",
                                marginBottom: "5px",
                                borderRadius: "5px",
                            }}
                        >
                            {renderAdditionalDataList(TPAddtionalDataUIModes.View1)}
                        </div>
                    }

                </div>
            );
            return jsxElement;
        };

        const renderAdditionalDataList = (addtionalDataUIMode: TPAddtionalDataUIModes) => {
            let endAdditionalDataValues = additionalDataValues;
            if (mode === "PREVIEW" && additionalDataValuesSend) {
                endAdditionalDataValues = additionalDataValuesSend;
            }

            return endAdditionalDataValues.map(function (item) {
                let elementJsx;
                let index: number;
                let value: any;
                index = endAdditionalDataValues.findIndex(
                    (x: TPKeyValue) => x.key == item.key
                );
                value = endAdditionalDataValues[index].value;
                if (addtionalDataUIMode == TPAddtionalDataUIModes.Collect) {
                    elementJsx = (
                        <div className="col-6 mb-2" key={"adda" + item.key}>
                            <TPRenderAdditionalDataSwitch
                                guidControl={taskguid}
                                modeUI={addtionalDataUIMode}
                                defaultValue={value}
                                itemToRender={item}
                                ref={(element: any) => {
                                    childRefArray.current[item.key] = element;
                                }}
                                onChange={(
                                    idControl: any,
                                    newValue: string,
                                    addtionalDataId: string
                                ) =>
                                    handleChangeAditionalDataValue(idControl, newValue, item.key)
                                }
                            />
                        </div>
                    );
                } else {
                    elementJsx = (
                        <div className="col-6 mb-2" key={"adda" + item.key}>
                            <TPRenderAdditionalDataSwitch
                                guidControl={taskguid}
                                modeUI={addtionalDataUIMode}
                                defaultValue={value}
                                itemToRender={item}
                                ref={(element: any) => {
                                    childRefArray.current[item.key] = element;
                                }}
                            />
                        </div>
                    );
                }

                return elementJsx;
            });
        };

        const validateAdditonalData = (): boolean => {
            let result: boolean = true;
            for (let i = 0; i <= additionalDataValues.length - 1; i++) {
                let element: TPKeyValue;
                element = additionalDataValues[i];

                if (childRefArray.current) {
                    const keys = Object.keys(childRefArray.current);
                    if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
                        let controlref: any = childRefArray.current[element.key];
                        if (!controlref.validateFromParent()) {
                            result = false;
                            break;
                        }
                    }
                }
            }
            return result;
        };

        const getFinalAdditonalDataValues =
            (): Array<TEmpAdditionalDataCaseViewModel> => {
                let result: Array<TEmpAdditionalDataCaseViewModel> = [];
                for (let i = 0; i <= additionalDataValues.length - 1; i++) {
                    let element: TPKeyValue;
                    element = additionalDataValues[i];

                    if (childRefArray.current) {
                        const keys = Object.keys(childRefArray.current);
                        if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
                            let controlref: any = childRefArray.current[element.key];

                            let stringValue: string;
                            stringValue = controlref.getValueFromParent();
                            if (stringValue) {
                                let oneAddData: TEmpAdditionalDataCaseViewModel;
                                oneAddData = {
                                    additionalDataId: element.key,
                                    value: stringValue,
                                    isMandatory: element.value2.isMandatory,
                                };
                                result.push(oneAddData);
                            }
                        }
                    }
                }
                return result;
            };

        const getFinalAdditonalDataValuesForm =
            (): Array<TEmpAdditionalDataCaseViewModel> => {
                let result: Array<TEmpAdditionalDataCaseViewModel> = [];

                additionalDataValues.forEach((e) => {
                    let oneAddData: TEmpAdditionalDataCaseViewModel;
                    oneAddData = {
                        additionalDataId: e.key,
                        value: e.value,
                        isMandatory: false,
                    };
                    result.push(oneAddData);
                });

                return result;
            };

        const getHeaderValues = () => {
            let headerValues: Array<TPKeyValue> = [];
            if (currentTask) {
                headerValues.push({
                    key: responsibleLabel,
                    value: currentTask?.writerUserName,
                });
                headerValues.push({
                    key: creationDateLabel,
                    value: currentTask?.creationDateFormatted,
                });
                headerValues.push({
                    key: startDateLabel,
                    value: currentTask?.startDateFormatted,
                });
                headerValues.push({
                    key: dueToDateLabel,
                    value: currentTask?.limitDateFormatted,
                    value2: true,
                });
                headerValues.push({
                    key: readerLabel,
                    value: currentTask?.readerUserName,
                });
            }
            return headerValues;
        };

        const handleChangeAditionalDataValue = (
            idControl: string,
            newValue: any,
            additionalDataId: string
        ) => {
            let newAddtionalDataValues: Array<TPKeyValue> = [...additionalDataValues];
            for (let i = 0; i <= newAddtionalDataValues.length - 1; i++) {
                if (newAddtionalDataValues[i].key === additionalDataId) {
                    newAddtionalDataValues[i].value = newValue;
                    setHasBeenModified(true);
                    break;
                }
            }
            setAdditionalDataValues(newAddtionalDataValues);
        };

        const realRefreshAdditionalData = (
            newAdditionaDataToCheck: Array<TEmpAdditionalDataTaskViewModel>
        ) => {
            let newAdditionalDataValues: Array<TPKeyValue> = [
                ...additionalDataValues,
            ];
            let found: boolean = false;
            for (let i = 0; i <= newAdditionaDataToCheck.length - 1; i++) {
                for (let j = 0; j <= newAdditionalDataValues.length - 1; j++) {
                    if (
                        newAdditionaDataToCheck[i].additionalDataId.toUpperCase() ===
                        newAdditionalDataValues[j].key.toUpperCase()
                    ) {
                        newAdditionalDataValues[j].value = newAdditionaDataToCheck[i].value;
                        found = true;
                    }
                }
            }
            if (found) {
                setAdditionalDataValues(newAdditionalDataValues);
            }
        };

        let alreadyLoadReasons: boolean = false;
        const handleOnActionClick = async (actionName: ActionTypeEnum) => {
            setIsShownActionModal(true);
            switch (actionName) {
                case ActionTypeEnum.Reassign:
                    setTaskTitleModal(
                        await TPI18N.GetText(
                            resourceSetReassignModal,
                            "ReassignTaskTitleModal"
                        )
                    );
                    setIsLoadingModalScreen(false);
                    break;
                case ActionTypeEnum.Reject:
                    setTaskTitleModal(
                        await TPI18N.GetText(resourceSetRejectModal, "RejectTaskTitleModal")
                    );
                    if (!alreadyLoadReasons) {
                        await getReasonsDatalist();
                        alreadyLoadReasons = true;
                    }
                    setIsLoadingModalScreen(false);
                    break;
                default:
                    break;
            }
            setCurrentActionType(actionName);
        };

        const getReasonsDatalist = async () => {
            let parametersService = new ParametersService();
            let expectedCodes: Array<number> = [200];
            try {
                let responseRequest =
                    await parametersService.getByParentIdAndFilterIsActive(
                        SystemParametersEnum.REFORETA,
                        TPActiveOptions.ACTIVE.toString(),
                        false,
                        true,
                        expectedCodes
                    );
                if (responseRequest) {
                    let newReasonList: Array<TPKeyValue> = responseRequest.map(
                        function (item) {
                            return { key: item.id, value: item.localizedDescription };
                        }
                    );
                    newReasonList.unshift({ key: "", value: "--" });
                    setReasonList(newReasonList);
                } else {
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getReasonsDatalist ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getReasonsDatalist ex`);
                return null;
            }
        };

        const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
            let newmodalReassignScreenState = { ...modalReassignScreenState };
            newmodalReassignScreenState.newResponsibleGuidUser = newSelectedValue;
            newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
            setModalReassignScreenState(newmodalReassignScreenState);
        };

        const handleTemplateChange = (newSelectedValue: Array<TPKeyValue>) => {
            let newmodalTemplateScreenState = { ...modalTemplateScreenState };
            newmodalTemplateScreenState.newTemplate = newSelectedValue;
            newmodalTemplateScreenState.newTemplateErrorMessage = "";
            setModalTemplateScreenState(newmodalTemplateScreenState);
        };

        const handleToOnAutocompleteQuery = async (query: string) => {
            let tasktypeService = new UserService();
            let expectedCodes: Array<number> = [200, 404];

            try {
                let responseRequest =
                    await tasktypeService.getActiveUsersBySearchParameter(
                        query,
                        false,
                        true,
                        expectedCodes
                    );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return {
                            key: item.userGuid,
                            value: `${item.name}`,
                        };
                    }
                );
                setAutocompleteToOptions(newToKeyValueList);
                return newToKeyValueList;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} handleToOnAutocompleteQuery ex`
                );
                return [];
            }
        };

        const handleTemplateOnAutocompleteQuery = async (query: string) => {
            let tasktypeService = new UserService();
            let expectedCodes: Array<number> = [200, 404];

            try {
                let responseRequest = await tasktypeService.getEmailTemplatesBySearch(
                    query,
                    "1",
                    templateLanguageValue,
                    false,
                    true,
                    expectedCodes
                );
                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item) {
                        return {
                            key: item.id,
                            value: `${item.localizedDescription}`,
                        };
                    }
                );
                setAutocompleteTemplateOptions(newToKeyValueList);
                return newToKeyValueList;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleTemplatOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} handleTemplateOnAutocompleteQuery ex`
                );
                return [];
            }
        };

        const handleToOnAutocompleteKeyDown = (event: any) => {
            const inputValue = event.target.value;
            if (inputValue.length === 1) {
                handleToOnAutocompleteQuery("");
            }
        };

        const handleTemplateOnAutocompleteKeyDown = (event: any) => {
            if (
                event.keyCode != 37 &&
                event.keyCode != 39 &&
                event.keyCode != 13 &&
                event.keyCode != 35 &&
                event.keyCode != 36
            ) {
                setAutocompleteTemplateOptions([]);
                let newModalTemplateScreenState = { ...modalTemplateScreenState };
                newModalTemplateScreenState.newTemplate = [];
                newModalTemplateScreenState.newTemplateErrorMessage = "";
                setModalTemplateScreenState(newModalTemplateScreenState);
            }
        };

        const handleAutoCompleteTopNClick = async () => {
            let newTopNOptions: Array<TPKeyValue> = [];
            if (autocompleteToTopNOptions.length === 0) {
                newTopNOptions = await handleToOnAutocompleteQuery("");

                if (newTopNOptions.length >= 1) {
                    setAutocompleteToTopNOptions([...newTopNOptions]);
                    setAutocompleteToOptions([...newTopNOptions]);
                    let newModalReassignScreenState = { ...modalReassignScreenState };
                    newModalReassignScreenState.newResponsibleGuidUser = [];
                    newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                    setModalReassignScreenState(newModalReassignScreenState);
                }
            } else {
                setAutocompleteToOptions([...autocompleteToTopNOptions]);
                let newModalReassignScreenState = { ...modalReassignScreenState };
                newModalReassignScreenState.newResponsibleGuidUser = [];
                newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
                setModalReassignScreenState(newModalReassignScreenState);
            }
        };

        const handleAutoCompleteTemplatepNClick = async () => {
            let newTemplatepTopNOptions: Array<TPKeyValue> = [];
            if (autocompleteTemplateTopNOptions.length === 0) {
                newTemplatepTopNOptions = await handleTemplateOnAutocompleteQuery("");

                if (newTemplatepTopNOptions.length >= 1) {
                    setAutocompleteTemplateTopNOptions([...newTemplatepTopNOptions]);
                    setAutocompleteTemplateOptions([...newTemplatepTopNOptions]);
                    let newModalTemplateScreenState = { ...modalTemplateScreenState };
                    newModalTemplateScreenState.newTemplate = [];
                    newModalTemplateScreenState.newTemplateErrorMessage = "";
                    setModalTemplateScreenState(newModalTemplateScreenState);
                }
            } else {
                setAutocompleteTemplateOptions([...autocompleteTemplateTopNOptions]);
                let newModalTemplateScreenState = { ...modalTemplateScreenState };
                newModalTemplateScreenState.newTemplate = [];
                newModalTemplateScreenState.newTemplateErrorMessage = "";
                setModalTemplateScreenState(newModalTemplateScreenState);
            }
        };

        const handleReassignsCommentOnChange = (newValue: string) => {
            let newModalReassignScreenState = { ...modalReassignScreenState };
            newModalReassignScreenState.report = newValue;
            newModalReassignScreenState.reportErrorMessage = "";
            setModalReassignScreenState(newModalReassignScreenState);
        };

        const handleRejectCommentOnChange = (newValue: string) => {
            let newModalRejectScreenState = { ...modalRejectScreenState };
            newModalRejectScreenState.comments = newValue;
            newModalRejectScreenState.commentsErrorMessage = "";
            setModalRejectScreenState(newModalRejectScreenState);
        };

        const handleCallbackAnserModal = async (confirm: boolean, data: any) => {
            if (confirm) {
                switch (currentActionType) {
                    case ActionTypeEnum.Reassign:
                        let recordInputDTO: ReassignTaskInputDTO = {
                            taskId: taskIdToView,
                            newResponsibleGuidUser:
                                modalReassignScreenState.newResponsibleGuidUser.length > 0
                                    ? modalReassignScreenState.newResponsibleGuidUser[0].key
                                    : "",
                            report: modalReassignScreenState.report,
                        };
                        let inputDTOValidator = new ReassignTaskInputDTOValidator();
                        let resultValidator = inputDTOValidator.validate(recordInputDTO);
                        if (!TPGlobal.TPIsEmpty(resultValidator)) {
                            let newModalReassignScreenState = { ...modalReassignScreenState };
                            var listPropertyNames = Object.keys(resultValidator);
                            if (listPropertyNames) {
                                for (let index = 0; index < listPropertyNames.length; index++) {
                                    const element = listPropertyNames[index];
                                    if (resultValidator[element]) {
                                        newModalReassignScreenState[element + "ErrorMessage"] =
                                            await TPI18N.GetResource(
                                                resultValidator[element] as string
                                            );
                                    } else {
                                        newModalReassignScreenState[element + "ErrorMessage"] = "";
                                    }
                                }
                            }
                            setModalReassignScreenState(newModalReassignScreenState);
                            return;
                        }
                        if (await reassignTask(recordInputDTO)) {
                            if (actionsTaskCallBack) {
                                actionsTaskCallBack(currentActionType);
                            }
                        }
                        break;
                    case ActionTypeEnum.Reject:
                        let recordInputRejectTaskDTO: TaskRejectInputDTO = {
                            id: taskIdToView,
                            reasonId: modalRejectScreenState.reasonId,
                            comments: modalRejectScreenState.comments,
                            typistGuidUser: TPGlobal.currentUserGuid,
                        };
                        let inputDTORejectTaskValidator = new TaskRejectInputDTOValidator();
                        let resultValidatorRejectTask =
                            inputDTORejectTaskValidator.validate(recordInputRejectTaskDTO);
                        if (!TPGlobal.TPIsEmpty(resultValidatorRejectTask)) {
                            let newModalRejectScreenState = { ...modalRejectScreenState };
                            var listPropertyNamesRejectTask = Object.keys(
                                resultValidatorRejectTask
                            );
                            if (listPropertyNamesRejectTask) {
                                for (
                                    let index = 0;
                                    index < listPropertyNamesRejectTask.length;
                                    index++
                                ) {
                                    const element = listPropertyNamesRejectTask[index];
                                    if (resultValidatorRejectTask[element]) {
                                        newModalRejectScreenState[element + "ErrorMessage"] =
                                            await TPI18N.GetResource(
                                                resultValidatorRejectTask[element] as string
                                            );
                                    } else {
                                        newModalRejectScreenState[element + "ErrorMessage"] = "";
                                    }
                                }
                            }
                            setModalRejectScreenState(newModalRejectScreenState);
                            return;
                        }

                        if (await rejectTask(recordInputRejectTaskDTO)) {
                            if (actionsTaskCallBack) {
                                actionsTaskCallBack(currentActionType);
                            }
                        }
                        break;
                }
            }
            setIsShownActionModal(false);
            setModalReassignScreenState(initialModalReassignScreenState);
            setModalRejectScreenState(initialModalRejectScreenState);
        };

        const reassignTask = async (
            inputDTO: ReassignTaskInputDTO
        ): Promise<boolean> => {
            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200];

            try {
                setIsLoadingModalScreen(true);

                let responseRequest = await serviceClient.reassignTaskResponsible(
                    inputDTO,
                    true,
                    true,
                    expectedCodes
                );
                setIsLoadingModalScreen(false);
                if (responseRequest.responseResult) {
                    return true;
                }
                return false;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} reassignTask ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} reassignTask ex`);
                setIsLoadingModalScreen(false);
                return false;
            }
        };

        const rejectTask = async (
            inputDTO: TaskRejectInputDTO
        ): Promise<boolean> => {
            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200, 404, 400];

            try {
                setIsLoadingModalScreen(true);

                let responseRequest = await serviceClient.rejectTask(
                    inputDTO,
                    true,
                    true,
                    expectedCodes
                );
                setIsLoadingModalScreen(false);
                if (responseRequest.responseResult) {
                    return true;
                }
                return false;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} rejectTask ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} rejectTask ex`);
                setIsLoadingModalScreen(false);
                return false;
            }
        };

        const handleOnReasonIdChange = (e: any) => {
            let newModalRejectScreenState = { ...modalRejectScreenState };
            newModalRejectScreenState.reasonId = e.target.value;
            newModalRejectScreenState.reasonIdErrorMessage = "";
            setModalRejectScreenState(newModalRejectScreenState);
        };

        const handleGetOriginalEmail = async () => {
            let taskService = new TaskService();
            let expectedCodes: Array<number> = [200, 404];
            var responseRequest = new Array();

            try {
                responseRequest = await taskService.getInboundMailboxReadByCaseId(
                    caseNumber,
                    false,
                    true,
                    expectedCodes
                );
                setMailboxID(responseRequest[0].id);

                let insertionType = responseRequest.find(
                    (s) => s.taskId == taskIdToView
                );
                if (insertionType) {
                    setOriginalEmail(insertionType.plainTextBody);
                }
            } catch (error) {
                setOriginalEmail("...");
            }
        };

        const handleIsActiveBtnOriginalEmail = (): boolean => {
            let isActive = false;

            if (
                TPGlobal.EnableStoryFai === "1" &&
                caseInformation?.caseType === TPGlobal.CaseType.MAIL_INBOUND &&
                currentTask?.taskTypeId === "S_RECEMA"
            ) {
                isActive = true;
            }

            return isActive;
        };




        const getColorBySentiment = (sentiment: string) => {
            switch (sentiment.toLowerCase()) {
                case 'neutral':
                    return 'rgba(238, 176, 37, 0.4)';
                case 'positive':
                    return 'rgba(111, 191, 68, 0.4)';
                case 'negative':
                    return 'rgba(240, 128, 55, 0.4)';
                default:
                    return '#ffffff';
            }
        };

        const escapeHtml = (unsafe: string) => {
            return unsafe
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        }

        const highlightTextBySentiment = (sentences: any, taskEmail: string) => {
            let highlightedText = "";
            let lastOffset = 0;

            sentences.forEach((sentence: any) => {
                const { sentiment } = sentence;
                highlightedText += `<span style="background-color: ${getColorBySentiment(sentiment)};">${sentence.text}</span>`;
                if (sentence.text.includes(".")) highlightedText += `<p></p>`;
            });

            if (taskEmail == "Outbound") setEditorValue(highlightedText);
            if (taskEmail == "Inbound") setInboundMailSentiment(highlightedText);
        };


        const getsentimentAnalysis = async (
            taskEmail: string
        ) => {

            setLoadingSentimentEditor(true);

            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200, 400];

            if (editorValue != "") {
                try {
                    let responseRequest = await serviceClient.getsentimentAnalysis(
                        removeHtmlAndCss(editorValue),
                        false,
                        true,
                        expectedCodes
                    );
                    setLoadingSentimentEditor(false);
                    setActiveSentimentEditor(true);
                    let data = responseRequest.responseData.data;
                    highlightTextBySentiment(data.sentences, taskEmail);

                    if (responseRequest.responseData.responseResult) {

                    }

                } catch (error) {
                    TPLog.Log(
                        `Error ${componentFileName} reassignTask ex`,
                        TPLogType.ERROR,
                        error
                    );
                }

                console.error(`Error ${componentFileName} reassignTask ex`);
                setIsLoadingModalScreen(false);
                return false;
            }
        };


        const getsentimentAnalysisByIdCase = async (
            taskEmail: string
        ) => {

            let serviceClient = new TaskService();
            let expectedCodes: Array<number> = [200, 400, 404];



            try {
                let responseRequest = await serviceClient.getSentimentAnalysisByTaskId(
                    taskIdToView,
                    true,
                    true,
                    expectedCodes
                );

                let data = responseRequest.responseData.data;

                highlightTextBySentiment(data[0].sentences, taskEmail);
                setGeneralSentiment(data[0].sentiment);
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} reassignTask ex`,
                    TPLogType.ERROR,
                    error
                );


                console.error(`Error ${componentFileName} reassignTask ex`);

                return false;
            }

        };
        function removeHtmlAndCss(content: string): string {
            const textWithoutHtml = content.replace(/<[^>]*>/g, "");
            const cleanText = textWithoutHtml.replace(/style=["'][^"']*["']/g, "");

            return cleanText.trim();
        }


        const handleActiveSentiment = (mode: string) => {
            let active = true;
            if (mode == "Outbound") {
                if (TPGlobal.EnableStoryFai != "1") active = false;
            }

            if (mode == "Inbound") {
                if (TPGlobal.EnableStoryFai != "1") active = false;
                if (taskData.taskTypeId != "S_RECEMA") active = false;
            }

            return active;
        }


        useEffect(() => {
            childRefArray.current = [];
            loadResourcesAndTaskInfo();
            handleGetOriginalEmail();
            getsentimentAnalysisByIdCase("Inbound");
        }, []);



        return (
            <>

                <TPModal
                    modalState={{
                        titleModal: `${fullViewLabel}`,
                        acceptLabel: modalSaveLabel,
                        showCancelButton: false,
                        callBackAnswer: (e: any) => {
                            setisShownModalSave(false);
                        },
                        callBackData: null,
                        isShown: isShownModalSave,
                        modalWidth:
                            currentActionType === ActionTypeEnum.Reassign
                                ? ModalSizeEnum.MODALMD
                                : ModalSizeEnum.MODALXL,
                    }}
                >

                    <div className="text-editor">
                        {!loadingSentimentEditor &&
                            <TPEditor
                                id="email-task-full"
                                referece={(editor: any) =>
                                    (editorRef.current = editor)
                                }
                                placeholder={writeSomethingHereLabel}
                                value={editorValue}
                                onChange={(e: any) => {

                                    if (isShownModalSave) {
                                        if (!activeSentimentEditor)
                                            handleChange(e);
                                    }

                                }}

                                initialValue=""
                                isVisible={false}
                                icons={[(!activeSentimentEditor ? TPIconTypes.sentimentNeutral : TPIconTypes.close)]}
                                onClickCustomIcon={(e: any) => {
                                    if (e == TPIconTypes.fullScreen.toString()) setisShownModalSave(true);
                                    if (e == TPIconTypes.sentimentNeutral.toString()) {
                                        setActiveSentimentEditor(true);
                                        getsentimentAnalysis("Outbound");
                                    }
                                    if (e == TPIconTypes.close.toString()) {
                                        setEditorValue(editorValueTemp);
                                        setActiveSentimentEditor(false);
                                    }
                                }}
                            />
                        }

                        {loadingSentimentEditor &&
                            <div style={{
                                height: "200px",
                                width: "100%",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 0px 2px gray"
                            }}  >
                                <TPLoadingSpinner style={{
                                    height: "50px",
                                    left: "50%"
                                }} top={top} />
                            </div>
                        }


                        {isMessageError && (
                            <div
                                style={{
                                    color: "#DC3545",
                                    fontSize: "14px",
                                    margin: "4px 0px 0px 0px",
                                }}
                            >
                                {pleaseEnterAMessageLabel}
                            </div>
                        )}
                    </div>
                </TPModal>
                <TPModal
                    modalState={{
                        titleModal: `${taskTitleModal} - ${currentTask?.taskTypeLocalizedDescription}`,
                        acceptLabel: modalAcceptLabel,
                        cancelLabel: modalCancelLabel,
                        callBackAnswer: handleCallbackAnserModal,
                        callBackData: null,
                        isShown: isShownActionModal,
                        modalWidth:
                            currentActionType === ActionTypeEnum.Reassign
                                ? ModalSizeEnum.MODALMD
                                : ModalSizeEnum.MODALXL,
                    }}
                >
                    <TPLoadingOverlay active={isLoadingModalScreen}>
                        {currentActionType === ActionTypeEnum.Reassign && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <TPAutoComplete
                                                isMandatory={true}
                                                labelText={toLabel}
                                                onValueChange={handleToChange}
                                                onSearch={(query: string) => {
                                                    handleToOnAutocompleteQuery(query);
                                                }}
                                                isLoading={false}
                                                options={autocompleteToOptions}
                                                withIcon={true}
                                                emptyLabel={emptyLabel}
                                                onKeyDown={handleToOnAutocompleteKeyDown}
                                                selected={
                                                    modalReassignScreenState.newResponsibleGuidUser
                                                }
                                                errorMessage={
                                                    modalReassignScreenState.newResponsibleGuidUserErrorMessage
                                                }
                                                downArrowClick={handleAutoCompleteTopNClick}
                                            ></TPAutoComplete>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TPTextArea
                                            id="IdTextArea"
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleReassignsCommentOnChange(e.target.value)
                                            }
                                            value={modalReassignScreenState.report}
                                            rows={7}
                                            errorMessage={modalReassignScreenState.reportErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {currentActionType === ActionTypeEnum.Reject && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <TPSelect
                                                id="IdSelect"
                                                isMandatory={true}
                                                labelText={reasonsLabel}
                                                onChange={handleOnReasonIdChange}
                                                dataSource={reasonList}
                                                value={modalRejectScreenState.reasonId}
                                                errorMessage={
                                                    modalRejectScreenState.reasonIdErrorMessage
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TPTextArea
                                            id="IdTextArea"
                                            labelText={commentsLabel}
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleRejectCommentOnChange(e.target.value)
                                            }
                                            value={modalRejectScreenState.comments}
                                            rows={7}
                                            errorMessage={modalRejectScreenState.commentsErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </TPLoadingOverlay>
                </TPModal>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div>
                        {(mode.toUpperCase() === "EDIT" || mode.toUpperCase() === "PREVIEW")
                            ? renderCollectMode()
                            : renderViewMode()}

                        <TaskFormView
                            additionalDataTask={additionalDataValues}
                            onChangedDatForm={(data: any) => {
                                setAdditionalDataValues(data);
                            }}
                            onChangedReadyForm={(e: any) => { setFormReadyToSave(e); }}
                            formId={isFormActive}
                            inputTryingToSave={inputTryingToSave}
                            modeEdit={mode.toUpperCase()}
                            visible={isFormActive != ""}
                        />

                        {(mode.toUpperCase() === "EDIT" || mode.toUpperCase() === "PREVIEW") &&
                            < div className="row">
                                <div className="col">
                                    <TPPageAcceptCancelButtonsContainer
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={() => {
                                                if (activeSentimentEditor) {
                                                    setEditorValue(editorValueTemp);
                                                    setActiveSentimentEditor(false);
                                                }
                                                handleTracking();
                                            }}
                                            disabled={mode === "PREVIEW" ? true : false}
                                        >
                                            {saveButtonLabel}
                                        </TPButton>
                                    </TPPageAcceptCancelButtonsContainer>
                                </div>
                            </div>
                        }
                        <div
                            className="row"
                            style={{
                                border: "1px solid #dee2e6",
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        ></div>


                    </div>
                </TPLoadingOverlay >
            </>
        );
    }
);
