import React, { useEffect, useState } from "react";

interface AnimatedNumberProps {
  targetNumber: number;
  duration?: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({
  targetNumber,
  duration = 1000,
}) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const increment = targetNumber / (duration / 10);
    let current = 0;

    const intervalId = setInterval(() => {
      current += increment;
      if (current >= targetNumber) {
        setCurrentNumber(targetNumber);
        clearInterval(intervalId);
      } else {
        setCurrentNumber(Math.round(current));
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetNumber, duration]);

  return <>{currentNumber}</>;
};

export default AnimatedNumber;
