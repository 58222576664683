import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageSectionTitle } from "@/components/TPPage/tpPageStyles";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { UserViewModel } from "@/models/Users/UserModels";
import TablePager from "@/modules/core/components/dynamic-table/TablePager";
import { StyledActionButtons, StyledDialogContent } from "@/modules/core/design-system/design-system-styles";
import UserProfile, { ThemeCase } from "@/modules/core/design-system/user-data/UserProfile";
import { ActiveTeamMembersService } from "@/services/ActiveTeamMembersService";
import { TPI18N } from "@/services/I18nService";
import { UserService } from "@/services/UserService";
import { Dialog } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { StyledUserProfileCompleted } from "../supervisor-cockpit-styles";
import "./active-team-members-styles.css";
import { TeamActivityTickets } from "./ActiveTeamMembersTickets";

type ReassignModalProps = {
    handleModalClose: (status: boolean, code?: number, file?: File) => void;
    selectedRows: TeamActivityTickets[];
    hasEvents: boolean;
    callback: Function;
};

interface employeeDisplayModel {
    name: string;
    id: number;
}

interface CheckboxStyleProps {
    employee: employeeDisplayModel;
}

interface casesAndUsers {
    caseId: number;
    userCurrentId: number;
}

interface reassignModel {
    userNewId: number;
    casesAndUsers: casesAndUsers[];
}

const ReassignModal: FC<ReassignModalProps> = ({ handleModalClose, selectedRows, hasEvents, callback }) => {

    const componentFileName = "ConfirmationModal.tsx";

    const resourceSet = "ConfirmationModal"

    const [willReasingEvents, setWillReasingEvents] = useState(hasEvents);

    const [filter, setFilter] = useState("");

    const [text, setText] = useState("");

    const [currentPage, setCurrentPage] = useState(1);

    const [itemsPerPage, setItemsPerPage] = useState(8);

    const [selectedEmployee, setSelectedEmployee] = useState<employeeDisplayModel | null>(null);

    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationQuestion, setConfirmationQuestion] = useState("");
    const [confirmationText1, setConfirmationText1] = useState("");
    const [confirmationText2, setConfirmationText2] = useState("");

    const [cancelButton, setCancelButton] = useState("");
    const [continueButton, setContinueButton] = useState("");

    const [reassignTitle, setReassignTitle] = useState("");
    const [reassignText, setReassignText] = useState("");
    const [selectLabel, setSelectLabel] = useState("");

    const [goBackLabel, setGoBackLabel] = useState("");
    const [saveLabel, setSaveLabel] = useState("");

    const [caseLabel, setCaseLabel] = useState("");
    const [eventLabel, setEventLabel] = useState("");

    const [searchLabel, setSearchLabel] = useState("");

    const [ticketLabel, setTicketLabel] = useState("");

    const loadResources = async () => {
        setConfirmationTitle(await TPI18N.GetText(resourceSet, "confirmationTitleLabel"));
        setConfirmationQuestion(await TPI18N.GetText(resourceSet, "confirmationQuestionLabel"));
        setConfirmationText1(await TPI18N.GetText(resourceSet, "confirmationText1Label"));
        setConfirmationText2(await TPI18N.GetText(resourceSet, "confirmationText2Label"));
        setCancelButton(await TPI18N.GetText(resourceSet, "cancelLabel"));
        setContinueButton(await TPI18N.GetText(resourceSet, "continueLabel"));
        setReassignTitle(await TPI18N.GetText(resourceSet, "ReassignTitleLabel"));
        setReassignText(await TPI18N.GetText(resourceSet, "ReassignTextLabel"));
        setSelectLabel(await TPI18N.GetText(resourceSet, "SelectLabel"));
        setGoBackLabel(await TPI18N.GetText(resourceSet, "goBackLabel"));
        setSaveLabel(await TPI18N.GetText(resourceSet, "saveeLabel"));
        setCaseLabel(await TPI18N.GetText(resourceSet, "CaseLabel"));
        setEventLabel(await TPI18N.GetText(resourceSet, "EventLabel"));
        setSearchLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "searchLabel"));
        setTicketLabel(await TPI18N.GetText("ActiveTeamMembersTickets", "ticketLabel"));
    }

    const [employees, setEmployees] = useState<Array<employeeDisplayModel>>([]);

    const reassingTickets = async () => {
        let ATMService = new ActiveTeamMembersService();

        let expectedCodes = [200];

        let reassignList: casesAndUsers[] = [];

        for (let i = 0; i < selectedRows.length; i++) {
            reassignList.push({
                caseId: selectedRows[i].id,
                userCurrentId: selectedRows[i].idAssignTo
            })
        }

        if (selectedEmployee) {
            let inputDTO: reassignModel = {
                userNewId: selectedEmployee.id,
                casesAndUsers: reassignList,
            }

            try {
                await ATMService.reassignTicket(
                    inputDTO,
                    true,
                    true,
                    expectedCodes
                )

                callback();
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getTeamData ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getTeamData ex`);
            }
        }
    }

    const getActiveUsers = async () => {
        let userService = new UserService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest: Array<UserViewModel> =
                await userService.getUsersByFilter(
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes
                );

            if (responseRequest) {
                let newData: Array<employeeDisplayModel> = [];

                for (let i = 0; i < responseRequest.length; i++) {
                    newData.push({
                        name: responseRequest[i].name,
                        id: Number(responseRequest[i].id),
                    })
                }

                setEmployees(newData);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTeamData ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTeamData ex`);
        }
    }

    useEffect(() => {
        let string = "";

        for (let i = 0; i < selectedRows.length; i++) {
            string += (selectedRows[i].ticket == "case" ? caseLabel : (selectedRows[i].ticket == "event" ? eventLabel : selectedRows[i].ticket)) + " " + (selectedRows[i].id) + ", ";
        }
        setText(string);
    }, [caseLabel, eventLabel]);

    useEffect(() => {
        getActiveUsers();
        loadResources();
    }, []);

    const CheckboxStyle: React.FC<CheckboxStyleProps> = ({ employee }) => {
        const [profileStyle, setProfileStyle] = useState<ThemeCase>(
            Math.floor(Math.random() * 5) as ThemeCase
        );

        const handleCheckboxChange = () => {
            setSelectedEmployee(employee);
        };

        return (
            <div className="grid-checkbox-container">
                <TPCheckBox id={employee.id.toString()} checked={selectedEmployee?.id === employee.id} onChange={handleCheckboxChange} />
                <StyledUserProfileCompleted>
                    <UserProfile
                        completeName={employee.name}
                        customSize={{ font: "16px", size: "34px" }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                        <b className="user-name" style={{ whiteSpace: 'nowrap' }}>{employee.name}</b>
                        <p className="user-name">{employee.id}</p>
                    </div>
                </StyledUserProfileCompleted>
            </div>
        );
    };

    const filteredData = (filter: string, data: employeeDisplayModel[]) => {

        let newData: employeeDisplayModel[] = [];

        if (filter.length < 2) {
            newData = data.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage);
        } else {
            return data.filter((item) => {
                return item.name.toLowerCase().includes(filter.toLowerCase()) || item.id.toString().startsWith(filter);
            }).slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage);
        }

        return newData;
    }

    return (
        <>
            {/*{willReasingEvents ? (
                <Dialog
                open={true}
                sx={{ fontFamily: "Noto Sans" }}
                maxWidth="lg"
                aria-labelledby="upload-file-dialog"
            >
                <StyledDialogContent>
                        <div style={{ alignContent: "center", marginBottom: "30px"}}>
                            <TPPageSectionTitle style={{ fontSize: "18px", textAlign: "center", marginBottom: "30px"}}>{confirmationTitle}</TPPageSectionTitle>
                            <p style={{ textAlign: "center",  marginBottom: "0px"}}>{confirmationQuestion}</p>
                            <p style={{ textAlign: "center", marginBottom: "0px" }}>{confirmationText1}</p>
                            <p style={{ textAlign: "center", marginBottom: "0px" }}>{confirmationText2}</p>
                        </div>
                        <StyledActionButtons isErrorFile style={{ width: "50%", marginLeft: "25%"}}>
                            <TPButton
                                id="cancel-upload"
                                isDesignSystem
                                customType={ButtonCustomType.secondary}
                                onClick={() => {handleModalClose(false)}}
                                style={{ padding: "1px 18px" }}
                            >
                                {cancelButton}
                            </TPButton>
                            <TPButton
                                id="validate-file"
                                isDesignSystem
                                onClick={() => setWillReasingEvents(false)}
                                style={{ padding: "1px 18px" }}
                            >
                                {continueButton}
                            </TPButton>
                        </StyledActionButtons>
                    </StyledDialogContent>
            </Dialog>
            ) : (
                
            )}*/}
            <Dialog
                open={true}
                sx={{ fontFamily: "Noto Sans" }}
                maxWidth="lg"
                aria-labelledby="upload-file-dialog"
            >
                <StyledDialogContent>
                    <TPPageSectionTitle className="title-style">{reassignTitle}</TPPageSectionTitle>
                    <p className="text-style"><b>{ticketLabel + "s:"} </b> {text}{reassignText}</p>
                    <div className="table-header">
                        <b style={{ fontWeight: 500 }}>{selectLabel}</b>
                        <TPTextBox
                            onChange={(event: any) => setFilter(event.target.value)}
                            value={filter}
                            icon={TPIconTypes.search}
                            withIcon
                            iconClick={() => { }}
                            containerStyle={{ width: '50%' }}
                            placeholder={searchLabel}
                        />
                    </div>
                    <div className="form-grid2">
                        {filteredData(filter, employees).map((employee, index) => (
                            <CheckboxStyle key={index} employee={employee} />
                        ))}
                    </div>
                    <div style={{ alignItems: 'flex-end' }}>
                        <TablePager
                            allLabel=""
                            allResults={employees.length}
                            page={currentPage}
                            totalPages={Math.ceil(employees.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()) || item.id.toString().startsWith(filter)).length / itemsPerPage)}
                            resultsPerPage={filteredData(filter, employees).length}
                            selectedResults={selectedEmployee ? 1 : 0}
                            rowsPerPage={itemsPerPage}
                            ofLabel="of"
                            pageLabel="Page"
                            nextPageLabel="Next"
                            onPagerChange={(e) => { setCurrentPage(e); }}
                            onRowsPerPageChange={(e) => { setItemsPerPage(parseInt(e.target.value)); }}
                            customRowsPerPageOptions={[4, 8, 12]}
                        />
                    </div>

                    <StyledActionButtons isErrorFile style={{ width: "100%" }}>
                        <TPButton
                            id="IdButton"
                            customType={ButtonCustomType.tertiary}
                            onClick={() => handleModalClose(false)}
                            isDesignSystem
                            style={{ padding: "1px 18px" }}
                        >
                            {goBackLabel}
                        </TPButton>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                            <TPButton
                                id="cancel-upload"
                                isDesignSystem
                                customType={ButtonCustomType.secondary}
                                onClick={() => handleModalClose(false)}
                                style={{ padding: "1px 18px" }}
                            >
                                {cancelButton}
                            </TPButton>
                            <TPButton
                                id="validate-file"
                                isDesignSystem
                                onClick={() => { handleModalClose(false); reassingTickets() }}
                                style={{ padding: "1px 18px" }}
                            >
                                {saveLabel}
                            </TPButton>
                        </div>

                    </StyledActionButtons>
                </StyledDialogContent>
            </Dialog>
        </>
    )
}

export default ReassignModal;
