import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  WallboardEnum as e,
  TabTypeEnum,
  WallboardEvents,
  WallboardStateActionType,
  WallboardTeamViewProps,
} from "@/models/Wallboard/WallboardModel";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { GroupsService } from "@/services/GroupsService";
import { WallboardService } from "@/services/WallboardService";
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CockpitRemovableItem from "../SupervisorCockpit/utils/CockpitRemovableItem";
import WallboardListContainer from "./WallboardListContainer";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "flex-end",
  } as CSSProperties,
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    overflowY: "auto",
  } as CSSProperties,
  sectionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box",
    marginBottom: "20px",
  } as CSSProperties,
  sectionSelected: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    boxSizing: "border-box",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard Group view
 */
const WallboardGroupView = forwardRef(
  ({ id, style, children, s, dispatch }: WallboardTeamViewProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * group
     */
    const [group, setGroup] = useState("1");
    /**
     * groups selected
     */
    const [groupsSelected, setGroupsSelected] = useState<Array<TPKeyValue>>([]);
    const [groupsSelectedApply, setGroupsSelectedApply] = useState<
      Array<TPKeyValue>
    >([]);
    /**
     * groups
     */
    const [groups, setGroups] = useState<Array<TPKeyValue>>([]);
    /**
     * sorts
     */
    const sorts: Array<TPKeyValue> = [
      { key: "", value: s.m?.[e.SectionActionSelectSortByLabel] },
      { key: "0", value: s.m?.[e.SectionActionOptionTimeMoreLessLabel] },
      { key: "1", value: s.m?.[e.SectionActionOptionTimeLessMoreLabel] },
      { key: "2", value: s.m?.[e.SectionActionOptionNameAZLabel] },
      { key: "3", value: s.m?.[e.SectionActionOptionNameZALabel] },
    ];
    /**
     * sort by
     */
    const [sort, setSort] = useState("");
    /**
     *
     */
    const removeGroupRef = useRef<(valueToRemove: string) => void | null>(null);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {
            loadDataSources();
          },
        }) as WallboardEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadGroups();
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * load projects
     */
    function loadGroups(): void {
      const { getGroupsBySuperior } = new GroupsService();

      getGroupsBySuperior(TPGlobal.currentUserGuid, false, true, [200, 404])
        .then((data) => {
          setGroups([
            ...data.map((e) => ({
              key: `${e.groupId}`,
              value: `${e.user.firstName}${e.user.lastName ? ` ${e.user.lastName}` : ""}`,
            })),
          ]);
        })
        .catch((error) => {
          console.error(`Error ${e.nameGroup} loadGroups`, error);
        });
    }
    /**
     * load data sources
     */
    function loadDataSources(): void {
      setGroupsSelectedApply([...groupsSelected]);

      if (groupsSelected.length === 0) {
        showToast(s.m.MessageGroupMustBeSelected!, TPToastTypes.warning);
        return;
      }

      const { getGroups } = new WallboardService();

      dispatch({
        type: WallboardStateActionType.setLoading,
        payload: true,
      });
      getGroups(groupsSelected.map((e) => Number(e.key)))
        .then((data) => {
          dispatch({
            type: WallboardStateActionType.setData,
            payload: { type: TabTypeEnum.GroupView, data, sort },
          });
        })
        .catch((error) => {
          dispatch({
            type: WallboardStateActionType.setLoading,
            payload: false,
          });
          console.error(`Error ${e.nameGroup} loadDataSources`, error);
        });
    }
    /**
     * group
     */
    function onChangeGroupHandler(event: any): void {
      const value = event.target.value;

      if (groupsSelected.find(({ key }) => key === value)) {
        showToast(s.m.MessageGroupAlreadySelected!, TPToastTypes.warning);
        return;
      }

      const sel = groups.find(({ key }) => key === value)!;
      setGroupsSelected([...groupsSelected, sel]);

      setGroup("");
    }
    /**
     * on delete chip
     *
     * @param index index of chip
     */
    function onDeleteChipHandler(index: number) {
      const q = [...groupsSelected];
      q.splice(index, 1);
      setGroupsSelected(q);

      if (q.length === 0) {
        dispatch({
          type: WallboardStateActionType.setData,
          payload: { type: TabTypeEnum.GroupView, data: [] },
        });
        return;
      }
    }
    /**
     * sort
     */
    function onChangeSortHandler(event: any): void {
      const value = event.target.value;
      setSort(value);

      dispatch({
        type: WallboardStateActionType.setData,
        payload: { type: TabTypeEnum.GroupView, sort: value },
      });
    }
    /**
     *
     */
    function handleGroupsChange(values: TPKeyValue[]) {
      setGroupsSelected(values);
      setGroupsSelectedApply([]);
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={e.nameGroup}
        key={e.nameGroup}
        style={{
          ...styles.container,
          ...style,
        }}
      >
        <div
          id={`${e.nameGroup}SectionAction`}
          key={`${e.nameGroup}SectionAction`}
          style={styles.sectionAction}
        >
          <div style={{ ...styles.row, gap: "20px" }}>
            <SearchSelect
              id={`${e.nameGroup}SelectGroup`}
              key={`${e.nameGroup}SelectGroup`}
              width="200px"
              options={groups}
              optionSelected={groupsSelected}
              label={s.m.SectionFilterSelectGroupLabel}
              placeholder={s.m.SectionFilterSelectChoosePlaceholderLabel}
              handleMultiChange={handleGroupsChange}
              removeSelectedOptionRef={removeGroupRef}
              orientation="vertical"
              isMandatory
              isMulti
            />

            <TPButton
              id={`${e.nameGroup}ButtonApply`}
              key={`${e.nameGroup}ButtonApply`}
              onClick={() => loadDataSources()}
              style={{ padding: "0 15px" }}
              disabled={groupsSelected.length === 0}
              isDesignSystem
            >
              {s.m.SectionFilterButtonApplyLabel}
            </TPButton>
          </div>

          <div style={{ ...styles.row, gap: "20px" }}>
            <TPButton
              id={`${e.nameGroup}IconButtonRefresh`}
              key={`${e.nameGroup}IconButtonRefresh`}
              iconStyle={{ fontSize: "26px" }}
              type={TPButtonTypes.icon}
              icon={TPIconTypes.loop}
              tooltip={s.m?.[e.SectionActionTooltipRefreshLabel]}
              onClick={() => loadDataSources()}
            />

            <TPSelect
              id={`${e.nameGroup}SelectSortBy`}
              key={`${e.nameGroup}SelectSortBy`}
              dataSource={sorts}
              value={sort}
              onChange={onChangeSortHandler}
            />
          </div>
        </div>

        <div
          id={`${e.nameGroup}SectionSelectedGroups`}
          key={`${e.nameGroup}SectionSelectedGroups`}
          style={styles.sectionSelected}
        >
          {groupsSelected.map((s, index) => (
            <CockpitRemovableItem
              key={`${e.nameGroup}Chip${s.key}`}
              item={groups.find(({ key }) => key === s.key) as any}
              onRemoveItem={() => {
                onDeleteChipHandler(index);
                removeGroupRef.current?.(s.key);
              }}
            />
          ))}
        </div>

        <WallboardListContainer
          m={s.m}
          queuesSelected={groupsSelectedApply}
          data={s.groups}
          noDataMessage={`${s.m.TableGroupNoDataLabel}`}
        />

        {children}
      </div>
    );
  }
);

export default WallboardGroupView;
