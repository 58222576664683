export type RecordLocalizedModel = {
  languageId: string;
  localizedValue: string;
  order: number;
};
export const ComponentCanAddAdditionalDataConst: Array<string> = [
  "CAREDERETRY",
  "CAREDETA",
  "CARESEEMAIL",
  "CARESTCO",
  "CARERECUWF",
];

/**
 * state attribute value event
 */
export enum Event {
  loading = "loading",
  success = "success",
  error = "error",
}

/**
 * state attribute value constructor
 */
export interface Sac<T> {
  value?: T | null;
  event?: Event;
  error?: any;
}

/**
 * state attribute value constructor
 */
export class Sa<T> {
  value?: T | null;
  event?: Event;
  error?: any;

  constructor(s?: Sac<T> | null) {
    if (s?.value) this.value = s.value;
    if (s?.event) this.event = s.event;
    if (s?.error) this.error = s.error;
  }

  static loading<T>(value?: T | null) {
    return new Sa<T>({value, event: Event.loading})
  }

  setValue(value: T) {
    this.value = value;
    this.event = Event.success;
    this.error = undefined;

    return this
  }

  setError(error: any) {
    this.value = undefined;
    this.event = Event.error;
    this.error = error;

    return this
  }
}

export type Action<S> = {
  type: S;
  payload?: any;
};
