import { forwardRef, useCallback, useEffect, useState, useMemo } from "react";

import TPCollapsableSection from "@/components/bootstrap/components/collapsable/TPCollapsableSection";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  Profile,
  Restriction,
} from "@/models/ProfileRestrictions/ProfileRestrictionsModels";
import { TPI18N } from "@/services/I18nService";
import { ProfileRestrictionsServices } from "@/services/ProfileRestrictionsServices";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import DynamicTable, {
  HeaderAlignment,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";

const ProfileRestrictions = forwardRef(() => {
  const RESOURCE_SET: string = "ProfileRestrictionsComponent";
  const COMPONENT_NAME: string = "ProfileRestrictions.tsx";

  const initialTexts = {
    pageTitle: "PageTitle",
    helpTitle: "PageSubTitle",
    helpDescription: "PageSubTitleDescription",
    greenLabel: "GreenLabel",
    redLabel: "RedLabel",
    yellowLabel: "YellowLabel",
    greenDescription: "GreenDescription",
    redDescription: "RedDescription",
    yellowDescription: "YellowDescription",
    profileRestrictionsLabel: "ProfileRestrictionsLabel",
    organizationRelationsLabel: "OrganizationRelationsLabel",
    organizationRelationsEmpty: "OrganizationRelationsEmpty",
    activeLabel: "ActiveLabel",
    inactiveLabel: "InactiveLabel",
    allLabel: "AllLabel",
    redTooltip: "RedTooltip",
    greenTooltip: "GreenTooltip",
  };

  const [isLoading, setIsLoading] = useState(true);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profileRestrictions, setProfileRestrictions] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<Restriction[]>([]);
  const [organizationRelations, setOrganizationRelations] = useState<
    TPKeyValue[]
  >([{ key: "", value: "--" }]);
  const [organizationRelation, setOrganizationRelation] = useState("");
  const [profileRestriction, setProfileRestriction] = useState("1");
  const [profileTexts, setProfileTexts] = useState(initialTexts);

  const loadTranslations = useCallback(async () => {
    try {
      const translatedTexts = Object.fromEntries(
        await Promise.all(
          Object.entries(initialTexts).map(async ([key, translationKey]) => {
            try {
              const translation = await TPI18N.GetText(
                RESOURCE_SET,
                translationKey
              );
              return [key, translation || translationKey];
            } catch (error) {
              TPLog.Log(
                `Error ${COMPONENT_NAME} getTranslation for key: ${translationKey}`,
                TPLogType.ERROR,
                error
              );
              return [key, translationKey];
            }
          })
        )
      );
      setProfileTexts(translatedTexts);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} loadTranslations`,
        TPLogType.ERROR,
        error
      );
    }
  }, []);

  const loadProfiles = useCallback(async () => {
    try {
      const profileRestrictionsServices = new ProfileRestrictionsServices();
      const response = await profileRestrictionsServices.getProfiles(
        Number(profileRestriction),
        false,
        true,
        [200, 404]
      );
      setProfiles(response);
    } catch (error) {
      TPLog.Log(`Error ${COMPONENT_NAME} loadProfiles`, TPLogType.ERROR, error);
    }
  }, [profileRestriction]);

  const loadOrganizationsRelations = useCallback(async () => {
    const serviceOrganizationRelationClient =
      new OrganizationsRelationsService();
    try {
      const responseRequestOrgRela =
        await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
          TPActiveOptions.ALL.toString(),
          false,
          true,
          [200]
        );
      const newOrganizationsRelationsListState = responseRequestOrgRela.map(
        (org: any) => ({
          key: org.id,
          value: org.localizedDescription,
        })
      );
      setOrganizationRelations([
        { key: "", value: "--" },
        ...newOrganizationsRelationsListState,
      ]);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} getOrganizationsRelationsList`,
        TPLogType.ERROR,
        error
      );
    }
  }, []);

  const loadRestrictions = useCallback(async () => {
    try {
      const profileRestrictionsServices = new ProfileRestrictionsServices();
      const response = await profileRestrictionsServices.getRestrictions(
        false,
        true,
        [200, 404]
      );
      setRestrictions(response);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} loadRestrictions`,
        TPLogType.ERROR,
        error
      );
    }
  }, []);

  const loadProfileRestrictions = useCallback(async () => {
    try {
      const profileRestrictionsServices = new ProfileRestrictionsServices();
      const response = await profileRestrictionsServices.getProfileRestrictions(
        organizationRelation,
        false,
        false,
        [200, 404]
      );

      setProfileRestrictions(response);
    } catch (error) {
      TPLog.Log(
        `Error ${COMPONENT_NAME} loadProfileRestrictions`,
        TPLogType.ERROR,
        error
      );
    }
  }, [organizationRelation]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        loadTranslations(),
        loadProfiles(),
        loadRestrictions(),
        loadOrganizationsRelations(),
      ]);
    } catch (error) {
      TPLog.Log(`Error ${COMPONENT_NAME} loadData`, TPLogType.ERROR, error);
    } finally {
      setIsLoading(false);
    }
  }, [
    loadTranslations,
    loadProfiles,
    loadRestrictions,
    loadOrganizationsRelations,
  ]);

  const handleToggleRestriction = useCallback(
    async (profileId: string, restrictionId: string) => {
      try {
        if (!organizationRelation) {
          showToast(
            profileTexts.organizationRelationsEmpty,
            TPToastTypes.error
          );
          return;
        }

        setIsLoading(true);
        const profileRestrictionsServices = new ProfileRestrictionsServices();
        await profileRestrictionsServices.toggleRestriction(
          profileId,
          restrictionId,
          organizationRelation,
          false,
          true,
          [200, 404]
        );

        await loadProfileRestrictions();
      } catch (error) {
        TPLog.Log(
          `Error ${COMPONENT_NAME} handleToggleRestriction`,
          TPLogType.ERROR,
          error
        );
      } finally {
        setIsLoading(false);
      }
    },
    [organizationRelation, loadData]
  );

  const handleReset = useCallback(() => {
    setIsLoading(true);
    setOrganizationRelation("");
    setProfileRestriction("1");
    loadData();
    setIsLoading(false);
  }, [loadData]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadProfiles();
  }, [profileRestriction, loadProfiles]);

  useEffect(() => {
    loadProfileRestrictions();
  }, [organizationRelation, loadProfileRestrictions]);

  const renderDescriptions = useMemo(
    () => (
      <div style={{ marginTop: "10px" }}>
        {[
          {
            iconType: TPIconTypes.GreenCircle,
            color: "green",
            label: profileTexts.greenLabel,
            description: profileTexts.greenDescription,
          },
          {
            iconType: TPIconTypes.RedCircle,
            color: "red",
            label: profileTexts.redLabel,
            description: profileTexts.redDescription,
          },
        ].map(({ iconType, color, label, description }, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "8px",
              marginTop: "10px",
            }}
          >
            <TPIcon iconType={iconType} />
            <strong style={{ marginTop: "2px", lineHeight: "1.2" }}>
              {label}:
            </strong>
            <p style={{ margin: 0, lineHeight: "1.5" }}>{description}</p>
          </div>
        ))}
      </div>
    ),
    [profileTexts]
  );

  const profileRestrictionsData = useMemo(() => {
    const profileRestrictionsMap = new Map<string, Set<string>>();
    profileRestrictions?.forEach(({ profileId, restriction }) => {
      if (!profileRestrictionsMap.has(profileId)) {
        profileRestrictionsMap.set(profileId, new Set());
      }
      profileRestrictionsMap.get(profileId)?.add(restriction);
    });

    return profiles?.map((profile) => {
      const profileData: Record<string, string> = {
        profile: profile.localizedDescription || profile.description,
      };
      restrictions?.forEach((restriction) => {
        const restrictionKey =
          restriction.localizedDescription || restriction.description;
        const isRestricted = profileRestrictionsMap
          .get(profile.id)
          ?.has(restriction.id);
        profileData[restrictionKey] = isRestricted ? "red" : "green";
      });
      return profileData;
    });
  }, [profiles, restrictions, profileRestrictions]);

  const columnStyles = useMemo(() => {
    const getProfileId = (profileName: string) =>
      profiles.find(
        ({ localizedDescription, description }) =>
          localizedDescription === profileName || description === profileName
      )?.id;

    const getRestrictionId = (restrictionKey: string) =>
      restrictions.find(
        ({ localizedDescription, description }) =>
          localizedDescription === restrictionKey ||
          description === restrictionKey
      )?.id;

    return Object.keys(profileRestrictionsData[0] || {})
      .filter((key) => key !== "profile")
      .reduce(
        (acc, restrictionKey) => {
          acc[restrictionKey] = ({ value, item }) => {
            const profileId = getProfileId(item.profile);
            const restrictionId = getRestrictionId(restrictionKey);
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  height: "100%",
                }}
              >
                <TPIcon
                  iconType={
                    value === "red"
                      ? TPIconTypes.RedCircle
                      : TPIconTypes.GreenCircle
                  }
                  onClick={() =>
                    handleToggleRestriction(
                      profileId || "",
                      restrictionId || ""
                    )
                  }
                  tooltip={
                    value === "red"
                      ? profileTexts.redTooltip
                      : profileTexts.greenTooltip
                  }
                  isTooltip
                />
              </div>
            );
          };
          return acc;
        },
        {} as Record<
          string,
          (props: {
            value: string;
            item: Record<string, string>;
          }) => JSX.Element
        >
      );
  }, [
    profileRestrictionsData,
    profiles,
    restrictions,
    handleToggleRestriction,
  ]);

  const headerAlignment = useMemo(() => {
    return Object.keys(profileRestrictionsData[0] || {})
      .filter((key) => key !== "profile")
      .reduce(
        (acc, key) => {
          acc[key as keyof typeof acc] = "center";
          return acc;
        },
        {} as HeaderAlignment<Record<string, string>>
      );
  }, [profileRestrictionsData]);

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoading}>
          <TPPageTitle>{profileTexts.pageTitle}</TPPageTitle>
          <TPCollapsableSection
            title={
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <TPIcon
                  iconType={TPIconTypes.CircleInfoFill}
                  style={{ color: "purple", fontSize: "24px" }}
                />
                <p style={{ margin: 0 }}>{profileTexts.helpTitle}</p>
              </div>
            }
            description={
              <div>
                {profileTexts.helpDescription}
                {renderDescriptions}
              </div>
            }
          />
          <hr />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "70%",
              margin: "0",
              gap: "10px",
            }}
          >
            <div style={{ flex: 1 }}>
              <TPSelect
                id="profileRestrictions"
                dataSource={[
                  { key: "2", value: profileTexts.allLabel },
                  { key: "1", value: profileTexts.activeLabel },
                  { key: "0", value: profileTexts.inactiveLabel },
                ]}
                onChange={(e: any) => setProfileRestriction(e.target.value)}
                value={profileRestriction}
                labelText={profileTexts.profileRestrictionsLabel}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TPSelect
                id="organizationRelations"
                dataSource={organizationRelations}
                onChange={(e: any) => setOrganizationRelation(e.target.value)}
                value={organizationRelation}
                labelText={profileTexts.organizationRelationsLabel}
                isMandatory
              />
            </div>
          </div>
          <br />
          <DynamicTable
            data={profileRestrictionsData}
            columnStyles={columnStyles}
            onIconClicked={handleReset}
            headerAlignment={headerAlignment}
            withPreferences
            exportNameFile={profileTexts.pageTitle}
          />
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
});

export default ProfileRestrictions;
