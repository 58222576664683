import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { GlobalPermissionsViewModel } from "@/models/Permission/GlobalPermissionsViewModel";

export class PermissionService {
  serviceFileName: string = "PermissionService.ts";

  public async getGlobalPermissions(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GlobalPermissionsViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/permission/get-globals";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGlobalPermissions ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGlobalPermissions`);
      throw new Error(`Error ${this.serviceFileName} getGlobalPermissions`);
    }
  }
}
