import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement, FC } from "react";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { useState } from "react";

export type TPModalStationIdState = {
  isShown: boolean;
  callBackData: any;
};

type TPModalStationIdProps = {
  title: string;
  yesLabel: string;
  noLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: Function;
};

const TPModalStationId: FC<TPModalStationIdProps> = ({
  title,
  yesLabel,
  noLabel,
  isShown,
  callBackData,
  callBackAnswer,
}): ReactElement => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }
  //const generalContext: any = React.useContext(TPGeneralContext);
  //const { isLeftMenuCollapsed } = generalContext;
  //TODO: borrar estas varaibles si no se va na usar, las del contexto
  const [stationId, setStationId] = useState("");

  const handleInputStationIdChanged = (element: any) => {
    setStationId(element.target.value);
  };

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => callBackAnswer(false, stationId)}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <TPTextBox
                id="IdTextBox"
                onChange={handleInputStationIdChanged}
                value={stationId}
                labelText={title}
              />
            </div>
            <div className="modal-footer">
              <TPButton
                disabled={!stationId}
                type={TPButtonTypes.primary}
                onClick={() => callBackAnswer(false, stationId)}
              >
                {yesLabel}
              </TPButton>
              <TPButton
                type={TPButtonTypes.link}
                onClick={() => callBackAnswer(false, stationId)}
              >
                {noLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>
      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalStationId;
