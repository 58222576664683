import styled from "styled-components";

const chipTypeColor = {
  success: "#B1F2D7",
  warning: "#FFE4B3",
  danger: "#FFD7DD",
};

const shapeTypeColor = {
  success: "#00826D",
  warning: "#FFA500",
  danger: "#FF0000",
};

export const StyledTableChip = styled.div<{
  justify: string;
  activeColor?: string;
  inactiveColor?: string;
  underline: boolean;
  notBoolean: boolean;
  beforeShape: boolean;
  value: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};

  p {
    position: relative;
    background-color: ${(props) => {
      if (props.activeColor && props.inactiveColor) {
        return props.value ? props.activeColor : props.inactiveColor;
      }

      return chipTypeColor[
        props.value ? "success" : props.notBoolean ? "warning" : "danger"
      ];
    }};
    font-size: 14px;
    width: fit-content;
    padding: 2px ${(props) => (props.underline ? "16px" : "10px")};
    border-radius: 20px;
    margin: 0;
    font-weight: 500;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    cursor: ${(props) => (props.underline ? "pointer" : "initial")};

    ${(props) =>
      props.underline &&
      `
        user-select: none;
                
        &:hover {
            opacity: 0.8;
        }
      `}

    ${(props) =>
      props.beforeShape &&
      `
        padding-left: 30px;
        padding-right: 15px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: ${
              shapeTypeColor[
                props.value
                  ? "success"
                  : props.notBoolean
                    ? "warning"
                    : "danger"
              ]
            };
            border-radius: 50%;
            margin-right: 5px;
            left: 12px;
            bottom: 6px;
        }
      `}
  }
`;
