import { FC } from "react"

interface CircleProperties {
  size?: string | number;
  backgroundColor?: string;
}

const Circle: FC<CircleProperties> = function ({ backgroundColor, size = '12px' }) {
  return (
    <div
      style={{
        borderRadius: '50%',
        aspectRatio: 1,
        height: size,
        width: size,
        backgroundColor: backgroundColor
      }}
    />
  )
}

export default Circle;