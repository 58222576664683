import { useEffect, useState } from "react";
import ImagesAdminFile from "./ImagesAdminFile";
import {
    ContainerFilesGridDivStyle,
    ContainerFilesListDivStyle,
    ContainerViewDetail,
    TextPStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import ImagesAdminDetail from "./ImagesAdminDetail";
import ImagesAdminSlider from "./ImagesAdminSlider";
import { OperationMode } from "./useStatusImagesAdmin";
import UploadImage from "./imageAdminUploadImage";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

interface ImagesAdminInterface {   
    ChangeImageSelection?: Function;
    mode?: string;
    startFolder?: string;
    onSelect: (selectedImage: RecursiveRoutes) => void; 
}
type ContainerFolderArgs = {
    ChangeImageSelection?: Function;
    mode?: string;
    startFolder?: string;
    onSelect: (selectedImage: RecursiveRoutes) => void;  // Add this callback prop
};

const ImagesAdminContainerFiles = ({ ChangeImageSelection,mode, startFolder,onSelect }: ContainerFolderArgs) => {
    const componentFileName: string = "ImagesAdminContainerFiles.tsx";

    const [listFolderTemp, selectedFolderTemp] = useState<Array<RecursiveRoutes>>(
        Array<RecursiveRoutes>,
    );

    const value = useAppConctextImageAdmin();
    const {
        status,
        listFilesModel,
        viewListAndGrid,
        selectedItem,
        operationMode,
        isLoading,
        modeSelect,
        containerFolderArgs,
        setContainerFolderArgs,
    } = value;

    useEffect(() => {
        selectedFolderTemp(listFilesModel);
    }, [listFilesModel]);

    const handleActivateMenu = (event: any) => {
        event.preventDefault();
    };
    const handleImageSelect = (selectedImage: RecursiveRoutes) => {
        onSelect(selectedImage);
    };

    useEffect(() => {
        let temp = { ...containerFolderArgs };
        temp.mode = mode;
        temp.startFolder = startFolder;
        setContainerFolderArgs(temp)
    }, [mode, startFolder]);

    if (!viewListAndGrid) {
        return (
            <>
                {mode !== "view" ? (
                    <>
                        <UploadImage Type="jpg" Size={150} />
                        {selectedItem && operationMode === OperationMode.SelectFile ? (
                            <ImagesAdminSlider ChangeImageSelection={ChangeImageSelection} files={listFilesModel} mode={mode} onSelectImage={handleImageSelect} />
                        ):null}
                        <ContainerFilesGridDivStyle>
                            {listFilesModel.map(file => (
                                <ImagesAdminFile file={file} key={file.id} mode={mode} />
                            ))}
                        </ContainerFilesGridDivStyle>
                    </>
                ) : (
                    <>
                        {selectedItem && operationMode === OperationMode.SelectFile ? (
                                <ImagesAdminSlider ChangeImageSelection={ChangeImageSelection} files={listFilesModel} mode={mode} onSelectImage={handleImageSelect} />
                        ):null} 
                        <ContainerFilesGridDivStyle>
                            {listFilesModel.filter(file => file.isActive).map(file => (
                                <ImagesAdminFile file={file} key={file.id} mode={mode}/>
                            ))}
                        </ContainerFilesGridDivStyle>
                    </>
                )}
            </>
        );
    } else {
        return (
            <>
                <ContainerViewDetail>
                    <TextPStyle>
                        <b>Image</b>
                    </TextPStyle>
                    <TextPStyle>
                        <b>Name</b>
                    </TextPStyle>
                    <TextPStyle>
                        <b>Size</b>
                    </TextPStyle>
                    <TextPStyle>
                        <b>Date</b>
                    </TextPStyle>
                </ContainerViewDetail>

                {(mode != "view") &&
                    <UploadImage Type="jpg" Size={150}  ></UploadImage>
                }
                 {selectedItem && operationMode === OperationMode.SelectFile ?
                    <ImagesAdminSlider ChangeImageSelection={ChangeImageSelection} files={listFilesModel} mode={mode} onSelectImage={handleImageSelect} />
                    : null
                }
                <ContainerFilesListDivStyle>
                    {listFilesModel
                        .filter(file => (mode !== "view" || file.isActive))  // Filter files based on mode
                        .map(file => (
                            <ImagesAdminDetail file={file} key={file.id} />
                        ))
                    }
                </ContainerFilesListDivStyle>
            </>
        );
    }
};

export default ImagesAdminContainerFiles;
