import allThemes from "@/assets/styles/theme";
import { copyToClipboard } from "@/helpers/ClipboardManager";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { Icon } from "@iconify/react";
import { FC, useEffect, useState } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPMailKeypoints from "./TPMailKeyPoints";

const resourceSet = "TPGenAI";

interface Properties {
  caseId: number;
}

const TPGenAIOutboundMenu: FC<Properties> = function ({ caseId }) {
  const [emailOutput, setEmailOutput] = useState<string>("");
  const [mainMailKeypointsLabel, setMainMailKeypointsLabel] = useState<string>("...");
  const [keyPointsForNewEmailLabel, setKeyPointsForNewEmailLabel] = useState<string>("...");
  const [outgoingMailNewCompositionLabel, setOutgoingMailNewCompositionLabel] = useState<string>("...");
  const [copyClicked, setCopyClicked] = useState(false);

  const loadLabels = async function () {
    setMainMailKeypointsLabel(await TPI18N.GetText(resourceSet, "MainMailKeypointsLabel"));
    setKeyPointsForNewEmailLabel(await TPI18N.GetText(resourceSet, "KeyPointsForNewEmailLabel"));
    setOutgoingMailNewCompositionLabel(await TPI18N.GetText(resourceSet, "OutgoingMailNewCompositionLabel"));
  };

  useEffect(() => {
    loadLabels();
  });

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCopyClicked(false);
    }, 5000)
    return () => clearTimeout(intervalId);
  }, [copyClicked])

  return (
    <div id="tpgenai-outbound-menu" className="outbound-mail-main-container">
      <div className="outbound-mail-top-container">
        <div style={{ width: "50%", height: "400px", overflow: "auto" }}>
          <div className="keypoints-title">
            <strong>{mainMailKeypointsLabel}</strong>
            <Icon icon="gravity-ui:list-ul" />
          </div>
          <TPMailKeypoints caseId={caseId} type="inbound" />
        </div>
        <div style={{ width: "50%", height: "400px", overflow: "auto" }}>
          <div className="keypoints-title">
            <strong>{keyPointsForNewEmailLabel}</strong>
            <Icon icon="gravity-ui:list-ul" />
          </div>
          <TPMailKeypoints
            caseId={caseId}
            type="outbound"
            dataCallback={setEmailOutput}
          />
        </div>
      </div>
      <div className="outbound-mail-bottom-container">
        <div className="composed-email-title-container">
          <div className="keypoints-title">
            <strong>{outgoingMailNewCompositionLabel}</strong>
            <Icon icon="material-symbols:outgoing-mail" />
          </div>
          <div
            id ='outbound-mail-copy-button-container'
            style={{
              display:'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <button
              type="button"
              className="copy-button"
              onClick={() => {
                copyToClipboard(emailOutput);
                setCopyClicked(true);
              }}
            >
              <Icon icon="iconamoon:copy-bold" />
            </button>
            {copyClicked &&
            <TPIcon iconType={TPIconTypes.checkCircle} style={{color:'green'}} />}
          </div>
        </div>
        <div className="output-email">
          <div
            style={{
              borderLeft: `5px solid ${allThemes.base.primary}`,
              padding: "4px",
              marginTop: "4px",
              width: "90%",
              overflow: "auto",
              whiteSpace: 'pre-wrap'
            }}
            dangerouslySetInnerHTML={{ __html: emailOutput }}
          />
        </div>
      </div>
    </div>
  );
};

export default TPGenAIOutboundMenu;
