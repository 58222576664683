import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";

import { QuickClassifierShortcutsInputDTO } from "@/models/QuickClassifierShortcuts/QuickClassifierShortcutsInputDTO";
import { QuickClassifierShortcutsModel } from "@/models/QuickClassifierShortcuts/QuickClassifiershortcutsModel";

export class QuickClassifierShortcutService {
  serviceFileName: string = "QuickClassifierShortcutService.ts";

  public async insertQuickClassifierShortcut(
    inputDTO: QuickClassifierShortcutsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/web-classifier-user-hotkey";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertQuickClassifierShortcut ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} insertQuickClassifierShortcut`,
      );
      throw new Error(
        `Error ${this.serviceFileName} insertQuickClassifierShortcut`,
      );
    }
  }

  /**
   * Get all shortcuts by user.
   *
   * @param guidUser
   * @param showPositiveMessage
   * @param showNegativeMessage
   * @param expectedCodes
   * @returns Promise<Array<QuickClassifierShortcutsModel>>>
   */
  public async getQuickClassifierShortcutsByGuidUser(
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<QuickClassifierShortcutsModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/web-classifier-user-hotkey/user/${guidUser}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser`,
      );
    }
  }

  /**
   * Get all shortcuts by user.
   *
   * @param webserviceClassifierUserId Id classifier.
   * @param guidUser Id user guid.
   * @param showPositiveMessage
   * @param showNegativeMessage
   * @param expectedCodes
   * @returns Promise<Array<QuickClassifierShortcutsModel>>
   */
  public async getQuickClassifierShortcutsByGuidUserAndClassifier(
    webserviceClassifierUserId: string,
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<QuickClassifierShortcutsModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/web-classifier-user-hotkey/${webserviceClassifierUserId}/user/${guidUser}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getQuickClassifierShortcutsByGuidUser`,
      );
    }
  }

  public async getQuickClassifierShortcutHotkeyById(
    baseLevelId: string,
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<QuickClassifierShortcutsModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/web-classifier-user-hotkey/idBale/${baseLevelId}/user/${guidUser}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`,
      );
    }
  }

  public async getQuickClassifierShortcutHotkeyNewcaseById(
    baseLevelId: string,
    guidUser: string,
    hotkey: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<QuickClassifierShortcutsModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/web-classifier-user-hotkey/user/${guidUser}/idBale/${baseLevelId}/hotkey/${hotkey}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getQuickClassifiersByBaseLevel`,
      );
    }
  }

  /**
   * Delete all shortcuts by user.
   *
   * @param webserviceClassifierUserId Id classifier.
   * @param guidUser  Id user guid.
   * @param showPositiveMessage
   * @param showNegativeMessage
   * @param expectedCodes
   * @returns 200
   */
  public async deleteQuickClassifierShortcut(
    webserviceClassifierUserId: string,
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/web-classifier-user-hotkey/${webserviceClassifierUserId}/user/${guidUser}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteQuickClassifierById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteQuickClassifierById`);
      throw new Error(
        `Error ${this.serviceFileName} deleteQuickClassifierById`,
      );
    }
  }

  public async updateQuickClassifierShortcut(
    inputDTO: QuickClassifierShortcutsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/web-classifier-user-hotkey";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut`,
      );
    }
  }

  public async updateQuickClassifierShortcuthotkey(
    inputDTO: QuickClassifierShortcutsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/web-classifier-user-hotkey";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateQuickClassifierShortcut`,
      );
    }
  }
}
