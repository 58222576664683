const NewCustomerIcon = ({ ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M10 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 19.11 0.89 20 2 20H14C15.11 20 16 19.11 16 18V6L10 0ZM14 18H2V2H9V7H14V18ZM9 11C9 12.1 8.1 13 7 13C5.9 13 5 12.1 5 11C5 9.9 5.9 9 7 9C8.1 9 9 9.9 9 11ZM11 16V17H3V16C3 14.67 5.67 14 7 14C8.33 14 11 14.67 11 16Z"
                    fill="#2E2E2E"
                    />
                </svg>               
        `,
      }}
    ></div>
  );
};

export default NewCustomerIcon;
