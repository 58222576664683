import { forwardRef, ReactNode } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";

interface Props {
    id: string;
    children?: ReactNode;
    isPopUp?: any;
    isVisible?: boolean;
    icons: Array<TPIconTypes>;
    openImageModal?: () => void;
    onClickCustomIcon?: (nameIco:string) => void;
}
export type Ref = HTMLDivElement;

export const CustomToolbar = forwardRef<Ref, Props>((props, ref) => (
    <div ref={ref} className="" id={props.id}>
        {/* {props.children} */}
        <select className="ql-header ql-picker" />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <select className="ql-align" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        <select className="ql-color" />
        <select className="ql-background" />
        <button className="ql-clean" />
        <button className="ql-help" />
        {props.icons != undefined &&
            props.icons.map((icon, index) => (
                <button
                    key={index}
                    className="ql-handleImageClick p-0"
                    style={{ justifyItems:"center"} }                    
                >
                    <TPIcon iconType={icon} style={{ with: "20px" }} onClick={() => { if (props?.onClickCustomIcon) props?.onClickCustomIcon(icon.toString()) }} />
                </button>
               
            ))
        }
      
        {props?.isVisible && (
            <>
                {/* <button className="ql-handlePopupClick p-0">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-paperclip"
              viewBox="0 0 16 16"
            >
              <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
            </svg>
          </span>
        </button> */}
                <button
                    className="ql-handleImageClick p-0"
                    onClick={props?.openImageModal}
                >
                    <div>📷</div>
                </button>
                <button className="ql-handlePopupClick p-0" onClick={props?.isPopUp}>
                    [tag]
                </button>
            </>
        )}
        {/* {!isInternalReportNotForSending && (
      <button className="ql-handlePopupClick p-0" onClick={handleModalPopup}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-paperclip"
            viewBox="0 0 16 16"
          >
            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
          </svg>
        </span>
      </button>
    )} */}
    </div>
));
