import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { Typography } from "@mui/material";
import { ReactElement } from "react";
import {
  ButtonCustomType,
  StyledTPButton,
  TPButtonStyled,
} from "./tpButtonStyles";

interface TPButtonInterface {
  onClick: Function;
  className?: string;
  type?: TPButtonTypes;
  children?: any;
  text?: string | JSX.Element;
  icon?: TPIconTypes;
  dataBsToggle?: boolean;
  tooltip?: string;
  disabled?: boolean;
  style?: any;
  iconStyle?: any;
  iconAtRight?: boolean;
  id?: string;
  customFontWeight?: string;
  isDesignSystem?: boolean;
  customType?: ButtonCustomType;
  withIcon?: TPIconTypes;
  orientationIcon?: "left" | "right";
}

const TPButton = ({
  onClick,
  children,
  className = "",
  type = TPButtonTypes.primary,
  icon = TPIconTypes.default,
  text = "",
  dataBsToggle = false,
  tooltip = "",
  disabled = false,
  style,
  iconStyle,
  iconAtRight = false,
  customFontWeight,
  isDesignSystem = false,
  customType = ButtonCustomType.primary,
  orientationIcon = "right",
  withIcon,
  id,
}: TPButtonInterface): ReactElement => {
  const handleClick = (e: any) => {
    onClick(e);
  };

  const buttonId = `${id ? `${id}-` : ""}btn`;

  return (
    <>
      {!isDesignSystem ? (
        <TPButtonStyled
          id={buttonId}
          name={id}
          disabled={disabled}
          type={"button"}
          onClick={handleClick}
          className={`btn ${type}  ${className}`}
          data-bs-toggle={dataBsToggle ? "dropdown" : null}
          title={tooltip}
          style={style}
        >
          {type === TPButtonTypes.icon ? (
            !iconAtRight ? (
              <>
                <TPIcon iconType={icon} style={iconStyle} />
                <span>{text}</span>
              </>
            ) : (
              <>
                <span>{text}</span>
                <TPIcon iconType={icon} style={iconStyle} />
              </>
            )
          ) : (
            children
          )}
        </TPButtonStyled>
      ) : (
        <StyledTPButton
          id={buttonId}
          type="button"
          className={`cim-button ${customType}`}
          onClick={handleClick}
          style={style}
          customType={customType}
          fontWeight={customFontWeight}
          orientationIcon={orientationIcon}
          disabled={disabled}
        >
          <Typography>{children}</Typography>
          {withIcon && <TPIcon iconType={withIcon} />}
        </StyledTPButton>
      )}
    </>
  );
};

export default TPButton;
