import { FC, useEffect, useState } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { ScriptGeneralInfoTab } from "./Tabs/ScriptGeneralInfo";
import { ScriptNextStepUpdateModel, ScriptsMode, ScriptsTab, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ScriptStepsTab } from "./Tabs/ScriptSteps";
import { StepManagement } from "./StepManagement";
import { ScriptAdditionalDataDefinition } from "./AdditionalDataDefinition";
import { ScriptPreviewTab } from "./Tabs/ScriptPreview";
import { ScriptDiagramTab } from "./Tabs/ScriptDiagram";
import TPGlobal from "@/helpers/TPGlobal";
import { areEqual } from "@/helpers/ValidateChanges";
import { useDispatch } from "react-redux";
import { ScriptsManagementSlice, ScriptsManagementSliceModel } from "../ScriptsManagementSlice";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { TPModalAlert } from "@/components/TPModalAlert/TPModalAlert";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { useScriptsLabels } from "../Assets/labelling";

interface ScriptManagementProperties {
  mode: ScriptsMode;
  verticalTabCallback: Function;
  updateCallback: Function;
  scriptId: string;
  scriptName?: string;
}

enum ScriptManagementMode {
  normal,
  step,
  additional_data
}

const blankStepForm: ScriptStep[] = [];

export const ScriptManagement: FC<ScriptManagementProperties> = function ({
  updateCallback,
  verticalTabCallback,
  mode,
  scriptId,
  scriptName = ""
}) {
  const {
    unsavedTabIds
  } = useSelector((store: StoreModel) => store[ScriptsManagementSlice.name]) as ScriptsManagementSliceModel;
  const dispatch = useDispatch();
  const { labels } = useScriptsLabels();
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState<ScriptsTab>(ScriptsTab.general_info);
  const [alertVisible, setAlertVisible] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const [initialSteps, setInitialSteps] = useState<ScriptStep[]>(structuredClone(blankStepForm));
  const [steps, setSteps] = useState<ScriptStep[]>(structuredClone(blankStepForm));
  const [chosenStep, setChosenStep] = useState<ScriptStep | null>(null);
  const [editingStep, setEditingStep] = useState(false);

  const [nextStepsMap, setNextStepsMap] = useState<ScriptNextStepUpdateModel[]>([]);

  const [chosenAddDataId, setChosenAddDataId] = useState<string | number | null>(null);

  const saveScript = async function () {
    setIsLoading(true);
    if (nextStepsMap.length == 0) return;
    ScriptsService.updateNextSteps(nextStepsMap, true, true, [200])
      .catch(err => console.error(err))
      .finally(() => {
        setNextStepsMap([]);
        setIsLoading(false);
      });
  }

  const getSteps = async function () {
    setIsLoading(true);
    ScriptsService.getSteps(scriptId, false, false, [200, 404])
      .then(response => {
        if (response) {
          const fixedSteps = [...response].sort((stepA, stepB) => {
            if (stepA.order < stepB.order) return -1;
            if (stepA.order > stepB.order) return 1;
            return 0;
          })
          setSteps([
            ...structuredClone(blankStepForm),
            ...fixedSteps
          ])
          setInitialSteps([
            ...structuredClone(blankStepForm),
            ...fixedSteps
          ])
        }
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }

  const changeStepNextStep = async function (nextStepId: string, stepId: string) {
    if (!stepId) return;
    setSteps([
      ...steps.map(s => s.id == stepId ?
        {
          ...s,
          defaultNextStepId: nextStepId
        } as ScriptStep
        :
        s
      )
    ]);
    if (!nextStepsMap.find(s => s.scriptStepId == stepId)) {
      setNextStepsMap([
        ...nextStepsMap,
        {
          defaultNextStep: Number(nextStepId),
          scriptStepId: stepId
        }
      ])
    }
  }

  const deleteStep = async function (stepId: string) {
    setIsLoading(true);
    ScriptsService.deleteStep(stepId, true, true, [200, 404])
    .then(() => getSteps())
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      })
  }

  const deleteAdditionalData = async function (addDataId: string | number) {
    setIsLoading(true);
    ScriptsService.deleteStepAdditionalData(addDataId, true, true, [200])
      .then(() => getSteps())
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      });
  }

  const checkLogic = async function () {
    setIsLoading(true);
    ScriptsService.checkLogic(scriptId, true, true, [200])
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getSteps();
  }, [])

  useEffect(() => {
    setChangesMade(!areEqual(initialSteps, steps));
  }, [steps, steps.length])

  useEffect(() => {
    if (changesMade) {
      dispatch(ScriptsManagementSlice.actions.addUnsavedTab(scriptId));
    } else {
      dispatch(ScriptsManagementSlice.actions.removeUnsavedTab(scriptId));
    }
  }, [changesMade])

  return (
    <>
      <TPModalAlert
        onDesicion={(confirmed) => {
          setAlertVisible(false);
          if (confirmed) {
            mode == ScriptsMode.new ?
              verticalTabCallback({
                command: 'delete',
                recordId: '--'
              })
              :
              verticalTabCallback({
                command: 'delete',
                recordId: scriptId
              })
          }
        }}
        visible={alertVisible}
        acceptLabel={labels.Yes}
        cancelLabel={labels.No}
        content={mode == ScriptsMode.new ?
          labels.CancelScriptCreation
          :
          labels.YouMadeSomeChanges
        }
      />
      {editingStep && (
        <StepManagement
          onCompletion={(saved) => {
            setEditingStep(false);
            setChosenStep(null);
            if (saved) getSteps();
          }}
          scriptId={scriptId}
          scriptName={scriptName}
          unavailableOrders={steps.map(s => Number(s.order))}
          stepId={chosenStep?.id || ""}
        />
      )}
      {chosenStep && !editingStep &&
        <ScriptAdditionalDataDefinition
        onSubmit={(saved, data) => {
          setChosenAddDataId(null);
          setChosenStep(null);
          if (saved) getSteps();
        }}
        onAdditionalDataChange={() => { }}
        step={chosenStep}
        takenOrders={typeof chosenStep.additionalData == 'object' ?
          chosenStep.additionalData.map(data => data.order) : []
        }
        availableSteps={steps.filter(st => st.order == -1 || st.order > chosenStep.order)}
        dataId={chosenAddDataId}
        />}
      {!chosenAddDataId && !chosenStep && !editingStep &&
        <TPLoadingOverlay active={isLoading}>
        <div id="scripts-management" className="scripts-main-container">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div className="script-admin-header">
                {mode == ScriptsMode.new && <TPPageTitle>{labels.NewScript}</TPPageTitle>}
                {mode == ScriptsMode.update && <TPPageTitle>{labels.UpdateScript}: {scriptName || "--"}</TPPageTitle>}
                {mode == ScriptsMode.clone && <TPPageTitle>{labels.CloneScript}: {scriptName || "--"}</TPPageTitle>}
            </div>
            <div id="script-management-nav-tab" className="secondary-style-tp-tabs nav nav-tabs" role="tablist">
              <button
                id="script-management-general-info-nav-tab-button"
                className={`nav-link ${tab == ScriptsTab.general_info && 'active'}`}
                type="button" role="tab"
                onClick={() => setTab(ScriptsTab.general_info)}
              >
                  {labels.GeneralInfo}
              </button>
              <button
                id="script-management-steps-nav-tab-button"
                className={`nav-link ${tab == ScriptsTab.steps && 'active'}`}
                type="button" role="tab"
                onClick={() => setTab(ScriptsTab.steps)}
                  disabled={mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0}
                  style={{ color: mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0 ? "#969696" : "" }}
              >
                  {labels.Steps}
              </button>
              <button
                id="script-management-preview-nav-tab-button"
                className={`nav-link ${tab == ScriptsTab.preview && 'active'}`}
                type="button" role="tab"
                onClick={() => setTab(ScriptsTab.preview)}
                  disabled={mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0}
                  style={{ color: mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0 ? "#969696" : "" }}
              >
                  {labels.Preview}
              </button>
              <button
                id="script-management-diagram-nav-tab-button"
                  className={`nav-link ${tab == ScriptsTab.diagram && 'active'}`}
                  style={{ color: mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0 ? "#969696" : "" }}
                type="button" role="tab"
                onClick={() => setTab(ScriptsTab.diagram)}
                  disabled={mode == ScriptsMode.new || mode == ScriptsMode.clone || scriptName.trim().length == 0}
              >
                  {labels.Diagram}
              </button>
              </div>
              <ScriptGeneralInfoTab
                mode={mode}
                verticalTabUpdateCallback={(scId, scDesc) => {
                  verticalTabCallback({
                    command: 'delete',
                    recordId: '--',
                    languageId: TPGlobal.language,
                    recordDescription: ''
                  });
                  verticalTabCallback({
                    command: 'update',
                    recordId: scId,
                    languageId: TPGlobal.language,
                    recordDescription: scDesc
                  });
                }}
                visible={tab == ScriptsTab.general_info}
                scriptId={scriptId}
                scriptName={scriptName}
              />
              <ScriptStepsTab
                onNextStepChange={changeStepNextStep}
                defineAdditionalDataCallback={(step, data) => {
                  data && setChosenAddDataId(data.id);
                  step && setChosenStep(step);
                }}
                chosenStepCallback={(step) => {
                  setChosenStep(step || null);
                  setEditingStep(true);
                }}
                steps={steps}
                visible={tab == ScriptsTab.steps}
                onStepDelete={(stepId) => deleteStep(stepId)}
                onAdditionalDataDelete={(stepId, dataId) => deleteAdditionalData(dataId)}
              />
              {tab == ScriptsTab.preview &&
                <ScriptPreviewTab
                  saveCallback={() => { }}
                onEditRequest={(stepId) => {
                  setChosenStep(steps.find(s => s.id == stepId) || null);
                  setEditingStep(true);
                }}
                  scriptId={scriptId}
                  steps={steps}
                scriptName={scriptName}
                />}
              {(tab == ScriptsTab.diagram) && (
                <ScriptDiagramTab
                  saveCallback={() => { }}
                  scriptId={scriptId}
              />
              )}
          </div>
          <div className="script-management-footer">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {tab == ScriptsTab.steps &&
                  <button
                    type="button"
                    style={{
                      color: 'purple',
                      background: 'none',
                      border: 'none',
                      textWrap: "nowrap"
                    }}
                    onClick={() => checkLogic()}
                  >
                    <u>{labels.CheckLogic}</u>
                  </button>}
                <div className="script-footer-buttons-container">
                  <TPButton
                    onClick={() => {
                      if (Boolean(unsavedTabIds.find(i => i === scriptId))) {
                        setAlertVisible(true)
                      } else {
                        mode == ScriptsMode.new ?
                          verticalTabCallback({
                            command: 'delete',
                            recordId: '--'
                          })
                          :
                          verticalTabCallback({
                            command: 'delete',
                            recordId: scriptId
                          })
                      }
                    }}
                    isDesignSystem
                    style={{
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      backgroundColor: 'white',
                      color: 'purple'
                    }}
                  >
                    {labels.Cancel}
                  </TPButton>
                  <TPButton
                    onClick={() => saveScript()}
                    isDesignSystem
                    style={{
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                    disabled={
                      mode == ScriptsMode.clone ||
                      mode == ScriptsMode.new ||
                      nextStepsMap.length == 0}
                  >
                    {labels.SaveScript}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </TPLoadingOverlay>}
    </>
  )
}