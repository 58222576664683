import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ReactElement } from "react";

import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { CSSProperties } from "styled-components";
import TPIcon from "../../extend/TPIcons/TPIcon";
import TPSelectStyled from "./tpSelectStyles";

interface TPSelectInterface {
  onChange: Function;
  className?: string;
  dataSource: Array<TPKeyValue>;
  value: any;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  minWidth?: number;
  maxWidth?: number;
  isHorizontal?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: number;
  multiple?: boolean;
  containerStyle?: any;
  labelStyle?: any;
  selectStyle?: any;
  withIcon?: boolean;
  icon?: TPIconTypes;
  iconClick?: any;
  id?: string;
  isDynamic?: boolean;
  placeholder?: string;
  iconStyle?: CSSProperties
}

const defaultIconStyle = {
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  paddingLeft:'10px',
  paddingRight:'10px',
  border: '1px solid rgb(206, 212, 218)',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  borderLeft: '0px',
  fontSize: '22px',
  cursor: 'pointer',
};

const TPSelect = ({
  onChange,
  className = "",
  dataSource,
  value,
  labelText,
  isMandatory = false,
  minWidth = 100,
  maxWidth,
  isHorizontal = false,
  errorMessage = "",
  disabled = false,
  size = 0,
  multiple = false,
  containerStyle,
  labelStyle,
  selectStyle,
  withIcon = false,
  icon = TPIconTypes.default,
  iconClick = undefined,
  isDynamic = false,
  placeholder,
  id,
  iconStyle
}: TPSelectInterface): ReactElement => {
  const elementId = `${id ? id : "values"}-selection`;

  const handleOnChange = (e: any) => {
    if (!multiple) {
      let item: TPKeyValue;
      item = dataSource.filter((x) => x.key === e.target.value)[0];
      onChange(e, item);
    } else {
      let options = e.target.options;
      let value: Array<TPKeyValue> = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push({ key: options[i].value, value: options[i].text });
        }
      }
      onChange(e, value);
    }
  };

  const drawLabel = () => {
    return (
      <TPLabel
        htmlFor={elementId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
    );
  };

  const drawInvalidFeedBack = () => {
      return <div className="invalid-feedback" style={{ display:"block" }}>{errorMessage}</div>;
  };

  const theSelect = (
    <TPSelectStyled
      disabled={disabled}
      id={elementId}
      value={!value ? "" : value}
      onChange={handleOnChange}
      className={`form-select ${className} ${errorMessage !== "" ? "is-invalid" : "" }`}
      minWidth={minWidth}
      maxWidth={maxWidth}
      size={size}
      multiple={multiple}
      style={{
        ...selectStyle,
        border: errorMessage !== "" ? "1px solid red" : "1px solid rgb(206, 212, 218)",
        borderTopRightRadius: withIcon ? '0px' : '4px',
        borderBottomRightRadius: withIcon ? '0px' : '4px',
      }}
    >
      {placeholder && (
        <option value="" hidden={value} disabled>
          {placeholder}
        </option>
      )}
      {dataSource.map(function (item, index) {
        return (
          <option key={"option" + index.toString()} value={item.key}>
            {item.value}
          </option>
        );
      })}
    </TPSelectStyled>
  );

  return (
    <>
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        isDynamic={isDynamic}
        className={`tp-select`}
        style={containerStyle}
      >
        {labelText && drawLabel()}
        <div style={{display:'flex', flexDirection:'row'}}>
          {theSelect}
          {withIcon &&
            <TPIcon
              style={{
                ...defaultIconStyle,
                ...iconStyle
              }}
              iconType={icon}
              onClick={iconClick}
            />
          }
        </div>
        {!withIcon && errorMessage != "" && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>
      {withIcon && errorMessage != "" && drawInvalidFeedBack()}
    </>
  );
};

export default TPSelect;
