import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPI18N } from "@/services/I18nService";
import { InboundMailAzureApplicationService } from "@/services/InboundMailAzureApplicationService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<InboundMailboxesViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface InboundMailAzureApplicationAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const InboundMailAzureApplicationAdmin = React.forwardRef(
  ({ callBackCommands }: InboundMailAzureApplicationAdminInterface, ref) => {
    const componentFileName: string = "InboundMailAzureApplicationAdmin.tsx";

    //Functions called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "InboundMailAzureApplicationAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [azureTenantIdColumnLabel, setAzureTenantIdColumnLabel] =
      useState("");
    const [azureClientIdColumnLabel, setAzureClientIdColumnLabel] =
      useState("");
    const [azureClientSecretColumnLabel, setAzureClientSecretColumnLabel] =
      useState("");
    const [authenticationURLColumnLabel, setAuthenticationURLColumnLabel] =
      useState("");
    const [redirectCallbackURLColumnLabel, setRedirectCallbackURLColumnLabel] =
      useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    // const [localizedDescriptionColumnLabel, setLocalizedDescriptionColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");


    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndFunctionsFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "TableId"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "TableDescLabel"),
      );
      setAzureTenantIdColumnLabel(
        await TPI18N.GetText(resourceSet, "TableAzureTenantIdLabel"),
      );
      setAzureClientIdColumnLabel(
        await TPI18N.GetText(resourceSet, "TableAzureClientIdLabel"),
      );
      setAzureClientSecretColumnLabel(
        await TPI18N.GetText(resourceSet, "TableClientSecretIdLabel"),
      );
      setAuthenticationURLColumnLabel(
        await TPI18N.GetText(resourceSet, "TableAuthenticationURLLabel"),
      );
      setRedirectCallbackURLColumnLabel(
        await TPI18N.GetText(resourceSet, "TableRedirectCallbackURLLabel"),
      );
      setIsActiveColumnLabel(
        await TPI18N.GetText(resourceSet, "TableActiveLabel"),
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      // setLocalizedDescriptionColumnLabel(await TPI18N.GetText(resourceSet, "localizedDescription"));

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    //Get functions by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new InboundMailAzureApplicationService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);
        //Filter API
        let responseRequest =
          await serviceClient.getInboundMailAzureApplicationByFilter(
            selectedFilter,
            false,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Function
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update function
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete function
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new InboundMailAzureApplicationService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest =
            await serviceClient.deleteInboundMailAzureApplicationById(
              callBackData.recordId,
              true,
              true,
              expectedCodes,
            );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "localizedDescription",
        "azureTenantId",
        "azureClientId",
        "azureClientSecret",
        "authenticationURL",
        "redirectCallbackURL",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    
    const InboundMailAzureColumns: CustomColumnNames<InboundMailboxesViewModel> = {
      id: idColumnLabel,
      description: (descriptionColumnLabel+` (${TPGlobal.TPClientAvailableLanguages[0].name})`),
      azureTenantId:azureTenantIdColumnLabel,
      azureClientId:azureClientIdColumnLabel,
      azureClientSecret:azureClientSecretColumnLabel,
      authenticationURL:authenticationURLColumnLabel,
      redirectCallbackURL:redirectCallbackURLColumnLabel,
      isActive: isActiveColumnLabel,
    };
 
    const customColumns: ColumnStyles<InboundMailboxesViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
     
    };

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];
 
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string; }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string; }) => handleDeleteClick(e.id),
      },
    ];
 
    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndFunctionsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndFunctionsFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndFunctionsFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>

         <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                      <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                      <TPButton
                        id="new-customer-type"
                        isDesignSystem={true}
                        onClick={handleNewClick}
                        withIcon={TPIconTypes.add}
                        orientationIcon="left"
                        style={{ padding: "1px 18px" }}
                      >
                        {newLabel}
                      </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={InboundMailAzureColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={["localizedDescription"]}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
      </TPLoadingOverlay>
      </>
    );
  },
);

export default InboundMailAzureApplicationAdmin;
