import { ReactElement, useEffect, useState } from "react";
import { TPSearchCustomerStyled } from "./tpSearchCustomerStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPTextBox from "../textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPModalSearchClient, {
  TPModalSearchCustomerState,
} from "@/layouts/TPModalSearchClient/TPModalSearchClient";
import { searchCiteriaEnum } from "@/pages/NewCase/NewCase";
import { TPI18N } from "@/services/I18nService";

interface TPSearchCustomerInterface {
  searchCriteria?: searchCiteriaEnum | null;
  onCustomerFound: Function;
  onClick?: Function;
  onChange: Function;
  className?: string;
  value: string | number | undefined;
  disabled?: boolean;
  maxLength?: number;
  hasFocus?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  type?: string;
  containerStyle?: any;
  labelStyle?: any;
  textStyle?: any;
}

const TPSearchCustomer = ({
  searchCriteria = searchCiteriaEnum.names,
  onCustomerFound,
  onClick,
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  hasFocus,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  type = "text",
  containerStyle,
  labelStyle,
  textStyle,
}: TPSearchCustomerInterface): ReactElement => {
  const resourceSetNewCase: string = "NewCaseComponent";

  //modal search client
  const [searchClientModalTitle, setSearchClientModalTitle] = useState("");
  const [okLabel, setOkLabel] = useState("");
  const [cancelLabel, setCancelLabel] = useState("");

  const initialModalSearchState: TPModalSearchCustomerState = {
    isShown: false,
    initialSearchCriteria: searchCiteriaEnum.none,
    initialSearchValue: "",
    initialAddtionaDataSearchId: "",
  };
  const [modalSearchCustomerState, setModalSearchCustomerState] =
    useState<TPModalSearchCustomerState>(initialModalSearchState);

  const iconClick = () => {
    let valueToSearch: string;
    if (value === "" || value == null) {
      valueToSearch = "";
    } else {
      valueToSearch = value.toString();
    }
    let currentSearcCriteria: searchCiteriaEnum;
    if (searchCriteria == null) {
      currentSearcCriteria = searchCiteriaEnum.names;
    } else {
      currentSearcCriteria = searchCriteria;
    }
    let newModalSearchCustomerState: TPModalSearchCustomerState = {
      isShown: true,
      initialSearchCriteria: currentSearcCriteria,
      initialSearchValue: valueToSearch,
      initialAddtionaDataSearchId: "",
    };
    setModalSearchCustomerState(newModalSearchCustomerState);
  };

  //callback to handle customer search
  const callBackModalCustomerSearch = async (
    successResult: boolean,
    selectedClient: any,
    usedSearchCriteria: string,
    usedSearchValue: string,
  ) => {
    // Close modal
    let newModalSearchCustomerState: TPModalSearchCustomerState = {
      isShown: false,
      initialSearchCriteria: searchCiteriaEnum.names,
      initialSearchValue: "",
      initialAddtionaDataSearchId: "",
    };

    setModalSearchCustomerState(newModalSearchCustomerState);
    onCustomerFound(selectedClient);
  };

  const loadResourcesAndOrganizationAndRelation = async () => {
    setSearchClientModalTitle(
      await TPI18N.GetText(resourceSetNewCase, "SearchClientModalTitle"),
    );
    setOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
    setCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
  };

  useEffect(() => {
    loadResourcesAndOrganizationAndRelation();
  }, []);

  const handleOnKeyPress = (event: any) => {
    if (event.charCode === 13)
      //Enter
      iconClick();
  };

  return (
    <>
      <TPTextBox
        id="IdTextBox"
        onClick={onClick}
        onChange={onChange}
        onKeyPress={handleOnKeyPress}
        className={className}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        hasFocus={hasFocus}
        labelText={labelText}
        isMandatory={isMandatory}
        errorMessage={errorMessage}
        isHorizontal={isHorizontal}
        placeholder={placeholder}
        withIcon={true}
        icon={TPIconTypes.personSearch}
        type={type}
        iconClick={() => iconClick()}
        containerStyle={containerStyle}
        labelStyle={labelStyle}
        textStyle={textStyle}
      />

      {/* Customer Search */}
      <TPModalSearchClient
        title={searchClientModalTitle}
        acceptLabel={okLabel}
        cancelLabel={cancelLabel}
        callBackAnswer={callBackModalCustomerSearch}
        initialState={modalSearchCustomerState}
      />
    </>
  );
};

export default TPSearchCustomer;
