import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";

export type UsersBulkLoadInputDTO = {
  profileId: string;
  superiorIdGuid: string | null;
  teamId: string;
  functionId: string;
  areaId: string;
  typistIdGuid: string;
  calendarId: string;
  timeZoneId: string;
  scheduleId: string;
  defaultLanguage: string;
  users: Array<UserLoadInputDTO>;
};

export type UserLoadInputDTO = {
  login: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
  phone: string;
  mobile: string;
};

export enum ValidationUserGroupModeEnum {
  user = "user",
  group = "group",
}

export class UsersBulkLoadInputDTOValidator extends Validator<UsersBulkLoadInputDTO> {
  constructor(mode: ValidationUserGroupModeEnum) {
    super();

    const resourceSet = "UsersBulkLoadComponent";

    // ProfileId
    if (mode === ValidationUserGroupModeEnum.user) {
      this.ruleFor("profileId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOProfileIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
        .must((value) => TPGlobal.TPSanitize(value) === value)
        .withMessage(resourceSet + "|InputDTOInvalidProfileId");
    } else {
      this.ruleFor("profileId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
        .must((value) => (value ? TPGlobal.TPSanitize(value) === value : true))
        .withMessage(resourceSet + "|InputDTOInvalidProfileId");
    }

    if (mode == ValidationUserGroupModeEnum.user) {
      //ProfileId
      this.ruleFor("profileId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOProfileIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
        .must((value: any) => {
          return TPGlobal.TPSanitize(value) === value;
        })
        .withMessage(resourceSet + "|InputDTOInvalidProfileId");
    } else {
      //ProfileId
      this.ruleFor("profileId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength")
        .must((value: any) => {
          if (value) {
            return TPGlobal.TPSanitize(value) === value;
          } else {
            return true;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidProfileId");
    }

    // TeamId
    this.ruleFor("teamId")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidTeamLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidTeam");

    // FunctionId
    this.ruleFor("functionId")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidFunctionIdLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidFunctionId");

    // AreaId
    this.ruleFor("areaId")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidAreaIdLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidAreaId");

    // CalendarId
    this.ruleFor("calendarId")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOCalendarIdEmpty")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidCalendarIdLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidCalendarId");

    // TimeZoneId
    this.ruleFor("timeZoneId")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOTimeZoneEmpty")
      .maxLength(50)
      .withMessage(resourceSet + "|InputDTOInvalidTimeZoneLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidTimeZone");

    // ScheduleId
    this.ruleFor("scheduleId")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOScheduleIdEmpty")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidScheduleIdLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidScheduleId");

    // TypistIdGuid
    this.ruleFor("typistIdGuid")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOTypistIdEmpty")
      .maxLength(50)
      .withMessage(resourceSet + "|InputDTOInvalidTypistIdLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidTypistId");

    // Default Language
    this.ruleFor("defaultLanguage")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTODefaultLanguageEmpty")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidDefaultLanguageLength")
      .must((value) => TPGlobal.TPSanitize(value) === value)
      .withMessage(resourceSet + "|InputDTOInvalidDefaultLanguage");
  }
}
