import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import { commandType, commandsEnum as event } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import { SequenceGeneratorSequencesNameEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { WorkflowDefinitionImportEnum as e, WorkflowDefinitionDetailImportModel, WorkflowDefinitionExportModel, WorkflowDefinitionImportModel } from "@/models/Workflow/WorkflowDefinition";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import WorkflowDefinitionImportInfo from "./WorkflowDefinitionImportInfo";
import WorkflowDefinitionImportTable, { WorkflowDefinitionImportTableEvents, WorkflowDefinitionImportTableStructure } from "./WorkflowDefinitionImportTable";
import { TPI18N } from "@/services/I18nService";

/**
 * STYLES
 */
const styles = {
    column: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
    } as CSSProperties,
    title: {
        margin: '0 0 30px',
    } as CSSProperties,
    subtitles: {
        fontWeight: 'bold',
    } as CSSProperties,
    actions: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: "border-box",
        placeContent: "flex-end",
        alignItems: "flex-end",
        gap: "10px",
    } as CSSProperties,
    cancelButton: {
        backgroundColor: "white",
        color: "#a00095",
        border: "1px solid #a00095",
    } as CSSProperties,
    containerTable: {
        width: "100%",
        minHeight: "300px",
        maxHeight: "500px",
        height: "max-content",
        overflowX: "auto",
        overflowY: "hidden",
        marginBottom: "20px",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface WorkflowDefinitionImportEvents {
    /**
     * allows you to reload the table detail data
     */
    load: () => void;
}

/**
 * properties that the child component needs
 */
export interface WorkflowDefinitionImportProps {
    /**
     * vertical tab identifier
     */
    tabId: string;
    /**
     * vertical tab event runner
     */
    verticalTabsDispatch: (action: commandType) => void;
    /**
     * workflow definition
     */
    element?: WorkflowDefinitionExportModel;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of workflow definition import
 */
const WorkflowDefinitionImport = forwardRef((
    {
        tabId,
        verticalTabsDispatch,
        element,
    }: WorkflowDefinitionImportProps,
    ref
) => {
    /**
     * ATTRIBUTES
     */
    /**
     * Messages that are rendered in the view depending on the language
     */
    const [m, setMessages] = useState<{ [attribute: string]: string }>({});
    /**
     * loading event
     */
    const [loading, setLoading] = useState(true);
    /**
     * types
     */
    const [types, setTypes] = useState<Array<string>>([]);
    /**
     * workflow definition
     */
    const [item, setItem] = useState<WorkflowDefinitionExportModel | null>(element ?? null);
    /**
     * main table data
     */
    const formInformationRef = useRef<WorkflowDefinitionImportTableEvents>();
    /**
     * secondary table detail data
     */
    const detailInformationRef = useRef<WorkflowDefinitionImportTableEvents>();
    /**
     * secondary table detail data
     */
    const [formInformationStructure, setFormInformationStructure] = useState<Array<WorkflowDefinitionImportTableStructure>>([]);
    /**
     * secondary table detail data
     */
    const [detailInformationStructure, setDetailInformationStructure] = useState<Array<WorkflowDefinitionImportTableStructure>>([]);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(ref, () => ({
        load() {
            loadDataSource()
        },
    } as WorkflowDefinitionImportEvents));
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
        loadResources();
        loadTypes();
        if (item) {
            loadDataSource(); 
        }
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
        setMessages({});
        setLoading(false);
    }, []);
    /**
     * event on component close
     */
    useEffect(() => {
        if (m?.[e.TitleLabel]) {
            setLoading(false);
            setFormInformationStructure([
                {
                    attribute: "descriptions",
                    label: m?.[e.TableColumnDescriptionLabel],
                    type: "language",
                    required: true,
                },
                {
                    attribute: "promiseLimitUnit",
                    label: m?.[e.TableColumnPromiseLimitUnitLabel],
                    type: "text",
                },
                {
                    attribute: "promiseLimit",
                    label: m?.[e.TableColumnPromiseLimitLabel],
                    subLabel: m?.[e.TableSubColumnNumberLabel],
                    type: "number",
                },
                {
                    attribute: "isDirectSolution",
                    label: m?.[e.TableColumnDirectSolutionLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
                {
                    attribute: "isActive",
                    label: m?.[e.TableColumnActiveLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
            ]);
            setDetailInformationStructure([
                {
                    attribute: "order",
                    label: m?.[e.TableDetailColumnOrderLabel],
                    subLabel: m?.[e.TableSubColumnNumberLabel],
                    type: "number",
                    required: true,
                },
                {
                    attribute: "taskTypeId",
                    label: m?.[e.TableDetailColumnTaskTypeLabel],
                    type: "text",
                    required: true,
                },
                {
                    attribute: "term",
                    label: m?.[e.TableDetailColumnTermLabel],
                    subLabel: m?.[e.TableSubColumnNumberLabel],
                    type: "number",
                    required: true,
                },
                {
                    attribute: "termUnit",
                    label: m?.[e.TableDetailColumnPredecessorLabel],
                    type: "text",
                    required: true,
                },
                {
                    attribute: "writerId",
                    label: m?.[e.TableDetailColumnResponsibleLabel],
                    type: "text",
                    required: true,
                },
                {
                    attribute: "readerId",
                    label: m?.[e.TableDetailColumnReaderLabel],
                    type: "text",
                },
                {
                    attribute: "definesResponsible",
                    label: m?.[e.TableDetailColumnDefinesResponsibleLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
                {
                    attribute: "closesCustomer",
                    label: m?.[e.TableDetailColumnClosesCustomerLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
                {
                    attribute: "requiresAllPredecessors",
                    label: m?.[e.TableDetailColumnRequiresAllPredecessorsLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
                {
                    attribute: "isActive",
                    label: m?.[e.TableDetailColumnIsActiveLabel],
                    subLabel: m?.[e.TableSubColumnYesNoLabel],
                    type: "boolean",
                },
            ]);
        }
    }, [m]);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
        const messages = {...m};
        messages[e.TitleLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TitleLabel);
        messages[e.InfoSectionTitleLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.InfoSectionTitleLabel);
        messages[e.InfoSectionDescriptionLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.InfoSectionDescriptionLabel);
        messages[e.FormInformationLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.FormInformationLabel);
        messages[e.DetailsInformationLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.DetailsInformationLabel);
        messages[e.TableColumnDescriptionLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableColumnDescriptionLabel);
        messages[e.TableColumnPromiseLimitUnitLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableColumnPromiseLimitUnitLabel);
        messages[e.TableColumnPromiseLimitLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableColumnPromiseLimitLabel);
        messages[e.TableColumnDirectSolutionLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableColumnDirectSolutionLabel);
        messages[e.TableColumnActiveLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableColumnActiveLabel);
        messages[e.TableDetailColumnOrderLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnOrderLabel);
        messages[e.TableDetailColumnTaskTypeLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnTaskTypeLabel);
        messages[e.TableDetailColumnTermLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnTermLabel);
        messages[e.TableDetailColumnPredecessorLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnPredecessorLabel);
        messages[e.TableDetailColumnResponsibleLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnResponsibleLabel);
        messages[e.TableDetailColumnReaderLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnReaderLabel);
        messages[e.TableDetailColumnDefinesResponsibleLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnDefinesResponsibleLabel);
        messages[e.TableDetailColumnClosesCustomerLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnClosesCustomerLabel);
        messages[e.TableDetailColumnRequiresAllPredecessorsLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnRequiresAllPredecessorsLabel);
        messages[e.TableDetailColumnIsActiveLabel] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.TableDetailColumnIsActiveLabel);
        messages[e.TableSubColumnYesNoLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.TableSubColumnYesNoLabel);
        messages[e.TableSubColumnNumberLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.TableSubColumnYesNoLabel);
        messages[e.ActionSectionCancelButton] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionSectionCancelButton);
        messages[e.ActionSectionSaveButton] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionSectionSaveButton);
        messages[e.NotificationDescriptionIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDescriptionIsRequiredMessage);
        messages[e.NotificationPromiseLimitUnitIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationPromiseLimitUnitIsInvalidMessage);
        messages[e.NotificationPromiseLimitIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationPromiseLimitIsInvalidMessage);
        messages[e.NotificationIsDirectSolutionIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationIsDirectSolutionIsInvalidMessage);
        messages[e.NotificationIsActiveIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationIsActiveIsInvalidMessage);
        messages[e.NotificationDetailOrderIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailOrderIsRequiredMessage);
        messages[e.NotificationDetailTaskTypeIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailTaskTypeIsRequiredMessage);
        messages[e.NotificationDetailTermIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailTermIsRequiredMessage);
        messages[e.NotificationDetailPredecessorIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailPredecessorIsRequiredMessage);
        messages[e.NotificationDetailResponsibleIsRequiredMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailResponsibleIsRequiredMessage);
        messages[e.NotificationDetailOrderIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailOrderIsInvalidMessage);
        messages[e.NotificationDetailTaskTypeIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailTaskTypeIsInvalidMessage);
        messages[e.NotificationDetailTermIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailTermIsInvalidMessage);
        messages[e.NotificationDetailPredecessorIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailPredecessorIsInvalidMessage);
        messages[e.NotificationDetailDefinesResponsibleIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailDefinesResponsibleIsInvalidMessage);
        messages[e.NotificationDetailClosesCustomerIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailClosesCustomerIsInvalidMessage);
        messages[e.NotificationDetailRequiresAllPredecessorsIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailRequiresAllPredecessorsIsInvalidMessage);
        messages[e.NotificationDetailActiveIsInvalidMessage] = await TPI18N.GetText(e.WorkflowDefinitionImportComponent, e.NotificationDetailActiveIsInvalidMessage);
        
        setMessages(messages)
    }
    /**
     * Function responsible for consulting the records that detail the component
     */
    function loadTypes() {
        let {getByParentIdAndFilterIsActive} = new ParametersService();
        getByParentIdAndFilterIsActive(
            SystemParametersEnum.TEUNI,
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            [200],
        )
            .then(response => {setTypes(response.map(({id}) => id))})
            .catch(error => console.error(error));
    }
    /**
     * Function responsible for consulting the records that detail the component
     */
    function loadDataSource() {
        const {exportOne} = new WorkflowTypeService();
        exportOne(item!.id)
            .then(response => setItem(response))
            .catch(error => console.error(error))
    }
    /**
     * Function responsible for consulting the records that detail the component
     */
    function onClickButtonCloseHandler() {
        verticalTabsDispatch({type: event.reloadGrid});
        verticalTabsDispatch({type: event.vertical_tab_close, payload: tabId});
    }
    /**
     * Function responsible for consulting the records that detail the component
     */
    async function onClickButtonSaveHandler() {
        let isInvalid = false
        const formInformationValue: WorkflowDefinitionImportModel = formInformationRef.current?.getChanges();
        const detailInformationValues: Array<WorkflowDefinitionDetailImportModel> = detailInformationRef.current?.getChanges();

        const description = formInformationValue.descriptions
            .find(({languageId}) => languageId === TPGlobal.language)?.localizedValue;
        
        if (!description) {
            isInvalid = true
            showToast(m?.[e.NotificationDescriptionIsRequiredMessage], TPToastTypes.warning);
        }

        if (formInformationValue.promiseLimitUnit && !types.includes(formInformationValue.promiseLimitUnit)) {
            isInvalid = true
            showToast(m?.[e.NotificationPromiseLimitUnitIsInvalidMessage], TPToastTypes.warning);
        }

        if (formInformationValue.promiseLimit === undefined) {
            isInvalid = true
            showToast(m?.[e.NotificationPromiseLimitIsInvalidMessage], TPToastTypes.warning);
        }

        if (formInformationValue.isDirectSolution === undefined) {
            isInvalid = true
            showToast(m?.[e.NotificationIsDirectSolutionIsInvalidMessage], TPToastTypes.warning);
        }

        if (formInformationValue.isActive === undefined) {
            isInvalid = true
            showToast(m?.[e.NotificationIsActiveIsInvalidMessage], TPToastTypes.warning);
        }

        for (let value of detailInformationValues) {
            if (isInvalid) {
                break;
            }

            if (value.order === null) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailOrderIsRequiredMessage], TPToastTypes.warning);
            }

            if (value.taskTypeId === null) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailTaskTypeIsRequiredMessage], TPToastTypes.warning);
            }

            if (value.term === null) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailTermIsRequiredMessage], TPToastTypes.warning);
            }

            if (value.termUnit === null) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailPredecessorIsRequiredMessage], TPToastTypes.warning);
            }

            if (value.writerId === null) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailResponsibleIsRequiredMessage], TPToastTypes.warning);
            }

            if (value.order === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailOrderIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.taskTypeId === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailTaskTypeIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.term === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailTermIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.termUnit && !types.includes(value.termUnit)) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailPredecessorIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.definesResponsible === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailDefinesResponsibleIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.closesCustomer === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailClosesCustomerIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.requiresAllPredecessors === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailRequiresAllPredecessorsIsInvalidMessage], TPToastTypes.warning);
            }

            if (value.isActive === undefined) {
                isInvalid = true
                showToast(m?.[e.NotificationDetailActiveIsInvalidMessage], TPToastTypes.warning);
            }
        }

        if (isInvalid) {
            return
        }

        const {generalAutomaticId} = new SequenceService();
        const response = await generalAutomaticId(false, true, [200], SequenceGeneratorSequencesNameEnum.SQWOTY);
        const id: string = response.responseData.data[0].sequenceCode;
        
        const details = detailInformationValues.map(v => ({...v, workflowTypeId: id}));

        const request = {
            ...formInformationValue,
            id,
            description,
            details,
        } as WorkflowDefinitionImportModel;

        setLoading(true)
        const {importOne} = new WorkflowTypeService();
        importOne(request, true, true, [200, 404, 400])
            .then(() => {
                setLoading(false);
                verticalTabsDispatch({type: event.reloadGrid});
                verticalTabsDispatch({type: event.vertical_tab_close, payload: tabId});
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <TPLoadingOverlay active={loading}>
            <div style={styles.column}>
                <TPPageTitle style={styles.title}>{m?.[e.TitleLabel]}</TPPageTitle>
                
                <WorkflowDefinitionImportInfo m={m} />

                <h6 style={styles.subtitles}>{m?.[e.FormInformationLabel]}</h6>

                {formInformationStructure.length > 0 && (
                    <WorkflowDefinitionImportTable
                        id="FormInformation"
                        key="FormInformation"
                        ref={formInformationRef}
                        structure={formInformationStructure}
                        dataSource={item}
                    />
                )}

                <h6 style={styles.subtitles}>{m?.[e.DetailsInformationLabel]}</h6>

                {detailInformationStructure.length > 0 && (
                    <WorkflowDefinitionImportTable
                        id="DetailsInformation"
                        key="DetailsInformation"
                        ref={detailInformationRef}
                        structure={detailInformationStructure}
                        dataSource={item?.workflowDetails}
                        multiple={true}
                    />
                )}

                <div 
                    id="WorkflowDefinitionImportActionsSection"
                    key="WorkflowDefinitionImportActionsSection"
                    style={styles.actions}
                >
                    <TPButton 
                        id="WorkflowDefinitionImportCancelButton"
                        key="WorkflowDefinitionImportCancelButton"
                        style={styles.cancelButton}
                        type={TPButtonTypes.primary}
                        onClick={onClickButtonCloseHandler}
                    >
                        {m?.[e.ActionSectionCancelButton]}
                    </TPButton>

                    <TPButton
                        id="WorkflowDefinitionImportAcceptButton"
                        key="WorkflowDefinitionImportAcceptButton"
                        type={TPButtonTypes.primary}
                        onClick={onClickButtonSaveHandler}
                    >
                        {m?.[e.ActionSectionSaveButton]}
                    </TPButton>
                </div>
            </div>
        </TPLoadingOverlay>
    );
})

export default WorkflowDefinitionImport;
