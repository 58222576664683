import React, { useState, useRef, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomToolbar } from "./CustomToolbar";
import TPModal from "@/layouts/TPModal/TPModal";
import ImagesAdmin from "@/pages/ImagesAdmin/ImagesAdmin";
import { AttachmentType, ModalSizeEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { AppProviderImagesAdmin } from "@/pages/ImagesAdmin/ImagesAdminContextImagesAdmin";
import QuillResizeImage from 'quill-resize-image';
import { StorageService } from "@/services/StorageService";

const ImageBlot = Quill.import("formats/image");

class CustomImageBlot extends ImageBlot {
    static create(value: any) {
        const node = super.create(value);
        node.setAttribute("src", value.img_src);
        node.setAttribute("img_blobId", value.img_blobId);
        node.style.width = `${value.width}px`;
        node.style.height = `${value.height}px`;
        return node;
    }

    static value(node: any) {
        return {
            img_src: node.getAttribute("src"),
            img_blobId: node.getAttribute("img_blobId"),
            width: node.style.width.replace('px', ''),
            height: node.style.height.replace('px', ''),
        };
    }
}

Quill.register({ "formats/image": CustomImageBlot });
Quill.register("modules/resize", QuillResizeImage);

export const TPEditor = ({
    id,
    value,
    onChange,
    popUpOpen,
    referece,
    initialValue,
    isVisible,
    placeholder,
    style,
    icons,
    onClickCustomIcon,
    isEdit
}: any) => {
    const random = Math.floor(Math.random() * 999);
    const toolbarId = "toolbar" + random;
    const editorId = `${id && `${id}-`}text-editor`;

    const styles = `
  .no-edit .ql-editor {
    pointer-events: none;
    user-select: none;
  }
`;

    const quillRef = useRef<ReactQuill>(null);
    const [toolbarNode, setToolbarNode] = useState<HTMLDivElement | null>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

    const handleOpenImageModal = () => {
        setIsImageModalOpen(true);
    };

    const handleCloseImageModal = () => {
        setIsImageModalOpen(false);
    };

    useEffect(() => {      
        if (quillRef.current && referece) {
            referece(quillRef.current);
        }
    }, [quillRef, referece]);

    const updateImageText = async () => {
        const editor = quillRef.current?.getEditor();

        if (editor) {
            let content = editor.root.innerHTML;
            const regex = /\[ImageEmbedded\|([a-zA-Z0-9-]{32,36})\|(\d+)\|(\d+)\]/g;

            const matches = [];
            let match;

            while ((match = regex.exec(content)) !== null) {
                const [fullMatch, blobId, width, height] = match;
                matches.push(
                    new Promise((resolve, reject) => {
                        const attachmentService = new StorageService();
                        attachmentService
                            .getFileUriSas(blobId, AttachmentType.IMAGE, false, false, [])
                            .then((response) => {
                                resolve({ fullMatch, blobId, width, height, sasUri: response.sasUri });
                            })
                            .catch((error) => {
                                console.error(error);
                                resolve({ fullMatch, blobId, width, height, sasUri: null });
                            });
                    })
                );
            }

            const results = await Promise.all(matches);

            results.forEach(({ fullMatch, blobId, width, height, sasUri }: any) => {
                if (sasUri) {
                    content = content.replace(
                        fullMatch,
                        `<img src='${sasUri}' img_blobId='${blobId}' style='width: ${width}px; height: ${height}px' />`
                    );
                }
            });

            if (editor.root.innerHTML !== content) {
                editor.root.innerHTML = content;
            }
        }
    };


 

    useEffect(() => {
        const interval = setInterval(() => {
            const quillValue = quillRef?.current?.value?.toString();

            if (quillValue) {
                const imagePattern = /\[ImageEmbedded\|([a-zA-Z0-9-]{32,36})\|(\d+)\|\d+\]/g;
                const matchesPattern = imagePattern.test(quillValue);

                if (matchesPattern) {
                    updateImageText();
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [quillRef]);

    const modules = React.useMemo(() => {
        return {
            toolbar: {
                container: toolbarNode,
            },
            clipboard: {
                matchVisual: false,
            },
            resize: {
                locale: {},
            },
        };
    }, [toolbarNode]);

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "background",
        "align",
    ];

    return (
        <>
            <CustomToolbar
                ref={setToolbarNode}
                id={toolbarId}
                isPopUp={popUpOpen}
                isVisible={isVisible}
                openImageModal={handleOpenImageModal}
                icons={icons}
                onClickCustomIcon={onClickCustomIcon }
            />

            {!!toolbarNode && (
                <ReactQuill
                    id={editorId}
                    defaultValue={initialValue}
                    ref={quillRef}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    modules={modules}
                    formats={formats}
                    theme={"snow"}
                    style={{ height: "140px" }}
                    readOnly={(isEdit != undefined && !isEdit)}
                />
            )}

            <ImageSelectorModal
                quillRef={quillRef}
                isModalOpen={isImageModalOpen}
                handleCloseModal={handleCloseImageModal}
            />
        </>
    );
};

const ImageSelectorModal: React.FC<{
    quillRef: React.RefObject<ReactQuill>;
    isModalOpen: boolean;
    handleCloseModal: () => void;
}> = ({ quillRef, isModalOpen, handleCloseModal }) => {
    const handleImageInsert = (imageData: any) => {
        const { imageUrl, blobId, width, height } = imageData;
        const editor = quillRef.current?.getEditor();

        if (editor) {
            const range = editor.getSelection();
            const index = range ? range.index : editor.getLength();

            editor.insertEmbed(index, "image", {
                img_src: imageUrl,
                img_blobId: blobId,
                width,
                height,
            });

            editor.setSelection({ index: index + 1, length: 0 });
        }
    };

    return (
        <TPModal
            modalState={{
                hideFooterButtons: true,
                titleModal: "",
                acceptLabel: "",
                callBackAnswer: handleCloseModal,
                callBackData: null,
                isShown: isModalOpen,
                modalWidth: ModalSizeEnum.MODALXL,
                isFullscreen: true,
                zIndex: 9999,
            }}
        >
            <AppProviderImagesAdmin>
                <ImagesAdmin
                    modeSelectFolder={true}
                    parentFolderIdActive={0}
                    ChangeImageSelection={async (e: any) => {
                        handleImageInsert(e);
                        handleCloseModal();
                    }}
                />
            </AppProviderImagesAdmin>
        </TPModal>
    );
};
