import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  TPPageActions,
  TPPageFirstRow,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ProfileMenuItemViewModel } from "@/models/MenuItems/MenuItemsModels";
import { TPI18N } from "@/services/I18nService";
import { MenuItemsService } from "@/services/MenuItemService";
import { ProfileMenuItemService } from "@/services/ProfileMenuItemService";
import React, { useImperativeHandle } from "react";
import { useEffect, useState } from "react";

export type tProfileItemModel = {
  profileId: string;
  profileName: string;
  profilePermission: number;
};

export type tItemModel = {
  itemId: string;
  itemName: string;
  profilePermissions: Array<tProfileItemModel>;
};

export type tGroupModel = {
  groupId: string;
  groupName: string;
  Items: Array<tItemModel>;
  profilePermissions: Array<tProfileItemModel>;
};

export type tSectionModel = {
  sectionId: string;
  sectionName: string;
  Order: number;
  Groups: Array<tGroupModel>;
  profilePermissions: Array<tProfileItemModel>;
};

type ColumnProps = {
  columnLabel: string;
};

interface MenuDefinitionAdminInterface {
  callBackCommands: Function;
}

const MenuDefinitionAdmin = React.forwardRef(
  ({ callBackCommands }: MenuDefinitionAdminInterface, ref) => {
    //Functions called form parent MenuDefinitionContainerInsertUpdate
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadDataGrid();
      },
    }));
    //#region Init
    const componentFileName: string = "MenuDefinitionAdmin.tsx";
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "MenuDefinitionAdmin";
    const [titleLabel, setTitleLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //simple state
    const [dataTransformed, setDataTransformed] = useState<
      Array<tSectionModel>
    >([]);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const loadResources = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );

      await reloadDataGrid();
    };

    const reloadDataGrid = async () => {
      try {
        setIsLoadingScreen(true);
        let data = await getGridData();
        let keys: any = Object.keys(data[0]);

        let newData: Array<tSectionModel> = [];
        let found: boolean = false;
        for (let i: number = 0; i <= data.length - 1; i++) {
          if (data[i].col12Value as boolean) {
            found = false;
            for (let j: number = 0; j <= newData.length - 1; j++) {
              if (
                newData[j].sectionId.toLowerCase() ===
                data[i].col1Value.toLowerCase()
              ) {
                found = true;
                break;
              }
            }
            if (!found) {
              let newSection: tSectionModel = {
                sectionId: data[i].col1Value,
                sectionName: data[i].col13Value,
                Order: data[i].col14Value,
                Groups: [],
                profilePermissions: [],
              };
              newData.push(newSection);
            }
          }
        }
        //order by section order
        for (let i: number = 0; i <= newData.length - 2; i++) {
          for (let j: number = i + 1; j <= newData.length - 1; j++) {
            if (newData[j].Order < newData[i].Order) {
              let swap: tSectionModel = { ...newData[i] };
              newData[i] = { ...newData[j] };
              newData[j] = { ...swap };
            }
          }
        }
        //for each section get groups
        for (let i: number = 0; i <= newData.length - 1; i++) {
          let newGroups: Array<tGroupModel> = [];
          for (let j: number = 0; j <= data.length - 1; j++) {
            if (
              newData[i].sectionId.toLowerCase() ==
              data[j].col3Value?.toLowerCase()
            ) {
              let newGroup: tGroupModel = {
                groupId: data[j].col1Value,
                groupName: data[j].col13Value,
                Items: [],
                profilePermissions: [],
              };
              newGroups.push(newGroup);
            }
          }

          for (let k: number = 0; k <= newGroups.length - 2; k++) {
            for (let m: number = k + 1; m <= newGroups.length - 1; m++) {
              if (
                newGroups[m].groupName.toLowerCase() <
                newGroups[k].groupName.toLowerCase()
              ) {
                let swap: tGroupModel = { ...newGroups[k] };
                newGroups[k] = { ...newGroups[m] };
                newGroups[m] = { ...swap };
              }
            }
          }
          newData[i].Groups = [...newGroups];
        }

        //for each group in each section search for items
        for (let i: number = 0; i <= newData.length - 1; i++) {
          for (let j: number = 0; j <= newData[i].Groups.length - 1; j++) {
            for (let k: number = 0; k <= data.length - 1; k++) {
              if (
                data[k].col3Value?.toLowerCase() ==
                newData[i].Groups[j].groupId.toLowerCase()
              ) {
                let newItem: tItemModel = {
                  itemId: data[k].col1Value,
                  itemName: data[k].col13Value,
                  profilePermissions: [],
                };
                newData[i].Groups[j].Items.push(newItem);
              }
            }

            //order items
            for (
              let k: number = 0;
              k <= newData[i].Groups[j].Items.length - 2;
              k++
            ) {
              for (
                let m: number = k + 1;
                m <= newData[i].Groups[j].Items.length - 1;
                m++
              ) {
                if (
                  newData[i].Groups[j].Items[m].itemName.toLowerCase() <
                  newData[i].Groups[j].Items[k].itemName.toLowerCase()
                ) {
                  let swap: tItemModel = { ...newData[i].Groups[j].Items[k] };
                  newData[i].Groups[j].Items[k] = {
                    ...newData[i].Groups[j].Items[m],
                  };
                  newData[i].Groups[j].Items[m] = { ...swap };
                }
              }
            }
          }
        }

        //fill permissions sections
        for (let i: number = 0; i <= newData.length - 1; i++) {
          for (let j: number = 0; j <= data.length - 1; j++) {
            if (
              newData[i].sectionId.toLowerCase() ===
              data[j].col1Value.toLowerCase()
            ) {
              let newPermissions: Array<tProfileItemModel> = [];
              for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
                let profileData: string;
                let profileId: string;
                let profileName: string;
                let profileValue: number;
                profileData = data[j][keys[k]];
                profileId = profileData.split("||")[1];
                profileName = profileData.split("||")[2];
                profileValue = data[j][keys[k + 1]];

                let newPermission: tProfileItemModel = {
                  profileId: profileId,
                  profileName: profileName,
                  profilePermission: profileValue,
                };
                newPermissions.push(newPermission);
              }
              newData[i].profilePermissions = [...newPermissions];
            }
          }
        }

        //fill groups permissions
        for (let i: number = 0; i <= newData.length - 1; i++) {
          for (let g: number = 0; g <= newData[i].Groups.length - 1; g++) {
            for (let j: number = 0; j <= data.length - 1; j++) {
              if (
                newData[i].Groups[g].groupId.toLowerCase() ===
                data[j].col1Value.toLowerCase()
              ) {
                let newPermissions: Array<tProfileItemModel> = [];
                for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
                  let profileData: string;
                  let profileId: string;
                  let profileName: string;
                  let profileValue: number;
                  profileData = data[j][keys[k]];
                  profileId = profileData.split("||")[1];
                  profileName = profileData.split("||")[2];
                  profileValue = data[j][keys[k + 1]];

                  let newPermission: tProfileItemModel = {
                    profileId: profileId,
                    profileName: profileName,
                    profilePermission: profileValue,
                  };
                  newPermissions.push(newPermission);
                }
                newData[i].Groups[g].profilePermissions = [...newPermissions];
              }
            }
          }
        }

        //fill items permissions
        for (let i: number = 0; i <= newData.length - 1; i++) {
          for (let g: number = 0; g <= newData[i].Groups.length - 1; g++) {
            for (
              let m: number = 0;
              m <= newData[i].Groups[g].Items.length - 1;
              m++
            ) {
              for (let j: number = 0; j <= data.length - 1; j++) {
                if (
                  newData[i].Groups[g].Items[m].itemId.toLowerCase() ===
                  data[j].col1Value.toLowerCase()
                ) {
                  let newPermissions: Array<tProfileItemModel> = [];
                  for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
                    let profileData: string;
                    let profileId: string;
                    let profileName: string;
                    let profileValue: number;
                    profileData = data[j][keys[k]];
                    profileId = profileData.split("||")[1];
                    profileName = profileData.split("||")[2];
                    profileValue = data[j][keys[k + 1]];

                    let newPermission: tProfileItemModel = {
                      profileId: profileId,
                      profileName: profileName,
                      profilePermission: profileValue,
                    };
                    newPermissions.push(newPermission);
                  }
                  newData[i].Groups[g].Items[m].profilePermissions = [
                    ...newPermissions,
                  ];
                }
              }
            }
          }
        }

        setDataTransformed(newData);
        setIsLoadingScreen(false);
      } catch (error) {
        setIsLoadingScreen(false);
      }
    };

    const getGridData = async () => {
      let expectedCodes: Array<number> = [200, 404];
      let profileMenuItemService = new ProfileMenuItemService();
      try {
        let response: Array<ProfileMenuItemViewModel> =
          await profileMenuItemService.getAllAssignedToProfile(
            false,
            true,
            expectedCodes,
          );
        if (response && response.length > 0) {
          return [...response[0].profileMenuItemDetail];
        } else {
          return [];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getGridData ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getGridData ex`);
        return [];
      }
    };

    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    const handleUpdateItem = (id: string, recordDescription: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        recordDescription: recordDescription,
      };
      callBackCommands(command);
    };

    const handleDeleteItem = async (id: string, menuName: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id, menuName: menuName };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete menu after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];

      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await MenuItemsService.delete(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            await reloadDataGrid();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    const handleRefreshClick = async () => {
      await reloadDataGrid();
    };

    useEffect(() => {
      loadResources();
    }, []);
    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.menuName,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                </TPPageActions>
              </TPPageFirstRow>
            </div>
          </div>
          {dataTransformed.length >= 1 && (
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="tptable-report table table-striped table-sm table-bordered caption-top">
                    <thead>
                      <tr>
                        <th
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                            padding: "10px",
                            borderBottom: "1px solid red",
                          }}
                        >
                          <TPLanguage
                            resourceSet={resourceSet}
                            resourceId="SectionColumnLabel"
                          />
                        </th>
                        <th
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                            padding: "10px",
                            borderBottom: "1px solid red",
                          }}
                        >
                          <TPLanguage
                            resourceSet={resourceSet}
                            resourceId="GroupColumnLabel"
                          />
                        </th>
                        <th
                          style={{
                            verticalAlign: "top",
                            textAlign: "left",
                            padding: "10px",
                            borderBottom: "1px solid red",
                          }}
                        >
                          <TPLanguage
                            resourceSet={resourceSet}
                            resourceId="ItemColumnLabel"
                          />
                        </th>
                        {dataTransformed[0].profilePermissions.map(
                          function (permission) {
                            return (
                              <th
                                style={{
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  padding: "10px",
                                  borderBottom: "1px solid red",
                                }}
                                key={"th" + permission.profileId}
                              >
                                {permission.profileName}
                              </th>
                            );
                          },
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dataTransformed.map(function (section, indexSection) {
                        return (
                          <>
                            <tr key={"trsection" + section.sectionId}>
                              <td
                                style={{
                                  verticalAlign: "top",
                                  padding: "10px",
                                }}
                              >
                                <div className="dropdown">
                                  <TPButton
                                    dataBsToggle={true}
                                    type={TPButtonTypes.icon}
                                    onClick={() => {
                                      TPGlobal.foo();
                                    }}
                                    className={"menu-button"}
                                    icon={TPIconTypes.moreVert}
                                    text={
                                      <TPLabel
                                        className="tp-primary-color tpbold"
                                        labelText={section.sectionName}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  ></TPButton>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a
                                        onClick={(id: any) =>
                                          handleDeleteItem(
                                            section.sectionId,
                                            section.sectionName,
                                          )
                                        }
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <TPLanguage
                                          resourceSet={resourceSet}
                                          resourceId="DeleteLabel"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={(id: any) =>
                                          handleUpdateItem(
                                            section.sectionId,
                                            section.sectionName,
                                          )
                                        }
                                        className="dropdown-item"
                                        href="#"
                                      >
                                        <TPLanguage
                                          resourceSet={resourceSet}
                                          resourceId="UpdateLabel"
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "top",
                                  padding: "10px",
                                }}
                              >
                                &nbsp;
                              </td>
                              <td
                                style={{
                                  verticalAlign: "top",
                                  padding: "10px",
                                }}
                              >
                                &nbsp;
                              </td>
                              {section.profilePermissions.map(
                                function (permission) {
                                  return (
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        textAlign: "center",
                                        padding: "10px",
                                      }}
                                      key={
                                        "tdsectionpermision" +
                                        section.sectionId +
                                        permission.profileId
                                      }
                                    >
                                      <TPCheckBox
                                        id="IdCheckBox"
                                        checked={
                                          permission.profilePermission === 1
                                        }
                                      />
                                    </td>
                                  );
                                },
                              )}
                            </tr>
                            {section.Groups.map(function (group, groupIndex) {
                              let stylerow: any;
                              stylerow = {
                                verticalAlign: "top",
                                padding: "10px",
                              };
                              return (
                                <>
                                  <tr key={"trgroup" + group.groupId}>
                                    <td style={stylerow}>&nbsp;</td>
                                    <td style={stylerow}>
                                      <div className="dropdown">
                                        <TPButton
                                          dataBsToggle={true}
                                          type={TPButtonTypes.icon}
                                          onClick={() => {
                                            TPGlobal.foo();
                                          }}
                                          className={"menu-button"}
                                          icon={TPIconTypes.moreVert}
                                          text={
                                            <TPLabel
                                              className="tp-primary-color tpbold"
                                              labelText={group.groupName}
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                            />
                                          }
                                        ></TPButton>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(id: any) =>
                                                handleDeleteItem(
                                                  group.groupId,
                                                  group.groupName,
                                                )
                                              }
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              <TPLanguage
                                                resourceSet={resourceSet}
                                                resourceId="DeleteLabel"
                                              />
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={(id: any) =>
                                                handleUpdateItem(
                                                  group.groupId,
                                                  group.groupName,
                                                )
                                              }
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              <TPLanguage
                                                resourceSet={resourceSet}
                                                resourceId="UpdateLabel"
                                              />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td style={stylerow}>&nbsp;</td>
                                    {group.profilePermissions.map(
                                      function (permission) {
                                        return (
                                          <td
                                            style={{
                                              verticalAlign: "top",
                                              padding: "10px",
                                              textAlign: "center",
                                            }}
                                            key={
                                              "tdgrouppermision" +
                                              section.sectionId +
                                              group.groupId +
                                              permission.profileId
                                            }
                                          >
                                            <TPCheckBox
                                              id="IdCheckBox"
                                              checked={
                                                permission.profilePermission ===
                                                1
                                              }
                                            />
                                          </td>
                                        );
                                      },
                                    )}
                                  </tr>

                                  {group.Items.map(function (item, itemIndex) {
                                    let stylerow: any;
                                    if (itemIndex === group.Items.length - 1) {
                                      stylerow = {
                                        verticalAlign: "top",
                                        padding: "10px",
                                      };
                                    } else {
                                      stylerow = {
                                        verticalAlign: "top",
                                        padding: "10px",
                                      };
                                    }
                                    return (
                                      <>
                                        <tr key={"tritem" + item.itemId}>
                                          <td style={stylerow}>&nbsp;</td>
                                          <td style={stylerow}>&nbsp;</td>
                                          <td style={stylerow}>
                                            <div className="dropdown">
                                              <TPButton
                                                dataBsToggle={true}
                                                type={TPButtonTypes.icon}
                                                onClick={() => {
                                                  TPGlobal.foo();
                                                }}
                                                className={"menu-button"}
                                                icon={TPIconTypes.moreVert}
                                                text={
                                                  <TPLabel
                                                    className="tp-primary-color tpbold"
                                                    labelText={item.itemName}
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                }
                                              ></TPButton>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <a
                                                    onClick={(id: any) =>
                                                      handleDeleteItem(
                                                        item.itemId,
                                                        item.itemName,
                                                      )
                                                    }
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <TPLanguage
                                                      resourceSet={resourceSet}
                                                      resourceId="DeleteLabel"
                                                    />
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={(id: any) =>
                                                      handleUpdateItem(
                                                        item.itemId,
                                                        item.itemName,
                                                      )
                                                    }
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <TPLanguage
                                                      resourceSet={resourceSet}
                                                      resourceId="UpdateLabel"
                                                    />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                          {item.profilePermissions.map(
                                            function (permission) {
                                              let stylerow: any;
                                              stylerow = {
                                                verticalAlign: "top",
                                                textAlign: "center",
                                                padding: "10px",
                                              };
                                              return (
                                                <td
                                                  style={stylerow}
                                                  key={
                                                    "tditempermision" +
                                                    section.sectionId +
                                                    group.groupId +
                                                    item.itemId +
                                                    permission.profileId
                                                  }
                                                >
                                                  <TPCheckBox
                                                    id="IdCheckBox"
                                                    checked={
                                                      permission.profilePermission ===
                                                      1
                                                    }
                                                  />
                                                </td>
                                              );
                                            },
                                          )}
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr key={"trspacer" + group.groupId}>
                                    <td>&nbsp;</td>
                                    <td
                                      colSpan={
                                        3 +
                                        dataTransformed[0].profilePermissions
                                          .length -
                                        1
                                      }
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </TPLoadingOverlay>
      </>
    );
  },
);

export default MenuDefinitionAdmin;
