import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  ContainerFilterDivStyle,
  ContainerHorizontalFlexRowStartDivStyle,
  ContainerItemFilterDivStyle,
  FilterInputStyle,
  IcoBtnIStyle,
  IcoBtnSearchIStyle,
  IcoMenuIStyle,
} from "./StyleImageAdmin";
import { useEffect, useState } from "react";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { TPFilterAndSearch } from "@/components/TPPage/tpPageStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";

const ImagesAdminContainerFilter = () => {
  const componentFileName: string = "ImagesAdminContainerFilter.tsx";
  const resourceSet: string = "ImagesAdmin";

  //Label
  const [title, setTitle] = useState("");
  const [titleOrderBy, setTitleOrderBy] = useState("");
  const [titleFilterTYpe, setTitleFilterTYpe] = useState("");

  const [filter, setFilter] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("All");
  const value = useAppConctextImageAdmin();
  const {
    orderBy,
    optionList,
    optionStatusList,
    sortAscendingOrDescending,
    viewListAndGrid,
    handleSortView,
    handleSortAscendingOrDescending,
    handleSortFolderActive,
    handleOrderBy,
    handleFilter,
  } = value;

  useEffect(() => {
    loadResourcesAndOrganizations();
  }, []);

  const loadResourcesAndOrganizations = async () => {
    setTitle(await TPI18N.GetText(resourceSet, "Title"));
    setTitleOrderBy(await TPI18N.GetText(resourceSet, "TitleOrderBy"));
    setTitleFilterTYpe(await TPI18N.GetText(resourceSet, "TitleFilterTYpe"));
  };

  const handleOnchangeOrderValue = (e: any, value: Array<TPKeyValue>) => {
    handleOrderBy(e.target.value);
  };

  const handleOnchangeStatusValue = (e: any, value: Array<TPKeyValue>) => {
    setSelectedStatus(e.target.value);
    handleSortFolderActive(e.target.value);
  };

  return (
    <div>
      <ContainerFilterDivStyle>
        <ContainerHorizontalFlexRowStartDivStyle>
          {title}
        </ContainerHorizontalFlexRowStartDivStyle>
        <ContainerHorizontalFlexRowStartDivStyle>
          <TPSelect
            id="IdSelect3"
            labelText={titleOrderBy + " :"}
            onChange={handleOnchangeOrderValue}
            dataSource={optionList}
            value={orderBy}
            isMandatory={false}
            errorMessage={""}
            size={1}
                      multiple={false}
                      containerStyle={{ display: "flex", alignItems: "center" }}
          ></TPSelect>
          <IcoBtnIStyle
            onClick={() => {
              handleSortAscendingOrDescending(!sortAscendingOrDescending);
            }}
            className={
              !sortAscendingOrDescending
                ? "bi-sort-alpha-down"
                : "bi bi-sort-alpha-up"
            }
          ></IcoBtnIStyle>
          <IcoBtnIStyle
            onClick={() => {
              handleSortView(!viewListAndGrid);
            }}
            className={
              !viewListAndGrid ? "bi bi-grid-3x3-gap" : "bi bi-list-task"
            }
          ></IcoBtnIStyle>

          <ContainerItemFilterDivStyle>
            <FilterInputStyle
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            ></FilterInputStyle>
            <IcoBtnSearchIStyle
              onClick={() => {
                handleFilter(filter);
              }}
              className="bi bi-search"
            ></IcoBtnSearchIStyle>
          </ContainerItemFilterDivStyle>

          <TPSelect
                      id="IdSelect2"
                      labelText={titleFilterTYpe + " :"}
                      onChange={handleOnchangeStatusValue}
                      dataSource={optionStatusList}
                      value={selectedStatus}
                      isMandatory={false}
                      errorMessage={""}
                      size={1}
                      multiple={false}
                      containerStyle={{ display: "flex", alignItems:"center"  }}
          ></TPSelect>
        </ContainerHorizontalFlexRowStartDivStyle>
      </ContainerFilterDivStyle>
    </div>
  );
};

export default ImagesAdminContainerFilter;
