import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { FC, useEffect, useState } from "react"
import NoDataImg from '@/assets/images/TPImages/tp-image-grid.png';
import { useScriptsLabels } from "../../Assets/labelling";

interface ScriptDiagramTab {
  saveCallback: Function;
  scriptId: string;
}

export const ScriptDiagramTab: FC<ScriptDiagramTab> = function ({ saveCallback, scriptId }) {
  const [diagram, setDiagram] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { labels } = useScriptsLabels();

  const getDiagram = function () {
    if (!scriptId || loading) return;
    setLoading(true);
    ScriptsService.getDiagram(scriptId, false, true, [200])
      .then((d) => d && setDiagram(d))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getDiagram();
  }, [])

  return (
    <TPLoadingOverlay active={loading}>
      <div
          style={{
            width: "100%",
            height: "512px",
            display: "flex",
            alignItems: "center",
          justifyContent: "flex-start",
            flexDirection: "column",
          gap: "32px",
          padding: "32px"
          }}
        >
        <img src={diagram ? String("data:image/png;base64,").concat(diagram) : NoDataImg} />
        {!Boolean(diagram) && labels.NoDiagram}
      </div>
    </TPLoadingOverlay>
  )
}