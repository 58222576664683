import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import { CasesUserViewModel } from "@/models/Cases/CasesUserViewModel";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { UserViewModel } from "@/models/Users/UserModels";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import FileSaver from "file-saver";
import React, { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { tableStyles } from "../FormDesigner/StyleFromDesigner";
import { exportToCSV } from "@/helpers/ExportToCSV";

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<UserViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface properties {
  currentUser: any;
  newUser: any;
  callback: Function;
}

interface ISelectedRows {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: CasesUserViewModel[] | never[];
}

const ReassignUsersTasksComponent: React.FC<properties> = ({
  currentUser,
  newUser,
  callback,
}) => {
  const componentFileName: string = "ReassignUsersTasksComponent.tsx";
  const resourceTasksTable = "ReassignUsersTasksTableComponent";
  const resourceTasksModal = "ReassignUsersTasksModalComponent";

  const casecontext: any = React.useContext(TPCaseViewerContext);

  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

  const [searchLabel, setSearchLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [modalActions, setModalActions] = useState(false);

  const [selectedRecordsLabel, setSelectedRecordsLabel] = useState("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
  const [recordsFailedLabel, setRecordsFailedLabel] = useState("");

  const [reassignUserButtonDisabled, setReassignUserButtonDisabled] =
    useState(true);

  const [reassignButtonLabel, setReassignButtonLabel] = useState("");

  const [statusColumnLabel, setStatusColumnLabel] = useState("");
  const [customStateColumnLabel, setCustomStateColumnLabel] = useState("");
  const [caseNumberColumnLabel, setCaseNumberColumnLabel] = useState("");
  const [customerTypeColumnLabel, setCustomerTypeColumnLabel] = useState("");
  const [customerColumnLabel, setCustomerColumnLabel] = useState("");
  const [commentsColumnLabel, setCommentsColumnLabel] = useState("");
  const [organizationRelationColumnLabel, setOrganizationRelationColumnLabel] =
    useState("");
  const [classifier1ColumnLabel, setClassifier1ColumnLabel] = useState("");
  const [classifier2ColumnLabel, setClassifier2ColumnLabel] = useState("");
  const [classifier3ColumnLabel, setClassifier3ColumnLabel] = useState("");
  const [classifier4ColumnLabel, setClassifier4ColumnLabel] = useState("");
  const [classifier5ColumnLabel, setClassifier5ColumnLabel] = useState("");
  const [insertDateColumnLabel, setInsertDateColumnLabel] = useState("");
  const [taskTypeDescriptionColumnLabel, setTaskTypeDescriptionColumnLabel] =
    useState("");
  const [caseCreatorColumnLabel, setCaseCreatorColumnLabel] = useState("");

  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");

  const loadResourcesAndUsersFilter = async () => {
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
    );
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
    );
    setStatusColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "StatusColumnLabel")
    );
    setCustomStateColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CustomStateColumnLabel")
    );
    setCaseNumberColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CaseNumberColumnLabel")
    );
    setCustomerTypeColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CustomerTypeColumnLabel")
    );
    setCustomerColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CustomerColumnLabel")
    );
    setCommentsColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CommentsColumnLabel")
    );
    setOrganizationRelationColumnLabel(
      await TPI18N.GetText(
        "ReassignUsersFunctionsTableComponent",
        "ClientServiceColumnLabel"
      )
    );
    setClassifier1ColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "Classifier1ColumnLabel")
    );
    setClassifier2ColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "Classifier2ColumnLabel")
    );
    setClassifier3ColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "Classifier3ColumnLabel")
    );
    setClassifier4ColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "Classifier4ColumnLabel")
    );
    setClassifier5ColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "Classifier5ColumnLabel")
    );
    setInsertDateColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "InsertDateColumnLabel")
    );
    setTaskTypeDescriptionColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "TaskTypeDescriptionColumnLabel")
    );
    setCaseCreatorColumnLabel(
      await TPI18N.GetText(resourceTasksTable, "CaseCreatorColumnLabel")
    );
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(resourceTasksTable, "ThereAreNoRecordsToShowLabel")
    );
    setModalSuccessTitle(
      await TPI18N.GetText(resourceTasksModal, "SuccessTitleLabel")
    );
    setReassignButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReassignButtonLabel")
    );
    setSelectedRecordsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectedRecordsLabel")
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsProcessedLabel")
    );
    setRecordsFailedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsFailedLabel")
    );
  };

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [data, setData] = useState<UserViewModel[]>([]);
  const [filteredData, setFilteredData] = useState<UserViewModel[]>([]);

  const [tasksSelectedRows, setTasksSelectedRows] = useState<ISelectedRows>({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    setReassignUserButtonDisabled(
      !newUser || tasksSelectedRows.selectedCount == 0
    );
  }, [newUser, tasksSelectedRows]);

  const setupGridColumns = (prevState: AdminStateType) => {
    try {
      let newState: AdminStateType;
      newState = { ...prevState };
      let newColumns: Array<any> = [];

      //name primary language
      newColumns.push({
        name: statusColumnLabel,
        selector: (row: { [x: string]: any }) => row["status"],
        cell: (row: { [x: string]: any }) =>
          row["taskStatusLocalizedDescription"],
        sortable: true,
      });

      //name login
      newColumns.push({
        name: customStateColumnLabel,
        selector: (row: { [x: string]: any }) => row["customState"],
        cell: (row: { [x: string]: any }) =>
          row["customStateLocalizedDescription"],
        sortable: true,
      });

      //initials login
      newColumns.push({
        name: caseNumberColumnLabel,
        selector: (row: { [x: string]: any }) => row["caseNumber"],
        cell: (row: { [x: string]: any }) => (
          <div
            onClick={() =>
              casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
            }
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {row["caseId"].toString()}
          </div>
        ),
        sortable: true,
      });

      //Calendar
      newColumns.push({
        name: customerTypeColumnLabel,
        selector: (row: { [x: string]: any }) => row["customerType"],
        cell: (row: { [x: string]: any }) =>
          row["customerTypeLocalizedDescription"],
        sortable: true,
      });

      //TimeZone
      newColumns.push({
        name: customerColumnLabel,
        selector: (row: { [x: string]: any }) => row["customer"],
        cell: (row: { [x: string]: any }) => row["clientName"],
        sortable: true,
      });

      //Authentication Method
      newColumns.push({
        name: commentsColumnLabel,
        selector: (row: { [x: string]: any }) => row["comments"],
        cell: (row: { [x: string]: any }) => row["comments"],
        sortable: true,
      });

      //isActive
      newColumns.push({
        name: organizationRelationColumnLabel,
        selector: (row: { [x: string]: any }) => row["organizationRelation"],
        cell: (row: { [x: string]: any }) =>
          row["baseLevelLocalizedDescription"],
        sortable: true,
      });

      newColumns.push({
        name: classifier1ColumnLabel,
        selector: (row: { [x: string]: any }) => row["Classifier1"],
        cell: (row: { [x: string]: any }) => row["classify1BranchId"],
        sortable: true,
      });

      newColumns.push({
        name: classifier2ColumnLabel,
        selector: (row: { [x: string]: any }) => row["Classifier2"],
        cell: (row: { [x: string]: any }) => row["classify2BranchId"],
        sortable: true,
      });

      newColumns.push({
        name: classifier3ColumnLabel,
        selector: (row: { [x: string]: any }) => row["Classifier3"],
        cell: (row: { [x: string]: any }) => row["classify3BranchId"],
        sortable: true,
      });

      newColumns.push({
        name: classifier4ColumnLabel,
        selector: (row: { [x: string]: any }) => row["Classifier4"],
        cell: (row: { [x: string]: any }) => row["classify4BranchId"],
        sortable: true,
      });

      newColumns.push({
        name: classifier5ColumnLabel,
        selector: (row: { [x: string]: any }) => row["Classifier5"],
        cell: (row: { [x: string]: any }) => row["classify5BranchId"],
        sortable: true,
      });

      newColumns.push({
        name: insertDateColumnLabel,
        selector: (row: { [x: string]: any }) => row["insertDate"],
        sortable: true,
      });

      newColumns.push({
        name: taskTypeDescriptionColumnLabel,
        selector: (row: { [x: string]: any }) => row["taskTypeDescription"],
        cell: (row: { [x: string]: any }) =>
          row["taskTypeLocalizedDescription"],
        sortable: true,
      });

      newColumns.push({
        name: caseCreatorColumnLabel,
        selector: (row: { [x: string]: any }) => row["caseCreator"],
        cell: (row: { [x: string]: any }) => row["caseResponsible"],
        sortable: true,
      });

      newState.gridColumns = [...newColumns];
      return newState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
      return prevState;
    }
  };

  const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
  };

  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);

  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
        break;
      case commandsEnum.setup_grid_columns:
        let newStateColumns: AdminStateType = setupGridColumns(prevState);
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
        break;
      case commandsEnum.reload_grid:
        let newStateGrid: AdminStateType;
        newStateGrid = { ...prevState };
        newStateGrid.gridData = command.payload;
        return newStateGrid;
        break;
      case commandsEnum.change_selectedFilter:
        let newStateChangeFilter: AdminStateType;
        newStateChangeFilter = { ...prevState };
        newStateChangeFilter.selectedFilter = command.payload;
        return newStateChangeFilter;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
        break;
      default:
        return prevState;
    }
  }

  const handleReassignUser = async () => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];

    let guids = [];

    for (let i = 0; i <= tasksSelectedRows.selectedRows.length - 1; i++) {
      guids.push(tasksSelectedRows.selectedRows[i].caseId);
    }

    let DTO = {
      CaseIds: guids,
      GuidUserNew: newUser.key,
      GuidUserCurrent: currentUser.key,
    };

    try {
      let responseRequest = await serviceClient.reassignTasks(
        DTO,
        false,
        true,
        expectedCodes
      );

      setModalSuccess(true);

      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      return [];
    }
  };

  const reloadDataGrid = async (selectedFilter: string) => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getCaseTasksByUserGuid(
        currentUser.key,
        false,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      callback(responseRequest.length);

      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const reloadGridCommand = () => {
    reloadDataGrid(adminState.selectedFilter)
      .then(function (result) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: result,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadGridCommand ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadGridCommand ex`);
      });
  };

  useEffect(() => {
    if (adminState.columnsAreLoaded) {
      reloadGridCommand();
    }
  }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

  const handleSearchPatternChange = (newValue: string) => {
    let command1: commandType = {
      type: commandsEnum.change_search_pattern,
      payload: newValue,
    };
    dispatchCommand(command1);
  };

  const fetchData = async function () {
    setIsLoadingScreen(true);
    const serviceClientInstance = new CaseService();
    const response = await serviceClientInstance.getCaseTasksByUserGuid(
      currentUser.key,
      false,
      false,
      [200]
    );

    setData(response);
    setFilteredData(response);
    callback(response.length);
    setIsLoadingScreen(false);
  };

  useEffect(() => {
    let searcheableColumns: Array<string> = [
      "taskStatusLocalizedDescription",
      "customStateLocalizedDescription",
      "caseId",
      "customerTypeLocalizedDescription",
      "clientName",
      "comments",
      "baseLevelLocalizedDescription",
      "classify1BranchId",
      "classify2BranchId",
      "classify3BranchId",
      "classify4BranchId",
      "classify5BranchId",
      "insertDate",
      "taskTypeLocalizedDescription",
      "caseResponsible",
    ];
    let i: number;
    let search: string;
    search = adminState.searchPattern.trim();
    setFilteredData(
      data.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]]
              ? itemany[searcheableColumns[i]]
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              : false
          ) {
            return item;
          }
        }
      })
    );
  }, [adminState.searchPattern]);

  useEffect(() => {
    fetchData();
    loadResourcesAndUsersFilter()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`
        );
      });
  }, []);

  //Run when filter is loaded to get columns
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }
  }, [adminState.filterIsLoaded]);

  const handleSelectedRowsChange = (data: ISelectedRows) => {
    setTasksSelectedRows(data);
  };

  // const exportToCSV = (
  //   apiData = filteredData,
  //   fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  // ) => {
  //   const ws = XLSX.utils.json_to_sheet(apiData);
  //   /* custom headers */
  //   XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   FileSaver.saveAs(data, fileName + ".xlsx");
  // };

  const handleClick = () => {
    const element = document.getElementsByName("select-all-rows");
    if (!tasksSelectedRows.allSelected) element[0]?.click();
    setTimeout(() => element[0]?.click(), 200);
  };

  useEffect(() => {
    if (currentUser) fetchData();
  }, [currentUser]);

  const handleFetchData = () => {
    fetchData();
    handleClick();
  };

  return (
    <>
      <TPPageFirstRow>
        <TPPageSearchContainer>
          <TPTextBox
            id="IdTextBox"
            icon={TPIconTypes.search}
            withIcon={true}
            value={adminState.searchPattern}
            placeholder={searchLabel}
            onChange={(e: any) => handleSearchPatternChange(e.target.value)}
            isHorizontal={true}
          />
        </TPPageSearchContainer>

        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() => exportToCSV(filteredData, 'reassign-users-tasks',
              [
                "baseLevelId",
                "caseStatusId",
                "closeDate",
                "customerTypeId",
                "hierarchyDescriptionClassify1",
                "hierarchyDescriptionClassify2",
                "hierarchyDescriptionClassify3",
                "hierarchyDescriptionClassify4",
                "hierarchyDescriptionClassify5",
                "lastModifiedDate",
                "promiseDate",
                "taskCreationDate",
                "taskId",
                "taskLimitDate",
                "taskStatusId",
                "taskWriterIdUSER",
                "totalRecords",
                "caseStatusLocalizedDescription",
                "taskResponsible"
              ],
              {
                taskStatusLocalizedDescription: statusColumnLabel,
                customStateLocalizedDescription: customStateColumnLabel,
                caseId: caseNumberColumnLabel,
                customerTypeLocalizedDescription: customerTypeColumnLabel,
                clientName: customerColumnLabel,
                comments: commentsColumnLabel,
                baseLevelLocalizedDescription: organizationRelationColumnLabel,
                classify1BranchId: classifier1ColumnLabel,
                classify2BranchId: classifier2ColumnLabel,
                classify3BranchId: classifier3ColumnLabel,
                classify4BranchId: classifier4ColumnLabel,
                classify5BranchId: classifier5ColumnLabel,
                insertDate: insertDateColumnLabel,
                taskTypeLocalizedDescription: taskTypeDescriptionColumnLabel,
                caseResponsible: caseCreatorColumnLabel
              }
            )}
            icon={TPIconTypes.excel}
            text={exportLabel}
          />
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() => handleFetchData()}
            text={refreshLabel}
            icon={TPIconTypes.refresh}
          />
          <TPPageAcceptCancelButtonsContainer>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={handleReassignUser}
              disabled={reassignUserButtonDisabled}
            >
              {reassignButtonLabel}
            </TPButton>
          </TPPageAcceptCancelButtonsContainer>
        </div>
      </TPPageFirstRow>
      <DataTable
        persistTableHead={true}
        fixedHeader={true}
        fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
        responsive={true}
        striped={true}
        highlightOnHover={true}
        pagination
        paginationPerPage={10}
        paginationComponentOptions={TPGlobal.paginationComponentOptions}
        columns={adminState.gridColumns}
        data={filteredData as any}
        noDataComponent={thereAreNoRecordsToShow}
        sortFunction={TPGlobal.datatableCustomSort}
        customStyles={tableStyles}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRows={true}
        selectableRowsHighlight
      />
      <TPModalSuccess
        modalState={{
          title: "success",
          yesLabel: "Ok",
          isShown: modalSuccess,
          callBackFunction: () => {
            setModalSuccess(false);
            setModalActions(false);
            fetchData();
            handleClick();
          },
        }}
      >
        <TPPageSectionTitle>{modalSuccessTitle}</TPPageSectionTitle>
        <br />
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{selectedRecordsLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
            }
          >
            {tasksSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsProcessedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "green" }}
          >
            {tasksSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsFailedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "red" }}
          >
            0
          </div>
        </div>
      </TPModalSuccess>
    </>
  );
};

export default ReassignUsersTasksComponent;
