const dataToCSV = function (data: any[], ignore?: string[], customHeaders?: {[key: string] : string}): string {
  let headers = Object.keys(data[0]);
  headers = headers.filter((header) => !ignore?.includes(header));
  if (customHeaders) {
    headers = headers.map((header) => customHeaders[header] || header);
  }
  const rows = data.map((row) =>
    headers
      .map((header) => {
        let originalKey = header;
        if (customHeaders) {
          originalKey = Object.keys(customHeaders || {}).find(
            (key) => customHeaders[key] === header
          ) || header;
        }
        return JSON.stringify(row[originalKey] || '')
      })
      .join(",")
  );

  return [headers.join(","), ...rows].join("\n");
};

export const exportToCSV = function (
  data: any[],
  filename?: string,
  ignore?: string[],
  customHeaders?: {[key: string] : string}
) {
  const csvFile = new Blob([dataToCSV(data, ignore, customHeaders)], { type: "text/csv" });
  const downloadLink = document.createElement("a");

  downloadLink.download = filename || "export.csv";
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
