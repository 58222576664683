import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

interface TagButtonProps {
  tagHandler?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: ReactNode;
}

const StyledButton = styled.button`
  border: 1px solid gray;
  background: #fff;
  padding: 5px;
  padding-bottom: 6px;
  border-radius: 0rem 0.375rem 0.375rem 0rem;
  border-left: 1px solid #dee2e6;
  border-top: none;
  border-right: none;
  border-bottom: none;

  &:focus {
    outline: none;
  }
`;

const TagButton: React.FC<TagButtonProps> = ({
  tagHandler,
  className,
  children,
}) => {
  return (
    <StyledButton className={className} onClick={tagHandler}>
      {children}
    </StyledButton>
  );
};

export default TagButton;
