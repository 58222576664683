import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import {
  Messages,
  ProfilesRolesAction,
  ProfilesRolesEnum as e,
  ProfilesRolesEvents,
  profilesRolesInitialState,
  ProfilesRolesProps,
  ProfilesRolesState,
  ProfilesRolesStateEvent,
  ProfilesRolesTab,
  ProfilesRolesTabItem,
} from "@/models/ProfilesRoles/ProfilesRoles";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import { v4 as uuidv4 } from "uuid";
import ProfilesAdmin from "./Profiles/ProfilesAdmin";
import RolesAdmin from "./Roles/RoleAdmin";
import { tab } from "@testing-library/user-event/dist/tab";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "0 20px 0 10px",
    margin: "5px 0 10px",
    gap: "20px",
  } as CSSProperties,
  nav: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    borderBottom: "1px solid #bfbfbf",
  } as CSSProperties,
  tab: {
    fontWeight: "bold",
    fontSize: "30px",
    padding: "10px 25px",
    cursor: "pointer",
    color: "#b47eaa",
    margin: "0",
  } as CSSProperties,
  selected: {
    fontSize: "35px",
    color: "#b10091",
    borderBottom: "5px solid #b10091",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of profiles and roles
 */
const ProfilesRoles = forwardRef(
  ({ callBackCommands }: ProfilesRolesProps, ref) => {
    /**
     * ATTRIBUTES
     */
    /**
     * profile table reference
     */
    const profileTableRef = useRef<ProfilesRolesEvents>();
    /**
     * role table reference
     */
    const roleTableRef = useRef<ProfilesRolesEvents>();
    /**
     * s = state
     * status of the values ​​seen in the window
     *
     * dispatch
     * function to change the state
     */
    const [s, dispatch] = useReducer(dispatchAction, profilesRolesInitialState);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          refreshGridFromParent() {
            profileTableRef.current?.refreshGridFromParent();
            roleTableRef.current?.refreshGridFromParent();
          },
        }) as ProfilesRolesEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources();
    }, []);
    /**
     * event on component close
     */
    useEffect(
      () => () => {
        dispatch({ type: ProfilesRolesStateEvent.clean });
      },
      []
    );
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * command function
     *
     * @param state current state
     * @param action action to be executed
     * @returns new state
     */
    function dispatchAction(
      state: ProfilesRolesState,
      action: ProfilesRolesAction
    ): ProfilesRolesState {
      switch (action.type) {
        case ProfilesRolesStateEvent.setMessages:
          return {
            ...state,
            m: action.payload,
          };
        case ProfilesRolesStateEvent.addDefaultTabs:
          const tabProfileId = uuidv4();

          return {
            ...state,
            focus: tabProfileId,
            tabs: [
              {
                id: tabProfileId,
                label: state.m[e.TabProfilesLabel],
                type: ProfilesRolesTab.Profiles,
              },
              {
                id: uuidv4(),
                label: state.m[e.TabRolesLabel],
                type: ProfilesRolesTab.Roles,
              },
            ],
          };
        case ProfilesRolesStateEvent.addTab:
          return {
            ...state,
            tabs: [
              ...state.tabs,
              {
                id: uuidv4(),
                label: action.payload?.label,
                icon: action.payload?.icon,
                element: action.payload?.element,
                type: action.payload?.type,
              },
            ],
          };
        case ProfilesRolesStateEvent.removeTab:
          return {
            ...state,
            tabs: state.tabs.filter((tab) => tab.id !== action.payload),
          };
        case ProfilesRolesStateEvent.changeTab:
          return {
            ...state,
            focus: action.payload,
          };
        case ProfilesRolesStateEvent.clean:
          return profilesRolesInitialState;
        default:
          return { ...state };
      }
    }
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
      const messages: Messages = {};

      messages[e.TabProfilesLabel] = "Profiles"; //await TPI18N.GetText(e.component,e.TabProfilesLabel);
      messages[e.TabRolesLabel] = "Roles"; //await TPI18N.GetText(e.component,e.TabRolesLabel);
      // messages[e.name] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.name);

      dispatch({
        type: ProfilesRolesStateEvent.setMessages,
        payload: messages,
      });
      dispatch({ type: ProfilesRolesStateEvent.addDefaultTabs });
    }
    /**
     * function responsible for updating the tab to be displayed
     *
     * @param type new tab to be displayed
     */
    function onChangeTabHandler(tabId: string) {
      dispatch({ type: ProfilesRolesStateEvent.changeTab, payload: tabId });
    }
    /**
     * get render
     */
    function getRender(tab: ProfilesRolesTabItem) {
      switch (tab.type) {
        case ProfilesRolesTab.Profiles:
          return (
            <div style={{ display: s.focus === tab.id ? "block" : "none" }}>
              <ProfilesAdmin
                callBackCommands={callBackCommands}
                ref={profileTableRef}
              />
            </div>
          );
        case ProfilesRolesTab.Roles:
          return (
            <div style={{ display: s.focus === tab.id ? "block" : "none" }}>
              <RolesAdmin
                callBackCommands={callBackCommands}
                ref={roleTableRef}
              />
            </div>
          );
        default:
          return <div>Page not found</div>;
      }
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={`${e.name}Container`}
        key={`${e.name}Container`}
        style={styles.container}
      >
        <div id={`${e.name}NavBar`} key={`${e.name}NavBar`} style={styles.nav}>
          {s.tabs.map((tab) => (
            <h2
              id={`${e.name}Tab${tab.type}${tab.id}`}
              key={`${e.name}Tab${tab.type}${tab.id}`}
              style={{
                ...styles.tab,
                ...(s.focus === tab.id ? styles.selected : {}),
              }}
              onClick={() => onChangeTabHandler(tab.id)}
            >
              {tab.label}
            </h2>
          ))}
        </div>

        <div>{s.tabs.map((tab) => getRender(tab))}</div>
      </div>
    );
  }
);

export default ProfilesRoles;
