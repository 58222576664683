import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { camelCaseToPhrase } from "../../utils/text-regex";
import { Short } from "./DynamicTable";
import { SortItemStyles } from "./dynamic-table-styles";

type SortTableItemProps = {
  itemName: string;
  direction: Short | null;
  isCustomColumns: boolean;
  handleClick: () => void;
};

function SortTableItem({
  itemName,
  direction,
  isCustomColumns,
  handleClick,
}: SortTableItemProps): ReactElement {
  const getIconType = () => {
    return direction === null ? "dash" : "arrowDropUp";
  };

  return (
    <SortItemStyles direction={direction} onClick={handleClick}>
      {isCustomColumns ? itemName : camelCaseToPhrase(itemName)}
      <TPIcon iconType={TPIconTypes[getIconType()]} />
    </SortItemStyles>
  );
}

export default SortTableItem;
