import {
  DataTableContainer,
  TableContainer,
  TableHeaderContainer,
  TableSearchContainer,
  tableStyles,
  TableToolbar,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { UserGroupInputDTO } from "@/models/UserGroups/UserGroupInputDTO";
import { UserGroupViewModel } from "@/models/UserGroups/UserGroupModels";
import { TPI18N } from "@/services/I18nService";
import { UserGroupService } from "@/services/UserGroupService";
import { GroupsService } from "@/services/GroupsService";
import React, { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";

interface GroupsAdminInterface {
  groupId: number;
  groupDescription: string;
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum commandsEnum {
  "change_selectedFilter" = 1,
  "set_griddata" = 2,
}

type AdminStateType = {
  selectedFilter: string;
  gridData: Array<UserGroupViewModel>;
};
const AssignUserToGroup = React.forwardRef(
  ({ groupId, groupDescription }: GroupsAdminInterface, ref) => {
    const componentFileName: string = "AssignUserToGroup.tsx";
    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "AssignUserToGroupComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubtitleLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    const [userLoginColumnLabel, setLoginColumnLabel] = useState("");
    const [userNameColumnLabel, setUserNameColumnLabel] = useState("");
    //end resources

    //State filter dropdown
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    const [assignToUserGroup, setAssignToUserGroup] = useState(false);
    const [searchPattern, setSearchPattern] = useState("");
    const [canSetupColumns, setCanSetupColumns] = useState(false);
    const [gridColumns, setGridColumns] = useState<Array<any>>([]);

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    const [groupIdDisplay, setGroupIdDisplay] = useState("");
    const [descriptionDisplay, setDescriptionDisplay] = useState("");

    const loadResources = async () => {
      setLoginColumnLabel(
        await TPI18N.GetText(resourceSet, "LoginColumnLabel"),
      );
      setUserNameColumnLabel(
        await TPI18N.GetText(resourceSet, "UserNameColumnLabel"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );

      setTitleLabel(await TPI18N.GetText(resourceSet, "EditGroupTitleLabel"));
      setSubtitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "NoAsigned"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Asigned"),
      });

      setFilterKeyValue(newFilterKeyValue);

      setCanSetupColumns(true);
    };

    const setupGridColumns = () => {
      try {
        let newColumns: Array<any> = [];

        //Login
        newColumns.push({
          name: userLoginColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["user"]["login"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["login"],
          sortable: true,
          width: "350px",
        });

        //Login
        newColumns.push({
          name: userNameColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["user"]["name"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["name"],
          sortable: true,
          width: "350px",
        });

        setGridColumns(newColumns);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
      }
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "login", "name"];
      let i: number;
      let search: string;
      search = searchPattern.trim();
      return adminState.gridData.filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany.user[searcheableColumns[i]] &&
            itemany.user[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(({ result, assignedUsers }) => {
          const newGridData = result.map((item: UserGroupViewModel) => {
            const existingItem = adminState.gridData.find(
              (prevItem: UserGroupViewModel) =>
                prevItem.user.userGuid === item.user.userGuid,
            );
            if (existingItem) {
              item.tpselectedRow = existingItem.tpselectedRow;
            } else {
              // Ensure tpselectedRow is set based on filter
              if (
                adminState.selectedFilter === TPActiveOptions.ALL.toString()
              ) {
                const assigned = assignedUsers.find(
                  (assignedItem: UserGroupViewModel) =>
                    assignedItem.user.userGuid === item.user.userGuid,
                );
                item.tpselectedRow = !!assigned;
              } else {
                item.tpselectedRow =
                  adminState.selectedFilter ===
                  TPActiveOptions.ACTIVE.toString();
              }
            }
            return item;
          });

          newGridData.sort((a, b) => a.user.login.localeCompare(b.user.login));

          dispatchCommand({
            type: commandsEnum.set_griddata,
            payload: { newGridData },
          });
        })
        .catch((error) => {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const getById = async () => {
      let serviceClient = new GroupsService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getGroupById(
          groupId.toString(),
          false,
          true,
          expectedCodes,
        );
        setGroupIdDisplay(responseRequest.user.login);
        setDescriptionDisplay(responseRequest.user.name);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getById ex`);
        setIsLoadingScreen(false);
      }
    };

    //Get organizations relations by Filter
    const reloadDataGrid = async (
      selectedFilter: string,
    ): Promise<{
      result: UserGroupViewModel[];
      assignedUsers: UserGroupViewModel[];
    }> => {
      const serviceClient = new UserGroupService();
      const expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest: Array<UserGroupViewModel> = [];
        let assignedUsers: Array<UserGroupViewModel> = [];
        switch (selectedFilter) {
          case TPActiveOptions.INACTIVE.toString(): // Not Assigned
            responseRequest = await serviceClient.getAllNoGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            break;
          case TPActiveOptions.ACTIVE.toString(): // Assigned
            responseRequest = await serviceClient.getAllGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            assignedUsers = responseRequest;
            break;
          case TPActiveOptions.ALL.toString(): // All
            const notAssigned = await serviceClient.getAllNoGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            assignedUsers = await serviceClient.getAllGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            responseRequest = [...notAssigned, ...assignedUsers];
            break;
        }
        setIsLoadingScreen(false);
        return { result: responseRequest, assignedUsers };
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return { result: [], assignedUsers: [] };
      }
    };

    const handleRowClick = async (row: any, event: any) => {
      //only one row selected policy
      let i: number;
      let isChecked: boolean = false;
      let newGridData: Array<any> = [...adminState.gridData];
      for (i = 0; i <= newGridData.length - 1; i++) {
        if (row.user.userGuid === newGridData[i].user.userGuid) {
          isChecked = !newGridData[i].tpselectedRow;
        }
      }

      if (isChecked) {
        let recordInputDTO: UserGroupInputDTO = {
          groupId: +groupId,
          guid_USER: row.user.userGuid,
        };
        if (await addUserToCurrentGroup(recordInputDTO)) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      } else {
        if (await removeUserFromCurrentGroup(row.user.userGuid)) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      }

      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: newGridData },
      };
      dispatchCommand(command1);
    };

    const addUserToCurrentGroup = async (inputDTO: UserGroupInputDTO) => {
      let serviceClient = new UserGroupService();
      let expectedCodes: Array<number> = [200];
      try {
        //setIsLoadingScreen(true);

        let responseRequest = await serviceClient.insert(
          inputDTO,
          false,
          true,
          expectedCodes,
        );
        //setIsLoadingScreen(false);
        return responseRequest.responseResult;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} insertFunction ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} insertFunction ex`);
        //setIsLoadingScreen(false);
      }
    };

    const removeUserFromCurrentGroup = async (userGuid: string) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new UserGroupService();
      try {
        //setIsLoadingScreen(true);
        let responseRequest = await serviceClient.delete(
          groupId.toString(),
          userGuid,
          false,
          true,
          expectedCodes,
        );
        //setIsLoadingScreen(false);
        return responseRequest.responseData.responseCode !== 500;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} removeUserFromCurrentGroup ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} removeUserFromCurrentGroup ex`,
        );
        //setIsLoadingScreen(false);
      }
    };

    const onSearchPatternChange2 = (newValue: string) => {
      setSearchPattern(newValue);
    };

    const onColumnSort = (columnSelector: any, direction: string) => {
      let sortedGridData: Array<any>;
      sortedGridData = TPGlobal.datatableCustomSort(
        adminState.gridData,
        columnSelector,
        direction,
      );
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: sortedGridData },
      };
      dispatchCommand(command1);
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridData: [],
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      let i: number;
      switch (command.type) {
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.set_griddata:
          let oldSelectedfilter = { ...prevState };
          let newGridData: Array<any> = [...command.payload.newGridData];
          let newAdminState: AdminStateType = {
            gridData: newGridData,
            selectedFilter: oldSelectedfilter.selectedFilter,
          };
          return newAdminState;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResources();
      getById();
    }, []);

    //user change filter
    useEffect(() => {
      reloadGridCommand();
    }, [adminState.selectedFilter]);

    useEffect(() => {
      if (canSetupColumns) {
        setupGridColumns();
      }
    }, [canSetupColumns]);
    return (
      <>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <TPPageSubTitle>
                {`${subTitleLabel} ${groupIdDisplay} / ${descriptionDisplay}`}
                {/* {`${subTitleLabel} ${groupId} / ${insertUpdateState.userGroupDescription}`} */}
              </TPPageSubTitle>
              <hr />
            </div>
          </div>
          <TPPageFirstRow>
            <TPFilterAndSearch>
              <TPPageFilterContainer>
                <div className="form-group">
                  <TPSelect
                    id="IdSelect"
                    onChange={handleFilterChange}
                    dataSource={filterKeyValue}
                    value={adminState.selectedFilter}
                    labelText={filterIsActiveLabel}
                    isHorizontal={false}
                  ></TPSelect>
                </div>
              </TPPageFilterContainer>
            </TPFilterAndSearch>
          </TPPageFirstRow>
          <TableHeaderContainer>
            <TableToolbar></TableToolbar>
            <TableSearchContainer></TableSearchContainer>
          </TableHeaderContainer>
          <TPFixedTable
            keyField={"tpguid"}
            columns={gridColumns}
            dataGrid={filteredData()}
            tableHeight={`${fixedHeaderScrollHeight}px`}
            onRowClicked={(row: any, event: any) => {
              handleRowClick(row, event);
            }}
            searchLabel={searchLabel}
            onSearchChange={onSearchPatternChange2}
            sortFunction={onColumnSort}
            highlightOnHover={true}
            selectableRows={true}
            multipleSelection={false}
          />
          {/* <div className="row">
            <div className="col">
              <TableContainer>
                <TableHeaderContainer>
                  <TableToolbar></TableToolbar>
                  <TableSearchContainer></TableSearchContainer>
                </TableHeaderContainer>
                <DataTableContainer>
                  <DataTable
                    fixedHeader={true}
                    persistTableHead={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    dense={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div> */}
        </TPLoadingOverlay>
      </>
    );
  },
);

export default AssignUserToGroup;
