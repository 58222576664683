import { SequenceGeneratorSequencesNameEnum } from "@/models/Global/TPGlobalEnums";
import { SequenceService } from "@/services/SequenceService";

export const generateId = async function(sequence: SequenceGeneratorSequencesNameEnum): Promise<string> {
  try {
    const sequenceServiceInstance = new SequenceService();
		const response = await sequenceServiceInstance.generalAutomaticId(false, false, [200], sequence);
    return response.responseData.data[0].sequenceCode || "";
  }
  catch (err) {
    console.log(err);
    throw err;
  }
}