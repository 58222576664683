import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { AdditionalDataFormViewModel } from "@/models/AdditionalDataForm/AdditionalDataFormModel";
import {
  FormCloneInputDTO,
  FormesignerInputDTO,
} from "@/models/FormDesigner/FormDesigner";

export class AdditionalDataFormService {
  serviceFileName: string = "AdditionalDataFormService.ts";

  public async filter(
    params?: any
  ): Promise<Array<AdditionalDataFormViewModel>> {
    const { getData } = new TPHTTPService();
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let url = `additional-data-form`;
    if (params) {
      url += "?";
      url += Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    }
    const finalURL = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let response: HTTPResponse;
    try {
      response = await getData(finalURL, [200], false, false, headers);
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} filter ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} filter`);
      throw new Error(`Error ${this.serviceFileName} filter`);
    }
  }

  public async getByFiltersIsActive(
    filterActive: string,
    formTypeId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<AdditionalDataFormViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additional-data-form/get-by-filters?FilterIsActive=${filterActive}&formTypeId=${formTypeId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} GetByFiltersIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} GetByFiltersIsActive`);
      throw new Error(`Error ${this.serviceFileName} GetByFiltersIsActive`);
    }
  }

  public async insertFormDesingner(
    inputDTO: FormesignerInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additional-data-form";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertFormDesingner ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} insertFormDesingner`);
      throw new Error(`Error ${this.serviceFileName} insertFormDesingner`);
    }
  }

  public async updateFormDesingner(
    inputDTO: FormesignerInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additional-data-form";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateFormDesingner ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} updateFormDesingner`);
      throw new Error(`Error ${this.serviceFileName} updateFormDesingner`);
    }
  }

  public async clone(
    inputDTO: FormCloneInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additional-data-form/clone";
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} clone ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} update`);
      throw new Error(`Error ${this.serviceFileName} update`);
    }
  }

  public async getFormDesingnerById(
    formId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additional-data-form/";
    url = url + formId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionAssignmentById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionAssignmentById`);
      throw new Error(
        `Error ${this.serviceFileName} getFunctionAssignmentById`
      );
    }
  }

  public async deleteFormDesingnerById(
    Id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/additional-data-form/" + Id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteFunctionAssignmentById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${this.serviceFileName} deleteFunctionAssignmentById`
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteFunctionAssignmentById`
      );
    }
  }

  public async execExport(
    id: string,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = false,
    expectedCodes: Array<number> = [200]
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/additional-data-form/export/${id}`;
    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} execExportt ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} execExportt`);
      throw new Error(`Error ${this.serviceFileName} execExportt`);
    }
  }

  public async execImport(
    element: any,
    showPositiveMessage: boolean = true,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200, 404]
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/additional-data-form/import`;
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        url,
        element,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} execImportt ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} execImportt`);
      throw new Error(`Error ${this.serviceFileName} execImportt`);
    }
  }
}
