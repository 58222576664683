import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { StyledDefaultPager } from "../design-system-styles";

export interface DefaultPagerProps {
  id?: string;
  currentPage: number;
  totalPages: number;
  previousLabel: string;
  nextPageLabel: string;
  onPageChange: (page: number) => void;
}

function DefaultPager({
  id,
  currentPage,
  totalPages,
  previousLabel,
  nextPageLabel,
  onPageChange,
}: DefaultPagerProps): ReactElement {
  const getPagerButtonId = (orientation: string) => {
    return `${id && `${id}-`}${orientation}-pager-button`;
  };

  const generatePageButtons = (): ReactElement[] => {
    const buttons: ReactElement[] = [];

    if (totalPages <= 0) {
      buttons.push(
        <button key={1} className={"contained"}>
          1
        </button>,
      );
      return buttons;
    }

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            className={i === currentPage ? "contained" : "outlined"}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>,
        );
      }

      return buttons;
    } else {
      buttons.push(
        <button
          key={1}
          className={1 === currentPage ? "contained" : "outlined"}
          onClick={() => onPageChange(1)}
        >
          1
        </button>,
      );

      if (currentPage > 4) {
        buttons.push(<Typography key="ellipsis-start">...</Typography>);
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button
            key={i}
            className={i === currentPage ? "contained" : "outlined"}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>,
        );
      }

      if (currentPage < totalPages - 3) {
        buttons.push(<Typography key="ellipsis-end">...</Typography>);
      }

      buttons.push(
        <button
          key={totalPages}
          className={totalPages === currentPage ? "contained" : "outlined"}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </button>,
      );
    }

    return buttons;
  };

  return (
    <StyledDefaultPager>
      {generatePageButtons()}
      <button
        id={getPagerButtonId("previous")}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1 || totalPages <= 0}
      >
        {previousLabel}
      </button>
      <button
        id={getPagerButtonId("next")}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages || totalPages <= 0}
      >
        {nextPageLabel}
      </button>
    </StyledDefaultPager>
  );
}

export default DefaultPager;
