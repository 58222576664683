import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { transpileModule } from "typescript";

export type ContactInputDTO = {
  contactId: number;
  clientId: number;
  subsidiaryId: number | null;
  name: string;
  relationshipId: string;
  phone: string;
  email: string;
  address: string;
  comments: string;
};

export class ContactInputDTOValidator extends Validator<ContactInputDTO> {
  constructor() {
    super();
    const resourceSet: string = "ContactInsertUpdateComponent";

    this.ruleFor("name")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTONameEmpty")
      .must((name) => {
        return TPGlobal.TPSanitize(name) == name;
      })
      .withMessage(resourceSet + "|InputDTOInvalidName")
      .maxLength(200)
      .withMessage(resourceSet + "|InputDTOInvalidNameLength");

    this.ruleFor("relationshipId")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOContactTypeEmpty")
      .must((id) => {
        let check = TPGlobal.checkSpecialCharacters(id);
        return check.result;
      })
      .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
      .must((relationshipId) => {
        return TPGlobal.TPSanitize(relationshipId) == relationshipId;
      })
      .withMessage(resourceSet + "|InputDTOInvalidContactType")
      .maxLength(20)
      .withMessage(resourceSet + "|InputDTOInvalidContactTypeLength");

    this.ruleFor("phone")
      //.notEmpty()
      //.withMessage(resourceSet + "|InputDTOPhoneEmpty")
      .must((phone) => {
        return TPGlobal.TPSanitize(phone) == phone;
      })
      .withMessage(resourceSet + "|InputDTOInvalidPhone")
      .maxLength(100)
      .withMessage(resourceSet + "|InputDTOInvalidPhoneLength");

    this.ruleFor("email")
      //.notEmpty()
      //.withMessage(resourceSet + "|InputDTOEmailEmpty")
      .must((email) => {
        return TPGlobal.TPSanitize(email) == email;
      })
      .withMessage(resourceSet + "|InputDTOInvalidEmail")
      .maxLength(200)
      .withMessage(resourceSet + "|InputDTOInvalidEmailLength")
      .must((value) => {
        if (value.trim() === "") {
          return true;
        }
        if (!TPGlobal.regularExpressionCheckEmail.test(value)) {
          return false;
        } else {
          return true;
        }
      })
      .withMessage(resourceSet + "|InputDTOInvalidEmail");

    this.ruleFor("address")
      //.notEmpty()
      //.withMessage(resourceSet + "|InputDTOAddressEmpty")
      .must((address) => {
        return TPGlobal.TPSanitize(address) == address;
      })
      .withMessage(resourceSet + "|InputDTOInvalidAddress")
      .maxLength(200)
      .withMessage(resourceSet + "|InputDTOInvalidAddressLength");

    this.ruleFor("comments")
      .must((comments) => {
        return TPGlobal.TPSanitize(comments) == comments;
      })
      .withMessage(resourceSet + "|InputDTOInvalidComments")
      .maxLength(5000)
      .withMessage(resourceSet + "|InputDTOInvalidCommentsLength");
  }
}
