import { ReactNode, createContext, useContext, useEffect } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { useStatusDistributionListAdmin } from "./useStatusDistributionListAdmin";



type AppProviderDistributionListAdmin = {
  children: ReactNode;
};

class model {
  status: Array<any> = new Array<any>;
  resourceSet:string ="";
  hotdataList:Array<Array<any>> = [['','','']];
  loading:boolean = false;
  setChanged:(data:number)=> void = (data:number)=> void{};
  changed:number =0;
  setLoading:(data:boolean) => void =(data:boolean) => void{};
  setHotDataList: (data:Array<Array<any>>) => void = (data:Array<Array<any>>) => void {};
}

type modelContext = {
  status: Array<any>;
  resourceSet:string;
  hotdataList:Array<Array<any>>;
  loading:boolean,
  setChanged:(data:number)=> void;
  changed:number;
  setLoading:(data:boolean) => void;
  setHotDataList: (data:Array<Array<any>>) => void;

  
}

export const DistributionListAdminContext = createContext<modelContext>(
  new model());

export const AppProviderDistributionListAdmin: React.FC<AppProviderDistributionListAdmin> = ({ children }) => {

  const {
    status,
    resourceSet,
    hotdataList,
    loading,
    setChanged,
    changed,
    setLoading,
    setHotDataList
   
  } = useStatusDistributionListAdmin();


  useEffect(() => {
  }, []);



  return (
    <TPLoadingOverlay active={loading}>
      <DistributionListAdminContext.Provider value={{
        status,     
        resourceSet,  
        hotdataList,
        loading,
        setChanged,
        changed,
        setLoading,
        setHotDataList
      }}>
        {children}
      </DistributionListAdminContext.Provider>
    </TPLoadingOverlay>
  );
};


export const useAppDistributionListAdmin = () => {
  return useContext(DistributionListAdminContext);
}