import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface AttachmentInputDTO {
  caseId: number | null;
  blobId: string;
  filename: string;
  extensionName: string;
  sender: string;
  description: string;
  guid_USER: string;
  fileData: any;
  [key: string]: any;
}

export class AttachmentInputDTODTOValidator extends Validator<AttachmentInputDTO> {
  /**
   *
   */
  constructor() {
    super();
    const resourceSet = "AttachmentInsertUpdateComponent";

    this.ruleFor("blobId")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOBlobIdEmpty")
      .maxLength(150)
      .withMessage(resourceSet + "|InputDTOInvalidBlobIdLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidBlobId");

    this.ruleFor("filename")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOFilenameEmpty")
      .maxLength(500)
      .withMessage(resourceSet + "|InputDTOInvalidFilenameLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidFilename");

    this.ruleFor("extensionName")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOExtensionNameEmpty")
      .maxLength(10)
      .withMessage(resourceSet + "|InputDTOInvalidExtensionNameLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidExtensionName");

    this.ruleFor("sender")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOSenderEmpty")
      .maxLength(500)
      .withMessage(resourceSet + "|InputDTOInvalidSenderLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidSender");

    this.ruleFor("description")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTODescriptionEmpty")
      .maxLength(500)
      .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidDescription");

    this.ruleFor("guid_USER")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOGuidUserEmpty")
      .maxLength(50)
      .withMessage(resourceSet + "|InputDTOInvalidGuidUserLength")
      .must((item) => {
        return TPGlobal.TPSanitize(item) == item;
      })
      .withMessage(resourceSet + "|InputDTOInvalidGuidUser");

    this.ruleFor("fileData")
      .must((item) => {
        return item.size > 0;
      })
      .withMessage(resourceSet + "|algunacosa");
  }
}
