import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailRuleInputDTO } from "@/models/InboundMailRule/InboundMailRuleDefinitionInputDTO";

export class InboundMailRuleService {
  serviceFileName: string = "InboundMailRuleService.ts";

  public async updateInboundMailRulesByMailboxId(
    inputDTO: InboundMailRuleInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailboxrule/update-rules-by-mailboxid";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateInboundMailRulesByMailboxId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateInboundMailRulesByMailboxId`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateInboundMailRulesByMailboxId`,
      );
    }
  }
}
