export interface GroupTakeNextGroupModel {
  groupId: number | string;
  description: string;
  ready: number;
  processing: number;
  priorityWeight: number;
  inQueue: number;
  groupType: string;
  ticket: TakeNextTicket;
  available?: boolean;
  start?: any;
  projectId: string;
  projectDescription: string;
}

export interface GroupTakeNextResponseModel {
  caseId: string;
  userId: string;
  taskId: string;
}

export interface UsersReadyByGroupModel {
  groupId: number;
  usersReady: number;
}

export interface FishCaseModel {
  userId: string;
  usersReadyByGroup: UsersReadyByGroupModel[];
  orderType: string;
}

export interface GroupTakeNextMomentInsertModel {
  userId: string;
  sortOrder: "case";
  isReady: boolean;
}

export interface GroupTakeNextLocalizationModel {
  LanguageId: string;
  LocalizaedValue: string;
}
export interface GroupTakeNextInsertStatisticsModel {
  userId: string;
  isReady: boolean;
  usersReadyByGroup: UsersReadyByGroupModel[];
}

export enum TakeNextOrder {
  case = "case",
  task = "task"
}

export enum TakeNextGroupType {
  'TAKENEXT' = 'TAKENEXT',
  'FRONTOFFICE' = 'FRONTOFFICE',
  'BACKOFFICE' = 'BACKOFFICE'
}

export enum TakeNextTicket {
  case,
  event
}