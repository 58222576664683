import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
  TPGenAIInsertTrackingModel,
  TPGenAIKeyPointsRequest,
  TPGenAIKeyPointsResponse,
  TPGenAIKeyPointsUpdateModel,
  TPGenAIMail2SummaryRequest,
  TPGenAISummaryResponse,
} from "@/models/TPGenAI/TPGenAIModel";

export class TPGenAIService {
  serviceFileName: string = "TPGenAIService.ts";

  public async processMailToKeyPoints(
    requestBody: TPGenAIKeyPointsRequest,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<TPGenAIKeyPointsResponse> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/tpgenai/mail2keypoints";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const utf8Request: TPGenAIKeyPointsRequest = {
      Email: TPGlobal.stringToUTF8(requestBody.Email).join(','),
      Keypoints: TPGlobal.stringToUTF8(requestBody.Keypoints).join(','),
      Language: TPGlobal.stringToUTF8(requestBody.Language).join(','),
    }

    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        utf8Request,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return {
        items: response.responseData.data,
      };
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get TPGenAI response: ${error}`,
      );
    }
  }

  public async updateMailKeypoints(
    requestBody: TPGenAIKeyPointsUpdateModel,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/inboundmailboxread/tpgenai/keypoints";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const jsonValue = {
      language: requestBody.LanguageTo,
      items: requestBody.Value.split('\n')
    }

    const utf8Request: TPGenAIKeyPointsUpdateModel = {
      Id: requestBody.Id,
      LanguageTo: TPGlobal.stringToUTF8(requestBody.LanguageTo).join(','),
      Value: TPGlobal.stringToUTF8(JSON.stringify(jsonValue)).join(','),
    }

    try {
      const response: HTTPResponse = await httpClientInstance.putData(
        url,
        utf8Request,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to update the mail's key points generated from TPGenAI: ${error}`,
      );
    }
  }

  public async insertTracking(
    insertData: TPGenAIInsertTrackingModel,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url =
        TPGlobal.baseAPIGateway +
        "api/tenant/" +
        TPGlobal.tenantHex +
        "/tpgenai/tracking";
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const utf8Request: TPGenAIInsertTrackingModel = {
        ...insertData,
        JsonResult: TPGlobal.stringToUTF8(insertData.JsonResult).join(','),
        LanguageTo: TPGlobal.stringToUTF8(insertData.LanguageTo).join(','),
      }

      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        utf8Request,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async keyPointsToMail(
    requestBody: TPGenAIMail2SummaryRequest,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<TPGenAISummaryResponse> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url =
        TPGlobal.baseAPIGateway +
        "api/tenant/" +
        TPGlobal.tenantHex +
        "/tpgenai/mail2summary";
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const utf8Request: TPGenAIMail2SummaryRequest = {
        email: TPGlobal.stringToUTF8(requestBody.email).join(','),
        keypoints: TPGlobal.stringToUTF8(requestBody.keypoints).join(','),
        language: TPGlobal.stringToUTF8(requestBody.language).join(','),
      }

      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        utf8Request,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return {
        output: response.responseData.data,
      };
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get the mail's summary from generated key points: ${error}`,
      );
    }
  }

  public async updateMailSummary(
    requestBody: TPGenAIKeyPointsUpdateModel,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + "/inboundmailboxread/tpgenai/summary";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const jsonValue = {
      output: requestBody.Value
    }

    const utf8Request: TPGenAIKeyPointsUpdateModel = {
      Id: requestBody.Id,
      LanguageTo: TPGlobal.stringToUTF8(requestBody.LanguageTo).join(','),
      Value: TPGlobal.stringToUTF8(JSON.stringify(jsonValue)).join(','),
    }

    try {
      const response: HTTPResponse = await httpClientInstance.putData(
        url,
        utf8Request,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateMailSummary ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(error);
      throw new Error(
        `Unexpected error when trying to update the mail summary (TPGenAI): ${error}`,
      );
    }
  }
}
