import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface WorkflowDetailReminderInputDTO {
  id: number;
  term: number;
  reminderTermUnitIdBranch: string;
}

export class WorkflowDetailReminderInputDTOValidator extends Validator<WorkflowDetailReminderInputDTO> {
  constructor(reminderTerm: number) {
    super();
    const resourceSet = "WorkflowDetailReminderInsertUpdateComponent";

    try {
      if (reminderTerm > 0) {
        this.ruleFor("reminderTermUnitIdBranch")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOReminderTermUnitIdBranchEmpty")
          .maxLength(20)
          .withMessage(
            resourceSet + "|InputDTOInvalidReminderTermUnitIdBranchLength",
          )
          .must((name) => {
            return TPGlobal.TPSanitize(name) == name;
          })
          .withMessage(
            resourceSet + "|InputDTOInvalidReminderTermUnitIdBranch",
          );
      }
    } catch (error) {}
  }
}
