import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";

export interface QuickClassifierShortcutsInputDTO {
  webserviceClassifierUserId: string;
  guidUser: string;
  hotkey: number;
}

export interface OrganizationRelationInputDTO {
  baseLevelId: string;
}

export class BaseLevelIdValidator extends Validator<OrganizationRelationInputDTO> {
  constructor() {
    super();

    const resourceSet = "QuickClassifiersInsertUpdateComponent";

    try {
      //baselevel
      this.ruleFor("baseLevelId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .must((baseLevelId) => {
          let check = TPGlobal.checkSpecialCharacters(baseLevelId);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .must((baseLevelId) => {
          return TPGlobal.TPSanitize(baseLevelId) == baseLevelId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidBaseLevelIdLength");
    } catch (error) {}
  }
}

export class QuickClassifierShortcutsInputDTOValidator extends Validator<QuickClassifierShortcutsInputDTO> {
  constructor() {
    super();

    const resourceSet = "QuickClassifiersInsertUpdateComponent";

    try {
      this.ruleFor("webserviceClassifierUserId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidClassifier");

      this.ruleFor("guidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidGuidUser")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidGuidUser");

      this.ruleFor("hotkey")
        .notNull()
        .withMessage(resourceSet + "|InputDTOInvalidHotkey")
        .greaterThanOrEqualTo(0)
        .withMessage(resourceSet + "|InputDTOInvalidHotkey")
        .lessThanOrEqualTo(9)
        .withMessage(resourceSet + "|InputDTOInvalidHotkey");
    } catch (error) {}
  }
}
