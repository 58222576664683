import { TPWizardContainer } from "./tpWizardStyles";
import TPWizardSteps from "./TPWizardSteps";
import { ITPWizardStep } from "./TPWizardStep";
import { ITPWizardContent } from "./TPWizardContent";
import TPWizardContents from "./TPWizardContents";

interface TPWizardProps {
    activeNewControl: boolean;
}

const TPWizard = (props: any) => {
    const steps: ITPWizardStep[] = props.children
        .filter((k: any) => k.props.name === "TPWizardStep")
        .map((k: any) => {

            return {
                id: k.props.id,
                isActive: k.props.isActive,
                labelText: k.props.labelText,
                numberText: k.props.numberText,
                withIcon: k.props.withIcon,
                icon: k.props.icon,
                activeNewComponet: k.props.activeNewComponet,
                addLine: k.props.addLine,
                isCheck: k.props.isCheck,
                callBackCommands: k.props.callBackCommands

            };
        });

    var contents: ITPWizardContent[] = props.children
        .filter((k: any) => k.props.name == "TPWizardContent")
        .map((k: any) => {
            return {
                id: k.props.id,
                children: k.props.children,
                isActive: k.props.isActive,
            };
        });

    const activeNewControl = props.children.find(
        (k: any) => k.props.name === "TPWizardStep"
    )?.props.activeNewComponet || false;
    return (
        <TPWizardContainer active={activeNewControl} >
            <TPWizardSteps tpWizardSteps={steps}></TPWizardSteps>
            <TPWizardContents tpWizardContents={contents}></TPWizardContents>
        </TPWizardContainer>
    );
};

export default TPWizard;
