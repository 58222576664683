import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QueueAdminSliceModel {
  queuesLoaded: boolean;
}

const initialState: QueueAdminSliceModel = {
  queuesLoaded: false
}

export const QueueAdminSlice = createSlice({
  initialState: initialState,
  name: "QueueAdminSlice",
  reducers: {
    setQueuesLoaded: (state, action: PayloadAction<boolean>) => {
      state.queuesLoaded = action.payload;
    }
  }
})