import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface ProfileMenuItemAssignProfileInputDTO {
  guidProfileIds: string[];
  menuItemId: string;
}

export class ProfileMenuItemAssignProfileInputDTOValidator extends Validator<ProfileMenuItemAssignProfileInputDTO> {
  constructor() {
    super();
    const resourceSet = "ProfileMenuItemInputDTOValidator";
    try {
      this.ruleFor("menuItemId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOMenuItemIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidMenuItemId")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidMenuItemId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidMenuItemIdLength");
    } catch (error) {}
  }
}
