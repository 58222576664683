import React, { CSSProperties, useEffect, useRef, useState } from "react";
import HotTable from "@handsontable/react";
import { LocationLanguages, LocationNameInsertUpdate } from "./DistributionListAdminLocalization";
import { useAppDistributionListAdmin } from "./ContextDistributionListAdmin";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { StoreModel } from "@/redux/store";
import { useSelector } from "react-redux";
import Handsontable from "handsontable";

interface PropsModel {
    columns: Array<Array<any>>;
}

export default function DistributionListAdmintImportTable({
    columns,
}: PropsModel) {
    const [location, setLocation] = useState(new LocationNameInsertUpdate());
    const hotTableRef = useRef<any>(null);
    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const loadResourcesAndLoadFunctionInfo = async () => {
        let temp = new LocationLanguages();
        await temp.locationDistributionListInsertUpdate(resourceSet);
        setLocation(temp.getLocationNameInsertUpdate);
    };

    const handleAfterChange = (changes: any, source: any) => {

        if (!changes || changes.length === 0) return;

        let newData = [...hotdataList];
        changes.forEach(([row, prop, oldValue, newValue]: any) => {
            if (newValue !== oldValue) {
                newData[row][prop] = newValue;
            }
        });


        if (newData && Array.isArray(newData)) {
            let status = true;
            newData.forEach((i) => {
                if (i[0] == "") status = false;
                if (i[1] == "") status = false;
                if (handleValidate(i) != "") status = false;
                if (handleValidateRepeated(i, newData) !="" ) status = false;
            });

           

            if (status) {
                newData.push(['', '', '']);
            } else if (newData.length > 1) {
                if (newData.filter(s => s[0] != "" && s[1] != "").length > 1)
                    newData = newData.filter(s => s[0] != "" && s[1] != "");
            }
        }
        setHotDataList(newData);
    };
    const handleValidate = (updatedRow: Array<any>) => {
        let msg = "";

        if (updatedRow[0] == "" && updatedRow[1] == "" && updatedRow[2] == "") return "";


        if (!isValidEmail(updatedRow[0])) {
            msg += location.invalidEmailHotTable;
        }
        if (updatedRow[1] === "") {
            msg += (msg ? ", " : "") + location.invalidNameHotTable;
        }

        return msg;
    };

    const handleValidateRepeated = (updatedRow: Array<any>, list:any[][]) => {
        let msg = "";
        const targetValue = updatedRow[0];

        if (updatedRow[0] == "" && updatedRow[1] == "" && updatedRow[2] == "") return "";

        let count = list.reduce((acc, row) => {
            return row[0] === targetValue ? acc + 1 : acc;
        }, 0);

       
        if (count>1) {
            msg += location.inputDTOEmailDuplicate;
        }
       
        return msg;
    };

    const value = useAppDistributionListAdmin();
    const { resourceSet, hotdataList,
        setHotDataList } = value;

    useEffect(() => {
        loadResourcesAndLoadFunctionInfo();
    }, []);

   
    return (
        <div style={styles.container}>
            <HotTable
                className="htCenter"
                colWidths={["auto", "auto", 100]}
                data={hotdataList}
                nestedHeaders={columns}
                contextMenu={["copy", "cut"]}
                licenseKey="non-commercial-and-evaluation"
                manualColumnResize
                autoWrapRow
                selectionMode="multiple"
                stretchH="all"
                fixedRowsTop={1}
                rowHeaders
                colHeaders
                ref={hotTableRef}
                afterChange={handleAfterChange}
                cells={(row: number, col: number) => {
                    const cellProperties: any = {
                        row,
                        col,
                        instance: hotTableRef.current,
                        visualRow: row,
                        visualCol: col,
                    };
                    if (col === 2) {
                        cellProperties.renderer = function (instance: Handsontable.Core, td: HTMLTableCellElement, row: number, col: number, prop: string | number, value: any, cellProperties: Handsontable.CellProperties) {
                            Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties);
                            if (value !== '') {
                                td.style.backgroundColor = 'red';
                                td.style.color = 'white';
                            }
                        };
                    }

                    if (col === 3) {
                        cellProperties.renderer = function (instance: Handsontable.Core, td: HTMLTableCellElement, row: number, col: number, prop: string | number, value: any, cellProperties: Handsontable.CellProperties) {
                            Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties);
                            cellProperties.readOnly = false;
                        };
                    }
                    return cellProperties;
                }}
            />
        </div>
    );
}

const styles = {

    container: {
        width: "100%",
        minHeight: "300px",
        maxHeight: "500px",
        height: "max-content",
        overflowX: "auto",
        overflowY: "hidden",
        marginBottom: "20px",
    } as CSSProperties,
    button: {
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    } as CSSProperties,
};

