import { TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useToast } from "../design-system/toasts/ToastContext";

const MAX_EXCEL_CELL_LENGTH = 32764;

const processDataForExport = (data: unknown[]) => {
  return data.map((row: any) => {
    const processedRow: any = {};
    Object.keys(row).forEach((key) => {
      const value = row[key];
      processedRow[key] =
        typeof value === "string" ? truncateText(value) : value;
    });
    return processedRow;
  });
};

const truncateText = (text: string): string => {
  if (text.length > MAX_EXCEL_CELL_LENGTH) {
    return text.substring(0, MAX_EXCEL_CELL_LENGTH) + "...";
  }
  return text;
};

export const exportDataToExcel = async (
  dataObject: unknown[],
  fileName: string
) => {
  try {
    const fileType: string =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const dataToExport = processDataForExport(dataObject);

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], {
      type: fileType,
    });

    FileSaver.saveAs(data, fileName);
  } catch (error) {
    const { showToast } = useToast();

    let message: string = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      "GeneralException"
    );

    console.error("Error exporting data to Excel:", error);
    showToast(message, TPToastTypes.error);
  }
};
