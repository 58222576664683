import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  TableProjectDetailModel
} from "@/models/Project/Projects";
import ProjectReassign from "@/pages/Projects/ProjectReassign/ProjectReassign";
import {
  CockpitGroupsTableLabels,
  CockpitView,
  GroupTableItem,
} from "@/pages/SupervisorCockpit/supervisor-cockpit.model";
import { Tooltip, Typography } from "@mui/material";
import React, { FC, ReactElement } from "react";
import TableChip from "../../utils/table-micro-components/TableChip";
import { StyledDefaultTableCard } from "../design-system-styles";

export type CustomActionProps<T> = {
  view: CockpitView;
  item: T;
  modalLabels: { [attribute: string]: string };
  tableLabels: CockpitGroupsTableLabels;
  goToDetailLabel: string;
  groupLabel: string;
  projectLabel: string;
  typeResource: string;
  selectedItem: TableProjectDetailModel;
  groups: TPKeyValue[];
  goToProjectDetails?: (projectId: string) => void;
};

const DefaultTableCard: FC<CustomActionProps<GroupTableItem>> = React.memo(
  ({
    view,
    item,
    modalLabels,
    tableLabels,
    groupLabel,
    projectLabel,
    goToDetailLabel,
    typeResource,
    selectedItem,
    groups,
    goToProjectDetails = () => { },
  }): ReactElement => {
    const getTooltipStyled = () => {
      return (
        <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
          {getCardTooltip()}
        </Typography>
      );
    };

    const getCardTitle = (): string => {
      if (view === "group") {
        return `${item.groupName}`;
      } else {
        return `${item.projectName}${item.groupName && ` / ${item.groupName}`}`;
      }
    };

    const getCardTooltip = (): string => {
      if (view === "group") {
        return `${groupLabel}: ${item.groupName}`;
      } else {
        return `${projectLabel}: ${item.projectName}${item.groupName && ` / ${groupLabel}: ${item.groupName}`}`;
      }
    }

    const getChipColorByView = (): string => {
      if (view === "group") return "#D3EAFF";

      if (view === "event") {
        return item.type === "BACKOFFICE" ? "#B1EFF2" : "#E2D7FF";
      };

      return "";
    }

    return (
      <StyledDefaultTableCard>
        <div className="card-header">
          <Tooltip title={getTooltipStyled()} placement="right">
            <p className="name">{getCardTitle()}</p>
          </Tooltip>

          {item.groupName ? (
            <>
              {item.type !== 'FRONTOFFICE' && (
                <ProjectReassign
                  m={modalLabels}
                  queues={groups}
                  detail={selectedItem}
                  loadDataSource={() => { }}
                />
              )}
            </>
          ) : (
            <TPButton
              id={`go-to-${item.projectId}`}
              key={`go-to-${item.projectId}`}
              isDesignSystem
              customType={ButtonCustomType.tertiary}
              onClick={() =>
                goToProjectDetails(item.projectId?.toUpperCase() ?? "")
              }
            >
              {goToDetailLabel}
            </TPButton>
          )}
        </div>
        <div className="card-body">
          <div className="customer">
            <TPIcon iconType={TPIconTypes.personCircle} />
            <div className="details">
              <p>
                {tableLabels.total}:
                <br />
                <strong>{item.totalCE}</strong>
              </p>
              <p>
                {tableLabels.online}:
                <br />
                <strong>{item.onlineStatus}</strong>
              </p>
              <p>
                {tableLabels.working}:
                <br />
                <strong>{item.workingStatus}</strong>
              </p>
              <p>
                {tableLabels.offline}:
                <br />
                <strong>{item.offlineStatus}</strong>
              </p>
            </div>
          </div>
          <div className="ticket">
            <TPIcon iconType={TPIconTypes.ticket} />
            <div className="details">
              {item.type !== "FRONTOFFICE" && (
                <p>
                  {view === "group" ? tableLabels.totalTickets : tableLabels.events}:
                  <br />
                  <strong>{item.totalTickets}</strong>
                </p>
              )}
              <p>
                {tableLabels.workedTickets}:
                <br />
                <strong>{item.workedTickets}</strong>
              </p>
              {item.type !== "FRONTOFFICE" && (
                <p>
                  {tableLabels.pendingTickets}:
                  <br />
                  <strong>{item.pendingTickets}</strong>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="ready">
            <p className="ce-ready">{tableLabels.ready}: </p>
            <TableChip value={true} onLabel={`${item.readyStatus}`} />
          </div>
          <TableChip
            value={true}
            onLabel={typeResource}
            activeColor={getChipColorByView()}
            inactiveColor={getChipColorByView()}
            justify="flex-end"
          />
        </div>
      </StyledDefaultTableCard>
    );
  }
);

export default DefaultTableCard;
