import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { FC, useState } from "react";
import '../../Assets/styles.css';
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface ScriptOpenTextLogicProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
}

const ScriptOpenTextLogic: FC<ScriptOpenTextLogicProperties> = function ({ availableNextSteps, logic, onLogicAdd }) {
  const [input, setInput] = useState("");
  const [nextStep, setNextStep] = useState<string>(availableNextSteps.find(a => a.order == -1)?.id || "");
  return (
    <>
      <TPTextBox
        value={input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
        placeholder="_type_here"
        labelText=""
      />
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => onLogicAdd({
          key_SSDA: input,
          nextStepId: nextStep,
          value: input
        })}
        isDesignSystem
        style={{
          width: 'min-content',
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={input.trim().length == 0}
      >
        _add_logic
      </TPButton>
    </>
  )
}

export default ScriptOpenTextLogic;