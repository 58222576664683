import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import React, { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { BranchService } from "@/services/BranchService";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface ScriptIntegerLogicProperties {
  availableNextSteps: ScriptStep[];
  logic?: ScriptAdditionalDataLogic[];
  onLogicAdd: (logic: ScriptAdditionalDataLogic) => void;
  minValue: number;
  maxValue: number;
}

const ScriptIntegerLogic: FC<ScriptIntegerLogicProperties> = function ({
  availableNextSteps,
  logic,
  onLogicAdd,
  minValue,
  maxValue
}) {
  const [options, setOptions] = useState<TPKeyValue[]>([]);
  const [chosenOption, setChosenOption] = useState<string>(String(minValue));
  const [nextStep, setNextStep] = useState(availableNextSteps.find(a => a.order == -1)?.id || "");

  useEffect(() => {
    const integers: TPKeyValue[] = [];
    for (let i = Number(minValue); i <= Number(maxValue); i++) {
      integers.push({ key: String(i), value: String(i) });
    }
    setOptions(integers);
  }, [])

  return (
    <>
      <TPSelect
        dataSource={[...options]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChosenOption(e.target.value)}
        value={chosenOption}
        labelText=""
        placeholder="--"
      />
      <TPSelect
        dataSource={
          availableNextSteps.map(step => {
            return {
              key: String(step.id),
              value: step.order == -1 ?
                extractTextFromHTML(step.description)
                :
                String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
            }
          })
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextStep(e.target.value)}
        value={nextStep}
        containerStyle={{ width: '100%' }}
      />
      <TPButton
        onClick={() => onLogicAdd({
          key_SSDA: chosenOption,
          nextStepId: nextStep,
          value: chosenOption
        })}
        isDesignSystem
        style={{
          width: 'min-content',
          alignSelf: 'flex-end',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
        disabled={Number.isNaN(Number(chosenOption))}
      >
        _add_logic
      </TPButton>
    </>
  )
}

export default ScriptIntegerLogic;