import { FC } from "react";
import "./Style.css";

interface Properties {
  label: string | number;
  backgroundColor?: string;
  accentColor?: string;
}

export const TPBadge: FC<Properties> = function ({
  label,
  backgroundColor,
  accentColor,
}) {
  return (
    <>
      <span
        class="badge badge-pill badge-primary"
        style={{
          backgroundColor: backgroundColor || "gray",
          accentColor: accentColor || "white",
        }}
      >
        {label}
      </span>
    </>
  );
};
