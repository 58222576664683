import { CustomError, StateModel } from "@/redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import e from "../models/EmailTemplateCloneEnum";

export interface EmailTemplateCloneFormStateModel extends StateModel {
  message: any;
  mailAccounts: Array<any>;
}

export const EmailTemplateCloneFormSlice = createSlice({
  name: e.EmailTemplateCloneForm,
  initialState: {
    message: {},
    mailAccounts: [] as Array<any>,
    result: {},
  } as EmailTemplateCloneFormStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        message: {},
        mailAccounts: [] as Array<any>,
        form: {},
        result: { action },
      }) as EmailTemplateCloneFormStateModel,
    close: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<string>
    ) =>
      ({
        ...state,
        result: { action },
      }) as EmailTemplateCloneFormStateModel,
    message: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<Array<string>>
    ) =>
      ({
        ...state,
        result: { action },
      }) as EmailTemplateCloneFormStateModel,
    messageSuccess: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<{
        attribute: string;
        message: string;
      }>
    ) => {
      const message = { ...state.message };
      message[action.payload.attribute] = action.payload.message;

      return {
        ...state,
        message,
        result: { action, message: action.payload.message },
      } as EmailTemplateCloneFormStateModel;
    },
    messageError: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<CustomError>
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as EmailTemplateCloneFormStateModel,
    getMailAccounts: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction
    ) =>
      ({
        ...state,
        result: { action },
      }) as EmailTemplateCloneFormStateModel,
    getMailAccountsSuccess: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<Array<any>>
    ) => {
      const mailAccounts = action.payload.map((item) => ({
        ...item,
        key: item.idAccount,
        value: item.description,
      }));
      mailAccounts.unshift({ key: "", value: "--" });

      return {
        ...state,
        mailAccounts,
        result: { action },
      } as EmailTemplateCloneFormStateModel;
    },
    getMailAccountsError: (
      state: EmailTemplateCloneFormStateModel,
      action: PayloadAction<CustomError>
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as EmailTemplateCloneFormStateModel,
  },
});
