interface LoadHistoryBase {
  projectId: string;
  projectName: string;
  eventLoadStructureId: string;
  structureDescription: string;
  userLoadId: string;
  userLoadName: string;
  insertDateEventLoad: string;
}

export interface LoadHistoryResponse extends LoadHistoryBase {
  eventLoadId: string;
  userLoadId: string;
  userLoadName: string;
  insertDateEventLoad: string;
  resolved: number;
  pending: number;
  total: number;
  isActiveEventLoad: boolean;
}

export interface UserRecord {
  ticketId: string;
  ticketType: string;
  workedUserId: string;
  workedUserName: string;
  status: string;
  timeWorked: string;
  workDate: string;
  interactionOutcomesId: string;
  interactionOutcomesDescription: string;
}

export enum RecordStatus {
  resolved = "PR",
  pending = "PE",
}

export interface UploadProgressData extends LoadHistoryBase {
  status: string;
  numberErrors: string;
  idEventTemporaryLoad: string;
  eventTemporaryLoad: string;
  fileName: string;
  azureBlodId: string;
}
