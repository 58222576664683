import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";

export class EmailTemplateAttachmentService {
  serviceFileName: string = "EmailTemplateAttachmentService.ts";

  public async insertAttachment(
    formData: FormData,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/emailtemplateattachment";
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        formData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAttachment ex`,
        TPLogType.ERROR,
        error
      );
      throw new Error(`Error ${this.serviceFileName} insertAttachment`);
    }
  }

  public async deleteAttachmentById(
    blobId: string,
    pathFile: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = `/emailtemplateattachment/${blobId}?pathFile=${encodeURIComponent(
      pathFile
    )}`;
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteAttachmentById ex`,
        TPLogType.ERROR,
        error
      );
      throw new Error(`Error ${this.serviceFileName} deleteAttachmentById`);
    }
  }

  public async getAttachmentByEmailTemplateId(
    emailTemplateId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = `/emailtemplateattachment/by-emailtemplate-id?emailTemplateId=${emailTemplateId}`;
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAttachmentByEmailTemplateId ex`,
        TPLogType.ERROR,
        error
      );
      throw new Error(
        `Error ${this.serviceFileName} getAttachmentByEmailTemplateId`
      );
    }
  }

  public async downloadAttachment(
    blobId: string,
    attachmentType: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = `/storage/file-sas/${blobId}?attachmentType=${attachmentType}`;
    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} downloadAttachment ex`,
        TPLogType.ERROR,
        error
      );
      throw new Error(`Error ${this.serviceFileName} downloadAttachment`);
    }
  }
}
