import { ReactElement } from "react";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { BranchService } from "@/services/BranchService";
import { TPTreeViewStyled } from "./TPTreeViewStyles";
import ExpandLessIcon from "@/assets/images/TPIcons/ExpandLessIcon";
import ExpandMoreIcon from "@/assets/images/TPIcons/ExpandMoreIcon";

export interface TPTreeViewDataModel {
  label: string;
  id: string;
  parentId: string;
  hasChild: boolean;
  isExpanded: boolean;
  isActive: boolean;
  isVisible: boolean;
  hierarchyIds: string;
  hierarchyDescription: string;
}

export interface TPTreeViewProps {
  treeId: string;
  selectedNode: string;
  selectedLabel: string;
  selectedHierarchyLabel: string;
  renderParent: string;
  guidTree: string;
  dataSource: Array<TPTreeViewDataModel>;
  mustSelectLastLevelBranch: boolean;
  filterIsActive?: number;
  filterIsVisible?: number;
  isAdminMode?: boolean;
  isFirstUL?: boolean;
  customStyle?: any;
  onTrySelectLastLevelBranch?: any;
  setIsLoading: Function;
  setDataSource: Function;
  setSelectedNode: Function;
  setSelectedLabel: Function;
  setSelectedHierarchyLabel: Function;
  handleChangeNodeCallBack: Function;
}

//todo styles
const TPTreeView = ({
  setIsLoading,
  treeId,
  dataSource,
  setDataSource,
  selectedNode,
  setSelectedNode,
  selectedLabel,
  setSelectedLabel,
  selectedHierarchyLabel,
  setSelectedHierarchyLabel,
  renderParent,
  handleChangeNodeCallBack,
  filterIsActive = 1,
  filterIsVisible = 1,
  guidTree,
  isAdminMode = false,
  isFirstUL = false,
  customStyle,
  mustSelectLastLevelBranch,
  onTrySelectLastLevelBranch = null,
}: TPTreeViewProps): ReactElement => {
  const componentFileName = "TPTreeView.tsx";

  //collapse
  const handleCollapse = (nodeId: string) => {
    let copyData = [...dataSource];
    let i: number;
    for (i = 0; i <= copyData.length - 1; i++) {
      if (copyData[i].id === nodeId) {
        copyData[i].isExpanded = false;
        break;
      }
    }
    setDataSource(copyData);
  };
  //expand
  const handleExpand = async (nodeId: string) => {
    let copyData = [...dataSource];
    let i: number;
    let childrenFound: boolean;
    for (i = 0; i <= copyData.length - 1; i++) {
      if (copyData[i].id === nodeId) {
        copyData[i].isExpanded = true;
        break;
      }
    }
    childrenFound = false;
    for (i = 0; i <= copyData.length - 1; i++) {
      if (copyData[i].parentId === nodeId) {
        childrenFound = true;
        break;
      }
    }
    if (!childrenFound) {
      let childBranches: Array<TPTreeViewDataModel> = [];
      setIsLoading(true);
      childBranches = await loadChildBranches(nodeId);
      for (i = 0; i <= childBranches.length - 1; i++) {
        copyData.push(childBranches[i]);
      }
      setIsLoading(false);
    }
    setDataSource(copyData);
  };

  const loadChildBranches = async (
    nodeId: string,
  ): Promise<Array<TPTreeViewDataModel>> => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    let finalBranches: Array<TPTreeViewDataModel> = [];
    try {
      let responseRequest = await serviceClient.getChildBranches(
        nodeId,
        filterIsActive,
        filterIsVisible,
        false,
        true,
        expectedCodes,
      );
      //transform BranchModel to TPTreeViewDataModel
      for (i = 0; i <= responseRequest.length - 1; i++) {
        let nodeElement: BranchViewModel = responseRequest[i];
        let branchElement: TPTreeViewDataModel = {
          id: nodeElement.id,
          label:
            nodeElement.localizedDescription !== null
              ? nodeElement.localizedDescription
              : nodeElement.description,
          parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
          hasChild: isAdminMode
            ? nodeElement.hasChild
              ? true
              : false
            : nodeElement.hasChildActive && nodeElement.hasChildVisible
              ? true
              : false,
          isExpanded: false,
          isActive: nodeElement.isActive,
          isVisible: nodeElement.isVisible,
          hierarchyIds: nodeElement.hierarchyIds,
          hierarchyDescription: nodeElement.hierarchyDescription,
        };
        finalBranches.push(branchElement);
      }
      return finalBranches;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadChildBranches ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadChildBranches ex`);
      return [];
    }
  };

  //change selectedNode
  const handleChangeSelectedNode = (
    nodeId: string,
    label: string,
    hierarchyDescription: string,
    hasChildren: boolean,
  ) => {
    if (mustSelectLastLevelBranch && hasChildren) {
      if (onTrySelectLastLevelBranch) {
        onTrySelectLastLevelBranch();
      }
      return;
    }

    setSelectedNode(nodeId);
    setSelectedLabel(label);
    setSelectedHierarchyLabel(hierarchyDescription);
    handleChangeNodeCallBack(nodeId, label, hierarchyDescription);
  };

  const getItemClass = (nodeId: string, item: TPTreeViewDataModel) => {
    let classes = "";
    classes += item.isActive ? " is-active " : " is-inactive ";
    classes += item.isVisible ? " is-visible " : " is-invisible ";
    classes += nodeId === selectedNode ? " is-selected " : "";
    return classes;
  };

  const getItemStyle = (nodeId: string, item: TPTreeViewDataModel) => {
    let nodeStyle: any = {
      padding: "5px",
      margin: "3px",
      whiteSpace: "nowrap",
      cursor: "pointer",
      borderRadius: "5px",
    };

    if (nodeId === selectedNode) {
      nodeStyle = {
        ...nodeStyle,
        backgroundColor: "#a00095",
        color: "white",
        whiteSpace: "nowrap",
        fontWeight: "500",
      };
    }

    if (!item.isActive && item.isVisible) {
      nodeStyle.color = "red";
    }

    if (!item.isActive && !item.isVisible) {
      nodeStyle.color = "red";
      nodeStyle.backgroundColor = "yellow";
    }

    if (item.isActive && !item.isVisible) {
      nodeStyle.backgroundColor = "yellow";
    }

    return nodeStyle;
  };

  const getRender = () => {
    const separatorStyle = { paddingRight: "5px", marginLeft: "12px" };

    if (dataSource.length === 0) {
      return <></>;
    } else {
      let ulMarginLeft: string;
      if (isAdminMode) {
        if (isFirstUL) {
          ulMarginLeft = "20px";
        } else {
          ulMarginLeft = "30px";
        }
      } else {
        ulMarginLeft = "30px";
      }
      return (
        <TPTreeViewStyled
          style={{
            listStyleType: "none",
            borderLeft: "none",
            marginLeft: ulMarginLeft,
            ...customStyle,
          }}
        >
          {dataSource.map((item, index) => {
            let styleArrowSpan: any;
            styleArrowSpan = {
              paddingRight: "5px",
              marginLeft: "-6px",
              paddingLeft: "5px",
              whiteSpace: "nowrap",
            };

            return item.parentId === renderParent ? (
              <li
                id={guidTree + "_" + item.id}
                key={item.id}
                className={
                  !isAdminMode
                    ? "tptreeviewbranchli"
                    : "tptreeviewbranchliadmin"
                }
              >
                {item.hasChild ? (
                  item.isExpanded ? (
                    <span
                      onClick={() => handleCollapse(item.id)}
                      style={styleArrowSpan}
                    >
                      <ExpandLessIcon style={{ cursor: "pointer" }} />
                    </span>
                  ) : (
                    <span
                      onClick={() => handleExpand(item.id)}
                      style={styleArrowSpan}
                    >
                      <ExpandMoreIcon style={{ cursor: "pointer" }} />
                    </span>
                  )
                ) : null}
                <span
                  onClick={() =>
                    handleChangeSelectedNode(
                      item.id,
                      item.label,
                      item.hierarchyDescription,
                      item.hasChild,
                    )
                  }
                  style={getItemStyle(item.id, item)}
                  className={getItemClass(item.id, item)}
                  id={`${item.id}-branch`}
                >
                  {item.hasChild ? (
                    <span>{item.label}</span>
                  ) : (
                    <span style={separatorStyle}>{item.label}</span>
                  )}
                </span>
                {item.hasChild && item.isExpanded && (
                  <TPTreeView
                    setIsLoading={setIsLoading}
                    treeId={treeId}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    selectedLabel={selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                    selectedHierarchyLabel={selectedHierarchyLabel}
                    setSelectedHierarchyLabel={setSelectedHierarchyLabel}
                    renderParent={item.id}
                    handleChangeNodeCallBack={handleChangeNodeCallBack}
                    filterIsActive={filterIsActive}
                    filterIsVisible={filterIsVisible}
                    guidTree={guidTree}
                    isAdminMode={isAdminMode}
                    isFirstUL={false}
                    mustSelectLastLevelBranch={mustSelectLastLevelBranch}
                  />
                )}
              </li>
            ) : null;
          })}
        </TPTreeViewStyled>
      );
    }
  };

  return getRender();
};

export default TPTreeView;
