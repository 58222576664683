import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
//import { globalHistory } from "@reach/router";

//todo instrumentationKey and login level
const reactPlugin = new ReactPlugin();
//"fec824e0-df48-4b8c-b5bd-dc33a7741bdc"
const tpAppInsights = process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION;
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: tpAppInsights,
    extensions: [reactPlugin],
    extensionConfig: {
      //[reactPlugin.identifier]: { history: globalHistory },
    },
  },
});
ai.loadAppInsights();
ai.addTelemetryInitializer((envelope) => {
  envelope.tags["ai.cloud.role"] = "reactfrontend";
  envelope.tags["ai.cloud.roleInstance"] = "main";
});

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
export { reactPlugin };
