import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface MenuItemInputDTO {
  id: string;
  description: string;
  parent: string | null;
  type: string | null;
  uRLLink: string | null;
  uRLLinkParameters: string | null;
  image: string | null;
  width: string | null;
  height: string | null;
  externalPublicKey: string | null;
  isActive: boolean;
  isSection: boolean | null;
  descriptionLocalizedValues: RecordLocalizedModel[];
  [x: string]: any;
}

export class MenuItemInputDTOValidator extends Validator<MenuItemInputDTO> {
  constructor(renderMode: string) {
    super();
    const resourceSet = "MenuItemInsertUpdateComponent";
    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((x) => {
          return TPGlobal.TPSanitize(x) == x;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidId")
        .must((x) => {
          let check = TPGlobal.checkSpecialCharacters(x);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((x) => {
          return TPGlobal.TPSanitize(x) == x;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidDescription")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      if (renderMode === "NEW_GROUP" || renderMode === "NEW_ITEM") {
        this.ruleFor("parent")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOParentEmpty")
          .must((x: any) => {
            if (x) {
              return TPGlobal.TPSanitize(x) == x;
            }
            return true;
          })
          .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidParent")
          .maxLength(10)
          .withMessage(resourceSet + "|InputDTOInvalidParentLength");
      } else {
        this.ruleFor("parent")
          .must((x) => {
            if (x) {
              return TPGlobal.TPSanitize(x) == x;
            }
            return true;
          })
          .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidParent")
          .maxLength(10)
          .withMessage(resourceSet + "|InputDTOInvalidParentLength");
      }

      this.ruleFor("type")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidType")
        .maxLength(10)
        .withMessage(resourceSet + "|InputDTOInvalidTypeLength");

      this.ruleFor("uRLLink")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidURLLinkParameters",
        )
        .maxLength(1000)
        .withMessage(resourceSet + "|InputDTOInvalidURLLinkParametersLength");

      this.ruleFor("uRLLinkParameters")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidURLLink")
        .maxLength(400)
        .withMessage(resourceSet + "|InputDTOInvalidURLLinkLength");

      this.ruleFor("image")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidImage")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidImageLength");

      this.ruleFor("width")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidWidth")
        .maxLength(10)
        .withMessage(resourceSet + "|InputDTOInvalidHeightLength");

      this.ruleFor("externalPublicKey")
        .must((x) => {
          if (x) {
            return TPGlobal.TPSanitize(x) == x;
          }
          return true;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidExternalPublicKey",
        )
        .maxLength(1000)
        .withMessage(resourceSet + "|InputDTOInvalidExternalPublicKeyLength");

      this.ruleForEach("descriptionLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 200;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
    } catch (error) {}
  }
}
