import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { useId } from "react";
import TPLabel from "../TPLabel/TPLabel";
import { TPNumericStyled } from "./TPNumericStyle";
import { NumericFormat } from "react-number-format";

interface TPNumericInterface {
  onChange: Function;
  className?: string;
  value: string | number | null;
  disabled?: boolean;
  maxLength?: number;
  labelText: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  withIcon?: boolean;
  icon?: TPIconTypes;
  currencySymbol?: string;
  currencyPlacement?: string;
  fixedDecimal?: number;
  decimalSeparator?: string;
  thousandSeparator?: string;
  containerStyle?: any;
  inputStyle?: any;
  labelStyle?: any;
  isTextBefore?: boolean;
  onKeyDownCallBack?: Function;
  id?: string;
}

const TPNumeric = ({
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  withIcon = false,
  currencySymbol = "",
  currencyPlacement = "",
  fixedDecimal = 0,
  decimalSeparator = ".",
  thousandSeparator = "",
  containerStyle,
  labelStyle,
  inputStyle,
  isTextBefore = true,
  onKeyDownCallBack = undefined,
  id,
}: TPNumericInterface) => {
  const theId = `textbox-${useId()}`;
  const handleOnTextChange = (values: any) => {
    if (onChange) {
      const { value } = values;
      if (value == null) {
        onChange(null);
      } else {
        onChange(value);
      }
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (onKeyDownCallBack) {
      onKeyDownCallBack(e);
    }
  };

  return (
    <>
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        className={`${withIcon ? "input-group" : ""}`}
        style={containerStyle}
      >
        {isTextBefore && (
          <TPLabel
            htmlFor={id ? id : theId}
            isMandatory={isMandatory}
            labelText={labelText}
            style={labelStyle}
          />
        )}
        <TPNumericStyled>
          {fixedDecimal && fixedDecimal > 0 ? (
            <NumericFormat
              autoComplete="off"
              id={id ? id : theId}
              className={`form-control ${className} ${errorMessage !== "" ? "is-invalid" : ""} ${withIcon ? "border-end-0" : ""}`}
              value={value}
              valueIsNumericString={true}
              prefix={
                currencySymbol && currencyPlacement === "Before"
                  ? currencySymbol
                  : undefined
              }
              suffix={
                currencySymbol && currencyPlacement === "After"
                  ? currencySymbol
                  : undefined
              }
              decimalScale={fixedDecimal}
              fixedDecimalScale
              thousandSeparator={
                thousandSeparator ? thousandSeparator : undefined
              }
              decimalSeparator={decimalSeparator ? decimalSeparator : undefined}
              onValueChange={handleOnTextChange}
              disabled={disabled}
              maxLength={maxLength}
              onKeyDown={handleOnKeyDown}
            />
          ) : (
            <NumericFormat
              autoComplete="off"
              id={id ? id : theId}
              className={`form-control ${className} ${errorMessage !== "" ? "is-invalid" : ""} ${withIcon ? "border-end-0" : ""}`}
              value={value}
              valueIsNumericString={true}
              prefix={
                currencySymbol && currencyPlacement === "Before"
                  ? currencySymbol
                  : undefined
              }
              suffix={
                currencySymbol && currencyPlacement === "After"
                  ? currencySymbol
                  : undefined
              }
              decimalScale={0}
              thousandSeparator={
                thousandSeparator ? thousandSeparator : undefined
              }
              decimalSeparator={decimalSeparator ? decimalSeparator : undefined}
              onValueChange={handleOnTextChange}
              disabled={disabled}
              maxLength={maxLength}
              onKeyDown={handleOnKeyDown}
            />
          )}
          {errorMessage !== "" && (
            <div className="invalid-feedback">{errorMessage}</div>
          )}
        </TPNumericStyled>
      </TPFormControlContainerStyled>
    </>
  );
};
export default TPNumeric;
