import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ScriptsManagementSliceModel {
  scriptsLoaded: boolean;
  unsavedTabIds: string[];
}

const initialState: ScriptsManagementSliceModel = {
  scriptsLoaded: true,
  unsavedTabIds: []
}

export const ScriptsManagementSlice = createSlice({
  name: 'Scripts',
  initialState: initialState,
  reducers: {
    setScriptsLoaded: (state, action: PayloadAction<boolean>) => {
      state.scriptsLoaded = action.payload;
    },
    addUnsavedTab: (state, action: PayloadAction<string>) => {
      state.unsavedTabIds = [...state.unsavedTabIds, action.payload];
    },
    removeUnsavedTab: (state, action: PayloadAction<string>) => {
      state.unsavedTabIds = [...state.unsavedTabIds.filter(t => t !== action.payload)];
    },
  }
})