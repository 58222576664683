import { TPI18N } from "@/services/I18nService";

export class LocationLanguagesAdministrationUsersStation {

    getLocationNameTable: LocationNameTable = new LocationNameTable();
    getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();


    public handlelocationTable = async (resourceSet: string) => {
        this.getLocationNameTable.title = await TPI18N.GetText(resourceSet, "StationIDAdministrationLabel");  
        this.getLocationNameTable.confirm = await TPI18N.GetText(resourceSet, "MsgOkAlertLabel");    
        this.getLocationNameTable.stationIdAdmin = await TPI18N.GetText(resourceSet, "StationIdAdmin");    
        this.getLocationNameTable.ColumnLogin = await TPI18N.GetText(resourceSet, "ColumnLogin");
        this.getLocationNameTable.ColumnIpAddress = await TPI18N.GetText(resourceSet, "ColumnIpAddress");    
       

    };


    public handlelocationGlobal = async (resourceSet: string) => {
        this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(resourceSet, "OkButton");
        this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(resourceSet, "DeleteLabel");
        this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(resourceSet, "BuikLoad");
        this.getLocationNameGlobal.confirmdeleteLabel = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.search = await TPI18N.GetText(resourceSet, "Search");
        this.getLocationNameGlobal.filterLabel = await TPI18N.GetText(resourceSet, "FilterLabel");
        this.getLocationNameGlobal.resultsLabel = await TPI18N.GetText(resourceSet, "ResultsLabel");
        this.getLocationNameGlobal.inputRequire = await TPI18N.GetText(resourceSet, "InputDTORequired");
        this.getLocationNameGlobal.questionConfirmDelete = await TPI18N.GetText("AdditionalDataRelateComponent", "RecordDeleteConfirm");
    };


}


export class LocationNameTable {
    title: string = "";
    confirm: string = "";
    stationIdAdmin: string = "";

    ColumnIpAddress: string="";
    ColumnLogin: string="";
    

}

export class LocationNameGlobal {
    confirmAlertTitle: string = "";
    okAlertButton: string = "";
    cancelAlertButton: string = "";
    questionConfirmDelete: string = "";
    deleteLabel: string = "";
    confirmdeleteLabel: string = "";
    updateLabel: string = "";
    buikLoad: string = "";
    search: string = "";
    filterLabel: string = "";
    resultsLabel: string = "";
    inputRequire: string = "";
}


