import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import moment from "moment";

export type AddTaskInputDTO = {
  useRPCMethod: boolean;
  caseId: number;
  taskTypeId: string;
  quantity: number;
  responsibleGuidUser: string;
  term: number | null;
  termUnitId: string | null;
  dueDate: string | null;
  comments: string;
  reminderDate: string | null;
  reminderComments: string | null;
  typistGuidUser: string;
  taskGuid: string;
};

export class AddTaskInputDTOValidator extends Validator<AddTaskInputDTO> {
  constructor(termType: string, isReminder: boolean) {
    super();

    const resourceSet = "AddTaskInputDTOValidator";

    try {
      this.ruleFor("caseId")
        .greaterThan(0)
        .withMessage(resourceSet + "|InputDTOInvalidCaseId");

      this.ruleFor("taskTypeId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTaskTaskTypeIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidTaskTypeIdLength");

      this.ruleFor("quantity")
        .greaterThan(0)
        .withMessage(resourceSet + "|InputDTOInvalidQuantity");

      this.ruleFor("responsibleGuidUser")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOResponsibleGuidUserEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidResponsibleGuidUser",
        )
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidResponsibleGuidUserLength");

      if (termType === "term") {
        this.ruleFor("term")
          .greaterThan(0)
          .withMessage(resourceSet + "|InputDTOInvalidTerm")
          .when((model) => !!model.termUnitId, "AppliesToCurrentValidator");

        this.ruleFor("termUnitId")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOTaskTermUnitIdEmpty")
          .when(
            (model) => (model.term ? model.term : 0) > 0,
            "AppliesToCurrentValidator",
          )
          .must((id: any) => {
            if (!id) {
              return true;
            }
            let check = TPGlobal.checkSpecialCharacters(id);
            return check.result;
          })
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitId")
          .must((id: any) => {
            if (!id) {
              return true;
            }
            return TPGlobal.TPSanitize(id) == id;
          })
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitId")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidTermUnitIdLength");
      } else {
        this.ruleFor("dueDate")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTODueDateIdEmpty")
          .must((id: any) => {
            if (id == null) {
              return false;
            }
            return true;
          })
          .withMessage(resourceSet + "|InputDTODueDateIdEmpty");
      }

      this.ruleFor("comments")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTaskCommentsEmpty")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComments")
        .maxLength(4000)
        .withMessage(resourceSet + "|InputDTOInvalidCommentsLength");

      if (isReminder) {
        this.ruleFor("reminderDate")
          .notNull()
          .withMessage(resourceSet + "|InputDTOReminderDateIdEmpty");

        this.ruleFor("reminderComments")
          .must((id: any) => {
            if (!id) {
              return true;
            }
            return TPGlobal.TPSanitize(id) == id;
          })
          .withMessage(resourceSet + "|InputDTOInvalidComments")
          .maxLength(4000)
          .withMessage(resourceSet + "|InputDTOInvalidCommentsLength")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOTaskReminderCommentsEmpty");
      }
    } catch (error) {}
  }
}
