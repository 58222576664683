import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";

import { CasesInputDTO } from "@/models/Cases/CasesInputDTO";
import { CasesHistoryViewModel } from "@/models/Cases/CasesHistoryViewModel";
import { TPActiveOptions } from "@/models/Global/TPGlobalEnums";
import { CasesStatusViewModel } from "@/models/Cases/CasesStatusViewModel";
import { CasesViewModel } from "@/models/Cases/CasesViewModels";
import { CasesSearchInputDTO } from "@/models/Cases/CasesSearchInputDTO";
import { ReclassifyCaseInputDTO } from "@/models/Cases/ReclassifyCaseInputDTO";
import { AttachmentViewModel } from "@/models/Attachments/AttachmentViewModel";
import { AttachmentInputDTO } from "@/models/Attachments/AttachmentInputDTO";
import { ReassignCaseInputDTO } from "@/models/Cases/ReassignCaseInputDTO";

export class CaseService {
  serviceFileName: string = "CaseService.ts";

  public async insertNewCase(
    inputDTO: CasesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/save-case";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertNewCase ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertNewCase`);
      throw new Error(`Error ${this.serviceFileName} insertNewCase`);
    }
  }

  public async getHistoryCasesBySubsidiaryOrgId(
    idSubOrg: number,
    userGuid: string,
    filterIsActive: TPActiveOptions,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CasesHistoryViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url =
      "/cases/history/subsidiary-organization?subsidiaryOrganizationId=" +
      idSubOrg.toString();
    url = url + "&userGuid=" + userGuid;
    url = url + "&filterIsActive=" + filterIsActive.toString();

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getHistoryCasesBySubsidiaryOrgId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getHistoryCasesBySubsidiaryOrgId`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getHistoryCasesBySubsidiaryOrgId`,
      );
    }
  }

  public async getCasesStatus(
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<CasesStatusViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/casestatus`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getCasesStatus ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getCasesStatus`);
      throw new Error(`Error ${this.serviceFileName} getCasesStatus`);
    }
  }

  public async checkCaseById(
    caseId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<boolean> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/cases/check/${caseId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} checkCaseById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} checkCaseById`);
      throw new Error(`Error ${this.serviceFileName} checkCaseById`);
    }
  }

  public async getCaseInfoByCaseNumber(
    caseNumber: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<CasesViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/cases/${caseNumber}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getCaseInfoById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getCaseInfoById`);
      throw new Error(`Error ${this.serviceFileName} getCaseInfoById`);
    }
  }

  public async getPDFCase(
    caseNumber: number,
    showCustomerInfo: boolean,
    showComments: boolean,
    showTask: boolean,
    showAdditionalData: boolean,
    showContactData: boolean,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<string> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/cases/${caseNumber}/pdf?showCustomerInfo=${showCustomerInfo}&showComments=${showComments}&showTasks=${showTask}&showAdditionalData=${showAdditionalData}&showContactData=${showContactData}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data.pdfBase64;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getPDFCase ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getPDFCase`);
      throw new Error(`Error ${this.serviceFileName} getPDFCase`);
    }
  }

  public async getByFilters(
    inputDTO: CasesSearchInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/search";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByFilters ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByFilters`);
      throw new Error(`Error ${this.serviceFileName} getByFilters`);
    }
  }

  public async reclassifyCase(
    inputDTO: ReclassifyCaseInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reclassify-case";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reclassifyCase ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reclassifyCase`);
      throw new Error(`Error ${this.serviceFileName} reclassifyCase`);
    }
  }

  public async reassignCase(
    inputDTO: ReassignCaseInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reassign-case";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reclassifyCase ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reclassifyCase`);
      throw new Error(`Error ${this.serviceFileName} reclassifyCase`);
    }
  }

  public async getAttachmentsByCaseId(
    caseId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AttachmentViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/attachment/by-case-id?caseId=${caseId.toString()}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAttachmentsByCaseId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAttachmentsByCaseId`);
      throw new Error(`Error ${this.serviceFileName} getAttachmentsByCaseId`);
    }
  }

  public async insertAttachment(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    //headers
    //add this special header
    let contentHeader: TPKeyValue = {
      key: "Content-Type",
      value: "multipart/form-data",
    };
    headers.push(contentHeader);

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAttachment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertAttachment`);
      throw new Error(`Error ${this.serviceFileName} insertAttachment`);
    }
  }

  public async getCaseTasksByUserGuid(
    userGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/user?guidUser=" + userGuid + "&startRecord=1&endRecord=100";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getPreferredCustomers ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getPreferredCustomers`);
      throw new Error(`Error ${this.serviceFileName} getPreferredCustomers`);
    }
  }

  public async reassignTasks(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reassign-user";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignFunctionAssignment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reassignFunctionAssignment`);
      throw new Error(
        `Error ${this.serviceFileName} reassignFunctionAssignment`,
      );
    }
  }

  public async insertAttachmentTemporary(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/temporary";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    //headers
    //add this special header
    let contentHeader: TPKeyValue = {
      key: "Content-Type",
      value: "multipart/form-data",
    };
    headers.push(contentHeader);

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAttachment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertAttachment`);
      throw new Error(`Error ${this.serviceFileName} insertAttachment`);
    }
  }

  public async getNewCaseId(
    inputDTO: any
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workingstatenewcase";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;

    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        [200, 404],
        false,
        true,
        headers
      )

      return response.responseData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAttachment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertAttachment`);
      throw new Error(`Error ${this.serviceFileName} insertAttachment`);
    }
  }

  public async newCaseKeepAlive(
    inputDTO: any
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workingstatenewcase";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;

    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        [200, 404],
        false,
        true,
        headers
      )

      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertAttachment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertAttachment`);
      throw new Error(`Error ${this.serviceFileName} insertAttachment`);
    }
  }
}

//   public async getFunctionsByFilter(
//     filterActive: string,
//     showPositiveMessage: boolean,
//     showNegativeMessage: boolean,
//     expectedCodes: Array<number>
//   ): Promise<Array<FunctionsViewModel>> {
//     let httpclient = new TPHTTPService();
//     let url: string;
//     url = "/functions?FilterIsActive=";
//     url = url + filterActive;

//     let finalURL: string =
//       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
//     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

//     let response: HTTPResponse;
//     try {
//       response = await httpclient.getData(
//         finalURL,
//         expectedCodes,
//         showPositiveMessage,
//         showNegativeMessage,
//         headers
//       );
//       return response.responseData.data;
//     } catch (error) {
//       TPLog.Log(
//         `Error ${this.serviceFileName} getFunctionsByFilter ex`,
//         TPLogType.ERROR,
//         error
//       );
//       console.error(`Error ${this.serviceFileName} getFunctionsByFilter`);
//       throw new Error(`Error ${this.serviceFileName} getFunctionsByFilter`);
//     }
//   }

//   public async deleteFunctionById(
//     functionId: string,
//     showPositiveMessage: boolean,
//     showNegativeMessage: boolean,
//     expectedCodes: Array<number>
//   ): Promise<any> {
//     let httpclient = new TPHTTPService();
//     let url: string;
//     url = "/functions/" + functionId;

//     let finalURL: string =
//       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
//     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

//     let response: HTTPResponse;
//     try {
//       response = await httpclient.deleteData(
//         finalURL,
//         expectedCodes,
//         showPositiveMessage,
//         showNegativeMessage,
//         headers
//       );
//       return response;
//     } catch (error) {
//       TPLog.Log(
//         `Error ${this.serviceFileName} deleteFunctionById ex`,
//         TPLogType.ERROR,
//         error
//       );
//       console.error(`Error ${this.serviceFileName} deleteFunctionById`);
//       throw new Error(`Error ${this.serviceFileName} deleteFunctionById`);
//     }
//   }

//   public async insertFunction(
//     inputDTO: FunctionsInputDTO,
//     showPositiveMessage: boolean,
//     showNegativeMessage: boolean,
//     expectedCodes: Array<number>
//   ): Promise<any> {
//     let httpclient = new TPHTTPService();
//     let url: string;
//     url = "/functions";

//     let finalURL: string =
//       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
//     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

//     let response: HTTPResponse;
//     try {
//       response = await httpclient.postData(
//         finalURL,
//         inputDTO,
//         expectedCodes,
//         showPositiveMessage,
//         showNegativeMessage,
//         headers
//       );
//       return response;
//     } catch (error) {
//       TPLog.Log(
//         `Error ${this.serviceFileName} insertFunction ex`,
//         TPLogType.ERROR,
//         error
//       );
//       console.error(`Error ${this.serviceFileName} insertFunction`);
//       throw new Error(`Error ${this.serviceFileName} insertFunction`);
//     }
//   }

//   public async updateFunction(
//     inputDTO: FunctionsInputDTO,
//     showPositiveMessage: boolean,
//     showNegativeMessage: boolean,
//     expectedCodes: Array<number>
//   ): Promise<any> {
//     let httpclient = new TPHTTPService();
//     let url: string;
//     url = "/functions";

//     let finalURL: string =
//       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
//     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

//     let response: HTTPResponse;
//     try {
//       response = await httpclient.putData(
//         finalURL,
//         inputDTO,
//         expectedCodes,
//         showPositiveMessage,
//         showNegativeMessage,
//         headers
//       );
//       return response;
//     } catch (error) {
//       TPLog.Log(
//         `Error ${this.serviceFileName} updateFunction ex`,
//         TPLogType.ERROR,
//         error
//       );
//       console.error(`Error ${this.serviceFileName} updateFunction`);
//       throw new Error(`Error ${this.serviceFileName} updateFunction`);
//     }
//   }
