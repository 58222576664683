import {
  DataTableContainer,
  TableContainer,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions } from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { v4 as uuidv4 } from "uuid";
import { tableStyles } from "../FormDesigner/StyleFromDesigner";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPI18N } from "@/services/I18nService";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";

interface CustomerHistoryRow {
  caseId: string;
  clientId: string;
  caseStatusId: string;
  insertDate: string;
  insertDateFormatted: string;
  lastModifiedDate: string;
  lastModifiedDateFormatted: string;
  localizedCaseStatusDescription: string;
  // Add any other fields that might be present in the row data
}

interface CustomerHistoryProps {
  userId: string;
}

const CustomerHistory: React.FC<CustomerHistoryProps> = ({ userId }: any) => {
  const resourceSet: string = "CustomerHistoryComponent";
  const componentFileName: string = "CustomerHistory.tsx";
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
  const [customerHistoryList, setCustomerHistoryList] = useState<
    CustomerHistoryRow[]
  >([]);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");

  // Columns labels
  const [caseLabel, setCaseColumnLabel] = useState("");
  const [clientIdLabel, setClientIdColumnLabel] = useState("");
  const [caseStatusLabel, setCaseStatusColumnLabel] = useState("");
  const [dateLabel, setDateColumnLabel] = useState("");
  const [dateFormattedLabel, setDateFormattedLabel] = useState("");
  const [modifiedDateLabel, setModifiedDateLabel] = useState("");
  const [modifiedDateFormattedLabel, setModifiedDateFormattedColumnLabel] =
    useState("");
  const [descriptionLabel, setDescriptionColumnLabel] = useState("");

  const loadResources = async () => {
    try {
      setCaseColumnLabel(await TPI18N.GetText(resourceSet, "CaseLabel"));
      setClientIdColumnLabel(
        await TPI18N.GetText(resourceSet, "ClientIdLabel")
      );
      setCaseStatusColumnLabel(
        await TPI18N.GetText(resourceSet, "CaseStatusLabel")
      );
      setDateColumnLabel(await TPI18N.GetText(resourceSet, "DateLabel"));
      setDateFormattedLabel(
        await TPI18N.GetText(resourceSet, "DateFormattedLabel")
      );
      setModifiedDateLabel(
        await TPI18N.GetText(resourceSet, "ModifiedDateLabel")
      );
      setModifiedDateFormattedColumnLabel(
        await TPI18N.GetText(resourceSet, "ModifiedDateFormattedLabel")
      );
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "DescriptionLabel")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(resourceSet, "DataTableNoCurrentData")
      );

      await getCustomerHistory();

      userId && (await getCustomerHistory());
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} loadResources ex`, error);
    }
  };

  const getCustomerHistory = async () => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest: any =
        await serviceClient.getHistoryCasesBySubsidiaryOrgId(
          userId,
          TPGlobal.currentUserGuid, // Ensure this is correctly initialized
          TPActiveOptions.ALL, // Ensure this is correctly defined
          false,
          true,
          expectedCodes
        );

      if (responseRequest && responseRequest.length > 0) {
        responseRequest = responseRequest.map((item: any) => ({
          ...item,
          tpguidrow: "historyrow" + uuidv4().replace(/-/g, ""),
        }));
      }
      setCustomerHistoryList(responseRequest);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerHistory ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getCustomerHistory ex`, error);
    } finally {
      setIsLoadingScreen(false);
    }
  };

  const customerTypeColumns: CustomColumnNames<any> = {
    caseId: clientIdLabel,
    caseStatusId: caseStatusLabel,
    insertDate: dateLabel,
    insertDateFormatted: dateFormattedLabel,
    lastModifiedDate: modifiedDateLabel,
    lastModifiedDateFormatted: modifiedDateFormattedLabel,
    localizedCaseStatusDescription: descriptionLabel,
  };

  const customColumns: ColumnStyles<any> = {
    caseId: ({ value, item }) => (
      <TablePrimaryItem
        value={value}
        isDisabled={item.isSystem}
        onClick={() => {
          casecontext.handleAddNewCaseViewerCallBack(item.caseId);
        }}
      />
    ),
  };

  useEffect(() => {
    loadResources();
    const loadHistory = async () => {
      await getCustomerHistory();
    };
    loadHistory();
  }, []);

  // useEffect(() => {
  //   setupGridColumns();
  // }, [
  //   caseLabel,
  //   clientIdLabel,
  //   caseStatusLabel,
  //   dateLabel,
  //   dateFormattedLabel,
  //   modifiedDateLabel,
  //   modifiedDateFormattedLabel,
  //   descriptionLabel,
  // ]);
  
  return (
    <div className="container-fluid">
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row mt-3">
          {/* <div className="col">
            <TableContainer>
              <DataTableContainer>
                <DataTable
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                  onChangeRowsPerPage={handleRowsPerPageChanged}
                  responsive={true}
                  striped={true}
                  highlightOnHover={true}
                  pagination
                  paginationPerPage={10}
                  paginationComponentOptions={
                    TPGlobal.paginationComponentOptions
                  }
                  columns={gridColumns}
                  data={customerHistoryList}
                  noDataComponent={thereAreNoRecordsToShow}
                  sortFunction={TPGlobal.datatableCustomSort}
                  customStyles={tableStyles}
                  persistTableHead={true}
                />
              </DataTableContainer>
            </TableContainer>
          </div> */}
          <DynamicTable
            data={customerHistoryList}
            columnNames={customerTypeColumns}
            columnStyles={customColumns}
            hiddenColumns={["tpguidrow"]}
            noDataMessage={`${thereAreNoRecordsToShow}.`}
            // disableMinorOption={(item) => item.isSystem}
          />
        </div>
      </TPLoadingOverlay>
    </div>
  );
};

export default CustomerHistory;
