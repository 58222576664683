import styled from "styled-components";


interface TPWizardProps {
    active: boolean;
}

export const TPWizardContainer = styled.div<TPWizardProps>(
    ({ theme, active }) => `
  padding: 20px;
  .nav.nav-pills {
    position: relative;
    &:after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 3px;
      background-color: ${!active? theme.colors.wizard.step.backgroundColor:""};
      top: 35px;
      z-index: 1;
    }

    .nav-item {
      z-index: 2;

      .nav-link {
        display: flex;
        flex-direction: column;
        max-width: 150px;
        align-items: center;
        color: ${theme.colors.wizard.step.fontColor};

        .wizard-number {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${theme.colors.wizard.step.number.backgroundColor};
          color: ${theme.colors.wizard.step.number.fontColor};
          border: 1px solid ${theme.colors.wizard.step.number.borderColor};
          font-size: 17px;
        }

        .wizard-title {
          word-break: break-word;
          color: ${theme.colors.wizard.step.title.fontColor};;
        }

        &.active {
          background-color: ${theme.colors.wizard.step.active.backgroundColor};
          color: black;

          .wizard-number {
            border-color: ${theme.colors.wizard.step.active.number.borderColor};
            background-color: ${theme.colors.wizard.step.active.number.backgroundColor};
            color: ${theme.colors.wizard.step.active.number.fontColor};
          }
          .wizard-title {
            color: ${theme.colors.wizard.step.active.title.fontColor};
          }
        }
      }
    }
  }
`,
);
