import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse, RequestResponse } from "@/helpers/TPStandardResponse";

export class StructureService {
  serviceFileName: string = "StructureService.ts";

  public async insertStructure(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      let response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );

      return response.responseData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertStructure ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} insertStructure`);
      throw new Error(`Error ${this.serviceFileName} insertStructure`);
    }
  }

  public async getStructureById(
    recordId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure/" + recordId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getStructureById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getStructureById`);
      throw new Error(`Error ${this.serviceFileName} getStructureById`);
    }
  }

  public async getStructureList(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure?filterIsActive=" + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getStructureList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getStructureList`);
      throw new Error(`Error ${this.serviceFileName} getStructureList`);
    }
  }

  public async updateStructure(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      let response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateStructure ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} updateStructure`);
      throw new Error(`Error ${this.serviceFileName} updateStructure`);
    }
  }

  public async deleteStructure(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure/" + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteStructure ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} deleteStructure`);
      throw new Error(`Error ${this.serviceFileName} deleteStructure`);
    }
  }

  public async cloneStructure(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructure/clone";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      let response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} cloneStructure ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} cloneStructure`);
      throw new Error(`Error ${this.serviceFileName} cloneStructure`);
    }
  }

  public async getStructureDetails(
    eventLoadStructureId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string =
      "/eventloadstructuredetail/eventloadstructureId?filterIsActive=2&eventloadstructureId=" +
      eventLoadStructureId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getStructureDetails ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getStructureDetails`);
      throw new Error(`Error ${this.serviceFileName} getStructureDetails`);
    }
  }

  public async downloadStructureDetailsBy(
    eventLoadStructureId: string,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = false,
    expectedCodes: number[] = [200, 404]
  ): Promise<RequestResponse> {
    let httpclient = new TPHTTPService();

    let url: string = `/eventloadstructuredetail/eventloadstructureId?filterIsActive=2&eventloadstructureId=${eventLoadStructureId}`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex + url}`;

    let headers: TPKeyValue[] = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} downloadStructureDetailsBy ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} downloadStructureDetailsBy`);
      throw new Error(
        `Error ${this.serviceFileName} downloadStructureDetailsBy`
      );
    }
  }

  public async getStructureDetailById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructuredetail/" + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getStructureDetailById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getStructureDetailById`);
      throw new Error(`Error ${this.serviceFileName} getStructureDetailById`);
    }
  }

  public async addStructureDetails(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructuredetail";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} addStructureDetails ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} addStructureDetails`);
      throw new Error(`Error ${this.serviceFileName} addStructureDetails`);
    }
  }

  public async updateStructureDetails(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructuredetail";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateStructureDetails ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} updateStructureDetails`);
      throw new Error(`Error ${this.serviceFileName} updateStructureDetails`);
    }
  }

  public async deleteSturctureDetails(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string = "/eventloadstructuredetail/" + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteSturctureDetails ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} deleteSturctureDetails`);
      throw new Error(`Error ${this.serviceFileName} deleteSturctureDetails`);
    }
  }
}
