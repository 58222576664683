import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailboxesInputDTO } from "@/models/InboundMailboxes/InboundMailboxesInputDTO";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";

export class InboundMailboxesService {
  serviceFileName: string = "InboundMailboxesService.ts";

  public async getInboundMailboxesByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxesViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxesByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxesByFilter`,
      );
    }
  }

  public async getInboundMailboxById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<InboundMailboxesViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getInboundMailboxById`);
      throw new Error(`Error ${this.serviceFileName} getInboundMailboxById`);
    }
  }

  public async deleteInboundMailboxById(
    Id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox/" + Id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteInboundMailboxById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
      throw new Error(`Error ${this.serviceFileName} deleteInboundMailboxById`);
    }
  }

  public async insertInboundMailboxes(
    inputDTO: InboundMailboxesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertInboundMailboxes ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertInboundMailboxes`);
      throw new Error(`Error ${this.serviceFileName} insertInboundMailboxes`);
    }
  }

  public async updateInboundMailboxes(
    inputDTO: InboundMailboxesInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailbox";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateInboundMailboxes ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateInboundMailboxes`);
      throw new Error(`Error ${this.serviceFileName} updateInboundMailboxes`);
    }
  }
}
