import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { ReactElement, useId } from "react";
import { TPCheckboxContainer, TPCheckBoxStyled } from "./tpCheckboxStyles";

interface TPCheckBoxInterface {
  onChange?: Function;
  className?: string;
  checked: boolean;
  labelText?: string | JSX.Element;
  disabled?: boolean;
  labelStyle?: any;
  checkboxStyle?: any;
  id?: string;
  alignment?: "center" | "flex-start" | "flex-end" | "space-between";
}

const TPCheckBox = ({
  onChange,
  className = "",
  checked,
  labelText,
  disabled,
  labelStyle,
  checkboxStyle,
  alignment = "flex-start",
  id,
}: TPCheckBoxInterface): ReactElement => {
  const theId = `checkbox-${useId()}`;

  const handleOnChange = (e: any) => {
    onChange && onChange(e);
  };

  return (
    <TPCheckboxContainer
      style={{ justifyContent: alignment }}
      className={`form-check ${className}`}
    >
      <TPCheckBoxStyled
        className={`form-check-input`}
        checked={checked}
        onChange={handleOnChange}
        type="checkbox"
        id={id ? id : theId}
        disabled={disabled}
        style={checkboxStyle}
      />
      {labelText && (
        <TPLabel
          className="form-check-label"
          htmlFor={id ? id : theId}
          labelText={labelText}
          style={labelStyle}
        />
      )}
    </TPCheckboxContainer>
  );
};

export default TPCheckBox;
