export function formatNumberString(
  input: string,
  options: {
    minValue: string;
    maxValue: string;
    percentage: boolean;
    fixedDecimal: string;
    currencySymbol: string;
    currencyPlacement: string;
    decimalSeparator: string;
    thousandsSeparator: string;
  }
): string | null {
  const numberValue = parseFloat(input);

  const minValue = parseFloat(options.minValue);
  const maxValue = parseFloat(options.maxValue);

  if (isNaN(numberValue) || numberValue < minValue || numberValue > maxValue) {
    return null;
  }

  const fixedDecimal = parseInt(options.fixedDecimal, 10);
  const formattedNumber = numberValue.toFixed(fixedDecimal);

  const [integerPart, decimalPart] = formattedNumber.split(".");
  const formattedInteger = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  );

  const finalNumber = decimalPart
    ? `${formattedInteger}${options.decimalSeparator}${decimalPart}`
    : formattedInteger;

  const currency =
    options.currencyPlacement === "Before"
      ? `${options.currencySymbol}${finalNumber}`
      : `${finalNumber}${options.currencySymbol}`;

  return options.percentage ? `${currency}%` : currency;
}

export function parseFormattedNumber(
  formatted: string,
  options: {
    decimalSeparator: string;
    thousandsSeparator: string;
    currencySymbol: string;
  }
): number | null {
  let sanitized = formatted.replace(options.currencySymbol, "");
  sanitized = sanitized.replace(
    new RegExp(`\\${options.thousandsSeparator}`, "g"),
    ""
  );
  sanitized = sanitized.replace(options.decimalSeparator, ".");
  const parsedNumber = parseFloat(sanitized);
  return isNaN(parsedNumber) ? null : parsedNumber;
}
