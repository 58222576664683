import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPNumeric from "../bootstrap/forms/TPNumeric/TPNumeric";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataNumericProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: string;
  onValueChange?: Function | null;
  minValue: number;
  maxValue: number;
  currencySymbol: string;
  currencyPlacement: string;
  fixedDecimal: number;
  decimalSeparator: string;
  thousandSeparator: string;
};

const TPAdditionalDataNumeric = React.forwardRef(
  (
    {
      idControl,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory = false,
      defaultValue,
      onValueChange = null,
      maxValue,
      minValue,
      currencySymbol,
      currencyPlacement,
      fixedDecimal,
      decimalSeparator,
      thousandSeparator,
    }: TPAdditionalDataNumericProps,
    ref,
  ) => {
    const componentFileName: string = "TPAdditionalDataNumeric";
    const [additionalDataValue, setAdditionalDataValue] =
      useState(defaultValue);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
    const [
      invalidNumericRangeErrorMessage,
      setInvalidNumericRangeErrorMessage,
    ] = useState("");

    const handleOnChange = (value: string) => {
      setErrorMessageValue("");
      setAdditionalDataValue(value);
      if (onValueChange) {
        onValueChange(value, idControl);
      }
    };

    const loadResources = async () => {
      let resource: string;
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
      resource = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "invalidNumericRangeErrorMessage",
      );
      resource = resource
        .replace("{minValue}", minValue.toString())
        .replace("{maxValue}", maxValue.toString());
      setInvalidNumericRangeErrorMessage(resource);
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        return additionalDataValue;
      },
      validateFromParent() {
        const realValue = additionalDataValue
          ? additionalDataValue.toString()
          : "";
        if (isMandatory && realValue.trim() === "") {
          setErrorMessageValue(mandatoryErrorMessage);
          return false;
        }

        if (
          parseFloat(realValue) < minValue ||
          parseFloat(realValue) > maxValue
        ) {
          setErrorMessageValue(invalidNumericRangeErrorMessage);
          return false;
        }

        return true;
      },
    }));

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <TPNumeric
          id="IdTPNumeric"
          labelText={labelText}
          onChange={handleOnChange}
          value={additionalDataValue}
          currencySymbol={currencySymbol}
          currencyPlacement={currencyPlacement}
          fixedDecimal={fixedDecimal}
          decimalSeparator={decimalSeparator}
          isMandatory={isMandatory}
          thousandSeparator={thousandSeparator}
          errorMessage={errorMessageValue}
        ></TPNumeric>
      );
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        <TPNumeric
          id="IdTPNumeric"
          disabled={true}
          labelText={labelText}
          onChange={handleOnChange}
          value={additionalDataValue}
          currencySymbol={currencySymbol}
          currencyPlacement={currencyPlacement}
          fixedDecimal={fixedDecimal}
          decimalSeparator={decimalSeparator}
          isMandatory={isMandatory}
          thousandSeparator={thousandSeparator}
          errorMessage={errorMessageValue}
        ></TPNumeric>
      );
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo better render
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{additionalDataValue}</span>
            </div>
          </div>
        </React.Fragment>
        // <TPNumeric
        //   disabled={true}
        //   labelText={labelText}
        //   onChange={handleOnChange}
        //   value={additionalDataValue}
        //   currencySymbol={currencySymbol}
        //   currencyPlacement={currencyPlacement}
        //   fixedDecimal={fixedDecimal}
        //   decimalSeparator={decimalSeparator}
        //   isMandatory={isMandatory}
        //   thousandSeparator={thousandSeparator}
        //   errorMessage={errorMessageValue}
        // ></TPNumeric>
      );
    };

    useEffect(() => {
      //load validation error messages
      loadResources();
    }, []);

    useEffect(() => {
      setAdditionalDataValue(defaultValue);
    }, [defaultValue]);

    return (
      <>
        {modeUI == TPAddtionalDataUIModes.Collect
          ? renderCollectMode()
          : modeUI == TPAddtionalDataUIModes.View1
            ? renderViewMode1()
            : renderViewMode2()}
      </>
    );
  },
);

export default TPAdditionalDataNumeric;
