import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import {
  MenuItemViewModel,
  MenuItemViewModelForRender,
} from "@/models/MenuItems/MenuItemsModels";
import { MenuItemInputDTO } from "@/models/MenuItems/MenuItemInputDTO";

export class MenuItemsService {
  static serviceFileName: string = "MenuItemsService.ts";
  static baseUrl: string = `${TPGlobal.baseAPIGateway}${TPGlobal.baseAPITenant}`;
  static httpclient = new TPHTTPService();
  static headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  public static async getByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<MenuItemViewModel>> {
    const finalURL: string = `${MenuItemsService.baseUrl}${TPGlobal.tenantHex}/menuitem?FilterIsActive=${filterActive}`;

    let response: HTTPResponse;
    try {
      response = await MenuItemsService.httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders],
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log("MenuItemsService.ts getByFilter ex", TPLogType.ERROR, error);
      console.error("Error MenuItemsService.ts getByFilter");
      throw new Error(`Error ${this.serviceFileName} getByFilter`);
    }
  }

  public static async getMenuItemsByUser(
    userIdGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<MenuItemViewModelForRender>> {
    const finalURL: string = `${MenuItemsService.baseUrl}${TPGlobal.tenantHex}/menuitem/user/${userIdGuid}/get-active-items?LanguageId=${TPGlobal.language}&OrderFilter=A`;
    let response: HTTPResponse;
    try {
      response = await MenuItemsService.httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders],
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        "MenuItemsService.ts getMenuItemsByUser ex",
        TPLogType.ERROR,
        error,
      );
      console.error("Error MenuItemsService.ts getMenuItemsByUser");
      throw new Error(`Error ${this.serviceFileName}  getMenuItemsByUser`);
    }
  }

  public static async insert(
    inputDTO: MenuItemInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/menuitem";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insert ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insert`);
      throw new Error(`Error ${this.serviceFileName} insert`);
    }
  }

  public static async getById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<MenuItemViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/menuitem/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getById`);
      throw new Error(`Error ${this.serviceFileName} getById`);
    }
  }

  public static async delete(
    menuItemId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/menuitem/" + menuItemId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} delete ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} delete`);
      throw new Error(`Error ${this.serviceFileName} delete`);
    }
  }

  public static async update(
    inputDTO: MenuItemInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/menuitem";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} update ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} update`);
      throw new Error(`Error ${this.serviceFileName} update`);
    }
  }
}
