import React, { useEffect, useState } from "react";

import ParentFolder from "./ImagesAdminParentFolder";
import {
  ImagesAdminContainerFolderDivStyle,
  IcoFolderIStyle,
  MenuItemDivStyle,
  NivelDivStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { DataType } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";

type ContainerFolderProps = {
  folders: Array<RecursiveRoutes>;
};

type ContainerFolderArgs = {
  mode?: string;
  startFolder?: string;
};

const ContainerFolder = ({mode,startFolder}: ContainerFolderArgs) => {
  const componentFileName: string = "ContainerFolder.tsx";

  const [listFolderTemp, setListFolderTemp] = useState<Array<RecursiveRoutes>>([]);

  const value = useAppConctextImageAdmin();
  const {
    status,
    selectedItem,
    listFolderModel,
    handleSelected,
    handleInitializeFolders,
    containerFolderArgs,
    setContainerFolderArgs,
  } = value;

  useEffect(() => {
    setListFolderTemp(listFolderModel);   
  }, [listFolderModel,mode]);

  const handleActivateMenu = (event: any) => {
    event.preventDefault();
  };
  useEffect(() => {
    let temp = { ...containerFolderArgs };
    temp.mode = mode;
    temp.startFolder = startFolder;
    setContainerFolderArgs(temp)
}, [mode, startFolder]);

  return (
    <>
    {mode === "view" ? (
      // Render only active folders when in view mode
      <ImagesAdminContainerFolderDivStyle onContextMenu={handleActivateMenu}>
        {listFolderTemp.filter(folder => folder.isActive).map(folder => (
          <ParentFolder folders={folder} key={folder.id} />
        ))}
      </ImagesAdminContainerFolderDivStyle>
    ) :(
        <ImagesAdminContainerFolderDivStyle onContextMenu={handleActivateMenu}>
          {listFolderTemp.map(folder => (
            <ParentFolder folders={folder} key={folder.id} />
          ))}
        </ImagesAdminContainerFolderDivStyle>
        )}
    </>
  );
};

export default ContainerFolder;
