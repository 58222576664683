import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";

export class LocationLanguagesReminders {
  getLocationNameReminders: LocationNameReminders = new LocationNameReminders();




  public handleLocationNameReminders = async (resourceSet: string) => {
    this.getLocationNameReminders.title = await TPI18N.GetText(resourceSet, "TitleLabel");
    this.getLocationNameReminders.nameIdColumn = await TPI18N.GetText(resourceSet, "NameIdColumnLabel");
    this.getLocationNameReminders.stateColumn = await TPI18N.GetText(resourceSet, "StateColumnLabel");
    this.getLocationNameReminders.caseNumberColumn = await TPI18N.GetText(resourceSet, "CaseNumberColumn");
    this.getLocationNameReminders.customerTypeColumn = await TPI18N.GetText(resourceSet, "CustomerTypeColumn");
    this.getLocationNameReminders.customerColumn = await TPI18N.GetText(resourceSet, "CustomerColumnLabel");
    this.getLocationNameReminders.commentsColumn = await TPI18N.GetText(resourceSet, "CommentsColumnLabel");
    this.getLocationNameReminders.reminderDateColumn = await TPI18N.GetText(resourceSet, "ReminderDateColumnLabel");
    this.getLocationNameReminders.reminderColumn = await TPI18N.GetText(resourceSet, "ReminderColumnLabel");
    this.getLocationNameReminders.customerStateColumn = await TPI18N.GetText(resourceSet, "CustomerStateColumnLabel");

    this.getLocationNameReminders.confirmAlertTitle = await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle");
    this.getLocationNameReminders.okAlertButton = await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton");
    this.getLocationNameReminders.cancelAlertButton = await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton");
    this.getLocationNameReminders.deleteLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel");
    this.getLocationNameReminders.updateLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel");
    this.getLocationNameReminders.buikLoad = await TPI18N.GetText(TPGlobal.globalResourceSet, "BuikLoad");
    this.getLocationNameReminders.search = await TPI18N.GetText(TPGlobal.globalResourceSet, "Search");
    this.getLocationNameReminders.titleModalDelete = await TPI18N.GetText(TPGlobal.globalResourceSet, "TitleModalDeleteLabel");
    this.getLocationNameReminders.thereAreNoRecordsToShow = await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableNoCurrentData");
    this.getLocationNameReminders.refreshLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton");
    this.getLocationNameReminders.exportLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton");




  };

}

export class LocationNameReminders {

  title: string = "";
  nameIdColumn: string = "";
  customerStateColumn: string = "";
  stateColumn: string = "";
  caseNumberColumn: string = "";
  customerTypeColumn: string = "";
  customerColumn: string = "";
  commentsColumn: string = "";
  reminderDateColumn: string = "";
  reminderColumn: string = "";


  exportLabel: string = "";
  refreshLabel: string = "";
  confirmAlertTitle: string = "";
  okAlertButton: string = "";
  cancelAlertButton: string = "";
  deleteLabel: string = "";
  updateLabel: string = "";
  buikLoad: string = "";
  search: string = "";
  titleModalDelete: string = "";
  thereAreNoRecordsToShow: string = "";



}


