import { AttachmentType } from "@/models/Global/TPGlobalEnums";
import { DownloadFileService } from "@/services/DownloadFileService";
import { StorageService } from "@/services/StorageService";
import { TPLog, TPLogType } from "./TPLog";

const componentFileName = "DownloadEML.ts"

const getFileUriSas = async (storageIdEML: string) => {
  const storageService = new StorageService();
  let expectedCodes: Array<number> = [200];
  try {
    let responseRequest = await storageService.getFileUriSas(
      storageIdEML,
      AttachmentType.MAILREADEREML,
      false,
      true,
      expectedCodes,
    );
    return responseRequest;
  } catch (error) {
    TPLog.Log(
      `Error ${componentFileName} getFileUriSas ex`,
      TPLogType.ERROR,
      error,
    );
    console.error(`Error ${componentFileName} getFileUriSas ex`);
    return null;
  }
};

export const downloadEML = async (storageIdEML: string) => {
  const downloadFileService = new DownloadFileService();
  const res = await getFileUriSas(storageIdEML);
  try {
    if (res) {
      const { sasUri, originalFileName, mimeType } = res;
      await downloadFileService.downloadFileUri({
        // isProxy: true,
        sasUri,
        originalFileName,
        mimeType,
      });
    }
  } catch (error) {
    TPLog.Log(
      `Error ${componentFileName} handleDownloadEML ex`,
      TPLogType.ERROR,
      error,
    );
    console.error(`Error ${componentFileName} handleDownloadEML ex`);
  }
};