import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { AdditionalDataTypeViewModel } from "@/models/AdditionalDataType/AdditionalDataTypeModels";

export class AdditionalDataTypeService {
  serviceFileName: string = "AdditionalDataTypeService.ts";
  public async getByFilterIsActive(
    filterIsActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AdditionalDataTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/additionaldatatype?FilterIsActive=${filterIsActive}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByFilterIsActive ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByFilterIsActive`);
      throw new Error(`Error ${this.serviceFileName} getByFilterIsActive`);
    }
  }
}
