import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const resourceSet = "MergeCustomers";

const useLabels = function () {
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);
  const [labels, setLabels] = useState<any>({});

  const loadLabels = async function () {
    setLabels({
      MenuItem: await TPI18N.GetText(resourceSet, "MenuItemLabel"),
      PageTitle: await TPI18N.GetText(resourceSet, "PageTitle"),
      MainCustomerRecord: await TPI18N.GetText(
        resourceSet,
        "MainCustomerRecord",
      ),
      MergeCustomerRecord: await TPI18N.GetText(
        resourceSet,
        "MergeCustomerRecord",
      ),
      CompareCustomers: await TPI18N.GetText(resourceSet, "CompareCustomers"),
      SearchBy: await TPI18N.GetText(resourceSet, "SearchBy"),
      Search: await TPI18N.GetText(resourceSet, "Search"),
      NoResults: await TPI18N.GetText(resourceSet, "NoResults"),
      SearchResultsBy: await TPI18N.GetText(resourceSet, "SearchResultsBy"),
      MergeAll: await TPI18N.GetText(resourceSet, "MergeAll"),
      GoBack: await TPI18N.GetText(resourceSet, "GoBack"),
      Merge: await TPI18N.GetText(resourceSet, "Merge"),
      Conflict: await TPI18N.GetText(resourceSet, "Conflict"),
      NewValue: await TPI18N.GetText(resourceSet, "NewValue"),
      Cancel: await TPI18N.GetText(resourceSet, "Cancel"),
      Confirm: await TPI18N.GetText(resourceSet, "Confirm"),
      OnlyOneListItem: await TPI18N.GetText(resourceSet, "OnlyOneListItem"),
      AllCasesListItem: await TPI18N.GetText(resourceSet, "AllCasesListItem"),
      IrreversibleListItem: await TPI18N.GetText(
        resourceSet,
        "IrreversibleListItem",
      ),
      AreYouSure: await TPI18N.GetText(resourceSet, "AreYouSure"),
      Person: await TPI18N.GetText(resourceSet, "Person"),
      Contacts: await TPI18N.GetText(resourceSet, "Contacts"),
      Products: await TPI18N.GetText(resourceSet, "Products"),
      PreferredAgents: await TPI18N.GetText(resourceSet, "PreferredAgents"),
      Main: await TPI18N.GetText(resourceSet, "Main"),
      Alternate: await TPI18N.GetText(resourceSet, "Alternate"),
      Custom: await TPI18N.GetText(resourceSet, "Custom"),
      Concatenate: await TPI18N.GetText(resourceSet, "Concatenate"),
      Save: await TPI18N.GetText(resourceSet, "Save"),
      Comments: await TPI18N.GetText(resourceSet, "Comments"),
      ContactId: await TPI18N.GetText(resourceSet, "ContactId"),
      UserId: await TPI18N.GetText(resourceSet, "UserId"),
      AgentName: await TPI18N.GetText(resourceSet, "AgentName"),
      AgentLogin: await TPI18N.GetText(resourceSet, "AgentLogin"),
      InsertDate: await TPI18N.GetText(resourceSet, "InsertDate"),
      Priority: await TPI18N.GetText(resourceSet, "Priority"),
      id: await TPI18N.GetText(resourceSet, "id"),
      documentType: await TPI18N.GetText(resourceSet, "documentType"),
      document: await TPI18N.GetText(resourceSet, "document"),
      name: await TPI18N.GetText(resourceSet, "name"),
      lastName: await TPI18N.GetText(resourceSet, "lastName"),
      additionalInfo: await TPI18N.GetText(resourceSet, "additionalInfo"),
      isActive: await TPI18N.GetText(resourceSet, "isActive"),
      typist_Guid: await TPI18N.GetText(resourceSet, "typist_Guid"),
      typist_FullName: await TPI18N.GetText(resourceSet, "typist_FullName"),
      typist_Initials: await TPI18N.GetText(resourceSet, "typist_Initials"),
      dateUpdate: await TPI18N.GetText(resourceSet, "dateUpdate"),
      cRCDocument: await TPI18N.GetText(resourceSet, "cRCDocument"),
      subsidiaryId: await TPI18N.GetText(resourceSet, "subsidiaryId"),
      internalCode: await TPI18N.GetText(resourceSet, "internalCode"),
      subsidiaryName: await TPI18N.GetText(resourceSet, "subsidiaryName"),
      preferredAddress: await TPI18N.GetText(resourceSet, "preferredAddress"),
      preferredAddressInfo: await TPI18N.GetText(
        resourceSet,
        "preferredAddressInfo",
      ),
      preferredPhone: await TPI18N.GetText(resourceSet, "preferredPhone"),
      preferredEmail: await TPI18N.GetText(resourceSet, "preferredEmail"),
      geography: await TPI18N.GetText(resourceSet, "geography"),
      subsidiaryOrganizationId: await TPI18N.GetText(
        resourceSet,
        "subsidiaryOrganizationId",
      ),
      regionId: await TPI18N.GetText(resourceSet, "regionId"),
      channelId: await TPI18N.GetText(resourceSet, "channelId"),
      organizationId: await TPI18N.GetText(resourceSet, "organizationId"),
      organizationName: await TPI18N.GetText(resourceSet, "organizationName"),
      organizationImage: await TPI18N.GetText(resourceSet, "organizationImage"),
      customerTypeId: await TPI18N.GetText(resourceSet, "customerTypeId"),
      relationId: await TPI18N.GetText(resourceSet, "relationId"),
      customerName: await TPI18N.GetText(resourceSet, "customerName"),
      customerTypeDescription: await TPI18N.GetText(
        resourceSet,
        "customerTypeDescription",
      ),
      documentTypeDescription: await TPI18N.GetText(
        resourceSet,
        "documentTypeDescription",
      ),
      MainSameAsMerge: await TPI18N.GetText(resourceSet, "MainSameAsMerge"),
    });
    setLabelsLoaded(true);
  };
  useEffect(() => {
    if (!labelsLoaded) loadLabels();
  });
  return { labels, labelsLoaded };
};

export default useLabels;
