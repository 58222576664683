import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { RolePermissionViewModel } from "@/models/Roles/RolesViewModel";

export class RolePermissionService {
  serviceFileName: string = "RolePermissionService.ts";
  public getRolePermissionsByRoleId = async (
    roleId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<RolePermissionViewModel>> => {
    let httpclient = new TPHTTPService();
    let url: string = `rolepermission/assigned-unassigned/role/${roleId}`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getRolePermissionsByRoleId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getRolePermissionsByRoleId`);
      throw new Error(
        `Error ${this.serviceFileName} getRolePermissionsByRoleId`,
      );
    }
  };

  public deletePermission = async (
    roleId: string,
    permissionGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> => {
    let httpclient = new TPHTTPService();
    let url: string = `rolepermission/role/${roleId}/permission/${permissionGuid}`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deletePermission ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deletePermission`);
      throw new Error(`Error ${this.serviceFileName} deletePermission`);
    }
  };

  public assignPermission = async (
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> => {
    let httpclient = new TPHTTPService();
    let url: string = `rolepermission`;

    let finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/${url}`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignPermission ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} assignPermission`);
      throw new Error(`Error ${this.serviceFileName} assignPermission`);
    }
  };
}
