import { StateModel } from "../../../redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MainItemTabType from "../MainMenuItemTabType";

export interface HorizontalTabsStateModel extends StateModel {
  tabs: Array<MainItemTabType>;
  focus: string;
}

export const HorizontalTabsSlice = createSlice({
  name: "HorizontalTabs",
  initialState: {
    result: {},
  } as HorizontalTabsStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        result: { action },
      }) as HorizontalTabsStateModel,
    update: (
      state: HorizontalTabsStateModel,
      action: PayloadAction<Array<MainItemTabType>>,
    ) =>
      ({
        ...state,
        tabs: action.payload.map((t) => structuredClone(t)),
        focus: action.payload.find(({ tabIsActive }) => tabIsActive)?.tabType,
        result: { action },
      }) as HorizontalTabsStateModel,
  },
});
