/**
 * child component events
 */
export interface ProfilesRolesEvents {
  /**
   * allows you to reload the data source
   */
  refreshGridFromParent: () => void;
}

/**
 * properties that the child component needs
 */
export interface ProfilesRolesProps {
  /**
   * vertical tab event runner
   */
  callBackCommands: Function;
}

/**
 * horizontal tab item model
 */
export enum ProfilesRolesTab {
  /**
   * Profiles
   */
  Profiles,
  /**
   * Roles
   */
  Roles,
}
/**
 * profiles roles enum
 */
export enum ProfilesRolesEnum {
  /**
   * name
   */
  name = "ProfilesRoles",
  /**
   * file name
   */
  fileName = "ProfilesRoles.tsx",
  /**
   * component name
   */
  component = "ProfilesRolesComponent",
  /**
   * tab profiles label
   */
  TabProfilesLabel = "TabProfilesLabel",
  /**
   * tab roles label
   */
  TabRolesLabel = "TabRolesLabel",
}

/**
 * Extract the string values of ProfilesRolesEnum
 */
type ProfilesRolesEnumValues = `${ProfilesRolesEnum}`;

/**
 * messages
 *
 * represent an object with all the labels and messages that will be displayed in the window
 */
export type Messages = Partial<Record<ProfilesRolesEnumValues, string>>;
/**
 * profiles and roles tab
 */
export interface ProfilesRolesTabItem {
  /**
   * identifier
   */
  id: string;
  /**
   * label
   */
  label?: string;
  /**
   * icon
   */
  icon?: string;
  /**
   * element
   */
  element?: any;
  /**
   * tsx
   */
  type: ProfilesRolesTab;
}
/**
 * profiles roles state
 */
export interface ProfilesRolesState {
  /**
   * represent an object with all the labels and messages that will be displayed in the window
   */
  m: Messages;
  /**
   * tab selected
   */
  tabs: ProfilesRolesTabItem[];
  /**
   * focused tab  identifier
   */
  focus: string;
}

/**
 * profiles and roles initial state
 */
export const profilesRolesInitialState: ProfilesRolesState = {
  m: {},
  tabs: [],
  focus: "",
};

/**
 * profiles roles state event
 */
export enum ProfilesRolesStateEvent {
  /**
   * set messages
   */
  setMessages,
  /**
   * add default tabs
   */
  addDefaultTabs,
  /**
   * add tab
   */
  addTab,
  /**
   * remove tab
   */
  removeTab,
  /**
   * change tab
   */
  changeTab,
  /**
   * clean
   */
  clean,
}

/**
 * profiles roles action
 */
export interface ProfilesRolesAction {
  /**
   * type of action
   */
  type: ProfilesRolesStateEvent;
  /**
   * payload
   */
  payload?: any;
}
