import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ListItemModel } from "@/models/ListAdministration/ListAdministrationModels";
import { FC, useState } from "react";
import '../assets/Styles.css';

interface ListAdminDraggableItemProperties {
  item: ListItemModel,
  onUpdateRequest?: () => void;
  onCheck?: (checked: boolean) => void;
  onDelete?: Function;
  style?: any
  enabled?: boolean;
}

const ListAdminDraggableItem: FC<ListAdminDraggableItemProperties> = function ({ item, style, onUpdateRequest, onDelete, onCheck, enabled=true }) {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const handleUpdateRequest = function () {
    onUpdateRequest && onUpdateRequest();
    setMenuVisible(false);
  }
  return (
    <div className="draggable-list-item" style={style}>
      <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <button
            type="button"
            style={{ border: 'none', background: 'none' }}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <TPIcon iconType={TPIconTypes.moreVert} />
          </button>
          <div
            className="draggable-menu"
            onMouseLeave={() => setMenuVisible(false)}
            style={{ display: menuVisible ? 'block' : 'none' }}
          >
            <button
              type="button"
              className="draggable-menu-item"
              onClick={() => handleUpdateRequest()}
            >
              <label style={{ pointerEvents: 'none' }}>EDIT</label>
              <TPIcon iconType={TPIconTypes.edit} />
            </button>
            <button
              type="button"
              className="draggable-menu-item"
              onClick={() => onDelete && onDelete()}
            >
              <label style={{ pointerEvents: 'none' }}>DELETE</label>
              <TPIcon iconType={TPIconTypes.delete} />
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
        <TPCheckBox checked={item.isActive} onChange={() => onCheck && onCheck(!item.isActive)} />
      </div>
      <div style={{ width: '15%', overflow: 'hidden' }}>
        {item.id}
      </div>
      <div style={{ width: '58%', overflow: 'hidden' }}>
        {item.description}
      </div>
      {enabled &&
      <div style={{ width: '2%', overflow: 'hidden' }}>
        <TPIcon iconType={TPIconTypes.mdDragIndicator} />
      </div>}
    </div>
  )
}

export default ListAdminDraggableItem;