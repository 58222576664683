const MenuExpandedIcon = ({ ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: `<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 12.8133L22.12 14.6667L15.44 8L22.12 1.33333L24 3.18667L19.2533 8L24 12.8133ZM0 0H17.3333V2.66667H0V0ZM0 9.33333V6.66667H13.3333V9.33333H0ZM0 16V13.3333H17.3333V16H0Z" fill="black"/>
                </svg>               
      `,
      }}
    ></div>
  );
};

export default MenuExpandedIcon;
