const WorkingDotIcon = ({ ...props }) => {
    return (
        <div 
            {...props}
            dangerouslySetInnerHTML={{
                __html: `<svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="6" cy="6" r="6" fill="#780096"/>
</svg>
`,
            }}
        ></div>
    )
}

export default WorkingDotIcon;