import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  TableHeaderContainer,
  TableSearchContainer,
  TableToolbar,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { HotTable } from "@handsontable/react";
import * as FileSaver from "file-saver";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { TableIcon } from "../Projects/ProjectDetail/ProjectDetailTableIcons";

// register Handsontable's modules
registerAllModules();

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<WorkflowTypeViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface WorkflowTypeAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const WorkflowTypeAdmin = React.forwardRef(
  ({ callBackCommands }: WorkflowTypeAdminInterface, ref) => {
    const componentFileName: string = "WorkflowTypeAdmin.tsx";
    //Functions called form parent WorkflowTypeAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));
    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "WorkflowTypeAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [importLabel, setImportLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");

    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [organizationColumnLabel, setOrganizationColumnLabel] = useState("");
    const [customerSLAColumnLabel, setCustomerSLAColumnLabel] = useState("");
    const [isDirectSolutionColumnLabel, setIsDirectSolutionColumnLabel] =
      useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isCasesUsedColumnLabel, setIsCasesUsedColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const [isHandsOnActive, setIsHandsOnActive] = useState<boolean>(false);
    const [renderedData, setRenderedData] = useState({
      id: "",
      description: "",
      promiseLimit: "",
      isDirectSolution: "",
      isActive: "",
      isCasesUsed: "",
    });
    //#endregion

    const loadResourcesAndOrganizationsRelationsFilter = async () => {
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );

      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setCloneLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setImportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ImportLabel")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setOrganizationColumnLabel(
        await TPI18N.GetText(ResourceSet, "Organization")
      );
      setDescriptionColumnLabel(
        await TPI18N.GetText(ResourceSet, "Description")
      );
      setCustomerSLAColumnLabel(
        await TPI18N.GetText(ResourceSet, "CustomerSLA")
      );
      setIsDirectSolutionColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsDirectSolution")
      );
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      setIsCasesUsedColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsCasesUsedColumnLabel")
      );

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      setFilterKeyValue([
        {
          key: TPActiveOptions.ALL.toString(),
          value: await TPI18N.GetText(ResourceSet, "All"),
        },
        {
          key: TPActiveOptions.ACTIVE.toString(),
          value: await TPI18N.GetText(ResourceSet, "Active"),
        },
        {
          key: TPActiveOptions.INACTIVE.toString(),
          value: await TPI18N.GetText(ResourceSet, "Inactive"),
        },
      ]);
    };

    const handleHandsOnTable = (row: any) => {
      setIsHandsOnActive(!isHandsOnActive);
      setRenderedData(row);
    };

    //Get organizations relations by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getByFilterIsActive(
          selectedFilter,
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Organization Relation
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      setIsHandsOnActive(false);
      reloadGridCommand();
    };

    function handleOnClickImportButton() {
      callBackCommands({ command: "import" });
    }

    //Update organization relation
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Clone organization relation
    const handleCloneClick = (id: string) => {
      let command: any = { command: "clone", recordId: id };
      callBackCommands(command);
    };

    function handleOnClickExportButton(element: any) {
      callBackCommands({ command: "export", payload: element });
    }

    //Modal Question to delete organization relation
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete organization relation after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new WorkflowTypeService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.delete(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
      if (event === TPIconTypes.import) handleOnClickImportButton();
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<WorkflowTypeViewModel> = {
      concatenatedPromise: customerSLAColumnLabel,
      isDirectSolution: isDirectSolutionColumnLabel,
      isActive: isActiveColumnLabel,
      isCasesUsed: isCasesUsedColumnLabel,
    };

    const customColumns: ColumnStyles<WorkflowTypeViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          // isDisabled={item.isSystemRecord}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isDirectSolution: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
      isCasesUsed: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    const tableIcons: TableIcon[] = [
      {
        type: TPIconTypes.import,
        tooltip: "Import",
        status: true,
      },
    ];

    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string }) => handleDeleteClick(e.id),
      },
      {
        key: cloneLabel,
        type: "clone",
        icon: TPIconTypes.clone,
        onOptionChange: (e: { id: string }) => handleCloneClick(e.id),
      },
      {
        key: exportLabel,
        type: "export",
        icon: TPIconTypes.import,
        onOptionChange: (e: { id: string }) => handleOnClickExportButton(e),
      },
    ];
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];

    const transformedData = adminState?.gridData?.map((item) => ({
      ...item,
      concatenatedPromise:
        item.promiseLimit && item.promiseLimitUnit
          ? `${item.promiseLimit}${item.promiseLimitUnit.toLocaleUpperCase()}`
          : "",
    }));

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizationsRelationsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded, isHandsOnActive]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          {!isHandsOnActive ? (
            <div>
              <div className="row">
                <div className="col">
                  <CIMTitleSection>
                    <TPPageTitle style={{ margin: "0" }}>
                      {titleLabel}
                    </TPPageTitle>
                    <TPButton
                      id="new-additional-data-type"
                      isDesignSystem={true}
                      onClick={handleNewClick}
                      withIcon={TPIconTypes.add}
                      orientationIcon="left"
                      style={{ padding: "1px 18px" }}
                    >
                      {newLabel}
                    </TPButton>
                  </CIMTitleSection>
                  <DynamicTable
                    data={transformedData}
                    columnNames={customerTypeColumns}
                    columnStyles={customColumns}
                    icons={tableIcons}
                    hiddenColumns={[
                      "promiseLimitUnit",
                      "promiseLimit",
                      "isSystemRecord",
                      "localizedDescription",
                      "hasDetails",
                    ]}
                    minorOptions={minorOptions}
                    noDataMessage={`${thereAreNoRecordsToShow}.`}
                    disableMinorOption={(item) => item.isSystemRecord}
                    additionalFilters={additionalFilters}
                    onIconClicked={(event) => handleIconClick(event)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
                Export and Bulk Load Workflow
              </h5>
              <h6>Workflow Information</h6>
              <HotTable
                data={[
                  [
                    renderedData.id,
                    renderedData.description,
                    renderedData.promiseLimit,
                    renderedData.isDirectSolution,
                    renderedData.isActive,
                    renderedData.isCasesUsed,
                  ],
                ]}
                rowHeaders={true}
                colHeaders={[
                  "Id",
                  "Description",
                  "Customer SLA",
                  "Is it direct solution?",
                  "Active?",
                  "Used in Cases",
                ]}
                // colHeaders={true}
                height="auto"
                width="auto"
                autoWrapRow={true}
                autoWrapCol={true}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                contextMenu={["copy", "cut"]}
              />
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => handleRefreshClick()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
          )}
        </TPLoadingOverlay>
      </>
    );
  }
);

export default WorkflowTypeAdmin;
