import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface ReassignTaskInputDTO {
  taskId: number;
  newResponsibleGuidUser: string;
  report: string;
  [x: string]: any;
}

export class ReassignTaskInputDTOValidator extends Validator<ReassignTaskInputDTO> {
  constructor() {
    super();
    const resourceSet = "ReassignTaskInputDTOValidator";

    this.ruleFor("taskId")
      .notNull()
      .withMessage(resourceSet + "|InputDTOTaskIdEmpty")
      .greaterThan(0)
      .withMessage(resourceSet + "|InputDTOTaskIdGreaterThan");

    this.ruleFor("newResponsibleGuidUser")
      .notEmpty()
      .withMessage(resourceSet + "|" + "InputDTONewResponsibleGuidUserEmpty")
      .maxLength(50)
      .withMessage(
        resourceSet + "|" + "InputDTOInvalidNewResponsibleGuidUserLength",
      )
      .must((id) => {
        return TPGlobal.TPSanitize(id) == id;
      })
      .withMessage(resourceSet + "|InputDTOInvalidNewResponsibleGuidUser");

    this.ruleFor("report")
      .notEmpty()
      .withMessage(resourceSet + "|" + "InputDTOReportEmpty")
      .maxLength(4000)
      .withMessage(resourceSet + "|" + "InputDTOInvalidReportLength")
      .must((x) => {
        return TPGlobal.TPSanitize(x) == x;
      })
      .withMessage(resourceSet + "|InputDTOInvalidReport");
  }
}
