import NoDataDash from "@/assets/svgs/no-data-dash.svg";
import { StyledNoDataImage } from "@/modules/core/components/dynamic-table/dynamic-table-styles";
import { TPI18N } from "@/services/I18nService";
import { ReactElement, useEffect, useState } from "react";
import { CockpitNoDataLabels, CockpitView } from "../supervisor-cockpit.model";

type CockpitNoDataProps = {
  view?: CockpitView;
  withAssignation?: boolean;
};

function CockpitNoData({ view = "team", withAssignation }: CockpitNoDataProps): ReactElement {
  const component: string = "CockpitNoData";
  const imageUrl = NoDataDash;

  const [componentLabels, setComponentLabels] = useState<CockpitNoDataLabels>(
    {} as CockpitNoDataLabels
  );

  const loadUtilsResources = async () => {
    setComponentLabels({
      baseMessage: await TPI18N.GetText("StructureAdminUpdateInsertDetails", "NoDataLabel"),
      team: await TPI18N.GetText(component, "Team"),
      group: await TPI18N.GetText(component, "Group"),
      event: await TPI18N.GetText(component, "Event"),
      assignation: await TPI18N.GetText(component, "Assignation"),
    });
  };

  useEffect(() => {
    loadUtilsResources().then();
  }, [])

  return (
    <StyledNoDataImage>
      <img src={imageUrl} alt="no-data" />
      <p className="base">
        {componentLabels.baseMessage},
        <br />
        <strong>{withAssignation && view !== "team" ? componentLabels.assignation : componentLabels[view]}</strong>
      </p>
    </StyledNoDataImage>
  );
}

export default CockpitNoData;
