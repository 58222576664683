import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from "react";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPI18N } from "@/services/I18nService";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  customStateListInitialState,
  CustomStateListState,
  CustomStateAdminEnum as e,
  CustomStateListStateEvent,
  CustomStateAdminProps,
  CustomStateAdminEvents,
  CustomStateCommandType,
  CustomStateAdminTableEvents,
  CustomStateModel,
  CustomStateListModel,
} from "@/models/CustomState/CustomState";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import { Action, Event, Sa } from "@/models/Global/TPGlobalModels";
import CustomStateAdminTable from "./CustomStateAdminTable";
import { CustomStateService } from "@/services/CustomStateService";

/**
 * STYLES
 */
const styles = {
  column: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  sectionTitle: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    placeContent: "center space-between",
    alignItems: "center",
    marginBottom: "30px",
  } as CSSProperties,
  title: {
    textTransform: "none",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of custom state
 */
const CustomStateAdmin = forwardRef(
  (
    {
      key,
      id,
      style,
      workflowDefinitionId,
      pageDispatch,
    }: CustomStateAdminProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * state of the component
     */
    const [state, componentDispatch] = useReducer(
      componentCommand,
      customStateListInitialState
    );
    /**
     * table reference
     */
    const tableRef = useRef<CustomStateAdminTableEvents>();
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {
            loadDataSource();
          },
        }) as CustomStateAdminEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources();
      loadDataSource();
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * command of the component
     */
    function componentCommand(s: CustomStateListState, action: Action<any>) {
      switch (action.type) {
        case CustomStateListStateEvent.getMessage:
          return (() => {
            const messages = { ...s.messages };
            messages[action.payload] = Sa.loading<string>();

            return {
              ...s,
              messages,
            } as CustomStateListState;
          })();
        case CustomStateListStateEvent.getMessageSuccess:
          return (() => {
            const messages = { ...s.messages };
            messages[action.payload.attribute].setValue(action.payload.message);

            return {
              ...s,
              messages,
            } as CustomStateListState;
          })();
        case CustomStateListStateEvent.getMessageError:
          return (() => {
            const messages = { ...s.messages };
            messages[action.payload.attribute].setError(action.payload.error);

            return {
              ...s,
              messages,
            } as CustomStateListState;
          })();
        case CustomStateListStateEvent.find:
          return (() => {
            const datasource = Sa.loading<Array<CustomStateListModel>>([]);

            return {
              ...s,
              datasource,
            } as CustomStateListState;
          })();
        case CustomStateListStateEvent.findSuccess:
          return (() => {
            const datasource = s.datasource;
            datasource.setValue(action.payload);

            return {
              ...s,
              datasource,
            } as CustomStateListState;
          })();
        case CustomStateListStateEvent.findError:
          const datasource = s.datasource;
          datasource.setError(action.payload);

          return {
            ...s,
            datasource,
          } as CustomStateListState;
        default:
          return s;
      }
    }
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
      const componentMessage = [
        e.TitleLabel,
        e.NewButtonLabel,
        e.ActionsTableColumnLabel,
        e.IdTableColumnLabel,
        e.OrderTableColumnLabel,
        e.BaseField1TableColumnLabel,
        e.ReferenceValue1TableColumnLabel,
        e.BaseField2TableColumnLabel,
        e.CustomStateTableColumnLabel,
        e.WeightTableColumnLabel,
        e.QuestionModalLabel,
      ];
      const globalMessage = [
        e.SearchInputLabel,
        e.ExportButtonLabel,
        e.RefreshButtonLabel,
        e.DeleteActionLabel,
        e.UpdateActionLabel,
        e.CloneActionLabel,
        e.titleModalLabel,
        e.YesModalButtonLabel,
        e.NoModalButtonLabel,
      ];

      [
        { a: e.TitleLabel, v: "Custom State" },
        { a: e.NewButtonLabel, v: "New Custom State" },
        { a: e.ActionsTableColumnLabel, v: "Actions" },
        { a: e.IdTableColumnLabel, v: "Id" },
        { a: e.OrderTableColumnLabel, v: "Order" },
        { a: e.BaseField1TableColumnLabel, v: "Task Type" },
        { a: e.ReferenceValue1TableColumnLabel, v: "Task Status" },
        { a: e.BaseField2TableColumnLabel, v: "And Case Status" },
        { a: e.CustomStateTableColumnLabel, v: "Custom State" },
        { a: e.WeightTableColumnLabel, v: "Weight" },
        {
          a: e.QuestionModalLabel,
          v: "Are you sure you want to delete the selected record?",
        },
      ].forEach(({ a: attribute, v: message }) => {
        componentDispatch({
          type: CustomStateListStateEvent.getMessage,
          payload: attribute,
        } as Action<CustomStateListStateEvent>);
        componentDispatch({
          type: CustomStateListStateEvent.getMessageSuccess,
          payload: { attribute, message },
        } as Action<CustomStateListStateEvent>);
      });
      // componentMessage.forEach((attribute) => {
      //   componentDispatch({
      //     type: CustomStateListStateEvent.getMessage,
      //     payload: attribute,
      //   } as Action<CustomStateListStateEvent>);
      //   TPI18N.GetText(e.CustomStateAdminComponent, attribute)
      //     .then((message) =>
      //       componentDispatch({
      //         type: CustomStateListStateEvent.getMessageSuccess,
      //         payload: { attribute, message },
      //       } as Action<CustomStateListStateEvent>)
      //     )
      //     .catch((error) =>
      //       componentDispatch({
      //         type: CustomStateListStateEvent.getMessageError,
      //         payload: { attribute, error },
      //       } as Action<CustomStateListStateEvent>)
      //     );
      // });
      globalMessage.forEach((attribute) => {
        componentDispatch({
          type: CustomStateListStateEvent.getMessage,
          payload: attribute,
        } as Action<CustomStateListStateEvent>);
        TPI18N.GetText(TPGlobal.globalResourceSet, attribute)
          .then((message) =>
            componentDispatch({
              type: CustomStateListStateEvent.getMessageSuccess,
              payload: { attribute, message },
            } as Action<CustomStateListStateEvent>)
          )
          .catch((error) =>
            componentDispatch({
              type: CustomStateListStateEvent.getMessageError,
              payload: { attribute, error },
            } as Action<CustomStateListStateEvent>)
          );
      });
    }

    function loadDataSource() {
      componentDispatch({
        type: CustomStateListStateEvent.find,
      } as Action<CustomStateListStateEvent>);
      const { find } = new CustomStateService();
      find(workflowDefinitionId)
        .then((payload) => {
          componentDispatch({
            type: CustomStateListStateEvent.findSuccess,
            payload,
          } as Action<CustomStateListStateEvent>);
        })
        .catch((payload) =>
          componentDispatch({
            type: CustomStateListStateEvent.findError,
            payload,
          } as Action<CustomStateListStateEvent>)
        );
    }

    function onClickButtonNewHandler() {
      pageDispatch({ type: CustomStateCommandType.redirectToNew });
    }

    return (
      <TPLoadingOverlay
        styles={style}
        active={
          !state.datasource.event || state.datasource.event === Event.loading
        }
      >
        <div style={styles.column}>
          <div style={styles.sectionTitle}>
            <TPPageTitle style={styles.title}>
              {`${state.messages?.[e.TitleLabel]?.value ?? ""}`}
            </TPPageTitle>

            <TPButton
              isDesignSystem={true}
              customType={ButtonCustomType.primary}
              withIcon={TPIconTypes.add}
              orientationIcon="left"
              onClick={onClickButtonNewHandler}
            >
              {`${state.messages?.[e.NewButtonLabel]?.value ?? ""}`}
            </TPButton>
          </div>

          <CustomStateAdminTable
            state={state}
            pageDispatch={pageDispatch}
            loadData={loadDataSource}
            ref={tableRef}
          />
        </div>
      </TPLoadingOverlay>
    );
  }
);

export default CustomStateAdmin;
