import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import TPGlobal from "@/helpers/TPGlobal";
import HotTable from "@handsontable/react";
import { LanguageModel } from "@/models/Project/Projects";

/**
 * STYLES
 */
const styles = {
    containerTable: {
        width: "100%",
        minHeight: "300px",
        maxHeight: "500px",
        height: "max-content",
        overflowX: "auto",
        overflowY: "hidden",
        marginBottom: "20px",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface WorkflowDefinitionImportTableEvents {
    /**
     * allows you to reload the table detail data
     */
    getChanges: () => any;
}
export interface WorkflowDefinitionImportTableStructure {
    /**
     * attribute
     */
    attribute: string;
    /**
     * label
     */
    label: string;
    /**
     * sub label
     */
    subLabel?: string;
    /**
     * type
     */
    type: "text" | "number" | "boolean" | "language";
    /**
     * required
     */
    required?: boolean;
}
/**
 * properties that the child component needs
 */
export interface WorkflowDefinitionImportTableProps {
    /**
     * id
     */
    id?: string;
    /**
     * key
     */
    key?: string;
    /**
     * structure
     */
    structure: Array<WorkflowDefinitionImportTableStructure>;
    /**
     * main table data
     */
    dataSource?: any;
    /**
     * Indicates whether the table accepts multiple rows
     */
    multiple?: boolean;
    /**
     * value sent after making a change
     */
    onChange?: (value: any) => void
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of workflow definition import table
 */
const WorkflowDefinitionImportTable = forwardRef((
    {
        id,
        key,
        structure,
        dataSource,
        multiple,
        onChange,
    }: WorkflowDefinitionImportTableProps,
    ref
) => {
    /**
     * ATTRIBUTES
     */
    /**
     * columns main table data
     */
    const [columns, setColumns] = useState<Array<Array<any>>>([[], []]);
    /**
     * 
     */
    const [data, setData] = useState<Array<Array<string>>>([]);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(ref, () => ({
        getChanges() {
            return getValue()
        },
    } as WorkflowDefinitionImportTableEvents));
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
        loadColumns()
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
    }, []);
    /**
     * 
     */
    useEffect(() => {
        loadDataSource();
    }, [dataSource]);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * 
     */
    function loadColumns() {
        const coll = TPGlobal.TPClientAvailableLanguages
            .map(({ id, name: label }) => ({
                id,
                label,
                colspan: 1,
            }));
        
        const header = structure.map(({attribute: id, label, type, required}) => ({
            id,
            label: `${label}${required ? " *" : ""}`,
            colspan: type === "language" ? coll?.length : 1,
        }));

        const subHeader = structure.flatMap(({attribute: id, subLabel, type, required}) => {
            if (type === "language") {
                if (required) {
                    return [...coll]
                        .map(c => ({
                            ...c,
                            label: c.id === TPGlobal.language ? `${c.label} *` : c.label
                        }))
                } else {
                    return [...coll];
                }
            }

            return {
                id,
                label: subLabel ?? "",
                colspan: 1,
            }
        });

        setColumns([
            [...header],
            [...subHeader],
        ]);

        addRow()
    }
    /**
     * 
     */
    function loadDataSource() {
        if (!dataSource) {
            return
        }

        if (data.length === 0) {
            return
        }

        let index = 0;
        const value: Array<Array<string>> = [[]];
        
        if (multiple) {
            dataSource.forEach((d: any, i: number) => {
                structure.forEach(({attribute, type}) => {
                    switch (type) {
                        case "text":
                            value[i].push(d[attribute] ?? "");
                            index += 1;
                            break;
                        case "number":
                            value[i].push(`${d[attribute] ?? ""}`);
                            index += 1;
                            break;
                        case "boolean":
                            value[i].push(`${d[attribute] === true ? "1" : d[attribute] === false ? "0" : ""}`);
                            index += 1;
                            break;
                        case "language":
                            TPGlobal.TPClientAvailableLanguages
                                .forEach(({ id }) => {
                                    value[i].push(
                                        d[attribute]
                                            ?.find(({languageId}: any) => languageId === id)
                                            ?.localizedValue ?? ""
                                    );
                                    index += 1;
                                })
                            break;
                    }
                });
                value.push([])
            });
        } else {
            structure.forEach(({attribute, type}) => {
                switch (type) {
                    case "text":
                        value[0].push(dataSource[attribute] ?? "");
                        index += 1;
                        break;
                    case "number":
                        value[0].push(`${dataSource[attribute] ?? ""}`);
                        index += 1;
                        break;
                    case "boolean":
                        value[0].push(`${dataSource[attribute] === true ? "1" : dataSource[attribute] === false ? "0" : ""}`);
                        index += 1;
                        break;
                    case "language":
                        TPGlobal.TPClientAvailableLanguages
                            .forEach(({ id }) => {
                                value[0].push(
                                    dataSource[attribute]
                                        ?.find(({languageId}: any) => languageId === id)
                                        ?.localizedValue ?? ""
                                );
                                index += 1;
                            })
                        break;
                }
            });
        }

        setData(value);
    }
    /**
     * add row
     */
    function addRow(rowValue?: any) {
        const row = structure.flatMap(({type}) => {
            if (type === "language") {
                return TPGlobal.TPClientAvailableLanguages.map(() => "");
            }

            return ""
        });

        setData([
            ...data,
            row
        ]);
    }
    /**
     * 
     */
    function getValue() {
        let result;

        if (multiple) {
            const dataResult = [...data];
            dataResult.pop();
            result = dataResult.map((d) => {
                if (d.length === 0) {
                    return null
                }

                let index = 0;
                const value: any = {};
                
                structure.forEach(({attribute, type}) => {
                    switch (type) {
                        case "text":
                            if (!!d[index]?.trim()) {
                                value[attribute] = d[index];
                            } else {
                                value[attribute] = null;
                            }
                            index += 1;
                            break;
                        case "number":
                            if (!!d[index]?.trim()) {
                                const v = Number(d[index]?.trim())
                                if (Number.isNaN(v)) {
                                    value[attribute] = undefined;
                                } else {
                                    value[attribute] = v;
                                }
                            } else {
                                value[attribute] = null;
                            }
                            index += 1;
                            break;
                        case "boolean":
                            if (!!d[index]?.trim()) {
                                value[attribute] = d[index] === '1' ? true : 
                                    d[index] === '0' ? false : 
                                    undefined;
                            } else {
                                value[attribute] = false;
                            }
                            index += 1;
                            break;
                        case "language":
                            value[attribute] = TPGlobal.TPClientAvailableLanguages
                                .map(({ id: languageId }, order) => {
                                    const localizedValue = d[index];
                                    index += 1;

                                    return { languageId, localizedValue, order, } as LanguageModel
                                })
                            break;
                    }
                });

                return value;
            });
        } else {
            if (data[0].length === 0) {
                return null
            }

            let index = 0;
            const value: any = {};

            structure.forEach(({attribute, type}) => {
                switch (type) {
                    case "text":
                        if (!!data[0][index]?.trim()) {
                            value[attribute] = data[0][index];
                        } else {
                            value[attribute] = null;
                        }
                        index += 1;
                        break;
                    case "number":
                        if (!!data[0][index]?.trim()) {
                            const v = Number(data[0][index].trim())
                            if (Number.isNaN(v)) {
                                value[attribute] = undefined;
                            } else {
                                value[attribute] = v;
                            }
                        } else {
                            value[attribute] = null;
                        }
                        index += 1;
                        break;
                    case "boolean":
                        if (!!data[0][index]?.trim()) {
                            value[attribute] = data[0][index] === '1' ? true : 
                                data[0][index] === '0' ? false : 
                                undefined;
                        } else {
                            value[attribute] = false;
                        }
                        index += 1;
                        break;
                    case "language":
                        value[attribute] = TPGlobal.TPClientAvailableLanguages
                            .map(({ id: languageId }, order) => {
                                const localizedValue = data[0][index];
                                index += 1;

                                return { languageId, localizedValue, order, } as LanguageModel
                            })
                        break;
                }
            });
            
            result = value
        }

        return result;
    }
    /**
     * value sent after making a change
     */
    function onAfterChange() {
        if (data.length === 0) {
            return
        }

        if (multiple) {
            if (data[data.length - 1].map(v => !v.trim()).includes(false)) {
                addRow()
            }
        } else {
            if (data.length > 1) {
                setData([data[0]])
            }
        }

        if (onChange) {
            onChange(getValue())
        }
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <div
            id={`Container${id}`}
            key={`Container${key}`}
            style={styles.containerTable}
        >
            <HotTable
                id={id}
                key={key}
                className="htCenter"
                colWidths={200}
                data={data}
                nestedHeaders={columns}
                contextMenu={["copy", "cut"]}
                licenseKey="non-commercial-and-evaluation"
                afterChange={onAfterChange}
                manualColumnResize
                autoWrapRow
                autoWrapCol
                selectionMode="multiple"
                stretchH="last"
                rowHeaders
                colHeaders
            />
        </div>
    );
})

export default WorkflowDefinitionImportTable;
