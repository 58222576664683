import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import { ScriptAdditionalData, ScriptAdditionalDataLogic, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";

interface ScriptYesNoLogicItemProperties {
  availableNextSteps: ScriptStep[];
  onCompletion: (logicSteps: ScriptAdditionalDataLogic[]) => void;
  logic?: ScriptAdditionalDataLogic[];
}

const ScriptYesNoLogicItem: FC<ScriptYesNoLogicItemProperties> = function ({ availableNextSteps, onCompletion, logic }) {
  const [yesValue, setYesValue] = useState<string>(availableNextSteps.find(a => a.order == -1)?.id || "");
  const [noValue, setNoValue] = useState<string>(availableNextSteps.find(a => a.order == -1)?.id || "");

  useEffect(() => {
    onCompletion([
      {
        key_SSDA: 'yes',
        nextStepId: yesValue,
        value: 'yes'
      },
      {
        key_SSDA: 'no',
        nextStepId: noValue,
        value: 'no'
      },
    ])
  }, [yesValue, noValue])

  useEffect(() => {
    if (logic) {
      setYesValue(logic.find(logic => logic.value === "yes")?.nextStepId || availableNextSteps.find(a => a.order == -1)?.id || "");
      setNoValue(logic.find(logic => logic.value === "no")?.nextStepId || availableNextSteps.find(a => a.order == -1)?.id || "");
    }
  }, [])
  return (
    <>
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ width: '10%' }}>_yes</label>
        <TPSelect
          dataSource={
            availableNextSteps.map(step => {
              return {
                key: String(step.id),
                value: step.order == -1 ?
                  extractTextFromHTML(step.description)
                  :
                  String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
              }
            })
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setYesValue(e.target.value)
          }
          value={yesValue}
          containerStyle={{ width: '100%' }}
          placeholder="_next_step"
        />
      </div>
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ width: '10%' }}>_no</label>
        <TPSelect
          dataSource={
            availableNextSteps.map(step => {
              return {
                key: String(step.id),
                value: step.order == -1 ?
                  extractTextFromHTML(step.description)
                  :
                  String(step.order).concat('. ', extractTextFromHTML(step.description) || '')
              }
            })
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNoValue(e.target.value)
          }
          value={noValue}
          containerStyle={{ width: '100%' }}
        />
      </div>
    </>
  )
}

export default ScriptYesNoLogicItem;