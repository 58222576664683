import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import { TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { UploadProgressData } from "@/models/LoadHistory/load-history.model";
import DynamicTable, {
  ColumnStyles,
  CustomActionProps,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import DragFileModal from "@/modules/core/design-system/drag-and-drop/DragFileModal";
import { useToast } from "@/modules/core/design-system/toasts/ToastContext";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import { exportDataToExcel } from "@/modules/core/utils/export-utils";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TableDateItem from "@/modules/core/utils/table-micro-components/TableDateItem";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import {
  GetErrorFileProps,
  GetUploadProgressProps,
  LoadHistoryService,
} from "@/services/LoadHistoryService";
import { ReactElement, useEffect, useState } from "react";
import { CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { SuccessfulTableTableProps } from "./SuccessfulTable";

interface UploadProgressTableProps
  extends Omit<SuccessfulTableTableProps, "setUserRecords"> { }

interface UploadTableData {
  projectId: string;
  structureId: string;
  evenTempLoadId: string;
  project: string;
  structure: string;
  uploadedBy: string;
  uploadDate: string;
  status: boolean;
  errors: number;
}

function UploadProgressTable({
  tableLabels,
  setIsLoadingScreen,
  callBackCommands,
}: UploadProgressTableProps): ReactElement {
  const { showToast } = useToast();
  const component: string = "UploadProgressTable";

  const [open, setOpen] = useState<boolean>(false);
  const [firstReRendering, setFirstReRendering] = useState<boolean>(false);
  const [projects, setProjects] = useState<TPKeyValue[]>([]);
  const [structures, setStructures] = useState<TPKeyValue[]>([]);
  const [projectSelected, setProjectSelected] = useState<string | null>(null);
  const [structureSelected, setStructureSelected] = useState<string | null>(
    null
  );
  const [projectId, setProjectId] = useState<string>("");
  const [structureId, setStructureId] = useState<string>("");
  const [tableData, setTableData] = useState<UploadTableData[]>([]);

  const progressFilters: AdditionalFilter[] = [
    {
      key: "project",
      data: projects,
      label: tableLabels.project,
      selectedValue: projectSelected,
      placeholder: tableLabels.projectPlaceholder,
      width: 254,
      onChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
        setProjectSelected(event.target.value),
    },
    {
      key: "structure",
      data: structures,
      label: tableLabels.structure,
      selectedValue: structureSelected,
      placeholder: tableLabels.structurePlaceholder,
      width: 254,
      onChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
        setStructureSelected(event.target.value),
    },
  ];

  const getTableData = async (): Promise<UploadProgressData[]> => {
    const loadService = new LoadHistoryService();

    const params: GetUploadProgressProps = {
      projectId: projectSelected,
      structureId: structureSelected,
    };

    try {
      setIsLoadingScreen(true);
      return await loadService.getUploadProgressData(params);
    } catch (error) {
      setIsLoadingScreen(false);
      throwAlertError(error, component, "getTableData");
      return [];
    }
  };

  const setTableResponse = (isRefresh: boolean = false) => {
    getTableData().then((res) => {
      if (res && res.length > 0) {
        setTableData(setResponseToTableItem(res));
        !isRefresh && setProjectsAndStructures(res);
      }
      setIsLoadingScreen(false);
    });
  };

  const setResponseToTableItem = (
    response: UploadProgressData[]
  ): UploadTableData[] => {
    return response.map((item) => {
      return {
        projectId: item.projectId,
        structureId: item.eventLoadStructureId,
        evenTempLoadId: item.idEventTemporaryLoad,
        project: item.projectName,
        structure: item.structureDescription,
        uploadedBy: item.userLoadName,
        uploadDate: item.insertDateEventLoad,
        status: item.status === "In Progress",
        errors: +item.numberErrors,
      };
    });
  };

  const setProjectsAndStructures = (response: UploadProgressData[]) => {
    const projectMap = new Map<string, string>();
    const structureMap = new Map<string, string>();

    response.forEach((item) => {
      projectMap.set(item.projectId, item.projectName);
      structureMap.set(item.eventLoadStructureId, item.structureDescription);
    });

    const allProjects = Array.from(projectMap, ([key, value]) => ({
      key,
      value,
    }));

    const allStructures = Array.from(structureMap, ([key, value]) => ({
      key,
      value,
    }));

    const resetValue: TPKeyValue = {
      key: "",
      value: "--",
    };

    setProjects([resetValue, ...allProjects]);
    setStructures([resetValue, ...allStructures]);
  };

  const getErrorsBy = async (evenTempLoadId: string) => {
    const loadService = new LoadHistoryService();

    const params: GetErrorFileProps = {
      eventTemporaryLoadId: evenTempLoadId,
    };

    try {
      setIsLoadingScreen(true);
      return await loadService.getFileErrorDetails(params);
    } catch (error) {
      setIsLoadingScreen(false);
      throwAlertError(error, component, "getErrorsBy");
      return [];
    }
  };

  const handleDownloadErrorsBy = (record: UploadTableData) => {
    getErrorsBy(record.evenTempLoadId).then((res) => {
      exportDataToExcel(
        parseToErrorData(res),
        `errors-details-${record.projectId}`
      );
      setIsLoadingScreen(false);
    });
  };

  const parseToErrorData = (data: any[]) => {
    return data.map((item, index) => {
      const { eventTemporaryLoadErrorDetail } = item;
      const { ErrorMessage, ...rest } = eventTemporaryLoadErrorDetail;

      return {
        ...rest,
        [tableLabels.errorDetails]: ErrorMessage
          ? `(${index + 1}) ${ErrorMessage}`
          : `(${index + 1})`,
      };
    });
  };

  const customColumns: ColumnStyles<UploadTableData> = {
    project: ({ value, item }) => (
      <TablePrimaryItem
        value={value}
        onClick={() => goToProjectTab(item.projectId)}
      />
    ),
    status: ({ value }) => (
      <TableChip
        value={value}
        onLabel={tableLabels.inProgress}
        offLabel={tableLabels.failed}
        activeColor={"#FFE4B3"}
        inactiveColor={"#FFD7DD"}
        justify="flex-start"
      />
    ),
    errors: ({ value, item }) => (
      <div style={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
        {value === 0 ? (
          " "
        ) : (
          <>
            {value}
            <TPIcon
              iconType={TPIconTypes.download}
              onClick={() => handleDownloadErrorsBy(item)}
            />
          </>
        )}
      </div>
    ),
    uploadDate: ({ value }) => <TableDateItem value={value} />,
  };

  const additionalAction: React.FC<CustomActionProps<UploadTableData>> = ({
    item,
  }) => {
    return (
      <TPButton
        id="close-solve-records"
        isDesignSystem
        customType={ButtonCustomType.tertiary}
        onClick={() => handleClickOpen(item.projectId, item.structureId)}
        disabled={item.status}
      >
        {tableLabels.uploadFile}
      </TPButton>
    );
  };

  const columnsNames: CustomColumnNames<UploadTableData> = {
    project: tableLabels.project,
    structure: tableLabels.structureName,
    uploadedBy: tableLabels.uploaded,
    uploadDate: tableLabels.date,
    status: tableLabels.status,
    errors: tableLabels.errors,
  };

  const handleClickOpen = (project: string, structure: string) => {
    setProjectId(project);
    setStructureId(structure);
    setOpen(true);
  };

  const handleModalClose = (response: boolean, code?: number) => {
    setProjectSelected("");
    setOpen(false);

    if (response) {
      code && code === 200
        ? showToast(tableLabels.successLoad, TPToastTypes.success)
        : showToast(tableLabels.errorLoad, TPToastTypes.error);
      console.log("refresh page");
    }
  };

  const goToProjectTab = (id: string) => {
    let command: any = {
      command: "new_horizontal_tab",
      recordId: id,
      component: "TPClientCloudMain.Client.Components.EventsManager.Projects",
    };
    callBackCommands(command);
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) {
      setTableResponse(true);
    }
  };

  useEffect(() => {
    if (!firstReRendering) {
      setFirstReRendering(true);
      setTableResponse();
    }
  }, [tableData]);

  useEffect(() => {
    setTableResponse(true);
  }, [projectSelected, structureSelected]);

  return (
    <>
      <DragFileModal
        status={open}
        projectId={projectId}
        eventLoadStructureId={structureId}
        handleModalClose={handleModalClose}
      />
      <DynamicTable
        id="upload-progress"
        searchPosition="right"
        hiddenColumns={["evenTempLoadId", "projectId", "structureId"]}
        data={tableData}
        additionalFilters={progressFilters}
        columnStyles={customColumns}
        CustomAction={additionalAction}
        columnNames={columnsNames}
        onIconClicked={handleIconClick}
        noDataMessage={tableLabels.noHistories}
      />
    </>
  );
}

export default UploadProgressTable;
