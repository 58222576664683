import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import {
  WallboardModel,
} from "@/models/Wallboard/WallboardModel";

export class WallboardService {
  serviceFileName: string = "WallboardService.ts";

  public async getTeams(
    userGuid: string,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200, 404]
  ): Promise<Array<WallboardModel>> {
    const { getData } = new TPHTTPService();

    try {
      const response = await getData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/wallboard/teamview?userGuid=${userGuid}`,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders]
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTeams ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getTeams`);
      throw new Error(`Error ${this.serviceFileName} getTeams`);
    }
  }

  public async getGroups(
    queueIds: Array<number>,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200, 404]
  ): Promise<Array<WallboardModel>> {
    const { getData } = new TPHTTPService();
    let params = "";
    if (queueIds && queueIds.length > 0) {
      params += `?queueIds=${queueIds.join(",")}`;
    }

    try {
      const response = await getData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/wallboard/groupview${params}`,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders]
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroups ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getGroups`);
      throw new Error(`Error ${this.serviceFileName} getGroups`);
    }
  }

  public async getEvents(
    projectId: string,
    queueIds?: Array<number>,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = true,
    expectedCodes: Array<number> = [200, 404]
  ): Promise<Array<WallboardModel>> {
    const { getData } = new TPHTTPService();
    let params = `?projectId=${projectId}&queueIds=`;
    if (queueIds && queueIds.length > 0) {
      params += `${queueIds.join(",")}`;
    } else {
      params += `-1`;
    }

    try {
      const response = await getData(
        `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/wallboard/eventview${params}`,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        [...TPGlobal.standardHeaders]
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getEvents ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getEvents`);
      throw new Error(`Error ${this.serviceFileName} getEvents`);
    }
  }
}
