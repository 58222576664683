import { TPLog, TPLogType } from "@/helpers/TPLog";

export const throwError = (
  error: unknown,
  fileName: string,
  functionName: string
): string => {
  return getErrorMessage(error, fileName, functionName);
};

export const throwAlertError = (
  error: unknown,
  fileName: string,
  functionName: string
): void => {
  getErrorMessage(error, fileName, functionName, true);
};

const getErrorMessage = (
  error: unknown,
  fileName: string,
  functionName: string,
  withConsoleError: boolean = false
): string => {
  let msg: string = `Exception error | File: ${fileName} - Function: ${functionName}`;
  TPLog.Log(msg, TPLogType.ERROR, error);
  withConsoleError && console.error(msg, error);
  return msg;
};
