import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPChip } from "@/components/TPChip/TPChip";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { RelationsService } from "@/services/RelationsService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import {
  ColumnStyles,
  CustomColumnNames,
} from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";

type AdminStateType = {
  selectedFilter: string;
  organizationsRelationsAreLoaded: boolean;
  currentBaseLevel: string;
  gridColumns: Array<any>;
  gridData: Array<QuickClassifierViewModel>;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface QuickClassifiersAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_OrganizationsRelationsAreLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_CurrentBaseLevel" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
  "change_selectedFilter" = 5,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const QuickClassifiersAdmin = React.forwardRef(
  ({ callBackCommands }: QuickClassifiersAdminInterface, ref) => {
    const componentFileName: string = "QuickClassifiersAdmin.tsx";

    //Function called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "QuickClassifiersAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    //organizations/relations filter
    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [
      classificatorBranchColumnLabel1,
      setClassificatorBranchColumnLabel1,
    ] = useState("");
    const [
      classificatorBranchColumnLabel2,
      setClassificatorBranchColumnLabel2,
    ] = useState("");
    const [
      classificatorBranchColumnLabel3,
      setClassificatorBranchColumnLabel3,
    ] = useState("");
    const [
      classificatorBranchColumnLabel4,
      setClassificatorBranchColumnLabel4,
    ] = useState("");
    const [
      classificatorBranchColumnLabel5,
      setClassificatorBranchColumnLabel5,
    ] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    //#endregion

    //Load Resources and QuickClassifiers
    const loadResourcesAndQuickClassifiers = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setCloneLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );

      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "BaseLevel")
      );
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setNameColumnLabel(await TPI18N.GetText(resourceSet, "Description"));
      setClassificatorBranchColumnLabel1(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel1")
      );
      setClassificatorBranchColumnLabel2(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel2")
      );
      setClassificatorBranchColumnLabel3(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel3")
      );
      setClassificatorBranchColumnLabel4(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel4")
      );
      setClassificatorBranchColumnLabel5(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel5")
      );
      setIsActiveColumnLabel(await TPI18N.GetText(resourceSet, "IsActive"));
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(resourceSet, "IsSystemRecord")
      );

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes")
      );
      setNoLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo")
      );

      //Organizations/Relations
      await getOrganizationsRelationsList();
      let command1: commandType = {
        type: commandsEnum.set_OrganizationsRelationsAreLoaded,
        payload: null,
      };
      dispatchCommand(command1);

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const getRelationsList = async (
      newOrganization: string
    ): Promise<Array<TPKeyValue>> => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    const getOrganizationsRelationsList = async () => {
      let serviceOrganizationRelationClient =
        new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);
        let responseRequestOrgRela =
          await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
            TPActiveOptions.ALL.toString(),
            false,
            true,
            expectedCodes
          );

        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: responseRequestOrgRela[i].id,
            value: responseRequestOrgRela[i].localizedDescription,
          };
          newOrganizationsRelationsListState.push(keyvalue);
        }

        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);
        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`
        );
        setIsLoadingScreen(false);
      }
    };

    // Define the columns, styles, and minor options for the DynamicTable
    const customerTypeColumns: CustomColumnNames<QuickClassifierViewModel> = {
      id: idColumnLabel,
      isSystemRecord: isSystemRecordColumnLabel,
    };
    const customColumns: ColumnStyles<QuickClassifierViewModel> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          isDisabled={item.isSystemRecord}
          onClick={() => handleUpdateClick(item.id)}
        />
      ),
      isSystemRecord: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };
    const minorOptions = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e: { id: string }) => handleUpdateClick(e.id),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e: { id: string }) => handleDeleteClick(e.id),
      },
    ];

    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };
 
    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevel: string;
      newBaseLevel = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_CurrentBaseLevel,
        payload: { newBaseLevel: newBaseLevel },
      };
      dispatchCommand(command1);
    };

    //New quick classifier
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Get quick classifiers by Filter organizationRelation
    const reloadDataGrid = async (
      baseLevelId: string,
      selectedFilter: string
    ) => {
      let serviceClient = new QuickClassifierService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        if (baseLevelId !== "") {
          let responseRequest =
            await serviceClient.getQuickClassifiersByBaseLevel(
              baseLevelId,
              false,
              true,
              expectedCodes
            );
          if (selectedFilter != TPActiveOptions.ALL.toString()) {
            responseRequest = responseRequest.filter((quickClassifier) =>
              selectedFilter == TPActiveOptions.ACTIVE.toString()
                ? quickClassifier.isActive == true
                : quickClassifier.isActive == false
            );
          }
          setIsLoadingScreen(false);
          return [...responseRequest];
        } else {
          let responseRequest = await serviceClient.getQuickClassifierByFilter(
            selectedFilter,
            false,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          return [...responseRequest];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.currentBaseLevel, adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //Update quick classifier
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    const handleCloneClick = (id: string) => {
      let command: any = { command: "clone", recordId: id };
      callBackCommands(command);
    };

    // Function to handle icon click events
    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    //Modal Question to delete quick classifier
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete QuickClassifier after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new QuickClassifierService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteQuickClassifierById(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      organizationsRelationsAreLoaded: false,
      currentBaseLevel: "",
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      let newAdminState: AdminStateType;
      switch (command.type) {
        case commandsEnum.set_OrganizationsRelationsAreLoaded:
          newAdminState = { ...prevState };
          newAdminState.organizationsRelationsAreLoaded = true;
          return newAdminState;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.change_CurrentBaseLevel:
          newAdminState = { ...prevState };
          newAdminState.currentBaseLevel = command.payload.newBaseLevel;
          return newAdminState;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "org-relation-status",
        data: organizationsRelationsKeyValue,
        label: organizationsRelationsLabel,
        selectedValue: adminState.currentBaseLevel,
        onChange: handleOrganizationRelationChange,
      },
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
    ];

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndQuickClassifiers();
    }, []);

    //Run when organizations/relations are loaded to get columns
    useEffect(() => {
      if (adminState.organizationsRelationsAreLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.organizationsRelationsAreLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change organization/relation
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [
      adminState.columnsAreLoaded,
      adminState.currentBaseLevel,
      adminState.selectedFilter,
    ]);

    const getHideenColumns = (): (keyof QuickClassifierViewModel)[] => {
      const hiddenColumns: (keyof QuickClassifierViewModel)[] = [
        "baseLevelId",
        "caseComments",
        "classifier1Id",
        "classifier2Id",
        "classifier3Id",
        "classifier4Id",
        "classifier5Id",
        "emailTemplateHeaderId",
        "localizedHierarchyClassifier1",
        "localizedHierarchyClassifier2",
        "localizedHierarchyClassifier3",
        "localizedHierarchyClassifier4",
        "localizedHierarchyClassifier5",
        "type",
      ];
      if (TPGlobal.TPClientAvailableLanguages[0].id === TPGlobal.language)
        hiddenColumns.push("localizedDescription");
      return hiddenColumns;
    };

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>

        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-tree"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={getHideenColumns()}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                disableMinorOption={(item) => item.isSystemRecord}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default QuickClassifiersAdmin;
