import { TPI18N } from "@/services/I18nService";


export class LocationLanguagesInboundMailWithErrors {

    getLocationNameTable: LocationNameTable = new LocationNameTable();
    getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();


    public handlelocationTable = async (resourceSet: string) => {
        this.getLocationNameTable.title = await TPI18N.GetText(resourceSet, "ProcessInboundMailsWithErrorsLabel");
        this.getLocationNameTable.confirm = await TPI18N.GetText(resourceSet, "MsgOkAlertLabel");
        this.getLocationNameTable.inboundMailBoxLabel = await TPI18N.GetText(resourceSet, "InboundMailBoxLabel");
        this.getLocationNameTable.actionLabel = await TPI18N.GetText(resourceSet, "ActionLabel");
      

        this.getLocationNameTable.columnId = await TPI18N.GetText(resourceSet, "ColumnIdLabel");
        this.getLocationNameTable.columnDownload = await TPI18N.GetText(resourceSet, "ColumnDownloadLabel");
        this.getLocationNameTable.columnViewMail = await TPI18N.GetText(resourceSet, "ColumnViewMailLabel");
        this.getLocationNameTable.columnType = await TPI18N.GetText(resourceSet, "ColumnTypeLabel");
        this.getLocationNameTable.columnFrom = await TPI18N.GetText(resourceSet, "ColumnFromLabel");
        this.getLocationNameTable.columnTo = await TPI18N.GetText(resourceSet, "ColumnToLabel");
        this.getLocationNameTable.columnCc = await TPI18N.GetText(resourceSet, "ColumnCcLabel");
        this.getLocationNameTable.columnSubject = await TPI18N.GetText(resourceSet, "ColumnSubjectLabel");
        this.getLocationNameTable.columnBody = await TPI18N.GetText(resourceSet, "ColumnBodyLabel");
        this.getLocationNameTable.columnMailDate = await TPI18N.GetText(resourceSet, "ColumnMailDateLabel");
        this.getLocationNameTable.columnInsertDate = await TPI18N.GetText(resourceSet, "ColumnInsertDateLabel");
        this.getLocationNameTable.columnInboundMailBox = await TPI18N.GetText(resourceSet, "ColumnInboundMailBoxLabel");
        this.getLocationNameTable.columnRule = await TPI18N.GetText(resourceSet, "ColumnRuleLabel");
        this.getLocationNameTable.applyFilterTooltip = await TPI18N.GetText(resourceSet, "ApplyFilterTooltip");

    };

    public handlelocationGlobal = async (resourceSet: string) => {
        this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(resourceSet, "OkButton");
        this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(resourceSet, "DeleteLabel");
        this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(resourceSet, "BuikLoad");
        this.getLocationNameGlobal.confirmdeleteLabel = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.search = await TPI18N.GetText(resourceSet, "Search");
        this.getLocationNameGlobal.filterLabel = await TPI18N.GetText(resourceSet, "FilterLabel");
        this.getLocationNameGlobal.resultsLabel = await TPI18N.GetText(resourceSet, "ResultsLabel");
        this.getLocationNameGlobal.inputRequire = await TPI18N.GetText(resourceSet, "InputDTORequired");
        this.getLocationNameGlobal.applyFilterText = await TPI18N.GetText(resourceSet, "ApplyFilterText");
        this.getLocationNameGlobal.questionConfirmDelete = await TPI18N.GetText("AdditionalDataRelateComponent", "RecordDeleteConfirm");
        this.getLocationNameGlobal.actionError = await TPI18N.GetText(resourceSet, "MinimumNumberOfSelectedRecords");
    };
}


export class LocationNameTable {
    title: string = "";
    confirm: string = "";
    inboundMailBoxLabel: string = "";
    actionLabel: string = "";


    columnId: string = "";
    columnDownload: string = "";
    columnViewMail: string = "";
    columnType: string = "";
    columnFrom: string = "";
    columnTo: string = "";
    columnCc: string = "";
    columnSubject: string = "";
    columnBody: string = "";
    columnMailDate: string = "";
    columnInsertDate: string = "";
    columnInboundMailBox: string = "";
    columnRule: string = "";
    applyFilterTooltip: string = "";
   

}

export class LocationNameGlobal {
    confirmAlertTitle: string = "";
    okAlertButton: string = "";
    cancelAlertButton: string = "";
    questionConfirmDelete: string = "";
    deleteLabel: string = "";
    confirmdeleteLabel: string = "";
    updateLabel: string = "";
    buikLoad: string = "";
    search: string = "";
    filterLabel: string = "";
    resultsLabel: string = "";
    inputRequire: string = "";
    applyFilterText: string = "";
    actionError: string = "";
   
}


