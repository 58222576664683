import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import {
  TPPageSection,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  AdditionalDataCategoryEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useState } from "react";

type TPModaladditionalDataProps = {
  title: string;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackAnswer: Function;
};

interface screenStateProps {
  additionalDataId: string;
  order: number;
  isMandatory: boolean;

  //validations
  additionalDataIdErrorMessage: string;
  orderErrorMessage: string;
  isMandatoryErrorMessage: string;
}

const TPModalAdditionalData: FC<TPModaladditionalDataProps> = ({
  title,
  acceptLabel,
  cancelLabel,
  isShown,
  callBackAnswer,
}) => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const componentFileName: string = "TPModalAdditionalData.tsx";
  const resourceSet: string = "TPModalAdditionalData";
  const screenStateInitialState: screenStateProps = {
    additionalDataId: "",
    order: 0,
    isMandatory: false,
    additionalDataIdErrorMessage: "",
    orderErrorMessage: "",
    isMandatoryErrorMessage: "",
  };
  const [screenState, setScreenState] = useState<screenStateProps>(
    screenStateInitialState
  );
  const [additionalDataKeyValue, setAdditionalDataKeyValue] = useState<
    Array<TPKeyValue>
  >([]);

  const [additionalDataSectionLabel, setAdditionalDataSectionLabel] =
    useState("");
  const [otherParametersSectionLabel, setOtherParametersSectionLabel] =
    useState("");

  const [additionalDataLabel, setAdditionalDataLabel] = useState("");
  const [orderLabel, setOrderLabel] = useState("");
  const [isMandatoryLabel, setIsMandatoryLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");

  //top n options for arrow icon
  const [autocompleteTopNOptions, setAutocompleteTopNOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState<
    Array<TPKeyValue>
  >([
    {
      key: "",
      value: "",
    },
  ]);

  const loadResourcesAndAdditionalData = async () => {
    setAdditionalDataSectionLabel(
      await TPI18N.GetText(resourceSet, "AdditionalDataSectionLabel")
    );
    setOtherParametersSectionLabel(
      await TPI18N.GetText(resourceSet, "OtherParametersSectionLabel")
    );
    setAdditionalDataLabel(
      await TPI18N.GetText(resourceSet, "AdditionalDataLabel")
    );
    setOrderLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
    setIsMandatoryLabel(await TPI18N.GetText(resourceSet, "IsMandatoryLabel"));
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
    );
  };

  const handleAdditionalDataChange = (selected: any) => {
    if (selected && selected.length && selected.length >= 1) {
      let newScreenState = {
        ...screenState,
      };
      newScreenState.additionalDataId = selected[0].key;
      newScreenState.additionalDataIdErrorMessage = "";
      setScreenState(newScreenState);
      setSelectedAutocompleteOption(selected);
    }
  };

  const handlerOnOrderChange = (newOrder: number) => {
    let newScreenState = {
      ...screenState,
    };
    newScreenState.order = newOrder;
    newScreenState.orderErrorMessage = "";
    setScreenState(newScreenState);
  };

  const handlerOnIsMandatoryChange = () => {
    let newScreenState = {
      ...screenState,
    };
    newScreenState.isMandatory = !newScreenState.isMandatory;
    setScreenState(newScreenState);
  };

  const handlerOnOKClick = async () => {
    //Validations
    let control: boolean = true;
    let newScreenState = { ...screenState };
    if (
      !screenState.additionalDataId ||
      screenState.additionalDataId.trim() == ""
    ) {
      control = false;
      newScreenState.additionalDataIdErrorMessage = await TPI18N.GetText(
        resourceSet,
        "InputDTOAdditionalDataIdEmpty"
      );
    }
    if (
      screenState.order == null ||
      screenState.order == undefined ||
      screenState.order.toString().trim() == ""
    ) {
      control = false;
      newScreenState.orderErrorMessage = await TPI18N.GetText(
        resourceSet,
        "InputDTOAdditionalDataOrderEmpty"
      );
    }
    if (control) {
      callBackAnswer(
        true,
        screenState.additionalDataId,
        screenState.order,
        screenState.isMandatory
      );
      setScreenState(screenStateInitialState);
    } else {
      // Save state
      setScreenState(newScreenState);
    }
  };

  const handleOnAutocompleteQuery = async (query: string) => {
    let additionalDataService = new AdditionalDataService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      //Load additioanal data
      let responseRequest = await additionalDataService.GetByParameters(
        query,
        AdditionalDataCategoryEnum.FC_BRANCH,
        "",
        false,
        true,
        expectedCodes
      );
      let newAdditionalDataKeyValue: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: `${item.localizedDescription} - ${item.additionalDataTypeDescription}`,
          };
        }
      );
      setAdditionalDataKeyValue(newAdditionalDataKeyValue);
      return newAdditionalDataKeyValue;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} handleOnAutocompleteQuery ex`);
      return [];
    }
  };

  const handleOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      // onChange("", "", "");
      // setCurrentSelectedNode1("");
      // setCurrentSelectedLabel1("");
      // setCurrentSelectedHierarchyLabel1("");
      setAdditionalDataKeyValue([]);
      setSelectedAutocompleteOption([]);
    }
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteTopNOptions.length === 0) {
      newTopNOptions = await handleOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //TODO save on cache
        //setAutocompleteTopNOptions([...newTopNOptions]);
        setAdditionalDataKeyValue([...newTopNOptions]);
        setSelectedAutocompleteOption([]);
      }
    } else {
      //use cached values;
      setAdditionalDataKeyValue([...autocompleteTopNOptions]);
      setSelectedAutocompleteOption([]);
    }
  };

  useEffect(() => {
    loadResourcesAndAdditionalData();
  }, []);

  useEffect(() => {
    if (isShown) {
      let newScreenState = {
        ...screenState,
      };
      newScreenState.additionalDataId = "";
      newScreenState.order = 0;
      newScreenState.isMandatory = false;
      newScreenState.additionalDataIdErrorMessage = "";
      newScreenState.orderErrorMessage = "";
      setScreenState(newScreenState);
      setAdditionalDataKeyValue([]);
      setSelectedAutocompleteOption([]);
    }
  }, [isShown]);
  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  callBackAnswer(false, null, null, null);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "450px" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"} isModal>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {additionalDataSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col-10">
                      <div className="form-group">
                        <TPAutoComplete
                          labelText={additionalDataLabel}
                          onValueChange={handleAdditionalDataChange}
                          onSearch={(query: string) => {
                            handleOnAutocompleteQuery(query);
                          }}
                          onKeyDown={handleOnAutocompleteKeyDown}
                          isLoading={false}
                          options={additionalDataKeyValue}
                          emptyLabel={emptyLabel}
                          errorMessage={
                            screenState.additionalDataIdErrorMessage
                          }
                          withIcon={true}
                          selected={selectedAutocompleteOption}
                          downArrowClick={handleAutoCompleteTopNClick}
                          isMandatory={true}
                        ></TPAutoComplete>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {otherParametersSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col-10">
                      <div className="form-group">
                        <TPNumeric
                          id="IdTPNumeric"
                          labelText={orderLabel}
                          onChange={handlerOnOrderChange}
                          value={screenState.order}
                          isMandatory={true}
                          errorMessage={screenState.orderErrorMessage}
                        ></TPNumeric>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={isMandatoryLabel}
                          checked={screenState.isMandatory}
                          onChange={handlerOnIsMandatoryChange}
                        ></TPCheckBox>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      handlerOnOKClick();
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => {
                      callBackAnswer(false, null, null, null);
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalAdditionalData;
