import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import {
  AdditionalDataOpenTextTypesEnum,
  AttachmentType,
  ModalSizeEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import React, { useState, useImperativeHandle, useEffect } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import FileUploader from "../TPDragAndDropUploadFile/FileUploader";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";
import { v4 as uuidv4 } from "uuid";
import {
  AttachmentInputDTO,
  AttachmentInputDTODTOValidator,
} from "@/models/Attachments/AttachmentInputDTO";
import {
  showToast,
  TPToastTypes,
} from "../bootstrap/components/toasts/TPToast";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { StorageService } from "@/services/StorageService";
import { DownloadFileService } from "@/services/DownloadFileService";
import { TPLog, TPLogType } from "@/helpers/TPLog";

export type TPAdditionalDataAttachmentProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: string;
  guidControl: string;
  onValueChange?: Function | null;
};

type CaseAttachmentType = {
  sender: string;
  description: string;
  filesDraggedList: Array<any>;

  //validations
  senderErrorMessage: string;
  descriptionErrorMessage: string;
  filesDraggedListErrorMessage: Array<string>;
  notFilesErrorMessage: string;
  [key: string]: any;
  attachmentName: string;
};

const TPAdditionalDataAttachment = React.forwardRef(
  (
    {
      idControl,
      // subType,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory,
      defaultValue,
      guidControl,
      onValueChange = null,
    }: TPAdditionalDataAttachmentProps,
    ref,
  ) => {
    //#region  Init
    //state
    const [textValue, setTextValue] = useState(defaultValue);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    const [isShownModalAttachment, setIsShownModalAttachment] = useState(false);
    const [uploadFile, setUploadFile] = useState(false);

    // Constants
    const delimiterADDA: string = "||";
    const pathFile: string = "";

    //Screen resources
    const resourceSet: string = "TPAdditionalDataAttachmentComponent";
    const resourceSetNewCase: string = "NewCaseComponent";
    const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
      useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
    const [attachmentLabel, setAttachmentLabel] = useState("");
    const [senderErrorMessage, setSenderErrorMessage] = useState("");
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
    const [deleteAttachmentLabel, setDeleteAttachmentLabel] = useState("");

    //Modal new attachment
    const [newAttachmentTitleLabel, setNewAttachmentTitleLabel] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [senderLabel, setSenderLabel] = useState("");
    const [descriptionLabel, setDescriptionLabel] = useState("");
    const [dropFilesHereLabel, setDropFilesHereLabel] = useState("");
    const [uploadFileButtonLabel, setUploadFileButtonLabel] = useState("");
    const [tabAttachmentsLabel, setTabAttachmentsLabel] = useState("");

    //#endregion

    // Screen loading
    const [isLoadingScreenModalAttachment, setIsLoadingScreenModalAttachment] =
      useState(false);

    //symple state for case attachments
    const initialCaseAttachmentType: CaseAttachmentType = {
      sender: TPGlobal.currentUserName,
      description: "",
      senderErrorMessage: "",
      descriptionErrorMessage: "",
      filesDraggedList: [],
      filesDraggedListErrorMessage: [],
      notFilesErrorMessage: "",
      attachmentName: "",
    };
    const [caseAttachmentState, setCaseAttachmentState] = useState(
      initialCaseAttachmentType,
    );

    const loadResources = async () => {
      // Blob id
      setInvalidCharactersErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidIdChar",
        ),
      );
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
      setAttachmentLabel(await TPI18N.GetText(resourceSet, "AttachmentLabel"));
      setNewAttachmentTitleLabel(
        await TPI18N.GetText(resourceSet, "NewAttachmentTitleLabel"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setSenderLabel(await TPI18N.GetText(resourceSet, "SenderLabel"));
      setDescriptionLabel(
        await TPI18N.GetText(resourceSet, "DescriptionLabel"),
      );
      setDropFilesHereLabel(
        await TPI18N.GetText(resourceSetNewCase, "DropFilesHereLabel"),
      );
      setUploadFileButtonLabel(
        await TPI18N.GetText(resourceSetNewCase, "UploadFileButtonLabel"),
      );
      setTabAttachmentsLabel(
        await TPI18N.GetText(resourceSet, "TabAttachmentsLabel"),
      );
      setSenderErrorMessage(
        await TPI18N.GetText(resourceSet, "SenderErrorMessage"),
      );
      setDescriptionErrorMessage(
        await TPI18N.GetText(resourceSet, "DescriptionErrorMessage"),
      );
      setDeleteAttachmentLabel(
        await TPI18N.GetText(resourceSet, "DeleteAttachmentLabel"),
      );

      // Default value
      setTextValue(defaultValue);
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        return textValue;
      },
      validateFromParent() {
        setErrorMessageValue("");
        if (
          isMandatory &&
          (!textValue || textValue == null || textValue.trim() === "")
        ) {
          setErrorMessageValue(mandatoryErrorMessage);
          return false;
        }
        if (textValue && textValue.trim() !== "") {
          if (textValue !== TPGlobal.TPSanitize(textValue)) {
            setErrorMessageValue(invalidCharactersErrorMessage);
            return false;
          }
        }
        return true;
      },
    }));

    useEffect(() => {
      if (onValueChange) {
        onValueChange(textValue, idControl);
      }
    }, [textValue]);

    const handleNewAttachment = () => {
      // Clear information
      setErrorMessageValue("");
      let newCaseAttachmentState = { ...caseAttachmentState };
      newCaseAttachmentState.senderErrorMessage = "";
      newCaseAttachmentState.notFilesErrorMessage = "";
      setCaseAttachmentState(newCaseAttachmentState);

      setIsShownModalAttachment(true);
    };

    const handleOnAttachmentValueChange = (
      newValue: string,
      propertyName: string,
    ) => {
      let newCaseAttachmentState = { ...caseAttachmentState };
      newCaseAttachmentState[propertyName] = newValue;
      newCaseAttachmentState[`${propertyName}ErrorMessage`] = "";
      setCaseAttachmentState(newCaseAttachmentState);
    };

    //handle upload attachment change
    const handleUploadFilesDraggedChange = (filesList: any) => {
      let newCaseAttachmentState = { ...caseAttachmentState };
      newCaseAttachmentState.filesDraggedList = filesList;
      newCaseAttachmentState.notFilesErrorMessage = "";
      setCaseAttachmentState(newCaseAttachmentState);
    };

    //handle to delete attachment modal
    const handleOnDeleteAttachment = async (itemName: string) => {
      let serviceClient = new TemporaryAttachmentService();
      let expectedCodes: Array<number> = [200];

      // Delete by blobId (Azure storage)
      if (uploadFile) {
        // let responseRequest = await serviceClient.deleteTemporaryAttachmentByBLOBId(blobId, "", false, false, expectedCodes);
        // if (responseRequest.responseResult) {
        setUploadFile(false);
        // }
      }

      let newCaseAttachmentState = { ...caseAttachmentState };
      // let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];
      // newFilesDraggedList = newFilesDraggedList.filter(
      //   (x: any) => x.name !== itemName
      // );
      newCaseAttachmentState.filesDraggedList = [];
      setTextValue("");
      setCaseAttachmentState(newCaseAttachmentState);
    };

    const handleCallBackNewAttachmentModal = async (
      confirmAdd: boolean,
      callBackData: any,
    ) => {
      if (confirmAdd) {
        let serviceClient = new TemporaryAttachmentService();
        let expectedCodes: Array<number> = [200];
        let filename: string;
        let extension: string;
        let parts: Array<string> = [];
        let attachmentData: any;
        let newData: any;
        let hasError: boolean = false;
        let newCaseAttachmentState = { ...caseAttachmentState };
        let blobId: string;

        // Sender
        if (caseAttachmentState.sender.trim() == "") {
          newCaseAttachmentState.senderErrorMessage = senderErrorMessage;
          hasError = true;
        }
        if (caseAttachmentState.description.trim() == "") {
          newCaseAttachmentState.descriptionErrorMessage =
            descriptionErrorMessage;
          hasError = true;
        }

        if (
          caseAttachmentState.filesDraggedList &&
          caseAttachmentState.filesDraggedList.length == 0
        ) {
          newCaseAttachmentState.notFilesErrorMessage = await TPI18N.GetText(
            resourceSet,
            "NotFilesErrorMessageLabel",
          );
          setCaseAttachmentState(newCaseAttachmentState);
          return;
        }

        if (hasError) {
          return;
        }

        // There are attachments
        setIsLoadingScreenModalAttachment(true);
        for (let i = 0; i < caseAttachmentState.filesDraggedList.length; i++) {
          const element = caseAttachmentState.filesDraggedList[i];
          filename = element.name;
          parts = filename.split(".");
          if (parts.length === 1) {
            extension = "";
          } else {
            extension = parts[parts.length - 1];
          }

          blobId = "caseblob" + uuidv4().replaceAll("-", "");
          const fileData = new FormData();
          // if (guidControl != null) {
          // Temporary attachment

          fileData.append("fileData", element);
          fileData.append("operationGuid", guidControl);
          fileData.append("blobId", blobId);
          fileData.append("filename", filename);
          fileData.append("extensionName", extension);
          fileData.append("sender", caseAttachmentState.sender);
          fileData.append("description", caseAttachmentState.description);
          fileData.append("guid_USER", TPGlobal.currentUserGuid);

          let recordInputDTO: AttachmentInputDTO = {
            caseId: 0,
            blobId: blobId,
            filename: filename,
            extensionName: extension,
            sender: caseAttachmentState.sender,
            description: caseAttachmentState.description,
            guid_USER: TPGlobal.currentUserGuid,
            fileData: element,
          };

          // Errors
          setCaseAttachmentState(newCaseAttachmentState);
          if (hasError) {
            return;
          }
          setTextValue("");
          // setUploadFile(true);

          // }
          // Insert file
          let responseRequest = await serviceClient.insertTemporaryAttachment(
            fileData,
            false,
            false,
            expectedCodes,
          );
          if (responseRequest.responseResult) {
            setTextValue(blobId + delimiterADDA + filename);
            setUploadFile(true);
          }
          setIsLoadingScreenModalAttachment(false);
          setIsShownModalAttachment(false);
          return;

          // } else {
          //   // TODO: The case exists
          // }
        }
        setIsLoadingScreenModalAttachment(false);
      }
      setIsShownModalAttachment(false);
    };

    const handleOpenAttachmentClick = async (
      textValue: string,
      pathFile: string,
    ) => {
      let fileName: string = "";
      let blobId: string = "";

      if (textValue.trim() != "") {
        try {
          fileName = textValue.split(delimiterADDA)[1];
          blobId = textValue.split(delimiterADDA)[0];
        } catch {
          return;
        }
      }

      const storageService = new StorageService();
      const downloadFileService = new DownloadFileService();
      let expectedCodes: Array<number> = [200];
      let attachmentType: AttachmentType;
      switch (pathFile) {
        case "MailReader/EML": {
          attachmentType = AttachmentType.MAILREADEREML;
          break;
        }
        case "MailReader/Attachments": {
          attachmentType = AttachmentType.MAILREADERATTACHMENT;
          break;
        }
        default: {
          attachmentType = AttachmentType.ATTACHMENT;
          break;
        }
      }

      try {
        let responseRequest = await storageService.getFileUriSas(
          blobId,
          attachmentType,
          false,
          true,
          expectedCodes,
        );

        if (responseRequest) {
          const { sasUri, mimeType } = responseRequest;
          await downloadFileService.downloadFileUri({
            // isProxy: true,
            sasUri,
            originalFileName: fileName,
            mimeType,
          });
        }
      } catch (error) {
        TPLog.Log(
          `Error ${resourceSet} handleOpenAttachmentClick ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${resourceSet} handleOpenAttachmentClick ex`);
        return null;
      }
    };

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group">
          {/* <span>Attachment en renderCollectMode: {attachmentLabel}</span> */}
          {/* <span>GUID Control: {guidControl}</span> */}
          {/* <div>Blob id: {blobId}</div> */}
          {/* <div>Default value: {defaultValue}</div> */}
          {/* <span>textValue {textValue}</span> */}
          <TPLabel
            // htmlFor={theId}
            isMandatory={isMandatory}
            labelText={labelText}
            style={labelStyle}
          />
          <div className="input-group" style={{ width: "100%" }}>
            {textValue != null && textValue.trim() != "" ? (
              // File name
              <>
                <TPButton
                  type={TPButtonTypes.icon}
                  onClick={handleNewAttachment}
                  text=""
                  icon={TPIconTypes.newEntity}
                />
                <span
                  onClick={() => handleOpenAttachmentClick(textValue, pathFile)}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {textValue.split(delimiterADDA)[1]}
                </span>
                {/* Delete attachment  handleDeleteAttachment(item.name) */}
                <TPButton
                  type={TPButtonTypes.icon}
                  onClick={() =>
                    handleOnDeleteAttachment(textValue.split(delimiterADDA)[0])
                  }
                  tooltip={deleteAttachmentLabel}
                  icon={TPIconTypes.delete}
                />
              </>
            ) : (
              <TPButton
                type={TPButtonTypes.icon}
                onClick={handleNewAttachment}
                text={attachmentLabel}
                icon={TPIconTypes.newEntity}
              />
            )}
            {errorMessageValue != null && errorMessageValue != "" && (
              <span className="invalid-feedback">{errorMessageValue}</span>
            )}
          </div>
        </div>
      );
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo labelstyles
        //todo horizontal
        <div className="form-group">
          {/* <span>renderViewMode1</span> */}
          <TPLabel
            // htmlFor={theId}
            isMandatory={isMandatory}
            labelText={labelText}
            style={labelStyle}
          />
          <div className="input-group" style={{ width: "100%" }}>
            {textValue != null && textValue.trim() != "" ? (
              // File name
              <>
                <span
                  onClick={() => handleOpenAttachmentClick(textValue, pathFile)}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {textValue.split(delimiterADDA)[1]}
                </span>
              </>
            ) : (
              <>
                <span>{attachmentLabel}</span>
              </>
            )}
          </div>
        </div>
      );
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo better render
        <React.Fragment>
          <div className="row">
            {/* <span>renderViewMode2</span> */}
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col text-break">
              {textValue != null && textValue.trim() != "" ? (
                <>
                  <span>{textValue.split(delimiterADDA)[1]}</span>
                </>
              ) : (
                <>
                  <span>{attachmentLabel}</span>
                </>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    };

    useEffect(() => {
      //load validation error messages
      loadResources();
    }, []);

    return (
      <React.Fragment>
        {modeUI == TPAddtionalDataUIModes.Collect
          ? renderCollectMode()
          : modeUI == TPAddtionalDataUIModes.View1
            ? renderViewMode1()
            : renderViewMode2()}

        {/* New attachment modal */}
        {isShownModalAttachment && (
          <TPModal
            modalState={{
              titleModal: newAttachmentTitleLabel,
              acceptLabel: deleteOkLabel,
              cancelLabel: deleteCanceLabel,
              callBackAnswer: handleCallBackNewAttachmentModal,
              callBackData: null,
              isShown: isShownModalAttachment,
              modalWidth: ModalSizeEnum.MODALMD,
            }}
          >
            <TPLoadingOverlay active={isLoadingScreenModalAttachment}>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      onChange={(e: any) =>
                        handleOnAttachmentValueChange(e.target.value, "sender")
                      }
                      value={caseAttachmentState.sender}
                      isMandatory={true}
                      labelText={senderLabel}
                      errorMessage={caseAttachmentState.senderErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <TPTextArea
                      id="IdTextArea"
                      onChange={(e: any) =>
                        handleOnAttachmentValueChange(
                          e.target.value,
                          "description",
                        )
                      }
                      value={caseAttachmentState.description}
                      isMandatory={true}
                      labelText={descriptionLabel}
                      errorMessage={caseAttachmentState.descriptionErrorMessage}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FileUploader
                    label={dropFilesHereLabel}
                    multiple={false}
                    handleChange={handleUploadFilesDraggedChange}
                    UploadButtonText={uploadFileButtonLabel}
                  />

                  {caseAttachmentState.notFilesErrorMessage && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "0.875em",
                          color: "#DC3545",
                        }}
                      >
                        {caseAttachmentState.notFilesErrorMessage}
                      </div>
                    </div>
                  )}
                  {caseAttachmentState.filesDraggedList &&
                    caseAttachmentState.filesDraggedList.length >= 1 && (
                      <div className="mt-2">
                        <h5 className="tpbold tp-primary-color">
                          {tabAttachmentsLabel}
                        </h5>
                        <hr />
                        <ul className="new_case__attachments-container">
                          {caseAttachmentState.filesDraggedList.length >= 1 &&
                            caseAttachmentState.filesDraggedList.map(function (
                              item: any,
                              index,
                            ) {
                              return (
                                <li
                                  className="d-flex mt-1"
                                  key={"lifile" + index.toString()}
                                >
                                  <span>{item.name}</span>
                                  <span className="new_case__attachments-btn-delete">
                                    <TPIcon
                                      className="tphover"
                                      key={"deletefile" + index.toString()}
                                      style={{
                                        fontSize: "25px",
                                        cursor: "pointer",
                                      }}
                                      iconType={TPIconTypes.delete}
                                      onClick={() =>
                                        handleOnDeleteAttachment(item.name)
                                      }
                                    />
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
            </TPLoadingOverlay>
          </TPModal>
        )}
      </React.Fragment>
    );
  },
);

export default TPAdditionalDataAttachment;
