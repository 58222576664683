/**
 * group clone form enum
 */
enum GroupCloneFormEnum {
  /**
   * store attribute name and component name
   */
  GroupCloneForm = "GroupCloneForm",

  /**
   * keyword resource set local
   */
  GroupCloneFormComponent = "GroupCloneFormComponent",

  /**
   * labels
   */

  /**
   * Title label
   */
  TitleLabel = "TitleLabel",

  /**
   * element to clone label
   */
  ElementToCloneLabel = "ElementToCloneLabel",

  /**
   * new element id label
   */
  NewElementIdLabel = "NewElementIdLabel",

  /**
   * email label
   */
  EmailLabel = "EmailLabel",

  /**
   * time zone label
   */
  TimeZoneLabel = "TimeZoneLabel",

  /**
   * calendar label
   */
  CalendarLabel = "CalendarLabel",

  /**
   * default language label
   */
  DefaultLanguageLabel = "DefaultLanguageLabel",

  /**
   * description label
   */
  DescriptionLabel = "DescriptionLabel",

  /**
   * is active label
   */
  IsActiveLabel = "IsActiveLabel",

  /**
   * Team label
   */
  TeamLabel = "TeamLabel",

  /**
   * department label
   */
  DepartmentLabel = "DepartmentLabel",

  /**
   * schedule label
   */
  ScheduleLabel = "ScheduleLabel",

  /**
   * Supervisor label
   */
  SupervisorLabel = "SupervisorLabel",

  /**
   * required field label
   */
  RequiredFieldLabel = "RequiredFieldLabel",

  /**
   * maximum 20 characters allowed label
   */
  Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

  /**
   * save label when clicking the save button
   */
  OkButtonLabel = "Save",

  /**
   * save label when clicking the save button
   */
  CancelButtonLabel = "Cancel",

  /**
   * save label when clicking the save button
   */
  ModalTitleLabel = "ModalTitleLabel",

  /**
   * save label when clicking the save button
   */
  ModalSelectedBranchLabel = "ModalSelectedBranchLabel",

  /**
   * save label when clicking the save button
   */
  EmptyLabel = "EmptyLabel",

  /**
   * save label when clicking the save button
   */
  ModalAcceptLabel = "ModalAcceptLabel",

  /**
   * save label when clicking the save button
   */
  ModalCancelLabel = "ModalCancelLabel",

  /**
   * Notification messages
   */

  /**
   * Notification message when you clone a template and get an error
   */
  CloneGetMessageErrorMessage = "Error please contact system administrator",

  /**
   * Notification message when a template is successfully cloned
   */
  CloneSaveSuccessMessage = "CloneSaveSuccessMessage",

  /**
   * Notification message when you clone a template and get an error
   */
  CloneSaveErrorMessage = "CloneSaveErrorMessage",
}

export default GroupCloneFormEnum;
