import { TPLog, TPLogType } from "@/helpers/TPLog";

export interface IDownloadFile {
  isProxy?: boolean;
  sasUri: string;
  originalFileName: string;
  mimeType: string;
}
export class DownloadFileService {
  serviceFileName: string = "DownloadFileService.ts";

  public async downloadFileUri({
    sasUri,
    originalFileName,
    mimeType,
  }: IDownloadFile) {
    const response = await fetch(`${sasUri}`, {
      method: "GET",
      headers: {
        "Content-Type": mimeType,
      },
    });

    try {
      const blob = await response.blob();
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", originalFileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} downloadFileUri ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} downloadFileUri`);
      throw new Error(`Error ${this.serviceFileName} `);
    }
  }
}
