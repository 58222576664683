// Check if the provided objects have differences
export const areEqual = function(objA: any, objB: any): boolean {

  if (objA === objB) return true;
  if (objA == null || objB == null || typeof objA !== "object" || typeof objB !== "object") return false;

  const isArrayA = Array.isArray(objA);
  const isArrayB = Array.isArray(objB);

  if (isArrayA !== isArrayB) return false;

  if (isArrayA && isArrayB) {
      if (objA.length !== objB.length) return false;
      for (let i = 0; i < objA.length; i++) {
          if (!areEqual(objA[i], objB[i])) {
              return false;
          }
      }
      return true;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
      if (!keysB.includes(key) || !areEqual(objA[key], objB[key])) {
          return false;
      }
  }

  return true;
}