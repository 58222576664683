import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  CloneScriptModel,
  CreateScriptStepModel,
  ScriptAdditionalData,
  ScriptFavoriteUpdateModel,
  ScriptHeader,
  ScriptHeaderRequestModel,
  ScriptNextStepRequest,
  ScriptNextStepUpdateModel,
  ScriptStep,
  UpdateScriptStepModel,
} from "@/models/Scripts/ScriptModel";

export class ScriptsService {
  private static serviceName = "ScriptsService";

  public static async getScripts(
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ScriptHeader[]> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/script"
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData?.data || [];
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getScripts exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get all scripts: ${err}`
      );
    }
  }

  public static async getScriptById(
    scriptId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ScriptHeader> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/script/",
      scriptId
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData?.data || ({} as ScriptHeader);
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getScriptById exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get a script by id: ${err}`
      );
    }
  }

  public static async createScript(
    scriptBody: ScriptHeaderRequestModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/script"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.postData(
        url,
        scriptBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} createScript exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to create a script: ${err}`
      );
    }
  }

  public static async updateScript(
    scriptBody: ScriptHeaderRequestModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/script"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.putData(
        url,
        scriptBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} updateScript exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to update a script: ${err}`
      );
    }
  }

  public static async deleteScript(
    scriptId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/script/",
      scriptId
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} deleteScript exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to delete a script: ${err}`
      );
    }
  }

  public static async getSteps(
    scriptId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ScriptStep[]> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/scriptStep/script/",
      scriptId,
      "/steps"
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData?.data;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getSteps exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get a script steps: ${err}`
      );
    }
  }

  public static async getStepById(
    stepId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ScriptStep> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/scriptStep/",
      stepId
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData?.data;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getStepById exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get a step by id: ${err}`
      );
    }
  }

  public static async deleteStep(
    stepId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/scriptStep/",
      stepId
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} deleteStep exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(`Unexpected error while trying to delete a step: ${err}`);
    }
  }

  public static async createStep(
    step: CreateScriptStepModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/scriptStep"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.postData(
        url,
        step,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} createStep exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to create a script step: ${err}`
      );
    }
  }

  public static async updateStep(
    step: UpdateScriptStepModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/scriptStep"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.putData(
        url,
        step,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} createStep exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to create a script step: ${err}`
      );
    }
  }

  public static async setNextStep(
    requestBody: ScriptNextStepRequest,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/scriptStep/updateDefaultNextStep"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} setNextStep exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while setting the default next step: ${err}`
      );
    }
  }

  public static async updateFavorite(
    requestBody: ScriptFavoriteUpdateModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/script/updateIsFavorite"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} updateFavorite exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to set script as (not) favorite: ${err}`
      );
    }
  }

  public static async createStepAdditionalData(
    data: ScriptAdditionalData,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/additional-data/script-steps"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.postData(
        url,
        data,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} createStepAdditionalData exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to create additional data for a step: ${err}`
      );
    }
  }

  public static async deleteStepAdditionalData(
    addDataId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/additional-data/script-steps/",
      String(addDataId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      await httpClientInstance.deleteData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} deleteStepAdditionalData exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to delete a step's additional data: ${err}`
      );
    }
  }

  public static async getStepAdditionalData(
    addDataId: number | string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<ScriptAdditionalData> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/additional-data/script-steps/",
      String(addDataId)
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData.data;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getStepAdditionalData exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get a step's additional data: ${err}`
      );
    }
  }

  public static async updateStepAdditionalData(
    data: ScriptAdditionalData,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/additional-data/script-steps"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      await httpClientInstance.putData(
        url,
        data,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} updateStepAdditionalData exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to update additional data for a step: ${err}`
      );
    }
  }

  public static async cloneScript(
    scriptBody: CloneScriptModel,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<any> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/script/clone"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      const r = await httpClientInstance.postData(
        url,
        scriptBody,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return r.responseData;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} cloneScript exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to clone a script: ${err}`
      );
    }
  }

  public static async checkLogic(
    scriptId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<any> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/scriptStep/check-Logic/",
        scriptId
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      const r = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return r.responseData;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} checkLogic exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to check the logic of a script: ${err}`
      );
    }
  }

  public static async updateNextSteps(
    requestBody: ScriptNextStepUpdateModel[],
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<any> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = String().concat(
        TPGlobal.baseAPIGateway,
        "api/tenant/",
        TPGlobal.tenantHex,
        "/scriptStep/updateAllDefaultNextStepById"
      );
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
      const r = await httpClientInstance.putData(
        url,
        { scriptStepDefaultNextStepList: requestBody },
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return r.responseData;
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} updateNextSteps exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to update a scripts next steps: ${err}`
      );
    }
  }

  public static async getDiagram(
    scriptId: string,
    showSuccessMessage: boolean,
    showErrorMessage: boolean,
    expectedCodes: number[]
  ): Promise<string> {
    const httpClientInstance = new TPHTTPService();
    const url = String().concat(
      TPGlobal.baseAPIGateway,
      "api/tenant/",
      TPGlobal.tenantHex,
      "/script/diagram/",
      scriptId
    );
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response = await httpClientInstance.getData(
        url,
        expectedCodes,
        showSuccessMessage,
        showErrorMessage,
        headers
      );
      return response.responseData?.data || "";
    } catch (err) {
      TPLog.Log(
        `Error ${this.serviceName} getDiagram exception`,
        TPLogType.ERROR,
        err
      );
      console.error(err);
      throw new Error(
        `Unexpected error while trying to get script diagram: ${err}`
      );
    }
  }
}