import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { useReducer, useState } from "react";




enum commandsEnum {
    defaul,
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}



export const useStatusCaseCreationStats = () => {
    const resourceSet: string = "CaseCreationStatsComponent";

    const [statusModel, setStatusModel] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);

    const reducerAction = (status = statusModel, action: { type: commandsEnum, payload: any }) => {
        switch (action.type) {
            
            case commandsEnum.defaul:
                if (status == undefined || status == null) status = Array<any>();
                var temp = [...status];
                temp.push(action.payload);
                return temp;
                break;
            default:
                return status;
        }
    }

    const [status, dispatch] = useReducer(reducerAction, new Array<any>);


    return {
        status,      
        resourceSet,      
        setLoading,
        loading
    };
}


