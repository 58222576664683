import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface TaskRejectInputDTO {
  id: number;
  reasonId: string;
  comments: string;
  typistGuidUser: string;
  [x: string]: any;
}

export class TaskRejectInputDTOValidator extends Validator<TaskRejectInputDTO> {
  constructor() {
    super();
    const resourceSet = "TASKRejectInputDTOValidator";

    this.ruleFor("id")
      .notNull()
      .withMessage(resourceSet + "|" + "InputDTOTaskIdEmpty")
      .greaterThan(0)
      .withMessage(resourceSet + "|" + "InputDTOTaskIdEmpty");

    this.ruleFor("reasonId")
      .notEmpty()
      .withMessage(resourceSet + "|" + "InputDTOTaskReasonIdEmpty")
      .maxLength(20)
      .withMessage(resourceSet + "|" + "InputDTOInvalidReasonIdLength")
      .must((id) => {
        return TPGlobal.TPSanitize(id) == id;
      })
      .withMessage(resourceSet + "|InputDTOInvalidReasonId");

    this.ruleFor("comments")
      .notEmpty()
      .withMessage(resourceSet + "|" + "InputDTOTaskCommentsEmpty")
      .maxLength(4000)
      .withMessage(resourceSet + "|" + "InputDTOInvalidCommentsLength")
      .must((id) => {
        return TPGlobal.TPSanitize(id) == id;
      })
      .withMessage(resourceSet + "|InputDTOInvalidComments");
  }
}
