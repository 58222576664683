import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import {
  AddTaskMasiveInputDTO,
  InactiveCasesClosedMasiveInputDTO,
  ReclassifyMassiveTasksInputDTO,
  CompletePendingTasksMasiveInputDTO,
} from "@/models/Cases/CaseMassUpdateInputDTO";

export class CaseMassUpdateService {
  serviceFileName: string = "CaseMassUpdateService.ts";

  public async addMassivetask(
    intpuDTO: AddTaskMasiveInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/task/add-massive-task";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} addMassivetask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} addMassivetask`);
      throw `Error ${this.serviceFileName} addMassivetask`;
    }
  }

  public async inactiveCasesclosedMassively(
    intpuDTO: InactiveCasesClosedMasiveInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/cases/inactivate-massive-cases";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} inactiveCasesclosedMassively ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} inactiveCasesclosedMassively`,
      );
      throw `Error ${this.serviceFileName} inactiveCasesclosedMassively`;
    }
  }

  public async reclassifyMassiveTasks(
    intpuDTO: ReclassifyMassiveTasksInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/cases/reclassify-massive-cases";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reclassifyMassiveTasks ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reclassifyMassiveTasks`);
      throw `Error ${this.serviceFileName} reclassifyMassiveTasks`;
    }
  }

  public async completePendingTasks(
    intpuDTO: CompletePendingTasksMasiveInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpclient = new TPHTTPService();
    const url: string = "/task/update-massive-task-report";

    const finalURL: string = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}${url}`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        intpuDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} completePendingTasks ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} completePendingTasks`);
      throw `Error ${this.serviceFileName} completePendingTasks`;
    }
  }
}
