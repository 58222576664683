export interface InboundMailWithErrorsModel {
    id: string;
    download: string;
    viewMail: string;
    type: string;
    from: string;
    to: string;
    cc: string;
    subject: string;
    body: string;
    mailDate: string;
    insertDate: string;
    inboundMailBox: string;
    rule: string;
    plainTextBody: string;
    blobId: string;
    fileName: string;
    pathFile: string;
}

export class InputAction
{
    mailIDs: string = ""
}