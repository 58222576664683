import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
// import { SchedulesInputDTO } from "@/models/Schedules/SchedulesInputDTO";
import { TemplateViewModel } from "@/models/Templates/TemplatesModels";

export class TemplatesService {
  serviceFileName: string = "SchedulesService.ts";

  public async getTemplateByFilter(
    filterActive: string,
    filterTemplateType: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TemplateViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/template?FilterIsActive=";
    url = url + filterActive;
    //todo template filter type

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTemplateByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTemplateByFilter`);
      throw new Error(`Error ${this.serviceFileName} getTemplateByFilter`);
    }
  }

  //   public async getRelationById(
  //     relationId: string,
  //     showPositiveMessage: boolean,
  //     showNegativeMessage: boolean,
  //     expectedCodes: Array<number>
  //   ): Promise<RelationsViewModel> {
  //     let httpclient = new TPHTTPService();
  //     let url: string;
  //     url = "/relation/";
  //     url = url + relationId;

  //     let finalURL: string =
  //       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //     let response: HTTPResponse;
  //     try {
  //       response = await httpclient.getData(
  //         finalURL,
  //         expectedCodes,
  //         showPositiveMessage,
  //         showNegativeMessage,
  //         headers
  //       );
  //       return response.responseData.data[0];
  //     } catch (error) {
  //       TPLog.Log(
  //         `Error ${this.serviceFileName} getRelationById ex`,
  //         TPLogType.ERROR,
  //         error
  //       );
  //       console.error(`Error ${this.serviceFileName} getRelationById`);
  //       throw new Error(`Error ${this.serviceFileName} getRelationById`);
  //     }
  //   }

  //   public async deleteRelationById(
  //     relationId: string,
  //     showPositiveMessage: boolean,
  //     showNegativeMessage: boolean,
  //     expectedCodes: Array<number>
  //   ): Promise<any> {
  //     let httpclient = new TPHTTPService();
  //     let url: string;
  //     url = "/relation/" + relationId;

  //     let finalURL: string =
  //       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //     let response: HTTPResponse;
  //     try {
  //       response = await httpclient.deleteData(
  //         finalURL,
  //         expectedCodes,
  //         showPositiveMessage,
  //         showNegativeMessage,
  //         headers
  //       );
  //       return response;
  //     } catch (error) {
  //       TPLog.Log(
  //         `Error ${this.serviceFileName} deleteRelationById ex`,
  //         TPLogType.ERROR,
  //         error
  //       );
  //       console.error(`Error ${this.serviceFileName} deleteRelationById`);
  //       throw new Error(`Error ${this.serviceFileName} deleteRelationById`);
  //     }
  //   }

  //   public async insertRelation(
  //     inputDTO: RelationsInputDTO,
  //     showPositiveMessage: boolean,
  //     showNegativeMessage: boolean,
  //     expectedCodes: Array<number>
  //   ): Promise<any> {
  //     let httpclient = new TPHTTPService();
  //     let url: string;
  //     url = "/relation";

  //     let finalURL: string =
  //       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //     let response: HTTPResponse;
  //     try {
  //       response = await httpclient.postData(
  //         finalURL,
  //         inputDTO,
  //         expectedCodes,
  //         showPositiveMessage,
  //         showNegativeMessage,
  //         headers
  //       );
  //       return response;
  //     } catch (error) {
  //       TPLog.Log(
  //         `Error ${this.serviceFileName} insertRelation ex`,
  //         TPLogType.ERROR,
  //         error
  //       );
  //       console.error(`Error ${this.serviceFileName} insertRelation`);
  //       throw new Error(`Error ${this.serviceFileName} insertRelation`);
  //     }
  //   }

  //   public async updateRelation(
  //     inputDTO: RelationsInputDTO,
  //     showPositiveMessage: boolean,
  //     showNegativeMessage: boolean,
  //     expectedCodes: Array<number>
  //   ): Promise<any> {
  //     let httpclient = new TPHTTPService();
  //     let url: string;
  //     url = "/relation";

  //     let finalURL: string =
  //       TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
  //     let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

  //     let response: HTTPResponse;
  //     try {
  //       response = await httpclient.putData(
  //         finalURL,
  //         inputDTO,
  //         expectedCodes,
  //         showPositiveMessage,
  //         showNegativeMessage,
  //         headers
  //       );
  //       return response;
  //     } catch (error) {
  //       TPLog.Log(
  //         `Error ${this.serviceFileName} updateRelation ex`,
  //         TPLogType.ERROR,
  //         error
  //       );
  //       console.error(`Error ${this.serviceFileName} updateRelation`);
  //       throw new Error(`Error ${this.serviceFileName} updateRelation`);
  //     }
  //   }
}
