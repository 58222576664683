import allThemes from "@/assets/styles/theme";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, useEffect, useState } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";

interface TPCustomAutocompleteProperties {
  key?: string;
  input: string;
  onQuery: (input: string) => void;
  children: React.ReactElement[];
  suggestionTimeout?: number;
  title?: string;
  placeholder?: string;
  mandatory?: boolean;
  disabled?: boolean;

  focused?: string;
}

const TPCustomAutocomplete: FC<TPCustomAutocompleteProperties> = function ({
  key,
  input,
  onQuery,
  children,
  suggestionTimeout,
  placeholder,
  title,
  mandatory,
  disabled,
  focused,
}) {
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    if (focused == "off") {
      setFocus(false);
      setShowSuggestion(false);
    }
  }, [focused]);

  useEffect(() => {
    if (focus) setShowSuggestion(true);

    const intervalId = setTimeout(() => {
      if (!focus) setShowSuggestion(false);
    }, suggestionTimeout || 1000);

    return () => clearInterval(intervalId);
  }, [focus]);

  return (
    <div
      key={key}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
        {title && (
          <label htmlFor="tp-custom-autocomplete" className="form-label">
            {title}
          </label>
        )}
        {mandatory && <label style={{ color: "red" }}>*</label>}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          flex: 1,
        }}
      >
        <input
          id="tp-custom-autocomplete"
          type="text"
          className="tp-custom-autocomplete form-control"
          onChange={(e) => onQuery(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholder={placeholder || ""}
          value={input}
          style={{
            borderRadius: "4px",
            border: focus
              ? `1px solid ${allThemes.base.primary}`
              : "1px solid gainsboro",
            backgroundColor: disabled ? "gainsboro" : "white",
            padding: "4px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: "none",
          }}
          disabled={disabled}
        />
        <div
          style={{
            cursor: "pointer",
            borderRadius: "4px",
            border: "1px solid gainsboro",
            borderLeft: "none",
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => {
            if (!disabled) setFocus(!focus);
          }}
          onBlur={() => {
            if (!disabled) setFocus(!focus);
          }}
        >
          <TPIcon iconType={TPIconTypes.caretDown} />
        </div>
      </div>
      <div
        style={{
          display: focus || (showSuggestion && !disabled) ? "block" : "none",
          position: "absolute",
          top: "100%",
          left: 0,
          overflowY: "auto",
          zIndex: 2,
          width: "100%",
          maxHeight: "256px",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "4px",
        }}
      >
        {children.map(
          (component) =>
            component.key?.toLowerCase().includes(input.toLowerCase()) && (
              <>{component}</>
            )
        )}
      </div>
    </div>
  );
};

export default TPCustomAutocomplete;
