import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  WallboardEnum as e,
  TicketTypeEnum,
  WallboardEvents,
  WallboardListProps,
} from "@/models/Wallboard/WallboardModel";
import { TPChip } from "@/components/TPChip/TPChip";
import moment from "moment";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPModalQuestion from "@/layouts/ModalQuestion/TPModalQuestion";
import UserProfile from "@/modules/core/design-system/user-data/UserProfile";

/**
 * STYLES
 */
const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  } as CSSProperties,
  container: {
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    overflowY: "hidden",
  } as CSSProperties,
  queueContainer: {
    display: "flex",
    flexFlow: "wrap",
    boxSizing: "border-box",
  } as CSSProperties,
  queue: {
    width: "50%",
    height: "830px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflowY: "auto",
  } as CSSProperties,
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "space-between",
    gap: "10px",
    padding: "10px 20px",
  } as CSSProperties,
  cell: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard team view
 */
const WallboardList = forwardRef(
  (
    { id, key, style, type = "completed", m, data }: WallboardListProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * selected
     */
    const [selected, setSelected] = useState<string>("");
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as WallboardEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {}, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     *
     */
    function onAfterCloseModalHandler(response: boolean) {
      const id = `${selected}`;
      setSelected("");

      if (response) {
        console.log(`Stoped ${id}`);

        // const { stop } = new WallboardService();
        // stop(id)
        //   .then(() => {
        //     dispatch({
        //       type: WallboardStateActionType.setData,
        //       payload: { type: TabTypeEnum.TeamView, data },
        //     });
        //   })
        //   .catch((error) => {
        //     console.error(`Error ${e.nameTeam} loadDataSources`, error);
        //   });
      }
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
      <div
        id={id ?? e.nameList}
        key={key ?? e.nameList}
        style={{
          ...styles.container,
          maxHeight: type === "completed" ? `${70 * 10}px` : `${60 * 10}px`,
          ...style,
        }}
        onMouseEnter={(e) => {
          (e.currentTarget as HTMLElement).style.overflowY = "auto";
        }}
        onMouseLeave={(e) => {
          (e.currentTarget as HTMLElement).style.overflowY = "hidden";
        }}
      >
        {data.map((item, index) => (
          <div
            id={`${e.nameList}Row${index}`}
            key={`${e.nameList}Row${index}`}
            style={{
              ...styles.row,
              height: type === "completed" ? "70px" : "67px",
              borderBottom:
                data.length - 1 === index
                  ? "transparent solid 1px"
                  : "#bfbfbf solid 1px",
            }}
          >
            <div
              id={`${e.nameList}CellConsecutive`}
              key={`${e.nameList}CellConsecutive`}
              style={{ ...styles.cell, width: "50px" }}
            >
              <p style={{ fontWeight: "bold", margin: "0" }}>
                {index + 1 < 10 ? `0${index + 1}` : index + 1}
              </p>
            </div>

            <div
              id={`${e.nameList}CellUser`}
              key={`${e.nameList}CellUser`}
              style={{
                ...styles.cell,
                justifyContent: "flex-start",
                flex: "1 1 0%",
              }}
            >
              <UserProfile completeName={item.userName} imageUrl={item?.url} />

              <div style={styles.column}>
                <h6 style={{ margin: "0", fontWeight: "bold" }}>
                  {item.userName}
                </h6>

                {type === "completed" && (
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    {item.userEmail}
                  </p>
                )}
              </div>
            </div>

            <div
              id={`${e.nameList}CellTicketType`}
              key={`${e.nameList}CellTicketType`}
              style={{ ...styles.cell, width: "20%" }}
            >
              <TPChip
                label={
                  item.ticketType === TicketTypeEnum.CASE
                    ? m.TableColumnTicketTypeCaseLabel
                    : item.ticketType === TicketTypeEnum.EVENT
                      ? m.TableColumnTicketTypeEventLabel
                      : item.ticketType === TicketTypeEnum.MAIL
                        ? m.TableColumnTicketTypeMailLabel
                        : ""
                }
                icon={TPIconTypes.circle}
                iconSize="10px"
                backgroundColor={
                  item.ticketType === TicketTypeEnum.CASE
                    ? "#ffe9fd"
                    : item.ticketType === TicketTypeEnum.EVENT
                      ? "#d8fff4"
                      : item.ticketType === TicketTypeEnum.MAIL
                        ? "#c8e3fc"
                        : "#fff"
                }
                iconColor={
                  item.ticketType === TicketTypeEnum.CASE
                    ? "#f00085"
                    : item.ticketType === TicketTypeEnum.EVENT
                      ? "#008673"
                      : item.ticketType === TicketTypeEnum.MAIL
                        ? "#002e81"
                        : "#fff"
                }
              />
            </div>

            {type === "completed" && (
              <div
                id={`${e.nameList}CellTicketId`}
                key={`${e.nameList}CellTicketId`}
                style={{
                  ...styles.cell,
                  ...styles.column,
                  width: "20%",
                  gap: "0",
                }}
              >
                <div>
                  <p style={{ margin: "0" }}>{m.TableColumnIdLabel}</p>

                  <p style={{ margin: "0", fontWeight: "bold" }}>
                    {item.ticketId}
                  </p>
                </div>
              </div>
            )}

            <div
              id={`${e.nameList}CellTime`}
              key={`${e.nameList}CellTime`}
              style={{
                ...styles.cell,
                ...styles.column,
                width: "20%",
                gap: "0",
              }}
            >
              <div>
                {type === "completed" && (
                  <p style={{ margin: "0" }}>{m.TableColumnTimeLabel}</p>
                )}

                <div style={{ ...styles.row, padding: "0" }}>
                  <p style={{ margin: "0", fontWeight: "bold" }}>
                    {item.timeSeconds}
                  </p>

                  {false && (
                    <TPButton
                      id={`${e.nameList}IconButtonStop`}
                      key={`${e.nameList}IconButtonStop`}
                      style={{ transform: "translateY(-3px)" }}
                      type={TPButtonTypes.icon}
                      icon={TPIconTypes.stop}
                      tooltip={"Stop"}
                      onClick={() => setSelected(item.ticketId)}
                    />
                  )}

                  {!!selected && selected === item.ticketId && (
                    <TPModalQuestion
                      id={`${e.nameList}Modal${selected}`}
                      key={`${e.nameList}Modal${selected}`}
                      title={`${m.ModalTitleLabel}`}
                      yesLabel={`${m.ModalYesLabel}`}
                      noLabel={`${m.ModalNoLabel}`}
                      question={`${m.ModalQuestionLabel}`}
                      callBackData={{}}
                      isShown={!!selected}
                      callBackAnswer={onAfterCloseModalHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default WallboardList;
