import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailAzureApplicationDTO } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationDTO";
import { InboundMailAzureApplicationViewModel } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationViewModel";

export class InboundMailAzureApplicationService {
  serviceFileName: string = "InboundMailAzureApplicationService.ts";

  public async getInboundMailAzureApplicationByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailAzureApplicationViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailazureapplication?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationByFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationByFilter`,
      );
    }
  }

  public async getInboundMailAzureApplicationById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<InboundMailAzureApplicationViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailazureapplication/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationById`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailAzureApplicationById`,
      );
    }
  }

  public async deleteInboundMailAzureApplicationById(
    applicationId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailazureapplication/" + applicationId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteInboundMailAzureApplicationById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} deleteInboundMailAzureApplicationById`,
      );
      throw new Error(
        `Error ${this.serviceFileName} deleteInboundMailAzureApplicationById`,
      );
    }
  }

  public async insertInboundMailAzureApplication(
    inputDTO: InboundMailAzureApplicationDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailazureapplication";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertInboundMailAzureApplication ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} insertInboundMailAzureApplication`,
      );
      throw new Error(
        `Error ${this.serviceFileName} insertInboundMailAzureApplication`,
      );
    }
  }

  public async updateInboundMailAzureApplication(
    inputDTO: InboundMailAzureApplicationDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailazureapplication";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateInboundMailAzureApplication ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} updateInboundMailAzureApplication`,
      );
      throw new Error(
        `Error ${this.serviceFileName} updateInboundMailAzureApplication`,
      );
    }
  }
}
