import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { ReactNode } from "react";

export interface TPModalLanguageListStateType {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  acceptLabel: string;
  cancelLabel: string;
  closeModal: () => void;
  saveChanges: () => void;
  id?: string;
}

const TPModalLanguageList = ({
  children,
  isOpen,
  title,
  acceptLabel,
  cancelLabel,
  closeModal,
  saveChanges,
  id = "",
}: TPModalLanguageListStateType) => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isOpen) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  return (
    <>
      <div
        id={id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <TPButton
                id="close-modal-language"
                type={TPButtonTypes.empty}
                onClick={closeModal}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">{children}</div>
              </div>
            </div>
            <div className="modal-footer">
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={saveChanges}
                isDesignSystem
                style={{
                  paddingLeft: '16px',
                  paddingRight: '16px'
              }}
              >
                {acceptLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>
      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalLanguageList;
