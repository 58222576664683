import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { camelCaseToId } from "@/modules/core/utils/text-regex";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ReactElement } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import {
    BsLightningChargeFill,
    BsPersonFillCheck,
    BsTicketPerforated,
} from "react-icons/bs";
import { CiCircleInfo } from "react-icons/ci";
import {
    FaCircle,
    FaCopy,
    FaEye,
    FaPlus,
    FaPlusSquare,
    FaRegStopCircle,
    FaThLarge,
} from "react-icons/fa";
import { FaCirclePlay, FaRegCircleCheck } from "react-icons/fa6";
import {
    FiAlertCircle,
    FiDownload,
    FiUpload,
    FiUploadCloud,
} from "react-icons/fi";
import { GoChevronDown, GoDash } from "react-icons/go";
import { HiTicket } from "react-icons/hi2";
import { IoIosStar, IoMdSwap } from "react-icons/io";
import { IoPersonCircleOutline } from "react-icons/io5";
import { LuRefreshCcw } from "react-icons/lu";
import {
    MdAccountTree,
    MdAdd,
    MdAddCircle,
    MdAllInbox,
    MdArrowCircleDown,
    MdArrowDropDown,
    MdArrowDropUp,
    MdAssessment,
    MdAutoAwesomeMosaic,
    MdAutoGraph,
    MdCalendarToday,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdCheckCircle,
    MdChecklist,
    MdChevronLeft,
    MdChevronRight,
    MdClose,
    MdCloseFullscreen,
    MdDeleteForever,
    MdDeviceHub,
    MdDone,
    MdDownload,
    MdDragIndicator,
    MdDynamicFeed,
    MdEdit,
    MdEditCalendar,
    MdEmail,
    MdEscalatorWarning,
    MdExpandLess,
    MdExpandMore,
    MdFileDownload,
    MdFilterAlt,
    MdFirstPage,
    MdFitScreen,
    MdFullscreen,
    MdFullscreenExit,
    MdGridOn,
    MdGroup,
    MdHelpOutline,
    MdHome,
    MdHourglassTop,
    MdImage,
    MdInfo,
    MdInsertDriveFile,
    MdKeyboard,
    MdKeyboardArrowDown,
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight,
    MdLanguage,
    MdLastPage,
    MdLibraryAdd,
    MdLock,
    MdLogin,
    MdLogout,
    MdLoop,
    MdMenu,
    MdMoreVert,
    MdNotifications,
    MdOpenInFull,
    MdOutlineMoreVert,
    MdOutlineSentimentDissatisfied,
    MdOutlineSentimentNeutral,
    MdOutlineSentimentSatisfiedAlt,
    MdOutlineWatchLater,
    MdOutlineWorkOutline,
    MdPermIdentity,
    MdPerson,
    MdPersonSearch,
    MdPlaylistAddCheck,
    MdPrint,
    MdPublic,
    MdReceipt,
    MdRefresh,
    MdSearch,
    MdSettings,
    MdSortByAlpha,
    MdStar,
    MdStarOutline,
    MdSwapVert,
    MdTab,
    MdTaskAlt,
    MdViewList,
    MdWarning,
    MdWindow,
    MdZoomIn,
    MdLaunch
} from "react-icons/md";
import { PiCaretDownBold } from "react-icons/pi";
import { RiErrorWarningLine, RiProhibited2Line } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import { LuAlarmClock } from "react-icons/lu";

import * as svgIcons from '@/assets/svgs';
import { CopilotIcon } from "./TPCopilotIcon";

interface TPIconInterface {
    className?: string;
    iconType: TPIconTypes;
    tooltip?: string;
    onClick?: any;
    style?: any;
    inline?: boolean;
    id?: string;
    isTooltip?: boolean;
    tooltipPlacement?: Placement;
}

export type Placement =
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;

const TPIcon = ({
    iconType,
    className = "",
    tooltip = "",
    onClick = undefined,
    style,
    inline = false,
    isTooltip,
    tooltipPlacement,
    id,
}: TPIconInterface): ReactElement => {
    const iconList: Array<TPKeyValue> = [
        {
            key: TPIconTypes.email,
            value: <MdEmail className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.addCircle,
            value: <MdAddCircle className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.delete,
            value: <MdDeleteForever className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.remove,
            value: (
                <img
                    src={svgIcons.TrashIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.refresh,
            value: <MdRefresh className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.refreshCircle,
            value: <LuRefreshCcw className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.clone,
            value: <MdDynamicFeed className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.newEntity,
            value: <MdInsertDriveFile className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.home,
            value: <MdHome className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.edit,
            value: <MdEdit className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.menu,
            value: <MdMenu className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.receipt,
            value: <MdReceipt className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.public,
            value: <MdPublic className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.lock,
            value: <MdLock className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.person,
            value: <MdPerson className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.personCircle,
            value: <IoPersonCircleOutline className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.diagram,
            value: <MdDeviceHub className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.expandLess,
            value: <MdExpandLess className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.expandMore,
            value: <MdExpandMore className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.expandDown,
            value: (
                <img
                    src={svgIcons.ArrowDownIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.expandRight,
            value: (
                <img
                    src={svgIcons.ArrowRightIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.activeInactive,
            value: className.includes("inactive") ? (
                <MdCheckBoxOutlineBlank className={className} title={tooltip} />
            ) : (
                <MdCheckBox className={className} title={tooltip} />
            ),
        },
        {
            key: TPIconTypes.moreVert,
            value: <MdMoreVert className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.chevronRight,
            value: <MdChevronRight className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.chevronLeft,
            value: <MdChevronLeft className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.search,
            value: <MdSearch className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.arrowcircledown,
            value: <MdArrowCircleDown className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.fileDownload,
            value: <MdFileDownload className={className} />,
        },
        {
            key: TPIconTypes.parent,
            value: <MdEscalatorWarning className={className} />,
        },
        {
            key: TPIconTypes.gridon,
            value: <MdGridOn className={className} />,
        },
        {
            key: TPIconTypes.window,
            value: <MdWindow className={className} />,
        },
        {
            key: TPIconTypes.libraryAdd,
            value: <MdLibraryAdd className={className} />,
        },
        {
            key: TPIconTypes.playlistAddCheck,
            value: <MdPlaylistAddCheck className={className} />,
        },
        {
            key: TPIconTypes.assessment,
            value: <MdAssessment className={className} />,
        },
        {
            key: TPIconTypes.accountTree,
            value: <MdAccountTree className={className} />,
        },
        {
            key: TPIconTypes.image,
            value: <MdImage className={className} />,
        },
        {
            key: TPIconTypes.close,
            value: <MdClose className={className} />,
        },
        {
            key: TPIconTypes.notifications,
            value: <MdNotifications className={className} />,
        },
        {
            key: TPIconTypes.keyboardArrowDown,
            value: <MdKeyboardArrowDown className={className} />,
        },
        {
            key: TPIconTypes.calendar,
            value: <MdEditCalendar className={className}></MdEditCalendar>,
        },
        {
            key: TPIconTypes.calendarToday,
            value: <MdCalendarToday className={className} />,
        },
        {
            key: TPIconTypes.checklist,
            value: <MdChecklist className={className} />,
        },
        {
            key: TPIconTypes.filter,
            value: <MdFilterAlt className={className} />,
        },
        {
            key: TPIconTypes.settings,
            value: <MdSettings className={className} />,
        },
        {
            key: TPIconTypes.fullScreen,
            value: <MdFullscreen className={className} />,
        },
        {
            key: TPIconTypes.fullScreenExit,
            value: <MdFullscreenExit className={className} />,
        },
        {
            key: TPIconTypes.closeFullScreen,
            value: <MdCloseFullscreen className={className} />,
        },
        {
            key: TPIconTypes.fitScreen,
            value: <MdFitScreen className={className} />,
        },
        {
            key: TPIconTypes.openInFull,
            value: <MdOpenInFull className={className} />,
        },
        {
            key: TPIconTypes.logIn,
            value: <MdLogin className={className} />,
        },
        {
            key: TPIconTypes.logOut,
            value: <MdLogout className={className} />,
        },
        {
            key: TPIconTypes.sortByAlpha,
            value: <MdSortByAlpha className={className} />,
        },
        {
            key: TPIconTypes.watchLater,
            value: <MdOutlineWatchLater className={className} />,
        },
        {
            key: TPIconTypes.checkCircle,
            value: <MdCheckCircle className={className} />,
        },
        {
            key: TPIconTypes.swapVert,
            value: <MdSwapVert className={className} />,
        },
        {
            key: TPIconTypes.warning,
            value: <MdWarning className={className} />,
        },
        {
            key: TPIconTypes.identity,
            value: <MdPermIdentity className={className} />,
        },
        {
            key: TPIconTypes.tab,
            value: <MdTab className={className} />,
        },
        {
            key: TPIconTypes.firstPage,
            value: <MdFirstPage className={className} />,
        },
        {
            key: TPIconTypes.lastPage,
            value: <MdLastPage className={className} />,
        },
        {
            key: TPIconTypes.favorite,
            value: <MdStar className={className} />,
        },
        {
            key: TPIconTypes.ticket,
            value: <BsTicketPerforated className={className} />,
        },
        {
            key: TPIconTypes.noFavorite,
            value: <MdStarOutline className={className} />,
        },
        {
            key: TPIconTypes.teams,
            value: <AiOutlineTeam className={className} />,
        },
        {
            key: TPIconTypes.import,
            value: <BiImport className={className} />,
        },
        {
            key: TPIconTypes.excel,
            value: <SiMicrosoftexcel className={className} />,
        },
        {
            key: TPIconTypes.task,
            value: <MdTaskAlt className={className} />,
        },
        {
            key: TPIconTypes.keyboard,
            value: <MdKeyboard className={className} />,
        },
        {
            key: TPIconTypes.personSearch,
            value: <MdPersonSearch className={className} />,
        },
        {
            key: TPIconTypes.all,
            value: <MdAllInbox className={className} />,
        },
        {
            key: TPIconTypes.hourglassTop,
            value: <MdHourglassTop className={className} />,
        },
        {
            key: TPIconTypes.help,
            value: <MdHelpOutline className={className} />,
        },
        {
            key: TPIconTypes.print,
            value: <MdPrint className={className} />,
        },
        {
            key: TPIconTypes.autograph,
            value: <MdAutoGraph className={className} />,
        },
        {
            key: TPIconTypes.arrowDropDown,
            value: <MdArrowDropDown className={className} />,
        },
        {
            key: TPIconTypes.arrowDropUp,
            value: <MdArrowDropUp className={className} />,
        },
        {
            key: TPIconTypes.custom,
            value: <>NA</>,
        },
        {
            key: TPIconTypes.info,
            value: <MdInfo className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.circleInfo,
            value: <CiCircleInfo className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.zoomIn,
            value: <MdZoomIn className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.language,
            value: <MdLanguage className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.mdDragIndicator,
            value: <MdDragIndicator className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.alert,
            value: <FiAlertCircle className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.copy,
            value: <FaCopy className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.mdDragIndicator,
            value: <MdDragIndicator className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.plus,
            value: <FaPlusSquare className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.circle,
            value: <FaCircle className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.eye,
            value: <FaEye className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.chevron,
            value: <GoChevronDown className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.dash,
            value: <GoDash className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.more,
            value: <MdOutlineMoreVert className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.doubleArrowLeft,
            value: (
                <MdKeyboardDoubleArrowLeft className={className} title={tooltip} />
            ),
        },
        {
            key: TPIconTypes.doubleArrowRight,
            value: (
                <MdKeyboardDoubleArrowRight className={className} title={tooltip} />
            ),
        },
        {
            key: TPIconTypes.loop,
            value: <MdLoop className={className} />,
        },
        {
            key: TPIconTypes.downloadTemplate,
            value: <MdDownload className={className} />,
        },
        {
            key: TPIconTypes.tableView,
            value: <MdViewList className={className} />,
        },
        { key: TPIconTypes.plusAlt, value: <FaPlus className={className} /> },
        {
            key: TPIconTypes.cardsView,
            value: <FaThLarge className={className} />,
        },
        {
            key: TPIconTypes.add,
            value: <MdAdd className={className} />,
        },
        {
            key: TPIconTypes.caretDown,
            value: <PiCaretDownBold className={className} />,
        },
        {
            key: TPIconTypes.eventManager,
            value: <FiUpload className={className} />,
        },
        { key: TPIconTypes.swap, value: <IoMdSwap className={className} /> },
        { key: TPIconTypes.cloud, value: <FiUploadCloud className={className} /> },
        { key: TPIconTypes.done, value: <MdDone className={className} /> },
        { key: TPIconTypes.download, value: <FiDownload className={className} /> },
        {
            key: TPIconTypes.plus,
            value: <FaPlusSquare className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.plusCircle,
            value: (
                <img
                    src={svgIcons.PlusCircleIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.circle,
            value: <FaCircle className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.eye,
            value: <FaEye className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.circleCheckOutline,
            value: <FaRegCircleCheck className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.warningCircleOutline,
            value: <RiErrorWarningLine className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.circle,
            value: <FaCircle className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.eye,
            value: <FaEye className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.cockpit,
            value: <MdAutoAwesomeMosaic className={className} title={tooltip} />,
        },
    {
      key: TPIconTypes.star,
      value: <IoIosStar className={className} title={tooltip} />,
    },
        {
            key: TPIconTypes.working,
            value: <MdGroup className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.online,
            value: <BsPersonFillCheck className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.ready,
            value: <BsLightningChargeFill className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.worked,
            value: <HiTicket className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.offline,
            value: <MdLogout className={className} />,
        },
        {
            key: TPIconTypes.work,
            value: <MdOutlineWorkOutline className={className} />,
        },
        {
            key: TPIconTypes.stop,
            value: <FaRegStopCircle className={className} />,
        },
        {
            key: TPIconTypes.circleCheckOutline,
            value: <FaRegCircleCheck className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.play,
            value: <FaCirclePlay className={className} title={tooltip} />,
        },
        {
            key: TPIconTypes.prohibited,
            value: <RiProhibited2Line className={className} />,
        },
        { key: TPIconTypes.circleCheckOutline, value: <FaRegCircleCheck className={className} title={tooltip} /> },
        { key: TPIconTypes.play, value: <FaCirclePlay className={className} title={tooltip} /> },
        { key: TPIconTypes.sentimentNeutral, value: <MdOutlineSentimentNeutral className={className} title={tooltip} /> },
        { key: TPIconTypes.sentimentSatisfiedAlt, value: <MdOutlineSentimentSatisfiedAlt className={className} title={tooltip} /> },
        { key: TPIconTypes.sentimentDissatisfied, value: <MdOutlineSentimentDissatisfied className={className} title={tooltip} /> },
        {
            key: TPIconTypes.clock,
            value: <LuAlarmClock className={className} />
        },
        {
            key: TPIconTypes.export,
            value: <MdLaunch className={className} />,
        },
        {
            key: TPIconTypes.CircleInfoFill,
            value: (
                <img
                    src={svgIcons.CircleInfoFillIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.CircleInfoFill,
            value: (
                <img
                    src={svgIcons.CircleInfoFillIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.GreenCircle,
            value: (
                <img
                    src={svgIcons.GreenCircleIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.YellowCircle,
            value: (
                <img
                    src={svgIcons.YellowCircleIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
        {
            key: TPIconTypes.RedCircle,
            value: (
                <img
                    src={svgIcons.RedCircleIcon}
                    alt="Expand Down"
                    className={className}
                />
            ),
        },
      { 
      key: TPIconTypes.copilot,
      value: <CopilotIcon/>
    }
  ];
    const icon = iconList.filter((iconElement) => iconElement.key == iconType)[0]
        ?.value;

    const getIconId = () => {
        return id
            ? camelCaseToId(id)
            : `${iconType ? camelCaseToId(iconType) : "material"}-ui-icon`;
    };

    const displayType = inline ? "inline" : "flex";

    const styledTooltipText = isTooltip ? (
        <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
            {tooltip}
        </Typography>
    ) : (
        ""
    );

    return (
        <Tooltip title={styledTooltipText} placement={tooltipPlacement}>
            <i
                style={{ ...style, display: displayType }}
                id={getIconId()}
                className={`ui-icon ${className}`}
                onClick={onClick}
            >
                {icon}
            </i>
        </Tooltip>
    );
};

export default TPIcon;
