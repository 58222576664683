import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { ProfileMenuItemViewModel } from "@/models/MenuItems/MenuItemsModels";
import {
  ProfileMenuItemAssignProfileInputDTO,
  ProfileMenuItemAssignProfileInputDTOValidator,
} from "@/models/Profiles/ProfileMenuItemAssignProfileInputDTO";
import { TPI18N } from "@/services/I18nService";
import { ProfileMenuItemService } from "@/services/ProfileMenuItemService";
import { ProfileService } from "@/services/ProfileService";
import React, { FC, useEffect, useState } from "react";
import {
  tGroupModel,
  tItemModel,
  tProfileItemModel,
  tSectionModel,
} from "./MenuDefinitionAdmin";
type MenuDefinitionProps = {
  recordInput: string;
  callBackComands: Function;
};
const MenuDefinitionAssignProfile: FC<MenuDefinitionProps> = ({
  recordInput,
  callBackComands,
}) => {
  const componentFileName: string = "MenuDefinitionAssignProfile.tsx";
  const resourceSet: string = "MenuDefinitionAssignProfileComponent";
  //screen resources (simple state)
  const [titleLabel, setTitleLabel] = useState(
    "Apply to the following profiles",
  );
  const [saveButtonLabel, setSaveButtonLabel] = useState("Save");
  const [subTitleLabel, setSubTitleLabel] = useState("Menu");
  //simple state
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [profileList, setProfileList] = useState<Array<TPKeyValue>>([]);
  const [dataTransformed, setDataTransformed] = useState<Array<tSectionModel>>(
    [],
  );

  const loadResourcesAndProfiles = async () => {
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubTitleLabel"));
    await loadProfiles();
    await loadInfo();
  };

  const loadProfiles = async () => {
    const profileClient = new ProfileService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      const profiles = await profileClient.getProfilesByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes,
      );
      const profilesToSelectConverted: Array<TPKeyValue> = profiles.map((k) => {
        return {
          key: k.guid,
          value: k.localizedDescription,
          value2: false,
        };
      });
      setProfileList(profilesToSelectConverted);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadProfiles ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadProfiles ex`);
      setIsLoadingScreen(false);
    }
  };

  const loadInfo = async () => {
    try {
      setIsLoadingScreen(true);
      let data = await getData();
      let keys: any = Object.keys(data[0]);

      let newData: Array<tSectionModel> = [];
      let found: boolean = false;
      for (let i: number = 0; i <= data.length - 1; i++) {
        if (data[i].col12Value as boolean) {
          found = false;
          for (let j: number = 0; j <= newData.length - 1; j++) {
            if (
              newData[j].sectionId.toLowerCase() ===
              data[i].col1Value.toLowerCase()
            ) {
              found = true;
              break;
            }
          }
          if (!found) {
            let newSection: tSectionModel = {
              sectionId: data[i].col1Value,
              sectionName: data[i].col13Value,
              Order: data[i].col14Value,
              Groups: [],
              profilePermissions: [],
            };
            newData.push(newSection);
          }
        }
      }
      //order by section order
      for (let i: number = 0; i <= newData.length - 2; i++) {
        for (let j: number = i + 1; j <= newData.length - 1; j++) {
          if (newData[j].Order < newData[i].Order) {
            let swap: tSectionModel = { ...newData[i] };
            newData[i] = { ...newData[j] };
            newData[j] = { ...swap };
          }
        }
      }
      //for each section get groups
      for (let i: number = 0; i <= newData.length - 1; i++) {
        let newGroups: Array<tGroupModel> = [];
        for (let j: number = 0; j <= data.length - 1; j++) {
          if (
            newData[i].sectionId.toLowerCase() ==
            data[j].col3Value?.toLowerCase()
          ) {
            let newGroup: tGroupModel = {
              groupId: data[j].col1Value,
              groupName: data[j].col13Value,
              Items: [],
              profilePermissions: [],
            };
            newGroups.push(newGroup);
          }
        }

        for (let k: number = 0; k <= newGroups.length - 2; k++) {
          for (let m: number = k + 1; m <= newGroups.length - 1; m++) {
            if (
              newGroups[m].groupName.toLowerCase() <
              newGroups[k].groupName.toLowerCase()
            ) {
              let swap: tGroupModel = { ...newGroups[k] };
              newGroups[k] = { ...newGroups[m] };
              newGroups[m] = { ...swap };
            }
          }
        }
        newData[i].Groups = [...newGroups];
      }

      //for each group in each section search for items
      for (let i: number = 0; i <= newData.length - 1; i++) {
        for (let j: number = 0; j <= newData[i].Groups.length - 1; j++) {
          for (let k: number = 0; k <= data.length - 1; k++) {
            if (
              data[k].col3Value?.toLowerCase() ==
              newData[i].Groups[j].groupId.toLowerCase()
            ) {
              let newItem: tItemModel = {
                itemId: data[k].col1Value,
                itemName: data[k].col13Value,
                profilePermissions: [],
              };
              newData[i].Groups[j].Items.push(newItem);
            }
          }

          //order items
          for (
            let k: number = 0;
            k <= newData[i].Groups[j].Items.length - 2;
            k++
          ) {
            for (
              let m: number = k + 1;
              m <= newData[i].Groups[j].Items.length - 1;
              m++
            ) {
              if (
                newData[i].Groups[j].Items[m].itemName.toLowerCase() <
                newData[i].Groups[j].Items[k].itemName.toLowerCase()
              ) {
                let swap: tItemModel = { ...newData[i].Groups[j].Items[k] };
                newData[i].Groups[j].Items[k] = {
                  ...newData[i].Groups[j].Items[m],
                };
                newData[i].Groups[j].Items[m] = { ...swap };
              }
            }
          }
        }
      }

      //fill permissions sections
      for (let i: number = 0; i <= newData.length - 1; i++) {
        for (let j: number = 0; j <= data.length - 1; j++) {
          if (
            newData[i].sectionId.toLowerCase() ===
            data[j].col1Value.toLowerCase()
          ) {
            let newPermissions: Array<tProfileItemModel> = [];
            for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
              let profileData: string;
              let profileId: string;
              let profileName: string;
              let profileValue: number;
              profileData = data[j][keys[k]];
              profileId = profileData.split("||")[1];
              profileName = profileData.split("||")[2];
              profileValue = data[j][keys[k + 1]];

              let newPermission: tProfileItemModel = {
                profileId: profileId,
                profileName: profileName,
                profilePermission: profileValue,
              };
              newPermissions.push(newPermission);
            }
            newData[i].profilePermissions = [...newPermissions];
          }
        }
      }

      //fill groups permissions
      for (let i: number = 0; i <= newData.length - 1; i++) {
        for (let g: number = 0; g <= newData[i].Groups.length - 1; g++) {
          for (let j: number = 0; j <= data.length - 1; j++) {
            if (
              newData[i].Groups[g].groupId.toLowerCase() ===
              data[j].col1Value.toLowerCase()
            ) {
              let newPermissions: Array<tProfileItemModel> = [];
              for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
                let profileData: string;
                let profileId: string;
                let profileName: string;
                let profileValue: number;
                profileData = data[j][keys[k]];
                profileId = profileData.split("||")[1];
                profileName = profileData.split("||")[2];
                profileValue = data[j][keys[k + 1]];

                let newPermission: tProfileItemModel = {
                  profileId: profileId,
                  profileName: profileName,
                  profilePermission: profileValue,
                };
                newPermissions.push(newPermission);
              }
              newData[i].Groups[g].profilePermissions = [...newPermissions];
            }
          }
        }
      }

      //fill items permissions
      for (let i: number = 0; i <= newData.length - 1; i++) {
        for (let g: number = 0; g <= newData[i].Groups.length - 1; g++) {
          for (
            let m: number = 0;
            m <= newData[i].Groups[g].Items.length - 1;
            m++
          ) {
            for (let j: number = 0; j <= data.length - 1; j++) {
              if (
                newData[i].Groups[g].Items[m].itemId.toLowerCase() ===
                data[j].col1Value.toLowerCase()
              ) {
                let newPermissions: Array<tProfileItemModel> = [];
                for (let k: number = 30; k <= keys.length - 2; k = k + 2) {
                  let profileData: string;
                  let profileId: string;
                  let profileName: string;
                  let profileValue: number;
                  profileData = data[j][keys[k]];
                  profileId = profileData.split("||")[1];
                  profileName = profileData.split("||")[2];
                  profileValue = data[j][keys[k + 1]];

                  let newPermission: tProfileItemModel = {
                    profileId: profileId,
                    profileName: profileName,
                    profilePermission: profileValue,
                  };
                  newPermissions.push(newPermission);
                }
                newData[i].Groups[g].Items[m].profilePermissions = [
                  ...newPermissions,
                ];
              }
            }
          }
        }
      }

      setDataTransformed(newData);
      setIsLoadingScreen(false);
    } catch (error) {
      setIsLoadingScreen(false);
    }
  };

  const getData = async () => {
    let expectedCodes: Array<number> = [200, 404];
    let profileMenuItemService = new ProfileMenuItemService();
    try {
      let response: Array<ProfileMenuItemViewModel> =
        await profileMenuItemService.getAllAssignedToProfile(
          false,
          true,
          expectedCodes,
        );
      //setIsLoadingScreen(false);
      if (response && response.length > 0) {
        return [...response[0].profileMenuItemDetail];
      } else {
        return [];
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getGridData ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getGridData ex`);
      //setIsLoadingScreen(false);
      return [];
    }
  };

  const handleOnCheckboxChange = (idx: number) => {
    let newProfileList = [...profileList];
    newProfileList[idx].value2 = !newProfileList[idx].value2;
    setProfileList(newProfileList);
  };

  const handleOkButtonClick = async () => {
    let recordInputDTO: ProfileMenuItemAssignProfileInputDTO = {
      guidProfileIds: profileList
        .filter((x) => x.value2)
        .map((item) => item.key),
      menuItemId: recordInput,
    };
    let inputDTOValidator = new ProfileMenuItemAssignProfileInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      return;
    }
    await updateProfileMenuItem(recordInputDTO);
  };

  const updateProfileMenuItem = async (
    inputDTO: ProfileMenuItemAssignProfileInputDTO,
  ) => {
    let clientService = new ProfileMenuItemService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await clientService.assignProfile(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      if (responseRequest.responseResult) {
      }
      callBackComands({ result: "OK", recordId: recordInput });
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    loadResourcesAndProfiles();
  }, []);

  useEffect(() => {
    if (dataTransformed.length === 0) {
      return;
    }
    let newProfileList = [...profileList];
    let found: boolean = false;
    for (let i = 0; i < dataTransformed.length; i++) {
      const section = dataTransformed[i];
      if (section.sectionId.toLowerCase() === recordInput.toLowerCase()) {
        for (let j = 0; j < section.profilePermissions.length; j++) {
          const profile = section.profilePermissions[j];
          const profileIdx: number = newProfileList.findIndex(
            (x) => x.key.toLowerCase() === profile.profileId.toLowerCase(),
          );
          if (profileIdx > -1) {
            newProfileList[profileIdx].value2 = profile.profilePermission === 1;
          } else {
            //TODO Logs
            console.error("Perfil not found");
          }
        }
        found = true;
        break;
      }
      if (!found) {
        for (let i = 0; i < dataTransformed.length; i++) {
          const section = dataTransformed[i];
          for (let j = 0; j < section.Groups.length; j++) {
            const group = section.Groups[j];
            if (group.groupId.toLowerCase() === recordInput.toLowerCase()) {
              for (let k = 0; k < group.profilePermissions.length; k++) {
                const profile = group.profilePermissions[k];
                const profileIdx: number = newProfileList.findIndex(
                  (x) =>
                    x.key.toLowerCase() === profile.profileId.toLowerCase(),
                );
                if (profileIdx > -1) {
                  newProfileList[profileIdx].value2 =
                    profile.profilePermission === 1;
                } else {
                  //TODO Logs
                  console.error("Perfil not found");
                }
              }
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i < dataTransformed.length; i++) {
              const section = dataTransformed[i];
              for (let j = 0; j < section.Groups.length; j++) {
                const group = section.Groups[j];
                for (let k = 0; k < group.Items.length; k++) {
                  const item = group.Items[k];
                  if (item.itemId.toLowerCase() === recordInput.toLowerCase()) {
                    for (let z = 0; z < item.profilePermissions.length; z++) {
                      const profile = item.profilePermissions[z];
                      const profileIdx: number = newProfileList.findIndex(
                        (x) =>
                          x.key.toLowerCase() ===
                          profile.profileId.toLowerCase(),
                      );
                      if (profileIdx > -1) {
                        newProfileList[profileIdx].value2 =
                          profile.profilePermission === 1;
                      } else {
                        //TODO Logs
                        console.error("Perfil not found");
                      }
                    }
                    found = true;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    setProfileList(newProfileList);
  }, [dataTransformed]);

  return (
    <>
      <ContentVerticalTabInsertUpdateStyled>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <TPPageSubTitle>{`${subTitleLabel} : ${recordInput}`}</TPPageSubTitle>
              {profileList.length > 0 && (
                <>
                  <div className="row">
                    <div className="col">
                      <TPPageSection>
                        {profileList.map((item, index, array) => {
                          let elementJsx;
                          if (index % 2 === 0) {
                            elementJsx = (
                              <div
                                className="row"
                                key={"chkprofile" + array[index].key}
                              >
                                <div className="col-6">
                                  <div className="form-group">
                                    <TPCheckBox
                                      id="IdCheckBox"
                                      key={`chk-${array[index].key}`}
                                      labelText={array[index].value}
                                      checked={array[index].value2}
                                      onChange={() =>
                                        handleOnCheckboxChange(index)
                                      }
                                    />
                                  </div>
                                </div>
                                {index + 1 <= array.length - 1 ? (
                                  <div className="col">
                                    <div className="form-group">
                                      <TPCheckBox
                                        id="IdCheckBox"
                                        key={`chk-${array[index + 1].key}`}
                                        labelText={array[index + 1].value}
                                        checked={array[index + 1].value2}
                                        onChange={() =>
                                          handleOnCheckboxChange(index + 1)
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            );
                          } else {
                            elementJsx = null;
                          }
                          return elementJsx;
                        })}
                      </TPPageSection>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-10">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={handleOkButtonClick}
                >
                  {saveButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </ContentVerticalTabInsertUpdateStyled>
    </>
  );
};

export default MenuDefinitionAssignProfile;
