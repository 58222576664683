import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
    DataTableContainer,
    TableContainer,
    tableStyles,
    IsActiveIcon,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    TPFilterAndSearch,
    TPPageActions,
    TPPageFilterContainer,
    TPPageFirstRow,
    TPPageSearchContainer,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import {
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TreeService } from "@/services/TreeService";
import { TPI18N } from "@/services/I18nService";
import React, {
    useEffect,
    useState,
    useImperativeHandle,
    useReducer,
} from "react";
import DataTable from "react-data-table-component";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import ContainerFolder from "./ImagesAdminContainerFolder";
import ContainerFiles from "./ImagesAdminContainerFiles";
import {
    AppProviderImagesAdmin,
    useAppConctextImageAdmin,
} from "./ImagesAdminContextImagesAdmin";
import ImagesAdminContainerFiles from "./ImagesAdminContainerFiles";
import {
    ContainerImageAdminDivStyle,
    ContainerVerticalDivStyle,
    NameFolderInputStyle,
} from "./StyleImageAdmin";
import { MdVerticalAlignTop } from "react-icons/md";
import { TreeInputDTOValidator } from "@/models/Tree/TreeInputDTO";
import ImagesAdminContainerFilter from "./ImagesAdminContainerFilter";
import ImagesAdminSlider from "./ImagesAdminSlider";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";
import TPModalLanguageList from "../../layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "../../layouts/TPModalLanguageList/useModal";



interface dataModel {
    handleChanged?: (id: string, value: string) => void;
    handleChangedName?: (value: string) => void;
    handleChangedList?: (list: Array<RecordLocalizedModel>) => void;
    recordLocalized: RecordLocalizedModel[];
    baseLanguageOptions: TPKeyValue[];
    activeSave: boolean;
    folderName: string;
}





const InputLanguages = ({ handleChanged, handleChangedList, handleChangedName, recordLocalized, baseLanguageOptions, activeSave, folderName }: dataModel) => {
    const resourceSet: string = "InputLanguages.tsx";

    const [recordLocalizedModel, setRecordLocalizedModel] = useState<Array<RecordLocalizedModel>>(recordLocalized);
    const [baseLanguageOptionsModel, setBaseLanguageOptionsModel] = useState<Array<TPKeyValue>>(baseLanguageOptions);

   

    const [languageListLabel, setLanguageListLabel] = useState("");
    const [saveButtonModalLabel, setSaveButtonModalLabel] = useState("");
    const [cancelButtonModalLabel, setCancelButtonModalLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [require, setRequiere] = useState("");

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);


    const loadResourcesAndLoadInfo = async () => {

        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );
        setSaveButtonModalLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonModalLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setNameLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
        );
        setRequiere(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "inputDtoRequiere"),
        );
    };


    const handleEdit = (id: string, value: string) => {
        if (handleChanged) handleChanged(id, value);
    }

    const handleChangedUpdateList = (list: RecordLocalizedModel[]) => {
        if (handleChangedList) handleChangedList(list);
    }

    const handleError = (index: string) => {
        let item = recordLocalizedModel.find((s) => s.languageId == index);
        if (!item) return true;
        if (!item.localizedValue) return true;
        if (item.localizedValue == "") return true;
        return false;
    }

    useEffect(() => {
        loadResourcesAndLoadInfo();
      
    }, []);

    return (

        <div style={{
            display: "flex",
            alignItems:"start"
        }}>
            {recordLocalizedModel &&
                recordLocalizedModel.length > 0 &&
                TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                        index === 0 && (
                            <div key={`languageItem-${item.id}`}>
                                <NameFolderInputStyle
                                    type="text"
                                    value={
                                        recordLocalizedModel?
                                        recordLocalizedModel.find(
                                            (s) => s.languageId == item.id,
                                        )?.localizedValue:""
                                    }
                                    placeholder={folderName}
                                    onChange={(e) => {
                                        if (handleChangedName) handleChangedName(e.target.value);
                                        let temp = [ ...recordLocalizedModel ];
                                        let newItem =
                                            recordLocalizedModel.find(
                                                (s) => s.languageId == item.id,
                                            );
                                        if (newItem)
                                            newItem.localizedValue = e.target.value;

                                        console.log("Localization", temp);

                                       setRecordLocalizedModel(temp);
                                        //handleEdit(item.id, e.target.value);
                                        //handleChangedUpdateList(temp);
                                    }}
                                    width={"182px"}
                                    />


                            </div>
                        ),
                )}
            {recordLocalizedModel &&
                recordLocalizedModel.length > 1 && (
                    <>
                        <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        icon={TPIconTypes.language}
                        text={`+${baseLanguageOptions.length - 1}`}
                        tooltip={languageListLabel}
                        className={"pt-3"}
                        onClick={handleOpenModalLanguageList}
                       
                        style={{ marginTop: "-5px" }}

                        />

                        <TPModalLanguageList
                            id="IdModalLanguageList"
                            isOpen={isOpenModalLanguageList}
                            title={languageListLabel}
                            acceptLabel={saveButtonModalLabel}
                            cancelLabel={cancelButtonModalLabel}
                            saveChanges={handleSaveChangesModalLanguageList}
                            closeModal={handleCloseModalLanguageList}
                        >
                            <div
                                className="row overflow-auto"
                                style={{ height: "200px" }}
                            >
                                {TPGlobal.TPClientAvailableLanguages.map(
                                    (item, index) =>
                                        index > 0 && (
                                            <div
                                                className="col-12"
                                                key={`languageItem-${item.id}`}
                                            >
                                                <div className="form-group">
                                                    <TPTextBox
                                                        id="IdTextBox"
                                                        isMandatory={index === 0}
                                                        labelText={`${nameLabel} (${item.name})`}
                                                        value={
                                                            recordLocalizedModel.find(
                                                                (s) => s.languageId == item.id,
                                                            )?.localizedValue
                                                        }
                                                        onChange={(e: any) => {
                                                          
                                                            let temp = [...recordLocalizedModel];
                                                            let newItem =
                                                                recordLocalizedModel.find(
                                                                    (s) => s.languageId == item.id,
                                                                );
                                                            if (newItem)
                                                                newItem.localizedValue = e.target.value;

                                                            console.log("Localization list", temp);

                                                            setRecordLocalizedModel(temp);
                                                            if (handleChangedList) handleChangedList(temp);
                                                        }}
                                                        containerStyle={{ with: "100%" }}
                                                        maxLength={100}
                                                        errorMessage={""}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                )}
                            </div>
                        </TPModalLanguageList>
                    </>
                )}
        </div>

    );
};

export default InputLanguages;
