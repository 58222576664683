import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailRuleDefinitionViewModel } from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";

export class InboundMailRuleDefinitionService {
  serviceFileName: string = "InboundMailRuleDefinitionService.ts";

  public async getInboundMailRuleDefinitionByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailRuleDefinitionViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailRuleDefinitionByFilter`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailRuleDefinitionByFilter`,
      );
    }
  }

  public async getInboundMailRuleDefinitionAvailablesById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailRuleDefinitionViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition/get-availables?inboundMailboxId=";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAvailablesById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAvailablesById`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAvailablesById`,
      );
    }
  }

  public async getInboundMailRuleDefinitionAssignedById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailRuleDefinitionViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/inboundmailruledefinition/get-assigned?inboundMailboxId=";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAssignedById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAssignedById`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getInboundMailRuleDefinitionAssignedById`,
      );
    }
  }
}
