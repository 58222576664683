import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";

export interface InboundMailRuleInputDTO {
  InboundMailboxId: string;
  AssignedRulesList: Array<InboundMailRuleAssignedRuleInputDTO>;
}

export interface InboundMailRuleAssignedRuleInputDTO {
  RuleId: string;
  Order: number;
}

export class InboundMailRuleInputDTOValidator extends Validator<InboundMailRuleInputDTO> {
  constructor() {
    super();
    const resourceSet = "InboundMailboxesAssignRulesComponent";
    try {
      this.ruleFor("InboundMailboxId")
        .notNull()
        .withMessage(resourceSet + "|InputDTOInboundMailboxIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInboundMailboxIdEmpty")

        .must((InboundMailboxId) => {
          let check = TPGlobal.checkSpecialCharacters(InboundMailboxId);
          return check.result;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidInboundMailboxIdChar",
        )

        .must((InboundMailboxId) => {
          return TPGlobal.TPSanitize(InboundMailboxId) === InboundMailboxId;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidInboundMailboxIdChar",
        )

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidInboundMailboxIdLength");

      this.ruleForEach("AssignedRulesList")
        .must((AssignedRulesList) => {
          if (
            AssignedRulesList.RuleId.trim() === "" ||
            AssignedRulesList.RuleId === null
          ) {
            return false;
          }
          return (
            TPGlobal.TPSanitize(AssignedRulesList.RuleId) ===
            AssignedRulesList.RuleId
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidRuleId")

        .must((AssignedRulesList) => {
          return AssignedRulesList.RuleId.length <= 20;
        })
        .withMessage(resourceSet + "|InputDTOInvalidRuleIdLength")

        .must((AssignedRulesList) => {
          return AssignedRulesList.Order !== null;
        })
        .withMessage(resourceSet + "|InputDTOInvalidOrder");
    } catch (error) {}
  }
}
