import styled from "styled-components";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";

const headerHeight = "120px";
const spaceBetweenSearchAndContent = "20px";
const leftMenuWidth = "330px";
const bottomHeight = "60px";

export const TPConfigValueHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  height: ${headerHeight};
  left: 22px;
  right: 10px;
  padding: 10px 0; /* Añadido para dar un poco de espacio interior */
`;

export const TPConfigValueHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TPConfigValueHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const TPConfigValueSearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TPConfigKeyValueContainerStyled = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const MenuContainer = styled.div(
  ({ theme }) => `
  overflow-x: auto;
  position: absolute;
  top: ${headerHeight};
  bottom: 0;
  left: 22px;
  width: ${leftMenuWidth};
  padding: 10px;
  background-color: ${theme.colors.configValues.menu.backgroundColor};
`
);

export const MenuStyled = styled.ul`
  padding: 20px;
`;

export const CategoryItemContainer = styled.span(
  ({ theme }) => `
  align-items: center;
  padding: 10px;
  font-size: 14px;
  cursor: default;
  color: ${theme.colors.configValues.category.itemContainer.fontColor};
  `
);

export const MenuItemStyled = styled.li(
  ({ theme }) => `
  font-weight: bold;

  div {
    background-color: ${theme.colors.configValues.menu.item.backgroundColor};
    padding: 10px !important;
    width: 100%;
  }
`,
);

export const SubMenuStyled = styled.ul`
  cursor: pointer;
`;

export const SubMenuItemStyled = styled.li(
  ({ theme }) => `
  padding: 5px !important;
  padding-left: 15px !important;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 5px !important;

  &.selected {
    background-color: ${theme.colors.configValues.submenu.hover.backgroundColor};
  }

  &.selected a {
    color: ${theme.colors.configValues.submenu.hover.a.fontColor};
    text-decoration: none;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.configValues.submenu.a.fontColor};
    word-break: break-word;
  }
  
  &:hover {
    background-color: ${theme.colors.configValues.submenu.hover.backgroundColor};

    a {
      text-decoration: none;
      color: ${theme.colors.configValues.submenu.hover.a.fontColor};
    }
  }
`,
);

export const ContentContainer = styled.div(
  ({ theme }) => `
  overflow-x: auto;
  position: absolute;
  top: ${headerHeight};
  bottom: ${bottomHeight};
  left: calc(${leftMenuWidth} + ${spaceBetweenSearchAndContent} + 10px);
  right: 0;
  border-left: 2px solid ${theme.colors.configValues.content.borderColor};
`
);

export const CategoryContentContainer = styled.div``;

export const CategoryContentTitle = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.configValues.category.content.title.backgroundColor};
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 17px;
`,
);

interface CategoryContentBodyProps {
  collapsed: boolean;
}

export const CategoryContentBody = styled.div(
  ({ collapsed }: CategoryContentBodyProps) => {
    return `
      margin-left: 22px;
      display: ${collapsed ? "none" : "block"};
    `;
  }
);

export const CollapsableCategoryContentBody = styled.div(
  ({ collapsed }: CategoryContentBodyProps) => {
    return `
      display: ${collapsed ? "none" : "block"};
    `;
  }
);

export const MenuContentContainer = styled.div``;

export const MenuContentTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px;
`;

export const CollapsableMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CollapsableMenuContentTitle = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.configValues.category.content.title.backgroundColor};
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  flex-grow: 1;
`
);

export const CollapsableMenuContentDescription = styled.div`
  font-weight: bold;
`;

export const CollapsableMenuContentBody = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const MenuContentDescription = styled.div`
  margin: 10px;
`;

export const MenuContentBody = styled.div`
  padding: 20px;
`;

export const FooterContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  bottom: 0;
  left: calc(${leftMenuWidth} + ${spaceBetweenSearchAndContent});
  top: calc(100% - ${bottomHeight});
  right: 0;
  padding: 10px;
  height: ${bottomHeight};
`
);

export const TPConfigKeyValueFieldsContainer = styled.div`
  background-color: red;
`;

export const TPStyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  display: block;
  max-height: 350px;
  overflow-y: auto;
  margin: 4px;
`;

export const TPStyledHead = styled.thead``;

export const TPStyledHeaderRow = styled.tr``;

export const TPStyledHeadCell = styled.th`
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
`;

export const TPStyledBody = styled.tbody``;

export const TPStyledBodyRow = styled.tr``;

export const TPStyledBodyCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

export const LanguageCountBadge = styled.div`
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(37, 41, 45);
  white-space: nowrap;
  background: #19875475;
  max-height: 24px;
  border-radius: 3px;
`;

export const TPStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 168px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: red;
  margin-top: 24px; /* Ajustar para alinear con los inputs */
`;

export const AddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: green;
  margin-left: auto;
  margin-top: 24px; /* Ajustar para alinear con los inputs */
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LabelWithAsterisk = styled.label`
  &::after {
    content: " *";
    color: red;
  }
`;

export const WideSelect = styled(TPSelect)`
  width: 100%; /* Hacer que el select ocupe todo el espacio disponible */
`;
