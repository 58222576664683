import { useSelector } from "react-redux";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import CustomerSearchAccordion from "./CustomerSearchAccordion";
import useLabels from "../styles/labels";

const MergeCustomersSearch = function () {
  const { labels } = useLabels();
  const { currentView } = useSelector(
    (state: MergeCustomersState) => state.mainData,
  );
  return (
    <>
      {currentView == "search" && (
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <CustomerSearchAccordion
            type="main"
            id="main"
            title={labels.MainCustomerRecord}
          />
          <CustomerSearchAccordion
            type="merge"
            id="merge"
            title={labels.MergeCustomerRecord}
          />
        </div>
      )}
    </>
  );
};

export default MergeCustomersSearch;
