import select from "@/assets/images/select.png";


export interface ITPWizardStep {
    children?: any;
    isActive: boolean;
    id: string;
    labelText: string;
    numberText: string;
    withIcon?: boolean;
    icon?: any;
    name: string;
    addLine?: boolean;
    activeNewComponet?: boolean;
    isCheck?: boolean;
    callBackCommands?: Function;
}
const TPWizardStep = ({
    callBackCommands,
    addLine = false,
    activeNewComponet = false,
    isCheck = false,
    isActive,
    id,
    labelText,
    numberText,
    withIcon,
    icon,
    name,
}: ITPWizardStep) => {
    return (
        <>
            <li className="nav-item" role="presentation">
                <a
                    id={`id${id}`}
                    className={`nav-link ${isActive ? "active" : ""}`}
                    data-bs-toggle="tab"
                    data-bs-target={"#" + id}
                    type="button"
                    role="tab"
                    style={{ maxWidth: "450px", padding: "4px" }}
                    aria-controls={id}
                    aria-selected={isActive}

                >
                    {!activeNewComponet ?
                        <>
                            <div className="wizard-number">{numberText}</div>
                            <div className="wizard-title" >{labelText}</div>
                        </>
                        :
                        <>
                            <div
                                onClick={() => {
                                    if (callBackCommands != undefined) callBackCommands(numberText)
                                }}
                                style={{ display: "flex", alignItems: "center", gap: "5px", fontWeight: "bold" }}>
                                {isCheck ?
                                    <img src={select} width={50}></img>
                                    :
                                    <div className="wizard-number">{numberText}</div>
                                }
                                <div className="wizard-title" style={{ color: "black" }}>{labelText}</div>
                                {addLine &&
                                    <div style={{ width: "100px", backgroundColor: "#989898", height: "3px" }} > </div>
                                }

                            </div>

                        </>

                    }
                </a>
            </li >
        </>
    );
};

export default TPWizardStep;
