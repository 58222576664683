import styled from "styled-components";

export const TPCollapsableSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TPCollapsableSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) =>
        theme.colors.configValues.category.content.title.backgroundColor};
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 17px;
`;

export const TPCollapsableSectionTitle = styled.div`
  flex-grow: 1;
`;

export const TPCollapsableSectionContent = styled.div<{ collapsed: boolean }>`
  display: ${({ collapsed }) => (collapsed ? "none" : "block")};
`;

export const TPCollapsableSectionDescription = styled.div`
  margin-top: 10px;
`;

export const TPCollapsableSectionBody = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
`;
