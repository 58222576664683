import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { downloadEML } from "@/helpers/DownloadEML";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal from "@/layouts/TPModal/TPModal";
import TPModalError from "@/layouts/TPModalError/TPModalError";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import {
  InboundMailboxInProcessActionsType,
  InboundMailboxInProcessType,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  WebServiceClassifierType
} from "@/models/Global/TPGlobalEnums";
import {
  ApplyRules,
  ApplyRulesDTO,
  InboundMailboxInProcessInputDTOValidator
} from "@/models/InboundMailboxInProcess/ApplyRulesDTO";
import { InboundMailboxInProcessViewModel } from "@/models/InboundMailboxInProcess/InboundMailboxInProcessViewModel";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import { InboundMailboxesService } from "@/services/InboundMailboxesService";
import { InboundMailboxInProcessService } from "@/services/InboundMailboxInProcessService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import FileSaver from "file-saver";
import moment from "moment";
import React, {
  HTMLProps,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState
} from "react";
import * as XLSX from "xlsx";
import RuleApplied from "./RuleApplied";
import { TPChip } from "@/components/TPChip/TPChip";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<InboundMailboxInProcessViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface InboundMailboxInProcessAdminInterface {
  callBackCommands: Function;
}

interface ApplyRulesResponseType {
  key: string;
  value: string;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
  selectName?: string;
};

interface FormStateType {
  insertionType: string;
  ruleDefinitionId: string;
  action: string | null;
  classifier: string | null;
  actionErrorMessage: string;
  classifierErrorMessage: string;
  inboundMailboxInProcessIdErrorMessage: string;
  [key: string]: any;
}

const InboundMailboxInProcessAdmin = React.forwardRef(
  ({ callBackCommands }: InboundMailboxInProcessAdminInterface, ref) => {
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const componentFileName: string = "InboundMailboxInProcessAdmin.tsx";
    const resourceSet: string = "InboundMailboxInProcessAdminComponent";

    const [titleLabel, setTitleLabel] = useState("");
    const [insertTypeLabel, setinsertTypeLabel] = useState("");
    const [inboundMailboxLabel, setInboundMailboxLabel] = useState("");
    const [actionLabel, setActionLabel] = useState("");
    const [classifierLabel, setClassifierLabel] = useState("");
    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [testInerHtmlComponent, setTestInerHtmlComponent] = useState("");
    const [downloadColumnLabel, setDownloadColumnLabel] = useState("");
    const [typeColumnLabel, setTypeColumnLabel] = useState("");
    const [viewMailColumnLabel, setViewMailColumnLabel] = useState("");
    const [fromNameColumnLabel, setFromNameColumnLabel] = useState("");
    const [addressTOColumnLabel, setAddressTOColumnLabel] = useState("");
    const [addressCcColumnLabel, setAddressCcColumnLabel] = useState("");
    const [subjectColumnLabel, setSubjectColumnLabel] = useState("");
    const [htmlBodyColumnLabel, setHtmlBodyColumnLabel] = useState("");
    const [emailGMTDateColumnLabel, setEmailGMTDateColumnLabel] = useState("");
    const [insertDateColumnLabel, setInsertDateColumnLabel] = useState("");
    const [inboundMailboxColumnLabel, setInboundMailboxColumnLabel] = useState("");
    const [ruleDefinitionIdColumnLabel, setRuleDefinitionIdColumnLabel] = useState("");
    const [modalSuccessTitle, setModalSuccessTitle] = useState("");
    const [modalConfirmTitle, setModalConfirmTitle] = useState("");
    const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");
    const [applyLabel, setApplyLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    let initialStateFilter: Array<TPKeyValue> = [];
    const [insertTypeKeyValue, setinsertTypeKeyValue] = useState(initialStateFilter);
    const [inboundMailboxKeyValue, setinboundMailboxKeyValue] = useState(initialStateFilter);
    const [filterCopyInboundMailboxKeyValue, setFilterCopyInboundMailboxKeyValue] = useState(initialStateFilter);
    const [actionsKeyValue, setActionsKeyValue] = useState(initialStateFilter);
    const [classifierKeyValue, setClassifierKeyValue] = useState(initialStateFilter);
    const [inboundMailboxInProcessSelectedRows, setInboundMailboxInProcessSelectedRows] = useState<InboundMailboxInProcessViewModel[]>();
    const [typeFilter, setTypeFilter] = useState<string>("");
    const [selectedInboundMailbox, setSelectedInboundMailbox] = useState<string>("");
    const [selectedAction, setSelectedAction] = useState<string>("");

    let formInitialState: FormStateType = {
      insertionType: `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
      inboundMailbox: "",
      ruleDefinitionId: "",
      action: "",
      classifier: "",
      inboundMailboxErrorMessage: "",
      insertionTypeErrorMessage: "",
      actionErrorMessage: "",
      classifierErrorMessage: "",
      inboundMailboxInProcessIdErrorMessage: "",
    };
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [formState, setFormState] = useState<FormStateType>(formInitialState);
    const [isShownModalBodySelect, setIsShownModalBodySelect] = useState(false);
    const [modalQuestionProcessMails, setModalQuestionProcessMails] = useState(modalQuestionInitialState);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [applyRulesResponse, setApplyRulesResponse] = useState<ApplyRulesResponseType[]>([]);
    const [fullData, setFullData] = useState<InboundMailboxInProcessViewModel[]>([]);
    const [tableData, setTableData] = useState<InboundMailboxInProcessViewModel[]>([]);

    const inerHtmlComponent = (inerHtml: string) => setTestInerHtmlComponent(inerHtml);
    const handleCallbackAnser = () => setIsShownModalBodySelect(false);

    const loadResourcesAndFilter = async () => {
      setModalConfirmTitle(await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"));
      setModalConfirmQuestion(await TPI18N.GetText(resourceSet, "ModalConfirmQuestion"));
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setinsertTypeLabel(await TPI18N.GetText(resourceSet, "InboundMailboxTypeLabel"));
      setInboundMailboxLabel(await TPI18N.GetText(resourceSet, "InboundMailboxLabel"));
      setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
      setClassifierLabel(await TPI18N.GetText(resourceSet, "ClassifierLabel"));
      setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
      setDownloadColumnLabel(await TPI18N.GetText(resourceSet, "DownloadLabel"));
      setTypeColumnLabel(await TPI18N.GetText(resourceSet, "InsertionTypeLabel"));
      setViewMailColumnLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
      setFromNameColumnLabel(await TPI18N.GetText(resourceSet, "FromNameLabel"));
      setAddressTOColumnLabel(await TPI18N.GetText(resourceSet, "AddressToLabel"));
      setAddressCcColumnLabel(await TPI18N.GetText(resourceSet, "AddressCcLabel"));
      setSubjectColumnLabel(await TPI18N.GetText(resourceSet, "SubjectLabel"));
      setHtmlBodyColumnLabel(await TPI18N.GetText(resourceSet, "HtmlBodyLabel"));
      setEmailGMTDateColumnLabel(await TPI18N.GetText(resourceSet, "EmailGMTDateLabel"));
      setInsertDateColumnLabel(await TPI18N.GetText(resourceSet, "InsertDateLabel"));
      setInboundMailboxColumnLabel(await TPI18N.GetText(resourceSet, "InboundMailboxLabel"));
      setRuleDefinitionIdColumnLabel(await TPI18N.GetText(resourceSet, "RuleDefinitionLabel"));
      setModalSuccessTitle(await TPI18N.GetText(resourceSet, "ModalSuccessTitle"));
      setOkButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
      setCancelButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
      setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportLabel"));
      setYesLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"));
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      await getInboundMailbox();
      await getClassifier();
      await getActions();
      await getTypes();
    };

    const handleInputTextChange = (propertyName: string, newValue: any) => {
      setFormState({
        ...formState,
        [propertyName]: newValue,
        [`${propertyName}ErrorMessage`]: "",
      });
    };

    const reloadDataGrid = async () => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getInboundMailboxInProcessByFilter(
            typeFilter,
            selectedInboundMailbox,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        const response = await getJsonRule(responseRequest);
        setFullData(response);
        setTableData(
          response.map((item) => {
            return {
              download: null,
              id: item.id,
              insertionType: item.insertionType,
              fromName: item.fromName,
              addressTO: item.addressTO,
              addressCC: item.addressCC,
              subject: item.subject,
              htmlBody: item.htmlBody,
              emailGMTDate: item.emailGMTDate,
              insertDate: item.insertDate,
              mailboxId: item.mailboxId,
              ruleDefinitionId: item.ruleDefinitionId,
              jsonRule: item.jsonRule,
              storageIdEML: item.storageIdEML,
            } as InboundMailboxInProcessViewModel;
          })
        );
        return [...response];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getAllMainboxReadInProcess = async function () {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getInboundMailboxInProcess(
            false,
            false,
            expectedCodes
          );
        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getAllMainboxReadInProcess ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getAllMainboxReadInProcess ex`
        );
        setIsLoadingScreen(false);
        return [];
      }
    }

    const getInboundMailboxInProcessByFilter = async (
      insertType: string,
      inboundMailboxId: string
    ) => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);

        let responseRequest =
          await serviceClient.getInboundMailboxInProcessByFilter(
            insertType,
            inboundMailboxId,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        const response = await getJsonRule(responseRequest);
        return [...response];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailboxInProcessByFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getInboundMailboxInProcessByFilter ex`
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const applyRulesInboundMailboxInProcess = async (data: ApplyRulesDTO) => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.applyRulesInboundMailboxInProcess(
            data,
            true,
            true,
            expectedCodes
          );
        const res = responseRequest.responseData?.keyList;
        setApplyRulesResponse(res);
        if (res?.length) setModalSuccess(true);
        setIsLoadingScreen(false);
        reloadGridCommand();
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getInboundMailboxs = async () => {
      let serviceClient = new InboundMailboxesService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getInboundMailboxesByFilter(
          adminState.selectedFilter,
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        const inboundMailboxesList = responseRequest.map((res) => ({
          key: res.id,
          value: res.description,
        }));
        return inboundMailboxesList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailboxs ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getInboundMailboxs ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getQuickClassifiersByType = async () => {
      const quickClassifierClient = new QuickClassifierService();
      const expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        const responseRequest =
          await quickClassifierClient.getQuickClassifiersByType(
            WebServiceClassifierType.MailReader,
            TPActiveOptions.ACTIVE,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        const quickClassifierList = responseRequest.map((res) => ({
          key: res.id,
          value: res.description,
        }));
        return quickClassifierList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getQuickClassifiersByType ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getQuickClassifiersByType ex`
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getInboundMailRuledefinitionById = async (
      ruleDefinitionId: string
    ) => {
      let serviceClient = new InboundMailboxesRulesService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getInboundMailRuleDefinitionById(
            ruleDefinitionId,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailRuledefinitionById ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getInboundMailRuledefinitionById ex`
        );
        setIsLoadingScreen(false);
        return null;
      }
    };

    const getJsonRule = async (
      inboundMailboxInProcess: InboundMailboxInProcessViewModel[]
    ) => {
      let response = [];
      for (const item of inboundMailboxInProcess) {
        const inboundMailRuledefinition =
          await getInboundMailRuledefinitionById(item.ruleDefinitionId);
        const jsonRule = inboundMailRuledefinition?.jsonRule || "[]";
        response.push({ ...item, jsonRule: jsonRule });
      }
      return response;
    };

    const getInboundMailbox = async () => {
      let newinboundMailboxKeyValue: TPKeyValue[] = [{ key: "", value: "--" }];
      const inboundMailboxs = await getInboundMailboxs();
      setinboundMailboxKeyValue([
        ...newinboundMailboxKeyValue,
        ...inboundMailboxs,
      ]);
      setFilterCopyInboundMailboxKeyValue([
        ...newinboundMailboxKeyValue,
        {
          key: `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
          value: await TPI18N.GetText(
            resourceSet,
            "InboundMailboxTypeAllValue"
          ),
        },
        ...inboundMailboxs,
      ]);
    };

    const getClassifier = async () => {
      let newclassifierKeyValue: TPKeyValue[] = [{ key: "", value: "--" }];
      const quickClassifiers = await getQuickClassifiersByType();
      setClassifierKeyValue([...newclassifierKeyValue, ...quickClassifiers]);
    };

    const getActions = async () => {
      const actionsEntries = Object.entries(InboundMailboxInProcessActionsType);

      let actionsKeyValue: TPKeyValue[] = await Promise.all(
        actionsEntries.map(async (element: any) => {
          return {
            key: element[1],
            value: await TPI18N.GetText(resourceSet, `${element[0]}Value`),
          };
        })
      );
      actionsKeyValue = [{ key: "", value: "--" }, ...actionsKeyValue];
      setActionsKeyValue(actionsKeyValue);
    };

    const getTypes = async () => {
      const typesEntries = Object.entries(InboundMailboxInProcessType);
      let typesKeyValue: TPKeyValue[] = await Promise.all(
        typesEntries.map(async (element: any) => {
          return {
            key: element[1],
            value: await TPI18N.GetText(resourceSet, `${element[0]}Value`),
          };
        })
      );
      setTypeFilter(typesKeyValue[0].key);
      setinsertTypeKeyValue(typesKeyValue);
    };

    const reloadGridCommand = () => {
      setTableData([]);
      setInboundMailboxInProcessSelectedRows([]);
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const handleApplyAction = async () => {
      const data = {
        InsertionType: typeFilter,
        InboundMailbox: selectedInboundMailbox,
        Action: Number(selectedAction),
        InboundMailboxInProcessId:
          inboundMailboxInProcessSelectedRows?.map((row) => Number(row.id)) ||
          [],
        FixedClassifier: formState.classifier || null,
      };
      formValidate(data);
    };

    const formValidate = async (recordInputDTO: ApplyRules) => {
      let newformState = { ...formState };
      let inputDTOGroupValidator =
        new InboundMailboxInProcessInputDTOValidator();
      let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);
      if (TPGlobal.TPIsEmpty(resultValidator)) {
        const newModalQuestionProcessMails = {
          ...modalQuestionProcessMails,
          isShown: true,
        };
        setModalQuestionProcessMails(newModalQuestionProcessMails);
      } else {
        if (resultValidator?.InboundMailbox) {
          newformState.inboundMailboxErrorMessage = await TPI18N.GetResource(
            resultValidator.InboundMailbox
          );
        } else {
          newformState.actionErrorMessage = "";
        }
        if (resultValidator?.Action) {
          newformState.actionErrorMessage = await TPI18N.GetResource(
            resultValidator.Action
          );
        } else {
          newformState.actionErrorMessage = "";
        }
        if (resultValidator?.FixedClassifier) {
          newformState.classifierErrorMessage = await TPI18N.GetResource(
            resultValidator.FixedClassifier
          );
        } else {
          newformState.classifierErrorMessage = "";
        }
        if (resultValidator?.InboundMailboxInProcessId) {
          newformState.inboundMailboxInProcessIdErrorMessage =
            await TPI18N.GetResource(
              `${resultValidator.InboundMailboxInProcessId}`
            );
          if (Object.keys(resultValidator).length === 1) setModalError(true);
        } else {
          newformState.inboundMailboxInProcessIdErrorMessage = "";
        }

        setFormState(newformState);
      }
    };

    const handleRefreshClick = () => {
      reloadGridCommand();
      setFormState(formInitialState);
      const filterInboundMailboxKeyValue = [
        ...filterCopyInboundMailboxKeyValue,
      ].filter(
        (res: any) =>
          res.key !== `${InboundMailboxInProcessType.InboundMailboxTypeAll}`
      );
      setinboundMailboxKeyValue(filterInboundMailboxKeyValue);
    };

    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: true,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    const doCommand = (prevState: AdminStateType, command: commandType) => {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    };

    const handleCallBackModalConfirm = async (confirm: boolean) => {
      const newModalQuestionProcessMails = {
        ...modalQuestionProcessMails,
        callBackData: {},
        isShown: false,
      };
      if (confirm) {
        applyRulesInboundMailboxInProcess({
          Action: Number(selectedAction),
          FixedClassifier: formState.classifier || null,
          InboundMailboxInProcessId:
            inboundMailboxInProcessSelectedRows?.map((row) => row.id) || [],
        });
      }
      setModalQuestionProcessMails(newModalQuestionProcessMails);
    };

    const callBackSaveSuccess = () => {
      setModalSuccess(false);
      setFormState({ ...formInitialState });
    };

    const callBackSaveError = () => {
      setModalError(false);
    };

    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    useEffect(() => {
      reloadGridCommand();
      loadResourcesAndFilter()
        .then(function () {
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
        });
    }, []);

    useEffect(() => {
      if (typeFilter && selectedInboundMailbox) {
        getInboundMailboxInProcessByFilter(
          typeFilter,
          selectedInboundMailbox
        ).then((data) =>
          setTableData(
            data.map((item) => {
              return {
                download: null,
                id: item.id,
                insertionType: item.insertionType,
                fromName: item.fromName,
                addressTO: item.addressTO,
                addressCC: item.addressCC,
                subject: item.subject,
                htmlBody: item.htmlBody,
                emailGMTDate: item.emailGMTDate,
                insertDate: item.insertDate,
                mailboxId: item.mailboxId,
                ruleDefinitionId: item.ruleDefinitionId,
                jsonRule: item.jsonRule,
                storageIdEML: item.storageIdEML,
              } as InboundMailboxInProcessViewModel;
            })
          )
        );
      } else {
        getAllMainboxReadInProcess()
          .then(data => setTableData(
            data.filter(item => typeFilter == 'ALL' ? true : item.insertionType == typeFilter).map((item) => {
              return {
                download: null,
                id: item.id,
                insertionType: item.insertionType,
                fromName: item.fromName,
                addressTO: item.addressTO,
                addressCC: item.addressCC,
                subject: item.subject,
                htmlBody: item.htmlBody,
                emailGMTDate: item.emailGMTDate,
                insertDate: item.insertDate,
                mailboxId: item.mailboxId,
                ruleDefinitionId: item.ruleDefinitionId,
                jsonRule: item.jsonRule,
                storageIdEML: item.storageIdEML,
              } as InboundMailboxInProcessViewModel;
            })
          ))
      }
    }, [typeFilter, selectedInboundMailbox]);

    const customCSVExport = async (fileName = "inbound-mailbox-in-process") => {
      const ws = XLSX.utils.json_to_sheet(fullData);
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    return (
      <>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <div
                id="inbound-mailbox-in-process-filters-container"
                style={{
                  display: "flex",
                  gap: "16px",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <TPSelect
                  id="inbound-mailbox-in-process-type-filter"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTypeFilter(String(e.target.value))
                  }
                  dataSource={insertTypeKeyValue}
                  value={typeFilter}
                  labelText={insertTypeLabel}
                  isMandatory={true}
                  errorMessage={formState.insertionTypeErrorMessage}
                />
                <TPSelect
                  id="inbound-mailbox-in-process-inbound-mailbox"
                  onChange={(e: any) =>
                    setSelectedInboundMailbox(String(e.target.value))
                  }
                  dataSource={inboundMailboxKeyValue}
                  value={selectedInboundMailbox}
                  labelText={inboundMailboxLabel}
                  isMandatory={true}
                  errorMessage={formState.inboundMailboxErrorMessage}
                />
                <TPSelect
                  id="inbound-mailbox-in-process-action"
                  onChange={(e: any) =>
                    setSelectedAction(String(e.target.value))
                  }
                  selectStyle={{ position: "realtive" }}
                  dataSource={actionsKeyValue}
                  value={selectedAction}
                  labelText={actionLabel}
                  isMandatory={true}
                  errorMessage={formState.actionErrorMessage}
                />
                {Number(selectedAction) == 3 && (
                  <TPSelect
                    id="inbound-mailbox-in-process-classifier"
                    onChange={(e: any) => {
                      handleInputTextChange("classifier", e.target.value);
                    }}
                    dataSource={classifierKeyValue}
                    value={formState.classifier}
                    labelText={classifierLabel}
                    isMandatory={true}
                    errorMessage={formState.classifierErrorMessage}
                  />
                )}
                <TPButton
                  id="inbound-mailbox-in-process-apply"
                  type={TPButtonTypes.primary}
                  onClick={handleApplyAction}
                  className="ms-2 mt-4"
                  style={{ backgroundColor: "#9002b1" }}
                >
                  {applyLabel}
                </TPButton>
              </div>
            </div>
          </div>
          <br />
          <DynamicTable
            data={tableData}
            hiddenColumns={[
              "jsonRule",
              "storageIdEML",
              "validationKey",
              "uidl",
              "messageID",
              "fromAddress",
              "replyTo",
              "numTO",
              "numCC",
              "numBCC",
              "addressBCC",
              "plainTextBody",
              "emailLocalDate",
              "emailCustomHeaders",
              "numAttachments",
              "numEmbeddedImages",
              "status",
              "update",
              "updateDate",
              "userBlocking",
              "dateBlocked",
              "ruleDefinitionHtml",
            ]}
            columnNames={{
              download: downloadColumnLabel.toUpperCase(),
              id: viewMailColumnLabel.toUpperCase(),
              insertionType: typeColumnLabel.toUpperCase(),
              fromName: fromNameColumnLabel.toUpperCase(),
              addressTO: addressTOColumnLabel.toUpperCase(),
              addressCC: addressCcColumnLabel.toUpperCase(),
              subject: subjectColumnLabel.toUpperCase(),
              htmlBody: htmlBodyColumnLabel.toUpperCase(),
              emailGMTDate: emailGMTDateColumnLabel.toUpperCase(),
              insertDate: insertDateColumnLabel.toUpperCase(),
              mailboxId: inboundMailboxColumnLabel.toUpperCase(),
              ruleDefinitionId: ruleDefinitionIdColumnLabel.toUpperCase(),
            }}
            hideExport
            uppercaseActions
            headerAlignment={{
              download: "center",
            }}
            searchPosition="right"
            selectable
            icons={[
              {
                status: true,
                type: TPIconTypes.downloadTemplate,
                tooltip: exportLabel,
              },
            ]}
            onIconClicked={(icon) => {
              if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh)
                handleRefreshClick();
              if (icon == TPIconTypes.downloadTemplate) customCSVExport();
            }}
            customHeight="512px"
            columnStyles={{
              download: (entry) => (
                <button
                  key={entry.item.id}
                  type="button"
                  style={{
                    border: "none",
                    background: "none",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setIsLoadingScreen(true);
                    downloadEML(entry.item.storageIdEML)
                      .then(() => setIsLoadingScreen(false))
                      .catch((error) => console.error(error));
                  }}
                >
                  <TPIcon iconType={TPIconTypes.downloadTemplate} />
                </button>
              ),
              id: (entry) => (
                <button
                  key={entry.item.id}
                  type="button"
                  style={{
                    border: "none",
                    background: "none",
                    color: "#9002b1",
                  }}
                  onClick={() => handleUpdateClick(String(entry.item.id))}
                >
                  <b>
                    <u>{entry.item.id}</u>
                  </b>
                </button>
              ),
              insertionType: (entry) => (
                <TPLanguage
                  key={entry.item.id}
                  resourceSet={TPGlobal.globalResourceSet}
                  resourceId={`ACTION${entry.item.insertionType}`}
                />
              ),
              addressTO: (entry) => (
                <div style={{ whiteSpace: "normal" }}>
                  {entry.item.addressTO}
                </div>
              ),
              addressCC: (entry) => (
                <div style={{ whiteSpace: "normal" }}>
                  {entry.item.addressCC}
                </div>
              ),
              subject: (entry) => (
                <div style={{ whiteSpace: "normal" }}>{entry.item.subject}</div>
              ),
              htmlBody: (entry) => (
                <button
                  key={entry.item.id}
                  type="button"
                  onClick={() => {
                    setIsShownModalBodySelect(true);
                    inerHtmlComponent(entry.item.htmlBody);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    color: "#9002b1",
                  }}
                >
                  <TPIcon iconType={TPIconTypes.zoomIn} />
                </button>
              ),
              emailGMTDate: (entry) => (
                <div>
                  {moment(entry.item.emailGMTDate).format("YYYY/MM/DD HH:mm")}
                </div>
              ),
              insertDate: (entry) => (
                <div>
                  {moment(entry.item.insertDate).format("YYYY/MM/DD HH:mm")}
                </div>
              ),
              mailboxId: (entry) => (
                <div style={{ whiteSpace: "normal" }}>
                  {
                    inboundMailboxKeyValue.find(
                      (res) => res.key === entry.item.mailboxId
                    )?.value
                  }
                </div>
              ),
              ruleDefinitionId: (entry) => (
                <div style={{ overflow: "auto" }}>
                  <RuleApplied
                    inboundMailRuleJson={JSON.parse(
                      entry.item.jsonRule || "[]"
                    )}
                  />
                </div>
              ),
            }}
            onSelectionChange={(selectedEntries) =>
              setInboundMailboxInProcessSelectedRows(selectedEntries)
            }
          />
        </TPLoadingOverlay>
        <TPModal
          modalState={{
            titleModal: `${htmlBodyColumnLabel} HTML`,
            acceptLabel: okButtonLabel,
            cancelLabel: cancelButtonLabel,
            callBackAnswer: handleCallbackAnser,
            callBackData: TPGlobal.foo,
            isShown: isShownModalBodySelect,
            modalWidth: undefined,
          }}
        >
          <div
            style={{ height: "400px", overflow: "auto" }}
            dangerouslySetInnerHTML={{ __html: `${testInerHtmlComponent}` }}
          />
        </TPModal>
        <TPModalQuestion
          id="IdModalQuestion"
          title={modalConfirmTitle}
          yesLabel={okButtonLabel}
          noLabel={cancelButtonLabel}
          question={modalConfirmQuestion}
          callBackData={modalQuestionProcessMails.callBackData}
          isShown={modalQuestionProcessMails.isShown}
          callBackAnswer={handleCallBackModalConfirm}
        />
        <TPModalSuccess
          modalState={{
            id: "IdModalSuccess",
            yesLabel: okButtonLabel,
            isShown: modalSuccess,
            title: modalSuccessTitle,
            callBackFunction: () => callBackSaveSuccess(),
          }}
        >
          {applyRulesResponse &&
            applyRulesResponse.map(
              ({ key, value }, index) =>
                index > 0 && (
                  <div key={key} className="d-flex bd-highlight px-2">
                    <div className="p-2 w-100 bd-highlight">
                      <TPLanguage
                        resourceSet={resourceSet}
                        resourceId={`${key}Label`}
                      />
                    </div>
                    <div className="p-2 flex-shrink-1 bd-highlight">
                      {typeof value == "boolean" ? (
                        <TPChip
                          label={value ? yesLabel : noLabel}
                          backgroundColor={value ? "#B1F2D7" : "#FFD7DD"}
                        />
                      ) : (
                        value
                      )}
                    </div>
                  </div>
                )
            )}
        </TPModalSuccess>
        <TPModalError
          modalState={{
            id: "IdModalError",
            title: "",
            yesLabel: okButtonLabel,
            isShown: modalError,
            errorMessage: formState.inboundMailboxInProcessIdErrorMessage,
            callBackFunction: () => callBackSaveError(),
          }}
        />
      </>
    );
  }
);

export default InboundMailboxInProcessAdmin;
