import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { InboundMailboxInProcessAttachmentViewModel } from "@/models/InboundMailboxInProcessAttachment/InboundMailboxInProcessAttachmentViewModel";

export class InboundMailboxInProcessAttachmentService {
  serviceFileName: string = "InboundMailboxInProcessAttachmentService.ts";

  public async getInboundMailboxInProcessAttachmentById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<InboundMailboxInProcessAttachmentViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/inboundmailboxinprocessattachment/get-by-mailbox-inprocess-id?mailboxInprocessId=${id}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getInboundMailboxInProcessAttachmentById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessAttachmentById`,
      );
      throw new Error(
        `Error ${this.serviceFileName}  getInboundMailboxInProcessAttachmentById`,
      );
    }
  }
}
